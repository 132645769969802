import { Row } from "antd";
import useTranslate from "src/utils/useTranslate";
import Text from "antd/lib/typography/Text";
import "./SignUp.scss";


const AllRightsReserved = ({ styleProp }: any) => {
  const [t] = useTranslate();

  return (
    <Row className="all-right-reserved-container">
      <Text type="secondary" className="all-right-reserved" style={styleProp}>
        {t("navbar.footer.rightsReserved")} © {new Date().getFullYear()}
      </Text>
    </Row>
  );
};
export default AllRightsReserved;
