import { useCallback, useEffect, useState } from "react"
import { Modal, Input } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { updateNoteRequest } from "src/store/actions/projects"
import { useDispatch } from "react-redux"
import useTranslate from "src/utils/useTranslate"
import Text from "antd/lib/typography/Text"
import { orange } from "@ant-design/colors"
import { Prompt } from "react-router-dom"
import { StyledButton } from "src/styled_components/StyledButton"

const { confirm }: any = Modal

export const EditNote = ({
  project_id,
  note_id,
  comment,
}: {
  project_id: string
  note_id: any
  comment: any
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [input, setInput] = useState(comment)
  const [unsaved, setUnsaved] = useState(false)

  const updateNote = () => {
    dispatch(updateNoteRequest({ project_id, note_id, comment: input.trim() }))
    setShowUpdateModal(false)
  }

  const openConfirmationPrompt = () => {
    confirm({
      title: t("common.modal.unsavedwarning"),
      onOk() {
        setShowUpdateModal(false)
        setUnsaved(false)
        setInput(comment)
      },
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      onCancel() { },
      zIndex: 2000,
    })
  }

  const handleCancel = (e: any) => {
    e.stopPropagation()
    if (unsaved) {
      openConfirmationPrompt()
    } else {
      setShowUpdateModal(false)
    }
  }

  const callback = useCallback(
    (e: any) => {
      e.preventDefault()
      e.returnValue = t("workOrderDetails.warn.unsavedChanges")
    },
    [t]
  )

  useEffect(() => {
    unsaved && window.addEventListener("beforeunload", callback)
    return () => {
      window.removeEventListener("beforeunload", callback)
    }
  }, [unsaved, callback])

  return (
    <>
      <Prompt
        when={unsaved as boolean}
        message={`${t("common.unsavedChangesLost")}!.`}
      />
      <StyledButton
        onClick={() => setShowUpdateModal(true)}
        type="link"
        icon={<EditOutlined />}
      >
        {t("common.edit")}
      </StyledButton>
      <Modal
        okText={t("common.ok")}
        cancelText={t("common.cancel")}
        title={
          unsaved ? (
            <Text style={{ color: orange.primary }}>
              {t("common.unsavedChanges")}
            </Text>
          ) : null
        }
        visible={showUpdateModal}
        onOk={updateNote}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !input?.trim()?.length ? true : false }}
      >
        <Input.TextArea
          rows={5}
          onChange={(e) => {
            setInput(e.target.value)
            setUnsaved(true)
          }}
          value={input}
          placeholder={t("projects.addANote")}
          style={{ borderRadius: 10, height: 50, marginTop: 20 }}
        />
      </Modal>
    </>
  )
}
