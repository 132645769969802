import { Collapse, Empty, Typography } from "antd";
import React, { useRef } from "react";
import { StoreState } from "src/store/configureStore";
import PerformancePercentageWrapper from "./PerformancePercentageWrapper";
import { useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
const { Panel } = Collapse;
const { Title } = Typography;

export const PerformancePercentageResult = ({ index, property, currentSelectedStage }: any) => {
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  );

  const scrollToRef = useRef<any>(null);
  const { performanceData, getPerformanceStatus } = useSelector(
    (state: StoreState) => state.performance,
  );

  return (
    <>
      <Collapse key={index} defaultActiveKey={["0"]}>
        <Panel
          header={
            <Title style={{ margin: "0", userSelect: "none" }} level={4}>
              {displayNames.properties?.[property]?.name ?? property}
            </Title>
          }
          key={index}
        >
          {Object.keys(performanceData.data_performance_percentage || {})
            .length > 0 &&
          Object.keys(performanceData.model_performance_percentage || {})
            .length > 0 &&
          Object.keys(performanceData.property_type || {}).length > 0 &&
          getPerformanceStatus === AsyncStates.SUCCESS ? (
            <>
              <PerformancePercentageWrapper
                data={
                  performanceData?.data_performance_percentage[property].data
                }
                percentage={
                  performanceData?.data_performance_percentage[property]
                    .perc_data
                }
                modelPercentageData={
                  performanceData?.model_performance_percentage[property].data
                }
                modelPercentage={
                  performanceData?.model_performance_percentage[property]
                    .perc_data
                }
                modelNames={
                  performanceData?.model_performance_percentage[property]
                    .model_name
                }
                type={performanceData?.property_type[property]}
                currentSelectedStage={currentSelectedStage}
              />
            </>
          ) : (
            <Empty
              description={`No Data for ${
                displayNames.properties?.[property]?.name ?? property
              }`}
            />
          )}
        </Panel>
      </Collapse>
      <div ref={scrollToRef}></div>
    </>
  );
};
