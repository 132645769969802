import { Card, Space, Spin, Typography } from "antd";
import { memo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import useTranslate from "src/utils/useTranslate";
import { PerformanceResult } from "./PerformanceResult";


const { Title } = Typography;

export const Performance = memo(() => {
  const [t] = useTranslate();

  const { performanceData, getPerformanceStatus } = useSelector(
    (state: StoreState) => state.performance,
  );

  return (
    <Card
      title={
        <Space direction="vertical">
          <Title level={4}>{t("aiEngine.modelAnalysis.performancePlot")}</Title>
        </Space>
      }
    >
      <Spin spinning={getPerformanceStatus === AsyncStates.LOADING}>
        {getPerformanceStatus === AsyncStates.SUCCESS &&
          Object.keys(performanceData || {}).length > 0 && (
            <div
              id="performanceResult"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Card>
                {Object.keys(
                  performanceData.property_predictions_and_actual || {},
                ).map((property, index) => (
                  <PerformanceResult index={index} property={property} />
                ))}
              </Card>
            </div>
          )}
      </Spin>
    </Card>
  );
})
