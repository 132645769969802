import { handleActions } from "redux-actions";
import { AsyncStates } from "../../constants";
import {
  UploadFileActionTypes,
  FileHistoryActionTypes,
  DeleteFileActionTypes,
  ImportMultipleWorkOrdersActionTypes,
  MappedFilesActionTypes,
  DraftFileUploadActionTypes,
} from "../actions/files";

export type FilesState = {
  draftFile: {
    status: AsyncStates;
    error: string;
    data: any;
  };
  uploadFile: {
    status: AsyncStates;
    error: string;
    data: any;
  };
  fileHistory: {
    status: AsyncStates;
    error: string;
    data: any[];
  };
  deleteFile: {
    status: AsyncStates;
    error: string;
  };
  uploadWorkOrderFile: {
    status: AsyncStates;
    error: string;
  };
  mappedFiles: {
    status: AsyncStates;
    error: string;
    data: any[];
  };
};

const defaultState: FilesState = {
  draftFile: {
    status: AsyncStates.INITIAL,
    error: "",
    data: {},
  },
  uploadFile: {
    status: AsyncStates.INITIAL,
    error: "",
    data: {},
  },
  fileHistory: {
    status: AsyncStates.INITIAL,
    error: "",
    data: [],
  },
  deleteFile: {
    status: AsyncStates.INITIAL,
    error: "",
  },
  uploadWorkOrderFile: {
    status: AsyncStates.INITIAL,
    error: "",
  },
  mappedFiles: {
    status: AsyncStates.INITIAL,
    error: "",
    data: []
  }
};

const filesReducer = handleActions(
  {
    [DraftFileUploadActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        draftFile: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [DraftFileUploadActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        draftFile: {
          ...state.draftFile,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [DraftFileUploadActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      draftFile: {
        ...state.draftFile,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [DraftFileUploadActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      draftFile: {
        status: AsyncStates.INITIAL,
        error: "",
        data: {},
      },
    }),
    [UploadFileActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        uploadFile: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [UploadFileActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [UploadFileActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      uploadFile: {
        ...state.uploadFile,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [UploadFileActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      uploadFile: {
        status: AsyncStates.INITIAL,
        error: "",
        data: {},
      },
    }),
    [MappedFilesActionTypes.REQUEST]: (state) => ({
      ...state,
      mappedFiles: {
        status: AsyncStates.LOADING,
        error: "",
        data: [],
      },
    }),
    [MappedFilesActionTypes.FAILURE]: (state) => ({
      ...state,
      mappedFiles: {
        status: AsyncStates.ERROR,
        error: "",
        data: [],
      },
    }),
    [MappedFilesActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      mappedFiles: {
        status: AsyncStates.SUCCESS,
        error: "",
        data: action.payload,
      },
    }),
    [FileHistoryActionTypes.REQUEST]: (state) => ({
      ...state,
      fileHistory: {
        status: AsyncStates.LOADING,
        error: "",
        data: [],
      },
    }),
    [FileHistoryActionTypes.FAILURE]: (state) => ({
      ...state,
      fileHistory: {
        status: AsyncStates.ERROR,
        error: "",
        data: [],
      },
    }),
    [FileHistoryActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      fileHistory: {
        status: AsyncStates.SUCCESS,
        error: "",
        data: action.payload,
      },
    }),
    [DeleteFileActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteFile: {
        status: AsyncStates.INITIAL,
        error: "",
      },
    }),
    [DeleteFileActionTypes.FAILURE]: (state) => ({
      ...state,
      deleteFile: {
        status: AsyncStates.ERROR,
        error: "",
      },
    }),
    [DeleteFileActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      deleteFile: {
        status: AsyncStates.SUCCESS,
        error: "",
      },
      fileHistory: {
        ...state.fileHistory,
        data: state.fileHistory.data.filter(
          (res: any) => res.file_id !== action.payload
        ),
      },
    }),
    [ImportMultipleWorkOrdersActionTypes.REQUEST]: (state) => ({
      ...state,
      uploadWorkOrderFile: {
        status: AsyncStates.LOADING,
        error: "",
      },
    }),
    [ImportMultipleWorkOrdersActionTypes.SUCCESS]: (state) => ({
      ...state,
      uploadWorkOrderFile: {
        status: AsyncStates.SUCCESS,
        error: "",
      },
    }),
    [ImportMultipleWorkOrdersActionTypes.FAILURE]: (state) => ({
      ...state,
      uploadWorkOrderFile: {
        status: AsyncStates.ERROR,
        error: "",
      },
    }),
    [ImportMultipleWorkOrdersActionTypes.CLEAR]: (state) => ({
      ...state,
      uploadWorkOrderFile: {
        status: AsyncStates.INITIAL,
        error: "",
      },
    }),
  },
  defaultState
);

export default filesReducer;
