import { useEffect, useMemo } from 'react'
import { List, Input, Form, Row, Avatar, Space, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { getNotesRequest, createNoteRequest } from 'src/store/actions/projects'
import { AsyncStates } from 'src/constants'
import { DropdownMenu } from '../DropdDownMenu/DropDownMenu'
import { useMemberName } from 'src/utils/useMemberName'
import dayjs from 'dayjs'
import useTranslate from 'src/utils/useTranslate'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledCard } from 'src/styled_components/StyledCard'
import { LoadingOutlined } from '@ant-design/icons'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
dayjs.extend(relativeTime)

const { Text, Paragraph } = Typography

export const Notes = ({ currentProject }: { currentProject: any }) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const requiredFieldStar = useRequiredFieldStar()

  const { getName } = useMemberName()
  const [form] = Form.useForm()

  const { project_type } = useSelector((state: StoreState) => state.login.loginResponse)
  const { projectNotes, createNoteStatus, getNotesStatus } = useSelector((state: StoreState) => state.projects)
  const { user_id, user_role } = useSelector((state: StoreState) => state.login.loginResponse)
  const notes = useMemo(() => {
    if (getNotesStatus === AsyncStates.SUCCESS) {
      return projectNotes?.reverse()
    } else {
      return []
    }
  }, [projectNotes, getNotesStatus])

  useEffect(() => {
    dispatch(getNotesRequest({ project_id: currentProject }))
  }, [dispatch, currentProject, project_type])

  const addNote = (value: { comment: string }) => {
    form.resetFields()
    dispatch(createNoteRequest({ project_id: currentProject, comment: value.comment }))
  }

  return (
    <StyledCard title={t("projects.notes")}>
      <List
        itemLayout="horizontal"
        dataSource={notes}
        loading={{ spinning: getNotesStatus === AsyncStates.LOADING, indicator: <LoadingOutlined /> }}
        pagination={{ pageSize: 5, showSizeChanger: false }}
        renderItem={(item: any) => (
          <List.Item key={item.created}
            extra={(user_id === item.created_by || user_role === 'admin') && <DropdownMenu type="notes" note_id={item.note_id} id={item.project_id} comment={item?.comment} />}>
            <List.Item.Meta
              avatar={<Avatar>{getName(item.created_by)[0]} </Avatar>}
              title={<Space direction="horizontal">
                <Text>{getName(item.created_by)}</Text>
                <Text type="secondary">{dayjs(item.created).fromNow()}</Text>
              </Space>}
              description={<Space direction="vertical" style={{ width: "100%", paddingRight: 30 }}>
                <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>{item.comment}</Paragraph>
              </Space>}
            />
          </List.Item>
        )}
      />
      <Form form={form} onFinish={addNote} style={{ marginTop: 30 }} requiredMark={false} >
        <Form.Item name="comment" rules={[{ required: true, message: 'Add some text', transform: (value: any) => value?.trim() }]}
        required tooltip={requiredFieldStar}
        >
          <Input.TextArea placeholder={t("projects.addANote")} style={{ height: 50 }} />
        </Form.Item>
        <Row justify="end">
          <StyledButton loading={createNoteStatus === AsyncStates.LOADING} htmlType="submit" type="primary" ghost>{t("common.add")}</StyledButton>
        </Row>
      </Form>
    </StyledCard>
  )
}