import React, { useEffect } from 'react'
import { TeamsUserInfoProps } from './DeactivatedAccountsModal'
import { Modal, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';
import { AsyncStates } from 'src/constants';
import { fetchProjectListRequest } from 'src/store/actions/projects';
import { reactivateAccountRequest } from 'src/store/actions/teams';
import { StyledButton } from 'src/styled_components/StyledButton';
import useTranslate from 'src/utils/useTranslate';


type ReactivateAccountModalProps = {
    reactivateModalVisible: {
        isModalVisible: boolean,
        userData: TeamsUserInfoProps | Record<string, never>;
    },
    setReactivateModalVisible: any
    setShowDeactivatedAccountModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const { Title } = Typography


export const ReactivateAccountModal = ({ setReactivateModalVisible, reactivateModalVisible, setShowDeactivatedAccountModalVisible }: ReactivateAccountModalProps) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()
    const { reactivateAccountStatus } = useSelector((state: StoreState) => state.teams)

    useEffect(() => {
        if (reactivateAccountStatus === AsyncStates.SUCCESS) {
            dispatch(fetchProjectListRequest({ set: true }))
            setReactivateModalVisible({
                isModalVisible: false,
                userData: {}
            })
            setShowDeactivatedAccountModalVisible(false)
        }
    }, [reactivateAccountStatus, setReactivateModalVisible, dispatch, setShowDeactivatedAccountModalVisible])

    const handleCancelAction = () => {
        setReactivateModalVisible({
            isModalVisible: false,
            userData: {}
        })
    }

    const handleReactivateAccounts = () => {
        dispatch(reactivateAccountRequest({ email: reactivateModalVisible.userData.user_email }))
    }
    return (
        <Modal title={null} open={reactivateModalVisible.isModalVisible} footer={null} onCancel={() => handleCancelAction()}>
            <div>
                <div>
                    <Title level={4}>{`${t("form.reactivateConfirm")} ${reactivateModalVisible.userData.user_name} (${reactivateModalVisible.userData.user_email})?`}</Title>
                </div>

                {/* <div>
                    They will be able to access your organisation's workspace.
                </div> */}
            </div>
            <div style={{ display: 'flex', justifyContent: "flex-end", gap: "0.7rem", paddingTop: "1rem" }}>
                <StyledButton onClick={() => handleCancelAction()}>{t("common.cancel")}</StyledButton>
                <StyledButton type="primary" onClick={() => handleReactivateAccounts()} loading={reactivateAccountStatus === AsyncStates.LOADING}>{t("common.confirm")}</StyledButton>
            </div>
        </Modal>
    )
}
