import { handleActions } from 'redux-actions'
import { AsyncStates } from 'src/constants'
import {
  AssetLibraryAddFile,
  AssetLibraryAddFolder,
  AssetLibraryDownload,
  AssetLibraryDelete,
  AssetLibraryGetContent,
  AssetLibraryLocalChanges,
  AssetLibraryMove,
  AssetLibraryPaste,
  AssetLibraryRename,
  AssetLibraryFolderNavigate
} from '../actions/assetLibrary'

export type AssetLibraryState = {
  assetLibraryGetContentResponse: any
  assetLibraryGetContentError: any
  assetLibraryGetContentStatus: AsyncStates

  assetLibraryAddFolderResponse: any
  assetLibraryAddFolderError: any
  assetLibraryAddFolderStatus: AsyncStates

  assetLibraryAddFileResponse: any
  assetLibraryAddFileError: any
  assetLibraryAddFileStatus: AsyncStates

  assetLibraryRenameResponse: any
  assetLibraryRenameError: any
  assetLibraryRenameStatus: AsyncStates

  assetLibraryDeleteResponse: any
  assetLibraryDeleteError: any
  assetLibraryDeleteStatus: AsyncStates

  assetLibraryDownloadResponse: any
  assetLibraryDownloadError: any
  assetLibraryDownloadStatus: AsyncStates

  assetLibraryPasteResponse: any
  assetLibraryPasteError: any
  assetLibraryPasteStatus: AsyncStates

  assetLibraryMoveResponse: any
  assetLibraryMoveError: any
  assetLibraryMoveStatus: AsyncStates

  assetLibraryBreadcrumb: any
  assetLibraryCurrentDirectory: any
  assetLibrarySelectedDirectories: any
  assetLibraryCopiedDirectories: any

  assetLibraryFolderNavigateStatus: AsyncStates
  assetLibraryFolderNavigateResponse: any
  assetLibraryFolderNavigateError: any
  assetLibraryFolderNavigateSelectedDirectory: any
  assetLibraryFolderNavigateBreadcrumb: any
  assetLibraryFolderNavigateCurrentDirectory: any
}

const defaultState: AssetLibraryState = {
  assetLibraryGetContentResponse: {
    files: [],
    folders: []
  },
  assetLibraryGetContentError: null,
  assetLibraryGetContentStatus: AsyncStates.INITIAL,

  assetLibraryAddFolderResponse: null,
  assetLibraryAddFolderError: null,
  assetLibraryAddFolderStatus: AsyncStates.INITIAL,

  assetLibraryAddFileResponse: null,
  assetLibraryAddFileError: null,
  assetLibraryAddFileStatus: AsyncStates.INITIAL,

  assetLibraryRenameResponse: null,
  assetLibraryRenameError: null,
  assetLibraryRenameStatus: AsyncStates.INITIAL,

  assetLibraryDeleteResponse: null,
  assetLibraryDeleteError: null,
  assetLibraryDeleteStatus: AsyncStates.INITIAL,

  assetLibraryDownloadResponse: null,
  assetLibraryDownloadError: null,
  assetLibraryDownloadStatus: AsyncStates.INITIAL,

  assetLibraryPasteResponse: null,
  assetLibraryPasteError: null,
  assetLibraryPasteStatus: AsyncStates.INITIAL,

  assetLibraryMoveResponse: null,
  assetLibraryMoveError: null,
  assetLibraryMoveStatus: AsyncStates.INITIAL,

  assetLibraryBreadcrumb: [
    {
      asset_id: 'root',
      name: 'Library'
    }
  ],
  assetLibraryCurrentDirectory: 'root',
  assetLibrarySelectedDirectories: [],
  assetLibraryCopiedDirectories: {
    files: [],
    folders: []
  },

  assetLibraryFolderNavigateStatus: AsyncStates.INITIAL,
  assetLibraryFolderNavigateResponse: {
    files: [],
    folders: []
  },
  assetLibraryFolderNavigateError: null,
  assetLibraryFolderNavigateSelectedDirectory: null,
  assetLibraryFolderNavigateBreadcrumb: [
    {
      asset_id: 'root',
      name: 'Library'
    }
  ],
  assetLibraryFolderNavigateCurrentDirectory: 'root'
}

const assetLibraryReducer = handleActions<AssetLibraryState, any>(
  {
    [AssetLibraryGetContent.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryGetContentStatus: AsyncStates.LOADING
    }),
    [AssetLibraryGetContent.SUCCESS]: (state, action) => {
      let folders: any[] = []
      let files: any[] = []

      action?.payload?.forEach((item: any) => {
        if (item.type === 'folder') {
          folders.push(item)
        } else {
          files.push(item)
        }
      })

      return {
        ...state,
        assetLibraryGetContentStatus: AsyncStates.SUCCESS,
        assetLibraryGetContentResponse: {
          folders,
          files
        }
      }
    },
    [AssetLibraryGetContent.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryGetContentStatus: AsyncStates.ERROR,
      assetLibraryGetContentError: action.payload.error
    }),
    [AssetLibraryGetContent.CLEAR]: (state) => ({
      ...state,
      assetLibraryGetContentStatus: AsyncStates.INITIAL,
      assetLibraryGetContentResponse: null,
      assetLibraryGetContentError: null
    }),

    [AssetLibraryAddFolder.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryAddFolderStatus: AsyncStates.LOADING
    }),
    [AssetLibraryAddFolder.SUCCESS]: (state, action) => {
      const newFolder = action.payload.data
      const type = action.payload.type

      const isBothAreSame =
        state.assetLibraryCurrentDirectory.asset_id ===
        state.assetLibraryFolderNavigateCurrentDirectory.asset_id

      return {
        ...state,
        assetLibraryAddFolderStatus: AsyncStates.SUCCESS,
        assetLibraryAddFolderResponse: action.payload,
        ...(isBothAreSame
          ? {
              assetLibraryGetContentResponse: {
                ...state.assetLibraryGetContentResponse,
                folders: [
                  ...state.assetLibraryGetContentResponse.folders,
                  newFolder
                ]
              },
              assetLibraryFolderNavigateResponse: {
                ...state.assetLibraryFolderNavigateResponse,
                folders: [
                  ...state.assetLibraryFolderNavigateResponse.folders,
                  newFolder
                ]
              }
            }
          : type === 'default'
          ? {
              assetLibraryGetContentResponse: {
                ...state.assetLibraryGetContentResponse,
                folders: [
                  ...state.assetLibraryGetContentResponse.folders,
                  newFolder
                ]
              }
            }
          : type === 'popup'
          ? {
              assetLibraryFolderNavigateResponse: {
                ...state.assetLibraryFolderNavigateResponse,
                folders: [
                  ...state.assetLibraryFolderNavigateResponse.folders,
                  newFolder
                ]
              }
            }
          : {})
      }
    },
    [AssetLibraryAddFolder.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryAddFolderError: action.payload.error,
      assetLibraryAddFolderStatus: AsyncStates.ERROR
    }),
    [AssetLibraryAddFolder.CLEAR]: (state, action) => ({
      ...state,
      assetLibraryAddFolderError: null,
      assetLibraryAddFolderStatus: AsyncStates.INITIAL,
      assetLibraryAddFolderResponse: null
    }),

    [AssetLibraryAddFile.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryAddFileStatus: AsyncStates.LOADING
    }),
    [AssetLibraryAddFile.SUCCESS]: (state, action) => {
      const newFile = action.payload

      return {
        ...state,
        assetLibraryAddFileStatus: AsyncStates.SUCCESS,
        assetLibraryAddFileResponse: action.payload,
        assetLibraryGetContentResponse: {
          ...state.assetLibraryGetContentResponse,
          files: [...state.assetLibraryGetContentResponse.files, newFile]
        }
      }
    },
    [AssetLibraryAddFile.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryAddFileError: action.payload.error,
      assetLibraryAddFileStatus: AsyncStates.ERROR
    }),
    [AssetLibraryAddFile.CLEAR]: (state, action) => ({
      ...state,
      assetLibraryAddFileError: null,
      assetLibraryAddFileStatus: AsyncStates.INITIAL,
      assetLibraryAddFileResponse: null
    }),

    [AssetLibraryRename.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryRenameStatus: AsyncStates.LOADING
    }),
    [AssetLibraryRename.SUCCESS]: (state, action) => {
      const renamedItem = action?.payload?.asset_id

      return {
        ...state,
        assetLibraryRenameStatus: AsyncStates.SUCCESS,
        assetLibraryRenameResponse: action.payload,
        assetLibrarySelectedDirectories: [],
        assetLibraryGetContentResponse: {
          files: state.assetLibraryGetContentResponse?.files?.map(
            (file: any) => {
              if (file.asset_id === renamedItem) {
                return {
                  ...file,
                  name: action.payload.name
                }
              }
              return file
            }
          ),
          folders: state.assetLibraryGetContentResponse?.folders?.map(
            (folder: any) => {
              if (folder.asset_id === renamedItem) {
                return {
                  ...folder,
                  name: action.payload.name
                }
              }
              return folder
            }
          )
        }
      }
    },
    [AssetLibraryRename.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryRenameError: action.payload.error,
      assetLibraryRenameStatus: AsyncStates.ERROR
    }),
    [AssetLibraryRename.CLEAR]: (state, action) => ({
      ...state,
      assetLibraryRenameError: null,
      assetLibraryRenameStatus: AsyncStates.INITIAL,
      assetLibraryRenameResponse: null
    }),

    [AssetLibraryDownload.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryDownloadStatus: AsyncStates.LOADING
    }),
    [AssetLibraryDownload.SUCCESS]: (state, action) => ({
      ...state,
      assetLibraryDownloadStatus: AsyncStates.SUCCESS,
      assetLibraryDownloadResponse: action.payload,
      assetLibrarySelectedDirectories: []
    }),
    [AssetLibraryDownload.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryDownloadError: action.payload.error,
      assetLibraryDownloadStatus: AsyncStates.ERROR
    }),
    [AssetLibraryDownload.CLEAR]: (state, action) => ({
      ...state,
      assetLibraryDownloadError: null,
      assetLibraryDownloadStatus: AsyncStates.INITIAL,
      assetLibraryDownloadResponse: null
    }),

    [AssetLibraryDelete.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryDeleteStatus: AsyncStates.LOADING
    }),
    [AssetLibraryDelete.SUCCESS]: (state, action) => {
      const assetToBeRemoved = action.payload.asset_id
      return {
        ...state,
        assetLibraryDeleteStatus: AsyncStates.SUCCESS,
        assetLibraryDeleteResponse: action.payload,
        assetLibrarySelectedDirectories: [],
        assetLibraryGetContentResponse: {
          files: state.assetLibraryGetContentResponse.files.filter(
            (file: any) => file.asset_id !== assetToBeRemoved
          ),
          folders: state.assetLibraryGetContentResponse.folders.filter(
            (folder: any) => folder.asset_id !== assetToBeRemoved
          )
        }
      }
    },
    [AssetLibraryDelete.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryDeleteError: action.payload.error,
      assetLibraryDeleteStatus: AsyncStates.ERROR
    }),
    [AssetLibraryDelete.CLEAR]: (state, action) => ({
      ...state,
      assetLibraryDeleteError: null,
      assetLibraryDeleteStatus: AsyncStates.INITIAL,
      assetLibraryDeleteResponse: null
    }),

    [AssetLibraryPaste.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryPasteStatus: AsyncStates.LOADING
    }),
    [AssetLibraryPaste.SUCCESS]: (state, action) => ({
      ...state,
      assetLibraryPasteStatus: AsyncStates.SUCCESS,
      assetLibraryPasteResponse: action.payload,
      assetLibrarySelectedDirectories: []
    }),
    [AssetLibraryPaste.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryPasteError: action.payload.error,
      assetLibraryPasteStatus: AsyncStates.ERROR
    }),
    [AssetLibraryPaste.CLEAR]: (state, action) => ({
      ...state,
      assetLibraryPasteError: null,
      assetLibraryPasteStatus: AsyncStates.INITIAL,
      assetLibraryPasteResponse: null
    }),

    [AssetLibraryMove.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryMoveStatus: AsyncStates.LOADING
    }),
    [AssetLibraryMove.SUCCESS]: (state, action) => ({
      ...state,
      assetLibraryMoveStatus: AsyncStates.SUCCESS,
      assetLibraryMoveResponse: action.payload,
      assetLibrarySelectedDirectories: []
    }),
    [AssetLibraryMove.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryMoveError: action.payload.error,
      assetLibraryMoveStatus: AsyncStates.ERROR
    }),
    [AssetLibraryMove.CLEAR]: (state, action) => ({
      ...state,
      assetLibraryMoveError: null,
      assetLibraryMoveStatus: AsyncStates.INITIAL,
      assetLibraryMoveResponse: null
    }),

    [AssetLibraryFolderNavigate.REQUEST]: (state, action) => ({
      ...state,
      assetLibraryFolderNavigateStatus: AsyncStates.LOADING
    }),
    [AssetLibraryFolderNavigate.SUCCESS]: (state, action) => {
      let folders: any[] = []
      let files: any[] = []

      action?.payload?.forEach((item: any) => {
        if (item.type === 'folder') {
          folders.push(item)
        } else {
          files.push(item)
        }
      })

      return {
        ...state,
        assetLibraryFolderNavigateStatus: AsyncStates.SUCCESS,
        assetLibraryFolderNavigateResponse: {
          folders,
          files
        }
      }
    },
    [AssetLibraryFolderNavigate.FAILURE]: (state, action) => ({
      ...state,
      assetLibraryFolderNavigateStatus: AsyncStates.ERROR,
      assetLibraryFolderNavigateError: action.payload.error
    }),
    [AssetLibraryFolderNavigate.CLEAR]: (state) => ({
      ...state,
      assetLibraryFolderNavigateStatus: AsyncStates.INITIAL,
      assetLibraryFolderNavigateResponse: null,
      assetLibraryFolderNavigateError: null,
      assetLibraryFolderNavigateSelectedDirectory: null,
      assetLibraryFolderNavigateBreadcrumb: [
        {
          asset_id: 'root',
          name: 'Library'
        }
      ],
      assetLibraryFolderNavigateCurrentDirectory: 'root'
    }),
    [AssetLibraryFolderNavigate.UPDATE_BREADCRUMB]: (state, action) => {
      const { asset_id } = action.payload

      const indexOfTheDirectory =
        state.assetLibraryFolderNavigateBreadcrumb.findIndex(
          (item: { asset_id: string }) => item.asset_id === asset_id
        )

      if (indexOfTheDirectory !== -1) {
        return {
          ...state,
          assetLibraryFolderNavigateBreadcrumb:
            state.assetLibraryFolderNavigateBreadcrumb.slice(
              0,
              indexOfTheDirectory + 1
            )
        }
      }

      return {
        ...state,
        assetLibraryFolderNavigateBreadcrumb: [
          ...state.assetLibraryFolderNavigateBreadcrumb,
          action.payload
        ]
      }
    },
    [AssetLibraryFolderNavigate.SELECT]: (state, action) => {
      return {
        ...state,
        assetLibraryFolderNavigateSelectedDirectory: action.payload
      }
    },
    [AssetLibraryFolderNavigate.SET_CURRENT_DIRECTORY]: (state, action) => {
      return {
        ...state,
        assetLibraryFolderNavigateCurrentDirectory: action.payload
      }
    },
    [AssetLibraryLocalChanges.SET_BREADCRUMB]: (state, action) => {
      return {
        ...state,
        assetLibraryBreadcrumb: action.payload
      }
    },
    [AssetLibraryLocalChanges.UPDATE_BREADCRUMB]: (state, action) => {
      const { asset_id } = action.payload

      const indexOfTheDirectory = state.assetLibraryBreadcrumb.findIndex(
        (item: { asset_id: string }) => item.asset_id === asset_id
      )

      if (indexOfTheDirectory !== -1) {
        return {
          ...state,
          assetLibraryBreadcrumb: state.assetLibraryBreadcrumb.slice(
            0,
            indexOfTheDirectory + 1
          )
        }
      }

      return {
        ...state,
        assetLibraryBreadcrumb: [
          ...state.assetLibraryBreadcrumb,
          action.payload
        ]
      }
    },
    [AssetLibraryLocalChanges.SET_CURRENT_DIRECTORY]: (state, action) => {
      return {
        ...state,
        assetLibraryCurrentDirectory: action.payload
      }
    },
    [AssetLibraryLocalChanges.SET_SELECTED_DIRECTORIES]: (state, action) => ({
      ...state,
      assetLibrarySelectedDirectories: action.payload
    }),
    [AssetLibraryLocalChanges.COPY_DIRECTORIES]: (state, action) => {
      let foldersTobeCopied: any = []
      let filesTobeCopied: any = []

      state.assetLibrarySelectedDirectories.forEach((asset_id: string) => {
        const folder = state.assetLibraryGetContentResponse.folders.find(
          (folder: any) => folder.asset_id === asset_id
        )
        if (folder) {
          foldersTobeCopied.push({
            ...folder,
            parentId: state.assetLibraryCurrentDirectory
          })
        }
        const file = state.assetLibraryGetContentResponse.files.find(
          (file: any) => file.asset_id === asset_id
        )
        if (file) {
          filesTobeCopied.push({
            ...file,
            parentId: state.assetLibraryCurrentDirectory
          })
        }
      })
      return {
        ...state,
        assetLibraryCopiedDirectories: {
          folders: foldersTobeCopied,
          files: filesTobeCopied
        }
      }
    }
  },
  defaultState
)

export default assetLibraryReducer
