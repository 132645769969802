import React, { useEffect, useState } from "react"
import { Checkbox, Flex, Row, Select, Space, Tooltip, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
import { DisplayNames } from "src/typings"
import { DownSquareFilled } from "@ant-design/icons"
import { antdTheme, AsyncStates } from "src/constants"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import { dataSummaryStreamRequest } from "src/store/actions/dataSummary"

const { Text } = Typography
const { Option } = Select

type P = {
	names: DisplayNames
	getDisplayName: (value: string) => string
}

export const FilterSection = ({
	names,
	getDisplayName,
}: P) => {
	const [t] = useTranslate();
	const dispatch = useDispatch();
	const { dataSummaryFilters: options, correlationStatus } = useSelector((state: StoreState) => state.dataSummary);
	const [selectedIngredients, setSelectedIngredients] = useState<string[]>([])
	const [selectedProperties, setSelectedProperties] = useState<string[]>([])
	const [selectedProcessing, setSelectedProcessing] = useState<string[]>([])
	const [selectedCosting, setSelectedCosting] = useState<string[]>([])
	const [error, setError] = useState<string>();

	useEffect(() => {
		if (correlationStatus.apiStatus === AsyncStates.ERROR && correlationStatus.errorMessage) setError(correlationStatus.errorMessage);
		else setError(undefined);
	}, [correlationStatus]);

	const getResults = () => {
		const totalLength = ((selectedIngredients?.length || 0) + (selectedProperties?.length || 0) + (selectedProcessing?.length || 0) + (selectedCosting?.length || 0))

		if (totalLength > 50) {
			setError("Please select less than 50 ingredients, processing, and properties combined");
			return;
		}

		if (totalLength === 0) {
			setError("Please select atlease 1 option");
			return;
		}

		dispatch(dataSummaryStreamRequest({
			type: "query",
			options: {
				ingredients: selectedIngredients || [],
				properties: selectedProperties || [],
				processing: selectedProcessing || [],
				costing: selectedCosting || []
			}
		}));
	}

	if (Object.keys(options).length === 0) return null;

	return (
		<Row
			style={{
				marginTop: "1rem",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<Space direction="vertical">
				<Text>{t("inventory.filterBy")} :</Text>

				<Tooltip
					title={
						Object.keys(options?.ingredients || {})?.length === 0
							? t("graphs.heatmap.noIngredientsData")
							: ""
					}
				>
					<Select
						placeholder={t("common.selectIngredients")}
						mode={"multiple"}
						disabled={Object.keys(options?.ingredients || {})?.length === 0}
						style={{ width: 500 }}
						optionFilterProp="children"
						value={selectedIngredients}
						allowClear
						dropdownRender={(menu) => {
							const ingredientsData = Object.keys(options?.ingredients || {}).map(key => key);

							const checked =
								ingredientsData.length === selectedIngredients.length

							return (
								<>
									<Checkbox
										style={{ padding: 10 }}
										checked={checked}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedIngredients(ingredientsData)
											} else {
												setSelectedIngredients([])
											}
										}}
									>
										{t("common.selectAllIngredients")}
									</Checkbox>
									{menu}
								</>
							)
						}}
						onChange={(e) => setSelectedIngredients(e)}
						suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#2980B9' }} />}
					>
						{Object.entries(options?.ingredients || {}).map(([key, value]) => (
							<Option value={key} key={key}>
								{value ?? names.ingredients?.[key]?.name ?? getDisplayName(key)}
							</Option>
						))}
					</Select>
				</Tooltip>

				<Tooltip
					title={
						Object.keys(options?.processing || {})?.length === 0
							? t("graphs.heatmap.noProcessingData")
							: ""
					}
				>
					<Select
						placeholder={t("common.selectProcessings")}
						mode={"multiple"}
						disabled={Object.keys(options?.processing || {})?.length === 0}
						optionFilterProp="children"
						style={{ width: 500 }}
						value={selectedProcessing}
						allowClear
						suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#633974' }} />}
						dropdownRender={(menu) => {
							const processingData = Object.keys(options?.processing || {}).map(key => key);
							const checked =
								processingData.length === selectedProcessing.length

							return (
								<>
									<Checkbox
										style={{ padding: 10 }}
										checked={checked}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedProcessing(processingData)
											} else {
												setSelectedProcessing([])
											}
										}}
									>
										{t("common.selectAllProcessings")}
									</Checkbox>
									{menu}
								</>
							)
						}}
						onChange={(e) => {
							setSelectedProcessing(e)
						}}
					>
						{Object.entries(options?.processing || {}).map(([key, value]) => (
							<Option value={key} key={key}>
								{value ?? names.processing?.[key]?.name}
							</Option>
						))}
					</Select>
				</Tooltip>

				<Tooltip
					title={
						Object.keys(options?.properties || {}).length === 0
							? t("graphs.heatmap.noProperties")
							: ""
					}
				>
					<Select
						placeholder={t("common.selectProperties")}
						mode={"multiple"}
						disabled={Object.keys(options?.properties || {}).length === 0}
						style={{ width: 500 }}
						optionFilterProp="children"
						value={selectedProperties}
						allowClear
						suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#F39C12' }} />}
						dropdownRender={(menu) => {
							const propertiesData = Object.keys(options?.properties || {}).map(key => key);
							const checked =
								propertiesData.length === selectedProperties.length

							return (
								<>
									<Checkbox
										style={{ padding: 10 }}
										checked={checked}
										onChange={(e: any) => {
											if (e.target.checked) {
												setSelectedProperties(propertiesData)
											} else {
												setSelectedProperties([])
											}
										}}
									>
										{t("common.selectAllProperties")}
									</Checkbox>
									{menu}
								</>
							)
						}}
						onChange={(e: any) => {
							setSelectedProperties(e)
						}}
					>
						{Object.entries(options?.properties || {}).map(([key, value]) => (
							<Option value={key} key={key}>
								{value ?? names.properties?.[key]?.name}
							</Option>
						))}
					</Select>
				</Tooltip>

				{Object.keys(options?.costing || {})?.length > 0 &&
					<Select
						placeholder={t("graphs.heatmap.selectCosting")}
						mode={"multiple"}
						optionFilterProp="children"
						style={{ width: 500 }}
						value={selectedCosting}
						allowClear
						suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#1a9850' }} />}
						dropdownRender={(menu) => {
							const costingData = Object.keys(options?.costing || {}).map(key => key);
							const checked = costingData.length === selectedCosting.length

							return (
								<>
									<Checkbox
										style={{ padding: 10 }}
										checked={checked}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedCosting(costingData)
											} else {
												setSelectedCosting([])
											}
										}}
									>
										{t("graphs.heatmap.selectAllCosting")}
									</Checkbox>
									{menu}
								</>
							)
						}}
						onChange={(e) => {
							setSelectedCosting(e)
						}}
					>
						{Object.entries(options?.costing || {}).map(([key, value]) => (
							<Option value={key} key={key}>
								{value}
							</Option>
						))}
					</Select>
				}
				<Flex justify="center">
					<StyledButton
						type="primary"
						loading={correlationStatus.apiStatus === AsyncStates.LOADING}
						onClick={getResults}
					>
						{t("feedback.form.submit")}
					</StyledButton>
				</Flex>
				<Flex justify="center">
					{error && <Text type="danger">{error}</Text>}
				</Flex>
			</Space>
		</Row >
	)
}
