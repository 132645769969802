import {
  // Button,
  // Dropdown,
  // MenuProps,
  // Space,
  Table,
  Tabs,
  Typography,
  // message,
} from "antd";
import { InventoryProperty } from "../types";
// import {
//   DeleteOutlined,
//   EditOutlined,
//   MoreOutlined,
//   PlusOutlined,
// } from "@ant-design/icons";
// import { useEffect, useState } from "react";
// import { InventoryPropertyModal } from "./InventoryPropertyModal";
// import { InventoryMethodsTable } from "./InventoryMethodsTable";
// import {
//   deleteInventoryPropertyClear,
//   deleteInventoryPropertyRequest,
//   getIngredientDetailsSuccess,
// } from "src/store/actions/inventoryV2";
// import { AsyncStates } from "src/constants";
// import DeleteConfirmationModal from "src/components/DeleteConfirmationModal";
import { ColumnsType } from "antd/es/table";
import useTranslate from "src/utils/useTranslate";
import { useValue } from "src/utils/useValue";

const { Title } = Typography;

export const InventoryFormulationsTable = ({ ingredient }: any) => {
  // const dispatch = useDispatch();
  const [t] = useTranslate();
  const { getValue } = useValue()
  // const [mode, setMode] = useState<"create" | "edit">("create");
  // const [propertyModalOpen, setPropertyModalOpen] = useState<boolean>(false);

  // const [selectedProperty, setSelectedProperty] = useState<
  //   InventoryProperty | undefined
  // >(undefined);

  // const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const columns: ColumnsType<InventoryProperty> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("common.category"),
      dataIndex: "category",
      key: "category",
      render: (text: any, record: any) => {
        return record?.category_name ?? record.category
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    // {
    //   title: "Is Range",
    //   dataIndex: "has_range",
    //   key: "has_range",
    //   render: (text, record) => <>{record.has_range ? "Yes" : "No"}</>,
    // },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any) => getValue(text),
    },
    // {
    //   title: "Max Value",
    //   dataIndex: "value_max",
    //   key: "value_max",
    // },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   fixed: "right" as any,
    //   align: "center" as any,
    //   width: 200,
    //   render: (text, record) => (
    //     <Dropdown menu={menuProps(record)} placement="bottomRight">
    //       <StyledButton size="small" icon={<MoreOutlined />} />
    //     </Dropdown>
    //   ),
    // },
  ];

  // const menuItems: MenuProps["items"] = [
  //   {
  //     label: "Edit",
  //     key: "edit",
  //     icon: <EditOutlined />,
  //   },
  //   {
  //     label: "Delete",
  //     key: "delete",
  //     icon: <DeleteOutlined />,
  //     danger: true,
  //   },
  // ];

  // const handleMenuClick = (e: any, record: InventoryProperty) => {
  //   switch (e.key) {
  //     case "edit":
  //       setMode("edit");
  //       setSelectedProperty(record);
  //       setPropertyModalOpen(true);
  //       break;
  //     case "delete":
  //       setSelectedProperty(record);
  //       setDeleteModalVisible(true);
  //       break;
  //   }
  // };

  // const menuProps = (record: any) => {
  //   return {
  //     items: menuItems,
  //     onClick: (e: any) => handleMenuClick(e, record),
  //   };
  // };

  // useEffect(() => {
  //   if (deletePropertyResponse.status === AsyncStates.SUCCESS) {
  //     dispatch(
  //       getIngredientDetailsSuccess({
  //         ...ingredient,
  //         properties: ingredient.properties.filter(
  //           (property: InventoryProperty) =>
  //             property.inventory_property_id !==
  //             selectedProperty?.inventory_property_id
  //         ),
  //       })
  //     );
  //     message.success(`Ingredient property deleted!`);
  //     dispatch(deleteInventoryPropertyClear());
  //     setDeleteModalVisible(false);
  //     setSelectedProperty(undefined);
  //   }
  // }, [
  //   deletePropertyResponse,
  //   dispatch,
  //   ingredient,
  //   selectedProperty?.inventory_property_id,
  // ]);

  return (
    <>
      <Title style={{ margin: 0 }} level={5}>{t("common.formulations")}</Title>
      <Tabs
        items={[{ label: t("common.ingredients"), key: "formulation_lookups" }, { label: t("common.processing"), key: "processing" }, { label: t("common.characterizations"), key: "characterizations" }].map((tab) => {
          return {
            label: tab.label,
            key: tab.key,
            children: <Table
              size="small"
              columns={columns}
              dataSource={[...ingredient[tab.key]]}
              rowKey={(record) => record.inventory_property_id}
              pagination={false}
            />
          };
        })}
      />
    </>
  );
};
