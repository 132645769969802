import { AsyncStates } from "src/constants";
import {
  CreateCustomFieldActionTypes,
  DeleteCustomFieldActionTypes,
  GetCustomFieldsActionTypes,
  UpdateCustomFieldActionTypes,
} from "../actions/customFields";
import { STORE_ACTION_INITIAL_DATA } from "../constants";
import { IStoreActionState } from "../interfaces";
import { handleActions } from "redux-actions";

export type CustomFieldsState = {
  createCustomField: IStoreActionState;
  getCustomFields: IStoreActionState;
  deleteCustomField: IStoreActionState;
  updateCustomField: IStoreActionState;
};

const defaultState: CustomFieldsState = {
  createCustomField: STORE_ACTION_INITIAL_DATA,
  getCustomFields: { ...STORE_ACTION_INITIAL_DATA, data: [] },
  deleteCustomField: STORE_ACTION_INITIAL_DATA,
  updateCustomField: STORE_ACTION_INITIAL_DATA,
};

const customFieldsReducer = handleActions(
  {
    // Create
    [CreateCustomFieldActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        createCustomField: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [CreateCustomFieldActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createCustomField: {
          ...state.createCustomField,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        getCustomFields: {
          ...state.getCustomFields,
          data: action.payload.fields,
        },
      };
    },
    [CreateCustomFieldActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      createCustomField: {
        ...state.createCustomField,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [CreateCustomFieldActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      createCustomField: STORE_ACTION_INITIAL_DATA,
    }),

    // Get
    [GetCustomFieldsActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getCustomFields: {
          data: [],
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [GetCustomFieldsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getCustomFields: {
          ...state.getCustomFields,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetCustomFieldsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getCustomFields: {
        ...state.getCustomFields,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetCustomFieldsActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getCustomFields: STORE_ACTION_INITIAL_DATA,
    }),

    // Delete
    [DeleteCustomFieldActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        deleteCustomField: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [DeleteCustomFieldActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        deleteCustomField: {
          ...state.deleteCustomField,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [DeleteCustomFieldActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      deleteCustomField: {
        ...state.deleteCustomField,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [DeleteCustomFieldActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      deleteCustomField: STORE_ACTION_INITIAL_DATA,
    }),

    // Update
    [UpdateCustomFieldActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        updateCustomField: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [UpdateCustomFieldActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateCustomField: {
          ...state.updateCustomField,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [UpdateCustomFieldActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      updateCustomField: {
        ...state.updateCustomField,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [UpdateCustomFieldActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      updateCustomField: STORE_ACTION_INITIAL_DATA,
    }),
  },
  defaultState
);

export default customFieldsReducer;
