import { Slider, Switch } from "antd";
import { memo, useEffect, useState } from "react";

type TProps = {
  minWidth: number;
  maxWidth: number;
  minLength: number;
  maxLength: number;
  showAllMasks: boolean;
  showOriginalImage: boolean;
  setWidthRangeThreshold: React.Dispatch<React.SetStateAction<number[]>>;
  setLengthRangeThreshold: React.Dispatch<React.SetStateAction<number[]>>;
  setShowAllMasks: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOriginalImage: React.Dispatch<React.SetStateAction<boolean>>;
  showBBox: boolean;
  setShowBBox: React.Dispatch<React.SetStateAction<boolean>>;
};

const FiberAnalysisDetailsSidebar = ({
  minWidth,
  maxWidth,
  minLength,
  maxLength,
  showAllMasks,
  showOriginalImage,
  setWidthRangeThreshold,
  setLengthRangeThreshold,
  setShowAllMasks,
  setShowOriginalImage,
  showBBox,
  setShowBBox,
}: TProps) => {
  const [widthRange, setWidthRange] = useState<any[]>([]);
  const [lengthRange, setLengthRange] = useState<any[]>([]);

  useEffect(() => {
    setWidthRange([minWidth, maxWidth]);
    setLengthRange([minLength, maxLength]);
    setWidthRangeThreshold([minWidth, maxWidth]);
    setLengthRangeThreshold([minLength, maxLength]);
  }, [
    maxLength,
    maxWidth,
    minLength,
    minWidth,
    setLengthRangeThreshold,
    setWidthRangeThreshold,
  ]);

  return (
    <div className="sidebar" style={{ width: "240px" }}>
      <div className="content" style={{ width: "240px" }}>
        <div className="option">
          <div className="label">Show Original Image</div>
          <Switch
            checkedChildren={"Show"}
            value={showOriginalImage}
            unCheckedChildren={"Hide"}
            onChange={(val: boolean) => {
              setShowOriginalImage(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>

        <div className="option">
          <div className="label">Show All Masks</div>
          <Switch
            checkedChildren={"Show"}
            value={showAllMasks}
            unCheckedChildren={"Hide"}
            onChange={(val: boolean) => {
              setShowAllMasks(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>

        <div className="option">
          <div className="label">Show Bounding Box</div>
          <Switch
            checkedChildren={"Show"}
            value={showBBox}
            unCheckedChildren={"Hide"}
            onChange={(val: boolean) => {
              setShowBBox(val);
            }}
            style={{
              outline: "none",
            }}
          />
        </div>
        <div className="option">
          <div className="label">Width</div>
          <Slider
            range
            min={minWidth}
            max={maxWidth}
            value={widthRange}
            onChange={(val) => setWidthRange(val)}
            onChangeComplete={(val) => setWidthRangeThreshold(val)}
            style={{ width: 200 }}
            rootClassName="sem-slider"
          />
        </div>
        <div className="option">
          <div className="label">Length</div>
          <Slider
            range
            min={minLength}
            max={maxLength}
            value={lengthRange}
            onChange={(val) => setLengthRange(val)}
            onChangeComplete={(val) => setLengthRangeThreshold(val)}
            style={{ width: 200 }}
            rootClassName="sem-slider"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(FiberAnalysisDetailsSidebar);
