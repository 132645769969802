import { createActions } from "redux-actions";
import { SettingsUpdatePayload, SettingsResponse } from "../../services/settings/interface"

export enum SettingsActionTypes {
    REQUEST = "SETTINGS_REQUEST",
    SUCCESS = "SETTINGS_SUCCESS",
    FAILURE = "SETTINGS_FAILURE",
    UPDATE_REQUEST = "SETTINGS_UPDATE_REQUEST",
    UPDATE_SUCCESS = "SETTINGS_UPDATE_SUCCESS",
    UPDATE_FAILURE = "SETTINGS_UPDATE_FAILURE",
    UPDATE_CLEAR = "SETTINGS_UPDATE_CLEAR",
}


export enum BillingDetailsActionTypes {
    REQUEST = "BILLING_DETAILS_REQUEST",
    SUCCESS = "BILLING_DETAILS_SUCCESS",
    FAILURE = "BILLING_DETAILS_FAILURE",
    CLEAR = "BILLING_DETAILS_CLEAR"
}

export enum AddLabsLocationActionTypes {
    REQUEST = "ADD_LABS_LOCATION_REQUEST",
    SUCCESS = "ADD_LABS_LOCATION_SUCCESS",
    FAILURE = "ADD_LABS_LOCATION_FAILURE",
    CLEAR = "ADD_LABS_LOCATION_CLEAR"
}

export enum EditLabsLocationActionTypes {
    REQUEST = "EDIT_LABS_LOCATION_REQUEST",
    SUCCESS = "EDIT_LABS_LOCATION_SUCCESS",
    FAILURE = "EDIT_LABS_LOCATION_FAILURE",
    CLEAR = "EDIT_LABS_LOCATION_CLEAR"
}

export enum FetchLabsLocationActionTypes {
    REQUEST = "FETCH_LABS_LOCATION_REQUEST",
    SUCCESS = "FETCH_LABS_LOCATION_SUCCESS",
    FAILURE = "FETCH_LABS_LOCATION_FAILURE",
    CLEAR = "FETCH_LABS_LOCATION_CLEAR"
}

export enum UpdatePaymentMethodActionTypes {
    REQUEST = "UPDATE_PAYMENT_METHOD_REQUEST",
    SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS",
    FAILURE = "UPDATE_PAYMENT_METHOD_FAILURE",
    CLEAR = "UPDATE_PAYMENT_METHOD_CLEAR"
}

export enum UpdateBillingDetailsActionTypes {
    REQUEST = "UPDATE_BILLING_DETAILS_REQUEST",
    SUCCESS = "UPDATE_BILLING_DETAILS_SUCCESS",
    FAILURE = "UPDATE_BILLING_DETAILS_FAILURE",
    CLEAR = "UPDATE_BILLING_DETAILS_CLEAR"
}


export enum UpdateSubscriptionActionTypes {
    REQUEST = "UPDATE_SUBSCRIPTION_REQUEST",
    SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS",
    FAILURE = "UPDATE_SUBSCRIPTION_FAILURE",
    CLEAR = "UPDATE_SUBSCRIPTION_CLEAR"
}


export enum UpdateNumericalCodeActionTypes {
    REQUEST = "UPDATE_NUMERICAL_CODE_REQUEST",
    SUCCESS = "UPDATE_NUMERICAL_CODE_SUCCESS",
    FAILURE = "UPDATE_NUMERICAL_CODE_FAILURE",
}

export const {
    settingsRequest,
    settingsSuccess,
    settingsFailure,
    settingsUpdateRequest,
    settingsUpdateSuccess,
    settingsUpdateFailure,
    settingsUpdateClear,
    billingDetailsRequest,
    billingDetailsSuccess,
    billingDetailsFailure,
    billingDetailsClear,
    updatePaymentMethodRequest,
    updatePaymentMethodSuccess,
    updatePaymentMethodFailure,
    updatePaymentMethodClear,
    updateBillingDetailsRequest,
    updateBillingDetailsSuccess,
    updateBillingDetailsFailure,
    updateBillingDetailsClear,
    updateSubscriptionRequest,
    updateSubscriptionSuccess,
    updateSubscriptionFailure,
    updateSubscriptionClear,
    addLabsLocationRequest,
    addLabsLocationSuccess,
    addLabsLocationFailure,
    addLabsLocationClear,
    editLabsLocationRequest,
    editLabsLocationSuccess,
    editLabsLocationFailure,
    editLabsLocationClear,
    fetchLabsLocationRequest,
    fetchLabsLocationSuccess,
    fetchLabsLocationFailure,
    fetchLabsLocationClear,
    updateNumericalCodeRequest,
    updateNumericalCodeSuccess,
    updateNumericalCodeFailure,
} = createActions({
    [SettingsActionTypes.REQUEST]: () => { },
    [SettingsActionTypes.SUCCESS]: (response: SettingsResponse) => response,
    [SettingsActionTypes.FAILURE]: (error: string) => ({ error }),
    [SettingsActionTypes.UPDATE_REQUEST]: (payload: SettingsUpdatePayload) => payload,
    [SettingsActionTypes.UPDATE_SUCCESS]: (response: string) => response,
    [SettingsActionTypes.UPDATE_FAILURE]: (error: string) => ({ error }),
    [SettingsActionTypes.UPDATE_CLEAR]: () => { },
    [BillingDetailsActionTypes.REQUEST]: () => { },
    [BillingDetailsActionTypes.SUCCESS]: (response) => response,
    [BillingDetailsActionTypes.FAILURE]: (error) => ({ error }),
    [BillingDetailsActionTypes.CLEAR]: () => { },
    [UpdatePaymentMethodActionTypes.REQUEST]: (payload) => payload,
    [UpdatePaymentMethodActionTypes.SUCCESS]: (response) => response,
    [UpdatePaymentMethodActionTypes.FAILURE]: (error) => ({ error }),
    [UpdatePaymentMethodActionTypes.CLEAR]: () => { },
    [UpdateBillingDetailsActionTypes.REQUEST]: (payload) => payload,
    [UpdateBillingDetailsActionTypes.SUCCESS]: (response) => response,
    [UpdateBillingDetailsActionTypes.FAILURE]: (error) => ({ error }),
    [UpdateBillingDetailsActionTypes.CLEAR]: () => { },
    [UpdateSubscriptionActionTypes.REQUEST]: (payload) => payload,
    [UpdateSubscriptionActionTypes.SUCCESS]: (response) => response,
    [UpdateSubscriptionActionTypes.FAILURE]: (error) => ({ error }),
    [UpdateSubscriptionActionTypes.CLEAR]: () => { },
    [AddLabsLocationActionTypes.REQUEST]: (payload) => payload,
    [AddLabsLocationActionTypes.SUCCESS]: (response) => response,
    [AddLabsLocationActionTypes.FAILURE]: (error: string) => ({ error }),
    [AddLabsLocationActionTypes.CLEAR]: () => { },
    [EditLabsLocationActionTypes.REQUEST]: (payload) => payload,
    [EditLabsLocationActionTypes.SUCCESS]: (response) => response,
    [EditLabsLocationActionTypes.FAILURE]: (error: string) => ({ error }),
    [EditLabsLocationActionTypes.CLEAR]: () => { },
    [FetchLabsLocationActionTypes.REQUEST]: (payload) => payload,
    [FetchLabsLocationActionTypes.SUCCESS]: (response) => response,
    [FetchLabsLocationActionTypes.FAILURE]: (error: string) => ({ error }),
    [FetchLabsLocationActionTypes.CLEAR]: () => { },
    [UpdateNumericalCodeActionTypes.REQUEST]: (payload) => payload,
    [UpdateNumericalCodeActionTypes.SUCCESS]: (response) => response,
    [UpdateNumericalCodeActionTypes.FAILURE]: () => { },

})

