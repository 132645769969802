
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Vollfaktoriell`}</h1>
    <p>{`Ein Versuchsdesign mit allen möglichen Kombinationen aller Eingangsfaktoren wird als `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Faktorieller_Versuch"
      }}>{`Vollfaktorieller Versuch`}</a>{` bezeichnet.`}</p>
    <p>{`Die experimentellen Designpunkte in einem Vollfaktoriellen Design sind die Eckpunkte eines Hyperwürfels im n-dimensionalen Designraum, der durch die "Stufen" der einzelnen Faktoren definiert wird. Diese experimentellen Punkte werden auch als Faktorialpunkte bezeichnet. Bei drei Faktoren mit je vier Stufen pro Faktor, bei Berücksichtigung eines Vollfaktoriellen Designs, müssen insgesamt 4³ (64) Experimente durchgeführt werden.`}</p>
    <p><strong parentName="p">{`Anwendungsfälle:`}</strong></p>
    <ul>
      <li parentName="ul">{`Faktorielle Experimente können verwendet werden, wenn es mehr als zwei Stufen für jeden Faktor gibt. Die Anzahl der erforderlichen Experimente für faktorielle Designs mit drei oder mehr Stufen wird jedoch erheblich größer sein als für ihre zweistufigen Gegenstücke.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;