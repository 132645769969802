import { Collapse, Form } from 'antd';
import StageAndParamFilter from './StageAndParamFilter'
import { StyledButton } from 'src/styled_components/StyledButton'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'
import useTranslate from 'src/utils/useTranslate';
import { multistageHeatmapRequest } from 'src/store/actions/multistageHeatmap';
import { useEffect, useState } from 'react';

const FilterForm = () => {
  // Other utility hooks
  const [t] = useTranslate()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  // States
  const [openPanel, setOpenPanel] = useState<string | string[]>('')
  const [submitAllowed, setSubmitAllowed] = useState<boolean>(true)

  // Selectors
  const { multistageHeatmapOptionsStatus, multistageHeatmapOptionsResponse, multistageHeatmapResultStatus } =
    useSelector((state: StoreState) => state.multiStageHeatmap)

  // Effects
  useEffect(() => {
    if (multistageHeatmapOptionsStatus === AsyncStates.SUCCESS) {
      form.setFieldsValue({
        'initial_stage-ingredients': multistageHeatmapOptionsResponse?.initial_stage?.ingredients,
        'initial_stage-processing': multistageHeatmapOptionsResponse?.initial_stage?.processing,
        'initial_stage-properties': multistageHeatmapOptionsResponse?.initial_stage?.properties,
        'final_stage-ingredients': multistageHeatmapOptionsResponse?.final_stage?.ingredients,
        'final_stage-processing': multistageHeatmapOptionsResponse?.final_stage?.processing,
        'final_stage-properties': multistageHeatmapOptionsResponse?.final_stage?.properties,
      })
    }
  }, [form, multistageHeatmapOptionsResponse, multistageHeatmapOptionsStatus])

  useEffect(() => {
    if (multistageHeatmapOptionsStatus === AsyncStates.SUCCESS) {
      setOpenPanel('1')
    } else {
      setOpenPanel('')
    }
  }, [multistageHeatmapOptionsStatus])

  // Helper functions

  const onSubmit = (values: any) => {
    const initial_stage: { [key: string]: {} } = {}
    const final_stage: { [key: string]: {} } = {}
    Object.entries(values).forEach(([key, value]: [string, any]) => {
      if (key.includes('initial_stage') && key !== 'initial_stage') {
        initial_stage[key.split('-')[1]] = value
      } else if (key.includes('final_stage') && key !== 'final_stage') {
        final_stage[key.split('-')[1]] = value
      }
    })
    const heatmapResultPayload = {
      ...multistageHeatmapOptionsResponse,
      type: 'query',
      initial_stage: {
        ...multistageHeatmapOptionsResponse.initial_stage,
        ...initial_stage,
      },
      final_stage: {
        ...multistageHeatmapOptionsResponse.final_stage,
        ...final_stage,
      }
    }
    dispatch(multistageHeatmapRequest(heatmapResultPayload))
  }

  return (
    <Collapse activeKey={openPanel} onChange={(key)=>setOpenPanel(key)} >
      <Collapse.Panel header={t("common.filters")} key="1" collapsible={multistageHeatmapOptionsStatus === AsyncStates.SUCCESS ? 'header' : 'disabled'} >
        {multistageHeatmapOptionsStatus === AsyncStates.SUCCESS ? <Form form={form} onFinish={onSubmit} style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign='left'
          requiredMark={false}
        >
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            width: 'calc(100% - 20px)',
          }}>
            <StageAndParamFilter identifier={'initial_stage'} form={form} setSubmitAllowed={setSubmitAllowed} />
            <StageAndParamFilter identifier={'final_stage'} form={form} setSubmitAllowed={setSubmitAllowed} />
          </div>

          <Form.Item style={{ width: "100%" }}>
            <StyledButton
              htmlType="submit"
              type="primary"
              loading={multistageHeatmapResultStatus === AsyncStates.LOADING}
              disabled={!submitAllowed}
            >
              {`${t("common.generate")} ${t("common.heatmap")}`}
            </StyledButton>
          </Form.Item>
        </Form> : null}
      </Collapse.Panel>
    </Collapse>
  )
}

export default FilterForm
