import { DeleteOutlined } from '@ant-design/icons'
import React from 'react'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'

const StyledDeleteIcon = React.forwardRef((
  props: AntdIconProps & React.RefAttributes<HTMLSpanElement>,
  ref: React.Ref<HTMLElement> | undefined
) => {
  return (
    <DeleteOutlined 
      className='delete-icon'
      {...props}
      ref={ref}
     />
  )
})

export default StyledDeleteIcon