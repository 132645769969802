
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"


const parameters = ["test_method", "test_medium", "test_time", "test_temperature", "test_type", "post_cure"]

export const useMethodDetails = () => {

    const methodList = useSelector((state: StoreState) => state.characterizationMethods.methodList)
    const getVariationDetails = useCallback((variationId: string, data: any = {}) => {
        const parameterDesc: any = []
        parameters.forEach((parameter: any) => {
            if (data?.[parameter]?.value !== "" && data?.[parameter]?.value !== undefined && data?.[parameter]?.value !== null) {
                parameterDesc.push(data?.[parameter]?.value)
            }
        })
        const method = methodList.find(method => method.variations.some((variation: any) => variation.variation_id === variationId))
        const variation = method?.variations.find((variation: any) => variation.variation_id === variationId)
        return { variationName: variation?.name, variationDesc: `${parameterDesc.join(", ")} ${!!variation?.description ? `(${variation?.description || ""})` : ""}` }
    }, [methodList])

    return { getVariationDetails }
}