import { Space } from "antd";
import { CommentList } from "../CommentList/CommentList";
import { CommentEditor } from "../CommentEditor";
import { FormOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { Comment } from "@ant-design/compatible";
import { Dropdown } from "./dropdown";
import { Team } from "src/typings";
import { antdTheme } from "src/constants";

export const CommentsSection = () => {
  const [t] = useTranslate();
  const users = useSelector((state: StoreState) => state.teams.data) as Team[];
  const user_id = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const work_order_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.work_order_id
  );

  if (!Boolean(work_order_id)) return null;

  return (
    <StyledCard
      title={
        <Space size={16}>
          <FormOutlined style={{ fontSize: antdTheme.fontSize}} />
          {t("common.comments")}
        </Space>
      }
      extra={
        <Dropdown user_id={user_id} users={users} />
      }
      headStyle={{ padding: "8px 16px" }}
      bodyStyle={{ padding: "0 16px" }}
    >
      <Comment
        style={{
          padding: 10,
          borderRadius: 5,
        }}
        content={<CommentEditor />}
      />
      <CommentList />
    </StyledCard>
  );
};
