import { Form, Input, Space } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { getExplainableAiReportClear, getExplainableAiReportRequest } from 'src/store/actions/explainableAI'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'

type payloadProps = {
    version: number
    property_name: string[]
    filter_names: string[]
    model_types: {
        [key: string]: string[]
    }
    bar_colors?: { [key: string]: string }
    chart_type: string
    formulation_urls?: string[],
    stage_version: string
}

type DownloadReportTypes = {
    data: payloadProps
    type?: string
    setIsModalVisible?: any
}

// explainableAIReportStatus will not be loading it will hold value of type to identify the Component.

export const DownloadReport = ({ data, type, setIsModalVisible }: DownloadReportTypes) => {
    const [t] = useTranslate()
    const [downloadForm] = useForm()
    const { explainableAIReportStatus } = useSelector((state: StoreState) => state.explainableAI)

    const dispatch = useDispatch()

    useEffect(() => {
        if (explainableAIReportStatus === AsyncStates.SUCCESS && type === "individual_report") {
            setIsModalVisible(false)
            downloadForm.resetFields()
            dispatch(getExplainableAiReportClear())
        }
        if (explainableAIReportStatus === AsyncStates.SUCCESS && !type) {
            downloadForm.resetFields()
        }
    }, [downloadForm, explainableAIReportStatus, setIsModalVisible, type, dispatch])

    return (
        <Form
            form={downloadForm}
            layout={type === "individual_report" ? "vertical" : 'inline'}
            name='download_report'
            onFinish={(value) => {
                // passing status to uniquely indentify the type during the loading.
                dispatch(getExplainableAiReportRequest({ ...value, ...data, status: type }))
            }}>
            <Form.Item
                name="report_name"
                initialValue={""}
            >
                <Input placeholder={t("aiEngine.modelAnalysis.enterReportName")} />
            </Form.Item>
            <Form.Item>
                <Space style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
                    {type === "individual_report" &&
                        <StyledButton onClick={() => {
                            setIsModalVisible(false)
                            downloadForm.resetFields()
                        }}>{t("common.cancel")}</StyledButton>
                    }
                    <StyledButton type="primary" htmlType="submit" loading={explainableAIReportStatus === type}>{t('report.downloadReport')}</StyledButton>
                </Space>
            </Form.Item>
        </Form>
    )
}
