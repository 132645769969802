
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Taguchi`}</h1>
    <p>{`Taguchi method is a robust experimental design that seeks to obtain the best combination set of factors/levels with the lowest cost solution to achieve the adequate functional requirements. It consists of several performance determining elements that can provide the necessary contribution needed to enhance the optimization implementation.`}</p>
    <p>{`The popularity of Taguchi method is due to its practicality in reducing the number of experiments (through orthogonal array design) and thereby saving the experimental cost and time. Further the generated insights (such as, signal to noise ratio, parametric contribution plot etc.) identify the unique experimental features and scientific correlations.`}</p>
    <p><strong parentName="p">{`Use Cases:-`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`Formulation optimization,`}</li>
      <li parentName="ul">{`Understanding parametric influence,`}</li>
      <li parentName="ul">{`Mechanical and chemical component design,`}</li>
      <li parentName="ul">{`Process optimisation,`}</li>
      <li parentName="ul">{`Minimization of performance variation.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;