import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Input, message } from 'antd';
import { Box } from '../types';
import { orange } from '@ant-design/colors';
import { boxExampleValue } from '../utils';
import { StyledButton } from "src/styled_components/StyledButton";

type P = {
    box: Box
    setBox: Dispatch<SetStateAction<Box>>
};

const inputStyle = {
    maxWidth: '100px'
}

export const BoxDimensionsForm = ({ setBox, box }: P) => {
    const [dimensions, setDimensions] = useState<Box>({ ...box })

    function handleDimensionChange(e: FormEvent<HTMLInputElement>) {
        const { value, name } = e.currentTarget

        setDimensions({
            ...dimensions,
            [name]: +value
        })
    }

    function onSubmit() {
        if (typeof dimensions.width !== 'number') return message.error('Please provide the width of the box!')
        if (typeof dimensions.length !== 'number') return message.error('Please provide the length of the box!')
        if (typeof dimensions.depth !== 'number') return message.error('Please provide the thickness of the box!')

        if (dimensions.width <= 0) return message.error('Width must be a number greater than 0')
        if (dimensions.length <= 0) return message.error('Length must be a number greater than 0')
        if (dimensions.depth <= 0) return message.error('Depth must be a number greater than 0')

        setBox(dimensions)
    }

    function addExampleBoxDimensions() {
        setDimensions(boxExampleValue)
        setBox(boxExampleValue)
    }

    return (
        <div style={{
            display: 'flex',
            gap: '8px'
        }}>
            <Input name="length" placeholder='5mm' value={dimensions.length} onChange={handleDimensionChange} style={inputStyle} />
            <Input name="width" placeholder='2mm' value={dimensions.width} onChange={handleDimensionChange} style={inputStyle} />
            <Input name="depth" placeholder='5mm' value={dimensions.depth} onChange={handleDimensionChange} style={inputStyle} />
            <StyledButton onClick={onSubmit} type="primary" htmlType='button'>
                Set EPS Box Dimensions
            </StyledButton>
            <StyledButton onClick={addExampleBoxDimensions} type="primary" style={{ backgroundColor: orange.primary }} htmlType='button'>
                Add Example Box Dimensions
            </StyledButton>
        </div>
    )
};
