import { createActions } from "redux-actions"

export enum NotificationsActionTypes {
	REQUEST = "NOTIFICATIONS_REQUEST",
	SUCCESS = "NOTIFICATIONS_SUCCESS",
	FAILURE = "NOTIFICATIONS_FAILURE",
	NOTIFICATION_READ_REQUEST = "NOTIFICATION_READ_REQUEST",
	NOTIFICATION_READ_SUCCESS = "NOTIFICATION_READ_SUCCESS",
	NOTIFICATION_READ_FAILURE = "NOTIFICATION_READ_FAILURE",
	NOTIFICATION_MULTIPLE_READ_REQUEST = "NOTIFICATION_MULTIPLE_READ_REQUEST",
	NOTIFICATION_MULTIPLE_READ_SUCCESS = "NOTIFICATION_MULTIPLE_READ_SUCCESS",
	NOTIFICATION_MULTIPLE_READ_FAILURE = "NOTIFICATION_MULTIPLE_READ_FAILURE",
	NOTIFICATION_MULTIPLE_READ_CLEANUP = "NOTIFICATION_MULTIPLE_READ_CLEANUP",
	NOTIFICATION_ADD = "NOTIFICATION_ADD"
}

export const {
	notificationsRequest,
	notificationsSuccess,
	notificationsFailure,
	notificationReadRequest,
	notificationReadSuccess,
	notificationReadFailure,
	notificationMultipleReadRequest,
	notificationMultipleReadSuccess,
	notificationMultipleReadFailure,
	notificationMultipleReadCleanup,
	notificationAdd
} = createActions({
	[NotificationsActionTypes.REQUEST]: (payload) => payload,
	[NotificationsActionTypes.SUCCESS]: (data) => data,
	[NotificationsActionTypes.FAILURE]: (message) => ({
		error: message,
	}),
	[NotificationsActionTypes.NOTIFICATION_READ_REQUEST]: (payload) => payload,
	[NotificationsActionTypes.NOTIFICATION_READ_SUCCESS]: (data) => data,
	[NotificationsActionTypes.NOTIFICATION_READ_FAILURE]: (message) => ({
		error: message,
	}),
	[NotificationsActionTypes.NOTIFICATION_MULTIPLE_READ_REQUEST]: (payload) =>
		payload,
	[NotificationsActionTypes.NOTIFICATION_MULTIPLE_READ_SUCCESS]: (data) => data,
	[NotificationsActionTypes.NOTIFICATION_MULTIPLE_READ_FAILURE]: ({
		message,
	}) => ({
		error: message,
	}),
	[NotificationsActionTypes.NOTIFICATION_MULTIPLE_READ_CLEANUP]: () => { },
	[NotificationsActionTypes.NOTIFICATION_ADD]: (payload) => payload,
})
