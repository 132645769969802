// @ts-ignore:next-line
import template from "src/assets/templates/SMILES-upload-template.csv";
import {
    Checkbox,
    Col,
    Empty,
    Form,
    Input,
    Row,
    Select,
    Spin,
    Switch,
    Tooltip,
    UploadProps,
    message,
} from "antd";
import "./SmileAnalytics.scss";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import useTranslate from "src/utils/useTranslate";
import { setIsEditing } from "src/store/actions/isEditing";
import { smileSummaryRequest } from "src/store/actions/smileSummary";
import {
    InfoCircleOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
const { Option } = Select;

const ACCEPTABLE_FILE_TYPES = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    ".csv",
];

type TProps = {
    setSmileRepostDownloadLink: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
    setHasHeatmapData: React.Dispatch<React.SetStateAction<boolean>>;
};

const SmileAnalyticsForm = ({
    setSmileRepostDownloadLink,
    setHasHeatmapData,
}: TProps) => {
    const { current: currentProject } = useSelector(
        (state: StoreState) => state.projects
    );
    const workOrders = useSelector((state: StoreState) => state.workOrders.data);
    const workOrdersStatus = useSelector(
        (state: StoreState) => state.workOrders.status
    );
    const [selectedWo, setSelectedWo] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [file, setFile] = useState<any>();
    const [mode, setMode] = useState<"WO" | "FILE">("WO");
    const [t] = useTranslate();
    const [analyticsForm] = Form.useForm();
    const dispatch = useDispatch();

    const handleFormValueChange = useCallback(() => {
        setSmileRepostDownloadLink(undefined);
        setHasHeatmapData(false);

        // Check if form populated
        const { work_order_ids } = analyticsForm.getFieldsValue();

        if (Array.isArray(work_order_ids) && work_order_ids.length > 0)
            dispatch(setIsEditing(true));
        else dispatch(setIsEditing(false));
    }, [
        analyticsForm,
        dispatch,
        setHasHeatmapData,
        setSmileRepostDownloadLink,
    ]);

    const resetForm = useCallback(() => {
        analyticsForm.resetFields();
        setSelectedWo([]);
        setSelectedStages([]);
        setFile(undefined);
    }, [analyticsForm]);

    useEffect(() => {
        resetForm();
    }, [currentProject, resetForm, mode]);

    useEffect(() => {
        handleFormValueChange();
    }, [selectedWo, selectedStages, handleFormValueChange]);

    const stagesOptions = useMemo(
        () =>
            workOrders
                .filter((wo) => selectedWo.includes(wo.work_order_id))
                .map((workOrder: any) => ({
                    label: workOrder.work_order_name,
                    options: workOrder.stages.map((stage: any, index: number) => ({
                        label: (
                            <span>
                                {stage?.actual_stage_name
                                    ? stage?.actual_stage_name
                                    : `Stage: ${index + 1}`}{" "}
                                -{" "}
                                <span style={{ color: "gray" }}>
                                    {workOrder.work_order_name}
                                </span>
                            </span>
                        ),
                        value: stage.identifier,
                    })),
                })),
        [selectedWo, workOrders]
    );

    const handleFormSubmit = () => {
        const selectedWorkOrders = workOrders.filter((wo) =>
            selectedWo.includes(wo.work_order_id)
        );
        const experiment_ids = selectedWorkOrders?.reduce(
            (acc: any, workOrder: any) => {
                workOrder.stages.forEach((stage: any, stageIndex: number) => {
                    if (selectedStages.includes(stage.identifier)) {
                        acc.push(workOrder?.experiment_id?.[stageIndex]);
                    }
                });
                return acc;
            },
            []
        );

        const experimentIds =
            (!!selectedStages.length
                ? experiment_ids
                : [
                    ...new Set(
                        selectedWorkOrders.flatMap((workOrder) => workOrder.experiment_id)
                    ),
                ]) ?? [];

        const formData = new FormData();

        if (mode === "WO") {
            experimentIds.forEach((id: any) => {
                formData.append("experiment_ids", id);
            });
        } else if (mode === "FILE") {
            formData.append("file", file);
        }
        formData.append("run_title", analyticsForm.getFieldValue("run_title"));

        dispatch(smileSummaryRequest(formData));
        dispatch(setIsEditing(false));
    };

    const switchModes = useCallback((checked: boolean) => checked ? setMode("FILE") : setMode("WO"), []);

    const fileProps: UploadProps = {
        name: "file",
        accept: ACCEPTABLE_FILE_TYPES.join(","),
        customRequest: () => { },
        showUploadList: false,
        beforeUpload: (file) => {
            const isFileTypeOk = ACCEPTABLE_FILE_TYPES.includes(file.type);
            if (!isFileTypeOk) {
                message.error(t("smiles.fileUploadWarning"));
            }
            if (!isFileTypeOk) return false;

            return true;
        },
        fileList: [],
        onChange: ({ file }) => {
            setFile(file.originFileObj);
        },
        multiple: false,
    };

    const modeContainer = useMemo(() => {
        return (
            <div className="mode-container">
                <div>
                    <span style={{ color: mode === "FILE" ? "#BFBFBF" : "#000" }}>
                        {t("common.workOrder")}&nbsp;&nbsp;
                    </span>
                    <Switch value={mode === "FILE"} onChange={switchModes} />
                    <span style={{ color: mode === "WO" ? "#BFBFBF" : "#000" }}>
                        &nbsp;&nbsp;
                        {t("dataMapper.uploadFile")}
                    </span>
                    &nbsp;&nbsp;
                    <Tooltip title={t("smiles.toggleWoAndFile")}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
            </div>
        );
    }, [mode, switchModes, t]);

    return (
        <div className="form-container">
            {mode === "WO" && (
                <div className="work-order-form">
                    <Form
                        onFinish={handleFormSubmit}
                        form={analyticsForm}
                        layout="vertical"
                        style={{ width: "100%" }}
                        scrollToFirstError
                    >
                        <Row justify="start" gutter={[16, 8]}>
                            <Col span={8}>
                                <Form.Item
                                    name="run_title"
                                    label={t("smiles.runTitle")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("common.required"),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t("smiles.runTitle")} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="work_order_ids"
                                    label={t("aiEngine.customInsights.workOrder")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("workOrder.message.SelectWorkOrder"),
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={t("aiEngine.customInsights.selectWorkOrder")}
                                        allowClear
                                        mode="multiple"
                                        notFoundContent={
                                            <Empty
                                                description={t("workOrder.noClosedWorkOrderForProject")}
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            />
                                        }
                                        optionFilterProp="children"
                                        onChange={setSelectedWo}
                                        maxTagCount="responsive"
                                        onClear={() => {
                                            analyticsForm.setFieldValue("stages", []);
                                            setSelectedStages([]);
                                        }}
                                        loading={workOrdersStatus === AsyncStates.LOADING}
                                        onDeselect={(woOrder) => {
                                            const removedWoStages = workOrders
                                                .find((wo) => wo.work_order_id === woOrder)
                                                .stages.map((stage: any) => stage.identifier);
                                            const filteredStages = analyticsForm
                                                .getFieldValue("stages")
                                                ?.filter(
                                                    (stage: string) => !removedWoStages.includes(stage)
                                                );

                                            analyticsForm.setFieldValue("stages", filteredStages);
                                            setSelectedStages(filteredStages ?? []);
                                        }}
                                        dropdownRender={(menu) => {
                                            return (
                                                <div>
                                                    {workOrders.length > 0 && (
                                                        <Checkbox
                                                            style={{ padding: 10 }}
                                                            checked={selectedWo.length === workOrders.length}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    const allWorkOrder = workOrders.map(
                                                                        (wo) => wo.work_order_id
                                                                    );
                                                                    analyticsForm.setFieldsValue({
                                                                        work_order_ids: allWorkOrder,
                                                                    });
                                                                    setSelectedWo(allWorkOrder);
                                                                } else {
                                                                    analyticsForm.setFieldsValue({
                                                                        work_order_ids: [],
                                                                    });
                                                                    setSelectedWo([]);
                                                                }
                                                            }}
                                                        >{`${t("common.selectAll")}`}</Checkbox>
                                                    )}
                                                    <Spin
                                                        spinning={workOrdersStatus === AsyncStates.LOADING}
                                                    >
                                                        {menu}
                                                    </Spin>
                                                </div>
                                            );
                                        }}
                                    >
                                        {workOrdersStatus === AsyncStates.SUCCESS &&
                                            workOrders.map((res: any) => (
                                                <Option
                                                    value={res.work_order_id}
                                                    key={res.work_order_id}
                                                >
                                                    {res.work_order_name}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="stages" label={t("common.stage")}>
                                    <Select
                                        placeholder={t("common.selectStage")}
                                        allowClear
                                        disabled={!selectedWo.length}
                                        mode="multiple"
                                        optionFilterProp="children"
                                        maxTagCount="responsive"
                                        onChange={setSelectedStages}
                                        filterOption={(input: any, option: any) => {
                                            if (
                                                option.label &&
                                                typeof option.label === "object" &&
                                                !!option.label.props.children.length
                                            ) {
                                                const { children } = option.label.props;
                                                const stages =
                                                    children[0] +
                                                    children[1] +
                                                    (typeof children[2] === "object"
                                                        ? children[2]?.props?.children
                                                        : "") || "";
                                                return stages
                                                    .toLowerCase()
                                                    ?.includes(input?.toLowerCase());
                                            }
                                            return false;
                                        }}
                                        options={stagesOptions}
                                        dropdownRender={(menu) => {
                                            const stages = stagesOptions.flatMap((stage) =>
                                                stage.options.map(
                                                    (res: { label: string; value: string }) => res.value
                                                )
                                            );
                                            return (
                                                <div>
                                                    <Checkbox
                                                        style={{ padding: 10 }}
                                                        checked={
                                                            !!selectedStages.length &&
                                                            selectedStages.length === stages.length
                                                        }
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                analyticsForm.setFieldsValue({
                                                                    stages,
                                                                });
                                                                setSelectedStages(stages);
                                                            } else {
                                                                setSelectedStages([]);
                                                                analyticsForm.setFieldsValue({
                                                                    stages: [],
                                                                });
                                                            }
                                                        }}
                                                    >{`${t("common.selectAll")}`}</Checkbox>
                                                    {menu}
                                                </div>
                                            );
                                        }}
                                    ></Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="start" gutter={[16, 8]} style={{ marginTop: "16px" }}>
                            <Col>
                                <StyledButton
                                    type="primary"
                                    htmlType="submit"
                                    size="small"
                                    style={{ borderRadius: "6px" }}
                                >
                                    {t("common.submit")}
                                </StyledButton>
                            </Col>
                            <Col>
                                <StyledButton
                                    type="default"
                                    size="small"
                                    style={{ borderRadius: "6px" }}
                                    onClick={resetForm}
                                >
                                    {t("common.clearAll")}
                                </StyledButton>
                            </Col>
                            {modeContainer}
                        </Row>
                    </Form>
                </div>
            )}

            {mode === "FILE" && (
                <Form
                    onFinish={handleFormSubmit}
                    form={analyticsForm}
                    layout="horizontal"
                    style={{ width: "100%" }}
                    scrollToFirstError
                >
                    <Row justify="start">
                        <Col span={10}>
                            <Form.Item
                                name="run_title"
                                label={t("smiles.runTitle")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("common.required"),
                                    },
                                ]}
                            >
                                <Input placeholder={t("smiles.runTitle")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {!file && (
                        <div className="file-upload-form">
                            <Dragger className="file-upload" {...fileProps}>
                                <UploadOutlined />
                                &nbsp;&nbsp;<span>{t("polyGPT.drag")}</span>
                            </Dragger>
                            <div style={{ marginTop: "8px" }}>
                                {t("datamapper.needHelp?")}{" "}
                                <a href={template} rel="noreferrer noopener" target="_blank">
                                    {t("datamapper.downloadTemplate?")}
                                </a>{" "}
                                {t("datamapper.spreadsheet?")}
                            </div>
                            <div style={{ marginTop: "16px" }}>{modeContainer}</div>
                        </div>
                    )}
                    {file && (
                        <div className="file-list-form">
                            <div className="file-label">{t("polygpt.File")}:</div>
                            <div className="name">{file.name}</div>
                            <Row
                                justify="start"
                                gutter={[16, 8]}
                                style={{ marginTop: "16px" }}
                            >
                                <Col>
                                    <StyledButton
                                        type="primary"
                                        htmlType="submit"
                                        size="small"
                                        style={{ borderRadius: "6px" }}
                                    >
                                        {t("common.submit")}
                                    </StyledButton>
                                </Col>
                                <Col>
                                    <StyledButton
                                        type="link"
                                        size="small"
                                        style={{ borderRadius: "6px" }}
                                        onClick={() => setFile(undefined)}
                                    >
                                        {t("common.reupload")}
                                    </StyledButton>
                                </Col>
                                {modeContainer}
                            </Row>
                        </div>
                    )}
                </Form>
            )}
        </div>
    );
};

export default SmileAnalyticsForm;
