import { ExpResults } from "./ExpResults"
import { motion } from "framer-motion"
import { useParams } from "react-router-dom"

export function ExpHistoryDetails() {
  const { doeId }: any = useParams()

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: "just" }}
    >
      {doeId === "draft" ? (
        <ExpResults
          from="generated-exp"
        />
      ) : (
        <ExpResults from="history" />
      )}
    </motion.div>
  )
}
