import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	displayNamesRequest,
	displayNamesSuccess,
	displayNamesFailure,
	filteredDisplayNamesFailure,
	filteredDisplayNamesRequest,
	filteredDisplayNamesSuccess,
} from "../actions/displayNames"
import {
	getDisplayNames,
	getFilterDisplayNamesApi,
} from "src/services/common/master"
// import { history } from "src"
import jwtManager from "src/utils/jwtManager"


function* displayNamesSaga(action: any): Generator<any, any, any> {
	try {
		const current: string = yield select((state) => state.language.current)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(
			getDisplayNames,
			{ ...action.payload, "lang": current },
			headers
		)
		if (status === "Success") {
			yield put(displayNamesSuccess({ ...data, language: current }))
		} else {
			yield put(displayNamesFailure(message))
			// yield call(history.push, "/error?path=" + window.location.pathname)
		}
	} catch (error) {
		yield put(displayNamesFailure(error))
		// yield call(history.push, "/error?path=" + window.location.pathname)
	}
}

function* filteredDisplayNamesSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(getFilterDisplayNamesApi, payload, headers)
		if (status === "Success") {
			yield put(filteredDisplayNamesSuccess(data))
		} else {
			yield put(filteredDisplayNamesFailure(message))
		}
	} catch (error) {
		yield put(filteredDisplayNamesFailure())
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(displayNamesRequest, displayNamesSaga)
	yield takeLatest(filteredDisplayNamesRequest, filteredDisplayNamesSaga)
}
