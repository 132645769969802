import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import { ActionTypes } from "../utils";

interface TextCellProps {
  initialValue: string | null;
  columnId: string;
  rowIndex: number;
  dataDispatch: React.Dispatch<any>;
  allowEditing: boolean;
}

export default function TextCell({
  initialValue,
  columnId,
  rowIndex,
  dataDispatch,
  allowEditing,
}: TextCellProps) {
  const [value, setValue] = useState<{ value: string | null; update: boolean }>({
    value: initialValue,
    update: false,
  });

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue({ value: e.target.value, update: false });
  }

  function onBlur() {
    setValue((old) => ({ value: old.value, update: true }));
  }

  useEffect(() => {
    setValue({ value: initialValue, update: false });
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({
        type: ActionTypes.UPDATE_CELL,
        columnId,
        rowIndex,
        value: value.value,
      });
    }
  }, [value.update,  value.value, columnId, rowIndex, dataDispatch]);

  return (
    <ContentEditable
      html={(value.value && value.value.toString()) || ""}
      onChange={onChange}
      onBlur={onBlur}
      className="data-input"
      contentEditable={allowEditing}
      disabled={!allowEditing}
    />
  );
}
