import { useCallback, useEffect, useMemo, useState } from "react"
import {
    Spin,
    Upload,
    Row,
    message,
    Table,
    Space,
    Card,
    Radio,
    Tabs,
    Form,
    Select,
    Col,
    Divider,
    Input,
    Typography,
    Dropdown,
    Menu,
    Popconfirm,
} from "antd";
import {
    DownloadOutlined,
    InboxOutlined,
    LoadingOutlined,
    MoreOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux'
import { antdTheme, AsyncStates } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
import { graphsDataDeleteRequest, uploadGraphsDataRequest } from 'src/store/actions/workOrderDetails'
import { useMemberName } from "src/utils/useMemberName"
import useTranslate from 'src/utils/useTranslate';
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
import { StyledButton } from "src/styled_components/StyledButton";


const { Dragger } = Upload
const { Option } = Select
const { Link, Text } = Typography

const DEFAULT_CYCLE_OPTIONS = [
    "Cycle 0", "Cycle 1", "Cycle 2", "Cycle 3", "Cycle 4", "Cycle 5", "Cycle 6"
]
const DEFAULT_COLUMN_OPTIONS = [
    "Time", "Temp.", "DSC", "DDSC", "角周波数", "貯蔵弾性率", "損失弾性率", "損失正接", "複素粘度", "温度", "状態", "ギャップ",
    "Angular Frequency", "Storage Modulus", "Loss Modulus", "Damping Factor", "Complex Viscosity", "Temperature", "Status"
]


export const RawFileUpload = ({ displayIdList, initialTrialSetList, currentExpIdIndex, componentType }: any) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()

    const { getName } = useMemberName()
    const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder)
    const uploadGraphsDataStatus = useSelector((state: StoreState) => state.workOrderDetails.uploadGraphsDataStatus)
    const graphsDataHistory = useSelector((state: StoreState) => state.workOrderDetails.graphsDataHistory)
    const graphsDataHistoryStatus = useSelector((state: StoreState) => state.workOrderDetails.graphsDataHistoryStatus)
    const graphsDataDeleteStatus = useSelector((state: StoreState) => state.workOrderDetails.graphsDataDeleteStatus)
    const configs = useSelector((state: StoreState) => state.configs.features)
    const [file, setFile] = useState<any>()
    const [tab, setTab] = useState("generalised_dsc")
    const [type, setType] = useState<any>("generalised_dsc")
    const [form] = Form.useForm()
    const [fileHistoryTable, setFileHistoryTable] = useState<any>([])
    const [columnItems, setColumnItems] = useState<any>(DEFAULT_COLUMN_OPTIONS)
    const [cycleItems, setCycleItems] = useState<any>(DEFAULT_CYCLE_OPTIONS)
    const [name, setName] = useState('')

    useEffect(() => {
        if (graphsDataHistoryStatus === AsyncStates.SUCCESS) {
            setFileHistoryTable(graphsDataHistory.filter((res: any) => res?.type === tab))
        }
    }, [graphsDataHistoryStatus, graphsDataHistory, tab])

    useEffect(() => {
        if (uploadGraphsDataStatus === AsyncStates.SUCCESS) {
            setFile(null)
            setColumnItems(DEFAULT_COLUMN_OPTIONS)
            setCycleItems(DEFAULT_CYCLE_OPTIONS)
            setName("")
            form.resetFields()
        }
    }, [uploadGraphsDataStatus, form])

    const uploadProps = {
        onRemove: (record: any) => {
            setFile(null)
        },
        beforeUpload: (record: any) => {
            if (["xlsx", "xls", "csv"].includes(record.name.split(".").pop())) {
                setFile(record)
            } else message.error(t("dataUpload.selectedFileDesc"))
            return false
        },
        multiple: false,
        fileList: !!file ? [file] : [],
    }

    const dropDownOverlay = useCallback((row) => {
        return (
            <Menu>
                <Menu.Item key={3}>
                    <StyledButton
                        icon={<DownloadOutlined />}
                        style={{ color: "black" }}
                        type="link"
                        onClick={(e) => {
                            e.stopPropagation()
                            window.open(row?.download_link, "_blank")
                        }}
                    >
                        {t("notifications.download")}
                    </StyledButton>
                </Menu.Item>
                {componentType === "crud" &&
                    <>
                        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
                        <Menu.Item key={4}>
                            <Popconfirm title={`${t("common.confirmDelete")} ${row?.display_id}`} onConfirm={() => {
                                dispatch(graphsDataDeleteRequest({
                                    work_order_id: workOrder.work_order_id,
                                    experiment_id: workOrder.experiment_id?.[currentExpIdIndex],
                                    formulation_id: row?.formulation_id,
                                    type: tab,
                                }))
                            }}>
                                <StyledButton
                                    icon={<StyledDeleteIcon className="delete-icon" />}
                                    style={{ color: "black" }}
                                    type="link"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {t("common.delete")}
                                </StyledButton>
                            </Popconfirm>
                        </Menu.Item>
                    </>}
            </Menu>
        )
    }, [dispatch, t, tab, componentType, workOrder?.work_order_id, workOrder?.experiment_id, currentExpIdIndex])

    const graphsHistoryColumns: any = useMemo(() => [
        {
            title: "Trial",
            dataIndex: "display_id",
            key: "display_id",
            align: "center"
        },
        {
            title: t("woReportPreview.fileName"),
            dataIndex: "file_name",
            key: "file_name",
            align: "center"
        },
        {
            title: "Uploaded on",
            dataIndex: "updated",
            key: "updated",
            render: (text: any) => new Date(text).toLocaleString(),
            align: "center"
        },
        {
            title: t("history.uploadedBy"),
            dataIndex: "user_id",
            key: "user_id",
            render: (text: any) => getName(text),
            align: "center"
        },
        {
            title: t("common.actions"),
            dataIndex: "actions",
            key: "actions",
            render: (text: any, row: any) => (
                <Dropdown key="more" overlay={() => dropDownOverlay(row)}>
                    <StyledButton
                        type="link"
                        style={{
                            border: "none",
                            padding: 0,
                            color: 'black',
                            outline: 'none'
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <MoreOutlined
                            style={{
                                fontSize: antdTheme.fontSizeHeading3,
                                verticalAlign: "top",
                            }}
                        />
                    </StyledButton>
                </Dropdown>
            ),
            align: "center"
        },
    ], [dropDownOverlay, getName, t])

    const addColumnItem = (e: any) => {
        const trimmedName = name?.trim()
        if (!trimmedName) {
            message.error(t("inventory.Addcontentintheitem"))
            return
        }
        if (columnItems.includes(trimmedName)) {
            message.error(`${trimmedName} is already added in options`)
            return
        }
        e.preventDefault()
        setColumnItems((prevState: any) => ([...prevState, trimmedName]))
        setName('')
        form.setFieldsValue({ columns: [...(form.getFieldsValue()?.columns || []), trimmedName] })
    }

    const addCycleItem = (e: any) => {
        const trimmedName = name?.trim()
        if (!trimmedName) {
            message.error(t("inventory.Addcontentintheitem"))
            return
        }
        if (cycleItems.includes(trimmedName)) {
            message.error(`${trimmedName} is already added in options`)
            return
        }
        e.preventDefault()
        setCycleItems((prevState: any) => ([...prevState, trimmedName]))
        setName('')
        form.setFieldsValue({ cycles: [...(form.getFieldsValue()?.cycles || []), trimmedName] })
    }

    const uploadGraphsData = (values: any) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('work_order_id', workOrder.work_order_id)
        formData.append('experiment_id', workOrder.experiment_id?.[currentExpIdIndex])
        formData.append('type', type)
        formData.append('data', JSON.stringify({
            columns: values?.columns,
            display_ids: Array.isArray(values?.display_ids) ? values?.display_ids : [values?.display_ids],
            cycles: values?.cycles
        }))
        dispatch(uploadGraphsDataRequest({ formData, rawData: true }))
    }


    return (
        <Spin spinning={uploadGraphsDataStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
            <Space direction="vertical" size="large" style={{ "width": "100%", overflowX: "auto" }}>
                {componentType === "crud" &&
                    <Row>
                        <Col span={16}>
                            <Form layout="vertical" onFinish={uploadGraphsData} form={form}>
                                <Form.Item name="type" initialValue={type} rules={[{ required: true }]}>
                                    <Radio.Group onChange={(e) => {
                                        form.setFieldsValue({ "display_ids": e.target.value === "generalised_dsc" ? "" : [] })
                                        setType(e.target.value)
                                    }
                                    } value={type}>
                                        <Radio value={"generalised_dsc"}>{"DSC"}</Radio>
                                        {Boolean(configs?.dma_graphs) &&
                                            <Radio value={"generalised_dma"}>{"DMA"}</Radio>
                                        }
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item name="display_ids" label={<Text className="highlighted">{"Select Trial"}</Text>}
                                    required={false}
                                    rules={[{ required: true, message: "required", }]}
                                    tooltip={{
                                        title: t("common.requiredField"),
                                        icon: (
                                            <div style={{ color: "#ff4d4f", fontSize: "14px" }}>
                                                *
                                            </div>
                                        ),
                                    }}>
                                    {type === "generalised_dsc" ?
                                        <Select style={{ width: "100%" }}>
                                            {initialTrialSetList.map((res: any, index: number) => (
                                                <Option value={displayIdList[index]} key={res?.id_set?.formulation_id + index}>
                                                    {displayIdList[index]}
                                                </Option>))}
                                        </Select> :
                                        <Select mode={"multiple"} style={{ width: "100%" }}>
                                            {initialTrialSetList.map((res: any, index: number) => (
                                                <Option value={displayIdList[index]} key={res?.id_set?.formulation_id + index}>
                                                    {displayIdList[index]}
                                                </Option>))}
                                        </Select>
                                    }
                                </Form.Item>
                                {type === "generalised_dsc" &&
                                    <Form.Item name="cycles" label={<Text className="highlighted">{"Add cycles to extract from file"}</Text>}
                                        required={false}
                                        rules={[{ required: true, message: "required", }]}
                                        tooltip={{
                                            title: t("common.requiredField"),
                                            icon: (
                                                <div style={{ color: "#ff4d4f", fontSize: "14px" }}>
                                                    *
                                                </div>
                                            ),
                                        }}>
                                        <Select mode="multiple" style={{ width: "100%" }}
                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder={t("common.addAnOption")} value={name} onChange={(e) => setName(e.target.value)} minLength={1} />
                                                        <Link onClick={addCycleItem} style={{ whiteSpace: 'nowrap' }}>
                                                            <PlusOutlined />{t("common.addOption")}
                                                        </Link>
                                                    </Space>
                                                </>
                                            )}>
                                            {cycleItems.map((res: any) => (
                                                <Option value={res} key={res}>{res}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                }
                                <Form.Item name="columns" label={<Text className="highlighted">{"Add columns to extract from file"}</Text>}
                                    required={false}
                                    rules={[{ required: true, message: "required", }]}
                                    tooltip={requiredFieldStar}>
                                    <Select mode="multiple" style={{ width: "100%" }}
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder={t("common.addAnOption")} value={name} onChange={(e) => setName(e.target.value)} minLength={1} />
                                                    <Link onClick={addColumnItem} style={{ whiteSpace: 'nowrap' }}>
                                                        <PlusOutlined />{t("common.addOption")}
                                                    </Link>
                                                </Space>
                                            </>
                                        )}>
                                        {columnItems.map((res: any) => (
                                            <Option value={res} key={res}>{res}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="file" rules={[{ required: true }]}>
                                    <Dragger {...uploadProps}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">{t("fileupload.upload")}</p>
                                        <p className="ant-upload-hint">
                                            {"Upload the files for the Trials , only Files with .xlsx, .xls, .csv are supported"}
                                        </p>
                                    </Dragger>
                                </Form.Item>
                                <Row justify={"end"}>
                                    <StyledButton htmlType="submit"
                                        type="primary"
                                        style={{ marginTop: 16 }}
                                    >
                                        {t("dataManagement.startUpload")}
                                    </StyledButton>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                }
                {!!workOrder?.plots_data_uploaded && (
                    <Card title={componentType === "crud" && 'File upload history'}>
                        <Tabs onChange={(e) => setTab(e)} >
                            <Tabs.TabPane key="generalised_dsc" tab="DSC">
                                <Table
                                    columns={graphsHistoryColumns}
                                    loading={{
                                        spinning:
                                            (graphsDataHistoryStatus === AsyncStates.LOADING || graphsDataDeleteStatus === AsyncStates.LOADING),
                                        indicator: <LoadingOutlined />
                                    }}
                                    dataSource={fileHistoryTable} bordered
                                />
                            </Tabs.TabPane>
                            {Boolean(configs?.dma_graphs) &&
                                <Tabs.TabPane key="generalised_dma" tab="DMA">
                                    <Table
                                        columns={graphsHistoryColumns}
                                        loading={{
                                            spinning:
                                                (graphsDataHistoryStatus === AsyncStates.LOADING || graphsDataDeleteStatus === AsyncStates.LOADING),
                                            indicator: <LoadingOutlined />
                                        }}
                                        dataSource={fileHistoryTable} bordered
                                    />
                                </Tabs.TabPane>
                            }
                        </Tabs>
                    </Card>
                )}
            </Space>
        </Spin>
    )
}