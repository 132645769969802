export enum IdSet {
    formulation_id = 'formulation_id',
    processing_id = 'processing_id',
    characterization_id = 'characterization_id',
    properties_id = 'properties_id'
}

export enum Dataset {
    ingredients = 'ingredients',
    processing = 'processing',
    characterizations = 'characterizations',
    properties = 'properties',
}

export const getHeader = (dataset: Dataset) => {
    switch (dataset) {
        case Dataset.ingredients:
            return IdSet.formulation_id
        case Dataset.processing:
            return IdSet.processing_id
        case Dataset.characterizations:
            return IdSet.characterization_id
        case Dataset.properties:
            return IdSet.properties_id
    }
}

export const getHeaderList = (dataset: Dataset) => {
    switch (dataset) {
        case Dataset.ingredients:
            return [IdSet.formulation_id]
        case Dataset.processing:
            return [IdSet.formulation_id, IdSet.processing_id]
        case Dataset.characterizations:
            return [IdSet.formulation_id, IdSet.processing_id, IdSet.characterization_id]
        case Dataset.properties:
            return [IdSet.formulation_id, IdSet.processing_id, IdSet.properties_id]
    }
}

export const getDataset = (header: IdSet) => {
    switch (header) {
        case IdSet.formulation_id:
            return Dataset.ingredients
        case IdSet.processing_id:
            return Dataset.processing
        case IdSet.characterization_id:
            return Dataset.characterizations
        case IdSet.properties_id:
            return Dataset.properties
    }
}