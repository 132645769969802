type ParamsWithUnits = {
	param: string
	unit: string | null
}[]

export const removeDuplicateParamsWithUnit = (data: ParamsWithUnits) => {
	const register: {
		[param: string]: boolean
	} = {}
	const result: ParamsWithUnits = []

	data.forEach((item) => {
		const key = item.param

		if (!register[key]) {
			result.push(item)
			register[key] = true
		}
	})

	return result
}
