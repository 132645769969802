import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { CorrelationStatusTypes, TabKeys } from 'src/components/AIEngine/DataSummary'
import { PivotTableAnalysis, ScatterPlot } from 'src/components/AIEngine/DataSummary/tabs/summaries'
import { VisualiseDistributions } from 'src/components/AIEngine/DataSummary/tabs/summaries/Visualise Distributions/VisualiseDistributions'
import { CategoricalSummary, NumericalSummary } from 'src/components/AIEngine/DataSummary/types'
import { SurfacePlot } from 'src/components/SurfacePlot'
import { AsyncStates, zeonFixedCategoryList } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
import { DisplayNames } from 'src/typings'
import { columnData, dsmRecipetransposePredictedData, pickDescriptorsKey, removeNulls } from 'src/utils/decorator'
import useTranslate from 'src/utils/useTranslate'
import { AnalyticsRecipeDistribution } from '../AnalyticsReciepeDistribution'
import { PlotType } from '../enums'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { Divider } from 'antd'
import { useValue } from 'src/utils/useValue'

type P = {
    categoricalData: CategoricalSummary;
    numericalData: NumericalSummary;
    workOrderIds: string[];
    characterizationMethodId?: string;
    selectedVariation?: any;
    correlationStatus: CorrelationStatusTypes;
    normalise: boolean | undefined;
    selectedStages: string[];
    selectedExperiments: string[];
    tab: TabKeys,
    selectedPlots: CheckboxValueType[],
    setSaveGraphsPayload: any,
    selectedPlotsData: { [key: string]: any },
    selectedFormulations: string[]
};


export const DataAnalyticsGraphs = ({
    workOrderIds,
    categoricalData,
    numericalData,
    characterizationMethodId,
    selectedVariation,
    correlationStatus,
    normalise,
    selectedStages,
    selectedExperiments,
    tab,
    selectedPlots,
    setSaveGraphsPayload,
    selectedPlotsData,
    selectedFormulations
}: P) => {

    const configs = useSelector((state: StoreState) => state.configs.features)
    const names = useSelector((state: StoreState) => state.displayNames.data) as DisplayNames
    const { getValue: getEUValue, convertValue } = useValue()

    const {
        analyticsRecipeDistributionTable: { analyticsRecipeDistributionTableData = [] }, dataSummaryPlotOptions: { plotOptionsFormulationDisplayNames },
    } = useSelector((state: StoreState) => state.dataSummary);
    const [t] = useTranslate();

    const [selectedRecipeIds, setselectedRecipeIds] = useState<any[]>([]);


    const experiments = useMemo(() => analyticsRecipeDistributionTableData?.map((exp) => ({
        ...exp,
        processing: pickDescriptorsKey("processing", exp.processing),
        properties: pickDescriptorsKey("properties", exp.properties),
    })), [analyticsRecipeDistributionTableData],);


    const displayNames = useSelector(
        (state: StoreState) => state.displayNames.data,
    );

    const zeonCategoryList = useMemo(() => {
        const variableCategoryList = Object.values(
            experiments?.[0]?.ingredients || {},
        ).map((res: any) => res?.category);
        return [...new Set([...zeonFixedCategoryList, ...variableCategoryList])];
    }, [experiments]);

    const tableColumnsVis = useMemo(() => {
        const reciepeExperiments = experiments?.filter((_exp: any) => selectedRecipeIds.includes(_exp?.id_set?.formulation_id),);

        return columnData(
            reciepeExperiments,
            displayNames,
            t,
            Boolean(configs?.characterization_methods)
        );
    }, [
        displayNames,
        t,
        configs,
        experiments,
        selectedRecipeIds,
    ]);

    const filteredDataVis = useMemo(() => {
        const recipeData = experiments?.filter((_exp: any) => selectedRecipeIds.includes(_exp?.id_set?.formulation_id));
        const recipeDistributionDisplayNames = {
            linked_trials: plotOptionsFormulationDisplayNames
        }
        const filteredReciepeDistributionDisplayKeys = {
            ...recipeData?.reduce((acc, curr) => {
                return {
                    ...acc,
                    ingredients: [
                        ...new Set([
                            ...Object.keys(curr.ingredients || {}),
                            ...(acc?.ingredients ?? []),
                        ]),
                    ].filter((ingredient) => names.ingredients?.[ingredient]?.name ?? recipeDistributionDisplayNames?.linked_trials?.[ingredient]),
                    processing: [
                        ...new Set([
                            ...Object.keys(curr.processing || {}),
                            ...(acc?.processing ?? []),
                        ]),
                    ],
                    predicted_properties: [
                        ...new Set([
                            ...Object.keys(curr.predicted_properties || {}),
                            ...(acc?.predicted_properties ?? []),
                        ]),
                    ],
                    properties: Array.isArray(curr.properties) && Boolean(configs?.nestle_configs) ? curr.properties : [
                        ...new Set([
                            ...Object.keys(curr.properties || {}),
                            ...(acc?.properties ?? []),
                        ]),
                    ],
                };
            }, {}),
        };



        const newData: any = dsmRecipetransposePredictedData(
            recipeData,
            zeonCategoryList,
            Boolean(configs?.characterization_methods),
            recipeDistributionDisplayNames,
            displayNames,
            filteredReciepeDistributionDisplayKeys,
            "data-summary",
            Boolean(configs?.nestle_configs),
            getEUValue
        );
        const removedNulls = removeNulls(newData, "suggested_exp", convertValue);
        const filteredRows = removedNulls.filter(
            (row: { parameter?: string;[key: string]: any }) =>
                row.parameter !== undefined,
        );

        return filteredRows;
    }, [experiments, plotOptionsFormulationDisplayNames, zeonCategoryList, configs?.characterization_methods, configs?.nestle_configs, displayNames, selectedRecipeIds, names.ingredients, getEUValue, convertValue]);

    const getDisplayName = useCallback(
        (value: string) => {
            return (
                names.ingredients?.[value]?.name ??
                names.processing?.[value]?.name ??
                names.properties?.[value]?.name ??
                categoricalData?.["formulation_display_names"][value] ??
                numericalData?.["formulation_display_names"][value] ??
                value
            );
        },
        [names, categoricalData, numericalData],
    );

    const { analyticsRecipeDistributionData, analyticsRecipeDistributionStatus } = useSelector((state: StoreState) => state.dataSummary)

    return (
        <div id="data-analytics-graphs">
            {!!Object.keys(numericalData?.scatter_plot?.data || {}).length && selectedPlots.includes(PlotType.SCATTER_PLOT) && (
                <ScatterPlot
                    scatterPlotData={numericalData?.scatter_plot}
                    getDisplayName={getDisplayName}
                    names={names}
                    setSaveGraphsPayload={setSaveGraphsPayload}
                    selectedPlotsData={selectedPlotsData}
                />
            )}

            {selectedPlots.includes(PlotType.SURFACE_PLOT) && <>
                <Divider className='common__divider' />
                <SurfacePlot
                    experiments={experiments}
                    selectedExperiments={selectedExperiments}
                    workOrderIds={workOrderIds}
                    setSaveGraphsPayload={setSaveGraphsPayload}
                    selectedPlotsData={selectedPlotsData}
                    selectedFormulations={selectedFormulations}
                />
            </>
            }


            {selectedPlots.includes(PlotType.RECIPE_DISTRIBUTION) && !Boolean(configs?.ai_engine_with_methods) && (
                <>
                    <Divider className='common__divider' />
                    <AnalyticsRecipeDistribution
                        recipeDistribution="data-summary"
                        toolbarEnabled={false}
                        tableColumnsVis={tableColumnsVis}
                        filteredDataVis={filteredDataVis}
                        checkedExperiments={[]}
                        generateWO={() => { }}
                        selectedRecipeIds={selectedRecipeIds}
                        setselectedRecipeIds={setselectedRecipeIds}
                        experiments={analyticsRecipeDistributionData}
                        expStatus={analyticsRecipeDistributionStatus}
                        status={analyticsRecipeDistributionStatus === AsyncStates.LOADING}
                        currentTab={"currentTab"}
                        selectedStages={selectedStages}
                        selectedExperiments={selectedExperiments}
                        workOrderIds={workOrderIds}
                        setSaveGraphsPayload={setSaveGraphsPayload}
                        selectedPlotsData={selectedPlotsData}
                        selectedFormulations={selectedFormulations}
                    />
                </>
            )}

            {selectedPlots.includes(PlotType.PIVOT_ANALYSIS) && <>
                <Divider className='common__divider' />
                <PivotTableAnalysis
                    getDisplayName={getDisplayName}
                    workOrderIds={workOrderIds}
                    ingredients={[
                        ...(numericalData.ingredients_processing_properties?.processing ??
                            []),
                        ...(numericalData.ingredients_processing_properties?.ingredients ??
                            []),
                        ...(numericalData.ingredients_processing_properties?.costing ?? []),
                    ]}
                    properties={
                        numericalData.ingredients_processing_properties?.properties ?? []
                    }
                    characterizationMethodId={characterizationMethodId}
                    selectedVariation={selectedVariation}
                    normalise={normalise}
                    selectedStages={selectedStages}
                    selectedExperiments={selectedExperiments}
                    setSaveGraphsPayload={setSaveGraphsPayload}
                    selectedPlotsData={selectedPlotsData}
                    selectedFormulations={selectedFormulations}
                />
            </>
            }

            {
                selectedPlots.includes(PlotType.VISUALIZE_DISTRIBUTION) &&
                !!categoricalData?.divided_columns?.original_categorical?.length &&
                Object.values(categoricalData?.divided_columns?.Numerical || {})
                    .length && (
                    <>
                        <Divider className='common__divider' />
                        <VisualiseDistributions
                            categoricalInput={
                                categoricalData?.divided_columns?.original_categorical
                            }
                            numericalInput={categoricalData?.divided_columns?.Numerical}
                            workOrderIds={workOrderIds}
                            selectedVariation={selectedVariation}
                            getDisplayName={getDisplayName}
                            normalise={normalise}
                            selectedExperiments={selectedExperiments}
                            setSaveGraphsPayload={setSaveGraphsPayload}
                            selectedPlotsData={selectedPlotsData}
                            selectedFormulations={selectedFormulations}
                        />
                    </>
                )}
        </div >
    )
}
