import React from "react"
import "./styles.scss"
import { ScatterPlot as Data } from "src/components/AIEngine/DataSummary/types"
import { SummaryWrapper } from "../summary-wrapper"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useHighChartRedraw } from "src/utils/hooks"
import { useData } from "./utils"
import { Form } from "./form"
import highchartsMore from "highcharts/highcharts-more"
import { Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
highchartsMore(Highcharts)

const { Text } = Typography

type P = {
	data: Data
	getDisplayName: (value: string) => string
	numericalVariables: string[]
}

const Component = ({ data, getDisplayName, numericalVariables }: P) => {
	const { ref } = useHighChartRedraw()
	const {
		input1,
		input2,
		input3,
		options,
		setInput1,
		setInput2,
		setInput3,
	} = useData(data, getDisplayName)
	const [t] = useTranslate()

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "1.5rem",
				padding: "10px 0",
			}}
		>
			<Form
				input1={input1}
				input2={input2}
				input3={input3}
				setInput1={setInput1}
				setInput2={setInput2}
				setInput3={setInput3}
				getDisplayName={getDisplayName}
				numericalVariables={numericalVariables}
			/>

			{input1 && input2 && input3 && (
				<HighchartsReact ref={ref} highcharts={Highcharts} options={options} />
			)}
			{(!input1 || !input2 || !input3) && (
				<Text style={{ color: "#888", margin: 0 }}>
					{t("3dScatterChart.requiredFieldMessage")}
				</Text>
			)}
		</div>
	)
}

export const ScatterChart3D = ({
	data,
	getDisplayName,
	numericalVariables,
}: P) => {
	const [t] = useTranslate()

	return (
		<SummaryWrapper heading={t("common.3dScatterChart")} tooltip={t("aiEngine.tab.dataSummary.3dscatterchart")} key={t("common.3dScatterChart")}>
			<Component
				data={data}
				getDisplayName={getDisplayName}
				numericalVariables={numericalVariables}
			/>
		</SummaryWrapper>
	)
}
