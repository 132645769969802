import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Select, Space, Table, Typography, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { StyledButton } from 'src/styled_components/StyledButton';
import { StyledCard } from 'src/styled_components/StyledCard';
import useTranslate from 'src/utils/useTranslate';

const { Text } = Typography
const { Option } = Select


export const MapSheets = ({ callbacks, data }: any) => {
    const [t] = useTranslate()

    const { sheets, filename, stage } = data
    const { setCurrent, setSheets } = callbacks

    const [errorList, setErrorList] = useState<any>([])

    useEffect(() => {
        setSheets((prevState: any) => {
            const array = [...prevState]
            array.forEach((element) => {
                const trimmedElement = element?.sheet_name?.trim()?.toLowerCase()
                if (trimmedElement?.includes("experi")) {
                    element.sheet_mapped_to = "experiments"
                }
                else if (trimmedElement?.includes("form") && stage.data.find((res: any) => res?.type === "formulation_stage")) {
                    element.sheet_mapped_to = "formulation"
                }
                else if (trimmedElement?.includes("process") && stage.data.find((res: any) => res?.type === "processing_stage")) {
                    element.sheet_mapped_to = "process"
                }
                else if (trimmedElement?.includes("char") && stage.data.find((res: any) => res?.type === "characterization_stage")) {
                    element.sheet_mapped_to = "characterisation"
                }
                else if (trimmedElement?.includes("prop") && stage.data.find((res: any) => res?.type === "properties_stage")) {
                    element.sheet_mapped_to = "property"
                }
            })
            return array
        })
    }, [setSheets, stage])

    useEffect(() => {
        const mappedColumns = sheets.filter((res: any) => !!res.sheet_mapped_to).map((res: any) => res.sheet_mapped_to)
        const duplicateColumns = mappedColumns.filter((item: any, index: any) => mappedColumns.indexOf(item) !== index && item !== "ignore")
        const missingError = []
        if (!mappedColumns.includes("experiments")) {
            missingError.push(t("mapSheets.sheetTypeExperimentsRequired"))
        }
        // if (!mappedColumns.includes("formulation")) {
        //     missingError.push(t("mapSheets.sheetTypeFormulationRequired"))
        // }
        // if (!mappedColumns.includes("process")) {
        //     missingError.push(`A sheet of type "Processing" is required.`)
        // }
        // if (!mappedColumns.includes("property")) {
        //     missingError.push(t("mapSheets.sheetTypePropertyRequired"))
        // }
        const duplicateError = duplicateColumns.map((res: any) => `${t("mapColumns.onlyMapSheetOfType")} "${res?.charAt(0)?.toUpperCase() + res?.slice(1)}" once.`)
        setErrorList([...duplicateError, ...missingError])
    }, [sheets, t])

    const columns = [{
        dataIndex: "sheet_name",
        title: <Space><Text>{t("mapSheets.sheetName")}</Text><Tooltip title={t("mapSheets.uploadedFileTitle")}><InfoCircleOutlined /></Tooltip></Space>,
        key: "sheet_name"
    }, {
        dataIndex: "sheet_mapped_to",
        title: <Space><Text>{t("mapUnits.sheetMappedTo")}</Text><Tooltip title={t("mapsheets.titleRequiredByOurSystem")}><InfoCircleOutlined /></Tooltip></Space>,
        key: "sheet_mapped_to",
        render: (text: any, record: any, index: any) => {
            return <Select value={record.sheet_mapped_to} style={{ width: "100%" }} onChange={(e: any) => {
                setSheets((prevState: any) => {
                    const array = [...prevState]
                    array.forEach((res: any) => {
                        if (res.sheet_name === record.sheet_name) {
                            res.sheet_mapped_to = e
                        }
                    })
                    return array
                })
            }}>
                <Option key="experiments" value={"experiments"}>{t("common.experiments")}</Option>
                {stage.data.find((res: any) => res?.type === "formulation_stage") &&
                    <Option key="formulation" value={"formulation"}>{t("common.formulation")}</Option>
                }
                {stage.data.find((res: any) => res?.type === "processing_stage") &&
                    <Option key="process" value={"process"}>{t("common.process")}</Option>
                }
                {stage.data.find((res: any) => res?.type === "characterization_stage") &&
                    <Option key="characterisation" value={"characterisation"}>{t("common.characterisation")}</Option>
                }
                {stage.data.find((res: any) => res?.type === "properties_stage") &&
                    <Option key="property" value={"property"}>{t("common.property")}</Option>
                }
                <Option key="ignore" value={"ignore"}>{t("common.ignore")}</Option>
            </Select>
        }
    }
    ]


    return (
        <Space direction="vertical" style={{ width: "100%" }} size="large">
            <StyledCard title={<Space direction="vertical" style={{ width: "100%" }} size="small">
                <Text type='secondary'>{t("mapsheets.mapSheetTitles")}</Text>
                <Text type='secondary'>{t("mapsheets.interpretedSheetTitles")} {t("mapsheets.interpretedSheetTitlesCorrectly")}</Text>
            </Space>}>
                <Text strong>{filename}</Text>
                <Table columns={columns} dataSource={sheets} pagination={false} />
                <Space direction="vertical" style={{ width: "100%", marginTop: 20 }} size="middle">
                    {errorList.map((res: any) => <Text type="danger">{res}</Text>)}
                </Space>
            </StyledCard>
            <Row justify='end'>
                <Space>
                    <StyledButton onClick={() => setCurrent(0)} size='large'>{`${t("common.back")}: ${t("common.uploadAFile")}`}</StyledButton>
                    <StyledButton disabled={!!errorList?.length} onClick={() => setCurrent(2)} size='large'>{t("dataMapper.nextMapColumns")}</StyledButton>
                </Space>
            </Row>
        </Space>
    )
}
