import { Row, Space, Table, Typography, Tabs, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllMethodsRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import useTranslate from "src/utils/useTranslate";
import { NewParametersModal } from "./NewParametersModal";
import { useVariableValue } from "@devcycle/devcycle-react-sdk";

const { Text } = Typography;
const { TabPane } = Tabs;

const { Search } = Input;

export const SelectExperiments = ({ callbacks, data }: any) => {
  const [t] = useTranslate();
  const enablePropertyStandards = useVariableValue('property-standards', false)
  const { tableData, sheetData, selectedExps, selectedExpsKeys, unitsData, workOrder } = data;
  const {
    setCurrent,
    setSelectedExpsKeys,
    setUnitsData,
    generateData,
    uploadData,
    setSelectedExps,
  } = callbacks;
  const displayNames = useSelector((state: StoreState) => state.displayNames.data)
  // const properties = useSelector((state: StoreState) => state.repository.properties.data)
  // const methods = useSelector((state: StoreState) => state.repository.allMethods.data)
  const [expTableData, setExpTableData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("")
  const [newParameters, setNewParameters] = useState<any>({ ingredients: [], processing: [], characterisation: [], properties: [] })
  const [showNewParametersModal, setShowNewParametersModal] = useState(false)
  const [skipPrompt, setSkipPrompt] = useState(false)

  const filteredExpTableData = expTableData.filter((item) => {
    if (searchText) {
      return item["experiments"]
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    }
    return true;
  });


  const [errorList, setErrorList] = useState<any>([]);
  const [entireData, setEnitreData] = useState<any>({
    columns: {
      formulations: [],
      processing: [],
      properties: [],
      characterisation: [],
    },
    data: {
      formulations: [],
      processing: [],
      properties: [],
      characterisation: [],
    },
  });

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAllMethodsRequest());
  }, [dispatch]);

  const allMethods = useSelector(
    (state: StoreState) => state.repository.allMethods.data
  );

  useEffect(() => {
    setEnitreData(generateData());
  }, [generateData]);

  const expColumns = [
    {
      dataIndex: "experiments",
      title: t("common.experiments"),
      key: "experiments",
    },
  ];


  useEffect(() => {
    const exps = sheetData.experiments.map((record: any, index: number) => ({
      experiments:
        record?.[
        tableData.experiments.find(
          (res: any) => res?.columns_mapped_to === "experiments"
        )?.columns
        ] || "",
      key: index,
    }));
    const missedExpIds: any = [];
    const dataTypes = [
      "formulations",
      "characterisation",
      "processing",
      "properties",
    ];
    dataTypes.forEach((key: string) => {
      if (!!tableData?.[key]?.length) {
        missedExpIds.push(
          ...tableData?.[key]
            ?.filter((res: any) => res.columns_mapped_to === "experiment")
            ?.map((res: any) => res?.columns)
        );
      }
    });
    const missedExpsFiltered = [
      ...new Set(missedExpIds.filter((res: any) => !!res)),
    ].map((res: any, index) => ({
      experiments: res,
      key: exps.length + index,
    }));
    setExpTableData([...exps, ...missedExpsFiltered]);
    setSelectedExps([...exps, ...missedExpsFiltered]);
    setSelectedExpsKeys([...exps.map((exp: any) => exp.key), ...missedExpsFiltered.map((exp) => exp.key)]);
  }, [
    tableData.experiments,
    sheetData.experiments,
    setSelectedExpsKeys,
    setSelectedExps,
    tableData,
  ]);

  useEffect(() => {
    if (selectedExpsKeys.length) {
      setSelectedExps(expTableData.filter((selectedExp: any) => selectedExpsKeys.includes(selectedExp.key)))
    }
  }, [setSelectedExps, selectedExpsKeys, expTableData])


  useEffect(() => {
    if (!!Object.keys(entireData).length) {
      let missingFormulationIds = [],
        missingPropertyIds = [];
      const expids = selectedExps.map((res: any) => res.experiments);
      const invalidTrials: any = [];
      const formulations = entireData.data.formulations;
      if (!!formulations.length) {
        const formulationParms = formulations.map(
          (res: any) => res.ingredients
        );
        const formulationCats = formulations.map(
          (res: any) => res.ingredients_category
        );
        if (
          new Set(formulations.map((res: any) => res?.ingredients_unit)).size !== 1 && !workOrder?.is_multiunit
        ) {
          invalidTrials.push(t("common.allIngredientsMustHaveTheSameUnit"));
        }
        const duplicateFormulations = formulationParms.filter(
          (item: any, index: any) =>
            (formulationParms.indexOf(item) !== index && (item !== "-" && !item))
            && (formulationCats.indexOf(item) !== index && (formulationCats[index] !== "-" && !formulationCats[index]))
        );
        if (!!duplicateFormulations.length) {
          invalidTrials.push(
            `${t(
              "common.duplicateIngredientsFound"
            )} - ${duplicateFormulations.join(", ")}.`
          );
        }
        const formulationsIds = entireData.columns.formulations.map(
          (res: any) => res.dataIndex
        );
        missingFormulationIds = expids
          .filter((val: any) => !formulationsIds.includes(val))
          .map(
            (res: any) => `${res} ${t("common.trialIsMissingFromFormulations")}`
          );
        if (formulations.every((res: any) => res.ingredients === "-"))
          invalidTrials.push(t("common.invalidIngredients"));
      }

      const processing = entireData.data.processing;
      if (!!processing.length) {
        const processingParams = processing.map(
          (res: any) => res.processing_parameters
        );
        const processingCats = processing.map(
          (res: any) => res.processing_category
        );
        const duplicateProcessing = processingParams.filter(
          (item: any, index: any) =>
            (processingParams.indexOf(item) !== index && (item !== "-" && !item))
            && (processingCats.indexOf(item) !== index && (processingCats[index] !== "-" && !processingCats[index]))
        );
        if (!!duplicateProcessing.length) {
          invalidTrials.push(
            `${t(
              "common.duplicateProcessingParametersFound"
            )} - ${duplicateProcessing.join(", ")}.`
          );
        }
        if (processing.every((res: any) => res.processing_parameters === "-"))
          invalidTrials.push(t("common.invalidProcessing"));
      }

      const characterisation = entireData.data.characterisation;
      if (!!characterisation.length) {
        const characterisationParams = characterisation.map(
          (res: any) => res.characterisation_parameters
        );
        const duplicateCharacterisation = characterisationParams.filter(
          (item: any, index: any) =>
            characterisationParams.indexOf(item) !== index && item !== "-"
        );
        if (!!duplicateCharacterisation.length) {
          invalidTrials.push(
            `${t(
              "common.duplicateCharacterisationParametersFound"
            )} - ${duplicateCharacterisation.join(", ")}.`
          );
        }

        if (
          characterisation.every(
            (res: any) => res.characterisation_parameters === "-"
          )
        )
          invalidTrials.push(t("common.invalidCharacterisation"));
      }

      const properties = entireData.data.properties;
      if (!!properties.length) {
        const propertyParams = properties.map(
          (res: any) => res.property_parameters
        );
        const propertyCats = properties.map(
          (res: any) => res.property_category
        );
        const propertyMethods = properties.map(
          (res: any) => res.property_method
        );
        const propertyStandards = properties.map(
          (res: any) => res.property_standard
        );
        const propertySpecimens = properties.map(
          (res: any) => res.property_specimen
        );
        const duplicateProperties = propertyParams.filter(
          (item: any, index: any) =>
            (propertyParams.indexOf(item) !== index && (item && item !== "-"))
            && ((!propertyCats[index] && propertyCats[index] !== "-") && propertyCats.indexOf(item) !== index)
            && ((!propertyMethods[index] && propertyStandards[index] !== "-") && propertyMethods.indexOf(item) !== index)
            && ((!propertyStandards[index] && propertyStandards[index] !== "-") && propertyStandards.indexOf(item) !== index)
            && ((!propertySpecimens[index] && propertySpecimens[index] !== "-") && propertySpecimens.indexOf(item) !== index)
        );
        if (!!duplicateProperties.length) {
          invalidTrials.push(
            `${t(
              "common.duplicatePropertyParametersFound"
            )} - ${duplicateProperties.join(", ")}.`
          );
        }
        const propertyIds = entireData.columns.properties.map(
          (res: any) => res.dataIndex
        );
        missingPropertyIds = expids
          .filter((val: any) => !propertyIds.includes(val))
          .map(
            (res: any) => `${res} ${t("common.trialisMissingFromProperties")}`
          );
        if (properties.every((res: any) => res.property_parameters === "-"))
          invalidTrials.push(t("common.invalidProperties"));
        properties.forEach((property: any) => {
          const found = !allMethods?.length || allMethods.some(method => (method.method_name?.trim()?.toLowerCase() === property.property_method?.trim()?.toLowerCase() && method.property_name?.trim()?.toLowerCase() === property.property_parameters?.trim()?.toLowerCase()) || (!property.property_method || property.property_method === "-"))
          if (!found) invalidTrials.push(`"${property.property_method}" method doesn't exist on property "${property.property_parameters}"`)
        })
      }

      expids.forEach((id: any) => {
        if (
          !!formulations.length &&
          formulations.every((record: any) => record[id] === "-")
        )
          invalidTrials.push(
            `${id} ${t("common.hasInvalidDataInTheFormulations")}`
          );
        // if (
        //   !!processing?.length &&
        //   processing.every((record: any) => record[id] === "-")
        // )
        //   invalidTrials.push(
        //     `${id} ${t("common.hasInvalidDataInTheProcessing")}`
        //   );
        if (
          !!characterisation?.length &&
          characterisation.every((record: any) => record[id] === "-")
        )
          invalidTrials.push(
            `${id} ${t("common.hasInvalidDataInTheCharacterisation")}`
          );
        if (
          !!properties?.length &&
          properties.every((record: any) => record[id] === "-")
        )
          invalidTrials.push(
            `${id} ${t("common.hasInvalidDataInTheProperties")}`
          );
      });
      const duplicateTrials = expids.filter(
        (item: any, index: any) => expids.indexOf(item) !== index
      );
      invalidTrials.push(
        ...duplicateTrials.map(
          (res: any) => `${t("common.duplicateExperimentFound")} - ${res}.`
        )
      );

      setErrorList([
        ...missingFormulationIds,
        ...missingPropertyIds,
        ...invalidTrials,
      ]);
    }
  }, [generateData, selectedExps, entireData, t, selectedExpsKeys, workOrder?.is_multiunit, allMethods])

  const onSubmit = () => {
    const parameters: any = { ingredients: [], processing: [], characterisation: [], properties: [] }
    entireData.data.formulations.forEach((res: any) => {
      const category = [null, "-", ""].includes(res?.ingredients_category) ? "Uncategorized" : res?.ingredients_category
      const record = Object.values(displayNames?.ingredients || {}).find((ingredient: any) =>
      (ingredient?.name?.toLowerCase().trim() === String(res?.ingredients).toLowerCase().trim()
        && ingredient?.category?.toLowerCase().trim() === String(category).toLowerCase().trim())
      )
      if (!record) {
        parameters.ingredients.push({ name: res?.ingredients, category: res?.ingredients_category })
      }
    })
    entireData.data.processing.forEach((res: any) => {
      const record = Object.values(displayNames?.processing || {}).find((processing: any) =>
      (processing?.name?.toLowerCase().trim() === String(res?.processing_parameters).toLowerCase().trim()
        && processing?.category?.toLowerCase().trim() === String(res?.processing_category).toLowerCase().trim())
      )
      if (!record) {
        parameters.processing.push({ name: res?.processing_parameters, category: res?.processing_category })
      }
    })
    entireData.data.characterisation.forEach((res: any) => {
      const record = Object.values(displayNames?.characterizations || {}).find((characterization: any) =>
      (characterization?.name?.toLowerCase().trim() === String(res?.characterisation_parameters).toLowerCase().trim()
        && characterization?.category?.toLowerCase().trim() === String(res?.characterisation_category).toLowerCase().trim())
      )
      if (!record) {
        parameters.characterisation.push({ name: res?.characterisation_parameters, category: res?.characterisation_category })
      }
    })
    if (!enablePropertyStandards) {
      entireData.data.properties.forEach((res: any) => {
        const record = Object.values(displayNames?.properties || {}).find((property: any) => (
          property?.name?.toLowerCase().trim() === String(res?.property_parameters).toLowerCase().trim()
          && property?.category?.toLowerCase().trim() === String(res?.property_category).toLowerCase().trim())
        )
        if (!record) {
          parameters.properties.push({ name: res?.property_parameters, category: res?.property_category })
        }
      })
    } else {
      // TODO : Check for Rehau accounts properties
      // entireData.data.properties.forEach((res: any) => {
      //   console.log({ res })
      //   const record = properties.find((property: any) => (
      //     property?.name?.toLowerCase().trim() === String(res?.property_parameters).toLowerCase().trim()
      //     && property?.category_name?.toLowerCase().trim() === String(res?.property_category).toLowerCase().trim())
      //     && String(res?.property_method).toLocaleLowerCase().trim()
      //     && String(res?.property_specimen).toLocaleLowerCase().trim()
      //     && property?.standards?.map((res: any) => res?.toLocaleLowerCase().trim()).includes(String(res?.property_standard).toLocaleLowerCase().trim())
      //   )
      //   if (!record) {
      //     parameters.properties.push({ name: res?.property_parameters, category: res?.property_category, standard: res?.property_standard, method: res?.property_method, specimen: res?.property_specimen })
      //   }
      // })
    }
    if (!enablePropertyStandards && !skipPrompt && (!!parameters.ingredients.length || !!parameters.processing.length || !!parameters.characterisation.length || !!parameters.properties.length)) {
      setNewParameters(parameters)
      setShowNewParametersModal(true)
    } else {
      if (!!unitsData?.length) {
        setCurrent(4)
      } else {
        uploadData()
      }
    }
  }


  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <StyledCard
        title={
          <Space direction="vertical" style={{ width: "100%" }} size="small">
            <Text type="secondary">{t("selectExp.specificTrials")}</Text>
          </Space>
        }
      >
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <div>
            <Search
              placeholder={t("common.search")}
              onSearch={(value) => setSearchText(value)}
              style={{ width: 200, marginBottom: 16 }}
            />
            <Table
              scroll={{ y: 320 }}
              bordered
              columns={expColumns}
              dataSource={searchText ? filteredExpTableData : expTableData}
              pagination={{
                pageSize: 20,
                showSizeChanger: false
              }}
              rowSelection={{
                selectedRowKeys: selectedExpsKeys,
                onChange: (selectedKeys) => {
                  if (searchText) {
                    const filteredKeys = filteredExpTableData.map((exp) => exp.key);
                    const selectionKeysDiff = filteredKeys.filter(key => !selectedKeys.includes(key));
                    if (selectionKeysDiff.length) {

                      setSelectedExpsKeys(selectedExpsKeys.filter((key: any) => !selectionKeysDiff.includes(key)))
                    } else {
                      setSelectedExpsKeys([...new Set([...selectedExpsKeys, ...selectedKeys])]);
                    }
                  } else {
                    setSelectedExpsKeys(selectedKeys)
                  }
                }
              }}
            />
          </div>
          <Tabs size="large">
            {!!tableData?.formulations?.length && (
              <TabPane
                tab={t("common.formulations")}
                key="formulations"
                style={{ width: "100%", overflow: "auto" }}
              >
                <Table
                  columns={entireData.columns.formulations}
                  dataSource={entireData.data.formulations}
                  bordered
                  pagination={false}
                />
              </TabPane>
            )}
            {!!tableData?.processing?.length && (
              <TabPane
                tab={t("common.processing")}
                key="processing"
                style={{ width: "100%", overflow: "auto" }}
              >
                <Table
                  columns={entireData.columns.processing}
                  dataSource={entireData.data.processing}
                  bordered
                  pagination={false}
                />
              </TabPane>
            )}
            {!!tableData?.characterisation?.length && (
              <TabPane
                tab={t("common.characterisation")}
                key="characterisation"
                style={{ width: "100%", overflow: "auto" }}
              >
                <Table
                  columns={entireData.columns.characterisation}
                  dataSource={entireData.data.characterisation}
                  bordered
                  pagination={false}
                />
              </TabPane>
            )}
            {!!tableData?.properties?.length && (
              <TabPane
                tab={t("formulations.type.properties")}
                key="properties"
                style={{ width: "100%", overflow: "auto" }}
              >
                <Table
                  columns={entireData.columns.properties}
                  dataSource={entireData.data.properties}
                  bordered
                  pagination={false}
                />
              </TabPane>
            )}
          </Tabs>
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            {errorList.map((res: any) => (
              <Text type="danger">{res}</Text>
            ))}
          </Space>
        </Space>
      </StyledCard>
      <Row justify="end">
        <Space>
          <StyledButton
            onClick={() => {
              window.open("/inventory/repository/properties", "_blank")
            }}
            danger
            size="large"
          >{`${t("common.open")} ${t("common.repository")}`}</StyledButton>
          <StyledButton
            onClick={() => {
              setCurrent(2);
              setUnitsData([]);
            }}
            size="large"
          >{`${t("common.back")}: ${t("common.mapColumns")}`}</StyledButton>
          <StyledButton
            disabled={!selectedExps.length || !!errorList.length}
            onClick={onSubmit}
            size="large"
            type="primary"
          >{`${t("common.next")}: ${!!unitsData?.length
            ? t("common.mapUnits")
            : t("common.confirmUpload")
            }`}</StyledButton>
        </Space>
      </Row>
      <NewParametersModal newParameters={newParameters} showNewParametersModal={showNewParametersModal}
        setShowNewParametersModal={setShowNewParametersModal} setSkipPrompt={setSkipPrompt} />
    </Space>
  );
};
