import { LoadingOutlined } from '@ant-design/icons'
import { Modal, Row, Space, Spin, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { deleteWorkOrderRequest } from 'src/store/actions/workOrderDetails'
import { StoreState } from 'src/store/configureStore'
import { useEffect } from 'react'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'

const { Text } = Typography


export function DeleteWoModal({ workOrder, deleteModalVisible, setDeleteModalVisible }: any) {
    const dispatch = useDispatch()
    const [t] = useTranslate()
    const { deleteWOStatus } = useSelector((state: StoreState) => state.workOrderDetails)

    useEffect(() => {
        if (deleteWOStatus === AsyncStates.SUCCESS) {
            setDeleteModalVisible(false)
        }
    }, [deleteWOStatus, setDeleteModalVisible])

    const handleOk = () => {
        dispatch(deleteWorkOrderRequest({ work_order_id: workOrder?.work_order_id }))
    }

    const handleCancel = () => {
        setDeleteModalVisible(false)
    }

    return (
        <Modal zIndex={1050} width={450} maskClosable={false}
            title={<Space><Text type="secondary">{`${t("common.confirm")} ${t("common.delete")}`}</Text><Text strong>{`${workOrder?.work_order_name} ?`}</Text></Space>}
            visible={deleteModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
            <Spin spinning={deleteWOStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Space direction="vertical" style={{ width: '100%' }} size="large" onClick={(e) => e.stopPropagation()}>
                    <Text>{t("workOrder.deleteMessage")}</Text>
                </Space>
                <Row style={{ marginTop: 30 }} justify="end">
                    <Space>
                        <StyledButton onClick={handleCancel} type="default">{"Cancel"}</StyledButton>
                        <StyledButton onClick={handleOk} danger type="primary">{`${t("common.confirm")} ${t("common.delete")}`}</StyledButton>
                    </Space>
                </Row>
            </Spin>
        </Modal>
    )
}

