import { Row, Col, Cascader } from "antd"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { forwardRef, useCallback, memo, useMemo, Dispatch, SetStateAction, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { getDisplayNameElement } from "src/utils/general/getDisplayNameElement"
import useTranslate from "src/utils/useTranslate"
import { analyticsRecipeDistributionRequest, analyticsRecipeDistributionTableClear, analyticsRecipeDistributionTableRequest } from "src/store/actions/dataSummary"
import { AsyncStates, recipeDistributionColorCodes } from "src/constants"
import { operatorConstantsMapper } from "./AnalyticsReciepeDistributionFilterForm"
import { useValue } from "src/utils/useValue"
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/heatmap")(Highcharts)
require("highcharts-coloraxis-bands")(Highcharts)
require("highcharts-contour")(Highcharts)
require("highcharts-draggable-3d")(Highcharts)
// import workerUrl from "src/workers/recipeService"

/**
 * props
 */
interface AnalyticsRecipeDistributionPlotProps {
    /**
     * Filters visibility status
     */
    visibilityArray: boolean[],
    filterValues: any[],
    /**
     * Selected recipes
     */
    setselectedRecipes: any,
    selectedRecipes: string[],
    experiments: any[],
    recipeDistributionFrom: string,
    Xaxis: string[],
    Yaxis: string[],
    Zaxis: string[],
    setYaxis: Dispatch<SetStateAction<any>>
    setXaxis: Dispatch<SetStateAction<any>>
    setZaxis: Dispatch<SetStateAction<any>>
    selectedExperiments: string[],
    workOrderIds: string[],
    selectedFormulations: string[]
}

/**
     * Recipes distribution bubble plots
     * @category AI Engine
     * @subcategory Inverse Prediction
     * @component SuggestedExpPlot
     * @function AnalyticsRecipeDistributionPlot
    //  * @param {AnalyticsRecipeDistributionPlot} props For plots
     */
export const AnalyticsRecipeDistributionPlot = memo(forwardRef(({ visibilityArray, filterValues, setselectedRecipes, selectedRecipes, experiments, recipeDistributionFrom, Xaxis, Yaxis, Zaxis, setYaxis, setXaxis, setZaxis, selectedExperiments, workOrderIds, selectedFormulations }: AnalyticsRecipeDistributionPlotProps, chartRef: any) => {
    /**
     * @member {string} Xaxis Label for X
     * @memberof SuggestedExpPlot
     * @instance
     */
    /**
     * @member {string} Yaxis Label for Y
     * @memberof AnalyticsRecipeDistributionPlot
     * @instance
     */
    const dispatch = useDispatch()

    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    /**
     * Drag Selection
     * @function selectPointsByDrag
     * @memberof AnalyticsRecipeDistributionPlot
     * @param {any} e Point info
     * @instance
     */

    // const recipeWorkerService = useMemo(() => new Worker(workerUrl), [])
    // const [chartData, SetChartData] = useState()
    const configs = useSelector((state: StoreState) => state.configs.features)
    const [t] = useTranslate()
    const { getValue: getEUValue } = useValue()
    const { plotOptions, plotOptionsFormulationDisplayNames } = useSelector((state: StoreState) => state.dataSummary.dataSummaryPlotOptions)
    const { analyticsRecipeDistributionTable: { analyticsRecipeDistributionTableData }, analyticsRecipeDistributionTableStatus } = useSelector((state: StoreState) => state.dataSummary);

    const getCascaderOptions = useCallback((parameter: string) => {
        const recipeData: any[] = plotOptions?.[parameter] ?? []
        if (['properties', 'processing'].includes(parameter) && Boolean(configs?.nestle_configs)) {
            if (parameter === "properties") {
                const variations = plotOptions?.variation_data?.properties || {}
                return Object.keys(variations || {}).map((variation) => ({
                    value: variation,
                    label: variations?.[variation]?.variation_name,
                    children: variations?.[variation]?.properties?.map((property: any) => ({
                        label: plotOptionsFormulationDisplayNames?.[property] || displayNames['properties'][property]?.name,
                        value: property
                    }))
                }))
            } else {
                const processingProfiles = plotOptions?.variation_data?.processing || {}
                return [{
                    value: "profile",
                    label: "Profile",
                    children: Object.keys(processingProfiles || {}).map((profile: any) => ({
                        label: processingProfiles?.[profile] ?? profile,
                        value: profile
                    }))
                }]
            }


            // return parameter === 'processing' ? [...new Set(recipeDistributionData?.flatMap(value => value.processing.flatMap((value: any) => value?.meta?.profile_id)))].map(value => ({ value, label: processingProfilesList.find(({ profile_id }: any) => profile_id === value).name }))
            //     : data.length > 0 ? variation_ids.map((var_id: any) => ({
            //         value: var_id,
            //         label: displayNames?.characterization_methods[var_id]?.name,
            //         children: Object.keys(getstruct(data.filter(((recipe: any) => recipe?.variation_id)).map(({ data }: any) => data))).map(pro_id => ({ value: pro_id, label: displayNames?.properties[pro_id].name })
            //         )
            //     })
            //     ) : []
        } else if (parameter === 'costing') {
            return [
                { value: 'total_cost', label: 'Formulation Cost' },
                ...plotOptions?.["ingredients"].map((value: any) => ({ value, label: displayNames?.ingredients[value as any]?.name + " Cost" }))
            ]
        } else {
            return [...recipeData?.map(value => ({ value: value, label: displayNames.hasOwnProperty(parameter) ? displayNames[parameter][value]?.name || plotOptionsFormulationDisplayNames?.[value] || value : displayNames['properties'][value]?.name }))]
        }
    }, [configs?.nestle_configs, displayNames, plotOptions, plotOptionsFormulationDisplayNames])

    const options: any = useMemo(() => ([
        {
            ...(!!plotOptions?.["ingredients"]?.length && {
                value: 'ingredients',
                label: t('common.ingredients'),
                children: [...getCascaderOptions('ingredients')]
            }),
        },
        {
            ...(!!plotOptions?.["properties"]?.length && {
                value: 'properties',
                label: t('common.properties'),
                children: [...getCascaderOptions('properties')],
            }),
        },
        {
            ...((!!plotOptions?.["processing"]?.length && !Boolean(configs?.nestle_configs)) && {
                value: 'processing',
                label: t('common.processing'),
                children: [...getCascaderOptions('processing')]
            })
        },
        {
            ...(Boolean(configs?.work_order_costing) && !!plotOptions?.["costing"]?.length && !Boolean(configs?.nestle_configs) && ({
                value: 'costing',
                label: t('inventory.costing'),
                children: [...getCascaderOptions('cost')]
            }))
        },
    ].filter(values => JSON.stringify(values) !== '{}') ?? []), [plotOptions, t, getCascaderOptions, configs?.nestle_configs, configs?.work_order_costing])

    useEffect(() => {
        const storedFormulations = [...new Set(analyticsRecipeDistributionTableData.map((exp) => exp.id_set.formulation_id))]
        const selectedFormulation = selectedRecipes.filter((recipe) => !storedFormulations.includes(recipe))
        if (!!selectedFormulation.length) {
            dispatch(analyticsRecipeDistributionTableRequest({ formulation_id: selectedFormulation }))
        }
    }, [dispatch, analyticsRecipeDistributionTableData, selectedRecipes])

    useEffect(() => {
        if (analyticsRecipeDistributionTableStatus === AsyncStates.ERROR) {
            const storedFormulations = [...new Set(analyticsRecipeDistributionTableData.map((exp) => exp.id_set.formulation_id))]
            setselectedRecipes(storedFormulations)
        }
    }, [analyticsRecipeDistributionTableData, analyticsRecipeDistributionTableStatus, setselectedRecipes])

    const selectByClick = useCallback((e: any) => {
        const selectedFormulation = e.point.trial_id
        setselectedRecipes((prevState: any) => {
            if (prevState.includes(selectedFormulation)) {
                return prevState.filter((rec: any) => rec !== selectedFormulation)
            } else {
                return [...prevState, selectedFormulation]
            }
        })
    }, [setselectedRecipes])

    const getAxisValue = useCallback((axisState: any) => {
        if (Boolean(configs?.nestle_configs) && axisState?.[0] === 'properties' && recipeDistributionFrom === "data-summary") {
            return `${axisState[2]}---${axisState[1]}`
        } else if (axisState[0] === 'cost') {
            return `${axisState[1]}---${axisState[0]}`
        } else if (["work_order_id", "material", "grade"].includes(axisState[0])) {
            return axisState[0]
        } else {
            return axisState[1]
        }
    }, [configs, recipeDistributionFrom])

    const getColor = useCallback((exp: any) => {
        let color: string[] = []
        let indices: number[] = []
        filterValues.forEach(({ param, op, val, val2, work_order_id, material, grade }: { param: any[], op: string, val: string, val2?: string, work_order_id?: string, material: string, grade: string }, index: number) => {
            if (visibilityArray[index]) {
                const parameter = getAxisValue(param)
                if (parameter && exp?.filters?.[parameter]) {
                    color.push(recipeDistributionColorCodes[index])
                    indices.push(index)
                }
            }
        });
        return { color, indices }
    }, [filterValues, visibilityArray, getAxisValue])

    const radialGradGen = (colors: string[]) => {
        const width = 1 / colors.length
        const transColors = colors.map(col => col)
        const stops = colors.flatMap((val, idx) => [[idx * width, transColors[idx]], [(idx + 1) * width, transColors[idx]]])
        return stops
    }

    const getValue = useCallback((value: any, parameterName: string) => {
        if (parameterName === "properties") {
            return (value === "" || value === null) ? null : Number(value)
        } else {
            return (value === '' || value === 0 || value === null) ? null : Number(value)
        }
    }, [])

    const handleApplyFilter = (xAxisData: string[], yAxisData: string[], zAxisData: string[]) => {
        const filters = filterValues.reduce((acc: any[], filter: any, index: number) => {
            if ((filter?.param?.length > 0) && ((!!filter?.op?.length) ? (filter.op === "range" ? ((filter.val2 !== null && filter.val2 !== "") && (filter.val !== null && filter.val !== ""))
                : (["work_order_id", "material", "grade"].includes(filter?.param[0]) ? !!filter?.[filter?.param?.[0]]?.length : (filter?.val !== null && filter?.val !== ""))) : false) && visibilityArray[index]) {
                acc.push({
                    "val": ["work_order_id", "material", "grade"].includes(filter?.param?.[0]) ? filter?.[filter?.param?.[0]] : filter?.val,
                    "max": filter?.val2,
                    "parameter": ["work_order_id", "material", "grade"].includes(filter?.param?.[0]) ? filter?.param?.[0] : filter?.param?.[1],
                    "parameter_type": filter?.param?.[0],
                    "operator": operatorConstantsMapper[filter?.op] ?? filter?.op
                })
            }
            return acc
        }, [])
        const paramTypes = ["properties", "processing"]
        dispatch(analyticsRecipeDistributionRequest({
            x: { "type": xAxisData[0], "value": xAxisData.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(xAxisData[0]))) && { category: xAxisData[1] }) },
            y: { "type": yAxisData[0], "value": yAxisData.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(yAxisData[0]))) && { category: yAxisData[1] }) },
            ...(!!zAxisData?.length && { z: { "type": zAxisData[0], "value": zAxisData.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(zAxisData[0]))) && { category: zAxisData[1] }) } }),
            ...(!!filters.length && { filters }),
            ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: selectedExperiments }),
            work_order_id: workOrderIds
        }))
    }

    const HighchartsOptions = useMemo(() => (
        {
            credits: {
                enabled: false
            },
            chart: {
                type: 'bubble',
                plotBorderWidth: 0.5,
                zoomType: 'xy',
                events: {
                    // selection: selectPointsByDrag,
                },
            },
            legend: {
                enabled: false
            },
            title: {
                text: null
            },
            accessibility: {
                point: {
                    valueDescriptionFormat: '{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, obesity: {point.z}%.'
                }
            },
            xAxis: {
                gridLineWidth: 1,
                labels: {
                    formatter: function (this: any) {
                        const point = getEUValue(this.value)
                        return point;
                    }
                },
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    formatter: function (this: any) {
                        const point = getEUValue(this.value)
                        return point;
                    }
                },
                maxPadding: 0.2,
            },
            tooltip: {
                useHTML: true,
                headerFormat: "",
                pointFormatter: function (this: any) {
                    const getName = (nameArg: string) => Boolean(configs?.nestle_configs) ? nameArg.split('---')[0] : nameArg
                    const getCostingName = (nameArg: string) => {
                        if (nameArg?.includes('cost')) {
                            const [costingName] = nameArg.split('---')
                            return costingName === 'total_cost' ? 'Total Cost' : `${displayNames.ingredients[costingName]?.name}(Cost)`
                        } else return nameArg
                    }

                    const XdisplayName = experiments?.[0]?.ingredients?.[this?.xName]?.name ?? plotOptionsFormulationDisplayNames?.[this?.xName] ?? getDisplayNameElement(displayNames, "ingredients", this?.xName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.xName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.xName)?.name ?? this?.xName
                    const ydisplayName = experiments?.[0]?.ingredients?.[this?.yName]?.name ?? plotOptionsFormulationDisplayNames?.[this?.yName] ?? getDisplayNameElement(displayNames, "ingredients", this?.yName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.yName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.yName)?.name ?? this?.yName
                    return `<strong>${this?.exp}</strong><br/><br/>
                    <strong>${getCostingName(ydisplayName)}: </strong><span>${getEUValue(this.y)}</span><br/>
                    <strong>${getCostingName(XdisplayName)}: </strong><span>${getEUValue(this.x)}</span>`
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    },
                    // allowPointSelect: true,
                    point: {
                        events: {
                            click: selectByClick,
                            // unselect: unselectByClick,
                        }
                    }
                },
                bubble: {
                    minSize: 10,
                    maxSize: 18
                }
            },
            series: [{
                data: (!!Xaxis.length && !!Yaxis.length ? experiments?.map((exp: any, index: number) => {
                    return ({
                        trial_id: exp.formulation_id,
                        exp: exp?.formulation_display_id ?? exp.formulation_id,
                        xName: exp?.x?.parameter,
                        yName: exp?.y?.parameter,
                        zName: exp?.z?.parameter,
                        z: getColor(exp).indices?.length * 10,
                        x: getValue(exp?.x?.value ?? null, exp?.x?.type ?? Xaxis?.[0]),
                        y: getValue(exp?.y?.value ?? null, exp?.y?.type ?? Yaxis?.[0]),
                        marker: {
                            fillColor: {
                                radialGradient: { cx: 0.5, cy: 0.5, r: .5 },
                                stops: radialGradGen(getColor(exp).color)
                            }
                        },
                        color: getColor(exp).color?.at(-1),
                        selected: selectedRecipes.includes(exp?.formulation_id)
                        // name: getColor(exp)?.indices
                    })
                }) : []),
            }],
            colors: ['grey']
        }
    ), [selectByClick, Xaxis, Yaxis, experiments, plotOptionsFormulationDisplayNames, displayNames, configs?.nestle_configs, getColor, selectedRecipes, getValue, getEUValue])


    const Highcharts3dOptions = useMemo(() => (
        {
            credits: {
                enabled: false
            },
            chart: {
                height: 500,
                width: 700,
                type: 'scatter3d',
                options3d: {
                    enabled: true,
                    alpha: 20,
                    beta: 30,
                    depth: 200,
                    viewDistance: 10,
                    frame: {
                        bottom: {
                            size: 1,
                            color: 'rgba(0,0,0,0.05)'
                        }
                    },
                    drag: {
                        enabled: true,
                        minBeta: Number.NEGATIVE_INFINITY,
                        maxBeta: Number.POSITIVE_INFINITY,
                        snap: 15,
                        animateSnap: true,
                        flipAxes: true,
                    },
                    fitToPlot: true,
                },
            },
            legend: {
                enabled: false
            },
            title: {
                text: null
            },
            accessibility: {
                point: {
                    valueDescriptionFormat: '{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, obesity: {point.z}%.'
                }
            },
            xAxis: {
                allowOverlap: true,
                gridLineWidth: 1,
                labels: {
                    crop: false,
                    overflow: 'justify',
                    formatter: function (this: any) {
                        const point = getEUValue(this.value)
                        return point;
                    }
                },
            },
            yAxis: {
                title: {
                    text: null
                },
                labels: {
                    formatter: function (this: any) {
                        const point = getEUValue(this.value)
                        return point;
                    }
                },
                maxPadding: 0.2,
            },
            zAxis: {
                title: {
                    text: null
                },
                labels: {
                    formatter: function (this: any) {
                        const point = getEUValue(this.value)
                        return point;
                    }
                },
                showFirstLabel: false,
                maxPadding: 0.2,
            },
            tooltip: {
                useHTML: true,
                headerFormat: "",
                pointFormatter: function (this: any) {
                    const getName = (nameArg: string) => Boolean(configs?.nestle_configs) ? nameArg.split('---')[0] : nameArg
                    const getCostingName = (nameArg: string) => {
                        if (nameArg?.includes('cost')) {
                            const [costingName] = nameArg.split('---')
                            return costingName === 'total_cost' ? 'Total Cost' : `${displayNames.ingredients[costingName]?.name}(Cost)`
                        } else return nameArg
                    }

                    const xdisplayName = experiments?.[0]?.ingredients?.[this?.xName]?.name ?? plotOptionsFormulationDisplayNames?.[this?.xName] ?? getDisplayNameElement(displayNames, "ingredients", this?.xName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.xName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.xName)?.name ?? this?.xName
                    const ydisplayName = experiments?.[0]?.ingredients?.[this?.yName]?.name ?? plotOptionsFormulationDisplayNames?.[this?.yName] ?? getDisplayNameElement(displayNames, "ingredients", this?.yName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.yName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.yName)?.name ?? this?.yName
                    const zdisplayName = experiments?.[0]?.ingredients?.[this?.zName]?.name ?? plotOptionsFormulationDisplayNames?.[this?.zName] ?? getDisplayNameElement(displayNames, "ingredients", this?.zName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.zName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.zName)?.name ?? this?.zName
                    return `<strong>${this?.exp}</strong><br/><br/>
                    <strong>${getCostingName(xdisplayName)}: </strong><span>${getEUValue(this.x)}</span><br/>
                    <strong>${getCostingName(ydisplayName)}: </strong><span>${getEUValue(this.y)}</span><br/>
                    <strong>${getCostingName(zdisplayName)}: </strong><span>${getEUValue(this.z)}</span>`
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    },
                    // allowPointSelect: true,
                    point: {
                        events: {
                            click: selectByClick,
                            // unselect: unselectByClick,
                        }
                    }
                },
            },
            series: [{
                data: (!!Xaxis.length && !!Yaxis.length && !!Zaxis?.length ? experiments?.map((exp: any, index: number) => {
                    return ({
                        trial_id: exp.formulation_id,
                        exp: exp?.formulation_display_id ?? exp.formulation_id,
                        xName: exp?.x?.parameter,
                        yName: exp?.y?.parameter,
                        zName: exp?.z?.parameter,
                        x: getValue(exp?.x?.value ?? null, exp?.x?.type ?? Xaxis?.[0]),
                        y: getValue(exp?.y?.value ?? null, exp?.y?.type ?? Yaxis?.[0]),
                        z: getValue(exp?.z?.value ?? null, exp?.z?.type ?? Zaxis?.[0]),
                        marker: {
                            radius: 8,
                            fillColor: {
                                radialGradient: { cx: 0.5, cy: 0.5, r: .5 },
                                stops: !!radialGradGen(getColor(exp)?.color)?.length ? radialGradGen(getColor(exp)?.color) : [[0, "grey"]]
                            }
                        },
                        color: getColor(exp).color?.at(-1),
                        selected: selectedRecipes.includes(exp?.formulation_id)
                        // name: getColor(exp)?.indices
                    })
                }) : []),
            }],
            colors: ['grey']
        }
    ), [selectByClick, Xaxis, Yaxis, Zaxis, experiments, plotOptionsFormulationDisplayNames, displayNames, configs?.nestle_configs, getColor, selectedRecipes, getValue, getEUValue])

    const displayRender = (labels: string[]) => labels.join('/')


    return (
        <Row align="middle">
            <Col style={{ width: 40 }}>
                <Cascader style={{ width: 200, transform: 'translate(-100px, 0px) rotate(-90deg)' }}
                    options={options}
                    value={Yaxis}
                    displayRender={displayRender}
                    showSearch
                    onChange={(value: any) => {
                        setYaxis(value ?? [])
                        if (!!Xaxis.length && !!value?.length) {
                            handleApplyFilter(Xaxis, value, Zaxis)
                        }
                        if (!!selectedRecipes?.length) {
                            setselectedRecipes([])
                            dispatch(analyticsRecipeDistributionTableClear())
                        }
                    }}
                    placeholder={t("common.pleaseSelect")} />
            </Col>
            <Col>
                <Row style={{
                    cursor: analyticsRecipeDistributionTableStatus === AsyncStates.LOADING ? "progress" : "auto",
                }}>
                    <div style={{ display: !!Zaxis?.length ? "block" : "none" }}>
                        <HighchartsReact
                            ref={chartRef as any}
                            highcharts={Highcharts}
                            options={Highcharts3dOptions}
                            containerProps={{ style: { width: '100%' } }}
                        />
                    </div>
                    <div style={{ display: !Zaxis?.length ? "block" : "none" }}>
                        <HighchartsReact
                            ref={chartRef as any}
                            highcharts={Highcharts}
                            options={HighchartsOptions}
                        />
                    </div>
                </Row>
                <Row justify="center">
                    <Cascader style={{ width: 200 }}
                        options={options}
                        value={Xaxis}
                        displayRender={displayRender}
                        showSearch
                        onChange={(value: any) => {
                            setXaxis(value ?? [])
                            if (!!Yaxis.length && !!value?.length) {
                                handleApplyFilter(value, Yaxis, Zaxis)
                            }
                            if (!!selectedRecipes?.length) {
                                setselectedRecipes([])
                                dispatch(analyticsRecipeDistributionTableClear())
                            }
                        }}
                        placeholder={t("common.pleaseSelect")} />
                </Row>
            </Col>
            <Col style={{ width: 40, marginLeft: 30 }}>
                <Cascader style={{ width: 200, transform: 'translate(-100px, 0px) rotate(-90deg)' }}
                    options={options}
                    value={Zaxis}
                    displayRender={displayRender}
                    showSearch
                    onChange={(value: any) => {
                        setZaxis(value ?? [])
                        if (!!Xaxis.length && !!Yaxis?.length && !!value?.length) {
                            handleApplyFilter(Xaxis, Yaxis, value)
                        }
                        if (!!selectedRecipes?.length) {
                            setselectedRecipes([])
                            dispatch(analyticsRecipeDistributionTableClear())
                        }
                    }}
                    placeholder={t("common.pleaseSelect")} />
            </Col>
        </Row>
    )
}))
