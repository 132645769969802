
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`일반화된 부분집합`}</h1>
    <p>{`일반화된 부분집합 설계법(GSD)은 다수의 다중 수준 요인을 다룰 때 축소된 설계를 제공합니다. 이 설계법은 논리적으로 연결된 일련의 축소된 설계 부분집합을 생성합니다. 이 부분집합을 결합하면 모든 부분집합이 완전한 다중수준 다요인 설계를 형성합니다.`}</p>
    <p>{`일반화된 부분집합 설계법(GSD)은 개별적으로 또는 결합하여 사용할 수 있는 일련의 축소된 설계 세트를 생성합니다. 각 개별 설계 세트의 목표는 가능한 모든 요인 조합의 균형 잡힌, 독특한 및 대표적인 샘플이 되는 것입니다.`}</p>
    <p><strong parentName="p">{`사용 사례:`}</strong></p>
    <ul>
      <li parentName="ul">{`효율적인 안정성 연구를 생성하여, 최대 70% 효율성 증가`}</li>
      <li parentName="ul">{`양적 및 질적 측면에서 다중 수준 요인의 스크리닝하기 위해`}</li>
      <li parentName="ul">{`대규모 스크리닝 DOE 디자인의 순차적 접근`}</li>
      <li parentName="ul">{`데이터베이스 또는 대용량 데이터 응용에서 편향되지 않은 샘플 선택`}</li>
      <li parentName="ul">{`다변량 보정 내 DOE`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;