import { createActions } from "redux-actions";
import { FormulationDatum } from "src/services/formulation/interface";

export enum LinkedFormulationsAiEngineActionTypes {
    REQUEST = "LINKED_FORMULATIONS_AI_ENGINE_REQUEST",
    SUCCESS = "LINKED_FORMULATIONS_AI_ENGINE_SUCCESS",
    FAILURE = "LINKED_FORMULATIONS_AI_ENGINE_FAILURE",
    CLEAR = "LINKED_FORMULATIONS_AI_ENGINE_CLEAR",
}


export const {
    linkedFormulationsAiEngineRequest,
    linkedFormulationsAiEngineSuccess,
    linkedFormulationsAiEngineFailure,
    linkedFormulationsAiEngineClear,
} = createActions({
    [LinkedFormulationsAiEngineActionTypes.REQUEST]: (payload: any) => (payload),
    [LinkedFormulationsAiEngineActionTypes.SUCCESS]: (payload: FormulationDatum[]) => payload,
    [LinkedFormulationsAiEngineActionTypes.FAILURE]: (error: string) => ({ error }),
    [LinkedFormulationsAiEngineActionTypes.CLEAR]: () => { },
})