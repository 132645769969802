import { LeftOutlined, RightOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Space, Typography, Row } from "antd";
import { useDispatch, useSelector } from "react-redux"
import { qcSetPageNumber, qcToggleFileNavigation } from "src/store/actions/qualityCheck"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"
import { QualityCheckStatus } from ".."
import StyledBadge from "src/styled_components/StyledBadge";
import { StyledButton } from "src/styled_components/StyledButton";


const PageNumberNavigation = ({ numPages }: any) => {
  const dispatch = useDispatch()
  const { pageNumber } = useSelector(
    (state: StoreState) => state.qualityCheck,
  )

  const buttonStyle: React.CSSProperties = {
    background: "#434343",
    border: "1px solid #434343",
    outline: "none",
    fontWeight:600
  }
  const textStyle: React.CSSProperties = {
    background: "#434343",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  }
  return (
    <Row style={{ gap: 0 }}>
      <StyledButton
        onClick={() => {
          pageNumber && dispatch(qcSetPageNumber(pageNumber - 1))
        }}
        disabled={pageNumber === 1}
        style={{
          ...buttonStyle,
          borderRadius: "6px 0px 0px 6px",
          ...(pageNumber === 1
            ? { color: "rgba(0, 0, 0, 0.45)" }
            : { color: "white" }),
        }}
      >
        <LeftOutlined />
      </StyledButton>
      <Typography.Text strong style={textStyle}>
        {pageNumber}/{numPages}
      </Typography.Text>
      <StyledButton
        onClick={() => {
          pageNumber && dispatch(qcSetPageNumber(pageNumber + 1))
        }}
        disabled={pageNumber === numPages}
        style={{
          ...buttonStyle,
          borderRadius: "0px 6px 6px 0px",
          ...(pageNumber === numPages
            ? { color: "rgba(0, 0, 0, 0.45)" }
            : { color: "white" }),
        }}
      >
        <RightOutlined />
      </StyledButton>
    </Row>
  )
}

const PageZoomNavigation = ({ pageScale, setPageScale }: any) => {
  const buttonStyle: React.CSSProperties = {
    background: "#434343",
    border: "1px solid #434343",
    outline: "none",
  }
  const textStyle: React.CSSProperties = {
    background: "#434343",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  }
  return (
    <Row style={{ gap: 0 }}>
      <StyledButton
        onClick={() => {
          setPageScale((val: number) => Number((val - 0.1).toPrecision(3)))
        }}
        disabled={pageScale === 0.5}
        style={{
          ...buttonStyle,
          borderRadius: "6px 0px 0px 6px",
          ...(pageScale === 0.5
            ? { color: "rgba(0, 0, 0, 0.45)" }
            : { color: "white" }),
        }}
      >
        <MinusOutlined />
      </StyledButton>
      <Typography.Text strong style={textStyle}>
        {Math.round(pageScale * 100)}%
      </Typography.Text>
      <StyledButton
        onClick={() => {
          setPageScale((val: number) => Number((val + 0.1).toPrecision(3)))
        }}
        disabled={pageScale === 2.0}
        style={{
          ...buttonStyle,
          borderRadius: "0px 6px 6px 0px",
          ...(pageScale === 2.0
            ? { color: "rgba(0, 0, 0, 0.45)" }
            : { color: "white" }),
        }}
      >
        <PlusOutlined />
      </StyledButton>
    </Row>
  )
}

export const TopActionBar = ({
  numPages,
  pageScale,
  setPageScale,
  openReportSection,
  setOpenReportSection,
  docRendered
}: any) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { selectedFile, isReportSectionUnsaved } = useSelector(
    (state: StoreState) => state.qualityCheck,
  )

  return (
    <div
      style={{
        height: 64,
        backgroundColor: "#262626",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "white",
      }}
    >
      <div></div>

      {docRendered && <Space>
        <PageNumberNavigation
          numPages={numPages}
        />
        <PageZoomNavigation pageScale={pageScale} setPageScale={setPageScale} />
      </Space>}

      <StyledBadge dot={isReportSectionUnsaved} >
        <StyledButton
          style={{ outline: "none", backgroundColor: "white", fontWeight:600 }}
          onClick={() => {
            setOpenReportSection((state: boolean) => !state);
            dispatch(qcToggleFileNavigation());
          }}
          disabled={
            selectedFile?.status !== QualityCheckStatus.COMPLETED && selectedFile?.status !== QualityCheckStatus.TABLE_EXTRACTED
          }
        >
          {`${openReportSection ? t('common.hide') :t('common.view')} ${t("common.results")}`}
        </StyledButton>
      </StyledBadge>
    </div>
  )
}
