import { useState } from "react";
import { Input } from "antd";
import { PCM } from "./types"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton";

type P = {
	data: PCM
	setData: (data: PCM) => void
	defaultValue: string
}

export const ColorInput = ({ setData, data, defaultValue }: P) => {
	const [t] = useTranslate()
	const [color, setColor] = useState(defaultValue)

	function updateColor() {
		setData({ ...data, color })
	}

	return (
		<div
			style={{
				display: "flex",
				gap: "2px",
				width: "100%",
				justifyContent: "space-between",
			}}
		>
			<Input
				type="color"
				value={color}
				onChange={(e) => setColor(e.target.value)}
			/>
			<StyledButton onClick={updateColor}>{t("common.update")}</StyledButton>
		</div>
	)
}
