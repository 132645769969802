import { handleActions } from "redux-actions";
import { CheckWorkOrderExistsActionTypes, CloseAllWorkOrdersActionTypes, SelectTrialsActionTypes, WorkOrdersActionTypes, WorkOrdersFilterOptionsActionTypes } from "../actions/workOrders";
import { AsyncStates } from "../../constants";

export type WorkOrdersState = {
  status: AsyncStates;
  error: string;
  data: any[];
  count: number;
  selectedTrials: any,
  isWorkOrderExists: boolean,
  checkWorkOrderExistStatus: AsyncStates,
  checkWorkOrderExistError: string,
  closeAllWorkOrdersStatus: AsyncStates,
  closeAllWorkOrdersSuccess: string,
  closeAllWorkOrdersError: string,
  filterOptionsStatus: AsyncStates,
  filterOptions: Record<string, any>,
  filterOptionsError: string
};

const defaultState: WorkOrdersState = {
  status: AsyncStates.INITIAL,
  error: "",
  data: [],
  count: 0,
  selectedTrials: [],
  isWorkOrderExists: false,
  checkWorkOrderExistStatus: AsyncStates.INITIAL,
  checkWorkOrderExistError: "",
  closeAllWorkOrdersStatus: AsyncStates.INITIAL,
  closeAllWorkOrdersSuccess: "",
  closeAllWorkOrdersError: "",
  filterOptionsStatus: AsyncStates.INITIAL,
  filterOptions: {},
  filterOptionsError: "",
};

const workOrdersReducer = handleActions(
  {
    [WorkOrdersActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [WorkOrdersActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        data: action?.payload?.data ?? [],
        count: action?.payload?.count ?? 0,
      };
    },
    [WorkOrdersActionTypes.CLEAR]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
      };
    },
    [WorkOrdersActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [SelectTrialsActionTypes.SET_SELECTED_TRIALS]: (state, action) => ({
      ...state,
      selectedTrials: action.payload,
    }),
    [CheckWorkOrderExistsActionTypes.REQUEST]: (state, action) => ({
      ...state,
      checkWorkOrderExistStatus: AsyncStates.LOADING,
      isWorkOrderExists: false,
      checkWorkOrderExistError: ''
    }),
    [CheckWorkOrderExistsActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      checkWorkOrderExistStatus: AsyncStates.SUCCESS,
      isWorkOrderExists: action.payload,
      checkWorkOrderExistError: ''
    }),
    [CheckWorkOrderExistsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      checkWorkOrderExistStatus: AsyncStates.ERROR,
    }),
    [CheckWorkOrderExistsActionTypes.CLEAR]: (state, action) => ({
      ...state,
      checkWorkOrderExistStatus: AsyncStates.INITIAL,
      isWorkOrderExists: false,
      checkWorkOrderExistError: ""
    }),

    [CloseAllWorkOrdersActionTypes.REQUEST]: (state, action) => ({
      ...state,
      closeAllWorkOrdersStatus: AsyncStates.LOADING,
      closeAllWorkOrdersError: ''
    }),
    [CloseAllWorkOrdersActionTypes.SUCCESS]: (state, action: any) => ({
      ...state,
      closeAllWorkOrdersStatus: AsyncStates.SUCCESS,
      closeAllWorkOrdersSuccess: action.payload,
      closeAllWorkOrdersError: ''
    }),
    [CloseAllWorkOrdersActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      closeAllWorkOrdersStatus: AsyncStates.ERROR,
      closeAllWorkOrdersError: action.payload.error
    }),
    [CloseAllWorkOrdersActionTypes.CLEAR]: (state, action) => ({
      ...state,
      closeAllWorkOrdersStatus: AsyncStates.INITIAL,
      closeAllWorkOrdersSuccess: '',
      closeAllWorkOrdersError: ""
    }),
    [WorkOrdersFilterOptionsActionTypes.REQUEST]: (state) => ({
      ...state,
      filterOptionsStatus: AsyncStates.LOADING,
      filterOptionsError: "",
    }),
    [WorkOrdersFilterOptionsActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        filterOptionsStatus: AsyncStates.SUCCESS,
        filterOptionsError: "",
        filterOptions: action?.payload?.data ?? {},
      };
    },
    [WorkOrdersFilterOptionsActionTypes.CLEAR]: (state, action) => {
      return {
        ...state,
        filterOptionsStatus: AsyncStates.INITIAL,
        filterOptionsError: "",
        filterOptions: {},
      };
    },
    [WorkOrdersFilterOptionsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      filterOptionsStatus: AsyncStates.ERROR,
      filterOptionsError: action?.payload?.error,
    }),
  },
  defaultState
);

export default workOrdersReducer;
