import { useEffect, useState } from "react";
import { Form as AntdForm, Select } from "antd";
import { FormData } from "./pivot-table-analysis"
import { aggregationMethods, AggregationMethod } from "./types"
import { toTitleCase } from "src/utils/general"
import useTranslate, { TranslateFunc } from "src/utils/useTranslate"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { getDisplayNameElement } from "src/utils/general/getDisplayNameElement"
import { AsyncStates } from "src/constants"
import { PlotType } from "src/components/Analytics/enums"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
import { StyledButton } from "src/styled_components/StyledButton"
const { Option } = Select

type P = {
	ingredients: string[]
	properties: string[]
	submitData: (data: FormData) => void
	getDisplayNameCallback: (value: string) => string,
	setSaveGraphsPayload: any,
	selectedPlotsData: { [key: string]: any }
}

function translateAggregationMethod(
	method: AggregationMethod,
	t: TranslateFunc
) {
	if (method === "max") return t("common.max")
	if (method === "min") return t("common.min")
	if (method === "mean") return t("common.mean")
	if (method === "median") return t("common.median")
	if (method === "sum") return t("common.sum")
	return method
}

export const Form = ({ ingredients, properties, submitData, getDisplayNameCallback, setSaveGraphsPayload, selectedPlotsData }: P) => {
	const [t] = useTranslate()
	const requiredFieldStar = useRequiredFieldStar()

	const [form] = AntdForm.useForm<FormData>()
	const displayNames = useSelector((state: StoreState) => state.displayNames.data)
	const { status: pivotTableStatus } = useSelector((state: StoreState) => state.pivotTable)
	const [ingredientsForm, setIngredientsForm] = useState({
		var_x: "",
		var_y: ""
	})

	const getDisplayName = (keyType: string, identifier: string) => {
		return getDisplayNameElement(displayNames, keyType, identifier)?.name
	}

	function onSubmit(values: FormData) {
		submitData(values)
	}

	useEffect(() => {
		if (Object.keys(selectedPlotsData?.data?.plot_data ?? {}).includes(PlotType.PIVOT_ANALYSIS)) {
			const data = selectedPlotsData?.data?.plot_data?.[PlotType.PIVOT_ANALYSIS] ?? {}
			if (ingredients.includes(data?.x?.[0])) {
				form.setFieldValue("var_x", data?.x?.[0])
			}
			if (ingredients.includes(data?.y?.[0])) {
				form.setFieldValue("var_y", data?.y?.[0])
			}
			if (properties.includes(data?.prop_to_compare)) {
				form.setFieldValue("prop_to_compare", data?.prop_to_compare)
			}
			form.setFieldValue("agg", data?.agg)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPlotsData, form])

	return (
		<AntdForm
			form={form}
			onFinish={onSubmit}
			id="data-summary-pivot-table-form"
			layout="inline"
			requiredMark={false}
		>
			<AntdForm.Item
				style={{ margin: 0 }}
				name="var_x"
				rules={[
					{
						required: true,
						message: t("common.pleaseSelectTheIngredientX"),
					},
				]}
				required
				tooltip={requiredFieldStar}
			>
				<Select showSearch placeholder={t("common.selectInputX")} onChange={(value) => setIngredientsForm((prev) => ({ ...prev, var_x: value }))} value={ingredientsForm.var_x} optionFilterProp="children">
					{ingredients.filter((ingredient) => ingredientsForm.var_y !== ingredient).map((ingredient) => {
						return (
							<Option key={ingredient} value={ingredient}>
								{getDisplayName("ingredients", ingredient) ?? getDisplayName("processing", ingredient) ?? getDisplayNameCallback(ingredient) ?? ingredient}
							</Option>
						)
					})}
				</Select>
			</AntdForm.Item>

			<AntdForm.Item
				style={{ margin: 0 }}
				name="var_y"
				rules={[
					{
						required: true,
						message: t("common.pleaseSelectTheIngredientY"),
					},
				]}
				required
				tooltip={requiredFieldStar}
			>
				<Select showSearch placeholder={t("common.selectInputY")} onChange={(value) => setIngredientsForm((prev) => ({ ...prev, var_y: value }))} value={ingredientsForm.var_y} optionFilterProp="children">
					{ingredients.filter((ingredient) => ingredientsForm.var_x !== ingredient).map((ingredient) => {
						return (
							<Option key={ingredient} value={ingredient}>
								{getDisplayName("ingredients", ingredient) ?? getDisplayName("processing", ingredient) ?? getDisplayNameCallback(ingredient) ?? ingredient}
							</Option>
						)
					})}
				</Select>
			</AntdForm.Item>

			<AntdForm.Item
				style={{ margin: 0 }}
				name="prop_to_compare"
				rules={[
					{
						required: true,
						message: t("common.pleaseSelectThePropertyToCompare"),
					},
				]}
				required
				tooltip={requiredFieldStar}
			>
				<Select showSearch placeholder={t("common.selectPropertyToCompare")} optionFilterProp="children">
					{properties.map((property) => {
						return (
							<Option key={property} value={property}>
								{getDisplayName("properties", property) ?? property}
							</Option>
						)
					})}
				</Select>
			</AntdForm.Item>

			<AntdForm.Item
				style={{ margin: 0 }}
				name="agg"
				rules={[
					{
						required: true,
						message: t("common.pleaseSelectTheAggregationMethod"),
					},
				]}
				required
				tooltip={requiredFieldStar}
			>
				<Select showSearch placeholder={t("common.selectAggregationMethod")} optionFilterProp="children">
					{aggregationMethods.map((method) => {
						return (
							<Option key={method} value={method}>
								{toTitleCase(translateAggregationMethod(method, t))}
							</Option>
						)
					})}
				</Select>
			</AntdForm.Item>

			<AntdForm.Item style={{ margin: 0 }}>
				<StyledButton style={{ width: "100%" }} htmlType="submit" type="primary" loading={pivotTableStatus === AsyncStates.LOADING}>
					{t("common.submit")}
				</StyledButton>
			</AntdForm.Item>
		</AntdForm>
	)
}
