import { InfoCircleOutlined, LoadingOutlined, SearchOutlined, } from '@ant-design/icons'
import { Input, Space, Table, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { EditMembersTable } from './EditMembersTable'
import { StyledCard } from 'src/styled_components/StyledCard'
import { AsyncStates, permissions } from 'src/constants'
import { ColumnsType } from 'antd/lib/table'
import { SorterResult } from 'antd/lib/table/interface'
import { useMemberName } from 'src/utils/useMemberName'
import { StyledButton } from 'src/styled_components/StyledButton'
import Highlighter from 'react-highlight-words';
import './ProjectsCard.scss'

type IsSorted = {
  name: undefined | 'ascend' | 'descend',
  members: undefined | 'ascend' | 'descend',
}


export default function ProjectsCard() {
  const [t] = useTranslate()
  const { getName } = useMemberName()

  const { projectList: projects, projectListStatus } = useSelector((state: StoreState) => state.projects)
  const [members, setMembers] = useState([])
  const [isAddMemberModalVisible, setIsAddMemberModalVisible] = useState(false)
  const [filterTerm, setfilterTerm] = useState("")
  const projectList = useMemo(() => projects.filter(project => project.name?.toLocaleLowerCase().includes(filterTerm?.toLocaleLowerCase())), [projects, filterTerm])
  const [currentProject, setCurrentProject] = useState({})
  const [isSorted, setIsSorted] = useState<IsSorted>({ name: undefined, members: undefined })
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    currentPage: 1
  })

  const columns: ColumnsType<object> = useMemo(
    () =>
      [
        {
          title: t("projects.header.title"),
          dataIndex: "name",
          key: "name",
          sorter: (a: any, b: any) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          },
          showSorterTooltip: {
            title: !!isSorted.name
              ? isSorted.name === "descend"
                ? t('file.clickToCancelSort') : t('file.clickToSortZA') : t('file.clickToSortAZ')
          },
          ellipsis: {
            showTitle: true,
          },
          width: "25%",
          render: (value:string)=>{
            return <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[filterTerm]}
            autoEscape
            textToHighlight={value ? value.toString() : ''}
          />
          }
        },
        {
          title: t("projects.teams.projectOrCreator"),
          key: "created_by",
          dataIndex: "created_by",
          align: "center" as any,
          render: (row: any, value: any, index: any) => {
            return getName(row);
          },
          width: "15%",
        },
        {
          title: (
            <Space>
                {t("projects.teams.noOfProjectAdmins")} 
                <Tooltip title={t("projects.teams.projectAdminsPopover")}>
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
          ),
          key: "project_owner_count",
          align: "center" as any,
          render: (row: any, value: any, index: any) => (
                row?.members?.filter(
                  (member: any) =>
                    member?.permission === permissions.projectAdmin
                ).length
          ),
          width: "19%",
        },
        {
          title: (
            <Space>
              {t("projects.teams.noOfEditors")}
              <Tooltip title={t("projects.teams.projectEditorsPopover")}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          ),
          key: "no_of_editors",
          align: "center" as any,
          render: (row: any, value: any, index: any) => (
                row?.members?.filter(
                  (member: any) => member?.permission === permissions.editor
                )?.length
          ),
          width: "13%",
        },
        {
          title: (
            <Space>
              {t("projects.teams.noOfViewers")}
              <Tooltip title={t("projects.teams.projectViewersPopover")}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          ),
          key: "no_of_viewers",
          align: "center" as any,
          render: (row: any, value: any, index: any) => (
                row?.members?.filter(
                  (member: any) => member?.permission === permissions.viewer
                )?.length
          ),
          width: "13%",
        },
        {
          title: "",
          dataIndex: "project_id",
          align: "center" as any,
          key: "",
          render: (value: any, row: any, index: any) => (
              <StyledButton
                type="link"
                onClick={() => {
                  const current = projectList.find(
                    (res: any) => res?.project_id === value
                  );
                  setCurrentProject(current);
                  setMembers(current?.members || []);
                  setIsAddMemberModalVisible(true);
                }}
              >
                {`${t("common.add")}/${t("projects.teams.viewMembers")}`}
              </StyledButton>
          ),
          width: "15%",
        },
      ] as ColumnsType<object>,
    [t, isSorted.name, filterTerm, getName, projectList]
  );


  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <StyledCard bodyStyle={{ padding: "8px 16px" }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <Text strong>{`${t("projects.header.title")} (${projectList?.length})`}</Text>
          <Input
            prefix={<SearchOutlined />}
            placeholder={t("projects.searchProjects")}
            onChange={e => setfilterTerm(e.target.value)}
            allowClear
          />
        </Space>
          <Table bordered dataSource={projectList} columns={columns}
            pagination={{
              pageSize: pageInfo.pageSize,
              onChange(page, pageSize) {
                setPageInfo(prev => ({
                  currentPage: page,
                  pageSize: pageSize
                }))
              },
              current: pageInfo.currentPage,
              style:{
                position:'absolute',
                top: `${33.5}rem`,
                right: `0rem`
              }
            }}
            onChange={(pagination, filters, sorter) => {
              const sorterObject = sorter as SorterResult<object>
              const sorterKey = sorterObject.field ?? sorterObject.columnKey
              const sorterData = { ...isSorted, [sorterKey as string]: sorterObject.order } || undefined
              setIsSorted(sorterData);
            }}
            scroll={{ x: 450 }}
            loading={{
              spinning: projectListStatus === AsyncStates.LOADING,
              indicator: <LoadingOutlined />,
            }}
            className='teams-projects-table'
          />
      </Space>
        <EditMembersTable
          isAddMemberModalVisible={isAddMemberModalVisible} setIsAddMemberModalVisible={setIsAddMemberModalVisible}
          members={members} setMembers={setMembers} project={currentProject}
          from="teams"
        />
      </StyledCard>
    </Space>
  )
}
