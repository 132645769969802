import React, { useCallback } from "react"
import { Collapse, Table } from "antd"
import useTranslate from "src/utils/useTranslate"
import './base-input-constraints.scss'
import { useValue } from "src/utils/useValue"
const { Panel } = Collapse

type P = {
	modelData: any
	labels: any
}

export const BaseInputConstraints = ({ modelData, labels }: P) => {
	const [t] = useTranslate()
	const { getValue } = useValue()


	const baseInputConstraints = useCallback(() => {
		const collator = new Intl.Collator([], { numeric: true })
		const columns = [
			{
				key: "input",
				title: t('common.inputs'),
				dataIndex: "input"
			},
			{
				key: "range",
				title: t('common.range'),
				dataIndex: "range"
			}
		]

		const data = Object.entries(modelData.inverse_input_constraints ?? {})
			.sort((a, b) => collator.compare(a[0], b[0]))
			.map(
				([key, value]: [string, any]) => (
					{
						input: modelData?.display_names?.ingredients?.[key] ?? labels?.ingredients?.[key]?.name ??
							labels?.processing?.[key]?.name ??
							key,
						range: Array.isArray(value)
							? `(${value.join(", ")})`
							: `(${getValue(value?.min)} - ${getValue(value?.max)})`
					}
				))

		return <Table columns={columns} dataSource={data} pagination={false} scroll={{ y: 300 }} className="base-input-constraints-table" />

	}, [labels?.ingredients, labels?.processing, modelData?.display_names?.ingredients, modelData.inverse_input_constraints, t, getValue])

	return (
		<Collapse size="small" >
			<Panel key="1" header={t("aiEngine.inverseModel.baseInputConstraints")}>
				{baseInputConstraints()}
			</Panel>
		</Collapse>
	)
}
