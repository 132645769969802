import { createActions } from "redux-actions";

export enum GetSuppliersActionTypes {
  REQUEST = "GET_SUPPLIERS_REQUEST",
  SUCCESS = "GET_SUPPLIERS_SUCCESS",
  FAILURE = "GET_SUPPLIERS_FAILURE",
  CLEAR = "GET_SUPPLIERS_CLEAR",
}

export enum CreateSupplierActionTypes {
  REQUEST = "CREATE_SUPPLIER_REQUEST",
  SUCCESS = "CREATE_SUPPLIER_SUCCESS",
  FAILURE = "CREATE_SUPPLIER_FAILURE",
  CLEAR = "CREATE_SUPPLIER_CLEAR",
}

export enum GetSupplierDetailsActionTypes {
  REQUEST = "GET_SUPPLIER_DETAILS_REQUEST",
  SUCCESS = "GET_SUPPLIER_DETAILS_SUCCESS",
  FAILURE = "GET_SUPPLIER_DETAILS_FAILURE",
  CLEAR = "GET_SUPPLIER_DETAILS_CLEAR",
}

export enum UpdateSupplierActionTypes {
  REQUEST = "UPDATE_SUPPLIER_REQUEST",
  SUCCESS = "UPDATE_SUPPLIER_SUCCESS",
  FAILURE = "UPDATE_SUPPLIER_FAILURE",
  CLEAR = "UPDATE_SUPPLIER_CLEAR",
}

export enum DeleteSupplierActionTypes {
  REQUEST = "DELETE_SUPPLIER_REQUEST",
  SUCCESS = "DELETE_SUPPLIER_SUCCESS",
  FAILURE = "DELETE_SUPPLIER_FAILURE",
  CLEAR = "DELETE_SUPPLIER_CLEAR",
}

export enum GetInventoryPropertiesActionTypes {
  REQUEST = "GET_INVENTORY_PROPERTIES_REQUEST",
  SUCCESS = "GET_INVENTORY_PROPERTIES_SUCCESS",
  FAILURE = "GET_INVENTORY_PROPERTIES_FAILURE",
  CLEAR = "GET_INVENTORY_PROPERTIES_CLEAR",
}

export enum CreateInventoryPropertyActionTypes {
  REQUEST = "CREATE_INVENTORY_PROPERTY_REQUEST",
  SUCCESS = "CREATE_INVENTORY_PROPERTY_SUCCESS",
  FAILURE = "CREATE_INVENTORY_PROPERTY_FAILURE",
  CLEAR = "CREATE_INVENTORY_PROPERTY_CLEAR",
}

export enum UpdateInventoryPropertyActionTypes {
  REQUEST = "UPDATE_INVENTORY_PROPERTY_REQUEST",
  SUCCESS = "UPDATE_INVENTORY_PROPERTY_SUCCESS",
  FAILURE = "UPDATE_INVENTORY_PROPERTY_FAILURE",
  CLEAR = "UPDATE_INVENTORY_PROPERTY_CLEAR",
}

export enum DeleteInventoryPropertyActionTypes {
  REQUEST = "DELETE_INVENTORY_PROPERTY_REQUEST",
  SUCCESS = "DELETE_INVENTORY_PROPERTY_SUCCESS",
  FAILURE = "DELETE_INVENTORY_PROPERTY_FAILURE",
  CLEAR = "DELETE_INVENTORY_PROPERTY_CLEAR",
}

export enum GetInventoryMethodsLookupActionTypes {
  REQUEST = "GET_INVENTORY_METHODS_LOOKUP_REQUEST",
  SUCCESS = "GET_INVENTORY_METHODS_LOOKUP_SUCCESS",
  FAILURE = "GET_INVENTORY_METHODS_LOOKUP_FAILURE",
  CLEAR = "GET_INVENTORY_METHODS_LOOKUP_CLEAR",
}

export enum GetInventoryMethodsActionTypes {
  REQUEST = "GET_INVENTORY_METHODS_REQUEST",
  SUCCESS = "GET_INVENTORY_METHODS_SUCCESS",
  FAILURE = "GET_INVENTORY_METHODS_FAILURE",
  CLEAR = "GET_INVENTORY_METHODS_CLEAR",
}

export enum CreateInventoryMethodActionTypes {
  REQUEST = "CREATE_INVENTORY_METHOD_REQUEST",
  SUCCESS = "CREATE_INVENTORY_METHOD_SUCCESS",
  FAILURE = "CREATE_INVENTORY_METHOD_FAILURE",
  CLEAR = "CREATE_INVENTORY_METHOD_CLEAR",
}

export enum UpdateInventoryMethodActionTypes {
  REQUEST = "UPDATE_INVENTORY_METHOD_REQUEST",
  SUCCESS = "UPDATE_INVENTORY_METHOD_SUCCESS",
  FAILURE = "UPDATE_INVENTORY_METHOD_FAILURE",
  CLEAR = "UPDATE_INVENTORY_METHOD_CLEAR",
}

export enum DeleteInventoryMethodActionTypes {
  REQUEST = "DELETE_INVENTORY_METHOD_REQUEST",
  SUCCESS = "DELETE_INVENTORY_METHOD_SUCCESS",
  FAILURE = "DELETE_INVENTORY_METHOD_FAILURE",
  CLEAR = "DELETE_INVENTORY_METHOD_CLEAR",
}

export enum GetMethodParametersActionTypes {
  REQUEST = "GET_METHOD_PARAMETERS_REQUEST",
  SUCCESS = "GET_METHOD_PARAMETERS_SUCCESS",
  FAILURE = "GET_METHOD_PARAMETERS_FAILURE",
  CLEAR = "GET_METHOD_PARAMETERS_CLEAR",
}

export enum CreateMethodParameterActionTypes {
  REQUEST = "CREATE_METHOD_PARAMETER_REQUEST",
  SUCCESS = "CREATE_METHOD_PARAMETER_SUCCESS",
  FAILURE = "CREATE_METHOD_PARAMETER_FAILURE",
  CLEAR = "CREATE_METHOD_PARAMETER_CLEAR",
}

export enum UpdateMethodParameterActionTypes {
  REQUEST = "UPDATE_METHOD_PARAMETER_REQUEST",
  SUCCESS = "UPDATE_METHOD_PARAMETER_SUCCESS",
  FAILURE = "UPDATE_METHOD_PARAMETER_FAILURE",
  CLEAR = "UPDATE_METHOD_PARAMETER_CLEAR",
}

export enum DeleteMethodParameterActionTypes {
  REQUEST = "DELETE_METHOD_PARAMETER_REQUEST",
  SUCCESS = "DELETE_METHOD_PARAMETER_SUCCESS",
  FAILURE = "DELETE_METHOD_PARAMETER_FAILURE",
  CLEAR = "DELETE_METHOD_PARAMETER_CLEAR",
}

export enum GetIngredientsActionTypes {
  SEARCHREQUEST = "GET_INGREDIENTS_SEARCH_REQUEST",
  REQUEST = "GET_INGREDIENTS_REQUEST",
  SUCCESS = "GET_INGREDIENTS_SUCCESS",
  FAILURE = "GET_INGREDIENTS_FAILURE",
  CLEAR = "GET_INGREDIENTS_CLEAR",
}

export enum GetIngredientsSearchActionTypes {
  REQUEST = "GET_INGREDIENTS_SEARCH_REQUEST",
}

export enum GetIngredientsMetaActionTypes {
  REQUEST = "GET_INGREDIENTS_Meta_REQUEST",
  SUCCESS = "GET_INGREDIENTS_Meta_SUCCESS",
  FAILURE = "GET_INGREDIENTS_Meta_FAILURE",
  CLEAR = "GET_INGREDIENTS_Meta_CLEAR",
}

export enum GetIngredientDetailsActionTypes {
  REQUEST = "GET_INGREDIENT_DETAILS_REQUEST",
  SUCCESS = "GET_INGREDIENT_DETAILS_SUCCESS",
  FAILURE = "GET_INGREDIENT_DETAILS_FAILURE",
  CLEAR = "GET_INGREDIENT_DETAILS_CLEAR",
}

export enum CreateIngredientActionTypes {
  REQUEST = "CREATE_INGREDIENT_REQUEST",
  SUCCESS = "CREATE_INGREDIENT_SUCCESS",
  FAILURE = "CREATE_INGREDIENT_FAILURE",
  CLEAR = "CREATE_INGREDIENT_CLEAR",
}

export enum CreateIngredientFromTrialActionTypes {
  REQUEST = "CREATE_INGREDIENT_FROM_TRIAL_REQUEST",
  SUCCESS = "CREATE_INGREDIENT_FROM_TRIAL_SUCCESS",
  FAILURE = "CREATE_INGREDIENT_FROM_TRIAL_FAILURE",
  CLEAR = "CREATE_INGREDIENT_FROM_TRIAL_CLEAR",
}

export enum UpdateIngredientActionTypes {
  REQUEST = "UPDATE_INGREDIENT_REQUEST",
  SUCCESS = "UPDATE_INGREDIENT_SUCCESS",
  FAILURE = "UPDATE_INGREDIENT_FAILURE",
  CLEAR = "UPDATE_INGREDIENT_CLEAR",
}

export enum DeleteIngredientActionTypes {
  REQUEST = "DELETE_INGREDIENT_REQUEST",
  SUCCESS = "DELETE_INGREDIENT_SUCCESS",
  FAILURE = "DELETE_INGREDIENT_FAILURE",
  CLEAR = "DELETE_INGREDIENT_CLEAR",
}

export enum UploadSmilesFileActionTypes {
  REQUEST = "UPLOAD_SMILES_FILE_REQUEST",
  SUCCESS = "UPLOAD_SMILES_FILE_SUCCESS",
  FAILURE = "UPLOAD_SMILES_FILE_FAILURE",
  CLEAR = "UPLOAD_SMILES_FILE_CLEAR",
}

export enum ResetDataSmilesFileActionTypes { // Delete Smiles From Inventory
  REQUEST = "RESET_DATA_SMILES_FILE_REQUEST",
  SUCCESS = "RESET_DATA_SMILES_FILE_SUCCESS",
  FAILURE = "RESET_DATA_SMILES_FILE_FAILURE",
  CLEAR = "RESET_DATA_SMILES_FILE_CLEAR",
}

export enum GetIngredientUsedInWoActionTypes {
  REQUEST = "GET_INGREDIENT_USED_IN_WO_REQUEST",
  SUCCESS = "GET_INGREDIENT_USED_IN_WO_SUCCESS",
  FAILURE = "GET_INGREDIENT_USED_IN_WO_FAILURE",
  CLEAR = "GET_INGREDIENT_USED_IN_WO_CLEAR",
}

export enum GetIngredientFamilyList {
  REQUEST = "GET_INGREDIENT_FAMILY_LIST_REQUEST",
  SUCCESS = "GET_INGREDIENT_FAMILY_LIST_SUCCESS",
  FAILURE = "GET_INGREDIENT_FAMILY_LIST_FAILURE",
  CLEAR = "GET_INGREDIENT_FAMILY_LIST_CLEAR",
}

export enum GetIngredientFamily {
  REQUEST = "GET_INGREDIENT_FAMILY_REQUEST",
  SUCCESS = "GET_INGREDIENT_FAMILY_SUCCESS",
  FAILURE = "GET_INGREDIENT_FAMILY_FAILURE",
  CLEAR = "GET_INGREDIENT_FAMILY_CLEAR",
}

export enum UpdateIngredientFamily {
  REQUEST = "UPDATE_INGREDIENT_FAMILY_REQUEST",
  SUCCESS = "UPDATE_INGREDIENT_FAMILY_SUCCESS",
  FAILURE = "UPDATE_INGREDIENT_FAMILY_FAILURE",
  CLEAR = "UPDATE_INGREDIENT_FAMILY_CLEAR",
}

export enum DeleteIngredientFamily {
  REQUEST = "DELETE_INGREDIENT_FAMILY_REQUEST",
  SUCCESS = "DELETE_INGREDIENT_FAMILY_SUCCESS",
  FAILURE = "DELETE_INGREDIENT_FAMILY_FAILURE",
  CLEAR = "DELETE_INGREDIENT_FAMILY_CLEAR",
}

export enum CreateIngredientFamily {
  REQUEST = "CREATE_INGREDIENT_FAMILY_REQUEST",
  SUCCESS = "CREATE_INGREDIENT_FAMILY_SUCCESS",
  FAILURE = "CREATE_INGREDIENT_FAMILY_FAILURE",
  CLEAR = "CREATE_INGREDIENT_FAMILY_CLEAR",
}


export enum GetIngredientPropertiesActionTypes {
  REQUEST = "GET_INGREDIENT_PROPERTIES_REQUEST",
  SUCCESS = "GET_INGREDIENT_PROPERTIES_SUCCESS",
  FAILURE = "GET_INGREDIENT_PROPERTIES_FAILURE",
  CLEAR = "GET_INGREDIENT_PROPERTIES_CLEAR",
}

export enum GetPropertyMethodsActionTypes {
  REQUEST = "GET_PROPERTY_METHODS_REQUEST",
  SUCCESS = "GET_PROPERTY_METHODS_SUCCESS",
  FAILURE = "GET_PROPERTY_METHODS_FAILURE",
  CLEAR = "GET_PROPERTY_METHODS_CLEAR",
}

export enum GetIngredientPropertiesDetailsActionTypes {
  REQUEST = "GET_INGREDIENT_PROPERTIES_DETAILS_REQUEST",
  SUCCESS = "GET_INGREDIENT_PROPERTIES_DETAILS_SUCCESS",
  FAILURE = "GET_INGREDIENT_PROPERTIES_DETAILS_FAILURE",
  CLEAR = "GET_INGREDIENT_PROPERTIES_DETAILS_CLEAR",
}


export enum AddPropertyToIngredientActionTypes {
  REQUEST = "ADD_PROPERTY_TO_INGREDIENT_REQUEST",
  SUCCESS = "ADD_PROPERTY_TO_INGREDIENT_SUCCESS",
  FAILURE = "ADD_PROPERTY_TO_INGREDIENT_FAILURE",
  CLEAR = "ADD_PROPERTY_TO_INGREDIENT_CLEAR",
}

export enum GetIngredientPropertyValuesActionTypes {
  REQUEST = "GET_INGREDIENT_PROPERTY_VALUES_REQUEST",
  SUCCESS = "GET_INGREDIENT_PROPERTY_VALUES_SUCCESS",
  FAILURE = "GET_INGREDIENT_PROPERTY_VALUES_FAILURE",
  CLEAR = "GET_INGREDIENT_PROPERTY_VALUES_CLEAR",
}

export enum GetPropertyMethodsListActionTypes {
  REQUEST = "GET_PROPERTY_METHODS_LIST_REQUEST",
  SUCCESS = "GET_PROPERTY_METHODS_LIST_SUCCESS",
  FAILURE = "GET_PROPERTY_METHODS_LIST_FAILURE",
  CLEAR = "GET_PROPERTY_METHODS_LIST_CLEAR",
}

export enum AddPropertyValueToPropertyActionTypes {
  REQUEST = "ADD_PROPERTY_VALUE_TO_PROPERTY_REQUEST",
  SUCCESS = "ADD_PROPERTY_VALUE_TO_PROPERTY_SUCCESS",
  FAILURE = "ADD_PROPERTY_VALUE_TO_PROPERTY_FAILURE",
  CLEAR = "ADD_PROPERTY_VALUE_TO_PROPERTY_CLEAR",
}

export enum UpdatePropertyValueToPropertyActionTypes {
  REQUEST = "UPDATE_PROPERTY_VALUE_TO_PROPERTY_REQUEST",
  SUCCESS = "UPDATE_PROPERTY_VALUE_TO_PROPERTY_SUCCESS",
  FAILURE = "UPDATE_PROPERTY_VALUE_TO_PROPERTY_FAILURE",
  CLEAR = "UPDATE_PROPERTY_VALUE_TO_PROPERTY_CLEAR",
}

export enum DeleteIngredientPropertyActionTypes {
  REQUEST = "DELETE_INGREDIENT_PROPERTY_REQUEST",
  SUCCESS = "DELETE_INGREDIENT_PROPERTY_SUCCESS",
  FAILURE = "DELETE_INGREDIENT_PROPERTY_FAILURE",
  CLEAR = "DELETE_INGREDIENT_PROPERTY_CLEAR",
}

export enum DeleteIngredientPropertyValueActionTypes {
  REQUEST = "DELETE_INGREDIENT_PROPERTY_VALUE_REQUEST",
  SUCCESS = "DELETE_INGREDIENT_PROPERTY_VALUE_SUCCESS",
  FAILURE = "DELETE_INGREDIENT_PROPERTY_VALUE_FAILURE",
  CLEAR = "DELETE_INGREDIENT_PROPERTY_VALUE_CLEAR",
}

export enum GetIngredientAttachmentsActionTypes {
  REQUEST = "GET_INGREDIENT_ATTACHMENTS_REQUEST",
  SUCCESS = "GET_INGREDIENT_ATTACHMENTS_SUCCESS",
  FAILURE = "GET_INGREDIENT_ATTACHMENTS_FAILURE",
  CLEAR = "GET_INGREDIENT_ATTACHMENTS_CLEAR",
}

export enum AddIngredientAttachmentActionTypes {
  REQUEST = "ADD_INGREDIENT_ATTACHMENT_REQUEST",
  SUCCESS = "ADD_INGREDIENT_ATTACHMENT_SUCCESS",
  FAILURE = "ADD_INGREDIENT_ATTACHMENT_FAILURE",
  CLEAR = "ADD_INGREDIENT_ATTACHMENT_CLEAR",
}

export enum EditIngredientAttachmentActionTypes {
  REQUEST = "EDIT_INGREDIENT_ATTACHMENT_REQUEST",
  SUCCESS = "EDIT_INGREDIENT_ATTACHMENT_SUCCESS",
  FAILURE = "EDIT_INGREDIENT_ATTACHMENT_FAILURE",
  CLEAR = "EDIT_INGREDIENT_ATTACHMENT_CLEAR",
}

export enum DeleteIngredientAttachmentActionTypes {
  REQUEST = "DELETE_INGREDIENT_ATTACHMENT_REQUEST",
  SUCCESS = "DELETE_INGREDIENT_ATTACHMENT_SUCCESS",
  FAILURE = "DELETE_INGREDIENT_ATTACHMENT_FAILURE",
  CLEAR = "DELETE_INGREDIENT_ATTACHMENT_CLEAR",
}

export enum GetIngredientGradesActionTypes {
  REQUEST = "GET_INGREDIENT_GRADES_REQUEST",
  SUCCESS = "GET_INGREDIENT_GRADES_SUCCESS",
  FAILURE = "GET_INGREDIENT_GRADES_FAILURE",
  CLEAR = "GET_INGREDIENT_GRADES_CLEAR",
}

export enum SaveInventoryFiltersTypes {
  REQUEST = "SAVE_INVENTORY_FILTERS_REQUEST",
  CLEAR = "SAVE_INVENTORY_FILTERS_CLEAR",
}

export const {
  // Supplier
  getSuppliersRequest,
  getSuppliersSuccess,
  getSuppliersFailure,
  getSuppliersClear,
  createSupplierRequest,
  createSupplierSuccess,
  createSupplierFailure,
  createSupplierClear,
  getSupplierDetailsRequest,
  getSupplierDetailsSuccess,
  getSupplierDetailsFailure,
  getSupplierDetailsClear,
  updateSupplierRequest,
  updateSupplierSuccess,
  updateSupplierFailure,
  updateSupplierClear,
  deleteSupplierRequest,
  deleteSupplierSuccess,
  deleteSupplierFailure,
  deleteSupplierClear,
  // Property
  getInventoryPropertiesRequest,
  getInventoryPropertiesSuccess,
  getInventoryPropertiesFailure,
  getInventoryPropertiesClear,

  createInventoryPropertyRequest,
  createInventoryPropertySuccess,
  createInventoryPropertyFailure,
  createInventoryPropertyClear,

  updateInventoryPropertyRequest,
  updateInventoryPropertySuccess,
  updateInventoryPropertyFailure,
  updateInventoryPropertyClear,

  deleteInventoryPropertyRequest,
  deleteInventoryPropertySuccess,
  deleteInventoryPropertyFailure,
  deleteInventoryPropertyClear,

  // Method
  getInventoryMethodsLookupRequest,
  getInventoryMethodsLookupSuccess,
  getInventoryMethodsLookupFailure,
  getInventoryMethodsLookupClear,

  getInventoryMethodsRequest,
  getInventoryMethodsSuccess,
  getInventoryMethodsFailure,
  getInventoryMethodsClear,

  createInventoryMethodRequest,
  createInventoryMethodSuccess,
  createInventoryMethodFailure,
  createInventoryMethodClear,

  updateInventoryMethodRequest,
  updateInventoryMethodSuccess,
  updateInventoryMethodFailure,
  updateInventoryMethodClear,

  deleteInventoryMethodRequest,
  deleteInventoryMethodSuccess,
  deleteInventoryMethodFailure,
  deleteInventoryMethodClear,

  // Method Parameter
  getMethodParametersRequest,
  getMethodParametersSuccess,
  getMethodParametersFailure,
  getMethodParametersClear,

  createMethodParameterRequest,
  createMethodParameterSuccess,
  createMethodParameterFailure,
  createMethodParameterClear,

  updateMethodParameterRequest,
  updateMethodParameterSuccess,
  updateMethodParameterFailure,
  updateMethodParameterClear,

  deleteMethodParameterRequest,
  deleteMethodParameterSuccess,
  deleteMethodParameterFailure,
  deleteMethodParameterClear,

  // Ingredient
  getIngredientsSearchRequest,
  getIngredientsRequest,
  getIngredientsSuccess,
  getIngredientsFailure,
  getIngredientsClear,

  getIngredientsMetaRequest,
  getIngredientsMetaSuccess,
  getIngredientsMetaFailure,

  getIngredientDetailsRequest,
  getIngredientDetailsSuccess,
  getIngredientDetailsFailure,
  getIngredientDetailsClear,

  createIngredientRequest,
  createIngredientSuccess,
  createIngredientFailure,
  createIngredientClear,
  updateIngredientRequest,
  updateIngredientSuccess,
  updateIngredientFailure,
  updateIngredientClear,
  deleteIngredientRequest,
  deleteIngredientSuccess,
  deleteIngredientFailure,
  deleteIngredientClear,
  createIngredientFromTrialRequest,
  createIngredientFromTrialSuccess,
  createIngredientFromTrialFailure,
  createIngredientFromTrialClear,

  // SMILES
  uploadSmilesFileRequest,
  uploadSmilesFileSuccess,
  uploadSmilesFileFailure,
  uploadSmilesFileClear,
  resetDataSmilesFileRequest,
  resetDataSmilesFileSuccess,
  resetDataSmilesFileFailure,
  resetDataSmilesFileClear,

  // Ingredient Used in WO/Project
  getIngredientUsedInWoRequest,
  getIngredientUsedInWoSuccess,
  getIngredientUsedInWoFailure,
  getIngredientUsedInWoClear,

  getIngredientFamilyListRequest,
  getIngredientFamilyListSuccess,
  getIngredientFamilyListFailure,
  getIngredientFamilyListClear,

  getIngredientFamilyRequest,
  getIngredientFamilySuccess,
  getIngredientFamilyFailure,
  getIngredientFamilyClear,

  updateIngredientFamilyRequest,
  updateIngredientFamilySuccess,
  updateIngredientFamilyFailure,
  updateIngredientFamilyClear,

  deleteIngredientFamilyRequest,
  deleteIngredientFamilySuccess,
  deleteIngredientFamilyFailure,
  deleteIngredientFamilyClear,

  createIngredientFamilyRequest,
  createIngredientFamilySuccess,
  createIngredientFamilyFailure,
  createIngredientFamilyClear,

  // Ingredient Properties
  getIngredientPropertiesRequest,
  getIngredientPropertiesSuccess,
  getIngredientPropertiesFailure,
  getIngredientPropertiesClear,

  getPropertyMethodsRequest,
  getPropertyMethodsSuccess,
  getPropertyMethodsFailure,
  getPropertyMethodsClear,

  getIngredientPropertiesDetailsRequest,
  getIngredientPropertiesDetailsSuccess,
  getIngredientPropertiesDetailsFailure,
  getIngredientPropertiesDetailsClear,

  // Add Property To Ingredient
  addPropertyToIngredientRequest,
  addPropertyToIngredientSuccess,
  addPropertyToIngredientFailure,
  addPropertyToIngredientClear,

  // Ingredient Property Values

  getIngredientPropertyValuesRequest,
  getIngredientPropertyValuesSuccess,
  getIngredientPropertyValuesFailure,
  getIngredientPropertyValuesClear,

  // Get Methods based on Property

  getPropertyMethodsListRequest,
  getPropertyMethodsListSuccess,
  getPropertyMethodsListFailure,
  getPropertyMethodsListClear,

  // Add Property Value To Property

  addPropertyValueToPropertyRequest,
  addPropertyValueToPropertySuccess,
  addPropertyValueToPropertyFailure,
  addPropertyValueToPropertyClear,

  updatePropertyValueToPropertyRequest,
  updatePropertyValueToPropertySuccess,
  updatePropertyValueToPropertyFailure,
  updatePropertyValueToPropertyClear,

  deleteIngredientPropertyRequest,
  deleteIngredientPropertySuccess,
  deleteIngredientPropertyFailure,
  deleteIngredientPropertyClear,

  deleteIngredientPropertyValueRequest,
  deleteIngredientPropertyValueSuccess,
  deleteIngredientPropertyValueFailure,
  deleteIngredientPropertyValueClear,

  getIngredientAttachmentsRequest,
  getIngredientAttachmentsSuccess,
  getIngredientAttachmentsFailure,
  getIngredientAttachmentsClear,

  addIngredientAttachmentRequest,
  addIngredientAttachmentSuccess,
  addIngredientAttachmentFailure,
  addIngredientAttachmentClear,

  editIngredientAttachmentRequest,
  editIngredientAttachmentSuccess,
  editIngredientAttachmentFailure,
  editIngredientAttachmentClear,

  deleteIngredientAttachmentRequest,
  deleteIngredientAttachmentSuccess,
  deleteIngredientAttachmentFailure,
  deleteIngredientAttachmentClear,

  getIngredientGradesRequest,
  getIngredientGradesSuccess,
  getIngredientGradesFailure,
  getIngredientGradesClear,

  saveInventoryFiltersRequest,
  saveInventoryFiltersClear
} = createActions({
  // Suppliers
  [GetSuppliersActionTypes.REQUEST]: (payload) => payload,
  [GetSuppliersActionTypes.SUCCESS]: (data: any) => data,
  [GetSuppliersActionTypes.FAILURE]: (message: any) => message,
  [GetSuppliersActionTypes.CLEAR]: (payload) => payload,

  [CreateSupplierActionTypes.REQUEST]: (payload) => payload,
  [CreateSupplierActionTypes.SUCCESS]: (data: any) => data,
  [CreateSupplierActionTypes.FAILURE]: (message: any) => message,
  [CreateSupplierActionTypes.CLEAR]: (payload) => payload,
  [GetSupplierDetailsActionTypes.REQUEST]: (payload: { supplier_id: string }) =>
    payload,
  [GetSupplierDetailsActionTypes.SUCCESS]: (data: any) => data,
  [GetSupplierDetailsActionTypes.FAILURE]: (message: any) => message,
  [GetSupplierDetailsActionTypes.CLEAR]: (payload) => payload,
  [UpdateSupplierActionTypes.REQUEST]: (payload: { supplier_id: string }) =>
    payload,
  [UpdateSupplierActionTypes.SUCCESS]: (data: any) => data,
  [UpdateSupplierActionTypes.FAILURE]: (message: any) => message,
  [UpdateSupplierActionTypes.CLEAR]: (payload) => payload,
  [DeleteSupplierActionTypes.REQUEST]: (payload: { supplier_id: string }) =>
    payload,
  [DeleteSupplierActionTypes.SUCCESS]: (data: any) => data,
  [DeleteSupplierActionTypes.FAILURE]: (message: any) => message,
  [DeleteSupplierActionTypes.CLEAR]: (payload) => payload,

  // Methods
  [GetInventoryPropertiesActionTypes.REQUEST]: (payload) => payload,
  [GetInventoryPropertiesActionTypes.SUCCESS]: (data: any) => data,
  [GetInventoryPropertiesActionTypes.FAILURE]: (message: any) => message,
  [GetInventoryPropertiesActionTypes.CLEAR]: (payload) => payload,

  [CreateInventoryPropertyActionTypes.REQUEST]: (payload) => payload,
  [CreateInventoryPropertyActionTypes.SUCCESS]: (data: any) => data,
  [CreateInventoryPropertyActionTypes.FAILURE]: (message: any) => message,
  [CreateInventoryPropertyActionTypes.CLEAR]: (payload) => payload,

  [UpdateInventoryPropertyActionTypes.REQUEST]: (payload) => payload,
  [UpdateInventoryPropertyActionTypes.SUCCESS]: (data: any) => data,
  [UpdateInventoryPropertyActionTypes.FAILURE]: (message: any) => message,
  [UpdateInventoryPropertyActionTypes.CLEAR]: (payload) => payload,

  [DeleteInventoryPropertyActionTypes.REQUEST]: (payload) => payload,
  [DeleteInventoryPropertyActionTypes.SUCCESS]: (data: any) => data,
  [DeleteInventoryPropertyActionTypes.FAILURE]: (message: any) => message,
  [DeleteInventoryPropertyActionTypes.CLEAR]: (payload) => payload,

  // Methods
  [GetInventoryMethodsLookupActionTypes.REQUEST]: (payload) => payload,
  [GetInventoryMethodsLookupActionTypes.SUCCESS]: (data: any) => data,
  [GetInventoryMethodsLookupActionTypes.FAILURE]: (message: any) => message,
  [GetInventoryMethodsLookupActionTypes.CLEAR]: (payload) => payload,

  [GetInventoryMethodsActionTypes.REQUEST]: (payload) => payload,
  [GetInventoryMethodsActionTypes.SUCCESS]: (data: any) => data,
  [GetInventoryMethodsActionTypes.FAILURE]: (message: any) => message,
  [GetInventoryMethodsActionTypes.CLEAR]: (payload) => payload,

  [CreateInventoryMethodActionTypes.REQUEST]: (payload) => payload,
  [CreateInventoryMethodActionTypes.SUCCESS]: (data: any) => data,
  [CreateInventoryMethodActionTypes.FAILURE]: (message: any) => message,
  [CreateInventoryMethodActionTypes.CLEAR]: (payload) => payload,

  [UpdateInventoryMethodActionTypes.REQUEST]: (payload) => payload,
  [UpdateInventoryMethodActionTypes.SUCCESS]: (data: any) => data,
  [UpdateInventoryMethodActionTypes.FAILURE]: (message: any) => message,
  [UpdateInventoryMethodActionTypes.CLEAR]: (payload) => payload,

  [DeleteInventoryMethodActionTypes.REQUEST]: (payload) => payload,
  [DeleteInventoryMethodActionTypes.SUCCESS]: (data: any) => data,
  [DeleteInventoryMethodActionTypes.FAILURE]: (message: any) => message,
  [DeleteInventoryMethodActionTypes.CLEAR]: (payload) => payload,

  // Method Parameters
  [GetMethodParametersActionTypes.REQUEST]: (payload) => payload,
  [GetMethodParametersActionTypes.SUCCESS]: (data: any) => data,
  [GetMethodParametersActionTypes.FAILURE]: (message: any) => message,
  [GetMethodParametersActionTypes.CLEAR]: (payload) => payload,

  [CreateMethodParameterActionTypes.REQUEST]: (payload) => payload,
  [CreateMethodParameterActionTypes.SUCCESS]: (data: any) => data,
  [CreateMethodParameterActionTypes.FAILURE]: (message: any) => message,
  [CreateMethodParameterActionTypes.CLEAR]: (payload) => payload,

  [UpdateMethodParameterActionTypes.REQUEST]: (payload) => payload,
  [UpdateMethodParameterActionTypes.SUCCESS]: (data: any) => data,
  [UpdateMethodParameterActionTypes.FAILURE]: (message: any) => message,
  [UpdateMethodParameterActionTypes.CLEAR]: (payload) => payload,

  [DeleteMethodParameterActionTypes.REQUEST]: (payload) => payload,
  [DeleteMethodParameterActionTypes.SUCCESS]: (data: any) => data,
  [DeleteMethodParameterActionTypes.FAILURE]: (message: any) => message,
  [DeleteMethodParameterActionTypes.CLEAR]: (payload) => payload,

  // Ingredient
  [GetIngredientsSearchActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientsActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientsActionTypes.SUCCESS]: (data: any) => data,
  [GetIngredientsActionTypes.FAILURE]: (message: any) => message,
  [GetIngredientsActionTypes.CLEAR]: (payload) => payload,

  [GetIngredientsMetaActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientsMetaActionTypes.SUCCESS]: (data: any) => data,
  [GetIngredientsMetaActionTypes.FAILURE]: (message: any) => message,

  [GetIngredientDetailsActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientDetailsActionTypes.SUCCESS]: (data: any) => data,
  [GetIngredientDetailsActionTypes.FAILURE]: (message: any) => message,
  [GetIngredientDetailsActionTypes.CLEAR]: (payload) => payload,

  [CreateIngredientActionTypes.REQUEST]: (payload) => payload,
  [CreateIngredientActionTypes.SUCCESS]: (data: any) => data,
  [CreateIngredientActionTypes.FAILURE]: (message: any) => message,
  [CreateIngredientActionTypes.CLEAR]: (payload) => payload,

  [UpdateIngredientActionTypes.REQUEST]: (payload) => payload,
  [UpdateIngredientActionTypes.SUCCESS]: (data: any) => data,
  [UpdateIngredientActionTypes.FAILURE]: (message: any) => message,
  [UpdateIngredientActionTypes.CLEAR]: (payload) => payload,

  [DeleteIngredientActionTypes.REQUEST]: (payload) => payload,
  [DeleteIngredientActionTypes.SUCCESS]: (data: any) => data,
  [DeleteIngredientActionTypes.FAILURE]: (message: any) => message,
  [DeleteIngredientActionTypes.CLEAR]: (payload) => payload,

  [CreateIngredientFromTrialActionTypes.REQUEST]: (payload) => payload,
  [CreateIngredientFromTrialActionTypes.SUCCESS]: (data: any) => data,
  [CreateIngredientFromTrialActionTypes.FAILURE]: (message: any) => message,
  [CreateIngredientFromTrialActionTypes.CLEAR]: (payload) => payload,

  [UploadSmilesFileActionTypes.REQUEST]: (payload) => payload,
  [UploadSmilesFileActionTypes.SUCCESS]: (data: any) => data,
  [UploadSmilesFileActionTypes.FAILURE]: (message: any) => message,
  [UploadSmilesFileActionTypes.CLEAR]: (payload) => payload,

  [ResetDataSmilesFileActionTypes.REQUEST]: (payload) => payload,
  [ResetDataSmilesFileActionTypes.SUCCESS]: () => { },
  [ResetDataSmilesFileActionTypes.FAILURE]: (message: any) => message,
  [ResetDataSmilesFileActionTypes.CLEAR]: () => { },

  [GetIngredientUsedInWoActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientUsedInWoActionTypes.SUCCESS]: (payload) => payload,
  [GetIngredientUsedInWoActionTypes.FAILURE]: (message: any) => message,
  [GetIngredientUsedInWoActionTypes.CLEAR]: () => { },

  [GetIngredientFamilyList.REQUEST]: (payload) => payload,
  [GetIngredientFamilyList.SUCCESS]: (payload) => payload,
  [GetIngredientFamilyList.FAILURE]: (message: any) => message,
  [GetIngredientFamilyList.CLEAR]: () => { },

  [GetIngredientFamily.REQUEST]: (payload) => payload,
  [GetIngredientFamily.SUCCESS]: (payload) => payload,
  [GetIngredientFamily.FAILURE]: (message: any) => message,
  [GetIngredientFamily.CLEAR]: () => { },

  [UpdateIngredientFamily.REQUEST]: (payload) => payload,
  [UpdateIngredientFamily.SUCCESS]: (payload) => payload,
  [UpdateIngredientFamily.FAILURE]: (message: any) => message,
  [UpdateIngredientFamily.CLEAR]: () => { },

  [DeleteIngredientFamily.REQUEST]: (payload) => payload,
  [DeleteIngredientFamily.SUCCESS]: (payload) => payload,
  [DeleteIngredientFamily.FAILURE]: (message: any) => message,
  [DeleteIngredientFamily.CLEAR]: () => { },


  [CreateIngredientFamily.REQUEST]: (payload) => payload,
  [CreateIngredientFamily.SUCCESS]: (payload) => payload,
  [CreateIngredientFamily.FAILURE]: (message: any) => message,
  [CreateIngredientFamily.CLEAR]: () => { },

  [GetIngredientPropertiesActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientPropertiesActionTypes.SUCCESS]: (payload) => payload,
  [GetIngredientPropertiesActionTypes.FAILURE]: (message: any) => message,
  [GetIngredientPropertiesActionTypes.CLEAR]: () => { },

  [GetPropertyMethodsActionTypes.REQUEST]: (payload) => payload,
  [GetPropertyMethodsActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
  [GetPropertyMethodsActionTypes.FAILURE]: (message: any) => message,
  [GetPropertyMethodsActionTypes.CLEAR]: () => { },

  [GetIngredientPropertiesDetailsActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientPropertiesDetailsActionTypes.SUCCESS]: (payload) => payload,
  [GetIngredientPropertiesDetailsActionTypes.FAILURE]: (message: any) => message,
  [GetIngredientPropertiesDetailsActionTypes.CLEAR]: () => { },

  [AddPropertyToIngredientActionTypes.REQUEST]: (payload) => payload,
  [AddPropertyToIngredientActionTypes.SUCCESS]: (payload) => payload,
  [AddPropertyToIngredientActionTypes.FAILURE]: (message: any) => message,
  [AddPropertyToIngredientActionTypes.CLEAR]: () => { },

  [GetIngredientPropertyValuesActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientPropertyValuesActionTypes.SUCCESS]: (payload) => payload,
  [GetIngredientPropertyValuesActionTypes.FAILURE]: (message: any) => message,
  [GetIngredientPropertyValuesActionTypes.CLEAR]: () => { },

  [GetPropertyMethodsListActionTypes.REQUEST]: (payload) => payload,
  [GetPropertyMethodsListActionTypes.SUCCESS]: (payload) => payload,
  [GetPropertyMethodsListActionTypes.FAILURE]: (message: any) => message,
  [GetPropertyMethodsListActionTypes.CLEAR]: () => { },

  [AddPropertyValueToPropertyActionTypes.REQUEST]: (payload) => payload,
  [AddPropertyValueToPropertyActionTypes.SUCCESS]: (payload) => payload,
  [AddPropertyValueToPropertyActionTypes.FAILURE]: (message: any) => message,
  [AddPropertyValueToPropertyActionTypes.CLEAR]: (payload) => payload,

  [UpdatePropertyValueToPropertyActionTypes.REQUEST]: (payload) => payload,
  [UpdatePropertyValueToPropertyActionTypes.SUCCESS]: (payload) => payload,
  [UpdatePropertyValueToPropertyActionTypes.FAILURE]: (message: any) => message,
  [UpdatePropertyValueToPropertyActionTypes.CLEAR]: (payload) => payload,

  [DeleteIngredientPropertyActionTypes.REQUEST]: (payload) => payload,
  [DeleteIngredientPropertyActionTypes.SUCCESS]: (payload) => payload,
  [DeleteIngredientPropertyActionTypes.FAILURE]: (message: any) => message,
  [DeleteIngredientPropertyActionTypes.CLEAR]: (payload) => payload,

  [DeleteIngredientPropertyValueActionTypes.REQUEST]: (payload) => payload,
  [DeleteIngredientPropertyValueActionTypes.SUCCESS]: (payload) => payload,
  [DeleteIngredientPropertyValueActionTypes.FAILURE]: (message: any) => message,
  [DeleteIngredientPropertyValueActionTypes.CLEAR]: (payload) => payload,

  [GetIngredientAttachmentsActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientAttachmentsActionTypes.SUCCESS]: (payload) => payload,
  [GetIngredientAttachmentsActionTypes.FAILURE]: (message: any) => message,
  [GetIngredientAttachmentsActionTypes.CLEAR]: (payload) => payload,

  [AddIngredientAttachmentActionTypes.REQUEST]: (payload) => payload,
  [AddIngredientAttachmentActionTypes.SUCCESS]: (payload) => payload,
  [AddIngredientAttachmentActionTypes.FAILURE]: (message: any) => message,
  [AddIngredientAttachmentActionTypes.CLEAR]: (payload) => payload,

  [EditIngredientAttachmentActionTypes.REQUEST]: (payload) => payload,
  [EditIngredientAttachmentActionTypes.SUCCESS]: (payload) => payload,
  [EditIngredientAttachmentActionTypes.FAILURE]: (message: any) => message,
  [EditIngredientAttachmentActionTypes.CLEAR]: (payload) => payload,

  [DeleteIngredientAttachmentActionTypes.REQUEST]: (payload) => payload,
  [DeleteIngredientAttachmentActionTypes.SUCCESS]: (payload) => payload,
  [DeleteIngredientAttachmentActionTypes.FAILURE]: (message: any) => message,
  [DeleteIngredientAttachmentActionTypes.CLEAR]: (payload) => payload,

  [GetIngredientGradesActionTypes.REQUEST]: (payload) => payload,
  [GetIngredientGradesActionTypes.SUCCESS]: (payload) => payload,
  [GetIngredientGradesActionTypes.FAILURE]: (message: any) => message,
  [GetIngredientGradesActionTypes.CLEAR]: (payload) => payload,

  [SaveInventoryFiltersTypes.REQUEST]: (message: any) => message,
  [SaveInventoryFiltersTypes.CLEAR]: (payload) => payload,
});
