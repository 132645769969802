
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`박스-벵켄`}</h1>
    <p>{`박스-벵켄 디자인은 일반 요인설계법보다 적은 실행으로 고차 응답 표면을 생성하는 데 사용됩니다. 박스-벵켄 디자인과 중심복합디자인의 경우, 선별된 실험의 수를 줄임으로써 고차 표면 정의를 유지합니다.`}</p>
    <p>{`박스-벵켄 디자인은 임베디드 또는 부분 요인설계를 포함하지 않는 독립적인 이차 설계입니다. 이 디자인에서 처리 조합은 공정 공간의 가장자리 중점과 중심에 있습니다. 이러한 디자인은 회전 가능하거나 (또는 거의 회전 가능하며) 각 요인에 3개의 수준이 필요합니다. 중심 복합 디자인과 비교하면 직교 블록 설계 기능이 제한됩니다.`}</p>
    <p><strong parentName="p">{`사용 사례:`}</strong></p>
    <p>{`제약
바이오프로세싱
식품 공학
농약
기타 다른 산업
다양한 원천으로부터 인간이 사용하는 목적으로 다당류, 페놀화합물 및 단백질과 같은 생물 활성 화합물을 추출하기 위해 사용됩니다`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;