import { Col } from 'antd'
import React from 'react'
import Plot from 'react-plotly.js'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { plotFont } from './catwise/SuggestedExp'
import { toTitleCase } from 'src/utils/general'
import { useValue } from 'src/utils/useValue'

export default function SuggestExpPlots({ plot, plotHover, plotClick, filtersVersion, hover, plotsType, inputType, experiments }: any) {
    const { getValue: getEUValue } = useValue()
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const formatText = (text: string) => plotsType === "predicted_properties_vs_costing" ? (text !== "Total Cost") ? `Cost of ${text}` : text : text

    const getTickValues = (values: number[], count: number) => {
        let min = Math.min(...values);
        let max = Math.max(...values);
        if(min === max) { 
            max += 1;
            min -= 1;
        }
        const step = (max - min) / (count - 1);
        return Array.from({length: count}, (_, i) => min + i * step);
    }

    return (
        <div>{
            plot?.map((res: any, index: any) => (
                <Col>
                    <Plot
                        onHover={(e) =>
                            plotHover(e, plotsType)
                        }
                        onClick={(e) =>
                            plotClick(
                                e,
                                false,
                                plotsType
                            )
                        }
                        layout={{
                            xaxis: {
                                title: {
                                    text:
                                        formatText(experiments?.[0]?.ingredients?.[res?.x_label]?.name || displayNames?.[inputType]?.[res?.x_label]?.name || toTitleCase(res?.x_label)),
                                    font: plotFont,
                                    
                                },
                                tickmode: 'array',
                                tickvals: getTickValues(Object.values(res?.x || {}), 4),
                                ticktext: getTickValues(Object.values(res?.x || {}), 4).map((v: any) => getEUValue(v))
                            },
                            yaxis: {
                                title: {
                                    text:
                                        displayNames?.properties?.[res?.y_label]?.name || res.y_label,
                                    font: plotFont,
                                },
                                tickmode: 'array',
                                tickvals: getTickValues(Object.values(res?.y || {}), 4),
                                ticktext: getTickValues(Object.values(res?.y || {}), 4).map((v: any) => getEUValue(v))
                            },
                            title: formatText(experiments?.[0]?.ingredients?.[res?.x_label]?.name || displayNames?.[inputType]?.[res?.x_label]?.name || toTitleCase(res.x_label)) + `${' vs ' + displayNames?.properties?.[res?.y_label]?.name || res.y_label}`,
                            width: 600,
                            height: 500,
                            hovermode: "closest",
                        }}
                        data={[
                            {
                                x: Object.values(res?.x || {}) as any,
                                y: Object.values(res?.y || {}) as any,
                                type: "scatter",
                                mode: "markers",
                                name: index,
                                hovertemplate: hover,
                                hoverinfo: "x+y",
                            },
                        ]}
                    />
                </Col>
            ))
        }</div>
    )
}
