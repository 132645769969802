import { Dropdown, MenuProps, Space, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { InventoryMethod, InventoryMethodParameter } from "../types";
import { useDispatch } from "react-redux";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { InventoryMethodParameterModal } from "./InventoryMethodParameterModal";
import { deleteMethodParameterRequest } from "src/store/actions/inventoryV2";
import { useValue } from "src/utils/useValue";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";

const { Text } = Typography;

type InventoryMethodParametersTableProps = {
  method: InventoryMethod;
  inventory_id?: string;
  from: string;
  ingredient: any
};

export const InventoryMethodParametersTable = ({
  method, inventory_id, from, ingredient
}: InventoryMethodParametersTableProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { getValue } = useValue()
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [methodParameterModalOpen, setMethodParameterModalOpen] =
    useState<boolean>(false);

  const [selectedMethodParameter, setSelectedMethodParameter] = useState<
    InventoryMethodParameter | undefined
  >(undefined);

  const columns: ColumnsType<InventoryMethodParameter> = [
    {
      title: t("common.parameter"),
      dataIndex: "parameter",
      key: "parameter",
    },
    {
      title: t("common.unit"),
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: t("common.category"),
      dataIndex: "category",
      key: "category",
    },
    // {
    //   title: "Has Range",
    //   dataIndex: "has_range",
    //   key: "has_range",
    // },
    ...(from !== 'family' ? [{
      title: t("common.value"),
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any) => getValue(text),
    },
    {
      title: t("common.maxValue"),
      dataIndex: "value_max",
      key: "value_max",
      render: (text: any, record: any) => getValue(text),
    }] : []),
  ];

  if (!inventory_id) columns.push({
    title: "Actions",
    key: "actions",
    fixed: "right" as any,
    align: "center" as any,
    width: 200,
    render: (text, record) => (
      <Dropdown menu={menuProps(record)} placement="bottomRight">
        <StyledButton size="small" icon={<MoreOutlined />} />
      </Dropdown>
    ),
  })

  const menuItems: MenuProps["items"] = [
    {
      label: t("common.edit"),
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: t("common.delete"),
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const handleMenuClick = (e: any, record: InventoryMethodParameter) => {
    switch (e.key) {
      case "edit":
        setMode("edit");
        setSelectedMethodParameter(record);
        setMethodParameterModalOpen(true);
        break;
      case "delete":
        setSelectedMethodParameter(record);
        dispatch(
          deleteMethodParameterRequest({
            ...(from === 'family' ? { family_id: record?.family_id } : { property_id: record?.inventory_id }),
            ...(from === 'family' ? { property_id: record?.property_id } : { property_id: record?.inventory_property_id }),
            method_id: record.method_id,
            method_parameter_id: record.method_parameter_id,
            from
          })
        );
        break;
    }
  };

  const menuProps = (record: any) => {
    return {
      items: menuItems,
      onClick: (e: any) => handleMenuClick(e, record),
    };
  };

  return (
    <div
      style={{
        marginLeft: "20px",
        margin: "5px",
      }}
    >
      <Table
        caption={
          <Space
            style={{
              width: "100%",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <Text style={{ fontWeight: "bold" }}>
              Parameters : {method.method_name}
            </Text>
            {!inventory_id && <StyledButton
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setMode("create");
                setMethodParameterModalOpen(true);
              }}
            >
              Parameter
            </StyledButton>}
          </Space>
        }
        size="small"
        columns={columns}
        dataSource={method.conditions ? [...method.conditions] : []}
        rowKey={(record) => record.method_parameter_id}
        pagination={false}
      />
      <InventoryMethodParameterModal
        open={methodParameterModalOpen}
        setOpen={setMethodParameterModalOpen}
        mode={mode}
        method={method}
        methodParameter={selectedMethodParameter}
        from={from}
        ingredient={ingredient}
      />
    </div>
  );
};
