import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardProjectStateCountsRequest } from 'src/store/actions/dashboard'
import { StoreState } from 'src/store/configureStore'
import { BORDER_STYLE, useStatusNames } from '../../Dashboard'
import CountDesc from '../CountDesc'
import DrawerProjects from './DrawerProjects'
import Legend from './Legend'
import Pie from './Pie'
import useTranslate from 'src/utils/useTranslate'
import { Spin } from 'antd'
import { AsyncStates } from 'src/constants'

const projectColors: { [key: string]: string } = {
  ongoing: '#FFC700',
  completed: '#428feb',
  archived: '#60ea90'
}

const PieChartProjects = () => {
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const {
    dashboardProjectStateCountsResponse,
    dashboardProjectStateCountsStatus
  } = useSelector((state: StoreState) => state.dashboard)

  const { projectStatusNames } = useStatusNames()

  useEffect(() => {
    dispatch(dashboardProjectStateCountsRequest())
  }, [dispatch])

  const total = useMemo(() => {
    return dashboardProjectStateCountsResponse?.total || 0
  }, [dashboardProjectStateCountsResponse])

  const data = useMemo(() => {
    return Object.keys(dashboardProjectStateCountsResponse || {})
      .filter((key) => key !== 'total')
      .map((key) => ({
        title: key,
        name: projectStatusNames[key],
        count: dashboardProjectStateCountsResponse[key],
        color: projectColors[key]
      }))
      .sort((a, b) => b.count - a.count)
  }, [dashboardProjectStateCountsResponse, projectStatusNames])

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [selectedProjectStatus, setSelectedProjectStatus] = useState('')

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 24px',
        width: '40%',
        gap: 4,
        outline: BORDER_STYLE
      }}
    >
      <Spin
        spinning={dashboardProjectStateCountsStatus === AsyncStates.LOADING}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              marginTop: 12
            }}
          >
            <CountDesc count={total} desc={t('common.projects')} />
          </div>
          <Pie
            data={data}
            total={total}
            onClick={(status: string) => {
              if (!isDrawerVisible) setIsDrawerVisible(true)
              setSelectedProjectStatus(status)
            }}
            size={120}
          />
        </div>
        <Legend list={data} />
        <DrawerProjects
          open={isDrawerVisible}
          setOpen={setIsDrawerVisible}
          type={selectedProjectStatus}
          setType={setSelectedProjectStatus}
          data={data}
          total={total}
        />
      </Spin>
    </div>
  )
}

export default PieChartProjects
