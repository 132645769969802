import { Flex, Switch, Tag } from "antd";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteSemAnalysisMaskHeightWidthRequest } from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";

type TProps = {
    showBBox: boolean;
    setShowBBox: React.Dispatch<React.SetStateAction<boolean>>;
    showGeneralAnalysis: boolean;
    setShowGeneralAnalysis: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPoints: any[];
    setSelectedPoints: React.Dispatch<React.SetStateAction<any[]>>;
    setShowCreateGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
    isGroupsVisible: boolean
    setIsGroupsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setShowCalibrateModal: React.Dispatch<React.SetStateAction<boolean>>;
    tooltip: any;
};

const ProtrusionAnalysisDetailsSidebar = ({
    showBBox,
    setShowBBox,
    showGeneralAnalysis,
    setShowGeneralAnalysis,
    selectedPoints,
    setSelectedPoints,
    setShowCreateGroupModal,
    isGroupsVisible,
    setIsGroupsVisible,
    setShowCalibrateModal,
    tooltip
}: TProps) => {
    const dispatch = useDispatch();
    const params = useParams<any>();
    const { semAnalysisMaskData, analysisTypes } = useSelector((state: StoreState) => state.semAnalysis);

    return (
        <div className="sidebar">
            <div className="content">
                <div className="option">
                    <div className="label">Show General Analysis</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showGeneralAnalysis}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            setShowGeneralAnalysis(val);
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                {showGeneralAnalysis && (
                    <>
                        <div className="option">
                            <StyledButton
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        deleteSemAnalysisMaskHeightWidthRequest({
                                            file_id: params.fileId,
                                            analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS as keyof typeof analysisTypes]
                                        })
                                    );
                                }}
                            >
                                Delete All Mask Groups
                            </StyledButton>
                        </div>

                        {selectedPoints && selectedPoints.length > 0 && <div className="option">
                            <Flex gap={5} className="points-buttons">
                                <StyledButton
                                    size="small"
                                    onClick={() => setSelectedPoints([])}
                                >
                                    Clear Points
                                </StyledButton>
                                <StyledButton
                                    type="primary"
                                    size="small"
                                    onClick={() => setShowCreateGroupModal(true)}
                                >
                                    Create Group
                                </StyledButton>
                            </Flex>
                        </div>}

                        <div className="option">
                            <div className="label">Group Masks</div>
                            <Switch
                                checkedChildren={"Show"}
                                unCheckedChildren={"Hide"}
                                checked={isGroupsVisible}
                                onChange={(val: any) => {
                                    setIsGroupsVisible(val);
                                }}
                                style={{
                                    outline: "none",
                                }}
                            />
                        </div>

                        <div className="option">
                            <div className="label">Bounding Box</div>
                            <Switch
                                checkedChildren={"Show"}
                                value={showBBox}
                                unCheckedChildren={"Hide"}
                                onChange={(val: boolean) => {
                                    setShowBBox(val);
                                }}
                                style={{
                                    outline: "none",
                                }}
                            />
                        </div>
                    </>
                )}

                {semAnalysisMaskData.image_type && (
                    <>
                        <div className="option">
                            <div className="label">Horizontal Channel Width</div>
                            <Tag color="#424242">
                                {semAnalysisMaskData?.image_type?.horizontal_channel_width}
                            </Tag>
                        </div>

                        <div className="option">
                            <div className="label">Vertical Channel Width</div>
                            <Tag color="#424242">
                                {semAnalysisMaskData?.image_type?.vertical_channel_width}
                            </Tag>
                        </div>

                        {showGeneralAnalysis && (
                            <div className="option">
                                <div className="label">Average Width</div>
                                <Tag color="#424242">
                                    {semAnalysisMaskData?.image_type?.average_channel_width}
                                </Tag>
                            </div>
                        )}
                    </>
                )}


                {Object.values(tooltip?.content || {}).length ? (
                    <div className="option">
                        <div className="card">
                            <div style={{ lineBreak: "auto" }}>
                                {Object.values(tooltip?.content || {}).map((val: any) => {
                                    return (
                                        <div key={val} className="label">
                                            {val}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>


            <div className="option bottom-fixed">
                <StyledButton
                    size="small"
                    onClick={() => setShowCalibrateModal(true)}
                >
                    Calibrate
                </StyledButton>
            </div>

        </div>
    );
};

export default memo(ProtrusionAnalysisDetailsSidebar);
