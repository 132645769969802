import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import RepositoryActionDrawer from "../Common/RepositoryActionDrawer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { removeCategoryRequest } from "src/store/actions/repository";
import { CATEGORY_TYPES } from "../Common/Data";
import useTranslate from "src/utils/useTranslate";
import { AsyncStates } from "src/constants";
import NoData from "../Common/NoData";

type TProps = {
    searchTerm: string | undefined;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
    setMode: React.Dispatch<React.SetStateAction<"edit" | "create" | "delete">>;
    type: CATEGORY_TYPES;
};

const CategoryDetails = ({
    searchTerm,
    setSelectedCategory,
    setMode,
    setShowModal,
    type,
}: TProps) => {
    const [t] = useTranslate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const categories = useSelector(
        (state: StoreState) => state.repository.categories.data
    );
    const categoriesStatus = useSelector(
        (state: StoreState) => state.repository.categories.status
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedCategories.length > 0) setDrawerOpen(true);
        else setDrawerOpen(false);
    }, [selectedCategories]);

    useEffect(() => {
        // Update selected categories when changed
        const availableCategories = categories.map((c) => c.category_id);
        setSelectedCategories((prev) =>
            prev.filter((c) => availableCategories.includes(c))
        );
    }, [categories]);

    // const onRowSelection = (
    //     selectedRowKeys: React.Key[],
    //     selectedRows: any[]
    // ) => {
    //     setSelectedCategories(selectedRowKeys.map((k) => String(k)));
    // };

    const deleteCategories = useCallback(
        (ids?: string[]) => {
            setMode("delete");
            dispatch(
                removeCategoryRequest({
                    type: type,
                    category_ids: ids ? ids : selectedCategories,
                })
            );
        },
        [dispatch, selectedCategories, setMode, type]
    );

    const takeAction = useCallback(
        (action: string, record: any) => {
            setSelectedCategory(record.key);

            if (action === "edit") {
                setShowModal(true);
                setMode("edit");
            }
            if (action === "delete") {
                deleteCategories([record.key]);
            }
        },
        [deleteCategories, setSelectedCategory, setShowModal, setMode]
    );

    const onDismiss = () => {
        setSelectedCategories([]);
        setDrawerOpen(false);
    };

    const columns: any = useMemo(() => {
        const cols = [
            {
                title: t("common.Category"),
                dataIndex: "category",
                key: "category",
                width: 200,
            },
            {
                title: t("common.Sub-Category"),
                dataIndex: "sub-category",
                key: "sub-category",
            },
            {
                title: "",
                dataIndex: "",
                key: "action",
                fixed: "right",
                width: 40,
                render: (record: any) => (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: (
                                        <div>
                                            <EditOutlined /> {t("common.edit")}
                                        </div>
                                    ),
                                    key: "edit",
                                },
                                // {
                                //     label: (
                                //         <div style={{ color: "#F5222D" }}>
                                //             <DeleteOutlined /> {t("common.delete")}
                                //         </div>
                                //     ),
                                //     key: "delete",
                                // },
                            ],
                            onClick: (event) => takeAction(event.key, record),
                        }}
                        trigger={["click"]}
                    >
                        <MoreOutlined />
                    </Dropdown>
                ),
            },
        ];

        // Remove sub category for property & additive
        if (type !== CATEGORY_TYPES.INGREDIENT) cols.splice(1, 1);
        return cols;
    }, [takeAction, type, t]);

    const data = useMemo(() => {
        const filteredCategories = searchTerm
            ? categories.filter((c) =>
                JSON.stringify(Object.values(c))
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
            : categories;
        return filteredCategories.map((c) => ({
            key: c.category_id,
            category: c.name,
            "sub-category": c.sub_category ? c.sub_category.join(", ") : "",
        })).sort((a, b) => a.category.localeCompare(b.category));
    }, [categories, searchTerm]);

    return (
        <>
            {categories.length === 0 && categoriesStatus === AsyncStates.SUCCESS ? (
                <NoData
                    title={`${t("repository.totalCategories")} (0)`}
                    btns={[
                        t("repository.addNewCategory"),
                        t("repository.uploadBulkCategories"),
                    ]}
                    addItem={setShowModal}
                />
            ) : (
                <>
                    <div className="category-details-container">
                        <Table
                            bordered={false}
                            // rowSelection={{
                            //     type: "checkbox",
                            //     onChange: onRowSelection,
                            //     selectedRowKeys: selectedCategories,
                            // }}
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            className="repository-table"
                        />
                    </div>
                    <RepositoryActionDrawer
                        open={drawerOpen}
                        close={onDismiss}
                        selected={selectedCategories.length}
                        onDelete={deleteCategories}
                    />
                </>
            )}
        </>
    );
};

export default CategoryDetails;
