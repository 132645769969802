import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../store/configureStore";
import { Space, Card, Spin, Typography, Popconfirm, message } from "antd";
import EditorJs from "react-editor-js";
import Table from "editorjs-table";
import Paragraph from "@editorjs/paragraph";
import Marker from "@editorjs/marker";
import Undo from "editorjs-undo";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import {
  createProcedureRequest,
  retrieveProcedureRequest,
  clearProcedure,
} from "../../store/actions/procedures";
import { setEditingProceduresState } from "src/store/actions/workOrderDetails";
import "./editor-js.css";
import { AsyncStates, permissions, projectStatus } from "src/constants";
import axios from "axios";
import useTranslate from "src/utils/useTranslate";
import { LoadingOutlined } from "@ant-design/icons";
import { StyledButton } from "src/styled_components/StyledButton";
import jwtManager from "src/utils/jwtManager";
import { usePermission } from "src/utils/usePermissions";
import { WorkOrderContainer } from "../WorkOrderDetails/WorkOrderContainer";
import { setIsEditing } from "src/store/actions/isEditing";

const { Text } = Typography;

interface Editorjs {
  save: any;
  render: any;
  isReady: any;
  clear: any;
}

export const Procedures = (props: any) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const [editorInstance, setEditorInstance] = useState<Editorjs>();
  const { saveProcedureStatus, retrieveProcedureStatus, data } = useSelector(
    (state: StoreState) => state.procedure
  );
  const { editingProceduresState } = useSelector(
    (state: StoreState) => state.workOrderDetails
  );

  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );

  const userAccess = usePermission();
  const disabled = useMemo(
    () =>
      userAccess?.permission === permissions.viewer ||
      userAccess?.status !== projectStatus.in_progress,
    [userAccess]
  );

  useEffect(() => {
    dispatch(
      retrieveProcedureRequest({ work_order_id: workOrder.work_order_id })
    );
    return () => {
      dispatch(clearProcedure());
    };
  }, [dispatch, workOrder.work_order_id]);

  useEffect(() => {
    return () => {
      dispatch(clearProcedure());
      dispatch(setIsEditing(false));
      dispatch(setEditingProceduresState(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (editorInstance) {
      editorInstance.isReady
        .then(() => {
          if (data.length !== 0) {
            editorInstance.render({ blocks: data });
          }
          new Undo({ editor: editorInstance });
        })
        .catch((err: any) => null);
    }
  }, [editorInstance, data]);

  useEffect(() => {
    if (saveProcedureStatus === AsyncStates.SUCCESS) {
      dispatch(setIsEditing(false));
      dispatch(setEditingProceduresState(false));
    }
  }, [saveProcedureStatus, dispatch]);

  const changeHandler = () => {
    dispatch(setIsEditing(true));
    dispatch(setEditingProceduresState(true));
  }

  const save = () => {
    if (editorInstance) {
      editorInstance?.save().then((res: any) => {
        if (res.blocks.length !== 0) {
          dispatch(
            createProcedureRequest({
              work_order_id: workOrder.work_order_id,
              data: res.blocks,
            })
          );
        } else {
          message.error(t("common.addSomeContentToSave"));
        }
      });
    }
  };

  const clearContent = () => {
    if (editorInstance) {
      editorInstance.clear();
      editorInstance.save().then((res: any) => {
        dispatch(
          createProcedureRequest({
            work_order_id: workOrder.work_order_id,
            data: res.blocks,
          })
        );
      });
    }
  };


  return (
    <WorkOrderContainer>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Card
          style={{ borderTopLeftRadius: "0px" }}
          type="inner"
          extra={
            <Space>
              <Text type="secondary">{t("procedures.undoRedoTip")}</Text>
              {editingProceduresState ? (
                <Text strong type="warning">
                  {t("workOrderDetails.unsavedChanges")}
                </Text>
              ) : null}
              <StyledButton
                type="primary"
                onClick={save}
                disabled={disabled || workOrder?.status === "closed" || !editorInstance}
              >
                {t("common.save")}
              </StyledButton>
              <Popconfirm
                okText={t("common.ok")}
                cancelText={t("common.cancel")}
                title={t("procedures.clearContentMessage")}
                onConfirm={clearContent}
              >
                <StyledButton
                  disabled={disabled || workOrder?.status === "closed" || !editorInstance}
                >
                  {t("common.clear")}
                </StyledButton>
              </Popconfirm>
            </Space>
          }
        >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={
              saveProcedureStatus === AsyncStates.LOADING ||
              retrieveProcedureStatus === AsyncStates.LOADING
            }
          >
            <Space
              direction="vertical"
              size="large"
              style={{
                width: "100%",
                padding: 50,
                pointerEvents:
                  disabled || workOrder?.status === "closed" ? "none" : "all",
              }}
            >
              <EditorJs
                placeholder={t("procedures.clickHereToAdd")}
                instanceRef={(instance: any) => setEditorInstance(instance)}
                onChange={(api, event: any) => {
                  if (JSON.stringify(event?.blocks || []) !== JSON.stringify(data || [])) {
                    changeHandler()
                  }
                }}
                tools={{
                  table: { class: Table, inlineToolbar: true },
                  paragraph: { class: Paragraph, inlineToolbar: true },
                  Marker: {
                    class: Marker,
                    shortcut: "CMD+SHIFT+M",
                    inlineToolbar: true,
                  },
                  header: {
                    class: Header,
                    shortcut: "CMD+SHIFT+H",
                    inlineToolbar: true,
                  },
                  list: { class: List, inlineToolbar: true },
                  image: {
                    class: ImageTool,
                    config: {
                      uploader: {
                        uploadByFile(file: any) {
                          let bodyFormData = new FormData();
                          bodyFormData.append(
                            "payload",
                            JSON.stringify({
                              work_order_id: workOrder.work_order_id,
                            })
                          );
                          bodyFormData.append("file", file);
                          return axios
                            .post(
                              process.env.REACT_APP_API_URL +
                              "v1/data/freepage/upload/img",
                              bodyFormData,
                              {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                  token: jwtManager.getToken() as any,
                                },
                              }
                            )
                            .then((result) => result.data)
                            .catch((error) => null);
                        },
                      },
                    },
                  },
                }}
              />
            </Space>
          </Spin>
        </Card>
      </Space>
    </WorkOrderContainer>
  );
};
