import { PlusOutlined } from "@ant-design/icons";
import { Cascader, Divider, Form, Modal, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import AddEditPropertyModel from "../Repository/Common/AddEditPropertyModel";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { fetchPropertyRequest } from "src/store/actions/repository";
import { AsyncStates } from "src/constants";
import { addPropertyToIngredientClear, addPropertyToIngredientRequest } from "src/store/actions/inventoryV2";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";

export const AddPropertyToIngredientModal = ({ addPropertyModalVisible, setAddPropertyModalVisible }: any) => {
    const [form] = Form.useForm();
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)
    const properties = useSelector((state: StoreState) => state.repository.properties.data);
    const propertiesStatus = useSelector((state: StoreState) => state.repository.properties.status);
    const addPropertyToIngredientStatus = useSelector((state: StoreState) => state.inventoryV2.addPropertyToIngredientStatus);


    useEffect(() => {
        dispatch(fetchPropertyRequest())
    }, [dispatch])

    useEffect(() => {
        if (addPropertyToIngredientStatus === AsyncStates.SUCCESS) {
            setAddPropertyModalVisible({
                isModalVisible: false,
                mode: "create",
                data: null
            })
            form.resetFields()
            dispatch(addPropertyToIngredientClear())
        }

    }, [addPropertyToIngredientStatus, form, setAddPropertyModalVisible, dispatch])


    const propertiesOptions = useMemo(() => {
        const uniqueCategoryIds = [...new Set(properties.map((property) => property.category_id))]
        const options = uniqueCategoryIds.filter((categoryId) => !!categoryId).map((categoryId) => {
            return {
                label: properties.find((property) => property.category_id === categoryId)?.category_name,
                value: categoryId,
                children: properties.filter((property) => property.category_id === categoryId).map((property) => ({
                    label: property.name,
                    value: property.property_id
                })).sort((a, b) => a.label?.localeCompare(b.label))
            }
        }).sort((a, b) => a.label?.localeCompare(b.label))
        return options
    }, [properties])

    const handleAddPropertyFormSubmit = (values: any) => {
        const propertyIds = values.property.reduce((acc: any, property: any) => {
            if (property.length === 1) {
                const propertyBasedOnCategory = properties.filter((prop) => prop.category_id === property[0]).map((prop) => prop.property_id)
                if (!!propertyBasedOnCategory.length) {
                    acc.push(...propertyBasedOnCategory)
                }
            }
            if (property.length === 2) {
                acc.push(property[1])
            }
            return [...new Set(acc)]
        }, [])
        const payload = {
            property_ids: propertyIds,
            inventory_id: addPropertyModalVisible.data.inventory_id
        }
        dispatch(addPropertyToIngredientRequest(payload))
    }

    return (
        <>
            <Modal
                title={t("inventory.addProperty")}
                open={addPropertyModalVisible.isModalVisible} footer={null} onCancel={() => {
                    setAddPropertyModalVisible({
                        isModalVisible: false,
                        mode: "create",
                        data: null
                    })
                }}>
                <Form layout="vertical" form={form} onFinish={handleAddPropertyFormSubmit}>
                    <Form.Item
                        label={t("common.property")}
                        name={"property"}
                        rules={[
                            {
                                required: true,
                                message: t("common.requiredInfo")
                            }
                        ]}
                    >
                        <Cascader
                            options={propertiesOptions}
                            multiple
                            maxTagCount="responsive"
                            showSearch
                            showCheckedStrategy={"SHOW_PARENT"}
                            dropdownRender={menu => (
                                <Spin spinning={propertiesStatus === AsyncStates.LOADING}>
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '0.25rem 0' }} />
                                        <div style={{ display: "flex", justifyContent: "flex-end", padding: "0.25rem 0rem" }}>
                                            <StyledButton type="link" size="small"
                                                icon={<PlusOutlined />}
                                                onClick={() => {
                                                    setShowModal(true)

                                                }}>{t("common.addNewProperty")}</StyledButton>
                                        </div>
                                    </div>
                                </Spin>
                            )
                            }
                        />
                    </Form.Item>

                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "0.5rem", alignItems: "center" }}>
                        <Form.Item>
                            <StyledButton danger
                                onClick={() => {
                                    form.resetFields()
                                }}
                            >{t("common.reset")}</StyledButton>
                        </Form.Item>
                        <Form.Item>
                            <StyledButton htmlType='submit' type="primary" loading={addPropertyToIngredientStatus === AsyncStates.LOADING}>{t("common.save")}</StyledButton>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
            <AddEditPropertyModel showModal={showModal} setShowModal={setShowModal} mode={"create"} />
        </>
    )
}