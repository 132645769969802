import { LoadingOutlined } from "@ant-design/icons";
import { Input, Modal, Select, Form, Row, Spin, AutoComplete } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { createParameterRequest } from "src/store/actions/inventory";
import { fetchAllCategoriesRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";

const { Option, OptGroup } = Select

interface CustomParameterModalProps {
    dataset: any
    showNewParamModal: boolean
    setShowNewParamModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function CustomParameterModal({ dataset: property_type, showNewParamModal, setShowNewParamModal }: CustomParameterModalProps) {
    const dispatch = useDispatch()
    const requiredFieldStar = useRequiredFieldStar()

    const ingredientAndPropertyCategories = useSelector((state: StoreState) => state.repository.allCategories.data);
    const ingredientAndPropertyStatus = useSelector((state: StoreState) => state.repository.allCategories.status);

    const unitsList = useSelector((state: StoreState) => state.conversion.unitList)
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const createParameterStatus = useSelector((state: StoreState) => state.inventory.createParameterStatus)
    const [type, setType] = useState(property_type)
    const [form] = Form.useForm()
    const [t] = useTranslate()

    useEffect(() => {
        if (showNewParamModal) {
            setType(property_type)
            form.setFieldsValue({ type: property_type })
        }
    }, [showNewParamModal, property_type, form])

    useEffect(() => {
        if (showNewParamModal) {
            dispatch(fetchAllCategoriesRequest())
        }
    }, [dispatch, showNewParamModal])

    const filterCategories = useMemo(() => {
        if (type === "ingredients") {
            return ingredientAndPropertyCategories?.["ingredient_category"]?.map((category: any) => {
                return {
                    key: category.category_id,
                    label: category.name,
                    value: category.category_id
                }
            })
        }
        else if (type === "characterizations") {
            const characterizationsCategoriesList = [...new Set(Object.values(displayNames?.characterizations || {}).map((res: any) => res.category))].filter((category: any) => category)
            return characterizationsCategoriesList.map((category: any) => {
                return {
                    key: category,
                    label: category,
                    value: category
                }
            })
        }
        else if (type === "processing") {
            const processingCategoriesList = [...new Set(Object.values(displayNames?.processing || {}).map((res: any) => res.category))].filter((category: any) => category)
            return processingCategoriesList.map((category: any) => {
                return {
                    key: category,
                    label: category,
                    value: category
                }
            })
        }
        else if (type === "properties") {
            return ingredientAndPropertyCategories?.["property_category"]?.map((category: any) => {
                return {
                    key: category.category_id,
                    label: category.name,
                    value: category.category_id
                }
            })
        }
        return []
    }, [displayNames, type, ingredientAndPropertyCategories])

    const clearData = useCallback(() => {
        setType("ingredients")
        form.resetFields()
        setShowNewParamModal(false)
    }, [form, setShowNewParamModal])

    useEffect(() => {
        if (createParameterStatus === AsyncStates.SUCCESS) {
            clearData()
        }
    }, [createParameterStatus, clearData])

    const addCustomParameter = (values: any) => {
        const { name, type, category, unit, } = values
        const params = new FormData()
        params.append("name", name?.trim())
        params.append("type", type)
        params.append("category", category?.trim())
        params.append("unit", JSON.stringify(Array.isArray(unit) ? unit : [unit]))
        dispatch(createParameterRequest(params))
    }


    return (
        <Modal
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            title={t("common.addCustomParameter(s)")}
            open={showNewParamModal}
            onCancel={clearData}
            maskClosable={false}
            footer={null}
        >
            <Spin spinning={createParameterStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Form layout="vertical" form={form} onFinish={addCustomParameter} requiredMark={false} >
                    <Form.Item label={t("common.type")} rules={[{ required: true }]} name="type" required tooltip={requiredFieldStar}>
                        <Select defaultValue={type} onChange={(e: any) => { setType(e); form.setFieldsValue({ category: "", unit: "", name: "" }) }}>
                            <Option value="ingredients">{t("common.ingredients")}</Option>
                            <Option value="characterizations">{t("common.characterizations")}</Option>
                            <Option value="processing">{t("common.processing")}</Option>
                            <Option value="properties"> {t("formulations.type.properties")}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={t('common.name')} required tooltip={requiredFieldStar} rules={[{ required: true, min: 2, whitespace: true, type: "string", transform: (value) => value?.trim() }]} name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label={t("common.category")} required tooltip={requiredFieldStar} rules={[{ required: true, transform: (value) => value?.trim() }]} name="category">
                        {
                            type === "processing" ?
                                <AutoComplete
                                    showSearch
                                    placeholder={t("common.selectCategory")}
                                    filterOption={(inpVal, opt) => opt?.label?.toLowerCase().includes(inpVal.toLowerCase()) || false}
                                    options={filterCategories}
                                />
                                :
                                <Select
                                    showSearch
                                    placeholder={t("common.selectCategory")}
                                    filterOption={(inpVal, opt) => opt?.label?.toLowerCase().includes(inpVal.toLowerCase()) || false}
                                    options={filterCategories}
                                    dropdownRender={(menu) => {
                                        return (
                                            <Spin spinning={ingredientAndPropertyStatus === AsyncStates.LOADING}>
                                                {menu}
                                            </Spin>
                                        )
                                    }}
                                />
                        }
                    </Form.Item>
                    <Form.Item label={t("common.unit")} required tooltip={requiredFieldStar} rules={[{ required: true }]} name="unit">
                        <Select
                            mode={type === "processing" ? "multiple" : undefined}
                            showSearch
                            placeholder={t("common.selectUnit")}>
                            {Array.from(new Set(unitsList.filter((res: any) => type === "ingredients" ? ["ratio", "weight", "Ignore"].includes(res.category.toLowerCase()) : true).map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                                <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                                    {unitsList.filter((res: any) => res.category.toLowerCase() === category).map((res: any) => (
                                        <Option value={res.name} key={res.name}>{res.name}</Option>
                                    ))}
                                </OptGroup>
                            )}
                        </Select>
                    </Form.Item>
                    <Row justify="end" style={{ marginTop: 50 }}>
                        <StyledButton htmlType="submit" type="primary">{t("common.submit")}</StyledButton>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}
