import { notification } from "antd";
import { put, takeLatest, call, select } from "redux-saga/effects";
import { messages } from "src/utils/hooks";
import jwtManager from "src/utils/jwtManager";
import { LanguageUnion } from "src/utils/useTranslate";
import {
  createParameterApi,
  updateParameterApi,
  checkPropertyApi,
  deletePropertyApi,
  createMetadataTemplateApi,
  updateMetadataTemplateApi,
  listMetadataTemplateApi,
  deleteMetadataTemplateApi,
  inventoryAttachmentsAddApi,
  inventoryAttachmentsDeleteApi,
  inventoryAttachmentsListApi,
  inventoryTotoInkUploadApi,
  inventoryUploadTDSApi,
} from "../../services/inventory";
import { displayNamesRequest } from "../actions/displayNames";
import {
  checkPropertyExistsRequest,
  checkPropertyExistsSuccess,
  checkPropertyExistsFailure,
  deletePropertyRequest,
  deletePropertySuccess,
  deletePropertyFailure,
  createParameterRequest,
  createParameterFailure,
  createParameterSuccess,
  updateParameterFailure,
  updateParameterRequest,
  updateParameterSuccess,
  createMetadataTemplateFailure,
  createMetadataTemplateSuccess,
  createMetadataTemplateRequest,
  editMetadataTemplateFailure,
  editMetadataTemplateRequest,
  editMetadataTemplateSuccess,
  listMetadataTemplateFailure,
  listMetadataTemplateRequest,
  listMetadataTemplateSuccess,
  deleteMetadataTemplateFailure,
  deleteMetadataTemplateRequest,
  deleteMetadataTemplateSuccess,
  inventoryAttachmentsAddFailure,
  inventoryAttachmentsAddRequest,
  inventoryAttachmentsAddSuccess,
  inventoryAttachmentsDeleteFailure,
  inventoryAttachmentsDeleteRequest,
  inventoryAttachmentsDeleteSuccess,
  inventoryAttachmentsListFailure,
  inventoryAttachmentsListRequest,
  inventoryAttachmentsListSuccess,
  inventoryUploadFailure,
  inventoryUploadRequest,
  inventoryUploadSuccess,
} from "../actions/inventory";
import { StoreState } from "../configureStore";

function* createParameterSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { message, status },
      },
    } = yield call(createParameterApi, payload, headers);
    if (status === "Success") {
      yield put(createParameterSuccess(payload));
      yield put(displayNamesRequest({ backgroundFetch: true }));
      notification.success({
        message,
        placement: "bottomRight",
      });
    } else {
      yield put(createParameterFailure(payload));
      notification.error({
        message,
        placement: "bottomRight",
      });
    }
  } catch (error) {
    yield put(createParameterFailure());
    notification.error({
      message: messages[ln].internal_server_error,
      placement: "bottomRight",
    });
  }
}

function* updateParameterSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { message, status },
      },
    } = yield call(updateParameterApi, payload, headers);
    if (status === "Success") {
      yield put(updateParameterSuccess());
      yield put(displayNamesRequest({ backgroundFetch: true }));
      notification.success({
        message,
        placement: "bottomRight",
      });
    } else {
      yield put(updateParameterFailure());
      notification.error({
        message,
        placement: "bottomRight",
      });
    }
  } catch (error) {
    yield put(updateParameterFailure());
    notification.error({
      message: messages[ln].internal_server_error,
      placement: "bottomRight",
    });
  }
}

function* checkPropertyExistsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status },
      },
    } = yield call(checkPropertyApi, payload, headers);
    if (status === "Success") {
      yield put(checkPropertyExistsSuccess(data));
    } else {
      yield put(checkPropertyExistsFailure());
    }
  } catch (error) {
    yield put(checkPropertyExistsFailure());
  }
}

function* deletePropertySaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { message, status },
      },
    } = yield call(deletePropertyApi, payload, headers);
    if (status === "Success") {
      yield put(deletePropertySuccess());
      notification.success({ message });
      yield put(displayNamesRequest({ backgroundFetch: true }));
    } else {
      yield put(deletePropertyFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    yield put(deletePropertyFailure(error));
    notification.error({ message: messages[ln].internal_server_error });
  }
}

function* createMetadataTemplateSaga({
  payload,
}: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message },
      },
    } = yield call(createMetadataTemplateApi, payload, headers);
    if (status === "Success") {
      yield put(createMetadataTemplateSuccess());
      notification.success({ message });
      yield put(listMetadataTemplateRequest({ type: "company" }));
    } else {
      yield put(createMetadataTemplateFailure());
    }
  } catch (error) {
    yield put(createMetadataTemplateFailure());
  }
}

function* deleteMetadataTemplateSaga({
  payload,
}: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message },
      },
    } = yield call(deleteMetadataTemplateApi, payload, headers);
    if (status === "Success") {
      yield put(deleteMetadataTemplateSuccess());
      yield put(listMetadataTemplateRequest({ type: payload?.type }));
      notification.success({
        message: message,
        placement: "bottomRight",
      });
    } else {
      yield put(deleteMetadataTemplateFailure(message));
    }
  } catch (error) {
    yield put(deleteMetadataTemplateFailure());
  }
}

function* editMetadataTemplateSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message },
      },
    } = yield call(updateMetadataTemplateApi, payload, headers);
    if (status === "Success") {
      yield put(editMetadataTemplateSuccess());
      yield put(listMetadataTemplateRequest({ type: payload?.currentType }));
      notification.success({
        message: message,
        placement: "bottomRight",
      });
    } else {
      yield put(editMetadataTemplateFailure(message));
    }
  } catch (error) {
    yield put(editMetadataTemplateFailure());
  }
}

function* listMetadataTemplateSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(listMetadataTemplateApi, payload, headers);
    if (status === "Success") {
      yield put(listMetadataTemplateSuccess(data));
    } else {
      yield put(listMetadataTemplateFailure(message));
    }
  } catch (error) {
    yield put(listMetadataTemplateFailure());
  }
}

function* inventoryAttachmentsAddSaga({
  payload,
}: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status },
      },
    } = yield call(inventoryAttachmentsAddApi, payload, headers);
    if (status === "Success") {
      yield put(inventoryAttachmentsAddSuccess());
      yield put(
        inventoryAttachmentsListRequest({
          type: payload.get("type"),
          identifier: payload.get("identifier"),
        })
      );
    } else {
      yield put(inventoryAttachmentsAddFailure());
    }
  } catch (error) {
    yield put(inventoryAttachmentsAddFailure());
  }
}

function* inventoryAttachmentsListSaga({
  payload,
}: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status },
      },
    } = yield call(inventoryAttachmentsListApi, payload, headers);
    if (status === "Success") {
      yield put(inventoryAttachmentsListSuccess(data));
    } else {
      yield put(inventoryAttachmentsListFailure());
    }
  } catch (error) {
    yield put(inventoryAttachmentsListFailure());
  }
}

function* inventoryAttachmentsDeleteSaga({
  payload,
}: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status },
      },
    } = yield call(inventoryAttachmentsDeleteApi, payload, headers);
    if (status === "Success") {
      yield put(inventoryAttachmentsDeleteSuccess());
      yield put(
        inventoryAttachmentsListRequest({
          type: payload?.type,
          identifier: payload?.identifier,
        })
      );
    } else {
      yield put(inventoryAttachmentsDeleteFailure());
    }
  } catch (error) {
    yield put(inventoryAttachmentsDeleteFailure());
  }
}

function* inventoryUploadSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders, configs } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message: apiMessage, data },
      },
    } = yield call(
      configs.toyoink_configs ? inventoryTotoInkUploadApi : 
      inventoryUploadTDSApi,
      // :
      // payload.get("is_tds") ? 
      // inventoryUploadApi,
      payload,
      headers
    );
    if (status === "Success") {
      yield put(inventoryUploadSuccess(data));
      yield put(displayNamesRequest({ backgroundFetch: true }));
      notification.success({ message: apiMessage });
    } else {
      notification.error({message: apiMessage});
      yield put(inventoryUploadFailure());
    }
  } catch (error) {
    yield put(inventoryUploadFailure());
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(createParameterRequest, createParameterSaga);
  yield takeLatest(updateParameterRequest, updateParameterSaga);
  yield takeLatest(checkPropertyExistsRequest, checkPropertyExistsSaga);
  yield takeLatest(deletePropertyRequest, deletePropertySaga);
  yield takeLatest(createMetadataTemplateRequest, createMetadataTemplateSaga);
  yield takeLatest(deleteMetadataTemplateRequest, deleteMetadataTemplateSaga);
  yield takeLatest(editMetadataTemplateRequest, editMetadataTemplateSaga);
  yield takeLatest(listMetadataTemplateRequest, listMetadataTemplateSaga);
  yield takeLatest(inventoryAttachmentsAddRequest, inventoryAttachmentsAddSaga);
  yield takeLatest(
    inventoryAttachmentsListRequest,
    inventoryAttachmentsListSaga
  );
  yield takeLatest(
    inventoryAttachmentsDeleteRequest,
    inventoryAttachmentsDeleteSaga
  );
  yield takeLatest(inventoryUploadRequest, inventoryUploadSaga);
}
