
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`ラテン超方格法`}</h1>
    <p><a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/%E3%83%A9%E3%83%86%E3%83%B3%E8%B6%85%E6%96%B9%E6%A0%BC%E6%B3%95"
      }}>{`ラテン超方格法（LHS）`}</a>{`は、多次元分布からランダムに近いパラメータ値の標本を生成する統計手法です。この標本抽出手法は、コンピュータ実験の構築によく用いられます。`}</p>
    <p>{`ラテン超方格法（LHS）は、パラメータ値のランダムなサンプルを生成する手法です。`}<a parentName="p" {...{
        "href": "https://www.statisticshowto.com/monte-carlo-simulation/"
      }}>{`モンテカルロシミュレーション`}</a>{`では、比較的正確な結果を得るために必要な実行回数を劇的に減らすことができます。`}</p>
    <p>{`LHSは、`}<a parentName="p" {...{
        "href": "https://www.statisticshowto.com/latin-square-design/"
      }}>{`ラテン方格デザイン`}</a>{`に基づいています。ラテン方格デザインは、各行と列に単一のサンプルを持つデザインです。"超立方体"とは、3次元以上の立方体を指し、ラテン方格は複数の次元や超平面からサンプルを抽出するために拡張されています。`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;