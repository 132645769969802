import { DeleteFilled } from '@ant-design/icons'
import { Collapse, Descriptions, Popconfirm, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate, { TranslationKey } from 'src/utils/useTranslate'

const { Text } = Typography

export const identifiers = {
    'scatter-plot': "SCATTER PLOT",
    'surface-plot': "SURFACE PLOT",
    "recipe-distribution": 'recipe-distribution',
    "pivot-analysis": 'pivot-analysis',
    "visualize-distribution": 'visualize-distribution'
}

export const ViewSelectedGraphsData = ({ plotData, getDisplayName, createdOn = null, from, key, setSaveGraphsPayload, plot }: any) => {
    const [t] = useTranslate()
    const configs = useSelector((state: StoreState) => state.configs.features);
    const { plotOptions } = useSelector((state: StoreState) => state.dataSummary.dataSummaryPlotOptions)

    const selectedplotData = from === "graph_history_drawer" ? plotData?.plot_data : plotData

    const getTranslatedKey = (input: string, t: (key: TranslationKey) => string) => {
        if (input === "ingredients") return t("common.ingredients");
        if (input === "processing") return t("common.processing");
        if (input === "properties") return t("common.properties");
        if (input === "scatter-plot") return t("dataSummary.scatterPlot")
        if (input === "surface-plot") return t("dataSummary.surfacePlot")
        if (input === "recipe-distribution") return t("aiEngine.RecipesDistribution")
        if (input === "pivot-analysis") return t("dataSummary.pivotAnalysis")
        if (input === "visualize-distribution") return t("dataSummary.visualizeDistributions")
        if (input === "x") return "X"
        if (input === "y") return "Y"
        if (input === "agg") return t("graphs.aggregationMethod")
        if (input === "prop_to_compare") return t("graphs.propertyCompare")
        if (input === "numerical_list") return t("graphs.numericalList")
        if (input === "categorical_list") return t("graphs.categoricalList")
        return input;
    };

    const showParamerterDisplayName = (parameterType: string, parameterData: string) => {
        if (parameterType === "processing") {
            const profileName = plotOptions?.variation_data?.[parameterType]?.[parameterData[2]] ?? plotData?.variation_data?.[parameterType]?.[parameterData[2]]
            return `${parameterData[1]} (${profileName})`
        } else if (parameterType === "properties") {
            const variationName = plotOptions?.variation_data?.[parameterType]?.[parameterData[1]]?.variation_name ?? plotData?.variation_data?.[parameterType]?.[parameterData[1]]
            return `${getDisplayName(parameterData[parameterData.length - 1])} (${variationName})`
        }
    }

    const getDisplayNameForSurfaceRecipe = (parameterType: string, parameterIdentifier: string, parameterData: string) => {
        return !Boolean(configs?.nestle_configs) ?
            getDisplayName(parameterIdentifier) :
            ["properties", "processing"].includes(parameterType) ?
                showParamerterDisplayName(parameterType, parameterData) :
                getDisplayName(parameterIdentifier)
    }

    const popConfirm = (key: string) => {
        return <Popconfirm
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            title={t("graphs.deleteConfirmation")}
            onCancel={(e) => e?.stopPropagation()}
            onConfirm={(e) => {
                e?.stopPropagation()
                setSaveGraphsPayload((prev: any) => {
                    const prevState = JSON.parse(JSON.stringify(prev))
                    if (prevState?.[key]) {
                        delete prevState[key]
                        return prevState
                    }
                    return prevState
                })
            }}
        >
            <DeleteFilled style={{ color: "#FF0000" }} onClick={(e) => e.stopPropagation()} />
        </Popconfirm>
    }

    return (
        <>
            {Object.entries(selectedplotData || {}).map(([key, currentPlotData]: [string, any]) => (
                <Collapse bordered={false} expandIconPosition='start' size='small' className="graph__history__drawer__collapse" key={key}>
                    <Collapse.Panel header={<Text strong>{getTranslatedKey(key, t)}</Text>} key={key} extra={from === "save_graphs_modal" ? <>{popConfirm(key)}</> : null}>
                        <Descriptions
                            column={1}
                            bordered
                            size="small"
                            style={{ background: "white" }}
                        >
                            {
                                key === "scatter-plot" && (
                                    <Descriptions.Item key={key} label={t("common.parameters")}>
                                        <div>
                                            {((currentPlotData?.["x1"]) || currentPlotData?.["y1"]) && <div>Plot 1: {((getDisplayName(currentPlotData?.["x1"])) ?? "Not Selected") + ", " + (getDisplayName(currentPlotData?.["y1"]) ?? "Not Selected")}</div>}
                                            {(currentPlotData?.["x2"] || currentPlotData?.["y2"]) && <div>Plot 2: {(getDisplayName(currentPlotData?.["x2"]) ?? "Not Selected") + ", " + (getDisplayName(currentPlotData?.["y2"]) ?? "Not Selected")}</div>}
                                        </div>
                                    </Descriptions.Item>
                                )

                            }
                            {
                                key === "surface-plot" && (
                                    Object.entries(currentPlotData || {}).map(([parameterType, parameterData]: any) => (
                                        <Descriptions.Item key={parameterData[0]} label={getTranslatedKey(parameterData[0], t)}>
                                            {getDisplayNameForSurfaceRecipe(parameterData[0], parameterData[parameterData.length - 1], parameterData)}
                                        </Descriptions.Item>
                                    ))
                                )
                            }
                            {
                                key === "recipe-distribution" && (
                                    <>
                                        <Descriptions.Item key={key} label={t("graphs.data")}>
                                            {(
                                                Object.entries((currentPlotData as any)?.data || {}).map(([parameterType, parameterData]: any) => (
                                                    <Descriptions.Item key={parameterData[0]} label={parameterData[0]}>
                                                        <div>
                                                            <Text strong>{getTranslatedKey(parameterData[0], t)}</Text>: {getDisplayNameForSurfaceRecipe(parameterData[0], parameterData[parameterData.length - 1], parameterData)}
                                                        </div>
                                                    </Descriptions.Item>
                                                ))
                                            )}
                                        </Descriptions.Item>
                                        {!!(currentPlotData as any)?.filters?.length &&
                                            <Descriptions.Item key={key} label={"Filters"} style={{ width: "100%" }}>
                                                {(currentPlotData as any)?.filters?.map((filter: any) => {
                                                    if (filter?.["work_order_id"]) {
                                                        const workOrderName = filter?.[filter?.["work_order_id"]]
                                                        return <Tag style={{ margin: "0.2rem" }}>{t("common.workOrder")} = {workOrderName ?? filter?.["work_order_id"]}</Tag>
                                                    } else if (filter?.["material"]) {
                                                        return <Tag style={{ margin: "0.2rem" }}>{filter?.param?.[0]} = {filter?.["material"]}</Tag>
                                                    } else if (filter?.["grade"]) {
                                                        return <Tag style={{ margin: "0.2rem" }}>{filter?.param?.[0]} = {filter?.["grade"]}</Tag>
                                                    } else if (filter?.op === "range") {
                                                        return <Tag style={{ margin: "0.2rem" }}>{getDisplayNameForSurfaceRecipe(filter?.param?.[0], filter?.param?.[filter?.param?.length - 1], filter?.param)} {filter?.[filter?.op]} {filter.val} - {filter.val2} </Tag>
                                                    }
                                                    return <Tag style={{ margin: "0.2rem" }}> {getDisplayNameForSurfaceRecipe(filter?.param?.[0], filter?.param?.[filter?.param?.length - 1], filter?.param)} {filter?.op} {filter.val}</Tag>
                                                }
                                                )}
                                            </Descriptions.Item>
                                        }
                                    </>
                                )
                            }
                            {
                                ["pivot-analysis", "visualize-distribution"].includes(key) && (
                                    <>
                                        {Object.entries((currentPlotData as any) || {}).map(([parameterType, parameterData]: any) => (
                                            <Descriptions.Item key={parameterType} label={getTranslatedKey(parameterType, t)}>
                                                {Array.isArray(parameterData) ? parameterData.map((param) => getDisplayName(param)).join(", ") : typeof parameterData === "string" ? getDisplayName(parameterData) : null}
                                            </Descriptions.Item>
                                        ))}
                                    </>
                                )
                            }
                        </Descriptions>
                    </Collapse.Panel>
                </Collapse>
            ))}
            {plot?.created ? (
                <Descriptions
                    column={1}
                    bordered
                    size="small"
                    style={{ background: "white" }}
                >
                    <Descriptions.Item key={t("common.createdOn")} label={t("common.createdOn")}>
                        {dayjs
                            .utc(plot?.created)
                            .format("MMMM Do YYYY, h:mm A") + " (UTC)"}
                    </Descriptions.Item>
                </Descriptions>
            ) : null}
            {!!Object.keys(plot?.data?.additional_info?.project ?? {})?.length ? (
                <Descriptions
                    column={1}
                    bordered
                    size="small"
                    style={{ background: "white" }}
                >
                    <Descriptions.Item key={plot?.data?.additional_info?.project?.project_id} label={t("common.project")}>
                        {plot?.data?.additional_info?.project?.project_name}
                    </Descriptions.Item>
                </Descriptions>
            ) : null}
        </>
    )
}
