import { Dataset } from "./getHeaders"

export enum Stages {
    work_order_initiated = 'work_order_initiated',
    formulation_stage = 'formulation_stage',
    processing_stage = 'processing_stage',
    characterization_stage = 'characterization_stage',
    properties_stage = 'properties_stage',
    report_preview = 'report_preview',
    work_order_status = 'work_order_status'
}

export const mapDataset = (stage: Stages) => {
    switch (stage) {
        case Stages.work_order_initiated:
            return undefined
        case Stages.formulation_stage:
            return Dataset.ingredients
        case Stages.processing_stage:
            return Dataset.processing
        case Stages.characterization_stage:
            return Dataset.characterizations
        case Stages.properties_stage:
            return Dataset.properties
    }
}