import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { setIsEditing } from 'src/store/actions/isEditing';

export const fieldChange = (value: any, dispatch: any) => {
    if (!!value) {
        dispatch(setIsEditing(true))
    } else {
        dispatch(setIsEditing(false));
    }
}

type P = {
	isEditing: boolean
	message: string
}

export const CustomPrompt = ({ isEditing, message }: P) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setIsEditing(false))
    }, [dispatch])

    const callback = useCallback((e: any) => {
        e.preventDefault()
        e.returnValue = "Changes you made may not be saved."
    }, [])

    useEffect(() => {
        (isEditing) && window.addEventListener("beforeunload", callback)
        return () => {
            window.removeEventListener("beforeunload", callback)
        }
    }, [isEditing, callback])

    return <Prompt when={isEditing} message={message} />
}; 