import { SmileOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { useHistory } from 'react-router-dom';
import { StyledButton } from 'src/styled_components/StyledButton';
import useTranslate from 'src/utils/useTranslate';

export const AfterResetPassword = () => {
    const [t] = useTranslate()
    const history = useHistory()
    return <Result
        icon={<SmileOutlined />}
        title="Password changed successfully"
        subTitle="Login to continue"
        extra={<StyledButton type="primary" onClick={() => history.push("/")}>{t("common.login")}</StyledButton>}
    />;
}