import {
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
} from "@ant-design/icons";
import { Collapse, Empty, Modal, Spin } from "antd";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
    deleteResidualBatchClear,
    deleteResidualBatchRequest,
    deleteSemAnalysisFilesClear,
    downloadBatchReportRequest,
    fetchResidualBatchInfoRequest,
    residualRecalibrateScaleClear,
    residualRecalibrateScaleRequest,
    setImageSubtractionClear,
} from "src/store/actions/semAnalysis";
import { AsyncStates } from "src/constants";
import { useHistory, useParams } from "react-router-dom";
import { IMAGE_ANALYSIS_TYPES } from "../SEMAnalysisWrapper";
import ThumbnailList from "../Shared/ThumbnailList";
import BatchCard from "../Shared/BatchCard";
import SelectionsActions from "../Shared/SelectionsActions";
import { Unsubscribe } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_SEM_ANALYSIS, firestoreDb } from "src/utils/firebase";
import ReCalibrateModal from "../Shared/ReCalibrateModal";
const { Panel } = Collapse

const ResidualAnalysis = () => {
    const dispatch = useDispatch();
    const params = useParams<any>();
    const {
        residualBatchInfo,
        residualBatchInfoStatus,
        residualRecalibrateScaleStatus,
        deleteSemAnalysisFilesStatus,
        deleteResidualBatchStatus,
        setImageSubtraction,
        setImageSubtractionStatus,
        downloadBatchReportStatus,
        analysisTypes
    } = useSelector((state: StoreState) => state.semAnalysis);
    const history = useHistory();
    const [view, setView] = useState<"batch" | "expanded">("batch");
    const [selectedBatch, setSelectedBatch] = useState<string>();
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [loadingDataMap, setLoadingDataMap] = useState<any>({});
    const [reCalibrationModalOpen, setReCalibrateModalOpen] = useState(false);

    useLayoutEffect(() => {
        if (Array.isArray(residualBatchInfo) && params.batchId) {
            setView("expanded");
            setSelectedBatch(params.batchId);
        } else {
            setSelectedBatch(undefined);
            setView("batch");
        }
    }, [residualBatchInfo, params]);

    const fetchBatchInfo = useCallback(() => {
        dispatch(
            fetchResidualBatchInfoRequest({
                batch_id: params?.batchId,
                analysis_type:
                    analysisTypes[
                    IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS as keyof typeof analysisTypes
                    ],
            })
        );
    }, [analysisTypes, dispatch, params?.batchId]);

    useEffect(() => {
        fetchBatchInfo();
    }, [fetchBatchInfo]);

    useEffect(() => {
        if (
            [
                deleteSemAnalysisFilesStatus,
                deleteResidualBatchStatus
            ].includes(AsyncStates.SUCCESS)
        ) {
            fetchBatchInfo();
            setSelectedIds([]);
            dispatch(deleteSemAnalysisFilesClear());
            dispatch(deleteResidualBatchClear());
        }
    }, [deleteResidualBatchStatus, deleteSemAnalysisFilesStatus, dispatch, fetchBatchInfo]);

    useEffect(() => {
        if (
            [
                setImageSubtractionStatus,
                residualRecalibrateScaleStatus
            ].includes(AsyncStates.SUCCESS)
        ) {
            fetchBatchInfo();
            dispatch(setImageSubtractionClear());
            dispatch(residualRecalibrateScaleClear());
        }
    }, [dispatch, fetchBatchInfo, residualRecalibrateScaleStatus, setImageSubtractionStatus]);

    useEffect(() => {
        if (residualBatchInfoStatus === AsyncStates.SUCCESS && (!residualBatchInfo || residualBatchInfo.length === 0))
            history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS}`)
    }, [history, residualBatchInfo, residualBatchInfoStatus]);

    const listenToBatchStatus = useCallback((doc_id) => {
        let unsub: Unsubscribe;
        const taskDocRef = doc(
            firestoreDb,
            `${FB_COLLECTION_SEM_ANALYSIS}/${doc_id}`
        );

        unsub = onSnapshot(taskDocRef, (doc) => {
            const taskDoc = doc.data();
            if (taskDoc) {
                const taskStatus = taskDoc["status"];
                setLoadingDataMap((prev: any) => ({
                    ...prev,
                    [doc_id]: {
                        ...taskDoc
                    },
                }));

                if (String(taskStatus).toLowerCase() === "completed") {
                    unsub && unsub();
                    fetchBatchInfo();
                }
            }
        });
    }, [fetchBatchInfo])


    useEffect(() => {
        if (
            setImageSubtraction?.firebase_doc_id &&
            !loadingDataMap?.[setImageSubtraction.firebase_doc_id]
        ) {
            setLoadingDataMap((prev: any) => ({
                ...prev,
                [setImageSubtraction.firebase_doc_id]: {}
            }));
            listenToBatchStatus(setImageSubtraction.firebase_doc_id);
        }
    }, [loadingDataMap, setImageSubtraction?.firebase_doc_id, listenToBatchStatus]);
    
    const selectedBatchInfo = useMemo(() => {
        if (
            !selectedBatch ||
            !Array.isArray(residualBatchInfo) ||
            residualBatchInfo.length === 0
        )
            return null;

        return residualBatchInfo.find((batch) => batch.batch_id === selectedBatch);
    }, [selectedBatch, residualBatchInfo]);

    const gotoBatchView = () =>
        history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS}`);

    const goToBatchDetails = (batchId: string) => {
        history.push(
            `/sem-analysis/${IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS}/batch/${batchId}`
        );
    };

    const goToFileDetails = useCallback(
        ({ fileId }: any) => {
            history.push(
                `/sem-analysis/${IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS}/batch/${selectedBatchInfo.batch_id}/files/${fileId}`
            );
        },
        [history, selectedBatchInfo?.batch_id]
    );

    const selectAll = useCallback(() => {
        if (
            view === "expanded" &&
            selectedBatchInfo?.thumbnails &&
            Array.isArray(selectedBatchInfo.thumbnails)
        ) {
            if (selectedIds.length !== selectedBatchInfo?.thumbnails.length)
                setSelectedIds([
                    ...selectedBatchInfo.thumbnails.map((t: any) => t.file_id),
                ]);
            else setSelectedIds([]);
        }

        if (view === "batch" && Array.isArray(residualBatchInfo)) {
            if (selectedIds.length !== residualBatchInfo.length)
                setSelectedIds([...residualBatchInfo.map((t: any) => t.batch_id)]);
            else setSelectedIds([]);
        }
    }, [
        residualBatchInfo,
        selectedBatchInfo?.thumbnails,
        selectedIds.length,
        view,
    ]);

    const deleteFiles = useCallback(() => {
        Modal.confirm({
            title: "Are you sure?",
            icon: <ExclamationCircleOutlined />,
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                const payload: any = {
                    batch_ids: [selectedBatch],
                    file_ids: selectedIds || [],
                    analysis_type:
                        analysisTypes[
                        IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS as keyof typeof analysisTypes
                        ],
                };
                dispatch(deleteResidualBatchRequest(payload));
            },
        });
    }, [analysisTypes, dispatch, selectedBatch, selectedIds]);

    const deleteBatches = useCallback(() => {
        Modal.confirm({
            title: "Are you sure?",
            icon: <ExclamationCircleOutlined />,
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                const payload: any = {
                    batch_ids: selectedIds || [],
                    analysis_type:
                        analysisTypes[
                        IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS as keyof typeof analysisTypes
                        ],
                };
                dispatch(deleteResidualBatchRequest(payload));
            },
        });
    }, [analysisTypes, dispatch, selectedIds]);

    const exportReport = () => {
        const payload: any = {
            batch_id: selectedBatch,
            file_ids: selectedIds || [],
            analysis_type:
                analysisTypes[
                IMAGE_ANALYSIS_TYPES.RESIDUAL_ANALYSIS as keyof typeof analysisTypes
                ],
        };

        dispatch(downloadBatchReportRequest(payload));
    };

    const handleReCalibrate = useCallback(
        (scale_length: any, unit: any) => {
            const payload = {
                scale: `${scale_length || ""} ${unit || ""}`,
                batch_id: selectedBatch,
                analysis_type: analysisTypes[
                    IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
                ]
            };
            dispatch(residualRecalibrateScaleRequest(payload));
        },
        [analysisTypes, dispatch, selectedBatch]
    );

    const PCBTypes = useMemo(() => {
        let types: any[] = [];
        if (residualBatchInfo && Array.isArray(residualBatchInfo))
            types = [...types, ...residualBatchInfo.map(batch => batch.type)]

        if (Object.values(loadingDataMap).filter((batch: any) => String(batch.status || "").toLowerCase() !== "completed").length > 0)
            types = [...types, ...Object.values(loadingDataMap).map((batch: any) => batch.type)]

        return [...new Set(types)].sort() || [];
    }, [residualBatchInfo, loadingDataMap])

    return (
        <Spin
            spinning={[
                residualBatchInfoStatus,
                deleteSemAnalysisFilesStatus,
                deleteResidualBatchStatus,
            ].includes(AsyncStates.LOADING)}
            indicator={<LoadingOutlined />}
        >
            <div className="paint-film-analysis-container">
                {Array.isArray(residualBatchInfo) && (
                    <>
                        {view === "batch" && (
                            <>
                                <SelectionsActions
                                    count={residualBatchInfo ? residualBatchInfo.length : 0}
                                    selectedIds={selectedIds}
                                    onSelectAll={selectAll}
                                    showDownloadReport={false}
                                    onDelete={deleteBatches}
                                />
                                <div>
                                    {PCBTypes.map((pcbType: string, idx) => (
                                        <Collapse defaultActiveKey={[idx]} key={idx} style={{ marginTop: "20px" }}>
                                            <Panel header={pcbType} key={idx}>
                                                <div className="batch-container">
                                                    {Object.values(loadingDataMap).filter((batch: any) => batch.type === pcbType && String(batch.status || "").toLowerCase() !== "completed").map((batch) => (
                                                        <BatchCard
                                                            batch={batch}
                                                        />
                                                    ))}
                                                    {residualBatchInfo.filter((batch: any) => batch.type === pcbType).map((batch) => (
                                                        <BatchCard
                                                            batch={batch}
                                                            onBatchClick={goToBatchDetails}
                                                            selectable={true}
                                                            selectedIds={selectedIds}
                                                            setSelectedIds={setSelectedIds}
                                                        />
                                                    ))}
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    ))}

                                    {residualBatchInfoStatus !== AsyncStates.LOADING &&
                                        (!residualBatchInfo || residualBatchInfo.length === 0) && Object.keys(loadingDataMap).length === 0 && (
                                            <Empty />
                                        )}
                                </div>
                            </>
                        )}
                        {view === "expanded" && (
                            <>
                                <div className="content-container">
                                    <div className="content-header">
                                        <div
                                            className="back"
                                            onClick={() => {
                                                gotoBatchView();
                                            }}
                                        >
                                            <ArrowLeftOutlined />
                                        </div>
                                        {selectedBatchInfo ? (
                                            <>
                                                <div className="batch-name">
                                                    {selectedBatchInfo?.batch_name}
                                                </div>
                                                {Object.keys(selectedBatchInfo?.scale || {}).length > 0 && (
                                                    <>
                                                        <div>
                                                            Scale: {selectedBatchInfo?.scale?.scale}{" "}
                                                            {selectedBatchInfo?.scale?.unit}
                                                        </div>

                                                        <div
                                                            className="link"
                                                            onClick={() => setReCalibrateModalOpen(true)}
                                                        >
                                                            Recalibrate Scale
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {residualBatchInfoStatus !== AsyncStates.LOADING && (
                                                    <Empty />
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <SelectionsActions
                                        count={
                                            selectedBatchInfo?.thumbnails &&
                                                Array.isArray(selectedBatchInfo.thumbnails)
                                                ? selectedBatchInfo.thumbnails.length
                                                : 0
                                        }
                                        selectedIds={selectedIds}
                                        onSelectAll={selectAll}
                                        onDownloadReport={exportReport}
                                        onDelete={deleteFiles}
                                        downloadReportStatus={downloadBatchReportStatus}
                                    />
                                    <div className="thumb-container">
                                        <ThumbnailList
                                            loadingDataMap={{}}
                                            handleCardClick={goToFileDetails}
                                            thumbnailList={
                                                selectedBatchInfo?.thumbnails &&
                                                    Array.isArray(selectedBatchInfo?.thumbnails)
                                                    ? selectedBatchInfo?.thumbnails
                                                    : []
                                            }
                                            loading={residualBatchInfoStatus === AsyncStates.LOADING}
                                            selectable={true}
                                            setSelectedIds={setSelectedIds}
                                            selectedIds={selectedIds}
                                        />
                                    </div>
                                </div>
                                <ReCalibrateModal
                                    reCalibrateModalOpen={reCalibrationModalOpen}
                                    setReCalibrateModalOpen={setReCalibrateModalOpen}
                                    handleReCalibrate={handleReCalibrate}
                                    selectedBatchInfo={selectedBatchInfo}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </Spin>
    );
};

export default ResidualAnalysis;
