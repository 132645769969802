import { Table, Tag, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { 
  // AvatarComponent, 
  dateSorter, listFilter, stringSorter, TEXT_PRIMARY, useStatusNames, valueFilter } from '../../Dashboard'
import { StoreState } from 'src/store/configureStore'
import { useSelector } from 'react-redux';
import { AsyncStates } from 'src/constants'
import dayjs from 'dayjs'
import useTranslate from 'src/utils/useTranslate'
import { Link } from 'react-router-dom';
// import { useMemberName } from 'src/utils/useMemberName'

const ProjectsTable = ({selectedProjectId}:any) => {
  const [t] = useTranslate()

  // const { getName } = useMemberName()

  const { projectStatusNames } = useStatusNames()

  const { projectList, projectListStatus } = useSelector(
    (state: StoreState) => state.projects
  )

  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id,
  )

  const tagFilterOptions = useMemo(() => {
    return (
      [...new Set(projectList?.flatMap((project: any) => project.tags))]
        ?.sort((a: any, b: any) => 1)
        .filter((res) => res) ?? []
    )?.map((tag, idx) => ({
      value: tag,
      text: tag,
      key: `${tag}_${idx}`
    }))
  }, [projectList])

  const categoriesFilterOptions = useMemo(
    () =>
      [...new Set(projectList.map((project: any) => project.category))]
        .sort((a: any, b: any) => 1)
        .map((res: any) => ({
          value: res,
          text: res
        })),
    [projectList]
  )

  // const projectOnwersFilterOptions = useMemo(
  //   () =>
  //     [...new Set(projectList.map((project: any) => project.created_by))].map(
  //       (res: any) => ({ text: getName(res), value: res })
  //     ),
  //   [projectList, getName]
  // )

  const projectStatusFilterOptions = useMemo(
    () =>
      [...new Set(projectList.map((project: any) => project.status))].map(
        (res: any) => ({ text: projectStatusNames[res], value: res })
      ),
    [projectList, projectStatusNames]
  )

  const getProjectStatus = useCallback(
    (status: string) => {

      const styles : React.CSSProperties = {
        margin: 0,
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        border: 'none'
      }

      switch (status) {
        case 'in_progress':
          return <Tag color="orange" style={styles}> {t('projects.ongoing')} </Tag>
        case 'completed':
          return <Tag color="green" style={styles}> {t('projects.completed')} </Tag>
        case 'archived':
          return <Tag color="blue" style={styles}> {t('projects.archived')} </Tag>
      }
    },
    [t]
  )

  const tableColumns = useMemo(() => {
    return [
      {
        title: t('common.Name'),
        key: 'name',
        dataIndex: 'name',
        width: 200,
        sorter: (a: any, b: any, c: any) => {
          return stringSorter(a.name, b.name)
        },
        render: (value: string, record:any) => (
          <Link to={`/projects/details/${record.project_id}`} >
            <Typography.Text
            style={{
              margin: 0,
              width: 200
            }}
            ellipsis={{ tooltip: value }}
            strong
            className='dashboard_underline_text'
          >
            {value}
          </Typography.Text>
          </Link>
        )
      },
      {
        dataIndex: 'tags',
        title: t('project.tags'),
        key: 'tags',
        width: 100,
        filters: tagFilterOptions,
        onFilter: (value: any, record: any) => {
          return listFilter(value, record.tags)
        },
        filterSearch: true,
        render: (tags: string[] | undefined) => {
          return !!tags?.length ? (
            <Typography.Text
              style={{ width: 200 }}
              ellipsis={{ tooltip: tags.join(', ') }}
            >
              {tags?.map((tag: string, idx: number) => (
                <Tag color="geekblue" key={idx}>
                  {tag}
                </Tag>
              ))}
            </Typography.Text>
          ) : null
        }
      },
      {
        dataIndex: 'category',
        title: t('common.category'),
        key: 'category',
        filters: categoriesFilterOptions,
        width: 150,
        onFilter: (value: any, record: any) =>{
          return valueFilter(value, record.category)
        },
        filterSearch: true,
        sorter: (a: any, b: any, c: any) => {
          return stringSorter(a.category, b.category)
        },
        render: (text: string) => (
          <Typography.Text
            style={{
              margin: 0,
              width: 100
            }}
            ellipsis={{ tooltip: text }}
            strong
          >
            {text}
          </Typography.Text>
        )
      },
      // {
      //   dataIndex: 'created_by',
      //   title: t('common.createdBy'),
      //   key: 'created_by',
      //   align: 'center' as 'center',
      //   filters: projectOnwersFilterOptions,
      //   onFilter: (value: any, record: any) =>{
      //     return valueFilter(value, record.created_by)
      //   },
      //   filterSearch: true,
      //   width: 150,
      //   render: (member: any) => (
      //     <AvatarComponent id={member}/>
      //   ),
      //   sorter: (a: any, b: any, c: any) => {
      //     return stringSorter(getName(a.created_by), getName(b.created_by))
      //   }
      // },
      {
        title: t('common.createdOn'),
        dataIndex: 'created',
        key: 'created',
        width: 150,
        render: (date: any) => {
          const dateFormatted = dayjs(date).format('DD MMM YYYY HH:mm')
          return (
            <Typography.Text
              style={{
                margin: 0,
                width: 150
              }}
              ellipsis={{ tooltip: dateFormatted }}
              strong
            >
              {date ? dateFormatted : 'N/A'}
            </Typography.Text>
          )
        },
        sorter: (a: any, b: any, c: any) => {
          return dateSorter(a.created, b.created)
        }
      },
      {
        dataIndex: 'status',
        title: t('common.status'),
        key: 'status',
        width: 100,
        align: 'center' as 'center',
        sorter: (a: any, b: any, c: any) => {
          return stringSorter(a.status, b.status)
        },
        filters:projectStatusFilterOptions,
        onFilter: (value: any, record: any) =>{
          return listFilter(value, record.status)
        },
        filterSearch: true,
        render: (text: string) => {
          return getProjectStatus(text)
        }
      }
    ]
  }, [t, tagFilterOptions, categoriesFilterOptions, projectStatusFilterOptions, getProjectStatus])

  return (
    <Table
      loading={projectListStatus === AsyncStates.LOADING}
      columns={tableColumns}
      dataSource={projectList.filter((data: any) => data.created_by === userId)}
      className="dashboard-activity-projects-drawer-table"
      pagination={{
        style: { color: TEXT_PRIMARY, gap: 8 },
        size: 'small'
      }}
      scroll={{ y: 800 }}
      style={{
        width: '100%',
        scrollbarWidth: 'none'
      }}
    />
  )
}

export default ProjectsTable
