import { LoadingOutlined } from '@ant-design/icons'
import { Col, Form, message, Row, Segmented, Select, Space, Spin, Table, Tooltip, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import Plot from 'react-plotly.js'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates, defaultHeaders } from 'src/constants'
import { clearSuggestedExpInsights, insightsSocketConnect } from 'src/store/actions/insights'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import SuggestExpPlots from '../SuggestExpPlots'
import { GetExperimentDataApi } from "src/services/suggestedExp/suggestedExp"
import { plotFont } from './SuggestedExp'
import { newColumnData, removeNulls, transposeData } from 'src/utils/decorator'
import jwtManager from 'src/utils/jwtManager'
import { LinkedTrialsAiEngine } from '../../common/LinkedTrialsAiEngine'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts"
import { StyledCard } from 'src/styled_components/StyledCard'
import { useValue } from "src/utils/useValue"
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'

const { Text } = Typography
const { Option } = Select

type Props = {
  setClickedExperiments: Dispatch<SetStateAction<any[]>>
  clickedExperiments: any[]
  filtersVersion: any
  generateWO: any
  clearExperiments: any
  checkedSelectedExp: any
  checkChange: any
  selectAll: any
  zeonCurrentPageInfo: any
  experimentsCurrent: any
  suggestedExpFilters: any,
  selectedObjective: any,
  zeonCategoryList: any
}

export const SuggestedExpVisualization = memo(({ setClickedExperiments, clickedExperiments, filtersVersion, generateWO, clearExperiments, checkedSelectedExp, checkChange, selectAll, zeonCurrentPageInfo, experimentsCurrent, suggestedExpFilters, selectedObjective, zeonCategoryList }: Props) => {
  const { suggestedExpInsights: plot, suggestedExpInsightsStatus, } = useSelector((state: StoreState) => state.insights)
  const displayNames = useSelector((state: StoreState) => state.displayNames.data)
  const { expIdStatus, data: { experiments } } = useSelector((state: StoreState) => state.suggestedExp)
  // const { user_id: key } = useSelector((state: StoreState) => state.login.loginResponse)
  const linkedFormulationDetailsData = useSelector((state: StoreState) => state.compareFormulations.linkedFormulationDetailsData)
  const configs = useSelector((state: StoreState) => state.configs.features)

  const [plotsType, setPlotsType] = useState("predicted_properties_vs_ingredients")
  const [selectedOptions, setSelectedOptions] = useState<any>({
    x_property: "",
    y_property: "",
    z_property: "",
  })
  const [options, setOptions] = useState<any[]>(Object.keys(experiments[0]?.predicted_properties || {}))
  const [hover, setHover] = useState<any>("")
  const [loadingData, setLoadingData] = useState<any>(false)

  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()

  const { getValue, convertValue } = useValue()

  const [suggestExpVizForm] = useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    setPlotsType("predicted_properties_vs_ingredients")
  }, [experiments])

  useEffect(() => {
    if (expIdStatus === AsyncStates.SUCCESS) {
      setOptions(
        Object.keys(experiments[0]?.predicted_properties || {}).map(
          (res: any) => displayNames?.properties?.data?.[res] || res
        ).filter((res: any) => !Object.values(selectedOptions || {}).includes(res))
      )
      setClickedExperiments([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiments, expIdStatus, displayNames, selectedOptions])

  const plotGraph = (values: any) => {
    const {
      x_property,
      y_property,
      z_property,
      property,
      ingredients,
      processing,
      x_clustering_pareto,
      y_clustering_pareto
    } = values;
    if (plotsType === "visualize_suggested_experiments") {
      dispatch(
        insightsSocketConnect({
          event: "custom_insights",
          action: "visualize_suggested_experiments",
          data: [
            {
              x_property,
              y_property,
              z_property,
              prediction_id: experiments[0].prediction_id,
              version: experiments[0].version,
            },
          ],
        }),
      );
    } else if (plotsType === "predicted_properties_vs_processing") {
      dispatch(
        insightsSocketConnect({
          event: "custom_insights",
          action: "predicted_properties_vs_processing",
          data: {
            property: property,
            prediction_id: experiments[0].prediction_id,
            inputs: processing,
            version: experiments[0].version,
          },
        }),
      );
    } else if (
      plotsType === "clustering"
    ) {
      dispatch(
        insightsSocketConnect({
          event: "custom_insights",
          action: "clustering",
          data: {
            y: y_clustering_pareto,
            prediction_id: experiments[0].prediction_id,
            x: x_clustering_pareto,
            version: experiments[0].version,
          },
        }),
      );
    } else if (
      plotsType === "pareto"
    ) {
      dispatch(
        insightsSocketConnect({
          event: "custom_insights",
          action: "pareto",
          data: {
            y: y_clustering_pareto,
            prediction_id: experiments[0].prediction_id,
            x: x_clustering_pareto,
            version: experiments[0].version,
            // sense: { y: ["max"], x: ["min", "max"] }
          },
        }),
      );
    } else {
      dispatch(
        insightsSocketConnect({
          event: "custom_insights",
          action: "predicted_properties_vs_ingredients",
          data: {
            property: property,
            prediction_id: experiments[0].prediction_id,
            inputs: ingredients,
            version: experiments[0].version,
          },
        }),
      );
    }
  };

  useEffect(() => {
    suggestExpVizForm.resetFields();
  }, [suggestExpVizForm, suggestedExpFilters, selectedObjective]);

  const allIngredients = useMemo(() => Object.entries(experiments.map((x: any) => x.ingredients).filter((x: any) => x !== null && x !== undefined).reduce((x: any, y: any) => ({ ...y, ...x }), {})).reverse(), [experiments])

  const showForm = useMemo(() => {
    if (plotsType === "predicted_properties_vs_processing") {
      return !!Object.entries(experiments?.[0]?.processing || {}).filter(
        ([key, value]: any) => !isNaN(value?.value),
      ).length;
    } else if (plotsType === "predicted_properties_vs_ingredients") {
      return !!allIngredients.filter(([key, value]: any) => !isNaN(value?.value)).length
    } else if (plotsType === "visualize_suggested_experiments") {
      return !!Object.entries(
        experiments?.[0]?.predicted_properties || {},
      ).filter(([key, value]: any) => !isNaN(value?.value)).length;
    } else if (
      plotsType === "pareto" ||
      plotsType === "clustering"
    ) {
      return !![
        ...allIngredients,
        ...Object.entries(experiments?.[0]?.processing || {}),
      ].filter(([key, value]: any) => !isNaN(value?.value)).length;
    }
    return false;
  }, [experiments, plotsType, allIngredients]);

  const multipleStringLines = (title: any) => {
    if (title.length > 20) {
      let axis = title.split(" ");
      let interval = title.split(" ").length / 2;
      return (
        axis.slice(0, interval).join(" ") +
        "<br>" +
        axis.slice(interval, axis.length).join(" ")
      );
    }
    return title;
  };

  const selectChange = (e: any, type: any) => {
    const obj = { ...selectedOptions };
    obj[type] = e;
    setSelectedOptions(obj);
    setOptions(
      Object.keys(experiments[0]?.predicted_properties || {})
        .map((res) => displayNames?.properties?.data?.[res] || res)
        .filter((res: any) => !Object.values(obj || {}).includes(res)),
    );
  };

  const plotHover = (data: any, type: string) => {
    let x: any, y: any;
    if (type === "predicted_properties_vs_ingredients") {
      x = plot.find(
        (res: any, index: number) =>
          index === Number(data?.points?.[0]?.data?.name),
      )?.x;
      y = plot.find(
        (res: any, index: number) =>
          index === Number(data?.points?.[0]?.data?.name),
      )?.y;
    } else if (type === "predicted_properties_vs_processing") {
      x = plot.find(
        (res: any, index: number) =>
          index === Number(data?.points?.[0]?.data?.name),
      )?.x;
      y = plot.find(
        (res: any, index: number) =>
          index === Number(data?.points?.[0]?.data?.name),
      )?.y;
    } else {
      x = plot.data.x;
      y = plot.data.y;
    }
    const x_labels: string[] = Object.keys(x).filter(
      (key: string) => x[key] === data.points[0].x,
    );
    const y_labels: string[] = Object.keys(y).filter(
      (key: string) => y[key] === data.points[0].y,
    );
    const intersection: any = x_labels.find((x: string) =>
      y_labels.includes(x),
    );
    console.log(intersection)
    setHover(intersection);
  };

  const plotClick = (data: any, plotType: boolean, type: string) => {
    setTimeout(
      () => {
        setLoadingData(true);
        let x: any, y: any;
        if (type === "predicted_properties_vs_ingredients") {
          x = plot.find(
            (res: any, index: number) =>
              index === Number(data?.points?.[0]?.data?.name),
          )?.x;
          y = plot.find(
            (res: any, index: number) =>
              index === Number(data?.points?.[0]?.data?.name),
          )?.y;
        } else if (type === "predicted_properties_vs_processing") {
          x = plot.find(
            (res: any, index: number) =>
              index === Number(data?.points?.[0]?.data?.name),
          )?.x;
          y = plot.find(
            (res: any, index: number) =>
              index === Number(data?.points?.[0]?.data?.name),
          )?.y;
        } else {
          x = plot.data.x;
          y = plot.data.y;
        }
        const x_labels: string[] = Object.keys(x).filter(
          (key: string) => x[key] === data.points[0].x,
        );
        const y_labels: string[] = Object.keys(y).filter(
          (key: string) => y[key] === data.points[0].y,
        );
        const intersection: any = x_labels.find((x: string) =>
          y_labels.includes(x),
        );
        const headers: any = {
          ...defaultHeaders,
          token: jwtManager.getToken(),
        };
        GetExperimentDataApi({ experiment_id: [intersection] }, headers)
          .then((res: any) => {
            setLoadingData(false);
            if (
              !clickedExperiments.find(
                (key: any) =>
                  res.data.result.data.suggested_expts[0].experiment_id ===
                  key.experiment_id,
              )
            ) {
              setClickedExperiments((prevState: any) => [
                ...prevState,
                res.data.result.data.suggested_expts[0],
              ]);
              document
                .getElementById("plots-results-table")
                ?.scrollIntoView();
            } else message.warning(t("aiEngine.graphClick.warning"));
          })
          .catch((err: any) => setLoadingData(false));
      },
      plotType ? 500 : 0,
    );
  };

  const plotClickNew = useCallback(async (experiment_id: any) => {
    setLoadingData(true);

    const headers: any = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    };
    GetExperimentDataApi({ experiment_id }, headers)
      .then((res: any) => {
        setLoadingData(false);
        if (
          !clickedExperiments.find(
            (key: any) =>
              res.data.result.data.suggested_expts[0].experiment_id ===
              key.experiment_id,
          )
        ) {
          setClickedExperiments((prevState: any) => [
            ...prevState,
            res.data.result.data.suggested_expts[0],
          ]);
          document
            .getElementById("plots-results-table")
            ?.scrollIntoView();
        } else message.warning(t("aiEngine.graphClick.warning"));
      })
      .catch((err: any) => setLoadingData(false));
  }, [clickedExperiments, setClickedExperiments, t]);

  const clickedFilteredData = useMemo(() => {
    const clickedNewData = transposeData(
      clickedExperiments,
      t,
      getValue,
      zeonCategoryList,
      Boolean(configs?.ai_engine_with_methods),
    );
    const removedNulls = removeNulls(clickedNewData, "suggested_exp", convertValue);
    return removedNulls;
  }, [clickedExperiments, configs, zeonCategoryList, t, getValue, convertValue]);

  const clickedTableColumns = useMemo(() => {
    return newColumnData(
      clickedExperiments,
      checkChange,
      displayNames,
      checkedSelectedExp,
      "suggested_clicked",
      selectAll,
      t,
      Boolean(configs?.ai_engine_with_methods)
        ? zeonCurrentPageInfo?.currentPage
        : experimentsCurrent,
      Boolean(configs?.ai_engine_with_methods),
    );
  }, [
    checkChange,
    checkedSelectedExp,
    clickedExperiments,
    configs,
    displayNames,
    experimentsCurrent,
    selectAll,
    t,
    zeonCurrentPageInfo?.currentPage,
  ]);

  const paretoPlotsData = useCallback((subplot) => {
    const exp = Object.keys(subplot?.x || {});
    const x = Object.values(subplot?.x || {});
    const y = Object.values(subplot?.y || {});
    const xp = Object.values(subplot?.pareto_x || {});
    const yp = Object.values(subplot?.pareto_y || {});
    const data = x.map((x: any, i: number) => ({ x, y: y[i], exp: exp[i] }))
    const datap = xp.map((x: any, i: number) => {
      const o = { x, y: yp[i] }
      return o
    })
      .sort((p, n) => p.x - n.x)
    const labels = { ...displayNames?.ingredients, ...displayNames?.processing, ...displayNames?.properties }
    const textX = labels?.[subplot?.x_label]?.name || subplot?.x_label
    const textY = labels?.[subplot?.y_label]?.name || subplot?.y_label
    return ({
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: undefined
      },
      yAxis: {
        title: {
          text: textY
        }
      },
      xAxis: {
        title: {
          text: textX
        },
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                plotClickNew([(this as any).exp])
              }
            }
          }
        }
      },
      tooltip: {
        formatter: function (): any {
          const x = getValue((this as any).x);
          const y = getValue((this as any).y);
          const exp = (this as any).point.exp;
          return `<b>${exp}:</b><br/>x: ${x}<br/>y: ${y}`;
        }
      },
      series: [
        {
          type: 'line',
          name: 'pareto',
          data: datap,
          marker: {
            enabled: true,
            symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)',
            width: 32,
            height: 32
          }
        },
        {
          type: 'scatter',
          name: 'data',
          data,
          marker: {
            symbol: 'circle'
          }
        },
      ]
    })
  }, [displayNames?.ingredients, displayNames?.processing, displayNames?.properties, plotClickNew, getValue])

  const clusterPlotsData = useCallback((subplot) => {
    const exp = Object.keys(subplot?.x || {});
    const x = Object.values(subplot?.x || {});
    const y = Object.values(subplot?.y || {});
    const color = Object.values(subplot?.clusters || {});
    const data = x.map((x: any, i: number) => ({ x, y: y[i], color: (Highcharts as any).getOptions().colors[color[i] as any], exp: exp[i] }))
    const labels = { ...displayNames?.ingredients, ...displayNames?.processing, ...displayNames?.properties }
    const textX = labels?.[subplot?.x_label]?.name || subplot?.x_label
    const textY = labels?.[subplot?.y_label]?.name || subplot?.y_label
    return ({
      credits: { enabled: false },
      legend: {
        enabled: false
      },
      title: {
        text: undefined
      },
      yAxis: {
        title: {
          text: textY
        }
      },
      xAxis: {
        title: {
          text: textX
        },
      },
      tooltip: {
        formatter: function (): any {
          const x = getValue((this as any).x);
          const y = getValue((this as any).y);
          const exp = (this as any).point.exp;
          return `<b>${exp}:</b><br/>x: ${x}<br/>y: ${y}`;
        }
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                plotClickNew([(this as any).exp])
              }
            }
          }
        }
      },
      series: [
        {
          type: 'scatter',
          name: 'data',
          data
        },
      ]
    })
  }, [displayNames?.ingredients, displayNames?.processing, displayNames?.properties, plotClickNew, getValue])

  const changePlotType = (plotType: string) => {
    dispatch(clearSuggestedExpInsights())
    suggestExpVizForm.resetFields();
    suggestExpVizForm.setFieldValue("type", plotType);
    setPlotsType(plotType);
  }

  const changeSense = (value: string | number, index: number, axis: string) => {

    const newSense = plot.reduce((acc: any, cur: any) => ({ x: [...acc.x, cur.x_sense], y: [...acc.y, cur.y_sense] }), { x: [], y: [] })
    newSense[axis][index] = value

    const data = {
      y: suggestExpVizForm.getFieldValue("y_clustering_pareto"),
      prediction_id: experiments[0].prediction_id,
      x: suggestExpVizForm.getFieldValue("x_clustering_pareto"),
      version: experiments[0].version,
      sense: newSense
    }

    dispatch(
      insightsSocketConnect({
        event: "custom_insights",
        action: "pareto",
        data
      }),
    );

  }

  return (
    <>
      <StyledCard
        title={t("aiEngine.graphs.card.title")}
        extra={<Text type="secondary">{t("aiEngine.graphs.note")}</Text>}
        bodyStyle={{ padding: "16px" }}
        headStyle={{ padding: "0px 16px" }}
      >
        <Spin
          indicator={<LoadingOutlined />}
          spinning={suggestedExpInsightsStatus === AsyncStates.LOADING}
        >
          <Space
            style={{ width: "100%", overflowX: "auto" }}
            direction="vertical"
            size="large"
          >
            <Form
              onFinish={plotGraph}
              layout="vertical"
              initialValues={{
                type: "predicted_properties_vs_ingredients",
                x_property: selectedOptions.x_property,
                y_property: selectedOptions.y_property,
              }}
              form={suggestExpVizForm}
              requiredMark={false}
            >
              <Row>
                <Col
                  span={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Form.Item
                    name="type"
                    label={t("common.type")}
                    rules={[{ required: true }]}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <Select onChange={changePlotType}>
                      <Option value={"predicted_properties_vs_ingredients"}>
                        {t("common.predictedPropertiesVsIngredients")}
                      </Option>
                      <Option value={"predicted_properties_vs_processing"}>
                        {t("common.predictedPropertiesVsProcessing")}
                      </Option>
                      <Option value={"visualize_suggested_experiments"}>
                        {t(
                          "aiEngine.suggestedExperiments.predictedProperties",
                        )}
                      </Option>
                      <Option value={"pareto"}>
                        {t("aiEngine.suggestedExperiments.paretoFront")}
                      </Option>
                      <Option value={"clustering"}>
                        {t("aiEngine.suggestedExperiments.clustering")}
                      </Option>
                    </Select>
                  </Form.Item>

                  {plotsType === "predicted_properties_vs_ingredients" ? (
                    showForm ? (
                      <>
                        <Form.Item
                          name="property"
                          label={t("suggestedExp.predictedProperty")}
                          rules={[{ required: true }]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select allowClear>
                            {Object.keys(
                              experiments?.[0]?.["predicted_properties"] ||
                              {},
                            ).map((res: any) => (
                              <Option value={res}>
                                {displayNames?.properties?.[res]?.name || res}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="ingredients"
                          label={t("common.ingredients")}
                          rules={[{ required: true }]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select mode="multiple">
                            {allIngredients.map(([res, value]: any) => (
                              <Option value={res}>
                                <Tooltip
                                  title={
                                    !!displayNames?.ingredients?.[
                                      res
                                    ]?.lot_no?.trim() ||
                                      !!displayNames?.ingredients?.[
                                        res
                                      ]?.supplier?.trim() ||
                                      !!displayNames?.ingredients?.[
                                        res
                                      ]?.sub_category?.trim() ? (
                                      <>
                                        {!!displayNames?.ingredients?.[
                                          res
                                        ]?.sub_category?.trim() && (
                                            <div>
                                              {"Category/Sub Category :"}{" "}
                                              {
                                                displayNames?.ingredients?.[res]
                                                  ?.sub_category
                                              }
                                            </div>
                                          )}
                                        {!!displayNames?.ingredients?.[
                                          res
                                        ]?.lot_no?.trim() && (
                                            <div>
                                              {`${t("common.lotNoOrSAPNo")}: `}
                                              {
                                                displayNames?.ingredients?.[res]
                                                  ?.lot_no
                                              }
                                            </div>
                                          )}
                                        {!!displayNames?.ingredients?.[
                                          res
                                        ]?.supplier?.trim() && (
                                            <div>
                                              {"Supplier :"}{" "}
                                              {
                                                displayNames?.ingredients?.[res]
                                                  ?.supplier
                                              }
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      value?.name ??
                                      displayNames?.ingredients?.[res]
                                        ?.name ??
                                      res
                                    )
                                  }
                                >
                                  {value?.name ??
                                    displayNames?.ingredients?.[res]?.name ??
                                    res}
                                </Tooltip>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                    ) : (
                      <Space
                        direction="vertical"
                        style={{ marginBottom: 12 }}
                      >
                        <Text type="danger">
                          {t("common.noIngredientsExistsPleaseChangeType")}
                        </Text>
                      </Space>
                    )
                  ) : plotsType === "predicted_properties_vs_processing" ? (
                    showForm ? (
                      <Space direction="vertical">
                        <Form.Item
                          name="property"
                          label={t("suggestedExp.predictedProperty")}
                          rules={[{ required: true }]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select allowClear>
                            {Object.keys(
                              experiments?.[0]?.["predicted_properties"] ||
                              {},
                            ).map((res: any) => (
                              <Option value={res}>
                                {displayNames?.properties?.[res]?.name || res}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="processing"
                          label={t("common.processing")}
                          rules={[{ required: true }]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select mode="multiple">
                            {Object.entries(
                              experiments?.[0]?.processing || {},
                            ).map(([res, value]: any) => (
                              <Option value={res}>
                                {displayNames?.["processing"]?.[res]?.name ||
                                  res}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Space>
                    ) : (
                      <Space direction="vertical">
                        <Text type="danger">
                          {t("common.noProcessingExistsPleaseChangeType")}
                        </Text>
                      </Space>
                    )
                  ) :
                    plotsType === "clustering" ? (
                      showForm ? (
                        <>
                          <Form.Item
                            name="y_clustering_pareto"
                            label={t("common.selectYAxis")}
                            rules={[{ required: true }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <Select
                              onSelect={(e: any) =>
                                selectChange(e, "y_clustering_pareto")
                              }
                            >
                              {allIngredients.length && (
                                <Select.OptGroup
                                  label={t("common.ingredients")}
                                >
                                  {allIngredients.map(([res, value]: any) => (
                                    <Option value={res} key={res}>
                                      {displayNames?.["ingredients"]?.[res]
                                        ?.name || res}
                                    </Option>
                                  ))}
                                </Select.OptGroup>
                              )}

                              {Object.entries(
                                experiments?.[0]?.processing || {},
                              ).length && (
                                  <Select.OptGroup label={t("common.processing")}>
                                    {Object.entries(
                                      experiments?.[0]?.processing || {},
                                    ).map(([res, value]: any) => (
                                      <Option value={res} key={res} >
                                        {displayNames?.["processing"]?.[res]
                                          ?.name || res}
                                      </Option>
                                    ))}
                                  </Select.OptGroup>
                                )}

                              {Object.entries(
                                experiments?.[0]?.['predicted_properties'] || {},
                              ).length && (
                                  <Select.OptGroup
                                    label={t("suggestedExp.predictedProperty")}
                                  >
                                    {Object.entries(
                                      experiments?.[0]?.['predicted_properties'] || {},
                                    ).map(([res, value]: any) => (
                                      <Option value={res} key={res}>
                                        {displayNames?.properties?.[res]
                                          ?.name || res}
                                      </Option>
                                    ))}
                                  </Select.OptGroup>
                                )}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="x_clustering_pareto"
                            label={t("common.selectXAxis")}
                            rules={[{ required: true }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <Select
                              onSelect={(e: any) =>
                                selectChange(e, "x_clustering_pareto")
                              }
                              mode='multiple'
                            >
                              {allIngredients.length && (
                                <Select.OptGroup
                                  label={t("common.ingredients")}
                                >
                                  {allIngredients.map(([res, value]: any) => (
                                    <Option value={res} key={res}>
                                      {displayNames?.["ingredients"]?.[res]
                                        ?.name || res}
                                    </Option>
                                  ))}
                                </Select.OptGroup>
                              )}

                              {Object.entries(
                                experiments?.[0]?.processing || {},
                              ).length && (
                                  <Select.OptGroup label={t("common.processing")}>
                                    {Object.entries(
                                      experiments?.[0]?.processing || {},
                                    ).map(([res, value]: any) => (
                                      <Option value={res} key={res}>
                                        {displayNames?.["processing"]?.[res]
                                          ?.name || res}
                                      </Option>
                                    ))}
                                  </Select.OptGroup>
                                )}

                              {Object.entries(
                                experiments?.[0]?.['predicted_properties'] || {},
                              ).length && (
                                  <Select.OptGroup
                                    label={t("suggestedExp.predictedProperty")}
                                  >
                                    {Object.entries(
                                      experiments?.[0]?.['predicted_properties'] || {},
                                    ).map(([res, value]: any) => (
                                      <Option value={res} key={res}>
                                        {displayNames?.properties?.[res]
                                          ?.name || res}
                                      </Option>
                                    ))}
                                  </Select.OptGroup>
                                )}
                            </Select>
                          </Form.Item>
                        </>
                      ) : (
                        <Space direction="vertical">
                          <Text type="danger">
                            {t("common.noProcessingExistsPleaseChangeType")}
                          </Text>
                        </Space>
                      )
                    ) : plotsType === "pareto" ? (
                      showForm ? (
                        <>
                          <Form.Item
                            name="y_clustering_pareto"
                            label={t("common.selectYAxis")}
                            rules={[{ required: true }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <Select
                              onSelect={(e: any) =>
                                selectChange(e, "y_clustering_pareto")
                              }
                            >
                              {/* {plotsType === "clustering" && allIngredients.length && (
                                <Select.OptGroup
                                  label={t("common.ingredients")}
                                >
                                  {allIngredients.map(([res, value]: any) => (
                                    <Option value={res} key={res}>
                                      {displayNames?.["ingredients"]?.[res]
                                        ?.name || res}
                                    </Option>
                                  ))}
                                </Select.OptGroup>
                              )} */}

                              {/* {plotsType === "clustering" && Object.entries(
                                experiments?.[0]?.processing || {},
                              ).length && (
                                  <Select.OptGroup label={t("common.processing")}>
                                    {Object.entries(
                                      experiments?.[0]?.processing || {},
                                    ).map(([res, value]: any) => (
                                      <Option value={res} key={res} >
                                        {displayNames?.["processing"]?.[res]
                                          ?.name || res}
                                      </Option>
                                    ))}
                                  </Select.OptGroup>
                                )} */}

                              {Object.entries(
                                experiments?.[0]?.['predicted_properties'] || {},
                              ).length && (
                                  <Select.OptGroup
                                    label={t("suggestedExp.predictedProperty")}
                                  >
                                    {Object.entries(
                                      experiments?.[0]?.['predicted_properties'] || {},
                                    ).map(([res, value]: any) => (
                                      <Option value={res} key={res}>
                                        {displayNames?.properties?.[res]
                                          ?.name || res}
                                      </Option>
                                    ))}
                                  </Select.OptGroup>
                                )}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            name="x_clustering_pareto"
                            label={t("common.selectXAxis")}
                            rules={[{ required: true }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <Select
                              onSelect={(e: any) =>
                                selectChange(e, "x_clustering_pareto")
                              }
                              mode='multiple'
                            >
                              {allIngredients.length && (
                                <Select.OptGroup
                                  label={t("common.ingredients")}
                                >
                                  {allIngredients.map(([res, value]: any) => (
                                    <Option value={res} key={res}>
                                      {displayNames?.["ingredients"]?.[res]
                                        ?.name || res}
                                    </Option>
                                  ))}
                                </Select.OptGroup>
                              )}

                              {Object.entries(
                                experiments?.[0]?.processing || {},
                              ).length && (
                                  <Select.OptGroup label={t("common.processing")}>
                                    {Object.entries(
                                      experiments?.[0]?.processing || {},
                                    ).map(([res, value]: any) => (
                                      <Option value={res} key={res}>
                                        {displayNames?.["processing"]?.[res]
                                          ?.name || res}
                                      </Option>
                                    ))}
                                  </Select.OptGroup>
                                )}

                              {Object.entries(
                                experiments?.[0]?.['predicted_properties'] || {},
                              ).length && (
                                  <Select.OptGroup
                                    label={t("suggestedExp.predictedProperty")}
                                  >
                                    {Object.entries(
                                      experiments?.[0]?.['predicted_properties'] || {},
                                    ).map(([res, value]: any) => (
                                      <Option value={res} key={res}>
                                        {displayNames?.properties?.[res]
                                          ?.name || res}
                                      </Option>
                                    ))}
                                  </Select.OptGroup>
                                )}
                            </Select>
                          </Form.Item>
                        </>
                      ) : (
                        <Space direction="vertical">
                          <Text type="danger">
                            {t("common.noProcessingExistsPleaseChangeType")}
                          </Text>
                        </Space>
                      )
                    ) : (
                      <>
                        <Form.Item
                          name="x_property"
                          label={t("aiEngine.graphs.xLabel")}
                          rules={[{ required: true }]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select
                            onSelect={(e: any) => selectChange(e, "x_property")}
                          >
                            {options.map((res: any) => (
                              <Option
                                value={
                                  displayNames?.properties?.[res]?.name || res
                                }
                              >
                                {displayNames?.properties?.[res]?.name || res}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="y_property"
                          label={t("aiEngine.graphs.yLabel")}
                          rules={[{ required: true }]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select
                            onSelect={(e: any) => selectChange(e, "y_property")}
                          >
                            {options.map((res: any) => (
                              <Option
                                value={
                                  displayNames?.properties?.[res]?.name || res
                                }
                              >
                                {displayNames?.properties?.[res]?.name || res}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="z_property"
                          label={t("aiEngine.graphs.zLabel")}
                        >
                          <Select
                            allowClear
                            onSelect={(e: any) => selectChange(e, "z_property")}
                            onClear={() => selectChange("", "z_property")}
                          >
                            {options.map((res: any) => (
                              <Option
                                value={
                                  displayNames?.properties?.[res]?.name || res
                                }
                              >
                                {displayNames?.properties?.[res]?.name || res}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </>
                    )}

                  <Space>
                    <StyledButton
                      type="primary"
                      htmlType="submit"
                      disabled={!showForm}
                    >
                      {" "}
                      {t("aiEngine.customInsights.plot")}
                    </StyledButton>
                  </Space>
                </Col>
              </Row>
            </Form>

            <Row justify="center">
              {Array.isArray(plot) ? (
                !!plot?.length && (
                  <>
                    {
                      plotsType === "predicted_properties_vs_ingredients" && (
                        <SuggestExpPlots
                          plotsType={plotsType}
                          plot={plot}
                          plotHover={plotHover}
                          plotClick={plotClick}
                          filtersVersion={filtersVersion}
                          hover={hover}
                          inputType={"ingredients"}
                          experiments={experiments}
                        />)
                    }
                    {
                      plotsType === "predicted_properties_vs_processing" && (

                        <SuggestExpPlots
                          plotsType={plotsType}
                          plot={plot}
                          plotHover={plotHover}
                          plotClick={plotClick}
                          filtersVersion={filtersVersion}
                          hover={hover}
                          inputType={"processing"}
                          experiments={experiments}
                        />
                      )
                    }
                    {
                      plotsType === "clustering" && (
                        <Space direction='vertical' size={'large'} style={{ width: '100%' }}>
                          {plot.map((subplot, index) => (

                            <HighchartsReact
                              key={'clutering' + index}
                              highcharts={Highcharts}
                              options={clusterPlotsData(subplot)}
                            />

                          ))}
                        </Space>
                      )
                    }
                    {
                      plotsType === "pareto" && (
                        <Space direction='vertical' size={'large'} style={{ width: '100%' }}>
                          {plot.map((subplot, index) => (
                            <div key={'pareto' + index}>
                              <Row justify={'end'}>
                                <Col>X: <Segmented defaultValue={subplot.x_sense} options={[{ value: 'min', label: t("common.min") }, { value: 'max', label: t("common.max") }]} size='small' onChange={value => changeSense(value, index, 'x')} /></Col>
                                <Col>Y: <Segmented defaultValue={subplot.y_sense} options={[{ value: 'min', label: t("common.min") }, { value: 'max', label: t("common.max") }]} size='small' onChange={value => changeSense(value, index, 'y')} /></Col>
                              </Row>
                              <Row>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={paretoPlotsData(subplot)}
                                />
                              </Row>
                            </div>
                          ))}
                        </Space>
                      )
                    }
                  </>
                )
              ) : (
                !!Object.keys(plot || {}).length &&
                plotsType === "visualize_suggested_experiments" && (
                  <Plot
                    onHover={(e) =>
                      plotHover(e, "visualize_suggested_experiments")
                    }
                    onClick={(e) =>
                      plotClick(
                        e,
                        plot?.data?.z ? true : false,
                        "visualize_suggested_experiments",
                      )
                    }
                    layout={{
                      xaxis: {
                        title: {
                          text:
                            displayNames?.properties?.[plot?.data?.x_axis]
                              ?.name || plot?.data?.x_axis,
                          font: plotFont,
                        },
                      },
                      yaxis: {
                        title: {
                          text:
                            displayNames?.properties?.[plot?.data?.y_axis]
                              ?.name || plot?.data?.y_axis,
                          font: plotFont,
                        },
                      },
                      scene: {
                        xaxis: {
                          title: {
                            text:
                              displayNames?.properties?.[plot?.data?.x_axis]
                                ?.name || plot?.data?.x_axis,
                            font: plotFont,
                          },
                        },
                        yaxis: {
                          title: {
                            text:
                              displayNames?.properties?.[plot?.data?.y_axis]
                                ?.name || plot?.data?.y_axis,
                            font: plotFont,
                          },
                        },
                        zaxis: {
                          title: {
                            text:
                              displayNames?.properties?.[plot?.data?.z_axis]
                                ?.name || plot?.data?.z_axis,
                            font: plotFont,
                          },
                        },
                      },
                      title: multipleStringLines(plot?.title),
                      ...(plot?.data?.z && { margin: { l: 0, b: 0, r: 0 } }),
                      width: 900,
                      height: 600,
                      hovermode: "closest",
                    }}
                    data={[
                      ...new Set(
                        Object.values(plot?.data?.Combination || {}),
                      ),
                    ].map((res: any) => ({
                      x: Object.values(plot?.data?.x || {}).filter(
                        (key: any, index: any) =>
                          plot?.data?.Combination[
                          Object.keys(plot?.data?.Combination || {})[index]
                          ] === res,
                      ) as any[],
                      y: Object.values(plot?.data?.y || {}).filter(
                        (key: any, index: any) =>
                          plot?.data?.Combination[
                          Object.keys(plot?.data?.Combination || {})[index]
                          ] === res,
                      ) as any[],
                      z: Object.values(plot?.data?.z || {}).filter(
                        (key: any, index: any) =>
                          plot?.data?.Combination[
                          Object.keys(plot?.data?.Combination || {})[index]
                          ] === res,
                      ) as any[],
                      type: plot?.data?.z ? "scatter3d" : "scatter",
                      mode: "markers",
                      name: res,
                      hovertext: hover,
                    }))}
                  />
                )
              )}
            </Row>
          </Space >
        </Spin >
      </StyledCard >
      {
        clickedExperiments.length || loadingData ? (
          <StyledCard
            title={t("aiEngine.graphs.selected.card.title")}
            id="plots-results-table"
            bodyStyle={{ padding: "16px" }}
            headStyle={{ padding: "0px 16px" }}
            extra={
              <Space>
                <StyledButton
                  type="primary"
                  disabled={expIdStatus === AsyncStates.LOADING}
                  onClick={() => generateWO("suggested_clicked")}
                >
                  {t("aiEngine.generateWorkOrder")}
                </StyledButton>
                <StyledButton onClick={clearExperiments}>
                  {t("compare.clear")}
                </StyledButton>
              </Space>
            }
          >
            <Table
              dataSource={clickedFilteredData}
              columns={clickedTableColumns}
              bordered
              scroll={{ x: true }}
              pagination={false}
              loading={{
                spinning: loadingData,
                indicator: <LoadingOutlined />,
              }}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <div style={{ width: "100%", marginLeft: "5rem" }}>
                      <LinkedTrialsAiEngine
                        record={record}
                        experiments={clickedExperiments}
                        linkedFormulationDetailsData={
                          linkedFormulationDetailsData
                        }
                        from={"suggestedViz"}
                        pageNumber={experimentsCurrent}
                      />
                    </div>
                  );
                },
                rowExpandable: (record) => {
                  return !!record?.linked_trial;
                },
              }}
              className={"suggested-exp-result-table"}
            />
          </StyledCard>
        ) : null
      }
    </>
  );
})
