import { useCallback, useEffect, useMemo, useState } from 'react';
import { DownOutlined, LoadingOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Form, Input, Popover, Select, Space, message, Tabs, Typography, Menu, Table, Modal, Tooltip, Radio, Tag, Popconfirm } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStates } from 'src/constants';
import { getPendingInvitationsListRequest, inviteRequest, resendEmailRequest, withdrawInvitationRequest } from 'src/store/actions/invite';
import { StoreState } from 'src/store/configureStore';
import useTranslate from 'src/utils/useTranslate';
import { motion } from 'framer-motion';
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader';
import { StyledButton } from 'src/styled_components/StyledButton';
import ProjectsCard from './ProjectsCard';
import { UserList } from './UserList';
import { StyledButtonDropdown } from 'src/styled_components/StyledButtonDropdown';
import { orange } from '@ant-design/colors';
import { CustomPrompt } from 'src/utils/CustomPrompts';
import { DeactivatedAccountsModal } from './DeactivatedAccountsModal';
import { approveInvitationRequest } from 'src/store/actions/teams';
import { trackEvent } from 'src/analytics';
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar';
import './ConnectTeams.scss'
import Highlighter from 'react-highlight-words';

const { TabPane } = Tabs
const { Text } = Typography

export const ConnectTeams = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const [form] = useForm()
  const requiredFieldStar = useRequiredFieldStar()

  const { projectList: projects, } = useSelector((state: StoreState) => state.projects)
  const { user_role: userRole, user_email } = useSelector((state: StoreState) => state.login.loginResponse)
  const { status } = useSelector((state: StoreState) => state.invite)
  const { invitationApprovalStatus } = useSelector((state: StoreState) => state.teams)
  const { resendEmailStatus } = useSelector((state: StoreState) => state.invite)

  const [tab, setTab] = useState("users")

  const isEditing = useSelector((state: StoreState) => state.isEditing)
  const [isInvitePopoverVisible, setisInvitePopoverVisible] = useState(false)
  const [pendingInvitationModalVisible, setPendingInvitationModalVisible] = useState(false)
  const [showDeactivatedAccountModalVisible, setShowDeactivatedAccountModalVisible] = useState(false)
  const [filterInvitationbyUserRole, setfilterInvitationbyUserRole] = useState("admin_invitation")
  const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)
  const [selectedEmailAction, setSelectedEmailAction] = useState<string | null>(null)

  const {
    invitationList,
    invitationListStatus,
    withdrawInvitationStatus,
  } = useSelector((state: StoreState) => state.invite)

  const [selectedRecords, setSelectedRecords] = useState<any[]>([])
  const [filterTerm, setfilterTerm] = useState("")
  const filteredInvitationList = useMemo(
    () => {
      const result = invitationList?.filter(
        (invitation) =>
          invitation?.invited_user_name
            ?.toLocaleLowerCase()
            ?.includes(filterTerm?.toLocaleLowerCase()) ||
          invitation?.invited_email?.includes(filterTerm)
      ).filter((invitation) => {
        const invitedBy = (userRole === "admin" && filterInvitationbyUserRole === "admin_invitation") ? "admin" : "member"
        return invitation.invited_by === invitedBy
      })
      return userRole === "member" ? result.filter((invitation) => invitation.status !== "Approved") : result
    }, [filterTerm, invitationList, filterInvitationbyUserRole, userRole])

  useEffect(() => {
    if (status === AsyncStates.SUCCESS) {
      setisInvitePopoverVisible(false)
      form.resetFields()
    }
  }, [status, form])

  useEffect(() => {
    if (withdrawInvitationStatus === AsyncStates.SUCCESS) {
      setSelectedRecords([])
    }
  }, [withdrawInvitationStatus])

  const onInvite = (values: { name: string, email: string, role: string, project_id?: string[] }) => {
    if (values.email.split("@")[1] === user_email.split("@")[1]) {
      trackEvent(userId, 'User tried to sent an teams invite', { values })
      dispatch(inviteRequest(values))
    } else {
      message.error(t('teams.message.cannotSendInvitation'))
    }
  }

  useEffect(() => {
    if (invitationApprovalStatus === AsyncStates.SUCCESS) {
      setPendingInvitationModalVisible(false)
    }
  }, [invitationApprovalStatus])

  useEffect(() => {
    setSelectedRecords([])
  }, [filterInvitationbyUserRole])


  const inviteForm = (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onInvite}
      // onFinishFailed={onFinishFailed}
      form={form}
      requiredMark={false}
    >
      <Form.Item
        label={t("common.name")}
        name="name"
        rules={[{ required: true, message: t("teams.form.name"), transform: (value) => value?.trim() }]}
        required 
        tooltip={requiredFieldStar}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("common.email")}
        name="email"
        required 
        tooltip={requiredFieldStar}
        rules={[{ required: true, message: t('common.invalidEmail'), pattern: new RegExp(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,}|[0-9]{1,3})(\]?)$/), transform: (value) => value?.trim() }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label={t("projects.header.title")}
        name="project_id"
      >
        <Select mode="multiple">
          {projects.map(({ project_id, name }: any) => (
            <Select.Option value={project_id} key={project_id}>
              {String(name)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {userRole === "admin" &&
        < Form.Item
          label={t("profile.role")}
          name="role"
          required 
          tooltip={requiredFieldStar}
          rules={[{ required: true, message: t("teams.form.role") }]}
        >
          <Select>
            <Select.Option value={"admin"} key={"admin"}>
              {t("common.admin")}
            </Select.Option>
            <Select.Option value={"member"} key={"member"}>
              {t("common.member")}
            </Select.Option>
          </Select>
        </Form.Item>
      }

      <Form.Item>
        <StyledButton
          type="primary"
          htmlType="submit"
          loading={status === AsyncStates.LOADING}
        >
          {t("common.send")}
        </StyledButton>
      </Form.Item>
    </Form >
  )

  const getComponentsFromTabs = () => {
    switch (tab) {
      case "projects":
        return <ProjectsCard />
      case "users":
        return <UserList />
    }
  }

  const showModal = () => {
    dispatch(getPendingInvitationsListRequest())
    setPendingInvitationModalVisible(true)
  }

  const showDeactivatedAccountModal = () => {
    setShowDeactivatedAccountModalVisible(true)
  }

  const deleteInvitation = useCallback((data: string[]) => {
    dispatch(withdrawInvitationRequest({ emails: data }))
  }, [dispatch])

  const invitationColumns = useMemo(() => {
    const columns = [
      {
        key: "invited_email",
        title: t("teams.inviteMember"),
        render: (rowValue: any, row: any, index: any): any => (
          <Space>
            <Space direction="vertical" style={{ rowGap: 0 }}>
              <Text strong>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[filterTerm]}
                autoEscape
                textToHighlight={row?.invited_user_name ? row?.invited_user_name.toString() : ''}
              />
              </Text>
              <Text type="secondary" style={{ wordBreak: "break-word" }}>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[filterTerm]}
                autoEscape
                textToHighlight={row?.invited_email ? row?.invited_email.toString() : ''}
              />
              </Text>
            </Space>
          </Space>
        ),
      },
      userRole === "admin" && {
        key: "invited_by",
        title: t("teams.invitedBy"),
        render: (rowValue: any, row: any, index: any): any => (
          <Space>
            <Space direction="vertical" style={{ rowGap: 0 }}>
              <Text strong>{row?.invited_by_user_name}</Text>
              <Text type="secondary" style={{ wordBreak: "break-word" }}>
                {row?.invited_by_user_email}
              </Text>
            </Space>
          </Space>
        ),
      },
      (userRole === "member" && {
        key: "status",
        title: t("common.status"),
        render: (rowValue: any, row: any, index: any): any => {
          return <Space>
            <Space direction="vertical" style={{ rowGap: 0 }}>
              <Tag>{row.status}</Tag>
            </Space>
          </Space>
        },
      }),
      {
        key: "actions",
        title: "",
        render: (rowValue: any, row: any, index: any): any => {
          return <Space style={{ float: "right" }}>
            {filterInvitationbyUserRole === "admin_invitation" ?
              <>
                <Tooltip title={userRole === "admin" ? t("teams.resendInvitation") : t("teams.resendAdminNote")}>
                  <StyledButton
                    loading={(selectedEmailAction === (rowValue?.invited_email + "resend")) && (resendEmailStatus === AsyncStates.LOADING)}
                    onClick={() => {
                      setSelectedEmailAction(rowValue?.invited_email + "resend")
                      dispatch(resendEmailRequest({ email: rowValue?.invited_email }))
                    }}
                  >
                    {userRole === "admin" ? t("teams.resend") : t("teams.remindAdmin")}
                  </StyledButton>
                </Tooltip>

                {userRole === "admin" &&
                  <Tooltip title={t("teams.withdrawInvitation")}>
                    <StyledButton
                      loading={(selectedEmailAction === (rowValue?.invited_email + "withdrawInvitation")) && (withdrawInvitationStatus === AsyncStates.LOADING)}
                      danger
                      style={{ wordBreak: "break-word" }}
                      onClick={() => {
                        setSelectedEmailAction(rowValue?.invited_email + "withdrawInvitation")
                        deleteInvitation([rowValue?.invited_email])
                      }}
                      disabled={selectedRecords?.length > 1}
                    >
                      {t("teams.withdraw")}
                    </StyledButton>
                  </Tooltip>
                }
              </> :
              <>
                {row.status !== "Approved" &&
                  <Popconfirm
                    placement="top"
                    title={`Approve ${row.invited_user_name}?`}
                    description={`${row.invited_user_name} ${t("teams.willAccessWorkspace")}`}
                    onConfirm={() => {
                      setSelectedEmailAction(rowValue?.invited_email + "Approve")
                      dispatch(approveInvitationRequest({
                        email: row.invited_email,
                        status: true
                      }))
                    }}
                    okText={t("form.approve")}
                    cancelText={t("common.cancel")}
                  >
                    <StyledButton loading={(selectedEmailAction === (rowValue?.invited_email + "Approve")) && (invitationApprovalStatus === AsyncStates.LOADING)}>{t("form.approve")}</StyledButton>
                  </Popconfirm>
                }
                <Popconfirm
                  placement="top"
                  title={`Reject ${row.invited_user_name}?`}
                  description={`${row.invited_user_name} ${t("teams.willNotAccessWorkspace")}`}
                  onConfirm={() => {
                    setSelectedEmailAction(rowValue?.invited_email + "Reject")
                    dispatch(approveInvitationRequest({
                      email: row.invited_email,
                      status: false
                    }
                    ))
                  }}
                  okText={t("form.reject")}
                  cancelText={t("common.cancel")}
                  okButtonProps={{ type: 'primary', danger: true }}
                >
                  <StyledButton danger type='primary' loading={(selectedEmailAction === (rowValue?.invited_email + "Reject")) && (invitationApprovalStatus === AsyncStates.LOADING)}>{t("form.reject")}</StyledButton>
                </Popconfirm>
              </>
            }
          </Space>
        },
      },
    ]
    return columns.filter((col) => col)
  }, [t, userRole, filterTerm, filterInvitationbyUserRole, selectedEmailAction, resendEmailStatus, withdrawInvitationStatus, selectedRecords?.length, invitationApprovalStatus, dispatch, deleteInvitation])

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
    >
      <CustomPrompt
        isEditing={isEditing}
        message={`${t("common.unsavedChangesLost")}!.`}
      />
      <Space direction="vertical" size="large" style={{ width: '100%', gap: 2 }}>
          <StyledPageHeader
          ghost={false}
          title={t("teams.teamManagement")}
          footer={(
            <Tabs activeKey={tab} onChange={setTab}>
              <TabPane key={"users"} tab={t("common.users")}></TabPane>
              <TabPane key={"projects"} tab={t("projects.header.title")}></TabPane>
            </Tabs>
          )}
          extra={
            <Space>
              <Popover
                content={inviteForm}
                title={
                  <>
                    {t("teams.invite")}{" "}
                    {isEditing && (
                      <Text
                        type="secondary"
                        style={{ color: orange.primary }}
                      >
                        {t("common.unsavedChanges")}
                      </Text>
                    )}
                  </>
                }
                trigger="click"
                placement="bottomRight"
                showArrow={false}
                overlayStyle={{ width: 400 }}
                open={isInvitePopoverVisible}
                onOpenChange={setisInvitePopoverVisible}
              >
                <StyledButtonDropdown
                  icon={<DownOutlined />}
                  type="primary"
                  size="large"
                  overlay={
                    <Menu>
                      <Menu.Item key="1" onClick={showModal} style={{outline:'none'}} >
                        {userRole === "admin" ? t("teams.pendingInvitations") : t("common.pendingApprovals")}
                      </Menu.Item>
                      {
                        userRole === "admin" &&
                        <Menu.Item key="2" onClick={showDeactivatedAccountModal} style={{outline:'none'}}>
                          {t("common.deactivatedAccounts")}
                        </Menu.Item>
                      }
                    </Menu>
                  }
                >
                  <UserAddOutlined /> {t("teams.invite")}
                </StyledButtonDropdown>
              </Popover>
            </Space >
          }
        />
        {getComponentsFromTabs()}
      </Space >
      <Modal
        okText={t("common.ok")}
        cancelText={t("common.cancel")}
        title={userRole === "admin" ? t("teams.pendingInvitations") : t("common.pendingApprovals")}
        open={pendingInvitationModalVisible}
        onCancel={() => {
          setSelectedRecords([])
          setfilterTerm("")
          setPendingInvitationModalVisible(false)
          setSelectedEmailAction(null)
        }}
        width={800}
        footer={null}
        styles={{
          body:{
            padding: '1rem 0rem'
          }
        }}
      >
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <Space>
            {!!selectedRecords?.length && (
              <Text strong>
                 {`${t("formulations.selected")} (${selectedRecords?.length})`}
              </Text>
            )}
          </Space>
          <Space>
            {(selectedRecords?.length > 1 && filterInvitationbyUserRole === "admin_invitation") && (
              <StyledButton
                danger
                onClick={() => deleteInvitation(selectedRecords)}
              >
                {t("teams.withdraw")}
              </StyledButton>
            )}
            <Input
              prefix={<SearchOutlined />}
              style={{ width: "310px" }}
              value={filterTerm}
              placeholder={t("teams.searchforMembersByNameOrEmail")}
              onChange={(e) => {
                setfilterTerm(e.target.value)
                setSelectedRecords([])
              }}
              allowClear
            />
          </Space>
        </Space>

        <Space
          direction="vertical"
          style={{ width: "100%"}}
        >
          {
            userRole === "admin" &&
            <div style={{ padding: "1rem 0rem" }}>
              <Radio.Group onChange={(event) => setfilterInvitationbyUserRole(event.target.value)} value={filterInvitationbyUserRole}>
                <Radio value={"admin_invitation"}>{t("common.invitedbyAdmins")}</Radio>
                <Radio value={"member_invitation"}>{t("common.invitedbyMembers")}</Radio>
              </Radio.Group>
            </div>
          }
          <Table
            dataSource={filteredInvitationList.map((ele: any, id: any) => ({
              ...ele,
              key: ele.invited_email,
            }))}
            columns={invitationColumns as any[]}
            loading={{ spinning: AsyncStates.LOADING === invitationListStatus, indicator: <LoadingOutlined /> }}
            rowSelection={
              userRole === "admin" ? {
                type: "checkbox",
                selectedRowKeys: selectedRecords.map((res: any) => res),
                onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                  setSelectedRecords(selectedRowKeys)
                },
              } : undefined}
            pagination={{ pageSize: 5 }}
            className='teams-pending-invitation-table'
          />
        </Space>
      </Modal>
      <DeactivatedAccountsModal setShowDeactivatedAccountModalVisible={setShowDeactivatedAccountModalVisible} showDeactivatedAccountModalVisible={showDeactivatedAccountModalVisible} />
    </motion.div >
  )
}
