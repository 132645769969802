import React, { useEffect, useState } from "react"
import { Col, Divider, Input, Row, Space, Spin, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
	clearOtpState,
	disableOtpRequest,
	requestOtpRequest,
	verifyOtpTrueRequest,
} from "src/store/actions/login"
import { LoadingOutlined } from "@ant-design/icons"
import { AsyncStates } from "src/constants"
import QRCode from "react-qr-code"
import { StyledButton } from "src/styled_components/StyledButton"

const { Text, Title } = Typography

export const TwoFactorAuth = () => {
	const [t] = useTranslate()
	const dispatch = useDispatch()

	const [faState, setFaState] = useState<string>("member")

	const {
		requestOtpData,
		requestOtpStatus,
		verifyOtpTrueStatus,
		disableOtpStatus,
		loginResponse: { otp, login_with_provider, user_role, user_two_fa, company_two_fa },
	} = useSelector((state: StoreState) => state.login)
	const [otpValue, setOtpValue] = useState("")

	useEffect(() => {
		return () => {
			dispatch(clearOtpState())
		}
	}, [dispatch, user_role])

	const codeChange = (e: any) => {
		if (e.target.value?.length < 7) setOtpValue(e.target.value)
	}

	return (
		<div>
			{login_with_provider ? (
				<Row style={{ marginTop: 10 }}>
					<Text strong>{t("settings.2faServiceProvider")}</Text>
				</Row>
			) : (
				<Row style={{ marginTop: 4 }}>
					<Space>
						<Text>{t("common.status")} :</Text>
						<Text type={otp ? "success" : "warning"}>
							{otp ? t("common.enabled") : t("common.disabled")}
						</Text>
					</Space>
					{user_role === "admin" && <StyledButton style={{ marginLeft: "auto" }}
						onClick={() => {
							if (otp && company_two_fa) dispatch(disableOtpRequest("admin"))
							else {
								setFaState("admin")
								dispatch(requestOtpRequest("admin"))
							}
						}}
						ghost
						type="primary"
					>
						{otp && company_two_fa ? t("setting.disabletotp") : t("setting.enabletotp")} {" "} {t("common.company")}
					</StyledButton>}
					{!company_two_fa && <StyledButton style={{ marginLeft: user_role === "admin" ? "0.5rem" : "auto" }}
						onClick={() => otp && user_two_fa ? dispatch(disableOtpRequest("member")) : dispatch(requestOtpRequest("member"))}
						ghost
						type="primary"
					>
						{otp && user_two_fa ? t("setting.disabletotp") : t("setting.enabletotp")} {" "} {t("common.member")}
					</StyledButton>}
				</Row>
			)}
			<Spin
				indicator={<LoadingOutlined />}
				spinning={
					requestOtpStatus === AsyncStates.LOADING ||
					verifyOtpTrueStatus === AsyncStates.LOADING ||
					disableOtpStatus === AsyncStates.LOADING
				}
			>
				<Space
					size="large"
					direction="vertical"
					style={{ width: "100%", marginTop: 10 }}
				>
					{requestOtpStatus === AsyncStates.SUCCESS ? (
						<>
							<Divider />
							<Row justify="space-between">
								<Col span={12} style={{ minWidth: 400, marginBottom: 50 }}>
									<Space direction="vertical">
										<QRCode value={requestOtpData} />
										<Input
											addonBefore={<Text strong>{t("common.code")}</Text>}
											value={otpValue}
											onChange={codeChange}
											placeholder={t("common.enterCode")}
											size="large"
											type="number"
											maxLength={6}
										/>
										<StyledButton
											style={{ width: "100%" }}
											disabled={otpValue?.length !== 6}
											type="primary"
											onClick={() => {
												setOtpValue("")
												dispatch(verifyOtpTrueRequest({ otp: otpValue, enable_for_company: faState === "admin" }))
											}}
										>
											{"Verify"}
										</StyledButton>
									</Space>
								</Col>
								<Col span={12}>
									<Space direction="vertical">
										<Title level={5}>{t("common.steps")}</Title>
										<Text type="secondary" strong>
											{t("common.authenticatorApp")}
										</Text>
										<Text type="secondary" strong>
											{t("common.QRCodeverification")}
										</Text>
									</Space>
								</Col>
							</Row>
						</>
					) : (
						""
					)}
				</Space>
			</Spin>
		</div>
	)
}
