import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import { motion } from "framer-motion"
import { Avatar, Select, Space, Table } from "antd"
import { StyledCard } from "src/styled_components/StyledCard"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchAuditLogActionsRequest,
  fetchAuditLogListRequest,
} from "src/store/actions/auditLog";
import { StoreState } from "src/store/configureStore"
import Text from "antd/lib/typography/Text"
import { AsyncStates } from "src/constants"
import useTranslate from "src/utils/useTranslate"
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const { Option } = Select

export const AuditLog = () => {
    const dispatch = useDispatch()
    const [selectedAction, setSelectedAction] = useState("")
    const {
      auditLogList,
      auditLogActionsList,
      auditLogListStatus,
      auditLogListTotalCount,
    } = useSelector((state: StoreState) => state.auditLog);
    const filteredAuditLogList = useMemo(
      () =>
        auditLogList.filter((auditLog: any) =>
          auditLog?.action
            ?.toLowerCase()
            ?.includes(selectedAction?.toLocaleLowerCase() || ""),
        ),
      [auditLogList, selectedAction],
    );
    const [t] = useTranslate();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
      dispatch(fetchAuditLogActionsRequest());
    }, [dispatch]);

    useEffect(() => {
      if (selectedAction !== "") {
        dispatch(fetchAuditLogListRequest({ action: selectedAction }));
        setPage(1);
        setLimit(10);
      }
    }, [dispatch, selectedAction]);

    useEffect(() => {
      let skip = (page - 1) * limit;
      if (skip < 0) skip = 0;
      dispatch(
        fetchAuditLogListRequest(
          selectedAction !== ""
            ? { action: selectedAction, start: skip, limit }
            : { start: skip, limit },
        ),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, dispatch]);

    const auditLogColumns: any = [
      {
        key: "user",
        title: t("auditLog.member"),
        width: 400,
        render: (rowValue: any, row: any, index: any): any => (
          <Space>
            <Avatar src={row?.user?.image_url} alt={row?.user?.user_name} />
            <Space direction="vertical" style={{ rowGap: 0 }}>
              <Text strong>
                {row?.user?.user_name[0]?.toUpperCase() +
                  row?.user?.user_name?.slice(1)}
              </Text>
              <Text type="secondary" style={{ wordBreak: "break-word" }}>
                {row?.message}
              </Text>
            </Space>
          </Space>
        ),
      },
      {
        key: "action",
        title: t("auditLog.action"),
        dataIndex: "action",
      },
      {
        key: "ip",
        title: t("common.ip"),
        dataIndex: "ip",
        render: (ipAddress: any) => <Text>{ipAddress || "-"}</Text>,
      },
      {
        key: "time",
        title: t("common.Time"),
        dataIndex: "created",
        width: 150,
        render: (date: any) => (
          <Text>
            {!!date
              ? dayjs.utc(date).format("MMMM Do YYYY, h:mm A") + " UTC"
              : "-"}
          </Text>
        ),
      },
    ];

    const scrollToTop = () => {
      setTimeout(() => window.scrollTo(0, 0), 600);
    };

    return (
      <>
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ type: "just" }}
          id={"audit-log-table"}
        >
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <StyledPageHeader
              ghost={false}
              title={t("common.auditLog")}
              onBack={() => window.history.back()}
            ></StyledPageHeader>

            <StyledCard bodyStyle={{padding:"8px 32px"}}>
              <Select
                style={{ width: 215, float: "right" }}
                placeholder={t("common.selectActions")}
                showSearch
                allowClear
                onChange={(value: string) => setSelectedAction(value)}
              >
                {auditLogActionsList?.map((action: any, index: any) => (
                  <Option key={index} value={action}>
                    {action}
                  </Option>
                ))}
              </Select>
              <Space
                direction="vertical"
                size="large"
                style={{ width: "100%" }}
              >
                <Table
                  dataSource={filteredAuditLogList}
                  columns={auditLogColumns}
                  bordered
                  loading={AsyncStates.LOADING === auditLogListStatus}
                  pagination={{
                    onChange: (page, size) => {
                      setPage(page);
                      scrollToTop();
                    },
                    onShowSizeChange: (curr, size) => {
                      setPage(curr);
                      setLimit(size);
                    },
                    total: auditLogListTotalCount,
                    pageSize: limit,
                    current: page,
                    showSizeChanger: true,
                  }}
                />
              </Space>
            </StyledCard>
          </Space>
        </motion.div>
      </>
    );
}