import React, { useMemo } from "react"
import { Collapse, Row, Typography } from "antd"
import { IDataSheet } from "src/components/IDataSheet"
import useTranslate from "src/utils/useTranslate"
import {
  CategoricalObjectiveList,
  CategoricalProperty,
  OptionsDropdown,
  ParameterListDropdown,
} from "./types"
import { StyledButton } from "src/styled_components/StyledButton"
import { PlusOutlined } from "@ant-design/icons"
import "./categorical-output-constraints.scss"

type P = {
  categoricalObjectiveList: CategoricalObjectiveList
  categoricalRange: CategoricalProperty[]
  parameterListDropDown: ParameterListDropdown
  optionsDropDown: OptionsDropdown
  addRow: (inputType: string) => void
}

export const CategoricalOutputConstraints = ({
  categoricalObjectiveList,
  categoricalRange,
  parameterListDropDown,
  optionsDropDown,
  addRow,
}: P) => {
  const [t] = useTranslate()

  const categoricalRangeInput = useMemo(() => {
    const cells = categoricalRange.map((range, i) => {
      return [
        {
          value: range.parameter,
          component: parameterListDropDown(i, "categoricalRange"),
          className: "dropdown-remove-cell",
          forceComponent: true,
        },
        {
          value: range.output_range_optimal,
          readOnly: true,
          forceComponent: true,
          width: 200
        },
        {
          value: range.parameter,
          component: optionsDropDown(range, i),
          forceComponent: true,
          readOnly: true,
        },
      ]
    })

    return cells
  }, [categoricalRange, optionsDropDown, parameterListDropDown])

  return (
    <Collapse style={{ width: "100%" }}>
      <Collapse.Panel
        key="categorical-output-constraints"
        header={t("aiEngine.inverseModel.categoricalOutputConstraints")}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <StyledButton
                disabled={
                  categoricalObjectiveList.length === 0 ||
                  categoricalObjectiveList.length === categoricalRange.length
                }
                onClick={() => addRow("categoricalRange")}
                icon={<PlusOutlined />}
                type="default"
                size="small"
                style={{ borderRadius: 5, outline: "none" }}
              >
                {t("common.add")} {t("formulations.type.properties")}
              </StyledButton>
            </div>
          </div>

          <IDataSheet
            data={[
              [
                {
                  value: t("aiEngine.objectives"),
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
                      <Typography.Text
                        strong
                      >
                        {t("aiEngine.objectives")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                  readOnly: true,
                  width: "30%",
                },
                {
                  value: t("aiEngine.range"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                        strong
                        style={{ width: '200px' }}
                      >
                        {t("aiEngine.range")}
                      </Typography.Text>
                    </Row>
                  ),
                  forceComponent: true,
                  width: 200
                },
                {
                  value: t("common.value"),
                  readOnly: true,
                  component: (
                    <Row style={{ padding: 8, marginLeft: 8 }}>
                      <Typography.Text
                      strong
                      >
                        {t("common.value")}
                      </Typography.Text>
                    </Row>
                  ),
                  width: "40%",
                  forceComponent: true,
                },
              ],
              ...categoricalRangeInput,
            ]}
            valueRenderer={(cell) => cell.value}
            className="categorical-output-constraints-table"
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}
