import { Modal } from "antd";
import { AddData } from "src/components/Inventory/AddData";

export const UpdateTrialDataModal = ({ visible, setVisible }: any) => {
  return (
    <Modal
      maskClosable={false}
      open={visible}
      styles={{
        body: {
          minHeight: 100,
          maxHeight: 500,
          overflowY: "auto",
        },
      }}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <AddData setModalOpen={setVisible} />
    </Modal>
  );
};
