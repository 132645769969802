import { Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
// import styles from './WorkOrderDetails.module.scss'
import "./react-datasheet.css";
import {
  defaultStages,
  permissions,
  projectStatus,
} from "src/constants";
import { Prompt } from "react-router-dom";
import useTranslate from "src/utils/useTranslate";
import { usePermission } from "src/utils/usePermissions";
import { CustomStagesModal } from "./CustomStagesModal";
import { WorkOrderForm } from "./WorkOrderForm";
import { listTemplateRequest } from "src/store/actions/templates";
import { CreateWoModal } from "../Datasheet/CreateWoModal";

interface WorkOrderDetailsProps { }

export const OpenWoDetails = (props: WorkOrderDetailsProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();

  //store state
  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );
  const isEditing = useSelector((state: StoreState) => state.isEditing);
  const cellChanges = useSelector((state: StoreState) => state.workOrderDetails.cellChanges);
  const userAccess = usePermission();
  const disabled = useMemo(
    () =>
      userAccess?.permission === permissions.viewer ||
      userAccess?.status !== projectStatus.in_progress,
    [userAccess]
  );

  const [createWoModalVisible, setCreateWoModalVisible] =
    useState<boolean>(false);

  const [WOChanges, setWOChanges] = useState<any>({});
  const [stagesModalVisisble, setStagesModalVisible] = useState(false);

  const [stages, setStages] = useState(() =>
    JSON.parse(JSON.stringify(defaultStages))
  );

  useEffect(() => {
    dispatch(listTemplateRequest());
  }, [dispatch]);

  return (
    <>
      <Prompt
        when={isEditing || Boolean(cellChanges.length)}
        message={t("workOrderDetails.warn.unsavedChanges")}
      />
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <WorkOrderForm
          disabled={disabled}
          setStagesModalVisible={setStagesModalVisible}
          setWOChanges={setWOChanges}
          stages={stages}
          WOChanges={WOChanges}
        />
      </Space>
      <CustomStagesModal
        stagesModalVisisble={stagesModalVisisble}
        x
        setStagesModalVisible={setStagesModalVisible}
        stages={stages}
        setStages={setStages}
        workOrder={workOrder}
      />
      <CreateWoModal
        createWoModalVisible={createWoModalVisible}
        setCreateWoModalVisible={setCreateWoModalVisible}
      />
    </>
  );
};