import { appReducer } from "../configureStore";
import { subscriptionsClient } from "../RelayEnvironment";
import { socket, socketChannel } from "../sagas/newInverseModel";
import jwtManager from "src/utils/jwtManager";
import { signOut } from "firebase/auth";
import { auth } from "src/utils/firebase";
import { AsyncStates } from "src/constants";

const initDisplayNamesState = {
    displayNames: {
        status: AsyncStates.INITIAL,
        statusWithoutSpinner: AsyncStates.INITIAL,
        error: '',
        inventoryData: {},
        data: {
            ingredients: {},
            characterizations: {},
            material: {},
            application: [],
            processing: {},
            properties: {},
            metadata_properties: {},
            stages: {},
            descriptor: { ingredients: "Ingredients", characterizations: "Characterizations", processing: "Processing", properties: "Properties" },
        }
    }
}


const rootReducer = (state: any, action: any) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        (window as any)?.Intercom("shutdown")
        signOut(auth)
        localStorage.removeItem('persist:root')
        socket?.close()
        socketChannel?.close()
        subscriptionsClient.dispose()
        jwtManager.deleteToken()
        const newState = appReducer(undefined, action)
        return {
            ...newState, ...initDisplayNamesState
        }
    }
    if (action.type.includes('REQUEST')) {
        return appReducer({ ...state, previousAction: [action, ...state.previousAction].slice(0, 10) }, action)
    }
    return appReducer(state, action)
}

export { rootReducer }