import { useEffect } from 'react'
import {
  Form,
  Space,
  Card,
  Typography,
  Spin,
  InputNumber,
  AutoComplete,
  Select,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../store/configureStore'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { celsureEditTrialRequest, celsureEditTrialReset, setCelsureEditingTrialsState } from 'src/store/actions/celsure'
import { AsyncStates } from 'src/constants'
import { geekblue, orange } from '@ant-design/colors'
import { celsureGenerateOptionalFields as generateOptionalFields } from "src/utils/decorator"
import useTranslate from 'src/utils/useTranslate'
import { useValue } from 'src/utils/useValue'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { StyledButton } from 'src/styled_components/StyledButton';

const { Text } = Typography
const { Option } = Select

interface PropertiesUploadProps {
  trialRecord: any
  setTrialRecord: React.Dispatch<React.SetStateAction<any>>
  form: any
}

export const PropertiesUpload = ({ trialRecord, setTrialRecord, form }: PropertiesUploadProps) => {
  const dispatch = useDispatch()
  const { getValue } = useValue()
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()

  const { celsureDisplayNamesData, celsureEditTrialStatus, celsureEditingTrialsState } = useSelector((state: StoreState) => state.celsure)
  const { celsureWo } = useSelector((state: StoreState) => state.celsureWo)

  useEffect(() => {
    if (celsureEditTrialStatus === AsyncStates.SUCCESS) {
      dispatch(celsureEditTrialReset())
      setTrialRecord({})
      form.resetFields()
      setTimeout(() => document?.getElementById?.('wo-trials')?.scrollIntoView(), 200)
    }
  }, [dispatch, celsureEditTrialStatus, form, setTrialRecord])

  useEffect(() => {
    if (!!trialRecord?.data?.trial_id && celsureWo?.work_order_stage === "properties_stage") {
      const { data: { backup_time, backup_till, minimum_temperature, maximum_temperature } } = trialRecord
      form.setFieldsValue({
        ...(!!backup_time?.value && { backup_time_value: getValue(backup_time?.value, 2) || "" }),
        backup_time_unit: backup_time?.unit || "hours",
        backup_till, minimum_temperature, maximum_temperature
      })
      dispatch(setCelsureEditingTrialsState(true))
    }
  }, [dispatch, form, trialRecord, celsureWo, getValue])

  const submitForm = (values: any) => {
    const { backup_time_value, backup_time_unit, backup_till, minimum_temperature, maximum_temperature } = values
    dispatch(celsureEditTrialRequest({
      ...trialRecord.data,
      backup_time: {
        value: backup_time_value, unit: backup_time_unit
      },
      backup_till, minimum_temperature, maximum_temperature
    }))
  }


  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }} id="upload-form">
      <Spin spinning={celsureEditTrialStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
        <Card
          title={
            <Space style={{ padding: 10, background: geekblue[1] }}><Text type="secondary"><EditOutlined /></Text>
              {`${trialRecord.data?.trial_display_id}`}
            </Space>}
          extra={celsureEditingTrialsState ? <Text style={{ color: orange.primary }}>{t("common.unsavedChanges")}</Text> : ""}
        >
          <Row>
            <Col span={8}>
              <Form onFinish={submitForm} form={form} layout="vertical" requiredMark={false} onFieldsChange={() => {
                if (!celsureEditingTrialsState) dispatch(setCelsureEditingTrialsState(true))
              }}>
                <Form.Item label={"Backup time"} required>
                  <Form.Item name="backup_time_value" rules={[{ required: true, message: "required" }]} style={{ display: 'inline-block' }}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                  <Form.Item name={"backup_time_unit"} rules={[{ required: true, message: "required" }]} initialValue={"mm"} style={{ display: 'inline-block', marginLeft: 20 }}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <AutoComplete
                      showSearch style={{ width: 80 }}
                      options={generateOptionalFields(celsureDisplayNamesData?.unit?.time)}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item label={"Backup till"} name={"backup_till"} rules={[{ required: true, message: "required" }]}
                  required
                  tooltip={requiredFieldStar}
                >
                  <Select>
                    <Option value={"threshold_maximum"}>{"Threshold Maximum"}</Option>
                    <Option value={"threshold_minimum"}>{"Threshold Minimum"}</Option>
                  </Select>
                </Form.Item>
                <Form.Item label={"Minimum Temperature"} name="minimum_temperature" rules={[{ required: false, message: t("common.required") }]}>
                  <InputNumber />
                </Form.Item>
                <Form.Item label={"Maximum Temperature"} name="maximum_temperature" rules={[{ required: false, message: t("common.required") }]}>
                  <InputNumber />
                </Form.Item>
                <Form.Item style={{ marginTop: 50 }}>
                  <StyledButton style={{ width: 150 }} type="primary" htmlType="submit">{t("common.submit")}</StyledButton>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Spin>
    </Space>
  )
}
