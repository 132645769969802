import { useEffect, useState } from "react"
import jwtManager from "../jwtManager"

const tokenBroadcast = new BroadcastChannel("token_channel");

export const useToken = () => {
    const [token, setToken] = useState<any>(() => jwtManager.getToken())

    useEffect(() => {
        const onBroadcast = (e: MessageEvent<any>) => {
            return setToken(e?.data?.data)
        }

        tokenBroadcast.addEventListener("message", onBroadcast)

        return () => {
            tokenBroadcast.removeEventListener("message", onBroadcast)
        }
    }, [])

    return token
}