import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Konva from "konva";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { StoreState } from "src/store/configureStore";
import useImage from "use-image";

type TProps = {
    data: any;
    image: any;
    showBBox: boolean;
    selectedPoints: any[];
    setSelectedPoints: React.Dispatch<React.SetStateAction<any[]>>;
    isGroupsVisible: boolean;
    setTooltip: React.Dispatch<React.SetStateAction<{}>>;
    selections: any[];
};

const SeedAnalysisDetailsContent = ({
    data,
    image,
    showBBox,
    selectedPoints,
    setSelectedPoints,
    isGroupsVisible,
    setTooltip,
    selections,
}: TProps) => {
    const [img] = useImage(image);
    const NORMAL_STROKE = 2;
    const {
        semAnalysisMaskHeightWidthStatus,
        semAnalysisMaskHeightWidth,
        classificationAnalysisStatus,
        deleteSemAnalysisMaskHeightWidthStatus,
        semAnalysisListStatus,
        semAnalysisScaleStatus,
        classificationAnalysis: { result: classificationAnalysis },
    } = useSelector((state: StoreState) => state.semAnalysis);

    const handlePolygonHover = useCallback(
        (polygon: any) => {
            const { pixel_area, max_length } = polygon;
            const { calibrated_length, calibrated_area } = polygon;

            const length =
                calibrated_length?.value || max_length?.pixel_length + " px";
            const angle = max_length?.angle;
            const area = calibrated_area?.value || pixel_area + " px²";
            const content = {
                mask_id: polygon?.id?.replace("mask_id_", "Mask Name: "),
                area: `Area: ${area}`,
                length: `Length: ${length}`,
                angle: `Angle: ${angle}°`,
            };
            setTooltip({
                visible: false,
                content,
            });
        },
        [setTooltip]
    );

    const handlePolygonOut = useCallback(() => {
        setTooltip({
            visible: false,
            content: {},
        });
    }, [setTooltip]);

    const handleCircleHover = useCallback(
        (stage: any, height: any, width: any) => {
            stage.container().style.cursor = "pointer";

            const content =
                height !== null && width !== null
                    ? {
                        height: `Height: ${height} px`,
                        width: `Width: ${width} px`,
                    }
                    : {};
            setTooltip({
                visible: false,
                content,
            });
        },
        [setTooltip]
    );

    const handleCircleOut = useCallback(
        (stage: any) => {
            stage.container().style.cursor = "default";

            setTooltip({
                visible: false,
                content: {},
            });
        },
        [setTooltip]
    );

    const allMasks: { [key: string]: any } = useMemo(() => {
        let masks: any = [];
        if (Object.keys(semAnalysisMaskHeightWidth || {}).length > 0) {
            Object.values(semAnalysisMaskHeightWidth)?.forEach((item: any) => {
                masks.push({ ...item?.points });
            });
        }
        return masks;
    }, [semAnalysisMaskHeightWidth]);

    const points = useMemo(() => {
        const pts: any[] = [];
        if (allMasks.length !== 0) {
            allMasks.forEach((mask: any) => {
                Object.values(mask)?.forEach((point: any) => {
                    const { loc, height, width } = point;
                    pts.push({ loc, height, width });
                });
            });
        }
        return pts;
    }, [allMasks]);

    const pointsParentMasks = useMemo(() => {
        const pts: any[] = [];
        if (allMasks.length !== 0) {
            allMasks.forEach((mask: any) => {
                Object.values(mask)?.forEach((point: any) => {
                    pts.push(point.mask);
                });
            });
        }
        return pts;
    }, [allMasks]);

    const pointsParentMaskColor: { [key: string]: any } = useMemo(() => {
        let masksColor = {};
        if (Object.keys(semAnalysisMaskHeightWidth || {}).length > 0) {
            Object.values(semAnalysisMaskHeightWidth)?.forEach((item: any) => {
                masksColor = {
                    ...masksColor,
                    ...Object.keys(item?.points)?.reduce((acc: any, curr: any) => {
                        acc[item?.points?.[curr]?.mask] = item?.mask_color;
                        return acc;
                    }, {}),
                };
            });
        }
        return masksColor;
    }, [semAnalysisMaskHeightWidth]);

    useEffect(() => {
        // Make sure data is not empty
        if (!data || !data.mask_results) return;

        // Setup
        const canvasWidth = img?.width || 1000;
        const canvasHeight = img?.height || 1000;

        const stage = new Konva.Stage({
            container: "image-masks", // id of container <div>
            width: canvasWidth,
            height: canvasHeight,
        });

        const layer = new Konva.Layer();
        stage.add(layer);
        layer.draw();

        const layerForCirclesApi = new Konva.Layer();
        stage.add(layerForCirclesApi);
        layerForCirclesApi.draw();

        const layerForCircles = new Konva.Layer();
        stage.add(layerForCircles);
        layerForCircles.draw();

        // Adding Image
        const img_layer = new Konva.Image({
            image: img,
        });

        layer.add(img_layer);
        layer.batchDraw();

        // Cross hairs
        const crossHairSquare = new Konva.Rect({
            x: 250 - 10,
            y: 250 - 10,
            width: 20,
            height: 20,
            stroke: "white",
            strokeWidth: NORMAL_STROKE,
        });

        const horizontalLeft = new Konva.Line({
            points: [0, 250, 250, 250],
            stroke: "white",
            strokeWidth: NORMAL_STROKE,
        });

        const horizontalRight = new Konva.Line({
            points: [250, 250, 500, 250],
            stroke: "white",
            strokeWidth: NORMAL_STROKE,
        });

        const verticalTop = new Konva.Line({
            points: [250, 0, 250, 250],
            stroke: "white",
            strokeWidth: NORMAL_STROKE,
        });

        const verticalBottom = new Konva.Line({
            points: [250, 250, 250, 500],
            stroke: "white",
            strokeWidth: NORMAL_STROKE,
        });

        let dataArr: any =
            selections && selections?.[0]
                ? data.mask_results.filter((item: any) => {
                    const { max_length, calibrated_length } = item;
                    const length =
                        calibrated_length?.value.replace(/[^0-9.]/g, "").trim() ||
                        max_length.pixel_length;
                    return length > selections[0]?.x0 && length < selections[0]?.x1;
                })
                : data.mask_results;

        dataArr?.forEach((mask: any) => {
            const group = new Konva.Group({
                clipFunc: (ctx) => {
                    ctx.beginPath();
                    mask?.mask_polygon?.forEach((point: any, index: number) => {
                        if (index === 0) {
                            ctx.moveTo(point[0], point[1]);
                        } else {
                            ctx.lineTo(point[0], point[1]);
                        }
                    });
                    ctx.closePath();
                },
                id: mask.id,
            });

            const pol = new Konva.Line({
                points: mask?.mask_polygon?.flat() ?? [],
                stroke: "white",
                fill: mask?.mask_color,
                strokeWidth: 0,
                opacity: 0.2,
                closed: true,
                id: mask.id,
            });

            const strokePol = new Konva.Line({
                points: mask?.mask_polygon?.flat() ?? [],
                fill: "transparent",
                stroke: "white",
                strokeWidth: 0,
                closed: true,
            });

            const groupPolygon = new Konva.Line({
                points: mask?.mask_polygon?.flat() ?? [],
                fill: pointsParentMasks?.includes(mask?.id)
                    ? pointsParentMaskColor[mask?.id]
                    : "transparent",
                stroke: "white",
                strokeWidth: 0,
                closed: true,
                visible: isGroupsVisible,
            });

            const boundBoxData = pol?.getClientRect();

            const bbox = new Konva.Rect({
                x: boundBoxData.x,
                y: boundBoxData.y,
                width: boundBoxData.width,
                height: boundBoxData.height,
                fill: "transparent",
                stroke: "red",
                strokeWidth: 0,
                id: mask.id,
                visible: showBBox,
            });

            group.on("click", (event) => {
                const mousePos = stage.getPointerPosition();

                setSelectedPoints((prev: any) => [
                    ...prev,
                    {
                        mask: mask.id,
                        loc: [mousePos?.x ?? 0, mousePos?.y ?? 0],
                        height: 0,
                        width: 0,
                        area: mask.pixel_area,
                    },
                ]);
            });

            // Circle Data Coming from API
            const combinedPoints = [...points, ...selectedPoints];
            combinedPoints?.forEach((point: any) => {
                const circle = new Konva.Circle({
                    radius: 5,
                    fill: "#f55",
                    x: point.loc[0],
                    y: point.loc[1],
                });
                circle.on("mouseenter", (event: any) =>
                    handleCircleHover(stage, point.height, point.width)
                );
                circle.on("mouseleave", () => handleCircleOut(stage));

                layerForCirclesApi.add(circle);
            });
            layerForCirclesApi.batchDraw();

            group.on("mouseenter", () => {
                stage.container().style.cursor = "pointer";

                strokePol.strokeWidth(NORMAL_STROKE);
                bbox.strokeWidth(NORMAL_STROKE);

                group.add(horizontalLeft);
                group.add(horizontalRight);
                group.add(verticalTop);
                group.add(verticalBottom);
                group.add(crossHairSquare);

                horizontalLeft.show();
                horizontalRight.show();
                verticalTop.show();
                verticalBottom.show();
                crossHairSquare.show();

                layer.batchDraw();

                handlePolygonHover(mask);
            });

            group.on("mouseleave", () => {
                stage.container().style.cursor = "default";

                strokePol.strokeWidth(0);
                bbox.strokeWidth(0);

                group.remove();
                layer.add(group);

                horizontalLeft.hide();
                horizontalRight.hide();
                verticalTop.hide();
                verticalBottom.hide();
                crossHairSquare.hide();

                layer.batchDraw();

                handlePolygonOut();
            });

            group.add(pol);
            group.add(strokePol);
            group.add(groupPolygon);

            layer.add(bbox);
            bbox.moveToTop();
            layer.add(group);
        });

        // Position update for crosshairs
        stage.on("mousemove", () => {
            const pt = stage.getPointerPosition();
            crossHairSquare.position({
                x: (pt?.x ?? 0) - 10,
                y: (pt?.y ?? 0) - 10,
            });
            horizontalLeft.points([0, pt?.y ?? 0, pt?.x ?? 0, pt?.y ?? 0]);
            horizontalRight.points([pt?.x ?? 0, pt?.y ?? 0, canvasWidth, pt?.y ?? 0]);
            verticalTop.points([pt?.x ?? 0, 0, pt?.x ?? 0, pt?.y ?? 0]);
            verticalBottom.points([pt?.x ?? 0, pt?.y ?? 0, pt?.x ?? 0, canvasHeight]);
            layer.batchDraw();
        });
    }, [
        data,
        handleCircleHover,
        handleCircleOut,
        handlePolygonHover,
        handlePolygonOut,
        img,
        isGroupsVisible,
        points,
        pointsParentMaskColor,
        pointsParentMasks,
        selectedPoints,
        setSelectedPoints,
        showBBox,
        selections,
        classificationAnalysis
    ]);

    return (
        <div className="image-mask-container">
            <Spin
                indicator={<LoadingOutlined style={{ color: "white" }} />}
                spinning={[
                    semAnalysisMaskHeightWidthStatus,
                    classificationAnalysisStatus,
                    deleteSemAnalysisMaskHeightWidthStatus,
                    semAnalysisListStatus,
                    semAnalysisScaleStatus
                ].includes(AsyncStates.LOADING)}
                style={{ height: "100%", width: "100%", marginTop: "50px" }}
            >
                <div
                    id="image-masks"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "auto",
                    }}
                />
            </Spin>
        </div>
    );
};

export default memo(SeedAnalysisDetailsContent);
