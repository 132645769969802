import {
    Checkbox,
    Col,
    Form,
    Modal,
    Row,
    Select,
    Space,
    Typography,
} from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { updatePropertyRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
const { Text } = Typography;

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPropertyId: string;
    setRefetchPropertyUnits: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddEditPropertyUnitsModal = ({
    showModal,
    setShowModal,
    selectedPropertyId,
    setRefetchPropertyUnits,
}: TProps) => {
    const [t] = useTranslate();
    const [propertyUnitForm] = Form.useForm();
    const dispatch = useDispatch();
    const unitsList = useSelector(
        (state: StoreState) => state.conversion.unitList
    );
    const propertyUnitsData = useSelector(
        (state: StoreState) => state.repository.propertyUnits.data
    );
    const propertyStatus = useSelector(
        (state: StoreState) => state.repository.properties.status
    );

    useEffect(() => {
        if (showModal) {
            propertyUnitForm.setFieldValue("units", propertyUnitsData?.units);
        }
    }, [propertyUnitForm, propertyUnitsData?.units, showModal]);

    useEffect(() => {
        if (propertyStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [propertyStatus, setShowModal]);

    const submit = () => {
        const payload = propertyUnitForm.getFieldsValue();

        dispatch(
            updatePropertyRequest({
                ...payload,
                property_id: selectedPropertyId,
            })
        );
        setRefetchPropertyUnits(true);
    };

    const unitOptions = useMemo(() => {
        return [...new Set(unitsList.map((unit) => unit.name))];
    }, [unitsList]);

    return (
        <Modal
            title={
                <Typography.Title level={4}>{t("repository.updateUnits")}</Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
            destroyOnClose
        >
            <Form
                onFinish={submit}
                form={propertyUnitForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name={"units"}
                    label={t("repository.updateUnits")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        loading={false}
                        placeholder={t("common.select")}
                        showSearch={true}
                        mode="multiple"
                        maxTagCount="responsive"
                        optionRender={(option) => (
                            <Space direction="horizontal">
                                <Checkbox
                                    checked={propertyUnitForm
                                        .getFieldValue("units")
                                        ?.includes(option.value)}
                                    disabled={option.label === propertyUnitsData?.default_unit}
                                />
                                <Text style={{ whiteSpace: "initial" }}>{option.label}</Text>
                            </Space>
                        )}
                        options={unitOptions.map((item) => ({
                            label: item,
                            value: item,
                            disabled: item === propertyUnitsData?.default_unit,
                        }))}
                    />
                </Form.Item>

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton
                            type="default"
                            onClick={() => {
                                propertyUnitForm.resetFields();
                                if (propertyUnitsData?.default_unit)
                                    propertyUnitForm.setFieldValue("units", [
                                        propertyUnitsData.default_unit,
                                    ]);
                            }}
                        >
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={propertyStatus === AsyncStates.LOADING}
                            loading={propertyStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditPropertyUnitsModal;
