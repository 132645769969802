import { CloseOutlined } from '@ant-design/icons'
import { Avatar, Drawer, Table, Typography } from 'antd';
import { AvatarComponent, BORDER_STYLE, TEXT_PRIMARY } from '../../Dashboard';
import Legend from './Legend'
import Pie from './Pie'
import CountDesc from '../CountDesc'
import useTranslate from 'src/utils/useTranslate'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { antdTheme, AsyncStates } from 'src/constants'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom';

const DrawerProjects = ({ open, setOpen, data, total, type, setType }: any) => {

  const [t] = useTranslate()

  return (
    <Drawer
      title={t('common.projects')}
      placement="bottom"
      closable={false}
      height={'80%'}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      extra={
        <CloseOutlined
          onClick={() => {
            setOpen(false)
          }}
          style={{
            outline: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      }
      styles={{
        body: {
          height: '100%',
          display: 'flex'
        }
      }}
    >
      <LeftSection
        data={data}
        total={total}
        open={open}
        setOpen={setOpen}
        type={type}
        setType={setType}
      />
      <RightSection data={data} type={type} />
    </Drawer>
  )
}

export default DrawerProjects

const LeftSection = ({ data, total, open, setOpen, type, setType }: any) => {
  const [t] = useTranslate()

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        gap: 24,
        borderRight: BORDER_STYLE,
        width: '50%',
        position: 'relative'
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '35%',
          zIndex: 2
        }}
      >
        <CountDesc
          count={total}
          desc={t('common.project')}
          heading1Size={42}
          heading2Size={18}
          align={'center'}
        />
      </div>
      <Pie
        data={data}
        total={total}
        size={400}
        margin={30}
        onClick={(status: string) => {
          if (!open) setOpen(true)
          setType(status)
        }}
        select={true}
        increase={30}
        type={type}
      />
      <Legend list={data} iconSize={15} fontSize={antdTheme.fontSizeHeading4} gap={18} />
    </div>
  )
}

const RightSection = ({ data, type }: any) => {
  const [t] = useTranslate()

  const { projectList, projectListStatus } = useSelector(
    (state: StoreState) => state.projects
  )

  const tableData = useMemo(() => {
    return projectList
      .filter((project: any) =>
        type === 'ongoing'
          ? project?.status === 'in_progress'
          : project?.status === type
      )
      .map((project: any) => ({
        name: project.name,
        members: project.members.map((member: any) => member.user_id),
        close_date: project.close_date,
        project_id: project.project_id
      }))
  }, [projectList, type])

  const tableColumns = useMemo(() => {
    return [
      {
        title: `${tableData?.length} ${t('common.items')}`,
        dataIndex: 'name',
        key: 'name',
        width: '60%',
        render: (text: any, record:any) => (
          <Link to={`/projects/details/${record.project_id}`} >
            <Typography.Text
              style={{
                margin: 0,
              }}
              ellipsis={{ tooltip: text }}
              strong
              className='dashboard_underline_text'
            >
              {text}
            </Typography.Text>
          </Link>
        )
      },
      {
        title: t('common.members'),
        dataIndex: 'members',
        key: 'members',
        width: '20%',
        render: (members: string[]) => {
          return (
            <Avatar.Group
              maxCount={2}
              maxStyle={{
                color: 'white',
                backgroundColor: TEXT_PRIMARY,
                fontSize: antdTheme.fontSize,
                cursor: 'pointer',
                height: 27,
                width: 27,
                display: 'flex',
                alignItems: 'center'
              }}
              maxPopoverTrigger='hover'
            >
              {(members || [])?.map((member: string, index:number) => (
                <AvatarComponent id={member} key={index} marginAuto={false} />
              ))}
            </Avatar.Group>
          )
        }
      },
      {
        title: t('common.closeDate'),
        dataIndex: 'close_date',
        key: 'close_date',
        width: '20%',
        render: (date: any) => (
          <Typography.Text
            style={{
              margin: 0,
            }}
            ellipsis={{ tooltip: date }}
            strong
          >
            {date ? dayjs(date).format('DD MMM YYYY') : '-'}
          </Typography.Text>
        )
      }
    ]
  }, [t, tableData?.length])

  return (
    <div
      style={{
        width: '50%',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: 12,
        padding: '0 12px'
      }}
    >
      <Legend
        list={data.filter((project: any) => project.title === type)}
        iconSize={12}
        fontSize={antdTheme.fontSizeHeading5}
        gap={15}
      />
      <Table
        loading={projectListStatus === AsyncStates.LOADING}
        dataSource={tableData}
        columns={tableColumns}
        className="dashboard-pie-chart-workorder-drawer-table"
        pagination={false}
        scroll={{ y: 600 }}
        style={{
          overflowY: "auto",
          width: '100%',
          scrollbarWidth: 'none'
        }}
      />
    </div>
  )
}
