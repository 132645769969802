import { Button } from "antd"
import { ButtonProps } from "antd/lib/button"
import React, { useState } from "react"

type ExtraProps = {
	hoverColor?: string
}

export const StyledButton = React.forwardRef((props: ButtonProps & ExtraProps, ref: React.Ref<HTMLElement> | undefined) => {
	const { style, hoverColor, type, ...rest } = props
	const [hover, setHover] = useState(false)

	const hoverStyle =
		hoverColor && hover ? { borderColor: hoverColor, color: hoverColor } : {}

	return type === 'primary' ? (
      <Button
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          borderRadius: 5,
          outline: 'none',
          ...hoverStyle,
          ...style
        }}
        type={type}
        {...rest}
        ref={ref}
      />
  ) : (
    <Button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        borderRadius: 5,
        outline: 'none',
        ...hoverStyle,
        ...style
      }}
      type={type}
      {...rest}
      ref={ref}
    />
  )
})
