import { Col, Form, Modal, Row, Select, Typography } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { updateIngredientTemplatesRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTemplateId: string;
};

const AddEditIngredientTemplateCustomFieldsModal = ({
    showModal,
    setShowModal,
    selectedTemplateId,
}: TProps) => {
    const [t] = useTranslate();
    const [templateForm] = Form.useForm();
    const dispatch = useDispatch();
    const templates = useSelector(
        (state: StoreState) => state.repository.templates.data
    );
    const customFields = useSelector(
        (state: StoreState) => state.repository.fields.data
    );
    const templateStatus = useSelector(
        (state: StoreState) => state.repository.templates.status
    );

    const currentTemplate = useMemo(() => {
        return templates.find((t) => t.template_id === selectedTemplateId);
    }, [templates, selectedTemplateId]);

    const selectOptions = useMemo(() => {
        return customFields.sort((a, b) => a.field_name.localeCompare(b.field_name)).map((c) => ({
            label: c.field_name,
            value: c.field_id,
        }));
    }, [customFields]);

    useEffect(() => {
        if (showModal) {
            const formValue = currentTemplate?.custom_fields;

            templateForm.setFieldValue("custom_fields", formValue);
        }
    }, [currentTemplate, templateForm, showModal]);

    useEffect(() => {
        if (templateStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [templateStatus, setShowModal]);

    const submit = () => {
        const payload = templateForm.getFieldsValue();

        dispatch(
            updateIngredientTemplatesRequest({
                ...payload,
                template_id: selectedTemplateId,
            })
        );
    };

    return (
        <Modal
            title={
                <Typography.Title level={4}>
                    {t("repository.updateCustomFields")}
                </Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
            destroyOnClose
        >
            <Form
                onFinish={submit}
                form={templateForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name={"custom_fields"}
                    label={t("inventory.SelectCustomField(s)")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder={t("common.select")}
                        allowClear
                        showSearch={true}
                        mode="multiple"
                        maxTagCount="responsive"
                        optionFilterProp="label"
                        options={selectOptions}
                    />
                </Form.Item>

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton
                            type="default"
                            onClick={() => templateForm.resetFields()}
                        >
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={templateStatus === AsyncStates.LOADING}
                            loading={templateStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditIngredientTemplateCustomFieldsModal;
