import { Checkbox, Drawer, List, Modal, Popconfirm, Row, Typography } from 'antd';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { analyticsRecipeDistributionClear, analyticsRecipeDistributionTableClear, deleteSavedPlotsRequest, surfacePlotClear } from 'src/store/actions/dataSummary'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledCard } from 'src/styled_components/StyledCard'
import useTranslate from 'src/utils/useTranslate'
import { ViewSelectedGraphsData } from './ViewSelectedGraphsData/ViewSelectedGraphsData'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'

const { Title } = Typography

export const GraphHistoryDrawer = ({ showgraphsListDrawer, setShowGraphsListDrawer, getDisplayName, setSelectedPlots, setSelectedPlotsData, handlePlotchanges }: any) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const [selectededPlotId, setselectedPlotId] = useState<string[]>([])

    const { plotsFilterListStatus, plotsFilterListData, deleteSavedPlotsStatus } = useSelector((state: StoreState) => state.dataSummary)

    const deleteSelectedPlot = () => {
        dispatch(deleteSavedPlotsRequest({
            "plots_data_id": selectededPlotId[0]
        }))
    }
    const handleApply = (plot: any) => {
        setSelectedPlots(Object.keys(plot?.data?.plot_data ?? {}))
        setSelectedPlotsData(plot)
        setShowGraphsListDrawer(false)
        handlePlotchanges(Object.keys(plot?.data?.plot_data ?? {}))
        dispatch(analyticsRecipeDistributionClear())
        dispatch(analyticsRecipeDistributionTableClear())
        dispatch(surfacePlotClear())
    }

    return (
        <Drawer
            width={550}
            open={showgraphsListDrawer}
            onClose={() => setShowGraphsListDrawer(false)}
            mask={false}
            className='graphs__history__drawer'
            title={
                <Row justify="space-between">
                    <Title level={4} style={{ margin: 0 }}>{t("graphs.plotHistory")}</Title>
                    <StyledButton
                        onClick={() => {
                            Modal.confirm({
                                title: `${t("common.delete")} ${t("common.selectedRecords")}`,
                                icon: <StyledDeleteIcon />,
                                okText: t("common.confirm"),
                                cancelText: t("common.cancel"),
                                okButtonProps: { style: { borderRadius: "12px" } },
                                cancelButtonProps: { style: { borderRadius: "12px" } },
                                onOk: () => deleteSelectedPlot(),
                            })
                        }}
                        disabled={!selectededPlotId.length}
                        loading={plotsFilterListStatus === AsyncStates.LOADING || deleteSavedPlotsStatus === AsyncStates.LOADING}
                        style={{ marginRight: 35 }}
                        type="primary"
                    >
                        {t("aiEngine.deleteRecords")}
                    </StyledButton>
                </Row>
            }
        >
            <StyledCard style={{ background: "#ffffff", overflowX: "auto" }}
                bodyStyle={{ padding: "0.5rem" }}
            >
                <List
                    loading={plotsFilterListStatus === AsyncStates.LOADING}
                    size="small"
                    grid={{ column: 1, gutter: 16 }}
                    dataSource={plotsFilterListData}
                    renderItem={(plot: any) => {
                        return <List.Item key={plot.plots_data_id} className='graph__history__item'>
                            <StyledCard
                                headStyle={{ padding: 0 }}
                                bodyStyle={{ padding: 0 }}
                                style={{
                                    background: "#ffffff",
                                    border: "1px solid #e8e8e8",
                                    borderRadius: "10px",
                                    padding: "0.5rem",
                                }}
                                size="small"
                                key={plot.plots_data_id}
                                title={
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "1rem",
                                        }}
                                    >
                                        <Checkbox
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setselectedPlotId([plot.plots_data_id])
                                                } else {
                                                    setselectedPlotId([])
                                                }
                                            }}
                                            checked={selectededPlotId.includes(plot.plots_data_id)}
                                        >
                                            <Typography.Text style={{ userSelect: "none" }}>
                                                {plot.name}
                                            </Typography.Text>
                                        </Checkbox>
                                    </div>
                                }
                                extra={
                                    <Popconfirm
                                        okText={t("common.ok")}
                                        cancelText={t("common.cancel")}
                                        title={t("graphs.selectPlot")}
                                        onConfirm={() => handleApply(plot)}
                                        placement='topLeft'
                                        description={() => {
                                            return <div style={{ maxWidth: 270 }}>{t("graphs.selectPlotNote")}</div>
                                        }}
                                    >
                                        <StyledButton type="link" size="middle"
                                            style={{
                                                cursor: "pointer",
                                            }}>
                                            {t("aiEngine.button.apply")}
                                        </StyledButton>
                                    </Popconfirm>
                                }
                            >
                                <ViewSelectedGraphsData plotData={plot?.data} getDisplayName={getDisplayName} createdOn={plot.created} key={plot.plots_data_id} from={"graph_history_drawer"} plot={plot} />
                            </StyledCard>
                        </List.Item>
                    }}
                />
            </StyledCard>
        </Drawer >
    )
}
