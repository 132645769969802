import { createActions } from "redux-actions";

export enum AssetLibraryGetContent {
    REQUEST = "ASSET_LIBRARY_GET_CONTENT_REQUEST",
    SUCCESS = "ASSET_LIBRARY_GET_CONTENT_SUCCESS",
    FAILURE = "ASSET_LIBRARY_GET_CONTENT_FAILURE",
    CLEAR = "ASSET_LIBRARY_GET_CONTENT_CLEAR",
}

export enum AssetLibraryAddFolder {
    REQUEST = 'ASSET_LIBRARY_ADD_FOLDER_REQUEST',
    SUCCESS = 'ASSET_LIBRARY_ADD_FOLDER_SUCCESS',
    FAILURE = 'ASSET_LIBRARY_ADD_FOLDER_FAILURE',
    CLEAR = 'ASSET_LIBRARY_ADD_FOLDER_CLEAR'
}

export enum AssetLibraryAddFile {
    REQUEST = 'ASSET_LIBRARY_ADD_FILE_REQUEST',
    SUCCESS = 'ASSET_LIBRARY_ADD_FILE_SUCCESS',
    FAILURE = 'ASSET_LIBRARY_ADD_FILE_FAILURE',
    CLEAR = 'ASSET_LIBRARY_ADD_FILE_CLEAR'
}

export enum AssetLibraryRename {
    REQUEST = 'ASSET_LIBRARY_RENAME_REQUEST',
    SUCCESS = 'ASSET_LIBRARY_RENAME_SUCCESS',
    FAILURE = 'ASSET_LIBRARY_RENAME_FAILURE',
    CLEAR = 'ASSET_LIBRARY_RENAME_CLEAR'
}

export enum AssetLibraryDelete {
    REQUEST = 'ASSET_LIBRARY_DELETE_REQUEST',
    SUCCESS = 'ASSET_LIBRARY_DELETE_SUCCESS',
    FAILURE = 'ASSET_LIBRARY_DELETE_FAILURE',
    CLEAR = 'ASSET_LIBRARY_DELETE_CLEAR'
}

export enum AssetLibraryDownload {
    REQUEST = 'ASSET_LIBRARY_DOWNLOAD_REQUEST',
    SUCCESS = 'ASSET_LIBRARY_DOWNLOAD_SUCCESS',
    FAILURE = 'ASSET_LIBRARY_DOWNLOAD_FAILURE',
    CLEAR = 'ASSET_LIBRARY_DOWNLOAD_CLEAR'
}

export enum AssetLibraryPaste {
    REQUEST = 'ASSET_LIBRARY_PASTE_REQUEST',
    SUCCESS = 'ASSET_LIBRARY_PASTE_SUCCESS',
    FAILURE = 'ASSET_LIBRARY_PASTE_FAILURE',
    CLEAR = 'ASSET_LIBRARY_PASTE_CLEAR'
}

export enum AssetLibraryMove {
    REQUEST = 'ASSET_LIBRARY_MOVE_REQUEST',
    SUCCESS = 'ASSET_LIBRARY_MOVE_SUCCESS',
    FAILURE = 'ASSET_LIBRARY_MOVE_FAILURE',
    CLEAR = 'ASSET_LIBRARY_MOVE_CLEAR'
}

export enum AssetLibraryFolderNavigate {
    REQUEST = 'ASSET_LIBRARY_FOLDER_NAVIGATE_REQUEST',
    SUCCESS = 'ASSET_LIBRARY_FOLDER_NAVIGATE_SUCCESS',
    FAILURE = 'ASSET_LIBRARY_FOLDER_NAVIGATE_FAILURE',
    CLEAR = 'ASSET_LIBRARY_FOLDER_NAVIGATE_CLEAR',
    UPDATE_BREADCRUMB = 'ASSET_LIBRARY_FOLDER_NAVIGATE_UPDATE_BREADCRUMB',
    SELECT = 'ASSET_LIBRARY_FOLDER_NAVIGATE_SELECT',
    SET_CURRENT_DIRECTORY = 'ASSET_LIBRARY_FOLDER_NAVIGATE_SET_CURRENT_DIRECTORY',
}

export enum AssetLibraryLocalChanges {
    SET_BREADCRUMB = 'ASSET_LIBRARY_SET_BREADCRUMB',
    UPDATE_BREADCRUMB = 'ASSET_LIBRARY_UPDATE_BREADCRUMB',
    SET_CURRENT_DIRECTORY = 'ASSET_LIBRARY_SET_CURRENT_DIRECTORY',
    SET_SELECTED_DIRECTORIES = 'ASSET_LIBRARY_SET_SELECTED_DIRECTORIES',
    COPY_DIRECTORIES = 'ASSET_LIBRARY_COPY_DIRECTORIES',
}

export const {
    assetLibraryGetContentRequest,
    assetLibraryGetContentSuccess,
    assetLibraryGetContentFailure,
    assetLibraryGetContentClear,

    assetLibraryAddFolderRequest,
    assetLibraryAddFolderSuccess,
    assetLibraryAddFolderFailure,
    assetLibraryAddFolderClear,

    assetLibraryAddFileRequest,
    assetLibraryAddFileSuccess,
    assetLibraryAddFileFailure,
    assetLibraryAddFileClear,

    assetLibraryRenameRequest,
    assetLibraryRenameSuccess,
    assetLibraryRenameFailure,
    assetLibraryRenameClear,

    assetLibraryDeleteRequest,
    assetLibraryDeleteSuccess,
    assetLibraryDeleteFailure,
    assetLibraryDeleteClear,

    assetLibraryDownloadRequest,
    assetLibraryDownloadSuccess,
    assetLibraryDownloadFailure,
    assetLibraryDownloadClear,

    assetLibraryPasteRequest,
    assetLibraryPasteSuccess,
    assetLibraryPasteFailure,
    assetLibraryPasteClear,

    assetLibraryMoveRequest,
    assetLibraryMoveSuccess,
    assetLibraryMoveFailure,
    assetLibraryMoveClear,

    assetLibraryFolderNavigateRequest,
    assetLibraryFolderNavigateSuccess,
    assetLibraryFolderNavigateFailure,
    assetLibraryFolderNavigateClear,
    assetLibraryFolderNavigateUpdateBreadcrumb,
    assetLibraryFolderNavigateSelect,
    assetLibraryFolderNavigateSetCurrentDirectory,

    assetLibrarySetBreadcrumb,
    assetLibraryUpdateBreadcrumb,
    assetLibrarySetCurrentDirectory,
    assetLibrarySetSelectedDirectories,
    assetLibraryCopyDirectories,
    
} = createActions({
    [AssetLibraryGetContent.REQUEST]: (payload) => payload,
    [AssetLibraryGetContent.SUCCESS]: (payload) => payload,
    [AssetLibraryGetContent.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryGetContent.CLEAR]: ()=>{},

    [AssetLibraryAddFolder.REQUEST]: (payload) => payload,
    [AssetLibraryAddFolder.SUCCESS]: (payload) => payload,
    [AssetLibraryAddFolder.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryAddFolder.CLEAR]: ()=>{},

    [AssetLibraryAddFile.REQUEST]: (payload) => payload,
    [AssetLibraryAddFile.SUCCESS]: (payload) => payload,
    [AssetLibraryAddFile.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryAddFile.CLEAR]: ()=>{},

    [AssetLibraryRename.REQUEST]: (payload) => payload,
    [AssetLibraryRename.SUCCESS]: (payload) => payload,
    [AssetLibraryRename.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryRename.CLEAR]: ()=>{},

    [AssetLibraryDelete.REQUEST]: (payload) => payload,
    [AssetLibraryDelete.SUCCESS]: (payload) => payload,
    [AssetLibraryDelete.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryDelete.CLEAR]: ()=>{},

    [AssetLibraryDownload.REQUEST]: (payload) => payload,
    [AssetLibraryDownload.SUCCESS]: (payload) => payload,
    [AssetLibraryDownload.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryDownload.CLEAR]: ()=>{},

    [AssetLibraryPaste.REQUEST]: (payload) => payload,
    [AssetLibraryPaste.SUCCESS]: (payload) => payload,
    [AssetLibraryPaste.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryPaste.CLEAR]: ()=>{},

    [AssetLibraryMove.REQUEST]: (payload) => payload,
    [AssetLibraryMove.SUCCESS]: (payload) => payload,
    [AssetLibraryMove.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryMove.CLEAR]: ()=>{},

    [AssetLibraryFolderNavigate.REQUEST]: (payload) => payload,
    [AssetLibraryFolderNavigate.SUCCESS]: (payload) => payload,
    [AssetLibraryFolderNavigate.FAILURE]: ({ message }: any) => ({ error: message }),
    [AssetLibraryFolderNavigate.CLEAR]: ()=>{},
    [AssetLibraryFolderNavigate.UPDATE_BREADCRUMB]: (payload) => payload,
    [AssetLibraryFolderNavigate.SELECT]: (payload) => payload,
    [AssetLibraryFolderNavigate.SET_CURRENT_DIRECTORY]: (payload) => payload,

    [AssetLibraryLocalChanges.SET_BREADCRUMB]: (payload) => payload,
    [AssetLibraryLocalChanges.UPDATE_BREADCRUMB]: (payload) => payload,
    [AssetLibraryLocalChanges.SET_CURRENT_DIRECTORY]: (payload) => payload,
    [AssetLibraryLocalChanges.SET_SELECTED_DIRECTORIES]: (payload) => payload,
    [AssetLibraryLocalChanges.COPY_DIRECTORIES]: (payload) => payload,
})