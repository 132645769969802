import { useEffect, useState } from "react";
import { StyledButton } from "src/styled_components/StyledButton"
import { ButtonProps } from "antd"
import { AsyncStates } from "src/constants"
import { Notification } from "src/store/reducers/notifications"
import { StoreState } from "src/store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import {
	notificationMultipleReadRequest,
	notificationMultipleReadCleanup,
} from "src/store/actions/notifications"
import useTranslate from "src/utils/useTranslate"
import { CheckCircleOutlined } from "@ant-design/icons"

type ButtonStatus =
	| "fetching"
	| "not-resolved"
	| "resolving"
	| "resolved"
	| "empty"
	| "error"

const buttonType: {
	[key in ButtonStatus]: ButtonProps["type"]
} = {
	"fetching": "default",
	"not-resolved": "default",
	"resolving": "default",
	"resolved": "default",
	"empty": "text",
	"error": "default",
}

type P = {
	notificationStatus: AsyncStates
	notifications: Notification[]
}

export const MarkAllRead = ({ notificationStatus, notifications }: P) => {
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const [buttonStatus, setButtonStatus] = useState<ButtonStatus>("fetching")
	const { readMultipleReqStatus: status } = useSelector(
		(state: StoreState) => state.notifications
	)

	function handleMarkAsRead() {
		dispatch(
			notificationMultipleReadRequest({
				notification_ids: notifications
					.filter((noti) => !noti.is_read)
					.map((noti) => noti.notification_id),
			})
		)
	}

	useEffect(() => {
		if (notificationStatus === AsyncStates.LOADING) {
			setButtonStatus("fetching")
		} else if (notificationStatus === AsyncStates.SUCCESS) {
			if (status === AsyncStates.SUCCESS) {
				setButtonStatus("resolved")
				return
			}
			if (status === AsyncStates.ERROR) {
				setButtonStatus("error")
				return
			}

			if (notifications.every(({ is_read }) => is_read)) {
				setButtonStatus("empty")
			} else {
				setButtonStatus("not-resolved")
			}
		} else if (notificationStatus === AsyncStates.ERROR) {
			setButtonStatus("error")
		}
	}, [notifications, notificationStatus, status, dispatch])

	useEffect(() => {
		return () => {
			dispatch(notificationMultipleReadCleanup())
		}
	}, [dispatch])

	return (
    <StyledButton
      loading={buttonStatus === "fetching" || buttonStatus === "resolving"}
      type={buttonType[buttonStatus]}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.4rem",
      }}
      onClick={handleMarkAsRead}
			icon={<CheckCircleOutlined />}
    >
      {buttonStatus === "fetching" && "Fetching Notifications"}
      {buttonStatus === "not-resolved" && (
        <span>{t("notifications.readAllNotifcations")}</span>
      )}
      {buttonStatus === "resolving" && "Updating..."}
      {buttonStatus === "resolved" && (
        <span>{t("notifications.allNotificationsRead")}</span>
      )}
      {buttonStatus === "empty" && (
        <span>{t("notifications.noUnreadNotifications")}</span>
      )}
    </StyledButton>
  );
}
