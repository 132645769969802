import { Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import "./Common.scss";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useEffect } from "react";
import { AsyncStates } from "src/constants";
import {
    createAdditiveRequest,
    fetchCategoryRequest,
    updateAdditiveRequest,
} from "src/store/actions/repository";
import { CATEGORY_TYPES } from "./Data";

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    mode?: "edit" | "create" | "delete";
    selectedAdditiveId?: string;
};

const AddEditAdditiveModal = ({
    showModal,
    setShowModal,
    mode = "create",
    selectedAdditiveId,
}: TProps) => {
    const [t] = useTranslate();
    const [additiveForm] = Form.useForm();
    const additives = useSelector(
        (state: StoreState) => state.repository.additives.data
    );
    const categories = useSelector(
        (state: StoreState) => state.repository.categories.data
    );
    const dispatch = useDispatch();
    const createAdditiveStatus = useSelector(
        (state: StoreState) => state.repository.additives.status
    );

    useEffect(() => {
        dispatch(fetchCategoryRequest({ type: CATEGORY_TYPES.ADDITIVE }));
    }, [dispatch]);

    useEffect(() => {
        if (mode === "edit" && selectedAdditiveId) {
            additiveForm.setFieldsValue({
                name: additives.find((a) => a.additive_id === selectedAdditiveId)?.name,
                category_id: additives.find((a) => a.additive_id === selectedAdditiveId)
                    ?.category_id,
            });
        } else if(mode === "create") {
            additiveForm.resetFields();
        }
    }, [selectedAdditiveId, additives, mode, additiveForm]);

    useEffect(() => {
        if (createAdditiveStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [createAdditiveStatus, setShowModal]);

    const submit = () => {
        const payload = additiveForm.getFieldsValue();

        if (mode === "create") dispatch(createAdditiveRequest(payload));
        if (mode === "edit")
            dispatch(
                updateAdditiveRequest({ ...payload, additive_id: selectedAdditiveId })
            );
    };

    return (
        <Modal
            title={<Typography.Title level={4}>{`${mode === "create" ? t("repository.addNewAdditives"): t("repository.editAdditives")}`}</Typography.Title>}
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
            rootClassName="repository-custom-modal"
        >
            <Form
                onFinish={submit}
                form={additiveForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name="name"
                    label={t("common.fieldName")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder={t("common.enterName")} />
                </Form.Item>

                <Form.Item
                    name="category_id"
                    label={t("common.category")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        loading={false}
                        placeholder={t("common.select")}
                        allowClear
                        showSearch={true}
                        optionFilterProp="label"
                        options={categories.filter(c => c.name).sort((a, b) => a.name.localeCompare(b.name)).map((c) => ({ label: c.name, value: c.category_id }))}
                    />
                </Form.Item>

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton
                            type="default"
                            onClick={() => additiveForm.resetFields()}
                        >
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={createAdditiveStatus === AsyncStates.LOADING}
                            loading={createAdditiveStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditAdditiveModal;
