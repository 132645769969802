import {
    EditOutlined,
    LoadingOutlined,
    MoreOutlined,
    PaperClipOutlined,
} from "@ant-design/icons";
import { Collapse, Dropdown, Empty, Spin, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import useTranslate from "src/utils/useTranslate";

import { useDispatch, useSelector } from "react-redux";
import {
    fetchPropertyUnitsRequest,
    markPropertyUnitDefaultRequest,
} from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import AddEditPropertyUnitsModal from "../Common/AddEditPropertyUnitsModal";

type TProps = {
    selectedProperty: string;
};

const PropertyUnits = ({ selectedProperty }: TProps) => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [refetchPropertyUnits, setRefetchPropertyUnits] = useState(false);
    const propertyStatus = useSelector(
        (state: StoreState) => state.repository.properties.status
    );
    const propertyUnitsStatus = useSelector(
        (state: StoreState) => state.repository.propertyUnits.status
    );
    const propertyUnitsData = useSelector(
        (state: StoreState) => state.repository.propertyUnits.data
    );
    const dispatch = useDispatch();

    useEffect(() => {
        // Refetch units after action (e.g. Mark Default)
        if (
            propertyUnitsStatus === AsyncStates.SUCCESS &&
            propertyStatus === AsyncStates.SUCCESS &&
            refetchPropertyUnits
        ) {
            dispatch(fetchPropertyUnitsRequest({ property_id: selectedProperty }));
            setRefetchPropertyUnits(false);
        }
    }, [
        selectedProperty,
        propertyStatus,
        dispatch,
        refetchPropertyUnits,
        propertyUnitsStatus,
    ]);

    const takeAction = useCallback(
        (action: string, unit: string) => {
            if (action === "setDefault") {
                const payload = { property_id: selectedProperty, unit };
                dispatch(markPropertyUnitDefaultRequest(payload));
                setRefetchPropertyUnits(true);
            }
        },
        [dispatch, selectedProperty]
    );

    const dropdownMenu = useCallback(() => {
        return [
            {
                label: (
                    <div>
                        <PaperClipOutlined /> {t("respository.markAsDefault")}
                    </div>
                ),
                key: "setDefault",
            },
        ];
    }, [t]);

    return (
        <div className="property-unit-container">
            <Spin
                spinning={propertyUnitsStatus === AsyncStates.LOADING}
                indicator={<LoadingOutlined />}
            >
                <Collapse
                    defaultActiveKey={["1"]}
                    items={[
                        {
                            key: "1",
                            showArrow: false,
                            collapsible: "icon",
                            label: (
                                <div className="collapse-header-container">
                                    <span className="collapse-header">{t("common.units")}</span>
                                    <span
                                        className="add-new-cta"
                                        onClick={() => setShowModal(true)}
                                    >
                                        <EditOutlined /> {t("common.update")}
                                    </span>
                                </div>
                            ),
                            children: (
                                <>
                                    {propertyUnitsData?.units?.length === 0 &&
                                        propertyUnitsStatus === AsyncStates.SUCCESS && (
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        )}

                                    {propertyUnitsData?.units?.map((unit) => (
                                        <div key={unit} className="unit-list">
                                            <span>
                                                {unit}
                                                {unit === propertyUnitsData?.default_unit && (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        <Tag> {t("common.default")}</Tag>
                                                    </>
                                                )}
                                            </span>
                                            {unit !== propertyUnitsData?.default_unit && (
                                                <Dropdown
                                                    menu={{
                                                        items: dropdownMenu(),
                                                        onClick: (event) => takeAction(event.key, unit),
                                                    }}
                                                    trigger={["click"]}
                                                >
                                                    <MoreOutlined />
                                                </Dropdown>
                                            )}
                                        </div>
                                    ))}
                                </>
                            ),
                        },
                    ]}
                />
            </Spin>
            <AddEditPropertyUnitsModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedPropertyId={selectedProperty}
                setRefetchPropertyUnits={setRefetchPropertyUnits}
            />
        </div>
    );
};

export default PropertyUnits;
