import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLinkedExperimentDetailsRequest } from 'src/store/actions/experiment'
import { StoreState } from 'src/store/configureStore'
import { usePermission } from 'src/utils/usePermissions'
import { WorkOrderTableWrapper } from '../WorkOrderDetails/WorkOrderTableWrapper'


export const TrialDetails = ({ trialId, trialType, from, filters }: any) => {
    const dispatch = useDispatch()
    const trialSelector = useCallback((state: StoreState) => trialType === "linked" ? state.workOrderDetails.linkedExperimentDetails : state.workOrderDetails.experiment, [trialType])
    const configs = useSelector((state: StoreState) => state.configs.features)
    const trials = useSelector(trialSelector)
    const formulationList = useMemo(() => trials.filter((trial: any) => trial?.id_set?.formulation_id === trialId), [trialId, trials])
    const userAccess = usePermission()

    useEffect(() => {
        if (Boolean(configs?.nestle_configs) && !!formulationList.length) {
            dispatch(fetchLinkedExperimentDetailsRequest({ formulation_merge_id: [...new Set(formulationList.map((res: any) => res?.formulation_merge_id))] }))
        }
    }, [configs, dispatch, formulationList])

    return (
        <WorkOrderTableWrapper
            disabled={true}
            formulationList={formulationList}
            from={from}
            filters={filters}
            userAccess={userAccess}
            tableId={"trial_details"}
        />
    )
}
