
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Zentrales Komposit`}</h1>
    <p>{`Ein `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Zentrales_Kompositdesign"
      }}>{`Zentrales Komposit Design`}</a>{` nach Box-Wilson, oft einfach als 'zentrales Kompositdesign' bezeichnet, ist das am häufigsten verwendete Versuchsdesign zur Erstellung von Response-Oberflächen. Zentrale Kompositdesigns sind ein faktorieller oder fraktorieller Versuchsplan mit Zentrumspunkten, ergänzt um eine Gruppe von axialen Punkten (auch Sternpunkte genannt), die es ermöglichen, die Krümmung zu schätzen. Mit einem zentralen Kompositdesign können Sie Folgendes durchführen:`}</p>
    <ul>
      <li parentName="ul">{`Effiziente Schätzung von Termen erster und zweiter Ordnung.`}</li>
      <li parentName="ul">{`Modellierung einer Antwortvariablen mit Krümmung, indem Sie Zentrumspunkte und axiale Punkte zu einem zuvor durchgeführten faktoriellen Design hinzufügen.`}</li>
    </ul>
    <p>{`Zentrale Kompositdesigns können ein vollständiges quadratisches Modell anpassen. Sie werden oft verwendet, wenn der Versuchsplan sequentielles Experimentieren erfordert, da diese Designs Informationen aus einem korrekt geplanten faktoriellen Experiment einschließen können.`}</p>
    <p>{`Wenn möglich, haben Zentrale Kompositdesigns die gewünschten Eigenschaften orthogonalen Blocks und Rotierbarkeit.`}</p>
    <p><strong parentName="p">{`Orthogonale Blocks:`}</strong></p>
    <p>{`Häufig werden zentrale Kompositdesigns in mehr als einem Block durchgeführt. Zentrale Kompositdesigns können orthogonale Blocks erstellen, wodurch Modellterme und Blockeffekte unabhängig voneinander geschätzt und die Variation in den Regressionskoeffizienten minimiert werden kann.`}</p>
    <p><strong parentName="p">{`Rotierbarkeit:`}</strong></p>
    <p>{`Rotierbare Designs bieten eine konstante Vorhersagevarianz an allen Punkten, die vom Designzentrum gleich weit entfernt sind.`}</p>
    <p><strong parentName="p">{`Anwendungsfälle:`}</strong></p>
    <ul>
      <li parentName="ul">{`Prozessoptimierung`}</li>
      <li parentName="ul">{`Sequenzielles Experimentieren`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;