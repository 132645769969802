import { handleActions } from "redux-actions";
import { CompareFormulationsActionTypes, LinkedCompareFormulationsActionTypes, ReportDeleteActionTypes, ReportShareActionTypes, ReportsRetrieveActionTypes, ReportUploadActionTypes, TemplateCreateActionTypes, TemplateDeleteActionTypes, TemplateListActionTypes, TemplateUpdateActionTypes } from "../actions/compareFormulations";
import { AsyncStates } from "../../constants";
export type CompareFormulationState = {
  status: AsyncStates;
  retrieveStatus: AsyncStates;
  uploadStatus: AsyncStates;
  shareStatus: AsyncStates;
  deleteStatus: AsyncStates;
  error: string;
  retrieveError: string;
  uploadError: string;
  shareError: string;
  deleteError: string;
  formulationDetailsData: any[];
  linkedFormulationDetailsData: any[],
  linkedError: string,
  linkedStatus: AsyncStates,
  reports: any[],
  lists: any[],
  createStatus: AsyncStates,
  templateDeleteStatus: AsyncStates,
  updateStatus: AsyncStates,
  listStatus: AsyncStates
};
const defaultState: CompareFormulationState = {
  status: AsyncStates.INITIAL,
  retrieveStatus: AsyncStates.INITIAL,
  uploadStatus: AsyncStates.INITIAL,
  shareStatus: AsyncStates.INITIAL,
  deleteStatus: AsyncStates.INITIAL,
  error: "",
  retrieveError: "",
  uploadError: "",
  shareError: "",
  deleteError: "",
  formulationDetailsData: [],
  linkedFormulationDetailsData: [],
  linkedError: "",
  linkedStatus: AsyncStates.INITIAL,
  reports: [],
  lists: [],
  createStatus: AsyncStates.INITIAL,
  templateDeleteStatus: AsyncStates.INITIAL,
  updateStatus: AsyncStates.INITIAL,
  listStatus: AsyncStates.INITIAL
};

const compareFormulationsReducer = handleActions<CompareFormulationState, any>(
  {
    [CompareFormulationsActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
    }),
    [CompareFormulationsActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        formulationDetailsData: action.payload.formulationDetailsData,
      };
    },
    [CompareFormulationsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [LinkedCompareFormulationsActionTypes.REQUEST]: (state) => ({
      ...state,
      linkedStatus: AsyncStates.LOADING,
      linkedError: "",
    }),
    [LinkedCompareFormulationsActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        linkedStatus: AsyncStates.SUCCESS,
        linkedError: "",
        linkedFormulationDetailsData: action.payload.formulationDetailsData,
      };
    },
    [LinkedCompareFormulationsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      linkedStatus: AsyncStates.ERROR,
      linkedError: action.payload.error,
    }),
    [ReportUploadActionTypes.REQUEST]: (state) => ({
      ...state,
      uploadStatus: AsyncStates.LOADING,
      uploadError: "",
    }),
    [ReportUploadActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        uploadStatus: AsyncStates.SUCCESS,
        uploadError: "",
      };
    },
    [ReportUploadActionTypes.FAILURE]: (state, action) => ({
      ...state,
      uploadStatus: AsyncStates.ERROR,
      uploadError: action.payload,
    }),
    [ReportShareActionTypes.REQUEST]: (state) => ({
      ...state,
      shareStatus: AsyncStates.LOADING,
      shareError: "",
    }),
    [ReportShareActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        shareStatus: AsyncStates.SUCCESS,
        shareError: "",
      };
    },
    [ReportShareActionTypes.FAILURE]: (state, action) => ({
      ...state,
      shareStatus: AsyncStates.ERROR,
      shareError: action.payload,
    }),
    [ReportDeleteActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteStatus: AsyncStates.LOADING,
      deleteError: "",
    }),
    [ReportDeleteActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        deleteStatus: AsyncStates.SUCCESS,
        deleteError: "",
      };
    },
    [ReportDeleteActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteStatus: AsyncStates.ERROR,
      deleteError: action.payload,
    }),
    [ReportsRetrieveActionTypes.REQUEST]: (state) => ({
      ...state,
      retrieveStatus: AsyncStates.LOADING,
      retrieveError: "",
    }),
    [ReportsRetrieveActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        retrieveStatus: AsyncStates.SUCCESS,
        retrieveError: "",
        reports: action.payload.reports.reverse(),
      };
    },
    [ReportsRetrieveActionTypes.FAILURE]: (state, action) => ({
      ...state,
      retrieveStatus: AsyncStates.ERROR,
      retrieveError: action.payload.error,
    }),
    [TemplateListActionTypes.REQUEST]: (state) => ({
      ...state,
      listStatus: AsyncStates.LOADING,
      listError: "",
    }),
    [TemplateListActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        listStatus: AsyncStates.SUCCESS,
        listError: "",
        lists: action.payload,
      };
    },
    [TemplateListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      listStatus: AsyncStates.ERROR,
      listError: action.payload.error,
    }),
    [TemplateCreateActionTypes.REQUEST]: (state) => ({
      ...state,
      createStatus: AsyncStates.LOADING,
      createError: "",
    }),
    [TemplateCreateActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        createStatus: AsyncStates.SUCCESS,
        createError: "",
        create: action.payload,
      };
    },
    [TemplateCreateActionTypes.FAILURE]: (state, action) => ({
      ...state,
      createStatus: AsyncStates.ERROR,
      createError: action.payload.error,
    }),
    [TemplateCreateActionTypes.CLEANUP]: (state) => ({
      ...state,
      createStatus: AsyncStates.INITIAL,
    }),
    [TemplateUpdateActionTypes.REQUEST]: (state) => ({
      ...state,
      updateStatus: AsyncStates.LOADING,
      updateError: "",
    }),
    [TemplateUpdateActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        updateStatus: AsyncStates.SUCCESS,
        updateError: "",
        update: action.payload,
      };
    },
    [TemplateUpdateActionTypes.FAILURE]: (state, action) => ({
      ...state,
      updateStatus: AsyncStates.ERROR,
      updateError: action.payload.error,
    }),
    [TemplateDeleteActionTypes.REQUEST]: (state) => ({
      ...state,
      templateDeleteStatus: AsyncStates.LOADING,
      templateDeleteError: "",
    }),
    [TemplateDeleteActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        templateDeleteStatus: AsyncStates.SUCCESS,
        templateDeleteError: "",
        templateDelete: action.payload,
      };
    },
    [TemplateDeleteActionTypes.FAILURE]: (state, action) => ({
      ...state,
      templateDeleteStatus: AsyncStates.ERROR,
      templateDeleteError: action.payload.error,
    }),
    [CompareFormulationsActionTypes.CLEANUP]: (state) => ({
      ...state,
      retrieveStatus: AsyncStates.INITIAL,
      retrieveError: "",
      formulationDetailsData: [],
    }),
  },
  defaultState
);

export default compareFormulationsReducer;
