import React from 'react'
import { BORDER_STYLE, TEXT_PRIMARY } from '../Dashboard';
import { Typography } from 'antd'
import useTranslate from 'src/utils/useTranslate'
import { motion } from 'framer-motion'
import CardSection from './CardSection';
import NotificationSection from './NotificationSection';
import RecentEdits from './RecentEdits';

const RightSidebar = () => {
  return (
    <motion.div
    initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
      style={{
        width: '35%',
        outline: BORDER_STYLE,
        display: 'flex',
        flexDirection: 'column',
        overflowY: "auto",
        scrollbarWidth: 'none',
      }}
    >
      <SectionHeader />
      <CardSection/>
      <RecentEdits/>
      <NotificationSection/>
    </motion.div>
  )
}

export default RightSidebar

const SectionHeader = () => {
  // Utility hooks
  const [t] = useTranslate()

  return (
    <div
      style={{
        padding: '12px 12px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '60px',
        outline: BORDER_STYLE
      }}
    >
      <Typography.Title
        style={{
          color: `${TEXT_PRIMARY}`,
          lineHeight: '16px',
          margin: 0
        }}
        level={5}
      >
        {t('common.myActivities')}
      </Typography.Title>
    </div>
  )
}


