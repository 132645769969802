import { Col, Drawer, Row } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../../Shared/SEMAnalysis.scss";
import PaintFilmAnalysisDetailsContent from "./PaintFilmAnalysisDetailsContent";
import PaintFilmAnalysisDetailsSidebar from "./PaintFilmAnalysisDetailsSidebar";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSemAnalysisMaskDataRequest,
  fetchPaintFilmBatchInfoRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import PaintFilmAnalysisAttributes from "./PaintFilmAnalysisAttributes";
import { AsyncStates } from "src/constants";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";
import { StyledButton } from "src/styled_components/StyledButton";

const PaintFilmAnalysisDetails = () => {
  const location = useLocation<any>();
  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [minDiameter, setMinDiameter] = useState(0);
  const [maxDiameter, setMaxDiameter] = useState(100);
  const [diameterRangeThreshold, setDiameterRangeThreshold] = useState([
    minDiameter,
    maxDiameter,
  ]);
  const [showAllMasks, setShowAllMasks] = useState(location.state?.showAllMasks ?? true);
  const [showBBox, setShowBBox] = useState(location.state?.showBBox ?? true);
  const [showOriginalImage, setShowOriginalImage] = useState(location.state?.showOriginalImage ?? true);
  const {
    semAnalysisMaskData,
    semAnalysisMaskDataStatus,
    paintFilmBatchInfo,
    analysisTypes,
    modifyPaintFilmStatus
  } = useSelector((state: StoreState) => state.semAnalysis);

  const fetchMasks = useCallback(() => {
    dispatch(
      fetchSemAnalysisMaskDataRequest({
        file_id: params.fileId,
        batch_id: params.batchId,
        analysis_type:
          analysisTypes[
          IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
          ],
      })
    );
  }, [analysisTypes, dispatch, params.batchId, params.fileId])

  useLayoutEffect(() => {
    fetchMasks();
  }, [fetchMasks]);

  useEffect(() => {
    if (modifyPaintFilmStatus === AsyncStates.SUCCESS) fetchMasks();
  }, [fetchMasks, modifyPaintFilmStatus]);

  useEffect(() => {
    if (semAnalysisMaskDataStatus === AsyncStates.SUCCESS) {
      const diameters = semAnalysisMaskData?.mask_results?.map(
        (item: any) =>
          item.calibrated_length?.value.replace(/[^0-9.]/g, "").trim() ||
          item.max_length?.pixel_length
      ) || [0];
      setMinDiameter(Math.min(...diameters));
      setMaxDiameter(Math.max(...diameters));
    }
  }, [semAnalysisMaskDataStatus, semAnalysisMaskData]);

  useEffect(() => {
    if (
      !paintFilmBatchInfo ||
      (Array.isArray(paintFilmBatchInfo) && paintFilmBatchInfo.length === 0)
    ) {
      dispatch(fetchPaintFilmBatchInfoRequest({
        batch_id: params?.batchId,
        analysis_type:
          analysisTypes[
          IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
          ]
      }));
    }
  }, [paintFilmBatchInfo, dispatch, params?.batchId, analysisTypes]);

  const maskData = useMemo(() => {
    return semAnalysisMaskData?.mask_results?.filter((item: any) => {
      const diameter =
        item.calibrated_length?.value.replace(/[^0-9.]/g, "").trim() ||
        item.max_length?.pixel_length;

      return (
        diameter >= diameterRangeThreshold[0] &&
        diameter <= diameterRangeThreshold[1]
      );
    });
  }, [semAnalysisMaskData, diameterRangeThreshold]);

  const imageToShow = useMemo(() => {
    return showOriginalImage
      ? semAnalysisMaskData?.original_image_url
      : semAnalysisMaskData?.binary_image_url;
  }, [semAnalysisMaskData, showOriginalImage]);

  const files = useMemo(() => {
    if (
      paintFilmBatchInfo &&
      Array.isArray(paintFilmBatchInfo) &&
      paintFilmBatchInfo.length > 0
    ) {
      const currentBatch = paintFilmBatchInfo.find((batch) => batch?.batch_id);

      return currentBatch?.thumbnails || [];
    }

    return [];
  }, [paintFilmBatchInfo]);

  const onDrawerClose = () =>
    history.push(
      `/sem-analysis/${IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS}/batch/${params.batchId}`
    );

  const goNext = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    const nextFileId = files[currentFileIndex + 1]?.file_id;

    history.push({
      pathname: `/sem-analysis/${IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS}/batch/${params.batchId}/files/${nextFileId}`,
      state: { showAllMasks, showBBox, showOriginalImage },
    });
  };

  const goPrevious = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    const prevFileId = files[currentFileIndex - 1]?.file_id;

    history.push({
      pathname: `/sem-analysis/${IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS}/batch/${params.batchId}/files/${prevFileId}`,
      state: { showAllMasks, showBBox, showOriginalImage },
    });
  };

  const hasPreviousFile = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    return currentFileIndex <= 0 ? false : true;
  };

  const hasNextFile = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    return currentFileIndex >= files.length - 1 ? false : true;
  };

  return (
    <Drawer
      title={"Paint Film Analysis"}
      placement="bottom"
      open={true}
      height="100%"
      styles={{
        body: { padding: 0 },
      }}
      onClose={onDrawerClose}
      className="paint-film-analysis-drawer"
      extra={
        <>
          {files && files.length > 0 && (
            <Row gutter={[8, 8]}>
              <Col>
                <StyledButton
                  type="link"
                  disabled={!hasPreviousFile()}
                  onClick={goPrevious}
                >
                  <LeftOutlined /> Previous Image
                </StyledButton>
              </Col>
              <Col>
                <StyledButton type="link" disabled={!hasNextFile()} onClick={goNext}>
                  Next Image <RightOutlined />
                </StyledButton>
              </Col>
            </Row>
          )}
        </>
      }
    >
      <div className="container">
        <PaintFilmAnalysisDetailsSidebar
          showOriginalImage={showOriginalImage}
          setShowOriginalImage={setShowOriginalImage}
          minDiameter={minDiameter}
          maxDiameter={maxDiameter}
          setDiameterRangeThreshold={setDiameterRangeThreshold}
          showAllMasks={showAllMasks}
          setShowAllMasks={setShowAllMasks}
          showBBox={showBBox}
          setShowBBox={setShowBBox}
          files={files}
        />
        <PaintFilmAnalysisDetailsContent
          showAllMasks={showAllMasks}
          filteredData={maskData}
          image={imageToShow}
          showBBox={showBBox}
        />
      </div>
      <div className="info-container">
        <PaintFilmAnalysisAttributes data={semAnalysisMaskData} />
      </div>
    </Drawer>
  );
};

export default PaintFilmAnalysisDetails;
