import { Modal, Progress, Table, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react'
import { QualityCheckStatus } from '..'
import useTranslate from 'src/utils/useTranslate'
import dayjs from 'dayjs'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { antdTheme, AsyncStates } from 'src/constants'
import Status from './Status'
import TestResult from './TestResult'
import {
  qcAddIgnoreFilesForReport,
  qcDeleteFileRequest,
  qcFileExtractionsRequest,
  qcLocalUpdateFile,
  qcRemoveIgnoreFilesForReport,
  qcSetFileDrawerVisible,
  qcSetSelectAllFiles,
  qcSetSelectedFile,
  qcSetSelectedFilesForReport
} from 'src/store/actions/qualityCheck'
import TopHeader from './TopHeader'
import {
  firestoreDb,
  FB_COLLECTION_QC_PROGRESS_TRACKER
} from 'src/utils/firebase'
import { Unsubscribe, doc, onSnapshot } from 'firebase/firestore'
import SelectionDrawer from './SelectionDrawer'
import PaginationTable from './PaginationTable'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton'

const TEXT_PRIMARY = '#171725'

export const modalProps = {
  okButtonProps: {
    style: {
      outline: 'none',
      fontWeight: 600,
    }
  },
  cancelButtonProps: {
    style: {
      outline: 'none',
      fontWeight: 600,
    }
  },
  styles: {
    header: {
      fontSize: antdTheme.fontSizeLG,
      fontWeight: 600
    },
    content: {
      fontSize: antdTheme.fontSize
    }
  }
}

const textStyles: React.CSSProperties = {
  color: `${TEXT_PRIMARY}`,
  margin: 0,
  width: '100%',
  verticalAlign: 'middle'
}

export const StyledText = (text: string) => {
  return (
    <Typography.Text
      style={textStyles}
      ellipsis={{
        tooltip: text
      }}
      strong
    >
      {text}
    </Typography.Text>
  )
}

const FilesTable = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const {
    getFilesData,
    getFilesDataStatus,
    getFilesPageNumber,
    filesToReport,
    getFilesTotal,
    isSelectAllFiles,
    filesToIgnore
  } = useSelector((state: StoreState) => state.qualityCheck)

  const columns = useMemo(() => {
    return [
      {
        title: t('common.fileName'),
        dataIndex: 'file_name',
        key: 'file_name',
        width: '35%',
        render: (text: string) => StyledText(text)
      },

      {
        title: t('common.status'),
        dataIndex: 'qc_details',
        key: 'qc_details',
        width: '5%',
        render: (qc_details: any, record: any) => <Status qc_details={qc_details} status={record.status} />
      },
      {
        title: t('common.tests'),
        dataIndex: 'tests',
        key: 'tests',
        width: '20%',
        render: (tests: string, record: any) => (
          <StatusComponent
            qc_details={record.qc_details}
            status={record.status}
            percentage={record.percentage || 0}
            file_id={record.file_id}
          />
        )
      },
      {
        title: t('common.Category'),
        dataIndex: 'category',
        key: 'category',
        width: '10%',
        render: (category: any) => StyledText(category || '')
      },
      {
        title: t('common.Supplier'),
        dataIndex: 'supplier',
        key: 'supplier',
        width: '10%',
        render: (supplier: any) => StyledText(supplier || '')
      },
      {
        title: t('common.addedOn'),
        dataIndex: 'created_at',
        key: 'created_at',
        width: '12%',
        render: (created_at: string) =>
          StyledText(dayjs(created_at).format('DD MMM YYYY HH:mm'))
      },
      {
        dataIndex: 'actions',
        key: 'actions',
        width: '8%',
        render: (value: any, row: any) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <StyledButton
              icon={<DownloadOutlined />}
              type="default"
              style={{
                outline: 'none',
                border: 'none',
                padding: '0px 5px',
                background: 'transparent'
              }}
              onClick={(e) => {
                e.stopPropagation()
                window.open(row?.s3_link, '_blank')
              }}
              size="small"
            />
            <StyledButton
              icon={
                <DeleteOutlined
                  style={{
                    color: 'red'
                  }}
                />
              }
              style={{
                outline: 'none',
                border: 'none',
                padding: '0px 5px',
                background: 'transparent'
              }}
              type="default"
              onClick={(e) => {
                e.stopPropagation()
                Modal.confirm({
                  title: `${t('common.delete')} ${row?.file_name} ?`,
                  icon: (
                    <StyledDeleteIcon
                      style={{
                        fontSize: antdTheme.fontSizeHeading4,
                        color: '#FF4D4F',
                        marginTop:4
                      }}
                    />
                  ),
                  okText: t('common.confirm'),
                  cancelText: t('common.cancel'),
                  onOk: () => {
                    dispatch(qcDeleteFileRequest({ file_ids: [row?.file_id] }))
                  },
                  onCancel: () => {},
                  ...modalProps,
                  centered: true,
                  type: 'confirm'
                })
              }}
              size="small"
            />
          </div>
        )
      }
    ]
  }, [dispatch, t])

  const selectedFiles = useMemo(
    () => Object.values(filesToReport).flat(),
    [filesToReport]
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <TopHeader />

      <Table
        columns={columns}
        dataSource={getFilesData}
        className="quality-check-files-table"
        loading={getFilesDataStatus === AsyncStates.LOADING}
        tableLayout="fixed"
        pagination={false}
        scroll={{ y: selectedFiles.length > 0 ? '82%' : '90%', scrollToFirstRowOnChange: true }}
        style={{
          height: selectedFiles.length > 0 ? 'calc(75% - 24px)' : '85%',
          overflowY: 'hidden',
          transition: 'height 0.5s'
        }}
        rowSelection={{
          type: 'checkbox',
          columnWidth: 30,
          onSelect: (record, selected) => {
            if(isSelectAllFiles && !selected) {
              dispatch(qcAddIgnoreFilesForReport(record.file_id))
            }

            if(selected) {
              dispatch(qcRemoveIgnoreFilesForReport(record.file_id))
            }
          },
          onChange: (selectedRowKeys, _, info) => {
            if(info?.type==='all' && selectedRowKeys.length===0 && isSelectAllFiles) {
              dispatch(qcSetSelectAllFiles(false))
            }
            dispatch(qcSetSelectedFilesForReport(selectedRowKeys))
          },
          selectedRowKeys: filesToReport[getFilesPageNumber]?.filter((file_id:string)=>!filesToIgnore?.includes(file_id)) || [],
          getCheckboxProps: (record: any) => ({
            // disabled: record.status !== QualityCheckStatus.COMPLETED
          }),
        }}
        rowKey={(record) => record.file_id}
        onRow={(record) => {
          return {
            onClick: () => {
              dispatch(qcSetSelectedFile(record?.file_id))
              dispatch(
                qcFileExtractionsRequest({
                  file_id: record?.file_id
                })
              )
              dispatch(qcSetFileDrawerVisible(true))
            },
            style: { cursor: 'pointer' }
          }
        }}
        title={() => (
          selectedFiles?.length>0?<Typography.Text
            style={{
              color: TEXT_PRIMARY,
              margin: 0,
              padding: 0
            }}
          >
            {`${isSelectAllFiles && (filesToIgnore || []).length === 0 ? t('common.Selected') : t('common.select')} ${t('common.all')} ${getFilesTotal} ${t('common.File')}`}
            {isSelectAllFiles && (
              <StyledButton
                type="link"
                onClick={() => {
                  dispatch(qcSetSelectAllFiles(false))
                }}
              >
                {t('common.clearAll')}
              </StyledButton>
            ) }
            { (!isSelectAllFiles || (filesToIgnore || []).length > 0) &&
              <StyledButton
                type="link"
                onClick={() => {
                  if(getFilesData?.length) {
                    dispatch(
                      qcSetSelectedFilesForReport(
                        getFilesData?.map((file) => file.file_id)
                      )
                    )
                    dispatch(qcSetSelectAllFiles(true))
                  }
                }}
              >
                {t('common.selectAll')}
              </StyledButton>
            }
          </Typography.Text>:null
        )}
      />

      <PaginationTable />

      <SelectionDrawer />
    </div>
  )
}

export default FilesTable

const StatusComponent = ({ qc_details, status, percentage, file_id }: any) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  useEffect(() => {
    let unsub: Unsubscribe | undefined
    if (status !== QualityCheckStatus.COMPLETED) {
      unsub = onSnapshot(
        doc(firestoreDb, FB_COLLECTION_QC_PROGRESS_TRACKER, file_id),
        (doc) => {
          if (doc.exists()) {
            dispatch(
              qcLocalUpdateFile({
                file_id: file_id,
                to_update: doc.data()
              })
            )
          }
        }
      )
    }
    return () => {
      unsub && unsub()
    }
  }, [dispatch, file_id, status])

  const getStatusProgressBar = ({ status, percentage }: any) => {
    const progressStyle: React.CSSProperties = {
      paddingRight: 6,
      fontSize: antdTheme.fontSizeSM,
      marginBottom: 0,
      width: '68%',
      marginInlineEnd: 0
    }
    const textStyle: React.CSSProperties = {
      fontSize: antdTheme.fontSizeSM,
      color: '#8C8C8C',
      width: '30%'
    }
    const spaceStyle: React.CSSProperties = {
      width: '100%',
      display: 'flex',
      gap: '2%',
      flex: 1,
      marginRight: 10
    }

    const getStatusText = (status: string) => {
      switch (status) {
        case QualityCheckStatus.UPLOADED:
          return `${t('common.processing')}...`
        case QualityCheckStatus.METADATA_EXTRACTED:
          return `${t('common.extracting')}...`
        case QualityCheckStatus.TABLE_EXTRACTED:
          return `${t('common.runningQc')}...`
        case QualityCheckStatus.COMPLETED:
          return `${t('common.completed')}`
        default:
          return ''
      }
    }

    return (
      <div style={spaceStyle}>
        <Typography.Text style={textStyle} ellipsis>
          {getStatusText(status)}
        </Typography.Text>
        <Progress
          percent={percentage}
          status="active"
          style={progressStyle}
          className="quality-check-progress"
        />
      </div>
    )
  }

  return Object.keys(qc_details || {}).length &&
    status === QualityCheckStatus.COMPLETED ? (
    <TestResult qc_details={qc_details} />
  ) : (
    getStatusProgressBar({ status, percentage })
  )
}
