import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import { ActionTypes } from "../utils";

interface NumberCellProps {
  initialValue: number;
  columnId: string;
  rowIndex: number;
  dataDispatch: React.Dispatch<any>;
  allowEditing: boolean;
}

export default function NumberCell({
  initialValue,
  columnId,
  rowIndex,
  dataDispatch,
  allowEditing,
}: NumberCellProps) {
  const [value, setValue] = useState<{ value: number; update: boolean }>({
    value: initialValue,
    update: false,
  });

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue({ value: parseFloat(e.target.value), update: false });
  }

  function onBlur() {
    setValue((old) => ({ value: old.value, update: true }));
  }

  useEffect(() => {
    setValue({ value: initialValue, update: false });
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({
        type: ActionTypes.UPDATE_CELL,
        columnId,
        rowIndex,
        value: value.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.update, columnId, rowIndex]);

  return (
    <ContentEditable
      html={(value.value && value.value.toString()) || ""}
      onChange={onChange}
      onBlur={onBlur}
      className="data-input text-align-right"
      contentEditable={allowEditing}
    />
  );
}
