import {
    DeleteOutlined,
    EditOutlined,
    MoreOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Collapse, Dropdown, Space, Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import useTranslate from "src/utils/useTranslate";
import RepositoryActionDrawer from "../Common/RepositoryActionDrawer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { removeParametersRequest } from "src/store/actions/repository";

type TProps = {
    searchTerm: string | undefined;
    showCreateParameterModal: () => void;
    showEditParameterModal: (parameter_id: string) => void;
};

const ParameterDetails = ({
    searchTerm,
    showCreateParameterModal,
    showEditParameterModal,
}: TProps) => {
    const [t] = useTranslate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState<any[]>([]);
    const parameters = useSelector(
        (state: StoreState) => state.repository.parameters.data
    );
    const dispatch = useDispatch();
    const properties = useSelector(
        (state: StoreState) => state.repository.properties.data
    );

    useEffect(() => {
        if (selectedParameters.length > 0) setDrawerOpen(true);
        else setDrawerOpen(false);
    }, [selectedParameters]);

    useEffect(() => {
        // Update selected parameters when changed
        const availableParameters = parameters.map((p) => p.parameter_id);
        setSelectedParameters((prev) =>
            prev.filter((p) => availableParameters.includes(p))
        );
    }, [parameters]);

    const onRowSelection = (
        selectedRowKeys: React.Key[],
        selectedRows: any[]
    ) => {
        setSelectedParameters(selectedRowKeys);
    };

    const deleteParameters = useCallback(
        (ids?: string[]) => {
            dispatch(
                removeParametersRequest({
                    parameter_ids: ids ? ids : selectedParameters,
                })
            );
        },
        [dispatch, selectedParameters]
    );

    const takeAction = useCallback(
        (action: string, record: any) => {
            if (action === "edit") showEditParameterModal(record.key);
            if (action === "delete") deleteParameters([record.key]);
        },
        [deleteParameters, showEditParameterModal]
    );

    const onDismiss = () => {
        setSelectedParameters([]);
        setDrawerOpen(false);
    }

    const columns: any = useMemo(
        () => [
            {
                title: t("common.Name"),
                dataIndex: "name",
                key: "name",
                width: 200,
            },
            {
                title: t("common.property"),
                dataIndex: "property",
                key: "property",
            },
            {
                title: "",
                dataIndex: "",
                key: "action",
                fixed: "right",
                width: 40,
                render: (record: any) => (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: (
                                        <div>
                                            <EditOutlined /> {t("common.edit")}
                                        </div>
                                    ),
                                    key: "edit",
                                },
                                {
                                    label: (
                                        <div style={{ color: "#F5222D" }}>
                                            <DeleteOutlined /> {t("common.delete")}
                                        </div>
                                    ),
                                    key: "delete",
                                },
                            ],
                            onClick: (event) => takeAction(event.key, record),
                        }}
                        trigger={["click"]}
                    >
                        <MoreOutlined />
                    </Dropdown>
                ),
            },
        ],
        [takeAction, t]
    );

    const groupedData = useMemo(() => {
        const filteredData = searchTerm
            ? parameters.filter((p) =>
                JSON.stringify(Object.values(p))
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
            : parameters;

        const parameterCategories = [
            ...new Set(filteredData.map((p) => p.category_name)),
        ].sort((a, b) => a.localeCompare(b));

        return parameterCategories.map((pc) => ({
            key: pc,
            name: pc,
            tableData: filteredData.filter(f => f.category_name === pc).map((t) => ({
                key: t.parameter_id,
                name: t.parameter,
                property:
                    properties.find((p) => p.property_id === t.property_id)?.name || "",
            })).sort((a, b) => a.name.localeCompare(b.name)),
        }));
    }, [parameters, properties, searchTerm]);

    const parameterDetail = useCallback((tableData) => {
        return (
            <div className="parameter-detail">
                <div className="parameter-details-header">
                    <div className="header-title">{t("common.parameters")} ({tableData.length})</div>
                    <div className="header-link" onClick={showCreateParameterModal}>
                        <PlusOutlined /> {t("common.Add New")}
                    </div>
                </div>
                <div className="parameter-details-content">
                    <Table
                        bordered={false}
                        rowSelection={{
                            type: "checkbox",
                            onChange: onRowSelection,
                            selectedRowKeys: selectedParameters,
                            hideSelectAll: true
                        }}
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                        className="repository-table"
                    />
                </div>
            </div>
        );
    }, [t, showCreateParameterModal, columns, selectedParameters]);

    return (
        <>
            <div className="parameter-details-container">
                <Space direction="vertical" style={{ width: "100%" }}>
                    {groupedData && groupedData.length > 0 && groupedData.map(data => (
                        <Collapse
                            key={data.key}
                            defaultActiveKey={["1"]}
                            items={[
                                {
                                    key: "1",
                                    label: data.name,
                                    children: parameterDetail(data.tableData),
                                },
                            ]}
                        />
                    ))}
                </Space>
            </div>
            <RepositoryActionDrawer
                open={drawerOpen}
                close={onDismiss}
                selected={selectedParameters.length}
                onDelete={deleteParameters}
            />
        </>
    );
};

export default ParameterDetails;
