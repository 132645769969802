import { LoadingOutlined } from "@ant-design/icons";
import { List, message, Modal, Space, Spin, Typography } from "antd";
import { useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { StoreState } from "src/store/configureStore";
import { useMemberName } from "src/utils/useMemberName";
import useTranslate from "src/utils/useTranslate";

const { Text } = Typography

export function TemplatesOptionModal({ templateOptionsModal, setTemplateOptionsModal, setCustomFields, type }: any) {
    const [t] = useTranslate()
    const { getName } = useMemberName()
    const { listTemplate: { status, data: templateData } } = useSelector((state: StoreState) => state.templates)


    return (
        <Modal
            title={t('template.selectFromTemplate')}
            visible={templateOptionsModal}
            onCancel={() => setTemplateOptionsModal(false)} footer={null}>
            <Spin spinning={status === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <List dataSource={templateData.filter((res: any) => res?.template_type === type)}
                        renderItem={(item: any) => (
                            <List.Item
                                key={item?.template_id}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setCustomFields(item?.fields)
                                    setTemplateOptionsModal(false)
                                    message.success(`${item?.name} field(s) added`)
                                }}>
                                <List.Item.Meta
                                    title={<Text strong type="secondary">{item.name}</Text>}
                                    description={<Space>
                                        <Text>{getName(item?.created_by)}</Text>
                                        <Text>{"|"}</Text>
                                        <Text>{new Date(item?.updated).toDateString()}</Text>
                                    </Space>}
                                />
                            </List.Item>
                        )}
                    />
                </Space>
            </Spin>
        </Modal>
    )
}
