import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import {
    deleteSemAnalysisFilesClear,
    deleteSemAnalysisFilesRequest,
    downloadBatchReportRequest,
    fetchSemAnalysisListRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import ThumbnailList from "../Shared/ThumbnailList";
import { Modal, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Unsubscribe } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_SEM_ANALYSIS, firestoreDb } from "src/utils/firebase";
import { useHistory } from "react-router-dom";
import { IMAGE_ANALYSIS_TYPES } from "../SEMAnalysisWrapper";
import SelectionsActions from "../Shared/SelectionsActions";

const GeneralAnalysis = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let {
        semAnalysisListStatus,
        deleteSemAnalysisFilesStatus,
        semAnalysisData,
        analysisTypes,
        semAnalysisList,
        downloadBatchReportStatus
    } = useSelector((state: StoreState) => state.semAnalysis);
    const [loadingDataMap, setLoadingDataMap] = useState<any>({});
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);

    const fetchThumbnails = useCallback(() => {
        dispatch(
            fetchSemAnalysisListRequest({
                analysisType:
                    analysisTypes[
                    IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS as keyof typeof analysisTypes
                    ],
            })
        );
    }, [analysisTypes, dispatch]);

    useEffect(() => {
        fetchThumbnails();

        return () => { dispatch(deleteSemAnalysisFilesClear()); }
    }, [fetchThumbnails, dispatch]);

    useEffect(() => {
        if (deleteSemAnalysisFilesStatus === AsyncStates.SUCCESS) {
            fetchThumbnails();
            setSelectedFileIds([]);

            // Clear loading data map
            setLoadingDataMap((prev: {}) => {
                return Object.fromEntries(Object.entries(prev).filter(([_, val]: any) => String(val.status).toLowerCase() !== "completed"))
            })
        }
    }, [deleteSemAnalysisFilesStatus, dispatch, fetchThumbnails]);

    const listenToProcessingStatus = useCallback((doc_id) => {
        let unsub: Unsubscribe;
        const taskDocRef = doc(
            firestoreDb,
            `${FB_COLLECTION_SEM_ANALYSIS}/${doc_id}`
        );

        unsub = onSnapshot(taskDocRef, (doc) => {
            const taskDoc = doc.data();
            if (taskDoc) {
                const taskStatus = taskDoc["percent_completed"] === "100%" ? "Completed" : "In Progress";
                setLoadingDataMap((prev: any) => ({
                    ...prev,
                    [doc_id]: {
                        status: taskStatus,
                        data: taskDoc,
                    },
                }));

                if (String(taskStatus).toLowerCase() === "completed") {
                    unsub && unsub();
                    fetchThumbnails();
                }
            }
        });
    }, [fetchThumbnails]);

    useEffect(() => {
        if (
            semAnalysisData?.firebase_doc_id &&
            !loadingDataMap?.[semAnalysisData.firebase_doc_id]
        ) {
            setLoadingDataMap((prev: any) => ({
                ...prev,
                [semAnalysisData.firebase_doc_id]: {}
            }));
            listenToProcessingStatus(semAnalysisData.firebase_doc_id);
        }
    }, [loadingDataMap, semAnalysisData?.firebase_doc_id, listenToProcessingStatus]);

    const handleCardClick = ({ fileId }: any) => {
        history.push(
            `/sem-analysis/${IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS}/files/${fileId}`
        );
    };

    const filteredSemAnalysisList = useMemo(
        () =>
            Array.isArray(semAnalysisList)
                ? semAnalysisList
                    ?.filter((item: any) => String(item.status).toLowerCase() === "completed")
                    ?.sort(
                        (x: any, y: any) =>
                            new Date(y.created_at).getTime() -
                            new Date(x.created_at).getTime()
                    )
                : [],
        [semAnalysisList]
    );

    const selectAll = useCallback(() => {
        if (selectedFileIds.length === filteredSemAnalysisList.length) setSelectedFileIds([]);
        else {
            setSelectedFileIds(filteredSemAnalysisList.map(s => s.file_id));
        }
    }, [filteredSemAnalysisList, selectedFileIds.length]);

    const downloadReport = useCallback(() => {
        const payload: any = {
            file_ids: selectedFileIds || [],
            analysis_type:
                analysisTypes[
                IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS as keyof typeof analysisTypes
                ],
        };

        dispatch(downloadBatchReportRequest(payload));
    }, [analysisTypes, dispatch, selectedFileIds]);

    const deleteFiles = useCallback(() => {
        Modal.confirm({
            title: "Are you sure?",
            icon: <ExclamationCircleOutlined />,
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                dispatch(
                    deleteSemAnalysisFilesRequest({
                        file_ids: selectedFileIds || [],
                        analysis_type:
                            analysisTypes[
                            IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS as keyof typeof analysisTypes
                            ],
                    })
                );
            },
        });
    }, [analysisTypes, dispatch, selectedFileIds]);

    return (
        <Spin
            spinning={[deleteSemAnalysisFilesStatus, semAnalysisListStatus].includes(
                AsyncStates.LOADING
            )}
        >
            <SelectionsActions
                count={filteredSemAnalysisList?.length || 0}
                selectedIds={selectedFileIds}
                onSelectAll={selectAll}
                onDownloadReport={downloadReport}
                onDelete={deleteFiles}
                downloadReportStatus={downloadBatchReportStatus}
            />
            <ThumbnailList
                loadingDataMap={loadingDataMap}
                handleCardClick={handleCardClick}
                thumbnailList={filteredSemAnalysisList}
                loading={semAnalysisListStatus === AsyncStates.LOADING}
                selectable={true}
                setSelectedIds={setSelectedFileIds}
                selectedIds={selectedFileIds}
            />
        </Spin>
    );
};

export default GeneralAnalysis;
