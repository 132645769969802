import { useEffect, useMemo, useState } from "react";
import { Row, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getChemDrawRequest,
  saveChemDrawRequest,
} from "src/store/actions/chemDraw";
import { StoreState } from "src/store/configureStore";
import { AsyncStates, permissions, projectStatus } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import { LoadingOutlined } from "@ant-design/icons";
import { Editor } from "ketcher-react";
import { StandaloneStructServiceProvider } from "ketcher-standalone";
// import { RemoteStructServiceProvider } from 'ketcher-core'
import "ketcher-react/dist/index.css";
import Miew from "miew";
import "miew/dist/miew.min.css";
import useTranslate from "src/utils/useTranslate";
import { setIsEditing } from "src/store/actions/isEditing";
import { CustomPrompt } from "src/utils/CustomPrompts";
import { usePermission } from "src/utils/usePermissions";
import { WorkOrderContainer } from "./WorkOrderContainer";
(global as any).Miew = Miew;

export const ChemDraw = () => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { getStatus, data, saveStatus } = useSelector(
    (state: StoreState) => state.chemDraw
  );
  const isEditing = useSelector((state: StoreState) => state.isEditing);

  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );

  const userAccess = usePermission();
  const disabled = useMemo(
    () =>
      userAccess?.permission === permissions.viewer ||
      userAccess?.status !== projectStatus.in_progress,
    [userAccess]
  );

  const structServiceProvider = new StandaloneStructServiceProvider();
  // const structServiceProvider = new RemoteStructServiceProvider(`http://ec2-18-140-71-47.ap-southeast-1.compute.amazonaws.com:8002/v2/indigo/info`)
  const [editorInstance, setEditorInstance] = useState<any>();

  const onLoad = (ketcher: any) => {
    setEditorInstance(ketcher);
    ketcher
      .setMolecule(String(data?.data || ""))
      .then((res: any) => console.log(res))
      .catch((err: any) => console.log(err));
    ketcher.editor.subscribe("change", (operations: any) => {
      if (operations && operations[0].operation !== "Load canvas")
        dispatch(setIsEditing(true));
    });
  };

  const save = () => {
    editorInstance.getKet().then((res: any) => {
      dispatch(
        saveChemDrawRequest({
          work_order_id: workOrder?.work_order_id,
          data: res,
        })
      );
    });
  };

  useEffect(() => {
    dispatch(getChemDrawRequest({ work_order_id: workOrder?.work_order_id }));
  }, [dispatch, workOrder?.work_order_id]);

  return (
    <WorkOrderContainer>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <CustomPrompt
          isEditing={isEditing}
          message="Unsaved changes will be lost!."
        />
        <Row justify="end">
          <StyledButton
            disabled={disabled || workOrder?.status === "closed"}
            loading={saveStatus === AsyncStates.LOADING}
            onClick={save}
            type="primary"
          >
            {t("common.save")}
          </StyledButton>
        </Row>
        {getStatus === AsyncStates.SUCCESS ? (
          <div
            style={{
              marginLeft: "0.5rem",
              pointerEvents:
                disabled || workOrder?.status === "closed" ? "none" : "all",
            }}
          >
            <Editor
              errorHandler={(message) => {
                console.log(message);
              }}
              staticResourcesUrl={""}
              structServiceProvider={structServiceProvider}
              onInit={onLoad}
            />{" "}
          </div>
        ) : (
          <Spin
            spinning={getStatus === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}
          >
            <div style={{ height: "200px" }}></div>
          </Spin>
        )}
      </Space>
    </WorkOrderContainer>
  );
};
