import { Empty, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants/index";
import useTranslate from "src/utils/useTranslate";
import { useValue } from "src/utils/useValue";
import "../InventoryV2.scss";

type TProps = {
    propertiesByCategory: any[];
    inventory_id: string;
};

const InventoryIngredientPropertyDetail = ({
    propertiesByCategory,
    inventory_id,
}: TProps) => {
    const [t] = useTranslate();
    const [properties, setProperties] = useState<any[]>([]);
    const ingredientPropertyStatus = useSelector(
        (state: StoreState) => state.inventoryV2.getIngredientPropertyStatus
    );
    const { getValue: getLocalValue, addZeroes } = useValue();

    const columns: any = useMemo(() => {
        return [
            {
                title: t("common.property"),
                dataIndex: "property",
                key: "property",
                width: 400,
            },
            {
                title: t("common.standard"),
                dataIndex: "standard",
                key: "standard",
                width: 300,
            },
            {
                title: t("inventory.specimen"),
                dataIndex: "specimen",
                key: "specimen",
                width: 300,
            },
            {
                title: t("common.value"),
                dataIndex: "value",
                key: "value",
                fixed: "right",
                width: 100,
                render: (val: any) => val
            },
        ];
    }, [t]);

    const getPropertyOrParameterValue = useCallback(
        (param: any) => {
            const unit = !param.unit || param.unit === "-" ? "" : param.unit;
            const operator = param.operator || "";

            if (param.value_type === "categorical") {
                return `${param.value_str || ""} ${unit}`;
            } else if (
                param.value_type === "numerical" &&
                param.value_subtype === "single"
            ) {
                return `${addZeroes(getLocalValue(param.value), 1) || ""} ${unit}`;
            } else if (param.value_subtype === "range" && param.value && param.value_max) {
                return `${addZeroes(getLocalValue(param.value), 1) || ""} - ${addZeroes(getLocalValue(param.value_max), 1) || ""
                    } ${unit}`;
            } else if (param.value_subtype === "operator") {
                return `${operator} ${addZeroes(getLocalValue(param.value), 1) || ""} ${unit}`;
            } else {
                return "";
            }
        },
        [getLocalValue, addZeroes]
    );

    const getTableData = useCallback(
        (properties: any[] = []) => {
            const tableData: any[] = [];

            const generatePropertyName = (property: any, property_value: any) => {
                let propertyName = property?.property_name;
                if (
                    property_value?.parameters &&
                    property_value?.parameters.length > 0
                ) {
                    propertyName += ` (${property_value.parameters
                        .map((parameter: any) => getPropertyOrParameterValue(parameter))
                        .filter((p: any) => p)
                        .join(", ")})`;
                }

                return propertyName;
            };

            properties.forEach((property: any) => {
                property.property_values.forEach((property_value: any) => {
                    tableData.push({
                        key: property_value.inventory_property_value_id,
                        property: generatePropertyName(property, property_value),
                        standard: property_value?.standard ? property_value?.standard : "-",
                        specimen: property_value?.specimen ? property_value?.specimen : "-",
                        value: getPropertyOrParameterValue(property_value),
                    });
                });
            });

            return tableData?.sort((a: any, b: any) => a.property?.localeCompare(b.property));
        },
        [getPropertyOrParameterValue]
    );

    useEffect(() => {
        if (propertiesByCategory && propertiesByCategory.length > 0) {
            const filteredPropertiesByCategories = propertiesByCategory
                .map((propertiesWithCategory: any) => {
                    const tableData = getTableData(propertiesWithCategory.properties);
                    if (tableData.length > 0)
                        return {
                            category: propertiesWithCategory.category_name,
                            tableData,
                        };

                    return null;
                })
                .filter((property) => property);
            setProperties(filteredPropertiesByCategories);
        }
    }, [propertiesByCategory, getTableData]);

    if (
        properties.length === 0 &&
        ingredientPropertyStatus?.[inventory_id] === AsyncStates.SUCCESS
    )
        return <Empty description={t("common.noProperties")} />;

    return (
        <div className="inventory-ingredient-property-detail-container">
            {properties.map((property: any) => (
                <div
                    className="property-detail-category-container"
                    key={property.category}
                >
                    <div className="header">{property.category}</div>
                    <Table
                        bordered={false}
                        columns={columns}
                        dataSource={property.tableData}
                        pagination={false}
                        className="inventory-v2-table"
                    />
                </div>
            ))}
        </div>
    );
};

export default InventoryIngredientPropertyDetail;
