import React from "react";
import { DataTypes } from "../utils";
import TextCell from "./TextCell";
import NumberCell from "./NumberCell";
import SelectCell from "./SelectCell";

interface CellProps {
  value: any;
  row: {
    index: number;
  };
  column: {
    id: string;
    dataType: DataTypes;
    options?: any[];
  };
  dataDispatch: React.Dispatch<any>;
  allowEditing: boolean;
}

export default function Cell(props: CellProps) {
  const {
    value: initialValue,
    row,
    column: { id, dataType, options },
    dataDispatch,
    allowEditing,
  } = props;

  function getCellElement() {
    switch (dataType) {
      case DataTypes.TEXT:
        return (
          <TextCell
            initialValue={initialValue}
            rowIndex={row.index}
            columnId={id}
            dataDispatch={dataDispatch}
            allowEditing={allowEditing}
          />
        );
      case DataTypes.NUMBER:
        return (
          <NumberCell
            initialValue={initialValue}
            rowIndex={row.index}
            columnId={id}
            dataDispatch={dataDispatch}
            allowEditing={allowEditing}
          />
        );
      case DataTypes.SELECT:
        return (
          <SelectCell
            initialValue={initialValue}
            options={options}
            rowIndex={row.index}
            columnId={id}
            dataDispatch={dataDispatch}
            allowEditing={allowEditing}
          />
        );
      default:
        return <span></span>;
    }
  }

  return getCellElement();
}
