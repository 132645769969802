import { sendPost } from "src/utils/sendApi";

export const reportUpload = sendPost("v1/data/upload_report");

export const reportSharing = sendPost("v1/data/share_report");

export const reportDeletion = sendPost("v1/data/delete_report");

export const reportsRetrieve = sendPost("v1/data/retrieve_reports")

export const modularReportsUploadApi = sendPost("v1/data/modular_reports/upload_report");

export const modularReportsRetrieveApi = sendPost("v1/data/modular_reports/retrieve_reports");

export const modularReportsDeleteApi = sendPost("v1/data/modular_reports/delete_report");

export const modularTemplateListApi = sendPost("v1/data/modular_reports/list_templates");
export const modularTemplateCreateApi = sendPost("v1/data/modular_reports/create_template");
export const modularTemplateUpdateApi = sendPost("v1/data/modular_reports/edit_template")
export const modularTemplateDeleteApi = sendPost("v1/data/modular_reports/delete_template")
