import {
    Space,
    Drawer,
    Spin,
    Empty,
    Collapse,
    List,
    Row,
    Typography,
    // Descriptions,
    Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import {
    fetchExperimentCleanup,
    fetchLinkedExperimentCleanup,
    fetchLinkedExperimentDetailsCleanup,
} from "src/store/actions/experiment";
import { WorkOrderTableWrapper } from "../WorkOrderDetails/WorkOrderTableWrapper";
import { usePermission } from "src/utils/usePermissions";
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from "react";
import Plot from "react-plotly.js";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";

const { Text } = Typography;


export function GroupFormulationDetailsDrawer({
    drawerState,
    filters
}: any) {

    const [groupDetails, setGroupDetails] = drawerState
    const userAccess = usePermission()
    const dispatch = useDispatch();
    const [t] = useTranslate();

    const plotsData = useSelector(
        (state: StoreState) => state.workOrderDetails.plotsData
    );
    const plotsDataStatus = useSelector(
        (state: StoreState) => state.workOrderDetails.plotsDataStatus
    );
    const attachments = useSelector(
        (state: StoreState) => state.workOrderDetails.fileList
    );
    const fileListStatus = useSelector(
        (state: StoreState) => state.workOrderDetails.fileListStatus
    );

    const trials = useSelector((state: StoreState) => state?.workOrderDetails?.experiment)
    const trialsStatus = useSelector((state: StoreState) => state?.workOrderDetails?.experimentStatus)

    const [image, setImage] = useState<any>({});
    const [imageVisible, setImageVisible] = useState(false);

    return (
        <>
            <Drawer
                width="900px"
                title={t("formulations.details")}
                placement="right"
                closable={true}
                onClose={() => {
                    setGroupDetails(false);
                    dispatch(fetchExperimentCleanup());
                    dispatch(fetchLinkedExperimentCleanup());
                    dispatch(fetchLinkedExperimentDetailsCleanup());
                }}
                open={groupDetails}
                mask={false}
                styles={{ body: { paddingBottom: 100 } }}

            >
                <Spin spinning={trialsStatus === AsyncStates.LOADING}>
                    {/* <Descriptions bordered size="small">
                        <Descriptions.Item
                            label={<Text strong>{"Formulations"}</Text>}
                        >
                            {trials?.map((trial: any) => trial?.id_set
                                ?.formulation_id)?.join(',') || "No Data"}
                        </Descriptions.Item>
                    </Descriptions> */}
                    <Space direction="vertical" size="large" style={{ width: "100%", marginTop: "0.5rem" }}>
                        {!!attachments?.length && (
                            <Collapse>
                                <Collapse.Panel
                                    key={"upload"}
                                    header={<Text strong>{"Uploads"}</Text>}
                                >
                                    <List
                                        size="small"
                                        loading={{
                                            spinning: fileListStatus === AsyncStates.LOADING,
                                            indicator: <LoadingOutlined />,
                                        }}
                                        itemLayout="vertical"
                                        dataSource={attachments}
                                        renderItem={(item: any) => (
                                            <List.Item>
                                                <Row justify="space-between">
                                                    <Text>{item?.filename}</Text>
                                                    <Space>
                                                        <StyledButton
                                                            type="link"
                                                            disabled={
                                                                !["jpg", "jpeg", "gif", "png"].includes(
                                                                    item?.s3_file_key?.split(".").pop()
                                                                )
                                                            }
                                                            onClick={() => {
                                                                setImage(item);
                                                                setImageVisible(true);
                                                            }}
                                                        >
                                                            {"view"}
                                                        </StyledButton>
                                                        <StyledButton type="link" href={item?.download_link}>
                                                            {"download"}
                                                        </StyledButton>
                                                    </Space>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                </Collapse.Panel>
                            </Collapse>
                        )}
                        {trials.length > 0 ? <WorkOrderTableWrapper
                            disabled={true}
                            formulationList={trials}
                            from="drawer"
                            filters={filters}
                            userAccess={userAccess}
                            tableId={"trial_details"}
                        /> : trialsStatus !== AsyncStates.LOADING && <Empty />}
                        {!!plotsData?.line?.[trials?.[0]?.meta?.display_id] && (
                            <Collapse defaultActiveKey={"5"}>
                                <Collapse.Panel header={"Time/Temperature (Line graph)"} key="5">
                                    <Spin
                                        spinning={plotsDataStatus === AsyncStates.LOADING}
                                        indicator={<LoadingOutlined />}
                                    >
                                        <Space
                                            direction="vertical"
                                            style={{ width: "100%", overflowX: "auto" }}
                                        >
                                            <Plot
                                                key={trials?.[0]?.meta?.display_id}
                                                layout={{
                                                    showlegend: true,
                                                    hovermode: "closest",
                                                    width: 620,
                                                    legend: {
                                                        orientation: "h",
                                                        xanchor: "center",
                                                        y: 1.2,
                                                        x: 0.5,
                                                    },
                                                    xaxis: {
                                                        showspikes: true,
                                                        spikemode: "toaxis",
                                                        title: {
                                                            text: "Time",
                                                            font: {
                                                                family: "Courier New, monospace",
                                                                size: 12,
                                                                color: "#11111",
                                                            },
                                                        },
                                                    },
                                                    yaxis: {
                                                        showspikes: true,
                                                        spikemode: "toaxis",
                                                        title: {
                                                            text: "Temperature / Bath",
                                                            font: {
                                                                family: "Courier New, monospace",
                                                                size: 12,
                                                                color: "#11111",
                                                            },
                                                        },
                                                    },
                                                }}
                                                data={[
                                                    {
                                                        x:
                                                            plotsData?.line?.[trials?.[0]?.meta?.display_id]?.[
                                                            "time"
                                                            ] || ([] as any),
                                                        y:
                                                            plotsData?.line?.[trials?.[0]?.meta?.display_id]?.[
                                                            "trial_data"
                                                            ] || ([] as any),
                                                        type: "scatter",
                                                        name: "Temperature",
                                                    },
                                                    {
                                                        x:
                                                            plotsData?.line?.[trials?.[0]?.meta?.display_id]?.[
                                                            "time"
                                                            ] || ([] as any),
                                                        y:
                                                            plotsData?.line?.[trials?.[0]?.meta?.display_id]?.[
                                                            "bath"
                                                            ] || ([] as any),
                                                        type: "scatter",
                                                        name: "Bath",
                                                    },
                                                ]}
                                            />
                                        </Space>
                                    </Spin>
                                </Collapse.Panel>
                            </Collapse>
                        )}
                        {!!plotsData?.bar?.[trials?.[0]?.meta?.display_id] && (
                            <Collapse defaultActiveKey={"6"}>
                                <Collapse.Panel header={"Enthalpy/Temperature (Bar graph)"} key="6">
                                    <Spin
                                        spinning={plotsDataStatus === AsyncStates.LOADING}
                                        indicator={<LoadingOutlined />}
                                    >
                                        <Space
                                            direction="vertical"
                                            style={{ width: "100%", overflowX: "auto" }}
                                        >
                                            <Plot
                                                key={trials?.[0]?.meta?.display_id}
                                                layout={{
                                                    showlegend: true,
                                                    hovermode: "closest",
                                                    width: 620,
                                                    legend: {
                                                        orientation: "h",
                                                        xanchor: "center",
                                                        y: 1.2,
                                                        x: 0.5,
                                                    },
                                                    xaxis: {
                                                        autorange: 'reversed',
                                                        title: {
                                                            text: 'Temperature in °C',
                                                            font: {
                                                                family: "Courier New, monospace",
                                                                size: 18,
                                                                color: "#11111",
                                                            },
                                                        },
                                                    },
                                                    yaxis: {
                                                        title: {
                                                            text: 'Enthalpy in J/g',
                                                            font: {
                                                                family: "Courier New, monospace",
                                                                size: 18,
                                                                color: "#11111",
                                                            },
                                                        },
                                                    },
                                                }}
                                                data={[{
                                                    x: plotsData?.bar?.[trials?.[0]?.meta?.display_id]?.['trial_data'] || [] as any,
                                                    y: plotsData?.bar?.[trials?.[0]?.meta?.display_id]?.['enthalpy_freezing'] || [] as any,
                                                    type: 'bar',
                                                    name: "Enthalpy-Freezing",
                                                },
                                                {
                                                    x: plotsData?.bar?.[trials?.[0]?.meta?.display_id]?.['trial_data'] || [] as any,
                                                    y: plotsData?.bar?.[trials?.[0]?.meta?.display_id]?.['enthalpy_melting'] || [] as any,
                                                    type: 'bar',
                                                    name: 'Enthalpy-Melting',
                                                },
                                                ]}
                                            />
                                        </Space>
                                    </Spin>
                                </Collapse.Panel>
                            </Collapse>
                        )}
                    </Space>
                </Spin>
            </Drawer >
            <Modal
                title={image?.filename}
                width={1200}
                visible={imageVisible}
                maskClosable={false}
                footer={null}
                onCancel={() => {
                    setImageVisible(false);
                    setImage({});
                }}
            >
                <img
                    style={{ marginTop: 10 }}
                    width={"100%"}
                    height={"100%"}
                    src={image?.url}
                    alt={"Preview not available"}
                />
            </Modal>
        </>
    );
}
