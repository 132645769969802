import { Form, Modal, Input, Typography, Space, Select, Row, Spin, message, AutoComplete } from "antd"
import { useState, useEffect, useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ModalTitle } from "../../utils/ModalTitle"
import { createMethodRequest, createMethodReset, fetchMethodListRequest } from "src/store/actions/characterizationMethods"
import { StoreState } from "src/store/configureStore"
import { LoadingOutlined, MinusCircleOutlined } from "@ant-design/icons"
import { AsyncStates } from "src/constants"
import { StyledButton } from "src/styled_components/StyledButton"
import { StyledCard } from "src/styled_components/StyledCard"
import { orange } from "@ant-design/colors"
import { CellProps, DataSheetGrid, keyColumn, textColumn } from "react-datasheet-grid"
import { useUnsavedChangesPrompt } from 'src/utils/hooks'
import { setIsEditing } from "src/store/actions/isEditing"
import useTranslate from 'src/utils/useTranslate'
import { CustomPrompt } from 'src/utils/CustomPrompts'
import { getDropdownFilteredValue } from "src/utils/decorator"
import { TextComponent } from "../../TextComponent"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"

const { Text } = Typography
const { OptGroup, Option } = Select


export const CreateNewMethod = ({ modalVisible, setModalVisible }: any) => {
    const dispatch = useDispatch()
    const requiredFieldStar = useRequiredFieldStar()

    const [t] = useTranslate()
    const isEditing = useSelector((state: StoreState) => state.isEditing)
    const { createMethodStatus, methodList } = useSelector((state: StoreState) => state.characterizationMethods)
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const unitList = useSelector((state: StoreState) => state.conversion.unitList)
    const characterizations = useSelector((state: StoreState) => state.displayNames?.data?.characterizations)

    const [newMethod, setNewMethod] = useState<any>({
        name: '',
        category: '',
        description: '',
        variations: [
            { key: 0, name: '', description: '', data: [], properties: [] }]
    })

    const [newMethodForm] = Form.useForm();
    const { checkUnsavedChanges } = useUnsavedChangesPrompt({
        form: newMethodForm,
        setModal: setModalVisible,
        setNewMethod: setNewMethod
    })

    const categoryOptions = useMemo(() => {
        return [...new Set(methodList.map((res: any) => res?.category))].map((category: any) => ({ value: category, label: category }))
    }, [methodList])

    useEffect(() => {
        if (createMethodStatus === AsyncStates.SUCCESS) {
            setModalVisible(false)
            dispatch(createMethodReset())
            dispatch(fetchMethodListRequest({ method_type: "characterizations" }))
            dispatch(setIsEditing(false))
            newMethodForm.resetFields()
            setNewMethod({
                name: '',
                category: '',
                description: '',
                variations: [
                    { key: 0, name: '', description: '', data: [], properties: [] }]
            })
        } else if (createMethodStatus === AsyncStates.ERROR) {
            dispatch(createMethodReset())
        }
    }, [createMethodStatus, dispatch, setModalVisible, newMethodForm]
    )

    const onNewMethodFormFinish = (values: NewMethodForm) => {
        const { name, description } = values
        if (!newMethod.variations?.length) {
            message.error("Add atleast one variation")
            return
        }
        if (newMethod.variations?.some((val: any) => !val?.data?.length) && newMethod.variations?.some((val: any) => !val?.properties?.length)) {
            message.error("Add atleast one row in the variation")
            return
        }
        if (newMethod.variations.some((val: any) => val.data.some((res: any) => !res?.parameter))) {
            message.error("Add parameters in the variations")
            return
        }
        if (newMethod.variations.some((val: any) => !!val?.properties?.length)) {
            if (newMethod.variations.some((val: any) => val.properties.some((res: any) => !res?.parameter))) {
                message.error("Add parameters in the variation properties")
                return
            }
        }
        // const tranformedValues = {
        //     ...values,
        //     variations: Object.values(values.variations || {}).map((variation, index: number) => ({
        //         ...variation,
        //         data: newMethod?.variations[index]?.data,
        //         properties: newMethod?.variations[index]?.properties
        //     }))
        // }
        const tranformedValues = {
            method_type: "characterizations",
            ...values,
            variations: [{
                name, description, data: newMethod?.variations?.[0]?.data,
                properties: newMethod?.variations[0]?.properties
            }]
        }
        dispatch(createMethodRequest(tranformedValues))
    }


    const getColumns = useCallback((typeKey = "characterizations") => {
        const valueKey: any = typeKey === "characterizations" ? "data" : "properties"
        const columns: any = [
            {
                title: "",
                width: "0 0 5px",
                component: (row: CellProps<any>) => (
                    <MinusCircleOutlined
                        onClick={() => setNewMethod((prevState: any) => {
                            prevState.variations?.[row.rowData.index][valueKey].splice(row.rowIndex, 1)
                            return ({ ...prevState })
                        })}
                        style={{ margin: "0 auto" }} />
                ),
            },
            {
                title: "Parameter",
                component: (row: any) => {
                    const usedIdentifiers = newMethod?.variations?.[row.rowData.index]?.[valueKey]?.map((res: any) => res?.parameter)
                    return (
                        <Select
                            bordered={false}
                            filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                            showSearch
                            style={{ width: "100%" }}
                            onChange={(value: any) => {
                                dispatch(setIsEditing(true))
                                const rowData = Object.entries(row.rowData || {}).reduce((obj, [key, value]: any) => ({ ...obj, [key]: key === "index" ? value : "" }), {})
                                row.setRowData({ ...row.rowData, ...rowData, parameter: value })
                            }}
                        >
                            {Object.entries(displayNames?.[typeKey] || {}).filter(([key, _]: any) => !usedIdentifiers?.includes(key))?.map(([key, value]: any) => (
                                <Option value={key}>{value?.name}</Option>
                            ))}
                        </Select>
                    )
                },
                minWidth: 200,
            },
            {
                ...keyColumn("unit", textColumn),
                title: "Unit",
                component: (row: any) => (
                    <Select
                        showSearch
                        bordered={false}
                        filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                        value={newMethod?.variations?.[row.rowData.index]?.[typeKey]?.[row?.rowIndex]?.unit}
                        style={{ width: "100%" }}
                        onChange={(value: any) => {
                            dispatch(setIsEditing(true))
                            row.setRowData({ ...row.rowData, unit: value })
                        }
                        }
                    >
                        {Array.from(new Set(unitList.map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                            <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                                {unitList.filter((res: any) => res.category === category).map((res: any) => (
                                    <Option value={res.name} key={res.name}>{res.name}</Option>
                                ))}
                            </OptGroup>
                        )}
                    </Select>
                ),
                renderWhenScrolling: false
            }
        ]
        if (typeKey === "characterizations") {
            columns.push({
                ...keyColumn("value", textColumn),
                title: "Value",
                component: (props: CellProps) => {
                    if (!!characterizations?.[props?.rowData?.parameter]?.options?.length) {
                        return <Select
                            value={newMethod?.variations?.[props.rowData.index]?.[typeKey]?.[props?.rowIndex]?.value}
                            bordered={false}
                            filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                            showSearch
                            style={{ width: "100%" }}
                            onChange={(value: any) => {
                                dispatch(setIsEditing(true))
                                props.setRowData({ ...props.rowData, value })
                            }
                            }
                        >
                            {characterizations?.[props?.rowData?.parameter]?.options?.map((res: any, index: number) => (
                                <Option value={res} key={index}>{res}</Option>
                            ))}
                        </Select>
                    } else {
                        return <TextComponent {...props} id={"value"} disabled={false} />
                    }
                }
            })
        }
        return columns
    }, [dispatch, displayNames, unitList, newMethod, characterizations])

    // const addVariation = () => {
    //     setNewMethod((state: any) => ({
    //         ...state,
    //         variations: [
    //             ...state.variations,
    //             {
    //                 key: state.variations.length,
    //                 name: '',
    //                 description: '',
    //                 data: [],
    //                 properties: []
    //             }
    //         ]
    //     }))
    // }

    // const removeVariation = (index: number) => {
    //     setNewMethod((state: any) => ({
    //         ...state,
    //         variations: state.variations.filter((_: any, _index: number) => _index !== index)
    //     }))
    // }

    const addVariationParameter = (index: number, key: string) => {
        setNewMethod((state: any) => {
            const tempParameters = [
                ...state.variations[index]?.[key],
                { parameter: '', unit: '', ...(key === "data" && { value: '' }), index }
            ]
            state.variations[index][key] = tempParameters
            return { ...state }
        })
    }


    return (
        <Modal
            maskClosable={false}
            title={
                <ModalTitle
                    title={
                        <div>
                            <span style={{ marginRight: "4px" }}>{t("inventory.CreateMethod")}</span>
                            {(isEditing && <Text style={{ color: orange.primary }}>{`(${t("common.unsavedChanges")})`}</Text>)}
                        </div>
                    }
                    subTitle={"Save a new Method and its variations into the Inventory"}
                />
            }
            centered width={800}
            visible={modalVisible}
            footer={null}
            onCancel={checkUnsavedChanges}
        >
            <Spin spinning={createMethodStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <CustomPrompt message={t("common.modal.unsavedwarning")} isEditing={isEditing} />

                <Form form={newMethodForm} labelCol={{ span: 4 }} requiredMark={false} wrapperCol={{ span: 20 }} labelAlign='left' colon={false} onFinish={onNewMethodFormFinish} onFieldsChange={() => dispatch(setIsEditing(true))}>
                    <Form.Item label="Name" rules={[{ required: true }]} name="name"
                        required 
				        tooltip={requiredFieldStar}
                    >
                        <Input style={{ width: 150 }} />
                    </Form.Item>
                    <Form.Item label="Category" rules={[{ required: true }]} name="category"
                        required 
				        tooltip={requiredFieldStar}
                    >
                        <AutoComplete options={categoryOptions} />
                        {/* <Cascader options={methodCascaderOptions} /> */}
                    </Form.Item>
                    <Form.Item label="Description" rules={[{ required: true }]} name="description"
                        required 
				        tooltip={requiredFieldStar}
                    >
                        <Input placeholder={t("editProject.enterDescription")} />
                    </Form.Item>
                    {/* <Divider plain>
                        <Text type='secondary'>
                            {"METHOD VARIATIONS"}
                        </Text>
                    </Divider> */}
                    {/* <Form.Item>
                        <StyledButton
                            type="dashed"
                            onClick={addVariation}
                            icon={<PlusOutlined />}
                        >
                            {"Add Variation"}
                        </StyledButton>
                    </Form.Item> */}
                    <Space direction='vertical' style={{ width: '100%' }} size='large'>
                        {newMethod.variations.map((variation: any, index: number) => (
                            <StyledCard key={`${variation.key}.variation`}>
                                {/* <Space style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                                    <Form.Item
                                        name={['variations', variation.key, 'name']}
                                        rules={[{ required: true, message: "Variation required" }]}

                                        required 
				                        tooltip={requiredFieldStar}
                                    >
                                        <Input placeholder="Enter Variation" />
                                    </Form.Item>
                                    <StyledDeleteIcon style={{ fontSize: antdTheme.fontSizeHeading5, color: red[3] }} onClick={() => removeVariation(index)} />
                                </Space>
                                <Form.Item name={['variations', variation.key, 'description']}>
                                    <Input placeholder=" Enter Description" />
                                </Form.Item> */}
                                <Space direction='vertical' style={{ width: '100%' }} size='large'>
                                    <StyledCard title={"Characterization parameters"}>
                                        <Space direction='vertical' style={{ width: "100%", overflowX: "auto" }}>
                                            <StyledButton onClick={() => addVariationParameter(index, "data")}>{"Add Parameter"}</StyledButton>
                                            <DataSheetGrid
                                                lockRows
                                                columns={getColumns("characterizations")}
                                                value={variation?.data}
                                                onChange={(e: any) => {
                                                    dispatch(setIsEditing(true))
                                                    setNewMethod((prevState: any) => {
                                                        prevState.variations[index].data = e
                                                        return { ...prevState }
                                                    })
                                                }}
                                            />
                                        </Space>
                                    </StyledCard>
                                    <StyledCard title={"Properties parameters"}>
                                        <Space direction='vertical' style={{ width: "100%", overflowX: "auto" }}>
                                            <StyledButton onClick={() => addVariationParameter(index, "properties")}>{"Add Parameter"}</StyledButton>
                                            <DataSheetGrid
                                                lockRows
                                                columns={getColumns("properties")}
                                                value={variation?.properties}
                                                onChange={(e: any) => {
                                                    dispatch(setIsEditing(true))
                                                    setNewMethod((prevState: any) => {
                                                        prevState.variations[index].properties = e
                                                        return { ...prevState }
                                                    })
                                                }}
                                            />
                                        </Space>
                                    </StyledCard>
                                </Space>

                            </StyledCard>
                        ))}
                    </Space>
                    <Row justify="end">
                        <Space>
                            <StyledButton onClick={checkUnsavedChanges}>
                                {"Cancel"}
                            </StyledButton>
                            <StyledButton type="primary" htmlType="submit">
                                {t("inventory.CreateMethod")}
                            </StyledButton>
                        </Space>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

