import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import { InventoryDetailsProperties } from "./components/InventoryDetailsProperties/InventoryDetailsProperties"
import { Menu, Row, Col, Empty, Modal, Input, Spin, Space, Typography } from "antd";
import {
  getIngredientFamilyRequest,
  createIngredientFamilyRequest,
  getIngredientFamilyListRequest,
  updateIngredientFamilyRequest,
  deleteIngredientFamilyRequest,
} from "src/store/actions/inventoryV2"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { AsyncStates } from "src/constants"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import useTranslate from "src/utils/useTranslate"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { StyledButton } from "src/styled_components/StyledButton"

const FamilyPage = () => {
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const [addFamilyModalVisible, setAddFamilyModalVisible] =
    useState<boolean>(false)
  const [addFamilyName, setAddFamilyName] = useState<string>("")

  const { ingredientFamilyStatus, ingredientFamilyListStatus } = useSelector(
    (state: StoreState) => state.inventoryV2,
  )

  return (
    <Spin spinning={ingredientFamilyStatus === AsyncStates.LOADING || ingredientFamilyListStatus === AsyncStates.LOADING}>
      <StyledPageHeader ghost={false} title={t("common.family")} />
      <Row>
        <Col span={3}>
          <MenuFamily setAddFamilyModalVisible={setAddFamilyModalVisible} />
        </Col>
        <Col span={21}>
          <ContentFamily />
        </Col>
      </Row>
      <Modal
        title={t("inventory.AddFamily")}
        onOk={() => {
          dispatch(
            createIngredientFamilyRequest({
              family_name: addFamilyName,
            }),
          )
          setAddFamilyModalVisible(false)
          setAddFamilyName('')
        }}
        open={addFamilyModalVisible}
        onCancel={() => {
          setAddFamilyModalVisible(false)
        }}
      >
        <Input
          value={addFamilyName}
          onChange={(e) => {
            setAddFamilyName(e.target.value)
          }}
        ></Input>
      </Modal>
    </Spin>
  )
}

export default FamilyPage

const MenuFamily = ({ setAddFamilyModalVisible }: any) => {
  const { push } = useHistory()
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const { uuid } = useParams<{
    uuid: string
  }>()

  const { ingredientFamilyList, ingredientFamilyListStatus } = useSelector(
    (state: StoreState) => state.inventoryV2,
  )

  useEffect(() => {
    dispatch(getIngredientFamilyListRequest())
  }, [dispatch])

  return (
    <div>
      <Menu
        style={{ outline: "none", userSelect: "none" }}
        activeKey={String(uuid)}
        selectedKeys={[String(uuid)]}
        items={[
          ...(ingredientFamilyListStatus === AsyncStates.SUCCESS
            ? ingredientFamilyList.map((family) => {
              return {
                key: String(family.family_id),
                label: family.family_name,
              }
            })
            : []),
          {
            key: "add-family",
            label: t("inventory.AddFamily"),
            icon: <PlusOutlined />,
          },
        ]}
        onClick={({ key }) => {
          if (key === "add-family") {
            setAddFamilyModalVisible(true)
          } else {
            push(`/inventory/family/${key}`)
          }
        }}
      />
    </div>
  )
}

const ContentFamily = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { ingredientFamilyDeleteStatus } = useSelector((state: StoreState) => state.inventoryV2,)

  const { uuid } = useParams<{
    uuid: string
  }>()

  const inventoryDataList = useSelector(
    (state: StoreState) => state.displayNames.inventoryData,
  )

  const { ingredientFamily, ingredientFamilyStatus, ingredientFamilyList } =
    useSelector((state: StoreState) => state.inventoryV2)

  useEffect(() => {
    if (ingredientFamilyList.find((family) => family.family_id === uuid)) {
      dispatch(getIngredientFamilyRequest({ family_id: uuid }))
    }
  }, [uuid, ingredientFamilyList, dispatch])

  const handleDeleteFamily = () => {
    Modal.confirm({
      title: `${t("common.deleteWarningMessage")} ${ingredientFamily.family_name}?`,
      content: "This action cannot be undone",
      okButtonProps: { style: { background: "red" } },
      onOk: () => {
        dispatch(deleteIngredientFamilyRequest({ family_id: ingredientFamily.family_id }))
      },
    })
  }

  return (
    <div style={{ padding: "0rem 1rem" }}>
      {uuid !== undefined && ingredientFamilyStatus === AsyncStates.SUCCESS ? (
        <Space direction="vertical" style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", alignItems: "center" }}>
            <Typography.Title
              style={{ width: "100%" }}
              editable={{
                onChange: (val) => {
                  if (val !== '') dispatch(updateIngredientFamilyRequest({ family_name: val, family_id: ingredientFamily.family_id }))
                }
              }}
              level={5}
            >
              {ingredientFamily.family_name}
            </Typography.Title>
            <StyledButton danger onClick={() => handleDeleteFamily()} loading={ingredientFamilyDeleteStatus === AsyncStates.LOADING}>
              <DeleteOutlined />
            </StyledButton>
          </div>
          <InventoryDetailsProperties
            ingredient={ingredientFamily}
            from={"family"}
            inventoryData={inventoryDataList}
          />
        </Space>
      ) : (
        <Empty description={t("inventory.SelectFamilyToSeeItsDetails")} />
      )}
    </div>
  )
}
