import { Collapse, Space, Typography, Select, Row } from 'antd'
import React, { useState } from 'react'
import Plot from 'react-plotly.js'
import { enthalpyPlotPlayout, temperaturePlotLayout } from './PropertiesPlots'

export const ComparePlots = ({ linePlots, barPlots }: any) => {

    const [compareTrials, setCompareTrials] = useState<any>([])
    const [compareGraphType, setCompareGraphType] = useState("line")

    return (
        <Collapse>
            <Collapse.Panel header="Compare graphs" key="compare_plots">
                <Space direction="vertical" size="large" style={{ width: "100%", maxHeight: 800, overflowY: "auto" }} >
                    <Row justify={"space-evenly"}>
                        <Space>
                            <Typography.Text strong>{"Select graph type :"}</Typography.Text>
                            <Select style={{ width: 200 }} onChange={(e) => {
                                setCompareTrials([])
                                setCompareGraphType(e)
                            }}>
                                <Select.Option value={"line"}>{"Line graph"}</Select.Option>
                                <Select.Option value={"bar"}>{"Bar Graph"}</Select.Option>
                            </Select>
                        </Space>
                        <Space>
                            <Typography.Text strong>{"Select Trials to compare  :"}</Typography.Text>
                            <Select style={{ width: 400 }} mode="multiple" onChange={setCompareTrials} value={compareTrials} allowClear>
                                {Object.keys((compareGraphType === "line" ? linePlots : barPlots) || {}).map(res => (
                                    <Select.Option value={res}>{res}</Select.Option>
                                ))}
                            </Select>
                        </Space>
                    </Row>
                    {!!compareTrials.length &&
                        <Row justify="space-between">
                            {compareTrials.map((trial: any) => (
                                compareGraphType === "line" ?
                                    <Plot key={trial}
                                        layout={{
                                            title: trial, showlegend: true, hovermode: 'closest', ...temperaturePlotLayout,
                                            width: 560,
                                            height: 450,
                                            // legend: { "orientation": "h" },
                                        }}
                                        data={[{
                                            x: linePlots?.[trial]?.['time'],
                                            y: linePlots?.[trial]?.['trial_data'],
                                            type: 'scatter',
                                            name: 'Temperature',
                                        },
                                        {
                                            x: linePlots?.[trial]?.['time'],
                                            y: linePlots?.[trial]?.['bath'],
                                            type: 'scatter',
                                            name: 'Bath',
                                        },
                                        ]}
                                    /> :
                                    <Plot key={trial}
                                        layout={{
                                            barmode: 'group',
                                            title: trial, showlegend: true, hovermode: 'closest',
                                            width: 560,
                                            height: 450,
                                            ...enthalpyPlotPlayout as any
                                        }}
                                        data={[{
                                            x: barPlots?.[trial]?.['trial_data'] || [] as any,
                                            y: barPlots?.[trial]?.['enthalpy_freezing'] || [] as any,
                                            type: 'bar',
                                            name: "Enthalpy-Freezing",
                                        },
                                        {
                                            x: barPlots?.[trial]?.['trial_data'] || [] as any,
                                            y: barPlots?.[trial]?.['enthalpy_melting'] || [] as any,
                                            type: 'bar',
                                            name: 'Enthalpy-Melting',
                                        },
                                        ]}
                                    />
                            ))
                            }
                        </Row>
                    }
                </Space>
            </Collapse.Panel>
        </Collapse>
    )
}
