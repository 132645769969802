import { PlusOutlined } from '@ant-design/icons';
import { Form, message, Modal, Row, Select, Space } from 'antd';
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setEditingState } from 'src/store/actions/workOrderDetails';
import { StoreState } from 'src/store/configureStore';
import { StyledButton } from 'src/styled_components/StyledButton';
import { getDropdownFilteredValue } from 'src/utils/decorator';
import { Dataset } from 'src/utils/getHeaders';
import { ModalTitle } from '../../utils/ModalTitle';
import useTranslate from 'src/utils/useTranslate';
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar';

const { OptGroup, Option } = Select


export const AddMethodModal = ({ setData, setTableData, addMethodModal, setAddMethodModal, setModalVisible, dataset,
    setAddMethodFlag, initialTrialSetList }: any) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()

    const displayNames = useSelector((state: StoreState) => state.displayNames?.data)

    const { methodList } = useSelector((state: StoreState) => state.characterizationMethods)
    // const [selectedCategory, setSelectedCategory] = useState<string | undefined>()
    const [selectedMethod, setSelectedMethod] = useState<string | null>(null)
    const [selectedMethodVariation, setSelectedMethodVariation] = useState<string | null>(null)
    // const [selectedTrialsForAddMethod, setselectedTrialsForAddMethod] = useState<any[]>([])

    const [form] = Form.useForm()

    // useEffect(() => {
    //     if (addMethodModal) {
    //         setselectedTrialsForAddMethod(trialsForAddMethodOptions.map((opt: any) => opt.value))
    //     }
    // }, [addMethodModal, setselectedTrialsForAddMethod, trialsForAddMethodOptions])

    // const selectSubCategory = (value: string) => {
    //     setSelectedMethod(null)
    //     setSelectedMethodVariation(null)
    //     setSelectedCategory(value)
    //     form.setFieldsValue({
    //         method: "", variation: ""
    //     })
    // }

    const selectMethod = (value: string) => {
        // setSelectedMethodVariation(null)
        setSelectedMethod(value)
        const variationId = methodList.find(method => method.characterization_method_id === value)?.variations?.[0]?.variation_id
        setSelectedMethodVariation(variationId)
        form.setFieldsValue({
            variation: variationId
        })
    }

    // const methodCascaderOptions = useMemo(() => [...new Set(methodList.map((res: any) => res?.category))].map(cat => ({
    //     value: cat,
    //     label: cat
    // })), [methodList])

    // const filteredMethodsOptions = useMemo(() => {
    //     return methodList.filter((res: any) => res?.category === selectedCategory).map(method => ({ value: method.characterization_method_id, label: method.name }))
    // }, [methodList, selectedCategory])

    // const filteredVariationsOptions = useMemo(() => {
    //     const methodId = filteredMethodsOptions.find(variation => variation.value === selectedMethod)?.value
    //     return methodList
    //         .find(method => method.characterization_method_id === methodId)
    //         ?.variations
    //         .map((variation: any) => ({
    //             value: variation.variation_id,
    //             label: variation.name
    //         }))
    // }, [filteredMethodsOptions, selectedMethod, methodList])

    const methodsOptionsList = useMemo(() => {
        return [...new Set(methodList.map((res: any) => res?.category))].sort((a, b) => (String(a.category).toLowerCase() > String(b.category).toLowerCase()) ? 1 : -1)
            .map((category: any, index: number) => (
                <OptGroup label={category} key={String(index + category)}>
                    {methodList.filter((method: any) => method?.category === category).sort((a, b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase()) ? 1 : -1)
                        .map((res: any, key: number) => (
                            <Option key={String(key) + String(res?.characterization_method_id)} value={res?.characterization_method_id} >
                                {`${res?.name} (${res?.description})`}
                            </Option>
                        ))}
                </OptGroup>
            ))
    }, [methodList])

    const closeModal = () => {
        form.resetFields()
        setSelectedMethod(null)
        setSelectedMethod(null)
        setSelectedMethodVariation(null)
        // setselectedTrialsForAddMethod([])
        setAddMethodModal(false)
    }

    const addMethodsToDatasheet = () => {
        setAddMethodFlag(true)
        const variation = methodList.find(method => method.characterization_method_id === selectedMethod)?.variations?.find((res: any) => res?.variation_id === selectedMethodVariation)
        setTableData((prevState: any) => {
            return [...prevState, {
                variation_id: variation?.variation_id,
                characterization_set_id: null,
                variation: variation?.name,
                description: variation?.description,
                key: prevState.length,
            }]
        })
        setData((prevState: any) => {
            const response = [...prevState]
            if (dataset === Dataset.characterizations) {
                response.push(variation.data.map((res: any) => ({
                    identifier: res?.parameter,
                    parameter: displayNames?.[dataset]?.[res?.parameter]?.name,
                    unit: res?.unit,
                    category: displayNames?.[dataset]?.[res?.parameter]?.category,
                    index: prevState.length,
                    input: res?.value ?? "",
                })))
            }
            if (dataset === Dataset.properties) {
                if (variation?.properties?.length) {
                    response.push(variation?.properties?.map((res: any) => ({
                        identifier: res?.parameter,
                        parameter: displayNames?.[dataset]?.[res?.parameter]?.name,
                        unit: res?.unit,
                        category: displayNames?.[dataset]?.[res?.parameter]?.category,
                        index: prevState.length,
                        ...initialTrialSetList.reduce((obj: any, id: any) => ({
                            ...obj,
                            [id?.id_set?.formulation_id]: res?.value ?? ""
                        }),
                            {})
                    })))
                } else {
                    response.push([])
                }

            }
            return response
        })
        dispatch(setEditingState(true))
        message.success(`Added ${variation?.name} in the Work Order`)
        closeModal()
    }


    return (
        <Modal
            maskClosable={false}
            footer={null}
            width={800}
            title={<ModalTitle
                title="Add Method"
                subTitle="Add a Method directly into this Work Order"
                extra={<StyledButton icon={<PlusOutlined />} onClick={() => { closeModal(); setModalVisible(true) }}>{t("inventory.CreateMethod")}</StyledButton>}
            />}
            visible={addMethodModal}
            onCancel={closeModal}
        >
            <Space size={'large'} direction='vertical' style={{ width: '100%' }}>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Form layout="vertical" onFinish={addMethodsToDatasheet} form={form} requiredMark={false}
                    // initialValues={{ selected_trials: trialsForAddMethodOptions.map((opt: any) => opt.value) }}
                    >
                        {/* <Form.Item label={"Select category"} name="category" rules={[{ required: true, message: "required" }]}
                        
                        required 
				        tooltip={requiredFieldStar}
                        >
                            <Select style={{ width: 200 }} filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)} showSearch placeholder='Category' options={methodCascaderOptions} onChange={selectSubCategory} value={selectedCategory} />
                        </Form.Item> */}
                        <Form.Item label={"Select Method"} name="method" rules={[{ required: true, message: "required" }]}
                        
                        required 
				        tooltip={requiredFieldStar}
                        >
                            <Select filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)} showSearch placeholder='Method' onChange={selectMethod} value={selectedMethod} >
                                {methodsOptionsList}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item label={"Select Variation"} name="variation" rules={[{ required: true, message: "required" }]}
                        
                        required 
				        tooltip={requiredFieldStar}
                        >
                            <Select filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)} style={{ width: 200 }} showSearch placeholder='Variation' options={filteredVariationsOptions} onChange={setSelectedMethodVariation} value={selectedMethodVariation} />
                        </Form.Item> */}
                        {/* <Form.Item label={"Select Trials"} name="selected_trials" style={{ marginTop: 20 }} rules={[{ required: true, message: "required" }]}
                        required 
				        tooltip={requiredFieldStar}
                        >
                            <Select mode='multiple' filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)} placeholder='Trials'
                                value={selectedTrialsForAddMethod} defaultValue={selectedTrialsForAddMethod} allowClear
                                options={trialsForAddMethodOptions} onChange={setselectedTrialsForAddMethod} />
                        </Form.Item> */}
                        <Row justify="end" style={{ marginTop: 50 }}>
                            <Space>
                                <StyledButton onClick={closeModal}>{"Cancel"}</StyledButton>
                                <StyledButton htmlType="submit" type="primary">{"Apply"}</StyledButton>
                            </Space>
                        </Row>
                    </Form>
                </Space>
            </Space>
        </Modal>
    )
}
