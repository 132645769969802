import { Col, Form, Input, Modal, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { downloadBatchReportRequest } from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { IMAGE_ANALYSIS_TYPES } from "../SEMAnalysisWrapper";

type TProps = {
    downloadReportModalOpen: boolean;
    setDownloadReportModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedBatch: string | undefined;
    selectedBatchInfo: any;
    selectedIds: string[];
};

const DownloadReportModal = ({
    downloadReportModalOpen,
    setDownloadReportModalOpen,
    selectedBatch,
    selectedBatchInfo,
    selectedIds,
}: TProps) => {
    const dispatch = useDispatch();
    const [downloadReportForm] = Form.useForm();
    const { downloadBatchReportStatus, analysisTypes } = useSelector(
        (state: StoreState) => state.semAnalysis
    );

    useEffect(() => {
        downloadReportForm.resetFields();
        downloadReportForm.setFieldValue(["diameter", "min"], 8);
    }, [selectedBatch, selectedBatchInfo, downloadReportForm]);

    useEffect(() => {
        if (downloadBatchReportStatus === AsyncStates.SUCCESS)
            setDownloadReportModalOpen(false);
    }, [downloadBatchReportStatus, setDownloadReportModalOpen]);

    const exportReport = () => {
        downloadReportForm
            .validateFields({ validateOnly: false })
            .then(() => {
                const payload: any = {
                    batch_id: selectedBatch,
                    file_ids: selectedIds || [],
                    additional_info: {
                        diameter: {
                            min: +downloadReportForm.getFieldValue(["diameter", "min"]),
                        },
                    },
                    analysis_type:
                        analysisTypes[
                        IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
                        ],
                };
                if (downloadReportForm.getFieldValue(["diameter", "max"]))
                    payload.additional_info.diameter.max = +downloadReportForm.getFieldValue([
                        "diameter",
                        "max",
                    ]);

                dispatch(downloadBatchReportRequest(payload));
            })
            .catch((err) => console.error(err));
    };

    return (
        <Modal
            title={"Report Settings"}
            open={downloadReportModalOpen}
            width={600}
            onCancel={() => setDownloadReportModalOpen(false)}
            onOk={exportReport}
            okButtonProps={{
                loading: downloadBatchReportStatus === AsyncStates.LOADING,
            }}
        >
            <Form form={downloadReportForm} layout="vertical">
                <Form.Item label={"Diameter Range"}>
                    <Row gutter={[8, 8]}>
                        <Col>
                            <Form.Item
                                name={["diameter", "min"]}
                                rules={[{ required: true }]}
                            >
                                <Input
                                    type="number"
                                    placeholder="Min"
                                    addonAfter={selectedBatchInfo?.scale?.unit}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name={["diameter", "max"]}>
                                <Input
                                    type="number"
                                    placeholder="Max (Optional)"
                                    addonAfter={selectedBatchInfo?.scale?.unit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default DownloadReportModal;
