import { useCallback } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "./useTranslate";

export function useMemberName() {
    const [t] = useTranslate()

    const members = useSelector((state: StoreState) => state.teams?.data)

    const getName = useCallback(
        (id: string) => members?.find((member: any) => member.user_id === id)?.user_name || t("common.admin"),
        [members,t],
    )

    const getId = useCallback(
        (userName: string) => members?.find((member: any) => member.user_name === userName)?.user_id || t("common.admin"),
        [members,t],
    )

    return { getName, getId }
}
