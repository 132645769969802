import { Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import {
    createIngredientTemplatesRequest,
    updateIngredientTemplatesRequest,
} from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    mode?: "edit" | "create";
    selectedTemplateId?: string;
};

const AddEditIngredientTemplateModal = ({
    showModal,
    setShowModal,
    mode = "create",
    selectedTemplateId,
}: TProps) => {
    const [t] = useTranslate();
    const [templateForm] = Form.useForm();
    const categories = useSelector(
        (state: StoreState) => state.repository.categories
    );
    const dispatch = useDispatch();
    const templates = useSelector(
        (state: StoreState) => state.repository.templates.data
    );
    const templateStatus = useSelector(
        (state: StoreState) => state.repository.templates.status
    );

    useEffect(() => {
        if (mode === "edit" && selectedTemplateId) {
            templateForm.setFieldsValue({
                name: templates.find((t) => t.template_id === selectedTemplateId)?.name,
                category_id: templates.find((t) => t.template_id === selectedTemplateId)
                    ?.category_id,
            });
        } else if (mode === "create") templateForm.resetFields();
    }, [selectedTemplateId, templates, mode, templateForm]);

    useEffect(() => {
        if (templateStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [templateStatus, setShowModal]);

    const submit = () => {
        const payload = templateForm.getFieldsValue();

        if (mode === "create") dispatch(createIngredientTemplatesRequest(payload));
        if (mode === "edit")
            dispatch(
                updateIngredientTemplatesRequest({
                    ...payload,
                    template_id: selectedTemplateId,
                })
            );
    };

    return (
        <Modal
            title={
                <Typography.Title level={4}>{`${mode === "create"
                    ? t("repository.addNewIngredientTemplate")
                    : t("repository.editTemplate")
                    }`}</Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
        >
            <Form
                onFinish={submit}
                form={templateForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name="name"
                    label={t("common.name")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder={t("common.enterName")} />
                </Form.Item>

                <Form.Item
                    name="category_id"
                    label={t("common.category")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        loading={categories.status === AsyncStates.LOADING}
                        placeholder={t("common.category")}
                        allowClear
                        showSearch={true}
                        optionFilterProp="label"
                        options={categories.data.filter(c => c.name).sort((a: any, b: any) => a.name?.localeCompare(b.name)).map((c) => ({
                            label: c.name,
                            value: c.category_id,
                        }))}
                    />
                </Form.Item>

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton
                            type="default"
                            onClick={() => templateForm.resetFields()}
                        >
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={templateStatus === AsyncStates.LOADING}
                            loading={templateStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditIngredientTemplateModal;
