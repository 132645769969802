import { createActions } from "redux-actions"


export enum MultistageHeatmapActionTypes {
  REQUEST = "MULTISTAGE_HEATMAP_REQUEST",
  SUCCESS_OPTIONS = "MULTISTAGE_HEATMAP_SUCCESS_OPTIONS",
  SUCCESS_RESULT = "MULTISTAGE_HEATMAP_SUCCESS_RESULT",
  FAILURE_OPTIONS = "MULTISTAGE_HEATMAP_FAILURE_OPTIONS",
  FAILURE_RESULT = "MULTISTAGE_HEATMAP_FAILURE_RESULT",
  CLEANUP_OPTIONS = "MULTISTAGE_HEATMAP_CLEANUP_OPTIONS",
  CLEANUP_RESULT = "MULTISTAGE_HEATMAP_CLEANUP_RESULT",
  FAILURE = "MULTISTAGE_HEATMAP_FAILURE",
}

export const {
  multistageHeatmapRequest,
  multistageHeatmapSuccessOptions,
  multistageHeatmapSuccessResult,
  multistageHeatmapFailureOptions,
  multistageHeatmapFailureResult,
  multistageHeatmapFailure,
  multistageHeatmapCleanupOptions,
  multistageHeatmapCleanupResult,
} = createActions({
  [MultistageHeatmapActionTypes.REQUEST]: payload => payload,
  [MultistageHeatmapActionTypes.SUCCESS_OPTIONS]: response => response,
  [MultistageHeatmapActionTypes.SUCCESS_RESULT]: response => response,
  [MultistageHeatmapActionTypes.FAILURE_OPTIONS]: error => error,
  [MultistageHeatmapActionTypes.FAILURE_RESULT]: error => error,
  [MultistageHeatmapActionTypes.FAILURE]: error => error,
  [MultistageHeatmapActionTypes.CLEANUP_OPTIONS]: () => ({}),
  [MultistageHeatmapActionTypes.CLEANUP_RESULT]: () => ({}),

})
