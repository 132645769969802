import { useMemo, useState } from "react";
import { Space, Tabs, Typography } from "antd";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import { StyledCard } from "src/styled_components/StyledCard";
import { Route, Switch, useHistory } from "react-router-dom";
import { trackEvent } from "src/analytics";
import Properties from "./Properties/Properties";
// import { StyledButton } from "src/styled_components/StyledButton";
// import { DownOutlined } from "@ant-design/icons";
import Parameters from "./Parameters/Parameters";
import CustomFields from "./CustomFields/CustomFields";
import Additives from "./Additives/Additives";
import Units from "./Units/Units";
import { DownOutlined } from "@ant-design/icons";
import Categories from "./Categories/Categories";
import { TrialsData } from "./WorkOrderParameters/Trials";
import IngredientTemplate from "./IngredientTemplate/IngredientTemplate";

const Repository = () => {
    const [t] = useTranslate();

    const tabsList = useMemo(() => [
        {
            title: t("inventory.IngredientProperties"),
            link: "/inventory/repository/properties",
        },
        {
            title: t("common.ingredientTemplate"),
            link: "/inventory/repository/ingredient-template",
        },
        {
            title: t("common.propertyParameters"),
            link: "/inventory/repository/parameters",
        },
        {
            title: t("projects.customFields"),
            link: "/inventory/repository/custom-fields",
        },
        {
            title: t("inventory.Additives"),
            link: "/inventory/repository/additives",
        },
        {
            title: t("common.units"),
            link: "/inventory/repository/units",
        },
        {
            title: t("common.Categories"),
            link: "/inventory/repository/categories",
        },
        {
            title: t("common.workOrderParameters"),
            link: "/inventory/repository/work-order-parameters",
        }
    ], [t]);

    const { push, location } = useHistory();
    const [activeTabKey] = useState<number>(
        tabsList.findIndex((t) => t.link === location.pathname)
    );
    const userId = useSelector(
        (state: StoreState) => state.login.loginResponse.user_id
    );

    return (
        <Space
            direction="vertical"
            size="large"
            className="gap-8"
            style={{ width: "100%" }}
        >
            <StyledPageHeader
                ghost={false}
                title={
                    <Space direction="vertical">
                        <Typography.Title level={3} style={{ marginBottom: 0 }}>
                            {t("common.repository")}
                        </Typography.Title>
                    </Space>
                }
            // extra={
            //     <StyledButton
            //         type="primary"
            //         style={{ borderRadius: "6px" }}
            //         size="large"
            //     >
            //         {t("common.upload")}
            //         <DownOutlined />
            //     </StyledButton>
            // }
            />
            <div style={{ marginTop: "20px" }}>
                <Tabs
                    onChange={(e) => {
                        trackEvent(
                            userId,
                            "Inventory -> Repository -> " + tabsList[+e].title + " was viewed"
                        );
                        push(tabsList[+e].link);
                    }}
                    activeKey={String(activeTabKey)}
                    moreIcon={<div style={{ color: "#1677ff" }}><DownOutlined /></div>}
                    size="large"
                    style={{ padding: "1rem", paddingTop: "0" }}
                    items={tabsList.map((t, idx) => ({
                        key: String(idx),
                        label: t.title,
                    }))}
                />
            </div>
            <StyledCard bodyStyle={{ padding: "0 16px" }}>
                <Switch>
                    <Route path="/inventory/repository/properties">
                        <Properties />
                    </Route>
                    <Route path="/inventory/repository/ingredient-template">
                        <IngredientTemplate />
                    </Route>
                    <Route path="/inventory/repository/parameters">
                        <Parameters />
                    </Route>
                    <Route path="/inventory/repository/custom-fields">
                        <CustomFields />
                    </Route>
                    <Route path="/inventory/repository/additives">
                        <Additives />
                    </Route>
                    <Route path="/inventory/repository/units">
                        <Units />
                    </Route>
                    <Route path="/inventory/repository/categories">
                        <Categories />
                    </Route>
                    <Route path="/inventory/repository/work-order-parameters">
                        <TrialsData />
                    </Route>
                </Switch>
            </StyledCard>
        </Space>
    );
};

export default Repository;
