import _ from "lodash"
import { handleActions } from "redux-actions"
import { AsyncStates } from "src/constants"
import {
	FetchVersionListActionTypes,
	NewInverseModelActionTypes,
} from "src/store/actions/newInverseModel"

export type NewInverseModelReducerState = {
	inverseStatus: AsyncStates
	error: string
	newInverseModel: any,
	inverseModelTasks: any[],
	ack: any
	versionListStatus: AsyncStates
	inverseVersionList: any[]
	versionListError: string
	currentSelectedStage: any
}

const defaultState: NewInverseModelReducerState = {
	inverseStatus: AsyncStates.INITIAL,
	error: "",
	newInverseModel: {},
	inverseModelTasks: [],
	ack: {},
	versionListStatus: AsyncStates.INITIAL,
	inverseVersionList: [],
	versionListError: "",
	currentSelectedStage: 1
}

const newInverseModelReducer = handleActions(
	{
		[NewInverseModelActionTypes.REQUEST]: (state) => ({
			...state,
			inverseStatus: AsyncStates.LOADING,
		}),
		[NewInverseModelActionTypes.SUCCESS]: (state, action) => ({
			...state,
			inverseStatus: AsyncStates.SUCCESS,
			newInverseModel: action.payload,
			inverseModelTasks: [
				...state.inverseModelTasks,
				action.payload
			]
		}),
		[NewInverseModelActionTypes.FILTER]: (state, action) => {
			const cloneState = _.cloneDeep(state);
			return {
				...state,
				inverseModelTasks: cloneState.inverseModelTasks.filter(t => t.prediction_id !== action.payload)
			}
		},
		[NewInverseModelActionTypes.FAILURE]: (state, action) => ({
			...state,
			inverseStatus: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[NewInverseModelActionTypes.NEW_INVERSE_ACK]: (state, action: any) => {
			return {
				...state,
				ack: action.payload,
			}
		},
		[FetchVersionListActionTypes.REQUEST]: (state) => ({
			...state,
			versionListStatus: AsyncStates.LOADING,
		}),
		[FetchVersionListActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				versionListStatus: AsyncStates.SUCCESS,
				inverseVersionList: action.payload,
			}
		},
		[FetchVersionListActionTypes.FAILURE]: (state, action) => ({
			...state,
			versionListStatus: AsyncStates.ERROR,
			versionListError: action.payload.error,
		})
	},
	defaultState
)

export default newInverseModelReducer
