import { useState } from "react";
import { StoreState } from "../../../store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import { Space, Select, Input, List, Typography, Popover, Form, Popconfirm, message } from "antd";
import {
	reportDeleteRequest,
	reportShareRequest,
} from "../../../store/actions/compareFormulations"
import { AsyncStates } from "../../../constants"
import useTranslate from "src/utils/useTranslate"
import { celsureReportDeleteRequest } from "src/store/actions/celsureCompareFormulation"
import { celsureWoDeleteReportRequest } from "src/store/actions/celsureReportPreview"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
import { StyledButton } from "src/styled_components/StyledButton"

export const ListItem = ({
	report,
	from,
	setLink,
	setReportName,
	workOrderId,
}: any) => {
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const requiredFieldStar = useRequiredFieldStar()

	const teamsData = useSelector((state: StoreState) => state.teams.data || [])
	const { project_type } = useSelector(
		(state: StoreState) => state.login.loginResponse
	)

	const [isSharePopoverVisible, setisSharePopoverVisible] = useState(false)

	let shareStatus: AsyncStates

	const share = (values: any) => {
		dispatch(
			reportShareRequest({
				...values,
				report_id: report.report_id,
			})
		)
		setisSharePopoverVisible(false)
	}

	const deleteReport = () => {
		if (from === "compare_formulation") {
			dispatch(celsureReportDeleteRequest({ report_id: report.report_id }))
		} else if (!!workOrderId && project_type === "celsure") {
			dispatch(
				celsureWoDeleteReportRequest({
					woReportId: report.wo_report_id,
					workOrderId: workOrderId,
				})
			)
		} else {
			dispatch(reportDeleteRequest({ report_id: report.report_id }))
		}
		setLink("")
	}

	const view = () => {
		setLink(report.download_link)
		setReportName(report.filename)
		setTimeout(() => {
			document
				.getElementById("reports")
				?.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
					inline: "center",
				})
		}, 100)
	}

	const shareForm = (assignedRoles: string[], userId: string) => (
		<Form
			name="basic"
			layout="vertical"
			onFinish={share}
		// onFinishFailed={onFinishFailed}
			requiredMark={false}
		>
			<Form.Item
				label={t("common.members")}
				name="receiver_ids"
				rules={[
					{ required: true, message: t("formulation.pleaseSelectMember") },
				]}
				required tooltip={requiredFieldStar}
			>
				<Select
					mode="multiple"
					style={{ width: 300 }}
					defaultValue={assignedRoles}
				>
					{teamsData.map(({ user_id, user_name }: any) => (
						<Select.Option value={user_id} key={user_id}>
							{String(user_name)}
						</Select.Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item
				label={t("formulation.message")}
				name="message"
				rules={[
					{ required: true, message: t("formulation.pleaseEnterAMessage") },
				]}
				required tooltip={requiredFieldStar}
			>
				<Input.TextArea />
			</Form.Item>
			<Form.Item>
				<StyledButton
					type="primary"
					htmlType="submit"
					loading={shareStatus === AsyncStates.LOADING}
				>
					{t("common.send")}
				</StyledButton>
			</Form.Item>
		</Form>
	)
	return (
		<List.Item
			extra={
				<Space>
					{from === "formulations_list" ? (
						<StyledButton
							onClick={() => {
								setLink(report.download_link)
								setReportName(report.filename)
								message.success("Scroll down to view the report")
							}}
						>
							{t("formulations.button.view")}
						</StyledButton>
					) : (
						<>
							<StyledButton onClick={view}>{t("formulations.button.view")}</StyledButton>
						</>
					)}
					<StyledButton type="link" size="small">
						<a href={report.download_link} target="_blank" rel="noreferrer">
							{t("notifications.download")}
						</a>
					</StyledButton>
					{project_type !== "celsure" && (
						<Popover
							content={shareForm}
							trigger="click"
							placement="leftTop"
							overlayStyle={{ width: 400 }}
							open={isSharePopoverVisible}
							onOpenChange={setisSharePopoverVisible}
						>
							<StyledButton type="link" size="small">
								{t("common.send")}
							</StyledButton>
						</Popover>
					)}
					<Popconfirm
						okText={t("common.ok")}
						cancelText={t("common.cancel")}
						title={`${t("common.confirm")}?`}
						onConfirm={deleteReport}
					>
						<StyledButton size="small" type="link">
							{t("common.delete")}
						</StyledButton>
					</Popconfirm>
				</Space>
			}
		>
			<Typography.Text>{report.filename}</Typography.Text>
		</List.Item>
	)
}
