import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { keyColumn, floatColumn, Column, CellProps } from "react-datasheet-grid"
import { InputNumber, Select, Space, SelectProps, Divider } from "antd"
import Checkbox from "antd/lib/checkbox/Checkbox"
import { AsyncStates } from "src/constants"
import { setCelsureEditingTrialsState } from "src/store/actions/celsure"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"
import { PCM, TabType } from "./types"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { nanoid } from "nanoid"
import { ColorInput } from "./ColorInput"
import { StyledButton } from "src/styled_components/StyledButton"
// import { pcmEnthalpyMapping } from "./utils"

type DefaultOptionType = SelectProps<string>["options"]

const { Option } = Select

interface useDataSheetGridProps {
	data: PCM[]
	setData: Dispatch<SetStateAction<PCM[]>>
	setModalVisible?: React.SetStateAction<any>
}

interface useDataSheetGridState {
	copyAndAddRow: () => void
	onRowsChange: (data: PCM[]) => void
	removeRows: () => void
	selectedRows: PCM[]
	columns: Column[]
	addRow: () => void
}

const positions = ["back", "bottom", "front", "left", "right", "top"] as const
type FieldsWithOptions = "pcm_type" | "size_type" | "tab_type"

export const useTable = ({
	data,
	setData,
	setModalVisible
}: useDataSheetGridProps): useDataSheetGridState => {
	const dispatch = useDispatch()
	const {
		celsureEditingTrialsState,
		celsureDisplayNamesData = {},
		celsureDisplayNamesStatus,
	} = useSelector((state: StoreState) => state.celsure)
	const [selectedRows, setSelectedRows] = useState<PCM[]>([])
	const [t] = useTranslate()

	const generateOptionalFields = useCallback(
		(identifier: FieldsWithOptions): DefaultOptionType => {
			const data = Object.values(
				celsureDisplayNamesData[identifier] || {}
			).map((value) => ({ value, label: value })) as DefaultOptionType

			if (identifier === "pcm_type") {
				return Object.values(
					celsureDisplayNamesData[identifier] || {}
				).map(({ name }: any) => ({ label: name, value: name })) || []
			}

			return data
		},
		[celsureDisplayNamesData]
	)

	const dropDownMenu = useCallback((menu: any, identifier: string) => (
		<div>
			{menu}
			<Divider style={{ margin: "4px 0" }} />
			<StyledButton
				onClick={() => setModalVisible({ visible: true, identifier })}
				type="link"
			>
				{t("common.newCustomParameter")}
			</StyledButton>
		</div>
	), [t, setModalVisible])

	useEffect(() => {
		setSelectedRows(data.filter((res) => res.select))
	}, [data])

	const copyAndAddRow = () => {
		if (!celsureEditingTrialsState) dispatch(setCelsureEditingTrialsState(true))

		setData(() => {
			const duplicateRows = selectedRows.map((row) => ({
				...row,
				id: nanoid(),
			}))
			return [...data, ...duplicateRows]
		})
		setSelectedRows([])
	}

	const removeRows = () => {
		if (!celsureEditingTrialsState) dispatch(setCelsureEditingTrialsState(true))
		setData(data.filter((row) => !row.select))
		setSelectedRows([])
	}

	const onRowsChange = (data: PCM[]) => {
		if (!celsureEditingTrialsState) dispatch(setCelsureEditingTrialsState(true))
		setData(
			data.map((pcm) => {
				// const pcmType = pcm.pcm_type?.toUpperCase() as PCM["pcm_type"]
				const enthalpy_melting = Object.entries(celsureDisplayNamesData.pcm_type || {})
					.find(([key, value]: any) => key === pcm.pcm_type || value?.name === pcm.pcm_type)?.[1]?.meta?.enthalpy_melting
				const enthalpy = !pcm.pcm_type || !pcm.no_of_packs || !pcm.quantity || !enthalpy_melting
					? 0 : (enthalpy_melting ?? 0) * pcm.quantity * pcm.no_of_packs
				return { ...pcm, enthalpy }
			})
		)
	}

	const addRow = () => {
		if (!celsureEditingTrialsState) dispatch(setCelsureEditingTrialsState(true))

		setData((prevState: any) => [
			...prevState,
			{
				id: nanoid(),
				pcm_type: "",
				size_type: "",
				cells_no: "",
				length: "",
				width: "",
				thickness: "",
				position: "",
				quantity: "",
				initial_temperature: "",
				thawing: "",
				enthalpy: "",
				ambient_temp: "",
				color: "#a57633",
				select: false,
				no_of_packs: null
			},
		])
	}

	const handleSelectAll = useCallback(
		(e: CheckboxChangeEvent) => {
			if (e.target.checked) {
				setSelectedRows(data)
				setData(data.map((ele) => ({ ...ele, select: true })))
			} else {
				setSelectedRows([])
				setData(data.map((ele) => ({ ...ele, select: false })))
			}
		},
		[data, setData]
	)

	const columns: Column[] = useMemo(() => {
		if (celsureDisplayNamesStatus !== AsyncStates.SUCCESS) return []

		return [
			{
				title: (
					<Space>
						<Checkbox
							checked={selectedRows.length > 0}
							onChange={handleSelectAll}
						></Checkbox>
					</Space>
				),
				component: (row: CellProps<PCM>) => (
					<Checkbox
						style={{ margin: "0 auto" }}
						checked={row.rowData.select}
						onChange={(e) => {
							row.setRowData({ ...row.rowData, select: e.target.checked })
						}}
					/>
				),
			},

			{
				key: "pcm_type",
				title: "Pcm Type",
				component: (row: CellProps<PCM>) => {
					return (
						<Select
							showSearch
							bordered={false}
							style={{ width: "100%" }}
							value={
								celsureDisplayNamesData["pcm_type"]?.[row.rowData.pcm_type]?.name ??
								row.rowData.pcm_type
							}
							onChange={(value) =>
								row.setRowData({ ...row.rowData, pcm_type: value })
							}
							options={generateOptionalFields("pcm_type")}
							dropdownRender={(menu) => dropDownMenu(menu, "pcm_type")}
							dropdownStyle={{ minWidth: 300 }}
						/>
					)
				},
			},

			{
				key: "size_type",
				title: t("common.type"),
				component: (row: CellProps<PCM>) => (
					<Select
						showSearch
						bordered={false}
						style={{ width: "100%" }}
						value={
							celsureDisplayNamesData["size_type"]?.[row.rowData.size_type] ??
							row.rowData.size_type
						}
						onChange={(value: PCM["size_type"]) => {
							if (value === "Tab") {
								row.setRowData({
									...row.rowData,
									size_type: value,
									cells_no: "tab2200",
								})
							} else {
								row.setRowData({
									...row.rowData,
									size_type: value,
									cells_no: 1,
								})
							}
						}}
						options={generateOptionalFields("size_type")}
						dropdownRender={(menu) => dropDownMenu(menu, "size_type")}
						dropdownStyle={{ minWidth: 300 }}
					/>
				),
			},

			{
				title: "No. of Cells / Tab type",
				width: "100%",
				component: (row: CellProps<PCM>) => {
					if (row.rowData.size_type !== "Tab") {
						return (
							<InputNumber
								bordered={false}
								onChange={(value: any) => {
									if (row.rowData.size_type !== "Tab") {
										row.setRowData({ ...row.rowData, cells_no: value })
									}
								}}
								value={row.rowData.cells_no}
							/>
						)
					}
					return (
						<Select
							showSearch
							bordered={false}
							style={{ width: "100%" }}
							value={row.rowData.cells_no}
							onChange={(value: TabType) => {
								if (row.rowData.size_type === "Tab") {
									row.setRowData({ ...row.rowData, cells_no: value })
								}
							}}
							options={generateOptionalFields("tab_type")}
							dropdownRender={(menu) => dropDownMenu(menu, "tab_type")}
							dropdownStyle={{ minWidth: 300 }}
						/>
					)
				},
			},
			{
				...keyColumn("length", floatColumn),
				title: "Length(mm)",
			},
			{
				...keyColumn("width", floatColumn),
				title: "Width(mm)",
			},
			{
				...keyColumn("thickness", floatColumn),
				title: "Thickness(mm)",
			},
			{
				key: "position",
				title: "Position",
				component: (row: CellProps<PCM>) => (
					<Select
						showSearch
						bordered={false}
						style={{ width: "100%" }}
						value={row.rowData.position}
						onChange={(value) => {
							row.setRowData({ ...row.rowData, position: value })
						}}
					>
						{positions.map((position) => {
							return (
								<Option value={position}>
									{position[0].toUpperCase()}
									{position.slice(1)}
								</Option>
							)
						})}
					</Select>
				),
			},
			{
				...keyColumn("no_of_packs", floatColumn),
				title: "No. of packs",
			},
			{
				...keyColumn("quantity", floatColumn),
				title: "Quantity/pack (kg)",
			},
			{
				...keyColumn("enthalpy", floatColumn),
				title: "PCM Energy (kJ)",
				disabled: true,
			},
			{
				...keyColumn("initial_temperature", floatColumn),
				title: "Initial Temperature(°C)",
			},
			{
				...keyColumn("thawing", floatColumn),
				title: "Thawing Time(hours)",
			},
			{
				...keyColumn("ambient_temp", floatColumn),
				title: "Ambient Temperature(°C)",
			},
			{
				title: "Color",
				width: "100%",
				minWidth: 150,
				component: (row: CellProps<PCM>) => (
					<ColorInput
						defaultValue={row.rowData.color}
						data={row.rowData}
						setData={row.setRowData}
					/>
				),
			},
		]
	}, [
		celsureDisplayNamesData,
		celsureDisplayNamesStatus,
		generateOptionalFields,
		selectedRows?.length,
		t,
		handleSelectAll,
		dropDownMenu
	])

	return {
		copyAndAddRow,
		onRowsChange,
		removeRows,
		selectedRows,
		columns,
		addRow,
	}
}
