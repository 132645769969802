import { handleActions } from "redux-actions";
import { UpdateExperimentUnitActionTypes } from "../actions/woUnitConversion";
import { AsyncStates } from "src/constants";

export type ExperimentUnitConversionState = {
    updateExperimentWithUnitStatus: AsyncStates,
    updateExperimentWithUnitError: string | null,
    unitConvertedData: any[]
};

const defaultState: ExperimentUnitConversionState = {
    updateExperimentWithUnitStatus: AsyncStates.INITIAL,
    updateExperimentWithUnitError: "",
    unitConvertedData: []
};

const woExperimentUnitConversionReducer = handleActions<any>({
    [UpdateExperimentUnitActionTypes.REQUEST]: (state) => ({
        ...state,
        updateExperimentWithUnitStatus: AsyncStates.LOADING,
        updateExperimentWithUnitError: "",
    }),
    [UpdateExperimentUnitActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            updateExperimentWithUnitStatus: AsyncStates.SUCCESS,
            unitConvertedData: action.payload.response
        }
    },
    [UpdateExperimentUnitActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updateExperimentWithUnitStatus: AsyncStates.ERROR,
        updateExperimentWithUnitError: action.payload,
    }),
    [UpdateExperimentUnitActionTypes.CLEANUP]: (state, action) => ({
        ...state,
        updateExperimentWithUnitStatus: AsyncStates.INITIAL,
        updateExperimentWithUnitError: "",
        unitConvertedData: []
    }),
}, defaultState);

export default woExperimentUnitConversionReducer;