import {
	useState,
	useEffect,
	useMemo,
	useCallback,
} from "react"
import { FormulationSearchResults } from "../../FormulationSearchResults"
import { useDispatch, useSelector } from "react-redux"
import {
    Form,
    Input,
    Select,
    Spin,
    Space,
    Typography,
    Row,
    Checkbox,
    Divider,
    Menu,
    Popover,
    Tooltip,
    Empty,
} from "antd";
import { DownOutlined, FilterOutlined, HistoryOutlined, LoadingOutlined } from "@ant-design/icons";
import { StoreState } from "../../../store/configureStore"
import { useDebounce } from "../../../utils/useDebounce"
import { AsyncStates } from "src/constants"
import { blue } from "@ant-design/colors"
import useTranslate from "src/utils/useTranslate"
import "./SearchFormulations.scss"
import { filteredDisplayNamesRequest } from "src/store/actions/displayNames"
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledButton } from "src/styled_components/StyledButton"
import { fetchSuggestionsAPi } from "src/services/formulation"
import { useHighlightOptionTags } from "src/utils/useHighlightOptionTags"
import { workOrdersRequest } from "src/store/actions/workOrders"
import { NewFilterForm } from "../SavedFilters/NewFilterForm"
import { FiltersHistoryDrawer } from "../SavedFilters"

import { fetchMethodListRequest } from "src/store/actions/characterizationMethods";
import jwtManager from "src/utils/jwtManager"
import { listProcessingProfilesRequest } from "src/store/actions/workOrderDetails"
import AdvanceFilters from "./AdvanceFilters";
import FilterButtons from "./FilterButtons";
import { setIsEditing } from "src/store/actions/isEditing";
import { fetchAllMethodsRequest } from "src/store/actions/repository";
import StyledBadge from "src/styled_components/StyledBadge";

const { Text } = Typography

interface IState {
	data: any[]
	value: any[] | string
	fetching: boolean
}

const SearchFormulation = () => {
	const [t] = useTranslate()
	const dispatch = useDispatch()

	const badgeColors = useMemo(
		() => ({
			ingredients: "#43aa8b",
			processing_profile: "#F3722C",
			processing: "#40bfbf",
			characterizations: "#648ba8",
			characterization_method: "#126782",
			characterization_method_parameters: "#023047",
			properties: "#e26d5c",
			characterization_method_properties: "#7400b8",
			work_order_id: "#555b6e",
			stage: "#bc6c25",
			user_id: "#42b96c",
			application: "#52796f",
			order_type: "#ffd100",
			grade: "#ff6700",
			material: "#65318e",
			lab_locations: "#6495ED"
		}),
		[],
	);

	const configs = useSelector((state: StoreState) => state.configs.features)
	const apiFilterFormulationsStatus = useSelector(
		(state: StoreState) => state.formulationsFilter.status
	)
	const { current: project_id, projectList } = useSelector(
		(state: StoreState) => state.projects
	)

	const [projectInput, setProjectInput] = useState("")
	const filteredProjects = useMemo(
		() =>
			projectList.filter((project: any) =>
				!projectInput?.trim()
					? true
					: project?.name
						?.toLowerCase()
						?.includes(projectInput?.toLocaleLowerCase())
			),
		[projectList, projectInput]
	)

	const [state, setState] = useState<IState>({
		data: [],
		value: [],
		fetching: false,
	})
	const { fetching, data, value } = state
	const debouncedSearchTerm = useDebounce(value, 300)
	const [outerSelector, setOuterSelector] = useState<string>()
	const [innerSelector, setInnerSelector] = useState<string[]>([])
	const [selectedProjects, setSelectedProjects] = useState<string[]>([
		project_id,
	])

	const [selectedLabLocations, setSelectedLabLocations] = useState<string[]>([])
	const [selectedWorkOrderType, setSelectedWorkOrderType] = useState<string>();
	const [currentInnerIndex, setCurrentInnerIndex] = useState<number>(0);

	const labLocationsList = useMemo(() => {
		return projectList.filter(project => selectedProjects.includes(project?.project_id)).map(project => {
			return project?.lab_locations || []
		}).flat().filter((value, index, self) => self.findIndex(v => v.lab_location_id === value.lab_location_id) === index);
	}, [projectList, selectedProjects])

	useEffect(() => {
		const labLocationsIds = labLocationsList.map(({ lab_location_id }: any) => lab_location_id)
		setSelectedLabLocations(
			(prevState: string[]) => {
				return prevState.filter(ll => labLocationsIds.includes(ll))
			}
		)
	}, [labLocationsList])

	const [labLocationsInput, setLabLocationsInput] = useState("")

	const filteredLabLocations = useMemo(
		() =>
			labLocationsList.filter((ll: any) =>
				!labLocationsInput?.trim()
					? true
					: ll?.name
						?.toLowerCase()
						?.includes(labLocationsInput?.toLocaleLowerCase())
			),
		[labLocationsList, labLocationsInput]
	)

	const [searchList, setSearchList] = useState<any[]>([])
	const [filters, setFilters] = useState<any>({})
	const [formState, setFormState] = useState<any>({})
	const [form] = Form.useForm()
	const [createNewFilterModalVisible, setCreateNewFilterModalVisible] = useState(false)
	const [filtersLoading, setFiltersLoading] = useState(false)
	const options = useHighlightOptionTags({ data, searchStr: value })
	const [filterHistoryDrawerVisible, setFilterHistoryDrawerVisible] =
		useState(false);
	const [advanceFiltersDrawerVisible, setAdvanceFiltersDrawerVisible] =
		useState(false);

	const clearFilters = useCallback(() => {
		setFiltersLoading(true)
		setFilters({})
		setCurrentInnerIndex(0);
		setFormState({})
		setInnerSelector([])
		setOuterSelector("")
		form.resetFields()
		dispatch(setIsEditing(false))
	}, [dispatch, form])

	useEffect(() => {
		if (
			[AsyncStates.SUCCESS, AsyncStates.ERROR].includes(
				apiFilterFormulationsStatus
			)
		) {
			setFiltersLoading(false)
		}
	}, [apiFilterFormulationsStatus])

	useEffect(() => {
		dispatch(fetchMethodListRequest({ method_type: "characterizations" }))
		dispatch(workOrdersRequest({ partial: true, status: "closed" }))
		dispatch(fetchAllMethodsRequest())
	}, [dispatch])

	useEffect(() => {
		if (Boolean(configs?.processing_profiles)) {
			dispatch(listProcessingProfilesRequest())
		}
	}, [dispatch, configs])

	useEffect(() => {
		dispatch(
			filteredDisplayNamesRequest(
				!project_id?.length ? {} : { project_id: project_id }
			)
		)
	}, [dispatch, project_id])

	useEffect(() => {
		setSelectedProjects([project_id])
		clearFilters()
	}, [project_id, clearFilters])

	useEffect(() => {
		if (
			typeof debouncedSearchTerm === "string" &&
			debouncedSearchTerm.length >= 3
		) {
			const headers = {
				token: jwtManager.getToken(),
			}
			const requestBody = {
				search_term: debouncedSearchTerm,
				...(projectList?.length !== selectedProjects?.length && { project_id: selectedProjects })
			}
			setState((state) => ({ ...state, fetching: true, data: [] }))
			fetchSuggestionsAPi(requestBody, headers)
				.then((results: []) => {
					results.length
						? setState((state) => ({
							...state,
							fetching: false,
							data: results,
						}))
						: setState((state) => ({
							...state,
							fetching: false,
							data: [{ text: "No similar results found.", disabled: true }],
						}))
				})
				.catch(() =>
					setState((state) => ({
						...state,
						fetching: false,
						data: [
							{ text: t("formulationFilter.UnableToConnect"), disabled: true },
						],
					}))
				)
		} else {
			setState((state) => ({
				...state,
				data: [
					{
						text: `${t("common.typeIn")} ${3 - state.value.length} ${t(
							"common.more"
						)} ${state.value.length === 2
							? t("common.character")
							: t("common.characters")
							} ${t("common.forSimilarResult")}`,
						disabled: true,
					},
				],
			}))
		}
	}, [debouncedSearchTerm, configs, t, projectList, selectedProjects])

	const handleChange = (values: any[]) => {
		setState((state) => ({
			...state,
			value: "",
			data: [],
			fetching: false,
		}))

		const payload = values.map((res) => state?.data?.find((stateData) => stateData.value === res.value)?.meta_data)?.filter((res) => {
			if (!res) {
				return false
			}
			if (searchList.some((searchedItem) => searchedItem.parameter_id === res.parameter_id)) {
				return false
			}
			return true
		})
		setSearchList((prev) => [...prev, ...payload])
	}

	const handleSearch = (value: string) => {
		if (value.length >= 3) {
			setState((state) => ({
				...state,
				value,
			}))
		} else {
			setState((state) => ({ ...state, data: [], value }))
		}
	}

	const onBlur = () => {
		setState((state) => ({ ...state, value: "" }));
	}


	const projectDropDownMenu = useMemo(() => (
		<Space style={{ width: "100%" }} size="small" direction="vertical">
			<Text strong type="secondary">
				{t("common.viewFormulationsFromProjects")}
			</Text>
			<Input.Search
				placeholder={t("projects.searchProjects")}
				onChange={(e) => setProjectInput(e.target.value)}
			/>
			<Menu style={{ padding: 5, maxHeight: 500, overflowY: "auto" }}>
				<Menu.Item key={"select_all"}>
					<Checkbox
						checked={selectedProjects.length === projectList.length}
						onClick={(e) => e.stopPropagation()}
						onChange={(e) => {
							e.target.checked
								? setSelectedProjects(
									projectList.map(({ project_id }) => project_id)
								)
								: setSelectedProjects([project_id])
							clearFilters()
						}}
					>
						{t("common.viewAllProjects")}
					</Checkbox>
				</Menu.Item>
				<Divider style={{ marginTop: 2, marginBottom: 2 }} />
				{filteredProjects.map(({ project_id, name }) => (
					<Menu.Item key={project_id}>
						<Checkbox
							onClick={(e) => e.stopPropagation()}
							checked={selectedProjects.includes(project_id)}
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedProjects((prevState) => [
										...prevState,
										project_id,
									])
								} else {
									setSelectedProjects((prevState) =>
										prevState.filter((res) => res !== project_id)
									)
								}
								clearFilters()
							}}
						>
							{name}
						</Checkbox>
					</Menu.Item>
				))}
			</Menu>
		</Space>
	), [filteredProjects, projectList, project_id, selectedProjects, clearFilters, t])

	const labLocationsDropDownMenu = useMemo(() => (
		<Space style={{ width: "100%" }} size="small" direction="vertical">
			{labLocationsList.length > 0 && <Input.Search
				placeholder={t("common.search")}
				onChange={(e) => setLabLocationsInput(e.target.value)}
			/>}
			<Menu style={{ padding: 5, maxHeight: 500, overflowY: "auto" }}>
				{labLocationsList.length > 0 ? <Menu.Item key={"select_all"}>
					<Checkbox
						checked={selectedLabLocations.length === labLocationsList.length}
						onClick={(e) => e.stopPropagation()}
						onChange={(e) => {
							e.target.checked
								? setSelectedLabLocations(
									labLocationsList.map(({ lab_location_id }: any) => lab_location_id)
								)
								: setSelectedLabLocations([])
						}}
					>
						{t("common.selectAll")}
					</Checkbox>
				</Menu.Item> : <Empty />}
				<Divider style={{ marginTop: 2, marginBottom: 2 }} />
				{filteredLabLocations.map(({ lab_location_id, name }) => (
					<Menu.Item key={lab_location_id}>
						<Checkbox
							onClick={(e) => e.stopPropagation()}
							checked={selectedLabLocations.includes(lab_location_id)}
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedLabLocations((prevState) => [
										...prevState,
										lab_location_id,
									])
								} else {
									setSelectedLabLocations((prevState) =>
										prevState.filter((res) => res !== lab_location_id)
									)
								}
							}}
						>
							{name}
						</Checkbox>
					</Menu.Item>
				))}
			</Menu>
		</Space>
	), [t, selectedLabLocations, labLocationsList, filteredLabLocations])

	const applyFilters = (values: any) => {
		const payload = {
			selector: outerSelector || "and",
			data: values?.outer_conditions?.reduce(
				(array: any[], element: any, index: number) => [
					...array,
					{
						selector: innerSelector?.[index] || "and",
						data: element?.inner_conditions?.map(
							(res: any) => {
								return ({
									...res,
									parameter:
										typeof res.parameter === "string"
											? res.parameter
											: res?.parameter?.[2] || res?.parameter?.[1] || res?.parameter?.[0],
									parameter_type:
										typeof res.parameter === "string"
											? res.parameter_type
											: res?.parameter?.[0],
									category: typeof res.parameter === "string" ? null : res?.parameter?.[1],
									...((typeof res.parameter !== "string" && ["characterization_method_parameters", "characterization_method_properties"]?.includes(res?.parameter?.[0])) && {
										parameter_method_value: res?.parameter?.[2],
										variation: res.parameter?.[1]
									}),
									// ...(res?.parameter?.[0] === "stage" && ({ val: workOrdersList.map((wo: any) => res?.val.map((id: any) => wo?.stages?.[id]?.identifier)).flatMap((id: any) => id).filter((id: any) => !!id) }))
								})
							}
						),
					},
				],
				[]
			),
		}
		setFilters(payload)
		setFiltersLoading(true)
	}

	const handleDeselect = (values: any) => {
		setSearchList((prev) => prev.filter((res) => res.parameter_id !== values.value))
	}

	return (
		<Space size="middle" direction="vertical" style={{ width: "100%" }}>
			<FiltersHistoryDrawer
				visible={filterHistoryDrawerVisible}
				setFilters={setFilters}
				setVisible={setFilterHistoryDrawerVisible}
				form={form}
				setInnerSelector={setInnerSelector}
				setOuterSelector={setOuterSelector}
				setFormState={setFormState}
			/>

			<AdvanceFilters
				visible={advanceFiltersDrawerVisible}
				setVisible={setAdvanceFiltersDrawerVisible}
				selectedProjects={selectedProjects}
				form={form}
				applyFilters={applyFilters}
				clearFilters={clearFilters}
				formState={formState}
				setFormState={setFormState}
				filtersLoading={filtersLoading}
				outerSelector={outerSelector}
				setOuterSelector={setOuterSelector}
				innerSelector={innerSelector}
				setInnerSelector={setInnerSelector}
			/>

			<NewFilterForm
				visible={createNewFilterModalVisible}
				setVisible={setCreateNewFilterModalVisible}
				filters={filters}
			/>

			<StyledCard>
				<Row align="middle" justify="space-around" style={{
					gap: 8,
					marginBottom: '12px'
				}}>
					<Select
						loading={fetching}
						mode="multiple"
						labelInValue
						placeholder={t(
							"formulations.placeholder.searchFromAnyCategory",
						)}
						notFoundContent={
							fetching ? (
								<Spin indicator={<LoadingOutlined />} size="small" />
							) : null
						}
						filterOption={false}
						onSearch={handleSearch}
						onChange={handleChange}
						onDeselect={handleDeselect}
						onBlur={onBlur}
						size="middle"
						allowClear
						bordered={false}
						style={{
							border: "1px solid " + blue.primary,
							borderRadius: 8,
							flexGrow: 1
						}}
						onClear={() => setSearchList([])}
					>
						{options}
					</Select>
					<Popover
						content={projectDropDownMenu}
						placement="bottom"
						trigger="click"
					>
						<StyledButton
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
							type="default"
							size="middle"
						>
							<Text
								ellipsis={{
									tooltip:
										selectedProjects?.length > 1
											? "Multiple Projects"
											: projectList.find(
												(res: any) =>
													res?.project_id === selectedProjects?.[0],
											)?.name || "No Project selected",
								}}
								strong
							>
								{selectedProjects?.length > 1
									? "Multiple Projects"
									: projectList.find(
										(res: any) =>
											res?.project_id === selectedProjects?.[0],
									)?.name || "No Project selected"}
							</Text>
							<DownOutlined />
						</StyledButton>
					</Popover>
					<Popover
						content={labLocationsDropDownMenu}
						placement="bottom"
						trigger="click"
					>
						<StyledButton
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
							type="default"
							size="middle"
						>
							<Text
								ellipsis={{
									tooltip:
										selectedLabLocations?.length > 1
											? "Multiple Lab Locations"
											: labLocationsList.find(
												(res: any) =>
													res?.id === selectedLabLocations?.[0],
											)?.name || t("common.selectLabLocations"),
								}}
								strong
							>
								{selectedLabLocations?.length > 1
									? "Multiple Lab Locations"
									: labLocationsList.find(
										(res: any) =>
											res?.lab_location_id === selectedLabLocations?.[0],
									)?.name || t("common.selectLabLocations")}
							</Text>
							<DownOutlined />
						</StyledButton>
					</Popover>
					<Select
						className="work-order-type-selection"
						placeholder={t("workorder.type")}
						onChange={setSelectedWorkOrderType}
						allowClear={true}
						options={[
							{ value: 'Single Stage', label: 'Single Stage' },
							{ value: 'Multi Stage', label: 'Multi Stage' }
						]}
					/>
					<Tooltip title={t("common.advancedFilters")} >
						<StyledBadge
							dot={false}
						>
							<StyledButton
								size="middle"
								onClick={(e) => {
									e.stopPropagation();
									setAdvanceFiltersDrawerVisible(true);
								}}
								icon={<FilterOutlined />}
							/>
						</StyledBadge>
					</Tooltip>
					<Tooltip title={t("common.filtersHistory")} >
						<StyledButton
							size="middle"
							ghost
							type="primary"
							onClick={(e) => {
								e.stopPropagation();
								setFilterHistoryDrawerVisible(true);
							}}
							icon={<HistoryOutlined />}
						/>
					</Tooltip>
				</Row>
				<FilterButtons
					currentInnerIndex={currentInnerIndex}
					setCurrentInnerIndex={setCurrentInnerIndex}
					filters={filters}
					clearFilters={clearFilters}
					setCreateNewFilterModalVisible={setCreateNewFilterModalVisible}
					selectedProjects={selectedProjects}
					badgeColors={badgeColors}
					setFilters={setFilters}
					setFormState={setFormState}
					setInnerSelector={setInnerSelector}
					setOuterSelector={setOuterSelector}
					form={form}
				/>
			</StyledCard>

			<FormulationSearchResults
				filters={filters}
				searchList={searchList}
				project_id={selectedProjects}
				selectedLabLocations={selectedLabLocations}
				selectedWorkOrderType={selectedWorkOrderType}
			/>
		</Space>
	);
}

export default SearchFormulation
