
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Latin Hypercube`}</h1>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Latin_hypercube_sampling"
      }}>{`Latin Hypercube Sampling (LHS)`}</a>{` is a statistical method for generating a near-random sample of parameter values from a multidimensional distribution. The sampling method is often used to construct computer experiments.`}</p>
    <p>{`Latin Hypercube Sampling (LHS) is a way of generating random samples of parameter values. It is widely used in `}<a parentName="p" {...{
        "href": "https://www.statisticshowto.com/monte-carlo-simulation/"
      }}>{`Monte Carlo simulation`}</a>{`, because it can drastically reduce the number of runs necessary to achieve a reasonably accurate result.`}</p>
    <p>{`LHS is based on the `}<a parentName="p" {...{
        "href": "https://www.statisticshowto.com/latin-square-design/"
      }}>{`Latin square design`}</a>{`, which has a single sample in each row and column. A “hypercube” is a cube with more than three dimensions; the Latin square is extended to sample from multiple dimensions and multiple hyperplanes.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;