import { StoreState } from "src/store/configureStore";
import KDEChart from "../../Shared/KDEChart";
import MaskTable from "../../Shared/MaskTable";
import PointsTable from "../../Shared/PointsTable";
import { useSelector } from "react-redux";
import QuadrantTable from "../../Shared/QuadrantTable";
import { useParams } from "react-router-dom";

type TProps = {
    showGeneralAnalysis: boolean;
    handleChartSelection: (eventData: any) => void;
    selections: any[];
};

const ProtrusionAnalysisAttributes = ({
    showGeneralAnalysis,
    selections,
    handleChartSelection
}: TProps) => {
    const { semAnalysisMaskData, semAnalysisMaskHeightWidth } =
        useSelector((state: StoreState) => state.semAnalysis);
    const params = useParams<any>();

    return (
        <>
            {!showGeneralAnalysis ? <QuadrantTable fileId={params.fileId} /> : (
                <>
                    {semAnalysisMaskData?.mask_results?.length ? (
                        <KDEChart
                            data={semAnalysisMaskData?.mask_results}
                            unit={semAnalysisMaskData?.scale.replace(/[0-9.]/g, "")}
                            selections={selections}
                            handleChartSelection={handleChartSelection}
                            showSelections={true}
                        />
                    ) : null}
                    <PointsTable data={semAnalysisMaskHeightWidth} />
                    <h3>Total masks {"(" + semAnalysisMaskData?.mask_results?.length + ")"}</h3>
                    <MaskTable data={semAnalysisMaskData} />
                </>
            )}
        </>
    );
};

export default ProtrusionAnalysisAttributes;
