import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Card, Space, Spin, Tooltip } from "antd";
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsHeatmap from "highcharts/modules/heatmap"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"
import { useValue } from "src/utils/useValue"
import { isValidNumber } from "src/utils/decorator"
import ExcelJS from "exceljs"
import saveAs from "file-saver"
import { AsyncStates } from "src/constants"
import { DownloadOutlined } from "@ant-design/icons"
import { StyledButton } from "src/styled_components/StyledButton"

HighchartsHeatmap(Highcharts)

const colorStops: [number, string][] = [
  [0, "#ac261f"],
  [0.125, "#f46d43"],
  [0.25, "#fdae61"],
  [0.375, "#fee08b"],
  [0.5, "#ffffbf"],
  [0.625, "#d9ef8b"],
  [0.75, "#a6d96a"],
  [0.875, "#66bd63"],
  [1, "#1a9850"],
]

function rgbStringToHex(rgbString: string) {
  const [r, g, b] = rgbString.slice(4, -1).split(",")
  return rgbToHex(parseInt(r), parseInt(g), parseInt(b))
}

function rgbToHex(r: number, g: number, b: number) {
  let red = r.toString(16)
  let green = g.toString(16)
  let blue = b.toString(16)

  if (red.length === 1) red = "0" + red
  if (green.length === 1) green = "0" + green
  if (blue.length === 1) blue = "0" + blue

  return "#" + red + green + blue
}

// Function to generate color based on position
function getColorFromStops(position: number, stops: [number, string][]) {
  // Scale the input value from -1 to 0 to fit within the 0 to 1 range
  var scaledValue = (position + 1) / 2

  // Find the color based on the scaled value
  for (var i = 0; i < stops.length - 1; i++) {
    if (scaledValue >= stops[i][0] && scaledValue <= stops[i + 1][0]) {
      var color1 = stops[i][1]
      var color2 = stops[i + 1][1]
      var t = (scaledValue - stops[i][0]) / (stops[i + 1][0] - stops[i][0])
      const colorValInRgb = Highcharts.color(color1)
        .tweenTo(Highcharts.color(color2), t)
        .toString()
      return rgbStringToHex(colorValInRgb)
    }
  }
  return "#FFFFFF"
}

const paramsToColorsMap: { [key: string]: string } = {
  ingredients: "#2980B9",
  processing: "#633974",
  properties: "#F39C12",
  costing: "#1a9850",
  others: "#2980B9",
}

export const Heatmap = () => {
  // Other utility hooks

  const [t] = useTranslate()
  const { getValue: getEUValue } = useValue()
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  // Selectors

  const isSidebarCollapsed = useSelector(
    (state: StoreState) => state.sidebar.collapsed,
  )
  const {
    multistageHeatmapResultResponse: { results: data },
    multistageHeatmapResultStatus,
  } = useSelector((state: StoreState) => state.multiStageHeatmap)

  // States

  const [x, setX] = useState<string[]>([])
  const [y, setY] = useState<string[]>([])

  // Callbacks

  const getPointLabelValue = useCallback(
    (point: any) => {
      const pointValue = isValidNumber(point.value)
        ? getEUValue(point.value as number)
        : "-"
      return pointValue
    },
    [getEUValue],
  )

  // Effects

  useEffect(() => {
    chartComponentRef.current?.chart?.reflow?.()
  }, [isSidebarCollapsed])

  useEffect(() => {
    return () => {
      if (chartComponentRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chartComponentRef?.current?.chart?.destroy()
      }
    }
  }, [])

  useEffect(() => {
    const x = [
      ...(data?.correlation_summary?.ingredients || []),
      ...(data?.correlation_summary?.processing || []),
      ...(data?.correlation_summary?.properties || []),
      ...(data?.correlation_summary?.costing || []),
    ]
    setX(x)
    setY(x)
  }, [data?.correlation_summary])

  // Memo

  const highChartsPlotsData = useMemo(() => {
    const correlation = data?.correlation_summary?.data
    const array: [number, number, null | number][] = []

    x.forEach((x_key, x_index) => {
      y.forEach((y_key, y_index) => {
        // if (x_key !== y_key) {
        const elementFound = array.find((item) => {
          return item[0] === y_index && item[1] === x_index
        })
        const value = correlation?.[y_key]?.[x_key]
        if (!elementFound) {
          array.push([x_index, y_index, value === "-" ? null : value])
        }
        // }
      })
    })

    const options: Highcharts.Options = {
      chart: {
        type: "heatmap",
        height: y?.length * 40 + 240,
        marginTop: 40,
        marginBottom: 200,
        scrollablePlotArea: {
          minWidth: x?.length * 40 + 240,
          scrollPositionX: 0,
        },
      },
      boost: {
        enabled: true,
        useGPUTranslations: true,
        usePreallocated: true,
      },
      xAxis: {
        categories: x,
        labels: {
          useHTML: true,
          formatter: function (item) {
            if (data?.correlation_summary?.ingredients?.includes(String(item.value))) {
              return `<span style="color:#2980B9 ; font-size: 12" title="${item.value}">${textFormater(String(item.value))}</span>`
            } else if (data?.correlation_summary?.processing?.includes(String(item.value))) {
              return `<span style="color:#633974; font-size: 12" title="${item.value}">${textFormater(String(item.value))}</span>`
            } else if (data?.correlation_summary?.properties?.includes(String(item.value))) {
              return `<span style="color:#F39C12; font-size: 12" title="${item.value}">${textFormater(String(item.value))}</span>`
            } else if (item.value === "Formulation Cost") {
              return `<span style="color:#1a9850; font-size: 12" title="${item.value}">${textFormater(String(item.value))}</span>`
            } else
              return `<span title="${item.value}">${textFormater(String(item.value))}</span>`
          },
          rotation: -45,
          style: { fontWeight: "bold" },
        },
      },
      yAxis: {
        categories: y,
        labels: {
          useHTML: true,
          formatter: function (item) {
            if (item.value === -1) { return '' }
            if (data?.correlation_summary?.ingredients?.includes(String(item.value))) {
              return `<span style="color:#2980B9 ; font-size: 12" title="${item.value}">${textFormater(String(item.value))}</span>`
            } else if (data?.correlation_summary?.processing?.includes(String(item.value))) {
              return `<span style="color:#633974; font-size: 12" title="${item.value}">${textFormater(String(item.value))}</span>`
            } else if (data?.correlation_summary?.properties?.includes(String(item.value))) {
              return `<span style="color:#F39C12; font-size: 12" title="${item.value}">${textFormater(String(item.value))}</span>`
            } else if (item.value === "Formulation Cost") {
              return `<span style="color:#1a9850; font-size: 12" title="${item.value}">${textFormater(String(item.value))}</span>`
            } else
              return `<span title="${item.value}">${textFormater(String(item.value))}</span>`
          },
          style: { fontWeight: "bold" },
        },
        reversed: true,
        gridLineColor: "transparent",
        title: {
          text: null,
        },
      },
      colorAxis: {
        min: -1,
        max: 1,
        stops: colorStops,
      },
      tooltip: {
        formatter: function (item) {
          const point = this.point
          const series = point.series
          const xVal = series.xAxis.categories[point["x"]]
          let yVal = ""
          if (point.y) yVal = series.yAxis.categories[point.y]
          const pointValue = getPointLabelValue(point)
          return `${pointValue}, ${xVal}, ${yVal}`
        },
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      legend: {
        align: "right",
        layout: "vertical",
        margin: 0,
        verticalAlign: "top",
        y: 25,
        symbolHeight: 280,
      },
      series: [
        {
          type: "heatmap",
          className: "data-summary-block",
          borderWidth: 1,
          borderColor: "#111",
          data: array,
          // nullColor: "#FFFFFF",
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            formatter: function (this) {
              const pointValue = getPointLabelValue(this.point)
              return pointValue
            },
            style: {
              fontSize: "8px",
            },
          },
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 200,
            },
          },
        ],
      },
    }
    return options
  }, [
    data?.correlation_summary?.data,
    data?.correlation_summary?.ingredients,
    data?.correlation_summary?.processing,
    data?.correlation_summary?.properties,
    x,
    y,
    getPointLabelValue,
  ])

  // Helper functions
  const textFormater = (text: string) => {
    return text?.length < 25 ? text : text?.substring(0, 24) + "..."
  }

  const getNamesColor = (id: string) => {
    if (data?.correlation_summary?.ingredients?.includes(String(id))) {
      return "#2980B9"
    } else if (data?.correlation_summary?.processing?.includes(String(id))) {
      return "#633974"
    } else if (data?.correlation_summary?.properties?.includes(String(id))) {
      return "#F39C12"
    } else if (id === "Formulation Cost") {
      return "#1a9850"
    } else {
      return "#d0d0d0"
    }
  }

  const getLabelFromId = (id: string) => {
    return textFormater(id) ?? id
  }

  const generateHeatmapExcel = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(
      t("dataSummary.numericalCorrelationHeatmaps"),
      {
        headerFooter: {
          firstHeader: `&B${t("dataSummary.numericalCorrelationHeatmaps")}&B`,
        },
      },
    )
    worksheet.properties.outlineLevelCol = 0
    worksheet.properties.outlineLevelRow = 0
    workbook.created = new Date()

    worksheet.columns = [
      { header: "", key: "values" },
      ...x.map((i) => ({ header: i, key: i })),
    ]

    const correlation = data?.correlation_summary?.data
    const heatmapData: { [key: string]: { [key: string]: string | number } } =
      {}
    const visitedMap: number[][] = []

    x.forEach((x_key, x_index) => {
      y.forEach((y_key, y_index) => {
        const elementFound = visitedMap.find((item) => {
          return item[0] === x_index && item[1] === y_index
        })
        if (!elementFound) {
          const value = correlation[y_key]?.[x_key]
          if (!heatmapData[`${y_key}`]) {
            heatmapData[`${y_key}`] = {}
          }
          heatmapData[`${y_key}`][`${x_key}`] = value
          visitedMap.push([y_index, x_index])
        }
      })
    })

    Object.keys(heatmapData).forEach((key: string, idx: number) => {
      const row: { [key: string]: string | number } = { values: key }
      Object.keys(heatmapData[key]).forEach((k: string) => {
        row[k] = heatmapData[key][k]
      })
      worksheet.addRow(row)
    })

    worksheet.addRow(["", ...x.map((i) => i)])

    worksheet.spliceRows(1, 1)

    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        const colorString = getColorFromStops(Number(cell.text), colorStops)
          .slice(1)
          .toUpperCase()
        cell.style = {
          font: {
            bold: true,
            color: {
              argb:
                cell.text === "-"
                  ? "FF000000"
                  : Number(cell.text) < 0.6 && Number(cell.text) > -0.75
                    ? "FF000000"
                    : "FFFFFFFF",
            },
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            bgColor: { argb: `FF${colorString}` },
            fgColor: { argb: `FF${colorString}` },
          },
        }
        cell.alignment = { vertical: "middle", horizontal: "center" }
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        }

        // Setting colors for ingredients, processing, properties and costing Y axis

        if (Number(cell.col) === 1) {
          cell.style = {
            font: {
              bold: true,
              color: {
                argb: `FF${getNamesColor(cell.text).slice(1).toUpperCase()}`,
              },
            },
          }
          cell.value = getLabelFromId(cell.text)
          cell.border = {}
        }

        // Setting colors for ingredients, processing, properties and costing X axis
        if (rowNumber === worksheet.rowCount) {
          cell.style = {
            font: {
              bold: true,
              color: {
                argb: `FF${getNamesColor(cell.text).slice(1).toUpperCase()}`,
              },
            },
          }
          cell.value = getLabelFromId(cell.text)
          cell.border = {}
        }
      })
    })

    worksheet.getColumn("values").width = 20

    worksheet.addRow([])

    worksheet
      .addRow([t("common.label"), `${t("common.values")} -1 to 1`])
      .eachCell((cell) => {
        cell.style = {
          font: {
            bold: true,
            color: { argb: "FF000000" },
          },
        }
      })
    Object.keys(paramsToColorsMap).forEach((key, idx) => {
      worksheet.addRow([key]).eachCell((cell) => {
        if (idx === 0) {
          worksheet.getCell(`B${cell.row}`).fill = {
            type: "gradient",
            gradient: "angle",
            degree: 0,
            stops: [
              { position: 0, color: { argb: "FFAC261F" } },
              { position: 0.125, color: { argb: "FFF46D43" } },
              { position: 0.25, color: { argb: "FFFDAE61" } },
              { position: 0.375, color: { argb: "FFFEE08B" } },
              { position: 0.5, color: { argb: "FFFFFFBF" } },
              { position: 0.625, color: { argb: "FFD9EF8B" } },
              { position: 0.75, color: { argb: "FFA6D96A" } },
              { position: 0.875, color: { argb: "FF66BD63" } },
              { position: 1, color: { argb: "FF1A9850" } },
            ],
          }
          worksheet.mergeCells(`B${cell.row}:F${cell.row}`)
        }
        cell.style = {
          font: {
            bold: true,
            color: {
              argb: `FF${paramsToColorsMap[key].slice(1).toUpperCase()}`,
            },
          },
        }
      })
    })

    workbook?.xlsx?.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
      saveAs(blob, `${t("common.heatmap")}.xlsx`)
    })
  }

  return multistageHeatmapResultStatus === AsyncStates.SUCCESS ? (
    <Card
      title={t("common.heatmap")}
      extra={
        <Tooltip title={t("report.downloadReport")}>
          <StyledButton
            icon={<DownloadOutlined />}
            style={{ outline: "none" }}
            onClick={generateHeatmapExcel}
          />
        </Tooltip>
      }
    >
      <Space style={{ width: "100%" }} direction="vertical">
        <HighchartsReact
          ref={chartComponentRef}
          highcharts={Highcharts}
          options={highChartsPlotsData}
        />
      </Space>
    </Card>
  ) : multistageHeatmapResultStatus === AsyncStates.LOADING ? (
    <Spin
      spinning
      style={{
        width: "100%",
        height: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  ) : null
}
