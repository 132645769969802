
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Plackett-Burman`}</h1>
    <p>{`Ein `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Plackett%E2%80%93Burman_design"
      }}>{`Plackett-Burman Design`}</a>{` (eine Art von Screening-Design) hilft dabei herauszufinden, welche Faktoren in einem Experiment wichtig sind. Dieses Design filtert unwichtige Faktoren (Rauschen) aus, was bedeutet, dass Sie vermeiden, große Mengen an Daten zu sammeln, die sich auf relativ unwichtige Faktoren beziehen.`}</p>
    <p>{`Im Jahr 1946 veröffentlichten R.L. Plackett und J.P. Burman ihr heute berühmtes Papier "The Design of Optimal Multifactorial Experiments" in Biometrika (Jahrgang 33). In diesem Papier wurde die Konstruktion sehr wirtschaftlicher Designs mit einer Laufzahl, die ein Vielfaches von vier ist (anstatt einer Potenz von 2), beschrieben. Plackett-Burman Designs sind sehr effiziente Screening-Designs, wenn nur Haupteffekte von Interesse sind.`}</p>
    <p>{`Plackett-Burman (PB) Designs werden für Screening-Experimente verwendet, weil in einem PB-Design Haupteffekte im Allgemeinen stark mit Interaktionen von zwei Faktoren verwechselt sind. Das PB-Design mit 12 Läufen kann für ein Experiment mit bis zu 11 Faktoren verwendet werden (zum Beispiel würden 4, 5, 6 oder 7 Faktoren 8 Experimentalläufe erfordern, 8, 9, 10 oder 11 würden 12 Läufe erfordern, und so weiter).`}</p>
    <p><strong parentName="p">{`Anwendungsfälle:`}</strong></p>
    <ul>
      <li parentName="ul">{`Im Screening`}</li>
      <li parentName="ul">{`Wenn das Vernachlässigen von Interaktionen höherer Ordnung möglich ist`}</li>
      <li parentName="ul">{`In Multi-Faktor-Experimenten mit zwei Ebenen`}</li>
      <li parentName="ul">{`Wenn es mehr als vier Faktoren gibt (wenn es zwischen zwei und vier Variablen gibt, kann ein vollständiges Faktoriell durchgeführt werden)`}</li>
      <li parentName="ul">{`Um große Haupteffekte wirtschaftlich zu erfassen`}</li>
      <li parentName="ul">{`Für `}<em parentName="li">{`N`}</em>{` = 12, 20, 24, 28 und 36 (wobei `}<em parentName="li">{`N`}</em>{` die Anzahl der Experimente ist)`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;