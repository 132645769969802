import { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"

export const useSpreadsheet = ({ data, callbacks }: any) => {
	const { toggleFullscreen, setParsedFile, generatePreview, toggleIframeLoading } = callbacks
	const { file } = data


	const spreadsheet = useRef<any>(null)
	const channel = useRef<any>(null)
	const dispatch = useDispatch()

	const workOrder = useSelector(
		(state: StoreState) => state.workOrderDetails.workOrder || {}
	);

	const currentExpIdIndex = useMemo(
		() =>
			workOrder?.stages?.findIndex(
				(stage: any) => stage?.identifier === workOrder?.work_order_parent_stage
			),
		[workOrder?.work_order_parent_stage, workOrder?.stages]
	);

	useEffect(() => {
		let transferPort: any
		const _spreadsheet = spreadsheet.current
		if (_spreadsheet) {
			channel.current = new MessageChannel()
			transferPort = (msg: any) => {
				if (msg.data === 'ready') {
					_spreadsheet.contentWindow?.postMessage({ type: 'load' }, "*", [
						channel.current.port2,
					]);
				}
			}
			window.addEventListener("message", transferPort)
		}
		return () => {
			if (_spreadsheet) {
				_spreadsheet.removeEventListener("message", transferPort)
			}
		}
	}, [])

	useEffect(() => {
		channel.current.port1.start()
		channel.current.port1?.addEventListener("message", onMessage, { capture: true });

		function onMessage(e: any) {
			console.log("🚀 ~ file: useSpreadsheet.ts:38 ~ onMessage ~ e:port1", e.data)
			switch (e.data.type) {
				case 'exit_fullscreen':
					toggleFullscreen()
					break;
				case 'edit_data':
					setParsedFile((parsedFile: any) => ({
						...parsedFile,
						Sheets: e.data.payload
					}))
					break;

				case 'PREVIEW':
					generatePreview(e.data.payload)

					break;

				case 'loaded':
					toggleIframeLoading()
					break;

				default:
					break;
			}
		}
		return () => {
			channel.current.port1.removeEventListener("message", onMessage, { capture: true })
		}
	}, [currentExpIdIndex, dispatch, file, generatePreview, setParsedFile, toggleFullscreen, toggleIframeLoading, workOrder.experiment_id, workOrder.stages, workOrder.work_order_id])

	return { spreadsheet: { ref: spreadsheet, port: channel.current?.port1 } }

}