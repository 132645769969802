import { useCallback } from "react"
import {
	languageToggle,
	LanguageToggleAction,
} from "src/store/actions/language"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import EnglishDict from "src/languages/en.json"
import FrenchDict from "src/languages/fr.json"
import JapaneseDict from "src/languages/ja.json"
import SpanishDict from "src/languages/es.json"
import PortugueseDict from "src/languages/pt.json"
import ChineseDict from "src/languages/zh.json"
import GermanDict from "src/languages/de.json"
import KoreanDict from "src/languages/ko.json"
import { Language } from "src/store/reducers/language"

export type LanguageUnion = `${Language}`

const languages: {
	[key in LanguageUnion]: TranslationObject
} = {
	en: EnglishDict,
	fr: FrenchDict,
	ja: JapaneseDict,
	de: GermanDict,
	es: SpanishDict,
	pt: PortugueseDict,
	zh: ChineseDict,
	ko: KoreanDict,
}

export type TranslationObject = typeof EnglishDict
export type TranslationKey = keyof TranslationObject

export type TranslateFunc = (key: TranslationKey) => string

const useTranslate = (): [
	translate: TranslateFunc,
	languageToggle: LanguageToggleAction
] => {
	const { current: language, dev } = useSelector(
		(state: StoreState) => state.language
	)

	const translate = useCallback(
		(key: TranslationKey) => dev ? key : languages[language || "en"][key],
		[dev, language]
	)

	return [translate, languageToggle]
}

export default useTranslate
