import React from "react"
import { Modal, ModalProps, Row, Space } from "antd"
import { CharacterizationGraph } from "src/components/Celsure/UploadForm/CharcaterizationUpload"
import { Ambient } from "../types"

type P = ModalProps & {
	title: string
	data: Ambient[]
}

export const AmbientModal = ({ data, ...props }: P) => {
	return (
		<Modal footer={null} width={1200} {...props}>
			<Space direction="vertical" style={{ width: "100%", overflow: "auto" }}>
				<Row justify="center">
					<CharacterizationGraph ambientTableData={data} />
				</Row>
			</Space>
		</Modal>
	)
}
