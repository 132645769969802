import { useCallback, useMemo } from 'react'
import { BORDER_STYLE, StyledText, TEXT_PRIMARY } from './Dashboard';
import useTranslate from 'src/utils/useTranslate'
import { Avatar, Badge, Dropdown, MenuProps, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { trackEvent } from 'src/analytics'
import { logoutRequest, updateProfileRequest } from 'src/store/actions/login'
import { useHistory } from 'react-router-dom'
import {
  AuditOutlined,
  BellOutlined,
  LogoutOutlined,
  NotificationOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons'
import LocalizationIcon from './Icon.svg'
import { languages } from 'src/store/reducers/language'
import { languageLabels } from '../core/language-selector'
import { motion } from 'framer-motion'
import { antdTheme } from 'src/constants';

const Header = () => {
  // Utility hooks
  const [t] = useTranslate()

  // Selectors

  return (
    <motion.div
    initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
      style={{
        width: '100%',
        height: '60px',
        outline: BORDER_STYLE,
        borderBottom: BORDER_STYLE
      }}
    >
      <div
        style={{
          padding: '12px 24px',
          paddingLeft:'30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <Typography.Title
          style={{
            color: `${TEXT_PRIMARY}`,
            margin: 0
          }}
          level={3}
        >
          {t('navBar.menuItem.dashboard')}
        </Typography.Title>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <LocalizationMenu />
          <Notification/>
          <AvatarMenu />
        </div>
      </div>
    </motion.div>
  )
}

export default Header

const AvatarMenu = () => {
  // Utility hooks

  const dispatch = useDispatch()
  const [t] = useTranslate()
  const { push } = useHistory()

  // Selectors

  const {
    loginResponse: { user_name, user_id, image_url, user_role }
  } = useSelector((state: StoreState) => state.login)

  const logout = useCallback(() => {
    dispatch(logoutRequest())
  }, [dispatch])

  const items: MenuProps['items'] = useMemo(() => {

    const iconStyles: React.CSSProperties = {
      fontSize: antdTheme.fontSizeSM,
      color: `${TEXT_PRIMARY}`
    }

    return [
      {
        key: 'profile',
        label: StyledText(t('common.profile')),
        onClick: () => {
          trackEvent(user_id, 'User viewed profile')
          push('/profile/my-details')
        },
        icon: <UserOutlined style={iconStyles} />
      },
      ...(user_role === 'admin'
        ? [
            {
              key: 'audit-log',
              label: StyledText(t('common.auditLog')),
              onClick: () => {
                trackEvent(user_id, 'User viewed audit log')
                push('/audit-log')
              },
              icon: <AuditOutlined style={iconStyles} />
            }
          ]
        : []),
      {
        key: 'settings',
        label: StyledText(t('navBar.menuItem.settings')),
        onClick: () => {
          trackEvent(user_id, 'User viewed settings')
          push('/settings')
        },
        icon: <SettingOutlined style={iconStyles} />
      },
      {
        key: 'feedback',
        label: StyledText(t('navBar.menuItem.feedback')),
        onClick: () => {
          trackEvent(user_id, 'User viewed feedback')
          push('/feedback')
        },
        icon: <NotificationOutlined style={iconStyles} />
      },
      {
        key: 'logout',
        label: StyledText(t('navBar.menuItem.logout')),
        onClick: () => {
          trackEvent(user_id, 'User Logged out')
          logout()
        },
        icon: <LogoutOutlined style={iconStyles} />
      }
    ]
  }, [logout, push, t, user_id, user_role])

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
    >
      <Avatar
        src={image_url}
        alt={user_name}
        style={{
          marginLeft: -8,
          border: '1px solid #434343',
          cursor: 'pointer'
        }}
        size={30}
      />
    </Dropdown>
  )
}

const LocalizationMenu = () => {
  // Utility hooks
  const dispatch = useDispatch()
  const [t, changeLanguage] = useTranslate()

  // Selectors
  const {
    loginResponse: { user_id }
  } = useSelector((state: StoreState) => state.login)

  const items: MenuProps['items'] = useMemo(() => {
    const textStyles: React.CSSProperties = {
      color: `${TEXT_PRIMARY}`,
      margin: 0
    }

    const StyledText = (text: string) => {
      return <Typography.Text style={textStyles} strong>{text}</Typography.Text>
    }

    return languages.map((lang) => ({
      key: lang,
      label: StyledText(`${languageLabels[lang].label} ${lang}`),
      onClick: () => {
        trackEvent(user_id, 'User changed the language to ' + lang)
        dispatch(changeLanguage(lang))
        dispatch(updateProfileRequest({ lang: lang }))
      }
    }))
  }, [dispatch, user_id, changeLanguage])

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
    >
      <Avatar
        src={LocalizationIcon}
        alt={t('navBar.switchLanguage')}
        style={{ marginLeft: -8, cursor: 'pointer' }}
        size={30}
      />
    </Dropdown>
  )
}

const Notification = () => {

  // Utility hooks
  const {push} = useHistory()

  // Selectors
  const { unread = 0 } = useSelector(
    (state: StoreState) => state.notifications.data
  )
  const {
    loginResponse: { user_id }
  } = useSelector((state: StoreState) => state.login)

  return (
    <div style={{
      width: '35px',
    }} >
    <Badge dot={unread>0} showZero={false} size="small" style={{
      outline: 'none',
    }} >
      
      <BellOutlined
        onClick={() => {
          trackEvent(user_id, 'User viewed notifications')
          push('/notifications')
        }}
        style={{
          fontSize: antdTheme.fontSizeHeading3,
          color: `${TEXT_PRIMARY}`,
          cursor: 'pointer',
          outline: 'none',
        }}
      />
      
    </Badge>
    </div>
  )
}
