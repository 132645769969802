
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Box-Behnken`}</h1>
    <p><a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Box-Behnken-Design"
      }}>{`Box-Behnken`}</a>{` Designs werden verwendet, um Response-Oberflächen höherer Ordnung zu generieren, wobei weniger erforderliche Durchläufe als bei einer normalen Faktoriellen Methode notwendig sind. Dies und die zentralen Composite-Techniken unterdrücken im Wesentlichen ausgewählte Durchläufe in dem Versuch, die Definition der Response-Oberfläche höherer Ordnung aufrechtzuerhalten.`}</p>
    <p>{`Das `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Box-Behnken-Design"
      }}>{`Box-Behnken`}</a>{` Design ist ein unabhängiges quadratisches Design, da es keine eingebettete Faktorielle oder Fraktorielle Gestaltung enthält. In diesem Design befinden sich die Behandlungskombinationen an den Mittelpunkten der Kanten des Prozessraums und in der Mitte. Diese Designs sind rotierbar (oder nahezu rotierbar) und erfordern 3 Stufen für jeden Faktor. Die Designs haben im Vergleich zu den zentralen Composite-Designs begrenzte Fähigkeiten zur orthogonalen Blockbildung.`}</p>
    <p><strong parentName="p">{`Anwendungsfälle:`}</strong>{`
Das Box-Behnken Design (BBD) wird weit verbreitet eingesetzt in`}</p>
    <ul>
      <li parentName="ul">{`Pharmazie`}</li>
      <li parentName="ul">{`Bioprozessierung`}</li>
      <li parentName="ul">{`Lebensmitteltechnik`}</li>
      <li parentName="ul">{`Agrochemie`}</li>
      <li parentName="ul">{`und anderen Branchen`}</li>
    </ul>
    <p>{`Um biologisch aktive Verbindungen, wie Polysaccharide, phenolische Verbindungen und Proteine aus verschiedenen Quellen, die für den menschlichen Gebrauch bestimmt sind, zu extrahieren.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;