import { MailOutlined } from '@ant-design/icons'
import { Alert, Form, Input, Space, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useTranslate from 'src/utils/useTranslate';
import AllRightsReserved from '../SignUp/AllRightsReserved';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginViaMagicLinkCleanup, loginViaMagicLinkRequest } from 'src/store/actions/loginViaMagicLink';
import { StoreState } from 'src/store/configureStore';
import { AsyncStates } from 'src/constants';
import { loginCleanup } from 'src/store/actions';
import { loginFirebaseClear } from 'src/store/actions/firebase';
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar';
import { StyledButton } from 'src/styled_components/StyledButton';


const { Text } = Typography;

export const MagicLinkLogin = () => {
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()
    const dispatch = useDispatch()
    const [magicLinkLogin] = useForm()

    const submitForm = (values: any) => {
        dispatch(loginViaMagicLinkRequest(values))
    }

    const { loginViaMagicLinkError: errorMsg, loginViaMagicLinkStatus } = useSelector((state: StoreState) => state.loginViaMagicLink)

    useEffect(() => {
        return () => {
            magicLinkLogin.resetFields()
            dispatch(loginViaMagicLinkCleanup())
            dispatch(loginCleanup())
            dispatch(loginFirebaseClear())
        }
    }, [magicLinkLogin, dispatch])

    return (
        <Space direction="vertical">
            {!!errorMsg && (
                <Alert
                    message={
                        <div dangerouslySetInnerHTML={{
                            __html: errorMsg
                        }}></div>
                    }
                    type="error"
                    style={{
                        backgroundColor: "#CF1322",
                        color: "white",
                        textAlign: "left",
                        display: "block",
                        margin: "0rem auto",
                        maxWidth: "80%"
                    }}
                    className="error-alert-msg"
                />
            )}
            <Form
                name="normal_login"
                className="login-form"
                layout="vertical"
                onFinish={submitForm}
                form={magicLinkLogin}
                requiredMark={false}
            >
                <Form.Item
                    label={""}
                    name="email"
                    validateTrigger="onChange"
                    rules={[
                        {
                            required: true,
                            pattern: new RegExp(
                                /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                            ),
                            message: (
                                <Text
                                    style={{
                                        color: "red",
                                        display: "block",
                                        textAlign: "left",
                                        margin: "0.2rem",
                                        marginLeft: "3.8rem",
                                    }}
                                >
                                    {t("common.notAvalidMail")}
                                </Text>
                            ),
                        },
                    ]}
                    required tooltip={requiredFieldStar}
                >
                    <Input
                        style={{
                            borderRadius: "7.5px",
                            minHeight: "50px",
                            width: "80%",
                        }}
                        prefix={<MailOutlined />}
                        size="large"
                        placeholder={t("common.email")}
                        className="round-text"
                        minLength={4}
                        autoFocus
                    />
                </Form.Item>

                <Form.Item>
                    <Text type='secondary'>We'll send a magic link to you inbox.</Text>
                </Form.Item>

                <Form.Item>
                    <StyledButton
                        loading={loginViaMagicLinkStatus === AsyncStates.LOADING}
                        style={{
                            background: "#1753E2",
                            width: "80%",
                            borderRadius: "7.5px",
                            minHeight: "50px",
                        }}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="primary-btn"
                    >
                        {t("common.login")}
                    </StyledButton>
                </Form.Item>

            </Form>
            <AllRightsReserved
                styleProp={{ position: "fixed" }
                }
            />
        </Space>
    );
}
