import { createActions } from "redux-actions";


export enum CreateCustomFieldActionTypes {
    REQUEST = "CREATE_CUSTOM_FIELD_REQUEST",
    SUCCESS = "CREATE_CUSTOM_FIELD_SUCCESS",
    FAILURE = "CREATE_CUSTOM_FIELD_FAILURE",
    CLEAR = "CREATE_CUSTOM_FIELD_CLEAR",
}

export enum GetCustomFieldsActionTypes {
    REQUEST = "GET_CUSTOM_FIELDS_REQUEST",
    SUCCESS = "GET_CUSTOM_FIELDS_SUCCESS",
    FAILURE = "GET_CUSTOM_FIELDS_FAILURE",
    CLEAR = "GET_CUSTOM_FIELDS_CLEAR",
}

export enum DeleteCustomFieldActionTypes {
    REQUEST = "DELETE_CUSTOM_FIELD_REQUEST",
    SUCCESS = "DELETE_CUSTOM_FIELD_SUCCESS",
    FAILURE = "DELETE_CUSTOM_FIELD_FAILURE",
    CLEAR = "DELETE_CUSTOM_FIELD_CLEAR",
}

export enum UpdateCustomFieldActionTypes {
    REQUEST = "UPDATE_CUSTOM_FIELD_REQUEST",
    SUCCESS = "UPDATE_CUSTOM_FIELD_SUCCESS",
    FAILURE = "UPDATE_CUSTOM_FIELD_FAILURE",
    CLEAR = "UPDATE_CUSTOM_FIELD_CLEAR",
}

export const {
    createCustomFieldRequest,
    createCustomFieldSuccess,
    createCustomFieldFailure,
    createCustomFieldClear,
    getCustomFieldsRequest,
    getCustomFieldsSuccess,
    getCustomFieldsFailure,
    getCustomFieldsClear,
    deleteCustomFieldRequest,
    deleteCustomFieldSuccess,
    deleteCustomFieldFailure,
    deleteCustomFieldClear,
    updateCustomFieldRequest,
    updateCustomFieldSuccess,
    updateCustomFieldFailure,
    updateCustomFieldClear,
} = createActions({
    [CreateCustomFieldActionTypes.REQUEST]: (payload) => payload,
    [CreateCustomFieldActionTypes.SUCCESS]: (data: any) => data,
    [CreateCustomFieldActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [CreateCustomFieldActionTypes.CLEAR]: (payload) => payload,
    [GetCustomFieldsActionTypes.REQUEST]: (payload) => payload,
    [GetCustomFieldsActionTypes.SUCCESS]: (data: any) => data,
    [GetCustomFieldsActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [GetCustomFieldsActionTypes.CLEAR]: (payload) => payload,
    [DeleteCustomFieldActionTypes.REQUEST]: (payload) => payload,
    [DeleteCustomFieldActionTypes.SUCCESS]: (data: any) => data,
    [DeleteCustomFieldActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [DeleteCustomFieldActionTypes.CLEAR]: (payload) => payload,
    [UpdateCustomFieldActionTypes.REQUEST]: (payload) => payload,
    [UpdateCustomFieldActionTypes.SUCCESS]: (data: any) => data,
    [UpdateCustomFieldActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [UpdateCustomFieldActionTypes.CLEAR]: (payload) => payload,
})