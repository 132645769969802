import { handleActions } from "redux-actions"
import { AsyncStates } from "../../constants"
import { NotificationsActionTypes } from "../actions/notifications"


export type Notification = {
	/**
	 * The Sender's ID
	 */
	sender: string
	message: {
		receivers_message: string
		senders_message?: string
		body: null | string
		status: null | string
	}
	ref_id: number
	notification_id: string
	/**
	 * The defines by the module which the notification is related to. For example, if the notification is related to a 2FA then the value will be "2fa_status".
	 */
	notification_type: string
	created: string
	read_by: {
		reader_id: string
		read_at: string
	}[]
	is_read: boolean
	download_link?: string
	work_order_id?: string
	model_version?: string
}

export type NotificationsState = {
	status: AsyncStates
	readReqStatus: AsyncStates
	error: string
	readReqError: string
	readMultipleReqStatus: AsyncStates
	readMultipleReqError: string
	data: {
		notifications: Notification[]
		unread: number
	}
}

const defaultState: NotificationsState = {
	status: AsyncStates.INITIAL,
	readReqStatus: AsyncStates.INITIAL,
	readMultipleReqStatus: AsyncStates.INITIAL,
	error: "",
	readReqError: "",
	readMultipleReqError: "",
	data: {
		notifications: [],
		unread: 0,
	},
}

const notificationsReducer = handleActions(
	{
		[NotificationsActionTypes.REQUEST]: (state) => {
			return {
				...state,
				status: AsyncStates.LOADING,
				error: "",
			}
		},
		[NotificationsActionTypes.SUCCESS]: (state, action: any) => {
			return {
				...state,
				status: AsyncStates.SUCCESS,
				error: "",
				data: {
					notifications: action.payload.notifications,
					unread: action.payload.unread,
				},
			}
		},
		[NotificationsActionTypes.FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[NotificationsActionTypes.NOTIFICATION_READ_REQUEST]: (state) => {
			return {
				...state,
				readReqStatus: AsyncStates.LOADING,
				readReqError: "",
			}
		},
		[NotificationsActionTypes.NOTIFICATION_READ_SUCCESS]: (
			state,
			action: any
		) => {
			return {
				...state,
				readReqStatus: AsyncStates.SUCCESS,
				readReqError: "",
				data: {
					notifications: state.data.notifications.map((notification) =>
						action.payload.notification_id?.includes(notification.notification_id)
							? { ...notification, is_read: true }
							: notification
					),
					unread: action.payload.unread,
				},
			}
		},
		[NotificationsActionTypes.NOTIFICATION_READ_FAILURE]: (state, action) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[NotificationsActionTypes.NOTIFICATION_ADD]: (state, action: any) => {
			return {
				...state,
				data: {
					notifications: [action.payload, ...state.data.notifications],
					unread: state.data.unread + 1,
				},
			}
		},
		[NotificationsActionTypes.NOTIFICATION_MULTIPLE_READ_REQUEST]: (state) => {
			return {
				...state,
				readMultipleReqStatus: AsyncStates.LOADING,
				readMultipleReqError: "",
			}
		},
		[NotificationsActionTypes.NOTIFICATION_MULTIPLE_READ_SUCCESS]: (
			state,
			action: any
		) => {
			return {
				...state,
				readMultipleReqStatus: AsyncStates.SUCCESS,
				readMultipleReqError: "",
				data: {
					notifications: state.data.notifications.map((notification) => {
						return {
							...notification,
							is_read:
								notification.is_read === true
									? true
									: action.payload.data.notification_ids.includes(
										notification.notification_id
									),
						}
					}),
					unread: action.payload.data.unread,
				},
			}
		},
		[NotificationsActionTypes.NOTIFICATION_MULTIPLE_READ_FAILURE]: (
			state,
			action
		) => ({
			...state,
			status: AsyncStates.ERROR,
			error: action.payload.error,
		}),
		[NotificationsActionTypes.NOTIFICATION_MULTIPLE_READ_CLEANUP]: (state) => {
			return {
				...state,
				readMultipleReqStatus: AsyncStates.INITIAL,
				readMultipleReqError: "",
			}
		},
	},
	defaultState
)

export default notificationsReducer
