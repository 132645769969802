import React, { ReactNode, useState } from "react";
import { Modal, Input, message, Space, Typography } from "antd";

import useTranslate from "src/utils/useTranslate";

const { Text } = Typography;

interface DeleteConfirmationModalProps {
  title: string;
  description: string | ReactNode;
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  confirmLoading?: boolean;
  strict?: boolean;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  title,
  description,
  visible,
  onCancel,
  onConfirm,
  confirmLoading,
  strict = true,
}) => {
  const [t] = useTranslate();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleConfirm = () => {
    if (!strict || inputValue === "DELETE") {
      setInputValue("");
      onConfirm();
    } else {
      message.error(t("polygpt.Pleaseenter'DELETE'toconfirm."));
    }
  };

  return (
    <Modal
      title={title}
      open={visible}
      onCancel={onCancel}
      onOk={handleConfirm}
      confirmLoading={confirmLoading || false}
    >
      <Space
        direction="vertical"
        style={{ width: "100%" }}
        size="large"
        onClick={(e) => e.stopPropagation()}
      >
        <Text>{description}</Text>
      </Space>

      {strict && (
        <>
          <Space
            style={{
              marginTop: "10px",
              marginBottom: "5px",
            }}
          >
            <Text>{t("common.deleteHelperText")}</Text>
          </Space>
          <Input
            placeholder={t("polygpt.EnterDELETE")}
            value={inputValue}
            onChange={handleInputChange}
          />
        </>
      )}
    </Modal>
  );
};

export default DeleteConfirmationModal;
