import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown, Empty, Input } from "antd";
import { useEffect, useMemo, useState } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import "../Common.scss";
import useTranslate from "src/utils/useTranslate";
import AddEditParameterModal from "../AddEditParameterModal";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { createParametersClear } from "src/store/actions/repository";

type TProps = {
    selectedParameters: string[];
    setSelectedParameters: React.Dispatch<React.SetStateAction<string[]>>;
    parameters: { [key: string]: string | boolean }[];
    form?: any;
    allowEditing?: boolean;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    showAddPropertyButton?: boolean;
    fetchProperties?: boolean;
};

const ParameterDropdown = ({
    selectedParameters,
    setSelectedParameters,
    parameters,
    form = null,
    allowEditing = false,
    open,
    setOpen,
    showAddPropertyButton = true,
    fetchProperties = true
}: TProps) => {
    const dispatch = useDispatch();
    const [t] = useTranslate();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [showAddParameterModal, setShowAddParameterModal] = useState(false)
    const [selectedParameterIds, setSelectedParametersIds] = useState([
        ...selectedParameters,
    ]);
    const createParameterStatus = useSelector((state: StoreState) => state.repository.parameters.status);

    useEffect(() => {
        setSelectedParametersIds([...selectedParameters]);
    }, [selectedParameters]);

    const updateSelectedParameters = (parameter_id: string) => {
        if (selectedParameterIds.includes(parameter_id))
            setSelectedParametersIds((prev) =>
                prev.filter((p) => p !== parameter_id)
            );
        else setSelectedParametersIds((prev) => [...prev, parameter_id]);
    };

    const reset = () => setSelectedParametersIds([]);

    const add = () => {
        if (form) {
            const existingParameters = form.getFieldValue('parameters')
            const newParameterIds = selectedParameterIds?.filter((p) => !existingParameters.map((p: any) => p.parameter_id).includes(p))
            const parameters = newParameterIds.map((p) => {
                return {
                    parameter_id: p,
                    value: null,
                    value_max: null,
                    unit: null,
                    type: [],
                    value_str: null,
                    operator: null
                }
            })
            form.setFieldValue('parameters', [...existingParameters, ...parameters])
        }
        setSelectedParameters((prev) => [...new Set([...selectedParameterIds])]);
        setOpen(false);
    };


    const filteredParameters = useMemo(() => {
        let resultParameters = parameters;
        if (searchTerm)
            resultParameters = parameters.filter((p) =>
                p.parameter.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )

        return resultParameters.sort((a: any, b: any) => a.parameter?.localeCompare(b.parameter));
    }, [searchTerm, parameters]);

    useEffect(() => {
        if (createParameterStatus === AsyncStates.SUCCESS) {
            dispatch(createParametersClear())
        }
    }, [createParameterStatus, dispatch])


    const parameterOptions = () => {
        return (
            <>
                <div className="dropdown-header">
                    <span>{t("common.parameters")} </span>
                    <span className="dropdown-close">
                        <CloseOutlined onClick={() => setOpen(false)} />
                    </span>
                </div>
                <div className="search-container">
                    <Input.Search
                        style={{ width: 300 }}
                        placeholder={t("common.search")}
                        onSearch={(value) => setSearchTerm(value)}
                        allowClear
                    />
                </div>
                <div className="parameter-list-container">
                    {
                        !!filteredParameters.length ? filteredParameters.map((p) => (
                            <div
                                key={p.parameter_id as string}
                                className={`parameter-list-item${selectedParameterIds.includes(p.parameter_id as string)
                                    ? " parameter-selected" : ""}${p.is_default ? " parameter-disabled" : ""}`
                                }
                            >
                                <Checkbox
                                    checked={selectedParameterIds.includes(
                                        p.parameter_id as string
                                    )}
                                    onClick={() =>
                                        updateSelectedParameters(p.parameter_id as string)
                                    }
                                    disabled={p.is_default as boolean}
                                />
                                <span>{p.parameter}</span>
                            </div>
                        )) : <Empty description="No parameters found" />}
                </div>
                <div className="parameter-list-footer">
                    <StyledButton
                        type="link"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => setShowAddParameterModal(true)}
                    >
                        {t("common.Add New")}
                    </StyledButton>
                    <div className="parameter-list-cta">
                        <StyledButton type="default" onClick={reset}>
                            {t("common.reset")}
                        </StyledButton>
                        <StyledButton type="primary" onClick={add}>
                            {t("common.add")}
                        </StyledButton>
                    </div>
                </div>
            </>
        );
    };

    return (

        <>
            <Dropdown
                disabled={!allowEditing}
                dropdownRender={parameterOptions}
                open={open}
                rootClassName="parameter-dropdown"
                placement="bottom"
            >
                <StyledButton
                    disabled={!allowEditing}
                    type="link"
                    style={{ borderRadius: "0" }}
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    {t("common.addNewParameter")}
                </StyledButton>
            </Dropdown>
            {
                showAddParameterModal &&
                <AddEditParameterModal
                    showModal={showAddParameterModal}
                    setShowModal={setShowAddParameterModal}
                    mode={"create"}
                    fetchProperties={fetchProperties}
                    showAddPropertyButton={showAddPropertyButton}
                />
            }
        </>

    );
};

export default ParameterDropdown;
