import { Col, Collapse, DatePicker, Form, Input, message, Row, Select, Space, Spin } from "antd"
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image, pdf } from '@react-pdf/renderer';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { fetchExperimentCleanup, fetchExperimentRequest, fetchLinkedExperimentCleanup, fetchLinkedExperimentRequest } from "src/store/actions/experiment";
import { antdTheme, AsyncStates } from "src/constants";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { fetchFileListRequest, plotsDataClear, plotsDataRequest } from "src/store/actions/workOrderDetails";
import { retrieveProcedureRequest } from "src/store/actions/procedures";
import { StyledButton } from "src/styled_components/StyledButton";
import { getDropdownFilteredValue } from "src/utils/decorator";
import { reportUploadRequest } from "src/store/actions/compareFormulations";
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
dayjs.extend(isSameOrAfter)

Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf' }
    ]
})

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        margin: 10,
        padding: 10,
    },
    header: {
        textAlign: "center",
        marginTop: 40,
    },
    section: {
        paddingRight: 5,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 20,
    },
    index: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSize,
        marginRight: 5
    },
    title: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSizeHeading4,
    },
    subtitle: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSize,
    },
    trialName: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSize,
        color: "grey"
    },
    text: {
        fontFamily: 'Open Sans',
        fontWeight: "thin",
        fontSize: antdTheme.fontSize,
        marginLeft: 10
    },
    image: { position: 'absolute', top: 2, right: 16, height: 100, width: 100 },
    imageContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: 15,
        marginTop: 15,
        marginBottom: 5
    },
    imageView: {
        maxWidth: 270,
        maxHeight: "550px",
        borderStyle: "solid",
        borderWidth: 1,
    },
    tableContainer: { paddingRight: 15, marginTop: 15 },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#F5F5F5"
    },
    tableHeaderRow: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#C0C0C0"
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: antdTheme.fontSizeSM
    },
    tableHeader: {
        margin: "auto",
        marginTop: 5,
        fontSize: antdTheme.fontSizeSM,
        fontFamily: 'Open Sans',
        fontWeight: "extrabold",
        color: "white"
    }
})


export const Verification = ({ currentProject, type }: any) => {
    const dispatch = useDispatch()

    const { data: workOrdersList, status: workOrdersStatus } = useSelector((state: StoreState) => state.workOrders)
    const { linkedExperiment: experimentsList, linkedExperimentStatus: experimentListStatus,
        experiment: experimentDetials, experimentStatus, fileList, plotsData, plotsDataStatus } = useSelector((state: StoreState) => state.workOrderDetails)
    const { retrieveProcedureStatus, data: procedureData } = useSelector((state: StoreState) => state.procedure)
    const reportUploadStatus = useSelector((state: StoreState) => state.compareFormulations.uploadStatus)
    const logo = useSelector((state: StoreState) => state.login.loginResponse.logo)
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const [formState, setFormState] = useState<any>({})
    const [workOrder, setWorkOrder] = useState<any>({})
    const [generate, setGenerate] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>("")
    const [plots, setPlots] = useState<any>({ temperaturePlot: {}, enthalpyPlot: {} })
    const [graphImages, setGraphImages] = useState<any>({ temperaturePlot: {}, enthalpyPlot: {} })
    const [form] = Form.useForm()
    const ref1: any = useRef()
    const ref2: any = useRef()

    useEffect(() => {
        return () => {
            dispatch(fetchExperimentCleanup())
            dispatch(fetchLinkedExperimentCleanup())
            dispatch(plotsDataClear())
        }
    }, [dispatch])

    useEffect(() => {
        if (currentProject?.project_id) {
            form.setFieldsValue({
                project: currentProject?.name,
                background: currentProject?.description
            })
        }
    }, [form, currentProject])

    useEffect(() => {
        if ((!!plots?.temperaturePlot && !!ref1?.current?.chart) || (!!plots?.enthalpyPlot && !!ref2?.current?.chart)) {
            setTimeout(() => {
                (async () => {
                    const tempGraphs = await Promise.all(
                        Object.entries(plots?.temperaturePlot || {}).map(async ([key, value]: any, index: any) => {
                            if (value) {
                                let svgDoms: any = Array.from(document?.getElementsByClassName(
                                    "highcharts-root" || ""
                                )).filter((res: any) => res?.textContent?.toLowerCase()?.includes("time"))
                                svgDoms[index]?.setAttribute("id", "temp_plot" + index)
                                const canvas = document.createElement("canvas")
                                const ctx = canvas.getContext("2d")
                                if (!!svgDoms?.length) {
                                    const svgXml = new XMLSerializer()?.serializeToString(svgDoms[index])
                                    canvas.width = svgDoms[index]?.width?.baseVal?.value
                                    canvas.height = svgDoms[index]?.height?.baseVal?.value
                                    const img = document.createElement("img")
                                    img.setAttribute(
                                        "src",
                                        "data:image/svg+xml;base64," +
                                        window.btoa(unescape(encodeURIComponent(svgXml)))
                                    )
                                    try {
                                        await img.decode()
                                        ctx?.drawImage(
                                            img,
                                            0,
                                            0,
                                            svgDoms[index]?.width?.baseVal?.value,
                                            svgDoms[index]?.height?.baseVal?.value
                                        )
                                        return Promise.resolve({
                                            ...{ [key]: canvas.toDataURL("image/png", 1.0) },
                                        })
                                    } catch (error) {
                                        return Promise.reject()
                                    }
                                }
                            }
                        })
                    )
                    const enthalpyGraphs = await Promise.all(
                        Object.entries(plots?.enthalpyPlot || {}).map(async ([key, value]: any, index: any) => {
                            if (value) {
                                let svgDoms: any = Array.from(document?.getElementsByClassName(
                                    "highcharts-root" || ""
                                )).filter((res: any) => res?.textContent?.toLowerCase()?.includes("enthalpy"))
                                svgDoms[index]?.setAttribute("id", "enthalpy_plot" + index)
                                const canvas = document.createElement("canvas")
                                const ctx = canvas.getContext("2d")
                                if (!!svgDoms?.length) {
                                    const svgXml = new XMLSerializer()?.serializeToString(svgDoms[index])
                                    canvas.width = svgDoms[index]?.width?.baseVal?.value
                                    canvas.height = svgDoms[index]?.height?.baseVal?.value
                                    const img = document.createElement("img")
                                    img.setAttribute(
                                        "src",
                                        "data:image/svg+xml;base64," +
                                        window.btoa(unescape(encodeURIComponent(svgXml)))
                                    )
                                    try {
                                        await img.decode()
                                        ctx?.drawImage(
                                            img,
                                            0,
                                            0,
                                            svgDoms[index]?.width?.baseVal?.value,
                                            svgDoms[index]?.height?.baseVal?.value
                                        )
                                        return Promise.resolve({
                                            ...{ [key]: canvas.toDataURL("image/png", 1.0) },
                                        })
                                    } catch (error) {
                                        return Promise.reject()
                                    }
                                }
                            }
                        })
                    )
                    setGraphImages({
                        temperaturePlot: tempGraphs.reduce((obj: any, element: any) => ({
                            ...obj, [Object.keys(element || {})?.[0]]: Object.values(element || {})?.[0]
                        }), {}),
                        enthalpyPlot: enthalpyGraphs.reduce((obj: any, element: any) => ({
                            ...obj, [Object.keys(element || {})?.[0]]: Object.values(element || {})?.[0]
                        }), {})
                    })
                })()
            }, 2000)
        } else {
            setGraphImages({ temperaturePlot: {}, enthalpyPlot: {} })
        }
    }, [plots])

    useEffect(() => {
        if (plotsDataStatus === AsyncStates.SUCCESS) {
            (async () => {
                const finalTempPlots: any = {}
                Object.entries(plotsData?.line || {}).forEach(([key, value]: any) => {
                    const temperaturePlot = {
                        chart: {
                            type: 'line',
                            height: "550px"
                        },
                        title: {
                            text: 'Temperature vs time curve', style: { fontSize: "1.5em", fontWeight: 'bold' }
                        },
                        legend: { itemStyle: { fontSize: "1.5em" } },
                        xAxis: {
                            title: {
                                text: 'Time in Minutes',
                                style: { fontSize: "1.5em", fontWeight: 'bold' }
                            },
                            labels: { style: { fontSize: "1.5em", fontWeight: 'bold' } },
                            categories: value?.time,
                            tickInterval: 100
                        },
                        yAxis: {
                            title: {
                                text: 'Temperature in °C', style: { fontSize: "1.5em", fontWeight: 'bold' }
                            },
                            labels: { style: { fontSize: "1.5em", fontWeight: 'bold' } },
                        },
                        series: [{
                            type: "line",
                            data: value?.bath,
                            name: 'Bath',
                            color: "#b3050e"
                        }, {
                            type: "line",
                            data: value?.trial_data,
                            name: 'Temperature',
                            color: "#0559b3"

                        }]
                    }
                    finalTempPlots[key] = temperaturePlot
                })
                const finalEnthalpyPlots: any = {}
                Object.entries(plotsData?.bar || {}).forEach(([key, value]: any) => {
                    const enthalpyPlot = {
                        chart: {
                            type: 'column',
                            height: "550px"
                        },
                        title: {
                            text: 'Enthalpy vs temperature curve', style: { fontSize: "1.5em", fontWeight: 'bold' }
                        },
                        legend: { itemStyle: { fontSize: "1.5em" } },
                        xAxis: {
                            title: {
                                text: 'Temperature in °C', style: { fontSize: "1.5em", fontWeight: 'bold' }
                            },
                            labels: { style: { fontSize: "1.5em", fontWeight: 'bold' } },
                            categories: value?.trial_data
                        },
                        yAxis: {
                            title: {
                                text: 'Enthalpy in J/g', style: { fontSize: "1.5em", fontWeight: 'bold' }
                            },
                            labels: { style: { fontSize: "1.5em", fontWeight: 'bold' } },
                        },
                        series: [{
                            type: "column",
                            data: value?.enthalpy_freezing,
                            name: 'Enthalpy-Melting',
                            color: "#0559b3"
                        }, {
                            type: "column",
                            data: value?.enthalpy_melting,
                            name: 'Enthalpy-Freezing',
                            color: "#b3050e"
                        }]
                    }
                    finalEnthalpyPlots[key] = enthalpyPlot
                })
                setPlots({ temperaturePlot: finalTempPlots, enthalpyPlot: finalEnthalpyPlots })
            }
            )()
        } else {
            setPlots({ temperaturePlot: {}, enthalpyPlot: {} })
        }
    }, [plotsData, plotsDataStatus])

    const onWorkOrderChange = (e: string) => {
        const currentWo = workOrdersList.find((res: any) => res?.work_order_id === e)
        form.setFieldsValue({
            experiment: [],
            objective: currentWo?.work_order_desc,
            date: dayjs(currentWo?.created)
        })
        setFormState((prevState: any) => ({
            ...prevState,
            objective: currentWo?.work_order_desc,
            date: currentWo?.created
        }))
        dispatch(fetchLinkedExperimentRequest({ experiment_id: currentWo?.experiment_id, linked_stages: true }))
        dispatch(fetchFileListRequest({ work_order_id: currentWo?.work_order_id }))
        dispatch(retrieveProcedureRequest({ work_order_id: currentWo.work_order_id }))
        setWorkOrder(currentWo)
    }

    const onExperimentChange = (e: string) => {
        dispatch(fetchExperimentRequest({ formulation_id: e }))
        dispatch(plotsDataRequest({ formulation_id: e }))
        const currentExps = experimentsList.filter((res: any) => e.includes(res?.formulation_id))
        const currentFiles = fileList.filter((res: any) => e.includes(res?.formulation_id))
        let proceduceStr = ""
        procedureData.forEach((res: any) => {
            if (res?.type === "paragraph") {
                const cleanedStr = res?.data?.text.replace("/(|<([^>]+)>)/", '').replace('&nbsp;', '')
                if (proceduceStr) {
                    proceduceStr = proceduceStr + "\n" + cleanedStr
                } else {
                    proceduceStr = cleanedStr
                }
            }
        })
        form.setFieldsValue({
            trial_no: currentExps.map((res: any) => res?.formulation_display_id).join(", "),
            ...(type === "verification-note" && { trial_note: currentExps.map((res: any) => res?.meta?.comment).join(", ") }),
            file_reference: currentFiles?.map((res: any) => res?.filename).join(", "),
            experimental_procedure: proceduceStr

        })
        setFormState((prevState: any) => ({
            ...prevState,
            trial_no: currentExps.map((res: any) => res?.formulation_display_id).join(", "),
            ...(type === "verification-note" && { trial_note: currentExps.map((res: any) => res?.meta?.comment).join(", ") }),
            file_reference: currentFiles?.map((res: any) => res?.filename).join(", "),
            experimental_procedure: proceduceStr
        }))
    }

    const renderValue = (key: string, value: any) => {
        if (key === "date") {
            return new Date(value).toLocaleDateString()
        }
        else if (key === "work_order") {
            return workOrdersList.find((res: any) => res?.work_order_id === workOrder?.work_order_id)?.work_order_name
        }
        return value
    }

    const pdfDocument = (
        <Document title={"TRIAL R&D DEVELOPMENT / WORK REPORT"} >
            <Page size="A4" style={styles.page} orientation="portrait" key={'page_'} wrap>
                <View style={styles.image}>
                    <Image fixed={true} cache src={logo + "?not-from-cache-please"} />
                </View>
                <View style={styles.header}>
                    <Text style={styles.title}>{"TRIAL R&D DEVELOPMENT / WORK REPORT"}</Text>
                </View>
                {Object.entries(formState || {}).filter(([key, value]: any) => !!value)
                    .map(([key, value]: any, index: number) => (
                        <View style={styles.section} >
                            <Text style={styles.subtitle}>{`${index + 1}. `}</Text>
                            <Text style={styles.subtitle}>{`${(String(key)).split("_").map((res: string) => res?.[0]?.toUpperCase() + res?.slice(1)).join(" ")}:`}</Text>
                            <Text style={styles.text}>{renderValue(key, value)}</Text>
                        </View>
                    ))}
                {!!experimentDetials?.length &&
                    <View style={styles.section} >
                        <Text style={styles.subtitle}>{`${Object.values(formState || {}).filter((value: any) => !!value).length + 1}. `}</Text>
                        <Text style={styles.subtitle}>{"Annexure:"}</Text>
                    </View>
                }
                {experimentDetials.map((exp: any) => (
                    <>
                        <View style={styles.tableContainer}>
                            <Text style={styles.trialName}>{exp?.meta?.display_id}</Text>
                            <Text style={styles.subtitle}>{"Formulation"}</Text>
                            <View style={styles.table}>
                                <View style={styles.tableHeaderRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableHeader}>{"Category"}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableHeader}>{"Parameter"}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableHeader}>{"Unit"}</Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableHeader}>{"Value"}</Text>
                                    </View>
                                </View>
                                {Object.entries(exp?.ingredients || {}).filter(([key, value]: any) => !!value?.value)
                                    .map(([key, value]: any) => (
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{displayNames?.ingredients?.[key]?.category || value?.category}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{displayNames?.ingredients?.[key]?.name || value?.name || key}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{value?.unit}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{value?.value}</Text>
                                            </View>
                                        </View>
                                    ))}
                            </View>
                        </View>
                        {["processing", "characterizations", "properties"].filter((parameter: any) => Object.entries(exp?.[parameter]?.[0]?.[parameter] || {}).filter(([key, value]: any) => value?.value).length)
                            .map((parameter: any) => (
                                <View style={styles.tableContainer}>
                                    <Text style={styles.subtitle}>{`${(String(parameter)).split("_").map((res: string) => res?.[0]?.toUpperCase() + res?.slice(1)).join(" ")}`}</Text>
                                    <View style={styles.table}>
                                        <View style={styles.tableHeaderRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableHeader}>{"Category"}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableHeader}>{"Parameter"}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableHeader}>{"Unit"}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableHeader}>{"Value"}</Text>
                                            </View>
                                        </View>
                                        {Object.entries(exp?.[parameter]?.[0]?.[parameter] || {}).filter(([key, value]: any) => !!value?.value)
                                            .map(([key, value]: any) => (
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>{displayNames?.[parameter]?.[key]?.category || value?.category}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>{displayNames?.[parameter]?.[key]?.name || value?.name || key}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>{value?.unit}</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>{value?.value}</Text>
                                                    </View>
                                                </View>
                                            ))}
                                    </View>
                                </View>
                            ))}
                        <View style={styles.imageContainer}>
                            {!!graphImages?.temperaturePlot?.[exp?.meta?.display_id] &&
                                <View style={styles.imageView}>
                                    <Image src={graphImages.temperaturePlot?.[exp?.meta?.display_id]}
                                        style={{
                                            maxWidth: 270,
                                        }}
                                    />
                                </View>
                            }
                            {!!graphImages?.enthalpyPlot?.[exp?.meta?.display_id] &&
                                <View style={styles.imageView}>
                                    <Image src={graphImages.enthalpyPlot?.[exp?.meta?.display_id]}
                                        style={{
                                            maxWidth: 270,
                                        }}
                                    />
                                </View>
                            }
                        </View>
                    </>
                ))}
            </Page>
        </Document>
    )


    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row justify={"space-between"}>
                <Col span={16}>
                    <Spin
                        spinning={[workOrdersStatus, experimentStatus, retrieveProcedureStatus, plotsDataStatus, experimentListStatus].includes(AsyncStates.LOADING)}
                        indicator={<LoadingOutlined />}
                    >
                        <Form name="trial_verification" layout="vertical" form={form}
                            onFieldsChange={(_, allFields) => {
                                setFormState(allFields
                                    .filter((field: any) => !["experiment"].includes(field?.name[0]))
                                    .reduce((obj: any, field: any) => ({ ...obj, [field.name[0]]: field?.value }), {})
                                )
                            }}>
                            <Form.Item label={"Work order"} name={"work_order"}>
                                <Select onChange={onWorkOrderChange} showSearch
                                    filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}>
                                    {workOrdersList.map((res: any) => (
                                        <Select.Option key={res?.work_order_id} value={res?.work_order_id}>
                                            {res?.work_order_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={"Project"} name={"project"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Experiment"} name={"experiment"}>
                                <Select onChange={onExperimentChange} showSearch mode="multiple"
                                    filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}>
                                    {experimentsList.map((res: any) => (
                                        <Select.Option key={res?.formulation_id} value={res?.formulation_id}>
                                            {res?.formulation_display_id}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={"Trial No"} name={"trial_no"}>
                                <Input />
                            </Form.Item>
                            {type === "verification-note" &&
                                <Form.Item label={"Trial Note"} name={"trial_note"}>
                                    <Input.TextArea rows={5} />
                                </Form.Item>
                            }
                            <Form.Item label={"File Reference"} name={"file_reference"}>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                            <Form.Item label={"Objective"} name={"objective"}>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                            <Form.Item label={"Back ground"} name={"background"}>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                            <Form.Item label={"Date"} name={"date"}>
                                <DatePicker />
                            </Form.Item>
                            <Form.Item label={"Criteria of Success"} name={"criteria_of_success"}>
                                <Input.TextArea rows={2} />
                            </Form.Item>
                            <Form.Item label={"Experimental Procedure"} name={"experimental_procedure"}>
                                <Input.TextArea rows={5} />
                            </Form.Item>
                            <Form.Item label={"Observations"} name={"observations"}>
                                <Input.TextArea rows={2} />
                            </Form.Item>
                            <Form.Item label={"Disposal"} name={"disposal"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Precautions"} name={"precautions"}>
                                <Input.TextArea rows={2} />
                            </Form.Item>
                            <Form.Item label={"Further Action"} name={"further_action"}>
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Col>
            </Row>
            <Collapse onChange={(e) => setGenerate(Boolean(e?.length))}>
                <Collapse.Panel header={"Preview"} key={"preview"} extra={
                    <Space onClick={(e) => e.stopPropagation()}>
                        <Input style={{ width: 250 }} value={fileName} onChange={e => setFileName(e.target.value)} />
                        <StyledButton loading={reportUploadStatus === AsyncStates.LOADING} onClick={() => {
                            if (!fileName) {
                                message.error("Please add a file name")
                                return
                            }
                            pdf(pdfDocument).toBlob().then((report) => {
                                dispatch(reportUploadRequest({
                                    report,
                                    type,
                                    file_name: fileName,
                                    data: formState,
                                    from: "modularReports"
                                }))
                            })
                        }} type="primary">{"Save"}</StyledButton>
                    </Space>
                }>
                    {generate &&
                        <PDFViewer style={{ width: "100%", height: 1280 }}>
                            {pdfDocument}
                        </PDFViewer>
                    }
                </Collapse.Panel>
            </Collapse>
            <div id="graph" style={{ display: "none" }}>
                {Object.entries(plots?.temperaturePlot || {})?.map(([key, value]: any) => (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={value}
                        ref={ref1}
                    />
                ))}
                {Object.entries(plots?.enthalpyPlot || {})?.map(([key, value]: any) => (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={value}
                        ref={ref2}
                    />
                ))}
            </div>
        </Space>
    )
}