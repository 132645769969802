import React, { useEffect, useState } from "react"
import { CaretRightOutlined } from "@ant-design/icons"
import { MainTable } from "./main-table"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { ZeonPredicton } from "./types"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { Checkbox, message } from "antd"
import { antdTheme, AsyncStates } from "src/constants"
import { Legend } from "./legend"
import { WorkOrderModal } from "../WorkOrderModal"


export const PredictionResultZeon = () => {
	const [collapsed, setCollapsed] = useState(false)
	const [selectedExperiments, setSelectedExperiments] = useState<
		ZeonPredicton[]
	>([])
	const [showZeroValue, setShowZeroValue] = useState(false)
	const { forwardPredResult: { predictions: forwardPredResult, hidden_procs: processingsWithZeroValue, hidden_ings: ingredientsWithZeroValue = [] }, forwardPredResultStatus } = useSelector(
		(state: StoreState) => state.formulate
	)
	const [t] = useTranslate()
	const [workOrderVisible, setWorkOrderVisible] = useState(false)

	useEffect(() => {
		if (forwardPredResultStatus === AsyncStates.SUCCESS) {
			setShowZeroValue(false)
			setTimeout(() => {
				document.getElementById("forwardpred-results")?.scrollIntoView()
			}, 300)
		}
	}, [forwardPredResultStatus, forwardPredResult])

	if (forwardPredResult.length === 0) return null

	return (<>
		<section
			id="forwardpred-results"
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				scrollMarginTop: "1rem",
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					gap: "2rem",
				}}
			>
				<h2
					onClick={() => setCollapsed(!collapsed)}
					style={{
						display: "flex",
						alignItems: "center",
						gap: "0.5rem",
						userSelect: "none",
						cursor: "pointer",
					}}
				>
					<CaretRightOutlined
						style={{ fontSize: antdTheme.fontSizeHeading4 }}
						rotate={collapsed ? 90 : 0}
					/>
					<span>Prediction Results</span>
				</h2>

				<div style={{ display: "flex", justifyContent: "space-between", gap: "0.5rem", alignItems: "center" }}>
					{(!!processingsWithZeroValue.length || !!ingredientsWithZeroValue.length) &&
						<Checkbox
							checked={showZeroValue}
							onChange={() => {
								setShowZeroValue((prev) => !prev)
							}}>
							{t("common.showZeroValues")}
						</Checkbox>
					}
					<StyledButton onClick={() => {
						if (!!selectedExperiments?.length) {
							setWorkOrderVisible(true)
						} else {
							message.error(t("forwardPredResult.atleastOneExperiment"))
						}
					}} type="primary">
						{t("common.generateWorkOrder")}
					</StyledButton>
				</div>
			</div>

			{!collapsed && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "1rem",
					}}
				>
					<Legend />
					<MainTable
						selectedExperiments={selectedExperiments}
						setSelectedExperiments={setSelectedExperiments}
						data={forwardPredResult}
						showZeroValue={showZeroValue}
					/>
				</div>
			)}
		</section>
		<WorkOrderModal
			workOrderVisible={workOrderVisible}
			setWorkOrderVisible={setWorkOrderVisible}
			checkedExperiments={selectedExperiments.map((exp) => exp.experiment_id)}
			experiments={forwardPredResult}
			type={"forward"}
		/>
	</>
	)
}
