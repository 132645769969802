import { useCallback } from "react"

export const useSessionStorage = () => {
    const setSessionStorage = useCallback((keyName: string, data: any) => {
        window.sessionStorage.setItem(keyName, JSON.stringify(data))
    }, [])

    const deleteSessionStorage = useCallback((keyName: string) => {
        window.sessionStorage.removeItem(keyName)
    }, [])

    const getSessionStorage = useCallback((keyName: string) => {
        const data = window.sessionStorage.getItem(keyName)
        if (data) {
            return JSON.parse(data)
        } else {
            return null
        }
    }, [])

    return { setSessionStorage, deleteSessionStorage, getSessionStorage }
}
