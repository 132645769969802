import React from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { CompareFormulation } from "./Compare"
import { ZeonCompareFormulation } from "./ZeonCompare"

const CompareFormulationWrapper = () => {
	const configs = useSelector((state: StoreState) => state.configs.features)

	if (Boolean(configs?.characterization_methods)) return <ZeonCompareFormulation />

	return <CompareFormulation />
}

export default CompareFormulationWrapper
