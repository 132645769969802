import { useMemo } from "react";

interface UseHighlightOptionsProps {
    data: {
        [key: string]: string
    },
    searchStr: string
}

export function useHighlightOptions({ data, searchStr }: UseHighlightOptionsProps) {
    const options = useMemo(() => {
        return Object.entries(data || {}).map(([value, label]: any) => {
            if (searchStr) {
                const index = label?.toLowerCase()?.indexOf(searchStr?.toLowerCase());

                if (index !== -1) {

                    const length = searchStr.length;

                    const prefix = label?.substring(0, index);
                    const suffix = label?.substring(index + length);
                    const match = label?.substring(index, index + length);

                    return ({
                        label: (
                            <span>
                                {prefix}<b>{match}</b>{suffix}
                            </span>
                        ),
                        value: label,
                        identifier: value,
                        key: value
                    });
                }
            }
            return ({ label, value: label, identifier: value, key: value })
        })
    }, [data, searchStr])

    return options
}