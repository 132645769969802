import React, { useMemo } from "react";
import { Space, Table, Typography } from "antd";
import { NumericalDataSummary as Data } from "src/components/AIEngine/DataSummary/types";
import { SummaryWrapper } from "./summary-wrapper";
import useTranslate from "src/utils/useTranslate";
import { useValue } from "src/utils/useValue";
const { Text } = Typography;

type P = {
  data: any;
  mergedData: Data;
  getDisplayName: (value: string) => string;
};

export const NumericalDataSummary = ({
  mergedData,
  data,
  getDisplayName,
}: P) => {
  const [t] = useTranslate();
  const { getValue } = useValue()
  const tableData = useMemo(() => {
    const keys = Object.keys(mergedData);
    const firstElement = mergedData[keys[0]];

    const fieldsArr: {
      field: keyof typeof firstElement;
      [key: string]: string;
    }[] = Object.keys(firstElement).map((key, i) => ({
      key: i.toString(),
      field: key as keyof typeof firstElement,
    }));

    fieldsArr.forEach((obj, index: number) => {
      keys.forEach((key) => {
        let value = mergedData[key][obj.field];
        if (value === "_NA_") {
          obj[key] = "-";
        } else if (index === 0) {
          obj[key] = value?.split("-").map((res: any) => getValue(res)).join("-")
        } else {
          obj[key] = getValue(value);
        }
      });
    });

    return fieldsArr;
  }, [mergedData, getValue]);

  const columns: any[] = Object.keys(data).map((title) => {
    const titleMapping: { [key: string]: string } = {
      ingredients: t("common.ingredients"),
      processing: t("common.processing"),
      properties: t("common.properties"),
      costing: t("inventory.costing"),
    };

    const colorMapping: { [key: string]: string } = {
      ingredients: "#2980B9",
      processing: "#633974",
      properties: "#F39C12",
      costing: "#1a9850",
    };
    return Object.keys(data[title]).length > 0 ? (
      <Table.ColumnGroup
        key={title}
        title={
          <span
            style={{
              color: colorMapping[title],
              width: "100%",
              fontWeight: "bold",
            }}
          >
            {titleMapping[title]}
          </span>
        }
        align={"left" as any}
      >
        {Object.keys(data[title])
          .map((key: any) => {
            return (
              <Table.Column
                dataIndex={key}
                title={() => (
                  <span
                    style={{
                      width: "100%",
                      color: colorMapping[title],
                      fontWeight: "bold",
                    }}
                  >
                    {getDisplayName(key)}
                  </span>
                )}
                key={key}
                width={150}
                align={"center" as any}
                render={(text: string) => (
                  <span
                    style={{ color: colorMapping[title], fontWeight: "bold" }}
                  >
                    {text}
                  </span>
                )}
              />
            );
          })}
      </Table.ColumnGroup>
    ) : null;
  });

  return (
    <SummaryWrapper
      key={t("dataSummary.numericalDataSummary")}
      heading={t("dataSummary.numericalDataSummary")}
      tooltip={t("aiEngine.tab.dataSummary.numericaldatasummary")}
    >
      <Space style={{ width: "100%", overflowX: "auto" }} direction="vertical">
        <Table
          dataSource={tableData}
          pagination={false}
          scroll={{ x: 5 }}
          bordered
        >
          <Table.Column
            dataIndex="field"
            title={t("common.stats")}
            key="stats"
            fixed={"left" as any}
            width={120}
            align={"center" as any}
            render={(text: string) => <Text strong>{text}</Text>}
          />
          {columns.filter((c) => c !== null).map((c) => c)}
        </Table>
      </Space>
    </SummaryWrapper>
  );
};
