import { motion } from 'framer-motion'
import { pdfjs, Document, Page } from 'react-pdf'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TopActionBar } from './TopActionBar'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { Drawer, Modal, Space, Spin, Typography } from 'antd'
import {
  qcFileExtractionsRequest,
  qcSetFileDrawerVisible,
  qcSetIsReportSectionUnsaved
} from 'src/store/actions/qualityCheck'
import { CloseOutlined, ExclamationCircleFilled, FilePdfOutlined } from '@ant-design/icons'
import { QualityCheckStatus } from '..'
import ViewReport from './ViewReport'
import useTranslate from 'src/utils/useTranslate'
import { modalProps } from '../FilesTable'
import { antdTheme } from 'src/constants'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const PdfViewer = () => {
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const {
    fileNavigationOpen,
    selectedFile,
    pageNumber,
    fileDrawerVisible,
    isReportSectionUnsaved
  } = useSelector((state: StoreState) => state.qualityCheck)

  const url = useMemo(() => {
    return selectedFile?.s3_link
  }, [selectedFile?.s3_link])

  const [numPages, setNumPages] = useState<number>()
  const [pageScale, setPageScale] = useState<number>(0.8)
  const [openReportSection, setOpenReportSection] = useState<boolean>(false)

  useEffect(() => {
    if (fileNavigationOpen) setOpenReportSection(false)
  }, [fileNavigationOpen])

  useEffect(() => {
    if (
      !fileNavigationOpen &&
      (selectedFile?.status === QualityCheckStatus.TABLE_EXTRACTED ||
        selectedFile?.status === QualityCheckStatus.COMPLETED)
    )
      setOpenReportSection(true)
  }, [fileNavigationOpen, selectedFile])

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
    setDocRendered(true)
  }

  // This is a hack to force the page to become visible as react-pdf has a bug and it makes it invisible
  useEffect(() => {
    const createInterval = setInterval(() => {
      const container = document.querySelector(
        '.pdf-viewer-container canvas.react-pdf__Page__canvas'
      ) as HTMLElement
      const visibility = container?.style.getPropertyValue('visibility')
      if (visibility !== 'visible') {
        container?.style.setProperty('visibility', 'visible')
      }
    }, 200)

    return () => clearInterval(createInterval)
  }, [])

  const [docRendered, setDocRendered] = useState<boolean>(false)

  useEffect(() => {
    const pdf: HTMLElement | null = document.querySelector(
      '.pdf-viewer-container'
    )
    const resizer: HTMLElement | null =
      document.querySelector('.resizer-container')
    const report: HTMLElement | null = document.querySelector(
      '.report-viewer-container'
    )

    const initResize = (e: any) => {
      window.addEventListener('mousemove', Resize)
      window.addEventListener('mouseup', stopResize)
    }

    const Resize = (e: any) => {
      resizer!.style.backgroundColor = antdTheme.colorPrimary as string
      pdf!.style.width = e.clientX - pdf!.getBoundingClientRect().left + 'px'
      report!.style.width = `calc(100% - ${pdf!.style.width})`
    }

    const stopResize = (e: any) => {
      resizer!.style.backgroundColor = '#434343'
      window.removeEventListener('mousemove', Resize)
      window.removeEventListener('mouseup', stopResize)
    }

    resizer?.addEventListener('mousedown', initResize)

    return () => {
      resizer?.removeEventListener('mousedown', initResize)
    }
  })

  const onClose = useCallback(() => {
    if (isReportSectionUnsaved) {
      Modal.confirm({
        title: t('common.saveChanges'),
        content: t('common.unsavedChangesWillLost'),
        icon: <ExclamationCircleFilled style={{
          fontSize: antdTheme.fontSizeHeading4,
          color: '#FAAD14',
          marginTop:4
        }} />,
        onOk: () => {
          if (selectedFile?.status === QualityCheckStatus.COMPLETED) {
            dispatch(
              qcFileExtractionsRequest({
                file_id: selectedFile?.file_id,
              })
            )
          }

          dispatch(qcSetIsReportSectionUnsaved(false))
          dispatch(qcSetFileDrawerVisible(false))
        },
        onCancel: () => {
          dispatch(qcSetIsReportSectionUnsaved(false))
          dispatch(qcSetFileDrawerVisible(false))
        },
        cancelText: t('common.cancel'),
        okText: t('common.save'),
        ...modalProps,
        centered: true,
        type: 'warning',
      })
    }else{
      dispatch(qcSetFileDrawerVisible(false))
    }
  }, [
    dispatch,
    isReportSectionUnsaved,
    selectedFile?.file_id,
    selectedFile?.status,
    t
  ])

  return (
    <Drawer
      open={fileDrawerVisible}
      onClose={() => {
        onClose()
      }}
      destroyOnClose={true}
      placement="bottom"
      style={{
        height: '100%'
      }}
      height={'100%'}
      styles={{
        body: {
          height: '100%',
          padding: 0
        },
        header: {
          padding: '10px 16px'
        }
      }}
      closeIcon={null}
      title={
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10
          }}
        >
          <FilePdfOutlined
            style={{
              color: '#FF4D4F',
              fontSize: antdTheme.fontSizeHeading5
            }}
          />
          <Typography.Text
            style={{
              maxWidth: '600px',
              fontSize: antdTheme.fontSizeHeading5,
              fontWeight: 600
            }}
            ellipsis={{
              tooltip: selectedFile?.file_name
            }}
          >
            {selectedFile?.file_name}
          </Typography.Text>
        </Space>
      }
      extra={
        <CloseOutlined
          style={{
            outline: 'none',
            border: 'none',
            fontSize: antdTheme.fontSizeHeading5
          }}
          onClick={() => onClose()}
        />
      }
    >
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        transition={{ type: 'just' }}
        style={{
          height: '100%',
          width: '100%',
          flexGrow: 1,
          backgroundColor: '#1F1F1F',
          display: 'flex',
          flexDirection: 'column',
          gap: 0,
          transition: 'width 0.3s'
        }}
      >
        <TopActionBar
          numPages={numPages}
          pageScale={pageScale}
          setPageScale={setPageScale}
          openReportSection={openReportSection}
          setOpenReportSection={setOpenReportSection}
          docRendered={docRendered}
        />

        <div
          style={{
            height: 'calc(100% - 64px)',
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <div
            style={{
              height: '100%',
              width: '2%'
            }}
          ></div>

          {!docRendered && (
            <div
              style={{
                width: '100%',
                maxWidth: '100%',
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexGrow: 1
              }}
            >
              <Spin />
            </div>
          )}

          <div
            style={{
              width: !docRendered ? 0 : '46%',
              maxWidth: '100%',
              height: '100%',
              overflow: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              scrollbarWidth: 'thin'
            }}
            className="pdf-viewer-container"
          >
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                key={`page_${pageNumber}`}
                pageNumber={pageNumber}
                size="A4"
                scale={pageScale}
              />
            </Document>
          </div>

          <div
            style={{
              height: '100%',
              width: '2%'
            }}
          ></div>

          {openReportSection && (
            <div
              className="resizer-container"
              style={{
                height: '100%',
                width: 3,
                backgroundColor: '#434343',
                cursor: 'ew-resize',
                position: 'relative'
              }}
            ></div>
          )}

          <ViewReport
            numPages={numPages}
            openReportSection={openReportSection}
          />
        </div>
      </motion.div>
    </Drawer>
  )
}
