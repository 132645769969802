import { useCallback, useEffect, useState } from 'react';
import {
    Modal,
    Tabs,
    Form,
    Input,
    Row,
    message,
    Spin,
    Select,
    Popconfirm,
    Typography,
    Space,
} from "antd";
import { DatasheetTable } from './DatasheetTable'
import { CharacterizationGraph } from './CharcaterizationUpload'
import { useDispatch, useSelector } from 'react-redux'
import { saveIstaProfilesRequest, deleteIstaProfilesRequest } from 'src/store/actions/celsure'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { orange } from '@ant-design/colors'
import useTranslate from 'src/utils/useTranslate'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton';

const { Text } = Typography
const { TabPane } = Tabs
const { Option } = Select

export const IstaProfiles = ({ profileModalVisible, setProfileModalVisible }: any) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()

    const { saveIstaProfilesStatus, deleteIstaProfilesStatus, celsureDisplayNamesStatus, celsureDisplayNamesData } = useSelector((state: StoreState) => state.celsure)

    const [createData, setcreateData] = useState<any[]>([])
    const [editData, setEditData] = useState<any[]>([])
    const [editingState, setEditingState] = useState<boolean>(false)
    const [form] = Form.useForm()
    const [type, setType] = useState("create")

    const resetData = useCallback(() => {
        setcreateData([])
        setEditData([])
        form.resetFields()
        setEditingState(false)
    }, [form])

    useEffect(() => {
        if (saveIstaProfilesStatus === AsyncStates.SUCCESS || deleteIstaProfilesStatus === AsyncStates.SUCCESS) {
            resetData()
        }
    }, [saveIstaProfilesStatus, deleteIstaProfilesStatus, resetData])

    const selectProfile = (e: any) => {
        setEditData(celsureDisplayNamesData?.ista_profiles?.find((res: any) => res.name === e)?.data)
        form.setFieldsValue({ name_select_edit: e })
    }

    const submitForm = (values: any) => {
        if ((!createData.length && type === "create") || (!editData.length && type === "edit")) {
            message.error("Add ambient time & temperature values for the profile")
            return
        }
        dispatch(saveIstaProfilesRequest(type === "create" ? { name: values.name_input, data: createData } :
            {
                profile_id: celsureDisplayNamesData?.ista_profiles?.find((res: any) => res.name === values?.name_select)?.profile_id,
                name: values?.name_select_edit,
                data: editData
            }))
    }

    const profileForm = () => (
        <Spin
            spinning={saveIstaProfilesStatus === AsyncStates.LOADING || celsureDisplayNamesStatus === AsyncStates.LOADING || deleteIstaProfilesStatus === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}>
            <Form onFinish={submitForm} form={form} style={{ marginTop: 20 }} requiredMark={false} onFieldsChange={() => {
                if (!editingState) setEditingState(true)
            }}>
                {type === "create" ?
                    <Form.Item name="name_input" label={"Profile Name"} rules={[{ required: true, message: "required" }]}
                        required 
                        tooltip={requiredFieldStar}
                    >
                        <Input />
                    </Form.Item> :
                    <>
                        <Form.Item name="name_select" label={t("common.select")} rules={[{ required: true, message: "required" }]}
                        required 
                        tooltip={requiredFieldStar}
                        >
                            <Select placeholder="Select Profile" onSelect={selectProfile}>
                                {celsureDisplayNamesData?.ista_profiles?.map((res: any) => (
                                    <Option key={res.profile_id} value={res.name}>{res.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="name_select_edit" label={"Profile"} rules={[{ required: true, message: "required" }]}
                            required 
                            tooltip={requiredFieldStar}
                        >
                            <Input />
                        </Form.Item>
                    </>
                }
                <DatasheetTable
                    data={type === "create" ? createData : editData}
                    setData={type === "create" ? setcreateData : setEditData}
                    setModalEditingState={setEditingState}
                    type={"ambient"} title={"Ambient Time and Temperature"}
                />
                {((!!createData.length && type === "create") || (!!editData.length && type === "edit")) &&
                    <Row justify='center'>
                        <CharacterizationGraph ambientTableData={type === "create" ? createData : editData} />
                    </Row>}
                <Row justify='end'>
                    <Space>
                        <StyledButton type='primary' htmlType='submit'>{t("common.submit")}</StyledButton>
                        <Popconfirm title={"Clear data?"} onConfirm={resetData}>
                            <StyledButton>{t("compare.clear")}</StyledButton>
                        </Popconfirm>
                    </Space>
                </Row>
            </Form>
        </Spin>

    )


    return (
        <Modal width={800}
            footer={""}
            title={"ISTA profiles"}
            open={profileModalVisible}
            maskClosable={false}
            onCancel={() => {
                const reset = () => {
                    resetData()
                    setProfileModalVisible(false)
                }
                if (editingState) {
                    Modal.confirm({
                        title: t("common.confirm"),
                        icon: <ExclamationCircleOutlined />,
                        content: 'Unsaved Changes. Discard?',
                        onOk: () => reset()
                    })
                } else reset()
            }}>
            <Tabs onChange={setType} tabBarExtraContent={editingState ?
                <Text style={{ color: orange.primary }}>{t("common.unsavedChanges")}</Text> : ""}>
                <TabPane tab={t('common.create')} key="create">
                    {profileForm()}
                </TabPane>
                <TabPane tab={`${t('common.edit')}/${t('common.delete')}`} key="edit">
                    <Row justify='end'>
                        <Popconfirm title={`${t('istaProfiles.deleteProfile')}?`}
                            disabled={!form.getFieldsValue()?.name_select}
                            onConfirm={() => dispatch(deleteIstaProfilesRequest({ profile_id: [celsureDisplayNamesData?.ista_profiles?.find((res: any) => res.name === form.getFieldsValue()?.name_select)?.profile_id] }))}>
                            <StyledButton danger disabled={!form.getFieldsValue()?.name_select} icon={<StyledDeleteIcon />}>{t("istaProfiles.deleteProfile")}</StyledButton>
                        </Popconfirm>
                    </Row>
                    {profileForm()}
                </TabPane>
            </Tabs>
        </Modal>
    )
}
