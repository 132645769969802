import useTranslate from "src/utils/useTranslate";
import { CATEGORY_TYPES } from "../Common/Data";

type TProps = {
    type: CATEGORY_TYPES;
    setType: React.Dispatch<React.SetStateAction<CATEGORY_TYPES>>;
};

const CategoryTypesSidebar = ({ type, setType }: TProps) => {
    const [t] = useTranslate();

    const categoryLabel = (category: CATEGORY_TYPES) => {
        if (category === CATEGORY_TYPES.INGREDIENT) return t("common.ingredient");
        else if (category === CATEGORY_TYPES.ADDITIVE) return t("inventory.Additives");
        else if (category === CATEGORY_TYPES.PROPERTY) return t("common.property");
        else return category;
    }

    return (
        <div className="category-sidebar">
            <div className="body">
                {Object.values(CATEGORY_TYPES).map((c) => (
                    <div
                        key={c}
                        className={`category${type === c ? " selected" : ""}`}
                        onClick={() => setType(c)}
                    >
                        {categoryLabel(c).toLowerCase()}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CategoryTypesSidebar;
