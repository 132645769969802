import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  assetLibraryAddFileFailure,
  assetLibraryAddFileRequest,
  assetLibraryAddFileSuccess,
  assetLibraryAddFolderFailure,
  assetLibraryAddFolderRequest,
  assetLibraryAddFolderSuccess,
  assetLibraryDeleteFailure,
  assetLibraryDeleteRequest,
  assetLibraryDeleteSuccess,
  assetLibraryDownloadFailure,
  assetLibraryDownloadRequest,
  assetLibraryDownloadSuccess,
  assetLibraryFolderNavigateFailure,
  assetLibraryFolderNavigateRequest,
  assetLibraryFolderNavigateSetCurrentDirectory,
  assetLibraryFolderNavigateSuccess,
  assetLibraryGetContentFailure,
  assetLibraryGetContentRequest,
  assetLibraryGetContentSuccess,
  assetLibraryMoveFailure,
  assetLibraryMoveRequest,
  assetLibraryMoveSuccess,
  assetLibraryPasteFailure,
  assetLibraryPasteRequest,
  assetLibraryPasteSuccess,
  assetLibraryRenameFailure,
  assetLibraryRenameRequest,
  assetLibraryRenameSuccess,
} from "../actions/assetLibrary";
import { assetLibraryAddFileApi, assetLibraryAddFolderApi, assetLibraryDeleteApi, assetLibraryDownloadApi, assetLibraryGetContentApi, assetLibraryMoveApi, assetLibraryPasteApi, assetLibraryRenameApi } from "src/services/assetLibrary";
import performApiCallSaga from "./utils";
import { StoreState } from "../configureStore";
import jwtManager from "src/utils/jwtManager";
import { messages } from "src/utils/hooks";
import { notification } from "antd";
import { LanguageUnion } from "src/utils/useTranslate";

function* assetLibraryGetContentSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    assetLibraryGetContentApi(action.payload.folder_id),
    assetLibraryGetContentSuccess,
    assetLibraryGetContentFailure,
    action.payload
  );
}

function* assetLibraryAddFolderSaga(action: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(assetLibraryAddFolderApi, action.payload, headers);
    if (status === "Success") {
      yield put(assetLibraryAddFolderSuccess({ data, type: action.payload.type }));
      yield put(assetLibraryGetContentRequest({ folder_id: action.payload.parent_id }));
    } else {
      yield put(assetLibraryAddFolderFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(assetLibraryAddFolderFailure());
    notification.error({
      message: messages[ln].internal_server_error,
      duration: 5,
    });
  }
}

function* assetLibraryAddFileSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    assetLibraryAddFileApi(action.payload.parent_id),
    assetLibraryAddFileSuccess,
    assetLibraryAddFileFailure,
    action.payload.file
  );
}


function* assetLibraryRenameSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    assetLibraryRenameApi,
    assetLibraryRenameSuccess,
    assetLibraryRenameFailure,
    action.payload
  );
}

function* assetLibraryDeleteSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    assetLibraryDeleteApi,
    assetLibraryDeleteSuccess,
    assetLibraryDeleteFailure,
    action.payload
  );
}

function* assetLibraryDownloadSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    assetLibraryDownloadApi,
    assetLibraryDownloadSuccess,
    assetLibraryDownloadFailure,
    action.payload,
    function* (): any {
      const { assetLibraryDownloadResponse } = yield select((state: StoreState) => state.assetLibrary);
      yield window.open(assetLibraryDownloadResponse.link, '_blank');
    }
  );
}

function* assetLibraryPasteSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    assetLibraryPasteApi,
    assetLibraryPasteSuccess,
    assetLibraryPasteFailure,
    action.payload,
    function* (): any {
      yield put(assetLibraryGetContentRequest({ folder_id: action.payload.to }));
    }
  );
}

function* assetLibraryMoveSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    assetLibraryMoveApi,
    assetLibraryMoveSuccess,
    assetLibraryMoveFailure,
    action.payload,
    function* (): any {
      const { assetLibraryCurrentDirectory } = yield select((state: StoreState) => state.assetLibrary);
      yield put(assetLibraryGetContentRequest({ folder_id: assetLibraryCurrentDirectory }));
    }
  );
}

function* assetLibraryFolderNavigateSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    assetLibraryGetContentApi(action.payload.folder_id),
    assetLibraryFolderNavigateSuccess,
    assetLibraryFolderNavigateFailure,
    action.payload,
    function* (): any {

      yield put(assetLibraryFolderNavigateSetCurrentDirectory(action.payload.folder_id));
    }
  );
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(assetLibraryGetContentRequest, assetLibraryGetContentSaga);
  yield takeLatest(assetLibraryAddFolderRequest, assetLibraryAddFolderSaga);
  yield takeEvery(assetLibraryAddFileRequest, assetLibraryAddFileSaga);
  yield takeLatest(assetLibraryRenameRequest, assetLibraryRenameSaga);
  yield takeEvery(assetLibraryDeleteRequest, assetLibraryDeleteSaga)
  yield takeEvery(assetLibraryDownloadRequest, assetLibraryDownloadSaga)
  yield takeLatest(assetLibraryPasteRequest, assetLibraryPasteSaga)
  yield takeLatest(assetLibraryMoveRequest, assetLibraryMoveSaga)
  yield takeLatest(assetLibraryFolderNavigateRequest, assetLibraryFolderNavigateSaga)
}