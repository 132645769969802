
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`2 Level Full Factorial`}</h1>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Factorial_experiment"
      }}>{`Factorial Experiments`}</a>{`, where all combination of the levels of the factors are run, are usually referred to as full factorial experiments. Full factorial two level experiments are also referred to as 2𝑘 designs where 𝑘 denotes the number of factors being investigated in the experiment.`}</p>
    <p>{`The 2𝑘 designs are a special category of the factorial experiments where all the factors are at two levels. The fact that these designs contain factors at only two levels and are orthogonal greatly simplifies their analysis even when the number of factors is large.`}</p>
    <p><strong parentName="p">{`Use Cases:-`}</strong></p>
    <ul>
      <li parentName="ul">{`2𝑘 designs are particularly useful in the early stages
of experimental work when they are many factors to
be investigated.`}</li>
      <li parentName="ul">{`It provides the smallest number of runs with which k
factors can be studied.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;