import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { Collapse, Tabs } from "antd"
import useTranslate from "src/utils/useTranslate"

const InputConstraintsPanel = ({ items, title, defaultOpen }: any) => {
  const [t] = useTranslate()
  return (
    items?.length ? <Collapse
      style={{ width: "100%" }}
      defaultActiveKey={defaultOpen ? ["input_constraints"] : []}
      expandIconPosition="right"
      expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
      ghost
      destroyInactivePanel
    >
      <Collapse.Panel
        key={"input_constraints"}
        header={title || `${t("aiEngine.inputConstraints")}`}
        style={{ background: '#fafafa' }}
      >
        <Tabs
          items={items}
          animated
          type="card"
          size="small"
          className="category-tabs"
          style={{ width: "100%", padding: 0 }}
        />
      </Collapse.Panel>
    </Collapse> : null
  )
}

export default InputConstraintsPanel
