import { DownOutlined } from '@ant-design/icons'
import { Checkbox, Divider, Input, Menu, Popover, Space, Tooltip, Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'

const { Text } = Typography

export type ProjectDropDownMenuPropsTypes = {
    selectedProjects: string[],
    setSelectedProjects: Dispatch<SetStateAction<string[]>>
}

export const ProjectDropDownMenu = ({ selectedProjects, setSelectedProjects }: ProjectDropDownMenuPropsTypes) => {
    const [t] = useTranslate()
    const [projectInput, setProjectInput] = useState("")

    const { current: project_id, projectList } = useSelector((state: StoreState) => state.projects)

    const filteredProjects = useMemo(
        () =>
            projectList.filter((project) =>
                !projectInput?.trim()
                    ? true
                    : project?.name
                        ?.toLowerCase()
                        ?.includes(projectInput?.toLocaleLowerCase())
            ),
        [projectList, projectInput]
    )

    useEffect(() => {
        setSelectedProjects([project_id])
    }, [project_id, setSelectedProjects])

    const projectDropDownMenu = useMemo(() => (
        <Space style={{ width: "100%", maxWidth: "18rem" }} size="small" direction="vertical">
            <Input.Search
                placeholder={t("projects.searchProjects")}
                onChange={(e) => setProjectInput(e.target.value)}
            />
            <Menu style={{ padding: 5, maxHeight: "18rem", overflowY: "auto" }}>
                <Menu.Item key={"select_all"}>
                    <Checkbox
                        checked={selectedProjects?.length === projectList.length || !selectedProjects.length}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            e.target.checked
                                ? setSelectedProjects(
                                    projectList.map(({ project_id }) => project_id)
                                )
                                : setSelectedProjects([project_id])
                        }}
                    >
                        {t("common.viewAllProjects")}
                    </Checkbox>
                </Menu.Item>
                <Divider style={{ marginTop: 2, marginBottom: 2 }} />
                {filteredProjects.map(({ project_id, name }) => (
                    <Menu.Item key={project_id}>
                        <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            checked={selectedProjects?.includes(project_id)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedProjects((prevState) => [
                                        ...prevState,
                                        project_id,
                                    ])
                                } else {
                                    setSelectedProjects((prevState) =>
                                        prevState.filter((res) => res !== project_id)
                                    )
                                }
                            }}
                        >
                            <Tooltip title={name} placement={"topLeft"}>
                                <span className='project__title'>
                                    {name.length < 25 ? name : name.slice(0, 24) + "..."}
                                </span>
                            </Tooltip>
                        </Checkbox>
                    </Menu.Item>
                ))}
            </Menu>
        </Space>
    ), [filteredProjects, projectList, project_id, selectedProjects, t, setProjectInput, setSelectedProjects])
    return (
        <Popover
            content={projectDropDownMenu}
            placement="bottomLeft"
            trigger="click"
        >
            <StyledButton style={{ width: "100%" }} type="default">
                <Space>
                    <Text
                        ellipsis={{
                            tooltip:
                                selectedProjects?.length > 1 || !selectedProjects.length
                                    ? "Multiple Projects"
                                    : projectList.find(
                                        (res) =>
                                            res?.project_id === selectedProjects?.[0]
                                    )?.name,
                        }}
                        style={{ maxWidth: 100 }}
                        strong
                        type="secondary"
                    >
                        {selectedProjects?.length > 1 || !selectedProjects.length
                            ? "Multiple Projects"
                            : projectList.find(
                                (res) =>
                                    res?.project_id === selectedProjects?.[0]
                            )?.name}
                    </Text>
                    <DownOutlined />
                </Space>
            </StyledButton>
        </Popover>
    )
}
