import React, { Dispatch, SetStateAction } from "react"
import { message } from "antd";
import { Box, PayloadElement, PCM } from "../types"
import { BoxDimensionsForm, PayloadDimensionForm } from "."
import { StyledButton } from "src/styled_components/StyledButton";

type P = {
	data: PCM[]
	box: Box
	setBox: Dispatch<SetStateAction<Box>>
	sendMessage: (data: PCM[], box: Box) => void
	payloadElement: PayloadElement | null
	setPayloadElement: Dispatch<SetStateAction<PayloadElement | null>>
	frameRef: React.RefObject<HTMLIFrameElement>
}

export const VisualisationControls = ({
	box,
	data,
	sendMessage,
	setBox,
	frameRef,
	payloadElement,
	setPayloadElement,
}: P) => {
	function refreshView() {
		if (box.width === 0 && box.length === 0 && box.depth === 0)
			return message.error("Please provide the box dimensions!")
		sendMessage(data, box)
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "10px",
			}}
		>
			<BoxDimensionsForm box={box} setBox={setBox} />
			<PayloadDimensionForm
				element={payloadElement}
				setElement={setPayloadElement}
				box={box}
			/>
			<StyledButton
				style={{ maxWidth: "max-content" }}
				htmlType="button"
				onClick={refreshView}
			>
				Refresh View
			</StyledButton>
		</div>
	)
}
