import { FileOutlined, InboxOutlined } from '@ant-design/icons'
import { message, Modal, Row, Space } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { clearInventoryData, inventoryAttachmentsAddRequest, inventoryAttachmentsListClear } from 'src/store/actions/inventory'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { AttachmentsList } from '../AttachmentsList'



export const AttachmentsModal = ({ visible, setVisible }: any) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()

    const { attachmentsList, attachementsAddStatus, inventoryData } = useSelector((state: StoreState) => state.inventory)
    const [fileList, setFileList] = useState<any>([])

    useEffect(() => {
        if (attachementsAddStatus === AsyncStates.SUCCESS) {
            setFileList([])
        }
    }, [attachementsAddStatus])

    const uploadProps = {
        onRemove: (file: any) => {
            setFileList((prevState: any) => {
                const index = prevState.indexOf(file)
                const newFileList = prevState.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            setFileList((prevState: any) => {
                if (fileList.map((fileRecord: any) => fileRecord.name).includes(file.name)) {
                    message.error("File already uploaded")
                    return prevState
                } else {
                    return [...prevState, file]
                }
            })
        },
        fileList,
        multiple: true
    }

    const uploadFiles = () => {
        const params = new FormData()
        params.append("type", inventoryData?.type)
        params.append("identifier", inventoryData?.identifier)
        fileList.forEach((file: any) => {
            params.append('files', file)
        })
        dispatch(inventoryAttachmentsAddRequest(params))
    }


    return (
        <Modal
            open={visible}
            width={1000}
            title={`${inventoryData?.name} ${t("common.attachments")}`}
            maskClosable={false}
            footer={null}
            onCancel={() => {
                dispatch(clearInventoryData())
                dispatch(inventoryAttachmentsListClear())
                setFileList([])
                setVisible(false)
            }}>
            <Space direction="vertical" size="large" style={{ width: "100%", marginTop: 20 }}>
                <Dragger {...uploadProps} >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t("fileupload.upload")}</p>
                </Dragger>
                <Row justify={"end"} style={{ marginBottom: 10 }}>
                    <StyledButton type="primary" disabled={!fileList?.length} icon={<FileOutlined />}
                        onClick={uploadFiles}
                        loading={attachementsAddStatus === AsyncStates.LOADING}
                    >
                        {t("common.uploadAFile")}
                    </StyledButton>
                </Row>
                {attachmentsList?.length ?
                    <AttachmentsList /> : null
                }
            </Space>
        </Modal>
    )
}
