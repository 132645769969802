import { RootEngine } from 'flume'
import config from './config'
import { similarDetection } from './nodeLogic'

const resolvePorts = (portType, data) => {
  switch (portType) {
    case 'combination':
      return data.combination
    case 'combinations':
      return data.combinations
    case 'boolean':
      return data.boolean
    default:
      return data
  }
}

const resolveNodes = (node, inputValues, nodeType, context) => {
  switch (node.type) {
    case 'combinations':
      return { combinations: context.combinations }
    case 'currentCombination':
      return { combination: context.currentCombination }
    case 'combination_detector':
      return { found: similarDetection(inputValues) }
    default:
      return inputValues
  }
}

const engine = new RootEngine(config, resolvePorts, resolveNodes)

export default engine