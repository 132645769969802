import { Flex, Switch, Tag } from "antd";
import { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteSemAnalysisMaskHeightWidthRequest } from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";

type TProps = {
    imgToShow: "After" | "Before";
    setImgToShow: React.Dispatch<React.SetStateAction<"After" | "Before">>;
    showBBox: boolean;
    setShowBBox: React.Dispatch<React.SetStateAction<boolean>>;
    showFixedArea: boolean;
    setShowFixedArea: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPoints: any[];
    setSelectedPoints: React.Dispatch<React.SetStateAction<any[]>>;
    setShowCreateGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
    isGroupsVisible: boolean
    setIsGroupsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setShowCalibrateModal: React.Dispatch<React.SetStateAction<boolean>>;
    tooltip: any;
    bboxInstances: any[]
};

const FlowabilityAnalysisDetailsSidebar = ({
    imgToShow,
    setImgToShow,
    showBBox,
    setShowBBox,
    showFixedArea,
    setShowFixedArea,
    selectedPoints,
    setSelectedPoints,
    setShowCreateGroupModal,
    isGroupsVisible,
    setIsGroupsVisible,
    setShowCalibrateModal,
    tooltip,
    bboxInstances
}: TProps) => {
    const dispatch = useDispatch();
    const params = useParams<any>();
    const { instanceDetection, analysisTypes } = useSelector((state: StoreState) => state.semAnalysis);

    const avgArea = useMemo(() => {
        const bboxAreas = bboxInstances.map((item: any) => item?.area)

        return (
            Number(bboxAreas?.reduce((num: any, total: any) => (total += num), 0)) /
            bboxAreas?.length
        );
    }, [bboxInstances]);

    return (
        <div className="sidebar">
            <div className="content">
                <div className="option">
                    <div className="label">Show Fixed Area</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={showFixedArea}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            setShowFixedArea(val);
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                <div className="option">
                    <div className="label">Show Original Image</div>
                    <Switch
                        checkedChildren={"Show"}
                        value={imgToShow === "Before"}
                        unCheckedChildren={"Hide"}
                        onChange={(val: boolean) => {
                            val ? setImgToShow("Before") : setImgToShow("After")
                        }}
                        style={{
                            outline: "none",
                        }}
                    />
                </div>

                {showFixedArea && (
                    <>
                        <div className="option">
                            <div className="label">Average Area</div>
                            <Tag color="#424242">
                                {isNaN(avgArea) ? 0 : Number(avgArea).toFixed(2)} {instanceDetection?.unit && <>{instanceDetection.unit} <sup>2</sup></>}
                            </Tag>
                        </div>

                        <div className="option">
                            <div className="label">Quality</div>
                            <Tag color="#424242">
                                {instanceDetection?.quality}
                            </Tag>
                        </div>
                    </>
                )}

                {!showFixedArea && (
                    <>
                        <div className="option">
                            <StyledButton
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        deleteSemAnalysisMaskHeightWidthRequest({
                                            file_id: params.fileId,
                                            analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS as keyof typeof analysisTypes]
                                        })
                                    );
                                }}
                            >
                                Delete All Mask Groups
                            </StyledButton>
                        </div>

                        {selectedPoints && selectedPoints.length > 0 && <div className="option">
                            <Flex gap={5} className="points-buttons">
                                <StyledButton
                                    size="small"
                                    onClick={() => setSelectedPoints([])}
                                >
                                    Clear Points
                                </StyledButton>
                                <StyledButton
                                    type="primary"
                                    size="small"
                                    onClick={() => setShowCreateGroupModal(true)}
                                >
                                    Create Group
                                </StyledButton>
                            </Flex>
                        </div>}

                        <div className="option">
                            <div className="label">Group Masks</div>
                            <Switch
                                checkedChildren={"Show"}
                                unCheckedChildren={"Hide"}
                                checked={isGroupsVisible}
                                onChange={(val: any) => {
                                    setIsGroupsVisible(val);
                                }}
                                style={{
                                    outline: "none",
                                }}
                            />
                        </div>

                        <div className="option">
                            <div className="label">Bounding Box</div>
                            <Switch
                                checkedChildren={"Show"}
                                value={showBBox}
                                unCheckedChildren={"Hide"}
                                onChange={(val: boolean) => {
                                    setShowBBox(val);
                                }}
                                style={{
                                    outline: "none",
                                }}
                            />
                        </div>
                    </>
                )}

                {Object.values(tooltip?.content || {}).length ? (
                    <div className="option">
                        <div className="card">
                            <div style={{ lineBreak: "auto" }}>
                                {Object.values(tooltip?.content || {}).map((val: any) => {
                                    return (
                                        <div key={val} className="label">
                                            {val}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>


            <div className="option bottom-fixed">
                <StyledButton
                    size="small"
                    onClick={() => setShowCalibrateModal(true)}
                >
                    Calibrate
                </StyledButton>
            </div>

        </div>
    );
};

export default memo(FlowabilityAnalysisDetailsSidebar);
