import {
  CloseCircleFilled,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Form,
  Row,
  Space,
  Col,
  Select,
  Typography,
  InputNumber,
  Input,
  Cascader,
  message,
  DatePicker,
} from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { toTitleCase } from "src/utils/general";
import { StyledCard } from "src/styled_components/StyledCard";
import { useValue } from "src/utils/useValue";
import { DefaultOptionType } from "antd/es/cascader";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";


const { Option } = Select;
const { Text } = Typography;

const valueType = [
  {
    label: "Numerical",
    value: "numerical"
  },
  {
    label: "Categorical",
    value: "categorical"
  }
]

type FilterFormProps = {
  formState: any;
  setFormState: React.Dispatch<React.SetStateAction<any>>;
  applyFilters: (values: any) => void;
  clearFilters: () => void;
  form: any;
  innerSelector: string[];
  setInnerSelector: React.Dispatch<React.SetStateAction<string[]>>;
  outerSelector: any;
  setOuterSelector: React.Dispatch<React.SetStateAction<any>>;
  filtersLoading: boolean;
};

export const FilterForm = ({
  formState,
  applyFilters,
  clearFilters,
  form,
  setFormState,
  setInnerSelector,
  outerSelector,
  setOuterSelector,
  filtersLoading,
  innerSelector,
}: FilterFormProps) => {
  const [t] = useTranslate();
  const { formatter, parser } = useValue()
  const requiredFieldStar = useRequiredFieldStar()

  const inventoryDisplayNames = useSelector(
    (state: StoreState) => state.displayNames.inventoryData
  );

  const FIELD_OPTIONS: {
    key: string;
    label: string;
  }[] = [
      // {
      //   key: "family",
      //   label: t("inventory.Family"),
      // },
      {
        key: "category",
        label: t("inventory.Category/Subcategory"),
      },
      {
        key: "ingredient",
        label: t("inventory.IngredientName"),
      },
      {
        key: "properties",
        label: t("inventory.IngredientProperties"),
      },
      {
        key: "methods",
        label: t("inventory.Methods"),
      },
      {
        key: "parameter",
        label: t("common.propertyParameters"),
      },
      {
        key: "custom_fields",
        label: t("inventory.CustomFields"),
      },
      {
        key: "additives",
        label: t("inventory.Additives"),
      },
      {
        key: "supplier",
        label: t("inventory.Suppliers"),
      },
      {
        key: "costing",
        label: t("inventory.Costing"),
      },
      {
        key: "lot_no",
        label: t("common.lotNoOrSAPNo"),
      },
      {
        key: "project_id",
        label: t("inventory.Projects"),
      },
      {
        key: "source",
        label: t("inventory.Source"),
      },
      {
        key: "grade",
        label: t("inventory.Grade"),
      },
    ];

  const projects = useSelector(
    (state: StoreState) => state.projects.projectList
  );

  const methods = useSelector(
    (state: StoreState) => state.inventoryV2.propertyMethods.data
  );

  const families = useSelector(
    (state: StoreState) => state.inventoryV2.ingredientFamilyList
  );

  const ingredientGrades = useSelector(
    (state: StoreState) => state.inventoryV2.ingredientGrades
  );

  const { suppliers } = useSelector(
    (state: StoreState) => state.inventoryV2.getSuppliers
  );

  const parameters = useSelector(
    (state: StoreState) => state.repository.parameters.data
  );

  const property = useSelector(
    (state: StoreState) => state.repository.properties.data
  );

  const customFields = useSelector(
    (state: StoreState) => state.repository.fields.data
  );

  const additives = useSelector(
    (state: StoreState) => state.repository.additives.data
  );

  const unitsList = useSelector(
    (state: StoreState) => state.conversion.unitList
  );

  const categories = useSelector((state: StoreState) => state.repository.categories.data);

  const getFormValue = (outerFieldName: string | number, innerFieldName: string | number, key: string) => {
    return formState?.outer_conditions?.[outerFieldName]?.inner_conditions?.[innerFieldName]?.[key]
  }

  // const ingredients: any = useMemo(() => ingredientsList?.data, [ingredientsList]);
  const ingredients: any = useMemo(() => inventoryDisplayNames?.ingredients, [inventoryDisplayNames]);

  const getCategoryName = useCallback((id: string) => {
    return categories.find(c => c.category_id === id)?.name;
  }, [categories])

  const lotNos = useMemo(() => {
    return [
      ...new Set(ingredients
        ?.map((res: any) => res?.lot_no)
        .filter((res: any) => !!res && (
          typeof res === 'number' ? (res).toString() : !!res?.trim()
        ))
      ),
    ];
  }, [ingredients]);

  const getDropdownOptions = useCallback(
    (type: string) => {
      if (type === "lot_no") {
        return lotNos
          .filter((value) => value)
          .sort((a: any, b: any) => (a).toString().localeCompare((b).toString()))
          .map((value: any, index: number) => ({
            label: value,
            value: value,
            key: index,
          }));
      } else if (type === "project_id") {
        return [
          ...new Set(
            ingredients?.flatMap(
              (project: any) => project?.project || project.project_id
            )
          ),
        ]
          .filter((value: any) => value)
          .map((value, index) => ({
            label:
              projects?.find(({ project_id }: any) => project_id === value)
                ?.name || value,
            value,
            key: index,
          })).sort((a: any, b: any) => a.label.localeCompare(b.label));
      } else if (type === "supplier") {
        return suppliers?.map(
          (supp: any) => supp?.name
        )
          .filter((value) => value)
          .sort((a: any, b: any) => a.localeCompare(b))
          .map((value: any, index: number) => ({
            label: value,
            value: value,
            key: index,
          }));
      } else if (type === "family") {
        return [
          ...new Set(
            families?.map(
              (value: any) => value?.family_id
            )
          ),
        ]
          .filter((value) => value)
          .map((value: any, index: number) => ({
            label: families?.find(({ family_id }: any) => family_id === value)
              ?.family_name,
            value: value,
            key: index,
          })).sort((a: any, b: any) => a.label.localeCompare(b.label));
      }
      else if (type === "ingredient") {
        const ingredientMap = new Map(ingredients.map((i: any) => [String(i.name || "").trim(), { ...i, name: String(i.name || "").trim() }]));
        return [...ingredientMap.values()].filter((i: any) => i.name).sort((a: any, b: any) => a.name.localeCompare(b.name)).map((i: any) => ({
          label: <Typography.Text>{i.name} <Typography.Text style={{ color: "#8C8C8C" }}>&#x2022; {getCategoryName(i.category) || i.category}</Typography.Text></Typography.Text>,
          value: i.name
        }))
      } else if (type === "category") {
        return categories
          .filter(c => c.name)
          .sort((a: any, b: any) => a.name.localeCompare(b.name))
          .map((category) => ({
            key: category.category_id,
            value: category.category_id,
            label: category.name,
            children: [...new Set(category.sub_category)]
              .filter((value) => value)
              .sort((a: any, b: any) => a.localeCompare(b))
              .map((value, index) => ({ label: value, value, key: index })),
          }));
      } else if (type === "properties") {
        const propertyCategories = [
          ...new Set(property.map((p: any) => p.category_name)),
        ].filter(c => c).sort((a: any, b: any) => a.localeCompare(b));

        return propertyCategories.map((category: any) => ({
          label: category ? category : "-",
          value: category,
          children: property
            .filter((p: any) => p.category_name === category)
            .sort((a: any, b: any) => a.name.localeCompare(b.name))
            .map((p: any) => ({
              label: p.name,
              value: p.property_id,
              key: p.property_id,
            })),
        }));
      } else if (type === "methods") {
        const propertyCategories = [
          ...new Set(methods.map((p: any) => p.category_name)),
        ].filter(c => c).sort((a: any, b: any) => a.localeCompare(b));

        return propertyCategories.map((category: any) => ({
          label: category ? category : "-",
          value: category,
          children: [
            ...new Set(methods.filter((m: any) => m.category_name === category).map((m: any) => m.property_name))
          ].sort((a: any, b: any) => a.localeCompare(b))
            .map(property => ({
              label: property,
              value: property,
              children: methods.filter((m: any) => m.category_name === category && m.property_name === property)
                .sort((a: any, b: any) => a.method_name.localeCompare(b.method_name))
                .map((m: any) => ({
                  label: m.method_name,
                  value: m.method_name,
                }))
            }))
        }));
      } else if (type === "parameter") {
        const propertyCategories = [
          ...new Set(parameters.map((p: any) => p.category_name)),
        ].filter(c => c).sort((a: any, b: any) => a.localeCompare(b));

        return propertyCategories.map((category: any) => ({
          label: category ? category : "-",
          value: category,
          children: [
            ...new Set(parameters.filter(p => p.category_name === category).map((p: any) => p.property_name))
          ].sort((a: any, b: any) => a.localeCompare(b))
            .map(property => ({
              label: property,
              value: property,
              children: parameters.filter(p => p.category_name === category && p.property_name === property)
                .sort((a: any, b: any) => a.parameter.localeCompare(b.parameter))
                .map(p => ({
                  label: p.parameter,
                  value: p.parameter_id,
                }))
            }))
        }));
      } else if (type === "custom_fields") {
        return customFields?.map(({ field_name, field_id, field_type, options }: any) => ({ label: ['select', 'categorical'].includes(field_type) ? `${field_name} (${options.toString()})` : field_name, value: field_id })).sort((a, b) => String(a.label || "").localeCompare(String(b.label || "")))
      } else if (type === "additives") {
        return additives?.map(({ name, additive_id }: any) => ({ label: name, value: additive_id })).sort((a, b) => String(a.label || "").localeCompare(String(b.label || "")))
      } else if (type === "source") {
        return [{ label: "File Upload", value: "upload" }, { label: t("common.trial"), value: "trial" }, { label: "Manual", value: "manual" }, { label: "Work Order Upload", value: "work_order_upload" }]
      } else if (type === "grade") {
        return ingredientGrades?.map((grade) => ({ label: grade === "" ? "-" : grade ?? "-", value: grade })).sort((a, b) => String(a.label || "").localeCompare(String(b.label || "")))
      } else {
        return []
      }
    },
    [customFields, ingredients, methods, parameters, projects, property, t, families, suppliers, lotNos, categories, getCategoryName, additives, ingredientGrades]
  );

  const onChangeOperator = (outerField: any, field: any) => {
    const { outer_conditions } = form.getFieldsValue();
    const { inner_conditions } = outer_conditions[outerField.name];
    inner_conditions[field.name].val = null;
    inner_conditions[field.name].max = null;
    form.setFieldsValue({ outer_conditions });
  };

  const getOperators = (outerField: any, field: any) => {
    let type = getFormValue(outerField?.name, field?.name, "parameter");
    const custom_field_type = customFields?.find(({ field_id }: any) => field_id === getFormValue(outerField?.name, field?.name, "parameter_type"))?.field_type

    if (type === "custom_fields" && ["select", "categorical"].includes(custom_field_type)) type = "custom_field_in"
    if (
      [
        "family",
        "category",
        "ingredient",
        "supplier",
        "project_id",
        "lot_no",
        "methods",
        "custom_field_in",
        "grade"
      ].includes(type)
    ) {
      return (
        <Select
          onChange={() => onChangeOperator(outerField, field)}
          disabled
          style={{ width: "100%" }}
          placeholder={t("formulations.placeholder.operator")}
          options={[{ label: "=", value: "in" }]}
        />
      );
    } else
      return (
        <Select
          disabled={
            ["text", "description", "source", "range", "alpha_numeric", "date"].includes(custom_field_type || type)
            || getFormValue(outerField?.name, field?.name, "value_type") === "categorical"
          }
          onChange={() => onChangeOperator(outerField, field)}
          style={{ width: "100%" }}
          placeholder={t("formulations.placeholder.operator")}
        >
          <Select.Option value={"eq" || "contains"}>{"="}</Select.Option>
          <Select.Option value="lt">{"<"}</Select.Option>
          <Select.Option value="gt">{">"}</Select.Option>
          <Select.Option value="lte">{"<="}</Select.Option>
          <Select.Option value="gte">{">="}</Select.Option>
          <Select.Option value="tde">{"~"}</Select.Option>
          {type !== "costing" && (
            <Select.Option value="range">{t("common.range")}</Select.Option>
          )}
        </Select>
      );
  };

  const getOptions = (parameterType: "properties" | "parameter", selectedInputs: string[] = []) => {
    return unitsList.map((unit) => ({ label: unit.name, value: unit.name }))
    // if (parameterType === "properties") {
    //   return property.find((p: any) => p.property_id === selectedInputs[(selectedInputs.length - 1)])?.unit?.map((unit: any) => ({
    //     label: unit,
    //     key: unit,
    //     value: unit
    //   })) || [];
    // } else if (parameterType === "parameter") {
    //   return parameters.find((p: any) => p.parameter_id === selectedInputs[(selectedInputs.length - 1)])?.unit?.map((unit: any) => ({
    //     label: unit,
    //     key: unit,
    //     value: unit
    //   })) || [];
    // }
  }

  const getComponentFromOperators = (outerField: any, field: any) => {
    const type =
      getFormValue(outerField?.name, field.name, "operator");
    const parameter =
      getFormValue(outerField?.name, field.name, "parameter");
    let custom_field_type = customFields?.find(({ field_id }: any) => field_id === getFormValue(outerField?.name, field.name, "parameter_type"))?.field_type;
    custom_field_type = custom_field_type === "categorical" ? "select" : custom_field_type;

    if (custom_field_type === "date") {
      return <Col>
        <Col>
          <Form.Item
            rules={[
              {
                required: true,
                message: t("common.required"),
                transform: (val: any) => val?.trim(),
              },
            ]}
            {...field}
            name={[field.name, "val"]}
            fieldKey={[field.key, "val"]}
            required
            tooltip={requiredFieldStar}
          >
            <DatePicker format={"YYYY/MM/DD"} />
          </Form.Item>
        </Col>
        {type === "range" ? <><Text>{"~"}</Text>
          <Col>
            <Form.Item
              {...field}
              rules={[
                {
                  required: true,
                  message: t("common.required"),
                  transform: (val: any) => val?.trim(),
                },
              ]}
              name={[field.name, "max"]}
              fieldKey={[field.key, "max"]}
              required
              tooltip={requiredFieldStar}
            >
              <DatePicker />
            </Form.Item>
          </Col></> : null}
      </Col>
    } else if ((type === "range") || (type === "range" && ["number", "numerical"].includes(custom_field_type))) {
      return (
        <Col>
          <Col>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("common.required"),
                  transform: (val: any) => val?.trim(),
                },
              ]}
              {...field}
              name={[field.name, "val"]}
              fieldKey={[field.key, "val"]}
              required
              tooltip={requiredFieldStar}
            >
              <InputNumber
                parser={parser}
                formatter={formatter}
                style={{ width: ["number", "numerical"].includes(custom_field_type) ? "100px" : "46px" }}
                placeholder={t("common.minimum")}
                onBlur={(e) => {
                  const value = Number(e.target.value);
                  if (
                    value &&
                    formState?.outer_conditions?.[outerField?.name]
                      ?.inner_conditions?.[field.name].max &&
                    value >=
                    formState?.outer_conditions?.[outerField?.name]
                      ?.inner_conditions?.[field.name].max
                  ) {
                    message.warning(t("aiEngine.minShouldBeLessThanMax"));
                    const { outer_conditions } = form.getFieldsValue();
                    outer_conditions[outerField.name].inner_conditions[
                      field.name
                    ].val = null;
                    form.setFieldsValue({ outer_conditions });
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Text>{"~"}</Text>
          <Col>
            <Form.Item
              {...field}
              rules={[
                {
                  required: true,
                  message: t("common.required"),
                  transform: (val: any) => val?.trim(),
                },
              ]}
              name={[field.name, "max"]}
              fieldKey={[field.key, "max"]}
              required
              tooltip={requiredFieldStar}
            >
              <InputNumber
                parser={parser}
                formatter={formatter}
                style={{ width: ["number", "numerical"].includes(custom_field_type) ? "100px" : "46px" }}
                placeholder={t("formulations.placeholder.maximum")}
                onBlur={(e) => {
                  const value = Number(e.target.value);
                  if (
                    value &&
                    value <=
                    formState?.outer_conditions?.[outerField?.name]
                      ?.inner_conditions?.[field.name].val
                  ) {
                    message.warning(t("aiEngine.maxShouldBeGreaterThanMin"));
                    const { outer_conditions } = form.getFieldsValue();
                    outer_conditions[outerField.name].inner_conditions[
                      field.name
                    ].max = null;
                    form.setFieldsValue({ outer_conditions });
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Col>
      );
    } else if (["gt", "lt", "gte", "lte"].includes(type)) {
      return (
        <Col>
          <Form.Item
            rules={[
              {
                required: true,
                message: t("common.required"),
              },
            ]}
            {...field}
            name={[field.name, "val"]}
            fieldKey={[field.key, "val"]}
            required
            tooltip={requiredFieldStar}
          >
            <InputNumber
              parser={parser}
              formatter={formatter}
              style={{ width: "100%" }}
              placeholder={t("formulations.placeholder.value")}
            />
          </Form.Item>
        </Col>
      );
    } else if (custom_field_type === "select") {
      return (<Col><Form.Item
        rules={[
          {
            required: true,
            message: t("common.required"),
          },
        ]}
        {...field}
        name={[field.name, "val"]}
        fieldKey={[field.key, "val"]}
        required
        tooltip={requiredFieldStar}
      >
        <Cascader
          placeholder={t("common.select")}
          maxTagCount={1}
          maxTagTextLength={15}
          multiple
          style={{ width: "100px" }}
          options={[...customFields?.find(({ field_id }: any) => field_id === formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[
            field.name
          ]?.parameter_type)?.options?.map((val: any) => ({ label: val, value: val }))]}
        />

      </Form.Item></Col>)
    } else {
      return (["costing", "properties", "parameter", "custom_fields", "additives"].includes(parameter) || ["text", "description", "number", "numerical", "alpha_numeric"].includes(custom_field_type)) && (
        <Col>
          <Col>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("common.required"),
                  transform: (val: any) => val?.trim(),
                },
              ]}
              {...field}
              name={[field.name, "val"]}
              fieldKey={[field.key, "val"]}
              required
              tooltip={requiredFieldStar}
            >
              {["properties", "parameter"].includes(parameter) || ["number", "numerical"].includes(custom_field_type) ?
                <InputNumber
                  parser={parser}
                  formatter={formatter}
                  style={{ width: "100px" }}
                  placeholder={t("formulations.placeholder.value")}
                />
                :
                <Input
                  style={{ width: "100px" }}
                  placeholder={t("formulations.placeholder.value")}
                />
              }
            </Form.Item>
          </Col>
        </Col>
      );
    }
  };

  const cascaderFilter = (inputValue: string, path: DefaultOptionType[]) => {
    return path.some(
      (option) => String((typeof option.label === "object" ? option.value : option.label) || "").toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  }

  const showPlaceholder: any = {
    properties: t("aiEngine.customInsights.selectProperties"),
    methods: t("common.methodName"),
    parameter: t("common.parameter"),
    custom_fields: t("inventory.CustomFields"),
    additives: t("inventory.Additives")
  }


  const operatorFormItem = (field: any, outerField: any) => {
    return (
      <Form.Item
        {...field}
        rules={[
          {
            required: true,
            message: t("common.required"),
            transform: (val: any) => val?.trim(),
          },
        ]}
        shouldUpdate
        name={[field.name, "operator"]}
        fieldKey={[field.key, "operator"]}
        required
        tooltip={requiredFieldStar}
      >
        {getOperators(outerField, field)}
      </Form.Item>
    )
  }

  return (
    <Form
      name="form_filters"
      onFinish={applyFilters}
      form={form}
      size="small"
      onFieldsChange={(e: any, t: any) => {
        return setFormState(form.getFieldsValue());
      }}
      requiredMark={false}
    >
      <Form.List name="outer_conditions">
        {(outerFields, { add: outerAdd, remove: outerRemove }) => {
          if (!outerFields.length) {
            setInnerSelector((state) => [...state, ""]);
            outerAdd();
          }
          return (
            <Space
              size="middle"
              direction="vertical"
              style={{ width: "100%" }}
              className="filter_form_gap"
            >
              {outerFields.map((outerField) => (
                <Row
                  align="middle"
                  justify={"start"}
                  gutter={16}
                  style={{ gap: "8px" }}
                >
                  <Col>
                    {outerField.name === 0 && outerFields.length > 1 && (
                      <Col style={{ width: 100, paddingLeft: "15px" }}>
                        {" "}
                        <Text strong>{t("common.where")}</Text>{" "}
                      </Col>
                    )}
                    {outerField.name > 0 && (
                      <Select
                        value={outerSelector || null}
                        placeholder={`${toTitleCase(
                          t("common.and")
                        )}/${toTitleCase(t("common.or"))}`}
                        style={{ width: 100, paddingLeft: "11px" }}
                        onChange={setOuterSelector}
                      >
                        <Option value="and" key="and">
                          {toTitleCase(t("common.and"))}
                        </Option>
                        <Option value="or" key="or">
                          {toTitleCase(t("common.or"))}
                        </Option>
                      </Select>
                    )}
                  </Col>
                  <StyledCard
                    key={outerField.name}
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <Form.List name={[outerField.name, "inner_conditions"]}>
                      {(fields, { add, remove }) => {
                        if (!fields.length) {
                          setTimeout(() => add(), 50);
                        }
                        return (
                          <Space
                            size="small"
                            direction="vertical"
                            style={{ width: "100%" }}
                            className="gap-0"
                          >
                            {outerFields.length > 1 && (
                              <StyledButton
                                size="middle"
                                icon={<CloseCircleFilled />}
                                style={{
                                  color: "red",
                                  float: "right",
                                }}
                                type="link"
                                onClick={() => {
                                  setInnerSelector((state) =>
                                    state.filter(
                                      (o, idx) => idx !== outerField.name
                                    )
                                  );
                                  outerRemove(outerField.name);
                                }}
                              ></StyledButton>
                            )}
                            {fields.map((field) => (
                              <Row
                                key={field.key}
                                // align="baseline"
                                // direction="horizontal"
                                style={{ width: "100%", gap: "8px", alignItems: "baseline" }}
                              // styles={{ item: { flex: "1 1 0" } }}
                              // className="gap-0"
                              >
                                <Col>
                                  {/* {fields.length > 1 && ( */}
                                  <StyledButton
                                    icon={<CloseCircleOutlined />}
                                    style={{ color: "#F5222D" }}
                                    type="link"
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  ></StyledButton>
                                  {/* )} */}
                                </Col>
                                <Col >
                                  <Form.Item
                                    {...field}
                                    name={[outerField.name, "selector"]}
                                    fieldKey={[field.key, "selector"]}
                                  >
                                    {field.name === 0 && fields.length > 1 && (
                                      <Space style={{ width: 100 }}>
                                        {" "}
                                        <Text strong>
                                          {t("common.where")}
                                        </Text>{" "}
                                      </Space>
                                    )}
                                    {field.name > 0 && (
                                      <Select
                                        value={
                                          innerSelector?.[outerField.key] ||
                                          null
                                        }
                                        placeholder={`${toTitleCase(
                                          t("common.and")
                                        )}/${toTitleCase(t("common.or"))}`}
                                        style={{ width: 100 }}
                                        onChange={(e) =>
                                          setInnerSelector(
                                            (state: string[]) => {
                                              const newState = [...state]
                                              newState[outerField.key] = e;
                                              return [...newState];
                                            }
                                          )
                                        }
                                      >
                                        <Option value="and" key="and">
                                          {toTitleCase(t("common.and"))}
                                        </Option>
                                        <Option value="or" key="or">
                                          {toTitleCase(t("common.or"))}
                                        </Option>
                                      </Select>
                                    )}
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item
                                    {...field}
                                    rules={[
                                      {
                                        required: true,
                                        message: t("common.required"),
                                        transform: (val: any) => val?.trim(),
                                      },
                                    ]}
                                    name={[field.name, "parameter"]}
                                    fieldKey={[field.key, "parameter"]}
                                    required
                                    tooltip={requiredFieldStar}
                                  >
                                    <Select
                                      placeholder={t("inventory.filterBy")}
                                      showSearch
                                      optionFilterProp="label"
                                      style={{
                                        width: "200px",
                                        background: "none",
                                      }}
                                      value={
                                        innerSelector?.[outerField.key] || null
                                      }
                                      options={FIELD_OPTIONS.map(
                                        (res) => ({
                                          ...res,
                                          value: res?.key,
                                        })
                                      )}
                                      onChange={(e: any) => {
                                        const { outer_conditions } = form.getFieldsValue();
                                        const { inner_conditions } = outer_conditions[outerField.name];

                                        let operatorValue = null;

                                        let parameterTypeVal: any = undefined;
                                        if ([
                                          "category",
                                          "ingredient",
                                          "supplier",
                                          "family",
                                          "methods",
                                          "project_id",
                                          "lot_no",
                                          "grade"
                                        ].includes(e)
                                        ) {
                                          operatorValue = "in";
                                          parameterTypeVal = [];
                                        } else if (["source"].includes(e)) {
                                          operatorValue = "eq"
                                          parameterTypeVal = undefined;
                                        }
                                        inner_conditions[field.name] = {
                                          ...inner_conditions[field.name],
                                          parameter: e,
                                          operator: operatorValue,
                                          parameter_type: parameterTypeVal,
                                          value_type: undefined,
                                          val: [
                                            "family",
                                            "category",
                                            "ingredient",
                                            "methods",
                                            "supplier",
                                            "project_id",
                                            "lot_no",
                                            "grade"
                                          ].includes(e)
                                            ? []
                                            : null,
                                          max: null,
                                        };
                                        form.setFieldsValue({
                                          outer_conditions
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>

                                {["family", "category", "ingredient", "methods", "supplier", "costing", "lot_no", "project_id", "source", "grade"].includes(getFormValue(outerField?.name, field.name, "parameter")) && (
                                  <Col>
                                    {operatorFormItem(field, outerField)}
                                  </Col>
                                )}

                                {["properties", "custom_fields", "parameter", "additives"].includes(getFormValue(outerField?.name, field.name, "parameter")) && (
                                  <>
                                    <Col >
                                      <Form.Item
                                        {...field}
                                        rules={[
                                          {
                                            required: true,
                                            message: t("common.required"),
                                            transform: (val: any) => val?.trim(),
                                          },
                                        ]}
                                        name={[field.name, "parameter_type"]}
                                        fieldKey={[field.key, "parameter_type"]}
                                        required
                                        tooltip={requiredFieldStar}
                                      >
                                        {["custom_fields"].includes(
                                          getFormValue(outerField?.name, field.name, "parameter")
                                        ) &&
                                          <Select
                                            options={getDropdownOptions(
                                              formState?.outer_conditions?.[
                                                outerField?.name
                                              ]?.inner_conditions?.[field.name]
                                                ?.parameter
                                            )}
                                            maxTagCount={1}
                                            placeholder={showPlaceholder?.[getFormValue(outerField?.name, field.name, "parameter")] ?? t("inventory.selectParameter")}
                                            showSearch
                                            optionFilterProp="label"
                                            style={{
                                              background: "none",
                                              width: "200px"
                                            }}
                                            onChange={(e: any) => {
                                              const { outer_conditions } = form.getFieldsValue();
                                              const { inner_conditions } = outer_conditions[outerField.name];
                                              let operatorValue = null;
                                              const fieldInfo = customFields.find(({ field_id }: any) => field_id === e);
                                              if (["text", "select", "description", "alpha_numeric", "categorical"].includes(fieldInfo?.field_type)) operatorValue = ["select", "categorical"].includes(fieldInfo?.field_type) ? "in" : "contains";
                                              if (["range"].includes(fieldInfo?.field_type)) operatorValue = "range";
                                              if (["date"].includes(fieldInfo?.field_type)) operatorValue = "eq";
                                              inner_conditions[field.name].val = null;
                                              inner_conditions[field.name].max = null;
                                              inner_conditions[field.name] = {
                                                ...inner_conditions[field.name],
                                                operator: operatorValue,
                                              };
                                              form.setFieldsValue({
                                                outer_conditions,
                                              });
                                            }
                                            }
                                          />}

                                        {["additives"].includes(
                                          getFormValue(outerField?.name, field.name, "parameter")
                                        ) &&
                                          <Select
                                            options={getDropdownOptions(
                                              formState?.outer_conditions?.[
                                                outerField?.name
                                              ]?.inner_conditions?.[field.name]
                                                ?.parameter
                                            )}
                                            maxTagCount={1}
                                            placeholder={showPlaceholder?.[getFormValue(outerField?.name, field.name, "parameter")] ?? t("inventory.selectParameter")}
                                            showSearch
                                            optionFilterProp="label"
                                            style={{
                                              background: "none",
                                              width: "200px"
                                            }}
                                            onChange={(e: any) => {
                                              const { outer_conditions } = form.getFieldsValue();
                                              const { inner_conditions } = outer_conditions[outerField.name];
                                              inner_conditions[field.name].val = null;
                                              inner_conditions[field.name].max = null;
                                              inner_conditions[field.name] = {
                                                ...inner_conditions[field.name],
                                                operator: "contains",
                                              };
                                              form.setFieldsValue({
                                                outer_conditions,
                                              });
                                            }
                                            }
                                          />}

                                        {["properties", "parameter"].includes(
                                          getFormValue(outerField?.name, field.name, "parameter")
                                        ) &&
                                          <Cascader
                                            options={getDropdownOptions(
                                              getFormValue(outerField?.name, field.name, "parameter")
                                            )}
                                            placeholder={showPlaceholder?.[formState?.outer_conditions?.[outerField?.name]?.inner_conditions?.[field.name]?.parameter] ?? t("inventory.selectParameter")}
                                            showSearch={{ filter: cascaderFilter }}
                                            style={{
                                              background: "none",
                                              width: "200px"
                                            }}
                                            onChange={(val: any) => {
                                              const { outer_conditions } = form.getFieldsValue();
                                              const { inner_conditions } = outer_conditions[outerField.name];
                                              inner_conditions[field.name] = {
                                                ...inner_conditions[field.name],
                                                value_type: undefined,
                                                operator: undefined,
                                                val: undefined,
                                                unit: undefined
                                              }
                                              form.setFieldsValue({
                                                outer_conditions
                                              });
                                            }}
                                          />
                                        }

                                      </Form.Item>
                                    </Col>
                                  </>
                                )}

                                {["properties", "parameter"].includes(
                                  getFormValue(outerField?.name, field.name, "parameter")
                                ) && getFormValue(outerField?.name, field.name, "parameter_type")?.length > 0 && (
                                    <Col>
                                      <Form.Item
                                        {...field}
                                        rules={[
                                          {
                                            required: true,
                                            message: t("common.required"),
                                            transform: (val: any) => val?.trim(),
                                          },
                                        ]}
                                        name={[field.name, "value_type"]}
                                        fieldKey={[field.key, "value_type"]}
                                        required
                                        tooltip={requiredFieldStar}
                                      >
                                        <Select
                                          options={valueType}
                                          placeholder={"Select Type"}
                                          style={{
                                            background: "none",
                                            width: "200px"
                                          }}
                                          showSearch
                                          optionFilterProp="label"
                                          onChange={(val: any) => {
                                            const { outer_conditions } = form.getFieldsValue();
                                            const { inner_conditions } = outer_conditions[outerField.name];
                                            inner_conditions[field.name] = {
                                              ...inner_conditions[field.name],
                                              operator: val === "categorical" ? "eq" : undefined,
                                              val: undefined,
                                              unit: undefined
                                            }
                                            form.setFieldsValue({
                                              outer_conditions
                                            });
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                {
                                  ["properties", "custom_fields", "parameter"].includes(getFormValue(outerField?.name, field.name, "parameter")) &&
                                  ((["custom_fields"].includes(getFormValue(outerField?.name, field.name, "parameter")) && getFormValue(outerField?.name, field.name, "parameter_type")) ||
                                    (["properties", "parameter"].includes(getFormValue(outerField?.name, field.name, "parameter")) && getFormValue(outerField?.name, field.name, "value_type"))) &&
                                  (
                                    <Col>
                                      {operatorFormItem(field, outerField)}
                                    </Col>
                                  )
                                }

                                {((["costing", "custom_fields"].includes(getFormValue(outerField?.name, field.name, "parameter")) && getFormValue(outerField?.name, field.name, "operator"))
                                  || (["properties", "parameter"].includes(getFormValue(outerField?.name, field.name, "parameter")) && getFormValue(outerField?.name, field.name, "value_type")))
                                  && (
                                    <>
                                      {getComponentFromOperators(outerField, field)}
                                    </>
                                  )}

                                {!["costing", "custom_fields", "properties", "parameter"].includes(getFormValue(outerField?.name, field.name, "parameter")) && (
                                  <Col>
                                    <Form.Item
                                      rules={[
                                        {
                                          required: getFormValue(outerField?.name, field.name, "parameter") === "additives" ? false : true,
                                          message: t("common.required"),
                                        },
                                      ]}
                                      {...field}
                                      name={[field.name, "val"]}
                                      fieldKey={[field.key, "val"]}
                                      required
                                      tooltip={requiredFieldStar}
                                    >
                                      {[
                                        "ingredient",
                                        "supplier",
                                        "lot_no",
                                        "family",
                                        "category",
                                        "project_id",
                                        "methods",
                                        "grade"
                                      ].includes(
                                        getFormValue(outerField?.name, field.name, "parameter")
                                      ) ? (
                                        <Cascader
                                          options={getDropdownOptions(
                                            getFormValue(outerField?.name, field.name, "parameter")
                                          )}
                                          maxTagCount="responsive"
                                          multiple={true}
                                          placeholder={t(
                                            "formulations.placeholder.selectOrType"
                                          )}
                                          showSearch={{ filter: cascaderFilter }}
                                          showCheckedStrategy="SHOW_CHILD"
                                          style={{
                                            width: 200,
                                            background: "none",
                                          }}
                                        />
                                      ) :
                                        <>
                                          {getFormValue(outerField?.name, field.name, "parameter") === "additives" ?
                                            <Input
                                              style={{ width: "100px" }}
                                              placeholder={t("formulations.placeholder.value")}
                                            />
                                            :
                                            <Select
                                              style={{ width: 200 }}
                                              showSearch
                                              optionFilterProp="label"
                                              options={getDropdownOptions(
                                                getFormValue(outerField?.name, field.name, "parameter")
                                              )}
                                              placeholder={t(
                                                "formulations.placeholder.value"
                                              )}
                                            />
                                          }
                                        </>
                                      }
                                    </Form.Item>
                                  </Col>
                                )}

                                {["properties", "parameter"].includes(getFormValue(outerField?.name, field.name, "parameter")) && getFormValue(outerField?.name, field.name, "val") ?
                                  <Col>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "unit"]}
                                      fieldKey={[field.key, "unit"]}
                                    >
                                      <Select
                                        placeholder="Select Unit"
                                        style={{
                                          background: "none",
                                          width: "150px"
                                        }}
                                        showSearch
                                        optionFilterProp="label"
                                        options={getOptions(
                                          getFormValue(outerField?.name, field.name, "parameter"),
                                          getFormValue(outerField?.name, field.name, "parameter_type") ?? []
                                        )}
                                      />
                                    </Form.Item>
                                  </Col> : null
                                }
                              </Row>
                            ))}
                            <StyledButton
                              size="middle"
                              onClick={() => {
                                add();
                              }}
                              type="text"
                              icon={<PlusOutlined />}
                            >
                              {t("common.addACondition")}
                            </StyledButton>
                          </Space>
                        );
                      }}
                    </Form.List>
                  </StyledCard>
                </Row>
              ))}
              <Row justify="space-between" style={{ marginTop: 20 }}>
                <StyledButton
                  size="middle"
                  onClick={() => {
                    setInnerSelector((state) => [...state, ""]);
                    outerAdd();
                  }}
                  type="text"
                  icon={<PlusOutlined />}
                >
                  {" "}
                  {t("common.addAConditionGroup")}
                </StyledButton>
                <Space size={"middle"}>
                  <StyledButton
                    size="middle"
                    disabled={!innerSelector.length}
                    onClick={clearFilters}
                    type="text"
                  >
                    {t("common.clearFilter")}
                  </StyledButton>
                  <StyledButton
                    size="middle"
                    style={{ borderRadius: 0 }}
                    disabled={!innerSelector.length}
                    loading={filtersLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    {t("common.applyFilter")}
                  </StyledButton>
                </Space>
              </Row>
            </Space>
          );
        }}
      </Form.List>
    </Form>
  );
};
