import { notification } from "antd"
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import {
	createMethodApi,
	fetchMethodListApi,
	saveCharacterizationSetsApi,
	deleteCharacterizationSetsApi,
	deleteCharacterizationMethodsApi,
	checkVariationUsedApi,
	updateCharacterizationMethodApi,
} from "src/services/methods"
import {
	createMethodRequest,
	fetchMethodListRequest,
	fetchMethodListSuccess,
	fetchMethodListFailure,
	createMethodSuccess,
	createMethodFailure,
	saveCharacterizationSetsRequest,
	saveCharacterizationSetsFailure,
	deleteCharacterizationSetsFailure,
	deleteCharacterizationSetsRequest,
	deleteCharacterizationSetsSuccess,
	deleteMethodFailure,
	deleteMethodRequest,
	deleteMethodSuccess,
	checkVariationUsedSuccess,
	checkVariationUsedFailure,
	checkVariationUsedRequest,
	updateCharacterizationMethodRequest,
	updateCharacterizationMethodSuccess,
	updateCharacterizationMethodFailure,
	saveCharacterizationSetsSuccess,
} from "../actions/characterizationMethods"
import { fetchExperimentSuccess } from "../actions/experiment"
import { StoreState } from '../configureStore'
import jwtManager from "src/utils/jwtManager"
import { editExperimentSuccess } from "../actions/workOrderDetails"


function* fetchMethodListSaga({ payload }: any) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(fetchMethodListApi, payload, headers)

		if (status === "Success") {
			yield put(fetchMethodListSuccess({ methodType: payload?.method_type, data }))
		} else {
			yield put(fetchMethodListFailure(message))
		}
	} catch (error) {
		yield put(fetchMethodListFailure(error))
	}
}


function* createMethodSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status },
			},
		} = yield call(createMethodApi, payload, headers)
		if (status === "Success") {
			yield put(createMethodSuccess())
			notification.success({
				message,
				// description: "",
				placement: "bottomRight",
			})
		} else {
			yield put(createMethodFailure())
			notification.error({
				message,
				// description: "",
				placement: "bottomRight",
			})
		}
	} catch (error) {
		yield put(createMethodFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			placement: "bottomRight",
		})
	}
}

function* saveCharacterizationSetsSaga({
	payload,
}: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(saveCharacterizationSetsApi, payload, headers)
		if (status === "Success") {
			yield put(editExperimentSuccess(data));
			yield put(saveCharacterizationSetsSuccess(data))
			notification.success({
				message,
				placement: "bottomRight",
			})
		} else {
			yield put(saveCharacterizationSetsFailure())
			notification.error({
				message,
				placement: "bottomRight",
			})
		}
	} catch (error) {
		yield put(saveCharacterizationSetsFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			// description: "",
			placement: "bottomRight",
		})
	}
}

function* deleteCharacterizationSetsSaga({
	payload,
}: any): Generator<any, any, any> {

	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(deleteCharacterizationSetsApi, payload, headers)
		if (status === "Success") {
			yield put(deleteCharacterizationSetsSuccess())
			yield put(fetchExperimentSuccess(data))
			notification.success({
				message,
				// description: "",
				placement: "bottomRight",
			})
		} else {
			yield put(deleteCharacterizationSetsFailure())
			notification.error({
				message,
				// description: "",
				placement: "bottomRight",
			})
		}
	} catch (error) {
		yield put(deleteCharacterizationSetsFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			// description: "",
			placement: "bottomRight",
		})
	}
}

function* deleteCharacterizationMethodsSaga({
	payload,
}: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status },
			},
		} = yield call(deleteCharacterizationMethodsApi, payload, headers)
		if (status === "Success") {
			yield put(deleteMethodSuccess())
			yield put(fetchMethodListRequest({ method_type: "characterizations" }))
			notification.success({
				message,
				// description: "",
				placement: "bottomRight",
			})
		} else {
			yield put(deleteMethodFailure())
			notification.error({
				message,
				// description: "",
				placement: "bottomRight",
			})
		}
	} catch (error) {
		yield put(deleteMethodFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			// description: "",
			placement: "bottomRight",
		})
	}
}

function* checkVariationUsedSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, is_used },
			},
		} = yield call(checkVariationUsedApi, payload, headers)
		if (status === "Success") {
			yield put(checkVariationUsedSuccess(is_used))
		} else {
			yield put(checkVariationUsedFailure(message))
			notification.error({
				message,
				placement: "bottomRight",
			})
		}
	} catch (error) {
		yield put(checkVariationUsedFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			placement: "bottomRight",
		})
	}
}

function* updateMethodSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }

		const {
			data: {
				result: { message, status },
			},
		} = yield call(updateCharacterizationMethodApi, payload, headers)
		if (status === "Success") {
			yield put(updateCharacterizationMethodSuccess())
			yield put(fetchMethodListRequest({ method_type: "characterizations" }))
			notification.success({
				message,
				placement: "bottomRight",
			})
		} else {
			yield put(updateCharacterizationMethodFailure())
			notification.error({
				message,
				// description: "",
				placement: "bottomRight",
			})
		}
	} catch (error) {
		yield put(updateCharacterizationMethodFailure())
		notification.error({
			message: messages[ln].internal_server_error,
			placement: "bottomRight",
		})
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeEvery(fetchMethodListRequest, fetchMethodListSaga)
	yield takeLatest(createMethodRequest, createMethodSaga)
	yield takeLatest(
		saveCharacterizationSetsRequest,
		saveCharacterizationSetsSaga
	)
	yield takeLatest(
		deleteCharacterizationSetsRequest,
		deleteCharacterizationSetsSaga
	)
	yield takeLatest(deleteMethodRequest, deleteCharacterizationMethodsSaga)
	yield takeLatest(checkVariationUsedRequest, checkVariationUsedSaga)
	yield takeLatest(updateCharacterizationMethodRequest, updateMethodSaga)
}
