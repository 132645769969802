import { Descriptions, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';
import { DisplayNames } from "src/typings"
import "./ShowVariationDetail.scss"

export default function ShowVariationDetail({ variationDetail }: any) {
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  ) as DisplayNames

  return (
    <Row
      style={{
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "#FAFAFA",
      }}
    >
    <Descriptions
      layout="vertical"
      colon={false}
      size="small"
      labelStyle={{ color: "black" }}
      className='show-variation-details-description' >
      {Object.entries(variationDetail ?? {}).map(
        ([key, data]: [string, any]) => (
          <Descriptions.Item
            key={key}
            label={displayNames.characterizations?.[key]?.name ?? key}
          >
            <Typography.Title level={5} style={{margin:0}} >
              {data.value}
            </Typography.Title>
          </Descriptions.Item>
        ),
      )}
    </Descriptions>
    </Row >
  )
}