import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import { Card, Empty, Tag, Typography } from "antd";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import "../Shared/SEMAnalysis.scss";
import ThumbnailCard from "./ThumbnailCard";
import { useParams } from "react-router-dom";

type TProps = {
    loadingDataMap?: any;
    thumbnailList: any[];
    handleCardClick: (args: {}) => void;
    loading?: boolean,
    selectable?: boolean,
    selectedIds?: string[],
    setSelectedIds?: React.Dispatch<React.SetStateAction<string[]>>
    handleDelete?: Function
};

const ThumbnailList = ({
    loadingDataMap,
    handleCardClick,
    thumbnailList,
    loading = false,
    selectable,
    setSelectedIds,
    selectedIds,
    handleDelete,
}: TProps) => {
    const params = useParams<any>();
    let {
        semAnalysisList,
        analysisTypes
    } = useSelector((state: StoreState) => state.semAnalysis);

    if (!Array.isArray(semAnalysisList))
        semAnalysisList = Array.from(semAnalysisList);

    return (
        <>
            <div className="sem-list">
                {loadingDataMap &&
                    Object.values(loadingDataMap)?.map(({ data }: any, i) =>
                        data?.["status"] !== "Completed" &&
                            data?.["analysis_type"] ===
                            analysisTypes[
                            params.analysisType as keyof typeof analysisTypes
                            ] &&
                            data?.["percent_completed"] !== "100%" ? (
                            <Card
                                className="card"
                                key={i}
                                cover={
                                    data?.thumbnail_url ? (
                                        <img
                                            alt="thumbnail"
                                            src={data.thumbnail_url}
                                            className="thumbnail"
                                        />
                                    ) : (
                                        <div className="card-img-placeholder">
                                            <LoadingOutlined />
                                        </div>
                                    )
                                }
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ paddingRight: 15, width: "100%" }}>
                                        <Typography.Text
                                            style={{
                                                color: "#00000073",
                                                display: "block",
                                            }}
                                        >
                                            <Tag icon={<SyncOutlined spin />} color="processing">
                                                In progress ({data?.percent_completed || "0%"})
                                            </Tag>
                                        </Typography.Text>
                                    </div>
                                </div>
                            </Card>
                        ) : null
                    )}

                {thumbnailList.length === 0 &&
                    Object.values(loadingDataMap || {})?.length === 0 &&
                    !loading && <Empty />}
                {thumbnailList.map((item: any) => {
                    return (
                        <ThumbnailCard
                            item={item}
                            handleCardClick={handleCardClick}
                            selectable={selectable}
                            setSelectedIds={setSelectedIds}
                            selectedIds={selectedIds}
                            handleDelete={handleDelete}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default ThumbnailList;
