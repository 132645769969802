import { call, put, select, takeLatest } from "redux-saga/effects";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import jwtManager from "src/utils/jwtManager";
import { updateExperimentUnitApi } from "src/services/workOrders";
import { notification } from "antd";
import { messages } from "src/utils/hooks";
import { updateExperimentUnitFailure, updateExperimentUnitRequest, updateExperimentUnitSuccess } from "../actions/woUnitConversion";



function* updateExperimentUnitSaga({ payload }: any) {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const newPayload = { ...payload }
        if (newPayload?.type) {
            delete newPayload?.type
        }
        const {
            data: {
                result: { data: responseData, status, message },
            },
        } = yield call(updateExperimentUnitApi, { ...newPayload }, headers);
        if (status === "Success") {
            yield put(updateExperimentUnitSuccess(responseData, payload?.type));
        } else {
            notification.error({
                message: message || messages[ln].internal_server_error
            })
            yield put(updateExperimentUnitFailure(message));
        }
    } catch (error) {
        notification.error({
            message: messages[ln].internal_server_error
        })
        yield put(updateExperimentUnitFailure(error));
    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(updateExperimentUnitRequest, updateExperimentUnitSaga);
}
