
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`2 수준 완전 요인설계법`}</h1>
    <p>{`모든 요인 수준의 조합으로 실행되는 요인설계법 실험은 보통 완전 요인설계법 실험이라 합니다. 완전 요인설계법에서의 2 수준 실험은 실험 중인 요인의 수를 나타내는 𝑘로 표시되는 2𝑘 설계로도 알려져 있습니다.`}</p>
    <p>{`2𝑘 설계는 모든 요인이 두 수준에 있는 요인설계법 실험의 특별한 범주입니다. 이러한 설계가 요인을 두 가지 수준에만 포함하고 서로 직교하기 때문에 요인 수가 많아도 분석이 크게 단순화됩니다.`}</p>
    <p><strong parentName="p">{`사용 사례:`}</strong></p>
    <ul>
      <li parentName="ul">{`2𝑘 설계는 실험 작업의 초기 단계에서 많은 요인을 조사할 때 특히 유용합니다.`}</li>
      <li parentName="ul">{`k 개의 요인을 연구할 때 가장 적은 실행 횟수를 제공합니다`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;