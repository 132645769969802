import { Collapse, Space, Table } from "antd"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { celsureWoRetrieveReportRequest } from "src/store/actions/celsureReportPreview"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"


export const CelsureReportHistory = () => {
    const dispatch = useDispatch()
    const [t] = useTranslate()

    const { data: { wo_reports } } = useSelector((state: StoreState) => state.celsureReportPreview)
    const celsureWo = useSelector((state: StoreState) => state.celsureWo.celsureWo)

    useEffect(() => {
        dispatch(celsureWoRetrieveReportRequest({ workOrderId: celsureWo.work_order_id }))
    }, [dispatch, celsureWo.work_order_id])

    const tableColumn: any = [
        {
            key: "filename", dataIndex: "filename", title: t("common.report")
        },
        {
            key: 'user_email', dataIndex: 'user_email', title: t("history.uploadedBy")
        },
        {
            key: 'created', dataIndex: 'created', title: t("history.uploadedDate"), render: (value: any) => new Date(value).toLocaleString()
        },
        {
            key: 'download_link', dataIndex: 'download_link', title: 'Download', render: (value: any, row: any, index: any) => (
                <a href={value} rel="noreferrer" target="_blank">Link</a>
            )
        }
    ]
    return (
        <Collapse>
            <Collapse.Panel header={"Report History"} key="1" forceRender={true} >
                <Space direction='vertical' size={"large"} style={{ width: "100%", overflow: 'auto' }}>
                    <Table columns={tableColumn} dataSource={wo_reports} scroll={{ y: 500 }} bordered pagination={{ pageSize: 5 }} />
                </Space>
            </Collapse.Panel>
        </Collapse>
    )
}
