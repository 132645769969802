import React, { memo, useCallback, useEffect, useState } from "react"
import { SummaryWrapper } from "../summary-wrapper"
import { Form } from "./form"
import { Table } from "./table"
import { AggregationMethod, PivotTableData } from "./types"
import { Col, message } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
	pivotTableClear,
	pivotTableRequest,
} from "src/store/actions/pivotTable"
import { AsyncStates } from "src/constants"
import useTranslate from "src/utils/useTranslate"
import { PlotType } from "src/components/Analytics/enums"

export type PayloadData = {
	var_x: string[]
	var_y: string[]
	prop_to_compare: string
	agg: AggregationMethod
	characterization_method_id?: string,
	normalise: boolean | undefined,
	experiment_ids?: string[],
	with_processing_profile: boolean | undefined,
	formulation_ids?: string[]
}
export type FormData = Omit<
	PayloadData,
	"work_order_id" | "var_x" | "var_y"
> & {
	var_x: string
	var_y: string
}

type P = {
	ingredients: string[]
	properties: string[]
	getDisplayName: (ingredient: string) => string
	workOrderIds: string[]
	characterizationMethodId?: string,
	selectedVariation?: any,
	normalise: boolean | undefined,
	selectedStages: string[],
	selectedExperiments: string[],
	setSaveGraphsPayload: any,
	selectedPlotsData: { [key: string]: any },
	selectedFormulations: string[]
}

export const PivotTableAnalysis = memo(({
	ingredients,
	properties,
	workOrderIds,
	getDisplayName,
	characterizationMethodId,
	selectedVariation,
	normalise,
	selectedStages,
	selectedExperiments,
	setSaveGraphsPayload,
	selectedPlotsData,
	selectedFormulations
}: P) => {
	const [t] = useTranslate()
	const [tableData, setTableData] = useState<PivotTableData | null>(null)
	const response = useSelector((state: StoreState) => state.pivotTable)

	const configs = useSelector((state: StoreState) => state.configs.features)
	const dispatch = useDispatch()

	const onFormSubmit = useCallback((data: FormData) => {
		const zeoncharacterizationMethodId = (Boolean(configs?.ai_engine_with_methods)) && {
			characterization_set_id: selectedVariation?.characterization_set_id
		}

		const payload: PayloadData = {
			prop_to_compare: data.prop_to_compare,
			var_x: [data.var_x],
			var_y: [data.var_y],
			agg: data.agg,
			...zeoncharacterizationMethodId,
			normalise,
			...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: selectedExperiments }),
			with_processing_profile: (Boolean(configs?.processing_profiles) ? true : undefined)
		}
		setSaveGraphsPayload((prev: any) => ({
			...prev,
			[PlotType.PIVOT_ANALYSIS]: {
				x: [data.var_x],
				y: [data.var_y],
				agg: data.agg,
				prop_to_compare: data.prop_to_compare
			}
		}))

		// dispatch(pivotTableClear())
		setTableData(null)
		dispatch(pivotTableRequest(payload))
	}, [configs?.ai_engine_with_methods, configs?.processing_profiles, dispatch, normalise, selectedExperiments, selectedVariation?.characterization_set_id, setSaveGraphsPayload, selectedFormulations])

	useEffect(() => {
		if (response.status === AsyncStates.SUCCESS) {
			if (response.data && Object.keys(response.data.table).length > 0) {
				message.success(t("dataSummary.pivotAnalysis.resultAreReady"))
				setTableData(response.data)
			} else {
				message.error(t("dataSummary.pivotAnalsis.notEnoughData"))
			}
		} else if (response.status === AsyncStates.ERROR) {
			message.error(response.error)
		}
	}, [response, t])

	useEffect(() => {
		return () => {
			dispatch(pivotTableClear())
		}
	}, [workOrderIds, dispatch])

	return (
		<SummaryWrapper heading={t("dataSummary.pivotAnalysis")} tooltip={t("aiEngine.tab.dataSummary.pivotanalysis")} id="pivot-analysis" key="pivot-analysis">
			<Form
				ingredients={ingredients}
				properties={properties}
				submitData={onFormSubmit}
				getDisplayNameCallback={getDisplayName}
				setSaveGraphsPayload={setSaveGraphsPayload}
				selectedPlotsData={selectedPlotsData}
			/>

			<Col style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
				{tableData && (
					<Table
						getDisplayName={getDisplayName}
						x_label={tableData["x-variable"]}
						y_label={tableData["y-variable"]}
						data={tableData.table}
					/>
				)}
			</Col>
		</SummaryWrapper>
	)
})
