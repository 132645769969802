import {
    CloseOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { Checkbox, Drawer, Tooltip } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import "./Common.scss";

type TProps = {
    open: boolean;
    selected: number;
    close: () => void;
    onDeactivate?: () => void;
    onDelete?: () => void;
};

const RepositoryActionDrawer = ({
    open,
    selected,
    close,
    onDeactivate,
    onDelete,
}: TProps) => {
    const [t] = useTranslate();

    return (
        <Drawer
            open={open}
            mask={false}
            placement="bottom"
            height={100}
            closable={false}
            rootClassName="property-drawer"
        >
            <div className="body">
                <div className="selected-items">
                    <Checkbox checked={true} />
                    <span>{selected} {t("repository.itemsSelected")}</span>
                </div>
                <div className="cta">
                    {/* <Tooltip title="Deactivate">
                        <div
                            className="deactivate"
                            onClick={() => onDeactivate && onDeactivate()}
                        >
                            <EyeInvisibleOutlined />
                        </div>
                    </Tooltip> */}
                    <Tooltip title={t("common.delete")}>
                        <div className="delete" onClick={() => onDelete && onDelete()}>
                            <DeleteOutlined />
                        </div>
                    </Tooltip>
                </div>
                <div className="dismiss">
                    <StyledButton
                        type="default"
                        htmlType="submit"
                        icon={<CloseOutlined />}
                        className="dismiss-button"
                        size="small"
                        onClick={close}
                    >
                        {t("common.dismiss")}
                    </StyledButton>
                </div>
            </div>
        </Drawer>
    );
};

export default RepositoryActionDrawer;
