import { Modal, Select, Spin, Typography } from "antd";
import { GptLogoV2 } from "..";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { conversationSummaryClear, conversationSummaryRequest } from "src/store/actions/polyGPT";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { antdTheme, AsyncStates } from "src/constants";
import axios from "axios";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";

type PropTypes = {
  modalState: any[];
};

export const GPTSummaryModal = ({ modalState }: PropTypes) => {
  const [t] = useTranslate()
  const dispatch = useDispatch();

  const [summaryModalState, setSummaryModalState] = modalState;

  const { currentConversation, conversationSummary } = useSelector(
    (state: StoreState) => state.polyGpt
  );

  const [selectedDocument, setSelectedDocument] = useState<any>(null);

  useEffect(() => {
    if (summaryModalState) {
      const payload = {
        conversation_id: currentConversation?.conversation_id,
      };
      dispatch(conversationSummaryRequest(payload));
    }
  }, [currentConversation, dispatch, summaryModalState]);

  const openPdfNewTab = async (url: string) => {
    const response = await axios.get(url, { responseType: 'blob' });
    const file = new Blob([response?.data as BlobPart], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  const handleClose = () => {
    dispatch(conversationSummaryClear());
    setSelectedDocument(null);
    setSummaryModalState(false);
  }
  
  return (
    <>
      <Modal
        title={t("polygpt.Summary")}
        open={summaryModalState}
        onOk={handleClose}
        onCancel={handleClose}
      >
        <Typography.Title level={5}>
          {t("polygpt.Thisissummaryofthedocumentuploaded.")}
        </Typography.Title>
        <div
          style={{
            maxHeight: "50vh",
            overflowY: "auto",
            background: "#FAFAFA",
            padding: "16px",
            borderRadius: "6px",
          }}
        >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={conversationSummary.status === AsyncStates.LOADING}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <Typography.Title level={5} style={{ marginRight: "10px" }}>{t("polygpt.File")}:
              </Typography.Title>
              <Select
                style={{ flexGrow:1 }}
                options={conversationSummary?.data?.summary_list?.map((doc: any) => {
                  return { value: doc.file_name, label: doc.file_name };
                })}
                onChange={(val) => setSelectedDocument(conversationSummary?.data?.summary_list?.find((doc: any) => doc.file_name === val))}
                value={selectedDocument?.file_name}
              />
              {selectedDocument && 
                <div style={{
                  marginLeft: "10px",
                }}>
                  <StyledButton onClick={() => openPdfNewTab(selectedDocument.file_url)} icon={<DownloadOutlined />} />
                </div>
              }
            </div>
            <div style={{ display: "flex", fontSize: antdTheme.fontSizeLG, marginTop: "10px" }}>
              <span>
                <GptLogoV2 />
              </span>
              <div style={{ marginLeft: "1rem" }}>
                <div>{selectedDocument?.summary}</div>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
};
