import React from 'react'
import { InventoryPropertiesTable } from '../InventoryPropertiesTable'
import { Divider, Spin } from 'antd'
import { InventoryCustomFieldsForm } from '../InventoryCustomFieldsForm'
import { InventoryAdditivesForm } from '../InventoryAdditivesForm'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'

type InventoryDetailsPropertiesProps = {
    inventory_id?: string,
    ingredient: any,
    from: string,
    inventoryData: any
}

export const InventoryDetailsProperties = ({ inventory_id, ingredient, from, inventoryData }: InventoryDetailsPropertiesProps) => {
    const updateIngredientResponse = useSelector((state: StoreState) => state.inventoryV2.updateIngredient);
    return (
        <>
            <InventoryPropertiesTable inventory_id={inventory_id} ingredient={ingredient} from={from} inventoryData={inventoryData} />
            <Divider style={{ margin: "40px 0" }} />
            <Spin spinning={updateIngredientResponse.status === AsyncStates.LOADING}>
                <InventoryCustomFieldsForm inventory_id={inventory_id} ingredient={ingredient} from={from} />
                <Divider style={{ margin: "10px 0" }} />
                <InventoryAdditivesForm inventory_id={inventory_id} ingredient={ingredient} from={from} />
            </Spin>
        </>
    )
}
