import { handleActions } from "redux-actions";
import { ProgressActionTypes } from "../actions/widget";
import { AsyncStates } from "../../constants";

export type WidgetState = {
    status: AsyncStates;
    error: string;
    progress: WidgetProgress[];
    showProgress: boolean;
};

export type WidgetProgress = {
    progress_id: string;
    type: string;
    current_stage?: string;
    stage?: string[];
    status: string;
    entity_name: string;
    display_name: string;
    fe_redirect_url: string;
    components: {
        component_id: string;
        component_name: string;
        percentage: number;
        position: number;
    }[];
    created_at: string;
    updated_at: string;
    scope: string;
    is_archived: boolean;
    user_id: string;
    company_id: string;
}

const defaultState: WidgetState = {
    status: AsyncStates.INITIAL,
    error: "",
    progress: [],
    showProgress: true
};

const widgetReducer = handleActions(
    {
        [ProgressActionTypes.REQUEST]: (state) => ({
            ...state,
            status: AsyncStates.LOADING,
            error: "",
        }),
        [ProgressActionTypes.SUCCESS]: (state, action) => {
            return {
                ...state,
                status: AsyncStates.SUCCESS,
                error: "",
                progress: action?.payload?.progress ?? [],
            };
        },
        [ProgressActionTypes.UPDATE]: (state, action: any) => {
            return {
                ...state,
                status: AsyncStates.SUCCESS,
                error: "",
                progress: [...state.progress.map(p => {
                    if (action.payload.id === p.progress_id) return action.payload.data;
                    else return p;
                })]
            };
        },
        [ProgressActionTypes.REMOVE]: (state, action: any) => {
            return {
                ...state,
                status: AsyncStates.SUCCESS,
                error: "",
                progress: [...state.progress.filter(p => p.progress_id !== action.payload.id)],
            };
        },
        [ProgressActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                status: AsyncStates.INITIAL,
                error: "",
                progress: [],
            };
        },
        [ProgressActionTypes.FAILURE]: (state, action) => ({
            ...state,
            status: AsyncStates.ERROR,
            error: action.payload.error,
        }),
        [ProgressActionTypes.SHOW]: (state, action) => ({
            ...state,
            showProgress: action.payload.showProgress
        }),
    },
    defaultState
);

export default widgetReducer;
