
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Generalized Subset`}</h1>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://pubs.acs.org/doi/10.1021/acs.analchem.7b00506"
      }}>{`Generalized Subset Designs (GSD)`}</a>{` provides reduced
designs when handling multiple multilevel factors. This design setup generates a series of reduced
designs, subsets, which are logically linked. When combined, all subsets will add up to a full
multilevel multifactorial design.`}</p>
    <p>{`Generalized Subset Designs, GSD, create a series of reduced design sets to use individually or in
combination. The objective for each individual design set is to be a balanced, unique and
representative sample of all possible factor combinations.`}</p>
    <p><strong parentName="p">{`Use Cases`}</strong>{`:– `}</p>
    <ul>
      <li parentName="ul">{`For generation of efficient stability studies, up to 70% more efficient`}</li>
      <li parentName="ul">{`Screening of multilevel factors, quantitative and qualitative`}</li>
      <li parentName="ul">{`Sequential approach for large screening DOE designs`}</li>
      <li parentName="ul">{`Non biased sample selection in databases or big data applications`}</li>
      <li parentName="ul">{`DOE within Multivariate calibration`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;