import { Dropdown } from 'antd'
import { DropdownButtonProps } from 'antd/lib/dropdown/dropdown-button'
export const StyledButtonDropdown = (props: DropdownButtonProps) => {
    const { style, ...rest } = props
    return (
        <Dropdown.Button
            style={{
                borderRadius: 8,
                overflow: 'hidden',
                outline: 'none',
                ...style
            }}
            {...rest}
        />
    )
}
