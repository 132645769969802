import React, { useEffect, useState } from "react"
import GenericError from "src/components/GenericError/GenericError"
import { CustomPrompt } from "src/utils/CustomPrompts"
import { StoreState } from "src/store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import Spinner from "src/components/Spinner/Spinner"
import { AsyncStates } from "src/constants"
import "../Compare.scss"
import { Space, Tooltip, Switch } from "antd";
import { PlusOutlined } from "@ant-design/icons"
import Text from "antd/lib/typography/Text"
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { setIsEditing } from "src/store/actions/isEditing"

import { ReportTable } from "../ReportTable"
import { ZeonChart as Chart } from "./ZeonChart"
import { dataExportClear, dataExportRequest } from "src/store/actions/workOrderDetails"
import { linkedCompareFormulationsRequest } from "src/store/actions/compareFormulations"

export const Report = ({ visible }: { visible: boolean }) => {
	const dispatch = useDispatch()

	const formulationList = useSelector(
		(state: StoreState) => state.compareFormulations.formulationDetailsData
	)

	const configs = useSelector((state: StoreState) => state.configs.features)
	const [plots, setPlots] = useState<any[]>([])
	const [report, setReport] = useState<any>({})
	const [filename, setFilename] = useState<string>("")
	const [tableData, setTableData] = useState<any>({ trialsCount: 0 })
	const [disableButton, setDisableButton] = useState<boolean>(false)
	const [editMode, setEditMode] = useState<boolean>(true)
	const [displayTables, setDisplayTables] = useState<any>({
		formulations: false,
		processing: false,
		properties: false,
		characterizations: false,
	})

	const isEditing = useSelector((state: StoreState) => state.isEditing)

	const [t] = useTranslate()

	const { dataExportStatus } = useSelector((state: StoreState) => state.workOrderDetails)

	useEffect(() => {
		dispatch(dataExportClear())
	}, [dispatch])


	useEffect(() => {
		const obj = {
			formulations: false,
			processing: false,
			properties: false,
			characterizations: false,
		}
		formulationList.forEach((res: any) => {
			if (Object.keys(res?.ingredients || {}).length) obj.formulations = true
			if (Object.keys(res?.processing?.[0]?.processing || {}).length)
				obj.processing = true
			if (
				Object.keys(res?.characterizations?.[0]?.characterizations || {}).length
			)
				obj.characterizations = true
			if (Object.keys(res?.properties?.[0]?.properties || {}).length)
				obj.properties = true
		})
		setDisplayTables(obj)
	}, [formulationList])

	const generate = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setReport({})
		e.stopPropagation()

		const payload = {
			from: "compare",
			graphs: plots.filter((ele) => !!ele.selectedPropertySets.series.length).map((plot: any) => ({
				id: plot.id,
				selected_trials: plot.selectedTrials,
				data: plot.selectedPropertySets.series,
				trial_names: plot.selectedPropertySets.xAxis.categories,
				title: plot.title ?? "",
				description: plot.description,
				x_label: plot.xLabel ?? "",
				y_label: plot.selectedPropertySets.yAxis,
				work_order_ids: [...new Set(formulationList.reduce((acc, curr) => {
					if (plot.selectedTrials.includes(curr.id_set.formulation_id)) {
						acc.push(curr.work_order_id)
					}
					return acc
				}, []))]
			}
			)),
		}
		dispatch(dataExportRequest(payload))
		setReport({ plots })
	}

	const apiStatus = useSelector(
		(state: StoreState) => state.displayNames.status
	)

	useEffect(() => {
		if (!!plots.length && plots?.filter(({ selectedPropertySets }) => !!selectedPropertySets?.series?.length).length !== plots.length) {
			setDisableButton(true)
		} else {
			setDisableButton(false)
		}
	}, [plots])

	useEffect(() => {
		const filteredFormulations = formulationList?.flatMap(({ ingredients }: any) =>
			Object.entries(ingredients)
				.filter(([key, value]: any) => {
					return value?.type === "trials"
				})
				.map(([key, value]: any) => key)
		)
		const uniqueFormulationIds = [...new Set(filteredFormulations)]
		if (!!uniqueFormulationIds?.length) {
			dispatch(linkedCompareFormulationsRequest({ formulation_id: uniqueFormulationIds }))
		}
	}, [formulationList, dispatch])

	return (
		<div>
			<CustomPrompt
				isEditing={isEditing}
				message={`${t("common.unsavedChangesLost")}!.`}
			/>

			{apiStatus === AsyncStates.LOADING && <Spinner />}

			{apiStatus === AsyncStates.ERROR || formulationList.length === 0 ? (
				<GenericError />
			) : (
				<>
					<Space direction="vertical" size="large" style={{ width: "100%" }}>
						<StyledCard
							title={
								<Space>
									<Text>{t("common.buildAReport")}</Text>
									{filename && (
										<Text code copyable>
											{filename}
										</Text>
									)}
								</Space>
							}
							actions={[
								(tableData?.tables?.length || plots?.length) ? (
									<Tooltip
										title={
											disableButton
												? "Please add remaining chart details"
												: t("common.generateReport")
										}
									>
										<span>
											<StyledButton
												type="primary"
												onClick={generate}
												size="large"
												disabled={disableButton}
												loading={dataExportStatus === AsyncStates.LOADING}
											>
												{t('report.downloadReport')}
											</StyledButton>
										</span>
									</Tooltip>
								) : (
									""
								),
							]}
							extra={
								<Space>
									{report?.graphs?.length ? (
										<>
											{" "}
											<Text type="secondary">{t("compare.modifyChart")}</Text>
											<Switch
												checked={editMode}
												onChange={() => setEditMode((prevState) => !prevState)}
											/>{" "}
										</>
									) : null}
									{filename && (
										<StyledButton
											onClick={() => {
												setPlots([])
												setReport([])
												setFilename("")
												setEditMode(true)
											}}
										>
											{t("common.newReport")}
										</StyledButton>
									)}
								</Space>
							}
						>
							<Space
								size="large"
								direction="vertical"
								style={{ width: "100%" }}
							>
								{!Boolean(configs?.characterization_methods) &&
									<ReportTable
										tableData={tableData}
										setTableData={setTableData}
										formulationList={formulationList}
										displayTables={displayTables}
									/>
								}
								{plots?.map((plot) => (
									<Chart
										visible={visible}
										editMode={editMode}
										allPlots={plots}
										plot={plot}
										setPlots={setPlots}
										key={plot?.id}
										formulationList={formulationList}
									/>
								))}
								<Space style={{ width: "100%", justifyContent: 'center' }}>
									{editMode && (
										<StyledButton
											style={{ margin: "auto", display: "block" }}
											size="large"
											onClick={() => {
												setPlots((state) =>
													state.concat([
														{
															id:
																"report_section_" +
																(Number(
																	state.slice(-1)[0]?.id.split("_").slice(-1)[0]
																) + 1 || 0),
														},
													])
												)
												dispatch(setIsEditing(true))
											}}
											type="dashed"
											icon={<PlusOutlined />}
										>
											{t("common.addChart")}
										</StyledButton>
									)}
								</Space>
							</Space>
						</StyledCard>
					</Space>
				</>
			)}
		</div>
	)
}
