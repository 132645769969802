import { useEffect, useState } from "react"
import { io, Socket } from 'socket.io-client';
import jwtManager from "../jwtManager";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";

// "undefined" means the URL will be computed from the `window.location` object
// const URL = 'http://localhost:8000/comments';


export const useWOCommentsSocket = (namespace: string, wo: string) => {

    const lang = useSelector((state: StoreState) => state.language.current)

    const [socket, setSocket] = useState<Socket | undefined>()

    useEffect(() => {

        if (wo) {
            const URL = process.env.REACT_APP_API_URL + namespace
            setSocket(io(
                URL,
                {
                    path: "/ws/socket.io/",
                    withCredentials: true,
                    forceNew: true,
                    query: {
                        token: jwtManager.getToken() ?? "",
                        wo,
                        lang
                    },
                    transports: ["websocket"]
                }))
        }

    }, [lang, namespace, wo]);

    useEffect(() => {
        socket && socket.connect();

        return () => {
            socket && socket.disconnect()
        }
    }, [socket])

    return socket
}