import React from "react"
import { Modal, ModalProps, Space } from "antd"
import { PCM } from "src/components/Celsure/Pcm3DVisualisation/types"
import { populateColor } from "src/components/Celsure/Pcm3DVisualisation/utils"
import { View3D } from "src/components/Celsure/Pcm3DVisualisation"
import { Table } from "./table"

type P = ModalProps & {
	data: Omit<PCM, "color">[]
}

export const PcmDetailsModal = ({ data, ...props }: P) => {
	return (
		<Modal footer={null} width={1200} {...props}>
			<Space direction="vertical" style={{ width: "100%", overflow: "auto" }}>
				<Table data={populateColor(data)} />
				<View3D
					data={populateColor(data)}
					box={{
						length: 1000,
						width: 1000,
						depth: 1000,
					}}
				/>
			</Space>
		</Modal>
	)
}
