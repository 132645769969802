import Highcharts from 'highcharts/highstock'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import { useRef, useMemo } from 'react';

HighchartsMore(Highcharts)

const Pie = ({
  data,
  total,
  size = 120,
  margin = 0,
  increase = 10,
  onClick = undefined,
  select = false,
  type = undefined
}: any) => {
  const chartRef = useRef<any>(null)

  const chartOptions = useMemo(() => {
    return {
      chart: {
        type: 'pie',
        custom: {},
        height: size,
        width: size,
        marginTop: margin,
        marginRight: margin,
        marginBottom: margin,
        marginLeft: margin,
        events: {
          redraw: function () {
            if (select) {
              let pointToClick = (this as any)?.series[0]?.data?.find(
                (pt: any) => pt?.name === type
              )
              pointToClick?.firePointEvent('click')
            }
          }
        }
      },
      tooltip: {
        formatter: function() {
            return '<b>'+ (this as any).point.key +'</b>: '+ (this as any).y;
        }
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          allowPointSelect: false,
          ...(select ? { cursor: 'pointer' } : {})
        },
        pie: {
          inactiveOther: false,
          borderColor: 'none',
          borderWidth: 0,
          point: {
            events: {
              ...(!select
                ? {
                    mouseOver: function () {
                      const shapeArgs = (this as any).shapeArgs
                      if (shapeArgs) {
                        ;(this as any).graphic.attr({
                          r: shapeArgs.r + increase
                        })
                      }
                    },
                    mouseOut: function () {
                      const shapeArgs = (this as any).shapeArgs
                      if (shapeArgs) {
                        ;(this as any).graphic.attr({
                          r: shapeArgs.r
                        })
                      }
                    }
                  }
                : {}),
              click: function () {
                onClick && onClick((this as any).name)
                const shapeArgs = (this as any).shapeArgs
                if (shapeArgs && select) {
                  ;(this as any).graphic.attr({
                    r: shapeArgs.r + increase
                  })
                }
              }
            }
          }
        }
      },
      state: {
        hover: {
          enabled: false
        }
      },
      series: [
        {
          colorByPoint: true,
          innerSize: '50%',
          dataLabels: { enabled: false },
          name: '',
          data: data?.map((item: any) => ({
            name: item.title,
            key: item.name,
            y: item.count,
            color: item.color
          }))
        }
      ]
    }
  }, [size, margin, select, data, type, increase, onClick])

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
    </div>
  )
}

export default Pie
