import {
  createCustomFieldApi,
  deleteCustomFieldApi,
  getCustomFieldsApi,
  updateCustomFieldApi,
} from "src/services/customFields";
import performApiCallSaga from "./utils";
import {
  createCustomFieldFailure,
  createCustomFieldRequest,
  createCustomFieldSuccess,
  deleteCustomFieldFailure,
  deleteCustomFieldRequest,
  deleteCustomFieldSuccess,
  getCustomFieldsFailure,
  getCustomFieldsRequest,
  getCustomFieldsSuccess,
  updateCustomFieldFailure,
  updateCustomFieldRequest,
  updateCustomFieldSuccess,
} from "../actions/customFields";
import { put, takeLatest } from "redux-saga/effects";
import { getIngredientDetailsRequest } from "../actions/inventoryV2";

function* createCustomFieldSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    createCustomFieldApi,
    createCustomFieldSuccess,
    createCustomFieldFailure,
    payload,
    function* (): any {yield payload?.inventory_id ?  put(getIngredientDetailsRequest({inventory_id: payload.inventory_id})): null}
  );
}

function* getCustomFieldsSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getCustomFieldsApi(payload),
    getCustomFieldsSuccess,
    getCustomFieldsFailure,
    payload
  );
}

function* deleteCustomFieldSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    deleteCustomFieldApi(payload),
    deleteCustomFieldSuccess,
    deleteCustomFieldFailure,
    payload
  );
}

function* updateCustomFieldSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    updateCustomFieldApi,
    updateCustomFieldSuccess,
    updateCustomFieldFailure,
    payload
  );
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(createCustomFieldRequest, createCustomFieldSaga);
  yield takeLatest(getCustomFieldsRequest, getCustomFieldsSaga);
  yield takeLatest(deleteCustomFieldRequest, deleteCustomFieldSaga);
  yield takeLatest(updateCustomFieldRequest, updateCustomFieldSaga);
}
