import { handleActions } from "redux-actions";
import { InverseModelActionTypes, InverseConfigActionTypes, AddFavouritesInInverseActionTypes, GetFavouritesListInInverseActionTypes, DeleteFavouritesInInverseActionTypes } from "../actions/inverseModel";
import { AsyncStates } from "../../constants";


export type InverseModelReducerState = {
    statusRange: any
    statusMinmax: any
    error: string
    inverseModelMinmax: any
    inverseModelRange: any
    statusPredictionIds: AsyncStates
    predictionIdsData: any,
    minmaxAck: any,
    rangeAck: any,
    statusDeletePredictionIds: AsyncStates
    inverseNotificationClicked: any,
    inverseConfigData: any,
    inverseConfigStatus: AsyncStates,
    inverseConfigError: string,
    inverseConfigOpen: boolean,
    updateInverseConfigData: any,
    updateInverseConfigStatus: AsyncStates,
    updateInverseConfigError: string,
    inverseConfigUnsaved: boolean,
    addFavouritesInInverseStatus: AsyncStates,
    addFavouritesInInverseError: string | null | undefined,

    deleteFavouritesInInverseStatus: AsyncStates,
    deleteFavouritesInInverseError: string | null | undefined,

    favouritesListInverse: any[],
    favouritesListInverseStatus: AsyncStates,
    favouritesListInverseError: string | null | undefined,
}

const defaultState: InverseModelReducerState = {
    statusRange: [],
    statusMinmax: [],
    error: "",
    inverseModelRange: [],
    inverseModelMinmax: [],
    statusPredictionIds: AsyncStates.INITIAL,
    predictionIdsData: { data: [], total: "" },
    minmaxAck: [],
    rangeAck: [],
    statusDeletePredictionIds: AsyncStates.INITIAL,
    inverseNotificationClicked: false,
    inverseConfigData: {},
    inverseConfigStatus: AsyncStates.INITIAL,
    inverseConfigError: "",
    inverseConfigOpen: false,
    updateInverseConfigData: {},
    updateInverseConfigStatus: AsyncStates.INITIAL,
    updateInverseConfigError: "",
    inverseConfigUnsaved: false,
    addFavouritesInInverseStatus: AsyncStates.INITIAL,
    addFavouritesInInverseError: null,
    deleteFavouritesInInverseStatus: AsyncStates.INITIAL,
    deleteFavouritesInInverseError: null,
    favouritesListInverse: [],
    favouritesListInverseStatus: AsyncStates.INITIAL,
    favouritesListInverseError: null,
}

const inverseModelReducer = handleActions({
    [InverseModelActionTypes.INVERSE_MODEL_RANGE_CONNECT]: (state, action: any) => {
        const data = { version: action.payload?.version, status: AsyncStates.LOADING }
        const filteredStatus = state.statusRange.filter((res: any) => res.version !== action.payload?.version)
        filteredStatus.push(data)
        return {
            ...state,
            statusRange: filteredStatus,
        }
    },
    [InverseModelActionTypes.INVERSE_MODEL_MINMAX_CONNECT]: (state, action: any) => {
        const data = { version: action.payload?.version, status: AsyncStates.LOADING }
        const filteredStatus = state.statusMinmax.filter((res: any) => res.version !== action.payload?.version)
        filteredStatus.push(data)
        return {
            ...state,
            statusMinmax: filteredStatus,
        }
    },
    [InverseModelActionTypes.INVERSE_MODEL_RANGE_SUCCESS]: (state, action: any) => {
        const dataStatus = { version: action.payload?.version, status: AsyncStates.SUCCESS }
        const filteredStatus = state.statusRange.filter((res: any) => res.version !== action.payload?.version)
        filteredStatus.push(dataStatus)
        const filtered = state.inverseModelRange.filter((res: any) => res.version !== action.payload?.version)
        filtered.push(action.payload)
        return {
            ...state,
            statusRange: filteredStatus,
            inverseModelRange: filtered,
        };
    },
    [InverseModelActionTypes.INVERSE_MODEL_MINMAX_SUCCESS]: (state, action: any) => {
        const dataStatus = { version: action.payload?.version, status: AsyncStates.SUCCESS }
        const filteredStatus = state.statusMinmax.filter((res: any) => res.version !== action.payload?.version)
        filteredStatus.push(dataStatus)
        const filtered = state.inverseModelMinmax.filter((res: any) => res.version !== action.payload?.version)
        filtered.push(action.payload)
        return {
            ...state,
            statusMinmax: filteredStatus,
            inverseModelMinmax: filtered,
        };
    },
    [InverseModelActionTypes.INVERSE_MODEL_RANGE_FAILURE]: (state, action: any) => {
        const dataStatus = { version: action.payload?.error, status: AsyncStates.ERROR }
        const filteredStatus = state.statusRange.filter((res: any) => res.version !== action.payload?.error)
        filteredStatus.push(dataStatus)
        return {
            ...state,
            statusRange: filteredStatus
        }
    },
    [InverseModelActionTypes.INVERSE_MODEL_MINMAX_FAILURE]: (state, action: any) => {
        const dataStatus = { version: action.payload?.error, status: AsyncStates.ERROR }
        const filteredStatus = state.statusMinmax.filter((res: any) => res.version !== action.payload?.error)
        filteredStatus.push(dataStatus)
        return {
            ...state,
            statusMinmax: filteredStatus
        }
    },
    [InverseModelActionTypes.INVERSE_RANGE_ACK]: (state, action: any) => {
        const data = { ack: action.payload }
        const filteredAck = state.rangeAck.filter((res: any) => res?.ack?.version !== action.payload?.version)
        filteredAck.push(data)
        return {
            ...state,
            rangeAck: filteredAck
        }
    },
    [InverseModelActionTypes.INVERSE_MINMAX_ACK]: (state, action: any) => {
        const data = { ack: action.payload }
        const filteredAck = state.minmaxAck.filter((res: any) => res?.ack?.version !== action.payload?.version)
        filteredAck.push(data)
        return {
            ...state,
            minmaxAck: filteredAck
        }
    },
    [InverseModelActionTypes.GET_PREDICTION_REQUEST]: (state) => ({
        ...state,
        statusPredictionIds: AsyncStates.LOADING,
        predictionIdsData: { data: [], total: "" }
    }),
    [InverseModelActionTypes.GET_PREDICTION_SUCCESS]: (state, action) => ({
        ...state,
        statusPredictionIds: AsyncStates.SUCCESS,
        predictionIdsData: action.payload
    }),
    [InverseModelActionTypes.GET_PREDICTION_FAILURE]: (state, action) => ({
        ...state,
        statusPredictionIds: AsyncStates.ERROR,
        predictionIdsData: { data: [], total: "" },
        error: action.payload.error
    }),
    [InverseModelActionTypes.DELETE_PREDICTION_IDS_REQUEST]: (state) => ({
        ...state,
        statusDeletePredictionIds: AsyncStates.LOADING
    }),
    [InverseModelActionTypes.DELETE_PREDICTION_IDS_SUCCESS]: (state) => ({
        ...state,
        statusDeletePredictionIds: AsyncStates.SUCCESS
    }),
    [InverseModelActionTypes.DELETE_PREDICTION_IDS_FAILURE]: (state, action) => ({
        ...state,
        statusDeletePredictionIds: AsyncStates.ERROR
    }),
    [InverseModelActionTypes.DELETE_PREDICTION_IDS_CLEANUP]: (state) => ({
        ...state,
        statusDeletePredictionIds: AsyncStates.INITIAL
    }),
    [InverseModelActionTypes.INVERSE_NOTIFICATION_CLICK]: (state, action) => ({
        ...state,
        inverseNotificationClicked: action.payload
    }),
    [InverseConfigActionTypes.INVERSE_CONFIG_REQUEST]: (state) => ({
        ...state,
        inverseConfigStatus: AsyncStates.LOADING
    }),
    [InverseConfigActionTypes.INVERSE_CONFIG_SUCCESS]: (state, action) => ({
        ...state,
        inverseConfigStatus: AsyncStates.SUCCESS,
        inverseConfigData: action.payload
    }),
    [InverseConfigActionTypes.INVERSE_CONFIG_FAILURE]: (state, action) => ({
        ...state,
        inverseConfigStatus: AsyncStates.ERROR,
        inverseConfigError: action.payload.error
    }),
    [InverseConfigActionTypes.INVERSE_CONFIG_CLEANUP]: (state) => ({
        ...state,
        inverseConfigStatus: AsyncStates.INITIAL,
        inverseConfigData: {}
    }),
    [AddFavouritesInInverseActionTypes.REQUEST]: (state) => ({
        ...state,
        addFavouritesInInverseStatus: AsyncStates.LOADING
    }),
    [AddFavouritesInInverseActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        addFavouritesInInverseStatus: AsyncStates.SUCCESS,
        addFavouritesInInverseError: null
    }),
    [AddFavouritesInInverseActionTypes.FAILURE]: (state, action: any) => ({
        ...state,
        addFavouritesInInverseStatus: AsyncStates.ERROR,
        addFavouritesInInverseError: action.payload
    }),
    [AddFavouritesInInverseActionTypes.CLEANUP]: (state) => ({
        ...state,
        addFavouritesInInverseStatus: AsyncStates.INITIAL,
        addFavouritesInInverseError: null
    }),

    [DeleteFavouritesInInverseActionTypes.REQUEST]: (state) => ({
        ...state,
        deleteFavouritesInInverseStatus: AsyncStates.LOADING
    }),
    [DeleteFavouritesInInverseActionTypes.SUCCESS]: (state) => ({
        ...state,
        deleteFavouritesInInverseStatus: AsyncStates.SUCCESS,
        deleteFavouritesInInverseError: null
    }),
    [DeleteFavouritesInInverseActionTypes.FAILURE]: (state, action: any) => ({
        ...state,
        deleteFavouritesInInverseStatus: AsyncStates.ERROR,
        deleteFavouritesInInverseError: action.payload
    }),
    [DeleteFavouritesInInverseActionTypes.CLEANUP]: (state) => ({
        ...state,
        deleteFavouritesInInverseStatus: AsyncStates.INITIAL,
        deleteFavouritesInInverseError: null
    }),

    [GetFavouritesListInInverseActionTypes.REQUEST]: (state) => ({
        ...state,
        favouritesListInverseStatus: AsyncStates.LOADING,
        favouritesListInverseError: null,
    }),
    [GetFavouritesListInInverseActionTypes.SUCCESS]: (state, action: any) => ({
        ...state,
        favouritesListInverse: action.payload,
        favouritesListInverseStatus: AsyncStates.SUCCESS,
        favouritesListInverseError: null,
    }),
    [GetFavouritesListInInverseActionTypes.FAILURE]: (state, action: any) => ({
        ...state,
        favouritesListInverseStatus: AsyncStates.ERROR,
        favouritesListInverseError: action.payload,
    }),
    [GetFavouritesListInInverseActionTypes.CLEANUP]: (state) => ({
        ...state,
        favouritesListInverse: [],
        favouritesListInverseStatus: AsyncStates.INITIAL,
        favouritesListInverseError: null,
    }),
}, defaultState)


export default inverseModelReducer;