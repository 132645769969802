import React, { Dispatch, SetStateAction, useMemo } from "react"
import { Checkbox, Table, Tooltip } from "antd"
import { ColumnType } from "antd/lib/table"
import { ValueType, ZeonPredicton } from "./types"
import { toTitleCase } from "src/utils/general"
import { removeDuplicateParamsWithUnit } from "./utils"
import { CharacterizationDataTable } from "./characterization-data-table"
import { convertToPrecision } from "src/utils/decorator"
import { geekblue, volcano, purple } from "@ant-design/colors"
import { ParameterTooltip } from "../form/payload-form/ParameterTooltip"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"

type P = {
	data: ZeonPredicton[]
	selectedExperiments: ZeonPredicton[]
	setSelectedExperiments: Dispatch<SetStateAction<ZeonPredicton[]>>
	showZeroValue: boolean
}

type TableColumnType = {
	name: {
		type: "ingredient" | "processing" | "property"
		data:
		| string
		| {
			charaterizationName: string
			properties: string[]
			parameters: string[]
		}
		identifier?: string
	}
	unit: {
		type: "ingredient" | "processing" | "property"
		data: string | { type: "property" | "characterization"; data: string }[]
	}
	// [time: string]: string | number | string[][][]
}

export const MainTable = ({ data, selectedExperiments, setSelectedExperiments, showZeroValue }: P) => {
	const categoryLabels = useSelector((state: StoreState) => state.displayNames.data?.characterizations || {})
	const { forwardPredResult: { hidden_procs: processingsWithZeroValue, hidden_ings: ingredientsWithZeroValue = [] } } = useSelector((state: StoreState) => state.formulate)

	const columns: ColumnType<TableColumnType>[] = useMemo(() => {
		// NAME AND UNITS
		const baseColumns = [
			{
				title: (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "0.2rem",
							alignItems: "center",
						}}
					>
						<Checkbox
							onChange={(e) => {
								if (e.target.checked) setSelectedExperiments(data)
								else setSelectedExperiments([])
							}}
							checked={selectedExperiments.length === data.length}
						/>
						Ingredients/Properties
					</div>
				),
				dataIndex: "name",
				width: 300,
				align: "center",
				className: "zeon-prediction-result-name-column no-padding",
				fixed: true,
				render: (value: TableColumnType["name"], _, index) => {
					// IF THE VALUE IS INGREDIENT OR PROCESSING
					if (typeof value.data === "string") {
						const isIngredient = value.type === "ingredient"

						return {
							key: index,
							props: {
								style: {
									background: isIngredient ? "white" : volcano[0],
								},
							},
							children: (
								<div
									style={{
										color: isIngredient ? "unset" : volcano[5],
									}}
									className="zeon-table-cell"
								>
									{isIngredient ? (
										<ParameterTooltip value={value.identifier ?? value.data} />
									) : (
										<Tooltip title={isIngredient ? "Ingredient" : "Processing"}>
											{value.data}
										</Tooltip>
									)}
								</div>
							),
						}
					}

					return {
						key: index,
						children: (
							<table className="zeon-prediction-result-custom-table">
								<tbody>
									{value.data.properties.map((property) => {
										return (
											<tr key={property}>
												<td
													style={{
														background: geekblue[0],
														color: geekblue[5],
													}}
													className="properties-cell zeon-table-cell"
													colSpan={2}
													align="center"
												>
													<Tooltip title="Property">{property}</Tooltip>
												</td>
											</tr>
										)
									})}

									<tr
										style={{
											background: purple[0],
											color: purple[5],
										}}
									>
										<td align="center" className="characterization-name-cell">
											<Tooltip title="Characterization">
												{toTitleCase(value.data.charaterizationName)}
											</Tooltip>
										</td>
										<td>
											{value.data.parameters.map((param) => {
												return (
													<table
														key={param}
														className="parameters-cell-row"
														style={{
															width: "100%",
															display: "table",
														}}
													>
														<tbody>
															<tr>
																<td
																	align="center"
																	className="parameters-cell zeon-table-cell"
																>
																	{categoryLabels?.[param]?.name ?? param ?? ""}
																</td >
															</tr >
														</tbody >
													</table >
												)
											})}
										</td >
									</tr >
								</tbody >
							</table >
						),
					}
				},
			},
			{
				title: "Units",
				dataIndex: "unit",
				className: "zeon-prediction-result-unit-column no-padding",
				width: 80,
				align: "center",
				fixed: true,
				render: (value: TableColumnType["unit"], record, index) => {
					if (typeof value.data === "string") {
						const isIngredient = value.type === "ingredient"

						return {
							key: index,
							props: {
								style: { background: isIngredient ? "white" : volcano[0] },
							},
							children: <div>{value.data}</div>,
						}
					}

					const isProperty = value.type === "property"

					return {
						key: index,
						children: (
							<table className="zeon-prediction-result-custom-table">
								<tbody>
									{value.data.map((unit, i) => {
										return (
											<tr
												key={i}
												style={{
													background:
														isProperty && unit.type === "property"
															? geekblue[0]
															: purple[0],
													color:
														isProperty && unit.type === "property"
															? geekblue[5]
															: purple[5],
												}}
												className="properties-cell-row"
											>
												<td
													className="properties-cell zeon-table-cell"
													align="center"
												>
													{unit.data}
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						),
					}
				},
			},
		] as ColumnType<TableColumnType>[]

		// TRIALS COLUMNS
		const trials = data.map((prediction) => {
			return {
				title: (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "0.2rem",
							alignItems: "center",
						}}
					>
						<Checkbox
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedExperiments((prev) => {
										return [...prev, prediction]
									})
								} else {
									setSelectedExperiments((prev) => {
										return prev.filter((pred) => {
											return pred.experiment_id !== prediction.experiment_id
										})
									})
								}
							}}
							checked={selectedExperiments.some((pred) => {
								return pred.experiment_id === prediction.experiment_id
							})}
						/>
						{prediction.experiment_id}
					</div>
				),
				dataIndex: prediction.experiment_id,
				width: 400,
				align: "center",
				className: "zeon-prediction-result-trial-column no-padding",
				render: (
					value: {
						type: "ingredient" | "property" | "processing"
						data:
						| ValueType
						| { type: "characterization" | "property"; value: ValueType }[][]
					},
					_,
					index
				) => {
					if (
						typeof value.data === "string" ||
						typeof value.data === "number" ||
						value.data === null
					) {
						const isIngredient = value.type === "ingredient"

						return {
							key: index,
							props: {
								style: { background: isIngredient ? "white" : volcano[0] },
							},
							children: <div className="zeon-table-cell">{value.data}</div>,
						}
					}

					return {
						key: index,
						children: <CharacterizationDataTable data={value.data} />,
					}
				},
			} as ColumnType<TableColumnType>
		})

		return [...baseColumns, ...trials]
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, selectedExperiments.length])

	const dataSource = useMemo(() => {
		if (data.length === 0) return []
		if (Object.keys(data[0].predicted_properties).length === 0) return []

		// INGREDIENTS CELLS
		const ingredients = Object.keys(data[0].ingredients || {})
		const unit = data[0].ingredients[ingredients[0]]?.unit ?? "-"
		const ingredientsData = ingredients.map((ingredient) => {
			const row: {
				name: TableColumnType["name"]
				unit: TableColumnType["unit"]
				[key: string]: unknown
			} = {
				name: {
					type: "ingredient",
					data: ingredient,
					identifier: data[0].ingredients[ingredient].identifier,
				},
				unit: {
					type: "ingredient",
					data: unit,
				},
			}

			data.forEach((prediction) => {
				row[prediction.experiment_id] = {
					type: "ingredient",
					data: convertToPrecision(prediction.ingredients[ingredient].value),
				}
			})

			return row
		})

		// PROCESSING CELLS)
		const processing = Object.keys(data[0].processing ?? {})
		const processingData = processing.map((proces) => {
			const row: {
				name: TableColumnType["name"]
				unit: TableColumnType["unit"]
				[key: string]: unknown
			} = {
				name: {
					type: "processing",
					data: proces,
				},
				unit: {
					type: "processing",
					data: unit,
				},
			}

			data.forEach((prediction) => {
				if (!prediction.processing) {
					row[prediction.experiment_id] = {
						type: "processing",
						data: null,
					}
					return
				}
				if (prediction.processing) {
					row[prediction.experiment_id] = {
						type: "proessing",
						data: convertToPrecision(prediction.processing[proces].value),
					}
				}
			})

			return row
		})

		// CHARATERIZATION CELLS FIRST & SECOND COLUMN CELLS
		const charaterizationName = Object.keys(data[0].predicted_properties)[0]
		const properties = [
			...new Set(
				data[0].predicted_properties[charaterizationName].flatMap((entry) => {
					return Object.keys(entry.properties)
				})
			),
		]
		const firstProperty = Object.keys(
			data[0].predicted_properties[charaterizationName][0]?.properties ?? {}
		)[0]
		const propertyUnit =
			data[0].predicted_properties[charaterizationName][0]?.properties[
				firstProperty
			]?.unit ?? "-"

		const paramsWithUnits = removeDuplicateParamsWithUnit(
			data.flatMap((prediction) => {
				return prediction.predicted_properties[charaterizationName].flatMap(
					(collection) => {
						return Object.entries(collection.variation).map(
							(
								entry: [
									string,
									{
										value: ValueType
										category: string | null
										unit: string | null
									}
								]
							) => {
								return {
									param: entry[0],
									unit: entry[1]?.unit ?? "-",
								}
							}
						)
					}
				)
			})
		)
		const parameters = paramsWithUnits.map((param) => param.param)
		const propertiesUnits: string[] = Array(properties.length).fill({
			type: "property",
			data: propertyUnit,
		})

		const parametersUnit = paramsWithUnits.map((param) => ({
			type: "characterization",
			data: param.unit,
		}))
		const totalUnits = [...propertiesUnits, ...parametersUnit]

		// CHARACTERIZATION CELLS REMAINING COLUMNS CELLS
		const characterizationData = data.map((prediction) => {
			const propertiesValues = prediction.predicted_properties[
				charaterizationName
			].map((collection) => {
				return [
					...properties.map((propertyName) => {
						const property = collection.properties[propertyName]
						if (!property) {
							return {
								type: "property",
								value: "-",
							}
						}

						return property.std !== null && property.std !== 0
							? {
								type: "property",
								value: `${convertToPrecision(property.value)} ± ${convertToPrecision(property.std)}`,
							}
							: {
								type: "property",
								value: property.value,
							}
					}),
					...parameters.map((paramName) => {
						const param = collection.variation[paramName]
						return {
							type: "characterization",
							value: param ? param.value : "-",
						}
					}),
				]
			})
			return propertiesValues
		})

		// CHARACTERIZATION ROW
		const characterizationRow = {
			// FIRST COLUMN
			name: {
				data: {
					charaterizationName,
					properties,
					parameters: parameters,
				},
				type: "property",
			},
			// SECOND COLUMN
			unit: {
				data: totalUnits,
				type: "property",
			},
		} as TableColumnType

		// ADDING CHARACTERIZATION SUB TABLE
		data.forEach((prediction, i) => {
			; (characterizationRow as any)[prediction.experiment_id] = {
				type: "property",
				data: characterizationData[i],
			}
		})

		const result = !showZeroValue ? [...ingredientsData, ...processingData].filter((parameter) => {
			const parameterName = typeof parameter.name.data === "string" ? parameter.name.data : ""
			return !((parameter.name.type === "ingredient" || parameter.name.type === "processing") && ([...ingredientsWithZeroValue, ...processingsWithZeroValue].includes(parameterName)))
		}) : [...ingredientsData, ...processingData]

		return [...result, characterizationRow]
	}, [data, ingredientsWithZeroValue, processingsWithZeroValue, showZeroValue])

	return (
		<Table
			className="zeon-prediction-result-table"
			bordered
			scroll={{ x: 400 }}
			pagination={false}
			columns={columns}
			dataSource={dataSource}
		/>
	)
}
