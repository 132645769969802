import { ref, onValue, onDisconnect, set, serverTimestamp, remove } from "firebase/database";
import { COLLECTION_ENVIRONTMENT_SUFFIX } from "./firebase";

// Since I can connect from multiple devices or browser tabs, we store each connection instance separately
// any time that connectionsRef's value is null (i.e. has no children) I am offline
export const presence = (db, user) => {

    const myConnectionsRef = ref(db, `${COLLECTION_ENVIRONTMENT_SUFFIX}/users/${user.uid}/online`);

    // stores the timestamp of my last disconnect (the last time I was seen online)
    const lastOnlineRef = ref(db, `${COLLECTION_ENVIRONTMENT_SUFFIX}/users/${user.uid}/lastOnline`);

    const connectedRef = ref(db, '.info/connected');
    onValue(connectedRef, (snap) => {
        if (snap.val() === true) {
            // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
            // Add this device to my connections list
            // this value could contain info about the device or a timestamp too
            set(myConnectionsRef, true);
            remove(lastOnlineRef);
            // When I disconnect, update the last time I was seen online
            onDisconnect(myConnectionsRef).set(false);
            onDisconnect(lastOnlineRef).set(serverTimestamp());
        }
    });

}