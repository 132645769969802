import { Image } from 'antd';
import React from 'react';

const DocumentViewer = ({ url, isImage }: { url: string, isImage: boolean }) => {
  return isImage ?
    <Image
      height={'100%'}
      src={url}
      preview={false}
    /> :
    <iframe
      title="Document Viewer"
      src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
      width="100%"
      height="100%"
    />
};

export default DocumentViewer;