import { call, put, select, takeLatest } from "redux-saga/effects"
import jwtManager from "src/utils/jwtManager"
import { getFormulationDetails } from "src/services/formulation"
import {
    linkedFormulationsAiEngineFailure,
    linkedFormulationsAiEngineRequest,
    linkedFormulationsAiEngineSuccess,
} from "../actions/common"

function* linkedFormulationsSaga({ payload }: any) {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data: {
                result: { data, status },
            },
        } = yield call(getFormulationDetails, payload, headers)
        if (status === "Success") {
            yield put(
                linkedFormulationsAiEngineSuccess({
                    formulation_id: payload.formulation_id?.[0],
                    data: data.reduce((acc: any, curr: any) => {
                        return { ...acc, [curr?.id_set?.formulation_id]: curr }
                    }, {})
                }),
            )
        } else {
            yield put(linkedFormulationsAiEngineFailure())
        }
    } catch (error) {
        yield put(linkedFormulationsAiEngineFailure())
    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(linkedFormulationsAiEngineRequest, linkedFormulationsSaga)
}
