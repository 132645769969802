import React from "react"
import { Switch } from "antd"
import {
	tooltipModules,
	tooltipModuleToTitle,
	useGuideTooltip,
} from "src/components/product-guide/product-guide-tooltip/utils"
import { useTranslationLabel } from "src/utils/hooks/useTranslationLabel"

const tooltips = tooltipModules.map((module) => {
	return {
		key: module,
		title: tooltipModuleToTitle[module],
	}
})

export const ProductGuideSettings = () => {
	const tLabel = useTranslationLabel()
	const { isModuleVisible, setModule } = useGuideTooltip()

	return (
		<div
			style={{
				display: "flex",
				listStyle: "none",
				flexDirection: "column",
				gap: "20px",
			}}
		>
			{tooltips.map((tooltip) => {
				return (
					<div
						key={tooltip.key}
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							gap: "20px",
						}}
					>
						<span>{tLabel(tooltip.title)}</span>
						<Switch
							checked={isModuleVisible(tooltip.key)}
							onChange={() => {
								setModule(tooltip.key, !isModuleVisible(tooltip.key))
							}}
						/>
					</div>
				)
			})}
		</div>
	)
}
