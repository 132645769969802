
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Generalisierte Teilmenge`}</h1>
    <p>{`Die `}<a parentName="p" {...{
        "href": "https://pubs.acs.org/doi/10.1021/acs.analchem.7b00506"
      }}>{`Generalisierten Teilmenge Designs (GSD)`}</a>{` bieten reduzierte
Designs, wenn es um die Handhabung mehrerer mehrstufiger Faktoren geht. Diese Designkonfiguration erzeugt eine Reihe von reduzierten
Designs, Teilmenge genannt, die logisch miteinander verknüpft sind. Wenn sie kombiniert werden, ergeben alle Teilmengen ein vollständiges
multistufiges multifaktorielles Design.`}</p>
    <p>{`Die generalisierten Teilmenge Designs, GSD, erstellen eine Reihe von reduzierten Designsets, die einzeln oder in
Kombination verwendet werden können. Das Ziel für jedes individuelle Designset ist es, eine ausgewogene, einzigartige und
repräsentative Stichprobe aller möglichen Faktorkombinationen zu sein.`}</p>
    <p><strong parentName="p">{`Anwendungsfälle:`}</strong></p>
    <ul>
      <li parentName="ul">{`Zur Generierung effizienter Stabilitätsstudien, bis zu 70% effizienter`}</li>
      <li parentName="ul">{`Screening von mehrstufigen Faktoren, quantitativen und qualitativen`}</li>
      <li parentName="ul">{`Sequenzieller Ansatz für große Screening-DOE-Designs`}</li>
      <li parentName="ul">{`Unvoreingenommene Stichprobenauswahl in Datenbanken oder Anwendungen für Big Data`}</li>
      <li parentName="ul">{`DOE innerhalb der multivariaten Kalibrierung`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;