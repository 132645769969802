import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	compareFormulationsRequest,
	compareFormulationsSuccess,
	compareFormulationsFailure,
	reportUploadRequest,
	reportUploadSuccess,
	reportUploadFailure,
	reportsRetrieveRequest,
	reportsRetrieveSuccess,
	reportsRetrieveFailure,
	reportShareRequest,
	reportDeleteRequest,
	reportDeleteSuccess,
	reportDeleteFailure,
	reportShareSuccess,
	reportShareFailure,
	linkedCompareFormulationsRequest,
	linkedCompareFormulationsSuccess,
	linkedCompareFormulationsFailure,
	templateListRequest,
	templateCreateFailure,
	templateCreateRequest,
	templateCreateSuccess,
	templateListFailure,
	templateListSuccess,
	templateUpdateFailure,
	templateUpdateRequest,
	templateUpdateSuccess,
	templateDeleteRequest,
	templateDeleteFailure,
	templateDeleteSuccess,
} from "../actions/compareFormulations"
import { getFormulationDetails } from "../../services/formulation/index"
import {
	reportUpload,
	reportsRetrieve,
	reportSharing,
	reportDeletion,
	modularReportsUploadApi,
	modularReportsRetrieveApi,
	modularReportsDeleteApi,
	modularTemplateListApi,
	modularTemplateCreateApi,
	modularTemplateUpdateApi,
	modularTemplateDeleteApi,
} from "src/services/report"
import { notification } from "antd"
import { history } from "src"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import { messages } from "src/utils/hooks"
import jwtManager from "src/utils/jwtManager"

type formulationDetailsAction = {
	type: string
	payload: {
		formulationId: Array<string>
	}
}

// type reportUploadAction = {
// 	type: string
// 	payload: {
// 		report: Blob
// 		metadata: any
// 	}
// }

type reportShareAction = {
	type: string
	payload: {
		receiver_ids: string
		message: string
		report_id: string
	}
}

// type reportDeleteAction = {
// 	type: string
// 	payload: {
// 		report_id: string
// 	}
// }

function* compareFormulationsSaga({
	payload: { formulationId },
}: formulationDetailsAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			formulation_id: formulationId,
		}
		const {
			data: {
				result: { data, status },
			},
		} = yield call(getFormulationDetails, params, headers)
		if (status === "Success") {
			yield put(compareFormulationsSuccess(data))
			history.push("/formulations/compare", {
				formulationId,
			})
		} else {
			yield put(compareFormulationsFailure())
		}
	} catch (error) {
		yield put(compareFormulationsFailure())
	}
}

function* reportUploadSaga({ payload }: any) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const ln: LanguageUnion = yield select(
			(state: StoreState) => state.language.current
		)
		const params = new FormData()
		params.append("report", payload.report)
		if (payload?.from === "modularReports") {
			params.append("file_name", payload.file_name)
			params.append("type", payload.type)
			params.append("data", JSON.stringify(payload.data))
		} else {
			params.append("metadata", JSON.stringify(payload.metadata))
		}

		const {
			data: {
				result: { message, status },
			},
		} = yield call(payload?.from === "modularReports" ? modularReportsUploadApi : reportUpload, params, headers)

		if (status === "Success") {
			notification.success({
				message: message,
				description: messages[ln].scroll_down_to_see_reports,
			})
			yield put(reportUploadSuccess())
			yield put(reportsRetrieveRequest({ type: payload?.type }))
		} else {
			yield put(reportUploadFailure(message))
		}
	} catch (error) {
		yield put(reportUploadFailure(error))
	}
}

function* reportShareSaga({ payload }: reportShareAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status },
			},
		} = yield call(reportSharing, payload, headers)

		if (status === "Success") {
			notification.success({ message })
			yield put(reportShareSuccess())
			yield put(reportsRetrieveRequest())
		} else {
			yield put(reportShareFailure(message))
		}
	} catch (error) {
		yield put(reportShareFailure(error))
	}
}

function* reportDeleteSaga({ payload }: any) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status },
			},
		} = yield call(payload?.from === "modularReports" ? modularReportsDeleteApi : reportDeletion, payload, headers)
		if (status === "Success") {
			notification.warning({ message })
			yield put(reportDeleteSuccess())
			yield put(reportsRetrieveRequest({ type: payload?.type, from: payload?.from }))
		} else {
			yield put(reportDeleteFailure(message))
		}
	} catch (error) {
		yield put(reportDeleteFailure(error))
	}
}

function* reportsRetrieveSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(!!payload?.type ? modularReportsRetrieveApi : reportsRetrieve, payload, headers)

		if (status === "Success") {
			yield put(reportsRetrieveSuccess(data))
		} else {
			yield put(reportsRetrieveFailure(message))
		}
	} catch (error) {
		yield put(reportsRetrieveFailure(error))
	}
}

function* templateListSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(modularTemplateListApi, payload, headers)

		if (status === "Success") {
			yield put(templateListSuccess(data))
		} else {
			notification.error({
				message,
				duration: 3,
			})
			yield put(templateListFailure(message))
		}
	} catch (error) {
		yield put(templateListFailure(error))
	}
}

function* templateCreateSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(modularTemplateCreateApi, payload, headers)

		if (status === "Success") {
			yield put(templateCreateSuccess(data))
			notification.success({
				message,
				duration: 3,
			})
		} else {
			notification.error({
				message,
				duration: 3,
			})
			yield put(templateCreateFailure())
		}
	} catch (error) {
		notification.error({
			message: error,
			duration: 3,
		})
		yield put(templateCreateFailure())
	}
}

function* templateUpdateSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(modularTemplateUpdateApi, payload, headers)

		if (status === "Success") {
			notification.success({
				message,
				duration: 3,
			})
			yield put(templateUpdateSuccess(data))
		} else {
			notification.error({
				message,
				duration: 3,
			})
			yield put(templateUpdateFailure(message))
		}
	} catch (error) {
		notification.error({
			message: error,
			duration: 3,
		})
		yield put(templateUpdateFailure(error))
	}
}

function* templateDeleteSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(modularTemplateDeleteApi, payload, headers)

		if (status === "Success") {
			notification.success({
				message,
				duration: 3,
			})
			yield put(templateDeleteSuccess(data))
		} else {
			notification.error({
				message,
				duration: 3,
			})
			yield put(templateDeleteFailure(message))
		}
	} catch (error) {
		yield put(templateDeleteFailure(error))
	}
}

function* linkedCompareFormulationsSaga({
	payload,
}: any) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(getFormulationDetails, payload, headers)
		if (status === "Success") {
			yield put(linkedCompareFormulationsSuccess(data))
		} else {
			yield put(linkedCompareFormulationsFailure())
		}
	} catch (error) {
		yield put(linkedCompareFormulationsFailure())
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(compareFormulationsRequest, compareFormulationsSaga)
	yield takeLatest(reportUploadRequest, reportUploadSaga)
	yield takeLatest(reportShareRequest, reportShareSaga)
	yield takeLatest(reportDeleteRequest, reportDeleteSaga)
	yield takeLatest(reportsRetrieveRequest, reportsRetrieveSaga)
	yield takeLatest(templateListRequest, templateListSaga)
	yield takeLatest(templateCreateRequest, templateCreateSaga)
	yield takeLatest(templateUpdateRequest, templateUpdateSaga)
	yield takeLatest(templateDeleteRequest, templateDeleteSaga)
	yield takeLatest(
		linkedCompareFormulationsRequest,
		linkedCompareFormulationsSaga
	)
}
