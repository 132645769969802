/* eslint-disable import/no-webpack-loader-syntax */
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Space,
  Typography,
  Form,
  Input,
  Modal,
  Collapse,
  message,
  Tooltip,
  Popconfirm,
  Table,
  Tabs,
  Row,
  Spin,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  listAlgorithmsRequest,
  generateExperimentRequest,
  setFormData as setFormDataAction,
  setAdditionalParams as setAdditionalParamsAction,
  setIngredientsFactors as setIngredientsFactorsAction,
  setIngredientsConstants as setIngredientsConstantsAction,
  setProcessingFactors as setProcessingFactorsAction,
  setProcessingConstants as setProcessingConstantsAction,
  setParamsCheck as setParamsCheckAction,
  setFactorsCheck as setFactorsCheckAction,
  setFactorsModalCheck as setFactorsModalCheckAction,
  setTaguchiSelectedLevel as setTaguchiSelectedLevelAction,
  generateExperimentSuccess,
  generateExperimentFailure,
} from "src/store/actions/doe";
import { StoreState } from "src/store/configureStore";
import { InfoCircleFilled } from "@ant-design/icons";
import { antdTheme, AsyncStates } from "src/constants";
import { AddFactors } from "./AddFactors";
import { AdditionalParameters } from "./AdditionalParameters";
import { ConstantFactors } from "./ConstantFactors";
import { AddFactorsModal } from "./AddFactorsModal";
import { motion } from "framer-motion";
import { StyledCard } from "src/styled_components/StyledCard";
import { StyledButton } from "src/styled_components/StyledButton";
import { setIsEditing } from "src/store/actions/isEditing";
import { CustomPrompt } from "src/utils/CustomPrompts";
import useTranslate from "src/utils/useTranslate";
import { trackEvent } from "src/analytics";
import { useHistory } from "react-router-dom";
import styles from "./DoE.module.scss";
import { TabsProps } from "antd/lib";
import { isInRange, isValidNumber } from "src/utils/decorator";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import { Unsubscribe } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { DOE_COLLECTION, firestoreDb } from "src/utils/firebase";

const { Text, Title } = Typography;

export type TaguchiSelectedLevelType = {
  selectedLevel: string | number;
  seletedLevelData: any;
  isMixedLevelModalOpen: boolean;
};

export default function NewExperimentAlgo() {
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const { push } = useHistory();
  const requiredFieldStar = useRequiredFieldStar();
  const { listAlgorithmsStatus, selectedAlgorithm } = useSelector(
    (state: StoreState) => state.doe
  );
  const history = useHistory();

  useEffect(() => {
    if (Object.keys(selectedAlgorithm).length === 0) {
      push("/generate-new-experiments");
    }
  }, [selectedAlgorithm, push]);

  const {
    formData: formDataRedux,
    additionalParams: additionalParamsRedux,
    ingredientsFactors: ingredientsFactorsRedux,
    ingredientsConstantData: ingredientsConstantDataRedux,
    processingFactors: processingFactorsRedux,
    processingConstanstData: processingConstanstDataRedux,
    taguchiSelectedLevel: taguchiSelectedLevelRedux,
    paramsCheck: paramsCheckRedux,
    factorsCheck: factorsCheckRedux,
    factorModalCheck: factorModalCheckRedux,
    generateExperimentStatus,
    generateExperimentData,
  } = useSelector((state: StoreState) => state.doe);

  const [formData, setFormData] = useState<any>(formDataRedux);
  const [additionalParams, setAdditionalParams] = useState<any>(
    additionalParamsRedux
  );
  const [ingredientsFactors, setIngredientsFactors] = useState<any>(
    ingredientsFactorsRedux
  );
  const [paramsCheck, setParamsCheck] = useState<boolean>(paramsCheckRedux);
  const [factorsCheck, setfactorsCheck] = useState<boolean>(factorsCheckRedux);
  const [ingredientsConstantData, setIngredientsConstantData] = useState<any[]>(
    ingredientsConstantDataRedux
  );
  const [factorModalCheck, setFactorModalCheck] = useState<string>(
    factorModalCheckRedux
  );
  const [processingFactors, setProcessingFactors] = useState<any>(
    processingFactorsRedux
  );
  const [processingConstanstData, setProcessingConstanstData] = useState<any[]>(
    processingConstanstDataRedux
  );
  const [taguchiSelectedLevel, setTaguchiSelectedLevel] =
    useState<TaguchiSelectedLevelType>(taguchiSelectedLevelRedux);
  const [expGenStatus, setExpGenStatus] = useState<any>({});
  const mounted = useRef(false);

  const [newFactorsModalVisible, setNewFactorsModalVisible] =
    useState<boolean>(false);
  const [addFactorsModalVisible, setAddFactorsModalVisible] =
    useState<boolean>(false);

  const isEditing = useSelector((state: StoreState) => state.isEditing);
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );

  const currentLanguage = useSelector(
    (state: StoreState) => state.language.current
  );
  useEffect(() => {
    dispatch(listAlgorithmsRequest());
  }, [dispatch, currentLanguage]);

  useEffect(() => {
    if (listAlgorithmsStatus === AsyncStates.INITIAL)
      dispatch(listAlgorithmsRequest());
  }, [dispatch, listAlgorithmsStatus]);

  useEffect(() => {
    const { parameters } = selectedAlgorithm;
    let flag = true;
    if (parameters?.reduction === null) {
      if (additionalParams.reduction < 2) flag = false;
    }
    if (
      parameters?.centers === null &&
      selectedAlgorithm.name === "box_benhken"
    ) {
      if (additionalParams.centers < 0) flag = false;
    }
    if (
      parameters?.centers === null &&
      selectedAlgorithm.name === "_ccdesign"
    ) {
      if (additionalParams.centers[0] < 0 || additionalParams.centers[1] < 0)
        flag = false;
    }
    setParamsCheck(flag);
  }, [additionalParams, selectedAlgorithm]);

  useEffect(() => {
    let flag = true;
    if (
      selectedAlgorithm.name === "_ccdesign" ||
      selectedAlgorithm.name === "_gsd"
    ) {
      if (ingredientsFactors.length + processingFactors.length < 2)
        flag = false;
    } else if (selectedAlgorithm.name === "_bbdesign") {
      if (ingredientsFactors.length + processingFactors.length < 3)
        flag = false;
    }
    setfactorsCheck(flag);
  }, [selectedAlgorithm, ingredientsFactors, processingFactors]);

  useEffect(() => {
    if (selectedAlgorithm.name === "_ccdesign") {
      setAdditionalParams({
        alpha: "orthogonal",
        face: "circumscribed",
        reduction: 2,
        samples: "",
        centers: [4, 4],
      });
    } else {
      setAdditionalParams({
        alpha: "orthogonal",
        face: "circumscribed",
        reduction: 2,
        samples: "",
        centers: 1,
      });
    }
  }, [selectedAlgorithm]);

  useEffect(() => {
    let unsub: Unsubscribe;
    if (generateExperimentData?.doe_collection_key) {
      const taskDocRef = doc(
        firestoreDb,
        `${DOE_COLLECTION}/${generateExperimentData?.doe_collection_key}`
      );

      unsub = onSnapshot(taskDocRef, (doc) => {
        const taskDoc = doc.data();
        if (taskDoc) {
          const taskStatus = taskDoc["status"];
          setExpGenStatus(() => ({
            ...taskDoc,
          }));

          if (String(taskStatus).toLowerCase() === "completed") {
            unsub && unsub();
            notification.success({
              message: (
                <div>
                  {t("doe.expGenSuccessPleaseCheck")}
                  <span
                    style={{ cursor: "pointer", color: "#1677ff" }}
                    onClick={() => history.push("/experiment-history")}
                  >
                    {t("navBar.menuItem.experimentHistory")}
                  </span>
                  {t("doe.expGenResultCheck")}
                </div>
              ),
              duration: 5,
            });
            dispatch(generateExperimentSuccess({}));
          }
          if (String(taskStatus).toLowerCase() === "failed") {
            unsub && unsub();
            notification.error({
              message: (
                <div>
                  <div>{t("doe.expGenInFailed")}</div>
                  <div
                    style={{ cursor: "pointer", color: "#1677ff" }}
                    onClick={() => history.push("/experiment-history")}
                  >
                    {t("navBar.menuItem.experimentHistory")}
                  </div>
                </div>
              ),
              duration: 5,
            });
            dispatch(generateExperimentFailure({}));
          }
        }
      });
    }

    return () => {
      unsub && unsub();
    };
  }, [dispatch, generateExperimentData, history, t]);

  useEffect(() => {
    if (generateExperimentStatus === AsyncStates.SUCCESS && mounted.current) {
      notification.info({
        message: (
          <div>
            {t("doe.expGenInProgressPleaseCheck")}
            <span
              style={{ cursor: "pointer", color: "#1677ff" }}
              onClick={() => history.push("/experiment-history")}
            >
              {t("navBar.menuItem.experimentHistory")}
            </span>
            {t("doe.expGenStatusCheck")}
          </div>
        ),
        duration: 5,
      });
    }
    mounted.current = true;
  }, [generateExperimentStatus, history, t]);

  const formatRange = (min: number, max: number) => {
    if (min === max) return max;
    return `${min} - ${max}`;
  };

  const generateExperiments = () => {
    let flag = false;
    if (
      selectedAlgorithm.name === "_taguchi" &&
      taguchiSelectedLevel.selectedLevel === "mixed_level"
    ) {
      const allFactors = [...ingredientsFactors, ...processingFactors];
      if (
        allFactors.length <=
        taguchiSelectedLevel.seletedLevelData?.total_allowed_factors
      ) {
        const twoLevelFactors = allFactors.filter(
          (res: any) =>
            isValidNumber(res.low) &&
            isValidNumber(res.high) &&
            res.center === null
        );
        const threeLevelFactors = allFactors.filter(
          (res: any) =>
            isValidNumber(res.low) &&
            isValidNumber(res.high) &&
            isValidNumber(res.center)
        );
        if (
          !isInRange(
            twoLevelFactors.length,
            taguchiSelectedLevel.seletedLevelData?.allowed_2_level_factors?.min,
            taguchiSelectedLevel.seletedLevelData?.allowed_2_level_factors?.max
          )
        ) {
          return message.error(
            `You have selected ${
              twoLevelFactors.length
            } 2-level factors, but the selected design allows ${formatRange(
              taguchiSelectedLevel.seletedLevelData?.allowed_2_level_factors
                ?.min,
              taguchiSelectedLevel.seletedLevelData?.allowed_2_level_factors
                ?.max
            )} 2-level factors. Please select the correct number of factors.`
          );
        }
        if (
          !isInRange(
            threeLevelFactors.length,
            taguchiSelectedLevel.seletedLevelData?.allowed_3_level_factors?.min,
            taguchiSelectedLevel.seletedLevelData?.allowed_3_level_factors?.max
          )
        ) {
          return message.error(
            `You have selected ${
              threeLevelFactors.length
            } 3-level factors, but the selected design allows ${formatRange(
              taguchiSelectedLevel.seletedLevelData?.allowed_3_level_factors
                ?.min,
              taguchiSelectedLevel.seletedLevelData?.allowed_3_level_factors
                ?.max
            )} 3-level factors. Please select the correct number of factors.`
          );
        }
      } else {
        return message.error(
          `You have selected ${allFactors.length} factors, but the selected design allows ${taguchiSelectedLevel.seletedLevelData?.total_allowed_factors} factors. Please select the correct number of factors.`
        );
      }
    }
    ingredientsFactors.forEach((res: any) => {
      if (res.level === 2) {
        if (res.high === null || res.low === null) flag = true;
      } else {
        if (
          selectedAlgorithm.name === "_taguchi" &&
          (taguchiSelectedLevel.selectedLevel === 2 ||
            taguchiSelectedLevel.selectedLevel === "mixed_level")
        ) {
          if (res.high === null || res.low === null) flag = true;
        } else if (
          selectedAlgorithm.name === "_taguchi" &&
          taguchiSelectedLevel.selectedLevel === 3
        ) {
          if (res.high === null || res.low === null || res.center === null)
            flag = true;
        } else {
          if (res.high === null || res.low === null || res.center === null)
            flag = true;
        }
      }
    });
    processingFactors.forEach((res: any) => {
      if (res.level === 2) {
        if (res.high === null || res.low === null) flag = true;
      } else {
        if (
          selectedAlgorithm.name === "_taguchi" &&
          (taguchiSelectedLevel.selectedLevel === 2 ||
            taguchiSelectedLevel.selectedLevel === "mixed_level")
        ) {
          if (res.high === null || res.low === null) flag = true;
        } else if (
          selectedAlgorithm.name === "_taguchi" &&
          taguchiSelectedLevel.selectedLevel === 3
        ) {
          if (res.high === null || res.low === null || res.center === null)
            flag = true;
        } else {
          if (res.high === null || res.low === null || res.center === null)
            flag = true;
        }
      }
    });
    ingredientsConstantData.forEach((res: any) => {
      if (res.value === "") {
        flag = true;
      }
    });
    processingConstanstData.forEach((res: any) => {
      if (res.value === "") {
        flag = true;
      }
    });
    if (flag || (!processingFactors?.length && !ingredientsFactors?.length)) {
      message.error(t("newExp.message.addValueTable"));
      return;
    }

    const algorithm = selectedAlgorithm.name;
    const ingredients = ingredientsFactors.map((res: any) => res.key);
    const processing = processingFactors.map((res: any) => res.key);
    const ingredientLevels: any = [];
    ingredientsFactors.forEach((element: any) => {
      if (element.level === 2) {
        ingredientLevels.push([element.low, element.high]);
      } else {
        if (
          selectedAlgorithm.name === "_taguchi" &&
          taguchiSelectedLevel.selectedLevel === 2
        ) {
          ingredientLevels.push([element.low, element.high]);
        } else {
          ingredientLevels.push([element.low, element.center, element.high]);
        }
      }
    });
    const processingLevels: any = [];
    processingFactors.forEach((element: any) => {
      if (element.level === 2) {
        processingLevels.push([element.low, element.high]);
      } else {
        if (
          selectedAlgorithm.name === "_taguchi" &&
          taguchiSelectedLevel.selectedLevel === 2
        ) {
          processingLevels.push([element.low, element.high]);
        } else {
          processingLevels.push([element.low, element.center, element.high]);
        }
      }
    });
    const { reduction, alpha, centers, face, samples } =
      selectedAlgorithm.parameters;
    trackEvent(userId, "DOE -> user generated new Experiments", {
      algorithm,
      ingredients,
      processing,
    });
    dispatch(
      generateExperimentRequest({
        algorithm,
        ingredients,
        ingredientLevels: ingredientLevels,
        processing,
        processingLevels,
        title: formData.title,
        description: formData.description,
        ...(reduction === null && { reduction: additionalParams.reduction }),
        ...(alpha === null && { alpha: additionalParams.alpha }),
        ...(centers === null && { centers: additionalParams.centers }),
        ...(samples === null && {
          samples: additionalParams?.samples
            ? additionalParams.samples
            : ingredients?.length,
        }),
        ...(face === null && { face: additionalParams.face }),
        ingredientsConstants: ingredientsConstantData.reduce(
          (obj: any, element: any) => ({
            ...obj,
            [element.key]: element?.value,
          }),
          {}
        ),
        processingConstants: processingConstanstData.reduce(
          (obj: any, element: any) => ({
            ...obj,
            [element.key]: element?.value,
          }),
          {}
        ),
        mixed_levels_type: taguchiSelectedLevel?.seletedLevelData?.key,
      })
    );
  };

  useEffect(() => {
    dispatch(setFormDataAction(formData));
  }, [dispatch, formData]);

  useEffect(() => {
    dispatch(setAdditionalParamsAction(additionalParams));
  }, [dispatch, additionalParams]);

  useEffect(() => {
    dispatch(setIngredientsFactorsAction(ingredientsFactors));
  }, [dispatch, ingredientsFactors]);

  useEffect(() => {
    dispatch(setIngredientsConstantsAction(ingredientsConstantData));
  }, [dispatch, ingredientsConstantData]);

  useEffect(() => {
    dispatch(setProcessingFactorsAction(processingFactors));
  }, [dispatch, processingFactors]);

  useEffect(() => {
    dispatch(setProcessingConstantsAction(processingConstanstData));
  }, [dispatch, processingConstanstData]);

  useEffect(() => {
    dispatch(setParamsCheckAction(paramsCheck));
  }, [dispatch, paramsCheck]);

  useEffect(() => {
    dispatch(setFactorsCheckAction(factorsCheck));
  }, [dispatch, factorsCheck]);

  useEffect(() => {
    dispatch(setFactorsModalCheckAction(factorModalCheck));
  }, [dispatch, factorModalCheck]);

  useEffect(() => {
    dispatch(setTaguchiSelectedLevelAction(taguchiSelectedLevel));
  }, [dispatch, taguchiSelectedLevel]);

  useEffect(() => {
    return () => {
      setTaguchiSelectedLevel({
        selectedLevel: 2,
        isMixedLevelModalOpen: false,
        seletedLevelData: null,
      });
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: "just" }}
    >
      <CustomPrompt
        isEditing={isEditing}
        message={`${t("common.unsavedChangesLost")}!.`}
      />
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        <StyledPageHeader
          ghost={false}
          title={
            <Row align={"middle"}>
              <Title level={3} style={{ margin: "0", marginRight: 12 }}>
                {t("common.generateNewExperiments")}
              </Title>
              <Text type={"secondary"}>
                {`(${t("newExperiment.selectAnAlgorithmAndYourFactors")})`}
              </Text>
            </Row>
          }
          onBack={() => window.history.back()}
        />
        <Spin
          spinning={
            generateExperimentStatus === AsyncStates.LOADING ||
            String(expGenStatus?.status || "completed").toLowerCase() !==
              "completed"
          }
          tip={t("doe.expGenInProgress")}
        >
          <StyledCard>
            <Space size="large" direction="vertical" style={{ width: "100%" }}>
              <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 18 }}
                requiredMark={false}
                style={{ marginTop: 20 }}
                onFieldsChange={() => dispatch(setIsEditing(true))}
              >
                <Form.Item
                  name="title"
                  labelAlign="left"
                  label={t("doe.exptitle")}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                      transform: (value) => value?.trim(),
                    },
                  ]}
                  required
                  tooltip={requiredFieldStar}
                >
                  <Input
                    placeholder={t("doe.newExp.placeholder.experimentName")}
                    defaultValue={formData.title}
                    value={formData.title}
                    onChange={(e: any) =>
                      setFormData((prevState: any) => ({
                        ...prevState,
                        title: e.target.value,
                      }))
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  labelAlign="left"
                  label={t("aiengine.Expdescription")}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                      transform: (value) => value?.trim(),
                    },
                  ]}
                  required
                  tooltip={requiredFieldStar}
                >
                  <Input.TextArea
                    value={formData.description}
                    defaultValue={formData.description}
                    placeholder={t(
                      "doe.newExp.placeholder.experimentDescription"
                    )}
                    onChange={(e: any) =>
                      setFormData((prevState: any) => ({
                        ...prevState,
                        description: e.target.value,
                      }))
                    }
                  ></Input.TextArea>
                </Form.Item>
              </Form>
            </Space>
          </StyledCard>

          <AddFactors
            selectedAlgorithm={selectedAlgorithm}
            ingredientsFactors={ingredientsFactors}
            setIngredientsFactors={setIngredientsFactors}
            factorsCheck={factorsCheck}
            setAddFactorsModalVisible={setAddFactorsModalVisible}
            setFactorModalCheck={setFactorModalCheck}
            processingFactors={processingFactors}
            setProcessingFactors={setProcessingFactors}
            setNewFactorsModalVisible={setNewFactorsModalVisible}
            taguchiSelectedLevel={taguchiSelectedLevel}
            setTaguchiSelectedLevel={setTaguchiSelectedLevel}
          />

          <Space
            size="large"
            direction="vertical"
            style={{ width: "100%", marginBottom: 100 }}
          >
            {Object.keys(selectedAlgorithm?.parameters || {}).length > 1 && (
              <Collapse defaultActiveKey={["add-params"]}>
                <Collapse.Panel
                  header={t("newExp.additionalParameters")}
                  key="add-params"
                >
                  <AdditionalParameters
                    selectedAlgorithm={selectedAlgorithm}
                    additionalParams={additionalParams}
                    setAdditionalParams={setAdditionalParams}
                  />
                </Collapse.Panel>
              </Collapse>
            )}
            <Collapse defaultActiveKey={["work-order"]}>
              <Collapse.Panel
                header={
                  <Space>
                    <Title level={5}>{t("doe.constantsFactor")}</Title>
                    <Tooltip title={t("newExp.pleaseNote")}>
                      <InfoCircleFilled
                        style={{ fontSize: antdTheme.fontSize }}
                      />
                    </Tooltip>
                  </Space>
                }
                key="work-order"
              >
                <ConstantFactors
                  setAddFactorsModalVisible={setAddFactorsModalVisible}
                  ingredientsConstantData={ingredientsConstantData}
                  setIngredientsConstantData={setIngredientsConstantData}
                  setFactorModalCheck={setFactorModalCheck}
                  processingConstanstData={processingConstanstData}
                  setProcessingConstanstData={setProcessingConstanstData}
                  setNewFactorsModalVisible={setNewFactorsModalVisible}
                />
              </Collapse.Panel>
            </Collapse>
            <Space style={{ width: "100%" }}>
              <Popconfirm
                okText={t("common.ok")}
                cancelText={t("common.cancel")}
                title={t("newExp.goBack")}
                onConfirm={() => {
                  setIngredientsFactors([]);
                  setProcessingFactors([]);
                  setIngredientsConstantData([]);
                  setProcessingConstanstData([]);
                  setTaguchiSelectedLevel({
                    selectedLevel: 2,
                    isMixedLevelModalOpen: false,
                    seletedLevelData: null,
                  });
                  push("/generate-new-experiments");
                  dispatch(setIsEditing(false));
                }}
              >
                <StyledButton style={{ paddingLeft: 16, paddingRight: 16 }}>
                  {t("common.back")}
                </StyledButton>
              </Popconfirm>
              <Tooltip
                title={
                  !formData.title || !formData.description
                    ? t("newExp.EnterTitleAndDescription")
                    : !paramsCheck
                    ? t("newExp.enterAdditionalParameter")
                    : !factorsCheck
                    ? "Enter the no. required factors"
                    : t("newExp.generateExperiment")
                }
              >
                <StyledButton
                  style={{ paddingLeft: 16, paddingRight: 16 }}
                  disabled={
                    !formData.title ||
                    !formData.description ||
                    !paramsCheck ||
                    !factorsCheck
                  }
                  type="primary"
                  loading={generateExperimentStatus === AsyncStates.LOADING}
                  onClick={generateExperiments}
                >
                  {t("common.generateNewExperiments")}
                </StyledButton>
              </Tooltip>
            </Space>
          </Space>

          <AddFactorsModal
            addFactorsModalVisible={addFactorsModalVisible}
            setAddFactorsModalVisible={setAddFactorsModalVisible}
            ingredientsConstantData={ingredientsConstantData}
            setIngredientsConstantData={setIngredientsConstantData}
            ingredientsFactors={ingredientsFactors}
            setIngredientsFactors={setIngredientsFactors}
            from={factorModalCheck}
            selectedAlgorithm={selectedAlgorithm}
            processingFactors={processingFactors}
            setProcessingFactors={setProcessingFactors}
            processingConstanstData={processingConstanstData}
            setProcessingConstanstData={setProcessingConstanstData}
            newFactorsModalVisible={newFactorsModalVisible}
            setNewFactorsModalVisible={setNewFactorsModalVisible}
          />

          {taguchiSelectedLevel.isMixedLevelModalOpen && (
            <TaguchiDesignSelectionModal
              taguchiSelectedLevel={taguchiSelectedLevel}
              setTaguchiSelectedLevel={setTaguchiSelectedLevel}
              selectedAlgorithm={selectedAlgorithm}
            />
          )}
        </Spin>
      </Space>
    </motion.div>
  );
}

type TaguchiDesignSelectionModalProps = {
  taguchiSelectedLevel: TaguchiSelectedLevelType;
  setTaguchiSelectedLevel: Dispatch<SetStateAction<TaguchiSelectedLevelType>>;
  selectedAlgorithm: any;
};

const TaguchiDesignSelectionModal = ({
  taguchiSelectedLevel,
  setTaguchiSelectedLevel,
  selectedAlgorithm,
}: TaguchiDesignSelectionModalProps) => {
  const [activeLevel, setActiveLevel] = useState(
    Object.keys(selectedAlgorithm?.mixed_designs ?? {})?.[0]
  );

  const formatRange = (min: number, max: number) => {
    if (min === max) return max;
    return `${min} - ${max}`;
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Designs",
        dataIndex: "design_name",
        key: "design_name",
      },
      {
        title: "2 Level",
        dataIndex: "two_level",
        key: "two_level",
        render: (text: string, record: any) => {
          const range = formatRange(
            record.two_level?.min ?? "",
            record.two_level?.max ?? ""
          );
          return <Text>{range}</Text>;
        },
      },
      {
        title: "3 Level",
        dataIndex: "three_level",
        key: "three_level",
        render: (text: string, record: any) => {
          const range = formatRange(
            record.three_level?.min ?? "",
            record.three_level?.max ?? ""
          );
          return <Text>{range}</Text>;
        },
      },
      {
        title: "Maximum Number of Factors",
        dataIndex: "total_allowed_factors",
        key: "total_allowed_factors",
      },
    ];
  }, []);

  const handleSubmit = () => {
    if (!taguchiSelectedLevel.seletedLevelData) {
      return message.error("Please select a design");
    }
    setTaguchiSelectedLevel((prevState) => ({
      ...prevState,
      isMixedLevelModalOpen: false,
    }));
  };

  const onChange = (key: string) => {
    setActiveLevel(key);
  };

  const tabOptions: TabsProps["items"] = useMemo(() => {
    return Object.entries(selectedAlgorithm?.mixed_designs ?? {}).map(
      ([key, design]: any) => {
        const tableData = Object.entries(design?.mixed_design_data ?? {}).map(
          ([designKey, designValue]: any) => {
            return {
              key: designKey,
              design_name: designKey,
              two_level: designValue?.allowed_2_level_factors,
              three_level: designValue?.allowed_3_level_factors,
              total_allowed_factors: designValue?.total_allowed_factors,
            };
          }
        );

        return {
          key: key,
          label: design.mixed_design_name,
          children: (
            <div
              style={{
                paddingTop: "0.5rem",
              }}
            >
              <Table
                key={key}
                dataSource={[...tableData]}
                columns={columns}
                scroll={{ y: 300 }}
                pagination={false}
                rowSelection={{
                  type: "radio",
                  onSelect: (record, selected, selectedRows) => {
                    setTaguchiSelectedLevel((prevState) => ({
                      ...prevState,
                      seletedLevelData: {
                        ...selectedAlgorithm?.mixed_designs?.[key]
                          ?.mixed_design_data?.[record?.key],
                        key: record?.key,
                      },
                    }));
                  },
                }}
              />
            </div>
          ),
        };
      }
    );
  }, [columns, selectedAlgorithm?.mixed_designs, setTaguchiSelectedLevel]);

  return (
    <Modal
      width={600}
      open={taguchiSelectedLevel.isMixedLevelModalOpen}
      title={
        <Text className={styles.taguchiDesignHeader}>Taguchi Designs</Text>
      }
      onCancel={() =>
        setTaguchiSelectedLevel((prevState) => ({
          ...prevState,
          isMixedLevelModalOpen: false,
          selectedLevel: 2,
          seletedLevelData: null,
        }))
      }
      footer={null}
      maskClosable={false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-between",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Text className={styles.available__taguchi__designs}>
          Available Taguchi designs (with number of factors)
        </Text>

        <Tabs items={tabOptions} onChange={onChange} activeKey={activeLevel} />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            disabled={!taguchiSelectedLevel.seletedLevelData}
            type="primary"
            onClick={() => handleSubmit()}
          >
            Submit
          </StyledButton>
        </div>
      </div>
    </Modal>
  );
};
