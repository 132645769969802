import { DownloadOutlined } from "@ant-design/icons";
import { Collapse, Row, Space, Table, Tooltip } from "antd";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { DisplayNames } from "src/typings";
import useTranslate from "src/utils/useTranslate";
import { useValue } from "src/utils/useValue";

type TProps = {
    smileReportDownloadLink: string | undefined
}

const SmileOverview = ({ smileReportDownloadLink }: TProps) => {
    const smileSummaryResult = useSelector((state: StoreState) => state.smileSummary.smileSummaryResult);
    const names = useSelector((state: StoreState) => state.displayNames.data) as DisplayNames
    const [t] = useTranslate();
    const { getValue: getLocalValue } = useValue();

    const smileOverallSummary = useMemo(() => {
        return {
            chemical_information: smileSummaryResult?.results?.overall_summary?.L2["chemical information"],
            properties: smileSummaryResult?.results?.overall_summary?.L3["Properties"]
        }
    }, [smileSummaryResult]);

    const smilePropertyData = useMemo(() => {
        return smileSummaryResult?.results?.data_summary?.properties;
    }, [smileSummaryResult])

    const getTitle = (text: string) => <div className="header">{text}</div>;

    const renderCell = (text: string | null, cutoff = 25) => {
        if (text === "_NA_" || !text) return "-";
        let formattedText = text;
        if (text.length > cutoff) formattedText = `${text.substring(0, cutoff)}...`;
        return (<div className="content">
            <Tooltip title={text}>
                {formattedText}
            </Tooltip>
        </div>);
    }

    const renderCellImage = (url: string, record: any) => url && (
        <div className="content">
            <Tooltip overlayClassName="molecular-structure" placement="left" title={(
                <>
                    <img src={url} className="img-small" alt="molecular structure" />
                    <div>{record.notation}</div>
                </>
            )}>
                <img className="img-thumb" src={url} alt="molecular structure" />
            </Tooltip>
        </div>
    )

    const getIngredientsColumns = useCallback(() => {
        const cols: any = [
            {
                title: getTitle(t("common.name")),
                dataIndex: 'name',
                key: 'name',
                render: (text: string) => renderCell(text, 15)
            },
            {
                title: getTitle(t("smiles.casId")),
                dataIndex: 'casId',
                key: 'casId',
                render: (text: string) => renderCell(text)
            },
            {
                title: getTitle(t("smiles.formula")),
                dataIndex: 'formula',
                key: 'formula',
                render: (text: string) => renderCell(text)
            },
            {
                title: getTitle(t("smiles.smileNotation")),
                dataIndex: 'notation',
                key: 'notation',
                render: (text: string) => renderCell(text)
            },
            {
                title: getTitle(t("smiles.molecular_weight")),
                dataIndex: 'molecularWeight',
                key: 'molecularWeight',
                render: (text: string) => renderCell(text)
            },
            {
                title: getTitle(t("smiles.structure")),
                dataIndex: 'structure',
                key: 'structure',
                render: (text: string, record: object) => renderCellImage(text, record)
            }
        ];

        return cols.map((col: object) => ({ ...col, width: 120 }));
    }, [t]);

    const getPropertyColumns = useCallback(() => {
        const cols: any = [
            {
                title: getTitle(t("common.name")),
                dataIndex: 'name',
                key: 'name',
                render: (text: string) => renderCell(text),
                width: 200
            },
            {
                title: getTitle(t("common.unit")),
                dataIndex: 'unit',
                key: 'unit',
                render: (text: string) => renderCell(text),
                width: 200
            }
        ];

        return cols.map((col: object) => ({ ...col, width: 120 }));
    }, [t])

    const getIngredientData = useCallback((sourceData: { [key: string]: { [key: string]: any } }, displayNames: { [key: string]: { [key: string]: any } }) => {
        let data: { [key: string]: string }[] = [];

        data = Object.keys(sourceData || {}).map((d: string) => {
            return {
                name: displayNames[d]?.name || d,
                casId: sourceData[d].csid,
                formula: sourceData[d].formula,
                notation: sourceData[d].smiles,
                molecularWeight: getLocalValue(sourceData[d].Mw, 4) && `${getLocalValue(sourceData[d].Mw, 4)} g/mol`,
                structure: sourceData[d].img_url
            }
        });

        return data;
    }, [getLocalValue]);

    const getPropertyData = useCallback((sourceData: { [key: string]: { [key: string]: any } }, displayNames: { [key: string]: { [key: string]: any } }) => {
        let data: { [key: string]: string }[] = [];

        data = Object.keys(sourceData || {}).map((d: string) => {
            return {
                name: displayNames[d]?.name || d,
                unit: sourceData[d].Units,
            }
        });

        return data;
    }, [])

    const ingredientsDataSource = useMemo(() => smileOverallSummary && getIngredientData(smileOverallSummary.chemical_information, names.ingredients), [smileOverallSummary, names, getIngredientData]);
    const propertyDataSource = useMemo(() => smilePropertyData && getPropertyData(smilePropertyData, names.properties), [smilePropertyData, names, getPropertyData]);

    return (
        <div className="overview-container" id="overview">
            <Row justify="space-between">
                <div className="heading">{t("projects.overview")}</div>
                <div>{smileReportDownloadLink && <Tooltip title="Export Report"><a className="download" href={smileReportDownloadLink} target="_blank" rel="noreferrer"><DownloadOutlined /></a></Tooltip>}</div>
            </Row>

            <Space direction="vertical" style={{ width: "100%", marginTop: "10px" }}>
                <Collapse
                    items={[{
                        key: "1",
                        label: t("common.ingredients"),
                        children: (
                            <div className="table-container">
                                <Table bordered={false} pagination={false} dataSource={ingredientsDataSource} columns={getIngredientsColumns()} scroll={{ x: "max-content" }} />
                            </div>
                        )
                    }]}
                />
                <Collapse
                    items={[{
                        key: "1",
                        label: t("common.properties"),
                        children: (
                            <div className="table-container">
                                <Table bordered={false} pagination={false} dataSource={propertyDataSource} columns={getPropertyColumns()} scroll={{ x: "max-content" }} />
                            </div>
                        )
                    }]}
                />
            </Space>
        </div>
    )
}

export default SmileOverview;