import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { useHighChartRedraw } from "src/utils/hooks";
import {
  Checkbox,
  Col,
  Collapse,
  Form,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { ExplainableAIViewData } from "../ExplainableAI/ExplainableAIViewData";
import { useQuery } from "src/utils/useQuery";
import { getCustomModelDataRequest } from "src/store/actions/modelAnalysis";
import { useValue } from "src/utils/useValue";
import { antdTheme } from "src/constants";
const { Title, Text } = Typography;
const { Panel } = Collapse;

HighchartsMore(Highcharts);
HighchartsHeatmap(Highcharts);

const CustomTooltip = ({ point, getEUValue, t }: any) => {
  return `<div>
      <p>${t("aiEngine.modelAnalysis.percentofFormulations")}: ${getEUValue(point.y)}</p>
    </div>`;
};

const barOverallPercentageOptions = (
  percentage: any,
  type: any,
  data: any,
  setChartType: Function,
  setFormulationsModalOpen: Function,
  setFormulations: Function,
  setSelectedPercentages: Function,
  setSelectedFormulations: Function,
  getEUValue: any,
  t: any
): Highcharts.Options => {
  const xCategories =
    ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"];
  return {
    chart: {
      type: "column",
      zoomType: "xy",
      height: "60%",
      reflow: true,
    },
    credits: { enabled: false },
    legend: { enabled: false },
    xAxis: {
      gridLineWidth: 0,
      categories: xCategories,
      title: {
        text: t("aiEngine.modelAnalysis.percentofModels"),
        style: { fontSize: `${antdTheme.fontSizeSM}` },
      },
      labels: {
        style: { fontSize: `${antdTheme.fontSizeSM}` },
      },
    },
    yAxis: {
      gridLineWidth: 0,
      min: 0,
      max: 100,
      tickInterval: 10,
      title: {
        text: t("aiEngine.modelAnalysis.percentofFormulations"),
        style: { fontSize: `${antdTheme.fontSizeSM}` },
      },
      labels: {
        style: { fontSize: `${antdTheme.fontSizeSM}` },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return CustomTooltip({ point: this.point, getEUValue, t });
      },
      hideDelay: 2000,
    },
    title: {
      text: `<b>${t("aiEngine.modelAnalysis.percentofModelsthathavecorrectlyPredicted")}</b>`,
      align: "center",
      style: { fontSize: `${antdTheme.fontSize}` },
    },
    series: [
      {
        type: "column",
        data: percentage,
        crisp: false,
      },
    ],
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              setChartType("percentage");
              const idx = xCategories.findIndex((cat) => cat === this.category);
              setFormulations(data[idx]);
              setSelectedFormulations(data[idx]);
              setFormulationsModalOpen(true);
              setSelectedPercentages((prevState: any) => [
                ...prevState,
                this.category,
              ]);
            },
          },
        },
      },
    },
  };
};

const barModelPercentageOptions = (
  modelPercentage: any,
  type: any,
  data: any,
  setChartType: Function,
  setFormulationsModalOpen: Function,
  setFormulations: Function,
  setSelectedModels: Function,
  setSelectedFormulations: Function,
  modelNames: any,
  getEUValue: any,
  t: any
): Highcharts.Options => {
  return {
    chart: {
      type: "column",
      zoomType: "xy",
      height: "60%",
      reflow: true,
    },
    credits: { enabled: false },
    legend: { enabled: false },
    xAxis: {
      gridLineWidth: 0,
      categories: modelNames,
      title: {
        text: t("common.models"),
        style: { fontSize: `${antdTheme.fontSizeSM}` },
      },
      labels: {
        style: { fontSize: `${antdTheme.fontSizeSM}` },
      },
    },
    yAxis: {
      gridLineWidth: 0,
      min: 0,
      max: 100,
      tickInterval: 10,
      title: {
        text: t("aiEngine.modelAnalysis.percentofFormulations"),
        style: { fontSize: `${antdTheme.fontSizeSM}` },
      },
      labels: {
        style: { fontSize: `${antdTheme.fontSizeSM}` },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return CustomTooltip({ point: this.point, getEUValue, t });
      },
      hideDelay: 2000,
    },
    title: {
      text: `<b>${t("aiEngine.modelAnalysis.percentofFormulationsthathavecorrectlypredicted")}</b>`,
      align: "center",
      style: { fontSize: `${antdTheme.fontSize}` },
    },
    series: [
      {
        type: "column",
        data: modelPercentage,
        crisp: false,
      },
    ],
    plotOptions: {
      series: {
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              setChartType("modelPercentage");
              const idx = modelNames.findIndex(
                (cat: any) => cat === this.category,
              );
              setFormulations(data[idx]);
              setSelectedFormulations(data[idx]);
              setFormulationsModalOpen(true);
              setSelectedModels((prevState: any) => [
                ...prevState,
                this.category,
              ]);
            },
          },
        },
      },
    },
  };
};

export default function PerformancePercentageWrapper({
  percentage,
  data,
  modelPercentage,
  modelPercentageData,
  modelNames,
  type,
  currentSelectedStage
}: any) {
  const { ref } = useHighChartRedraw();
  const [t] = useTranslate();
  const percentageList = useMemo(
    () => ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
    [],
  );

  const { getValue: getEUValue } = useValue()

  const [formulationsModalOpen, setFormulationsModalOpen] = useState(false);
  const [formulations, setFormulations] = useState<string[]>([]);
  const [selectedFormulations, setSelectedFormulations] = useState<string[]>(
    [],
  );
  const [selectedPercentages, setSelectedPercentages] = useState<string[]>([]);
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [chartType, setChartType] = useState<string>("");

  const reverseMappingPercentage = useCallback(
    (val: string) => percentageList.findIndex((ele: any) => ele === val),
    [percentageList],
  );

  const reverseMappingModels = useCallback(
    (val: string) => modelNames.findIndex((ele: any) => ele === val),
    [modelNames],
  );

  const handleChange = (formulations: string[]) => {
    setSelectedFormulations(formulations);
  };

  const handleChangePerc = (percs: any[]) => {
    setSelectedPercentages(percs);
  };

  const handleChangeModels = (percs: any[]) => {
    setSelectedModels(percs);
  };

  useEffect(() => {
    let nextFormulations: any[] = [];

    for (let perc of selectedPercentages) {
      const distinctFormulations = new Set([
        ...nextFormulations,
        ...data[reverseMappingPercentage(perc)],
      ]);
      nextFormulations = Array.from(distinctFormulations);
    }
    setFormulations(nextFormulations);
    if (selectedPercentages.length === 0) {
      setSelectedFormulations([]);
    }
  }, [selectedPercentages, data, reverseMappingPercentage]);

  useEffect(() => {
    let nextFormulations: any[] = [];

    for (let model of selectedModels) {
      const distinctFormulations = new Set([
        ...nextFormulations,
        ...modelPercentageData[reverseMappingModels(model)],
      ]);
      nextFormulations = Array.from(distinctFormulations);
    }
    setFormulations(nextFormulations);
    if (selectedModels.length === 0) {
      setSelectedFormulations([]);
    }
  }, [selectedModels, modelPercentageData, reverseMappingModels]);

  const { customModelDataFormulations, modelData } = useSelector((state: StoreState) => state.modelAnalysis);

  const query = useQuery()
  const dispatch = useDispatch()
  const [version, setVersion] = useState(Number(query.get('version')))

  useEffect(() => {
    setVersion(Number(query.get('version')))
  }, [query])

  useEffect(() => {
    setVersion(modelData.version)
  }, [modelData])

  useEffect(() => {
    // Calling filtered formulation only when modal is opened
    if (formulationsModalOpen) {
      dispatch(getCustomModelDataRequest({ version, formulation_ids: selectedFormulations, stage_version: modelData?.stages_meta?.[`Stage ${currentSelectedStage}`]?.version }));
    }
  }, [dispatch, version, selectedFormulations, formulationsModalOpen, modelData?.stages_meta, currentSelectedStage]);

  useEffect(() => {
    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref?.current?.chart?.destroy();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const companyId = useSelector((state: StoreState) => state.login.loginResponse.company_id)

  return (
    <>
      <div style={{ display: "flex", gap: 10, width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0,1fr))",
            gap: 10,
          }}
        >
          {companyId !== "COMPA5SBIP4DTP2022" && <div
            style={{
              border: "1px solid #ccc",
              width: "100%",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={barOverallPercentageOptions(
                percentage,
                type,
                data,
                setChartType,
                setFormulationsModalOpen,
                setFormulations,
                setSelectedPercentages,
                setSelectedFormulations,
                getEUValue,
                t
              )}
              ref={ref}
            />
          </div>}
          <div
            style={{
              border: "1px solid #ccc",
              width: "100%",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={barModelPercentageOptions(
                modelPercentage,
                type,
                modelPercentageData,
                setChartType,
                setFormulationsModalOpen,
                setFormulations,
                setSelectedModels,
                setSelectedFormulations,
                modelNames,
                getEUValue,
                t
              )}
              ref={ref}
            />
          </div>
        </div>
      </div>
      <Modal
        title={<Title level={3}>{"Formulations Info"}</Title>}
        centered
        open={formulationsModalOpen}
        onCancel={(e) => {
          e.stopPropagation();
          setFormulationsModalOpen(false);
          setSelectedFormulations([]);
          setSelectedPercentages([]);
          setSelectedModels([]);
          // Reset the XAI to non-filterted state
          dispatch(getCustomModelDataRequest({ version, stage_version: modelData?.stages_meta?.[`Stage ${currentSelectedStage}`]?.version }));
        }}
        footer={null}
        closable
        keyboard
        destroyOnClose
        width={"80%"}
        bodyStyle={{ maxHeight: "70%", height: "70%" }}
      >
        <Row>
          <Col span={24}>
            <Form>
              {chartType === "percentage" && (
                <Form.Item
                  label={"Percentage of Models"}
                  style={{ width: "275px" }}
                >
                  <Select
                    placeholder={"Select %"}
                    showSearch
                    style={{ minWidth: 275, maxWidth: 275 }}
                    value={selectedPercentages}
                    mode="multiple"
                    optionFilterProp={"children"}
                    onChange={handleChangePerc}
                    maxTagCount={"responsive"}
                    dropdownRender={(menu) => {
                      return (
                        <div>
                          {percentageList.length > 0 && (
                            <Checkbox
                              style={{ padding: 10 }}
                              checked={
                                selectedPercentages.length ===
                                percentageList.length
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedPercentages(percentageList);
                                } else {
                                  setSelectedPercentages([]);
                                }
                              }}
                            >{`${t(
                              "common.selectAll",
                            )} ${"percentage"}`}</Checkbox>
                          )}
                          {menu}
                        </div>
                      );
                    }}
                  >
                    {percentageList.map((perc: any) => (
                      <Select.Option value={perc} key={perc}>
                        {perc}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {chartType === "modelPercentage" && (
                <Form.Item label={"Models"} style={{ width: "275px" }}>
                  <Select
                    placeholder={"Select Models"}
                    showSearch
                    style={{ minWidth: 275, maxWidth: 275 }}
                    value={selectedModels}
                    mode="multiple"
                    optionFilterProp={"children"}
                    onChange={handleChangeModels}
                    maxTagCount={"responsive"}
                    dropdownRender={(menu) => {
                      return (
                        <div>
                          {modelNames.length > 0 && (
                            <Checkbox
                              style={{ padding: 10 }}
                              checked={
                                selectedModels.length === modelNames.length
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedModels(modelNames);
                                } else {
                                  setSelectedModels([]);
                                }
                              }}
                            >{`${t(
                              "common.selectAll",
                            )} ${"models"}`}</Checkbox>
                          )}
                          {menu}
                        </div>
                      );
                    }}
                  >
                    {modelNames.map((model: any) => (
                      <Select.Option value={model} key={model}>
                        {model}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item label={"Formulations"} style={{ width: "275px" }}>
                <Select
                  placeholder={"Select Formulations"}
                  showSearch
                  style={{ minWidth: 275, maxWidth: 275 }}
                  value={selectedFormulations}
                  mode="multiple"
                  optionFilterProp={"children"}
                  onChange={handleChange}
                  maxTagCount={"responsive"}
                  dropdownRender={(menu) => {
                    return (
                      <div>
                        {formulations.length > 0 && (
                          <Checkbox
                            style={{ padding: 10 }}
                            checked={
                              selectedFormulations.length ===
                              formulations.length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedFormulations(formulations);
                              } else {
                                setSelectedFormulations([]);
                              }
                            }}
                          >{`${t("common.selectAll")} ${t(
                            "common.formulations",
                          )}`}</Checkbox>
                        )}
                        {menu}
                      </div>
                    );
                  }}
                >
                  {formulations.length > 0 &&
                    Object.keys(customModelDataFormulations).length > 0 &&
                    formulations.map((form: any) => (
                      <Select.Option value={form} key={form}>
                        {
                          customModelDataFormulations[form]
                        }
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <Collapse accordion defaultActiveKey={"1"}>
                <Panel
                  header={<Text strong>{t("models.viewData")}</Text>}
                  key="1"
                >
                  <ExplainableAIViewData selectedFormulations={selectedFormulations} version={version} />
                </Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
