import { useEffect } from "react";
import { List, Avatar, Space, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { projectActivityRequest } from "src/store/actions/projects";
import { StoreState } from "src/store/configureStore";
import { useMemberName } from "src/utils/useMemberName";
import { AsyncStates } from "src/constants";
import dayjs from "dayjs";
import { ExportOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import useTranslate from "src/utils/useTranslate";
import { StyledCard } from "src/styled_components/StyledCard";
import { StyledButton } from "src/styled_components/StyledButton";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const { Text } = Typography;

export const Activity = ({ currentProject }: { currentProject: any }) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { getName } = useMemberName();
  const { push } = useHistory();

  const { project_type } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );
  const { activityStatus, activityData } = useSelector(
    (state: StoreState) => state.projects
  );
  const currentLanguage = useSelector(
    (state: StoreState) => state.language.current
  );

  useEffect(() => {
    dispatch(projectActivityRequest({ project_id: currentProject }));
  }, [dispatch, currentProject, project_type]);

  const onActivityLinkOpen = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    link: { label: string; url: string }
  ) => {
    const { url } = link;
    dispatch(resetWorkOrder());
    push(`/work-orders/details/${url}/`);
  };

  return (
    <StyledCard title={t("projects.activity")}>
      <List
        itemLayout="horizontal"
        dataSource={activityData}
        loading={{
          spinning: activityStatus === AsyncStates.LOADING,
          indicator: <LoadingOutlined />,
        }}
        pagination={{ pageSize: 5, showSizeChanger: false }}
        renderItem={(item: any) => (
          <List.Item key={item.activity_id}>
            <List.Item.Meta
              avatar={<Avatar>{getName(item.created_by)[0]} </Avatar>}
              title={
                <Space direction="horizontal">
                  <Text>{getName(item.created_by)}</Text>
                </Space>
              }
              description={
                <Space direction="vertical">
                  {item.description[`${currentLanguage}`] ||
                    item.description.en}
                  <Space>
                    {dayjs(item.created).fromNow()}
                    {(item.collection === "work_orders" ||
                      item.collection === "userComments" ||
                      item.collection === "workOrderReports") && (
                      <StyledButton
                        type="link"
                        icon={<ExportOutlined />}
                        onClick={(e) => onActivityLinkOpen(e, item.link)}
                      >
                        {t("projects.open")}
                      </StyledButton>
                    )}
                  </Space>
                </Space>
              }
            />
          </List.Item>
        )}
      />
    </StyledCard>
  );
};
