import { orange } from '@ant-design/colors'
import { ExclamationCircleOutlined, InfoCircleOutlined, LoadingOutlined, UserAddOutlined } from '@ant-design/icons'
import { Modal, Row, Select, Space, Table, Typography, message, Popconfirm, Spin, Tooltip, Drawer } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates, permissions, projectStatus } from 'src/constants'
import { setIsEditing } from 'src/store/actions/isEditing'
import { updateProjectRequest } from 'src/store/actions/projects'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { displayTextPermissions } from 'src/utils/decorator'
import { usePermission } from 'src/utils/usePermissions'
import useTranslate from 'src/utils/useTranslate'
import './EditMembersTable.scss'

const { Text } = Typography
const { Option } = Select


export const EditMembersTable = ({ isAddMemberModalVisible, setIsAddMemberModalVisible, members, setMembers, project, from }: any) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const { updateProjectStatus, projectList, editProjectId } = useSelector((state: StoreState) => state.projects)
  const editprojectDetails = useMemo(() => projectList.find((res: any) => res.project_id === editProjectId), [projectList, editProjectId])
  const user_id = useSelector((state: StoreState) => state.login.loginResponse.user_id)
  const teamsList = useSelector((state: StoreState) => state.teams?.data)
  const [selectedMembers, setSelectedMembers] = useState<any[]>([])
  const isEditing = useSelector((state: StoreState) => state.isEditing)
  const workOrderStages = useSelector((state: StoreState) => state.displayNames.data?.stages)

  const userAccess = usePermission(project?.project_id)
  const disabled = useMemo(() => (userAccess?.permission !== permissions.projectAdmin || userAccess?.status !== projectStatus.in_progress) && !!project?.project_id, [userAccess, project])

  useEffect(() => {
    if (updateProjectStatus === AsyncStates.SUCCESS) {
      setIsAddMemberModalVisible(false)
      dispatch(setIsEditing(false))
    }
  }, [updateProjectStatus, setIsAddMemberModalVisible, dispatch])

  const permissionsMenu = useMemo(() => {
    return [
      {
        label: t("projects.members.projectAdmin"),
        value: "project_admin",
        desc: t("projects.teams.projectAdminsPopover")
      },
      {
        label: t("projects.members.editor"),
        value: "editor",
        desc: t("projects.teams.projectEditorsPopover")
      },
      {
        label: t("projects.members.viewer"),
        value: "viewer",
        desc: t("projects.teams.projectViewersPopover")
      }
    ];
  }, [t]);

  const columns = useMemo(() => [
    {
      dataIndex: "user_id",
      title: t("aiEngine.user"),
      key: "user_id",
      render: (text: any, record: any, index: number) => {
        return (
          <Select
            value={
              teamsList.find((res: any) => res?.user_id === text)
                ?.user_name
            }
            showSearch
            disabled={disabled}
            placeholder={t("projects.members.addProjectMembers")}
            optionFilterProp="children"
            style={{ width: '125px' }}
            filterOption={(input, option: any) => {
              return (
                option.key.toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
                option.title
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
            onChange={(e) => {
              if (!isEditing) {
                dispatch(setIsEditing(true));
              }
              const user = teamsList.find(
                (res: any) => res?.user_id === e
              );
              setMembers((prevState: any) => {
                prevState.forEach((res: any, memberIndex: number) => {
                  if (memberIndex === index) {
                    res.user_id = e;
                    if (user?.role === "admin") {
                      res.permission = "project_admin";
                      res.stages = Object.keys(workOrderStages);
                    }
                  }
                });
                return [...prevState];
              });
            }}
          >
            {teamsList
              .filter(
                (res: any) =>
                  (project?.created_by || user_id) !== res?.user_id &&
                  !members
                    .map((member: any) => member?.user_id)
                    .includes(res?.user_id)
              )
              .map(({ user_id, user_name, user_email }: any) => (
                <Option
                  key={user_email}
                  title={user_name}
                  value={user_id}
                >
                  {
                    <>
                      {" "}
                      {user_name}
                      <Text type="secondary"> {user_email}</Text>
                    </>
                  }
                </Option>
              ))}
          </Select>
        );
      },
      width:'25%'
    },
    {
      dataIndex: "permission",
      title: t("profile.role"),
      key: "permission",
      render: (text: any, record: any, index: number) => {
        const user = teamsList.find((res: any) => res?.user_id === record?.user_id)
        return (
          <Tooltip
            title={
              user?.role === "admin"
                ? t("projects.members.cannotChangeAccessOfAdmins")
                : ""
            }
          >
            <Select
              style={{ width: "125px" }}
              popupMatchSelectWidth={340}
              disabled={user?.role === "admin" || disabled}
              value={record.permission}
              optionRender={(option) => (
                <Space direction="vertical">
                  <Text>{option.label}</Text>
                  <Text type="secondary" style={{ whiteSpace: "initial" }}>
                    {option.data?.desc}
                  </Text>
                </Space>
              )}
              options={permissionsMenu}
              onChange={(e) => {
                if (!isEditing) {
                  dispatch(setIsEditing(true));
                }
                setMembers((prevState: any) => {
                  prevState.forEach((res: any, memberIndex: any) => {
                    if (memberIndex === index) {
                      res.stages = Object.keys(workOrderStages);
                      res.permission = e;
                    }
                  });
                  return [...prevState];
                });
              }}
            />
          </Tooltip>
        );
      },
      width:'25%'
    },
    {
      dataIndex: "stages",
      title: <Space><Text>{t("common.stages")}</Text><Tooltip title={t("projects.members.willBeAbleToAccessNote")}><InfoCircleOutlined /></Tooltip> </Space>,
      key: "stages",
      render: (text: any, record: any, index: number) => {
        const user = teamsList.find((res: any) => res?.user_id === record?.user_id)
        return <Tooltip title={user?.role === "admin" ? t("projects.members.cannotChangeAccessOfAdmins") : ""}>
          <Select
            style={{ width: 225 }}
            disabled={record?.permission === "project_admin" || disabled}
            mode="tags"
            value={record?.stages || []}
            maxTagCount={1}
            onChange={(e) => {
              if (!isEditing) {
                dispatch(setIsEditing(true))
              }
              setMembers((prevState: any) => {
                prevState.forEach((res: any, memberIndex: any) => {
                  if (memberIndex === index) {
                    res.stages = e
                  }
                })
                return [...prevState]
              })
            }}>
            {Object.entries(workOrderStages || {}).map(([key, value]: any) => (
              <Option value={key} key={key} disabled={key === "work_order_initiated"} >
                {value}
              </Option>
            ))}
          </Select>
        </Tooltip>
      },
      width:'40%'
    }
  ], [members, project, setMembers, teamsList, user_id, dispatch, isEditing, workOrderStages, disabled, t, permissionsMenu])

  const closeModal = () => {
    setMembers(editprojectDetails?.members || [])
    setSelectedMembers([])
    setIsAddMemberModalVisible(false)
    dispatch(setIsEditing(false))
  }

  const handleCloseModal = () => {
    if (isEditing) {
      Modal.confirm({
        title: `${t("common.unsavedChangesPresent")}`,
        icon: <ExclamationCircleOutlined />,
        okText: `${t("common.ok")}`,
        onOk: () => {
          closeModal()
        }
      })
    } else {
      closeModal()
    }
  }


  return (
    <Drawer
      title={editprojectDetails?.name ??
        project?.name ??
        `${t("common.project")}`}
      extra={isEditing && (
        <Typography.Text style={{ color: orange.primary }}>
          {t("common.unsavedChanges")}
        </Typography.Text>
      )}
      open={isAddMemberModalVisible}
      onClose={() => {
        handleCloseModal();
      }}
      width={'50%'}
      styles={{
        body:{
          padding: '0.75rem 1.5rem'
        }
      }}
    >
      <Spin
        spinning={updateProjectStatus === AsyncStates.LOADING}
        indicator={<LoadingOutlined />}
      >
        <Space
          direction="vertical"
          style={{ width: "100%", marginTop: 5 }}
        >
          {!!project?.project_id && displayTextPermissions(userAccess)}
          <Row justify={"space-between"} style={{ display: "flex", alignItems: "center" }}>
            <Text strong>{`${t("common.members")} (${members?.length})`}</Text>
            <Space>
                <StyledButton icon={<UserAddOutlined />} disabled={teamsList?.length - 1 === members.length || disabled} onClick={() => {
                setMembers((prevState: any) => {
                  return [...prevState, { user_id: "", permission: "", stages: [] }]
                })
                dispatch(setIsEditing(true))
                }}>{t("teams.addMember")}</StyledButton>

              <Popconfirm
                title={t("common.removeSelectedMembers")}
                onConfirm={() => {
                  setMembers((prevState: any, index: number) => {
                    return prevState.filter(
                      (res: any, memberIndex: any) =>
                        !selectedMembers.includes(memberIndex)
                    );
                  });
                  setSelectedMembers([]);
                }}
              >
                  <StyledButton
                    disabled={!selectedMembers.length || disabled}
                    danger
                  >
                    {t("common.removeMembers")}
                  </StyledButton>
              </Popconfirm>
            </Space>
          </Row>
          <Table
            scroll={{ y: 480 }}
            className='teams-projects-edit-members'
            title={() => (
              <Text strong type="secondary" italic>
                {t("projects.members.adminAcessNote")}
              </Text>
            )}
            columns={columns}
            dataSource={members.map((res: any, index: number) => ({
              ...res,
              key: index,
            }))}
            pagination={false}
            rowSelection={{
              selectedRowKeys: selectedMembers,
              onChange: (key: any, row: any) => {
                setSelectedMembers(row.map((res: any) => res.key));
              },
            }}
          />
          <Row justify="end">
            <StyledButton
              disabled={disabled || !isEditing}
              type="primary" onClick={() => {
                if (members.some((res: any) => !res?.user_id || !res?.permission || !res?.stages?.length)) {
                  message.error("Add user and permissions for all the rows")
                  return
                }
                if (!!project?.project_id) {
                  dispatch(updateProjectRequest({
                    project_id: project?.project_id, members, start_date: project?.start_date,
                    close_date: project?.close_date, customer: project?.customer, additional_fields: project?.additional_fields, from
                  }))
                } else {
                  setIsAddMemberModalVisible(false)
                }
              }}>{!!project?.project_id ? t("common.save") : t("common.confirm")}</StyledButton>
          </Row>
        </Space>
      </Spin>
    </Drawer>
  );
}