import React, { useState, useRef, useContext, useEffect } from "react"
import { Input, Form, InputNumber } from "antd"
import { useDrag, useDrop } from "react-dnd"
import { useValue } from "src/utils/useValue"

const EditableContext = React.createContext(null)

export const EditableRow = ({
	index,
	moveRow,
	className,
	style,
	...props
}: any) => {
	const [form]: any = Form.useForm()
	const ref = useRef()
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: "DraggableBodyRow",
		collect: (monitor) => {
			const { index: dragIndex }: any = monitor.getItem() || {}
			if (dragIndex === index) {
				return {}
			}
			return {
				isOver: monitor.isOver(),
				dropClassName:
					dragIndex < index ? " drop-over-downward" : " drop-over-upward",
			}
		},
		drop: (item: any) => {
			moveRow(item.index, index)
		},
	})
	const [, drag] = useDrag({
		type: "DraggableBodyRow",
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})
	drop(drag(ref))

	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr
					ref={ref}
					className={`${className}${isOver ? dropClassName : ""}`}
					style={{ cursor: "move", ...style }}
					{...props}
				/>
			</EditableContext.Provider>
		</Form>
	)
}

export const EditableCell = ({
	title,
	editable,
	children,
	dataIndex,
	inputType,
	record,
	handleSave,
	propertyType,
	...restProps
}: any) => {
	const [editing, setEditing] = useState(false)
	const inputRef: any = useRef(null)
	const form: any = useContext(EditableContext)
	const { parser, formatter } = useValue()
	useEffect(() => {
		if (editing) {
			inputRef.current.focus()
		}
	}, [editing])

	const toggleEdit = () => {
		setEditing(!editing)
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		})
	}

	const save = async () => {
		try {
			const values = await form.validateFields()
			// if (Object.values(values)[0]) {
			toggleEdit()
			handleSave({ ...record, ...values })
			// }
			// else {
			// 	message.error(t("editableCell.errorMessage.enterValidValue"))
			// 	toggleEdit()
			// 	handleSave(record)
			// }
		} catch (errInfo) {
			console.log("Save failed:", errInfo)
		}
	}

	const inputNode =
		inputType === "number" ? (
			<InputNumber
				parser={parser}
				formatter={formatter}
				ref={inputRef}
				min={record.factorType === "ingredients" ? 0 : -1000}
				onPressEnter={save}
				onBlur={save}
			/>
		) : (
			<Input ref={inputRef} onPressEnter={save} onBlur={save} />
		)
	let childNode = children
	if (editable && !(dataIndex === "center" && record.level === 2)) {
		childNode = editing ? (
			<Form.Item style={{ margin: 0 }} name={dataIndex}>
				{inputNode}
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{ paddingRight: 24 }}
				onClick={toggleEdit}
			>
				{children}
			</div>
		)
	}
	return <td {...restProps}>{childNode}</td>
}
