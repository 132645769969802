import { blue } from "@ant-design/colors"
import {
	Form,
	Space,
	Upload,
	Typography,
	message,
	Row,
	Tooltip,
} from "antd"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fileUploadtemplateUrl, permissions, projectStatus } from "src/constants"
import { fileHistoryRequest } from "src/store/actions/files"
import { workOrdersRequest } from "src/store/actions/workOrders"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import { StyledCard } from "src/styled_components/StyledCard"
import { usePermission } from "src/utils/usePermissions"
import useTranslate from "src/utils/useTranslate"


const { Text } = Typography
const { Dragger } = Upload

export const DataUpload = ({ callbacks, data }: any) => {
	const [t] = useTranslate()
	const dispatch = useDispatch()

	const { file } = data
	const { setFile, setCurrent, setUnsaved } = callbacks

	const { data: historyData } = useSelector(
		(state: StoreState) => state.files.fileHistory
	)
	const { current: project_id } = useSelector(
		(state: StoreState) => state.projects
	)
	const userAccess = usePermission()

	useEffect(() => {
		dispatch(fileHistoryRequest({ project_id }))
		dispatch(workOrdersRequest({ project_id }))
	}, [project_id, dispatch])

	const handleFileUpload = async () => {
		setUnsaved(true)
		setCurrent(1)
	}

	const uploadProps = {
		onRemove: (record: any) => {
			setFile()
		},
		beforeUpload: (record: any) => {
			if (record.name.split(".").pop() === "xlsx") {
				setFile(record)
			} else message.error(t("dataUpload.selectedFileDesc"))
			return false
		},
		multiple: false,
		fileList: !!file ? [file] : [],
	}


	return (
		<Space direction="vertical" style={{ width: "100%" }} size="large">
			<Form
				onFinish={handleFileUpload}
				layout="vertical"
				onChange={() => setUnsaved(true)}
				disabled={userAccess.permission === permissions.viewer || userAccess?.status !== projectStatus.in_progress}
			>
				<StyledCard
					title={
						<Space
							direction="vertical"
							style={{ width: "100%" }}
							size="small"
						>
							<Text type="secondary">
								{t("dataUpload.importExperimentData")}
							</Text>
						</Space>
					}
				>
					<Space direction="vertical" style={{ width: "100%" }} size="large">
						<Form.Item>
							<Dragger {...uploadProps}>
								<p
									style={{ paddingTop: 30, paddingBottom: 30 }}
									className="ant-upload-text"
								>
									{t("dataUpload.dragnDrop")}
									<span style={{ color: blue[5] }}>
										{" "}
										{t("dataUpload.selectFile")}
									</span>
								</p>
							</Dragger>
						</Form.Item>
						<Form.Item>
							<Text>
								{t("datamapper.needHelp?")}{" "}
								<a
									target={"_blank"}
									rel="noreferrer"
									href={fileUploadtemplateUrl}
								>
									{t("datamapper.downloadTemplate?")}{" "}
								</a>
								{t("datamapper.spreadsheet?")}
							</Text>
						</Form.Item>
						{!!historyData.find((res: any) => res.file_name === file?.name)
							?.file_id ? (
							<Space>
								<Text strong>{`${t("workOrderDetails.note")} :`}</Text>
								<Text strong type="danger">{`${file?.name} ${t(
									"common.alreadyUploadedInThisProject"
								)}`}</Text>
							</Space>
						) : (
							""
						)}
						<Row justify="end">
							<Tooltip
								title={!file ? t("common.uploadAFile") : t("dataMapper.mapsheets")}
							>
								<StyledButton
									htmlType="submit"
									disabled={!file}
									size="large"
								>{`${t("common.next")}: ${t("common.mapSheets")}`}</StyledButton>
							</Tooltip>
						</Row>
					</Space>
				</StyledCard>
			</Form>
		</Space>
	)
}