import { InboxOutlined, LoadingOutlined } from "@ant-design/icons"
import {
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Upload,
  Typography,
  Card,
  Tabs,
  Radio,
  Spin,
} from "antd";
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates, sendCredsHostnames } from "src/constants"
import { celsureFileHistoryRequest, celsureFileUploadRequest, celsureGetPlotsRequest } from "src/store/actions/celsureWo"
import { StoreState } from "src/store/configureStore"
import { useMemberName } from "src/utils/useMemberName"
import { useMesssage } from "src/utils/hooks"
import workerUrl from "src/workers/service"
import "src/components/Datasheet/Pluss/FileUpload.scss"
import { setIsEditing } from "src/store/actions/isEditing"
import { CustomPrompt } from "src/utils/CustomPrompts"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import jwtManager from "src/utils/jwtManager"
import axios from "axios"

const { Dragger } = Upload
const { Text } = Typography


export const FileUpload = () => {
  const dispatch = useDispatch()
  const { getName } = useMemberName()
  const [t] = useTranslate()
  const { m } = useMesssage()

  const { celsureWo, celsureWoTrials, celsureFileHistoryData, celsureFileUploadStatus,
    ceslureFileHistoryStatus } = useSelector((state: StoreState) => state.celsureWo)

  const isEditing = useSelector((state: StoreState) => state.isEditing)
  const [fileList, setFileList] = useState<any[]>([])
  const [dataList, setDataList] = useState([] as any[])
  const [errorRowCount, setErrorRowCount] = useState([] as any[])
  const [missingTrials, setMissingTrials] = useState([] as any[])
  const [isProcessing, setIsProcessing] = useState(false)
  const [tab, setTab] = useState("actual")
  const [type, setType] = useState<any>("actual")
  const [fileHistoryTable, setFileHistoryTable] = useState<any>([])

  useEffect(() => {
    if (ceslureFileHistoryStatus === AsyncStates.SUCCESS) {
      const tableData = celsureFileHistoryData.filter((res: any) => res?.[tab] && JSON.stringify(res?.[tab]) !== '{}').map((res: any) => {
        return { ...res?.[tab], trial_display_id: res.trial_display_id }
      })
      setFileHistoryTable(tableData)
    }
  }, [celsureFileHistoryData, ceslureFileHistoryStatus, tab])

  useEffect(() => {
    if (!!celsureWo?.plots_data_uploaded) {
      dispatch(celsureFileHistoryRequest({ work_order_id: celsureWo?.work_order_id }))
    }
  }, [dispatch, celsureWo?.work_order_id, celsureWo?.plots_data_uploaded])

  const worker = useMemo(() => new Worker(workerUrl), [])

  worker.addEventListener("message", (e) => {
    const [newDataList, newErrorCount, newMissingTrials] = e.data
    setMissingTrials(newMissingTrials)
    setErrorRowCount(newErrorCount)
    setDataList(newDataList)
    setIsProcessing(false)
  })

  worker.addEventListener("error", () => {
    setIsProcessing(false)
    message.error(m('internal_server_error'))
  })

  useEffect(() => {
    !!fileList.length && (async () => {
      setIsProcessing(true)
      dispatch(setIsEditing(true))
      const newFileList = await Promise.all(fileList.map((file: any) => file.arrayBuffer()))
      worker.postMessage([newFileList, celsureWoTrials.map((res: any) => res.trial_display_id), "celsure"])
    })()
  }, [fileList, celsureWoTrials, worker, dispatch])

  const uploadProps = {
    onRemove: (file: any) => {
      setFileList((prevState: any) => {
        const index = prevState.indexOf(file)
        const newFileList = prevState.slice()
        newFileList.splice(index, 1)
        return newFileList
      })
      setDataList([])
      setErrorRowCount([])
      setMissingTrials([])
    },
    beforeUpload: (file: any) => {
      if (file.name.split(".").pop() === "xlsx") {
        setFileList((prevState: any) => {
          if (fileList.map((fileRecord: any) => fileRecord.name).includes(file.name)) {
            message.error("File already uploaded")
            return prevState
          } else {
            return [...prevState, file]
          }
        })
      } else message.error(t("dataUpload.selectedFileDesc"))
      return false
    },
    fileList,
    multiple: true
  }

  const uploadGraphFiles = () => {
    const formData = new FormData()
    fileList.forEach((file: any) => {
      formData.append('files', file)
    })
    formData.append('work_order_id', celsureWo.work_order_id)
    formData.append('type', type)
    formData.append('data', JSON.stringify(dataList))
    dispatch(celsureFileUploadRequest(formData))
    dispatch(setIsEditing(false))
  }

  useEffect(() => {
    if (celsureFileUploadStatus === AsyncStates.SUCCESS) {
      setFileList([])
      setDataList([])
      setErrorRowCount([])
      setMissingTrials([])
    }
  }, [celsureFileUploadStatus])

  const graphsHistoryColumns: any = [
    {
      title: "Trial",
      dataIndex: "trial_display_id",
      key: "trial_display_id",
      align: "center"
    },
    {
      title: t("woReportPreview.fileName"),
      dataIndex: "file_name",
      key: "file_name",
      align: "center"
    },
    {
      title: "Uploaded on",
      dataIndex: "updated",
      key: "updated",
      render: (text: any, record: any) => new Date(text).toLocaleString(),
      align: "center"
    },
    {
      title: t("history.uploadedBy"),
      dataIndex: "user_id",
      key: "user_id",
      render: (text: any, record: any) => getName(text),
      align: "center"
    },
    {
      title: "Download Link",
      dataIndex: "download_link",
      key: "download_link",
      render: (text: any, record: any) => <a href={text} target="_blank" rel="noreferrer">{"Link"}</a>,
      align: "center"
    },
    {
      key: "action",
      dataIndex: "file_id",
      title: t("common.actions"),
      fixed: "right",
      render: (value: any, row: any, index: any) => (
        <Space>
          <Popconfirm
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            title={t("plus.workorder.filehistory.delete")}
            onConfirm={() => {
              axios.post<any>(process.env.REACT_APP_API_URL + 'v1/data/_celsure_graph_files_delete', {
                file_id: value,
                type: tab
              }, {
                headers: { 'token': `${jwtManager.getToken()}` },
                ...(sendCredsHostnames.includes(window.location.hostname) && { withCredentials: true })
              }).then((response) => {
                if (response?.data?.result?.status === "Success") {
                  dispatch(celsureGetPlotsRequest(({ work_order_id: celsureWo?.work_order_id })))
                  dispatch(celsureFileHistoryRequest({ work_order_id: celsureWo?.work_order_id }))
                } else {
                  message.info(response?.data?.result?.message);
                }
              }).catch((error) => {
                message.info(error);
              })
            }
            }
          >
            <StyledButton type="primary">{t("common.delete")}</StyledButton>
          </Popconfirm>
        </Space>
      ),
    },
  ]


  return (
    <Spin spinning={celsureFileUploadStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
      <Card title="File Upload">
        <CustomPrompt isEditing={isEditing} message={`${t("common.unsavedChangesLost")}!.`} />
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
            <Radio value={"actual"}>{"Actual Experiment"}</Radio>
            <Radio value={"theoretical"}>{"Simulation"}</Radio>
          </Radio.Group>
          <Dragger {...uploadProps} disabled={isProcessing}>
            {isProcessing ? (<>
              <p className="ant-upload-drag-icon">
                <LoadingOutlined />
              </p>
              <p className="ant-upload-text">Generating Preview...</p>
            </>) : (<>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t("fileupload.upload")}</p>
              <p className="ant-upload-hint">
                {!celsureWo.plots_data_uploaded ? "Upload the files for the Trials , only Files with .xlsx supported" : "Update Data, only Files with .xlsx supported"}
              </p>
            </>)}
          </Dragger>
          {Boolean(dataList.length) &&
            <Card title="File Preview">
              {dataList.map((data, index) => (
                <Space size="middle" direction="vertical" style={{ width: "100%" }}>
                  <Table
                    bordered
                    key={fileList[index]?.uid}
                    title={() => fileList[index]?.name}
                    dataSource={data}
                    columns={[...new Set(data.reduce((array: any, record: any) => [...array, ...Object.keys(record)], []))].map((col: any) => ({ title: col, dataIndex: col, key: col }))}
                    scroll={{ y: 400 }} pagination={false}
                    rowClassName={(record) => {
                      const hasMissingValue = Object.values(record).some((val: any) => isNaN(val))
                      return (hasMissingValue && Boolean(errorRowCount[index])) ? 'error' : 'row'
                    }}
                  />
                  {Boolean(missingTrials[index]?.length) && <Text type="danger">{t("fileUpload.matchingTrialsNotFound")} : <b>{missingTrials[index]?.join(', ')}</b></Text>}
                  {Boolean(errorRowCount[index]) && <Row justify="space-between">
                    <Text type="danger">Number of rows with invalid values : <b>{errorRowCount[index]}</b></Text>
                    <Popconfirm onConfirm={() => {
                      setErrorRowCount((prevState: any) => {
                        const newState = [...prevState]
                        newState[index] = 0
                        return newState
                      })
                    }} title={"Remove invalid value from the trials while uploading the file ?"}>
                      <StyledButton type="primary">{"Remove Invalid data"}</StyledButton>
                    </Popconfirm>
                  </Row>}
                </Space>))}
            </Card>}
          <Row>
            <Tooltip title={fileList?.length > celsureWoTrials?.length ? "Number of uploaded files are greater than the number of trials in the datasheet" : "Upload data"}>
              <StyledButton
                type="primary"
                onClick={uploadGraphFiles}
                disabled={Boolean(errorRowCount.some(val => val > 0)) || Boolean(missingTrials.every(val => val?.length))}
                style={{ marginTop: 16 }}
              >
                {'Start Upload'}
              </StyledButton>
            </Tooltip>
          </Row>
          {!!celsureWo?.plots_data_uploaded && !!celsureFileHistoryData?.length && (
            <Card title='File Upload history'>
              <Tabs onChange={(e) => setTab(e)} >
                <Tabs.TabPane key="actual" tab="Actual Experiment">
                  <Table
                    columns={graphsHistoryColumns}
                    loading={{ spinning: ceslureFileHistoryStatus === AsyncStates.LOADING, indicator: <LoadingOutlined /> }}
                    dataSource={fileHistoryTable} bordered
                  />
                </Tabs.TabPane>
                <Tabs.TabPane key="theoretical" tab="Simulation">
                  <Table
                    columns={graphsHistoryColumns}
                    loading={{ spinning: ceslureFileHistoryStatus === AsyncStates.LOADING, indicator: <LoadingOutlined /> }}
                    dataSource={fileHistoryTable} bordered
                  />
                </Tabs.TabPane>
              </Tabs>
            </Card>
          )}
        </Space>
      </Card>
    </Spin>
  )
}