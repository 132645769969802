import { handleActions } from "redux-actions"
import { BuildOfMaterialsActionTypes, ChangeTitleActionTypes, ExportBuildOfMaterialsActionTypes, ExportPredictionActionTypes, SuggestedExpActionTypes, SuggestedExperimentsFilterActionTypes } from "src/store/actions/suggestedExp"
import { AsyncStates } from "src/constants"

export interface suggestedExpReducerState {
	expIdStatus: AsyncStates
	error: string
	data: any,
	buildOfMaterials: any,
	buildOfMaterialsStatus: AsyncStates,
	buildOfMaterialsError: string,
	suggestedExpDataForFilterData: any
	predictionDataExport: string | null,
	predictionDataExportStatus: AsyncStates
	predictionDataExportError: string
	buildOfMaterialsExport: string | null,
	buildOfMaterialsExportStatus: AsyncStates
	buildOfMaterialsExportError: string
	changeTitleStatus: AsyncStates
	changeTitleResponse: any
}

const defaultState: suggestedExpReducerState = {
	expIdStatus: AsyncStates.INITIAL,
	error: "",
	data: { experiments: [], total: 0, versions: [], inverseMissingProperties: [] },
	buildOfMaterials: { experiments: [], total: 0 },
	buildOfMaterialsStatus: AsyncStates.INITIAL,
	buildOfMaterialsError: '',
	suggestedExpDataForFilterData: [],
	predictionDataExport: null,
	predictionDataExportStatus: AsyncStates.INITIAL,
	predictionDataExportError: "",
	buildOfMaterialsExport: null,
	buildOfMaterialsExportStatus: AsyncStates.INITIAL,
	buildOfMaterialsExportError: "",
	changeTitleStatus: AsyncStates.INITIAL,
	changeTitleResponse: {}
}

const suggestedExpReducer = handleActions(
	{
		[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_REQUEST]: (
			state,
			action
		) => ({
			...state,
			expIdStatus: AsyncStates.LOADING,
			error: "",
			data: { experiments: [], total: 0, versions: [], inverseMissingProperties: [] },
		}),
		[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_SUCCESS]: (
			state,
			action
		) => {
			return {
				...state,
				expIdStatus: AsyncStates.SUCCESS,
				error: "",
				data: action.payload,
			}
		},
		[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_FAILURE]: (
			state,
			action
		) => ({
			...state,
			expIdStatus: AsyncStates.ERROR,
			error: action.payload.error,
			data: { experiments: [], total: 0, versions: [], inverseMissingProperties: [] },
		}),
		[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_CLEAR]: (state) => ({
			...state,
			expIdStatus: AsyncStates.INITIAL,
			error: "",
			data: { experiments: [], total: 0, versions: [], inverseMissingProperties: [] },
		}),

		[BuildOfMaterialsActionTypes.BUILD_OF_MATERIALS_REQUEST]: (
			state,
			action
		) => ({
			...state,
			buildOfMaterialsStatus: AsyncStates.LOADING,
			buildOfMaterialsError: "",
			buildOfMaterials: { experiments: [], total: 0 },
		}),
		[BuildOfMaterialsActionTypes.BUILD_OF_MATERIALS_SUCCESS]: (
			state,
			action
		) => {
			return {
				...state,
				buildOfMaterialsStatus: AsyncStates.SUCCESS,
				buildOfMaterialsError: "",
				buildOfMaterials: action.payload,
			}
		},
		[BuildOfMaterialsActionTypes.BUILD_OF_MATERIALS_FAILURE]: (
			state,
			action
		) => ({
			...state,
			buildOfMaterialsStatus: AsyncStates.ERROR,
			buildOfMaterialsError: action.payload.error,
			buildOfMaterials: { experiments: [], total: 0 },
		}),
		[BuildOfMaterialsActionTypes.BUILD_OF_MATERIALS_CLEAR]: (state) => ({
			...state,
			buildOfMaterialsStatus: AsyncStates.INITIAL,
			buildOfMaterialsError: "",
			buildOfMaterials: { experiments: [], total: 0 },
		}),

		[SuggestedExperimentsFilterActionTypes.SUCCESS]: (
			state,
			action
		) => {
			return {
				...state,
				suggestedExpDataForFilterData: action.payload,
			}
		},
		[SuggestedExperimentsFilterActionTypes.FAILURE]: (
			state,
			action
		) => ({
			...state,
			suggestedExpDataForFilterData: [],
		}),
		[ExportPredictionActionTypes.REQUEST]: (state, action) => ({
			...state,
			predictionDataExportStatus: AsyncStates.LOADING,
			predictionDataExport: null
		}),
		[ExportPredictionActionTypes.SUCCESS]: (state, action: any) => ({
			...state,
			predictionDataExportStatus: AsyncStates.SUCCESS,
			// predictionDataExport: action.payload
		}),
		[ExportPredictionActionTypes.FAILURE]: (state, action) => ({
			...state,
			predictionDataExportStatus: AsyncStates.ERROR,
			predictionDataExportError: action.payload.error
		}),
		[ExportPredictionActionTypes.CLEAR]: (state, action) => ({
			...state,
			predictionDataExportStatus: AsyncStates.INITIAL,
			predictionDataExportError: ""
		}),
		[ExportBuildOfMaterialsActionTypes.REQUEST]: (state, action) => ({
			...state,
			buildOfMaterialsExportStatus: AsyncStates.LOADING,
			buildOfMaterialsExport: null
		}),
		[ExportBuildOfMaterialsActionTypes.SUCCESS]: (state, action: any) => ({
			...state,
			buildOfMaterialsExportStatus: AsyncStates.SUCCESS,
			buildOfMaterialsExport: action.payload
		}),
		[ExportBuildOfMaterialsActionTypes.FAILURE]: (state, action) => ({
			...state,
			buildOfMaterialsExportStatus: AsyncStates.ERROR,
			buildOfMaterialsExportError: action.payload.error
		}),
		[ExportBuildOfMaterialsActionTypes.CLEAR]: (state, action) => ({
			...state,
			buildOfMaterialsExportStatus: AsyncStates.INITIAL,
			buildOfMaterialsExportError: ""
		}),
		[ChangeTitleActionTypes.REQUEST]: (state, action) => ({
			...state,
			changeTitleStatus: AsyncStates.LOADING,
		}),
		[ChangeTitleActionTypes.SUCCESS]: (state, action: any) => ({
			...state,
			changeTitleStatus: AsyncStates.SUCCESS,
			changeTitleResponse: action.payload
		}),
		[ChangeTitleActionTypes.FAILURE]: (state, action) => ({
			...state,
			changeTitleStatus: AsyncStates.ERROR,
			changeTitleResponse: action.payload.error
		}),
		[ChangeTitleActionTypes.CLEAR]: (state, action) => ({
			...state,
			changeTitleStatus: AsyncStates.INITIAL,
			changeTitleResponse: ''
		}),
	},
	defaultState
)

export default suggestedExpReducer
