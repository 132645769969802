import { handleActions } from "redux-actions";
import {
  LoginActionTypes,
  RefreshTokenActionTypes,
  OtpActionTypes,
  SetProjectTypeActionTypes,
  LogoutActionTypes,
  UpdateProfileActionTypes,
  UpdateCurrencyActionTypes,
} from "../actions/login"
import { AsyncStates } from "../../constants"
import {
  LoginResponse,
  CheckUserExists,
} from "../../services/login/interface"
import jwtManager from "src/utils/jwtManager"
import { tokenBroadcast } from "src/App"

export interface FirebaseResponse extends CheckUserExists {
  displayName: string
  isUserExists?: boolean
}

export enum LoginProgress {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  NOT_VERIFIED = "NOT_VERIFIED",
}

export type LoginReducerState = {
  status: AsyncStates
  error: string
  loginResponse: LoginResponse
  isLoginComplete: LoginProgress
  requestOtpStatus: AsyncStates
  requestOtpData: string
  verifyOtpTrueStatus: AsyncStates
  verifyOtpStatus: AsyncStates
  disableOtpStatus: AsyncStates
  otpRequired: boolean
  otpVerificationRequired: boolean
  setProjectTypeStatus?: AsyncStates
  logoutStatus: AsyncStates
  tokenStatus: boolean
  updateProfileStatus?: AsyncStates,
  updateCurrencyStatus: AsyncStates
}

const defaultState: LoginReducerState = {
  isLoginComplete: LoginProgress.NOT_STARTED,
  status: AsyncStates.INITIAL,
  error: "",
  loginResponse: {
    company: "",
    company_id: "",
    company_location: "",
    message: "",
    status: "",
    user_access: "",
    user_email: "",
    user_id: "",
    user_location: "",
    user_loggedin_IP: "",
    user_name: "",
    user_role: "",
    user_trial_days: 0,
    user_verified: false,
    is_masked: false,
    otp: false,
    two_fa: false,
    logo: '',
    login_with_provider: false,
    currency: {
      currency_name: "Singapore (SGD)",
      currency_code: "SGD",
      is_default_value_updated: false
    },
    created: "",
    trial_start_date: "",
    job_title: null,
    subscription_status: "",
    license_period_end: "",
    license_period_start: "",
    user_two_fa: false,
    company_two_fa: false
  },
  requestOtpStatus: AsyncStates.INITIAL,
  requestOtpData: "",
  verifyOtpTrueStatus: AsyncStates.INITIAL,
  verifyOtpStatus: AsyncStates.INITIAL,
  disableOtpStatus: AsyncStates.INITIAL,
  otpRequired: false,
  otpVerificationRequired: false,
  setProjectTypeStatus: AsyncStates.INITIAL,
  logoutStatus: AsyncStates.INITIAL,
  tokenStatus: false,
  updateProfileStatus: AsyncStates.INITIAL,
  updateCurrencyStatus: AsyncStates.INITIAL
}

const loginReducer = handleActions(
  {
    [LoginActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      error: "",
      tokenStatus: false,
    }),
    [LoginActionTypes.NOT_VERIFIED]: (state) => {
      return {
        ...state,
        isLoginComplete: LoginProgress.NOT_VERIFIED,
        status: AsyncStates.SUCCESS,
        error: "",
      }
    },
    [LoginActionTypes.SUCCESS]: (state, action: any) => {
      jwtManager.setToken(action.payload.loginResponse.token)
      tokenBroadcast.postMessage(action.payload.loginResponse.token as any)
      const response = { ...action.payload.loginResponse }
      delete response["token"]
      return {
        ...state,
        isLoginComplete: LoginProgress.COMPLETE,
        status: AsyncStates.SUCCESS,
        error: "",
        logoutStatus: AsyncStates.INITIAL,
        loginResponse: response,
        tokenStatus: true,
      }
    },
    [LoginActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error,
    }),
    [LoginActionTypes.CLEANUP]: (state, action) => ({
      ...state,
      status: AsyncStates.INITIAL,
      error: "",
    }),
    [RefreshTokenActionTypes.REQUEST]: (state, action) => {
      return {
        ...state,
        tokenStatus: false,
      }
    },
    [RefreshTokenActionTypes.SUCCESS]: (state, action): any => {
      jwtManager.setToken(action.payload as any)
      tokenBroadcast.postMessage(action.payload as any)
      return {
        ...state,
        tokenStatus: true,
      }
    },
    [OtpActionTypes.REQUEST_OTP_REQUEST]: (state, action) => {
      return {
        ...state,
        requestOtpStatus: AsyncStates.LOADING,
        requestOtpData: "",
      }
    },
    [OtpActionTypes.REQUEST_OTP_SUCCESS]: (state, action): any => {
      return {
        ...state,
        requestOtpStatus: AsyncStates.SUCCESS,
        requestOtpData: action.payload,
      }
    },
    [OtpActionTypes.REQUEST_OTP_FAILURE]: (state, action) => {
      return {
        ...state,
        requestOtpStatus: AsyncStates.ERROR,
      }
    },

    [OtpActionTypes.VERIFY_OTP_TRUE_REQUEST]: (state, action) => {
      return {
        ...state,
        verifyOtpTrueStatus: AsyncStates.LOADING,
      }
    },
    [OtpActionTypes.VERIFY_OTP_TRUE_SUCCESS]: (state, action): any => {
      return {
        ...state,
        verifyOtpTrueStatus: AsyncStates.SUCCESS,
      }
    },
    [OtpActionTypes.VERIFY_OTP_TRUE_FAILURE]: (state, action) => {
      return {
        ...state,
        verifyOtpTrueStatus: AsyncStates.ERROR,
      }
    },
    [OtpActionTypes.VERIFY_OTP_REQUEST]: (state, action) => {
      return {
        ...state,
        verifyOtpStatus: AsyncStates.LOADING,
        status: AsyncStates.LOADING,
      }
    },
    [OtpActionTypes.VERIFY_OTP_SUCCESS]: (state, action): any => {
      return {
        ...state,
        verifyOtpStatus: AsyncStates.SUCCESS,
      }
    },
    [OtpActionTypes.VERIFY_OTP_FAILURE]: (state, action) => {
      return {
        ...state,
        verifyOtpStatus: AsyncStates.ERROR,
        status: AsyncStates.INITIAL,
      }
    },
    [LoginActionTypes.UPDATE_LOGIN_RESPONSE]: (state, action: any) => {
      return {
        ...state,
        loginResponse: action.payload?.partial
          ? { ...state.loginResponse, user_id: action.payload?.user_id }
          : action.payload,
      }
    },
    [OtpActionTypes.DISABLE_OTP_REQUEST]: (state, action) => {
      return {
        ...state,
        disableOtpStatus: AsyncStates.LOADING,
      }
    },
    [OtpActionTypes.DISABLE_OTP_SUCCESS]: (state, action): any => {
      return {
        ...state,
        disableOtpStatus: AsyncStates.SUCCESS,
      }
    },
    [OtpActionTypes.DISABLE_OTP_FAILURE]: (state, action) => {
      return {
        ...state,
        disableOtpStatus: AsyncStates.ERROR,
      }
    },
    [OtpActionTypes.SET_OTP]: (state, action: any) => {
      return {
        ...state,
        otpRequired: action.payload,
        status: AsyncStates.INITIAL,
      }
    },
    [OtpActionTypes.SET_VERIFICATION]: (state, action: any) => {
      return {
        ...state,
        otpVerificationRequired: action.payload.otpVerificationRequired,
        requestOtpData: action.payload.requestOtpData,
        status: AsyncStates.INITIAL,
      }
    },
    [OtpActionTypes.SET_TWO_FA_STATUS]: (state, action: any) => {
      return {
        ...state,
        loginResponse: { ...state.loginResponse, two_fa: action.payload },
      }
    },
    [OtpActionTypes.CLEAR_OTP_STATE]: (state, action: any) => {
      return {
        ...state,
        requestOtpStatus: AsyncStates.INITIAL,
        requestOtpData: "",
        verifyOtpTrueStatus: AsyncStates.INITIAL,
        verifyOtpStatus: AsyncStates.INITIAL,
        disableOtpStatus: AsyncStates.INITIAL,
        otpRequired: false,
      }
    },
    [SetProjectTypeActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        setProjectTypeStatus: AsyncStates.LOADING,
      }
    },
    [SetProjectTypeActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        setProjectTypeStatus: AsyncStates.SUCCESS,
        loginResponse: {
          ...state.loginResponse,
          project_type: action.payload,
        },
      }
    },
    [SetProjectTypeActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        setProjectTypeStatus: AsyncStates.ERROR,
      }
    },
    [LogoutActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        logoutStatus: AsyncStates.LOADING,
      }
    },
    [LogoutActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        logoutStatus: AsyncStates.SUCCESS,
        tokenStatus: false,
      }
    },
    [LogoutActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        logoutStatus: AsyncStates.ERROR,
      }
    },
    [LoginActionTypes.SET_TOKEN_STATUS]: (state, action: any) => {
      return {
        ...state,
        tokenStatus: action.payload,
      }
    },
    [UpdateProfileActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        updateProfileStatus: AsyncStates.LOADING,
      }
    },
    [UpdateProfileActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateProfileStatus: AsyncStates.SUCCESS,
        loginResponse: {
          ...state.loginResponse,
          user_name: action.payload.user_name,
          user_location: action.payload.user_location,
        },
      }
    },
    [UpdateProfileActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        updateProfileStatus: AsyncStates.ERROR,
      }
    },
    [UpdateCurrencyActionTypes.REQUEST]: (state, action: any) => {
      return {
        ...state,
        updateCurrencyStatus: AsyncStates.LOADING,
      }
    },
    [UpdateCurrencyActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateCurrencyStatus: AsyncStates.SUCCESS,
        loginResponse: {
          ...state.loginResponse,
          currency: action.payload,
        },
      }
    },
    [UpdateCurrencyActionTypes.FAILURE]: (state, action: any) => {
      return {
        ...state,
        updateCurrencyStatus: AsyncStates.ERROR,
      }
    },
  },
  defaultState,
)

export default loginReducer;
