import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import Badge from "../Badge";
import { grey } from "../colors";
import PlusIcon from "../img/Plus";
import { ActionTypes, randomColor } from "../utils";

interface SelectCellProps {
  initialValue: string | null;
  options: {
    label: string;
    backgroundColor: string;
  }[];
  columnId: string;
  rowIndex: number;
  dataDispatch: React.Dispatch<any>;
  allowEditing: boolean;
}

export default function SelectCell({
  initialValue,
  options,
  columnId,
  rowIndex,
  dataDispatch,
  allowEditing,
}: SelectCellProps) {
  const [selectRef, setSelectRef] = useState<null | HTMLDivElement>(null);
  const [selectPop, setSelectPop] = useState<null | HTMLDivElement>(null);
  const [showSelect, setShowSelect] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const addSelectRef = useRef<HTMLInputElement>(null);

  const { styles, attributes } = usePopper(selectRef, selectPop, {
    placement: "bottom-start",
    strategy: "fixed",
  });

  const [value, setValue] = useState<{ value: string | null; update: boolean }>({
    value: initialValue,
    update: false,
  });

  useEffect(() => {
    setValue({ value: initialValue, update: false });
  }, [initialValue]);

  useEffect(() => {
    if (value.update) {
      dataDispatch({
        type: ActionTypes.UPDATE_CELL,
        columnId,
        rowIndex,
        value: value.value,
      });
    }
  }, [value.update, value.value, columnId, rowIndex, dataDispatch]);

  useEffect(() => {
    if (addSelectRef.current && showAdd) {
      addSelectRef.current.focus();
    }
  }, [addSelectRef, showAdd]);

  function getColor() {
    let match = options.find((option) => option.label === value.value);
    return (match && match.backgroundColor) || grey(200);
  }

  function handleAddOption(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      if (e.currentTarget.value !== "") {
        dataDispatch({
          type: ActionTypes.ADD_OPTION_TO_COLUMN,
          option: e.currentTarget.value,
          backgroundColor: randomColor(),
          columnId,
        });
      }
      setShowAdd(false);
    }
  }

  function handleOptionBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (e.currentTarget.value !== "") {
      dataDispatch({
        type: ActionTypes.ADD_OPTION_TO_COLUMN,
        option: e.currentTarget.value,
        backgroundColor: randomColor(),
        columnId,
      });
    }
    setShowAdd(false);
  }

  function handleOptionClick(option: { label: string }) {
    setValue({ value: option.label, update: true });
    setShowSelect(false);
  }

  return (
    <>
      <div
        ref={setSelectRef}
        className="cell-padding d-flex cursor-default align-items-center flex-1"
        onClick={() => setShowSelect(true)}
      >
        {value.value && (
          <Badge value={value.value} backgroundColor={getColor()} />
        )}
      </div>
      {showSelect && (
        <div className="overlay" onClick={() => setShowSelect(false)} />
      )}
      {showSelect &&
        createPortal(
          <div
            className="shadow-5 bg-white border-radius-md"
            ref={setSelectPop}
            {...attributes.popper}
            style={{
              ...styles.popper,
              zIndex: 4,
              minWidth: 200,
              maxWidth: 320,
              maxHeight: 400,
              padding: "0.75rem",
              overflow: "auto",
            }}
          >
            <div
              className="d-flex flex-wrap-wrap"
              style={{ marginTop: "-0.5rem" }}
            >
              {options.map((option) => (
                <div
                  className="cursor-pointer mr-5 mt-5"
                  onClick={() => handleOptionClick(option)}
                  key={option.label}
                >
                  <Badge
                    value={option.label}
                    backgroundColor={option.backgroundColor}
                  />
                </div>
              ))}
              {showAdd && (
                <div
                  className="mr-5 mt-5 bg-grey-200 border-radius-sm"
                  style={{
                    width: 120,
                    padding: "2px 4px",
                  }}
                >
                  <input
                    type="text"
                    className="option-input"
                    onBlur={handleOptionBlur}
                    ref={addSelectRef}
                    onKeyDown={handleAddOption}
                  />
                </div>
              )}
              <div
                className="cursor-pointer mr-5 mt-5"
                onClick={(e) => handleAddOption(e)}
              >
                <Badge
                  value={
                    <span className="svg-icon-sm svg-text">
                      <PlusIcon />
                    </span>
                  }
                  backgroundColor={grey(200)}
                />
              </div>
            </div>
          </div>,
          //@ts-ignore
          document.querySelector("#popper-portal") 
        )}
    </>
  );
}
