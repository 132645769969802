export function multistageHeatmapSocket(payload: any) {
	return new Promise((resolve, reject) => {
		const socket = new WebSocket(
			process.env.REACT_APP_ML_WS_URL + "ws/multistage/heatmap/" + payload
		)
		socket.onopen = function () {
			resolve(socket)
		}

		socket.onerror = function (evt) {
			reject(payload)
		}
	})
}