import React, { useEffect, useState } from "react"
import { Spin, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { forgotPasswordRequest } from "src/store/actions/forgotPassword"
import { blue } from "@ant-design/colors"
import { AsyncStates } from "src/constants"
import { LoadingOutlined } from "@ant-design/icons"
const { Text } = Typography

export const ForgotPassword = () => {
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const user_email = useSelector<StoreState, string>(
		(state) => state.login.loginResponse.user_email
	);
	const forgotPasswordStatus = useSelector((state: StoreState) => state.forgotPassword.status);
	const [allowResend, setAllowResend] = useState(false);
	const [timeTillResend, setTimeTillResend] = useState(60);

	useEffect(() => {
		let timer: any;

		if (forgotPasswordStatus === AsyncStates.SUCCESS) {
			timer = setTimeout(() => {
				const newTimeTillResend = timeTillResend - 1;
				if (newTimeTillResend > 0) setTimeTillResend(newTimeTillResend);
				else {
					setAllowResend(true);
					clearTimeout(timer);
				}
			}, 1000)
		} else {
			setAllowResend(false);
			setTimeTillResend(60);
		}

		return () => clearTimeout(timer);
	}, [forgotPasswordStatus, timeTillResend])

	function handleForgotPassword() {
		dispatch(forgotPasswordRequest({ user_email, dontRedirect: true }))
	}

	if (forgotPasswordStatus === AsyncStates.LOADING) return <Spin indicator={<LoadingOutlined />} />

	return (
		<div>
			{forgotPasswordStatus !== AsyncStates.SUCCESS ? (<Text
				strong
				type="secondary"
				onClick={handleForgotPassword}
				style={{ cursor: "pointer", color: blue.primary }}
			>
				{t("common.iForgotMyPassword")}?
			</Text>)
				: (
					<div>
						<div>We've sent an email to {user_email} with a link to reset your password.</div>
						{!allowResend && <div>Resend in {timeTillResend} seconds</div>}
						{allowResend && <Text
							strong
							type="secondary"
							onClick={handleForgotPassword}
							style={{ cursor: "pointer", color: blue.primary }}
						>
							Resend
						</Text>}
					</div>
				)}
		</div>
	)
}
