import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Empty, Spin, Tooltip, Typography } from 'antd';
import { CollapseProps } from 'antd/lib';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AsyncStates } from 'src/constants';
import { getIngredientUsedInWoRequest } from 'src/store/actions/inventoryV2';
import { setProject } from 'src/store/actions/projects';
import { workOrderClear, workOrderRequest } from 'src/store/actions/workOrderDetails';
import { StoreState } from 'src/store/configureStore';
import { StyledButton } from 'src/styled_components/StyledButton';
import useTranslate from 'src/utils/useTranslate';


const { Text } = Typography

export const InventoryDetailsProjects = () => {

    const [t] = useTranslate()
    const dispatch = useDispatch()
    const { push } = useHistory();


    const [activeKeys, setActiveKeys] = useState<string | string[]>([])
    const { ingredientsUsedInWoData, ingredientsUsedInWoStatus } = useSelector((state: StoreState) => state.inventoryV2);
    const { data: ingredient } = useSelector((state: StoreState) => state.inventoryV2.getIngredientDetails);

    const ingredientsUsedInWo: any[] = useMemo(() => ingredientsUsedInWoData?.[ingredient?.inventory_id] ?? [], [ingredient?.inventory_id, ingredientsUsedInWoData])
    const { current: currentProject } = useSelector((state: StoreState) => state.projects,)
    const currentWorkOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder || {});

    useEffect(() => {
        if (!ingredientsUsedInWo?.length) {
            dispatch(getIngredientUsedInWoRequest({ inventory_id: ingredient?.inventory_id }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])


    const openWorkOrder = useCallback((workOrderId: string) => {
        dispatch(workOrderClear())
        dispatch(workOrderRequest(workOrderId))
        push(`/work-orders/details/${workOrderId}`)
    }, [dispatch, push])

    const items: CollapseProps['items'] = useMemo(() => {
        const data = ingredientsUsedInWo.map((projectInfo) => {
            return {
                key: projectInfo.project_id,
                label: <div>
                    <Text strong
                        style={{ color: "#3986ff", textDecoration: "underline" }}
                        onClick={(e) => {
                            e.stopPropagation()
                            push(`/projects/details/${projectInfo.project_id}`)
                        }}
                    >{projectInfo.project_name}
                    </Text>
                </div>,
                children: projectInfo.work_orders?.map((workOrder: any) => <Tooltip title={currentWorkOrder.work_order_id === workOrder?.work_order_id ? "Current Work Order" : "Click to view work order"}>
                    <StyledButton
                        onClick={(e) => {
                            e.stopPropagation()
                            if (currentProject !== projectInfo.project_id) {
                                dispatch(setProject(projectInfo.project_id));
                            }
                            if (currentWorkOrder.work_order_id !== workOrder.work_order_id) {
                                openWorkOrder(workOrder.work_order_id)
                            }
                        }}
                        disabled={currentWorkOrder.work_order_id === workOrder.work_order_id}
                        key={workOrder.work_order_id}
                        style={{
                            margin: "0.5rem 0.25rem", backgroundColor: "#e6f4ff"
                        }}>
                        {workOrder.work_order_name}
                    </StyledButton>
                </Tooltip>
                )
            }
        })
        return data
    }, [currentProject, currentWorkOrder.work_order_id, dispatch, ingredientsUsedInWo, openWorkOrder, push]);

    const onChange = (keys: string | string[]) => {
        setActiveKeys(keys)
    };

    return (
        <>
            <Spin tip={t("common.loading")} indicator={<LoadingOutlined />} size="default" spinning={ingredientsUsedInWoStatus === AsyncStates.LOADING}>
                <div style={{ padding: "0.5rem 0rem" }}>
                    {!!ingredientsUsedInWo.length ?
                        <Collapse size='small' items={items} defaultActiveKey={['1']} onChange={(key) => onChange(key)} activeKey={activeKeys} />
                        : <Empty description={ingredientsUsedInWoStatus === AsyncStates.LOADING ? null : `${ingredient.name} ${t("inventory.ingredientNotUsed")}`} />}
                </div>
            </Spin>
        </>

    )
}
