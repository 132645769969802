import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
import {
  // AvatarComponent,
  dateSorter,
  numberSorter,
  stringSorter,
  TEXT_PRIMARY,
  valueFilter,
} from '../../Dashboard';
import { Table, Tag, Typography } from 'antd'
import useTranslate from 'src/utils/useTranslate'
import dayjs from 'dayjs'
// import { useMemberName } from 'src/utils/useMemberName'
import { workOrdersRequest } from 'src/store/actions/workOrders'
import { Link } from 'react-router-dom';

const WorkordersTable = ({ selectedProjectId, showOnlyCurrentUser = true }: any) => {
  const [t] = useTranslate()

  // const { getName } = useMemberName()

  const { data: workOrders, status } = useSelector(
    (state: StoreState) => state.workOrders
  )

  const stages = useSelector(
    (state: StoreState) => state.displayNames.data?.stages
  )

  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      workOrdersRequest({ project_ids: [selectedProjectId], is_table: true })
    )
  }, [dispatch, selectedProjectId])

  // const workorderOnwersFilterOptions = useMemo(
  //   () =>
  //     [...new Set(workOrders.map((project: any) => project.created_by))].map(
  //       (res: any) => ({ text: getName(res), value: res })
  //     ),
  //   [workOrders, getName]
  // )

  const getWorkorderStatus = useCallback(
    (status: string) => {
      const styles: React.CSSProperties = {
        margin: 0,
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        border: 'none'
      }

      switch (status) {
        case 'open':
          return (
            <Tag color="orange" style={styles}>
              {' '}
              {t('common.ongoing')}{' '}
            </Tag>
          )
        case 'closed':
          return (
            <Tag color="blue" style={styles}>
              {' '}
              {t('common.closed')}{' '}
            </Tag>
          )
        default:
          return (
            <Tag color="orange" style={styles}>
              {' '}
              {t('common.ongoing')}{' '}
            </Tag>
          )
      }
    },
    [t]
  )

  const workorderStatusNames = useCallback(
    (status:string) => {
      switch (status) {
        case 'open':
          return t('common.ongoing')
        case 'closed':
          return t('common.closed')
        default:
          return t('common.ongoing')
      }
    },
    [t]
  )

  const workOrderStatusFilterOptions = useMemo(
    () =>
      [...new Set(workOrders.map((project: any) => project.status))].map(
        (res: any) => ({ text: workorderStatusNames(res), value: res })
      ),
    [workOrders, workorderStatusNames]
  )

  const workorderStageFilterOptions = useMemo(() => {
    return [...new Set(workOrders.map((wo: any) => wo.work_order_stage))]
      .filter((res: any) => res !== null && res !== undefined)
      .map((res: any) => ({
        text: stages[res] || res,
        value: res
      }))
  }, [workOrders, stages])

  const workorderParentStageFilterOptions = useMemo(() => {
    return [...new Set(workOrders.map((wo: any) => wo.parent_stage))]
      .filter((res: any) => res !== null && res !== undefined)
      .map((res: any) => ({
        text: res,
        value: res
      }))
  }, [workOrders])

  const tableColumns = useMemo(() => {
    return [
      {
        title: t('common.Name'),
        key: 'work_order_name',
        dataIndex: 'work_order_name',
        sorter: (a: any, b: any, c: any) => {
          return stringSorter(a.work_order_name, b.work_order_name)
        },
        width: 150,
        render: (value: string, record:any) => (
          <Link to={`/work-orders/details/${record.work_order_id}`} >
            <Typography.Text
              style={{
                margin: 0,
                width: 150
              }}
              ellipsis={{ tooltip: value }}
              strong
              className='dashboard_underline_text'
            >
              {value}
            </Typography.Text>
          </Link>
        )
      },
      {
        title: t('common.trials'),
        key: 'total_trials',
        dataIndex: 'total_trials',
        width: 75,
        render: (value: string) => (
          <Typography.Text
            style={{
              margin: 0,
              width: 75
            }}
            ellipsis={{ tooltip: value }}
            strong
          >
            {value}
          </Typography.Text>
        ),
        sorter: (a: any, b: any) => {
          return numberSorter(a.total_trials, b.total_trials)
        }
      },
      {
        title: t('common.stage'),
        dataIndex: 'parent_stage',
        key: 'parent_stage',
        width: 100,
        render: (value: any, row: any, index: any) => {
          return (
            <Typography.Text
              style={{
                margin: 0,
                width: 100
              }}
              ellipsis={{ tooltip: value }}
              strong
            >
              {value}
            </Typography.Text>
          )
        },
        sorter: (a: any, b: any) => {
          return stringSorter(
            a.parent_stage,
            b.parent_stage
          )
        },
        onFilter: (value: any, record: any) => {
          return valueFilter(value, record.parent_stage)
        },
        filterSearch: true,
        filters: workorderParentStageFilterOptions
      },
      {
        title: t('common.subStage'),
        dataIndex: 'work_order_stage',
        key: 'work_order_stage',
        width: 150,
        render: (value: any, row: any, index: any) => {
          const stageName = stages[value] || value

          return (
            <Typography.Text
              style={{
                margin: 0,
                width: 150
              }}
              ellipsis={{ tooltip: stageName }}
              strong
            >
              {stageName}
            </Typography.Text>
          )
        },
        sorter: (a: any, b: any) => {
          return stringSorter(
            a.work_order_stage,
            b.work_order_stage
          )
        },
        onFilter: (value: any, record: any) => {
          return valueFilter(value, record.work_order_stage)
        },
        filterSearch: true,
        filters: workorderStageFilterOptions
      },
      // {
      //   dataIndex: 'created_by',
      //   title: t('common.createdBy'),
      //   key: 'created_by',
      //   align: 'center' as 'center',
      //   filters: workorderOnwersFilterOptions,
      //   onFilter: (value: any, record: any) => {
      //     return valueFilter(value, record.created_by)
      //   },
      //   filterSearch: true,
      //   width: 150,
      //   sorter: (a: any, b: any, c: any) => {
      //     return stringSorter(getName(a.created_by), getName(b.created_by))
      //   },
      //   render: (member: any) => <AvatarComponent id={member} />
      // },
      {
        title: t('common.createdOn'),
        dataIndex: 'created',
        key: 'created',
        width: 150,
        render: (date: any) => {
          const dateFormatted = dayjs(date).format('DD MMM YYYY HH:mm')
          return (
            <Typography.Text
              style={{
                margin: 0,
                width: 150
              }}
              ellipsis={{ tooltip: dateFormatted }}
              strong
            >
              {date ? dateFormatted : 'N/A'}
            </Typography.Text>
          )
        },
        sorter: (a: any, b: any) => {
          return dateSorter(a.created, b.created)
        }
      },
      {
        dataIndex: 'status',
        title: t('common.status'),
        key: 'status',
        width: 100,
        align: 'center' as 'center',
        sorter: (a: any, b: any) => {
          return stringSorter(a.status, b.status)
        },
        render: (text: string) => {
          return getWorkorderStatus(text)
        },
        onFilter: (value: any, record: any) => {
          return valueFilter(value, record.status)
        },
        filterSearch: true,
        filters: workOrderStatusFilterOptions
      }
    ]
  }, [getWorkorderStatus, stages, t, workOrderStatusFilterOptions, workorderStageFilterOptions, workorderParentStageFilterOptions])

  return (
    <Table
      loading={status === AsyncStates.LOADING}
      columns={tableColumns}
      dataSource={showOnlyCurrentUser ? workOrders.filter((data: any) => data.created_by === userId) : workOrders}
      className="dashboard-activity-workorders-drawer-table"
      pagination={{
        style: { color: TEXT_PRIMARY, gap: 8 },
        size: 'small',
        showSizeChanger: false
      }}
      scroll={{ y: 800 }}
      style={{
        width: '100%',
        scrollbarWidth: 'none'
      }}
    />
  )
}

export default WorkordersTable
