
import { CopyOutlined, InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Select, Space, Table, Tooltip, Typography } from 'antd';
import { useCallback, useMemo, useState } from 'react'
import { DynamicDataSheetGrid, keyColumn, textColumn, CellProps } from 'react-datasheet-grid';
import { useDispatch, useSelector } from 'react-redux';
import { setEditingState } from 'src/store/actions/workOrderDetails';
import { StoreState } from 'src/store/configureStore';
import { StyledButton } from 'src/styled_components/StyledButton';
import { getDropdownFilteredValue } from 'src/utils/decorator';
import { CopyValuesModal } from './modals/CopyValuesModal';
import "./DataSheetVariation.scss"
import { Dataset } from 'src/utils/getHeaders';
import { PropertiesModal } from './modals/PropertiesModal';
import { TextComponent } from "src/components/Datasheet/TextComponent"
import { useValue } from 'src/utils/useValue';
import useTranslate from 'src/utils/useTranslate';


const { Option, OptGroup } = Select
const { Text } = Typography


export const DataSheetVariation = ({ trialsList, dataset, initialDisplayIdList, data, setData, tableData, selectedRecords, setSelectedRecords, disabled, metaData, setMetaData, saveCharacterizationsData, showModal, currentTrial, setCurrentTrial, metaTitle, setMetaTitle }: any) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const { getValue } = useValue()
    const displayNames = useSelector((state: StoreState) => state.displayNames?.data)
    const unitList = useSelector((state: StoreState) => state.conversion.unitList)
    const characterizations = useSelector((state: StoreState) => state.displayNames.data?.characterizations)
    const linkedExperiments = useSelector((state: StoreState) => state.workOrderDetails.linkedExperiment)
    const experiments = useSelector((state: StoreState) => state.workOrderDetails.experiment)
    const [copyModalVisible, setCopyModalVisible] = useState(false)
    const [propertiesModalVisible, setPropertiesModalVisible] = useState(false)
    const [copyRecordKey, setCopyRecordKey] = useState(0)

    const variationsColumns = useMemo(() => {
        const columns: any = [
            {
                title: "",
                width: "0 0 5px",
                component: (row: CellProps<any>) => (
                    <MinusCircleOutlined
                        onClick={() => {
                            if (!disabled) {
                                setData((prevState: any) => {
                                    prevState[row?.rowData?.index].splice(row.rowIndex, 1)
                                    return [...prevState]
                                })
                            }
                        }}
                        style={{ margin: "0 auto", cursor: disabled ? "auto" : "pointer" }} />
                ),
            },
            {
                title: "Parameter",
                component: (row: any) => {
                    const usedIdentifiers = data?.[row?.rowData?.index]?.map((res: any) => res?.identifier)
                    return (
                        <Select
                            disabled={disabled}
                            bordered={false}
                            dropdownStyle={{ width: 300 }}
                            popupMatchSelectWidth={false}
                            filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                            showSearch
                            style={{ width: "100%" }}
                            value={data?.[row.rowData.index]?.[row.rowIndex]?.parameter || displayNames?.[dataset]?.[data?.[row?.rowData?.index]?.[row?.rowIndex]?.identifier]?.name}
                            onChange={(value: any) => {
                                const rowData = Object.entries(row.rowData || {}).reduce((obj, [key, value]: any) => ({ ...obj, [key]: key === "index" ? value : "" }), {})
                                row.setRowData({ ...row.rowData, ...rowData, identifier: value, parameter: displayNames?.[dataset]?.[value]?.name, category: displayNames?.[dataset]?.[value]?.category || "" })
                            }}
                        >
                            {Object.entries(displayNames?.[dataset] || {}).filter(([key, _]: any) => !usedIdentifiers?.includes(key))?.map(([key, value]: any) => (
                                <Option value={key}>{value?.name}</Option>
                            ))}
                        </Select>
                    )
                },
                minWidth: 200,
            },
            {
                ...keyColumn("unit", textColumn),
                title: "Unit",
                component: (row: any) => (
                    <Select
                        disabled={disabled}
                        bordered={false}
                        filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                        showSearch
                        style={{ width: "100%" }}
                        value={data?.[row?.rowData?.index]?.[row?.rowIndex]?.unit}
                        onChange={(value: any) =>
                            row.setRowData({ ...row.rowData, unit: value })
                        }
                    >
                        {Array.from(new Set(unitList.map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                            <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                                {unitList.filter((res: any) => res.category === category).map((res: any) => (
                                    <Option value={res.name} key={res.name}>{res.name}</Option>
                                ))}
                            </OptGroup>
                        )}
                    </Select>
                ),
                renderWhenScrolling: false
            },
            {
                ...keyColumn("category", textColumn),
                title: t("common.category"),
                disabled: true,
                component: (props: any) => <TextComponent {...props} id={"category"} disabled={true} />
            },
        ]
        if (dataset === Dataset.characterizations) {
            columns.push({
                ...keyColumn("input", textColumn),
                title: "Input",
                minWidth: 250,
                component: (props: CellProps) => {
                    if (!!characterizations?.[props?.rowData?.identifier]?.options?.length) {
                        return <Select
                            disabled={disabled}
                            value={data?.[props?.rowData?.index]?.[props?.rowIndex]?.["input"]}
                            bordered={false}
                            filterOption={(inputValue: string, options: any) => getDropdownFilteredValue(inputValue, options)}
                            showSearch
                            dropdownStyle={{ width: "auto" }}
                            popupMatchSelectWidth={false}
                            style={{ width: "100%" }}
                            onChange={(value: any) =>
                                props.setRowData({ ...props.rowData, input: value })
                            }
                        >
                            {characterizations?.[props?.rowData?.identifier]?.options
                                ?.sort((a: any, b: any) => (String(a).toLowerCase() > String(b).toLowerCase()) ? 1 : -1)
                                .map((res: any, index: number) => (
                                    <Option value={res} key={index}>{String(res)}</Option>
                                ))}
                        </Select>
                    } else {
                        return <TextComponent {...props} id={"input"} disabled={disabled} />
                    }
                }
            })
        }
        else if (dataset === Dataset.properties) {
            trialsList.forEach((trial: any, index: any) => {
                columns.push({
                    ...keyColumn(trial?.id_set?.formulation_id, textColumn),
                    title: <Space>
                        <Text style={{ width: 170, textAlign: "center" }} ellipsis={{ tooltip: initialDisplayIdList?.[index] || trial?.id_set?.formulation_id }}>
                            {initialDisplayIdList?.[index] || trial?.id_set?.formulation_id}
                        </Text>
                        <InfoCircleOutlined style={{ cursor: "pointer", zIndex: 20 }} onClick={() => showModal(trial?.id_set?.formulation_id)} />
                    </Space>,
                    minWidth: 200,
                    component: (props: CellProps) => {
                        if (["dry_matter_in_strong_wort_g",
                            "initial_dry_matter_in_substrate",
                            "yield_pct",
                            "dry_matter_in_total_strong_wort_g",
                            "dry_matter_in_weak_wort_g"]
                            .includes(props.rowData?.identifier)) {
                            return <Tooltip title={"This cell is not user editable (as it is derived using a relationship)"}>
                                <Text strong style={{ width: "100%", paddingLeft: 10 }}>
                                    {props?.rowData?.[trial?.id_set?.formulation_id] ?? "-"}
                                </Text>
                            </Tooltip>
                        }
                        else return <TextComponent {...props} id={trial?.id_set?.formulation_id} disabled={disabled} />
                    }
                })
            })
        }
        return columns
    }, [dataset, initialDisplayIdList, trialsList, displayNames, data, unitList, setData, characterizations, disabled, showModal, t])

    const addParameters = useCallback((variationIndex: number) => {
        setData((prevState: any) => {
            const record = prevState?.[variationIndex] || []
            record.push({
                parameter: "",
                identifier: "",
                unit: "",
                category: "",
                index: variationIndex,
                ...trialsList.reduce((obj: any, trial: any, index: any) => (
                    { ...obj, [trial?.id_set?.formulation_id]: "" }
                ), {})
            })
            return [...prevState]
        })
    }, [trialsList, setData])

    const methodsTableProps = useMemo(() => {
        const props: any = {
            bordered: true,
            pagination: false,
            columns: [
                {
                    dataIndex: "key",
                    key: "key",
                    title: "No",
                    width: 40,
                    render: (key: any) => Number(key) + 1
                },
                {
                    dataIndex: "variation",
                    key: "variation",
                    title: "Variation",
                    width: 250,
                },
                {
                    dataIndex: "description",
                    key: "description",
                    title: "Description",
                    wdith: 250
                }
            ],
            dataSource: tableData,
            expandable: {
                expandedRowRender: (record: any) => {
                    return <Space direction='vertical' style={{ width: "100%", overflowX: "auto" }} size="large">
                        {!disabled &&
                            <Row justify="space-between">
                                <Space>
                                    <StyledButton icon={<PlusOutlined />} onClick={() => addParameters(record?.key)}>
                                        {"Add Parameter"}
                                    </StyledButton>
                                    {dataset === Dataset.properties &&
                                        <StyledButton icon={<CopyOutlined />} onClick={() => {
                                            setCopyRecordKey(record?.key)
                                            setCopyModalVisible(true)
                                        }}>
                                            {"Copy Values"}
                                        </StyledButton>
                                    }
                                </Space>
                                {(["Final Product (Without Leaching)", "Final Product (Total Strong Wort)"].includes(record?.variation) && dataset === Dataset.properties) &&
                                    <StyledButton type="primary" onClick={() => {
                                        setMetaTitle(record?.variation)
                                        if (Array.isArray(experiments?.[0]?.properties?.[0]?.properties)) {
                                            setMetaData(experiments?.[0]?.properties?.[0]?.properties?.find((res: any) => res?.variation_id === record?.variation_id)?.meta || [])
                                        }
                                        setCurrentTrial(experiments?.[0]?.id_set?.formulation_id || "")
                                        setPropertiesModalVisible(true)
                                    }}>
                                        {"Add More Details"}
                                    </StyledButton>
                                }
                            </Row>
                        }
                        <DynamicDataSheetGrid
                            lockRows
                            height={600}
                            value={data?.[record?.key] || []}
                            onChange={(e: any) => {
                                dispatch(setEditingState(true))
                                if (!disabled) {
                                    setData((prevState: any) => {
                                        trialsList.forEach((trial: any, index: number) => {
                                            let totalSolids: any, amount: any, dryMatter: any, initialDryMatter: any, yeild: any
                                            e.forEach((res: any) => {
                                                if (res?.identifier === "pctts_brix") {
                                                    totalSolids = res?.[trial?.id_set?.formulation_id]
                                                }
                                                else if (res?.identifier === "amount_g") {
                                                    amount = res?.[trial?.id_set?.formulation_id]
                                                }
                                            })
                                            if (!!totalSolids && !!amount && !isNaN(totalSolids) && !isNaN(amount)) {
                                                dryMatter = getValue((Number(totalSolids) / 100) * Number(amount))
                                            }
                                            if (!!linkedExperiments?.[index]?.ingredients?.["substrate"]?.value || !!linkedExperiments?.[0]?.ingredients?.["substrate"]?.value) {
                                                initialDryMatter = getValue(90 / 100 * (Number(linkedExperiments?.[0]?.ingredients?.["substrate"]?.value) || Number(linkedExperiments?.[0]?.ingredients?.["substrate"]?.value)))
                                            }
                                            if (!!dryMatter && !!initialDryMatter && !isNaN(dryMatter) && !isNaN(initialDryMatter)) {
                                                yeild = getValue(Number(dryMatter) / Number(initialDryMatter) * 100)
                                            }
                                            e.forEach((res: any) => {
                                                if (res?.identifier === "pctts_brix") {
                                                    res[trial.id_set?.formulation_id] = totalSolids
                                                }
                                                else if (res?.identifier === "amount_g") {
                                                    res[trial.id_set?.formulation_id] = amount
                                                }
                                                else if ([
                                                    "dry_matter_in_strong_wort_g",
                                                    "dry_matter_in_total_strong_wort_g",
                                                    "dry_matter_in_weak_wort_g"
                                                ].includes(res?.identifier)) {
                                                    res[trial.id_set?.formulation_id] = dryMatter
                                                }
                                                else if (res?.identifier === "initial_dry_matter_in_substrate") {
                                                    res[trial.id_set?.formulation_id] = initialDryMatter
                                                }
                                                else if (res?.identifier === "yield_pct") {
                                                    res[trial.id_set?.formulation_id] = yeild
                                                }
                                            })
                                        })
                                        prevState[record?.key] = e
                                        return [...prevState]
                                    })
                                }
                            }}
                            columns={variationsColumns as any}
                        />
                    </Space>
                }
            }
        }
        props.rowSelection = {
            selectedRowKeys: selectedRecords,
            onChange: setSelectedRecords
        }
        return props
    }, [addParameters, variationsColumns, data, dataset, selectedRecords, setData, setSelectedRecords, tableData, dispatch, disabled, trialsList, linkedExperiments, experiments, setCurrentTrial, setMetaData, setMetaTitle, getValue])


    return (
        <>
            <Space size='large' direction='vertical' className='datasheet-variation' style={{ display: "flex", justifyContent: "space-between", width: "100%", overflow: "clip" }}>
                <Table {...methodsTableProps} />
            </Space>
            <CopyValuesModal
                visible={copyModalVisible}
                setVisible={setCopyModalVisible}
                initialDisplayIdList={initialDisplayIdList}
                trialsList={trialsList}
                recordKey={copyRecordKey}
                setData={setData}
            />
            <PropertiesModal
                visible={propertiesModalVisible}
                setVisible={setPropertiesModalVisible}
                metaData={metaData}
                setMetaData={setMetaData}
                setData={setData}
                saveCharacterizationsData={saveCharacterizationsData}
                trialsList={trialsList}
                edit={true}
                currentTrial={currentTrial}
                setCurrentTrial={setCurrentTrial}
                metaTitle={metaTitle}
            />
        </>
    )
}

