import React from "react"
import { Select, Space, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import useTranslate from "src/utils/useTranslate"
import { setProjectTypeRequest } from "src/store/actions/login"
import { AsyncStates } from "src/constants"
import { StoreState } from "src/store/configureStore"

export const ProjectType = () => {
	const [t] = useTranslate()
	const dispatch = useDispatch()

	const {
		setProjectTypeStatus,
		loginResponse: { project_type },
	} = useSelector((state: StoreState) => state.login)
	const configs = useSelector((state: StoreState) => state.configs.features)

	function handleProjectTypeChange(value: string) {
		dispatch(setProjectTypeRequest({ project_type: value }))
	}

	if (!Boolean(configs?.celsure)) return null

	return (
		<Space size="small">
			<Typography.Text strong type="secondary">
				{`${t("common.selectProjectType")}:`}
			</Typography.Text>

			<Select
				style={{ width: 200, fontWeight: "bold" }}
				value={project_type}
				loading={setProjectTypeStatus === AsyncStates.LOADING}
				onChange={handleProjectTypeChange}
				placeholder="Project type"
			>
				<Select.Option value="pcm">{"PCM"}</Select.Option>
				<Select.Option value="celsure">{t("celsure.title")}</Select.Option>
			</Select>
		</Space>
	)
}
