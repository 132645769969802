import {
  Form,
  Modal,
  Select,
  Space,
  Row,
  message,
} from "antd";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { StyledButton } from "src/styled_components/StyledButton";
import { setIsEditing } from "src/store/actions/isEditing";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import dayjs from "dayjs";
import { updateIngredientRequest } from "src/store/actions/inventoryV2";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";

const { Option } = Select;

interface InventoryCustomFieldModalProps {
  ingredient: any;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  customFields: any[];
  setCustomFields: React.Dispatch<React.SetStateAction<any[]>>;
}

export const InventoryCustomFieldModal = ({
  ingredient,
  visible,
  setVisible,
  customFields,
}: InventoryCustomFieldModalProps) => {
  const [form] = Form.useForm();
  const [t] = useTranslate();
  const requiredFieldStar = useRequiredFieldStar()


  const dispatch = useDispatch();
  const isEditing = useSelector((state: StoreState) => state.isEditing);

  const fieldsResponse = useSelector(
    (state: StoreState) => state.repository.fields
  );

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  };

  const addCustomFieldSubmit = (values: any) => {
    values.field_id = values.field_id.trim();

    if (
      !!customFields.find(
        (res: any) =>
          res.field_id ===
          values.field_id
      )
    ) {
      message.error(`Custom field already exists`);
      return;
    }

    const selectedCustomField = fieldsResponse.data.find(
      (res: any) =>
        res.field_id ===
        values.field_id
    );

    const newField = {
      field_id: values.field_id,
      value:
      selectedCustomField?.field_type === "date"
          ? dayjs(selectedCustomField.default_value || new Date()).format("YYYY-MM-DD")
          : selectedCustomField?.default_value,
    };


    dispatch(
      updateIngredientRequest({
        ...ingredient,
        custom_fields: 
        [...(ingredient?.custom_fields ?? []), {...newField}]
      })
    );
    
    closeModal();
  };
  
  const handleCloseModal = () => {
    if (isEditing) {
      Modal.confirm({
        title: `Unsaved changes present`,
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          closeModal();
        },
      });
    } else {
      closeModal();
    }
  };

  return (
    <Modal
      open={visible}
      width={600}
      maskClosable={false}
      onCancel={handleCloseModal}
      title={t("projects.customField")}
      footer={""}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={addCustomFieldSubmit}
        onFieldsChange={() => {
          dispatch(setIsEditing(true));
        }}
        requiredMark={false}
      >
        <Form.Item
          name="field_id"
          label={t("common.fieldType")}
          required
          tooltip={requiredFieldStar}
          rules={[{ required: true, message: "required" }]}
        >
          <Select
            placeholder={t("common.Selectthefieldtype")}
            style={{ width: "100%" }}
            showSearch
            onChange={(e: string) => {
              form.setFieldsValue({ default_value: undefined, options: [] });
            }}
            optionFilterProp="children"
          >
            {
              fieldsResponse.data.sort((a: any, b: any) => a.field_name?.localeCompare(b.field_name)).map((field) => <Option key={field.field_id} value={field.field_id}>{field.field_name}</Option>)
            }            
          </Select>
        </Form.Item>
        <Row justify="end" style={{ marginTop: 30 }}>
          <Space size={"large"}>
            <StyledButton type="text" onClick={closeModal}>
              {t("common.cancel")}
            </StyledButton>
            <StyledButton type="primary" htmlType="submit">
              {t("common.addField")}
            </StyledButton>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};
