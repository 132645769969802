export const aggregationMethods = [
	"sum",
	"mean",
	"min",
	"max",
	"median",
] as const

export type AggregationMethod = typeof aggregationMethods[number]

export type PivotTableData = {
	"x-variable": string
	"y-variable": string
	table: {
		[key: string]: {
			[key: string]: string
		}
	},
	formulation_display_names?: {
		[key: string]: string
	},
}
