import { convertToPrecision } from "src/utils/decorator";

export const calculateAverageAmbientTemperature = (ambientTemperatureArray: any[]) => {
    if (!ambientTemperatureArray.length) {
        return null
    }

    const totalSum = ambientTemperatureArray.reduce((accumulator, currentValue) => {
        return accumulator + ((currentValue.ambient_temperature_value || 0) * (currentValue.ambient_time_value || 0));
    }, 0);

    const totalTime = ambientTemperatureArray.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.ambient_time_value || 0);
    }, 0);

    const result = totalSum / totalTime;
    if (isNaN(result) || !isFinite(result)) {
        return null
    }

    return convertToPrecision(result, 3);
}