import React, { Dispatch, SetStateAction } from "react"
import { Tabs } from "antd"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import useTranslate from "src/utils/useTranslate"
import { Tab } from "./compare"

type P = {
	tab: Tab
	setTab: Dispatch<SetStateAction<Tab>>
}

export const Header = ({ tab, setTab }: P) => {
	const [t] = useTranslate()
	return (
		<StyledPageHeader
			style={{ width: "100%" }}
			ghost={false}
			title={t("common.compare")}
			onBack={() => window.history.back()}
			footer={
				<Tabs activeKey={tab} onChange={(key) => setTab(key as Tab)}>
					<Tabs.TabPane tabKey="summary" key="summary" tab="Summary" />
					<Tabs.TabPane tabKey="report" key="report" tab="Report" />
				</Tabs>
			}
		/>
	)
}
