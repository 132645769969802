import { handleActions } from "redux-actions"
import { MultistageHeatmapActionTypes } from "../actions/multistageHeatmap"
import { AsyncStates } from "src/constants"

export interface MultistageHeatmapState {
  multistageHeatmapOptionsResponse: any
  multistageHeatmapOptionsStatus: string
  multistageHeatmapOptionsError: any

  multistageHeatmapResultResponse: any
  multistageHeatmapResultStatus: string
  multistageHeatmapResultError: any

  type: string
}

const defaultState: MultistageHeatmapState = {
  multistageHeatmapOptionsResponse: {},
  multistageHeatmapOptionsStatus: AsyncStates.INITIAL,
  multistageHeatmapOptionsError: null,

  multistageHeatmapResultResponse: {},
  multistageHeatmapResultStatus: AsyncStates.INITIAL,
  multistageHeatmapResultError: null,

  type: "",
}

const multistageHeatmapReducer = handleActions(
  {
    [MultistageHeatmapActionTypes.REQUEST]: (state, action) => {
      const { payload } = action
      return {
        ...state,
        ...(payload?.type === "query"
          ? { multistageHeatmapResultStatus: AsyncStates.LOADING }
          : {
            multistageHeatmapOptionsStatus: AsyncStates.LOADING,
            multistageHeatmapResultStatus: AsyncStates.INITIAL
          }),
      }
    },
    [MultistageHeatmapActionTypes.SUCCESS_OPTIONS]: (state, action) => {
      return {
        ...state,
        multistageHeatmapOptionsResponse: action.payload,
        multistageHeatmapOptionsStatus: AsyncStates.SUCCESS,
      }
    },
    [MultistageHeatmapActionTypes.SUCCESS_RESULT]: (state, action) => {
      return {
        ...state,
        multistageHeatmapResultResponse: action.payload,
        multistageHeatmapResultStatus: AsyncStates.SUCCESS,
      }
    },

    [MultistageHeatmapActionTypes.FAILURE]: (state, action) => {
      return {
        ...state,
        multistageHeatmapOptionsStatus: AsyncStates.ERROR,
        multistageHeatmapOptionsError: action.payload,
        multistageHeatmapResultStatus: AsyncStates.ERROR,
        multistageHeatmapResultError: action.payload,
      }
    },

    [MultistageHeatmapActionTypes.CLEANUP_OPTIONS]: (state, action) => {
      return {
        ...state,
        multistageHeatmapOptionsResponse: {},
        multistageHeatmapOptionsStatus: AsyncStates.INITIAL,
        multistageHeatmapOptionsError: null,
        multistageHeatmapResultResponse: {},
        multistageHeatmapResultStatus: AsyncStates.INITIAL,
        multistageHeatmapResultError: null,
      }
    },
    [MultistageHeatmapActionTypes.CLEANUP_RESULT]: (state, action) => {
      return {
        ...state,
        multistageHeatmapResultResponse: {},
        multistageHeatmapResultStatus: AsyncStates.INITIAL,
        multistageHeatmapResultError: null,
      }
    },

    [MultistageHeatmapActionTypes.FAILURE_OPTIONS]: (state, action) => {
      return {
        ...state,
        multistageHeatmapOptionsStatus: AsyncStates.ERROR,
        multistageHeatmapOptionsError: action.payload,
      }
    },
    [MultistageHeatmapActionTypes.FAILURE_RESULT]: (state, action) => {
      return {
        ...state,
        multistageHeatmapResultStatus: AsyncStates.ERROR,
        multistageHeatmapResultError: action.payload,
      }
    },
  },
  defaultState,
)

export default multistageHeatmapReducer
