
export const FIELD_TYPES = [
    {
        label: "Numerical",
        value: "numerical"
    },
    {
        label: "Select",
        value: "select"
    },
    {
        label: "Alphanumeric",
        value: "alpha_numeric"
    },
    {
        label: "Range",
        value: "range"
    },
    {
        label: "Operator & Value",
        value: "operator_value"
    },
    {
        label: "Date",
        value: "date"
    },
    {
        label: "Link",
        value: "link"
    },
];

export const OPERATORS = [
    {
        label: "=",
        value: "="
    },
    {
        label: "<",
        value: "<"
    },
    {
        label: ">",
        value: ">"
    },
    {
        label: "<=",
        value: "<="
    },
    {
        label: ">=",
        value: ">="
    }
];

export const PARAMETER_TYPES = [
    {
        label: "Numerical",
        value: "numerical",
        children: [
            {
                label: "Single Value",
                value: "single"
            },
            {
                label: "Range",
                value: "range"
            },
            {
                label: "Operator",
                value: "operator"
            }
        ]
    },
    {
        label: "Categorical",
        value: "categorical",
        children: [
            {
                label: "Text Field",
                value: "single"
            }
        ]
    }
]

export enum CATEGORY_TYPES {
    INGREDIENT = "INGREDIENT",
    ADDITIVE = "ADDITIVE",
    PROPERTY = "PROPERTY"
}

export enum TRIAL_DATA_TYPES {
    CHARACTERIZATIONS = "characterizations",
    PROCESSING = "processing",
    PROPERTIES = "properties",
    // METADATA_PROPERTIES = "metadata_properties"
}