import { red } from "@ant-design/colors";
import { WarningOutlined } from "@ant-design/icons";
import { Space, Table, Tooltip, Typography, Pagination, Radio, RadioChangeEvent, Checkbox, Row } from "antd";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { customMLtableData } from "src/utils/decorator";
import useTranslate, { TranslationKey } from "src/utils/useTranslate";
import { useValue } from "src/utils/useValue";

type Props = {
  filters?: any;
  filterData: any;
  from: string;
  setModalVisible?: any;
  dataCount?: any;
  pagination?: any;
  submitDataFilter?: any;
  UserIgnoredDataInfo?: any;
  propertyTrainWarnings?: any;
  isMultiStage?: any;
  activeWorkOrderId: string;
  typeInfo: [string, React.Dispatch<React.SetStateAction<string>>];
  stage: string | undefined
  isEquationModel?: boolean
};

const { Text } = Typography;

export const CustomMLViewData = ({
  filterData,
  dataCount,
  pagination,
  submitDataFilter,
  from,
  UserIgnoredDataInfo,
  propertyTrainWarnings,
  isMultiStage,
  activeWorkOrderId,
  typeInfo,
  stage,
  isEquationModel
}: Props) => {
  const [type, setType] = typeInfo;
  const [t] = useTranslate();
  const { getValue } = useValue()
  const dispNames = useSelector((state: StoreState) => state.displayNames.data);
  const [userIgnoredData, setUserIgnoredData] = UserIgnoredDataInfo

  const rowSelection = {
    onSelect: (record: any, selected: any) => {
      if (selected) setUserIgnoredData({ ...userIgnoredData, [type]: userIgnoredData[type].filter((d: string) => record.key !== d) });
      else setUserIgnoredData({ ...userIgnoredData, [type]: [...userIgnoredData[type], record.key] });
    },
    getCheckboxProps: (record: any) => {
      return ({
        name: record.ingredients || record.properties || record.processing,
      })
    },
  };

  const toggleIgnoreFormulations = useCallback((checked: boolean, formulationId: string) => {
    if (checked) setUserIgnoredData({ ...userIgnoredData, formulations: userIgnoredData.formulations.filter((d: string) => formulationId !== d) });
    else setUserIgnoredData({ ...userIgnoredData, formulations: [...userIgnoredData.formulations, formulationId] });
  }, [setUserIgnoredData, userIgnoredData]);

  const translateFirstColumnOfTable = (
    input: string,
    t: (key: TranslationKey) => string
  ) => {
    if (input === "ingredients") return t("common.ingredients");
    if (input === "processing") return t("common.processing");
    if (input === "properties") return t("common.properties");
    return t("common.ingredients");
  };

  const ingredientsDataInfo = useMemo(() => {
    const result = filterData.reduce((acc: any, curr: any) => {
      return { ...acc, ...curr.ingredients };
    }, {});
    return result;
  }, [filterData]);

  const tableData: any = useMemo(
    () => customMLtableData(filterData, type, ingredientsDataInfo, getValue),
    [filterData, type, ingredientsDataInfo, getValue]
  );

  const columns: any = useMemo(() => {
    const firstColumnTitle = translateFirstColumnOfTable(type, t);

    return [
      {
        title:
          firstColumnTitle.slice(0, 1).toLocaleUpperCase() +
          firstColumnTitle.slice(1),
        dataIndex: type,
        key: type,
        width: 200,
        fixed: "left",
        render: (text: any, record: any) => {
          if (type === "properties") {
            if (propertyTrainWarnings?.[text] && !isEquationModel)
              return (
                <div style={{ padding: "8px" }}>
                  <Text>{dispNames?.properties?.[text]?.name || text}</Text>
                  <Tooltip title={t("customML.7DataPoints")}>
                    {" "}
                    <WarningOutlined style={{ color: red[5] }} />
                  </Tooltip>
                </div>
              );
            else return <div style={{ padding: "8px" }}>{dispNames?.properties?.[text]?.name || text}</div>
          } else
            return (
              <div style={{ padding: "8px" }}>
                {ingredientsDataInfo?.[text]?.name ||
                  dispNames?.ingredients?.[text]?.name ||
                  dispNames?.processing?.[text]?.name ||
                  text}
              </div>
            );
        },
      },
      ...filterData?.map((res: any) => ({
        title: !isMultiStage && !isEquationModel ? (
          <Row align="middle" style={{ padding: "16px 0" }}>
            <Checkbox checked={!userIgnoredData.formulations.includes(res?.formulation_id)} onChange={(e) => toggleIgnoreFormulations(e.target.checked, res?.formulation_id)} />
            &nbsp;&nbsp;{res?.meta?.display_id}
          </Row>
        ) : (<div style={{ padding: "16px 0" }}>{res?.meta?.display_id}</div>),
        dataIndex: res?.meta?.display_id,
        key: res?.meta?.display_id,
        width: 150
      })),
    ];
  }, [type, t, filterData, ingredientsDataInfo, dispNames?.ingredients, dispNames?.processing, dispNames?.properties, propertyTrainWarnings, isMultiStage, isEquationModel, userIgnoredData.formulations, toggleIgnoreFormulations]);


  return (
    <Space
      direction="vertical"
      size="large"
      style={{
        width: "100%",
        overflowX: "auto",
        height: "600",
        overflowY: "auto",
        paddingTop: "30px"
      }}
    >
      <Radio.Group onChange={
        (e: RadioChangeEvent) => {
          setType(e.target.value);
        }
      }
        style={{ userSelect: "none", marginLeft: "16px" }}
        value={type}>
        <Radio value={'ingredients'}>{t("common.formulations")}</Radio>
        {filterData?.some(
          (res: any) =>
            !!Object.keys(res?.processing?.[0]?.processing || {})?.length
        ) && <Radio value={'processing'}>{t("common.processing")}</Radio>}
        <Radio value={'properties'}>{t("formulations.type.properties")}</Radio>
      </Radio.Group>

      {!isMultiStage ? <Table
        title={() => (
          <div style={{ marginBottom: "16px" }}>
            <span className="highlighted">{t("common.trials")} :</span>{" "}
            {dataCount || filterData.length}
          </div>
        )}
        rowSelection={!isEquationModel ? {
          selectedRowKeys: tableData.flatMap((d: any) => d.key).filter((key: any) => !userIgnoredData[type].includes(key)),
          type: "checkbox",
          ...rowSelection,
          onChange: (selectedRowKeys: any, selectedRows) => {
            if (selectedRowKeys.length === tableData.length) {
              setUserIgnoredData({ ...userIgnoredData, [type]: [] });
            }

            if (selectedRowKeys.length === 0) {
              setUserIgnoredData({ ...userIgnoredData, [type]: tableData.map((d: any) => d.key) });
            }
          }
        } : undefined}
        columns={columns}
        dataSource={tableData}
        bordered
        pagination={false}
        scroll={{ x: 450, y: 250 }}
      /> : <Table
        title={() => (
          <div style={{ marginBottom: "16px" }}>
            <span className="highlighted">{t("common.trials")} :</span>{" "}
            {dataCount || filterData.length}
          </div>
        )}
        columns={columns}
        dataSource={tableData}
        bordered
        pagination={false}
        scroll={{ x: 450, y: 250 }}
      />}
      {from === "custom_ml" && (
        <Pagination
          style={{ textAlign: "right", marginTop: "1rem" }}
          current={pagination?.current || 1}
          total={dataCount}
          pageSize={pagination?.pageSize || 10}
          pageSizeOptions={[10, 20, 30, 40, 50]}
          onChange={(current, pageSize) => {
            submitDataFilter(current, pageSize, activeWorkOrderId, stage);
          }}
          showTotal={(total) => `${t("common.total")} ${dataCount || filterData.length} ${t("common.items")}`}
        />
      )}
    </Space>
  );
};
