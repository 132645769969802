import { Collapse, Divider, Space, Spin, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { PredictedProperties } from "./PredictedProperties";
import {
  WOCharacterizationsComparisonTable,
  WOIngredientsComparisonTable,
  WOProcessingComparisonTable,
  WOPropertiesComparisonTable,
  LinkedMethodTable,
  OutputCostingTable,
  FormulationsCostingTable,
  ProcessingCostingTable,
} from "./WorkOrderTable";
import { NestleProfiles } from "../Datasheet/Processing/NestleProfiles";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { AsyncStates } from "src/constants";
import { LoadingOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { Text } = Typography;

export const WorkOrderTableWrapper = ({
  disabled,
  userAccess,
  checkBoxChange,
  formulationsCheck,
  formulationList,
  from,
  filters,
  tableId = "",
}: any) => {
  const [t] = useTranslate();
  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder
  );
  const displayNamesStatus = useSelector(
    (state: StoreState) => state.displayNames.status
  );
  const allMethodsStatus = useSelector(
    (state: StoreState) => state.repository.allMethods.status
  );
  const experimentStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.experimentStatus
  );
  const linkedExperimentDetailsStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.linkedExperimentDetailsStatus
  );
  const linkedExperimentStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.linkedExperimentStatus
  );
  const linkedExperimentDetails = useSelector(
    (state: StoreState) => state.workOrderDetails.linkedExperimentDetails
  );
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data
  );
  const configs = useSelector((state: StoreState) => state.configs.features);

  const initialFormulations = useMemo(
    () => formulationList?.filter((res: any) => !res?.predicted),
    [formulationList]
  );
  const suggestedFormulations = useMemo(
    () => formulationList?.filter((res: any) => !!res?.predicted),
    [formulationList]
  );

  const [displayTables, setDisplayTables] = useState<any>({
    formulations: false,
    processing: false,
    properties: false,
    characterizations: false,
    procProfilesCheck: false,
    methods: false,
  });

  useEffect(() => {
    const tableObj: any = {
      trialsCount: 0,
      tables: [],
    };
    const obj = {
      formulations: false,
      processing: false,
      properties: false,
      characterizations: false,
      procProfilesCheck: false,
      methods: false,
    };
    formulationList?.forEach((res: any) => {
      if (Object.keys(res?.ingredients || {}).length) {
        obj.formulations = true;
        tableObj["tables"].push("formulation");
      }
      if (Object.keys(res?.processing?.[0]?.processing || {}).length) {
        obj.processing = true;
        tableObj["tables"].push("processing");
      }
      if (Object.keys(res?.processing?.[0]?.meta?.profile_id || {}).length) {
        obj.procProfilesCheck = true;
      }
      if (
        Object.keys(res?.characterizations?.[0]?.characterizations || {}).length
      ) {
        obj.characterizations = true;
        tableObj["tables"].push("characterizations");
      }
      if (Object.keys(res?.properties?.[0]?.properties || {}).length) {
        obj.properties = true;
        tableObj["tables"].push("property_sets");
      }
      if (
        (Array.isArray(res?.characterizations?.[0]?.characterizations)
          && res?.characterizations?.[0]?.characterizations?.length)
        ||
        (Array.isArray(res?.properties?.[0]?.properties)
          && res?.properties?.[0]?.properties?.length)
      ) {
        obj.methods = true;
      }
    });
    setDisplayTables(obj);
  }, [formulationList, displayNames]);

  return (
    <Spin
      spinning={
        experimentStatus === AsyncStates.LOADING ||
        linkedExperimentDetailsStatus === AsyncStates.LOADING ||
        linkedExperimentStatus === AsyncStates.LOADING ||
        displayNamesStatus === AsyncStates.LOADING ||
        allMethodsStatus === AsyncStates.LOADING
      }
      indicator={<LoadingOutlined />}
    >
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        {displayTables.formulations &&
          from === "drawer" &&
          Boolean(configs?.nestle_configs) &&
          userAccess?.stages?.includes("formulation_stage") && (
            <Collapse defaultActiveKey={["1"]}>
              <Panel header={<Text strong>{t("common.stage1")}</Text>} key="1">
                <Space
                  size="small"
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {!!initialFormulations.length && (
                    <WOIngredientsComparisonTable
                      formulationList={linkedExperimentDetails.filter(
                        (res: any) =>
                          formulationList?.[0]?.experiment_id !==
                          res?.experiment_id &&
                          formulationList?.[0]?.formulation_merge_id ===
                          res?.formulation_merge_id
                      )}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_ingredient_table`}
                    />
                  )}
                </Space>
              </Panel>
            </Collapse>
          )}

        {displayTables.formulations &&
          userAccess?.stages?.includes("formulation_stage") && (
            <Collapse defaultActiveKey={["1"]}>
              <Panel
                header={<Text strong>{t("common.formulations")}</Text>}
                key="1"
              >
                <Space
                  size="small"
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {!!initialFormulations.length && (
                    <WOIngredientsComparisonTable
                      formulationList={initialFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_ingredient_table`}
                    />
                  )}
                  {!!suggestedFormulations?.length &&
                    !!initialFormulations.length && <Divider />}
                  {!!suggestedFormulations.length && (
                    <WOIngredientsComparisonTable
                      formulationList={suggestedFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_ingredient_table`}
                    />
                  )}
                </Space>
              </Panel>
            </Collapse>
          )}

        {displayTables.processing &&
          userAccess?.stages?.includes("processing_stage") && (
            <Collapse defaultActiveKey={["2"]}>
              <Panel
                header={<Text strong>{t("common.processing")}</Text>}
                key="2"
              >
                <Space
                  size="small"
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {!!initialFormulations.length && (
                    <WOProcessingComparisonTable
                      formulationList={initialFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_processing_table`}
                    />
                  )}
                  {!!suggestedFormulations?.length &&
                    !!initialFormulations.length && <Divider />}
                  {!!suggestedFormulations.length && (
                    <WOProcessingComparisonTable
                      formulationList={suggestedFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_processing_table`}
                    />
                  )}
                </Space>
              </Panel>
            </Collapse>
          )}

        {displayTables.procProfilesCheck &&
          userAccess?.stages?.includes("processing_stage") && (
            <Collapse defaultActiveKey={["12"]}>
              <Panel
                header={<Text strong>{t("common.processing")}</Text>}
                key="12"
              >
                <Space
                  size="small"
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {!!initialFormulations.length && (
                    <NestleProfiles
                      initialTrials={initialFormulations}
                      disabled={true}
                      size={"small"}
                    />
                  )}
                  {!!suggestedFormulations?.length &&
                    !!initialFormulations.length && <Divider />}
                  {!!suggestedFormulations.length && (
                    <NestleProfiles
                      initialTrials={suggestedFormulations}
                      disabled={true}
                      size={"small"}
                    />
                  )}
                </Space>
              </Panel>
            </Collapse>
          )}

        {displayTables.methods &&
          (userAccess?.stages?.includes("characterization_stage") ||
            userAccess?.stages?.includes("properties_stage")) && (
            <Collapse defaultActiveKey={["3"]}>
              <Panel header={<Text strong>{t("common.methods")}</Text>} key="3">
                <Space
                  size="small"
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {!!initialFormulations.length && (
                    <LinkedMethodTable
                      formulationList={initialFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      filters={filters}
                      tableId={tableId}
                    />
                  )}
                  {!!suggestedFormulations?.length &&
                    !!initialFormulations.length && <Divider />}
                  {!!suggestedFormulations.length && (
                    <LinkedMethodTable
                      formulationList={suggestedFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      filters={filters}
                      tableId={tableId}
                    />
                  )}
                </Space>
              </Panel>
            </Collapse>
          )}

        {Boolean(configs?.work_order_costing) &&
          displayTables.formulations &&
          !formulationList.some(
            (res: any) => res?.ingredients?.substrate?.value
          ) && (
            <Collapse defaultActiveKey={["14"]}>
              <Panel
                header={<Text strong>{"Formulations Costing"}</Text>}
                key="14"
              >
                <FormulationsCostingTable formulationList={formulationList} />
              </Panel>
            </Collapse>
          )}

        {Boolean(configs?.work_order_costing) &&
          displayTables.procProfilesCheck &&
          !formulationList.some(
            (res: any) => res?.ingredients?.substrate?.value
          ) && (
            <Collapse defaultActiveKey={["15"]}>
              <Panel
                header={<Text strong>{"Processing Costing"}</Text>}
                key="15"
              >
                <ProcessingCostingTable formulationList={formulationList} />
              </Panel>
            </Collapse>
          )}

        {Boolean(configs?.work_order_costing) &&
          (displayTables.procProfilesCheck || displayTables.properties) &&
          !formulationList.some(
            (res: any) => res?.ingredients?.substrate?.value
          ) && (
            <Collapse defaultActiveKey={["16"]}>
              <Panel header={<Text strong>{"Output Costing"}</Text>} key="16">
                <OutputCostingTable formulationList={formulationList} />
              </Panel>
            </Collapse>
          )}

        {displayTables.characterizations &&
          !displayTables.methods &&
          userAccess?.stages?.includes("characterization_stage") && (
            <Collapse defaultActiveKey={["4"]}>
              <Panel
                header={<Text strong>{t("common.characterization")}</Text>}
                key="4"
              >
                <Space
                  size="small"
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {!!initialFormulations.length && (
                    <WOCharacterizationsComparisonTable
                      formulationList={initialFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_characterization_table`}
                    />
                  )}
                  {!!suggestedFormulations?.length &&
                    !!initialFormulations.length && <Divider />}
                  {!!suggestedFormulations.length && (
                    <WOCharacterizationsComparisonTable
                      formulationList={suggestedFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_characterization_table`}
                    />
                  )}
                </Space>
              </Panel>
            </Collapse>
          )}

        {displayTables.properties &&
          !displayTables.methods &&
          userAccess?.stages?.includes("properties_stage") && (
            <Collapse defaultActiveKey={["5"]}>
              <Panel
                header={<Text strong>{t("formulations.type.properties")}</Text>}
                key="5"
              >
                <Space
                  size="small"
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  {!!initialFormulations.length && (
                    <WOPropertiesComparisonTable
                      formulationList={initialFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_property_table`}
                    />
                  )}
                  {(!!workOrder?.suggested_experiment_id?.length || !!workOrder?.prediction_experiment_ids?.length) &&
                    initialFormulations.some((res: any) => !!Object.keys(res?.predicted_properties || {})?.length) &&
                    userAccess?.stages?.includes("properties_stage") ? (
                    <PredictedProperties
                      experiments={initialFormulations}
                      title={`${t("common.predictedProperties")} (${t(
                        "closedWO.basedOnAIRecommendation"
                      )})`}
                    />
                  ) : null}
                  {!!suggestedFormulations?.length &&
                    !!initialFormulations.length && <Divider />}
                  {!!suggestedFormulations.length && (
                    <WOPropertiesComparisonTable
                      formulationList={suggestedFormulations}
                      checkbox={!disabled}
                      formulationsCheck={formulationsCheck}
                      checkBoxChange={checkBoxChange}
                      from={from}
                      tableId={tableId + `_property_table`}
                    />
                  )}
                  {(!!workOrder?.suggested_experiment_id?.length || !!workOrder?.prediction_experiment_ids?.length) &&
                    suggestedFormulations.some((res: any) => !!Object.keys(res?.predicted_properties || {})?.length) &&
                    userAccess?.stages?.includes("properties_stage") ? (
                    <PredictedProperties
                      experiments={suggestedFormulations}
                      title={`${t("common.predictedProperties")} (${t(
                        "closedWO.basedOnAIRecommendation"
                      )})`}
                    />
                  ) : null}
                </Space>
              </Panel>
            </Collapse>
          )}
      </Space>
    </Spin>
  );
};
