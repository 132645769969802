import { Drawer, Select, Table, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { TEXT_PRIMARY, BORDER_STYLE, StyledText } from '../Dashboard'
import CountDesc from './CountDesc'
import WorkordersTable from '../RightSidebar/CardSection/WorkordersTable'
import { useState } from 'react'

const ProjectTrials = () => {
  const { projectList: projects } = useSelector(
    (state: StoreState) => state.projects
  )

  const { dashboardActivityCardTotalResponse } = useSelector(
    (state: StoreState) => state.dashboard
  )

  const [t] = useTranslate()

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [selectedProjectId, setSelectedProjectId] = useState('')

  const userColumns = [
    {
      title: t('common.project'),
      dataIndex: 'project_name'
    },
    {
      title: t('common.trials'),
      dataIndex: 'total_trials',
      render: (text: any, record: any) => (
        <Typography.Title
          style={{
            color: TEXT_PRIMARY,
            margin: 0
          }}
          level={5}
        >
          {record.total_trials}
        </Typography.Title>
      ),
      align: 'right' as 'right'
    }
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        outline: BORDER_STYLE,
        gap: 12,
        padding: '12px 12px'
      }}
    >
      <div
        style={{
          padding: '0px 12px'
        }}
      >
        <CountDesc
          count={dashboardActivityCardTotalResponse?.total_trials}
          desc={t('dashboard.totalTrialsConducted')}
        />
      </div>
      <Table
        dataSource={projects
          .map((project: any) => ({
            project_name: project.name,
            total_trials_number: project.total_trials || 0,
            total_trials: `${project.total_trials || 0} ${t('common.trials')}`,
            project_id: project?.project_id
          }))
          .sort(
            (a: any, b: any) => b.total_trials_number - a.total_trials_number
          )}
        columns={userColumns}
        pagination={false}
        style={{ width: '100%', padding: '0px 0px', scrollbarWidth: 'none' }}
        scroll={{ y: 220 }}
        className="dashboard-project-trials-table"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              setSelectedProjectId(record.project_id)
              setIsDrawerVisible(true)
            }
          }
        }}
      />

      <ProjectsTrialsDrawer
        selectedProjectId={selectedProjectId}
        setSelectedProjectId={setSelectedProjectId}
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
      />
    </div>
  )
}

export default ProjectTrials

const ProjectsTrialsDrawer = ({
  selectedProjectId,
  setSelectedProjectId,
  isDrawerVisible,
  setIsDrawerVisible
}: any) => {
  const [t] = useTranslate()

  const { projectList: projects } = useSelector(
    (state: StoreState) => state.projects
  )

  return (
    <Drawer
      open={isDrawerVisible}
      onClose={() => {
        setIsDrawerVisible(false)
      }}
      contentWrapperStyle={{
        maxWidth: 1200,
        minWidth: 950
      }}
      title={t('dashboard.totalTrialsConducted')}
      extra={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8
          }}
        >
          {StyledText(`${t('common.project')}:`)}
          <Select
            value={selectedProjectId}
            onChange={(value: any) => setSelectedProjectId(value)}
            style={{ width: 300 }}
            placeholder={t('common.project')}
            options={projects?.map((project: any) => ({
              value: project.project_id,
              label: (
                <div
                  style={{
                    display: 'flex',
                    gap: 4
                  }}
                >
                  {StyledText(project.name)}
                  {StyledText(`(${project.total_trials} ${t('common.trials')})`)}
                </div>
              )
            }))}
          ></Select>
        </div>
      }
    >
      <WorkordersTable selectedProjectId={selectedProjectId} showOnlyCurrentUser={false} />
    </Drawer>
  )
}
