import { SearchOutlined } from '@ant-design/icons'
import { Input, Modal, Space, Table, Typography } from 'antd'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { ReactivateAccountModal } from './ReactivateAccountModal'

type DeactivatedAccountsModalProps = {
    showDeactivatedAccountModalVisible: boolean,
    setShowDeactivatedAccountModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const { Text } = Typography

export type TeamsUserInfoProps = {
    company_id: string
    company_location: string
    revoked_access: boolean
    role: string
    user_email: string
    user_id: string
    user_name: string
}

export const DeactivatedAccountsModal = ({ setShowDeactivatedAccountModalVisible, showDeactivatedAccountModalVisible }: DeactivatedAccountsModalProps) => {
    const [t] = useTranslate()

    const users = useSelector((state: StoreState) => state.teams?.data)
    const [filterTerm, setFilterTerm] = useState("")
    const [reactivateModalVisible, setReactivateModalVisible] = useState({
        isModalVisible: false,
        userData: {}
    })

    const deactivatedUsersList = useMemo(() => {
        const usersData = users.filter((user: any) => (user?.user_name.toLowerCase()?.includes(filterTerm?.toLocaleLowerCase()) || user?.user_email?.toLowerCase()?.includes(filterTerm?.toLocaleLowerCase())))
        return usersData.filter((user) => user.revoked_access)
    }, [users, filterTerm])

    const handleReactivateAccount = (userInfo: TeamsUserInfoProps) => {
        setReactivateModalVisible({
            isModalVisible: true,
            userData: userInfo
        })
    }

    const columns = [
        {
            key: "user_name",
            title: t("teams.inviteMember"),
            render: (rowValue: any, row: any, index: any): any => (
                <Space>
                    <Space direction="vertical" style={{ rowGap: 0 }}>
                        <Text strong>{row?.user_name}</Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: t("common.email"),
            dataIndex: 'user_email',
        },
        {
            title: t("profile.roles"),
            dataIndex: 'role',
            render: (text: string) => text.charAt(0).toUpperCase() + text.slice(1)
        },
        {
            key: "Reactivate Account",
            title: t("common.reactivateAccount"),
            render: (rowValue: any, row: any, index: any): any => (
                <Space style={{ float: "right" }}>
                    <StyledButton
                        onClick={() => {
                            handleReactivateAccount(row)
                        }}
                    >
                        {t("common.reactivateAccount")}
                    </StyledButton>
                </Space>
            ),
        },
    ]


    return (
        <>
            <Modal
                open={showDeactivatedAccountModalVisible}
                onCancel={() => setShowDeactivatedAccountModalVisible(false)}
                title={t("common.deactivatedAccounts")}
                footer={null}
                width={800}
            >
                <Space style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Space>
                        <Input
                            prefix={<SearchOutlined />}
                            style={{ width: "310px" }}
                            value={filterTerm}
                            placeholder={t("teams.searchforMembersByNameOrEmail")}
                            onChange={(e) => {
                                setFilterTerm(e.target.value)
                            }}
                            allowClear
                        />
                    </Space>
                </Space>

                <Space
                    direction="vertical"
                    style={{ width: "100%", marginBottom: "1rem" }}
                >
                    <Table
                        dataSource={deactivatedUsersList}
                        columns={columns}
                        // loading={{ spinning: AsyncStates.LOADING === invitationListStatus, indicator: <LoadingOutlined /> }}
                        pagination={{ pageSize: 5 }}
                    />
                </Space>

            </Modal>
            <ReactivateAccountModal reactivateModalVisible={reactivateModalVisible} setReactivateModalVisible={setReactivateModalVisible} setShowDeactivatedAccountModalVisible={setShowDeactivatedAccountModalVisible} />
        </>
    )
}
