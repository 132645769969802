import React from "react"
import { PivotTableData } from "./types"
import { Table as AntdTable } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { useValue } from "src/utils/useValue"

type P = {
	data: PivotTableData["table"]
	x_label: string
	y_label: string
	getDisplayName: (ingredient: string) => string
}


export const Table = ({ data, x_label, y_label, getDisplayName }: P) => {
	const response = useSelector((state: StoreState) => state.pivotTable)
	const { getValue: getEUValue } = useValue()

	const getFormatedTitle = (label: string) => {
		const inputStr = label.replace(" (binned)", "")
		const result = getDisplayName(inputStr)
		const displayName = label.includes(" (binned)") ? (response?.data?.formulation_display_names?.[result] ?? result) + " (binned)" : result
		return displayName
	}

	const getColumns = () => {
		const columns: ColumnsType<object> = Object.keys(data).map((key, i) => {
			return {
				key: key,
				dataIndex: key,
				title: key,
				align: "center",
				children: [
					{
						key: key,
						dataIndex: key,
						title: "",
						align: "center",
					},
				],
			}
		})

		return [
			{
				key: x_label,
				dataIndex: x_label,
				title: getFormatedTitle(x_label),
				children: [
					{
						key: y_label,
						dataIndex: y_label,
						title: getFormatedTitle(y_label),
					},
				],
			},
			...columns,
		] as ColumnsType<object>
	}

	const getDataSource = () => {
		const firstKey = Object.keys(data)[0]
		const rowsElement = Object.keys(data[firstKey])

		return rowsElement.map((element) => {
			const columns = getColumns()

			const object = {
				[x_label]: element,
				[y_label]: element,
			}

			columns.slice(1).forEach((column) => {
				const key = column.key as string
				const trimmedData = data[key][element].split(" ", 1)?.[0] ?? ""
				const restvalue = data[key][element].replace(trimmedData, "").trim()
				object[key] = `${getEUValue(trimmedData)} ${restvalue}`
			})

			return object
		})
	}

	return (
		<div>
			<AntdTable
				columns={getColumns()}
				dataSource={getDataSource()}
				pagination={false}
				bordered={false}
			/>
		</div>
	)
}
