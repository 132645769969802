import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import RepositoryActionDrawer from "../Common/RepositoryActionDrawer";
import { useDispatch, useSelector } from "react-redux";
import { removeAdditiveRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    searchTerm: string | undefined;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    setSelectedAdditive: React.Dispatch<React.SetStateAction<string | undefined>>
    setMode: React.Dispatch<React.SetStateAction<"edit" | "create" | "delete">>
};

const AdditiveDetails = ({ searchTerm, setSelectedAdditive, setMode, setShowModal }: TProps) => {
    const [t] = useTranslate()
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedAdditives, setSelectedAdditives] = useState<string[]>([]);
    const additives = useSelector(
        (state: StoreState) => state.repository.additives.data
    );
    const categories = useSelector(
        (state: StoreState) => state.repository.categories.data
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedAdditives.length > 0) setDrawerOpen(true);
        else setDrawerOpen(false);
    }, [selectedAdditives]);

    useEffect(() => {
        // Update selected additives when changed
        const availableAdditives = additives.map((c) => c.additive_id);
        setSelectedAdditives((prev) =>
            prev.filter((c) => availableAdditives.includes(c))
        );
    }, [additives]);

    const onRowSelection = (
        selectedRowKeys: React.Key[],
        selectedRows: any[]
    ) => {
        setSelectedAdditives(selectedRowKeys.map((k) => String(k)));
    };

    const deleteAdditives = useCallback(
        (ids?: string[]) => {
            setMode("delete");
            dispatch(
                removeAdditiveRequest({ additive_ids: ids ? ids : selectedAdditives })
            );
        },
        [dispatch, selectedAdditives, setMode]
    );

    const takeAction = useCallback(
        (action: string, record: any) => {
            setSelectedAdditive(record.key);

            if (action === "edit") {
                setShowModal(true);
                setMode("edit");
            }
            if (action === "delete") {
                deleteAdditives([record.key]);
            }
        },
        [deleteAdditives, setSelectedAdditive, setShowModal, setMode]
    );

    const onDismiss = () => {
        setSelectedAdditives([]);
        setDrawerOpen(false);
    }

    const columns: any = useMemo(
        () => [
            {
                title: t("common.Name"),
                dataIndex: "name",
                key: "name",
                width: 200,
            },
            {
                title: t("common.Category"),
                dataIndex: "category",
                key: "category",
            },
            {
                title: "",
                dataIndex: "",
                key: "action",
                fixed: "right",
                width: 40,
                render: (record: any) => (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: (
                                        <div>
                                            <EditOutlined /> {t("common.edit")}
                                        </div>
                                    ),
                                    key: "edit",
                                },
                                {
                                    label: (
                                        <div style={{ color: "#F5222D" }}>
                                            <DeleteOutlined /> {t("common.delete")}
                                        </div>
                                    ),
                                    key: "delete",
                                },
                            ],
                            onClick: (event) => takeAction(event.key, record),
                        }}
                        trigger={["click"]}
                    >
                        <MoreOutlined />
                    </Dropdown>
                ),
            },
        ],
        [takeAction, t]
    );

    const data = useMemo(() => {
        const filteredData = searchTerm
            ? additives.filter((c) =>
                JSON.stringify(Object.values(c))
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
            : additives;
        return filteredData.map((c) => ({
            key: c.additive_id,
            name: c.name,
            category: categories.find(category => category.category_id === c.category_id)?.name || "",
        })).sort((a, b) => a.name.localeCompare(b.name));
    }, [additives, searchTerm, categories]);

    return (
        <>
            <div className="additive-details-container">
                <Table
                    bordered={false}
                    rowSelection={{
                        type: "checkbox",
                        onChange: onRowSelection,
                        selectedRowKeys: selectedAdditives,
                        hideSelectAll: true
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    className="repository-table"
                />
            </div>
            <RepositoryActionDrawer
                open={drawerOpen}
                close={onDismiss}
                selected={selectedAdditives.length}
                onDelete={deleteAdditives}
            />

        </>
    );
};

export default AdditiveDetails;
