import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
    Space,
    Collapse,
    Typography,
    Row,
    Card,
    Tooltip,
    Switch,
    Col,
    Select,
    Input,
    Form,
    List,
} from "antd";
import { CloseOutlined, EyeInvisibleOutlined, EyeOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { CelsureChart } from "../CelsureChart/CelsureChart"
import { StyledCard } from "src/styled_components/StyledCard"
import { Option } from "antd/lib/mentions"
import { CelsureDoc } from "../Formulations/CelsureDoc"
import { PDFViewer, pdf } from "@react-pdf/renderer"
import { Document, Page } from "react-pdf"
import { antdTheme, AsyncStates } from "src/constants"
import {
	celsureWoRetrieveReportRequest,
	celsureWoUploadReportRequest,
} from "src/store/actions/celsureReportPreview"
import { celsureWoUpdateRequest } from "src/store/actions/celsureWo"
import { StyledButton } from "src/styled_components/StyledButton"
import { ListItem } from "src/components/Formulation/Compare/ListItem"
import useTranslate from "src/utils/useTranslate"
import { celsureReportUploadRequest } from "src/store/actions/celsureCompareFormulation"
import { Plots } from "./Plots"
import { setIsEditing } from "src/store/actions/isEditing"
import { CustomPrompt } from "src/utils/CustomPrompts"
import Plot from "react-plotly.js"
import Plotly from "plotly.js"

const { Panel } = Collapse
const { Text } = Typography

export const WorkOrderReportPreview = ({ celsureWo, from }: any) => {

	const plotLayout: any = {
		xaxis: {
			showspikes: true,
			spikemode: 'toaxis',
			title: {
				text: 'Time',
				font: {
					family: "Courier New, monospace",
					size: 18,
					color: "#11111",
				},
			},
		},
		yaxis: {
			showspikes: true,
			spikemode: 'toaxis',
			title: {
				text: 'Temperature',
				font: {
					family: "Courier New, monospace",
					size: 18,
					color: "#11111",
				},
			},
		},
	}
	const dispatch = useDispatch()
	const [t] = useTranslate()

	const company = useSelector((state: StoreState) => state.login.loginResponse)
	const reportList = useSelector(
		(state: StoreState) => state.celsureCompareFormulation.reports
	)

	const [displayTables, setDisplayTables] = useState<any>({ formulations: [] })
	const [plots, setPlots] = useState<any[]>([])
	const [editMode, setEditMode] = useState<boolean>(true)
	const [report, setReport] = useState<any>({})
	const [tableData, setTableData] = useState<any>({ trialsCount: 0 })
	const [disableButton, setDisableButton] = useState<boolean>(false)
	const [tableChecks, setTableChecks] = useState<any>(true)
	const { celsureReportUploadStatus } = useSelector(
		(state: StoreState) => state.celsureReportPreview
	)
	const [celsureWoTrialsList, setCelsureWoTrialsList] = useState<any[]>([])
	const [link, setLink] = useState<string>("")
	const [numOfPages, setNumOfPages] = useState<number>()
	const [reportName, setReportName] = useState<string>("")
	const [reportLists, setreportLists] = useState<any[]>([])
	const [includeGraph, setIncludeGraph] = useState<Boolean>(false)
	const [actualGraphView, setActualGraphView] = useState<Boolean>(false)
	const { ceslurePlotsData, celsureWoTrials } = useSelector((state: StoreState) => state.celsureWo)
	const {
		data: { wo_reports },
	} = useSelector((state: StoreState) => state.celsureReportPreview)
	const { formulationDetailsData: celsureTrialList } = useSelector(
		(state: StoreState) => state.celsureCompareFormulation
	)
	const isEditing = useSelector((state: StoreState) => state.isEditing)

	useEffect(() => {
		if (from !== "compare_formulation") {
			dispatch(
				celsureWoRetrieveReportRequest({
					workOrderId: celsureWo?.work_order_id,
				})
			)
		}
	}, [dispatch, celsureWo?.work_order_id, from])

	useEffect(() => {
		if (from === "compare_formulation") {
			setCelsureWoTrialsList(celsureTrialList)
			setreportLists(reportList)
		} else {
			setCelsureWoTrialsList(celsureWoTrials)
			setreportLists(wo_reports)
		}
	}, [from, celsureWoTrials, celsureTrialList, reportList, wo_reports])
	const [checkedData, setCheckedData] = useState<any>(
		celsureWoTrialsList?.map((res: any) => true)
	)

	useEffect(() => {
		setCheckedData(celsureWoTrialsList?.map((res: any) => true))
	}, [celsureWoTrialsList])

	useEffect(() => {
		const woTrialsData = Object.keys(celsureWoTrialsList?.[0] || {})
			.filter(
				(data: any) =>
					![
						"access",
						"work_order_id",
						"trial_id",
						"project_id",
						"approved",
						"user_id",
						"updated_by",
						"pcm_type",
						"water",
						"insulation_sheets",
						"temperature_profile",
					].includes(data)
			)
			.map((trial: any) => ({
				identifier: trial,
				fields: trial
					?.split("_")
					?.map((res: any) => res?.[0]?.toUpperCase() + res?.substring(1))
					?.join(" "),
			}))
		celsureWoTrialsList.forEach((res: any) => {
			woTrialsData.forEach((data: any, i: any) => {
				data[res.trial_id] = res[data.identifier]
				data.key = i
			})
		})
		setDisplayTables({ formulations: woTrialsData })
	}, [celsureWoTrialsList])

	useEffect(() => {
		if (
			checkedData.reduce((sum: any, element: any) => sum + element, 0) === 0
		) {
			setTableChecks(false)
		} else {
			setTableChecks(true)
		}
	}, [checkedData])

	useEffect(() => {
		if (!!plots.length) {
			let key = false
			plots.forEach((res) => {
				if (!res?.selectedTrials?.length || !res.selectedProperties?.length) {
					key = true
				}
			})
			key ? setDisableButton(true) : setDisableButton(false)
		} else {
			setDisableButton(false)
		}
	}, [plots])

	const generate = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		dispatch(setIsEditing(true))
		setReport({})
		e.stopPropagation()
		const plotsWithGraph = await Promise.all(
			[...plots, ...new Set(plots.flatMap(value => value.selectedTrials)?.filter((value: string) => value))]?.map(async (plot: any, index: any) => {
				if (typeof plot === 'object') {
					let svgDom: any = document?.getElementsByClassName(
						"highcharts-root" || ""
					)
					svgDom[index].setAttribute("id", "plot" + plot.id)
					const canvas = document.createElement("canvas")
					const ctx = canvas.getContext("2d")
					const svgXml = new XMLSerializer().serializeToString(svgDom[index])
					canvas.width = svgDom[index]?.width?.baseVal?.value
					canvas.height = svgDom[index]?.height?.baseVal?.value
					const img = document.createElement("img")
					img.setAttribute(
						"src",
						"data:image/svg+xml;base64," +
						window.btoa(unescape(encodeURIComponent(svgXml)))
					)
					try {
						await img.decode()
						ctx?.drawImage(
							img,
							0,
							0,
							svgDom[index]?.width?.baseVal?.value,
							svgDom[index]?.height?.baseVal?.value
						)
						return Promise.resolve({
							...plots[index],
							...{ dataUrl: canvas.toDataURL("image/png", 1.0) },
						})
					} catch (error) {
						return Promise.reject()
					}
				} else {
					return document.getElementById(plot) ? Plotly.toImage(document.getElementById(plot), { format: 'png', width: 800, height: 600 }).then((dataUrl: string) => ({ plot, dataUrl, description: '' })) : Promise.resolve(plot)
				}
			})
		)
		if (plotsWithGraph.length) setEditMode(false)
		setReport({ graphs: plotsWithGraph, tableData, checkedData })
	}

	const save = ({ filename }: any) => {
		if (from === "compare_formulation") {
			pdf(
				<CelsureDoc
					report={report}
					company={company}
					celsureWoTrialsList={celsureWoTrialsList}
					displayTables={displayTables}
				/>
			)
				.toBlob()
				.then((report) =>
					dispatch(
						celsureReportUploadRequest({
							report,
							metadata: {
								filename,
								plots,
								selectedTrials: celsureWoTrialsList.map(
									({ trial_id }: any) => trial_id
								),
							},
						})
					)
				)
				.catch((error) => error)
		} else {
			pdf(
				<CelsureDoc
					report={report}
					company={company}
					celsureWoTrialsList={celsureWoTrialsList}
					displayTables={displayTables}
				/>
			)
				.toBlob()
				.then((report) =>
					dispatch(
						celsureWoUploadReportRequest({
							payload: {
								workOrderId: celsureWo?.work_order_id,
								filename,
								trialsIds: celsureWoTrialsList
									.filter((res: any, index) => checkedData[index])
									.map(({ trial_id }: any) => trial_id),
							},
							report,
						})
					)
				)
				.catch((error) => error)
		}
		dispatch(setIsEditing(false))
	}

	return (
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
			<CustomPrompt
				isEditing={isEditing as boolean}
				message={`${t("common.unsavedChangesLost")}!.`}
			/>
			<Plots from={from} />
			<Card
				title={
					<Space>
						<Text>{t("common.buildAReport")}</Text>
					</Space>
				}
				actions={[
					plots?.length ||
						(tableChecks && tableData?.tables?.length) ||
						(from === "compare_formulation" &&
							(tableData?.tables?.length || plots?.length)) ? (
						<Tooltip
							title={
								disableButton
									? "Select Trials and properties"
									: t("common.generateReport")
							}
						>
							<StyledButton
								type="primary"
								onClick={generate}
								size="large"
								disabled={disableButton}
							>
								{report?.graph?.length || report?.tableData
									? t("common.update")
									: t("common.generate")}
							</StyledButton>
						</Tooltip>
					) : (
						""
					),
				]}
				extra={
					<Space>
						{report?.graphs?.length ? (
							<>
								{" "}
								<Text type="secondary">{t("compare.modifyChart")}</Text>
								<Switch
									checked={editMode}
									onChange={() => setEditMode((prevState) => !prevState)}
								/>{" "}
							</>
						) : null}
					</Space>
				}
			>
				<Space size="large" direction="vertical" style={{ width: "100%" }}>
					<CelsureReportTable
						setTableData={setTableData}
						displayTables={displayTables}
					/>
					{plots?.map((plot) => (
						<CelsureChart
							editMode={editMode}
							allPlots={plots}
							plot={plot}
							setPlots={setPlots}
							key={plot.id}
							celsureWoTrialsList={celsureWoTrialsList}
							displayTables={displayTables}
						/>
					))}
					<Row style={{ justifyContent: 'center' }}>
						{editMode && (
							<StyledButton
								size="large"
								onClick={() => {
									setPlots((state) =>
										state.concat([
											{
												id:
													"report_section_" +
													(Number(
														state.slice(-1)[0]?.id.split("_").slice(-1)[0]
													) + 1 || 0),
											},
										])
									)
									dispatch(setIsEditing(true))
								}}
								type="dashed"
								icon={<PlusOutlined />}
							>
								{t("common.addChart")}
							</StyledButton>
						)}
						<Tooltip title={[...new Set(plots.flatMap(value => value.selectedTrials)?.filter((value: string) => value))].length === 0 && 'Please “Add Charts” first and select the trials'}>
							<StyledButton
								size="large"
								type="dashed"
								style={{ marginLeft: '2rem' }}
								danger={includeGraph as boolean}
								disabled={[...new Set(plots.flatMap(value => value.selectedTrials)?.filter((value: string) => value))].length === 0}
								icon={!includeGraph ? <PlusOutlined /> : <CloseOutlined style={{ color: 'red' }} />}
								onClick={() => {
									setIncludeGraph(!includeGraph)
									if (!includeGraph) setActualGraphView(false)
								}}
							>
								{!includeGraph ? 'Add Actual Experiment Graph' : 'Remove Actual Experiment Graph'}
							</StyledButton>
						</Tooltip>

					</Row>
					{includeGraph && <Collapse collapsible='icon' defaultActiveKey={['1']} >
						<Panel showArrow={false} header={<><span>Actual Experiment Graph</span><Tooltip title="Trials with uploaded graph data are plotted"><InfoCircleOutlined style={{ color: antdTheme.colorPrimary, marginLeft: '0.5rem' }} /></Tooltip> </>} key="1">
							<Row style={{ justifyContent: 'center' }}>
								{includeGraph && <StyledButton
									size="large"
									type="dashed"
									style={{ marginLeft: '2rem' }}
									disabled={[...new Set(plots.flatMap(value => value.selectedTrials)?.filter((value: string) => value))].length === 0}
									icon={!actualGraphView ? <EyeOutlined /> : <EyeInvisibleOutlined />}
									onClick={() => setActualGraphView(!actualGraphView)}
								>
									{!actualGraphView ? 'View Actual Experiment Graph' : 'Hide Actual Experiment Graph'}
								</StyledButton>}
							</Row>
							<div style={{ display: actualGraphView ? 'block' : 'none', marginTop: '1rem' }}>
								{[...new Set(plots.flatMap(value => value.selectedTrials)?.filter((value: string) => value))].length > 0 && ceslurePlotsData.filter((plot: any) => [...new Set(plots.flatMap(value => value.selectedTrials)?.filter((value: string) => value))]?.includes(plot.trial_id) && plot.actual).map((plot: any) => {
									return <Plot key={plot.trial_id}
										layout={{
											title: plot?.trial_display_id, showlegend: true, hovermode: 'closest', ...plotLayout
										}}
										className={plot.trial_id}
										divId={plot.trial_id}
										data={[
											{
												x: plot?.actual?.["Time"] || plot?.actual?.["time"] as any,
												y: plot?.actual?.[plot.trial_display_id] || plot?.actual?.["trial_data"] as any,
												type: 'scatter',
												name: 'Ambient Temperature',
											},
											...(Object.keys(plot?.actual || {}).filter((res: any) => !["time", "Time", "trial_data", plot?.trial_display_id].includes(res))
												.map((res: any) => ({
													x: plot?.actual?.["time"] || plot?.actual?.["Time"] as any,
													y: plot?.actual?.[res] || [] as any,
													type: 'scatter',
													name: res.toUpperCase(),
												}))),
											...((!!celsureWoTrials?.find((trialObj: any) => trialObj.trial_id === plot.trial_id)?.threshold_minimum_temperature?.value || !!celsureWoTrials?.find((trialObj: any) => trialObj.trial_id === plot.trial_id)?.threshold_maximum_temperature?.value) ? [
												!!celsureWoTrials?.find((trialObj: any) => trialObj.trial_id === plot.trial_id)?.threshold_minimum_temperature?.value ? {
													x: plot?.actual?.["time"] || plot?.actual?.["Time"] as any,
													y: (plot?.actual?.["time"] || plot?.actual?.["Time"])?.map((res: any) => celsureWoTrials.find((trialObj: any) => trialObj.trial_id === plot.trial_id)?.threshold_minimum_temperature?.value),
													type: 'scatter',
													name: 'Threshold Minimum Temperature',
												} : {},
												!!celsureWoTrials.find((trialObj: any) => trialObj.trial_id === plot.trial_id)?.threshold_maximum_temperature?.value ? {
													x: plot?.actual?.["time"] || plot?.actual?.["Time"] as any,
													y: (plot?.actual?.["time"] || plot?.actual?.["Time"])?.map((res: any) => celsureWoTrials.find((trialObj: any) => trialObj.trial_id === plot.trial_id)?.threshold_maximum_temperature?.value),
													type: 'scatter',
													name: 'Threshold Maximum Temperature',
												} : {}] as any : []),
										]}
									/>
								})}</div>
						</Panel>
					</Collapse>}
				</Space>
			</Card>

			<Collapse defaultActiveKey={["1"]}>
				<Panel
					header={t("common.report")}
					key="1"
					extra={
						(!!report?.graphs?.length ||
							!!report?.tableData?.tables.length) && (
							<Form
								layout="inline"
								onFinish={save}
								onClick={(e) => e.stopPropagation()}
							>
								<Form.Item
									name="filename"
									rules={[
										() => ({
											validator(_, value) {
												if (
													/^[^\x{01}-\x7E]*([A-Za-z0-9０-９ぁ-ヶ一-龠々ー！？。．＠＃＄％＆（）＜＞［］｛｝／＼＾＿｜￣]+[^\x{01}-\x7E]*)*$/.test(
														value
													)
												) {
													return Promise.resolve();
												}
												return Promise.reject(new Error("Invalid Filename"))
											},
										}),
									]}
								>
									<Input placeholder={t("woReportPreview.fileName")} />
								</Form.Item>
								<Form.Item>
									<StyledButton
										htmlType="submit"
										loading={celsureReportUploadStatus === AsyncStates.LOADING}
									>
										{t("common.save")}
									</StyledButton>
								</Form.Item>
							</Form>
						)
					}
				>
					<Space direction="vertical" style={{ width: "100%" }}>
						{(!!report?.graphs?.length ||
							!!report?.tableData?.tables?.length) && (
								<PDFViewer style={{ width: "100%", height: 1280 }}>
									<CelsureDoc
										report={report}
										company={company}
										celsureWoTrialsList={celsureWoTrialsList}
										displayTables={displayTables}
									/>
								</PDFViewer>
							)}
						{link && (
							<StyledCard
								title={reportName}
								style={{ marginTop: 50 }}
								extra={
									<StyledButton type="primary">
										<a href={link} target="_blank" rel="noreferrer">
											{t("notifications.download")}
										</a>
									</StyledButton>
								}
							>
								<Space
									style={{
										width: "100",
										display: "flex",
										justifyContent: "center",
										textAlign: "center",
									}}
								>
									<Document
										file={link}
										onLoadSuccess={(e: any) => setNumOfPages(e.numPages)}
										style={{ width: "100%", border: "1px solid black" }}
									>
										{Array.apply(null, Array(numOfPages))
											?.map((x: any, i: any) => i + 1)
											?.map((page: number) => (
												<Page pageNumber={page} size={[1200, 720]} />
											))}
									</Document>
								</Space>
							</StyledCard>
						)}
						<List
							bordered
							header={t("common.previouslyGeneratedReport")}
							dataSource={reportLists}
							renderItem={(report) => (
								<ListItem
									report={report}
									setLink={setLink}
									setReportName={setReportName}
									key={report?.report_id || report.wo_report_id}
									setPlots={setPlots}
									setReport={setReport}
									from={from}
									workOrderId={celsureWo?.work_order_id}
								/>
							)}
						/>
					</Space>
				</Panel>
			</Collapse>
			{from !== "compare_formulation" && (
				<Row justify="end">
					<Tooltip title="Save a report for submitting">
						<StyledButton
							type="primary"
							disabled={celsureReportUploadStatus !== AsyncStates.SUCCESS}
							onClick={() =>
								dispatch(
									celsureWoUpdateRequest({
										work_order_id: celsureWo?.work_order_id,
										work_order_stage: "work_order_status",
									})
								)
							}
						>
							{t("reportPreview.submitForApproval")}
						</StyledButton>
					</Tooltip>
				</Row>
			)}
		</Space>
    );
}

export const CelsureReportTable = ({ setTableData, displayTables }: any) => {
	const dispatch = useDispatch()
	const [t] = useTranslate()

	return (
		<StyledCard title={t("common.tablesForReport")}>
			<Space direction="vertical" size={"large"} style={{ width: "100%" }}>
				<Row gutter={16}>
					<Col span={10}>
						<Select
							mode="multiple"
							allowClear
							style={{ width: "100%" }}
							placeholder={t("common.selectTables")}
							onChange={(e: any) => {
								dispatch(setIsEditing(!!e?.length))
								setTableData((prevState: any) => ({ ...prevState, tables: e }))
							}}
						>
							{displayTables?.formulations && (
								<Option key="f1" value="formulations">
									{t("common.trials")}
								</Option>
							)}
						</Select>
					</Col>
					<Col span={4}>
						<Input
							placeholder={t("common.trialsPerTable")}
							type="number"
							onChange={(e) =>
								setTableData((prevState: any) => ({
									...prevState,
									trialsCount: Number(e.target.value),
								}))
							}
						/>
					</Col>
				</Row>
			</Space>
		</StyledCard>
	)
}
