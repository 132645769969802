import { handleActions } from "redux-actions"
import { LanguageActionTypes } from "src/store/actions/language"
import { switchMomentLocale } from "src/utils/switchLocale"

export const languages = ["en", "ja", "de", "fr", "es", "pt", "zh", "ko"] as const
export type Language = typeof languages[number]

export type LanguageState = {
	current: Language,
	dev: boolean,
}

const defaultState: LanguageState = {
	current: "en",
	dev: false,
}

type Payload = {
	current: Language
}

const languageReducer = handleActions<LanguageState, Payload>(
	{
		[LanguageActionTypes.TOGGLE]: (state, action) => {
			switchMomentLocale(action.payload.current)

			return {
				...state,
				current: action.payload.current,
			}
		},
		[LanguageActionTypes.DEV]: (state) => {

			return {
				...state,
				dev: !state.dev
			}
		},
	},
	defaultState
)
export default languageReducer
