import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { CustomPrompt } from "src/utils/CustomPrompts"
import { useEffect, useState } from "react"
import useTranslate from "src/utils/useTranslate"
import { useQuery } from "src/utils/useQuery"
import { StyledCard } from "src/styled_components/StyledCard"
import { Tooltip, Typography } from "antd";
import { BulbOutlined, HistoryOutlined } from "@ant-design/icons"
import { getPredictionRequest, inverseConfigRequest } from "src/store/actions/inverseModel"
import { modelsConfigRequest } from "src/store/actions/formulate"
import { useHistory } from "react-router-dom"
import { setModelVersion } from "src/store/actions/modelAnalysis"
import { InverseModelCatwise } from "./InverseModelNew/catwise/inverse-model"
import StyledBadge from "src/styled_components/StyledBadge"
import { StyledButton } from "src/styled_components/StyledButton"

const InversePrediction = ({
  tab,
  selectedObjective,
  setSelectedObjective,
  experimentsCurrent,
  setExperimentsCurrent,
  inverseCurrent,
  inverseFilters,
}: any) => {
  const [t] = useTranslate()
  let query = useQuery()
  let modelVersion = query?.get("version")
  const { push } = useHistory()
  const dispatch = useDispatch()
  const ai_engine = useSelector((state: StoreState) => state.configs.ai_engine)
  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const { configData } = useSelector((state: StoreState) => state.formulate)

  const { setAnalysisTabValue } = useSelector(
    (state: StoreState) => state.modelAnalysis,
  )
  useEffect(() => {
    if (Boolean(modelVersion)) {
      dispatch(inverseConfigRequest())
    }
  }, [modelVersion, dispatch])

  useEffect(() => {
    // if (setAnalysisTabValue) {
    // 	push('/ai-engine?tab=model_analysis')
    // }
    return () => {
      // dispatch(setAnalysisTab(false))
      dispatch(setModelVersion({ value: false, type: "" }))
    }
  }, [dispatch, push, setAnalysisTabValue])

  useEffect(() => {
    if (!!configData.length && Number(modelVersion)) {
      const currentModelData = configData.find((config: any) => config.version === Number(modelVersion))
      dispatch(
        modelsConfigRequest({
          version: modelVersion,
          ...(currentModelData?.is_multistage && {
            isMultiStage: currentModelData?.is_multistage,
            stage_name: currentModelData?.all_stages?.[0] ?? "Stage 1",
          }),
        }),
      )
    }
  }, [dispatch, configData, modelVersion])

  return (
    <StyledCard
      title={
        <Typography.Text
          ellipsis={{
            tooltip: t("aiEngine.inverseModel.makePropertiesPrediction"),
          }}
          strong
          style={{
            marginBottom: 0,
            backgroundColor: "#e6f4ff",
            borderRadius: 6,
            padding: 12,
          }}
        >
          <BulbOutlined /> -{" "}
          {t("aiEngine.inverseModel.makePropertiesPrediction")}
        </Typography.Text>
      }
      extra={
        <Tooltip
          placement="top"
          title={t("aiEngine.predictedExperimentsHistory")}
        >
          <StyledBadge count={ai_engine?.inverse?.total_unread_count} overflowCount={10} showZero={false}>
            <StyledButton
              type="default"
              icon={<HistoryOutlined />}
              size="large"
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                dispatch(
                  getPredictionRequest(
                    inverseFilters ?? {
                      pageNum: inverseCurrent,
                    },
                  ),
                )
                push(`/ai-engine/history/formulation_prediction`)
              }}
            />
          </StyledBadge>
        </Tooltip>
      }
      headStyle={{ padding: "1rem", border: 'none', paddingBottom: 0 }}
      bodyStyle={{ padding: "1rem" }}
      style={{ userSelect: "none" }}
    >
      <CustomPrompt
        isEditing={unsavedChanges}
        message={`${t("aiEngine.inputsFrom")} ${t(
          "navBar.menuItem.formulations",
        )} ${t("aiEngine.predictionNotPredicted")} ${t(
          "common.unsavedChangesLost",
        )}`}
      />

      <InverseModelCatwise
        tab={tab}
        setUnsavedChanges={setUnsavedChanges}
        selectedObjective={selectedObjective}
        setSelectedObjective={setSelectedObjective}
        experimentsCurrent={experimentsCurrent}
        setExperimentsCurrent={setExperimentsCurrent}
      />
    </StyledCard>
  )
}

export default InversePrediction
