import { DownloadOutlined } from '@ant-design/icons'
import { Collapse, Empty, Form, Modal, Select, Space, Tooltip, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { colorCodes } from 'src/components/Formulation/Compare/ZeonCompare/zeon-chart/utils'
import { AsyncStates } from 'src/constants'
import { getExplainableAiReportClear } from 'src/store/actions/explainableAI'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { DownloadReport } from './DownloadReport'
import { ExplainableAIBarChart } from './ExplainableAIBarChart'
import { ExplainableAIBeeswarm } from './ExplainableAIBeeswarm'
import ExplainableAIWaterfall from './ExplainableAIWaterfall'
import { generateBase64, removeUnderScore } from './utils'
import useTranslate from 'src/utils/useTranslate'
// import { ExplainableAIDependence } from './ExplainableAIDependence'
// import { ExplainableAIInteraction } from './ExplainableAIInteraction'

const { Option } = Select
const { Panel } = Collapse;
const { Title, Text } = Typography

export const ExplainableAIResult = ({ index, property, version, currentChartType, currentSelectedStage }: any) => {
    const [t] = useTranslate()
    const { explainableAIData, getExplainabiltyStatus } = useSelector((state: StoreState) => state.explainableAI)
    const { modelData } = useSelector((state: StoreState) => state.modelAnalysis)
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)

    const dispatch = useDispatch()
    const scrollToRef = useRef<any>(null)

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [waterfallPlotInfo, setWaterfallPlotInfo] = useState({
        options: [],
        urls: []
    })
    const [disableDownloadReport, setDisableDownloadReport] = useState<string[]>(["0"])

    const validAvailablePlots = useCallback((property) => {
        return explainableAIData[property]?.["models_list"].filter((model: any) => explainableAIData?.[property]?.[model]?.plots !== undefined)
    }, [explainableAIData])

    const [selectedModel, setSelectedModel] = useState<any>(Object.keys(explainableAIData || {}).reduce((acc, curr) => {
        const model = validAvailablePlots(curr)
        let initialModel = model.includes("combined_model") ? ["combined_model"] : [model?.[0] ?? "model_1"]
        if (initialModel[0] === 'model_1') {
            initialModel = []
        }
        return {
            ...acc,
            [curr]: initialModel
        }
    }, {}))

    const [filteredParameters, setfilteredParameters] = useState<any>(Object.keys(explainableAIData || {}).reduce((acc, curr) => {
        return {
            ...acc,
            [curr]: []
        }
    }, {}))

    const handleSelectModelChange = (property: string, value: string) => {
        setSelectedModel((prev: any) => {
            return {
                ...prev,
                [property]: currentChartType === "bar" ? [...new Set([...value])] : [value]
            }
        })
    }

    useEffect(() => {
        setSelectedModel(Object.keys(explainableAIData || {}).reduce((acc, curr) => {
            const model = validAvailablePlots(curr)
            let initialModel = model.includes("combined_model") ? ["combined_model"] : [model?.[0] ?? "model_1"]
            if (initialModel[0] === 'model_1') {
                initialModel = []
            }
            return {
                ...acc,
                [curr]: initialModel
            }
        }, {}))
    }, [explainableAIData, currentChartType, validAvailablePlots])

    useEffect(() => {
        if (getExplainabiltyStatus === AsyncStates.SUCCESS) {
            scrollToRef.current?.scrollIntoView({
                behavior: 'smooth', block: "center"
            });
        }
    }, [getExplainabiltyStatus])

    const getDisplayName = (catergory: string) => {
        const displayName = displayNames.processing?.[catergory]?.name ?? displayNames.ingredients?.[catergory]?.name
        return displayName ?? catergory
    }

    const handleSelectParameter = (property: any, value: string) => {
        setfilteredParameters((prev: any) => {
            return {
                ...prev,
                [property]: [...new Set([...value])]
            }
        })
    }

    const colorOptions = useMemo(() => {
        return explainableAIData[property]?.models_list?.reduce((acc: any, curr: string, index: number) => {
            return {
                ...acc,
                [curr]: colorCodes[index]
            }
        }, {})
    }, [property, explainableAIData])

    const generateUrls = async () => {
        const result = await generateBase64(waterfallPlotInfo.options, index)
        setWaterfallPlotInfo((prev: any) => ({ ...prev, urls: result }))
    }

    const renderChart = (property: string, model: string[]) => {
        if (currentChartType === "bar") {
            return <ExplainableAIBarChart property={property} model={model} filterBy={filteredParameters[property]} colorOptions={colorOptions} />
        } else if (currentChartType === "beeswarm") {
            return <ExplainableAIBeeswarm property={property} model={model} filterBy={filteredParameters[property]} />
        } else if (currentChartType === "waterfall") {
            return <ExplainableAIWaterfall property={property} model={model} setWaterfallPlotOptions={setWaterfallPlotInfo} chartIndex={index} />
        } else if (currentChartType === "dependence") {
            // return <ExplainableAIDependence property={property} model={model} />
        } else if (currentChartType === "interaction") {
            // return <ExplainableAIInteraction property={property} model={model} />
        }
        return null
    }

    return (
        <Space direction='vertical'>
            <Collapse key={index} defaultActiveKey={['0']} onChange={(e: any) => { setDisableDownloadReport(e) }}>
                <Panel header={
                    <Title style={{ margin: "0", userSelect: "none" }} level={4}>
                        {displayNames.properties?.[property]?.name ?? property}
                    </Title>
                } key={index}
                    extra={
                        <Tooltip title={!disableDownloadReport.includes(String(index)) ? !(validAvailablePlots(property).length > 0) ? "There are no reports to download" : "To download report, Expand the section" : !(validAvailablePlots(property).length > 0) ? "There are no reports to download" : t('report.downloadReport')}>
                            <div>
                                <StyledButton
                                    icon={<DownloadOutlined />}
                                    disabled={!disableDownloadReport.includes(String(index)) || !(validAvailablePlots(property).length > 0)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(getExplainableAiReportClear())
                                        setIsModalVisible(true)
                                        if (currentChartType === "waterfall") {
                                            generateUrls()
                                        }
                                    }}
                                >
                                    {t('report.downloadReport')}
                                </StyledButton>
                            </div>
                        </Tooltip>
                    }
                >
                    {Object.keys(explainableAIData?.[property] || {}).length > 0 ?
                        <>
                            <Space style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-between" }}>
                                <Form layout='vertical'
                                    style={{ maxWidth: 600 }}
                                    requiredMark={false}
                                >
                                    {validAvailablePlots(property).length > 0 && <Form.Item
                                        label="Select a Model"
                                        rules={[{ required: true, message: 'Please Select!' }]}
                                        required 
                                    >
                                        <Select placeholder={"Select Modal(s)"}
                                            showSearch
                                            style={{ minWidth: process.env.REACT_APP_ENV !== "production" ? 700 : 400, maxWidth: process.env.REACT_APP_ENV !== "production" ? 700 : 400 }}
                                            mode={currentChartType === "bar" ? 'multiple' : undefined}
                                            defaultValue={selectedModel?.[property]?.[0]}
                                            value={selectedModel?.[property]}
                                            allowClear={currentChartType === "bar" ? true : false}
                                            optionFilterProp={"children"}
                                            onChange={(value) => handleSelectModelChange(property, value)}
                                        >
                                            {validAvailablePlots(property).map((model: any) =>
                                                <Option
                                                    value={model}
                                                    key={model}>
                                                    {removeUnderScore(model) ?? model} {process.env.REACT_APP_ENV !== "production" ? `${explainableAIData[property]?.[model]?.original_name ? `(${explainableAIData[property]?.[model]?.original_name})` : ""} ${explainableAIData[property]?.[model]?.is_best_model ? '(Best Model)' : ""} ` : ""}
                                                </Option>)
                                            }
                                        </Select>
                                    </Form.Item>}

                                    {(currentChartType === "bar" || currentChartType === "beeswarm") &&
                                        <Form.Item label={t("common.filter")}>
                                            <Select placeholder={t("aiEngine.modelAnalysis.selectParameter")}
                                                showSearch
                                                style={{ minWidth: process.env.REACT_APP_ENV !== "production" ? 700 : 400, maxWidth: process.env.REACT_APP_ENV !== "production" ? 700 : 400 }}
                                                mode='multiple'
                                                value={filteredParameters?.[property]}
                                                optionFilterProp={"children"}
                                                allowClear
                                                onChange={(value) => handleSelectParameter(property, value)}
                                            >
                                                {explainableAIData?.[property]?.x_cols?.map((parameter: any) => <Option value={parameter} key={parameter}>{getDisplayName(parameter) ?? parameter}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    }
                                </Form>
                            </Space>
                            {
                                selectedModel?.[property].length > 0
                                    ? renderChart(property, selectedModel?.[property])
                                    : validAvailablePlots(property).length > 0 
                                        ? <Empty description={t("aiEngine.pleaseSelectAlteastOneModel")} /> 
                                        : <Empty description={t("aiEngine.noDataAvailableForThisModel")} />
                            }
                        </>
                        :
                        <Empty description={`No Data for ${displayNames.properties?.[property]?.name ?? property}`} />
                    }
                </Panel>
            </Collapse>
            <div ref={scrollToRef}></div>
            <Modal open={isModalVisible} title={<Text strong>{`${t("aiEngine.modelAnalysis.downloadReportfor")} ${displayNames.properties?.[property]?.name ?? property}.`}</Text>} footer={null} onCancel={() => setIsModalVisible(false)} closable={false}>
                <DownloadReport data={{ version, stage_version: modelData?.stages_meta?.[`Stage ${currentSelectedStage}`]?.version, property_name: [property], chart_type: currentChartType, model_types: { [property]: selectedModel[property] }, filter_names: filteredParameters[property], ...(currentChartType === "bar" && { bar_colors: colorOptions }), ...(currentChartType === "waterfall" && { formulation_urls: waterfallPlotInfo.urls }) }} type={"individual_report"} setIsModalVisible={setIsModalVisible} />
            </Modal>
        </Space>
    )
}
