import {
    DeleteOutlined,
    EditOutlined,
    LoadingOutlined,
    MoreOutlined,
    PaperClipOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Collapse, Dropdown, Empty, Spin, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import useTranslate from "src/utils/useTranslate";
import AddEditParameterModal from "../Common/AddEditParameterModal";

import { useDispatch, useSelector } from "react-redux";
import {
    fetchPropertyParametersRequest,
    removeParametersRequest,
    updateParametersRequest,
} from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";

type TProps = {
    showPropertyModal: () => void;
    parameters: { [key: string]: any }[];
    selectedProperty: string;
};

const PropertyParameters = ({
    showPropertyModal,
    parameters,
    selectedProperty,
}: TProps) => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [mode, setMode] = useState<"edit" | "create">("create");
    const [selectedParameterId, setSelectedParameterId] = useState<string>();
    const [refetchPropertyParameters, setRefetchPropertyParameters] =
        useState(false);
    const parametersStatus = useSelector(
        (state: StoreState) => state.repository.parameters.status
    );
    const dispatch = useDispatch();

    useEffect(() => {
        // Refetch properties after action (e.g. Mark Default)
        if (parametersStatus === AsyncStates.SUCCESS && refetchPropertyParameters) {
            dispatch(
                fetchPropertyParametersRequest({ property_id: selectedProperty })
            );
            setRefetchPropertyParameters(false);
        }
    }, [selectedProperty, dispatch, refetchPropertyParameters, parametersStatus]);

    const deleteParameters = useCallback(
        (ids: string[]) => {
            dispatch(
                removeParametersRequest({
                    parameter_ids: ids,
                })
            );
        },
        [dispatch]
    );

    const takeAction = useCallback(
        (action: string, parameter_id: string) => {
            if (action === "edit") {
                setSelectedParameterId(parameter_id);
                setMode("edit");
                setShowModal(true);
            }
            if (action === "delete") deleteParameters([parameter_id]);
            if (action === "setDefault" || action === "removeDefault") {
                const payload = { parameter_id, is_default: action === "setDefault" };
                dispatch(updateParametersRequest(payload));
                setRefetchPropertyParameters(true);
            }
        },
        [deleteParameters, setShowModal, dispatch]
    );

    const createParameterForProperty = () => {
        setMode("create");
        setShowModal(true);
    };

    const dropdownMenu = useCallback(
        (is_default) => {
            return [
                {
                    label: (
                        <div>
                            <EditOutlined /> {t("common.edit")}
                        </div>
                    ),
                    key: "edit",
                },
                {
                    label: (
                        <div>
                            <PaperClipOutlined />{" "}
                            {!is_default
                                ? t("respository.markAsDefault")
                                : t("repository.removeAsDefault")}
                        </div>
                    ),
                    key: !is_default ? "setDefault" : "removeDefault",
                },
                {
                    label: (
                        <div style={{ color: "#F5222D" }}>
                            <DeleteOutlined /> {t("common.delete")}
                        </div>
                    ),
                    key: "delete",
                },
            ];
        },
        [t]
    );

    return (
        <div className="property-parameter-container">
            <Spin
                spinning={parametersStatus === AsyncStates.LOADING}
                indicator={<LoadingOutlined />}
            >
                <Collapse
                    defaultActiveKey={["1"]}
                    items={[
                        {
                            key: "1",
                            showArrow: false,
                            collapsible: "icon",
                            label: (
                                <div className="collapse-header-container">
                                    <span className="collapse-header">
                                        {t("repository.parameterList")}
                                    </span>
                                    <span
                                        className="add-new-cta"
                                        onClick={createParameterForProperty}
                                    >
                                        <PlusOutlined /> {t("common.Add New")}
                                    </span>
                                </div>
                            ),
                            children: (
                                <>
                                    {parameters.length === 0 &&
                                        parametersStatus === AsyncStates.SUCCESS && (
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        )}

                                    {parameters.sort((a, b) => a.parameter.localeCompare(b.parameter)).map((p) => (
                                        <div key={p.parameter_id} className="parameter-list">
                                            <span>
                                                {p.parameter}
                                                {p.is_default && (
                                                    <>
                                                        &nbsp;&nbsp;
                                                        <Tag> {t("common.default")}</Tag>
                                                    </>
                                                )}
                                            </span>
                                            <Dropdown
                                                menu={{
                                                    items: dropdownMenu(p.is_default),
                                                    onClick: (event) =>
                                                        takeAction(event.key, p.parameter_id),
                                                }}
                                                trigger={["click"]}
                                            >
                                                <MoreOutlined />
                                            </Dropdown>
                                        </div>
                                    ))}
                                </>
                            ),
                        },
                    ]}
                />
            </Spin>
            <AddEditParameterModal
                showModal={showModal}
                setShowModal={setShowModal}
                showPropertyModal={showPropertyModal}
                fetchProperties={false}
                mode={mode}
                selectedParameterId={selectedParameterId}
                linkPropertyId={mode === "create" ? selectedProperty : undefined}
            />
        </div>
    );
};

export default PropertyParameters;
