import React from 'react'
import { useSelector } from 'react-redux'
import { projectType } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
// import { Inventory } from './Inventory'
import { Inventory as CelsureInventory } from '../Celsure/Inventory/Inventory'
import { InventoryV2 } from 'src/modules/InventoryV2'

export default function InventoryPage() {

    const { project_type } = useSelector((state: StoreState) => state.login.loginResponse
    )

    return (
        project_type !== projectType.celsure ? (
            <InventoryV2 />
        ) : <CelsureInventory />
    )
}
