import { call, put, select, takeLatest } from "redux-saga/effects"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import { messages } from "src/utils/hooks"
import { authMagicLinkApi, loginViaMagicLinkApi } from "src/services/login"
import { authMagicLinkFailure, authMagicLinkRequest, authMagicLinkSuccess, loginViaMagicLinkFailure, loginViaMagicLinkRequest, loginViaMagicLinkSuccess } from "../actions/loginViaMagicLink"
import { notification } from "antd"
import { loginSuccess } from "../actions"
import { history } from "src"
import { handleFirebaseLogin } from "src/utils/firebase"




function* loginViaMagicLinkSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders }
        const {
            data: { result: { status, message } } } = yield call(loginViaMagicLinkApi, payload, headers)
        if (status === "Success") {
            yield put(loginViaMagicLinkSuccess())
            notification.success({ message })
        } else {
            yield put(loginViaMagicLinkFailure(message))
        }
    } catch (err: any) {
        if (err?.response?.status === 400) {
            yield put(loginViaMagicLinkFailure(err?.response?.data?.detail ?? messages[ln].internal_server_error))
        } else {
            yield put(loginViaMagicLinkFailure(messages[ln].internal_server_error))
        }
    }
}

function* authMagicLinkSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders }
        const {
            data: { result: { status, message, data } } } = yield call(authMagicLinkApi, payload, headers)
        if (status === "Success") {
            if (!!data?.firebase_auth_token?.length) {
                yield call(handleFirebaseLogin,data?.firebase_auth_token);
            }
            yield put(loginSuccess(data))
            yield put(authMagicLinkSuccess())
            yield call(history.push, "/formulations");
        } else {
            yield put(authMagicLinkFailure(message))
            notification.error({ message })
            yield call(history.push, "/login");
        }
    } catch (err: any) {
        if (err?.response?.status === 400) {
            yield put(authMagicLinkFailure(err?.response?.data?.detail ?? messages[ln].internal_server_error))
            notification.error({ message: err?.response?.data?.detail ?? messages[ln].internal_server_error })
        } else {
            yield put(authMagicLinkFailure(messages[ln].internal_server_error))
            notification.error({ message: messages[ln].internal_server_error })
        }
        yield call(history.push, "/login");
    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(loginViaMagicLinkRequest, loginViaMagicLinkSaga)
    yield takeLatest(authMagicLinkRequest, authMagicLinkSaga)
}