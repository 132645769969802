import { Row, Space, Typography } from "antd"
import { CategoricalDistributionCharts as Data } from "src/components/AIEngine/DataSummary/types"
import { SummaryWrapper } from "../summary-wrapper"
import {
	getPieChartOptions,
	preparePieChartData,
	getBarChartOptions,
	prepareBarChartData,
} from "./utils"
import { PieChart, BarChart } from "./chart"
import useTranslate from "src/utils/useTranslate"
import { useValue } from "src/utils/useValue"

type P = {
	data: Data
	getDisplayName: (value: string) => string
}

export const CategoricalDistributionCharts = ({ data, getDisplayName }: P) => {
	const [t] = useTranslate()
	const { getValue } = useValue()
	const pieCharts = Object.keys(data).map((key) => {
		if (data[key].chart_type === "Pie Chart") {
			const { title, ...payload } = preparePieChartData(
				data[key],
				getDisplayName(key)
			)
			const options = getPieChartOptions(payload, title, getValue)
			return <PieChart key={title} title={title} options={options} />
		}
		return null
	})
	const barCharts = Object.keys(data).map((key) => {
		if (data[key].chart_type === "Bar Chart") {
			const { title, xAxisData, ...payload } = prepareBarChartData(
				data[key],
				getDisplayName(key)
			)
			const options = getBarChartOptions(payload, title, xAxisData, getValue)
			return <BarChart key={title} title={title} options={options} />
		}

		return null
	})

	return (
		<SummaryWrapper heading={t("dataSummary.categoricalDistributionCharts")} tooltip={t("aiEngine.tab.dataSummary.categoricaldistributioncharts")} key={"categorical-distribution-charts"}>
			<Space style={{ width: "100%", overflowX: "auto" }} direction="vertical">
				<Row style={{ display: "flex", flexDirection: "column", gap: "25px" }}>
					{pieCharts.length > 0 && (
						<Row className="categorical-data-summary-chart-row">
							{pieCharts}
						</Row>
					)}
					{barCharts.length > 0 && (
						<Row className="categorical-data-summary-chart-row">
							{barCharts}
						</Row>
					)}
					{barCharts.length === 0 && pieCharts.length === 0 && (
						<Typography.Title level={5} style={{ color: "#ff3d54"}}>
							{t("dataSummary.categoricalDistributionCharts.noDataAvailable")}
						</Typography.Title>
					)}
				</Row>
			</Space>
		</SummaryWrapper>
	)
}
