import { volcano } from "@ant-design/colors"
import { CalendarOutlined } from "@ant-design/icons";
import {
	Avatar,
	Descriptions,
	Divider,
	Row,
	Space,
	Tag,
	Tooltip,
	Typography,
} from "antd"
import { StyledCard } from "src/styled_components/StyledCard"
import useTranslate from "src/utils/useTranslate"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import { permissions, projectStatus } from "src/constants"
import { useMemberName } from "src/utils/useMemberName"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"

const { Title, Text, Paragraph } = Typography
const dateFormat = "YYYY-MM-DD"

interface WoCardProps {
	order: any
	showDetails: () => void
	handleDelete: () => void
	userAccess: any
}

export const WoCard = ({ order, showDetails, handleDelete, userAccess }: WoCardProps) => {
	const [t] = useTranslate()
	const { getName } = useMemberName()
	const teamsData = useSelector((state: StoreState) => state.teams.data || [])
	const user_id = useSelector((state: StoreState) => state.login.loginResponse.user_id)


	return (
		<StyledCard
			style={{
				width: 300,
				margin: 5,
			}}
			onClick={showDetails.bind(order)}
			hoverable
			actions={[]}
		>
			<Row justify="space-between">
				<Title
					level={4}
					style={{
						whiteSpace: "nowrap",
						width: 180,
						textOverflow: "ellipsis",
						overflow: "hidden",
					}}
					title={order.work_order_name}
				>
					{order.work_order_name}
				</Title>
				<Avatar.Group
					maxCount={2}
					maxStyle={{
						color: volcano[3],
						backgroundColor: "#fde3cf",
					}}
				>
					{order.work_order_dri?.map((memberId: string) => (
						<Tooltip
							title={
								getName(memberId)
							}
						>
							<Avatar
								key={memberId}
								style={{
									backgroundColor: volcano[3],
								}}
								src={
									teamsData.find((member: any) => member.user_id === memberId)
										?.child_user_image_url
								}
							>
								{teamsData
									.find((member: any) => member.user_id === memberId)
									?.user_name?.slice(0, 3)
									?.toUpperCase()}
							</Avatar>
						</Tooltip>
					))}
				</Avatar.Group>
			</Row>
			<Divider
				style={{
					margin: 0,
					marginBottom: 16,
				}}
			/>
			<Descriptions
				column={1}
				size="small"
				style={{
					height: 80,
					textOverflow: "ellipsis",
				}}
			>
				{(!["work_order_initiated", "report_preview", "work_order_status"].includes(order.work_order_parent_stage) && order.status !== "closed") ? (
					<>
						<Descriptions.Item label={""}>
							<Text type="secondary">
								{order?.stage_name}
							</Text>
						</Descriptions.Item>
						<Descriptions.Item label={""}>
							<Text type="secondary">
								{order?.description}
							</Text>
						</Descriptions.Item>
					</>
				)
					:
					<Descriptions.Item
						label={<Text strong>{t("common.description")}</Text>}
					>
						<Paragraph
							ellipsis={{
								rows: 2,
								symbol: "more",
								tooltip: order.work_order_desc,
							}}
						>
							{order.work_order_desc}
						</Paragraph>
					</Descriptions.Item>}
			</Descriptions>
			<Space style={{ width: "100%", justifyContent: "space-between" }}>
				<Tag color="blue" icon={<CalendarOutlined />}>
					{t("common.createdOn")} : {dayjs(order.created).format(dateFormat)}
				</Tag>
				{((userAccess?.permission === permissions.projectAdmin || user_id === order?.created_by) && userAccess?.status === projectStatus.in_progress) && (
					<StyledButton
						type="link"
						icon={<StyledDeleteIcon style={{ color: volcano[3] }} />}
						onClick={(e) => {
							e?.stopPropagation()
							handleDelete.call(order)
						}}
					/>
				)}
			</Space>
		</StyledCard>
	)
}
