import { useEffect, useState } from 'react'
import { Checkbox, ConfigProvider, Flex, Modal, Row, Tooltip } from 'antd';
import { useVariableValue } from '@devcycle/devcycle-react-sdk';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { UpCircleFilled } from '@ant-design/icons';
import { TinyColor } from '@ctrl/tinycolor';
import { StyledButton } from 'src/styled_components/StyledButton';

const colors1 = ['#6253E1', '#04BEFE'];

const getHoverColors = (colors: string[]) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());
const getActiveColors = (colors: string[]) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());

export const VersionUpdate = ({ collapsed }: { collapsed: boolean }) => {

  const version = localStorage.getItem("version") || ""
  const newVersion = useVariableValue("version", version)
  const newDescription = useVariableValue("version-description", "Reloading the page will update the app.")

  const [update, setUpdate] = useState(() => Boolean(JSON.parse(localStorage.getItem("reloaded") === "false" ? "true" : "false")))

  const [isOpen, setIsOpen] = useState(false)
  const [remind, setRemind] = useState(() => Boolean(JSON.parse(localStorage.getItem("remind") || "true")))

  useEffect(() => {
    if (update) {
      if (remind) {
        setIsOpen(true)
      }
    }
    else if (newVersion !== version) {
      localStorage.setItem("reloaded", "false")
      setUpdate(true)
      if (remind) {
        setIsOpen(true)
      }

    }
    localStorage.setItem("version", newVersion)

  }, [newVersion, remind, update, version])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const doNotRemind = (e: CheckboxChangeEvent) => {
    localStorage.setItem("remind", String(e.target.checked))
    setRemind(e.target.checked)
  }

  return update ? (
    <Row
    justify="start"
    align="bottom"
    gutter={16}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 6,
    }}
  >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: `linear-gradient(135deg, ${colors1.join(', ')})`,
              colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(colors1).join(', ')})`,
              colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(colors1).join(', ')})`,
              lineWidth: 0,
            },
          },
        }}
      >
        <Tooltip title="Click to update." placement='right'>
          <StyledButton shape='round' size='small' type="primary" onClick={openModal} icon={<UpCircleFilled />}>
            {collapsed ? "" : "New update available!"}
          </StyledButton>
        </Tooltip>
      </ConfigProvider>
      <Modal
        title="Update Available"
        open={isOpen}
        onCancel={closeModal}
        footer={[
          <Flex style={{ width: "100%" }} align='baseline'>
            <Checkbox checked={remind} onChange={doNotRemind} style={{ marginRight: "auto" }}>Remind me later.</Checkbox>
            <StyledButton key="submit" onClick={closeModal}>
              Skip
            </StyledButton>
            <StyledButton key="back" type='primary' onClick={() => {
              localStorage.setItem("version", newVersion);
              window.location.reload()
            }}>
              Reload
            </StyledButton>
          </Flex>
        ]}
      >
        {newDescription}
      </Modal>

    </Row>
  ) : null;
}
