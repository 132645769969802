import { Col, Form, Modal, Row, Typography } from "antd";
import { DefaultOptionType } from "antd/es/cascader";
import { Cascader } from "antd/lib";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { updateIngredientTemplatesRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTemplateId: string;
};

const AddEditIngredientTemplatePropertiesModal = ({
    showModal,
    setShowModal,
    selectedTemplateId,
}: TProps) => {
    const [t] = useTranslate();
    const [templateForm] = Form.useForm();
    const dispatch = useDispatch();
    const templates = useSelector(
        (state: StoreState) => state.repository.templates.data
    );
    const properties = useSelector(
        (state: StoreState) => state.repository.properties.data
    );
    const templateStatus = useSelector(
        (state: StoreState) => state.repository.templates.status
    );

    const currentTemplate = useMemo(() => {
        return templates.find((t) => t.template_id === selectedTemplateId);
    }, [templates, selectedTemplateId]);

    useEffect(() => {
        if (showModal) {
            const property_ids = currentTemplate?.properties;

            const propertyCascaderValue = property_ids.map((id: any) => ([properties.find(p => p.property_id === id)?.category_name, id]))
            templateForm.setFieldValue("properties", propertyCascaderValue);
        }
    }, [currentTemplate, templateForm, showModal, properties]);

    useEffect(() => {
        if (templateStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [templateStatus, setShowModal]);

    const submit = () => {
        const payload = templateForm.getFieldsValue();

        // Flatten cascader value
        payload.properties = payload.properties.map((p: any) => p[(p.length - 1)]);
        dispatch(
            updateIngredientTemplatesRequest({
                ...payload,
                template_id: selectedTemplateId,
            })
        );
    };

    const propertyByCategories = useMemo(() => {
        const propertyCategories = [
            ...new Set(properties.map((p) => p.category_name)),
        ].filter(p => p).sort((a, b) => a?.localeCompare(b));

        return propertyCategories.map((category_name: any) => ({
            label: category_name ? category_name : "-",
            value: category_name,
            children: properties
                .filter((p) => p.category_name === category_name)
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map((p) => ({
                    label: p.name,
                    value: p.property_id,
                })),
        }));
    }, [properties]);

    const filter = (inputValue: string, path: DefaultOptionType[]) =>
        path.some(
            (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );

    return (
        <Modal
            title={
                <Typography.Title level={4}>
                    {t("repository.updateProperties")}
                </Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
            destroyOnClose
        >
            <Form
                onFinish={submit}
                form={templateForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name="properties"
                    label={t("common.selectProperties")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Cascader
                        style={{ width: "100%" }}
                        options={propertyByCategories}
                        rootClassName="repository-cascader"
                        placeholder={t("common.select")}
                        showSearch={{ filter }}
                        multiple
                        maxTagCount="responsive"
                        showCheckedStrategy={Cascader.SHOW_CHILD}
                    />
                </Form.Item>

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton
                            type="default"
                            onClick={() => templateForm.resetFields()}
                        >
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={templateStatus === AsyncStates.LOADING}
                            loading={templateStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditIngredientTemplatePropertiesModal;
