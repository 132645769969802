import { BORDER_STYLE } from '../../Dashboard';
import PieChartProjects from './PieChartProjects';
import PieChartWorkorder from './PieChartWorkorder';

const PieCharts = () => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '0px 0px',
        outline: BORDER_STYLE
      }}
    >
      <PieChartProjects />
      <PieChartWorkorder />
    </div>
  )
}

export default PieCharts