import { red } from "@ant-design/colors"
import { WarningOutlined } from "@ant-design/icons"
import {
  Alert,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates } from "src/constants"
import { StoreState } from "src/store/configureStore"
import { customMLtableData } from "src/utils/decorator"
import useTranslate, { TranslationKey } from "src/utils/useTranslate"
import { ExplainableAIPagination } from "./ExplainableAIPagination"
import { getCustomModelDataRequest } from "src/store/actions/modelAnalysis"
import { useValue } from "src/utils/useValue"

const { Text } = Typography

export const ExplainableAIViewData = ({
  selectedFormulations,
  version,
  currentSelectedStage
}: any) => {
  const [type, setType] = useState("ingredients")
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const { getValue } = useValue()
  const {
    customModelData,
    customModelDataTotal,
    customModelWoInfo,
    customModelDataPropertyTrainWarnings,
    getCustomModelDataStatus,
    getCustomModelDataError,
    modelData
  } = useSelector((state: StoreState) => state.modelAnalysis)

  const [currentSelectedWo, setCurrentSelectedWo] = useState<string>("")

  useEffect(() => {
    if (currentSelectedWo === '' && getCustomModelDataStatus === AsyncStates.SUCCESS) setCurrentSelectedWo(Object.keys(customModelWoInfo)?.sort((a, b) => Number(a) - Number(b))?.[0])
  }, [currentSelectedWo, customModelWoInfo, getCustomModelDataStatus])

  const conditionalDataToShow: any[] = useMemo(() => {
    return customModelData[currentSelectedWo] && getCustomModelDataStatus === AsyncStates.SUCCESS
      ? customModelData[currentSelectedWo]
      : []
  }, [currentSelectedWo, getCustomModelDataStatus, customModelData])

  const conditionalTotalToShow: string | number = useMemo(() => {
    return customModelDataTotal[currentSelectedWo] && getCustomModelDataStatus === AsyncStates.SUCCESS
      ? customModelDataTotal[currentSelectedWo]
      : 0
  }, [currentSelectedWo, getCustomModelDataStatus, customModelDataTotal])

  const woTabItems = useMemo(() => {
    return Object.keys(customModelWoInfo)
      .sort((a, b) => Number(a) - Number(b))
      .map((infoId) => ({
        label: <Typography.Text style={{ width: 100 }} ellipsis={{ tooltip: customModelWoInfo[infoId]?.name || "" }} >{customModelWoInfo[infoId]?.name || ""}</Typography.Text>,
        key: infoId,
      }))
  }, [customModelWoInfo])

  const dispNames = useSelector((state: StoreState) => state.displayNames.data)

  const translateFirstColumnOfTable = (
    input: string,
    t: (key: TranslationKey) => string,
  ) => {
    if (input === "ingredients") return t("common.ingredients")
    if (input === "processing") return t("common.processing")
    if (input === "properties") return t("common.properties")
    return t("common.ingredients")
  }

  const ingredientsDataInfo: any[] = useMemo(() => {
    const result = conditionalDataToShow.reduce((acc: any, curr: any) => {
      return { ...acc, ...curr.ingredients }
    }, {})
    return result
  }, [conditionalDataToShow])

  const tableData: any = useMemo(
    () => customMLtableData(conditionalDataToShow, type, ingredientsDataInfo, getValue),
    [conditionalDataToShow, type, ingredientsDataInfo, getValue],
  )

  const columns: any = useMemo(() => {
    const firstColumnTitle = translateFirstColumnOfTable(type, t)

    return [
      {
        title:
          firstColumnTitle.slice(0, 1).toLocaleUpperCase() +
          firstColumnTitle.slice(1),
        dataIndex: type,
        key: type,
        width: 200,
        fixed: "left",
        render: (text: any, record: any) => {
          if (type === "properties") {
            if (customModelDataPropertyTrainWarnings?.[text])
              return (
                <>
                  <Text>{dispNames?.properties?.[text]?.name || text}</Text>
                  <Tooltip title={t("customML.7DataPoints")}>
                    {" "}
                    <WarningOutlined style={{ color: red[5] }} />
                  </Tooltip>
                </>
              )
            else return dispNames?.properties?.[text]?.name || text
          } else
            return (
              ingredientsDataInfo?.[text]?.name ||
              dispNames?.ingredients?.[text]?.name ||
              dispNames?.processing?.[text]?.name ||
              text
            )
        },
      },
      ...conditionalDataToShow?.map((res: any) => ({
        title: (
          <Row justify={'space-between'}>
            <Typography.Text>{res?.meta?.display_id}</Typography.Text>
          </Row>
        ),
        dataIndex: res?.meta?.display_id,
        key: res?.meta?.display_id,
        width: 150,
      })),
    ]
  }, [type, t, conditionalDataToShow, ingredientsDataInfo, dispNames?.ingredients, dispNames?.processing, dispNames?.properties, customModelDataPropertyTrainWarnings])

  return (
    <Spin spinning={getCustomModelDataStatus === AsyncStates.LOADING}>
      {getCustomModelDataStatus === AsyncStates.ERROR ? (
        <Alert type="error" showIcon message={getCustomModelDataError} />
      ) : (
        <Space
          direction="vertical"
          size="middle"
          style={{
            width: "100%",
            overflowX: "auto",
            height: "600",
            overflowY: "auto",
          }}
        >
          <Tabs
            items={woTabItems}
            onChange={(woId) => {
              setCurrentSelectedWo(woId)
              dispatch(getCustomModelDataRequest({ version, formulation_ids: selectedFormulations, work_order_id: woId, stage_version: modelData?.stages_meta?.[`Stage ${currentSelectedStage}`]?.version }));
            }}
          />

          <Radio.Group onChange={
            (e: RadioChangeEvent) => {
              setType(e.target.value);
            }
          }
            style={{ userSelect: "none" }}
            value={type}>
            <Radio value={'ingredients'}>{t("common.formulations")}</Radio>
            {conditionalDataToShow?.some(
              (res: any) =>
                !!Object.keys(res?.processing?.[0]?.processing || {})?.length,
            ) && <Radio value={'processing'}>{t("common.processing")}</Radio>}
            <Radio value={'properties'}>{t("formulations.type.properties")}</Radio>
          </Radio.Group>

          <Table
            title={() => (
              <span>
                <span className="highlighted">{`${t("common.total")} ${t("common.trials")} : `}</span>
                {conditionalTotalToShow}
              </span>
            )}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ x: 450, y: 250 }}
            className="xai-view-data"
          />

          <ExplainableAIPagination
            selectedFormulations={selectedFormulations}
            version={version}
            total={conditionalTotalToShow}
            currentSelectedWo={currentSelectedWo}
              currentSelectedStage={currentSelectedStage}
          />
        </Space>
      )}
    </Spin>
  )
}
