import { Cascader, Form, FormInstance, Input, Select, Table, Tag } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import "../Common.scss";
import ParameterDropdown from "./ParameterDropdown";
import useTranslate from "src/utils/useTranslate";
import { OPERATORS, PARAMETER_TYPES } from "../Data";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";

const columns: any = [
    {
        title: "Parameter",
        dataIndex: "parameter_id",
        key: "parameter_id",
        width: 150,
    },
    {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 150,
    },
    {
        title: "Value",
        dataIndex: "value",
        key: "value",
    },
    {
        title: "",
        dataIndex: "unit",
        key: "unit",
        fixed: "right",
        width: 100,
    },
];

type TProps = {
    rawTableData: { [key: string]: any }[];
    setRawTableData: React.Dispatch<
        React.SetStateAction<
            {
                [key: string]: any;
            }[]
        >
    >;
    parameters?: any[];
    editable?: boolean;
    form?: any;
    methodForm: FormInstance<any>;
    mode: "create" | "edit";
};

type onChangeValueType = string | number | (string | number)[];

const MethodParameters = ({
    rawTableData,
    setRawTableData,
    parameters,
    editable = true,
    methodForm,
    mode,
}: TProps) => {
    const [t] = useTranslate();
    const [open, setOpen] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState<string[]>(
        rawTableData && rawTableData.length > 0
            ? rawTableData.map((r) => r.parameter_id)
            : []
    );
    const unitsList = useSelector(
        (state: StoreState) => state.conversion.unitList
    );

    useEffect(() => {
        if (mode === "create") {
            if (parameters && Array.isArray(parameters))
                setSelectedParameters(
                    parameters
                        .filter((p) => p.is_default)
                        ?.map((p) => p.parameter_id as string)
                );
            else setSelectedParameters([]);
        }
    }, [parameters, mode]);

    useEffect(() => {
        if (editable) {
            setRawTableData((prev) =>
                selectedParameters.map((parameter_id) => {
                    const existing = prev.findIndex((p) => p.key === parameter_id);
                    if (existing !== -1) return prev[existing];
                    else
                        return {
                            key: parameter_id,
                            parameter_id: parameter_id,
                            is_default: parameters?.find(
                                (p) => p.parameter_id === parameter_id
                            )?.is_default,
                        };
                })
            );
        }
    }, [selectedParameters, editable, setRawTableData, parameters]);

    const unselectedParameters = useMemo(() => {
        return parameters
            ? parameters.filter(
                (p) =>
                    rawTableData.filter((r) => r.key === p.parameter_id).length === 0
            )
            : [];
    }, [parameters, rawTableData]);

    const onParameterChange = useCallback(
        (parameter_id: string, new_parameter_id: string, deleteParam = false) => {
            if (!deleteParam)
                setSelectedParameters((prev) =>
                    prev.map((p) => (p === parameter_id ? new_parameter_id : p))
                );
            else
                setSelectedParameters((prev) => prev.filter((p) => p !== parameter_id));

            methodForm.setFieldsValue({
                [`${parameter_id}_type`]: undefined,
                [`${parameter_id}_unit`]: undefined,
                [`${parameter_id}_value`]: undefined,
                [`${parameter_id}_value_max`]: undefined,
                [`${parameter_id}_value_str`]: undefined,
                [`${parameter_id}_operator`]: undefined,
            });
        },
        [methodForm]
    );

    const onTypeChange = useCallback(
        (parameter_id: string, value: onChangeValueType) => {
            setRawTableData((prev) =>
                prev.map((p) => {
                    if (p.key === parameter_id)
                        return {
                            key: p.key,
                            parameter_id: p.parameter_id,
                            value_type: Array.isArray(value) ? value[0] : undefined,
                            value_subtype: Array.isArray(value) ? value[1] : undefined,
                        };
                    return p;
                })
            );

            methodForm.setFieldsValue({
                [`${parameter_id}_unit`]: undefined,
                [`${parameter_id}_value`]: undefined,
                [`${parameter_id}_value_max`]: undefined,
                [`${parameter_id}_value_str`]: undefined,
                [`${parameter_id}_operator`]: undefined,
            });
        },
        [setRawTableData, methodForm]
    );

    const onValueChange = useCallback(
        (parameter_id: string, key: string, value: onChangeValueType) => {
            setRawTableData((prev) =>
                prev.map((p) => {
                    if (p.key === parameter_id)
                        return {
                            ...p,
                            [key]: value,
                        };
                    return p;
                })
            );
        },
        [setRawTableData]
    );

    const parameterSelection = useCallback(
        (parameter_id: string, isDefault = false) => {
            if (isDefault)
                return (
                    <>
                        {
                            parameters?.find((p) => p.parameter_id === parameter_id)
                                ?.parameter
                        }{" "}
                        <Tag>{t("common.default")}</Tag>
                    </>
                );

            const currentParameter = parameters?.find(
                (p) => p.parameter_id === parameter_id
            );
            const filteredParameters = [...unselectedParameters];
            if (currentParameter) filteredParameters.push(currentParameter);

            return (
                <div className="parameter-block">
                    {!isDefault && (
                        <MinusCircleOutlined
                            className="delete-icon"
                            onClick={() => onParameterChange(parameter_id, "", true)}
                        />
                    )}
                    <Form.Item
                        name={`${parameter_id}_parameter`}
                        rules={[
                            {
                                required: true,
                                message: t("common.required"),
                            },
                        ]}
                        initialValue={parameter_id}
                    >
                        <Select
                            className="custom-table-input"
                            placeholder={t("common.select")}
                            onChange={(v) => onParameterChange(parameter_id, v)}
                            options={filteredParameters?.map((p) => ({
                                label: p.parameter,
                                value: p.parameter_id,
                            }))}
                        />
                    </Form.Item>
                </div>
            );
        },
        [unselectedParameters, onParameterChange, parameters, t]
    );

    const typeSelection = useCallback(
        (parameter_id: string, type: string[]) => {
            return (
                <Form.Item
                    name={`${parameter_id}_type`}
                    rules={[
                        {
                            required: true,
                            message: t("common.required"),
                        },
                    ]}
                    initialValue={type}
                >
                    <Cascader
                        className="custom-table-input"
                        placeholder={t("common.select")}
                        options={PARAMETER_TYPES}
                        onChange={(v) => onTypeChange(parameter_id, v)}
                        displayRender={(labels) =>
                            `${labels[0].substring(0, 3)}./${labels[1]}`
                        }
                    />
                </Form.Item>
            );
        },
        [t, onTypeChange]
    );

    const renderFormFieldByType = useCallback(
        (parameter_id: string, type: string[], value?: onChangeValueType) => {
            if (type[0] === "numerical") {
                if (type[1] === "single")
                    return (
                        <Form.Item
                            name={`${parameter_id}_value`}
                            rules={[
                                {
                                    required: true,
                                    message: t("common.required"),
                                },
                            ]}
                            initialValue={value}
                        >
                            <Input
                                type="number"
                                placeholder="-"
                                className="custom-table-input"
                                onChange={(e) =>
                                    onValueChange(parameter_id, "value", e.target.value)
                                }
                            />
                        </Form.Item>
                    );

                if (type[1] === "range")
                    return (
                        <div className="range-container">
                            <Form.Item
                                name={`${parameter_id}_value`}
                                rules={[
                                    {
                                        required: true,
                                        message: t("common.required"),
                                    },
                                ]}
                                initialValue={Array.isArray(value) ? value[0] : undefined}
                            >
                                <Input
                                    type="number"
                                    placeholder="-"
                                    className="custom-table-input"
                                    onChange={(e) =>
                                        onValueChange(parameter_id, "value", e.target.value)
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                dependencies={[`${parameter_id}_value`]}
                                name={`${parameter_id}_value_max`}
                                rules={[
                                    {
                                        required: true,
                                        message: t("common.required"),
                                    },
                                    {
                                        validator: (_, value, callback) => {
                                            const minValue = methodForm.getFieldValue(`${parameter_id}_value`);
                                            if (
                                                !value ||
                                                !minValue ||
                                                (Number(minValue) < Number(value))
                                            ) {
                                                callback();
                                            }
                                            else {
                                                callback(t("aiEngine.maxShouldBeGreaterThanMin"))
                                            }
                                        },
                                    }
                                ]}
                                initialValue={Array.isArray(value) ? value[1] : undefined}
                            >
                                <Input
                                    type="number"
                                    placeholder="-"
                                    className="custom-table-input"
                                    onChange={(e) =>
                                        onValueChange(parameter_id, "value_max", e.target.value)
                                    }
                                />
                            </Form.Item>
                        </div>
                    );

                if (type[1] === "operator")
                    return (
                        <div className="range-container">
                            <Form.Item
                                name={`${parameter_id}_operator`}
                                rules={[
                                    {
                                        required: true,
                                        message: t("common.required"),
                                    },
                                ]}
                                initialValue={Array.isArray(value) ? value[0] : undefined}
                            >
                                <Select
                                    placeholder="-"
                                    className="custom-table-input"
                                    onChange={(v) => onValueChange(parameter_id, "operator", v)}
                                    options={OPERATORS}
                                />
                            </Form.Item>
                            <Form.Item
                                name={`${parameter_id}_value`}
                                rules={[
                                    {
                                        required: true,
                                        message: t("common.required"),
                                    },
                                ]}
                                initialValue={Array.isArray(value) ? value[1] : undefined}
                            >
                                <Input
                                    type="number"
                                    placeholder="-"
                                    className="custom-table-input"
                                    onChange={(e) =>
                                        onValueChange(parameter_id, "value", e.target.value)
                                    }
                                />
                            </Form.Item>
                        </div>
                    );
            }
            if (type[0] === "categorical") {
                if (type[1] === "single")
                    return (
                        <Form.Item
                            name={`${parameter_id}_value_str`}
                            rules={[
                                {
                                    required: true,
                                    message: t("common.required"),
                                },
                            ]}
                            initialValue={value}
                        >
                            <Input
                                placeholder="-"
                                className="custom-table-input"
                                onChange={(e) =>
                                    onValueChange(parameter_id, "value_str", e.target.value)
                                }
                            />
                        </Form.Item>
                    );
            }
        },
        [onValueChange, t, methodForm]
    );

    const getParameterUnitOptions = useCallback((parameter_id) => {
        const parameter = parameters?.find((p) => p.parameter_id === parameter_id);
        
        return parameter?.units?.length ? [...new Set(parameter?.units)].map(unit => ({
            label: unit,
            value: unit
        })) : unitsList.map((u) => ({ label: u.name, value: u.name }));
    }, [parameters, unitsList]);

    const renderUnitFieldByType = useCallback(
        (parameter_id: string, type: string[], value: string) => {
            return (
                <Form.Item
                    name={`${parameter_id}-unit`}
                    rules={[
                        {
                            required: true,
                            message: t("common.required"),
                        },
                    ]}
                    initialValue={value}
                >
                    <Select
                        placeholder="-"
                        showSearch={true}
                        allowClear={true}
                        className="custom-table-input"
                        onChange={(v) => onValueChange(parameter_id, "unit", v)}
                        options={getParameterUnitOptions(parameter_id)}
                    />
                </Form.Item>
            );
        },
        [onValueChange, t, getParameterUnitOptions]
    );

    const tableData: any = useMemo(() => {
        if (!editable) {
            return rawTableData.map((r) => ({
                key: r.parameter_id,
                parameter_id: r.parameter_id,
                type: r.value_subtype,
                value:
                    r.value_subtype === "range" ? (
                        <>{r.value.join("-")}</>
                    ) : (
                        <>{r.value}</>
                    ),
                unit: r.unit,
            }));
        } else {
            return rawTableData.map((r) => {
                let value = r.value;
                if (r.value_type && r.value_subtype === "range")
                    value = [r.value, r.value_max];
                if (r.value_type && r.value_subtype === "operator")
                    value = [r.operator, r.value];
                if (
                    r.value_type &&
                    r.value_type === "categorical" &&
                    r.value_subtype === "single"
                )
                    value = r.value_str;

                return {
                    key: r.key,
                    parameter_id: parameterSelection(r.parameter_id, r.is_default),
                    type: typeSelection(
                        r.parameter_id,
                        r.value_type && [r.value_type, r.value_subtype]
                    ),
                    value:
                        r.value_type &&
                        renderFormFieldByType(
                            r.parameter_id,
                            [r.value_type, r.value_subtype],
                            value
                        ),
                    unit:
                        r.value_type &&
                        renderUnitFieldByType(
                            r.parameter_id,
                            [r.value_type, r.value_subtype],
                            r.unit
                        ),
                };
            });
        }
    }, [
        editable,
        parameterSelection,
        rawTableData,
        typeSelection,
        renderFormFieldByType,
        renderUnitFieldByType,
    ]);

    return (
        <div className="method-parameter-container">
            <div className="method-parameter-header">
                <div className="method-parameter-title">
                    {t("common.parameters")} ({selectedParameters.length})
                </div>
                {parameters && (
                    <ParameterDropdown
                        selectedParameters={selectedParameters}
                        setSelectedParameters={setSelectedParameters}
                        parameters={parameters}
                        allowEditing={editable}
                        open={open}
                        setOpen={setOpen}
                        showAddPropertyButton={false}
                        fetchProperties={false}
                    />
                )}
            </div>
            <div className="method-parameter-content">
                <Table
                    columns={columns}
                    dataSource={tableData}
                    size="middle"
                    pagination={false}
                    className="repository-table"
                />
            </div>
        </div>
    );
};

export default MethodParameters;
