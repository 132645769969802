import { Col, Drawer, Row } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../../Shared/SEMAnalysis.scss";
import FiberAnalysisDetailsContent from "./FiberAnalysisDetailsContent";
import FiberAnalysisDetailsSidebar from "./FiberAnalysisDetailsSidebar";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSemAnalysisMaskDataRequest,
  fetchFiberBatchInfoRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import FiberAnalysisAttributes from "./FiberAnalysisAttributes";
import { AsyncStates } from "src/constants";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";
import { StyledButton } from "src/styled_components/StyledButton";
import { extractNumericValue } from "../../Shared/MaskTable";

const FiberAnalysisDetails = () => {
  const location = useLocation<any>();
  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(100);
  const [widthRangeThreshold, setWidthRangeThreshold] = useState([
    minWidth,
    maxWidth,
  ]);
  const [minLength, setMinLength] = useState(0);
  const [maxLength, setMaxLength] = useState(100);
  const [lengthRangeThreshold, setLengthRangeThreshold] = useState([
    minLength,
    maxLength,
  ]);
  const [showAllMasks, setShowAllMasks] = useState(
    location.state?.showAllMasks ?? true
  );
  const [showBBox, setShowBBox] = useState(location.state?.showBBox ?? true);
  const [showOriginalImage, setShowOriginalImage] = useState(
    location.state?.showOriginalImage ?? true
  );
  const {
    semAnalysisMaskData,
    semAnalysisMaskDataStatus,
    fiberBatchInfo,
    analysisTypes,
  } = useSelector((state: StoreState) => state.semAnalysis);

  const fetchMasks = useCallback(() => {
    dispatch(
      fetchSemAnalysisMaskDataRequest({
        file_id: params.fileId,
        batch_id: params.batchId,
        analysis_type:
          analysisTypes[
            IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS as keyof typeof analysisTypes
          ],
      })
    );
  }, [analysisTypes, dispatch, params.batchId, params.fileId]);

  useLayoutEffect(() => {
    fetchMasks();
  }, [fetchMasks]);

  useEffect(() => {
    if (semAnalysisMaskDataStatus === AsyncStates.SUCCESS) {
      const widths = semAnalysisMaskData?.mask_results?.map(
        (item: any) => item.width
      ) || [0];
      setMinWidth(Math.min(...widths));
      setMaxWidth(Math.max(...widths));

      const lengths = semAnalysisMaskData?.mask_results?.map(
        (item: any) => item.calibrated_length?.value ? extractNumericValue(item.calibrated_length.value) : item.max_length.pixel_length
      ) || [0];
      setMinLength(Math.min(...lengths));
      setMaxLength(Math.max(...lengths));
    }
  }, [semAnalysisMaskDataStatus, semAnalysisMaskData]);

  useEffect(() => {
    if (
      !fiberBatchInfo ||
      (Array.isArray(fiberBatchInfo) && fiberBatchInfo.length === 0)
    ) {
      dispatch(
        fetchFiberBatchInfoRequest({
          batch_id: params?.batchId,
          analysis_type:
            analysisTypes[
              IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS as keyof typeof analysisTypes
            ],
        })
      );
    }
  }, [fiberBatchInfo, dispatch, params?.batchId, analysisTypes]);

  const maskData = useMemo(() => {
    return semAnalysisMaskData?.mask_results?.filter((item: any) => {
      const width = item.width;
      const length = item.calibrated_length?.value ? extractNumericValue(item.calibrated_length.value) : item.max_length.pixel_length;

      return width >= widthRangeThreshold[0] && width <= widthRangeThreshold[1] && length >= lengthRangeThreshold[0] && length <= lengthRangeThreshold[1]
    });
  }, [semAnalysisMaskData, widthRangeThreshold, lengthRangeThreshold]);

  const imageToShow = useMemo(() => {
    return showOriginalImage
      ? semAnalysisMaskData?.original_image_url
      : semAnalysisMaskData?.binary_image_url;
  }, [semAnalysisMaskData, showOriginalImage]);

  const files = useMemo(() => {
    if (
      fiberBatchInfo &&
      Array.isArray(fiberBatchInfo) &&
      fiberBatchInfo.length > 0
    ) {
      const currentBatch = fiberBatchInfo.find((batch) => batch?.batch_id);

      return currentBatch?.thumbnails || [];
    }

    return [];
  }, [fiberBatchInfo]);

  const onDrawerClose = () =>
    history.push(
      `/sem-analysis/${IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS}/batch/${params.batchId}`
    );

  const goNext = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    const nextFileId = files[currentFileIndex + 1]?.file_id;

    history.push({
      pathname: `/sem-analysis/${IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS}/batch/${params.batchId}/files/${nextFileId}`,
      state: { showAllMasks, showBBox, showOriginalImage },
    });
  };

  const goPrevious = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    const prevFileId = files[currentFileIndex - 1]?.file_id;

    history.push({
      pathname: `/sem-analysis/${IMAGE_ANALYSIS_TYPES.FIBER_ANALYSIS}/batch/${params.batchId}/files/${prevFileId}`,
      state: { showAllMasks, showBBox, showOriginalImage },
    });
  };

  const hasPreviousFile = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    return currentFileIndex <= 0 ? false : true;
  };

  const hasNextFile = () => {
    const fileId = params.fileId;
    const currentFileIndex = files.findIndex(
      (file: any) => file.file_id === fileId
    );
    return currentFileIndex >= files.length - 1 ? false : true;
  };

  return (
    <Drawer
      title={"Fiber Analysis"}
      placement="bottom"
      open={true}
      height="100%"
      styles={{
        body: { padding: 0 },
      }}
      onClose={onDrawerClose}
      className="fiber-analysis-drawer"
      extra={
        <>
          {files && files.length > 0 && (
            <Row gutter={[8, 8]}>
              <Col>
                <StyledButton
                  type="link"
                  disabled={!hasPreviousFile()}
                  onClick={goPrevious}
                >
                  <LeftOutlined /> Previous Image
                </StyledButton>
              </Col>
              <Col>
                <StyledButton
                  type="link"
                  disabled={!hasNextFile()}
                  onClick={goNext}
                >
                  Next Image <RightOutlined />
                </StyledButton>
              </Col>
            </Row>
          )}
        </>
      }
    >
      <div className="container">
        <FiberAnalysisDetailsSidebar
          showOriginalImage={showOriginalImage}
          setShowOriginalImage={setShowOriginalImage}
          minWidth={minWidth}
          maxWidth={maxWidth}
          minLength={minLength}
          maxLength={maxLength}
          setWidthRangeThreshold={setWidthRangeThreshold}
          setLengthRangeThreshold={setLengthRangeThreshold}
          showAllMasks={showAllMasks}
          setShowAllMasks={setShowAllMasks}
          showBBox={showBBox}
          setShowBBox={setShowBBox}
        />
        <FiberAnalysisDetailsContent
          showAllMasks={showAllMasks}
          filteredData={maskData}
          image={imageToShow}
          showBBox={showBBox}
        />
      </div>
      <div className="info-container">
        <FiberAnalysisAttributes data={semAnalysisMaskData} />
      </div>
    </Drawer>
  );
};

export default FiberAnalysisDetails;
