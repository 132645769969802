import { handleActions } from "redux-actions";
import {
  LoginFirebaseActionTypes,
} from "../actions/firebase";
import { AsyncStates } from "../../constants";
import { CheckUserExists } from "../../services/login/interface";

export interface FirebaseResponse extends CheckUserExists {
  displayName: string;
  isUserExists?: boolean;
}

export enum LoginProgress {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  NOT_VERIFIED = "NOT_VERIFIED"
}

export type LoginFirebaseReducerState = {
  status: AsyncStates;
  error: string;
  response: FirebaseResponse;
};

const defaultState: LoginFirebaseReducerState = {
  status: AsyncStates.INITIAL,
  error: "",
  // loginResponse: {
  //   company: "",
  //   company_location: "",
  //   message: "",
  //   status: "",
  //   token: "",
  //   refresh_token: "",
  //   user_access: "",
  //   user_email: "",
  //   user_id: "",
  //   user_location: "",
  //   user_loggedin_IP: "",
  //   user_name: "",
  //   user_role: "",
  //   user_trial_days: 0,
  //   user_verified: false,
  //   is_masked: false,
  // },
  response: {
    user_email: "",
    uid: "",
    firebase_token: "",
    firebase_accessToken: "",
    displayName: "",
    isUserExists: false,
    user_name: ""
  },
};

const firebaseReducer = handleActions<any, any>(
  {
    [LoginFirebaseActionTypes.CLEAR]: (state) => ({
      ...state,
      status: AsyncStates.INITIAL,
      firebaseError: "",
    }),
    [LoginFirebaseActionTypes.REQUEST]: (state) => ({
      ...state,
      status: AsyncStates.LOADING,
      firebaseError: "",
    }),
    [LoginFirebaseActionTypes.SUCCESS]: (state, action) => {
      return {
        ...state,
        status: AsyncStates.SUCCESS,
        error: "",
        response: action.payload.firebaseResponse,
      };
    },
    [LoginFirebaseActionTypes.FAILURE]: (state, action) => ({
      ...state,
      status: AsyncStates.ERROR,
      error: action.payload.error
    })
  },
  defaultState
);

export default firebaseReducer;
