import {
  // FolderAddOutlined,
  // MoreOutlined,
  // PlusOutlined
} from '@ant-design/icons'
import {
  // MenuProps, Upload,
  // Dropdown,
  UploadProps,
  // Space, Button
} from 'antd'
import React
// , { useMemo }
  from 'react'
// import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import AddFolderModal from './AddFolderModal'
// import { useSelector } from 'react-redux'
// import { StoreState } from 'src/store/configureStore'
// import { ItemType } from 'antd/lib/menu/hooks/useItems'

type P = {
  onAddFolder: (folderName: string) => void
  uploadProps?: UploadProps
  addFolderModalVisible: boolean
  setAddFolderModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  copyDirectories: () => void
  deleteDirectories: () => void
  downloadDirectories: () => void
  renameDirectories: () => void
  moveDirectories: () => void
}

const TopHeader = ({
  onAddFolder,
  uploadProps,
  addFolderModalVisible,
  setAddFolderModalVisible,
  copyDirectories,
  deleteDirectories,
  downloadDirectories,
  renameDirectories,
  moveDirectories
}: P) => {
  // selectors
  // const { assetLibrarySelectedDirectories } = useSelector(
  //   (state: StoreState) => state.assetLibrary
  // )

  // Memo

  // const isSelected = useMemo(
  //   () => assetLibrarySelectedDirectories.length > 0,
  //   [assetLibrarySelectedDirectories]
  // )

  // const contextMenuItems: ItemType[] = useMemo(() => {
  //   return [
  //     {
  //       label: 'Copy',
  //       key: 'copy',
  //       onClick: (info) => {
  //         info?.domEvent.stopPropagation()
  //         copyDirectories()
  //       },
  //       style: {
  //         outline: 'none'
  //       },
  //       disabled: !isSelected
  //     },
  //     {
  //       label: 'Delete',
  //       key: 'delete',
  //       danger: true,
  //       onClick: (info) => {
  //         info?.domEvent.stopPropagation()
  //         deleteDirectories()
  //       },
  //       style: {
  //         outline: 'none'
  //       },
  //       disabled: !isSelected
  //     },
  //     {
  //       label: 'Move',
  //       key: 'move',
  //       onClick: (info) => {
  //         info?.domEvent.stopPropagation()
  //         moveDirectories()
  //       },
  //       style: {
  //         outline: 'none'
  //       },
  //       disabled: !isSelected
  //     },
  //     ...(assetLibrarySelectedDirectories.length === 1
  //       ? [
  //         {
  //           label: 'Rename',
  //           key: 'rename',
  //           onClick: (info: any) => {
  //             info?.domEvent.stopPropagation()
  //             renameDirectories()
  //           },
  //           style: {
  //             outline: 'none'
  //           }
  //         }
  //       ]
  //       : []),
  //     {
  //       label: 'Download',
  //       key: 'download',
  //       onClick: (info) => {
  //         info?.domEvent.stopPropagation()
  //         downloadDirectories()
  //       },
  //       style: {
  //         outline: 'none'
  //       },
  //       disabled: !isSelected
  //     }
  //   ]
  // }, [isSelected, assetLibrarySelectedDirectories.length, copyDirectories, deleteDirectories, renameDirectories, downloadDirectories, moveDirectories])

  // const addToLibraryButtonItems: MenuProps = useMemo(() => {
  //   return {
  //     items: [
  //       {
  //         key: 'addFolder',
  //         label: 'Add Folder',
  //         icon: <FolderAddOutlined />,
  //         onClick: () => {
  //           setAddFolderModalVisible(true)
  //         },
  //         style: {
  //           outline: 'none'
  //         }
  //       },
  //       {
  //         key: 'addFiles',
  //         label: <Upload {...uploadProps}>Add Files</Upload>,
  //         icon: <PlusOutlined />,
  //         // onClick: () => console.log('Add Files'),
  //         style: {
  //           outline: 'none'
  //         }
  //       }
  //     ]
  //   }
  // }, [setAddFolderModalVisible, uploadProps])

  return (
    <>
      <StyledPageHeader
        ghost={false}
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            {`Asset Library`}
          </div>
        }
        style={{ height: '5%' }}
      // extra={
      //   <Space>
      //     <Dropdown menu={addToLibraryButtonItems} placement="bottomRight">
      //       <StyledButton type="primary" icon={<PlusOutlined />}>
      //         Add
      //       </StyledButton>
      //     </Dropdown>
      //     <Dropdown
      //       menu={{
      //         items: contextMenuItems
      //       }}
      //       trigger={['click']}
      //       placement="bottomRight"
      //       disabled={assetLibrarySelectedDirectories.length === 0}
      //       forceRender={true}
      //     >
      //       <Button
      //         type="default"
      //         style={{
      //           outline: 'none',
      //           padding: '0 6px'
      //         }}
      //         onClick={(e) => {
      //           e.stopPropagation()
      //         }}
      //       >
      //         <MoreOutlined />
      //       </Button>
      //     </Dropdown>
      //   </Space>
      // }
      />
      <AddFolderModal
        open={addFolderModalVisible}
        closeModal={() => setAddFolderModalVisible(false)}
        onAddFolder={onAddFolder}
      />
    </>
  )
}

export default TopHeader
