import { memo, useEffect, useMemo, useState } from "react";
import { DownOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Input, Menu, Popover, Space, Spin, Typography } from 'antd'
import clsx from "clsx";
import "./style.css";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
const { Text } = Typography;

const CustomTableFilter = memo(({ isProjects, setWoSearchTerm, projectIds, setProjectIds }: any) => {
    const [t] = useTranslate();
    const { status } = useSelector(
        (state: StoreState) => state.workOrders
    )
    const { projectList, current: currentProject } = useSelector((state: StoreState) => state.projects);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [localSearchInputState, setLocalSearchInputState] = useState<string>("");

    useEffect(() => {
        // Debounce search
        const timer = setTimeout(() => {
            setWoSearchTerm(localSearchInputState)
        }, 300)

        return () => clearTimeout(timer);
    }, [setWoSearchTerm, localSearchInputState]);

    const filteredProjects = useMemo(() => {
        if (searchTerm && String(searchTerm).length > 0) return projectList.filter((p: any) => p.name.toLowerCase().includes(searchTerm.toLowerCase()))

        return projectList;
    }, [projectList, searchTerm])

    const projectDropDownMenu = useMemo(() => {
        if (isProjects) return null;

        return (
            <Spin
                indicator={<LoadingOutlined />}
                size="large"
                spinning={status === AsyncStates.LOADING}
                style={{
                    paddingBottom: 12
                }}
            >
                <Space style={{ width: "100%" }} size="small" direction="vertical">
                    <Input.Search
                        placeholder={t("projects.searchProjects")}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Menu style={{ padding: 5, maxHeight: 500, overflowY: "auto" }}>
                        <Menu.Item key={"select_all"}>
                            <Checkbox
                                checked={projectIds.length === projectList.length}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                    e.target.checked
                                        ? setProjectIds(
                                            projectList.map(({ project_id }) => project_id)
                                        )
                                        : setProjectIds([currentProject])
                                }}
                            >
                                {t("common.viewAllProjects")}
                            </Checkbox>
                        </Menu.Item>
                        <Divider style={{ marginTop: 2, marginBottom: 2 }} />
                        {filteredProjects.map(({ project_id, name }) => (
                            <Menu.Item key={project_id}>
                                <Checkbox
                                    onClick={(e) => e.stopPropagation()}
                                    checked={projectIds.includes(project_id)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setProjectIds((prevState: any) => [
                                                ...prevState,
                                                project_id,
                                            ])
                                        } else {
                                            setProjectIds((prevState: any) =>
                                                prevState.filter((res: any) => res !== project_id)
                                            )
                                        }
                                    }}
                                >
                                    {name}
                                </Checkbox>
                            </Menu.Item>
                        ))}
                    </Menu>
                </Space>
            </Spin>
        )
    }, [isProjects, status, t, projectIds, projectList, filteredProjects, setProjectIds, currentProject])

    return (
        <div className={clsx("custom__table__filter__search__container")}>
            <Input
                placeholder={t('common.search')}
                prefix={<SearchOutlined />}
                className="table__filter__input"
                onChange={(e) => setLocalSearchInputState(e.target.value)}
            />
            {!isProjects &&
                <div style={{ maxHeight: 32, marginLeft: 10 }}>
                    <Popover
                        content={projectDropDownMenu}
                        placement="bottom"
                        trigger="click"
                    >
                        <StyledButton
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}
                            type="default"
                            size="middle"
                        >
                            <Text
                                ellipsis={{
                                    tooltip:
                                        projectIds?.length > 1
                                            ? "Multiple Projects"
                                            : projectList.find(
                                                (res: any) =>
                                                    res?.project_id === projectIds?.[0],
                                            )?.name || "No Project selected",
                                }}
                                strong
                            >
                                {projectIds?.length > 1
                                    ? "Multiple Projects"
                                    : projectList.find(
                                        (res: any) =>
                                            res?.project_id === projectIds?.[0],
                                    )?.name || "No Project selected"}
                            </Text>
                            <DownOutlined />
                        </StyledButton>
                    </Popover>
                </div>
            }
        </div>
    );
})

export default memo(CustomTableFilter);