import { grey, orange } from "@ant-design/colors";
import {
  HeatMapOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Modal, Pagination, Row, Select, Space, Spin, Table, Tooltip, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates, EXP_PAGE_SIZE } from "src/constants";
import {
  editExperimentCleanup,
  editExperimentRequest,
  listProcessingProfilesRequest,
} from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { AddProfiles } from "./modals/AddProfiles";
import { ViewProfiles } from "./modals/ViewProfiles";
import "../../WorkOrderDetails/WorkOrderTable.scss";
import useTranslate from "src/utils/useTranslate";
import { setIsEditing } from "src/store/actions/isEditing";
import { fetchExperimentRequest } from "src/store/actions/experiment";

const { Text } = Typography;
const { Option } = Select;

export const NestleProfiles = ({
  initialTrials,
  disabled,
  size,
  title,
}: any) => {
  const dispatch = useDispatch();
  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder
  );
  const experimentStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.experimentStatus
  );
  const editFormulationsStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.editFormulationsStatus
  );
  const processingProfilesList = useSelector(
    (state: StoreState) => state.workOrderDetails.processingProfilesList
  );
  const processingProfilesLoading = useSelector(
    (state: StoreState) => state.workOrderDetails.processingProfilesLoading
  );
  const initialTrialSetList = useMemo(
    () =>
      initialTrials.flatMap(
        (trialSet: any) => trialSet?.processing?.[0]?.meta || {}
      ),
    [initialTrials]
  );
  const expIndex = useMemo(
    () =>
      workOrder?.stages?.findIndex(
        (stage: any) => stage?.identifier === workOrder?.work_order_parent_stage
      ),
    [workOrder?.stages, workOrder?.work_order_parent_stage]
  );
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<any>({});
  const [data, setData] = useState<any>({});
  const [t] = useTranslate();
  const isEditing = useSelector((state: StoreState) => state.isEditing);
  const expTotal = useSelector(
    (state: StoreState) => state.workOrderDetails.total
  );
  const editingState = useSelector(
    (state: StoreState) => state.workOrderDetails.editingState
  );
  const [current, setCurrent] = useState<number>(1);

  useEffect(() => {
    dispatch(listProcessingProfilesRequest());
  }, [dispatch]);

  useEffect(() => {
    setData({
      ...initialTrialSetList.reduce(
        (acc: any, res: any, index: number) => ({
          ...acc,
          [initialTrials[index]?.id_set?.formulation_id]: res?.profile_id ?? "",
        }),
        {}
      ),
    });
  }, [initialTrialSetList, initialTrials]);

  useEffect(() => {
    if (isEditing && editFormulationsStatus === AsyncStates.SUCCESS)
      dispatch(setIsEditing(false));
    if (editFormulationsStatus === AsyncStates.SUCCESS)
      dispatch(editExperimentCleanup());
  }, [dispatch, editFormulationsStatus, isEditing]);

  const showViewModal = useCallback(
    (text) => {
      setCurrentProfile(
        processingProfilesList.find((res: any) => res?.profile_id === text)
      );
      setViewModalVisible(true);
    },
    [processingProfilesList]
  );

  const columns: any = useMemo(
    () => [
      {
        key: "profile",
        dataIndex: "profile",
        title: "-",
        fixed: "left",
        width: 100,
        render: () => <Text strong>{"Profile"}</Text>,
      },
      ...initialTrials.map((res: any) => ({
        key: res?.id_set?.formulation_id,
        dataIndex: res?.id_set?.formulation_id,
        align: "center",
        title: () => (
          <Space
            style={{ width: "100%" }}
            align="center"
            size="small"
            direction="vertical"
          >
            <Typography.Title level={5} style={{ color: grey[1] }}>
              {res?.meta?.display_id}
            </Typography.Title>
          </Space>
        ),
        width: 150,
        render: (text: any, record: any) => {
          return disabled ? (
            text ? (
              <StyledButton type="link" onClick={() => showViewModal(text)}>
                {
                  processingProfilesList.find(
                    (res: any) => res?.profile_id === text
                  )?.name
                }
              </StyledButton>
            ) : null
          ) : (
            <Space>
              {text && (
                <Tooltip title={"View Profile details"}>
                  <InfoCircleOutlined
                    onClick={() => showViewModal(text)}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              )}
              <Select
                allowClear
                value={text || undefined}
                style={{ width: "100%" }}
                placeholder={"Select Profile"}
                bordered={false}
                onChange={(e) => {
                  dispatch(setIsEditing(true));
                  setData((prevState: any) => {
                    prevState[res?.id_set?.formulation_id] = e;
                    return { ...prevState };
                  });
                }}
              >
                {processingProfilesList?.map((res: any) => (
                  <Option key={res?.profile_id} value={res?.profile_id}>
                    {res?.name}
                  </Option>
                ))}
              </Select>
            </Space>
          );
        },
      })),
    ],
    [initialTrials, disabled, processingProfilesList, showViewModal, dispatch]
  );

  const saveProcessingTrials = () => {
    const {
      material,
      application,
      work_order_id,
      experiment_id,
      work_order_parent_stage,
    } = workOrder;
    const exp_data: any = Object.entries(data || {}).map(
      ([key, value]: any) => ({
        formulation_id: key,
        input_data: {},
        input_meta: { profile_id: value ?? "" },
      })
    );
    dispatch(
      editExperimentRequest({
        stage: work_order_parent_stage,
        material,
        application,
        work_order_id,
        exp_data,
        input_key: "processing",
        experiment_id: experiment_id[expIndex],
      })
    );
  };

  return (
    <Spin
      spinning={
        processingProfilesLoading === AsyncStates.LOADING ||
        editFormulationsStatus === AsyncStates.LOADING ||
        experimentStatus === AsyncStates.LOADING
      }
      indicator={<LoadingOutlined />}
    >
      <>
        {!disabled && (
          <Row justify={"end"}>
            <Space>
              {isEditing && (
                <Text style={{ color: orange.primary }}>
                  {t("common.unsavedChanges")}
                </Text>
              )}
              <StyledButton type="primary" onClick={saveProcessingTrials}>
                {"Save"}
              </StyledButton>
              <StyledButton
                type="primary"
                ghost
                onClick={() => setProfileModalVisible(true)}
              >
                {"Add/Edit Profile"}
              </StyledButton>
            </Space>
          </Row>
        )}
        <Row justify="end">
          <Pagination
            total={expTotal}
            current={current}
            pageSize={20}
            showSizeChanger={false}
            onChange={(e) => {
              if (editingState) {
                Modal.confirm({
                  title: "Unsaved changes present",
                  onCancel: () => { },
                  onOk: () => {
                    setCurrent(e);
                    dispatch(
                      fetchExperimentRequest({
                        experiment_id: [workOrder.experiment_id?.[expIndex]],
                        page_num: e,
                        page_size: EXP_PAGE_SIZE
                      })
                    );
                  },
                });
              } else {
                setCurrent(e);
                dispatch(
                  fetchExperimentRequest({
                    experiment_id: [workOrder.experiment_id?.[expIndex]],
                    page_num: e,
                    page_size: EXP_PAGE_SIZE
                  })
                );
              }
            }}
          />
        </Row>
        <div>
          <span className="highlighted">{t("common.totalTrials")}</span> {expTotal}
        </div>
        <Table
          className={"report-table"}
          title={() =>
            !!title ? (
              <Space>
                <HeatMapOutlined />
                <Text strong type="secondary">
                  {title}
                </Text>{" "}
              </Space>
            ) : null
          }
          columns={columns}
          dataSource={[data]}
          bordered
          pagination={false}
          size={size}
          scroll={{ x: 450 }}
        />
        <AddProfiles
          profileModalVisible={profileModalVisible}
          setProfileModalVisible={setProfileModalVisible}
        />
        <ViewProfiles
          viewModalVisible={viewModalVisible}
          setViewModalVisible={setViewModalVisible}
          currentProfile={currentProfile}
          setCurrentProfile={setCurrentProfile}
        />
      </>
    </Spin>
  );
};
