import { createActions } from "redux-actions";

export enum CreateConversationActionTypes {
    REQUEST = "CREATE_CONVERSATION_REQUEST",
    SUCCESS = "CREATE_CONVERSATION_SUCCESS",
    FAILURE = "CREATE_CONVERSATION_FAILURE",
    CLEAR = "CREATE_CONVERSATION_CLEAR",
}

export enum GetConversationsActionTypes {
    REQUEST = "GET_CONVERSATIONS_REQUEST",
    SUCCESS = "GET_CONVERSATIONS_SUCCESS",
    FAILURE = "GET_CONVERSATIONS_FAILURE",
    CLEAR = "GET_CONVERSATIONS_CLEAR",
}

export enum ConversationAskActionTypes {
    REQUEST = "CONVERSATION_ASK_REQUEST",
    SUCCESS = "CONVERSATION_ASK_SUCCESS",
    FAILURE = "CONVERSATION_ASK_FAILURE",
    CLEAR = "CONVERSATION_ASK_CLEAR",
}

export enum ConversationHistoryActionTypes {
    REQUEST = "CONVERSATION_HISTORY_REQUEST",
    SUCCESS = "CONVERSATION_HISTORY_SUCCESS",
    FAILURE = "CONVERSATION_HISTORY_FAILURE",
    CLEAR = "CONVERSATION_HISTORY_CLEAR",
}

export enum ConversationSummaryActionTypes {
    REQUEST = "CONVERSATION_SUMMARY_REQUEST",
    SUCCESS = "CONVERSATION_SUMMARY_SUCCESS",
    FAILURE = "CONVERSATION_SUMMARY_FAILURE",
    CLEAR = "CONVERSATION_SUMMARY_CLEAR",
}

export enum ConversationFavoriteActionTypes {
    REQUEST = "CONVERSATION_FAVORITE_REQUEST",
    SUCCESS = "CONVERSATION_FAVORITE_SUCCESS",
    FAILURE = "CONVERSATION_FAVORITE_FAILURE",
    CLEAR = "CONVERSATION_FAVORITE_CLEAR",
}

export enum ConversationDeleteActionTypes {
    REQUEST = "CONVERSATION_DELETE_REQUEST",
    SUCCESS = "CONVERSATION_DELETE_SUCCESS",
    FAILURE = "CONVERSATION_DELETE_FAILURE",
    CLEAR = "CONVERSATION_DELETE_CLEAR",
}

export enum ConversationUpdateActionTypes {
    REQUEST = "CONVERSATION_UPDATE_REQUEST",
    SUCCESS = "CONVERSATION_UPDATE_SUCCESS",
    FAILURE = "CONVERSATION_UPDATE_FAILURE",
    CLEAR = "CONVERSATION_UPDATE_CLEAR",
}

export enum CurrentConversationActionTypes {
    SET = "CURRENT_CONVERSATION_SET",
    CLEAR = "CURRENT_CONVERSATION_CLEAR",
}

export enum GetExtractionsActionTypes {
    REQUEST = "GET_EXTRACTIONS_REQUEST",
    SUCCESS = "GET_EXTRACTIONS_SUCCESS",
    FAILURE = "GET_EXTRACTIONS_FAILURE",
    CLEAR = "GET_EXTRACTIONS_CLEAR",
}

export enum UpdateExtractionTableActionTypes {
    REQUEST = "UPDATE_EXTRACTION_TABLE_REQUEST",
    SUCCESS = "UPDATE_EXTRACTION_TABLE_SUCCESS",
    FAILURE = "UPDATE_EXTRACTION_TABLE_FAILURE",
    CLEAR = "UPDATE_EXTRACTION_TABLE_CLEAR",
}

export const {
    createConversationRequest,
    createConversationSuccess,
    createConversationFailure,
    createConversationClear,
    getConversationsRequest,
    getConversationsSuccess,
    getConversationsFailure,
    getConversationsClear,
    conversationAskRequest,
    conversationAskSuccess,
    conversationAskFailure,
    conversationAskClear,
    conversationHistoryRequest,
    conversationHistorySuccess,
    conversationHistoryFailure,
    conversationHistoryClear,
    conversationSummaryRequest,
    conversationSummarySuccess,
    conversationSummaryFailure,
    conversationSummaryClear,
    conversationFavoriteRequest,
    conversationFavoriteSuccess,
    conversationFavoriteFailure,
    conversationFavoriteClear,
    conversationUpdateRequest,
    conversationUpdateSuccess,
    conversationUpdateFailure,
    conversationUpdateClear,
    conversationDeleteRequest,
    conversationDeleteSuccess,
    conversationDeleteFailure,
    conversationDeleteClear,
    currentConversationSet,
    currentConversationClear,
    getExtractionsRequest,
    getExtractionsSuccess,
    getExtractionsFailure,
    getExtractionsClear,
    updateExtractionTableRequest,
    updateExtractionTableSuccess,
    updateExtractionTableFailure,
    updateExtractionTableClear,
} = createActions({
    [CreateConversationActionTypes.REQUEST]: (payload) => payload,
    [CreateConversationActionTypes.SUCCESS]: (data: any) => data,
    [CreateConversationActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [CreateConversationActionTypes.CLEAR]: (payload) => payload,
    [GetConversationsActionTypes.REQUEST]: (payload) => payload,
    [GetConversationsActionTypes.SUCCESS]: (data: any) => data,
    [GetConversationsActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [GetConversationsActionTypes.CLEAR]: (payload) => payload,
    [ConversationAskActionTypes.REQUEST]: (payload) => payload,
    [ConversationAskActionTypes.SUCCESS]: (data: any) => data,
    [ConversationAskActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [ConversationAskActionTypes.CLEAR]: (payload) => payload,
    [ConversationHistoryActionTypes.REQUEST]: (payload) => payload,
    [ConversationHistoryActionTypes.SUCCESS]: (data: any) => data,
    [ConversationHistoryActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [ConversationHistoryActionTypes.CLEAR]: (payload) => payload,
    [ConversationSummaryActionTypes.REQUEST]: (payload) => payload,
    [ConversationSummaryActionTypes.SUCCESS]: (data: any) => data,
    [ConversationSummaryActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [ConversationSummaryActionTypes.CLEAR]: (payload) => payload,
    [ConversationFavoriteActionTypes.REQUEST]: (payload) => payload,
    [ConversationFavoriteActionTypes.SUCCESS]: (data: any) => data,
    [ConversationFavoriteActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [ConversationFavoriteActionTypes.CLEAR]: (payload) => payload,
    [ConversationUpdateActionTypes.REQUEST]: (payload) => payload,
    [ConversationUpdateActionTypes.SUCCESS]: (data: any) => data,
    [ConversationUpdateActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [ConversationUpdateActionTypes.CLEAR]: (payload) => payload,
    [ConversationDeleteActionTypes.REQUEST]: (payload) => payload,
    [ConversationDeleteActionTypes.SUCCESS]: (data: any) => data,
    [ConversationDeleteActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [ConversationDeleteActionTypes.CLEAR]: (payload) => payload,
    [CurrentConversationActionTypes.SET]: (payload) => payload,
    [CurrentConversationActionTypes.CLEAR]: (payload) => payload,
    [GetExtractionsActionTypes.REQUEST]: (payload) => payload,
    [GetExtractionsActionTypes.SUCCESS]: (data: any) => data,
    [GetExtractionsActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [GetExtractionsActionTypes.CLEAR]: (payload) => payload,
    [UpdateExtractionTableActionTypes.REQUEST]: (payload) => payload,
    [UpdateExtractionTableActionTypes.SUCCESS]: (data: any) => data,
    [UpdateExtractionTableActionTypes.FAILURE]: (message: any) => ({ error: message }),
    [UpdateExtractionTableActionTypes.CLEAR]: (payload) => payload,
})