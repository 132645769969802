import { Button, Card, Dropdown, Typography, message } from "antd"
import {
  CheckOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FolderOutlined,
  LinkOutlined,
  MoreOutlined,
} from "@ant-design/icons"

import "./DirectoryCard.scss"
import { useState } from "react"
import { ItemType } from "antd/lib/menu/hooks/useItems"

const { Meta } = Card

type P = {
  isFile?: boolean
  thumbnailUrl?: string
  name: string
  type: string
  asset_id: string
  onClick?: (isCtrl: boolean) => void
  onDoubleClick?: () => void
  onAuxClick?: (isCtrl: boolean) => void
  isSelected?: boolean
  contextMenuItems?: ItemType[]
}

export const directoryIcon = (type: string) => {
  switch (type) {
    case "folder":
      return <FolderOutlined />
    case "pdf":
      return (
        <FilePdfOutlined
          style={{
            color: "#ff0000",
          }}
        />
      )
    case "xlsx":
    case "xls":
      return (
        <FileExcelOutlined
          style={{
            color: "#2e8b57",
          }}
        />
      )
    case "doc":
    case "docx":
      return (
        <FileWordOutlined
          style={{
            color: "#1e90ff",
          }}
        />
      )
    case "ppt":
    case "pptx":
      return (
        <FilePptOutlined
          style={{
            color: "#ff4500",
          }}
        />
      )
    case "zip":
      return (
        <FileZipOutlined
          style={{
            color: "#a52a2a",
          }}
        />
      )
    case "txt":
      return <FileTextOutlined />
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "webp":
    case "svg":
      return (
        <FileImageOutlined
          style={{
            color: "#ff4500",
          }}
        />
      )
    case "mp4":
    case "mov":
    case "avi":
    case "mkv":
    case "webm":
      return (
        <FileImageOutlined
          style={{
            color: "#ff4500",
          }}
        />
      )
  }
}

// const getThumbnailUrl = (type: string) => {
//   switch (type) {
//     case "pdf":
//       return "https://cdn-icons-png.flaticon.com/512/179/179483.png"
//     case "xlsx":
//     case "xls":
//       return "https://cdn-icons-png.flaticon.com/512/179/179518.png"
//     case "doc":
//     case "docx":
//       return "https://cdn-icons-png.flaticon.com/512/179/179516.png"
//     case "ppt":
//     case "pptx":
//       return "https://cdn-icons-png.flaticon.com/512/179/179517.png"
//     case "zip":
//       return "https://cdn-icons-png.flaticon.com/512/179/179514.png"
//     case "txt":
//       return "https://cdn-icons-png.flaticon.com/512/179/179515.png"
//     case "png":
//     case "jpg":
//     case "jpeg":
//     case "gif":
//     case "bmp":
//     case "webp":
//     case "svg":
//       return "https://cdn-icons-png.flaticon.com/512/179/179514.png"
//     case "mp4":
//     case "mov":
//     case "avi":
//     case "mkv":
//     case "webm":
//       return "https://cdn-icons-png.flaticon.com/512/179/179514.png"
//     default:
//       return ""
//   }
// }

const DirectoryCard = ({
  name,
  type,
  asset_id,
  thumbnailUrl = "",
  isSelected,
  onClick,
  onDoubleClick,
  onAuxClick,
  contextMenuItems,
}: P) => {
  const [linkClicked, setLinkClicked] = useState(false)

  const linkIconStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    right: 0,
    fontSize: 15,
    margin: 10,
    padding: 5,
    outline: "none",
    cursor: "pointer",
    borderRadius: 4,
  }

  return (
    <Dropdown menu={{ items: contextMenuItems }} trigger={["contextMenu"]}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          width: 200,
          border: "1px solid #c1c1c1",
          maxHeight: 200,
          ...(isSelected && {
            backgroundColor: "#e6f7ff",
          }),
        }}
        className="directory-card"
        cover={
          type !== "folder" ? (
            <div
              style={{
                position: "relative",
                height: 140,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 80
              }}
            >
              {/* <img
                alt="example"
                src={getThumbnailUrl(type) || thumbnailUrl}
                style={{
                  height: "140px",
                  objectFit: "contain",
                }}
              /> */}
              {directoryIcon(type)}
              {linkClicked ? (
                <CheckOutlined
                  style={{
                    ...linkIconStyle,
                    color: "green",
                  }}
                />
              ) : (
                <LinkOutlined
                  style={linkIconStyle}
                  className="copy-asset-file-link-icon"
                  onClick={(e) => {
                    e.stopPropagation()
                    message.success("Link copied to clipboard", 1)
                    navigator.clipboard.writeText(
                      `https://labs.polymerize.io/${asset_id}`,
                    )
                    setLinkClicked(true)
                    setTimeout(() => {
                      setLinkClicked(false)
                    }, 1000)
                  }}
                />
              )}
            </div>
          ) : null
        }
        bodyStyle={{
          padding: 14,
        }}
        onDoubleClick={(e) => {
          e.stopPropagation()
          onDoubleClick && onDoubleClick()
        }}
        onClick={(e) => {
          e.stopPropagation()
          const isCtrl = e.ctrlKey || e.metaKey
          onClick && onClick(isCtrl)
        }}
        onAuxClick={(e) => {
          e.stopPropagation()
          const isCtrl = e.ctrlKey || e.metaKey
          onAuxClick && onAuxClick(isCtrl)
        }}
      >
        <Meta
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 16,
              }}
            >
              {directoryIcon(type)}
              <Typography.Text
                style={{
                  flexGrow: 1,
                  textAlign: "left",
                }}
                ellipsis={{ tooltip: true }}
              >
                {name}
              </Typography.Text>
              <Dropdown
                menu={{
                  items: contextMenuItems,
                  onClick: (info) => {
                    info?.domEvent?.stopPropagation()
                  },
                }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <Button
                  type="text"
                  style={{
                    outline: "none",
                    border: "none",
                    padding: "0 6px",
                  }}
                  onClick={(e) => {
                    // e.stopPropagation()
                  }}
                >
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </div>
          }
        />
      </Card>
    </Dropdown>
  )
}

export default DirectoryCard
