import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Row, Space, Spin } from "antd";
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AsyncStates } from "src/constants";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { DeleteWoModal } from "./DeleteWoModal";
import { WoCard } from "./WoCard";
import useTranslate from "src/utils/useTranslate";
import { usePermission } from "src/utils/usePermissions";

export const AllClosedWorkOrders = ({ workOrders }: any) => {
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const { push } = useHistory();

  const status = useSelector((state: StoreState) => state.workOrders.status);

  const [workOrder, setWorkOrder] = useState<any>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const userAccess = usePermission();

  function showDetails(this: any) {
    dispatch(resetWorkOrder());
    push(`/work-orders/details/${this.work_order_id}/`);
  }

  function handleDelete(this: any) {
    setWorkOrder(this);
    setDeleteModalVisible(true);
  }

  return (
    <StyledCard
      style={{ marginTop: 20 }}
      bodyStyle={{ padding: "8px 16px" }}
      headStyle={{ padding: "8px 16px" }}
    >
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Spin
          indicator={<LoadingOutlined />}
          spinning={status === AsyncStates.LOADING}
        >
          {!!workOrders.filter((res: any) => res?.status === "closed")
            ?.length ? (
            <Row>
              {workOrders
                .filter((res: any) => res?.status === "closed")
                .map((order: any) => (
                  <WoCard
                    key={order.work_order_name}
                    order={order}
                    showDetails={showDetails}
                    handleDelete={handleDelete}
                    userAccess={userAccess}
                  />
                ))}
            </Row>
          ) : (
            <Text strong>{t("closeWO.noCloseWO")}</Text>
          )}
        </Spin>
        <DeleteWoModal
          workOrder={workOrder}
          deleteModalVisible={deleteModalVisible}
          setDeleteModalVisible={setDeleteModalVisible}
        />
      </Space>
    </StyledCard>
  );
};
