import { Drawer, Select, Spin } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardActivityCardTotalRequest } from 'src/store/actions/dashboard'
import { StoreState } from 'src/store/configureStore'
import CountCard from './CountCard'
import ProjectsTable from './ProjectsTable'
import WorkordersTable from './WorkordersTable'
import DatasetsTable from './DatasetsTable'
import PredictionsTable from './PredictionsTable'
import useTranslate from 'src/utils/useTranslate'
import { StyledText } from '../../Dashboard'
import { AsyncStates } from 'src/constants'
import { useHistory } from 'react-router-dom'

const CardSection = () => {
  const dispatch = useDispatch()

  const [t] = useTranslate()

  const { push } = useHistory()

  useEffect(() => {
    dispatch(dashboardActivityCardTotalRequest())
  }, [dispatch])

  const currentProject = useSelector(
    (state: StoreState) => state.projects.current
  )

  const { projectList: projects } = useSelector(
    (state: StoreState) => state.projects
  )

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [selectedCard, setSelectedCard] = useState('')
  const [selectedProjectId, setSelectedProjectId] = useState(currentProject)

  const {
    dashboardActivityCardTotalResponse,
    dashboardActivityCardTotalStatus
  } = useSelector((state: StoreState) => state.dashboard)

  const data = useMemo(() => {
    return [
      {
        key: 'projects_created',
        total: dashboardActivityCardTotalResponse?.projects_created || 0,
        title: t('dashboard.projectsCreated'),
        type: 'status',
        statusList: [
          {
            title: t('projects.onGoing'),
            count: dashboardActivityCardTotalResponse?.ongoing_projects || 0
          },
          {
            title: t('projects.completed'),
            count: dashboardActivityCardTotalResponse?.completed_projects || 0
          }
        ]
      },
      {
        key: 'work_orders_created',
        total: dashboardActivityCardTotalResponse?.work_orders_created || 0,
        title: t('dashboard.WorkordersCreated'),
        type: 'status',
        statusList: [
          {
            title: t('common.ongoing'),
            count:
              dashboardActivityCardTotalResponse?.ongoing_work_orders || 0
          },
          {
            title: t('common.completed'),
            count: dashboardActivityCardTotalResponse?.completed_work_orders || 0
          }
        ]
      },
      {
        key: 'datasets_uploaded',
        total: dashboardActivityCardTotalResponse?.datasets_uploaded || 0,
        title: t('dashboard.datasetsUploaded'),
        type: 'viewAll',
        viewAllText: t('dashboard.viewAllDatasets')
      },
      {
        key: 'predictions_generated',
        total: dashboardActivityCardTotalResponse?.predictions_generated || 0,
        title: t('dashboard.predictionsGenerated'),
        type: 'viewAll',
        viewAllText: t('dashboard.viewPredictionsHistory')
      }
    ]
  }, [
    dashboardActivityCardTotalResponse?.completed_projects,
    dashboardActivityCardTotalResponse?.completed_work_orders,
    dashboardActivityCardTotalResponse?.datasets_uploaded,
    dashboardActivityCardTotalResponse?.ongoing_projects,
    dashboardActivityCardTotalResponse?.ongoing_work_orders,
    dashboardActivityCardTotalResponse?.predictions_generated,
    dashboardActivityCardTotalResponse?.projects_created,
    dashboardActivityCardTotalResponse?.work_orders_created,
    t
  ])

  const DrawerComponents: { [key: string]: JSX.Element } = useMemo(() => {
    return {
      projects_created: <ProjectsTable />,
      work_orders_created: (
        <WorkordersTable selectedProjectId={selectedProjectId} />
      ),
      datasets_uploaded: (
        <DatasetsTable selectedProjectId={selectedProjectId} />
      ),
      predictions_generated: <PredictionsTable />
    }
  }, [selectedProjectId])

  return (
    <Spin spinning={dashboardActivityCardTotalStatus === AsyncStates.LOADING}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {data.map((item: any) => (
          <CountCard
            {...item}
            onClick={() => {
              if (item.key === 'predictions_generated') {
                push('/ai-engine/history/properties_prediction')
                return
              }
              setIsDrawerVisible(true)
              setSelectedCard(item.key)
            }}
          />
        ))}
        {selectedCard !== 'predictions_generated' && (
          <Drawer
            open={isDrawerVisible}
            onClose={() => {
              setIsDrawerVisible(false)
              setSelectedCard('')
            }}
            contentWrapperStyle={{
              maxWidth: 1200,
              minWidth: 800
            }}
            title={data.find((item: any) => item.key === selectedCard)?.title}
            extra={
              selectedCard !== 'projects_created' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8
                  }}
                >
                  {StyledText(`${t('common.project')}:`)}
                  <Select
                    value={selectedProjectId}
                    onChange={(value: any) => setSelectedProjectId(value)}
                    style={{ width: 300 }}
                    placeholder={t('common.project')}
                    options={projects?.map((project: any) => ({
                      value: project.project_id,
                      label: (
                        <div
                          style={{
                            display: 'flex',
                            gap: 4
                          }}
                        >
                          {StyledText(project.name)}
                          {selectedCard === 'work_orders_created'
                            ? StyledText(
                                `(${project.total_work_orders} ${t(
                                  'common.workOrders'
                                )})`
                              )
                            : null}
                        </div>
                      )
                    }))}
                  ></Select>
                </div>
              ) : null
            }
          >
            {DrawerComponents[selectedCard]}
          </Drawer>
        )}
      </div>
    </Spin>
  )
}

export default CardSection
