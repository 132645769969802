import { useMemo, useState, useEffect, useCallback } from 'react'
import {
    Spin,
    Select,
    Space,
    Col,
    Row,
    Form,
    notification,
    Table,
    Typography,
    Tabs,
    Card,
} from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from "src/constants"
import { StoreState } from 'src/store/configureStore'
import { LoadingOutlined } from '@ant-design/icons'
import { graphsDataHistoryRequest, plotsDataRequest } from 'src/store/actions/workOrderDetails'
import { Unsubscribe, doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_NAME_TASKERS, firestoreDb } from "src/utils/firebase";
import { convertToPrecision, isValidNumber } from 'src/utils/decorator'
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { Slider } from 'antd/lib'
import { StyledButton } from 'src/styled_components/StyledButton'


const { Option } = Select
const { Text } = Typography


export const RawPlots = ({ currentExpIdIndex, componentType }: any) => {
    const dispatch = useDispatch()
    const { plotsData, plotsDataStatus, graphsTaskId, workOrder, graphsDataDeleteStatus } = useSelector((state: StoreState) => state.workOrderDetails)
    const configs = useSelector((state: StoreState) => state.configs.features)
    const [selectedTrial, setSelectedTrial] = useState("")
    const [points, setPoints] = useState<any>({})
    const [superimposed, setSuperimposed] = useState(false)
    const [xaxis, setXaxis] = useState<any[]>([])
    const [yaxis, setYaxis] = useState<any[]>([])
    const [range, setRange] = useState<any>({})
    const [tab, setTab] = useState("generalised_dsc")
    const trialSetList = useMemo(() => (Object.keys(plotsData?.[tab] || {})), [plotsData, tab])

    // graphs file upload
    useEffect(() => {
        let unsub: Unsubscribe
        const listenToFileUploadTaskStatus = async () => {
            const taskDocRef = doc(firestoreDb, `${FB_COLLECTION_NAME_TASKERS}/${graphsTaskId}`)
            unsub = onSnapshot(taskDocRef, (doc: any) => {
                const taskDoc = doc.data()
                if (taskDoc) {
                    const taskStatus = taskDoc?.status
                    if (taskStatus === "COMPLETED") {
                        notification.success({ message: "Graphs generated succesfully" })
                        dispatch(plotsDataRequest({ work_order_id: workOrder.work_order_id, experiment_id: workOrder?.experiment_id?.[currentExpIdIndex] }))
                        dispatch(graphsDataHistoryRequest({ work_order_id: workOrder.work_order_id, experiment_id: workOrder?.experiment_id?.[currentExpIdIndex] }))
                        unsub()
                    }
                    if (taskStatus === "FAILED") {
                        notification.error({ message: "Added columns not found in the file. Failed to generate graphs" })
                        unsub()
                    }
                }
            })
        }
        if (graphsTaskId) {
            listenToFileUploadTaskStatus()
        }
    }, [dispatch, graphsTaskId, workOrder?.work_order_id, workOrder?.experiment_id, currentExpIdIndex])

    useEffect(() => {
        if (plotsDataStatus === AsyncStates.SUCCESS) {
            if (Object.keys(plotsData?.[tab] || {}).length) {
                setSelectedTrial(Object.keys(plotsData?.[tab] ?? {})?.[0])
            } else {
                setSelectedTrial("")
            }
            setXaxis([])
            setYaxis([])
        }
    }, [plotsDataStatus, plotsData, tab])

    const graphsClickEvent = useCallback((e: any, key: string) => {
        const point = e.point
        if (tab === "generalised_dsc") {
            setPoints((prevState: any) => {
                const obj = prevState?.[key]
                const selectedCycle = point?.series?.name
                const selectedPoint = obj?.selectedPoint
                obj.cycles[selectedCycle][selectedPoint ?? "onset"] = JSON.stringify({ x: convertToPrecision(point.x), y: convertToPrecision(point.y) })
                return ({ ...prevState, [key]: obj })
            })
        } else {
            setPoints((prevState: any) => {
                const obj = prevState?.[key]
                const selectedPoint = obj?.selectedPoint
                obj[selectedPoint ?? "onset"] = JSON.stringify({ x: convertToPrecision(point.x), y: convertToPrecision(point.y) })
                return ({ ...prevState, [key]: obj })
            })
        }
    }, [tab])

    const resetCalculationTable = useCallback((key: string) => {
        if (tab === "generalised_dsc") {
            const cycles = Object.keys(plotsData?.[tab]?.[selectedTrial]?.data || {})
            setPoints((prevState: any) => ({
                ...prevState, [key]: {
                    cycles: cycles.reduce((obj, cycle: string) => ({
                        ...obj, [cycle]: { onset: "-", peak: "-", end: "-", cycleName: cycle }
                    }), {}),
                    selectedPoint: "onset"
                }
            }))
        } else {
            setPoints((prevState: any) => ({ ...prevState, [key]: { onset: "-", peak: "-", end: "-", selectedPoint: "onset" } }))
        }
    }, [tab, plotsData, selectedTrial])

    const getCalculationTable = useCallback((key: string) => {
        const columns: any = []
        if (tab === "generalised_dsc") {
            columns.push({
                dataIndex: 'cycleName',
                title: 'Cycles',
                key: 'cycles',
                align: "center",
                width: 50
            })
        }
        columns.push(
            {
                dataIndex: 'onset',
                title: 'Onset',
                key: 'onset',
                align: "center",
                width: 80
            },
            {
                dataIndex: 'peak',
                title: 'Peak',
                key: 'peak',
                align: "center",
                width: 80
            },
            {
                dataIndex: 'end',
                title: "End",
                key: 'end',
                align: "center",
                width: 80
            }
        )
        let dataSource = []
        if (tab === "generalised_dsc") {
            dataSource = Object.values(points?.[key]?.cycles || {}) || []
        } else {
            dataSource = [points?.[key]]
        }

        return (
            <Space direction="vertical" size="large" style={{ width: "100%" }} id="points-table">
                <Table
                    title={() => (
                        <Space>
                            <Text strong>{"Select Point - "}</Text>
                            <Select value={points?.[key]?.selectedPoint} style={{ width: 200 }}
                                onChange={(e) => setPoints((prevState: any) => ({ ...prevState, [key]: { ...prevState?.[key], selectedPoint: e } }))}>
                                <Option value={"onset"} key={"onset"}>{"Onset"}</Option>
                                <Option value={"peak"} key={"peak"}>{"Peak"}</Option>
                                <Option value={"end"} key={"end"}>{"End"}</Option>
                            </Select>
                        </Space>
                    )}
                    columns={columns} dataSource={dataSource} pagination={false} style={{ marginTop: 20 }} />
                <Row justify="end">
                    <Space size="large">
                        <StyledButton type="dashed"
                            onClick={() => resetCalculationTable(key)}>
                            {"Reset"}
                        </StyledButton>
                    </Space>
                </Row>
            </Space>
        )
    }, [points, tab, resetCalculationTable])

    useEffect(() => {
        if (superimposed) {
            setRange((prevState: any) => {
                return xaxis.reduce((obj: any, element: any) => (
                    { ...obj, [element]: prevState?.[element] || { x: { min: "", max: "" }, y: { min: "", max: "" } } }
                ), {})
            })
            setPoints((prevState: any) => {
                return xaxis.reduce((obj: any, element: any) => (
                    { ...obj, [element]: prevState?.[element] || [{ onset: "-", peak: "-", end: "-", selectedPoint: "onset" }] }
                ), {})
            })
        } else {
            setRange((prevState: any) => {
                return xaxis.flatMap((x_res: any) => yaxis.map((y_res: any) => x_res + y_res))
                    .reduce((obj: any, element: any) => (
                        { ...obj, [element]: prevState?.[element] || { x: { min: "", max: "" }, y: { min: "", max: "" } } }
                    ), {})
            })
            if (tab === "generalised_dsc") {
                const cycles = Object.keys(plotsData?.[tab]?.[selectedTrial]?.data || {})
                setPoints((prevState: any) => {
                    return xaxis.flatMap((x_res: any) => yaxis.map((y_res: any) => x_res + y_res))
                        .reduce((obj: any, element: any) => (
                            {
                                ...obj, [element]: prevState?.[element] || {
                                    cycles: cycles.reduce((obj, cycle: string) => ({
                                        ...obj, [cycle]: { onset: "-", peak: "-", end: "-", cycleName: cycle }
                                    }), {}),
                                    selectedPoint: "onset"
                                }
                            }
                        ), {})
                })
            } else {
                setPoints((prevState: any) => {
                    return xaxis.flatMap((x_res: any) => yaxis.map((y_res: any) => x_res + y_res))
                        .reduce((obj: any, element: any) => (
                            { ...obj, [element]: prevState?.[element] || { onset: "-", peak: "-", end: "-", selectedPoint: "onset" } }
                        ), {})
                })
            }
        }
    }, [superimposed, xaxis, yaxis, tab, plotsData, selectedTrial])

    const getPlotsData = useCallback(() => {
        let xAxisOptions: any = [], yAxisOptions: any = []
        if (tab === "generalised_dsc") {
            xAxisOptions = [...new Set(Object.entries(plotsData?.[tab]?.[selectedTrial]?.data || {})
                .flatMap(([key, value]: any) => Object.keys(value)))]
                .filter((res: any) => !yaxis.includes(res))
                .map((res: any) => (
                    <Option key={res} value={res}>{res}</Option>
                ))
            yAxisOptions = [...new Set(Object.entries(plotsData?.[tab]?.[selectedTrial]?.data || {})
                .flatMap(([key, value]: any) => Object.keys(value)))]
                .filter((res: any) => !xaxis.includes(res))
                .map((res: any) => (
                    <Option key={res} value={res}>{res}</Option>
                ))
        } else {
            xAxisOptions = Object.keys(plotsData?.[tab]?.[selectedTrial]?.data || {})
                .filter((res: any) => !yaxis.includes(res))
                .map((res: any) => (
                    <Option key={res} value={res}>{res}</Option>
                ))
            yAxisOptions = Object.keys(plotsData?.[tab]?.[selectedTrial]?.data || {})
                .filter((res: any) => !xaxis.includes(res))
                .map((res: any) => (
                    <Option key={res} value={res}>{res}</Option>
                ))
        }
        return (
            <Space direction="vertical" size="large" style={{ width: "100%", marginTop: 20 }}>
                <Col span={12}>
                    <Form layout='vertical'>
                        <Form.Item label="Select Trial">
                            <Select value={selectedTrial} style={{ width: "100%" }} onSelect={(e: any) => {
                                setSuperimposed(false)
                                setXaxis([])
                                setYaxis([])
                                setSelectedTrial(e)
                            }}>
                                {trialSetList.map(res => (
                                    <Select.Option value={res}>{res}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Space>
                    <Select placeholder="Select X-axis" style={{ minWidth: 300 }} mode="multiple" value={xaxis}
                        onChange={(e) => setXaxis(e)}>
                        {xAxisOptions}
                    </Select>
                    <Select placeholder="Select Y-axis" style={{ minWidth: 300 }} mode="multiple" value={yaxis}
                        onChange={(e: any) => setYaxis(e)}>
                        {yAxisOptions}
                    </Select>
                </Space>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    {(tab === "generalised_dma" && yaxis.length > 1 && xaxis.length > 0) &&
                        <StyledButton onClick={() => setSuperimposed(prevState => !prevState)} type="primary">
                            {superimposed ? "Create Separate Graphs" : "Superimpose Graphs"}
                        </StyledButton>
                    }
                    {tab === "generalised_dsc" ?
                        (xaxis.map((x_res: any, x_index: any) => {
                            const x: any = Object.entries(plotsData?.[tab]?.[selectedTrial]?.data || {})
                                .reduce((obj, [key, value]: any) => ({
                                    ...obj, [key]: value?.[x_res]
                                        ?.map((point: any) => isValidNumber(point) ? point : null) || []
                                }), {})
                            const xMin = Math.min(...(Object.values(x).flatMap((res: any) => res)))
                            const xMax = Math.max(...(Object.values(x).flatMap((res: any) => res)))
                            return (
                                yaxis.map((y_res: any, y_index: number) => {
                                    const currentRange = range?.[x_res + y_res]
                                    const y: any = Object.entries(plotsData?.[tab]?.[selectedTrial]?.data || {})
                                        .reduce((obj, [key, value]: any) => ({
                                            ...obj, [key]: value?.[y_res]
                                                ?.map((point: any) => isValidNumber(point) ? point : null) || []
                                        }), {})
                                    const yMin = Math.min(...(Object.values(y).flatMap((res: any) => res)))
                                    const yMax = Math.max(...(Object.values(y).flatMap((res: any) => res)))
                                    return (
                                        <Col span={16}>
                                            <Card>
                                                <HighchartsReact
                                                    style={{ width: "100%" }}
                                                    key={"separate" + x_index + y_index}
                                                    highcharts={Highcharts}
                                                    options={{
                                                        plotOptions: {
                                                            series: {
                                                                point: {
                                                                    events: {
                                                                        click(e: any) { graphsClickEvent(e, x_res + y_res) }
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        credits: {
                                                            enabled: false,
                                                        },
                                                        chart: {
                                                            type: "scatter",
                                                            height: "550px",
                                                            zoomType: "xy"
                                                        },
                                                        title: {
                                                            text: `${x_res ?? ""} vs ${y_res ?? ""}`, style: { fontSize: "1.2em", fontWeight: 'bold' }
                                                        },
                                                        legend: { enabled: true, itemStyle: { fontSize: "1em" } },
                                                        xAxis: {
                                                            title: {
                                                                text: x_res,
                                                                style: { fontSize: "1em", fontWeight: 'bold' }
                                                            },
                                                            labels: { style: { fontSize: "1em", fontWeight: 'bold' } },
                                                            startOnTick: true,
                                                            endOnTick: true,
                                                            showLastLabel: true,
                                                        },
                                                        yAxis: {
                                                            title: {
                                                                text: y_res, style: { fontSize: "1em", fontWeight: 'bold' }
                                                            },
                                                            labels: { style: { fontSize: "1em", fontWeight: 'bold' } },
                                                        },
                                                        series: Object.entries(x).map(([x_cycle, x_values]: any) => {
                                                            const currentY = y?.[x_cycle]
                                                            return (
                                                                {
                                                                    data: x_values.map((point: number, index: number) => [point, currentY[index]])
                                                                        .filter(([x_point, y_point]: any[]) => (x_point !== null) &&
                                                                            (isValidNumber(currentRange?.x?.min) ? currentRange?.x?.min <= x_point : true) &&
                                                                            (isValidNumber(currentRange?.x?.max) ? currentRange?.x?.max >= x_point : true) &&
                                                                            (y_point !== null) &&
                                                                            (isValidNumber(currentRange?.y?.min) ? currentRange?.y?.min <= y_point : true) &&
                                                                            (isValidNumber(currentRange?.y?.max) ? currentRange?.y?.max >= y_point : true)
                                                                        ),
                                                                    name: x_cycle,
                                                                    id: x_cycle
                                                                }
                                                            )
                                                        })
                                                    }}
                                                />
                                                <Row justify={"space-between"} align={"middle"}>
                                                    <Col span={2}>
                                                        <Text strong type="secondary">{"X-axis"}</Text>
                                                    </Col>
                                                    <Col span={22}>
                                                        <Slider
                                                            key={"separateX"}
                                                            range
                                                            min={xMin}
                                                            max={xMax}
                                                            defaultValue={[xMin, xMax]}
                                                            onChangeComplete={(e) => {
                                                                setRange((prevState: any) => ({ ...prevState, [x_res + y_res]: { ...prevState?.[x_res + y_res], x: { min: e?.[0], max: e?.[1] } } }))
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row justify={"space-between"} align={"middle"}>
                                                    <Col span={2}>
                                                        <Text strong type="secondary">{"Y-axis"}</Text>
                                                    </Col>
                                                    <Col span={22}>
                                                        <Slider
                                                            key={"separateY"}
                                                            range
                                                            min={yMin}
                                                            max={yMax}
                                                            defaultValue={[yMin, yMax]}
                                                            onChangeComplete={(e) => {
                                                                setRange((prevState: any) => ({ ...prevState, [x_res + y_res]: { ...prevState?.[x_res + y_res], y: { min: e?.[0], max: e?.[1] } } }))
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                {componentType === "crud" && getCalculationTable(x_res + y_res)}
                                            </Card>
                                        </Col>
                                    )
                                })
                            )
                        }))
                        : (superimposed ?
                            (xaxis.map((x_res: any, x_index: number) => {
                                const x = plotsData?.[tab]?.[selectedTrial]?.data?.[x_res]?.map((point: any) => isValidNumber(point) ? point : null) || []
                                const yAxisFullData = yaxis.flatMap((y_res: any) => {
                                    return plotsData?.[tab]?.[selectedTrial]?.data?.[y_res]?.map((point: any) => isValidNumber(point) ? point : null) || []
                                })
                                const currentRange = range?.[x_res]
                                const xMin = Math.min(...x)
                                const xMax = Math.max(...x)
                                const yMin = Math.min(...yAxisFullData)
                                const yMax = Math.max(...yAxisFullData)
                                return (
                                    <Col span={16}>
                                        <Card>
                                            <HighchartsReact
                                                style={{ width: "100%" }}
                                                key={"imposed" + x_index}
                                                highcharts={Highcharts}
                                                options={{
                                                    credits: {
                                                        enabled: false,
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            point: {
                                                                events: {
                                                                    click(e: any) { graphsClickEvent(e, x_res) }
                                                                }
                                                            }
                                                        },
                                                        scatter: {
                                                            jitter: {
                                                                x: 0.005
                                                            }
                                                        }
                                                    },
                                                    chart: {
                                                        type: "scatter",
                                                        height: "550px",
                                                        zoomType: "xy"
                                                    },
                                                    title: {
                                                        text: !!x_res && !!yaxis?.length ? `${x_res ?? ""} vs ${yaxis.join(", ")}` : "",
                                                        style: { fontSize: "1.2em", fontWeight: 'bold' }
                                                    },
                                                    legend: { enabled: true, itemStyle: { fontSize: "1em" } },
                                                    xAxis: {
                                                        title: {
                                                            text: x_res,
                                                            style: { fontSize: "1em", fontWeight: 'bold' }
                                                        },
                                                        labels: { style: { fontSize: "1em", fontWeight: 'bold' } },
                                                        startOnTick: true,
                                                        endOnTick: true,
                                                        showLastLabel: true,
                                                    },
                                                    yAxis: {
                                                        labels: { style: { fontSize: "1em", fontWeight: 'bold' } },
                                                    },
                                                    series: yaxis.map((y_res: any) => {
                                                        const y = plotsData?.[tab]?.[selectedTrial]?.data?.[y_res]?.map((point: any) => isValidNumber(point) ? point : null) || []
                                                        return (
                                                            {
                                                                data: x.map((point: number, index: number) => [point, y[index]])
                                                                    .filter(([x_point, y_point]: any[]) => (x_point !== null) &&
                                                                        (isValidNumber(currentRange?.x?.min) ? currentRange?.x?.min <= x_point : true) &&
                                                                        (isValidNumber(currentRange?.x?.max) ? currentRange?.x?.max >= x_point : true) &&
                                                                        (y_point !== null) &&
                                                                        (isValidNumber(currentRange?.y?.min) ? currentRange?.y?.min <= y_point : true) &&
                                                                        (isValidNumber(currentRange?.y?.max) ? currentRange?.y?.max >= y_point : true)
                                                                    ),
                                                                name: y_res,
                                                                id: y_res
                                                            }
                                                        )
                                                    })
                                                }}
                                            />
                                            <Row justify={"space-between"} align={"middle"}>
                                                <Col span={2}>
                                                    <Text strong type="secondary">{"X-axis"}</Text>
                                                </Col>
                                                <Col span={22}>
                                                    <Slider
                                                        key={"imposedX"}
                                                        range
                                                        min={xMin}
                                                        max={xMax}
                                                        defaultValue={[xMin, xMax]}
                                                        onChangeComplete={(e) => {
                                                            setRange((prevState: any) => ({ ...prevState, [x_res]: { ...prevState?.[x_res], x: { min: e?.[0], max: e?.[1] } } }))
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row justify={"space-between"} align={"middle"}>
                                                <Col span={2}>
                                                    <Text strong type="secondary">{"Y-axis"}</Text>
                                                </Col>
                                                <Col span={22}>
                                                    <Slider
                                                        key={"imposedY"}
                                                        range
                                                        min={yMin}
                                                        max={yMax}
                                                        defaultValue={[yMin, yMax]}
                                                        onChangeComplete={(e) => {
                                                            setRange((prevState: any) => ({ ...prevState, [x_res]: { ...prevState?.[x_res], y: { min: e?.[0], max: e?.[1] } } }))
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            {componentType === "crud" && getCalculationTable(x_res)}
                                        </Card>
                                    </Col>
                                )
                            }))
                            :
                            (xaxis.map((x_res: any, x_index: any) => {
                                const x = plotsData?.[tab]?.[selectedTrial]?.data?.[x_res]?.map((point: any) => isValidNumber(point) ? point : null) || []
                                const xMin = Math.min(...x)
                                const xMax = Math.max(...x)
                                return (
                                    yaxis.map((y_res: any, y_index: number) => {
                                        const currentRange = range?.[x_res + y_res]
                                        const y = plotsData?.[tab]?.[selectedTrial]?.data?.[y_res]?.map((point: any) => isValidNumber(point) ? point : null) || []
                                        const yMin = Math.min(...y)
                                        const yMax = Math.max(...y)
                                        return (
                                            <Col span={16}>
                                                <Card>
                                                    <HighchartsReact
                                                        style={{ width: "100%" }}
                                                        key={"separate" + x_index + y_index}
                                                        highcharts={Highcharts}
                                                        options={{
                                                            credits: {
                                                                enabled: false,
                                                            },
                                                            plotOptions: {
                                                                series: {
                                                                    point: {
                                                                        events: {
                                                                            click(e: any) { graphsClickEvent(e, x_res + y_res) }
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            chart: {
                                                                type: "scatter",
                                                                height: "550px",
                                                                zoomType: "xy"
                                                            },
                                                            title: {
                                                                text: `${x_res ?? ""} vs ${y_res ?? ""}`, style: { fontSize: "1.2em", fontWeight: 'bold' }
                                                            },
                                                            legend: { enabled: true, itemStyle: { fontSize: "1em" } },
                                                            xAxis: {
                                                                title: {
                                                                    text: x_res,
                                                                    style: { fontSize: "1em", fontWeight: 'bold' }
                                                                },
                                                                labels: { style: { fontSize: "1em", fontWeight: 'bold' } },
                                                                startOnTick: true,
                                                                endOnTick: true,
                                                                showLastLabel: true,
                                                            },
                                                            yAxis: {
                                                                title: {
                                                                    text: y_res, style: { fontSize: "1em", fontWeight: 'bold' }
                                                                },
                                                                labels: { style: { fontSize: "1em", fontWeight: 'bold' } },
                                                            },
                                                            series: [{
                                                                data: x.map((point: number, index: number) => [point, y[index]])
                                                                    .filter(([x_point, y_point]: any[]) => (x_point !== null) &&
                                                                        (isValidNumber(currentRange?.x?.min) ? currentRange?.x?.min <= x_point : true) &&
                                                                        (isValidNumber(currentRange?.x?.max) ? currentRange?.x?.max >= x_point : true) &&
                                                                        (y_point !== null) &&
                                                                        (isValidNumber(currentRange?.y?.min) ? currentRange?.y?.min <= y_point : true) &&
                                                                        (isValidNumber(currentRange?.y?.max) ? currentRange?.y?.max >= y_point : true)
                                                                    ),
                                                                name: y_res,
                                                                id: y_res
                                                            }]
                                                        }}
                                                    />
                                                    <Row justify={"space-between"} align={"middle"}>
                                                        <Col span={2}>
                                                            <Text strong type="secondary">{"X-axis"}</Text>
                                                        </Col>
                                                        <Col span={22}>
                                                            <Slider
                                                                key={"separateX"}
                                                                range
                                                                min={xMin}
                                                                max={xMax}
                                                                defaultValue={[xMin, xMax]}
                                                                onChangeComplete={(e) => {
                                                                    setRange((prevState: any) => ({ ...prevState, [x_res + y_res]: { ...prevState?.[x_res + y_res], x: { min: e?.[0], max: e?.[1] } } }))
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row justify={"space-between"} align={"middle"}>
                                                        <Col span={2}>
                                                            <Text strong type="secondary">{"Y-axis"}</Text>
                                                        </Col>
                                                        <Col span={22}>
                                                            <Slider
                                                                key={"separateY"}
                                                                range
                                                                min={yMin}
                                                                max={yMax}
                                                                defaultValue={[yMin, yMax]}
                                                                onChangeComplete={(e) => {
                                                                    setRange((prevState: any) => ({ ...prevState, [x_res + y_res]: { ...prevState?.[x_res + y_res], y: { min: e?.[0], max: e?.[1] } } }))
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {componentType === "crud" && getCalculationTable(x_res + y_res)}
                                                </Card>
                                            </Col>
                                        )
                                    })
                                )
                            }))
                        )}
                </Space>
            </Space >
        )
    }, [plotsData, selectedTrial, getCalculationTable, superimposed, xaxis, yaxis, range, trialSetList, tab, graphsClickEvent, componentType])


    return (
        <Spin spinning={plotsDataStatus === AsyncStates.LOADING || graphsDataDeleteStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <Tabs
                    activeKey={tab}
                    onChange={(e) => {
                        setSuperimposed(false)
                        setXaxis([])
                        setYaxis([])
                        setTab(e)
                    }}>
                    <Tabs.TabPane tab={"DSC"} key={"generalised_dsc"} tabKey={"generalised_dsc"}>
                        {getPlotsData()}
                    </Tabs.TabPane>
                    {Boolean(configs?.dma_graphs) &&
                        <Tabs.TabPane tab={"DMA"} key={"generalised_dma"} tabKey={"generalised_dma"}>
                            {getPlotsData()}
                        </Tabs.TabPane>
                    }
                </Tabs >
            </Space>
        </Spin>
    )
}