import { Badge, Typography } from "antd"
import { TEXT_SECONDARY, TEXT_PRIMARY } from "../../Dashboard"
import { antdTheme } from "src/constants"

const Legend = ({ list, iconSize = 10, fontSize = antdTheme.fontSize, gap = 12 }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: `0px ${gap}px`,
        flexWrap: 'wrap'
      }}
    >
      {list.map((item: any, index: number) => (
        <div
          key={index}
          style={{
            display: 'flex',
            gap: gap / 3,
            alignItems: 'center'
          }}
        >
          <Badge
            color={item.color}
            styles={{
              indicator: {
                width: iconSize,
                height: iconSize
              }
            }}
          />

          <Typography.Text
            style={{
              fontSize: fontSize,
              fontWeight: 400,
              color: `${TEXT_SECONDARY}`,
              lineHeight: '22px',
              margin: 0
            }}
          >
            {item.name}
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: fontSize,
              fontWeight: 600,
              color: `${TEXT_PRIMARY}`,
              lineHeight: '22px',
              margin: 0
            }}
          >
            {item.count}
          </Typography.Text>
        </div>
      ))}
    </div>
  )
}

export default Legend