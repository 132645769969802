
import { useMemo } from 'react'
import { Space, Table, Tooltip, Spin, Typography, Checkbox, Empty } from "antd"
import { StoreState } from 'src/store/configureStore'
import {
  DownOutlined, UpOutlined, LoadingOutlined, RightCircleOutlined
} from '@ant-design/icons'
import { geekblue } from '@ant-design/colors'
import { useTable } from "react-table"
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import './InfiniteTable.scss'
import useTranslate from 'src/utils/useTranslate'

const { Text } = Typography


export const InfiniteTable = ({ selectedRecords, setSelectedRecords, setSelectAll, modalHandler, onSelect, loadMoreData,
  formulations, sortRecords }: any) => {

  const { celsureDisplayNamesData } = useSelector((state: StoreState) => state.celsure)
  const [t] = useTranslate()

  const columns = useMemo(() => {
    return [
      {
        Header: <Space>
          <Checkbox onChange={(e: any) => {
            if (!e.target.checked) {
              setSelectedRecords([])
              setSelectAll(false)
            } else {
              setSelectAll(true)
            }
          }}>{t("infiniteTable.trialName")}</Checkbox>
          {<Tooltip title={t("common.descendingSort")}> <DownOutlined onClick={() => sortRecords("trial_display_id", "descending")} style={{ cursor: "pointer" }} /></Tooltip>}
          {<Tooltip title={t("common.ascendingSort")}> <UpOutlined onClick={() => sortRecords("trial_display_id", "ascending")} style={{ cursor: "pointer" }} /></Tooltip>}
        </Space>,
        accessor: "trial_display_id"
      },
      {
        Header: <Space>
          {"PCM types"}
        </Space>,
        accessor: "pcm_type"
      },
      {
        Header: <Space>
          {"Trial type"}
          {<Tooltip title={t("common.descendingSort")}> <DownOutlined onClick={() => sortRecords("trial_type", "descending")} style={{ cursor: "pointer" }} /></Tooltip>}
          {<Tooltip title={t("common.ascendingSort")}> <UpOutlined onClick={() => sortRecords("trial_type", "ascending")} style={{ cursor: "pointer" }} /></Tooltip>}
        </Space>,
        accessor: "trial_type"
      },
      {
        Header: <Space>
          {"Created"}
          {<Tooltip title={t("common.descendingSort")}> <DownOutlined onClick={() => sortRecords("created", "descending")} style={{ cursor: "pointer" }} /></Tooltip>}
          {<Tooltip title={t("common.ascendingSort")}> <UpOutlined onClick={() => sortRecords("created", "ascending")} style={{ cursor: "pointer" }} /></Tooltip>}
        </Space>,
        accessor: "created"
      },
      {
        Header: <Space>
          {"Backup Time"}
          {<Tooltip title={t("common.descendingSort")}> <DownOutlined onClick={() => sortRecords("backup_time", "descending")} style={{ cursor: "pointer" }} /></Tooltip>}
          {<Tooltip title={t("common.ascendingSort")}> <UpOutlined onClick={() => sortRecords("backup_time", "ascending")} style={{ cursor: "pointer" }} /></Tooltip>}
        </Space>,
        accessor: "backup_time"
      },
    ]
    // eslint-disable-next-line
  }, [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, } = useTable({ columns, data: formulations.data.map((data: any, key: number) => ({ ...data, key })) })


  return (
    <Space direction="vertical" style={{ width: "100%", overflow: "auto" }}>
      <InfiniteScroll
        height={"500px"}
        next={loadMoreData}
        dataLength={formulations.data?.length}
        hasMore={formulations.data?.length < formulations.total_records}
        loader={<Spin indicator={<LoadingOutlined />} size="large" spinning>
          <div style={{ textAlign: 'center', height: "100px" }}></div>
        </Spin>}
      >
        {!!formulations?.data?.length ?
          <table className="infinite-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps(column)} style={column.id !== 'trial_display_id' ? { textAlign: "center" } : {}}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, i: any) => {
                prepareRow(row);
                return (
                  <tr onClick={(event) => modalHandler(formulations?.data?.[i], event)} {...row.getRowProps()}
                    style={selectedRecords.includes(formulations.data?.[i]?.trial_id) ? { backgroundColor: geekblue[0] } : {}}
                  >
                    {row.cells.map((cell: any, index: any) => {
                      if (cell.column.id === 'trial_display_id') {
                        return (
                          <td {...cell.getCellProps()}>
                            <Checkbox checked={selectedRecords.includes(formulations.data?.[i]?.trial_id)}
                              onChange={(e) => {
                                onSelect(e, formulations?.data?.[i]?.trial_id)
                              }}
                            />
                            <Space style={{ padding: 0, cursor: "pointer" }}>
                              <RightCircleOutlined style={{ color: geekblue[4], marginLeft: 15 }} />
                              <Text style={{ width: 150 }} ellipsis={{ tooltip: cell.value }}>{cell.value}</Text>
                            </Space>
                          </td>
                        )
                      }
                      else if (cell.column.id === 'pcm_type') {
                        return (
                          <td {...cell.getCellProps()} className="table-data-center">
                            {[...new Set(cell.value?.map((res: any) => celsureDisplayNamesData?.pcm_type?.[res?.pcm_type]?.name || res?.pcm_type))]?.join(", ")}
                          </td>
                        )
                      }
                      else if (cell.column.id === 'trial_type') {
                        return (
                          <td {...cell.getCellProps()} className="table-data-center">
                            {celsureDisplayNamesData?.trial_type?.[cell.value] || cell.value}
                          </td>
                        )
                      }
                      else if (cell.column.id === 'created') {
                        return (
                          <td {...cell.getCellProps()} className="table-data-center">
                            {new Date(cell.value).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                          </td>
                        )
                      }
                      else if (cell.column.id === 'backup_time') {
                        return (
                          <td {...cell.getCellProps()} className="table-data-center">
                            {`${cell.value?.value || ""} ${cell.value?.unit || ""}`}
                          </td>
                        )
                      }
                      else {
                        return <td className="table-data-center" {...cell.getCellProps()}>{cell.value}</td>
                      }
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          :
          <Table locale={{ emptyText: <Empty description={t("formulations.noFormulationUnderSelectedProject")} /> }} />
        }
      </InfiniteScroll>
    </Space>
  )
}