
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`중심 합성 계획법`}</h1>
    <p>{`박스-윌슨 중심 합성 계획법은 일반적으로 '중심 합성 계획법'이라고 불리며, 가장 일반적으로 사용되는 응답 표면 실험 설계입니다. 중심 합성 계획법은 중심점이 있는 요인설계 또는 분수 요인설계에 추가로 축점을 추가하는 방식으로, 곡률을 추정할 수 있습니다. 중심 합성 계획법을 사용하여 다음을 수행할 수 있습니다:`}</p>
    <p>{`일차 및 이차 항을 효율적으로 추정합니다.
중심 및 축점을 이전에 수행된 요인설계에 추가하여 곡률이 있는 응답 변수를 모델링합니다.
중심 합성 계획법은 전체 이차 모델에 적합할 수 있습니다. 이 계획법은 순차적 실험을 요구하는 경우 종종 사용됩니다. 올바르게 계획된 요인설계법에서 얻은 정보를 포함할 수 있기 때문입니다.
가능한 경우 중심 합성 계획법은 직교 블록 및 회전성을 갖습니다.`}</p>
    <p><strong parentName="p">{`직교 블록:`}</strong></p>
    <p>{`중심 합성 계획법은 종종 하나 이상의 블록에서 수행됩니다. 중심 합성 계획법은 직교 블록을 생성하여 모델 항과 블록 효과를 독립적으로 추정하고 회귀 계수의 변동을 최소화할 수 있습니다.`}</p>
    <p><strong parentName="p">{`회전성:`}</strong></p>
    <p>{`회전기능설계는 디자인 중심축에서 동일한 예측 분산을 제공합니다.`}</p>
    <p><strong parentName="p">{`사용 사례:`}</strong></p>
    <ul>
      <li parentName="ul">{`공정 최적화`}</li>
      <li parentName="ul">{`순차적 디자인`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;