import {
	CharacterizationVariation,
	CharaterizationVariationParameter,
} from "src/typings"
import { styledConsoleLog } from "src/utils/general/styled-console-log"
import { Column } from "src/components/AIEngine/common/characterization-variation-selection/types"

export const getFilters = (data: CharacterizationVariation[], key: string) => {
	try {
		const values = data.reduce<unknown[]>((acc, variation) => {
			const value = variation[key]?.value
			value ? acc.push(value) : acc.push("-")
			return acc
		}, [])

		const filteredData = [...new Set(values)]

		return (filteredData as string[]).map((value) => {
			return { text: value === "-" ? "NA" : value, value }
		})
	} catch (err) {
		styledConsoleLog({
			message: `ERROR OCCURED IN FORWARD PREDICTION CHARACTERIZATION SELECTION TABLE FILTERS ${err}`,
			styles: {
				color: "red",
			},
		})
		return undefined
	}
}

export const getSorter = (data: CharacterizationVariation[], key: string) => {
	try {
		const values = data.reduce<unknown[]>((acc, variation) => {
			const value = variation[key]?.value
			if (value) acc.push(value)
			return acc
		}, [])

		const filteredData = [
			...new Set(
				values.filter((value) => {
					return value !== null && value !== "-"
				})
			),
		]

		if (filteredData.length > 0 && typeof filteredData[0] === "number") {
			return (a: Column, b: Column) => {
				const firstProperty = a[key] as CharaterizationVariationParameter
				const secondProperty = b[key] as CharaterizationVariationParameter

				if (
					firstProperty &&
					secondProperty &&
					typeof firstProperty.value === "number" &&
					typeof secondProperty.value === "number"
				) {
					return firstProperty.value - secondProperty.value
				}

				return false
			}
		}

		return false
	} catch (err) {
		styledConsoleLog({
			message: `ERROR OCCURED IN FORWARD PREDICTION CHARACTERIZATION SELECTION TABLE SORTER ${err}`,
			styles: {
				color: "red",
			},
		})
		return false
	}
}
