import useTranslate from "src/utils/useTranslate";
import { FilteredSummary } from "./SmileNumericalHeatmap";
import { useEffect, useMemo, useRef, useState } from "react";
import { Collapse, Empty, Image, Space, Tooltip } from "antd";
import { InfoCircleOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { DisplayNames } from "src/typings";
import { useValue } from "src/utils/useValue";

type TProps = {
    filteredSummmary: FilteredSummary
}

const SmileAnalysis = ({ filteredSummmary }: TProps) => {
    const [t] = useTranslate();
    const names = useSelector((state: StoreState) => state.displayNames.data) as DisplayNames;
    const { getValue: getLocalValue } = useValue();
    const [, setRefresh] = useState(0);
    const scrollerRef = useRef<{ [key: string]: HTMLElement | null }>({});
    const containerRef = useRef<{ [key: string]: HTMLElement | null }>({});

    const [relationships] = useState([
        {
            key: "Highly Positive",
            label: t("dataSummary.highlyPositive"),
            desc: t("dataSummary.highlyPositiveDesc"),
            impact: t("dataSummary.hasAHighlyPositiveImpact"),
            emptyDesc: t("dataSummary.emptyPositiveDesc")
        },
        {
            key: "Positive",
            label: t("dataSummary.positive"),
            desc: t("dataSummary.positiveDesc"),
            impact: t("dataSummary.hasAPositiveImpact"),
            emptyDesc: t("dataSummary.emptyPositiveDesc")
        },
        {
            key: "Highly Negative",
            label: t("dataSummary.highlyNegative"),
            desc: t("dataSummary.highlyNegativeDesc"),
            impact: t("dataSummary.hasAHighlyNegativeImpact"),
            emptyDesc: t("dataSummary.emptyNegativeDesc")
        },
        {
            key: "Negative",
            label: t("dataSummary.negative"),
            desc: t("dataSummary.negativeDesc"),
            impact: t("dataSummary.hasANegativeImpact"),
            emptyDesc: t("dataSummary.emptyNegativeDesc")
        }
    ]);

    useEffect(() => {
        refreshDom();
    }, [filteredSummmary]);

    const isInsightsEmpty = useMemo(() => {
        let isEmpty = true;
        relationships.forEach(r => {
            if (filteredSummmary.correlation_insights[r.key]?.length > 0) isEmpty = false
        })

        return isEmpty;
    }, [filteredSummmary, relationships]);

    const showLeftPaddle = (id: string) => {
        const leftScollForDiv = containerRef.current[id]?.scrollLeft
        if (!leftScollForDiv) return false;

        return true
    }

    const showRightPaddle = (id: string) => {
        const leftScollForDiv = containerRef.current[id]?.scrollLeft;
        const parentWidth = scrollerRef.current[id]?.offsetWidth;
        const scrollerWidth = containerRef.current[id]?.scrollWidth;

        if (leftScollForDiv === null || leftScollForDiv === undefined) return false;

        if (scrollerWidth && parentWidth && scrollerWidth > parentWidth) {
            // Check if at end of scroll
            const scrollPosition = leftScollForDiv + parentWidth;
            if (scrollPosition < scrollerWidth)
                return true;
        }

        return false
    }

    const scrollRight = (id: string) => {
        const currentScroll = containerRef.current[id]?.scrollLeft;
        containerRef.current[id]?.scrollTo({
            left: currentScroll ? currentScroll + 400 : 400,
            behavior: 'smooth',
        });
        refreshDom();
    }

    const scrollLeft = (id: string) => {
        let currentScroll = containerRef.current[id]?.scrollLeft;
        containerRef.current[id]?.scrollTo({
            left: currentScroll ? currentScroll - 400 : 0,
            behavior: 'smooth'
        });
        refreshDom();
    }

    const refreshDom = () => {
        setTimeout(() => { setRefresh(Math.random()); }, 500)
    }

    const generateDivId = (label: string, idx: number) => {
        return `${String(label).toLowerCase().split(" ").join("-")}-${idx}`;
    }

    return (
        <div className="analysis-container" id="fingerprint-analysis">
            <div className="heading">{t("smiles.directInverseRelationship")}</div>
            {isInsightsEmpty ?
                <div className="empty-analysis">
                    {t("dataSummary.poorCorrelation")}
                </div>
                :
                <Space direction="vertical" style={{ width: "100%", marginTop: "16px" }}>
                    {relationships.map((r, idx) => (
                        <Collapse
                            key={idx}
                            onChange={() => refreshDom()}
                            items={[
                                {
                                    key: "1",
                                    label: (<div className="panel-header">{r.label}&nbsp;&nbsp;<Tooltip title={r.desc}><InfoCircleOutlined /></Tooltip></div>),
                                    forceRender: true,
                                    children: (
                                        <Space direction="vertical" style={{ width: "100%" }}>
                                            {filteredSummmary.correlation_insights[r.key]?.length === 0 && <Empty description={r.emptyDesc} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                            {filteredSummmary.correlation_insights[r.key]?.map((corelation, idx) => (
                                                <Collapse
                                                    key={idx}
                                                    defaultActiveKey="1"
                                                    items={[{
                                                        key: "1",
                                                        showArrow: false,
                                                        label: `${corelation[0]} (${corelation[3]}) ${r.impact} (${getLocalValue(corelation[1]) || ""}) ${t("common.on")} ${names.properties?.[corelation[2]]?.name ?? corelation[2]}`,
                                                        children: (
                                                            <div className="images-scroller" ref={el => scrollerRef.current[generateDivId(r.label, idx)] = el}>
                                                                <div className="images-container" ref={el => containerRef.current[generateDivId(r.label, idx)] = el}>
                                                                    {corelation[4] && Object.keys(corelation[4] || {}).map((name: string, idx: number) => (
                                                                        <div className="img-container" key={name}>
                                                                            <Image
                                                                                alt="name"
                                                                                src={corelation[4]?.[name]}
                                                                            />
                                                                            <div className="image-title">
                                                                                {name && String(name).length > 20 ? <Tooltip title={name}>{name.substring(0, 18)}...</Tooltip> : name}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {showLeftPaddle(generateDivId(r.label, idx)) && <div className="left-paddle" onClick={() => scrollLeft(generateDivId(r.label, idx))}>
                                                                    <LeftOutlined />
                                                                </div>}
                                                                {showRightPaddle(generateDivId(r.label, idx)) && <div className="right-paddle" onClick={() => scrollRight(generateDivId(r.label, idx))}>
                                                                    <RightOutlined />
                                                                </div>}
                                                            </div>
                                                        )
                                                    }]}
                                                />
                                            ))}
                                        </Space>
                                    )
                                }
                            ]}
                        />
                    ))}
                </Space>
            }
        </div>
    )
}

export default SmileAnalysis;