import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { WorkOrderDetails as CelsureWorkOrderDetails } from "src/components/Celsure/WorkOrderDetails/WorkOrderDetails";
import { projectType } from "src/constants";
import { StoreState } from "src/store/configureStore";
import { ClosedWoDetails } from "./ClosedWoDetails";
import { OpenWoDetails } from "./OpenWoDetails";
import { WorkOrderContainer } from "./WorkOrderContainer";

export const WorkOrderDetails = () => {
  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );
  const project_type = useSelector(
    (state: StoreState) => state.login.loginResponse.project_type
  );
  const { celsureWo } = useSelector((state: StoreState) => state.celsureWo);

  let { woId }: { woId: string } = useParams();

  // useEffect(() => {
  //   if (woId !== "create-workorder") {
  //     if (project_type === projectType.celsure) {
  //       dispatch(
  //         setCelsureWo(celsureWoList.find((wo) => wo.work_order_id === woId))
  //       );
  //     } else dispatch(workOrderRequest(woId));
  //   }
  // }, [celsureWoList, dispatch, project_type, woId]);
  const renderWODetails = () => {
    if (project_type === projectType.celsure) {
      return (
        (Object.keys(celsureWo)?.length || woId === "create-workorder") && (
          <CelsureWorkOrderDetails />
        )
      );
    } else if (workOrder?.status === "closed") {
      return <ClosedWoDetails />;
    } else {
      return <OpenWoDetails />;
    }
  };
  return <WorkOrderContainer>{renderWODetails()}</WorkOrderContainer>;
};
