import { call, put, select, takeLatest } from "redux-saga/effects";
import performApiCallSaga, { performApiCallSagaV2, performApiCallSagaV3 } from "./utils";
import { askQuestionApi, createConversationApi, deleteConversationApi, favoriteConversationApi, getConversationHistoryApi, getConversationSummaryApi, getConversationsApi, getExtractionsApi, updateConversationApi, updateExtractionTableApi } from "src/services/polyGPT";
import { conversationAskFailure, conversationAskRequest, conversationAskSuccess, conversationDeleteFailure, conversationDeleteRequest, conversationDeleteSuccess, conversationFavoriteFailure, conversationFavoriteRequest, conversationFavoriteSuccess, conversationHistoryFailure, conversationHistoryRequest, conversationHistorySuccess, conversationSummaryFailure, conversationSummaryRequest, conversationSummarySuccess, conversationUpdateFailure, conversationUpdateRequest, conversationUpdateSuccess, createConversationClear, createConversationFailure, createConversationRequest, createConversationSuccess, currentConversationSet, getConversationsFailure, getConversationsRequest, getConversationsSuccess, getExtractionsFailure, getExtractionsRequest, getExtractionsSuccess, updateExtractionTableFailure, updateExtractionTableRequest, updateExtractionTableSuccess } from "../actions/polyGPT";
import jwtManager from "src/utils/jwtManager";
import { message, notification } from "antd";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";

function* createConversationSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    createConversationApi,
    createConversationSuccess,
    createConversationFailure,
    payload
  );
}

function* getConversationsSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(getConversationsApi, payload, headers);
    if (status === "Success") {
      yield put(getConversationsSuccess(data));
      yield put(createConversationClear());
      if (data.conversations?.length) {
        const firstConversation = data.conversations[0];
        yield put(currentConversationSet(firstConversation));
        yield put(conversationHistoryRequest({
          conversation_id: firstConversation.conversation_id,
          page_size: 10,
          page_num: 1,
        }));
      }
    } else {
      yield put(getConversationsFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(getConversationsFailure());
  }
}

function* conversationAskSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSagaV3(
    askQuestionApi,
    conversationAskSuccess,
    conversationAskFailure,
    payload
  );
}

function* conversationHistorySaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getConversationHistoryApi,
    conversationHistorySuccess,
    conversationHistoryFailure,
    payload
  );
}
function* conversationSummarySaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    getConversationSummaryApi,
    conversationSummarySuccess,
    conversationSummaryFailure,
    payload
  );
}
function* conversationFavoriteSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    favoriteConversationApi,
    conversationFavoriteSuccess,
    conversationFavoriteFailure,
    payload
  );
}
function* conversationUpdateSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    updateConversationApi,
    conversationUpdateSuccess,
    conversationUpdateFailure,
    payload
  );
}

function* conversationDeleteSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    deleteConversationApi,
    conversationDeleteSuccess,
    conversationDeleteFailure,
    payload
  );
}

function* getExtractionsSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    getExtractionsApi(payload),
    getExtractionsSuccess,
    getExtractionsFailure,
    payload
  );
}

function* updateExtractionTableSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSagaV2(
    updateExtractionTableApi(payload),
    updateExtractionTableSuccess,
    updateExtractionTableFailure,
    payload
  );
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(createConversationRequest, createConversationSaga);
  yield takeLatest(getConversationsRequest, getConversationsSaga);
  yield takeLatest(conversationAskRequest, conversationAskSaga);
  yield takeLatest(conversationHistoryRequest, conversationHistorySaga);
  yield takeLatest(conversationSummaryRequest, conversationSummarySaga);
  yield takeLatest(conversationFavoriteRequest, conversationFavoriteSaga);
  yield takeLatest(conversationUpdateRequest, conversationUpdateSaga);
  yield takeLatest(conversationDeleteRequest, conversationDeleteSaga);
  yield takeLatest(getExtractionsRequest, getExtractionsSaga);
  yield takeLatest(updateExtractionTableRequest, updateExtractionTableSaga);
}