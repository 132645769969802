import { Input, Result, Form, Space } from "antd"
import Text from "antd/lib/typography/Text"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates } from "src/constants"
import { createIssueRequest } from "src/store/actions/feedback"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar"

interface ErrorProps {
	error?: Error
}

export const Error = (_: ErrorProps) => {
	const dispatch = useDispatch()
	const [t] = useTranslate()
	const requiredFieldStar = useRequiredFieldStar()

	const createIssueStatus = useSelector(
		(state: StoreState) => state.feedback.createIssueStatus
	)
	const company = useSelector(
		(state: StoreState) => state.login.loginResponse.company
	)

	const onFinish = (values: any) => {
		dispatch(createIssueRequest({ ...values, labels: [company] }))
	}

	const onFinishFailed = (errorInfo: any) => {}

	return (
		<Space size="large" direction="vertical" style={{ width: "100%" }}>
			<Result
				status="warning"
				title={
					<>
						<Text>{t("error.operationalProblems")}</Text>
						{/* Drop us a mail at */}
						{/* <a href="mailto: contact@polymerize.io">contact@polymerize.io</a> */}
					</>
				}
				extra={
					<StyledButton
						size="large"
						type="primary"
						key="reload"
						onClick={() =>
							(window.location.href =
								new URLSearchParams(window.location.search).get("path") ||
								"/formulations")
						}
					>
						{t("common.tryAgain")}!
					</StyledButton>
				}
			/>
			<Form
				layout="vertical"
				name="basic"
				initialValues={{
					remember: true,
				}}
				requiredMark={false}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				style={{ maxWidth: 400, margin: "auto", textAlign: "center" }}
			>
				<Form.Item
					label={t("common.title")}
					name="title"
					rules={[
						{
							required: true,
							message: `${t("feedback.warn.title")}!`,
						},
					]}
					required tooltip={requiredFieldStar}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label={t("common.description")}
					name="body"
					rules={[
						{
							required: true,
							message: `${t("feedback.warn.description")}!`,
						},
					]}
					required tooltip={requiredFieldStar}
				>
					<Input.TextArea
						placeholder={t("error.placeholder.reportingAnIssue")}
					/>
				</Form.Item>

				<Form.Item>
					<StyledButton
						type="primary"
						ghost
						htmlType="submit"
						loading={createIssueStatus === AsyncStates.LOADING}
					>
						{t("common.reportAnIssue")}
					</StyledButton>
				</Form.Item>
			</Form>
		</Space>
	)
}
