import { useMemo, useState, useEffect, useCallback } from 'react'
import {
  Spin,
  Select,
  Space,
  Typography,
  message,
  Table,
  Col,
  Row,
  Form,
  notification,
  Tabs,
} from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from "src/constants"
import { StoreState } from 'src/store/configureStore'
import Plot from 'react-plotly.js'
import { LoadingOutlined } from '@ant-design/icons'
import { graphsDataHistoryRequest, plotsDataRequest, setEditingState } from 'src/store/actions/workOrderDetails'
import { ComparePlots } from './ComparePlots'
import { useValue } from 'src/utils/useValue'
import { Unsubscribe, doc, onSnapshot } from "firebase/firestore";
import { FB_COLLECTION_NAME_TASKERS, firestoreDb } from "src/utils/firebase";
import { StyledButton } from 'src/styled_components/StyledButton'

const { Option } = Select
const { Text } = Typography

interface PlotsProps {
  parameterList: any
  displayIdList: any
  setTrialSetDataList: React.Dispatch<React.SetStateAction<any>>,
  setHighlightIndex: React.Dispatch<React.SetStateAction<number>>,
}

export const dscHeatTimePlotLayout: any = {
  xaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    dtick: 2000,
    title: {
      text: 'Time (s)',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
  yaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Heat Flow (mW)',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
}

export const dscHeatTempPlotLayout: any = {
  xaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    tickmode: 'auto',
    title: {
      text: 'Temperature (°C)',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
  yaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Heat Flow (mW)',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
}

export const dscTimeTempPlotLayout: any = {
  xaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    dtick: 2000,
    title: {
      text: 'Time (s)',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
  yaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Temperature (°C)',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
}


export const temperaturePlotLayout: any = {
  xaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    dtick: 90,
    title: {
      text: 'Time',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
  yaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Temperature / Bath',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
}

export const enthalpyPlotPlayout = {
  xaxis: {
    autotypenumbers: "strict",
    title: {
      text: 'Temperature in °C',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
  yaxis: {
    title: {
      text: 'Enthalpy in J/g',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
}

export const heatBatteryPlotLayout: any = {
  xaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    dtick: 90,
    title: {
      text: 'Time',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
  yaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Temperature',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
}

enum IdentifierChecks {
  meltingPoint = "melting_point",
  freezingPoint = "freezing_point",
  enthalpyFreezing = "enthalpy-freezing",
  enthalpyMelting = "enthalpy-melting",
  degreeOfSuperCooling = "degree_of_supercooling",
  nucleationTemperature = "nucleation_temperature",
  plateauLength = "plateau_length",
  chargingTime = "95d3fb76-cfba-4864-8199-ca31d41c4592",
  dischargingTime = "0479a2e6-483f-4d95-b658-9ef8f9922a59",
  threshold_temperature = "threshold_temperature"
}


export const PropertiesPlots = ({ parameterList, displayIdList, setTrialSetDataList, setHighlightIndex }: PlotsProps) => {
  const dispatch = useDispatch()
  const { getValue } = useValue()
  const { plotsData, plotsDataStatus, editingState, graphsTaskId, workOrder } = useSelector((state: StoreState) => state.workOrderDetails)
  const datasetLabels = useSelector((state: StoreState) => state.displayNames.data?.properties || {})
  const [selectedTrial, setSelectedTrial] = useState("")
  const [selectedParameter, setSelectedParameter] = useState<any>(Object.entries(datasetLabels || {}).filter(([key, value]: any) => parameterList.includes(key))?.[0]?.[0] || "")
  const [points, setPoints] = useState({ point_1: "-", point_2: "-", final_point: "-" })
  const linePlots = useMemo(() => plotsData?.line, [plotsData])
  const barPlots = useMemo(() => plotsData?.bar, [plotsData])
  const dscPlots = useMemo(() => plotsData?.dsc, [plotsData])
  const batteryPlots = useMemo(() => plotsData?.battery, [plotsData])
  const trialSetList = useMemo(() => [...new Set([...Object.keys(linePlots || {}), ...Object.keys(barPlots || {}), ...Object.keys(dscPlots || {}), ...Object.keys(batteryPlots || {})])], [linePlots, barPlots, batteryPlots, dscPlots])
  const [selectedPoints, setSelectedPoints] = useState<any>({ x: "", y: "" })

  // graphs file upload
  useEffect(() => {
    let unsub: Unsubscribe
    const listenToFileUploadTaskStatus = async () => {
      const taskDocRef = doc(firestoreDb, `${FB_COLLECTION_NAME_TASKERS}/${graphsTaskId}`)
      unsub = onSnapshot(taskDocRef, (doc: any) => {
        const taskDoc = doc.data()
        if (taskDoc) {
          const taskStatus = taskDoc?.status
          if (taskStatus === "COMPLETED") {
            notification.success({ message: "Graphs generated succesfully" })
            dispatch(plotsDataRequest({ work_order_id: workOrder.work_order_id }))
            dispatch(graphsDataHistoryRequest({ work_order_id: workOrder.work_order_id }))
            unsub()
          }
        }
      })
    }
    if (graphsTaskId) {
      listenToFileUploadTaskStatus()
    }
  }, [dispatch, graphsTaskId, workOrder?.work_order_id])


  // useEffect(() => {
  //   if (!parameterList.includes(selectedParameter)) {
  //     setSelectedParameter("")
  //   }
  // }, [parameterList, selectedParameter])

  useEffect(() => {
    if (plotsDataStatus === AsyncStates.SUCCESS) {
      if (Object.keys(linePlots || {}).length) {
        setSelectedTrial(Object.keys(linePlots ?? {})?.[0])
      }
      else if (Object.keys(barPlots || {}).length) {
        setSelectedTrial(Object.keys(barPlots ?? {})?.[0])
      }
      else if (Object.keys(dscPlots || {}).length) {
        setSelectedTrial(Object.keys(dscPlots ?? {})?.[0])
      }
      else if (Object.keys(batteryPlots || {}).length) {
        setSelectedTrial(Object.keys(batteryPlots ?? {})?.[0])
      }
    }
  }, [plotsDataStatus, linePlots, barPlots, dscPlots, batteryPlots])

  const calculationTableColumns: any = useMemo(() => [{
    dataIndex: 'point_1',
    title: 'Point 1',
    key: 'Point 1',
    align: "center"
  }, {
    dataIndex: 'point_2',
    title: 'Point 2',
    key: 'Point 2',
    align: "center"
  },
  {
    dataIndex: 'final_point',
    title: (String(datasetLabels?.[selectedParameter]?.name || "Final point").split("_").map((res: string) => res.charAt(0).toUpperCase() + res.slice(1))).join(" "),
    key: 'Final Point',
    align: "center"
  }], [selectedParameter, datasetLabels])

  const addPoint = useCallback(() => {
    const index = displayIdList.indexOf(selectedTrial)
    if (points.final_point === "-") {
      message.error(`${(String(selectedParameter).split("_").map((res: string) => res.charAt(0).toUpperCase() + res.slice(1))).join(" ")} is not calculated`)
      return
    }
    if (index === -1) {
      message.error("Selected Trial not added in the datasheet")
      return
    }
    setTrialSetDataList((prevState: any) => {
      const newState = [...prevState]
      newState[index][selectedParameter] = points.final_point
      if (selectedParameter === IdentifierChecks.freezingPoint) {
        if (!!newState[index][IdentifierChecks.nucleationTemperature] && parameterList.includes(IdentifierChecks.degreeOfSuperCooling)) {
          const degreeOfSuperCooling = Number(points.final_point) - newState[index][IdentifierChecks.nucleationTemperature]
          if (degreeOfSuperCooling > 0) {
            newState[index][IdentifierChecks.degreeOfSuperCooling] = getValue(degreeOfSuperCooling, 2)
          } else {
            message.error("Degree of supercooling cannot be negative, re-calculate freezing point & nucleation temperature")
            return prevState
          }
        }
      }
      message.success(`${(String(selectedParameter).split("_").map((res: string) => res.charAt(0).toUpperCase() + res.slice(1))).join(" ")} value added in the datasheet`)
      return newState
    })
    setHighlightIndex(index)
    if (!editingState) {
      dispatch(setEditingState(true))
    }
  }, [displayIdList, parameterList, points, selectedParameter, selectedTrial, setTrialSetDataList, dispatch, setHighlightIndex, editingState, getValue])

  const lineGraphsClickEvent = useCallback((e: any) => {
    const point = e.points[0]
    const name = e.points[0].fullData.name
    const index = displayIdList.indexOf(selectedTrial)
    if (name === 'Bath') {
      message.error("Click on the blue points(temperature) to select values")
      return
    }
    if (!selectedParameter) {
      message.error("Selected parameter not added in the datasheet")
      return
    }
    if (index === -1) {
      message.error("Selected Trial not added in the datasheet")
      return
    }
    setSelectedPoints({ x: point.x, y: point.y })
    if (selectedParameter === IdentifierChecks.meltingPoint || selectedParameter === IdentifierChecks.freezingPoint) {
      setPoints((prevState: any) => {
        const obj = { ...prevState }
        if (obj.point_1 === "-") {
          obj.point_1 = JSON.stringify({ x: point.x, y: point.y })
        } else {
          obj.point_2 = JSON.stringify({ x: point.x, y: point.y })
        }
        if (obj.point_1 !== "-" && obj.point_2 !== "-") {
          const point_1 = Number(JSON.parse(obj.point_1).x)
          const point_2 = Number(JSON.parse(obj.point_2).x)
          const midpoint = (point_1 + point_2) / 2
          const closestValue: any = Object.values(linePlots[selectedTrial].time || {}).reduce((prev: any, curr: any) => Math.abs(curr - midpoint) < Math.abs(prev - midpoint) ? curr : prev)
          const index = Object.values(linePlots[selectedTrial].time || {}).findIndex((res: any) => res === closestValue)
          obj.final_point = Object.values(linePlots[selectedTrial].trial_data || {})[index]
        }
        return obj
      })
    }
    else if (selectedParameter === IdentifierChecks.plateauLength) {
      setPoints((prevState: any) => {
        const obj = { ...prevState }
        if (obj.point_1 === "-") {
          obj.point_1 = JSON.stringify({ x: point.x, y: getValue(point.y) })
        } else {
          obj.point_2 = JSON.stringify({ x: point.x, y: getValue(point.y) })
        }
        if (obj.point_1 !== "-" && obj.point_2 !== "-") {
          const point_1 = Number(JSON.parse(obj.point_1).x)
          const point_2 = Number(JSON.parse(obj.point_2).x)
          obj.final_point = getValue(Math.abs(point_1 - point_2))
        }
        return obj
      })
    }
    else {
      setTrialSetDataList((prevState: any) => {
        const newState = [...prevState]
        newState[index][selectedParameter] = point?.y || ""
        if (selectedParameter === IdentifierChecks.nucleationTemperature) {
          if (!!newState[index][IdentifierChecks.freezingPoint] && !!newState[index][IdentifierChecks.nucleationTemperature] && parameterList.includes(IdentifierChecks.degreeOfSuperCooling)) {
            const degreeOfSuperCooling = newState[index][IdentifierChecks.freezingPoint] - newState[index][IdentifierChecks.nucleationTemperature]
            if (degreeOfSuperCooling > 0) {
              newState[index][IdentifierChecks.degreeOfSuperCooling] = getValue(degreeOfSuperCooling, 2)
            } else {
              message.error("Degree of supercooling cannot be negative, re-calculate freezing point & nucleation temperature")
              return prevState
            }
          }
        }
        return newState
      })
      setHighlightIndex(index)
      if (!editingState) {
        dispatch(setEditingState(true))
      }
    }
  }, [dispatch, displayIdList, selectedParameter, selectedTrial, parameterList, linePlots, setTrialSetDataList, setHighlightIndex, editingState, getValue])

  const barGraphsClickEvent = useCallback((e: any) => {
    const point = e.points[0]
    const name = e.points[0].fullData.name
    const index = displayIdList.indexOf(selectedTrial)
    if (selectedParameter !== String(name).toLowerCase()) {
      message.error(`Click on ${selectedParameter} bar to select values`)
      return
    }
    if (!selectedParameter) {
      message.error("Selected parameter not added in the datasheet")
      return
    }
    if (index === -1) {
      message.error("Selected Trial not added in the datasheet")
      return
    }
    setSelectedPoints({ x: point.x, y: point.y })
    if (selectedParameter === IdentifierChecks.enthalpyFreezing || selectedParameter === IdentifierChecks.enthalpyMelting) {
      setPoints((prevState: any) => {
        const obj = { ...prevState }
        if (obj.point_1 === "-") {
          obj.point_1 = JSON.stringify({ x: point.x, y: getValue(point.y) })
        } else {
          obj.point_2 = JSON.stringify({ x: point.x, y: getValue(point.y) })
        }
        if (obj.point_1 !== "-" && obj.point_2 !== "-") {
          const point_1 = Number(JSON.parse(obj.point_1).x)
          const point_2 = Number(JSON.parse(obj.point_2).x)
          const point1Index = point?.data?.x.findIndex((res: any) => res === String(point_1))
          const point2Index = point?.data?.x.findIndex((res: any) => res === String(point_2))
          const y = point?.data?.y?.slice(point1Index, point2Index + 1)
          obj.final_point = getValue(y.reduce((sum: number, res: any) => sum + res, 0))
        }
        return obj
      })
    }
  }, [displayIdList, selectedParameter, selectedTrial, getValue])

  const batteryGraphsClickEvent = useCallback((e: any) => {
    const point = e.points[0]
    const index = displayIdList.indexOf(selectedTrial)
    if (!selectedParameter) {
      message.error("Selected parameter not added in the datasheet")
      return
    }
    if (index === -1) {
      message.error("Selected Trial not added in the datasheet")
      return
    }
    setSelectedPoints({ x: point.x, y: point.y })
    if (selectedParameter === IdentifierChecks.chargingTime || selectedParameter === IdentifierChecks.dischargingTime) {
      setPoints((prevState: any) => {
        const obj = { ...prevState }
        if (obj.point_1 === "-") {
          obj.point_1 = JSON.stringify({ x: point.x, y: getValue(point.y) })
        } else {
          obj.point_2 = JSON.stringify({ x: point.x, y: getValue(point.y) })
        }
        if (obj.point_1 !== "-" && obj.point_2 !== "-") {
          const point_1 = Number(JSON.parse(obj.point_1).x)
          const point_2 = Number(JSON.parse(obj.point_2).x)
          obj.final_point = getValue(Math.abs(point_2 - point_1))
        }
        return obj
      })
    }
  }, [displayIdList, selectedParameter, selectedTrial, getValue])

  const dscGraphClickEvent = useCallback((e: any) => {
    const point = e.points[0]
    const index = displayIdList.indexOf(selectedTrial)
    if (!selectedParameter) {
      message.error("Selected parameter not added in the datasheet")
      return
    }
    if (index === -1) {
      message.error("Selected Trial not added in the datasheet")
      return
    }
    setSelectedPoints({ x: point.x, y: point.y })
    setTrialSetDataList((prevState: any) => {
      const newState = [...prevState]
      newState[index][selectedParameter] = getValue(point?.x) || ""
      return newState
    })
    setHighlightIndex(index)
    if (!editingState) {
      dispatch(setEditingState(true))
    }
  }, [dispatch, displayIdList, selectedParameter, selectedTrial, setTrialSetDataList, setHighlightIndex, editingState, getValue])

  const calculationTable = useMemo(() => {
    return (
      <Space direction="vertical" size="large" style={{ width: "100%" }} id="points-table">
        <Table title={() => <Text strong>{`Calculate ${(String(datasetLabels?.[selectedParameter]?.name || selectedParameter).split("_").map((res: string) => res.charAt(0).toUpperCase() + res.slice(1))).join(" ")}`}</Text>}
          columns={calculationTableColumns} dataSource={[points]} pagination={false} style={{ marginTop: 20 }} />
        <Row justify="end">
          <Space size="large">
            <StyledButton type="dashed" onClick={() => setPoints({ point_1: "-", point_2: "-", final_point: "-" })}>{"Reset"} </StyledButton>
            <StyledButton type="primary" onClick={addPoint}>{`Add ${(String(datasetLabels?.[selectedParameter]?.name || selectedParameter).split("_").map((res: string) => res.charAt(0).toUpperCase() + res.slice(1))).join(" ")}`}</StyledButton>
          </Space>
        </Row>
      </Space>
    )
  }, [addPoint, calculationTableColumns, points, selectedParameter, datasetLabels])

  const selectedPointsDisplay = useMemo(() => {
    return (
      <Space size="small">
        {selectedPoints?.x && <Text strong>{`X: ${selectedPoints?.x}`}</Text>}
        {selectedPoints?.y && <Text strong>{`Y: ${selectedPoints?.y}`}</Text>}
      </Space>
    )
  }, [selectedPoints])

  const formulationGraphs = useMemo(() => {
    if (plotsDataStatus === AsyncStates.SUCCESS) {
      return (
        <Tabs onChange={() => setSelectedPoints("")}>
          {Object.keys(linePlots?.[selectedTrial] || {}).length ?
            <Tabs.TabPane tab={"Line Plot"} key={"1"} tabKey='1'>
              <Space direction="vertical" size="large" style={{ width: '100%', textAlign: "center" }}>
                <Plot onClick={lineGraphsClickEvent} key={selectedTrial}
                  layout={{
                    title: selectedTrial, showlegend: true, hovermode: 'closest', ...temperaturePlotLayout
                  }}
                  data={[{
                    x: linePlots?.[selectedTrial]?.['time'] || [] as any,
                    y: linePlots?.[selectedTrial]?.['trial_data'] || [] as any,
                    type: 'scatter',
                    name: 'Temperature',
                  },
                  {
                    x: linePlots?.[selectedTrial]?.['time'] || [] as any,
                    y: linePlots?.[selectedTrial]?.['bath'] || [] as any,
                    type: 'scatter',
                    name: 'Bath',
                  },
                  ]}
                />
                {([IdentifierChecks.meltingPoint, IdentifierChecks.freezingPoint].includes(selectedParameter) &&
                  !!Object.keys(linePlots?.[selectedTrial] || {}).length) ?
                  calculationTable : selectedPointsDisplay
                }
              </Space>
            </Tabs.TabPane>
            : null}
          {Object.keys(barPlots?.[selectedTrial] || {}).length ?
            <Tabs.TabPane tab={"Bar Plot"} key={"2"} tabKey='2'>
              <Space direction="vertical" size="large" style={{ width: '100%', textAlign: "center" }}>
                <Plot key={"enthalpy"} onClick={barGraphsClickEvent}
                  layout={{
                    barmode: 'group',
                    // title: "Enthalpy vs Temperature",
                    showlegend: true, hovermode: "closest",
                    ...enthalpyPlotPlayout as any
                  }}
                  data={[{
                    x: barPlots?.[selectedTrial]?.['trial_data'] || [] as any,
                    y: barPlots?.[selectedTrial]?.['enthalpy_freezing'] || [] as any,
                    type: 'bar',
                    name: "Enthalpy-Freezing",
                  },
                  {
                    x: barPlots?.[selectedTrial]?.['trial_data'] || [] as any,
                    y: barPlots?.[selectedTrial]?.['enthalpy_melting'] || [] as any,
                    type: 'bar',
                    name: 'Enthalpy-Melting',
                  },
                  ]}
                />
                {([IdentifierChecks.enthalpyFreezing, IdentifierChecks.enthalpyMelting, IdentifierChecks.plateauLength].includes(selectedParameter) &&
                  !!Object.keys(barPlots?.[selectedTrial] || {}).length) ?
                  calculationTable : selectedPointsDisplay}
              </Space>
            </Tabs.TabPane>
            : null}
          {Object.keys(batteryPlots?.[selectedTrial]?.data || {}).length ?
            <Tabs.TabPane tab={"Heat Battery Plot"} key={"3"} tabKey='3'>
              <Space direction="vertical" size="large" style={{ width: '100%', textAlign: "center" }}>
                <Plot key={"battery"} onClick={batteryGraphsClickEvent}
                  layout={{
                    barmode: 'group',
                    // title: "Enthalpy vs Temperature",
                    showlegend: true, hovermode: "closest",
                    ...heatBatteryPlotLayout as any
                  }}
                  data={[...Object.entries(batteryPlots?.[selectedTrial]?.data || {})
                    ?.filter(([key, value]: any) => key !== selectedTrial)
                    ?.map(([key, value]: any) => ({
                      x: batteryPlots?.[selectedTrial]?.data?.[selectedTrial] || [] as any,
                      y: value as any,
                      type: 'line',
                      name: key,
                    })) as any,
                  ]}
                />
                {([IdentifierChecks.dischargingTime, IdentifierChecks.chargingTime].includes(selectedParameter) &&
                  !!Object.keys(batteryPlots?.[selectedTrial]?.data || {}).length) ?
                  calculationTable : selectedPointsDisplay
                }
              </Space>
            </Tabs.TabPane>
            : null}
          {Object.keys(dscPlots?.[selectedTrial] || {}).length ?
            <Tabs.TabPane tab={"DSC Plot"} key={"4"} tabKey='4'>
              <Space direction="vertical" size="large" style={{ width: '100%', textAlign: "center" }}>
                <Plot onClick={dscGraphClickEvent} key={selectedTrial}
                  layout={{
                    title: "Heat Flow vs Time", showlegend: true, hovermode: 'closest', ...dscHeatTimePlotLayout
                  }}
                  data={[{
                    x: dscPlots?.[selectedTrial]?.['time'] || [] as any,
                    y: dscPlots?.[selectedTrial]?.['heat_flow'] || [] as any,
                    type: 'scatter',
                    name: "Heat Flow"
                  }]}
                />
                {selectedPointsDisplay}
                <Plot onClick={dscGraphClickEvent} key={selectedTrial}
                  layout={{
                    title: "Heat Flow vs Temperature", showlegend: true, hovermode: 'closest', ...dscHeatTempPlotLayout
                  }}
                  data={[{
                    x: dscPlots?.[selectedTrial]?.['trial_data'] || [] as any,
                    y: dscPlots?.[selectedTrial]?.['heat_flow'] || [] as any,
                    type: 'scatter',
                    name: "Heat Flow"
                  }]}
                />
                {selectedPointsDisplay}
                <Plot onClick={dscGraphClickEvent} key={selectedTrial}
                  layout={{
                    title: "Temperature vs Time", showlegend: true, hovermode: 'closest', ...dscTimeTempPlotLayout
                  }}
                  data={[{
                    x: dscPlots?.[selectedTrial]?.['time'] || [] as any,
                    y: dscPlots?.[selectedTrial]?.['trial_data'] || [] as any,
                    type: 'scatter',
                    name: 'Temperature',
                  }]}
                />
              </Space>
            </Tabs.TabPane>
            : null}
        </Tabs >
      )
    } else return null
  }, [selectedTrial, plotsDataStatus, linePlots, barPlots, dscPlots, batteryPlots, selectedPointsDisplay, batteryGraphsClickEvent, lineGraphsClickEvent, barGraphsClickEvent, dscGraphClickEvent, selectedParameter, calculationTable])


  return (
    <Spin spinning={plotsDataStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row justify="center" gutter={16}>
          <Col xxl={8} lg={12}>
            <Form.Item label="Select Trial">
              <Select value={selectedTrial} style={{ width: "100%" }} onSelect={(e: any) => {
                setPoints({ point_1: "-", point_2: "-", final_point: "-" })
                setSelectedPoints({ x: "", y: "" })
                // setTemperaturePoints({ point_1: "-", point_2: "-" })
                setSelectedTrial(e)
              }}>
                {trialSetList.map(res => (
                  <Select.Option value={res}>{res}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xxl={8} lg={12}>
            <Form.Item label="Select property">
              <Select value={selectedParameter} style={{ width: "100%" }} onSelect={(e: any) => {
                if ([IdentifierChecks.meltingPoint, IdentifierChecks.freezingPoint, IdentifierChecks.enthalpyFreezing, IdentifierChecks.enthalpyMelting, IdentifierChecks.plateauLength].includes(e)) {
                  setTimeout(() => document.getElementById('points-table')?.scrollIntoView(false), 300)
                }
                setPoints({ point_1: "-", point_2: "-", final_point: "-" })
                setSelectedPoints({ x: "", y: "" })
                setSelectedParameter(e)
              }} >
                {Object.entries({ ...datasetLabels, threshold_temperature: { name: "Threshold Temperature" } })
                  .filter(([key, _]: any) => (parameterList.includes(key) && key !== IdentifierChecks.degreeOfSuperCooling)
                    || (key === "threshold_temperature" && Object.keys(batteryPlots?.[selectedTrial]?.data || {}).length))
                  .map(([key, value]: any) => (
                    <Option value={key}>
                      {value?.name}
                    </Option>))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          {formulationGraphs}
        </Row>
        <ComparePlots linePlots={linePlots} barPlots={barPlots} />
      </Space>
    </Spin>
  )
}
