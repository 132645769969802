import React from "react"
import { Modal, ModalProps, Space } from "antd"
import Table, { ColumnsType } from "antd/lib/table"

type P = ModalProps & {
	columns: ColumnsType<object>
	data: object[]
}

export const WaterModal = ({ columns, data, ...props }: P) => {
	return (
		<Modal footer={null} width={1200} {...props}>
			<Space direction="vertical" style={{ width: "100%", overflow: "auto" }}>
				<Table
					columns={columns}
					dataSource={data}
					pagination={false}
					size="small"
					bordered
				/>
			</Space>
		</Modal>
	)
}
