import {
  Pagination,
} from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomModelDataRequest } from "src/store/actions/modelAnalysis";
import { StoreState } from "src/store/configureStore";

export const ExplainableAIPagination = ({ selectedFormulations, version, total, currentSelectedWo, currentSelectedStage }: any) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch()

  const { modelData } = useSelector((state: StoreState) => state.modelAnalysis)

  const handleChange = (page_num:number,page_size:number)=>{
    dispatch(
      getCustomModelDataRequest({
        page_num,
        page_size,
        version,
        formulation_ids: selectedFormulations,
        ...(currentSelectedWo !== '' ? { work_order_id: currentSelectedWo } : {}),
        stage_version: modelData?.stages_meta?.[`Stage ${currentSelectedStage}`]?.version
      }),
    );
  }

  return (
    <Pagination
      total={total}
      showTotal={(total, range) =>
        `${range[0]}-${range[1]} of ${total} items`
      }
      defaultPageSize={10}
      defaultCurrent={1}
      style={{ paddingTop: 8, textAlign: "end" }}
      showSizeChanger
      onChange={(page, pageSize) => {
        setPage(page);
        setLimit(pageSize);
        handleChange(page,pageSize)
      }}
      pageSize={limit}
      current={page}
    />
  )
}