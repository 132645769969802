import { Form, Input, Space, message, Pagination, Collapse, Empty } from "antd"
import Text from "antd/lib/typography/Text"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates } from "src/constants"
import {
	createIssueRequest,
	fetchIssuesRequest,
	issuesCleanup,
} from "src/store/actions/feedback"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"
import { motion } from "framer-motion"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import { StyledButton } from "src/styled_components/StyledButton"
import { StyledCard } from "src/styled_components/StyledCard"
import "./feedback.scss"
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar"

type FeedbackData = {
	status: string,
	page_size: number,
	page_num: number
};

export const Feedback = () => {
	const { Panel } = Collapse;
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const requiredFieldStar = useRequiredFieldStar()
	const [feedbackState, setFeedbackState] = useState<FeedbackData>({ status: 'open', page_size: 10, page_num: 1 })
	useEffect(() => {
		dispatch(fetchIssuesRequest(feedbackState))
	}, [dispatch, feedbackState])
	const issues = useSelector((state: StoreState) => state.feedback.data)
	const createIssueStatus = useSelector(
		(state: StoreState) => state.feedback.createIssueStatus
	)
	const tabListNoTitle = [
		{
			key: 'open',
			tab: t("projects.open"),
		},
		{
			key: 'closed',
			tab: t("common.closed"),
		}
	]

	const [form] = Form.useForm<{ title: string; body: string }>()
	const layout = {
		labelCol: {
			span: 3,
		},
		wrapperCol: {
			span: 16,
		},
	}
	// const tailLayout = {
	// 	wrapperCol: {
	// 		offset: 8,
	// 		span: 16,
	// 	},
	// }

	const onFinish = (values: { title: string; body: string }) => {
		form.resetFields()
		dispatch(createIssueRequest({ ...values, page_size: feedbackState.page_size }))
	}

	const onFinishFailed = (errorInfo: any) => { }

	useEffect(() => {
		if (createIssueStatus === AsyncStates.SUCCESS) {
			message.success(t("feedback.message.success"))
			dispatch(issuesCleanup())
		} else if (createIssueStatus === AsyncStates.ERROR) {
			message.error(t("feedback.message.error"))
			dispatch(issuesCleanup())
		}
	}, [createIssueStatus, dispatch, t])

	const genExtra = (id: number) => (
		<Text
			code
			copyable={{
				tooltips: [t("common.copy"), t("common.copied")],
			}}
		>
			{id}
		</Text>
	);

	return (
		<motion.div
			initial={{ opacity: 0, x: 20 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 20 }}
			transition={{ type: "just" }}
		>
			<Space direction="vertical" id="feedback" size="large" style={{ width: "100%" }}>
				<StyledPageHeader ghost={false} title={t("navBar.menuItem.feedback")} />
				{/* Form */}
				<StyledCard title={t("feedback.card.openATicket")} headStyle={{paddingLeft:"16px", paddingRight:"16px"}} bodyStyle={{paddingLeft:"16px", paddingRight:"16px"}}>
					<Form
						{...layout}
						name="basic"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						form={form}
						requiredMark={false}
					>
						<Form.Item
							label={t("common.title")}
							name="title"
							rules={[
								{
									required: true,
									message: t("feedback.warn.title"),
								},
							]}
							required 
							tooltip={requiredFieldStar}
							labelAlign="left"
						>
							<Input />
						</Form.Item>

						<Form.Item
							label={t("common.description")}
							name="body"
							rules={[
								{
									required: true,
									message: t("feedback.warn.description"),
								},
							]}
							required 
							tooltip={requiredFieldStar}
							labelAlign="left"
						>
							<Input.TextArea placeholder={t("feedback.placeholder.include")} />
						</Form.Item>
						<Form.Item>
							<StyledButton
								type="primary"
								htmlType="submit"
								loading={createIssueStatus === AsyncStates.LOADING}
							>
								{t("feedback.form.submit")}
							</StyledButton>
						</Form.Item>
					</Form>		
				</StyledCard>

				{/* Paginated Results */}
				<StyledCard tabList={tabListNoTitle} activeTabKey={feedbackState.status} onTabChange={(key) => setFeedbackState({ ...feedbackState, page_num: 1, status: key })} headStyle={{paddingLeft:"16px", paddingRight:"16px"}} bodyStyle={{paddingLeft:"16px", paddingRight:"16px"}}>
						{issues.data.length ? <Collapse style={{ marginTop: '1rem' }}>
							{issues.data?.map((issue: any) => (
								<Panel header={issue?.title} key={issue.feedback_id} extra={genExtra(issue.ticket_no)}>
									<p>{issue.description}</p>
								</Panel>
							))}
						</Collapse> : <Empty />}
						<Pagination style={{ marginTop: "1rem" }} hideOnSinglePage={false} current={feedbackState.page_num} showSizeChanger defaultCurrent={1} onChange={(page_num, page_size) => setFeedbackState({ ...feedbackState, page_size, page_num })}
							total={issues.count} />
					</StyledCard>
			</Space>
		</motion.div>
	)
}
