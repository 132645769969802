
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`中心複合計画`}</h1>
    <p><a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/Central_composite_design"
      }}>{`Box-Wilson 中心複合計画`}</a>{`（一般的には「中央複合計画」とも呼ばれます）は、最も一般的に使用される応答曲面設計実験です。中央複合計画は、中心点を持つ階差または部分的な要因計画に、曲面性を推定するための軸点（または星点）のグループが追加されています。中央複合計画を使用すると、次のことができます。`}</p>
    <ul>
      <li parentName="ul">{`一次および二次項を効率的に推定する。`}</li>
      <li parentName="ul">{`既に行われた要因計画に中心点と軸点を追加することで、曲面性を持つ応答変数をモデル化する。`}</li>
    </ul>
    <p>{`中央複合計画は、完全な二次モデルに適合することができます。これらの計画は、計画が連続的な実験を必要とする場合に、正しく計画された要因実験からの情報を含めることができます。`}</p>
    <p>{`可能な場合、中央複合計画は直交ブロックと回転可能性の望ましい特性を持っています。`}</p>
    <p><strong parentName="p">{`直交ブロック:`}</strong></p>
    <p>{`多くの場合、中央複合計画は複数のブロックで実施されます。中央複合計画は直交ブロックを作成し、モデル項やブロック効果を独立に推定し、回帰係数のばらつきを最小化することができます。`}</p>
    <p><strong parentName="p">{`回転可能性:`}</strong></p>
    <p>{`回転可能な設計は、設計中心から等距離にあるすべての点で一定の予測分散を提供します。`}</p>
    <p><strong parentName="p">{`使用例:`}</strong></p>
    <ul>
      <li parentName="ul">{`プロセスの最適化`}</li>
      <li parentName="ul">{`逐次計画`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;