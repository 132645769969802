import { Drawer } from "antd";
import { useHistory, useParams } from "react-router-dom";
import "../../Shared/SEMAnalysis.scss";
import FlowabilityAnalysisDetailsContent from "./FlowabilityAnalysisDetailsContent";
import FlowabilityAnalysisDetailsSidebar from "./FlowabilityAnalysisDetailsSidebar";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadSemAnalysisReportRequest,
  fetchInstanceDetectionRequest,
  fetchSemAnalysisMaskDataRequest,
  fetchSemAnalysisMaskHeightWidthRequest,
  fetchSemAnalysisScaleRequest,
  setSemAnalysisMaskHeightWidthRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import FlowabilityAnalysisAttributes from "./FlowabilityAnalysisAttributes";
import { CreateGroupModal } from "../../Shared/CreateGroupModal";
import CalibrateModal from "../../Shared/CalibrateModal";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";

const FlowabilityAnalysisDetails = () => {
  const params = useParams<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const [t] = useTranslate();
  const [showBBox, setShowBBox] = useState(false);
  const [imgToShow, setImgToShow] = useState<"Before" | "After">("After");
  const [showFixedArea, setShowFixedArea] = useState(true);
  const [selectedPoints, setSelectedPoints] = useState<any[]>([]);
  const [showCreateGrooupModal, setShowCreateGroupModal] = useState(false);
  const [isGroupsVisible, setIsGroupsVisible] = useState(false);
  const [showCalibrateModal, setShowCalibrateModal] = useState(false);
  const [tooltip, setTooltip] = useState({});
  const [selections, setSelections] = useState<any[]>([]);
  const {
    semAnalysisMaskData,
    instanceDetection,
    downloadSemAnalysisReportStatus,
    analysisTypes,
    deleteSemAnalysisMaskHeightWidthStatus,
    setSemAnalysisMaskHeightWidthStatus,
    semAnalysisScaleStatus
  } = useSelector((state: StoreState) => state.semAnalysis);

  useLayoutEffect(() => {
    if (!mounted.current || semAnalysisScaleStatus === AsyncStates.SUCCESS) {
      mounted.current = true;
      dispatch(fetchSemAnalysisMaskDataRequest({
        file_id: params.fileId,
        analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS as keyof typeof analysisTypes]
      }));
      dispatch(fetchSemAnalysisMaskHeightWidthRequest({
        file_id: params.fileId,
        analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS as keyof typeof analysisTypes]
      }));
      dispatch(fetchInstanceDetectionRequest({ file_id: params.fileId }));
    }
  }, [analysisTypes, dispatch, params.fileId, semAnalysisScaleStatus]);

  useEffect(() => {
    if (
      deleteSemAnalysisMaskHeightWidthStatus === AsyncStates.SUCCESS ||
      setSemAnalysisMaskHeightWidthStatus === AsyncStates.SUCCESS
    )
      setSelectedPoints([]);
  }, [
    deleteSemAnalysisMaskHeightWidthStatus,
    setSemAnalysisMaskHeightWidthStatus,
    setSelectedPoints,
  ]);

  const downloadReport = useCallback(() => {
    const payload: any = {
      file_id: params.fileId,
      analysis_type: showFixedArea
        ? analysisTypes[
        IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS as keyof typeof analysisTypes
        ]
        : analysisTypes[
        IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS as keyof typeof analysisTypes
        ],
    };

    dispatch(downloadSemAnalysisReportRequest(payload));
  }, [analysisTypes, dispatch, params.fileId, showFixedArea]);

  const chosenImage = useMemo(() => {
    if (imgToShow === "After") return semAnalysisMaskData?.processed_image_url;
    if (imgToShow === "Before") return semAnalysisMaskData?.original_image_url;

    return null;
  }, [imgToShow, semAnalysisMaskData?.original_image_url, semAnalysisMaskData?.processed_image_url]);

  const bboxInstances: any[] = useMemo(() => {
    return !!Object.keys(instanceDetection?.mapping || {}).length
      ? Object.values(instanceDetection?.mapping ?? {})
      : [];
  }, [instanceDetection?.mapping]);

  const handleCreateGroup = useCallback(
    (val: any) => {
      const points: any = {};
      selectedPoints.forEach((item: any, i: any) => {
        points[`p${i}`] = item;
      });
      const obj = { group_name: val, points };
      dispatch(
        setSemAnalysisMaskHeightWidthRequest({
          ...obj,
          file_id: params.fileId,
          analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS as keyof typeof analysisTypes]
        })
      );
    },
    [selectedPoints, dispatch, params.fileId, analysisTypes]
  );

  const handleCalibrate = useCallback(
    (scale_length: any, unit: any) => {
      dispatch(
        fetchSemAnalysisScaleRequest({
          scale_length,
          unit,
          file_id: params.fileId,
          analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS as keyof typeof analysisTypes]
        })
      );
    },
    [dispatch, params.fileId, analysisTypes]
  );

  const handleChartSelection = useCallback((eventData) => {
    if (eventData.selections && Array.isArray(eventData.selections))
      setSelections(eventData.selections);
    else {
      setSelections([
        {
          xref: "x",
          yref: "y",
          line: { width: 1, dash: "dot" },
          type: "rect",
          x0: eventData["selections[0].x0"],
          y0: eventData["selections[0].y0"],
          x1: eventData["selections[0].x1"],
          y1: eventData["selections[0].y1"],
        },
      ]);
    }
  }, []);

  const onDrawerClose = () =>
    history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.FLOWABILITY_ANALYSIS}`);

  return (
    <Drawer
      title={"Flowability Analysis"}
      placement="bottom"
      open={true}
      height="100%"
      styles={{
        body: { padding: 0 },
      }}
      onClose={onDrawerClose}
      className="paint-film-analysis-drawer"
      extra={
        <>
          <StyledButton
            type="primary"
            onClick={downloadReport}
            style={{ marginRight: 10 }}
            loading={downloadSemAnalysisReportStatus === AsyncStates.LOADING}
          >
            {t("report.downloadReport")}
          </StyledButton>
        </>
      }
    >
      <div className="container">
        <FlowabilityAnalysisDetailsSidebar
          imgToShow={imgToShow}
          setImgToShow={setImgToShow}
          showBBox={showBBox}
          setShowBBox={setShowBBox}
          showFixedArea={showFixedArea}
          setShowFixedArea={setShowFixedArea}
          selectedPoints={selectedPoints}
          setSelectedPoints={setSelectedPoints}
          setShowCreateGroupModal={setShowCreateGroupModal}
          isGroupsVisible={isGroupsVisible}
          setIsGroupsVisible={setIsGroupsVisible}
          setShowCalibrateModal={setShowCalibrateModal}
          tooltip={tooltip}
          bboxInstances={bboxInstances}
        />
        <FlowabilityAnalysisDetailsContent
          showFixedArea={showFixedArea}
          data={semAnalysisMaskData}
          image={chosenImage}
          showBBox={showBBox}
          selectedPoints={selectedPoints}
          setSelectedPoints={setSelectedPoints}
          isGroupsVisible={isGroupsVisible}
          setTooltip={setTooltip}
          bboxInstances={bboxInstances}
          selections={selections}
        />
      </div>
      <div className="info-container">
        <FlowabilityAnalysisAttributes
          showFixedArea={showFixedArea}
          handleChartSelection={handleChartSelection}
          selections={selections}
        />
      </div>
      {showCreateGrooupModal && (
        <CreateGroupModal
          createGroupModal={showCreateGrooupModal}
          setCreateGroupModal={setShowCreateGroupModal}
          handleCreateGroup={handleCreateGroup}
        />
      )}
      {showCalibrateModal && (
        <CalibrateModal
          calibrateModal={showCalibrateModal}
          setCalibrateModal={setShowCalibrateModal}
          handleCalibrate={handleCalibrate}
        />
      )}
    </Drawer>
  );
};

export default FlowabilityAnalysisDetails;
