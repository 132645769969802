import "src/components/WorkOrderDetails/react-datasheet.css"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { AsyncStates } from "src/constants"
import Spinner from "src/components/Spinner/Spinner"
import { Collapse, Space, Tabs, Typography } from "antd"
import {
	LinkedMethodTable,
	WOIngredientsComparisonTable,
	WOProcessingComparisonTable,
	OutputCostingTable,
	ProcessingCostingTable,
	FormulationsCostingTable
} from "src/components/WorkOrderDetails/WorkOrderTable"
import useTranslate from "src/utils/useTranslate"
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledButton } from "src/styled_components/StyledButton"
import { FileExcelOutlined } from "@ant-design/icons"
import { dataExportRequest, listProcessingProfilesRequest } from "src/store/actions/workOrderDetails"
import { NestleProfiles } from "src/components/Datasheet/Processing/NestleProfiles"

const { Panel } = Collapse
const { Text } = Typography

type DisplayTables = {
	formulations: boolean
	processing: boolean
	procProfilesCheck: boolean
	methods: boolean
}

export type ReportTab = "over-all-summary" | string

export const ViewData = () => {
	const [t] = useTranslate()
	const { status } = useSelector((state: StoreState) => state.compareFormulations)
	const [displayTables, setDisplayTables] = useState<DisplayTables>({
		formulations: false,
		processing: false,
		methods: false,
		procProfilesCheck: false
	})

	const dispatch = useDispatch()
	const configs = useSelector((state: StoreState) => state.configs.features)
	const { formulationDetailsData: formulationList } = useSelector((state: StoreState) => state.compareFormulations)
	const workOrderList = useMemo(() => [...new Set(formulationList.map(({ work_order_id }: any) => work_order_id))], [formulationList])
	const [reportTab, setReportTab] = useState<ReportTab>("over-all-summary")
	const linkedExperiment = useSelector((state: StoreState) => state.workOrderDetails.linkedExperimentDetails)

	const updatedFormulationList = useMemo(() => {
		if (workOrderList.includes(reportTab)) {
			return formulationList.filter(({ work_order_id }: any) => work_order_id === reportTab)
		} else {
			return formulationList
		}
	}, [formulationList, reportTab, workOrderList])

	const updatedLinedTrials = useMemo(() => {
		const formulationIds = updatedFormulationList.map((res: any) => res?.id_set?.formulation_id)
		if (workOrderList.includes(reportTab)) {
			return linkedExperiment.filter((res: any) => res?.work_order_id === reportTab && !formulationIds.includes(res?.id_set?.formulation_id))
		} else {
			return linkedExperiment.filter((res: any) => !formulationIds.includes(res?.id_set?.formulation_id))
		}
	}, [linkedExperiment, reportTab, workOrderList, updatedFormulationList])

	const generatePayload = (workOrderList: string[], formulationList: any) => {
		const result = workOrderList.map((workOrderId: string) => {
			return formulationList.filter(({ work_order_id }: any) => work_order_id === workOrderId).map(({ id_set: { formulation_id } }: any) => formulation_id)
		})
		return result
	}

	useEffect(() => {
		dispatch(listProcessingProfilesRequest())
	}, [dispatch])


	useEffect(() => {
		const obj = {
			formulations: false,
			processing: false,
			methods: false,
			procProfilesCheck: false
		}

		updatedFormulationList.forEach((res: any) => {
			if (Object.keys(res?.ingredients || {}).length) obj.formulations = true
			if (Object.keys(res?.processing?.[0]?.processing || {}).length) obj.processing = true
			if (Object.keys(res?.processing?.[0]?.meta?.profile_id || {}).length) obj.procProfilesCheck = true
			if (res?.characterizations?.[0]?.characterizations?.length || res?.properties?.[0]?.properties?.length) {
				obj.methods = true
			}
		})
		setDisplayTables(obj)
	}, [updatedFormulationList])

	return (
		<>
			{status === AsyncStates.LOADING && <Spinner />}
			<StyledCard style={{ width: "100%" }} title={
				<Tabs style={{ margin: "0.5rem 0rem" }} activeKey={reportTab} defaultActiveKey={reportTab} onChange={(key) => setReportTab(key)}>
					<Tabs.TabPane tabKey="over-all-summary" key="over-all-summary" tab="Over All Summary" />
					{workOrderList?.map((work_order_id: any) => <Tabs.TabPane tabKey={work_order_id} key={work_order_id} tab={
						<Text style={{ maxWidth: "175px" }} ellipsis={
							{ tooltip: formulationList.find((formulation) => formulation.work_order_id === work_order_id)?.work_order_name ?? work_order_id }
						}>
							{formulationList.find((formulation) => formulation.work_order_id === work_order_id)?.work_order_name ?? work_order_id}</Text>
					} />
					)}
				</Tabs>
			}
			>
				<Space size="small" direction="vertical" style={{ width: "100%" }}>
					{<Space style={{ display: "flex", justifyContent: "flex-end" }}>
						<StyledButton type="primary"
							icon={<FileExcelOutlined />}
							ghost
							onClick={() => {
								const payload = reportTab === "over-all-summary" ? {
									formulation_ids: generatePayload(workOrderList, formulationList),
								} : {
									work_order_ids: [reportTab],
									formulation_ids: [...new Set(formulationList.filter(({ work_order_id }: any) => work_order_id === reportTab).map(({ id_set }: any) => id_set.formulation_id))],
								}
								dispatch(dataExportRequest(payload))
							}
							}
						>
							{t("workOrderDetails.exportDatasheet")}
						</StyledButton>
					</Space>}
					{displayTables.formulations && Boolean(configs?.nestle_configs) && (
						<Collapse defaultActiveKey={["100"]}>
							<Panel
								header={<Text strong>{"Stage 1 - Formulations"}</Text>}
								key="100"
							>
								<WOIngredientsComparisonTable
									formulationList={updatedLinedTrials}
								/>
							</Panel>
						</Collapse>
					)}
					{displayTables.formulations && (
						<Collapse defaultActiveKey={["1"]}>
							<Panel
								header={<Text strong>{t("common.formulations")}</Text>}
								key="1"
							>
								<WOIngredientsComparisonTable
									formulationList={updatedFormulationList}
								/>
							</Panel>
						</Collapse>
					)}
					{(Boolean(configs?.processing_profiles) && displayTables.procProfilesCheck) &&
						<Collapse defaultActiveKey={["2"]}>
							<Panel
								header={<Text strong>{t("common.processing")}</Text>}
								key="2"
							>
								<NestleProfiles initialTrials={updatedFormulationList} disabled={true} size={"small"} />
							</Panel>
						</Collapse>}
					{displayTables.processing && (
						<Collapse defaultActiveKey={["3"]}>
							<Panel
								header={<Text strong>{t("common.processing")}</Text>}
								key="3"
							>
								<WOProcessingComparisonTable
									formulationList={updatedFormulationList}
								/>
							</Panel>
						</Collapse>
					)}
					{displayTables.methods && (
						<Collapse defaultActiveKey={["4"]}>
							<Panel
								header={<Text strong>{!Boolean(configs?.characterization_methods) ? t("formulations.characterizations") : t("common.methods")}</Text>}
								key="4"
							>
								<LinkedMethodTable
									formulationList={updatedFormulationList}
								/>
							</Panel>
						</Collapse>
					)}
					{Boolean(configs?.work_order_costing) &&
						<Collapse defaultActiveKey={["5"]}>
							<Panel
								header={<Text strong>{"Formulations Costing"}</Text>}
								key="5"
							>
								<FormulationsCostingTable formulationList={updatedFormulationList} />
							</Panel>
						</Collapse>
					}
					{Boolean(configs?.work_order_costing) &&
						<Collapse defaultActiveKey={["6"]}>
							<Panel
								header={<Text strong>{"Processing Costing"}</Text>}
								key="6"
							>
								<ProcessingCostingTable formulationList={updatedFormulationList} />
							</Panel>
						</Collapse>
					}
					{Boolean(configs?.work_order_costing) &&
						<Collapse defaultActiveKey={["7"]}>
							<Panel
								header={<Text strong>{"Output Costing"}</Text>}
								key="7"
							>
								<OutputCostingTable formulationList={updatedFormulationList}
								/>
							</Panel>
						</Collapse>
					}
				</Space>
			</StyledCard>
		</>
	)
}
