import { createActions } from "redux-actions";


export enum CreateParameterActionType {
  REQUEST = 'CREATE_PARAMETER_REQUEST',
  SUCCESS = 'CREATE_PARAMETER_SUCCESS',
  FAILURE = 'CREATE_PARAMETER_FAILURE',
  CLEAR = 'CREATE_PARAMETER_CLEAR'
}

export enum UpdateParameterActionType {
  REQUEST = 'UPDATE_PARAMETER_REQUEST',
  SUCCESS = 'UPDATE_PARAMETER_SUCCESS',
  FAILURE = 'UPDATE_PARAMETER_FAILURE',
  CLEAR = 'UPDATE_PARAMETER_CLEAR'
}

export enum CheckPropertyExistsType {
  REQUEST = 'CHECK_PROPERTY_EXISTS_REQUEST',
  SUCCESS = 'CHECK_PROPERTY_EXISTS_SUCCESS',
  FAILURE = 'CHECK_PROPERTY_EXISTS_FAILURE',
  CLEAR = 'CHECK_PROPERTY_EXISTS_CLEAR'
}

export enum DeletePropertyActionType {
  REQUEST = 'DELETE_PROPERTY_REQUEST',
  SUCCESS = 'DELETE_PROPERTY_SUCCESS',
  FAILURE = 'DELETE_PROPERTY_FAILURE',
  CLEAR = 'DELETE_PROPERTY_CLEAR'
}

export enum MetaDataTemplatesCreateActionTypes {
  REQUEST = "CREATE_METADATA_TEMPLATE_REQUEST",
  SUCCESS = "CREATE_METADATA_TEMPLATE_SUCCESS",
  FAILURE = "CREATE_METADATA_TEMPLATE_FAILURE",
}
export enum MetaDataTemplatesListsActionTypes {
  REQUEST = "LIST_METADATA_TEMPLATE_REQUEST",
  SUCCESS = "LIST_METADATA_TEMPLATE_SUCCESS",
  FAILURE = "LIST_METADATA_TEMPLATE_FAILURE",
}

export enum MetaDataTemplatesDeleteActionTypes {
  REQUEST = "DELETE_METADATA_TEMPLATE_REQUEST",
  SUCCESS = "DELETE_METADATA_TEMPLATE_SUCCESS",
  FAILURE = "DELETE_METADATA_TEMPLATE_FAILURE",
}

export enum MetaDataTemplatesUpdateActionTypes {
  REQUEST = "EDIT_METADATA_TEMPLATE_REQUEST",
  SUCCESS = "EDIT_METADATA_TEMPLATE_SUCCESS",
  FAILURE = "EDIT_METADATA_TEMPLATE_FAILURE",
}

export enum InventoryDataActionTypes {
  SET = "SET_INVENTORY_DATA",
  CLEAR = "CLEAR_INVENTORY_DATA"
}

export enum InventoryAttachmentsAddActionTypes {
  REQUEST = "INVENTORY_ATTACHMENTS_ADD_REQUEST",
  SUCCESS = "INVENTORY_ATTACHMENTS_ADD_SUCCESS",
  FAILURE = "INVENTORY_ATTACHMENTS_ADD_FAILURE",
}

export enum InventoryAttachmentsListActionTypes {
  REQUEST = "INVENTORY_ATTACHMENTS_LIST_REQUEST",
  SUCCESS = "INVENTORY_ATTACHMENTS_LIST_SUCCESS",
  FAILURE = "INVENTORY_ATTACHMENTS_LIST_FAILURE",
  CLEAR = "INVENTORY_ATTACHMENTS_LIST_CLEAR",
}

export enum InventoryAttachmentsDeleteActionTypes {
  REQUEST = "INVENTORY_ATTACHMENTS_DELETE_REQUEST",
  SUCCESS = "INVENTORY_ATTACHMENTS_DELETE_SUCCESS",
  FAILURE = "INVENTORY_ATTACHMENTS_DELETE_FAILURE",
}

export enum InventoryUploadActionType {
  REQUEST = 'INVENTORY_UPLOAD_REQUEST',
  SUCCESS = 'INVENTORY_UPLOAD_SUCCESS',
  FAILURE = 'INVENTORY_UPLOAD_FAILURE',
}


export const {
  createParameterRequest,
  createParameterSuccess,
  createParameterFailure,
  createParameterClear,
  updateParameterRequest,
  updateParameterSuccess,
  updateParameterFailure,
  updateParameterClear,
  checkPropertyExistsRequest,
  checkPropertyExistsSuccess,
  checkPropertyExistsFailure,
  checkPropertyExistsClear,
  deletePropertyRequest,
  deletePropertySuccess,
  deletePropertyFailure,
  deletePropertyClear,
  createMetadataTemplateRequest,
  createMetadataTemplateSuccess,
  createMetadataTemplateFailure,
  listMetadataTemplateRequest,
  listMetadataTemplateSuccess,
  listMetadataTemplateFailure,
  deleteMetadataTemplateRequest,
  deleteMetadataTemplateSuccess,
  deleteMetadataTemplateFailure,
  editMetadataTemplateRequest,
  editMetadataTemplateSuccess,
  editMetadataTemplateFailure,
  setInventoryData,
  clearInventoryData,
  inventoryAttachmentsAddRequest,
  inventoryAttachmentsAddSuccess,
  inventoryAttachmentsAddFailure,
  inventoryAttachmentsListRequest,
  inventoryAttachmentsListSuccess,
  inventoryAttachmentsListFailure,
  inventoryAttachmentsListClear,
  inventoryAttachmentsDeleteRequest,
  inventoryAttachmentsDeleteSuccess,
  inventoryAttachmentsDeleteFailure,
  inventoryUploadRequest,
  inventoryUploadSuccess,
  inventoryUploadFailure,
} = createActions({
  [CreateParameterActionType.REQUEST]: (payload) => payload,
  [CreateParameterActionType.SUCCESS]: (response) => response,
  [CreateParameterActionType.FAILURE]: (error: string) => ({ error }),
  [CreateParameterActionType.CLEAR]: () => { },
  [UpdateParameterActionType.REQUEST]: (payload) => payload,
  [UpdateParameterActionType.SUCCESS]: (response) => response,
  [UpdateParameterActionType.FAILURE]: (error: string) => ({ error }),
  [UpdateParameterActionType.CLEAR]: () => { },
  [CheckPropertyExistsType.REQUEST]: (payload) => payload,
  [CheckPropertyExistsType.SUCCESS]: (response) => response,
  [CheckPropertyExistsType.FAILURE]: (error: string) => ({ error }),
  [CheckPropertyExistsType.CLEAR]: () => { },
  [DeletePropertyActionType.REQUEST]: (payload) => payload,
  [DeletePropertyActionType.SUCCESS]: (response) => response,
  [DeletePropertyActionType.FAILURE]: (error: string) => ({ error }),
  [DeletePropertyActionType.CLEAR]: () => { },
  [MetaDataTemplatesCreateActionTypes.REQUEST]: (payload) => payload,
  [MetaDataTemplatesCreateActionTypes.SUCCESS]: (data) => data,
  [MetaDataTemplatesCreateActionTypes.FAILURE]: (error: any) => (error),
  [MetaDataTemplatesListsActionTypes.REQUEST]: (payload) => payload,
  [MetaDataTemplatesListsActionTypes.SUCCESS]: (data) => data,
  [MetaDataTemplatesListsActionTypes.FAILURE]: (error: any) => (error),
  [MetaDataTemplatesDeleteActionTypes.REQUEST]: (payload) => payload,
  [MetaDataTemplatesDeleteActionTypes.SUCCESS]: (data) => data,
  [MetaDataTemplatesDeleteActionTypes.FAILURE]: (error: any) => (error),
  [MetaDataTemplatesUpdateActionTypes.REQUEST]: (payload) => payload,
  [MetaDataTemplatesUpdateActionTypes.SUCCESS]: (data) => data,
  [MetaDataTemplatesUpdateActionTypes.FAILURE]: (error: any) => (error),
  [InventoryDataActionTypes.SET]: (data) => data,
  [InventoryDataActionTypes.CLEAR]: () => ({}),
  [InventoryAttachmentsAddActionTypes.REQUEST]: (data) => data,
  [InventoryAttachmentsAddActionTypes.SUCCESS]: (data) => data,
  [InventoryAttachmentsAddActionTypes.FAILURE]: (data) => data,
  [InventoryAttachmentsListActionTypes.REQUEST]: (data) => data,
  [InventoryAttachmentsListActionTypes.SUCCESS]: (data) => data,
  [InventoryAttachmentsListActionTypes.FAILURE]: (data) => data,
  [InventoryAttachmentsListActionTypes.CLEAR]: () => { },
  [InventoryAttachmentsDeleteActionTypes.REQUEST]: (data) => data,
  [InventoryAttachmentsDeleteActionTypes.SUCCESS]: (data) => data,
  [InventoryAttachmentsDeleteActionTypes.FAILURE]: (data) => data,
  [InventoryUploadActionType.REQUEST]: (data) => data,
  [InventoryUploadActionType.SUCCESS]: (data) => data,
  [InventoryUploadActionType.FAILURE]: () => { },
})
