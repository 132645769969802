// import { NotificationsResponse } from "./interface"
import axios from "axios"
import { Headers } from "../interface"

export const notifications = (headers: Headers) => {
	return axios.get<any>(
		process.env.REACT_APP_API_URL + "v1/data/_notifications",
		{
			headers,
		}
	)
}

export const updateNotification = (params: any, headers: Headers) => {
	return axios.post<any>(
		process.env.REACT_APP_API_URL + "v1/data/_notification/" + params,
		null,
		{
			headers,
		}
	)
}

export type UpdateMultipleNotificationParams = {
	notification_ids: string[]
}
export type UpdateMultipleNotificationResponse = {
	notification_ids: string[]
}
export const updateMultipleNotification = (
	params: UpdateMultipleNotificationParams,
	headers: Headers
) => {
	return axios.post<any>(
		process.env.REACT_APP_API_URL + "v1/data/_update_notifications",
		params,
		{
			headers,
		}
	)
}
