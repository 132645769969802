import { message, notification } from "antd";
import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  deleteFileApi,
  fileUploadAPi,
  fileHistoryApi,
  importMultipleWorkOrdersApi,
  fileListApi,
  draftFileUploadAPi,
} from "src/services/files";
import { NestleFileUploadApi, ToyoInkFileUploadApi } from "src/services/fileUpload";
import { messages } from "src/utils/hooks";
import jwtManager from "src/utils/jwtManager";
import { LanguageUnion } from "src/utils/useTranslate";
import {
  deleteFileFailure,
  deleteFileRequest,
  deleteFileSuccess,
  fileHistoryFailure,
  fileHistoryRequest,
  fileHistorySuccess,
  importMultipleWorkOrdersFailure,
  importMultipleWorkOrdersRequest,
  importMultipleWorkOrdersSuccess,
  mappedFilesFailure,
  mappedFilesSuccess,
  mappedFilesRequest,
  uploadFileFailure,
  uploadFileRequest,
  uploadFileSuccess,
  draftFileUploadFailure,
  draftFileUploadSuccess,
  draftFileUploadRequest,
} from "../actions/files";
import { StoreState } from "../configureStore";

function* deleteFileSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const currentProject = yield select((state) => state.projects.current);
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { message: responseMessage, status },
      },
    } = yield call(deleteFileApi, payload, headers);

    if (status === "Success") {
      yield put(deleteFileSuccess(payload.file_id));
      yield put(fileHistoryRequest({ project_id: currentProject }));
      message.success(responseMessage);
    } else {
      yield put(deleteFileFailure());
      message.error(responseMessage);
    }
  } catch (error) {
    yield put(deleteFileFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* draftFileUploadFileSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, message, status },
      },
    } = yield call(
      draftFileUploadAPi,
      payload,
      headers
    );

    if (status === "Success") {
      yield put(mappedFilesRequest({ context: payload.context }));
      yield put(draftFileUploadSuccess(data));
      notification.success({
        message,
        description: `${messages[ln].work_order_created
          }, ${new Date().toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}`,
      });
      //   yield put(uploadFileClear());
    } else {
      yield put(draftFileUploadFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(draftFileUploadFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* uploadFileSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const configs = yield select((state: StoreState) => state.configs.features);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, message, status },
      },
    } = yield call(
      Boolean(configs?.nestle_configs) ? NestleFileUploadApi : Boolean(configs?.toyoink_configs) ? ToyoInkFileUploadApi : fileUploadAPi,
      payload,
      headers
    );

    if (status === "Success") {
      yield put(uploadFileSuccess(data));
      notification.success({
        message,
        description: `${messages[ln].work_order_created
          }, ${new Date().toLocaleString([], {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}`,
      });
      //   yield put(uploadFileClear());
    } else {
      yield put(uploadFileFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(uploadFileFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* fileHistorySaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status },
      },
    } = yield call(fileHistoryApi, payload, headers);

    if (status === "Success") {
      yield put(fileHistorySuccess(data));
    } else {
      yield put(fileHistoryFailure());
    }
  } catch (error) {
    yield put(fileHistoryFailure(error));
  }
}

function* importMultipleWOSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status },
      },
    } = yield call(importMultipleWorkOrdersApi, payload, headers);

    if (status === "Success") {
      yield put(importMultipleWorkOrdersSuccess(data));
    } else {
      yield put(importMultipleWorkOrdersFailure());
    }
  } catch (error) {
    yield put(importMultipleWorkOrdersFailure(error));
  }
}

function* mappedFilesSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status },
      },
    } = yield call(fileListApi, payload, headers);

    if (status === "Success") {
      yield put(mappedFilesSuccess(data));
    } else {
      yield put(mappedFilesFailure());
    }
  } catch (error) {
    yield put(mappedFilesFailure(error));
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(deleteFileRequest, deleteFileSaga);
  yield takeLatest(draftFileUploadRequest, draftFileUploadFileSaga);
  yield takeLatest(uploadFileRequest, uploadFileSaga);
  yield takeLatest(fileHistoryRequest, fileHistorySaga);
  yield takeLatest(importMultipleWorkOrdersRequest, importMultipleWOSaga);
  yield takeLatest(mappedFilesRequest, mappedFilesSaga);
}
