import React from "react"
import { ArrowUpOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { antdTheme } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";


type ScrollToTopProps = {
    sectionRef: React.RefObject<HTMLDivElement>;
}

export const ScrollToTopInDrawer = ({ sectionRef }: ScrollToTopProps) => {
    return (
        <div style={{ position: "relative" }}>
            <Tooltip title="Scroll To Top">
                <StyledButton size="large" shape="circle"
                    style={{
                        position: "fixed",
                        bottom: "90px",
                        right: "25px",
                        zIndex: 20,
                        width: "40px",
                        height: "40px",
                    }}
                    onClick={() => {
                        sectionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" })
                    }}
                    icon={<ArrowUpOutlined style={{ fontSize: antdTheme.fontSizeHeading3 }} />}>
                </StyledButton>
            </Tooltip>
        </div >
    )
}