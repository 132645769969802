
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Central Composite`}</h1>
    <p>{`A Box-Wilson `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Central_composite_design"
      }}>{`Central Composite Design`}</a>{`, commonly called 'a central composite design' is the most commonly used response surface designed experiment. Central composite designs are a factorial or fractional factorial design with center points, augmented with a group of axial points (also called star points) that let you estimate curvature. You can use a central composite design to:`}</p>
    <ul>
      <li parentName="ul">{`Efficiently estimate first- and second-order terms.`}</li>
      <li parentName="ul">{`Model a response variable with curvature by adding center and axial points to a previously-done factorial design.`}</li>
    </ul>
    <p>{`Central Composite designs can fit a full quadratic model. They are often used when the design plan calls for sequential experimentation because these designs can include information from a correctly planned factorial experiment.`}</p>
    <p>{`When possible, central composite design have the desired properties of orthogonal blocks and rotatability.`}</p>
    <p><strong parentName="p">{`Orthogonal blocks:`}</strong></p>
    <p>{`Often, central composite designs are done in more than one block. Central composite designs can create orthogonal blocks, letting model terms and block effects be estimated independently and minimizing the variation in the regression coefficients.`}</p>
    <p><strong parentName="p">{`Rotatability:`}</strong></p>
    <p>{`Rotatable designs provide constant prediction variance at all points that are equidistant from the design center`}</p>
    <p><strong parentName="p">{`Use Cases :-`}</strong></p>
    <ul>
      <li parentName="ul">{`Process Optimization`}</li>
      <li parentName="ul">{`Sequential Designing`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;