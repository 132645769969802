import { Col, Descriptions, Row, Space, Typography } from 'antd'
import { StyledCard } from 'src/styled_components/StyledCard'
import { Activity } from './Activity'
import { Notes } from './Notes'
import { Dashboard } from './Dashboard'
import useTranslate from 'src/utils/useTranslate'
import { useValue } from 'src/utils/useValue'
import { LinkOutlined } from '@ant-design/icons'
import { getExternalLink } from 'src/utils/decorator'

const { Link } = Typography;

export const Overview = ({ currentProject }: any) => {
  const [t] = useTranslate()
  const { getValue } = useValue()

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Dashboard />
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Row justify="space-between">
          <Col span={15}>
            <StyledCard
              title={t("workOrderDetails.details")}
              style={{ marginBottom: 30 }}
            >
              <Descriptions column={1} bordered>
                <Descriptions.Item label={t("projects.projectName")}>
                  {currentProject?.name}
                </Descriptions.Item>
                <Descriptions.Item label={t("common.description")}>
                  {currentProject?.description}
                </Descriptions.Item>
                <Descriptions.Item label={t("common.category")}>
                  {currentProject?.category}
                </Descriptions.Item>
                {!!currentProject?.tags?.length &&
                  <Descriptions.Item label={t("project.tags")}>{currentProject?.tags?.join(", ")}</Descriptions.Item>}
                {!!currentProject?.lab_locations?.length &&
                  <Descriptions.Item label={t("common.labLocation")}>{currentProject?.lab_locations?.map((location: any) => `${location?.name} (${location?.city}, ${location?.country})`)?.join(", ")}</Descriptions.Item>}
                {currentProject?.start_date &&
                  <Descriptions.Item label={t("workOrderDetails.startDate")}>{new Date(currentProject?.start_date).toLocaleDateString()}</Descriptions.Item>}
                {currentProject?.close_date &&
                  <Descriptions.Item label={t("common.closeDate")}>{new Date(currentProject?.close_date).toLocaleDateString()}</Descriptions.Item>}
                {currentProject?.additional_fields?.map((field: any) => (
                  <Descriptions.Item label={field?.field_name}>{Array.isArray(field?.value) ? field?.value?.join(", ") : (field.field_type === "date" && (field?.value ?? field?.default_value)) ? new Date(field.value).toLocaleDateString() : 
                    <>
                    {getValue(field?.value)}
                    {!!field.value && field.field_type === 'link' &&
                          <Link style={{marginLeft: "10px"}} href={getExternalLink(field.value)} target="_blank" rel="noreferrer">
                            <LinkOutlined />
                          </Link>}
                    </>
                    }</Descriptions.Item>
                ))}
              </Descriptions>
            </StyledCard>
            <Notes currentProject={currentProject.project_id} />
          </Col>
          <Col span={8}>
            <Activity currentProject={currentProject.project_id} />
          </Col>
        </Row>
      </Space>
    </Space>
  );
}
