import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { SuggestedExp as SuggestedExpCatwise } from "../InverseModelNew/catwise/SuggestedExp"

export default function GeneralSuggestExpWrapper({
  selectedObjective,
  experimentsCurrent,
  filters,
  pageChange,
  setFilters,
  useCustom,
  tab,
}: any) {
  const { inverseVersionList } = useSelector(
    (state: StoreState) => state.newInverseModel,
  )

  return <SuggestedExpCatwise
      key={inverseVersionList[0]}
      experimentsCurrent={experimentsCurrent}
      filters={filters}
      pageChange={pageChange}
      setFilters={setFilters}
      selectedObjective={selectedObjective}
      useCustom={useCustom}
      tab={tab}
    />

}
