import {
  Dropdown as AndDropdown,
  Badge,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Team } from "src/typings";
import { Dayjs } from "dayjs";
import { DownOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
import { useEffect, useMemo, useRef, useState } from "react";
import { COLLECTION_ENVIRONTMENT_SUFFIX, realtimeDb } from "src/utils/firebase";
import { off, onValue, ref } from "firebase/database";

export type OnlineUser = Team & { lastSeen: Dayjs };

type P = {
  users: Team[];
  user_id: string;
};

type UserMenuItemProps = {
  user: Team;
};

export const UserMenuItem = ({ user }: UserMenuItemProps) => {
  const [status, setStatus] = useState(false);
  const userRef = useRef(ref(realtimeDb, `${COLLECTION_ENVIRONTMENT_SUFFIX}/users/${user.user_id}`));

  useEffect(() => {
    let subscribe = true
    onValue(userRef.current, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        subscribe && setStatus(data.online);
      }
    });

    return () => {
      subscribe = false;
    };
  }, [user.user_id]);

  useEffect(() => {
    const localRef = userRef.current
    return () => {
      off(localRef);
    };
  }, []);

  return (
    <Space>
      <Tooltip title={status ? "online" : "offline"}>
        <Badge status={status ? "success" : "default"} />
      </Tooltip>
      <Typography.Text
        ellipsis={{ tooltip: user.user_name }}
        style={{ width: 90 }}
      >
        {user.user_name}
      </Typography.Text>
    </Space>
  );
}

export const Dropdown = ({ users, user_id }: P) => {
  const [t] = useTranslate();

  const items = useMemo(() => users
    .filter((user) => user.user_id !== user_id)
    .map((user) => ({
      key: user.user_id,
      label: <UserMenuItem user={user} />
    }))
    , [user_id, users])

  return (
    <AndDropdown trigger={["click"]} menu={{ items }} destroyPopupOnHide>
      <div
        style={{
          cursor: "pointer",
          userSelect: "none",
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <Tooltip title="online">
            <Badge status="success" />
          </Tooltip>
          <span>{t("common.online")}</span>
        </div>
        <DownOutlined />
      </div>
    </AndDropdown>
  );
};
