import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Space,
  Card,
  Drawer,
  Spin,
  Row,
  Popconfirm,
  Typography,
  Collapse,
  Tabs,
  Tooltip,
  message,
} from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'
import { celsureDeleteTrialsRequest, getCelsureTrialsRequest } from 'src/store/actions/celsure'
import { CelsureExperiments } from "../CelsureExperiments/CelsureExperiments"
import { celsureGetPlotsRequest, celsureWoListRequest } from "src/store/actions/celsureWo"
import { InfiniteTable } from "./InfiniteTable"
import { FiltersForm } from "./FiltersForm"
import { LineChartOutlined, LoadingOutlined } from '@ant-design/icons';
import Plot from 'react-plotly.js'
import './InfiniteTable.scss'
import { motion } from 'framer-motion'
import useTranslate from 'src/utils/useTranslate'
import { celsureCompareFormulationsRequest } from 'src/store/actions/celsureCompareFormulation'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader';

const { Text } = Typography

const searchFilters = {
  trial_type: "Trial Type",
  insulation_type: "Insulation Type",
  // payload_material_type: "Payload Material Type",
  payload_material: "Dummy Payload Material"
}


export const Formulations = () => {
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const { current: selectedProject, selectAllProjects } = useSelector((state: StoreState) => state.projects)
  const { celsureDisplayNamesStatus, celsureTrialsList, getCelsureTrialsStatus, celsureDeleteTrialsStatus } = useSelector((state: StoreState) => state.celsure)
  const { celsureWoListStatus, ceslurePlotsData, ceslurePlotsStatus } = useSelector((state: StoreState) => state.celsureWo)
  const { current } = useSelector((state: StoreState) => state.projects)
  const [trialsModalVisible, setTrialsModalVisible] = useState(false)
  const [selectedRecords, setSelectedRecords] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [formulations, setFormulations] = useState<any>({ total_records: 0, data: [] })
  const [sorting, setSorting] = useState<any>({ sort_on: null, sort_order: null })
  const [filterList, setFilterList] = useState<string[]>([])
  const [searchList, setSearchList] = useState<[]>([])
  const [selectedTab, setSelectedTab] = useState(Object.keys(searchFilters)[0])
  const [selectedTags, setSelectedTags] = useState<any[]>([])
  const [deletedFormulations, setDeletedFormulations] = useState<any[]>([])
  const [deletedCount, setDeletedCount] = useState<number>(0)
  const [details, setDetails] = useState<any>()
  const [tab, setTab] = useState("actual")
  const [maximumTempThreshold, setMaximumTempThreshold] = useState<any>()
  const [minimumTempThreshold, setMinimumTempThreshold] = useState<any>()

  useEffect(() => {
    dispatch(celsureWoListRequest({ project_id: current }))
  }, [dispatch, current])

  useEffect(() => {
    if (getCelsureTrialsStatus === AsyncStates.SUCCESS) {
      setFormulations({
        ...celsureTrialsList,
        total_records: celsureTrialsList.total_records - deletedCount,
        data: celsureTrialsList.data.filter((res: any) => !deletedFormulations.includes(res?.trial_id))
      })
    }
    setDeletedCount(0)
    // eslint-disable-next-line
  }, [celsureTrialsList, getCelsureTrialsStatus, deletedFormulations])

  useEffect(() => {
    dispatch(getCelsureTrialsRequest({
      reset: true,
      "page_size": 20,
      filter_list: selectAllProjects ? [] : [`project_id,eq,${selectedProject}`]
    }))
  }, [dispatch, selectAllProjects, selectedProject])

  useEffect(() => {
    if (celsureWoListStatus === AsyncStates.INITIAL) {
      dispatch(celsureWoListRequest({ project_id: current }))
    }
  }, [dispatch, celsureWoListStatus, current])

  useEffect(() => {
    setTab(!!Object.keys(ceslurePlotsData?.[0]?.actual || {}).length ? "actual" : "theoretical")
  }, [details, ceslurePlotsData])

  const modalHandler = (trial: any, event: any) => {
    if (event.target.className !== "ant-checkbox-input") {
      dispatch(celsureCompareFormulationsRequest({ trial_ids: [trial.trial_id], from: "drawer" }))
      dispatch(celsureGetPlotsRequest({ work_order_id: trial.work_order_id, formulation_id: [trial.trial_id] }))
      setTrialsModalVisible(true)
      setDetails(trial)
    }
  }

  const onSelect = (e: any, trial_id: any) => {
    if (e.target.checked) {
      setSelectedRecords([...new Set([...selectedRecords, trial_id])])
    } else {
      setSelectedRecords(selectedRecords.filter((record) => record !== trial_id))
    }
  }
  const compareHandler = () => {
    if (selectedRecords && selectedRecords.length > 1 && selectedRecords.length < 10) {
      dispatch(celsureCompareFormulationsRequest({ trial_ids: selectedRecords, from: "compare" }))
    } else {
      message.error("Please Select between 2-10 trials")
    }
  }

  useEffect(() => {
    if (celsureDeleteTrialsStatus === AsyncStates.SUCCESS) {
      setDeletedFormulations(selectedRecords)
      setDeletedCount(selectedRecords.length)
      setSelectedRecords([])
    }
    // eslint-disable-next-line
  }, [celsureDeleteTrialsStatus])

  useEffect(() => {
    if (selectAll) {
      setSelectedRecords(formulations.data.map((res: any) => res.trial_id))
    }
  }, [selectAll, formulations])

  useEffect(() => {
    setMinimumTempThreshold(details?.threshold_minimum_temperature?.value)
    setMaximumTempThreshold(details?.threshold_maximum_temperature?.value)
  }, [details?.threshold_minimum_temperature?.value, details?.threshold_maximum_temperature?.value, tab])

  const sortRecords = (sort_on: string, sort_order: string) => {
    setSorting({ sort_on, sort_order })
    dispatch(
      getCelsureTrialsRequest({
        filter_list: selectAllProjects ? [...filterList, ...selectedTags.map(tag => selectedTab + ',eq,' + tag)] : [...filterList, ...selectedTags.map(tag => selectedTab + ',eq,' + tag), `project_id,eq,${selectedProject}`],
        ...(searchList.length ? { search_list: searchList } : {}),
        sort_on,
        sort_order,
        reset: true,
        page_size: 20
      }))
  }

  const loadMoreData = useCallback(() => {
    return getCelsureTrialsStatus === AsyncStates.LOADING && formulations.data?.length < formulations.total_records ?
      null :
      Promise.resolve(
        dispatch(
          getCelsureTrialsRequest({
            reset: false,
            previous_id: formulations.previous_id,
            ...(searchList.length ? { search_list: searchList } : {}),
            filter_list: selectAllProjects ? [...filterList, ...selectedTags.map(tag => selectedTab + ',eq,' + tag)] : [...filterList, ...selectedTags.map(tag => selectedTab + ',eq,' + tag), `project_id,eq,${selectedProject}`],
            ...(sorting.sort_order && sorting.sort_on && sorting),
            page_size: 20
          })));
  }, [dispatch, formulations, getCelsureTrialsStatus, sorting, filterList, selectAllProjects, selectedProject, selectedTab, selectedTags, searchList])

  const formulationGraphs = useMemo(() => {
    if (ceslurePlotsStatus === AsyncStates.SUCCESS) {
      return (
        <Plot
          layout={{
            showlegend: true, hovermode: 'closest',
            width: 520,
            height: 800,
            legend: {
              "orientation": "h",
              xanchor: "center",
              y: -0.2,
              x: 0.5
            },
            xaxis: {
              showspikes: true,
              spikemode: 'toaxis',
              title: {
                text: 'Time',
                font: {
                  family: "Courier New, monospace",
                  size: 12,
                  color: "#11111",
                },
              },
            },
            yaxis: {
              showspikes: true,
              spikemode: 'toaxis',
              title: {
                text: 'Temperature / Bath',
                font: {
                  family: "Courier New, monospace",
                  size: 12,
                  color: "#11111",
                },
              },
            },
          }}
          data={[
            {
              x: ceslurePlotsData?.[0]?.[tab]?.["Time"] || ceslurePlotsData?.[0]?.[tab]?.["time"] as any,
              y: ceslurePlotsData?.[0]?.[tab]?.[details?.trial_display_id] || ceslurePlotsData?.[0]?.[tab]?.["trial_data"] as any,
              type: 'scatter',
              name: 'Ambient Temperature',
            },
            ...(Object.keys(ceslurePlotsData?.[0]?.[tab] || {}).filter((res: any) => !["time", "Time", details?.trial_display_id, "trial_data"].includes(res))
              .map((res: any) => ({
                x: ceslurePlotsData?.[0]?.[tab]?.["time"] || ceslurePlotsData?.[0]?.[tab]?.["Time"] as any,
                y: ceslurePlotsData?.[0]?.[tab]?.[res] || [] as any,
                type: 'scatter',
                name: res.toUpperCase(),
              }))),
            ...((!!minimumTempThreshold || !!maximumTempThreshold) ? [
              !!minimumTempThreshold ? {
                x: ceslurePlotsData?.[0]?.[tab]?.["time"] || ceslurePlotsData?.[0]?.[tab]?.["Time"] as any,
                y: (ceslurePlotsData?.[0]?.[tab]?.["time"] || ceslurePlotsData?.[0]?.[tab]?.["Time"])?.map((res: any) => minimumTempThreshold),
                type: 'scatter',
                name: 'Threshold Minimum Temperature',
              } : {},
              !!maximumTempThreshold ? {
                x: ceslurePlotsData?.[0]?.[tab]?.["time"] || ceslurePlotsData?.[0]?.[tab]?.["Time"] as any,
                y: (ceslurePlotsData?.[0]?.[tab]?.["time"] || ceslurePlotsData?.[0]?.[tab]?.["Time"])?.map((res: any) => maximumTempThreshold),
                type: 'scatter',
                name: 'Threshold Maximum Temperature',
              } : {}] as any : []),
          ]}
        />
      )
    } else return null
  }, [ceslurePlotsData, ceslurePlotsStatus, tab, maximumTempThreshold, minimumTempThreshold, details?.trial_display_id])


  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
    >
      <Space direction="vertical" size="large" style={{ width: '100%', overflowX: "auto" }}>
        <StyledPageHeader title={"Celsure Formulations"} ghost={false} />
        <Spin spinning={celsureDisplayNamesStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
          <Card>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <FiltersForm filterList={filterList} setFilterList={setFilterList}
                searchList={searchList} setSearchList={setSearchList}
                selectedTab={selectedTab} setSelectedTab={setSelectedTab}
                selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
              <Row justify="end">
                <Space size="large">
                  {!!selectedRecords?.length && <Text type="secondary" strong>{`${selectedRecords?.length} selected`}</Text>}
                  <Text strong>{`${formulations?.total_records} Results`}</Text>
                  <Tooltip title={((selectedRecords.length < 2 || selectedRecords.length > 10)) ? "Please Select between 2-10 trials" : null}>
                    <StyledButton type="primary" size="middle" icon={<LineChartOutlined />} onClick={compareHandler} disabled={selectedRecords.length < 2}>{t("formulations.button.compareAndGenerateReports")}</StyledButton>
                  </Tooltip>
                  <Popconfirm title={`${t("common.confirmDelete")} ?`} onConfirm={() => dispatch(celsureDeleteTrialsRequest({ trial_ids: selectedRecords }))}>
                    <StyledButton disabled={!selectedRecords?.length} type="primary" danger ghost icon={<StyledDeleteIcon />}>{t("formulations.button.delete")}</StyledButton>
                  </Popconfirm>
                </Space>
              </Row>
              <Spin spinning={getCelsureTrialsStatus === AsyncStates.LOADING || celsureDeleteTrialsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <div className="table-container">
                  <InfiniteTable modalHandler={modalHandler} onSelect={onSelect} loadMoreData={loadMoreData}
                    selectedRecords={selectedRecords} setSelectedRecords={setSelectedRecords} setSelectAll={setSelectAll}
                    formulations={formulations} sortRecords={sortRecords} />
                </div>
              </Spin>
            </Space>
          </Card>
        </Spin>
        <Drawer title={"Celsure Trial details"} width={600} open={trialsModalVisible} footer={null}
          onClose={() => { setDetails({}); setTrialsModalVisible(false) }}>

          <Collapse defaultActiveKey={["trials", "graphs"]}>
            <Collapse.Panel header="Sample" key="trials">
              <Spin spinning={celsureWoListStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <CelsureExperiments from={"formulations"} />
              </Spin>
            </Collapse.Panel>
            {!!ceslurePlotsData.length &&
              <Collapse.Panel header="Graph" key="graphs">
                <Spin spinning={ceslurePlotsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                  <Tabs onChange={(e) => setTab(e)} >
                    {!!(ceslurePlotsData?.find((ele: any) => ele.trial_id === details?.trial_id)?.actual) &&
                      <Tabs.TabPane key="actual" tab="Actual Experiment">
                        <Row justify="center">
                          {formulationGraphs}
                        </Row>
                      </Tabs.TabPane>
                    }
                    {!!(ceslurePlotsData?.find((ele: any) => ele.trial_id === details?.trial_id)?.theoretical) &&
                      <Tabs.TabPane key="theoretical" tab="Simulation">
                        <Row justify="center">
                          {formulationGraphs}
                        </Row>
                      </Tabs.TabPane>}
                  </Tabs>
                </Spin>
              </Collapse.Panel>}
          </Collapse>
        </Drawer>
      </Space>
    </motion.div>
  )
}
