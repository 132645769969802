import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { Space, Select, Row, Col, Input, Empty } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { ChangeEvent, useEffect, useState } from "react";
import { StyledCard } from "src/styled_components/StyledCard";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";

const { Option } = Select


export const CelsureChart = ({ allPlots, plot, setPlots, editMode, celsureWoTrialsList, displayTables }: { setPlots: any, plot: any, allPlots: any, editMode: any, celsureWoTrialsList: any, displayTables: any }) => {
    const { id } = plot
    const [selectedTrials, setSelectedTrials] = useState<any[]>(plot.selectedTrials || [])
    const [title, setTitle] = useState<string>(plot.title || "")
    const [xLabel, setxLabel] = useState<string>(plot.xLabel || '')
    const [yLabel, setyLabel] = useState<string>(plot.yLabel || '')
    const [plotType, setPlotType] = useState<string>("column")
    const [description, setDescription] = useState<string>(plot.description || '')
    const [selectedProperties, setselectedProperties] = useState<any[]>(plot.selectedProperties || [])
    const [plotData, setPlotData] = useState<any[]>(plot.plotData || [])
    const [t] = useTranslate()

    useEffect(() => {
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, plotType: "column", description: '' } : plot))
    }, [id, setPlots])

    const moveCardUp = () => {
        setPlots((prevState: any) => {
            const array = [...prevState]
            const element = array.filter((res: any) => res.id === id)[0]
            array[array.indexOf(element)] = array.splice(array.indexOf(element) - 1, 1, array[array.indexOf(element)])[0]
        })
    }
    const moveCardDown = () => {
        setPlots((prevState: any) => {
            const array = [...prevState]
            const element = array.filter((res: any) => res.id === id)[0]
            array[array.indexOf(element)] = array.splice(array.indexOf(element) + 1, 1, array[array.indexOf(element)])[0]
            return array
        })
    }
    const { series }: any = plotData
    const selectTrailIds = (value: any) => {
        setSelectedTrials(value)
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, selectedTrials: value } : plot))
    }
    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, title: e.target.value } : plot))
    }
    const handleXAxisChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setxLabel(value)
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, XLabel: value } : plot))
    }
    const changeyLabel = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setyLabel(value)
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, yLabel: value } : plot))
    }

    const changeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value
        setDescription(value)
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, description: value } : plot))
    }
    const selectPlotType = (value: string) => {
        setPlotType(value)
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, plotType: value } : plot))
    }
    const selectProperties = (value: any) => {
        setselectedProperties(value);
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, selectedProperties: value } : plot))
    }
    const getName = (name: string) => {
        const displayName = name.split("_").map((propertyName => propertyName[0].toUpperCase() + propertyName.substring(1))).join(" ")
        return displayName
    }

    useEffect(() => {
        let chartData
        if (plotType === "pie") {
            chartData = selectedProperties?.map((nameType: string, index: number) => ({
                name: getName(nameType),
                data: !!selectedTrials?.length ? celsureWoTrialsList?.filter((obj: any) => selectedTrials?.includes(obj?.trial_id))
                    .map((ele: any) => ele[nameType]?.value !== undefined || null ? { name: ele["trial_display_id"], y: Number(ele[nameType]?.value), unit: ele[nameType]?.unit } : null) : [],
                type: plotType,
                unit: !!selectedTrials?.length ? celsureWoTrialsList?.filter((obj: any) => selectedTrials?.includes(obj?.trial_id))
                    .map((ele: any) => ele[nameType]?.unit !== undefined || null ? { name: ele["trial_display_id"], y: ele[nameType]?.unit } : null) : [],
            }))
        } else {
            chartData = selectedProperties?.map((nameType: string, index: number) => ({
                name: getName(nameType),
                data: !!selectedTrials?.length ? celsureWoTrialsList?.filter((obj: any) => selectedTrials?.includes(obj?.trial_id))
                    .map((ele: any) => ele[nameType]?.value !== undefined || null ? Number(ele[nameType]?.value) : null)
                    : celsureWoTrialsList?.flatMap((obj: any) => obj[nameType]?.value) || null,
                yAxis: index,
                type: plotType,
            }))
        }

        const plotData: any = {
            chart: {
                renderTo: "plot" + id,
                height: "50%",
            },
            title: {
                text: title
            },

            xAxis: {
                title: {
                    text: plotType !== "pie" ? xLabel : ''
                },
                categories: celsureWoTrialsList?.filter((ele: any) => selectedTrials?.includes(ele?.trial_id))?.map((ele: any) => ele?.trial_display_id)
            },
            yAxis: {
                title: {
                    text: plotType !== "pie" ? yLabel : '',
                },
            },

            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 0
            },

            series: chartData,
            plotOptions: {
                pie: {
                    showInLegend: true,
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name} <br>{series.name}: {point.y} {point.unit}'
                    }
                }
            }, credits: {
                enabled: false
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 800
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
        }
        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, plotData: plotData } : plot))
        setPlotData(plotData)
    }, [celsureWoTrialsList, plotType, selectedProperties, yLabel, selectedTrials, id, setPlots, title, xLabel]);

    return (
        <StyledCard size="small" type="inner" key={""} style={!editMode ? { display: "none" } : { width: "100%" }}
            extra={[<Space>{allPlots?.indexOf(plot) !== 0 && <StyledButton onClick={moveCardUp} icon={<UpOutlined />} />}
                {allPlots?.indexOf(plot) !== allPlots.length - 1 && <StyledButton onClick={moveCardDown} icon={<DownOutlined />} />}
                <StyledButton danger onClick={() => setPlots((plots: any) => plots.filter(({ id: _id }: any) => _id !== id))} icon={<CloseOutlined />} />
            </Space>]}
        >
            <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
                <Checkbox onChange={(e) => {
                    if (e.target.checked) {
                        setSelectedTrials(celsureWoTrialsList.map(({ trial_id }: any) => trial_id))
                        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, selectedTrials: celsureWoTrialsList.map(({ trial_id }: any) => trial_id) } : plot))
                    } else {
                        setSelectedTrials([])
                        setPlots((plots: any) => plots.map((plot: any) => plot.id === id ? { ...plot, selectedTrials: [] } : plot))
                    }
                }}>{t("compare.selectAllTrials")}</Checkbox>
                <Select size={"large"} value={selectedTrials} onChange={selectTrailIds} style={{ width: "100%" }} mode="multiple" placeholder="Select Trials">
                    {celsureWoTrialsList?.map((element: any, index: any) => (
                        <Option value={element.trial_id} key={index}>{element.trial_display_id}</Option>
                    ))}
                </Select>
                <Select size={"large"} value={selectedProperties} onChange={selectProperties} style={{ width: "100%" }} mode="multiple" placeholder={t("common.selectProperties")}>
                    <Option value={'backup_time'} key={"backup_time"}>{"Backup Time"}</Option>
                </Select>
                <Row gutter={6}>
                    <Col span={plotType !== "pie" ? 6 : 12}>
                        <Input placeholder={t("common.title")} onChange={handleTitleChange} value={title} />
                    </Col>
                    {plotType !== "pie" &&
                        <>
                            <Col span={6}>
                                <Input placeholder={t("chart.placeholder.xAxisLabel")} onChange={handleXAxisChange} value={xLabel} disabled={plotType === "pie"} />
                            </Col>
                            <Col span={6}>
                                <Input placeholder="Y-axis label" value={yLabel} onChange={changeyLabel} disabled={plotType === "pie"} />
                            </Col>

                        </>
                    }

                    <Col span={plotType !== "pie" ? 6 : 12}>
                        <Select defaultValue="column" value={plotType} style={{ width: "100%" }} placeholder="Select graph type" onSelect={selectPlotType}>
                            <Option value="column" key="bar">{t("chartType.Bar")}</Option>
                            <Option value="line">{t("chartType.Line")}</Option>
                            <Option value="scatter">{t("chartType.Scatter")}</Option>
                            <Option value="pie">{t("chartType.pieChart")}</Option>
                        </Select>
                    </Col>
                </Row>

                <Input.TextArea placeholder={t("common.description")} value={description} onChange={changeDescription} />
            </Space>

            {!!selectedTrials.length && !!series.length ? (
                <StyledCard style={{ marginTop: 20 }}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={plotData}
                    />
                </StyledCard>
            ) :
                <Empty description="Select a Property Set" />}
        </StyledCard>
    );
};
