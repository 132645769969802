import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { StoreState } from 'src/store/configureStore';
import useTranslate from 'src/utils/useTranslate';
import styles from './Verify.module.scss'
import { currentPlatform } from 'src/constants';



const verifyURL = `${process.env.REACT_APP_API_URL}v1/data/_verify`
const resendURL = `${process.env.REACT_APP_API_URL}v1/data/_re_verify`

const Verified = Object.freeze({
  Success: 0,
  Failed: 1,
  Pending: 2,
  ResendPending: 3,
  ResendSuccess: 4,
  ResendFailed: 5
})


export const Verify = () => {
  const [t] = useTranslate()
  const history = useHistory()

  const [verified, setVerified] = useState(Verified.Pending)
  const [error, setError] = useState("")
  const userData = useSelector((store: StoreState) => store.login.loginResponse)


  const [{ user_email, otp, user_name }, setState] = useState<{ user_email: string | null, otp: string | null, user_name: string | null }>({ user_email: "", otp: "", user_name: "" })
  const setVerifiyStatus = useCallback(
    (result) => {
      switch (result.status) {
        case 'Success':
          setVerified(Verified.Success)
          break;

        case 'Failed':
          setVerified(Verified.Failed)
          setError(result.message)
          break;

        default:
          setVerified(Verified.Pending)
      }
    },
    [],
  )

  const setResendStatus = useCallback(
    (result) => {
      switch (result.status) {
        case 'Success':
          setVerified(Verified.ResendSuccess)
          break;

        case 'Failed':
          setVerified(Verified.ResendFailed)
          setError(result.message)
          break;

        default:
          setVerified(Verified.ResendPending)
      }
    },
    [],
  )

  useEffect(() => {
    let subscribed = true

    const user_email = new URLSearchParams(window.location.search).get('email')
    const otp = new URLSearchParams(window.location.search).get('otp')
    const user_name = new URLSearchParams(window.location.search).get('user_name')

    subscribed && setState({ user_email, otp, user_name })
    return () => {
      subscribed = false
    }
  }, [])

  const headers: any = useMemo(() => {
    return {
      'Content-Type': 'application/json',
      'platform': currentPlatform
    }
  }, [])

  useEffect(() => {
    let subscribed = true

    user_email && otp && fetch(verifyURL, {
      method: 'POST',
      body: JSON.stringify({ user_email, otp }),
      headers: headers
    }).then(res => res.json())
      .then(({ result }) => subscribed && setVerifiyStatus(result)).catch(err => err)

    return () => {
      subscribed = false
    }
  }, [user_email, otp, setVerifiyStatus, headers])

  const resend = () => {
    setVerified(Verified.ResendPending)
    fetch(resendURL, {
      method: 'POST',
      body: JSON.stringify({ user_email }),
      headers: headers
    }).then(res => res.json()
      .then(({ result }) => setResendStatus(result))).catch(err => err)
  }

  useEffect(() => {
    if (verified === Verified.Success) {
      history.push("/verified", { user_email, user_name })
    }
  }, [verified, history, user_email, user_name])

  useEffect(() => {
    if (userData?.user_verified) {
      history.push("/formulations")
    }
  }, [history, userData?.user_verified])

  return (
    <div className={styles['verify-container']}>
      <div className={styles[`box`]}>
        <h2>{t("verify.accountStatus")}</h2>
        {verified === Verified.Pending && 'Verification Pending...'}
        {verified === Verified.Success && (
          <>
            <div>{t("verify.accountVerifiedSuccessfully")}</div>
            <Link className={styles[`action`]} to={"/login?email=" + user_email}>{t("verify.proceedToLogin")}</Link>
          </>
        )}
        {(verified === Verified.Failed || verified === Verified.ResendPending || verified === Verified.ResendFailed) && (
          <>
            <div>{error ? error : t("verify.verficationFailed")}</div>
            <button className={styles[`action`]} onClick={resend}>{t("verify.resendVerificationEmail")}</button>
            {verified === Verified.ResendPending && <div>{t("common.sending")}</div>}
            {verified === Verified.ResendFailed && <div>{t("verify.resendingFailed")}</div>}
          </>
        )}
        {verified === Verified.ResendSuccess && 'Verification mail resent. Check your mailbox.'}
      </div>
    </div>
  )
};