import { Page, View, Document, Image, Text as PDFText } from "@react-pdf/renderer"
import { Table as PDFTable, } from "react-table-pdf"
import { blue, grey } from "@ant-design/colors"
import { calculateAverageAmbientTemperature } from "../celsureUtils/celsureUtils"


export const CelsureDoc = ({ report, celsureWoTrialsList, company, displayTables }: any) => {
    const { checkedData, tableData, graphs } = report
    let formulations: any = []
    if (checkedData) {
        formulations = celsureWoTrialsList.filter((res: any, index: any) => checkedData[index])
    } else {
        formulations = celsureWoTrialsList.map((res: any) => res)
    }
    const threshold = tableData?.trialsCount > formulations?.length ? (formulations.length > 5 ? 5 : formulations.length) : (tableData?.trialsCount || (formulations.length > 5 ? 5 : formulations.length))
    const size = formulations.length / threshold
    const formulationsTable = []

    const getTableData = (identifier: any, formulation: any) => {
        if (identifier === "payload_type") {
            const { payload_box, type, volume } = formulation[identifier]
            return `Volume:  ${volume?.value || ""} ${volume.unit || ""} 
                        Type:  ${type || ""}
                        Inner:  ${payload_box?.inner?.value?.join(" * ") || ""} ${payload_box?.inner?.unit || ""} 
                        Outer:  ${payload_box?.outer?.value?.join(" * ") || ""} ${payload_box?.outer?.unit || ""} 
                        Thickness:  ${payload_box?.thickness?.value || ""} ${payload_box?.thickness?.unit || ""} `
        }
        else if (identifier === "insulation_box_dimension") {
            return `${formulation[identifier]?.value?.join(" * ")} ${formulation[identifier]?.unit}`

        } else if (["payload_material_weight", "insulation_thickness", "threshold_minimum_temperature",
            "threshold_maximum_temperature", "payload_initial_temperature", "backup_time"].includes(identifier)) {
            return `${formulation[identifier]?.value || ""} ${formulation[identifier]?.unit || ""}`

        }
        else if (["ambient"].includes(identifier)) {
            const avgTemp = calculateAverageAmbientTemperature(formulation.ambient)
            return avgTemp ? `${avgTemp} degC` : null
        }
        else if (["created", "updated", "start_date", "close_date"].includes(identifier)) {
            if (!!formulation?.[identifier]) {
                return new Date(formulation[identifier]).toLocaleString()
            } else return ""

        }
        else if (identifier === "payload_material") {
            return formulation?.[identifier][0]
        }
        else {
            return formulation?.[identifier]
        }
    }

    for (let i = 0; i < size; i++) {
        tableData?.tables?.includes('formulations') && (
            formulationsTable.push(
                <View style={{ width: '100%', padding: 20, textAlign: 'center' }} wrap={false}>
                    {i === 0 && <PDFText style={{ fontSize: '1.25rem', textAlign: 'center', marginBottom: 20 }}>Formulations Sets</PDFText>}
                    <PDFTable
                        columns={[
                            {
                                title: "Fields",
                                dataIndex: "fields",
                            },
                            ...formulations?.slice(i * threshold, (i * threshold) + threshold).flatMap(({ trial_display_id }: any, index: any) => ({
                                title: trial_display_id,
                                dataIndex: trial_display_id
                            })),
                        ]}
                        rowStyle={{ fontSize: '0.5rem', borderTop: 'none' }}
                        headerRowStyle={{ backgroundColor: blue[0], color: blue.primary, border: '1px solid ' + blue.primary, fontSize: '0.5rem', textOverflow: 'clip' }}
                        dataSource={
                            Array.from(new Set(displayTables?.formulations))?.map((ele: any, key: any) => ({
                                fields: ele.fields === "Ambient" ? `Average Ambient Temperature` : ele.fields,
                                ...celsureWoTrialsList.reduce(
                                    (acc: any, formulation: any) => ({
                                        ...acc,
                                        [formulation.trial_display_id]:
                                            getTableData(ele.identifier, formulation) || "-"
                                    }),
                                    {}
                                ),
                            }))
                        }
                    />
                </View>
            )
        )
    }

    return (
        <Document>
            <Page size="A4" orientation="portrait" key={'page_'}>
                <View style={{ position: 'absolute', top: 16, right: 16, height: 60, width: 60, opacity: 0.4 }}>
                    {company?.logo && <Image src={company?.logo} />}
                </View>
                {formulationsTable?.map((res: any) => res)}
                {graphs?.map((plot: any, index: number) => (
                    <View style={{ width: '100%', padding: 50 }} wrap={false}>
                        {!!plot?.dataUrl && <Image src={plot.dataUrl} />}
                        {!!plot?.description && <PDFText style={{ textAlign: "center", fontSize: '0.875rem', color: grey.primary, marginBottom: 50 }}>{plot.description}</PDFText>}
                    </View>
                ))}
            </Page>
        </Document >
    )
}
