import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { Modal, Popconfirm, Space, Tooltip, Typography } from "antd";
import { useState, useEffect } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import { ModelConfig } from "src/typings";
import { TableTransfer } from "./TableTransfer";
import useTranslate from "src/utils/useTranslate";
import starsImg from "../../assets/svgs/stars.svg";

interface ModifyProps {
  parameterList: string[];
  allParameterData: any[];
  handleModify: any;
  aiEngineVersionData?: ModelConfig | undefined;
  linkedTrialIdDisplayName?: { [key: string]: string }
  titles: string[]
  dataset: string
}

export const Modify = ({ parameterList, allParameterData, handleModify, titles, dataset }: ModifyProps) => {
  const [t] = useTranslate()

  const [targetKeys, setTargetKeys] = useState<string[]>(parameterList);
  useEffect(() => { setTargetKeys(parameterList.filter(id => !id.includes(t("workOrderDetails.newParameter")))) }, [parameterList, t])

  const [showModify, setShowModify] = useState(false)

  const [loading, setLoading] = useState(false)
  const [unsortedTargetKeys, setUnsortedTargetKeys] = useState(parameterList)
  const [filters, setFilters] = useState({})
  const [sortOrders, setSortOrders] = useState({})
  useEffect(() => { setUnsortedTargetKeys(parameterList.filter(id => !id.includes(t("workOrderDetails.newParameter")))) }, [parameterList, t])

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
    setUnsortedTargetKeys(nextTargetKeys);
    setSortOrders({})
  };

  const onSort = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
  };

  const changeSortOrders = (sortOrders: {}) => {
    setSortOrders(sortOrders)
  };

  const toggleFilters = (filters: any) => {
    setFilters(filters)
  };

  const onConfirm = async () => {
    setLoading(true);
    setTimeout(() => {
      handleModify(targetKeys)
      setShowModify(false)
      setLoading(false)
    }, 300);
  }

  const onReset = () => {
    setTargetKeys(parameterList)
    changeSortOrders({})
    toggleFilters({})
  }

  const [modifyUpdate, setModifyUpdate] = useState(localStorage.getItem("modifyUpdate") === "false" ? false : true)

  return (<>
    <Tooltip defaultOpen={modifyUpdate} title={modifyUpdate ? <Space size={"large"} align="start" style={{ padding: 8 }}>
      <div><img src={starsImg} alt="stars" /></div>
      <div>
        <Typography.Title level={5} style={{ color: "#fff", paddingBottom: 8 }}>
          New feature update!
        </Typography.Title>
        <Typography.Text style={{ color: "#fff" }}>
          Enhance your experience with our latest update, allowing you to effortlessly add multiple ingredients in bulk and customize orders as you please! Simply click on 'Add/Modify list' to get started.
        </Typography.Text>
        <CloseOutlined onClick={() => { setModifyUpdate(false); localStorage.setItem("modifyUpdate", "false") }} style={{ position: "absolute", right: 16, top: 16 }} />
      </div>
    </Space> : ""
    }
      placement="topLeft"
      open={modifyUpdate}
      overlayStyle={{ width: 500, maxWidth: 500 }}
      onOpenChange={(open) => { !open && localStorage.setItem("modifyUpdate", "false") }}
    >
      <StyledButton
        id="modify-wo-btn"
        size='small'
        onClick={() => { setShowModify(state => !state) }}
        icon={<SettingOutlined />}
      >
        {"Add/Modify List"}
      </StyledButton>
    </Tooltip>
    <Modal
      width={"95%"}
      styles={{ body: { padding: 8 } }}
      style={{ top: 32 }}
      open={showModify}
      onCancel={() => { setShowModify(false); onReset() }}
      title={`${dataset?.[0]?.toUpperCase()}${dataset?.slice(1)} from Inventory`}
      footer={[
        <StyledButton key="back" onClick={onReset}>
          Reset
        </StyledButton>,
        <Popconfirm
          title="Are you sure to modify this work order?"
          onConfirm={onConfirm}
          okText="Confirm"
          showCancel={false}
          placement="topLeft"
        >
          <StyledButton key="submit" type="primary" loading={loading}>
            Modify
          </StyledButton>
        </Popconfirm>
        ,
      ]}
      destroyOnClose
    >
      <TableTransfer
        unsortedTargetKeys={unsortedTargetKeys}
        targetKeys={targetKeys}
        showSearch={true}
        onChange={onChange}
        onSort={onSort}
        filterOption={(inputValue, item) => (item.title && item.title!.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1) || (item.category && item.category?.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()) !== -1)}
        allParameterData={allParameterData}
        sortOrders={sortOrders}
        changeSortOrders={changeSortOrders}
        titles={titles}
        filters={filters}
        toggleFilters={toggleFilters}
      />
    </Modal>
  </>)
}