//App.js File
import { FileExcelOutlined } from "@ant-design/icons";
import { Row, Space, Pagination, Collapse } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates, EXP_PAGE_SIZE } from "src/constants";
import {
  fetchExperimentRequest,
  fetchLinkedExperimentRequest,
} from "src/store/actions/experiment";
import { dataExportRequest, graphsDataHistoryRequest, plotsDataRequest } from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import { getStageName } from "src/utils/decorator";
import { usePermission } from "src/utils/usePermissions";
import useTranslate from "src/utils/useTranslate";
import { WorkOrderTableWrapper } from "../WorkOrderTableWrapper";
import BuildOfMaterials from "src/components/AIEngine/InverseModelNew/catwise/BuildOfMaterials";
import { RawPlots } from "src/components/Datasheet/RawData/RawPlots";
import { RawFileUpload } from "src/components/Datasheet/RawData/RawFileUpload";

const ClosedWoStages = () => {
  const [formulationList, setFormulationlist] = useState<any>([]);
  const dispatch = useDispatch();
  const userAccess = usePermission();
  const [t] = useTranslate();

  const formulations = useSelector(
    (state: StoreState) => state.workOrderDetails.experiment
  );
  const plotsData = useSelector(
    (state: StoreState) => state.workOrderDetails.plotsData
  );
  const dataExportStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.dataExportStatus
  );
  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder
  );
  const [currentExpId, setCurrentExpId] = useState(
    () => workOrder?.experiment_id?.[0]
  );
  const [current, setCurrent] = useState<number>(1);

  const total = useSelector(
    (state: StoreState) => state.workOrderDetails.total
  );

  const getTabList = useCallback(() => {
    return workOrder.stages.map((stage: any, index: number) => ({
      key: workOrder.experiment_id[index],
      tab: getStageName(stage?.name, index, t),
    }));
  }, [workOrder, t]);

  useEffect(() => {
    if (!!workOrder?.work_order_id) {
      dispatch(
        fetchExperimentRequest({
          experiment_id: [workOrder.experiment_id?.[0]],
          page_num: 1,
          page_size: EXP_PAGE_SIZE,
          // stages: ['closed']
        })
      );
    }
  }, [dispatch, workOrder?.experiment_id, workOrder?.work_order_id]);

  useEffect(() => {
    dispatch(plotsDataRequest({ work_order_id: workOrder.work_order_id, experiment_id: currentExpId }))
    dispatch(graphsDataHistoryRequest({ work_order_id: workOrder.work_order_id, experiment_id: currentExpId }))
  }, [dispatch, workOrder.work_order_id, currentExpId])

  useEffect(() => {
    setFormulationlist(formulations.filter((res: any) => res?.use_for_ml));
  }, [formulations]);

  const [openBom, setOpenBom] = useState<boolean>(false)

  const stagesOptions = useMemo(() => {
    const segmentOptions = workOrder?.stages?.map((res: any, index: number) => ({
      value: index + 1,
      label: res.name,
      title: res.name,
      disabled: false,
    }))
    return segmentOptions
  }, [workOrder?.stages])

  return (
    <>
      <Row justify="end" style={{ marginBottom: "1rem", gap: '16px' }}>
        {!!formulationList?.length && (
          <StyledButton
            loading={dataExportStatus === AsyncStates.LOADING}
            onClick={() =>
              dispatch(
                dataExportRequest({
                  work_order_id: workOrder.work_order_id,
                })
              )
            }
            type="primary"
            icon={<FileExcelOutlined />}
          >
            {t("workOrderDetails.exportDatasheet")}
          </StyledButton>
        )}
        {/* {
          workOrder.stages?.length > 1 && (
            <StyledButton onClick={() => setOpenBom(true)} >
              {t("common.viewBuildOfMaterials")}
            </StyledButton>
          )
        } */}
      </Row>
      <StyledCard
        activeTabKey={currentExpId}
        tabList={getTabList()}
        onTabChange={(e) => {
          setCurrentExpId(e);
          setCurrent(1);
          dispatch(
            fetchExperimentRequest({
              experiment_id: [e],
              page_num: 1,
              page_size: EXP_PAGE_SIZE,
            })
          );
          const currentExpIdIndex = workOrder?.experiment_id?.findIndex(
            (id: any) => id === e
          );
          dispatch(
            fetchLinkedExperimentRequest({
              experiment_id: workOrder.experiment_id.slice(
                0,
                currentExpIdIndex
              ),
              access: false,
              linked_stages: true,
            })
          );
        }}
      >
        <Space
          style={{ width: "100%", gap: "10px" }}
          direction="vertical"
          size="large"
        >
          <Row justify="end">
            <Pagination
              total={total}
              current={current}
              pageSize={20}
              showSizeChanger={false}
              onChange={(e) => {
                const currentExpIdIndex = workOrder?.experiment_id?.findIndex(
                  (id: any) => id === currentExpId
                );
                dispatch(
                  fetchExperimentRequest({
                    experiment_id: [
                      workOrder.experiment_id?.[currentExpIdIndex],
                    ],
                    page_num: e,
                    page_size: EXP_PAGE_SIZE,
                  })
                );
                setCurrent(e);
              }}
            />
          </Row>
          <WorkOrderTableWrapper
            disabled={true}
            userAccess={userAccess}
            formulationList={formulationList}
            tableId={"closed_wo_details"}
          />
          {!!Object.keys(plotsData || {}).length &&
            <Collapse defaultActiveKey={["files", "graphs"]}>
              <Collapse.Panel header={"Graphs"} key="graphs">
                <RawPlots currentExpIdIndex={workOrder?.experiment_id?.findIndex((res: any) => res === currentExpId)} componentType={"read_only"} />
              </Collapse.Panel>
              <Collapse.Panel header={"File Upload History"} key="files">
                <RawFileUpload displayIdList={[]} initialTrialSetList={[]} currentExpIdIndex={workOrder?.experiment_id?.findIndex((res: any) => res === currentExpId)} componentType={"read_only"} />
              </Collapse.Panel>
            </Collapse>
          }
        </Space>
      </StyledCard>
      <BuildOfMaterials
        openBom={openBom}
        setOpenBom={setOpenBom}
        stagesOptions={stagesOptions}
        source="workorder"
      />
    </>
  );
};
export default ClosedWoStages;
