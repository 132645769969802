import { useState, useEffect, useMemo, useCallback, useRef } from "react"
import {
  Space,
  Row,
  message,
  Col,
  Collapse,
  Form,
  Table,
  Tag,
  Typography,
  Alert,
  MenuProps,
  TableColumnsType,
  Drawer,
} from "antd";
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import useTranslate, { TranslationKey } from "src/utils/useTranslate"
import { antdTheme, AsyncStates, zeonFixedCategoryList } from "src/constants"
import {
  transposeData,
  removeNulls,
  newColumnData,
  customScrollIntoView,
} from "../../../../utils/decorator"
//import { getClickedDataRequest } from 'src/store/actions/clickedData'
import {
  LoadingOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"
import { StyledButton } from "src/styled_components/StyledButton"
import ShowVariationDetail from "../../InverseResults/ShowVariationDetail"
import { SuggestedExpVisualization } from "./SuggestedExpVisualization"
import { exportPredictionRequest } from "src/store/actions/suggestedExp"
import { RecipeDistribution } from "../../ReciepeDistribution"
import { WorkOrderModal } from "../../ForwardModel/WorkOrderModal"
import { linkedCompareFormulationsRequest } from "src/store/actions/compareFormulations"
import "../styles.scss"
import "./SuggestedExp.scss"
import { AiCosting } from "../../common/AiCosting"
import FilterAndSorting from "./FilterAndSorting"
import TopAction from "../SuggestedExpShared/TopAction"
import ModelDetails from "../SuggestedExpShared/ModelDetails"
import Note from "../../Note"
import InputConstraintsPanel from "../SuggestedExpShared/InputConstraintsPanel"
import { DroppedPropertyWarning } from "../../common/DroppedPropertyWarning"
import { LinkedTrialsAiEngine } from "../../common/LinkedTrialsAiEngine"
import { linkedFormulationsAiEngineRequest } from "src/store/actions/common"
import { useValue } from "src/utils/useValue"
import StyledBadge from "src/styled_components/StyledBadge"
import { ExportDataSheetModal } from "../../common/ExportDataSheetModal";

export const plotFont = {
  family: "Courier New, monospace",
  size: 18,
  color: "#11111",
}

const { Text } = Typography

export const translatedLabel = (
  input: string,
  t: (key: TranslationKey) => string,
) => {
  if (input === "ingredients") return t("common.ingredients")
  if (input === "processing") return t("common.processing")
  if (input === "predicted_properties") return t("common.properties")
  return input
}

type CheckedExperimentsProps = {
  checkAllPages: number[]
  experimentIdList: string[]
}

export const SuggestedExp = ({
  experimentsCurrent,
  pageChange,
  filters,
  setFilters,
  selectedObjective,
  variationDetail,
  zeonCurrentPageInfo,
  key: componentKey,
  tab,
}: any) => {
  const [t] = useTranslate()
  const { getValue, convertValue } = useValue()

  const collator = useMemo(() => new Intl.Collator([], { numeric: true }), [])
  const dispatch = useDispatch()
  const configs = useSelector((state: StoreState) => state.configs.features)
  const {
    expIdStatus,
    data: { experiments, total, inverseMissingProperties = [] },
    predictionDataExportStatus,
    suggestedExpDataForFilterData,
  } = useSelector((state: StoreState) => state.suggestedExp)

  const { configData } = useSelector((state: StoreState) => state.formulate)

  const dataRelatedToRecipeDistribution = useSelector(
    (state: StoreState) => state.insights?.data?.data?.results,
  )
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  )
  const { inverseStatus } = useSelector(
    (state: StoreState) => state.newInverseModel,
  )
  const recipeStatus = useSelector((state: StoreState) => state.insights.status)
  const [clickedExperiments, setClickedExperiments] = useState<any>([])
  const [operatorStates, setOperatorStates] = useState<any[]>([])
  const [form] = Form.useForm()
  const [checkedExperiments, setCheckedExperiments] =
    useState<CheckedExperimentsProps>({
      checkAllPages: [],
      experimentIdList: [],
    })
  const [checkedSelectedExp, setCheckedSelectedExp] = useState<string[]>(
    clickedExperiments.map((res: any) => false),
  )
  const [filtersVersion, setFiltersVersion] = useState<any>()
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)
  const [suggestedExpFilters, setsuggestedExpFilters] = useState<any>({})
  const [selectedRecipeIds, setselectedRecipeIds] = useState<any[]>([])

  const ingredientsLables = useSelector(
    (state: StoreState) => state.displayNames.data?.ingredients ?? {},
  )
  const processingLables = useSelector(
    (state: StoreState) => state.displayNames.data?.processing ?? {},
  )

  const propertiesLables = useSelector(
    (state: StoreState) => state.displayNames.data?.properties ?? {},
  )

  const inputType = useMemo(
    () => ["ingredients", "processing", "predicted_properties"],
    [],
  )
  const linkedFormulationDetailsData = useSelector(
    (state: StoreState) =>
      state.compareFormulations.linkedFormulationDetailsData,
  )
  const [workOrderVisible, setWorkOrderVisible] = useState(false)
  const [expType, setExpType] = useState<any>()
  const [isFilterSortingVisible, setIsFilterSortingVisible] =
    useState<boolean>(false)

  const [exportDataSheetModalData, setExportDataSheetModalData] = useState({
    isModalVisible: false,
    include_range: true
  })

  const tableRef = useRef<any>(null)

  useEffect(() => {
    if (inverseStatus === AsyncStates.SUCCESS) form.resetFields()
  }, [form, inverseStatus])

  useEffect(() => {
    setCheckedExperiments({
      checkAllPages: [],
      experimentIdList: [],
    })
  }, [
    selectedObjective,
    zeonCurrentPageInfo?.variation_id,
    zeonCurrentPageInfo?.version,
    componentKey,
  ])

  useEffect(() => {
    if (
      expIdStatus === AsyncStates.SUCCESS &&
      !!experiments.length &&
      !!suggestedExpDataForFilterData.length
    ) {
      setsuggestedExpFilters(suggestedExpDataForFilterData[0] ?? experiments[0])
    }
  }, [experiments, expIdStatus, suggestedExpDataForFilterData])

  useEffect(() => {
    if (expIdStatus === AsyncStates.SUCCESS) {
      setFiltersVersion(
        configData.find(
          (config: any) => config?.version === experiments?.[0]?.version,
        ),
      )
    }
  }, [configData, experiments, expIdStatus])

  const zeonCategoryList = useMemo(() => {
    const variableCategoryList = Object.values(
      experiments?.[0]?.ingredients || {},
    ).map((res: any) => res?.category)
    return [...new Set([...zeonFixedCategoryList, ...variableCategoryList])]
  }, [experiments])

  const zeonCategorySocketList = useMemo(() => {
    const variableCategoryList = Object.values(
      dataRelatedToRecipeDistribution?.[0]?.ingredients || {},
    ).map((res: any) => res?.category)
    return [...new Set([...zeonFixedCategoryList, ...variableCategoryList])]
  }, [dataRelatedToRecipeDistribution])

  // useEffect(() => {
  // 	if (expIdStatus === AsyncStates.SUCCESS) {
  // 		console.log("running")
  // 		setCheckedExperiments({
  // 			checkAllPages: [],
  // 			predictionIdList: [],
  // 		})
  // 	}
  // }, [experiments, expIdStatus, displayNames])

  const checkChange = useCallback(
    (e: any, i: any, type: any) => {
      if (type === "suggested") {
        if (e.target.checked) {
          setCheckedExperiments((prevState) => {
            const data = [
              ...new Set([...prevState.experimentIdList, e.target.name]),
            ]
            const result = experiments.filter(
              (exp: any) => !data.includes(exp.experiment_id),
            )
            const page = Boolean(configs?.ai_engine_with_methods)
              ? zeonCurrentPageInfo?.currentPage
              : experimentsCurrent
            const checkAllPages =
              result.length % 10 === 0
                ? [...new Set([...prevState.checkAllPages, page])]
                : [...new Set([...prevState.checkAllPages])]

            return {
              checkAllPages: checkAllPages,
              experimentIdList: [
                ...new Set([...prevState.experimentIdList, e.target.name]),
              ],
            } as any
          })
        } else {
          setCheckedExperiments((prev) => {
            return {
              checkAllPages: [
                ...new Set(
                  prev.checkAllPages.filter(
                    (res: any) =>
                      res !==
                      (Boolean(configs?.ai_engine_with_methods)
                        ? zeonCurrentPageInfo?.currentPage
                        : experimentsCurrent),
                  ),
                ),
              ],
              experimentIdList: prev.experimentIdList.filter(
                (res: any) => res !== e.target.name,
              ),
            }
          })
        }
      }
      if ("recipe-suggested") {
        if (e.target.checked) {
          setselectedRecipeIds((prevState) => [
            ...new Set([...prevState, e.target.name]),
          ])
        } else {
          setselectedRecipeIds((prev) =>
            prev.filter((res: any) => res !== e.target.name),
          )
        }
      } else if (type === "suggested_clicked") {
        if (e.target.checked) {
          setCheckedSelectedExp((prevState) => [
            ...new Set([...prevState, e.target.name]),
          ])
        } else {
          setCheckedSelectedExp((prevState: any) => {
            return prevState.filter((res: any) => res !== e.target.name)
          })
        }
      }
    },
    [
      experimentsCurrent,
      zeonCurrentPageInfo?.currentPage,
      configs,
      experiments,
    ],
  )

  const selectAll = useCallback(
    (e: any, type: any) => {
      if (type === "suggested") {
        if (e.target.checked) {
          setCheckedExperiments((prevState) => {
            const data = [
              ...new Set([
                ...prevState.experimentIdList,
                ...experiments.map((res: any) => res.experiment_id),
              ]),
            ]
            return {
              checkAllPages: [
                ...new Set([
                  ...prevState.checkAllPages,
                  Boolean(configs?.ai_engine_with_methods)
                    ? zeonCurrentPageInfo?.currentPage
                    : experimentsCurrent,
                ]),
              ],
              experimentIdList: data,
            } as any
          })
        } else {
          setCheckedExperiments((prev) => {
            const data = prev.experimentIdList.filter(
              (res: any) =>
                !experiments.map((res: any) => res.experiment_id).includes(res),
            )
            return {
              checkAllPages: [
                ...new Set(
                  prev.checkAllPages.filter(
                    (res: any) =>
                      res !==
                      (Boolean(configs?.ai_engine_with_methods)
                        ? zeonCurrentPageInfo?.currentPage
                        : experimentsCurrent),
                  ),
                ),
              ],
              experimentIdList: data,
            }
          })
        }
      } else if (type === "recipe-suggested") {
        setselectedRecipeIds([])
      } else if (type === "suggested_clicked") {
        if (e.target.checked) {
          setCheckedSelectedExp(
            clickedExperiments.map((res: any) => res.experiment_id),
          )
        } else {
          setCheckedSelectedExp([])
        }
      }
    },
    [
      clickedExperiments,
      configs,
      experiments,
      experimentsCurrent,
      zeonCurrentPageInfo?.currentPage,
    ],
  )

  const tableColumns = useMemo(() => {
    return newColumnData(
      experiments,
      checkChange,
      displayNames,
      checkedExperiments,
      "suggested",
      selectAll,
      t,
      Boolean(configs?.ai_engine_with_methods)
        ? zeonCurrentPageInfo?.currentPage
        : experimentsCurrent,
      Boolean(configs?.ai_engine_with_methods),
    )
  }, [
    displayNames,
    experiments,
    selectAll,
    t,
    configs,
    checkChange,
    checkedExperiments,
    experimentsCurrent,
    zeonCurrentPageInfo?.currentPage,
  ])

  const filteredData = useMemo(() => {
    const newData: any = transposeData(
      experiments,
      t,
      getValue,
      zeonCategoryList,
      Boolean(configs?.characterization_methods),
      Boolean(configs?.nestle_configs),
      linkedFormulationDetailsData,
    )
    const removedNulls = removeNulls(newData, "suggested_exp", convertValue)
    return removedNulls.map((res: any, index: number) => ({
      ...res,
      ...(res?.key?.includes("header") ? {} : { key: index }),
    }))
  }, [
    experiments,
    zeonCategoryList,
    configs?.characterization_methods,
    configs?.nestle_configs,
    linkedFormulationDetailsData,
    t,
    getValue,
    convertValue
  ])

  const generateWO = useCallback(
    (type: string, selectAll: boolean = false) => {
      if (type === "suggested") {
        if (selectAll) {
          setIsSelectAll(true)
          setWorkOrderVisible(true)
        } else if (checkedExperiments.experimentIdList.length > 0) {
          // dispatch(includeSuggestedExp({ suggested_experiment_id: checkedExperiments.experimentIdList, version: experiments?.[0]?.version }))
          // dispatch(setPredictionId(experiments?.[0]?.prediction_id))
          // push("/work-orders/details")
          setExpType(checkedExperiments.experimentIdList)
          setWorkOrderVisible(true)
        } else {
          message.warning(t("aiEngine.wo.error"))
        }
      }
      if (type === "recipe-suggested") {
        if (selectedRecipeIds.length > 0) {
          // dispatch(includeSuggestedExp({ suggested_experiment_id: selectedRecipeIds, version: experiments?.[0]?.version }))
          // dispatch(setPredictionId(experiments?.[0]?.prediction_id))
          // push("/work-orders/details")
          setExpType(selectedRecipeIds)
          setWorkOrderVisible(true)
        } else {
          message.warning(t("aiEngine.wo.error"))
        }
      } else if (type === "suggested_clicked") {
        if (checkedSelectedExp.length > 0) {
          // dispatch(includeSuggestedExp({ suggested_experiment_id: checkedSelectedExp, version: experiments?.[0]?.version }))
          // dispatch(setPredictionId(clickedExperiments?.[0]?.prediction_id))
          // push("/work-orders/details")
          setExpType(checkedSelectedExp)
          setWorkOrderVisible(true)
        } else {
          message.warning(t("aiEngine.wo.error"))
        }
      }
    },
    [
      checkedExperiments.experimentIdList,
      checkedSelectedExp,
      selectedRecipeIds,
      t,
    ],
  )

  const clearExperiments = () => {
    setCheckedSelectedExp([])
    setClickedExperiments([])
  }

  const applyFilters = ({
    expFilters,
    sorting,
    isCleared = false,
  }: {
    expFilters: any
    sorting: any
    isCleared?: boolean
  }) => {
    if (!expFilters?.length && !sorting?.length && isCleared) {
      pageChange(1, "experiments")
      setFilters([])
      return
    }

    if (!expFilters?.length && !sorting?.length) {
      message.error(t("aiEngine.filters.error"))
    } else {
      let filtersPayload = []
      let sortingPayload = []
      if (!!expFilters?.length) {
        filtersPayload = expFilters
          .filter((item: any) => !!item)
          .reduce(
            (array: any, res: any) => [
              ...array,
              {
                category: res.property[0],
                field: res.property[1],
                operand: res.operand,
                minVal: String(convertValue(res.minVal)),
                ...(res?.maxVal && { maxVal: String(convertValue(res.maxVal)) }),
              },
            ],
            [],
          )
      }
      if (!!sorting?.length) {
        sortingPayload = sorting
          .filter((item: any) => !!item)
          .reduce(
            (array: any, res: any) => [
              ...array,
              {
                category: res.property[0],
                field: res.property[1],
                sort_order: res.sort_order,
              },
            ],
            [],
          )
      }
      const payload = { expFilters: filtersPayload, sorts: sortingPayload }
      setFilters(payload)
      clearExperiments()
      pageChange(1, "experiments", payload)
    }
  }

  const modelConfigData = useSelector((state: StoreState) => state.formulate.modelConfigData);


  const filterData: any = useMemo(() => {
    return inputType
      .map((parameter) => {
        if (
          Object.keys(suggestedExpFilters?.results_range || {}).includes(
            parameter,
          ) &&
          Object.keys(suggestedExpFilters?.[parameter] || {}).length > 0
        ) {
          return {
            value: parameter,
            label: translatedLabel(parameter, t),
            children: Object.keys(
              suggestedExpFilters?.results_range?.[parameter] || {},
            ).map((res: any) => ({
              value: res,
              label:
                suggestedExpFilters?.results_range?.[parameter]?.[res]
                  ?.name ?? modelConfigData?.[0]?.display_names?.[parameter]?.[res] ??
                ingredientsLables?.[res]?.name ??
                processingLables?.[res]?.name ??
                propertiesLables?.[res]?.name ??
                res,
            }))
          }
        } else {
          return null
        }
      })
      .filter((res: any) => res)
  }, [inputType, suggestedExpFilters, t, modelConfigData, ingredientsLables, processingLables, propertiesLables])

  useEffect(() => {
    form.resetFields()
  }, [selectedObjective, form])

  const updateSelectedExperiments = (e: any, selectedId: string) => {
    e.preventDefault()
    setCheckedExperiments((prev) => {
      const filteredList = prev.experimentIdList.filter(
        (id) => id !== selectedId,
      )
      const checkAllPages = filteredList.length === 0 ? [] : prev.checkAllPages
      return {
        checkAllPages: checkAllPages,
        experimentIdList: filteredList,
      }
    })
  }

  const clearAllSelectedExperiment = () => {
    setCheckedExperiments({
      checkAllPages: [],
      experimentIdList: [],
    })
  }

  const exportDataSheet = () => {
    dispatch(
      exportPredictionRequest({
        prediction_id: experiments?.[0]?.prediction_id,
        ...(!!checkedExperiments?.experimentIdList?.length && {
          suggested_experiment_ids: checkedExperiments.experimentIdList,
        }),
        type: "inverse",
        filters: filters.expFilters,
        sorts: filters.sorts,
        version: filtersVersion?.version,
        include_range: exportDataSheetModalData.include_range
      }),
    )
  }

  const tableColumnsVis = useMemo(() => {
    const exp = dataRelatedToRecipeDistribution?.filter(
      ({ experiment_id }: any) => selectedRecipeIds.includes(experiment_id),
    )
    return newColumnData(
      exp,
      checkChange,
      displayNames,
      selectedRecipeIds,
      "recipe-suggested",
      selectAll,
      t,
      Boolean(configs?.ai_engine_with_methods)
        ? zeonCurrentPageInfo?.currentPage
        : experimentsCurrent,
      Boolean(configs?.ai_engine_with_methods),
    )
  }, [
    displayNames,
    selectAll,
    t,
    configs,
    checkChange,
    experimentsCurrent,
    zeonCurrentPageInfo?.currentPage,
    selectedRecipeIds,
    dataRelatedToRecipeDistribution,
  ])

  const filteredDataVis = useMemo(() => {
    const exp = dataRelatedToRecipeDistribution?.filter(
      ({ experiment_id }: any) => selectedRecipeIds.includes(experiment_id),
    )
    const newData: any = transposeData(
      exp,
      t,
      getValue,
      zeonCategorySocketList,
      Boolean(configs?.characterization_methods),
    )
    const removedNulls = removeNulls(newData, "suggested_exp", convertValue)
    return removedNulls
  }, [
    dataRelatedToRecipeDistribution,
    t,
    zeonCategorySocketList,
    configs?.characterization_methods,
    selectedRecipeIds,
    getValue,
    convertValue
  ])

  useEffect(() => {
    if (tab === "formulation_prediction") {
      const linkedFormulations = experiments?.flatMap((exp: any) =>
        Object.entries(exp.ingredients || {})
          .filter(([key, values]: [any, any]) => values?.type === "trials")
          .map(([key, values]: [any, any]) => key),
      )
      const uniqueLinkedFormulationIds = [...new Set(linkedFormulations)]
      if (!!uniqueLinkedFormulationIds.length) {
        dispatch(
          linkedCompareFormulationsRequest({
            formulation_id: uniqueLinkedFormulationIds,
            is_nested: true,
          }),
        )
      }
    }
  }, [experiments, dispatch, tab])

  const generateWoMenuItems: MenuProps = useMemo(() => {
    return {
      items: [
        {
          key: "generate-wo-selected-trials",
          label: t("aiEngine.generateWorkOrder"),
          disabled: expIdStatus === AsyncStates.LOADING,
        },
        {
          key: "generate-wo-all-trials",
          label: t("aiEngine.generateWorkOrderAll"),
          disabled: expIdStatus === AsyncStates.LOADING,
        },
      ],
      onClick: ({ key }: any) => {
        generateWO("suggested", key === "generate-wo-all-trials")
      },
    }
  }, [expIdStatus, generateWO, t])

  const objectiveConstraints = useCallback(() => {
    const columns: TableColumnsType<any> = [
      { title: t("common.objective"), dataIndex: "objective", key: "objective" },
      // {
      //   title: "Historical Experiment Range",
      //   dataIndex: "range",
      //   key: "range",
      // },
      {
        title: t("aiEngine.optimizationType"),
        key: "optimization_type",
        dataIndex: "optimization_type",
      },
      { title: t("common.min"), dataIndex: "min", key: "min" },
      { title: t("common.max"), dataIndex: "max", key: "max" },
      {
        title: t("common.priority"),
        dataIndex: "priority",
        key: "priority",
      },
    ]

    const data = []
    const objectives = experiments[0]?.objectives ?? {}
    const priority = suggestedExpFilters?.priority_list ?? {}

    for (let obj of Object.keys(objectives)) {
      const actualObjective = objectives?.[obj]
      data.push({
        key: obj,
        objective:
          displayNames?.properties?.[obj]?.name ||
          obj
            .split("_")
            .map((value: string) => value[0].toUpperCase() + value.slice(1))
            .join(" "),
        stage: objectives[obj]?.stage,
        range: objectives[obj]?.range,
        optimization_type:
          typeof actualObjective === "object" &&
            !Array.isArray(actualObjective) &&
            actualObjective !== null
            ? t("common.range")
            : `${actualObjective[0].toUpperCase()}${actualObjective.slice(1)}`,
        min: getValue(objectives[obj]?.min),
        max: getValue(objectives[obj]?.max),
        priority: priority[obj],
      })
    }
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className="constraints-table"
        scroll={{ y: 300 }}
      />
    )
  }, [
    displayNames?.properties,
    experiments,
    suggestedExpFilters?.priority_list,
    t,
    getValue
  ])

  const baseInputConstraints = useCallback(() => {
    const columns = [
      {
        key: "input",
        title: t("common.inputs"),
        dataIndex: "input",
      },
      {
        key: "range",
        title: t("common.range"),
        dataIndex: "range",
      },
    ]

    const data = Object.entries(suggestedExpFilters?.input_constraints ?? {})
      .sort((a, b) => collator.compare(a[0], b[0]))
      .map(([key, value]: [string, any]) => ({
        input:
          suggestedExpFilters?.ingredients?.[key]?.name ||
          displayNames?.ingredients?.[key]?.name ||
          displayNames?.processing?.[key]?.name ||
          key,
        range: Array.isArray(value)
          ? `(${value.reduce(
            (string: string, element: string) => element + ", " + string,
          )})`
          : `(${getValue(value?.min)} - ${getValue(
            value?.max
          )})`,
      }))

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 300 }}
        className="constraints-table"
      />
    )
  }, [
    collator,
    displayNames?.ingredients,
    displayNames?.processing,
    suggestedExpFilters?.ingredients,
    suggestedExpFilters?.input_constraints,
    t,
    getValue
  ])

  // const inverseConfigs = useCallback(() => {
  //   const inverseConfigData = suggestedExpFilters?.inverse_configs || {}
  //   const columns = [
  //     {
  //       title: t("common.parameters"),
  //       dataIndex: "parameter",
  //       key: "parameter",
  //     },
  //     {
  //       title: t("common.values"),
  //       dataIndex: "values",
  //       key: "values",
  //     },
  //   ]

  //   const data: any[] = Object.keys(inverseConfigData || {}).map((property: any, index: any) => {
  //     return {
  //       key: property,
  //       parameter: inverseConfigData[property]?.name,
  //       values: inverseConfigData[property]?.values,
  //     }
  //   })

  //   return <Table
  //     columns={columns}
  //     dataSource={data}
  //     pagination={false}
  //     scroll={{ y: 300 }}
  //     className="constraints-table"
  //   />
  // }, [suggestedExpFilters?.inverse_configs, t])

  const isCompleted = useMemo(() => {
    return suggestedExpFilters?.status?.toLowerCase() === "completed"
  }, [suggestedExpFilters])

  const constraintsItems = useMemo(() => {
    return [...(Object.keys(suggestedExpFilters?.input_constraints || {}).length
      ? [
        {
          key: "base-input-constraints",
          label: t("common.baseInputConstraints"),
          children: baseInputConstraints(),
        },
      ]
      : []),
    ]
  }, [baseInputConstraints, suggestedExpFilters?.input_constraints, t])




  const objectivesAndOtherItems = useMemo(() => {
    // const options = suggestedExpFilters?.all_stages
    return [
      {
        key: "objectives",
        label: t("common.objectives"),
        children: objectiveConstraints(),
      },
      // {
      //   key: "inverse-config",
      //   label: t("aiEngine.configurations"),
      //   children: inverseConfigs(),
      // },
    ]
  }, [objectiveConstraints, t])

  const { linkedFormulationData } = useSelector((state: StoreState) => state.common)


  return (
    <>
      <Space
        size="middle"
        direction="vertical"
        style={{ width: "100%" }}
        id="inversepred-results"
      >
        {/* Top action */}
        {isCompleted && (
          <TopAction
            generateWoMenuItems={generateWoMenuItems}
            predictionDataExportStatus={predictionDataExportStatus}
            setExportDataSheetModalData={setExportDataSheetModalData}
          />
        )}

        {
          exportDataSheetModalData.isModalVisible &&
          <ExportDataSheetModal exportDataSheetModalData={exportDataSheetModalData} setExportDataSheetModalData={setExportDataSheetModalData} loadingState={predictionDataExportStatus} onSubmit={exportDataSheet} from="inverse-prediction" />
        }

        {/* Model detials */}
        <ModelDetails data={suggestedExpFilters} />

        {/* Objectives and other */}
        <InputConstraintsPanel
          items={objectivesAndOtherItems}
          title={`${t("aiEngine.objectives")} ${Object.keys(suggestedExpFilters?.objectives || {}).length ? `(${Object.keys(suggestedExpFilters?.objectives || {}).length})` : ``}`}
          defaultOpen={true}
        />

        {/* Variation details */}
        {variationDetail && (
          <ShowVariationDetail variationDetail={variationDetail} />
        )}

        {/* Note about '-' */}
        {isCompleted && (
          <Note
            content={
              <>
                {t("predictedValue.note")}
                <a
                  href="mailto:contact@polymerize.io"
                  style={{ color: "#1677ff", display: "inline" }}
                >
                  {"contact@polymerize.io"}
                </a>
              </>
            }
            icon={<InfoCircleOutlined />}
          />
        )}

        {(filteredData?.length > 0 && !!inverseMissingProperties?.length) && <DroppedPropertyWarning missingProperties={inverseMissingProperties} />}

        {/* Input Costraints */}
        <InputConstraintsPanel
          items={constraintsItems}
          title={`${t("aiEngine.inputConstraints")}`}
        />

        {isCompleted && (
          <>
            {/* Checked exp listing */}
            <Drawer
              open={checkedExperiments.experimentIdList.length > 0}
              placement="bottom"
              style={{
                background: "#262626",
              }}
              height={125}
              mask={false}
              maskClosable={false}
              closeIcon={false}
              title={
                <>
                  <Space direction="vertical" size={"large"}>
                    <Typography.Title
                      level={5}
                      style={{ margin: 0, color: "#fff" }}
                    >{`${checkedExperiments.experimentIdList.length} ${t(
                      "common.trials",
                    )} ${t("formulations.selected")}`}</Typography.Title>
                    <div
                      style={{
                        display: "flex",
                        overflowX: "auto",
                      }}
                    >
                      {checkedExperiments.experimentIdList.map(
                        (experimentId, index) => {
                          const idx = experiments.findIndex(
                            (exp: any) =>
                              exp?.experiment_id === experimentId,
                          )
                          return (
                            <Tag
                              closable
                              onClose={(e) =>
                                updateSelectedExperiments(e, experimentId)
                              }
                              style={{
                                background: "#262626",
                                borderColor: "#fff",
                                color: "#fff",
                              }}
                              closeIcon={
                                <CloseOutlined
                                  style={{
                                    fontSize: antdTheme.fontSize,
                                    color: "#fff",
                                  }}
                                />
                              }
                              key={experimentId ?? idx}
                            >
                              <Typography.Title
                                level={5}
                                style={{ color: "#fff" }}
                              >{`Trial ${(Boolean(configs?.ai_engine_with_methods)
                                ? zeonCurrentPageInfo?.currentPage
                                : experimentsCurrent - 1) *
                                10 +
                                idx +
                                1
                                }`}</Typography.Title>
                            </Tag>
                          )
                        },
                      )}
                    </div>
                  </Space>
                  <div
                    style={{
                      position: "absolute",
                      top: "-12%",
                      left: "50%",
                    }}
                  >
                    <StyledButton
                      onClick={() => clearAllSelectedExperiment()}
                      style={{
                        background: "#262626",
                        color: "#fff",
                        borderColor: "#fff",
                      }}
                    >
                      <Space>
                        <CloseOutlined />
                        {t("common.clear")}
                      </Space>
                    </StyledButton>
                  </div>
                </>
              }
              styles={{
                body: {
                  display: "none",
                },
                header: {
                  padding: 24,
                },
              }}
            />

            {/* Suggested Experiment Table */}
            <Table
              ref={tableRef}
              dataSource={filteredData}
              columns={tableColumns}
              bordered={false}
              className="suggested-exp-result-table"
              scroll={{ x: 400, y: 800 }}
              title={() => (
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Row justify="space-between">
                    <Typography.Title level={5}>
                      {`${total} ${t("common.trials")}`}
                    </Typography.Title>
                    <Col>
                      <StyledBadge
                        dot={
                          filters?.expFilters?.length > 0 ||
                          filters?.sorts?.length > 0
                        }
                        style={{ marginRight: 5 }}
                      >
                        <StyledButton
                          onClick={() =>
                            setIsFilterSortingVisible(
                              (prevState) => !prevState,
                            )
                          }
                          className={
                            isFilterSortingVisible
                              ? "action-button-active"
                              : ""
                          }
                          style={{ marginRight: 5 }}
                        >
                          {t("aiEngine.filters.title")}
                        </StyledButton>
                      </StyledBadge>
                    </Col>
                  </Row>

                  <Alert
                    description={
                      <FilterAndSorting
                        form={form}
                        applyFilters={applyFilters}
                        filterData={filterData}
                        operatorStates={operatorStates}
                        setOperatorStates={setOperatorStates}
                        filters={filters}
                        setFilters={setFilters}
                        pageChange={pageChange}
                      />
                    }
                    type="info"
                    message={
                      <Row justify={"space-between"}>
                        <Typography.Title
                          level={5}
                        >
                          {t("aiEngine.filters.title")}
                        </Typography.Title>
                        <CloseOutlined
                          onClick={() => setIsFilterSortingVisible(false)}
                          style={{ outline: "none" }}
                        />
                      </Row>
                    }
                    style={{
                      border: "none",
                      padding: "16px",
                      backgroundColor: "#FAFAFA",
                      display: isFilterSortingVisible ? "flex" : "none",
                    }}
                  />
                </Space>
              )}
              pagination={{
                pageSize: filteredData?.length,
                current: Boolean(configs?.ai_engine_with_methods)
                  ? zeonCurrentPageInfo?.currentPage
                  : experimentsCurrent,
                showSizeChanger: false,
                responsive: true,
                total: Math.ceil((filteredData?.length * total) / 10),
                onChange: (e) => {
                  pageChange(e, "experiments", filters)
                  customScrollIntoView(tableRef, { behavior: "smooth" })
                },
                position: ["topRight"],
              }}
              loading={{
                spinning: expIdStatus === AsyncStates.LOADING,
                indicator: <LoadingOutlined />,
              }}
              expandable={{
                expandedRowRender: (record) => {
                  return <LinkedTrialsAiEngine record={record} experiments={experiments}
                    linkedFormulationDetailsData={linkedFormulationData} from={"inverse"} pageNumber={experimentsCurrent} />
                },
                rowExpandable: (record) => {
                  return !!record?.linked_trial
                },
                onExpand: (expanded, record) => {
                  if (expanded && !!record?.linked_trial && !linkedFormulationData?.[record?.linked_trial]) {
                    const payload = {
                      formulation_id: [record?.linked_trial], is_nested: true
                    }
                    dispatch(linkedFormulationsAiEngineRequest(payload))
                  }
                }
              }}
            />

            {/* Costing Panel */}
            {!!experiments?.length &&
              Boolean(configs?.work_order_costing) && (
                <Collapse>
                  <Collapse.Panel
                    header={<Text strong>{"Costing"}</Text>}
                    key={"1"}
                  >
                    <AiCosting experiments={experiments} />
                  </Collapse.Panel>
                </Collapse>
              )}

            {/* Suggested Experiment Visualization */}
            <SuggestedExpVisualization
              setClickedExperiments={setClickedExperiments}
              clickedExperiments={clickedExperiments}
              filtersVersion={filtersVersion}
              generateWO={generateWO}
              clearExperiments={clearExperiments}
              checkedSelectedExp={checkedSelectedExp}
              checkChange={checkChange}
              selectAll={selectAll}
              zeonCurrentPageInfo={zeonCurrentPageInfo}
              experimentsCurrent={experimentsCurrent}
              suggestedExpFilters={suggestedExpFilters}
              selectedObjective={selectedObjective}
              zeonCategoryList={zeonCategoryList}
            />

            {/* Recipe Distribution */}
            {!Boolean(configs?.characterization_methods) && (
              <RecipeDistribution
                recipeDistribution="inverse-prediction"
                tableColumnsVis={tableColumnsVis}
                filteredDataVis={filteredDataVis}
                generateWO={generateWO}
                selectedRecipeIds={selectedRecipeIds}
                setselectedRecipeIds={setselectedRecipeIds}
                experiments={dataRelatedToRecipeDistribution}
                expStatus={expIdStatus}
                status={recipeStatus === AsyncStates.LOADING}
              />
            )}

            {/* Workorder Modal */}
            <WorkOrderModal
              workOrderVisible={workOrderVisible}
              setWorkOrderVisible={setWorkOrderVisible}
              checkedExperiments={expType}
              experiments={experiments}
              type={"inverse"}
              isSelectAll={isSelectAll}
            />

            {/* Spacing equal to Exp Listing drawer so we can see content properly */}
            <div
              style={{
                paddingBottom:
                  checkedExperiments.experimentIdList.length > 0 ? 125 : 10,
              }}
            ></div>
          </>
        )}
      </Space>
    </>
  )
}
