import { CalendarOutlined, DownOutlined, FilterOutlined, RightOutlined } from "@ant-design/icons";
import {
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Avatar,
  Tag,
  Tooltip,
  Input,
  DatePicker,
  Select,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
// import styles from './HorizontalStages.module.scss'
import dayjs from "dayjs";
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { editWorkOrderRequest } from "src/store/actions/workOrderDetails";
import Title from "antd/lib/typography/Title";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { volcano } from "@ant-design/colors";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useMemberName } from "src/utils/useMemberName";
import { StyledCard } from "src/styled_components/StyledCard";
import { usePermission } from "src/utils/usePermissions";
import { permissions, projectStatus } from "src/constants";
import { trackEvent } from "src/analytics";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { StyledButton } from "src/styled_components/StyledButton";

interface StagesProps {
  data: any[];
  callbacks: any;
}

interface StagesState {
  columnOrder: any[];
  orders: { [key: string]: any };
  columns: { [key: string]: any };
}

const dateFormat = "YYYY-MM-DD";

export const HorizontalStages = ({ data, callbacks }: StagesProps) => {
  const [t] = useTranslate();
  const {
    showDetails,
    handleDelete,
    filterByTitle,
    filterByDateRange,
    filterByMember,
  } = callbacks;
  const dispatch = useDispatch();
  const { getName } = useMemberName();

  const teamsData = useSelector((state: StoreState) => state.teams.data || []);
  const stagesData = useSelector(
    (state: StoreState) => state.displayNames.data?.stages || {}
  );
  const { user_id, user_role } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );
  const { projectList, current } = useSelector(
    (state: StoreState) => state.projects
  );
  const woList = useSelector((state: StoreState) => state.workOrders.data);
  const currentProject = useMemo(
    () => projectList.find((project: any) => project.project_id === current),
    [projectList, current]
  );
  const stages: any = useMemo(() => {
    if (user_role === "admin" || currentProject?.created_by === user_id) {
      return stagesData;
    } else {
      const newStages: any = {};
      for (let i in stagesData) {
        if (
          currentProject?.members
            ?.find((member: any) => member.user_id === user_id)
            .stages.includes(i)
        ) {
          newStages[i] = stagesData[i];
        }
      }
      return newStages;
    }
  }, [user_id, user_role, stagesData, currentProject]);
  const userAccess = usePermission();

  const activeTabList = useMemo(
    () =>
      Object.entries(stages).map(([key, tab]) => ({ key, tab: String(tab) })),
    [stages]
  );
  const [board, setBoard] = useState<StagesState>();
  const [userFilter, setUserFilter] = useState<any>({});

  useEffect(() => {
    let subscribed = true;
    subscribed &&
      setUserFilter(
        Object.keys(stages).reduce(
          (acc, stage) => ({ ...acc, [stage]: "" }),
          {}
        )
      );
    return () => {
      subscribed = false;
    };
  }, [stages]);

  const { orders, columns }: any = board || { orders: {}, columns: {} };

  useEffect(() => {
    setBoard((state) =>
      !Object.keys(state?.orders || {}).length
        ? {
          ...(state || {}),
          orders: data.reduce(
            (acc, cur: any) => ({ ...acc, [cur.work_order_id]: cur }),
            {}
          ),
          columns: Object.entries(stages).reduce(
            (acc, [key, name]: any) => ({
              ...acc,
              [key]: {
                id: key,
                title: name,
                orderIds: data
                  .filter(
                    (order: any) =>
                      order.work_order_stage === key &&
                      (order.work_order_dri?.length
                        ? order.work_order_dri?.some((memberId: string) =>
                          userFilter[key]
                            ? userFilter[key] === memberId
                            : true
                        )
                        : userFilter[key]
                          ? false
                          : true)
                  )
                  .sort(
                    (prev: any, next: any) =>
                      Number(prev.work_order_seq) -
                      Number(next.work_order_seq)
                  )
                  .map((order: any) => order.work_order_id),
              },
            }),
            {}
          ),
          columnOrder: Object.entries(stages).map(([key, name]) => ({
            key,
            name,
          })),
        }
        : {
          ...(state || {}),
          orders: Object.values(state?.orders || {}).reduce(
            (acc: any, cur: any) => ({ ...acc, [cur.work_order_id]: cur }),
            {}
          ),
          columns: Object.entries(stages).reduce(
            (acc, [key, name]: any) => ({
              ...acc,
              [key]: {
                id: key,
                title: name,
                orderIds: Object.values(state?.orders || {})
                  .filter(
                    (order: any) =>
                      order.work_order_stage === key &&
                      (order.work_order_dri?.length
                        ? order.work_order_dri?.some((memberId: string) =>
                          userFilter[key]
                            ? userFilter[key] === memberId
                            : true
                        )
                        : userFilter[key]
                          ? false
                          : true)
                  )
                  .sort(
                    (prev: any, next: any) =>
                      Number(prev.work_order_seq) -
                      Number(next.work_order_seq)
                  )
                  .map((order: any) => order.work_order_id),
              },
            }),
            {}
          ),
          columnOrder: Object.entries(stages).map(([key, name]) => ({
            key,
            name,
          })),
        }
    );
  }, [data, userFilter, stages]);

  useEffect(() => {
    return () => {
      setBoard(undefined);
    };
  }, [data]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const workOrder = woList.find(
      (res: any) => res.work_order_id === draggableId
    );
    dispatch(
      editWorkOrderRequest({
        project_id: current,
        work_order_id: draggableId,
        data: {
          work_order_seq: destination.index,
          work_order_parent_stage: [
            "work_order_initiated",
            "report_preview",
            "work_order_status",
          ].includes(destination.droppableId)
            ? destination.droppableId
            : [
              "work_order_initiated",
              "report_preview",
              "work_order_status",
            ].includes(workOrder.work_order_parent_stage)
              ? workOrder?.stages?.[0]?.identifier
              : workOrder.work_order_parent_stage,
          work_order_stage: destination.droppableId,
        },
        updateDisplayNames: true
      })
    );

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    if (start === finish) {
      const newOrderIds = Array.from(start.orderIds);
      newOrderIds.splice(source.index, 1);
      newOrderIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        orderIds: newOrderIds,
      };

      const newBoard = {
        ...board,
        columns: {
          ...columns,
          [newColumn.id]: newColumn,
        },
      };

      setBoard(newBoard as StagesState);
      return;
    }

    const startOrderIds = Array.from(start.orderIds);
    startOrderIds.splice(source.index, 1);
    const newStart = {
      ...start,
      orderIds: startOrderIds,
    };

    const finishOrderIds = Array.from(finish.orderIds);
    finishOrderIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      orderIds: finishOrderIds,
    };
    const newBoard = {
      ...board,
      columns: {
        ...columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
      orders: {
        ...orders,
        [draggableId]: {
          ...orders[draggableId],
          work_order_stage: destination.droppableId,
        },
      },
    };
    if (user_id && newFinish && draggableId)
      trackEvent(
        user_id,
        "Work Order was dragged into " + newFinish.title + " stage",
        { "WO details": { newFinish, draggableId } }
      );

    setBoard(newBoard as StagesState);
  };

  const [filterCollapse, setfilterCollapse] = useState(true);

  const memberOptions = useMemo(
    () =>
      currentProject?.members
        ?.map((member: any) => member?.user_id)
        ?.concat(currentProject?.created_by)
        ?.map((res: any) => ({ label: getName(res), value: res })),
    [currentProject, getName]
  );
  const stageMembers = useCallback(
    (key) =>
      teamsData.filter((team: any) =>
        currentProject?.members
          ?.filter((member: any) => member?.stages?.includes(key))
          ?.map((res: any) => res?.user_id)
          ?.concat(currentProject?.created_by)
          .includes(team.user_id)
      ),
    [currentProject, teamsData]
  );

  return (
    <StyledCard
      bodyStyle={{ padding: "8px 16px" }}
      headStyle={{ padding: "8px 16px" }}
      title={
        <Space
          direction="vertical"
          style={{ width: "100%", padding: "12px !important" }}
        >
          <Row justify="end" gutter={8}>
            <Col flex={1}>
              <Input.Search
                placeholder={t("workOrders.placeholder.searchWorkOrder")}
                onChange={filterByTitle}
                allowClear
                enterButton
              />
            </Col>
            <Col>
              <StyledButton
                icon={<FilterOutlined />}
                onClick={() => setfilterCollapse((state) => !state)}
              >
                {t("common.filter")}{" "}
                {filterCollapse ? <DownOutlined /> : <RightOutlined />}
              </StyledButton>
            </Col>
          </Row>
          {!filterCollapse && (
            <Row gutter={8}>
              <Col>
                <DatePicker.RangePicker
                  style={{ width: 325 }}
                  placeholder={["From Creation Date", "To Creation Date"]}
                  allowEmpty={[true, true]}
                  onCalendarChange={filterByDateRange}
                />
              </Col>
              <Col flex="200px">
                <Select
                  allowClear
                  placeholder={t("common.createdBy")}
                  options={memberOptions}
                  style={{ width: 200 }}
                  onChange={filterByMember}
                />
              </Col>
            </Row>
          )}
        </Space>
      }
      bordered={false}
      style={{ width: "100%" }}
    >
      <SimpleBar>
        <DragDropContext onDragEnd={onDragEnd}>
          <Space align="start" style={{ width: "100%" }} size="large">
            {activeTabList.map(({ key, tab }: any) => (
              <Droppable droppableId={key} key={key}>
                {(provided) => (
                  <StyledCard
                    title={
                      <Title
                        type="secondary"
                        level={5}
                        style={{
                          display: "inline",
                          lineHeight: "unset",
                          width: 200,
                        }}
                        ellipsis={{ tooltip: tab.toUpperCase() }}
                      >
                        {tab.toUpperCase()}
                      </Title>
                    }
                    key="1"
                    extra={
                      <Space size="large">
                        <Avatar.Group
                          maxCount={2}
                          maxStyle={{
                            color: volcano[3],
                            backgroundColor: "#fde3cf",
                          }}
                        >
                          {userFilter[key] && (
                            <StyledButton
                              type="link"
                              onClick={(e) => {
                                e.stopPropagation();
                                setUserFilter((state: any) => ({
                                  ...state,
                                  [key]: "",
                                }));
                              }}
                            >
                              {t("common.clear")}
                            </StyledButton>
                          )}
                          {stageMembers(key).map((member: any) => (
                            <div
                              key={member.user_id}
                              onClick={(e) => {
                                e.stopPropagation();
                                setUserFilter((state: any) => ({
                                  ...state,
                                  [key]: member.user_id,
                                }));
                              }}
                            >
                              <Tooltip title={member.user_name}>
                                <Avatar
                                  style={{
                                    backgroundColor: userFilter[key]?.includes(
                                      member.user_id
                                    )
                                      ? volcano[3]
                                      : "grey",
                                  }}
                                  src={member.child_user_image_url}
                                >
                                  {member.user_name?.slice(0, 3)?.toUpperCase()}
                                </Avatar>
                              </Tooltip>
                            </div>
                          ))}
                        </Avatar.Group>
                      </Space>
                    }
                    bodyStyle={{ padding: 0, overflowX: "hidden" }}
                  >
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ minHeight: "calc(100vh - 500px)" }}
                    >
                      <SimpleBar
                        style={{
                          height: "calc(100vh - 370px)",
                          overflowY: "auto",
                          overflowX: "hidden",
                          width: 388,
                          padding: 12,
                          zIndex: 1,
                        }}
                      >
                        {columns[key]?.orderIds
                          .map((orderId: any) => orders[orderId])
                          .map((order: any, index: any) => (
                            <Draggable
                              isDragDisabled={
                                userAccess?.permission === permissions.viewer ||
                                userAccess?.status !== projectStatus.in_progress
                              }
                              draggableId={order.work_order_id}
                              index={index}
                              key={order.work_order_id}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <StyledCard
                                    size="small"
                                    type="inner"
                                    style={{
                                      width: 340,
                                      marginBlock: 16,
                                    }}
                                    onClick={() => {
                                      const boundShowDetails =
                                        showDetails.bind(order);
                                      boundShowDetails();
                                      if (user_id && order)
                                        trackEvent(
                                          user_id,
                                          "Work Order details were viewed",
                                          { "WO details": { order } }
                                        );
                                    }}
                                    hoverable
                                  >
                                    <Row justify="space-between">
                                      <Title
                                        level={5}
                                        style={{
                                          whiteSpace: "nowrap",
                                          width: 240,
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                        title={order.work_order_name}
                                      >
                                        {order.work_order_name}
                                      </Title>
                                      <Avatar.Group
                                        maxCount={2}
                                        maxStyle={{
                                          color: volcano[3],
                                          backgroundColor: "#fde3cf",
                                        }}
                                        size="small"
                                      >
                                        {order.work_order_dri?.map(
                                          (memberId: string) => (
                                            <Tooltip
                                              title={getName(
                                                teamsData.find(
                                                  (member: any) =>
                                                    member.user_id === memberId
                                                )?.created_by
                                              )}
                                            >
                                              <Avatar
                                                key={memberId}
                                                style={{
                                                  backgroundColor: volcano[3],
                                                }}
                                                src={
                                                  teamsData.find(
                                                    (member: any) =>
                                                      member.user_id ===
                                                      memberId
                                                  )?.child_user_image_url
                                                }
                                              >
                                                {getName(
                                                  teamsData.find(
                                                    (member: any) =>
                                                      member.user_id ===
                                                      memberId
                                                  )?.created_by
                                                )
                                                  .slice(0, 3)
                                                  ?.toUpperCase()}
                                              </Avatar>
                                            </Tooltip>
                                          )
                                        )}
                                      </Avatar.Group>
                                    </Row>
                                    <Divider
                                      style={{
                                        margin: 0,
                                        marginBottom: 16,
                                      }}
                                    />
                                    <Descriptions column={1} size="small">
                                      {![
                                        "work_order_initiated",
                                        "report_preview",
                                        "work_order_status",
                                      ].includes(key) ? (
                                        <>
                                          <Descriptions.Item label={""}>
                                            <Text type="secondary">
                                              {order?.stage_name}
                                            </Text>
                                          </Descriptions.Item>
                                          <Descriptions.Item label={""}>
                                            <Text type="secondary">
                                              {order?.stage_description}
                                            </Text>
                                          </Descriptions.Item>
                                        </>
                                      ) : (
                                        <Descriptions.Item
                                          label={
                                            <Text>
                                              {t("common.description")}
                                            </Text>
                                          }
                                        >
                                          <SimpleBar
                                            style={{
                                              height: 40,
                                              width: "100%",
                                            }}
                                          >
                                            <Text type="secondary">
                                              {order.work_order_desc}
                                            </Text>
                                          </SimpleBar>
                                        </Descriptions.Item>
                                      )}
                                    </Descriptions>
                                    <Space
                                      style={{
                                        width: "100%",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Tag
                                        color="blue"
                                        icon={<CalendarOutlined />}
                                      >
                                        {t("common.createdOn")} :{" "}
                                        {dayjs(order.created).format(
                                          dateFormat
                                        )}
                                      </Tag>
                                      {(userAccess?.permission ===
                                        permissions.projectAdmin ||
                                        user_id === order?.created_by) &&
                                        userAccess.status ===
                                        projectStatus.in_progress && (
                                          <StyledButton
                                            type="link"
                                            icon={
                                              <StyledDeleteIcon
                                                style={{ color: volcano[3] }}
                                              />
                                            }
                                            onClick={(e) => {
                                              e?.stopPropagation();
                                              handleDelete.call(order);
                                            }}
                                          />
                                        )}
                                    </Space>
                                  </StyledCard>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </SimpleBar>
                    </div>
                  </StyledCard>
                )}
              </Droppable>
            ))}
          </Space>
        </DragDropContext>
      </SimpleBar>
    </StyledCard>
  );
};
