import { createActions } from "redux-actions";

export enum UserPreferencesActionTypes {
  REQUEST = "USER_PREFERENCES_REQUEST",
  SUCCESS = "USER_PREFERENCES_SUCCESS",
  FAILURE = "USER_PREFERENCES_FAILURE",
}

export enum UpdateUserPreferencesActionTypes {
    REQUEST = "UPDATE_USER_PREFERENCES_REQUEST",
    SUCCESS = "UPDATE_USER_PREFERENCES_SUCCESS",
    FAILURE = "UPDATE_USER_PREFERENCES_FAILURE",
  }

export const {
  userPreferencesRequest,
  userPreferencesSuccess,
  userPreferencesFailure,

  updateUserPreferencesRequest,
  updateUserPreferencesSuccess,
  updateUserPreferencesFailure,
} = createActions({
  [UserPreferencesActionTypes.REQUEST]: payload => payload,
  [UserPreferencesActionTypes.SUCCESS]: (payload) => payload,
  [UserPreferencesActionTypes.FAILURE]: (error: string) => error,

  [UpdateUserPreferencesActionTypes.REQUEST]: payload => payload,
  [UpdateUserPreferencesActionTypes.SUCCESS]: (payload) => payload,
  [UpdateUserPreferencesActionTypes.FAILURE]: (error: string) => error,
});
