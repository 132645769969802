import { Col, Divider, Form, Input, Modal, Row, Select, Switch, message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { InventoryProperty } from "../types";
import {
  createInventoryPropertyClear,
  createInventoryPropertyRequest,
  updateInventoryPropertyClear,
  updateInventoryPropertyRequest,
} from "src/store/actions/inventoryV2";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import { AddMetaDataModal } from "src/components/Inventory/modals/AddMetaDataModal";
import { PropertiesConstant } from "src/components/Inventory/AddData";
import { isValidNumber } from "src/utils/decorator";
import { useValue } from "src/utils/useValue";

const { Option, OptGroup } = Select;

type InventoryPropertyModalProps = {
  open: boolean;
  // setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: Function;
  mode: "create" | "edit";
  property?: InventoryProperty;
  from: string,
  ingredient: any,
  inventoryDataList: any
};

export const InventoryPropertyModal = ({
  open,
  onClose,
  mode,
  property,
  from,
  ingredient,
  inventoryDataList
}: InventoryPropertyModalProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { convertValue, getValue } = useValue()
  const unitsList = useSelector(
    (state: StoreState) => state.conversion.unitList
  );

  const createPropertyResponse = useSelector(
    (state: StoreState) => state.inventoryV2.createProperty
  );

  const updatePropertyResponse = useSelector(
    (state: StoreState) => state.inventoryV2.updateProperty
  );

  const [metadataModalVisible, setMetadataModalVisible] =
    useState<boolean>(false);

  const [form] = Form.useForm();

  const filterCategories = useMemo(() => {
    if (metadataModalVisible)
      return [
        ...new Set(
          inventoryDataList?.["metadata_properties"]
            ?.map((res: any) => res?.category)
            .filter((res: any) => !!res?.trim())
        ),
        ...PropertiesConstant,
      ];
    return [
      ...new Set(
        inventoryDataList?.["ingredients"]
          ?.map((res: any) => res?.category)
          .filter((res: any) => !!res?.trim())
      ),
    ];
  }, [inventoryDataList, metadataModalVisible]);

  const onFinish = (values: any) => {
    if (values.has_range && !isValidNumber(values.value) && isValidNumber(values.value_max)) {
      return message.error("Please Enter Min Value")
    }
    if (mode === "create") {
      dispatch(
        createInventoryPropertyRequest({
          ...values,
          name: inventoryDataList?.metadata_properties.find(({ identifier }: any) => identifier === values.name)?.name,
          ...(from === 'family' ? { family_id: ingredient?.family_id } : { inventory_id: ingredient.inventory_id }),
          value: values.value ? convertValue(values.value) : undefined,
          value_max: values.has_range ? values.value_max ? convertValue(values.value_max) : 0 : null,
          value_type: from !== 'family' ? values.is_non_numerical_value ? "string" : "numerical" : undefined,
          from
        })
      );
    } else {
      dispatch(
        updateInventoryPropertyRequest({
          ...values,
          name: inventoryDataList?.metadata_properties.find(({ identifier }: any) => identifier === values.name)?.name,
          ...(from === 'family' ? { family_id: property?.family_id } : { inventory_id: property?.inventory_id }),
          property_id: from === 'family' ? property?.property_id : property?.inventory_property_id,
          value: values.value ? convertValue(values.value) : undefined,
          value_max: values.has_range ? values.value_max ? convertValue(values.value_max) : 0 : null,
          value_type: from !== 'family' ? values.is_non_numerical_value ? "string" : "numerical" : undefined,
          from
        })
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const handleClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  useEffect(() => {
    if (
      createPropertyResponse.status === AsyncStates.SUCCESS
    ) {
      // message.success(`Property created`);
      dispatch(createInventoryPropertyClear());
      handleClose();
    }
  }, [createPropertyResponse.status, dispatch, handleClose]);

  useEffect(() => {
    if (
      updatePropertyResponse.status === AsyncStates.SUCCESS
    ) {
      // message.success(`Property updated`);
      dispatch(updateInventoryPropertyClear());
      handleClose();
    }
  }, [updatePropertyResponse.status, dispatch, handleClose]);


  useEffect(() => {
    if (mode === "create") {
      form.resetFields();
    } else {
      form.resetFields();
      form.setFieldsValue({
        ...property, is_non_numerical_value: property?.value_type !== 'numerical',
        value: getValue(property?.value),
        value_max: getValue(property?.value_max)
      });
    }
  }, [form, property, mode, getValue]);

  return (
    <Modal
      title={`${mode === "create" ? "Create" : "Update"} Property`}
      footer={null}
      width={520}
      open={open}
      onCancel={() => handleClose()}
      maskClosable={false}
    >
      <Form
        size="small"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={"category"}
              label={"Category"}
              rules={[
                {
                  required: false,
                  message: t("common.required"),
                  transform: (value) => value?.trim(),
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"name"}
              label="Name"
              rules={[
                {
                  required: true,
                  message: t("common.required"),
                  transform: (value) => value?.trim(),
                },
              ]}
              required
            >
              <Select
                showSearch
                style={{ width: 220 }}
                filterOption={true}
                placeholder={t("common.selectProperties")}
                onChange={(e) => {
                  const currentObject =
                    inventoryDataList?.metadata_properties?.find(
                      (res: any) => res?.identifier === e
                    );
                  form.setFieldsValue({
                    name: e,
                    category: currentObject?.category,
                  });
                }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <StyledButton
                      onClick={() => setMetadataModalVisible(true)}
                      type="link"
                    >
                      {t("inventory.AddNewproperty")}
                    </StyledButton>
                  </div>
                )}
              >
                {inventoryDataList?.metadata_properties?.map((res: any) => {
                  return <Option value={res?.identifier}>{res?.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={"unit"}
              label={"Unit"}
            >
              <Select showSearch placeholder={t("common.selectUnit")}>
                {Array.from(
                  new Set(
                    unitsList.map((res: any) => res.category.toLowerCase())
                  )
                ).map((category: any) => (
                  <OptGroup
                    label={
                      String(category).charAt(0).toLocaleUpperCase() +
                      String(category).slice(1)
                    }
                  >
                    {unitsList
                      .filter(
                        (res: any) => res.category.toLowerCase() === category
                      )
                      .map((res: any, index: any) => (
                        <Option value={res.name} key={res.name + index}>
                          {res.name}
                        </Option>
                      ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {from !== 'family' &&
            <Col span={12}>
              <Row>
                <Form.Item label="Non Numerical Value" name="is_non_numerical_value" valuePropName="checked">
                  <Switch />
                </Form.Item>
                {
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.is_non_numerical_value !== currentValues.is_non_numerical_value
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("is_non_numerical_value") !== true ? (
                        <Form.Item label="Range" name="has_range" valuePropName="checked">
                          <Switch />
                        </Form.Item>
                      ) : null}
                  </Form.Item>
                }
              </Row>
            </Col>
          }
        </Row>
        {
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.is_non_numerical_value !== currentValues.is_non_numerical_value
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("is_non_numerical_value") === true ? (
                <Form.Item label="Value" name="value_str">
                  <Input />
                </Form.Item>
              ) : <>
                {
                  from !== 'family' &&
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Value" name="value">
                        <Input onBlur={(event) => {
                          const value = convertValue(event.target.value)
                          if (!!value && !isValidNumber(value)) {
                            form.setFieldValue("value", null)
                            message.error("Invalid Input")
                          }
                          if (form.getFieldValue("has_range") &&
                            !!(value) && !!form.getFieldValue("value_max")
                            && (value > convertValue(form.getFieldValue("value_max")))) {
                            form.setFieldValue("value", null)
                            message.error(t("inventory.ValueisGreaterthanMaxValue"))
                          }
                        }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.has_range !== currentValues.has_range
                        }
                      // hidden={(form) => form.getFieldValue('has_range')}
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("has_range") === true ? (
                            <Form.Item label="Max Value" name="value_max">
                              <Input onBlur={(event) => {
                                const value = convertValue(event.target.value)
                                if (!!value && !isValidNumber(value)) {
                                  form.setFieldValue("value_max", null)
                                  message.error("Invalid Input")
                                }
                                if (!!form.getFieldValue("value") && !!value &&
                                  convertValue(form.getFieldValue("value")) > value) {
                                  form.setFieldValue("value_max", null)
                                  message.error(t("inventory.Maxvalueislessthanvalue"))
                                }
                              }} />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                }
              </>
            }
          </Form.Item>
        }

        <Row>
          <Form.Item
            noStyle
            shouldUpdate={(_, currentValues) => (!!currentValues?.value_str || !!currentValues?.value || currentValues?.value?.length === 0 || !!property?.value)}
          >
            {({ getFieldValue }) => getFieldValue("value")?.length > 0 || !!property?.value || !!getFieldValue("value_str")?.length || !!property?.value_str
              ? (
                <Form.Item name={"method_name"}
                  label={"Method Name"}
                >
                  <Input />
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Row>

        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
          <StyledButton
            type="primary"
            htmlType="submit"
            style={{
              float: "right",
            }}
            loading={createPropertyResponse.status === AsyncStates.LOADING || updatePropertyResponse.status === AsyncStates.LOADING}
          >
            {t("common.submit")}
          </StyledButton>
        </Form.Item>
      </Form>
      <AddMetaDataModal
        metadataModalVisible={metadataModalVisible}
        setMetadataModalVisible={setMetadataModalVisible}
        filterCategories={filterCategories}
      />
    </Modal>
  );
};
