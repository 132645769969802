import { useLayoutEffect, useState } from "react";
import "./Categories.scss";
import useTranslate from "src/utils/useTranslate";
import TabHeader from "../Common/TabHeader";
import CategoryDetails from "./CategoryDetails";
import AddEditCategoryModal from "../Common/AddEditCategoryModal";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { fetchCategoryRequest } from "src/store/actions/repository";
import { AsyncStates } from "src/constants";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CATEGORY_TYPES } from "../Common/Data";
import CategoryTypesSidebar from "./CategoryTypeSidebar";

const Categories = () => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState(CATEGORY_TYPES.INGREDIENT);
    const categories = useSelector(
        (state: StoreState) => state.repository.categories
    );
    const [searchTerm, setSearchTerm] = useState<string>();
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [mode, setMode] = useState<"edit" | "create" | "delete">("create");
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(fetchCategoryRequest({ type }));
    }, [dispatch, type]);

    const addCategory = () => {
        setMode("create");
        setShowModal(true);
    };

    return (
        <Spin
            spinning={categories.status === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}
        >
            <div className="category-container">
                <div className="content-container">
                    <>
                        <CategoryTypesSidebar type={type} setType={setType} />
                        <div className="main-container">
                            {categories.data.length > 0 && <TabHeader
                                title={`${t("repository.totalCategories")} (${categories.data.length
                                    })`}
                                addItem={addCategory}
                                setSearchTerm={setSearchTerm}
                            />}
                            <CategoryDetails
                                searchTerm={searchTerm}
                                setSelectedCategory={setSelectedCategory}
                                setMode={setMode}
                                setShowModal={setShowModal}
                                type={type}
                            />
                        </div>
                    </>
                </div>

                <AddEditCategoryModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    mode={mode}
                    selectedCategoryId={selectedCategory}
                    type={type}
                />
            </div>
        </Spin>
    );
};

export default Categories;
