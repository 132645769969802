import { LoadingOutlined } from '@ant-design/icons'
import { Form, message, Select, Spin } from 'antd'
import React, { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { boxPlotSummaryRequest } from 'src/store/actions/dataSummary'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { getDropdownFilteredValue } from 'src/utils/decorator'
import useTranslate from 'src/utils/useTranslate'
import { SummaryWrapper } from '../summary-wrapper'
import { BoxPlotDistributionChart } from './BoxPlotDistributionChart'
import "./VisualiseDistributions.scss"
import { toTitleCase } from 'src/utils/general'
import { PlotType } from 'src/components/Analytics/enums'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'

type VisualiseDistributionsProps = {
    categoricalInput: string[],
    numericalInput: any,
    workOrderIds: string[],
    selectedVariation?: any
    getDisplayName: any,
    normalise: boolean | undefined,
    selectedExperiments: string[],
    setSaveGraphsPayload: any,
    selectedPlotsData: { [key: string]: any },
    selectedFormulations: string[]
}

export const VisualiseDistributions = memo(({ categoricalInput, numericalInput, workOrderIds, selectedVariation, getDisplayName, normalise, selectedExperiments, setSaveGraphsPayload, selectedPlotsData, selectedFormulations }: VisualiseDistributionsProps) => {
    const [visualiseDistributionsForm] = Form.useForm()
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()

    const dispatch = useDispatch()
    const configs = useSelector((state: StoreState) => state.configs.features)
    const isSidebarCollapsed = useSelector((state: StoreState) => state.sidebar.collapsed)
    const { boxPlotSummaryStatus } = useSelector((state: StoreState) => state.dataSummary)

    const options = useMemo(() => {
        if (!!Object.keys(numericalInput || {}).length) {
            return Object.keys(numericalInput || {}).map((key: string) => {
                if (!!numericalInput[key].length) {
                    const data = numericalInput[key]
                    return ({
                        label: toTitleCase(key),
                        options: data.map((parameter: string) => ({
                            value: parameter,
                            label: getDisplayName(parameter),
                        })),
                    })
                } else {
                    return null
                }
            }).filter((res) => res)
        } else {
            return []
        }
    }, [numericalInput, getDisplayName])

    const handleFinish = (values: any) => {
        if (!((values.categorical_list.length <= 2 && values.numerical_list.length === 1) || (values.numerical_list.length <= 2 && values.categorical_list.length === 1))) {
            return message.error(t("dsm.visualizeDistributionErrorMessage"))
        }

        const payload = {
            work_order_ids: workOrderIds,
            categorical_list: values.categorical_list,
            numerical_list: values.numerical_list,
            normalise,
            ...(Boolean(configs?.characterization_methods)) && {
                characterization_set_id: selectedVariation?.characterization_set_id,
            },
            ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: selectedExperiments }),
            ...(Boolean(configs?.processing_profiles)) && {
                with_processing_profile: true
            }
        }
        setSaveGraphsPayload((prev: any) => ({
            ...prev,
            [PlotType.VISUALIZE_DISTRIBUTION]: {
                categorical_list: values.categorical_list,
                numerical_list: values.numerical_list,
            }
        }))
        dispatch(boxPlotSummaryRequest(payload))
    }

    useEffect(() => {
        if (Object.keys(selectedPlotsData?.data?.plot_data ?? {}).includes(PlotType.VISUALIZE_DISTRIBUTION)) {
            const data: { [key: string]: string[] } = selectedPlotsData?.data?.plot_data?.[PlotType.VISUALIZE_DISTRIBUTION] ?? {}
            if (!!data?.categorical_list?.length) {
                const categoricalValue = data?.["categorical_list"]?.filter((res) => categoricalInput.includes(res))
                visualiseDistributionsForm.setFieldValue("categorical_list", categoricalValue)
            }
            if (!!data?.numerical_list?.length) {
                const numericalParameristList = Object.values(numericalInput ?? {}).flatMap(value => value)
                const numericalValue = data?.["numerical_list"]?.filter((res) => numericalParameristList.includes(res))
                visualiseDistributionsForm.setFieldValue("numerical_list", numericalValue)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlotsData, visualiseDistributionsForm])

    return (
        <SummaryWrapper heading={t("dataSummary.visualizeDistributions")} tooltip={t("dataSummary.visualizeDistributions")} id={'visualize-distribution'} key={'visualize-distribution'} >
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: "1rem" }}>
                <Form form={visualiseDistributionsForm} onFinish={handleFinish} layout="inline" className='visualiseDistributionsForm' requiredMark={false}>
                    <Form.Item
                        name="categorical_list"
                        style={{ margin: 0, width: isSidebarCollapsed ? "26rem" : "24rem" }}
                        label={t("graphs.categoricalInput")}
                        labelAlign='left'
                        className="form__item"
                        rules={[
                            { required: true, message: t("graphs.categoryRequired") },
                        ]}
                        required
                        tooltip={requiredFieldStar}
                    >
                        <Select placeholder={t("aiEngine.customInsights.selectIngredients")} allowClear mode="multiple">
                            {
                                categoricalInput?.map((categoryInput) => (
                                    <Select.Option value={categoryInput} key={categoryInput}>{getDisplayName(categoryInput)}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="numerical_list"
                        style={{ margin: 0, width: isSidebarCollapsed ? "26rem" : "24rem" }}
                        label={t("graphs.numericInput")}
                        labelAlign='left'
                        className="form__item"
                        rules={[
                            { required: true, message: t("graphs.numericInputRequired") },
                        ]}
                        required
                        tooltip={requiredFieldStar}
                    >
                        <Select
                            placeholder={t("aiEngine.customInsights.selectIngredients")}
                            filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}
                            allowClear mode="multiple"
                            options={options as any[]}
                        />
                    </Form.Item>

                    <Form.Item
                        className="form__item"
                        style={{ marginTop: "1.7rem" }}
                    >
                        <StyledButton
                            loading={boxPlotSummaryStatus === AsyncStates.LOADING}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("common.submit")}
                        </StyledButton>

                    </Form.Item>
                </Form >
            </div>
            <Spin spinning={boxPlotSummaryStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <BoxPlotDistributionChart getDisplayName={getDisplayName} />
            </Spin>

        </SummaryWrapper >
    )
})