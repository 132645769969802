import React, { CSSProperties } from "react"
import { geekblue, purple, volcano } from "@ant-design/colors"

type Props = {
	from?: string
}

export const Legend = ({ from }: Props) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				gap: "1rem",
				padding: ".5rem 1rem",
				border: "1px solid #ccc",
				backgroundColor: "#fff",
			}}
		>
			<LegendItem
				label="Ingredients"
				color="#222"
				bg="white"
				ballStyle={{
					border: "1px solid #ccc",
				}}
			/>
			<LegendItem label="Processing" color={volcano[5]} bg={volcano[0]} />
			{from !== "favorites" &&
				<>
					<LegendItem label="Properties" color={geekblue[5]} bg={geekblue[0]} />
					<LegendItem label="Characterizations" color={purple[5]} bg={purple[0]} />
				</>
			}
		</div>
	)
}

type LegendItemProps = {
	color: string
	bg: string
	label: string
	ballStyle?: CSSProperties
}
const LegendItem = ({ color, label, bg, ballStyle = {} }: LegendItemProps) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				gap: ".5rem",
				userSelect: "none",
			}}
		>
			<span
				style={{
					borderRadius: "1rem",
					width: "1rem",
					height: "1rem",
					background: bg,
					...ballStyle,
				}}
			></span>
			<span style={{ fontWeight: 600, color }}>{label}</span>
		</div>
	)
}
