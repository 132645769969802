import axios from "axios";
import { sendGet } from "src/utils/sendApi";

export const fetchdashboardDataApi = (params: any, headers: any) => {
  return axios.post<any>(
    process.env.REACT_APP_API_URL + "v1/data/_dashboard",
    params,
    { headers }
  );
};

export const fetchNewsApi = (params: any, headers: any) => {
  return axios.post<any>(
    process.env.REACT_APP_API_URL + "v1/data/_news",
    params,
    { headers }
  );
};

export const dashboardRecentEditsApi = sendGet( "v1/data/dashboard_v2/get_recent_edits");

export const dashboardUsedIngredientsApi = sendGet( "v1/data/dashboard_v2/get_used_ingredients");

export const dashboardWorkorderStateCountsApi = sendGet( "v1/data/dashboard_v2/get_work_order_counts");

export const dashboardProjectStateCountsApi = sendGet( "v1/data/dashboard_v2/get_project_counts");

export const dashboardActivityCardTotalApi = sendGet( "v1/data/dashboard_v2/get_activities");
