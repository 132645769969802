import React, { useMemo } from "react"
import { Table as AntTable } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useSelector } from "react-redux"
import { PCM } from "src/components/Celsure/Pcm3DVisualisation/types"
import { AsyncStates } from "src/constants"
import { StoreState } from "src/store/configureStore"
import { toTitleCase } from "src/utils/general"
import useTranslate from "src/utils/useTranslate"


type P = {
	data: PCM[]
}

export const Table = ({ data }: P) => {
	const {
		celsureDisplayNamesData = {},
		celsureDisplayNamesStatus,
	} = useSelector((state: StoreState) => state.celsure)
	const [t] = useTranslate()

	const columns: ColumnsType<PCM> = useMemo(() => {
		if (celsureDisplayNamesStatus !== AsyncStates.SUCCESS) return []

		return [
			{
				title: "PCM type",
				dataIndex: "pcm_type",
				key: "pcm_type",
				width: 20,
				render: (value: PCM["pcm_type"]) =>
					celsureDisplayNamesData["pcm_type"]?.[value]?.name ?? value,
			},
			{
				title: t("common.type"),
				dataIndex: "size_type",
				key: "size_type",
				width: 20,
				render: (value: PCM["size_type"]) =>
					celsureDisplayNamesData?.size_type[value] ?? value,
			},
			{
				title: "No. of Cells/ Tab type",
				key: "cells_no",
				dataIndex: "cells_no",
				width: 40,
			},
			{
				title: "Length(mm)",
				key: "length",
				dataIndex: "length",
				width: 20,
			},
			{
				title: "Width(mm)",
				key: "width",
				dataIndex: "width",
				width: 20,
			},
			{
				title: "Thickness(mm)",
				key: "thickness",
				dataIndex: "thickness",
				width: 20,
			},
			{
				title: "Position",
				dataIndex: "position",
				key: "position",
				width: 20,
				render: (text: PCM["position"]) => toTitleCase(text),
			},
			{
				title: "No. of packs",
				dataIndex: "no_of_packs",
				key: "no_of_packs",
				width: 20,
			},
			{
				title: "Quantity/pack (kg)",
				dataIndex: "quantity",
				key: "quantity",
				width: 20,
			},
			{
				title: "PCM Energy (kJ)",
				dataIndex: "enthalpy",
				key: "enthalpy",
				width: 20,
			},
			{
				title: "Initial Temperature(°C)",
				dataIndex: "initial_temperature",
				key: "initial_temperature",
				width: 20,
			},
			{
				title: "Thawing Time(hours)",
				dataIndex: "thawing",
				key: "thawing",
				width: 20,
			},
			{
				title: "Ambient Temperature(°C)",
				key: "ambient_temp",
				dataIndex: "ambient_temp",
				width: 20,
			},
		] as ColumnsType<PCM>
	}, [celsureDisplayNamesData, celsureDisplayNamesStatus, t])

	return (
		<AntTable
			style={{
				marginBottom: "3rem",
			}}
			columns={columns}
			dataSource={data}
			bordered
			pagination={false}
			size="small"
		/>
	)
}
