import { EditOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import { useMemo, useState } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import AddEditIngredientTemplateAdditivesModal from "../Common/IngredientTemplate/AddEditIngredientTemplateAdditivesModal";

type TProps = {
    title: string;
    additives: any[];
    selectedTemplateId: string;
};

const IngredientTemplateAdditives = ({
    title,
    additives,
    selectedTemplateId,
}: TProps) => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);

    const columns: any = useMemo(
        () => [
            {
                title: t("common.Name"),
                dataIndex: "name",
                key: "name",
                width: 200,
            },
            {
                title: t("common.Category"),
                dataIndex: "category",
                key: "category",
            },
        ],
        [t]
    );

    return (
        <>
            <Row justify={"space-between"} style={{ marginBottom: "12px" }}>
                <Col></Col>
                <Col>
                    <StyledButton
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => setShowModal(true)}
                    >
                        {t("common.edit")}
                    </StyledButton>
                </Col>
            </Row>
            <Table
                bordered={false}
                columns={columns}
                dataSource={additives}
                pagination={false}
                className="repository-table"
            />
            <AddEditIngredientTemplateAdditivesModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedTemplateId={selectedTemplateId}
            />
        </>
    );
};

export default IngredientTemplateAdditives;
