import { useVariableValue } from "@devcycle/devcycle-react-sdk";
import { IJsonModel, Model as LayoutModel } from "flexlayout-react";
import { useMemo } from "react";

export const useLayoutModel = () => {

    const enableCharacterizationTab = useVariableValue('characterization-tab', false)
    const modelData = useMemo<IJsonModel>(() => ({
        "global": {
            "tabEnableClose": false,
            "tabSetEnableTabStrip": false
        },
        "borders": enableCharacterizationTab ? [
            {
                "type": "border",
                "location": "right",
                "size": 500,
                "children": [
                    {
                        "type": "tab",
                        "name": "Characterization",
                        "component": "properties"
                    }
                ]
            }
        ] : [],
        "layout": {
            "type": "row",
            "weight": 100,
            "children": [
                {
                    "type": "tabset",
                    "weight": 50,
                    "children": [
                        {
                            "type": "tab",
                            "name": "One",
                            "component": "datasheet"
                        }
                    ]
                }
            ]
        }
    }

    ), [enableCharacterizationTab])
    const model = useMemo(() => LayoutModel.fromJson(modelData), [modelData])
    return model;
}