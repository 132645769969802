import { blue } from "@ant-design/colors"
import { EllipsisOutlined, FileExcelOutlined, InboxOutlined, SettingOutlined, UploadOutlined } from "@ant-design/icons"
import {
    Form,
    Space,
    Upload,
    Typography,
    message,
    Tabs,
    Tag,
    Drawer,
    Tooltip,
    Dropdown,
    MenuProps,
    FloatButton,
} from "antd";
import { TabsProps } from "antd/lib"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { permissions, projectStatus } from "src/constants"
import {
	// draftFileUploadRequest,
	fileHistoryRequest, mappedFilesRequest, uploadFileRequest
} from "src/store/actions/files"
import { workOrdersRequest } from "src/store/actions/workOrders"
import { StoreState } from "src/store/configureStore"
import { StyledCard } from "src/styled_components/StyledCard"
import { useSpreadsheet } from "src/utils/hooks/useSpreadsheet"
import { usePermission } from "src/utils/usePermissions"
import useTranslate from "src/utils/useTranslate"
import { Tree } from 'antd';
import type { DataNode, DirectoryTreeProps } from 'antd/es/tree';
import XLSX from "xlsx"
import { useLiveQuery } from "dexie-react-hooks"
import { filesDB, mappingsDB } from "src/db"
import jsondata from "./data.json"
import { StyledButton } from "src/styled_components/StyledButton"

declare global {
	namespace JSX {
		interface IntrinsicElements {
			"regular-table": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
		}
	}
}

const context = "CONTEXT_1"

const { DirectoryTree } = Tree;

const { Text } = Typography
const { Dragger } = Upload

const minimiseStyle = { border: 0, width: '100%', height: '100%', position: 'unset' as any, overscrollBehavior: 'contain' }
const maximiseStyle = { border: 0, width: '100%', height: '100%', position: 'fixed' as any, zIndex: 5000, left: 0, top: 0 }

// const mockStyleData = { borderColor: blue.primary, borderWidth: 2, borderStyle: 'dashed', padding: 8, height: 240, borderRadius: 4 }

export const ExcelMapper = ({ callbacks, data }: any) => {
	const [t] = useTranslate()
	const dispatch = useDispatch()

	const [file, setFile] = useState<any>()
	const [, setSheets] = useState<any>()
	const [, setParsedFile] = useState<any>()


	const mappings = useLiveQuery(
		() => mappingsDB.mappings.toArray()
	);

	const mappedFiles = useLiveQuery(
		() => filesDB.files.toArray()
	);

	const { setUnsaved } = callbacks

	const { data: historyData } = useSelector(
		(state: StoreState) => state.files.fileHistory
	)
	const { current: project_id } = useSelector(
		(state: StoreState) => state.projects
	)

	const treeData: DataNode[] = useMemo(() => [{
		key: 'root',
		title: 'Collection',
		children: mappedFiles?.map(record => {
			const mappingName = mappings?.find(mapping => mapping.id === record.mapping_id)?.name
			return ({
				key: record.id as any,
				title: (
					<Space>
						<Typography.Text ellipsis={{ tooltip: record.file.name }} style={{ width: 200 }}>
							{record.file.name}
						</Typography.Text>
						{mappingName && <Tag color={"blue"} style={{ lineHeight: '17px' }}>{mappingName}</Tag>}
					</Space>
				),
				isLeaf: true
			})
		}
		)
	}],
		[mappedFiles, mappings]
	)
	const userAccess = usePermission()

	useEffect(() => {
		dispatch(fileHistoryRequest({ project_id }))
		dispatch(workOrdersRequest({ project_id }))
		dispatch(workOrdersRequest({ project_id }))
		dispatch(mappedFilesRequest({ context }))
	}, [project_id, dispatch])

	// const [savingInProgress, setSavingInProgress] = useState(false)

	const handleFileUpload = async () => {
		await spreadsheet.port.postMessage({ type: "save_changes" })
		setUnsaved(true)
		// setSavingInProgress(true)
		// setCurrent(1)
	}

	const uploadProps = {
		showUploadList: false,
		accept: ".xlsx",
		onRemove: (record: any) => {
			setFile()
			setParsedFile({})
			setSheets([])
		},
		beforeUpload: async (record: any) => {
			if (record.name.split(".").pop() === "xlsx") {
				setFile(record)
				const id = crypto.randomUUID()
				filesDB.files.add({ id, file: record })
				const workbook = XLSX.read(await record.arrayBuffer())
				setParsedFile(workbook)
				loadFile(workbook, id)
				setShowSpreadsheet(true)
			} else message.error(t("dataUpload.selectedFileDesc"))
			return false
		},
		multiple: false,
		fileList: !!file ? [file] : [],
	}

	const [fullscreen, setFullscreen] = useState(false)

	const toggleFullscreen = useCallback(() => {
		setFullscreen(state => !state)
	}, [])

	const [previewState, setPreviewState] = useState<any>(jsondata);
	const [open, setOpen] = useState(true);

	const showDrawer = () => {
		setOpen(true);
	};

	const generatePreview = (data: any) => {
		setPreviewState(data)
		// setOpen(true);
	};

	const workOrder = useSelector(
		(state: StoreState) => state.workOrderDetails.workOrder || {}
	);

	const upload = async () => {
		const TYPES = ["ingredients", "processing", "properties"];

		const missingKeys = (recordName: string, record: any) => {
			switch (recordName) {
				case TYPES[0]:
					return { ingredients_unit: record.unit ?? "-", ingredients_category: record.category ?? "uncategorised" };
				case TYPES[1]:
					return { processing_parameters: record.processing, processing_unit: record.unit ?? "-", processing_category: record.category ?? "uncategorised" };
				case TYPES[2]:
					return { characterisation_parameters: record.properties, characterisation_unit: record.unit ?? "-", characterisation_category: record.category ?? "uncategorised" };

				default:
					return {};
			}
		};

		const patchedData = {
			experiments: previewState.experiments,
			formulations: previewState[TYPES[0]].map((record: any) => ({
				...record,
				...missingKeys(TYPES[0], record),
			})),
			[TYPES[1]]: previewState[TYPES[1]].map((record: any) => ({
				...record,
				...missingKeys(TYPES[1], record),
			})),
			characterizations: previewState[TYPES[2]].map((record: any) => ({
				...record,
				...missingKeys(TYPES[2], record),
			})),
		};
		const params = new FormData()
		params.append("file", file)
		params.append("work_order_id", workOrder.work_order_id)
		params.append("experiment_id", workOrder.experiment_id[0])
		params.append("stage", workOrder.stages[0]?.identifier)
		params.append("data", JSON.stringify(patchedData))
		dispatch(uploadFileRequest(params))
	};

	const onClose = () => {
		setOpen(false);
	};

	const onChange = (key: string) => {

		if (key === '2') {

			console.log('tab', key);
		}
	};

	const items: TabsProps['items'] = useMemo(() => [
		{
			key: '1',
			label: t('common.ingredients'),
			children: <PreviewTable data={previewState?.ingredients} experiments={previewState?.experiments} />
		},
		{
			key: '2',
			label: t('common.processing'),
			children: <PreviewTable data={previewState?.processing} experiments={previewState?.experiments} />
		},
		{
			key: '3',
			label: t('common.properties'),
			children: <PreviewTable data={previewState?.properties} experiments={previewState?.experiments} />
		},
	], [previewState, t])

	const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
		setSelectedFiles(keys)
	};

	const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
		console.log('Trigger Expand', keys, info);
	};

	const itemsD: MenuProps['items'] = useMemo(() => mappings?.map(m => (
		{
			key: m.id,
			label: m.name
		} as any
	)).concat([
		{
			type: 'divider',
		},
		{
			key: 'clear',
			label: 'Clear'
		}
	]), [mappings])

	const [selectedFiles, setSelectedFiles] = useState<any[]>([])

	const selectedMappings = useMemo(() => (mappedFiles as any)?.filter((file: any) => file.id === selectedFiles[0])?.[0]?.mapping_id, [mappedFiles, selectedFiles])

	const selectMapping = async ({ key }: any) => {
		const files = await filesDB.files.toArray()
		const ids: any = files.map(file => file.id)
		if (selectedFiles[0] === 'root') {
			if (key === 'clear') {
				for (const id of ids) {
					await filesDB.files?.update(id, { mapping_id: undefined })
				}
			} else {
				for (const id of ids) {
					await filesDB.files?.update(id, { mapping_id: key })
				}
			}
		} else {
			if (key === 'clear') {
				await filesDB.files.update(selectedFiles[0], { mapping_id: undefined })
			} else {
				await filesDB.files.update(selectedFiles[0], { mapping_id: key })
			}
		}
	}


	const [, setIsIframeLoaded] = useState(false)

	const toggleIframeLoading = useCallback(() => { setIsIframeLoaded(true) }, [])

	const { spreadsheet }: any = useSpreadsheet({ data: { file }, callbacks: { toggleFullscreen, setParsedFile, generatePreview, toggleIframeLoading } })

	const loadFile = useCallback((parsedFile, fileId) => {
		const mappingId = mappedFiles?.find(m => m.id === fileId)?.mapping_id
		const formatData = (cells: any = {}) => {
			const newCells: any = {}
			for (const cell in cells) {
				if (cell[0] !== '!') {
					newCells[cell] = { content: cells[cell].v?.toString() }
				}
			}
			return newCells
		}

		const sheets = parsedFile?.SheetNames?.map((sheet: any, index: number) => {
			const data = parsedFile?.Sheets?.[sheet]
			return ({
				name: sheet,
				id: sheet,
				cells: formatData(data),
				merges: data?.['!merges']?.map(XLSX.utils.encode_range)
			})
		})

		spreadsheet.port.postMessage({
			type: 'init', payload: {
				mappingId,
				data: {
					version: 1,
					sheets
				}
			}
		});

		if (!mappingId) {
			setPreviewState(jsondata)
		}

		setOpen(false)

	}, [mappedFiles, spreadsheet.port])

	const [, setShowSpreadsheet] = useState(false)

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
			<Drawer
				placement="left"
				closable={false}
				onClose={onClose}
				open={open}
				// getContainer={false}
				// mask={false}
				title="Files"
				width={500}
				styles={{ body: { display: 'flex', height: '100%', flexDirection: 'column' } }}
			>
				<StyledCard
					size="small"
					style={{ flexGrow: 1 }}
					actions={[
						<Tooltip title={"Apply Mapping"}>
							<Dropdown menu={{ items: itemsD, selectable: true, onSelect: selectMapping, selectedKeys: selectedMappings }} placement="bottomLeft">
								<SettingOutlined key="setting" />
							</Dropdown>
						</Tooltip>,
						<Tooltip title={"Preview File"}>
							<FileExcelOutlined key="edit" onClick={async () => {
								// const url = selectedFiles[0] && mappedFiles.data.find(file => file.file_id === selectedFiles[0]).file_url
								// const file = await fetch(url, { headers: { "Access-Control-Allow-Origin": "*" } }).then(res => res.arrayBuffer())
								const files = await filesDB.files.get(selectedFiles[0])
								setFile(files?.file)
								const workbook = XLSX.read(await files?.file.arrayBuffer(), { type: "array" })
								setParsedFile(workbook)
								loadFile(workbook, files?.id)
								setShowSpreadsheet(true)
							}} />
						</Tooltip>,
						<EllipsisOutlined key="ellipsis" />,
					]}
				>
					<DirectoryTree
						multiple
						defaultExpandAll
						onSelect={onSelect}
						onExpand={onExpand}
						treeData={treeData}
						expandAction={false}
						selectedKeys={selectedFiles}
					/>
				</StyledCard>
				<Form
					onFinish={handleFileUpload}
					layout="vertical"
					onChange={() => setUnsaved(true)}
					disabled={userAccess.permission === permissions.viewer || userAccess?.status !== projectStatus.in_progress}
					style={{ marginTop: 'auto' }}
				>
					<Space direction="vertical" style={{ width: "100%" }} size="large">
						<Form.Item>
							<Dragger {...uploadProps}>
								<p
									className="ant-upload-drag-icon"
								>
									<InboxOutlined />
								</p>
								<p
									className="ant-upload-text"
									style={{ padding: 16 }}
								>
									{t("dataUpload.dragnDrop")}
									<span style={{ color: blue[5] }}>
										{" "}
										{t("dataUpload.selectFile")}
									</span>
								</p>
							</Dragger>
						</Form.Item>
						{!!historyData.find((res: any) => res.file_name === file?.name)
							?.file_id ? (
							<Space>
								<Text strong>{`${t("workOrderDetails.note")} :`}</Text>
								<Text strong type="danger">{`${file?.name} ${t(
									"common.alreadyUploadedInThisProject"
								)}`}</Text>
							</Space>
						) : (
							""
						)}
					</Space>
				</Form>
			</Drawer>
			<iframe
				loading="lazy"
				ref={spreadsheet.ref}
				title="spreadsheet"
				id="spreadsheet"
				src={window.location.origin + "/spreadsheet/"}
				style={fullscreen ? maximiseStyle : { ...minimiseStyle, flexGrow: 1 }}
			/>
			<Tabs size="small" defaultActiveKey="1" items={items} onChange={onChange} tabPosition="bottom" style={{ flexGrow: 1, paddingRight: 200 }} tabBarExtraContent={{ left: <StyledButton type="primary" onClick={showDrawer} style={{ margin: 8 }}>Files</StyledButton>, right: <Space size={"large"} style={{ padding: 16 }}>{`Count: ( Ingredients: ${previewState?.ingredients?.length ?? 0}, Experiments: ${previewState?.experiments?.length ?? 0} )`}</Space> }} />
			<FloatButton
				shape="square"
				type="primary"
				style={{ right: 24, bottom: 64, width: 160, height: 45, maxWidth: 'unset' }}
				icon={<UploadOutlined />}
				description="Upload"
				onClick={upload}
			>Upload</FloatButton>
		</div>
	)
}

const PreviewTable = ({ data: _data, experiments }: any = { data: [], experiments: [] }) => {

	console.log("🚀 ~ file: ExcelMapper.tsx:461 ~ PreviewTable ~ _data:", _data)

	const regularTable = useRef<any>(null);

	useEffect(() => {
		const num_rows = _data?.length
		const row1 = _data?.[0] || {}
		const headers = [
			...(row1.unit ? ['unit'] : []),
			...(row1.category ? ['category'] : []),
			...(row1.method ? ['method'] : []),
			...(row1.variation ? ['variation'] : []),
			...experiments
		]
		const num_columns = headers.length
		function dataListener(x0: number, y0: number, x1: number, y1: number) {
			const data = headers.slice(x0, x1).map((col: any) => _data.slice(y0, y1).map((paramObj: any) => paramObj[col]))
			const row_headers = _data.slice(y0, y1).map((paramObj: any) => [paramObj[row1.type]])
			const column_headers = headers.slice(x0, x1).map((col: string) => [col])
			return {
				num_rows,
				num_columns,
				data,
				column_headers,
				row_headers
			};
		}
		regularTable.current.setDataListener(dataListener);
		regularTable.current.draw();
	}, [_data, experiments])

	return (
		<div style={{ height: 180 }}>
			<regular-table id="regularTable" ref={regularTable} />
		</div>
	)
}	
