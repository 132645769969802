import { createActions } from "redux-actions";

export enum WorkOrdersActionTypes {
    REQUEST = "WORK_ORDERS_REQUEST",
    SUCCESS = "WORK_ORDERS_SUCCESS",
    FAILURE = "WORK_ORDERS_FAILURE",
    CLEAR = "WORK_ORDERS_CLEAR",
}

export enum SelectTrialsActionTypes {
    SET_SELECTED_TRIALS = "SET_SELECTED_TRIALS"
}

export enum WorkOrdersFilterOptionsActionTypes {
    REQUEST = "WORK_ORDERS_FILTER_OPTIONS_REQUEST",
    SUCCESS = "WORK_ORDERS_FILTER_OPTIONS_SUCCESS",
    FAILURE = "WORK_ORDERS_FILTER_OPTIONS_FAILURE",
    CLEAR = "WORK_ORDERS_FILTER_OPTIONS_CLEAR",
}

export enum CheckWorkOrderExistsActionTypes {
    REQUEST = "CHECK_WORK_ORDER_EXISTS_REQUEST",
    SUCCESS = "CHECK_WORK_ORDER_EXISTS_SUCCESS",
    FAILURE = "CHECK_WORK_ORDER_EXISTS_FAILURE",
    CLEAR = "CHECK_WORK_ORDER_EXISTS_CLEAR",
}

export enum CloseAllWorkOrdersActionTypes {
    REQUEST = "CLOSE_ALL_WORK_ORDERS_REQUEST",
    SUCCESS = "CLOSE_ALL_WORK_ORDERS_SUCCESS",
    FAILURE = "CLOSE_ALL_WORK_ORDERS_FAILURE",
    CLEAR = "CLOSE_ALL_WORK_ORDERS_CLEAR",
}

export const {
    workOrdersRequest,
    workOrdersSuccess,
    workOrdersFailure,
    workOrdersClear,
    setSelectedTrials,
    checkWorkOrderExistsRequest,
    checkWorkOrderExistsSuccess,
    checkWorkOrderExistsFailure,
    checkWorkOrderExistsClear,
    closeAllWorkOrdersRequest,
    closeAllWorkOrdersSuccess,
    closeAllWorkOrdersFailure,
    closeAllWorkOrdersClear,
    workOrdersFilterOptionsRequest,
    workOrdersFilterOptionsSuccess,
    workOrdersFilterOptionsFailure,
    workOrdersFilterOptionsClear
} = createActions({
    [WorkOrdersActionTypes.REQUEST]: payload => payload,
    [WorkOrdersActionTypes.SUCCESS]: (data: any, count?: number) => { return ({ data, count }) },
    [WorkOrdersActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [WorkOrdersActionTypes.CLEAR]: () => { },
    [SelectTrialsActionTypes.SET_SELECTED_TRIALS]: payload => payload,
    [CheckWorkOrderExistsActionTypes.REQUEST]: (payload) => payload,
    [CheckWorkOrderExistsActionTypes.SUCCESS]: (response) => response,
    [CheckWorkOrderExistsActionTypes.FAILURE]: (error: string) => ({ error }),
    [CheckWorkOrderExistsActionTypes.CLEAR]: () => { },
    [CloseAllWorkOrdersActionTypes.REQUEST]: (payload) => payload,
    [CloseAllWorkOrdersActionTypes.SUCCESS]: (response) => response,
    [CloseAllWorkOrdersActionTypes.FAILURE]: (error: string) => ({ error }),
    [CloseAllWorkOrdersActionTypes.CLEAR]: () => { },
    [WorkOrdersFilterOptionsActionTypes.REQUEST]: (payload) => payload,
    [WorkOrdersFilterOptionsActionTypes.SUCCESS]: (data) => { return ({ data }) },
    [WorkOrdersFilterOptionsActionTypes.FAILURE]: (error: string) => ({ error }),
    [WorkOrdersFilterOptionsActionTypes.CLEAR]: () => { },
});
