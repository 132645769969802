import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Row, Col, Input, Typography, Space, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { AsyncStates } from "src/constants";
import { updateIngredientFamilyRequest, updateIngredientRequest } from "src/store/actions/inventoryV2";
import { fetchAdditiveRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import useTranslate from "src/utils/useTranslate";

const { Text } = Typography;

type InventoryAdditivesFormProps = {
  inventory_id?: string,
  ingredient: any,
  from: string
}

export const InventoryAdditivesForm = ({ inventory_id, ingredient, from }: InventoryAdditivesFormProps) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [t] = useTranslate();
  const [isFormUpdated, setIsFormUpdated] = useState(false)
  const additives = useSelector((state: StoreState) => state.repository.additives);

  useLayoutEffect(() => {
    dispatch(fetchAdditiveRequest());
  }, [dispatch]);

  const unitsList = useSelector((state: StoreState) => state.conversion.unitList);

  const additiveCategoryBasedOnName = useMemo(() => {
    const categoryIds = [...new Set(additives.data?.map((additive: any) => additive.category_id))]
    const categoriesData = categoryIds.map((categoryId: any) => {
      return {
        label: additives.data.find((additive: any) => additive.category_id === categoryId)?.category_name ?? t("formulations.type.uncategorized"),
        value: categoryId,
        key: categoryId,
        options: additives.data.filter((additive: any) => additive.category_id === categoryId).map((additive: any) => {
          return {
            label: additive.name,
            value: additive.additive_id
          }
        }).sort((a, b) => a.label.localeCompare(b.label))
      }
    })
    return categoriesData.sort((a, b) => a.label.localeCompare(b.label))
  }, [additives.data, t])

  const onFinish = (values: any) => {
    dispatch(
      from === 'family' ? updateIngredientFamilyRequest({
        ...ingredient,
        ...values,
      }) : updateIngredientRequest({
        ...ingredient,
        ...values,
        additive: values.additive.sort((a: any, b: any) => a.additive_name?.localeCompare(b.additive_name)).map((additive: any) => {
          const metaDataAdditive = additives.data.find((additiveOption: any) => additiveOption.additive_id === additive.additive_id)
          return {
            ...additive,
            ...(metaDataAdditive && ({
              additive_category_id: metaDataAdditive.category_id,
              additive_category_name: metaDataAdditive.category_name,
              additive_name: metaDataAdditive.name
            }))
          }
        })
      })
    );
    setIsFormUpdated(false)
  };

  return (
    <Row style={{ justifyContent: "center" }}>
      <Col span={23}>
        <Form
          size="small"
          form={form}
          name="ingredient-additives"
          onFinish={onFinish}
          onFieldsChange={() => {
            setIsFormUpdated(true)
          }}
        >
          <Form.List
            name={"additive"}
            initialValue={
              ingredient?.additive?.sort((a: any, b: any) => a.additive_name?.localeCompare(b.additive_name)) ?? [
                { category: "", name: "", quantity: "", unit: null },
              ]
            }
          >
            {(fields, { add, remove }) => (
              <>
                <StyledCard
                  bordered={false}
                  headStyle={{ padding: "0.5rem" }}
                  bodyStyle={{
                    padding: "0.5rem",
                    marginLeft: "0.5rem"
                  }}
                  title={
                    <Space>
                      <Form.Item><Text className="highlighted">{t("inventory.Additives")}</Text></Form.Item>
                      {!inventory_id &&
                        <Form.Item>
                          <StyledButton
                            type="dashed"
                            size="small"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            {t("common.Add New")}
                          </StyledButton>
                        </Form.Item>}
                    </Space>
                  }
                  extra={!inventory_id && <Form.Item><StyledButton
                    type="primary"
                    htmlType="submit"
                    disabled={!isFormUpdated}
                  // loading={
                  //   updateIngredientResponse.status ===
                  //   AsyncStates.LOADING
                  // }
                  >
                    {t("common.save")}
                  </StyledButton></Form.Item>}
                >
                  <Row
                    gutter={4}
                    align={"middle"}
                    style={{ paddingBottom: "5px" }}
                  >
                    <Col span={2}></Col>
                    {/* <Col span={5}>
                      <Text style={{ fontWeight: "bold" }}>Category</Text>
                    </Col> */}
                    <Col span={5}>
                      <Text style={{ fontWeight: "bold" }}>{t("common.Name")}</Text>
                    </Col>
                    <Col span={5}>
                      <Text style={{ fontWeight: "bold" }}>{t("inventory.Quantity")}</Text>
                    </Col>
                    <Col span={5}>
                      <Text style={{ fontWeight: "bold" }}>{t("common.unit")}</Text>
                    </Col>
                  </Row>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      <Row gutter={4} align={"middle"}>
                        {!inventory_id ?
                          <Col span={2}
                            style={{
                              marginBottom: "12px",
                            }}
                          >
                            <MinusCircleOutlined
                              disabled={!!inventory_id}
                              onClick={() => {
                                remove(name);
                              }}
                            />
                          </Col>
                          : null}
                        {/* <Col span={5}>
                          <Form.Item
                            {...restField}
                            name={[name, "category"]}
                            style={{ marginRight: 8 }}
                            rules={[
                              {
                                required: true,
                                message: "Category required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Category"
                              disabled={!!inventory_id}>
                              {categories.data.filter((additiveOption) => {
                                const additives = form.getFieldValue("additive").map((additive: any) => additive?.category)?.filter(Boolean)
                                return !additives.includes(additiveOption.name)
                              }).map((category: any) => (
                                <Select.Option value={category.name} key={category.id}>
                                  {category.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col> */}
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            name={[name, "additive_id"]}
                            style={{ marginRight: 8 }}
                            rules={[
                              {
                                required: true,
                                message: "Name required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder={t("common.Name")}
                              disabled={!!inventory_id}
                              filterOption={(input, option) => {
                                return option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                              optionFilterProp="children"
                              options={additiveCategoryBasedOnName}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            name={[name, "quantity"]}
                            style={{ marginRight: 8 }}
                          >
                            <Input disabled={!!inventory_id} placeholder={t("inventory.Quantity")} />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            name={[name, "unit"]}
                            style={{ marginRight: 8 }}
                          >
                            <Select showSearch placeholder={t("common.selectUnit")} disabled={!!inventory_id}>
                              {Array.from(
                                new Set(
                                  unitsList.map((res: any) => res.category.toLowerCase())
                                )
                              ).map((category: any) => (
                                <Select.OptGroup
                                  label={
                                    String(category).charAt(0).toLocaleUpperCase() +
                                    String(category).slice(1)
                                  }
                                >
                                  {unitsList
                                    .filter(
                                      (res: any) => res.category.toLowerCase() === category
                                    )
                                    .map((res: any, index: any) => (
                                      <Select.Option value={res.name} key={res.name + index}>
                                        {res.name}
                                      </Select.Option>
                                    ))}
                                </Select.OptGroup>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </StyledCard>
              </>
            )}
          </Form.List>
        </Form>
      </Col>
    </Row>
  );
};
