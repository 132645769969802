import { blue } from '@ant-design/colors'
import { LoadingOutlined } from '@ant-design/icons'
import { List, Modal, Spin, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'

const { Text } = Typography


export const WorkOrdersModal = ({ visible, resetData }: any) => {
    const [t] = useTranslate()
    const { checkPropertyExists, checkPropertyExistsStatus, inventoryData } = useSelector((state: StoreState) => state.inventory)

    return (
        <Modal maskClosable={false} bodyStyle={{ minHeight: 100, maxHeight: 500, overflowY: "auto" }}
            visible={visible}
            title={<Text style={{ color: blue[6] }}>{inventoryData?.name ?? ""}</Text>}
            footer={null}
            onCancel={resetData}>
            <Spin spinning={checkPropertyExistsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                {!!checkPropertyExists?.length ?
                    <List size="small" dataSource={checkPropertyExists} renderItem={(item: string) => <List.Item><Text strong>{item}</Text></List.Item>} />
                    : <Text strong>{t("inventory.NotusedinanyWorkOrder")}</Text>}	</Spin>
        </Modal>
    )
}
