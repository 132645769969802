import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { deleteFileAttachmentRequest, fetchFileListRequest } from "src/store/actions/workOrderDetails"
import { StoreState } from "src/store/configureStore"
import { StyledCard } from "src/styled_components/StyledCard"
import { FileListView } from "../FileListView"
import { FileListColumns } from "../FileListView/FileListView"
import Text from 'antd/lib/typography/Text'
import useTranslate from "src/utils/useTranslate"

interface FileUploadsProps {
    project?: Boolean
}

export const FileUploads = ({ project }: FileUploadsProps) => {
    const [t] = useTranslate()

    const dispatch = useDispatch()

    const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder || {})

    const attachments = useSelector((state: StoreState) => state.workOrderDetails.fileList)
    const uploadFileAttachmentStatus = useSelector((state: StoreState) => state.workOrderDetails.uploadFileAttachmentStatus)

    const { id: project_id }: { id: string } = useParams()

    useEffect(() => {
        dispatch(fetchFileListRequest({
            project_id
        }))

    }, [workOrder, project_id, dispatch])

    const deleteAttachment = useCallback((record: any) => {
        const { work_order_id, work_order_stage, file_id } = record

        dispatch(deleteFileAttachmentRequest(project ? {
            file_id,
            project_id,
            work_order_stage,
        } : {
            file_id,
            work_order_id,
            work_order_stage,
        }))
    }, [dispatch, project_id, project])


    return (
        <StyledCard>
            {!!attachments.length ? <FileListView columns={[FileListColumns.NAME, FileListColumns.WORK_ORDER, FileListColumns.STAGE, FileListColumns.UPLOADED_BY, FileListColumns.UPLOADED_ON, FileListColumns.DELETE, FileListColumns.DOWNLOAD]} fileList={attachments} uploadStatus={uploadFileAttachmentStatus} onDelete={deleteAttachment} /> : <Text strong>
                {t("project.noFileUploadedMessage")}</Text>}
        </StyledCard>
    )
}