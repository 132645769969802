import { Typography } from 'antd'
import { TEXT_PRIMARY, TEXT_SECONDARY } from '../Dashboard'
import { antdTheme } from 'src/constants'

type P = {
  count: number
  desc: string
  heading1Size?: number
  heading2Size?: number
  align?: string
}

const CountDesc = ({ count, desc, heading1Size=antdTheme.fontSizeHeading2, heading2Size=antdTheme.fontSizeHeading5, align='flex-start' }: P) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap:8,
        alignItems: align
      }}
    >
      <Typography.Title
        style={{
          fontSize: heading1Size,
          fontWeight: 600,
          color: `${TEXT_PRIMARY}`,
          margin: 0
        }}
      >
        {count}
      </Typography.Title>
      <Typography.Title
        style={{
          fontSize: heading2Size,
          fontWeight: 400,
          color: `${TEXT_SECONDARY}`,
          margin: 0
        }}
      >
        {desc}
      </Typography.Title>
    </div>
  )
}

export default CountDesc
