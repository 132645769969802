import { Divider, Dropdown, Input, MenuProps, Spin, Table, Typography } from 'antd';
import { useMemo, useState } from 'react';
import "./LabLocation.scss"
import { LabLocationModal } from './LabLocationModal/LabLocationModal'
import { EditOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'
import useTranslate from 'src/utils/useTranslate'
import { StyledButton } from 'src/styled_components/StyledButton'

const { Title } = Typography

export const labLocationInitialState = {
    selectedLabLocation: {},
    isModalVisible: false,
    mode: "create" as "create" | "edit",
}

export const LabLocation = () => {
    const [t] = useTranslate();
    const [labLocationModalState, setLabLocationModalState] = useState(labLocationInitialState);
    const labsLocationList = useSelector((state: StoreState) => state.settings.labsLocationList);
    const labsLocationListStatus = useSelector((state: StoreState) => state.settings.labsLocationListStatus);
    const [searchInput, setSearchInput] = useState("")

    const menuItems: MenuProps["items"] = [
        {
            label: t("common.edit"),
            key: "edit",
            icon: <EditOutlined />,
        },
        // {
        //     label: "Delete",
        //     key: "delete",
        //     icon: <DeleteOutlined />,
        //     danger: true,
        // },
    ];

    const handleMenuClick = (e: any, record: any) => {
        switch (e.key) {
            case "edit":
                setLabLocationModalState({ selectedLabLocation: record, isModalVisible: true, mode: "edit" })

                break;
            case "delete":

                break;
        }
    };

    const menuProps = (record: any) => {
        return {
            items: menuItems,
            onClick: (e: any) => handleMenuClick(e, record),
        };
    };

    const handleClose = () => {
        setLabLocationModalState(labLocationInitialState)
    }

    const filteredLabsLocationList = useMemo(() => {
        const searchTerm = searchInput?.trim()?.toLowerCase()
        if (!searchTerm?.length) {
            return labsLocationList
        }
        return labsLocationList.filter((labLocation) => {
            return (labLocation.city?.toLowerCase()?.includes(searchTerm) || labLocation.country?.toLowerCase()?.includes(searchTerm) || labLocation.name?.toLowerCase()?.includes(searchTerm))
        })
    }, [searchInput, labsLocationList])

    return (
        <div>
            <div className="lab__location__header__container">
                <Title level={4} className={"lab__location__title"}>{t("common.labLocations")}</Title>
                <StyledButton onClick={() => {
                    setLabLocationModalState((prev) => ({ ...prev, isModalVisible: true }))
                }}>{t("common.addLocation")}</StyledButton>

            </div>
            <Divider className='common__divider' />
            {labLocationModalState?.isModalVisible &&
                <LabLocationModal labLocationModalState={labLocationModalState} handleClose={handleClose} />
            }
            <Spin spinning={labsLocationListStatus === AsyncStates.LOADING}
                tip={`${t("common.loading")} ${t("common.labLocations")}`} indicator={<LoadingOutlined />} size="default"
            >
                <div className='table__header'>
                    <div className='table__header__search'>
                        <Input placeholder={`${t("common.searchHere")}...`} size='small' onChange={(e) => setSearchInput(e.target.value)} allowClear />
                    </div>
                </div>
                <Table
                    dataSource={filteredLabsLocationList}
                    pagination={false}
                    size='small'
                    rowKey={"lab_location_id"}
                    columns={[
                        {
                            title: t("common.name"),
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: t("profile.country"),
                            dataIndex: 'country',
                            key: 'country',
                        },
                        {
                            title: t("common.city"),
                            dataIndex: 'city',
                            key: 'city',
                        },
                        {
                            title: t("common.actions"),
                            dataIndex: 'actions',
                            key: 'actions',
                            width: 100,
                            render: (text, record) => (
                                <Dropdown menu={menuProps(record)} placement="bottomRight">
                                    <StyledButton size="small" icon={<MoreOutlined />} />
                                </Dropdown>
                            ),
                        },
                    ]}
                />
            </Spin>
        </div>
    )
}
