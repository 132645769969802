import { BadgeProps, Badge } from 'antd'
import React from 'react'
import { antdTheme } from 'src/constants'

const StyledBadge = React.forwardRef(
  (
    props: BadgeProps & React.RefAttributes<HTMLSpanElement>,
    ref: React.Ref<HTMLElement> | undefined
  ) => {
    return <Badge ref={ref} color={antdTheme.colorPrimary} {...props} />
  }
)

export default StyledBadge
