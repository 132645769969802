import React from "react";
import "./Profile.scss";
import MyDetails from "./MyDetails";
import { Card, Space } from "antd";
import useTranslate from "src/utils/useTranslate";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";


const Profile = () => {
  const [t] = useTranslate()
  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <StyledPageHeader title={t("common.profile")} ghost={false} />
      <Card bodyStyle={{padding:"16px"}}>
        <MyDetails />
      </Card>
    </Space>
  );
};

export default Profile;
