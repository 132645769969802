import Legend from './Legend'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardWorkorderStateCountsRequest } from 'src/store/actions/dashboard'
import { StoreState } from 'src/store/configureStore'
import { BORDER_STYLE, useStatusNames } from '../../Dashboard'
import CountDesc from '../CountDesc'
import Pie from './Pie'
import useTranslate from 'src/utils/useTranslate'
import { Spin } from 'antd'
import { AsyncStates } from 'src/constants'

const workorderColors: { [key: string]: string } = {
  work_order_initiated: '#3e61d7',
  formulation_stage: '#ea61dc',
  processing_stage: '#cfa128',
  characterization_stage: '#70bfd8',
  properties_stage: '#a70202',
  report_preview: '#05d341',
  closed: '#9e5dd1'
}

const PieChartWorkorder = () => {
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const {
    dashboardWorkorderStateCountsResponse,
    dashboardWorkorderStateCountsStatus
  } = useSelector((state: StoreState) => state.dashboard)

  const { workorderStatusNames } = useStatusNames()

  useEffect(() => {
    dispatch(dashboardWorkorderStateCountsRequest())
  }, [dispatch])

  const total = useMemo(() => {
    return dashboardWorkorderStateCountsResponse?.total || 0
  }, [dashboardWorkorderStateCountsResponse])

  const data = useMemo(() => {
    return Object.keys(dashboardWorkorderStateCountsResponse?.stages || {})
      .filter((key) => key !== 'work_order_status')
      .map((key) => ({
        title: key,
        name: workorderStatusNames[key],
        count: dashboardWorkorderStateCountsResponse?.stages[key],
        color: workorderColors[key]
      }))
  }, [dashboardWorkorderStateCountsResponse?.stages, workorderStatusNames])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 24px',
        width: '60%',
        gap: 4,
        outline: BORDER_STYLE
      }}
    >
      <Spin
        spinning={dashboardWorkorderStateCountsStatus === AsyncStates.LOADING}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              marginTop: 12
            }}
          >
            <CountDesc count={total} desc={t('common.workOrders')} />
          </div>
          <Pie data={data} total={total} />
        </div>
        <Legend list={data} />
      </Spin>
    </div>
  )
}

export default PieChartWorkorder
