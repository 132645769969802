import { EditOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import useTranslate from "src/utils/useTranslate";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useMemo, useState } from "react";
import IngredientTemplateProperties from "./IngredientTemplateProperties";
import IngredientTemplatsCustomFields from "./IngredientTemplateCustomFields";
import IngredientTemplateAdditives from "./IngredientTemplateAdditives";

type TProps = {
    selectedTemplateId: string;
    showEditTemplateModal: () => void;
};

const IngredientTemplateDetails = ({
    selectedTemplateId,
    showEditTemplateModal,
}: TProps) => {
    const [t] = useTranslate();
    const [activeTab, setActiveTab] = useState("properties");
    const templates = useSelector(
        (state: StoreState) => state.repository.templates.data
    );
    const selectedTemplate = useMemo(
        () => templates.find((t) => t.template_id === selectedTemplateId),
        [templates, selectedTemplateId]
    );
    const properties = useSelector(
        (state: StoreState) => state.repository.properties.data
    );
    const customFields = useSelector(
        (state: StoreState) => state.repository.fields.data
    );
    const additives = useSelector(
        (state: StoreState) => state.repository.additives.data
    );

    const propertiesByCategory = useMemo(() => {
        const propertyCategories = [
            ...new Set(
                selectedTemplate?.properties
                    .map(
                        (pid: any) =>
                            properties.find((p) => p.property_id === pid)?.category_name
                    )
                    .filter((category: string) => category)
            ),
        ] as string[];

        return (
            propertyCategories.map((category: string) => ({
                category,
                properties: selectedTemplate?.properties
                    ?.filter(
                        (id: any) =>
                            properties.find((p) => p.property_id === id)?.category_name ===
                            category
                    )
                    .map((id: any) => ({
                        id,
                        name: properties.find((p) => p.property_id === id)?.name,
                    })),
            })) || []
        );
    }, [selectedTemplate?.properties, properties]);

    return (
        <>
            <div className="template-details">
                <div className="header">
                    <div className="template-title">{selectedTemplate?.name}</div>
                    <div className="template-edit">
                        <EditOutlined onClick={showEditTemplateModal} />
                    </div>
                </div>
                <div className="content">
                    <Tabs
                        defaultActiveKey="1"
                        onChange={(tab) => setActiveTab(tab)}
                        activeKey={activeTab}
                        items={[
                            {
                                key: "properties",
                                label: t("common.properties"),
                                children: (
                                    <div className="template-tab-container">
                                        <IngredientTemplateProperties
                                            propertiesByCategory={propertiesByCategory}
                                            selectedTemplateId={selectedTemplateId}
                                        />
                                    </div>
                                ),
                            },
                            {
                                key: "custom_fields",
                                label: t("inventory.CustomFields"),
                                children: (
                                    <div className="template-tab-container">
                                        <IngredientTemplatsCustomFields
                                            title={t("repository.customFieldList")}
                                            selectedTemplateId={selectedTemplateId}
                                            fields={
                                                selectedTemplate?.custom_fields?.map((id: any) => ({
                                                    id,
                                                    name: customFields.find((c) => c.field_id === id)
                                                        ?.field_name,
                                                })) || []
                                            }
                                        />
                                    </div>
                                ),
                            },
                            {
                                key: "additives",
                                label: t("inventory.Additives"),
                                children: (
                                    <div className="template-tab-container">
                                        <IngredientTemplateAdditives
                                            title={t("repository.customFieldList")}
                                            selectedTemplateId={selectedTemplateId}
                                            additives={
                                                selectedTemplate?.additives?.map((id: any) => ({
                                                    id,
                                                    name: additives.find((a) => a.additive_id === id)
                                                        ?.name,
                                                    category: additives.find((a) => a.additive_id === id)
                                                        ?.category_name,
                                                })) || []
                                            }
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        </>
    );
};

export default IngredientTemplateDetails;
