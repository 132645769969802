import { LoadingOutlined } from "@ant-design/icons"
import {
  Space,
  Card,
  Spin,
  Tabs,
  Row,
  Select,
  message,
  Col,
  Form,
  Typography,
  Table,
} from "antd";
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates } from "src/constants"
import { celsureGetPlotsRequest } from "src/store/actions/celsureWo"
import { StoreState } from "src/store/configureStore"
import Plot from 'react-plotly.js'
import { StyledCard } from "src/styled_components/StyledCard"
import useTranslate from "src/utils/useTranslate"
import { useValue } from "src/utils/useValue"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
import { StyledButton } from "src/styled_components/StyledButton";

const { Option } = Select
const { Text } = Typography

const plotLayout: any = {
  xaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Time',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
  yaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Temperature',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
}


export const Plots = ({ form, trialRecord, from }: any) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const { getValue } = useValue()
  const requiredFieldStar = useRequiredFieldStar()

  const { celsureWo, ceslurePlotsData, ceslurePlotsStatus, celsureFileHistoryData, celsureWoTrials } = useSelector((state: StoreState) => state.celsureWo)
  const { formulationDetailsData } = useSelector((state: StoreState) => state.celsureCompareFormulation)
  const [selectedTrial, setSelectedTrial] = useState<any>("")
  const [tab, setTab] = useState("actual")
  const [maximumTempThreshold, setMaximumTempThreshold] = useState<any>()
  const [minimumTempThreshold, setMinimumTempThreshold] = useState<any>()
  // const [backupTime, setBackupTime] = useState({ minimum: "", maximum: "" })
  const [points, setPoints] = useState({ point_1: "-", point_2: "-", final_point: "-" })
  const [plotData, setPlotData] = useState<any>({})
  const [sensor, setSensor] = useState("")
  const [plotsForm] = Form.useForm()

  const calculationTableColumns: any = useMemo(() => [{
    dataIndex: 'point_1',
    title: 'Threshold Minimum',
    key: 'Point 1',
    align: "center"
  }, {
    dataIndex: 'point_2',
    title: 'Threshold Maximum',
    key: 'Point 2',
    align: "center"
  },
  {
    dataIndex: 'final_point',
    title: "Backup Time",
    key: 'Final Point',
    align: "center"
  }], [])

  useEffect(() => {
    if (from === "work-order") {
      dispatch(celsureGetPlotsRequest(({ work_order_id: celsureWo?.work_order_id })))
    } else if (from === "compare_formulation") {
      dispatch(celsureGetPlotsRequest(({ formulation_id: formulationDetailsData.map((res: any) => res?.trial_id) })))
    }
  }, [dispatch, celsureWo?.work_order_id, formulationDetailsData, from])

  useEffect(() => {
    if (ceslurePlotsStatus === AsyncStates.SUCCESS) {
      setSelectedTrial(ceslurePlotsData?.[0]?.trial_id || "")
      plotsForm.setFieldsValue({ trial: ceslurePlotsData?.[0]?.trial_id })
    }
  }, [ceslurePlotsStatus, ceslurePlotsData, plotsForm])

  useEffect(() => {
    setPlotData((ceslurePlotsData || [])?.find((ele: any) => ele.trial_id === selectedTrial))
  }, [ceslurePlotsData, selectedTrial])

  useEffect(() => {
    setTab(!!Object.keys(plotData?.actual || {}).length ? "actual" : "theoretical")
  }, [plotData?.actual])

  const graphsClickEvent = useCallback((e: any) => {
    const point = e.points[0]
    if (selectedTrial !== trialRecord?.data?.trial_id) {
      message.error(`Edit trial ${plotData?.trial_display_id} from the table to populate points`)
      return
    }
    if (point?.data?.name?.toLowerCase() !== sensor?.toLowerCase()) {
      message.error(`Please click points on the ${sensor} sensor`)
      return
    }
    // form.setFieldsValue({
    //   backup_time_value: getValue(point?.x, 2) || ""
    // })
    setPoints((prevState: any) => {
      const obj = { ...prevState }
      if (obj.point_1 === "-") {
        obj.point_1 = JSON.stringify({ x: getValue(point.x), y: getValue(point.y) })
      } else {
        obj.point_2 = JSON.stringify({ x: getValue(point.x), y: getValue(point.y) })
      }
      if (obj.point_1 !== "-" && obj.point_2 !== "-") {
        const point_1 = Number(JSON.parse(obj.point_1).x)
        const point_2 = Number(JSON.parse(obj.point_2).x)
        const difference = (point_2 - point_1)
        obj.final_point = getValue(difference)
      }
      return obj
    })
  }, [selectedTrial, trialRecord, plotData?.trial_display_id, sensor, getValue])

  useEffect(() => {
    const minimum = [...(from === "work-order" ? celsureWoTrials : formulationDetailsData)]?.find((ele: any) => ele?.trial_id === selectedTrial)?.threshold_minimum_temperature?.value || 0
    const maximum = [...(from === "work-order" ? celsureWoTrials : formulationDetailsData)]?.find((ele: any) => ele?.trial_id === selectedTrial)?.threshold_maximum_temperature?.value || 0
    setMinimumTempThreshold(minimum)
    setMaximumTempThreshold(maximum)
    plotsForm.setFieldsValue({
      minimum_threshold: minimum,
      maximum_threshold: maximum
    })
  }, [celsureWoTrials, formulationDetailsData, selectedTrial, plotsForm, from])

  const formulationGraphs = useMemo(() => {
    if (ceslurePlotsStatus === AsyncStates.SUCCESS) {
      return (
        <Plot onClick={tab === "actual" ? graphsClickEvent : () => { }} key={selectedTrial}
          layout={{
            title: plotData?.trial_display_id, showlegend: true, hovermode: 'closest', ...plotLayout
          }}
          data={[
            {
              x: plotData?.[tab]?.["Time"] || plotData?.[tab]?.["time"] as any,
              y: plotData?.[tab]?.[ceslurePlotsData.find((element: any) => element?.trial_id === selectedTrial)?.trial_display_id] || plotData?.[tab]?.["trial_data"] as any,
              type: 'scatter',
              name: 'Ambient Temperature',
            },
            ...(Object.keys(plotData?.[tab] || {}).filter((res: any) => !["time", "Time", "trial_data", ceslurePlotsData.find((element: any) => element?.trial_id === selectedTrial)?.trial_display_id].includes(res))
              .map((res: any) => ({
                x: plotData?.[tab]?.["time"] || plotData?.[tab]?.["Time"] as any,
                y: plotData?.[tab]?.[res] || [] as any,
                type: 'scatter',
                name: res.toUpperCase(),
              }))),
            ...((!!minimumTempThreshold || !!maximumTempThreshold) ? [
              !!minimumTempThreshold ? {
                x: plotData?.[tab]?.["time"] || plotData?.[tab]?.["Time"] as any,
                y: (plotData?.[tab]?.["time"] || plotData?.[tab]?.["Time"])?.map((res: any) => minimumTempThreshold),
                type: 'scatter',
                name: 'Threshold Minimum Temperature',
              } : {},
              !!maximumTempThreshold ? {
                x: plotData?.[tab]?.["time"] || plotData?.[tab]?.["Time"] as any,
                y: (plotData?.[tab]?.["time"] || plotData?.[tab]?.["Time"])?.map((res: any) => maximumTempThreshold),
                type: 'scatter',
                name: 'Threshold Maximum Temperature',
              } : {}] as any : []),
          ]}
        />
      )
    } else return null
  }, [selectedTrial, ceslurePlotsStatus, plotData, tab, graphsClickEvent, maximumTempThreshold, minimumTempThreshold, ceslurePlotsData])

  // const calculateBackupTime = (values: any) => {
  //   const { type } = values
  //   if (selectedTrial !== trialRecord?.data?.trial_id) {
  //     message.error(`Edit trial ${plotData?.trial_display_id} from the table to populate points`)
  //     return
  //   }
  //   if (!minimumTempThreshold && !maximumTempThreshold) {
  //     message.error("Add the threshold values")
  //     return
  //   }
  //   const closestValueMinimun: any = plotData[tab][type].reduce((prev: any, curr: any) => Math.abs(curr - minimumTempThreshold) < Math.abs(prev - minimumTempThreshold) ? curr : prev)
  //   const closestValueMaximum: any = plotData[tab][type].reduce((prev: any, curr: any) => Math.abs(curr - maximumTempThreshold) < Math.abs(prev - maximumTempThreshold) ? curr : prev)
  //   const indexMin = plotData[tab][type].indexOf(closestValueMinimun)
  //   const indexMax = plotData[tab][type].indexOf(closestValueMaximum)
  //   setBackupTime({
  //     minimum: getValue((plotData[tab]?.["time"] || plotData[tab]?.["Time"])?.[indexMin], 2) ?? "",
  //     maximum: getValue((plotData[tab]?.["time"] || plotData[tab]?.["Time"])?.[indexMax], 2) ?? "",
  //   })
  //   message.success("Backup time calculated")
  //   //  document?.getElementById?.('upload-form')?.scrollIntoView()
  // }

  const sensorOptions = useMemo(() => Object.keys(plotData?.[tab] || {})
    .filter((sensor) => ![ceslurePlotsData.find((element: any) => element?.trial_id === selectedTrial)?.trial_display_id, "time", "Time", "trial_data"].includes(sensor))
    .filter((filteredSensor) => !!plotData?.[tab]?.[filteredSensor]?.length), [plotData, tab, ceslurePlotsData, selectedTrial],)


  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      {!!ceslurePlotsData?.length &&
        <Spin spinning={ceslurePlotsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
          <Card title={"Trial Plots"}>
            <Space direction="vertical" style={{ width: "100%" }} size="large">
              <Row style={{ marginBottom: 20 }} justify="space-between">
                <Col span={12}>
                  <Space direction="vertical" style={{ width: "100%" }} size="large">
                    <StyledCard>
                      <Form layout="vertical" requiredMark={false} form={plotsForm} onChange={() => form.validateFields(["minimum_threshold", "maximum_threshold"])}>
                        <Form.Item label="Select Trial" name="trial" rules={[{ required: true, message: t("common.required") }]}

                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select value={selectedTrial} style={{ width: "100%" }} onChange={(e) => {
                            // setBackupTime({ minimum: "", maximum: "" })
                            setSensor("")
                            setPoints({ point_1: "-", point_2: "-", final_point: "-" })
                            setSelectedTrial(e)
                          }}>
                            {ceslurePlotsData?.map((res: any) => (
                              <Option value={res.trial_id}>{res.trial_display_id}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {(from === "work-order" && celsureWo?.work_order_stage === "properties_stage") && <>
                          <Form.Item label="Select sensor" name={"type"} rules={[{ required: true, message: t("common.required") }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <Select onChange={(e) => {
                              setSensor(e)
                              setPoints({ point_1: "-", point_2: "-", final_point: "-" })
                            }
                            } >
                              {sensorOptions.map((sensor) =>
                                <Option value={sensor}>{sensor?.toLocaleUpperCase()}</Option>
                              )}
                            </Select>
                          </Form.Item>
                          {/* <Form.Item label={t("plots.thresholdMin")} name={"minimum_threshold"} rules={[{ required: true, message: t("common.required") }, ({ getFieldValue }) => ({
                            validator(_, value) {
                              const threshold_maximum_temperature = getFieldValue('maximum_threshold')
                              if (value && threshold_maximum_temperature && value > threshold_maximum_temperature) {
                                return Promise.reject(new Error('Minimum should be less than maximum'))
                              }
                              return Promise.resolve()
                            },
                          })]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <InputNumber value={minimumTempThreshold} onChange={(value: any) => {
                              if (value <= maximumTempThreshold) {
                                setBackupTime({ minimum: "", maximum: "" })
                                setMinimumTempThreshold(value)
                              }
                            }} />
                          </Form.Item>
                          <Form.Item label={t("plots.thresholdMax")} name={"maximum_threshold"} rules={[{ required: true, message: t("common.required") }, ({ getFieldValue }) => ({
                            validator(_, value) {
                              const threshold_minimum_temperature = getFieldValue('minimum_threshold')
                              if (value && threshold_minimum_temperature && value < threshold_minimum_temperature) {
                                return Promise.reject(new Error('Maximum should be more than minimum'))
                              }
                              return Promise.resolve()
                            },
                          })]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <InputNumber value={maximumTempThreshold} onChange={(value: any) => {
                              if (value >= minimumTempThreshold) {
                                setBackupTime({ minimum: "", maximum: "" })
                                setMaximumTempThreshold(value)
                              }
                            }} />
                          </Form.Item>
                          <Row justify="end">
                            <StyledButton type={"primary"} htmlType="submit">{"Calculate"}</StyledButton>
                          </Row>   */}
                        </>
                        }
                      </Form>
                    </StyledCard>
                  </Space>
                </Col>
                <Col span={10}>
                  {/* {(from === "work-order" && celsureWo?.work_order_stage === "properties_stage") &&
                    <Descriptions column={1} bordered>
                      <Descriptions.Item label={"Backup till Threshold Maximum"}>{backupTime?.maximum}</Descriptions.Item>
                      <Descriptions.Item label={"Backup till Threshold Minimum"}>{backupTime?.minimum}</Descriptions.Item>
                    </Descriptions>
                  } */}
                  <Space size="large" direction="vertical">
                    <Table caption={() => <Text strong>{`Calculate Backup Time`}</Text>}
                      columns={calculationTableColumns} dataSource={[points]} pagination={false} style={{ marginTop: 20 }} />
                    <Row justify="end">
                      <Space size="large">
                        <StyledButton type="dashed" onClick={() => setPoints({ point_1: "-", point_2: "-", final_point: "-" })}>{"Reset"} </StyledButton>
                        <StyledButton type="primary" disabled={points?.final_point === "-"} onClick={() => {
                          form.setFieldsValue({
                            backup_time_value: points?.final_point,
                            minimum_temperature: JSON.parse(points?.point_1)?.y,
                            maximum_temperature: JSON.parse(points?.point_2)?.y
                          })
                        }}>{`Add Data`}</StyledButton>
                      </Space>

                    </Row>
                  </Space>
                </Col>
              </Row>
              {celsureFileHistoryData.find((value: any) => value.trial_id === selectedTrial) && <Tabs onChange={(e) => setTab(e)} >
                {celsureFileHistoryData.find((value: any) => value.trial_id === selectedTrial).actual && JSON.stringify(celsureFileHistoryData.find((value: any) => value.trial_id === selectedTrial).actual) !== '{}' &&
                  <Tabs.TabPane key="actual" tab="Actual Experiment">
                    <Space direction="vertical" style={{ width: "100%" }} size="large">
                      <Row justify="center" style={{ height: "500px" }}>
                        {formulationGraphs}
                      </Row>
                    </Space>
                  </Tabs.TabPane>}
                {celsureFileHistoryData.find((value: any) => value.trial_id === selectedTrial).theoretical && JSON.stringify(celsureFileHistoryData.find((value: any) => value.trial_id === selectedTrial).theoretical) !== '{}' &&
                  <Tabs.TabPane key="theoretical" tab="Simulation">
                    <Space direction="vertical" style={{ width: "100%" }} size="large">
                      <Row justify="center" style={{ height: "500px" }}>
                        {formulationGraphs}
                      </Row>
                    </Space>
                  </Tabs.TabPane>}
              </Tabs>}
            </Space>
          </Card>
        </Spin>}
    </Space >
  )
}