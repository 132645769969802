
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Box-Behnken`}</h1>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Box%E2%80%93Behnken_design#:~:text=In%20statistics%2C%20Box%E2%80%93Behnken%20designs,1%2C%200%2C%20%2B1."
      }}>{`Box-Behnken`}</a>{` designs are used to generate higher order response surfaces using fewer required runs than a normal factorial technique]. This and the central composite techniques essentially suppress selected runs in an attempt to maintain the higher order surface definition.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Box%E2%80%93Behnken_design#:~:text=In%20statistics%2C%20Box%E2%80%93Behnken%20designs,1%2C%200%2C%20%2B1."
      }}>{`Box-Behnken`}</a>{` design is an independent quadratic design in that it does not contain an embedded factorial or fractional factorial design. In this design the treatment combinations are at the midpoints of edges of the process space and at the center. These designs are rotatable (or near rotatable) and require 3 levels of each factor. The designs have limited capability for orthogonal blocking compared to the central composite designs.`}</p>
    <p><strong parentName="p">{`Use Cases:-`}</strong>{` `}</p>
    <p>{`The Box-Behnken design (BBD) has been widely used in `}</p>
    <ul>
      <li parentName="ul">{`Pharmaceuticals`}</li>
      <li parentName="ul">{`Bioprocessing`}</li>
      <li parentName="ul">{`Food engineering`}</li>
      <li parentName="ul">{`Agrochemicals`}</li>
      <li parentName="ul">{`and other industries`}</li>
    </ul>
    <p>{`To extract biological active compounds, such as polysaccharides, phenolic
compounds, and proteins from various sources, intended for human use.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;