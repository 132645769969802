import { Table, Typography } from 'antd'
import useTranslate from 'src/utils/useTranslate'
import { TEXT_PRIMARY, BORDER_STYLE } from '../Dashboard'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { dashboardRecentEditsRequest } from 'src/store/actions/dashboard'
import { AsyncStates } from 'src/constants'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'

const { Text } = Typography

const RecentEdits = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { dashboardRecentEditsResponse, dashboardRecentEditsStatus } =
    useSelector((state: StoreState) => state.dashboard)

  useEffect(() => {
    dispatch(dashboardRecentEditsRequest())
  }, [dispatch])

  const recentEditsColumn = [
    {
      title: t('common.workOrder'),
      dataIndex: 'work_order_name',
      render: (text: any) => (
        <Text
          style={{
            minWidth: '50px',
            maxWidth: '100px',
            margin: 0,
            fontWeight: 600
          }}
           className='dashboard-recent-edits-work-order-name'
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Text>
      )
    },

    {
      title: t('common.project'),
      dataIndex: 'project_name',
      render: (text: any) => (
        <Text
          style={{
            minWidth: '50px',
            maxWidth: '100px',
            margin: 0
          }}
          ellipsis={{ tooltip: text }}
        >
          {text}
        </Text>
      )
    },
    {
      title: t('common.lastEdited'),
      dataIndex: 'updated',
      render: (text: any) => (
        <Text
          style={{
            minWidth: '50px',
            maxWidth: '100px',
            margin: 0
          }}
          ellipsis={{ tooltip: text }}
        >
          {dayjs(text).fromNow()}
        </Text>
      )
    }
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        outline: BORDER_STYLE,
      }}
    >
      <HeaderSection />
      <Table
        dataSource={dashboardRecentEditsResponse.map((item, index) => ({
          ...item,
          key: index
        }))}
        columns={recentEditsColumn}
        pagination={false}
        style={{ width: '100%', padding: '0px 0px' }}
        className="dashboard-recent-edits-table"
        loading={dashboardRecentEditsStatus === AsyncStates.LOADING}
        onRow={(record) => {
          return {
            onClick: () => {
              push(`/work-orders/details/${record?.work_order_id}/`)
            }
          }
        }}
      />
    </div>
  )
}

export default RecentEdits

const HeaderSection = () => {

  const [t] = useTranslate()

  return (
    <div
      style={{
        padding: '12px 12px',
        borderBottom: BORDER_STYLE,
        display: 'flex',
        height: '60px',
        alignItems: 'center',
        gap: '16px'
      }}
    >
      <Typography.Title
        style={{
          color: `${TEXT_PRIMARY}`,
          lineHeight: '16px',
          margin: 0,
          flexShrink: 0
        }}
        level={5}
      >
        {t('common.myRecentEdits')}
      </Typography.Title>
    </div>
  )
}
