import { Space } from 'antd'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { celsureReportsRetrieveRequest } from 'src/store/actions/celsureCompareFormulation';
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import { CelsureExperiments } from '../CelsureExperiments/CelsureExperiments';
import { WorkOrderReportPreview } from '../WorkOrderDetails/WorkOrderReportPreview';

export const CelsureCompareFormulation = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(celsureReportsRetrieveRequest())
    }, [dispatch])

    return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <StyledPageHeader ghost={false} title="Compare" onBack={() => window.history.back()} />
            <CelsureExperiments from={"compare_formulation"} />
            <WorkOrderReportPreview from={"compare_formulation"} />
        </Space>
    )
}
