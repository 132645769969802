/* eslint-disable import/no-webpack-loader-syntax */
import { useCallback, useEffect, useMemo, useState } from "react"
import { Space, Typography, Spin, Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { clearOtherStuff, listAlgorithmsRequest, selectAlgo } from "src/store/actions/doe"
import { StoreState } from "src/store/configureStore"
import { LoadingOutlined } from "@ant-design/icons"
import { AsyncStates } from "src/constants"
import { motion } from 'framer-motion';
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledButton } from "src/styled_components/StyledButton"
import { CustomPrompt } from "src/utils/CustomPrompts"
import useTranslate from "src/utils/useTranslate"
import { trackEvent } from "src/analytics"
import { useHistory } from 'react-router-dom'
import shortDesc from "./shortDesc.json"

const { Text, Title, Paragraph } = Typography

export default function NewExperiment() {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const { push } = useHistory();
  const { listAlgorithmsStatus, listAlgorithmsData } = useSelector((state: StoreState) => state.doe)
  const [descModalVisible, setDescModalVisible] = useState<boolean>(false)
  const [descModalData, setDescModalData] = useState<any>({})
  const isEditing = useSelector((state: StoreState) => state.isEditing)
  const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)

  const currentLanguage = useSelector(
    (state: StoreState) => state.language.current
  )

  useEffect(() => {
    dispatch(clearOtherStuff())
  }, [dispatch])

  useEffect(() => {
    dispatch(listAlgorithmsRequest())
  }, [dispatch, currentLanguage])

  useEffect(() => {
    if (listAlgorithmsStatus === AsyncStates.INITIAL)
      dispatch(listAlgorithmsRequest())
  }, [dispatch, listAlgorithmsStatus])


  const getMdText = useCallback((key: any, value: any) => {
    if (currentLanguage === "ko") {
      return (shortDesc as any)[key]
    }

    return { info: value.info, title: key.split("_").map((res: any) => res.charAt(0).toUpperCase() + res.slice(1)).join(" ") }
  }, [currentLanguage]);

  const displayMdText = useMemo(() => {
    const componentFileNames: { [key: string]: string } = {
      _ff2n: "two_ff",
      _bbdesign: "bb",
      _ccdesign: "cc",
      _fullfact: "ff",
      _gsd: "gs",
      _lhs: "lhs",
      _pbdesign: "pb",
      _taguchi: "Taguchi",
    };

    // Get the component name based on the mapping, or default to "BoxBemhken"
    const componentName = componentFileNames[descModalData?.name] || "bb";

    // Use dynamic import to load the component
    let ComponentToRender;
    try {
      const module = require(`!babel-loader!@mdx-js/loader!../../doe_desc/${currentLanguage}/${componentName}.mdx`);
      ComponentToRender = module.default || module;
    } catch (error) {
      // Handle the case where the component for the selected language is not found
      ComponentToRender = require(`!babel-loader!@mdx-js/loader!../../doe_desc/en/${componentName}.mdx`);
    }

    return <ComponentToRender />;
  }, [descModalData, currentLanguage]);

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
    >
      <CustomPrompt isEditing={isEditing} message={`${t("common.unsavedChangesLost")}!.`} />
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        <StyledCard title={<div >
          <Title level={3}>{t("common.generateNewExperiments")}</Title>
          <Text type={"secondary"}>{t("newExpeiment.selectYourDesign")}</Text>
        </div>}>
        </StyledCard >

        <Spin spinning={listAlgorithmsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
          <Space size="large" direction="vertical" style={{ width: "100%" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                gap: "0.5rem",
              }}
            >
              {Object.entries(listAlgorithmsData || {}).map(([key, value]: any) => (
                <StyledCard
                  hoverable
                  key={key}
                  title={getMdText(key, value).title}
                  style={{
                    minWidth: 350,
                    border: "1px solid rgb(225 228 232 / 1)",
                  }}>
                  <Paragraph ellipsis={{ rows: 2, symbol: 'more', tooltip: getMdText(key, value).info }}>
                    {getMdText(key, value).info}
                  </Paragraph>
                  <Space style={{ marginTop: 10 }}>
                    <StyledButton type="primary" onClick={() => { trackEvent(userId, 'DOE -> user selected ' + value.name + ' algorithm', { value }); push(`/generate-new-experiments/${value.name}`); dispatch(selectAlgo(value)) }}>{t("common.select")}</StyledButton>
                    <StyledButton type="link" ghost onClick={() => {
                      setDescModalData(value)
                      setDescModalVisible(true)
                    }}>{t("newExperiment.learnMore")}</StyledButton>
                  </Space>
                </StyledCard>
              ))}
            </div>
          </Space>
        </Spin>

        <Modal
          title={Object.entries(listAlgorithmsData || {}).find(([key, value]: any) => descModalData.name === value.name)?.[0]?.split("_").map((res: any) => res.charAt(0).toUpperCase() + res.slice(1)).join(" ")}
          visible={descModalVisible}
          onCancel={() => setDescModalVisible(false)}
          okText={t("common.select")}
          cancelText={t("common.cancel")}
          width={600}
          onOk={() => { trackEvent(userId, 'DOE -> user selected ' + descModalData.name + ' algorithm', { descModalData }); setDescModalVisible(false); push(`/generate-new-experiments/${descModalData.name}`); dispatch(selectAlgo(descModalData)) }}>
          <Space direction="vertical" style={{ width: "100%", maxHeight: 350, overflowY: "auto" }}>
            {displayMdText}
          </Space>
        </Modal>
      </Space >
    </motion.div>
  )
}

