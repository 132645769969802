import { blue } from "@ant-design/colors";
import { NumberOutlined, MenuOutlined } from "@ant-design/icons";
import { Popover, Typography } from "antd";
import { useCallback, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { ModelConfig } from "src/typings";
import useTranslate from "src/utils/useTranslate";
// import styles from './Sorter.module.scss'

const { Text } = Typography;

interface SorterProps {
  list: string[];
  onChange: any;
  dataset: string;
  aiEngineVersionData?: ModelConfig | undefined;
  linkedTrialIdDisplayName?: { [key: string]: string }
}

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  border: `1px solid ${isDragging ? blue.primary : "#f0f0f0"}`,
  background: "white",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "baseline",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const grid = 6;

const getListStyle = (isDraggingOver: boolean) => ({
  padding: grid,
  width: 160,
  maxHeight: 300,
  overflow: "auto",
});

export const Sorter = ({
  list,
  onChange,
  dataset,
  aiEngineVersionData,
  linkedTrialIdDisplayName = {} // for ingredients only used for forward prediction
}: SorterProps) => {
  const datasetLabelsSelector = useCallback(
    (state: StoreState) => state.displayNames.data[dataset] || {},
    [dataset]
  );
  const datasetLabels = useSelector(datasetLabelsSelector);
  const [t] = useTranslate();

  const items = useMemo(
    () =>
      list.map((id) => ({ value: id, label: datasetLabels[id]?.name ?? linkedTrialIdDisplayName?.[id] ?? id })),
    [list, datasetLabels, linkedTrialIdDisplayName]
  );

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(list, result.source.index, result.destination.index);
    // dispatch(setEditingState(true));
    onChange(items);
  };

  const content = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item: any, index: number) => (
              <Draggable
                key={item.value}
                draggableId={item.value}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <MenuOutlined style={{ color: "#d0d0d0" }} />
                    <Text
                      ellipsis={{ tooltip: item.label }}
                      style={{ width: 80 }}
                    >
                      {aiEngineVersionData?.display_names?.[dataset]?.[
                        item.label
                      ] ?? item.label}
                    </Text>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <>
      {!!list?.length && (
        <Popover content={content} trigger="click">
          <StyledButton size='small' icon={<NumberOutlined />}>{t("common.reorder")}</StyledButton>
        </Popover>
      )}
    </>
  );
};
