import { Modal, Space, Table, Tabs, Typography } from 'antd'
import { ProfileGraph } from '../ProfileGraph';
import "src/components/WorkOrderDetails/WorkOrderTable.scss"
import { useMemo } from 'react';

const { Text } = Typography


export const ViewProfiles = ({ viewModalVisible, setViewModalVisible, currentProfile, setCurrentProfile }: any) => {

    const tableData = useMemo(() => {
        if (!!currentProfile?.data?.length) {
            return [
                ...currentProfile?.data,
                { parameter: "Total", time: currentProfile?.data.reduce((sum: number, element: any) => sum + Number(element.time), 0), temp: "-" }
            ]
        }
        return []
    },
        [currentProfile.data])

    return (
        <Modal
            width={800}
            maskClosable={false}
            footer={null}
            open={viewModalVisible}
            onCancel={() => { setCurrentProfile({}); setViewModalVisible(false) }}
            title={currentProfile?.name}>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <Tabs size='large'>
                    <Tabs.TabPane key={"data"} tab={"Data"}>
                        <Table
                            style={{ marginBottom: 30 }}
                            columns={[{
                                key: "time",
                                dataIndex: "time",
                                title: "Time (min)",
                                align: "center",
                                render: (text: any, record: any) => record?.parameter === "Total" ? <Text strong>{`Total: ${text}`}</Text> : text
                            },
                            {
                                key: "temperature",
                                dataIndex: "temperature",
                                title: "Temperature (degC)",
                                align: "center"
                            }
                            ]}
                            dataSource={tableData}
                            pagination={false}
                            bordered
                            rowClassName={(record, index) => {
                                if (record.parameter === "Total") {
                                    return "highlight-row"
                                }
                                return ""
                            }}
                        />
                        <ProfileGraph tableData={currentProfile?.data || []} />

                    </Tabs.TabPane>
                    {currentProfile?.costing?.length &&
                        <Tabs.TabPane key={"costing"} tab={"Costing"}>
                            <Table
                                size='small'
                                columns={[{
                                    key: "parameter",
                                    dataIndex: "parameter",
                                    title: "Parameter",
                                    align: "left",
                                    render: (text: string) => <Text strong type="secondary">{text}</Text>
                                },
                                {
                                    key: "value",
                                    dataIndex: "value",
                                    title: "Value",
                                    align: "center"
                                }
                                ]}
                                dataSource={currentProfile?.costing || []}
                                pagination={false}
                                bordered
                            />
                        </Tabs.TabPane>
                    }
                </Tabs>
            </Space>
        </Modal>
    )
}
