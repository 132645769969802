import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	catDataSummaryFailure,
	catDataSummaryRequest,
	catDataSummarySuccess,
} from "src/store/actions/catDataSummary"
import {
	postIndividualCategoricalSummary,
	postOverallCategoricalSummary,
} from "src/services/dataSummary"
import { messages } from "src/utils/hooks"
import { APIError, APIResponse } from "src/typings"
import { CategoricalSummary } from "src/components/AIEngine/DataSummary/types"
import { StoreState } from "../configureStore"
import { LanguageUnion } from "src/utils/useTranslate"
import jwtManager from "src/utils/jwtManager"

type CatDataSummaryPayload = {
	payload: {
		work_order_ids: string[]
		type?: "ingredients" | "processing" | "properties",
		characterization_set_id?: "string"
		formulation_id?: "string",
		normalise?: boolean,
		stage_identifier?: string | undefined,
		experiment_ids?: string[],
		with_processing_profile?: boolean,
		formulation_ids?: string[]
	}
}

function* catDataSummarySaga({
	payload,
}: CatDataSummaryPayload): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }

		const { status, data }: APIResponse<CategoricalSummary> = yield call(
			payload.type
				? postIndividualCategoricalSummary
				: postOverallCategoricalSummary,
			payload.type
				? {
					work_order_ids: payload.work_order_ids, type: payload.type,
					characterization_set_id: payload?.characterization_set_id, normalise: payload?.normalise, stage_identifier: payload.stage_identifier, experiment_ids: payload.experiment_ids, with_processing_profile: payload?.with_processing_profile,
					formulation_ids: payload?.formulation_ids
				}
				: { work_order_ids: payload.work_order_ids, characterization_set_id: payload?.characterization_set_id, normalise: payload?.normalise, stage_identifier: payload.stage_identifier, experiment_ids: payload.experiment_ids, with_processing_profile: payload?.with_processing_profile, formulation_ids: payload?.formulation_ids },
			headers
		)

		if (status === 200) {
			yield put(catDataSummarySuccess(data))
		} else {
			yield put(catDataSummaryFailure(messages[ln].internal_server_error))
		}
	} catch (err: unknown) {
		if (err instanceof Error) {
			const error = (err as unknown) as APIError
			yield put(
				catDataSummaryFailure(Array.isArray(error?.response?.data?.detail) ? messages[ln].internal_server_error : (error?.response?.data?.detail ?? messages[ln].internal_server_error))
			)
		} else {
			const error = err as APIError
			if (error.response.status === 500) {
				yield put(catDataSummaryFailure(messages[ln].internal_server_error))
			} else {
				yield put(
					catDataSummaryFailure(Array.isArray(error?.response?.data?.detail) ? messages[ln].internal_server_error : (error?.response?.data?.detail ?? messages[ln].internal_server_error))
				)
			}
		}
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(catDataSummaryRequest, catDataSummarySaga)
}
