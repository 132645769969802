import { TranslationKey } from "src/utils/useTranslate"

export const isTrialValueExceeded = (
	value: number,
	min: number,
	max: number
) => {
	if (value < min) return true
	if (value > max) return true
	return false
}
export function getTranslatedIngredientsAndProcessing(
	input: string,
	t: (key: TranslationKey) => string
) {
	if (input === "ingredients") return t("common.ingredients")
	if (input === "processing") return t("common.processing")
	return input
}
