import { Input, Modal } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";

export function CreateGroupModal({
  createGroupModal,
  setCreateGroupModal,
  handleCreateGroup,
}: any) {
  const handleOk = () => {
    const input: any = document.getElementById("group-name");
    handleCreateGroup(input?.value);
    setCreateGroupModal(false);
  };

  return (
    <Modal
      title={<h3>Create new group</h3>}
      open={createGroupModal}
      onCancel={() => setCreateGroupModal(false)}
      footer={
        <>
          <StyledButton
            size="middle"
            onClick={() => setCreateGroupModal(false)}
          >
            Cancel
          </StyledButton>
          <StyledButton type="primary" size="middle" onClick={() => handleOk()}>
            Ok
          </StyledButton>
        </>
      }
    >
      <Input id="group-name" type="text" placeholder="Group name" />
    </Modal>
  );
}
