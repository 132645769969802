import { Checkbox, Modal, Row, Space, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'

const { Text } = Typography

interface P {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    selectedTrials: number[]
    displayIdList: string[]
    createCopiedTrials: () => void
    copyTrials: any
    setCopyTrials: React.Dispatch<React.SetStateAction<any>>
}


export const CopyTrialsModal = ({ visible, setVisible, selectedTrials, displayIdList, createCopiedTrials, copyTrials, setCopyTrials }: P) => {
    const [t] = useTranslate()
    const onSliceSelect = (e: CheckboxChangeEvent, key: string) => {
        setCopyTrials((prevState: any) => ({ ...prevState, [key]: e.target.checked }))
    }

    const closeModal = () => {
        setCopyTrials({
            ingredients: true,
            processing: true,
            characterizations: true,
            properties: true,
        })
        setVisible(false)
    }


    return (
        <Modal
            title={t("workOrderDetails.copyAndCreateTrials")}
            open={visible}
            onCancel={closeModal}
            footer={null}>
            <Space style={{ width: "100%" }} direction="vertical" size={"large"}>
                <Text strong type="secondary">{`Copy ${displayIdList.filter((res: any, index: number) => selectedTrials.includes(index)).join(", ")} ?`}</Text>
                <Space style={{ width: "100%" }} direction="vertical">
                    <Checkbox
                        disabled
                        checked={copyTrials?.ingredients}
                        onChange={(e) => onSliceSelect(e, "ingredients")}
                    >
                        <Text strong>{`${t("workOrderDetails.include")} ${t(
                            "common.ingredients"
                        )}`}</Text>
                    </Checkbox>
                    <Checkbox
                        checked={copyTrials?.processing}
                        onChange={(e) => onSliceSelect(e, "processing")}
                    >
                        <Text strong>{`${t("workOrderDetails.include")} ${t(
                            "common.processing"
                        )}`}</Text>
                    </Checkbox>
                    <Checkbox
                        checked={copyTrials?.characterizations}
                        onChange={(e) => onSliceSelect(e, "characterizations")}
                    >
                        <Text strong>
                            {`${t("workOrderDetails.include")} ${t(
                                "common.characterizations"
                            )}`}
                        </Text>
                    </Checkbox>
                    <Checkbox
                        checked={copyTrials?.properties}
                        onChange={(e) => onSliceSelect(e, "properties")}
                    >
                        <Text strong>{`${t("workOrderDetails.include")} ${t(
                            "common.properties"
                        )}`}</Text>
                    </Checkbox>
                </Space>
                <Row justify='end'>
                    <Space direction="horizontal">
                        <StyledButton type="default" onClick={closeModal}>
                            {t("common.cancel")}
                        </StyledButton>
                        <StyledButton type="primary" onClick={createCopiedTrials}>
                            {t("common.create")}
                        </StyledButton>
                    </Space>
                </Row>
            </Space>
        </Modal>
    )
}
