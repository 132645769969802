import { Row, Space } from "antd";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslate from "src/utils/useTranslate";
import FilterButton from "./FilterButton";
import { StoreState } from "src/store/configureStore";
import FilterDisplay from "./FilterDisplay";
import { StyledCard } from "src/styled_components/StyledCard";
import { StyledButton } from "src/styled_components/StyledButton";
import { setIsEditing } from "src/store/actions/isEditing";
import { AsyncStates } from "src/constants";
import { fetchAllCategoriesRequest } from "src/store/actions/repository";
import React from "react";
import InventoryUtils from "src/modules/InventoryV2/utils";
import { useValue } from "src/utils/useValue";

const initialIndicesState = {
  ingredients: -1,
  processing_profile: -1,
  processing: -1,
  characterizations: -1,
  characterization_method: -1,
  characterization_method_parameters: -1,
  properties: -1,
  characterization_method_properties: -1,
  work_order_id: -1,
  stage: -1,
  user_id: -1,
  material: -1,
  application: -1,
  order_type: -1,
  grade: -1,
  lab_locations: -1
};

const FilterButtons = ({
  currentInnerIndex,
  setCurrentInnerIndex,
  filters,
  clearFilters,
  setCreateNewFilterModalVisible,
  selectedProjects,
  badgeColors,
  setFilters,
  setFormState,
  form,
  setInnerSelector,
  setOuterSelector
}: any) => {
  const [t] = useTranslate();
  const dispatch = useDispatch()
  const currentOuterIndex = 0;

  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  );
  const properties = useSelector(
    (state: StoreState) => state.displayNames.data?.properties,
  );
  const allMethods = useSelector(
    (state: StoreState) => state.repository.allMethods.data
  );

  // const labsLocationList = useSelector((state: StoreState) => state.settings.labsLocationList);
  const ingredientAndPropertyCategories = useSelector((state: StoreState) => state.repository.allCategories.data);
  const ingredientAndPropertyCategoriesStatus = useSelector((state: StoreState) => state.repository.allCategories.status);

  const propertiesLabels = useMemo(() => {
    return Object.entries(properties || {}).reduce(
      (acc: any, [key, value]: any) => ({
        ...acc,
        [key]: value?.category,
      }),
      {},
    );
  }, [properties]);

  useEffect(() => {
    dispatch(fetchAllCategoriesRequest())
  }, [dispatch])

  const { getValue: getLocalValue } = useValue();

  const ingredients = useSelector(
    (state: StoreState) => state.displayNames.data?.ingredients,
  );
  const ingredientsLabels = useMemo(() => {
    return Object.entries(ingredients || {}).reduce(
      (acc: any, [key, value]: any) => ({
        ...acc,
        [key]: value?.category === null ? "uncategorized" : value?.category,
      }),
      {},
    );
  }, [ingredients]);

  const getCategoryNamefromId = useCallback((categoryId: string, type: "ingredient_category" | "property_category" = "ingredient_category") => {
    return ingredientAndPropertyCategories[type]?.find((category: any) => category.category_id === categoryId)?.name ?? categoryId
  }, [ingredientAndPropertyCategories])

  const processing = useSelector(
    (state: StoreState) => state.displayNames.data?.processing,
  );
  const processingLabels = useMemo(() => {
    return Object.entries(processing || {}).reduce(
      (acc: any, [key, value]: any) => ({
        ...acc,
        [key]: String(value?.category || t("formulations.type.uncategorized")),
      }),
      {},
    );
  }, [processing, t]);
  const processingProfiles = useSelector(
    (state: StoreState) => state.workOrderDetails.processingProfilesList,
  );

  const characterizations = useSelector(
    (state: StoreState) => state.displayNames.data?.characterizations,
  );
  const characterizationLabels = useMemo(() => {
    return Object.entries(characterizations || {}).reduce(
      (acc: any, [key, value]: any) => ({
        ...acc,
        [key]: String(value?.category || t("formulations.type.uncategorized")),
      }),
      {},
    );
  }, [characterizations, t]);
  const methodList = useSelector(
    (state: StoreState) => state.characterizationMethods.methodList,
  );
  const configs = useSelector((state: StoreState) => state.configs.features);
  const sortedMethodList = useMemo(
    () => methodList.sort((a, b) => a.name?.localeCompare(b.name)),
    [methodList],
  );

  const arraySorter = (inputArray: string[] = []): string[] | [] => {
    const sortedArray = inputArray?.sort((a: any, b: any) =>
      a?.localeCompare(b),
    );
    return sortedArray || [];
  };

  const filterDataNew = useMemo(() => {
    const responseData: any = [
      {
        value: "ingredients",
        label: t("common.ingredients"),
        children:
          ingredientsLabels &&
          arraySorter(
            Array.from(new Set(Object.values(ingredientsLabels))),
          )?.map((label: any) => ({
            label: String(getCategoryNamefromId(label, "ingredient_category") || t("formulations.type.uncategorized")),
            value: label,
            children: Object.entries(ingredientsLabels)
              .filter(([key, value]) => value === label)
              .map(([key]) => ({
                label: `${ingredients?.[key]?.name}(${ingredients?.[key]?.unit?.[0] || ""})`,
                value: key,
              }))
              .sort((a: any, b: any) => a.label.localeCompare(b.label)),
          })),
      },
    ];
    if (Boolean(configs?.processing_profile) && processingProfiles?.length) {
      responseData.push({
        value: "processing_profile",
        label: t("formulations.type.processing"),
        key: "processing_profile",
        children: processingProfiles.map((res: any) => ({
          label: res?.name,
          value: res?.profile_id,
        }))
        .sort((a: any, b: any) => a.label.localeCompare(b.label)),
      });
    }
    if (!Boolean(configs?.processing_profile)) {
      responseData.push({
        value: "processing",
        label: t("formulations.type.processing"),
        children:
          processingLabels &&
          arraySorter(Array.from(new Set(Object.values(processingLabels)))).map(
            (label) => ({
              label: String(label || t("formulations.type.uncategorized")),
              value: label,
              children: Object.entries(processingLabels)
                .filter(([key, value]) => value === label)
                .map(([key]) => ({
                  label: `${processing?.[key]?.name}(${processing?.[key]?.unit || ""})`,
                  value: key,
                }))
                .sort((a: any, b: any) => a.label.localeCompare(b.label)),
            }),
          ),
      });
    }
    responseData.push({
      value: "characterizations",
      label: t("common.characterizations"),
      key: "characterizations",
      children:
        characterizationLabels &&
        arraySorter(
          Array.from(new Set(Object.values(characterizationLabels))),
        ).map((label) => ({
          label: String(label || t("formulations.type.uncategorized")),
          value: label,
          children: Object.entries(characterizationLabels)
            .filter(([key, value]) => value === label)
            .map(([key]) => ({
              label: `${characterizations?.[key]?.name}(${characterizations?.[key]?.unit || ""})`,
              value: key,
            }))
            .sort((a: any, b: any) => a.label.localeCompare(b.label)),
        })),
    });
    if (
      Boolean(configs?.characterization_methods) &&
      !!sortedMethodList?.length
    ) {
      responseData.push({
        value: "characterization_method",
        label: "Methods",
        key: "characterization_method",
        children: sortedMethodList.map((res: any) => ({
          label: res?.name,
          value: res?.variations?.[0]?.variation_id,
          // children: res?.variations.map((val: any) => ({
          //     label: val?.name,
          //     value: val?.variation_id
          // }))
        }))
        .sort((a: any, b: any) => a.label.localeCompare(b.label)),
      });
      responseData.push({
        value: "characterization_method_parameters",
        label: "Characterization Parameters in Methods",
        key: "characterization_method_parameters",
        children: sortedMethodList.map((res: any) => ({
          label: res?.name,
          value: res?.variations?.[0]?.variation_id,
          // children: res?.variations.map((val: any) => ({
          //     label: val?.name,
          //     value: val?.variation_id,
          children: Object.entries(displayNames?.characterizations || {}).map(
            ([key, value]: any) => ({
              label: value?.name,
              value: key,
            }),
          ).sort((a: any, b: any) => a.label.localeCompare(b.label)),
        })),
        // }))
      });
      responseData.push({
        value: "properties",
        label: t("formulations.type.properties"),
        children: arraySorter(
          Array.from(new Set(Object.values(propertiesLabels))),
        ).map((label) => ({
          label: String(label || t("formulations.type.uncategorized")),
          value: label,
          children: Object.entries(propertiesLabels)
            .filter(([key, value]) => value === label)
            .map(([key]) => ({
              label: `${properties?.[key]?.name}(${properties?.[key]?.unit || ""})`,
              value: key,
            }))
            .sort((a: any, b: any) => a.label.localeCompare(b.label)),
        })),
      });
      responseData.push({
        value: "characterization_method_properties",
        label: "Properties Parameters in Methods",
        key: "characterization_method_properties",
        children: sortedMethodList.map((res: any) => ({
          label: res?.name,
          value: res?.variations?.[0]?.variation_id,
          // children: res?.variations.map((val: any) => ({
          //     label: val?.name,
          //     value: val?.variation_id,
          children: res.variations?.[0]?.properties
            ?.sort((a: any, b: any) => a.parameter?.localeCompare(b.parameter))
            ?.map(({ parameter }: any) => ({
              label: displayNames.properties?.[parameter]?.name ?? parameter,
              value: parameter,
            }))
            .sort((a: any, b: any) => a.label.localeCompare(b.label)),
        })),
        // }))
      });
    }
    if (!Boolean(configs?.characterization_methods)) {
      responseData.push({
        value: "properties",
        label: t("formulations.type.properties"),
        children: arraySorter(
          Array.from(new Set(Object.values(propertiesLabels))),
        ).map((label) => ({
          label: getCategoryNamefromId(label, "property_category"),
          value: label,
          children: Object.entries(propertiesLabels)
            .filter(([key, value]) => value === label)
            .map(([key]) => {
                const displayNamesProperty = properties?.[key];
                const method = allMethods?.find(
                  (method) => method.method_id === displayNamesProperty?.method_id
                );
                return {
                  label: !!method
                    ? InventoryUtils.getNameWithParameters(
                        displayNamesProperty?.name,
                        method.parameters,
                        getLocalValue
                      )
                    : `${displayNamesProperty?.name}(${
                        displayNamesProperty?.unit || ""
                      })`,
                  value: key,
                };
            })
            .sort((a: any, b: any) => a.label.localeCompare(b.label)),
        })),
      });
    }
    responseData.push({
      value: "work_order_id",
      label: t("common.workOrders"),
      key: "Work Orders",
    });
    responseData.push({
      value: "stage",
      label: t("common.stages"),
      key: "stage",
    });
    responseData.push({
      value: "user_id",
      label: t("common.users"),
      key: "users",
    });

    if (!!Object.keys(displayNames?.material || {}).length) {
      responseData.push({
        value: "material",
        label: t("common.material"),
        key: "material",
      });
    }
    if (!!Object.keys(displayNames?.application || {}).length) {
      responseData.push({
        value: "application",
        label: t("common.application"),
        key: "application",
      });
    }
    if (!!Object.keys(displayNames?.order_type || {}).length) {
      responseData.push({
        value: "order_type",
        label: t("workOrderDetails.orderType"),
        key: "order_type",
      });
    }
    if (!!Object.keys(displayNames?.grade || {}).length) {
      responseData.push({
        value: "grade",
        label: t("common.grade"),
        key: "grade",
      });
    }
    // if (!!labsLocationList.length) {
    //   responseData.push({
    //     value: "lab_locations",
    //     label: "Lab Locations",
    //     key: "lab_locations",
    //   });
    // }
    return responseData;
  }, [displayNames, t, ingredients, ingredientsLabels, processing, processingLabels, properties, propertiesLabels, characterizations, characterizationLabels, sortedMethodList, processingProfiles, configs, getCategoryNamefromId, allMethods, getLocalValue]);

  const [appliedFiltersIndices, setAppliedFiltersIndices] = useState<any>(initialIndicesState);

  const apiFilterFormulationsStatus = useSelector<StoreState, AsyncStates>((state) => state.formulationsFilter.status);
  const isEditing = useSelector((state: StoreState) => state.isEditing)

  useEffect(() => {

    if (apiFilterFormulationsStatus === AsyncStates.SUCCESS && isEditing) {
      dispatch(setIsEditing(false))
    }
  }, [apiFilterFormulationsStatus, dispatch, isEditing])


  useEffect(() => {
    if (Object.keys(filters ?? {}).length > 0) {
      dispatch(setIsEditing(true))
    }

    setAppliedFiltersIndices((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(initialIndicesState))
      filters?.data?.[currentOuterIndex]?.data.forEach((p: any, idx: number) => {
        const currIdx = prevState[p.parameter_type]
        newState[`${p.parameter_type}`] = currIdx + 1

        setCurrentInnerIndex(idx + 1)
      })
      return { ...newState }
    })

    if (Object.keys(filters ?? {}).length < 1) {
      setAppliedFiltersIndices((prevState: any) => {
        const updatedState = JSON.parse(JSON.stringify(prevState));
        Object.keys(updatedState).forEach((state) => {
          updatedState[state] = -1;
        });
        return updatedState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const [showMore, setShowMore] = useState<boolean>(false)

  return (
    <React.Fragment key={'filter-buttons'} >
      <Row align="middle" gutter={[8, 8]} style={{ marginLeft: 0, marginRight: 0 }}>
        <Space
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {!showMore ? filterDataNew.filter((filter: any) => ['ingredients', 'processing', 'properties', 'characterizations', 'work_order_id', 'stage', "user_id"].includes(filter.value)).map((filter: any) => {
            const parameterCascaderData = ['ingredients', 'properties']?.includes(filter.value) ? ingredientAndPropertyCategoriesStatus === AsyncStates.LOADING ? [] : filter.children : filter.children;
            return (
              <FilterButton
                selectedProjects={selectedProjects}
                currentInnerIndex={currentInnerIndex}
                setCurrentInnerIndex={setCurrentInnerIndex}
                currentOuterIndex={currentOuterIndex}
                parameterId={filter.value}
                parameterText={filter.label}
                parameterCascaderData={parameterCascaderData}
                appliedFiltersIndices={appliedFiltersIndices}
                setAppliedFiltersIndices={setAppliedFiltersIndices}
                badgeColors={badgeColors}
                clearFilters={clearFilters}
                filters={filters}
                setFilters={setFilters}
                setFormState={setFormState}
                form={form}
                setOuterSelector={setOuterSelector}
                setInnerSelector={setInnerSelector}
              />
            );
          })
            : filterDataNew.map((filter: any) => {
              return (
                <FilterButton
                  selectedProjects={selectedProjects}
                  currentInnerIndex={currentInnerIndex}
                  setCurrentInnerIndex={setCurrentInnerIndex}
                  currentOuterIndex={currentOuterIndex}
                  parameterId={filter.value}
                  parameterText={filter.label}
                  parameterCascaderData={filter.children}
                  appliedFiltersIndices={appliedFiltersIndices}
                  setAppliedFiltersIndices={setAppliedFiltersIndices}
                  badgeColors={badgeColors}
                  clearFilters={clearFilters}
                  filters={filters}
                  setFilters={setFilters}
                  setFormState={setFormState}
                  form={form}
                  setOuterSelector={setOuterSelector}
                  setInnerSelector={setInnerSelector}

                />
              );
            })}
          <StyledButton onClick={() => setShowMore(prev => !prev)} type="primary" ghost>
            {showMore ? t("formulations.showLess") : t("common.showMore")}
          </StyledButton>
        </Space>
      </Row>
      {!(Object.keys(filters ?? {}).length < 1) && (
        <StyledCard bodyStyle={{ padding: "0.5rem 0rem" }}>
          <FilterDisplay
            filters={filters}
            badgeColors={badgeColors}
            setFilters={setFilters}
            clearFilters={clearFilters}
            setAppliedFiltersIndices={setAppliedFiltersIndices}
            setCreateNewFilterModalVisible={setCreateNewFilterModalVisible}
            setCurrentInnerIndex={setCurrentInnerIndex}
          />
        </StyledCard>
      )}
    </React.Fragment>
  );
};

export default FilterButtons;
