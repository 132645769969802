import { createActions } from "redux-actions";

export enum FetchDashboardDataActionTypes {
  REQUEST = "FETCH_DASHBOARD_DATA_REQUEST",
  SUCCESS = "FETCH_DASHBOARD_DATA_SUCCESS",
  FAILURE = "FETCH_DASHBOARD_DATA_FAILURE",
}

export enum FetchNewsActionTypes {
  REQUEST = "FETCH_NEWS_REQUEST",
  SUCCESS = "FETCH_NEWS_SUCCESS",
  FAILURE = "FETCH_NEWS_FAILURE",
}

export enum DashboardRecentEditsActionTypes {
  REQUEST = "DASHBOARD_RECENT_EDITS_REQUEST",
  SUCCESS = "DASHBOARD_RECENT_EDITS_SUCCESS",
  FAILURE = "DASHBOARD_RECENT_EDITS_FAILURE",
}

export enum DashboardUsedIngredientsActionTypes {
  REQUEST = "DASHBOARD_USED_INGREDIENTS_REQUEST",
  SUCCESS = "DASHBOARD_USED_INGREDIENTS_SUCCESS",
  FAILURE = "DASHBOARD_USED_INGREDIENTS_FAILURE",
}

export enum DashboardWorkorderStateCountsActionTypes {
  REQUEST = "DASHBOARD_WORKORDER_STATE_COUNTS_REQUEST",
  SUCCESS = "DASHBOARD_WORKORDER_STATE_COUNTS_SUCCESS",
  FAILURE = "DASHBOARD_WORKORDER_STATE_COUNTS_FAILURE",
}

export enum DashboardProjectStateCountsActionTypes {
  REQUEST = "DASHBOARD_PROJECT_STATE_COUNTS_REQUEST",
  SUCCESS = "DASHBOARD_PROJECT_STATE_COUNTS_SUCCESS",
  FAILURE = "DASHBOARD_PROJECT_STATE_COUNTS_FAILURE",
}

export enum DashboardActivityCardTotalActionTypes {
  REQUEST = "DASHBOARD_ACTIVITY_CARD_TOTAL_REQUEST",
  SUCCESS = "DASHBOARD_ACTIVITY_CARD_TOTAL_SUCCESS",
  FAILURE = "DASHBOARD_ACTIVITY_CARD_TOTAL_FAILURE",
}

export const {
  fetchDashboardDataRequest,
  fetchDashboardDataSuccess,
  fetchDashboardDataFailure,
  fetchNewsRequest,
  fetchNewsSuccess,
  fetchNewsFailure,

  dashboardRecentEditsRequest,
  dashboardRecentEditsSuccess,
  dashboardRecentEditsFailure,

  dashboardUsedIngredientsRequest,
  dashboardUsedIngredientsSuccess,
  dashboardUsedIngredientsFailure,

  dashboardWorkorderStateCountsRequest,
  dashboardWorkorderStateCountsSuccess,
  dashboardWorkorderStateCountsFailure,

  dashboardProjectStateCountsRequest,
  dashboardProjectStateCountsSuccess,
  dashboardProjectStateCountsFailure,

  dashboardActivityCardTotalRequest,
  dashboardActivityCardTotalSuccess,
  dashboardActivityCardTotalFailure,
} = createActions({
  [FetchDashboardDataActionTypes.REQUEST]: (payload) => payload,
  [FetchDashboardDataActionTypes.SUCCESS]: (payload) => payload,
  [FetchDashboardDataActionTypes.FAILURE]: (error: any) => error,
  [FetchNewsActionTypes.REQUEST]: (payload) => payload,
  [FetchNewsActionTypes.SUCCESS]: (payload) => payload,
  [FetchNewsActionTypes.FAILURE]: (error: any) => error,

  [DashboardRecentEditsActionTypes.REQUEST]: (payload) => payload,
  [DashboardRecentEditsActionTypes.SUCCESS]: (payload) => payload,
  [DashboardRecentEditsActionTypes.FAILURE]: (error: any) => error,

  [DashboardUsedIngredientsActionTypes.REQUEST]: (payload) => payload,
  [DashboardUsedIngredientsActionTypes.SUCCESS]: (payload) => payload,
  [DashboardUsedIngredientsActionTypes.FAILURE]: (error: any) => error,

  [DashboardWorkorderStateCountsActionTypes.REQUEST]: (payload) => payload,
  [DashboardWorkorderStateCountsActionTypes.SUCCESS]: (payload) => payload,
  [DashboardWorkorderStateCountsActionTypes.FAILURE]: (error: any) => error,

  [DashboardProjectStateCountsActionTypes.REQUEST]: (payload) => payload,
  [DashboardProjectStateCountsActionTypes.SUCCESS]: (payload) => payload,
  [DashboardProjectStateCountsActionTypes.FAILURE]: (error: any) => error,

  [DashboardActivityCardTotalActionTypes.REQUEST]: (payload) => payload,
  [DashboardActivityCardTotalActionTypes.SUCCESS]: (payload) => payload,
  [DashboardActivityCardTotalActionTypes.FAILURE]: (error: any) => error,
});