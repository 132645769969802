import { List } from "antd";
import { GPTConversation } from "../interfaces";
import { GPTConversationListItem } from "./GPTConversationListItem";
import { AsyncStates } from "src/constants";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";

type ConversationHistoryItemProps = {
  conversations: GPTConversation[];
  isFavourites?: boolean;
};

export const GPTConversationList = ({
  conversations,
  // isFavourites = false
}: ConversationHistoryItemProps) => {
  const { status: conversationsStatus } = useSelector(
    (state: StoreState) => state.polyGpt.getConversations
  );

  return (
    <List
      itemLayout="vertical"
      size="small"
      loading={conversationsStatus === AsyncStates.LOADING}
      pagination={conversations?.length > 0 && {
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 10,
        size: "small"
      }}
      className="gpt-list"
      // style={{
      //   height: "55vh",
      //   overflowY: "auto"
      // }}
      dataSource={conversations}
      renderItem={(item) => <GPTConversationListItem conversation={item} />}
    />
  );
};
