import { handleActions } from "redux-actions";
import {
    FetchPropertyActionTypes,
    FetchCategoriesActionTypes,
    CreateCategoriesActionTypes,
    UpdateCategoriesActionTypes,
    RemoveCategoriesActionTypes,
    FetchAdditivesActionTypes,
    CreateAdditivesActionTypes,
    UpdateAdditivesActionTypes,
    RemoveAdditivesActionTypes,
    FetchFieldActionTypes,
    CreateFieldActionTypes,
    UpdateFieldActionTypes,
    RemoveFieldActionTypes,
    FetchParametersActionTypes,
    CreateParametersActionTypes,
    UpdateParametersActionTypes,
    RemoveParametersActionTypes,
    CreatePropertyActionTypes,
    UpdatePropertyActionTypes,
    RemovePropertyActionTypes,
    FetchPropertyParmetersActionTypes,
    FetchIngredientTemplatesActionTypes,
    CreateIngredientTemplatesActionTypes,
    UpdateIngredientTemplatesActionTypes,
    RemoveIngredientTemplatesActionTypes,
    FetchMethodsActionTypes,
    CreateMethodsActionTypes,
    UpdateMethodsActionTypes,
    RemoveMethodsActionTypes,
    FetchAllCategoriesActionTypes,
    FetchPropertyUnitsActionTypes,
    MarkPropertyUnitDefaultActionTypes,
    FetchAllMethodsActionTypes,
} from "../actions/repository";
import { AsyncStates } from "../../constants";

export type RepositoryState = {
    properties: {
        status: AsyncStates;
        error: string;
        data: {
            [key: string]: any;
        }[];
    };
    categories: {
        status: AsyncStates;
        error: string;
        data: {
            [key: string]: any;
        }[];
    };
    additives: {
        status: AsyncStates;
        error: string;
        data: {
            [key: string]: any;
        }[];
    };
    fields: {
        status: AsyncStates;
        error: string;
        data: {
            [key: string]: any;
        }[];
    };
    parameters: {
        status: AsyncStates;
        error: string;
        data: {
            [key: string]: any;
        }[];
    };
    allMethods: {
        status: AsyncStates;
        error: string;
        data: {
            [key: string]: any;
        }[];
    };
    methods: {
        status: AsyncStates;
        error: string;
        data: {
            [key: string]: any;
        }[];
    };
    templates: {
        status: AsyncStates;
        error: string;
        data: {
            [key: string]: any;
        }[];
    };
    allCategories: {
        status: AsyncStates;
        error: string | null;
        data: {
            [key: string]: any[];
        };
    };
    propertyUnits: {
        status: AsyncStates;
        error: string | null;
        data: {
            [key: string]: any[];
        };
    }
};

const defaultState: RepositoryState = {
    properties: {
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
    },
    categories: {
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
    },
    additives: {
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
    },
    fields: {
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
    },
    parameters: {
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
    },
    allMethods: {
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
    },
    methods: {
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
    },
    templates: {
        status: AsyncStates.INITIAL,
        error: "",
        data: [],
    },
    allCategories: {
        status: AsyncStates.INITIAL,
        error: null,
        data: {},
    },
    propertyUnits: {
        status: AsyncStates.INITIAL,
        error: null,
        data: {},
    }
};

const repositoryReducer = handleActions(
    {
        [FetchPropertyActionTypes.REQUEST]: (state) => ({
            ...state,
            properties: {
                status: AsyncStates.LOADING,
                error: "",
                data: [],
            },
        }),
        [FetchPropertyActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                properties: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: action?.payload?.data ?? [],
                },
            };
        },
        [FetchPropertyActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                properties: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [],
                },
            };
        },
        [FetchPropertyActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            properties: {
                status: AsyncStates.ERROR,
                error: action.payload?.error,
                data: [],
            },
        }),

        [FetchPropertyParmetersActionTypes.REQUEST]: (state) => ({
            ...state,
            parameters: {
                ...state.parameters,
                status: AsyncStates.LOADING,
                error: "",
            },
        }),
        [FetchPropertyParmetersActionTypes.SUCCESS]: (state, action: any) => {
            const combinedParameters = [
                ...state.parameters.data,
                ...action.payload.data,
            ];
            const uniqueParameters = [
                ...new Set(combinedParameters.map((cp) => cp.parameter_id)),
            ];

            return {
                ...state,
                parameters: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...uniqueParameters.map((parameter_id) => {
                            const parameterIndex = combinedParameters.findLastIndex(
                                (cd: any) => cd.parameter_id === parameter_id
                            );
                            return combinedParameters[parameterIndex];
                        }),
                    ],
                },
            };
        },
        [FetchPropertyParmetersActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            parameters: {
                ...state.parameters,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [CreatePropertyActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                properties: {
                    ...state.properties,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [CreatePropertyActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                properties: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [...state.properties.data, action.payload.data],
                },
            };
        },
        [CreatePropertyActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            properties: {
                ...state.properties,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [UpdatePropertyActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                properties: {
                    ...state.properties,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [UpdatePropertyActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                properties: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.properties.data.map((c) => {
                            if (c.property_id === action.payload.property_id)
                                return { ...c, ...action.payload.data };
                            else return c;
                        }),
                    ],
                },
            };
        },
        [UpdatePropertyActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            properties: {
                ...state.properties,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [RemovePropertyActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                properties: {
                    ...state.properties,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [RemovePropertyActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                properties: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.properties.data.filter(
                            (c) => !action.payload.property_ids.includes(c.property_id)
                        ),
                    ],
                },
            };
        },
        [RemovePropertyActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            properties: {
                ...state.properties,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        // ---------------------------------------------------------------------------------------------

        [FetchCategoriesActionTypes.REQUEST]: (state) => ({
            ...state,
            categories: {
                status: AsyncStates.LOADING,
                error: "",
                data: [],
            },
        }),
        [FetchCategoriesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                categories: {
                    data: action?.payload?.data ?? [],
                    status: AsyncStates.SUCCESS,
                    error: "",
                },
            };
        },
        [FetchCategoriesActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                categories: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [],
                },
            };
        },
        [FetchCategoriesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            categories: {
                status: AsyncStates.ERROR,
                error: action.payload?.error,
                data: [],
            },
        }),

        [CreateCategoriesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [CreateCategoriesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                categories: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [...state.categories.data, action.payload.data],
                },
            };
        },
        [CreateCategoriesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            categories: {
                ...state.categories,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [UpdateCategoriesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [UpdateCategoriesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                categories: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.categories.data.map((c) => {
                            if (c.category_id === action.payload.category_id)
                                return { ...c, ...action.payload.data };
                            else return c;
                        }),
                    ],
                },
            };
        },
        [UpdateCategoriesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            categories: {
                ...state.categories,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [RemoveCategoriesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [RemoveCategoriesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                categories: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.categories.data.filter(
                            (c) => !action.payload.category_ids.includes(c.category_id)
                        ),
                    ],
                },
            };
        },
        [RemoveCategoriesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            categories: {
                ...state.categories,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        // ---------------------------------------------------------------------------------------------

        [FetchAdditivesActionTypes.REQUEST]: (state) => ({
            ...state,
            additives: {
                status: AsyncStates.LOADING,
                error: "",
                data: [],
            },
        }),
        [FetchAdditivesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                additives: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: action?.payload?.data ?? [],
                },
            };
        },
        [FetchAdditivesActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                additives: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [],
                },
            };
        },
        [FetchAdditivesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            additives: {
                status: AsyncStates.ERROR,
                error: action.payload?.error,
                data: [],
            },
        }),

        [CreateAdditivesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                additives: {
                    ...state.additives,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [CreateAdditivesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                additives: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [...state.additives.data, action.payload.data],
                },
            };
        },
        [CreateAdditivesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            additives: {
                ...state.additives,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [UpdateAdditivesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                additives: {
                    ...state.additives,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [UpdateAdditivesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                additives: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.additives.data.map((c) => {
                            if (c.additive_id === action.payload.additive_id)
                                return { ...c, ...action.payload.data };
                            else return c;
                        }),
                    ],
                },
            };
        },
        [UpdateAdditivesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            additives: {
                ...state.additives,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [RemoveAdditivesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                additives: {
                    ...state.additives,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [RemoveAdditivesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                additives: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.additives.data.filter(
                            (c) => !action.payload.additive_ids.includes(c.additive_id)
                        ),
                    ],
                },
            };
        },
        [RemoveAdditivesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            additives: {
                ...state.additives,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        // ---------------------------------------------------------------------------------------------

        [FetchFieldActionTypes.REQUEST]: (state) => ({
            ...state,
            fields: {
                status: AsyncStates.LOADING,
                error: "",
                data: [],
            },
        }),
        [FetchFieldActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                fields: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: action?.payload?.data ?? [],
                },
            };
        },
        [FetchFieldActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                fields: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [],
                },
            };
        },
        [FetchFieldActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            fields: {
                status: AsyncStates.ERROR,
                error: action.payload?.error,
                data: [],
            },
        }),

        [CreateFieldActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [CreateFieldActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                fields: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [...state.fields.data, action.payload.data],
                },
            };
        },
        [CreateFieldActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            fields: {
                ...state.fields,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [UpdateFieldActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [UpdateFieldActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                fields: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.fields.data.map((c) => {
                            if (c.field_id === action.payload.field_id)
                                return { ...c, ...action.payload.data };
                            else return c;
                        }),
                    ],
                },
            };
        },
        [UpdateFieldActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            fields: {
                ...state.fields,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [RemoveFieldActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [RemoveFieldActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                fields: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.fields.data.filter(
                            (c) => !action.payload.field_ids.includes(c.field_id)
                        ),
                    ],
                },
            };
        },
        [RemoveFieldActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            fields: {
                ...state.fields,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        // ---------------------------------------------------------------------------------------------

        [FetchParametersActionTypes.REQUEST]: (state) => ({
            ...state,
            parameters: {
                status: AsyncStates.LOADING,
                error: "",
                data: [],
            },
        }),
        [FetchParametersActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                parameters: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: action?.payload?.data ?? [],
                },
            };
        },
        [FetchParametersActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                parameters: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [],
                },
            };
        },
        [FetchParametersActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            parameters: {
                status: AsyncStates.ERROR,
                error: action.payload?.error,
                data: [],
            },
        }),

        [CreateParametersActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [CreateParametersActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                parameters: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [...state.parameters.data, action.payload.data],
                },
            };
        },
        [CreateParametersActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            parameters: {
                ...state.parameters,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),
        [CreateParametersActionTypes.CLEAR]: (state, action: any) => ({
            ...state,
            parameters: {
                ...state.parameters,
                status: AsyncStates.INITIAL,
                error: "",
            },
        }),

        [UpdateParametersActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [UpdateParametersActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                parameters: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.parameters.data.map((c) => {
                            if (c.parameter_id === action.payload.parameter_id)
                                return { ...c, ...action.payload.data };
                            else return c;
                        }),
                    ],
                },
            };
        },
        [UpdateParametersActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            parameters: {
                ...state.parameters,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [RemoveParametersActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [RemoveParametersActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                parameters: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.parameters.data.filter(
                            (c) => !action.payload.parameter_ids.includes(c.parameter_id)
                        ),
                    ],
                },
            };
        },
        [RemoveParametersActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            parameters: {
                ...state.parameters,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        // ----------------------------------------------------------------------------------


        [FetchAllMethodsActionTypes.REQUEST]: (state) => ({
            ...state,
            allMethods: {
                ...state.allMethods,
                status: AsyncStates.LOADING,
                error: ""
            },
        }),
        [FetchAllMethodsActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                allMethods: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: action?.payload?.data ?? [],
                },
            };
        },
        [FetchAllMethodsActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                allMethods: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [],
                },
            };
        },
        [FetchAllMethodsActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            allMethods: {
                status: AsyncStates.ERROR,
                error: action.payload?.error,
                data: [],
            },
        }),


        [FetchMethodsActionTypes.REQUEST]: (state) => ({
            ...state,
            methods: {
                status: AsyncStates.LOADING,
                error: "",
                data: [],
            },
        }),
        [FetchMethodsActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                methods: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: action?.payload?.data ?? [],
                },
            };
        },
        [FetchMethodsActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                methods: {
                    status: AsyncStates.INITIAL,
                    error: "",
                    data: [],
                },
            };
        },
        [FetchMethodsActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            methods: {
                status: AsyncStates.ERROR,
                error: action.payload?.error,
                data: [],
            },
        }),

        [CreateMethodsActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                methods: {
                    ...state.methods,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [CreateMethodsActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                methods: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [...state.methods.data, action.payload.data],
                },
            };
        },
        [CreateMethodsActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            methods: {
                ...state.methods,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),
        [CreateMethodsActionTypes.CLEAR]: (state, action: any) => ({
            ...state,
            methods: {
                ...state.methods,
                status: AsyncStates.INITIAL,
                error: "",
            },
        }),

        [UpdateMethodsActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                methods: {
                    ...state.methods,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [UpdateMethodsActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                methods: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.methods.data.map((m) => {
                            if (m.method_id === action.payload.method_id)
                                return { ...m, ...action.payload.data };
                            else return m;
                        }),
                    ],
                },
            };
        },
        [UpdateMethodsActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            methods: {
                ...state.methods,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [RemoveMethodsActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                methods: {
                    ...state.methods,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [RemoveMethodsActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                methods: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.methods.data.filter(
                            (m) => !action.payload.method_ids.includes(m.method_id)
                        ),
                    ],
                },
            };
        },
        [RemoveMethodsActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            methods: {
                ...state.methods,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),


        // ----------------------------------------------------------------------------------


        [FetchIngredientTemplatesActionTypes.REQUEST]: (state) => ({
            ...state,
            templates: {
                status: AsyncStates.LOADING,
                error: "",
                data: [],
            },
        }),
        [FetchIngredientTemplatesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                templates: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: action?.payload?.data ?? [],
                },
            };
        },
        [FetchIngredientTemplatesActionTypes.CLEAR]: (state, action) => {
            return {
                ...state,
                templates: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [],
                },
            };
        },
        [FetchIngredientTemplatesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            templates: {
                status: AsyncStates.ERROR,
                error: action.payload?.error,
                data: [],
            },
        }),

        [CreateIngredientTemplatesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [CreateIngredientTemplatesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                templates: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [...state.templates.data, action.payload.data],
                },
            };
        },
        [CreateIngredientTemplatesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            templates: {
                ...state.templates,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),
        [CreateIngredientTemplatesActionTypes.CLEAR]: (state, action: any) => ({
            ...state,
            templates: {
                ...state.templates,
                status: AsyncStates.INITIAL,
                error: "",
            },
        }),

        [UpdateIngredientTemplatesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [UpdateIngredientTemplatesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                templates: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.templates.data.map((t) => {
                            if (t.template_id === action.payload.template_id)
                                return { ...t, ...action.payload.data };
                            else return t;
                        }),
                    ],
                },
            };
        },
        [UpdateIngredientTemplatesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            templates: {
                ...state.templates,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),

        [RemoveIngredientTemplatesActionTypes.REQUEST]: (state) => {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    status: AsyncStates.LOADING,
                    error: "",
                },
            };
        },
        [RemoveIngredientTemplatesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                templates: {
                    status: AsyncStates.SUCCESS,
                    error: "",
                    data: [
                        ...state.templates.data.filter(
                            (t) => !action.payload.template_ids.includes(t.template_id)
                        ),
                    ],
                },
            };
        },
        [RemoveIngredientTemplatesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            templates: {
                ...state.templates,
                status: AsyncStates.ERROR,
                error: action.payload?.error,
            },
        }),


        // ----------------------------------------------------------------------------------


        [FetchAllCategoriesActionTypes.REQUEST]: (state) => ({
            ...state,
            allCategories: {
                status: AsyncStates.LOADING,
                error: null,
                data: {},
            },
        }),
        [FetchAllCategoriesActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                allCategories: {
                    status: AsyncStates.SUCCESS,
                    error: null,
                    data: action?.payload ?? {},
                },
            };
        },
        [FetchAllCategoriesActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            allCategories: {
                ...state.allCategories,
                status: AsyncStates.ERROR,
                error: action.payload,
            },
        }),
        [FetchAllCategoriesActionTypes.CLEAR]: (state) => ({
            ...state,
            allCategories: {
                status: AsyncStates.INITIAL,
                error: null,
                data: {},
            },
        }),


        // ---------------------------------------------------------------------------------------------


        [FetchPropertyUnitsActionTypes.REQUEST]: (state) => ({
            ...state,
            propertyUnits: {
                status: AsyncStates.LOADING,
                error: null,
                data: {},
            },
        }),
        [FetchPropertyUnitsActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                propertyUnits: {
                    status: AsyncStates.SUCCESS,
                    error: null,
                    data: action?.payload ?? {},
                },
            };
        },
        [FetchPropertyUnitsActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            propertyUnits: {
                ...state.propertyUnits,
                status: AsyncStates.ERROR,
                error: action.payload,
            },
        }),
        [FetchPropertyUnitsActionTypes.CLEAR]: (state) => ({
            ...state,
            propertyUnits: {
                status: AsyncStates.INITIAL,
                error: null,
                data: {},
            },
        }),


        // ---------------------------------------------------------------------------------------------


        [MarkPropertyUnitDefaultActionTypes.REQUEST]: (state) => ({
            ...state,
            propertyUnits: {
                ...state.propertyUnits,
                status: AsyncStates.LOADING,
                error: null
            },
        }),
        [MarkPropertyUnitDefaultActionTypes.SUCCESS]: (state, action: any) => {
            return {
                ...state,
                propertyUnits: {
                    ...state.propertyUnits,
                    status: AsyncStates.SUCCESS,
                    error: null,
                },
            };
        },
        [MarkPropertyUnitDefaultActionTypes.FAILURE]: (state, action: any) => ({
            ...state,
            propertyUnits: {
                ...state.propertyUnits,
                status: AsyncStates.ERROR,
                error: action.payload,
            },
        })
    },
    defaultState
);

export default repositoryReducer;
