import { CaretRightOutlined } from "@ant-design/icons";
import "./Widget.scss"
import { Collapse, Progress, Row, Tooltip, Typography, Tag } from "antd";
import { WidgetProgress } from "src/store/reducers/widget";
import { useEffect } from "react";
import { Unsubscribe } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { FB_COLLECTION_PROGRESS_TRACKER, firestoreDb } from "src/utils/firebase";
import Stepper from "../Common/Stepper";
import { useDispatch } from "react-redux";
import { progressRemove, progressUpdate } from "src/store/actions/widget";
import { useValue } from "src/utils/useValue";
import { antdTheme } from "src/constants";
const { Text } = Typography;

type TProps = {
    widgetProgress: WidgetProgress;
    setHasUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    setShowFlash: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProgressInfo = ({ widgetProgress: progressData, setHasUpdate, setShowFlash }: TProps) => {
    const dispatch = useDispatch();
    const { getValue } = useValue()

    useEffect(() => {
        let unsub: Unsubscribe;
        (async () => {
            const woCommentsQuery = query(
                collection(firestoreDb, FB_COLLECTION_PROGRESS_TRACKER),
                where("progress_id", "==", progressData.progress_id)
            );

            unsub = onSnapshot(woCommentsQuery, (querySnapshot) => {
                if (querySnapshot.docs.length === 0) dispatch(progressRemove(progressData.progress_id));
                querySnapshot.docs.forEach((docSnapshot) => {
                    dispatch(progressUpdate(docSnapshot.data() as WidgetProgress, progressData.progress_id));
                    setHasUpdate(true);
                    setShowFlash(true);
                });
            });
        })();

        return () => {
            unsub && unsub();
        };
    }, [progressData.progress_id, dispatch, setHasUpdate, setShowFlash]);

    const isWoTypeSingle = () => progressData?.type?.toLowerCase() === "single" || progressData?.stage?.length === 0 || !progressData?.stage;

    const formatNameWithTooltip = (maxLength: number, text: string) => {
        return text.length > maxLength ? <Tooltip title={text}>{text.substring(0, maxLength)}...</Tooltip> : <>{text}</>
    }

    const stepper = () => {
        if (isWoTypeSingle() || !progressData?.stage) return null;

        return (
            <Stepper items={progressData.stage.map(s => formatNameWithTooltip(8, s))} fontSize={antdTheme?.fontSizeSM || 9} current={progressData.stage.findIndex(s => s === progressData.current_stage)} className="stepper-block" />
        );
    }

    const tag = () => {
        const name = progressData?.entity_name
        if (name) return <Tag color="blue">{name}</Tag>
        return null;
    }

    if (!progressData) return <></>;

    return (
        <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined style={{ marginTop: isWoTypeSingle() ? "0" : "12px" }} rotate={isActive ? 90 : 0} />}
            style={{ background: "#fff" }}
            items={[{
                key: 1,
                label: (<Row align="middle" justify="space-between"><div>{formatNameWithTooltip(15, progressData.display_name)} {tag()}</div> ({progressData.components.filter(c => c.percentage === 100).length}/{progressData.components.length}) {stepper()}</Row>),
                children: (
                    <>
                        {progressData.components.sort((a, b) => a.position - b.position).map((component, index: number) => (
                            <Row key={index}>
                                <Text>{component.component_name}</Text>
                                <Progress percent={component.percentage} format={(percent) => `${getValue(percent)}%`} status="active" strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} />
                            </Row>
                        ))}
                    </>
                ),
                style: {
                    margin: "20px 0px",
                    border: "1px solid rgba(5, 5, 5, 0.06)",
                    borderRadius: "8px",
                }
            }]}
        />
    )
}

export default ProgressInfo;