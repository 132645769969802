import {
  DownOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import {
  createWorkOrderRequest,
  resetWorkOrder,
  setDuplicateWo,
} from "src/store/actions/workOrderDetails";
import { setSelectedTrials } from "src/store/actions/workOrders";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { useMesssage } from "src/utils/hooks";
import { useHighlightOptions } from "src/utils/useHighlightOptions";
import { useMemberName } from "src/utils/useMemberName";
import useTranslate from "src/utils/useTranslate";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { trackEvent } from "src/analytics";
import { history } from "src";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";
dayjs.extend(isSameOrAfter);

const { Text } = Typography;
const { Option } = Select;

export const CreateWoModal = ({
  createWoModalVisible,
  setCreateWoModalVisible,
}: any) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { getName } = useMemberName();
  const { m } = useMesssage();
  const requiredFieldStar = useRequiredFieldStar()

  const [stage, setStage] = useState(2);
  const [error, setError] = useState(false);
  const [value, setValue] = useState("yes");
  const [expand, setExpand] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [duplicateTrialNames, setDuplicateTrialNames] = useState([]);
  const [isDuplicateWoCalled, setisDuplicateWoCalled] = useState(false);

  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const { projectList, current } = useSelector(
    (state: StoreState) => state.projects
  );
  const currentProject = useMemo(
    () => projectList.find((project: any) => project.project_id === current),
    [projectList, current]
  );
  const createWOStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.createWOStatus
  );
  const { material, application } = useSelector(
    (state: StoreState) => state.displayNames.data || []
  );

  const [materialStr, setmaterialStr] = useState("");
  const materialOptions = useHighlightOptions({
    data: Object.entries(material || {}).reduce(
      (acc: any, [key, value]: any) => ({ ...acc, [key]: value?.name }),
      {}
    ) as any,
    searchStr: materialStr,
  });

  const [applicationStr, setapplicationStr] = useState("");
  const applicationOptions = useHighlightOptions({
    data: application?.reduce(
      (acc: any, res: any) => ({ ...acc, [res]: res }),
      {}
    ) as any,
    searchStr: applicationStr,
  });

  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );
  const duplicateWoData = useSelector(
    (state: StoreState) => state.workOrderDetails.duplicateWoData || {}
  );
  // const [trials, setTrials] = useState([])

  const [selectedDate, setSelectedDate] = useState({
    startDate: workOrder?.start_date ? dayjs(workOrder.start_date) : null,
    closeDate: workOrder?.close_date ? dayjs(workOrder.close_date) : null,
  });

  // useEffect(() => {
  // 	if (createWoModalVisible) {
  // 		if (from === "closedWo") {
  // 			setTrials(
  // 				initialTrials.filter((res: any) => selectedTrials?.includes(res?.id_set?.formulation_id))
  // 			)
  // 		} else {
  // 			setTrials(
  // 				initialTrials.filter((res: any, index: any) =>
  // 					selectedTrials?.includes(index)
  // 				)
  // 			)
  // 		}
  // 	}
  // }, [createWoModalVisible, from, initialTrials, selectedTrials])

  const closeModal = useCallback(() => {
    setCreateWoModalVisible(false);
    setValue("yes");
    setStage(2);
    setExpand(false);
    setTableData([]);
  }, [setCreateWoModalVisible]);

  const handleAlert = useCallback(() => {
    if (
      createWOStatus === AsyncStates.SUCCESS &&
      !!Object.keys(duplicateWoData || {}).length
    ) {
      dispatch(setSelectedTrials());
      closeModal();
      if (isDuplicateWoCalled) {
        notification.success({
          message: m("work_order_created_from_selected_trials"),
          description: (
            <StyledButton
              type="link"
              onClick={() => {
                dispatch(resetWorkOrder());
                history.push(
                  `/work-orders/details/${duplicateWoData?.work_order_id}/`
                );
              }}
            >
              {t("common.goToWorkOrder")}
            </StyledButton>
          ),
          duration: 8,
        });
      }
      isDuplicateWoCalled
        ? trackEvent(userId, "Duplicate WO created", {
          "WO details": duplicateWoData,
        })
        : trackEvent(userId, "New WO created", { "WO details": workOrder });
      dispatch(setDuplicateWo({}));
    }
  }, [
    dispatch,
    createWOStatus,
    closeModal,
    duplicateWoData,
    t,
    m,
    isDuplicateWoCalled,
    workOrder,
    userId,
  ]);

  useEffect(() => {
    handleAlert();
  }, [handleAlert]);
  useEffect(() => {
    return () => {
      setisDuplicateWoCalled(false);
    };
  }, []);

  // useEffect(() => {
  // 	if (createWoModalVisible) {
  // 		setTableData(
  // 			trials.map((res: any) => ({
  // 				original_trial_name: res?.meta?.display_id,
  // 				new_trial_name: res?.meta?.display_id,
  // 				note: "",
  // 			}))
  // 		)
  // 	}
  // }, [initialTrials, trials, createWoModalVisible])

  useEffect(() => {
    const newTrialNames = tableData.map((res: any) =>
      res.new_trial_name?.trim()
    );
    const duplicateTrials = newTrialNames.filter(
      (item: any, index: any) => newTrialNames.indexOf(item) !== index && !!item
    );
    setDuplicateTrialNames(duplicateTrials);
  }, [tableData]);

  useEffect(() => {
    if (value === "rename") {
      setError(tableData.some((res: any) => !res.new_trial_name));
    }
  }, [tableData, value]);

  const columns = [
    {
      dataIndex: "original_trial_name",
      title: t("common.originalTrialName"),
      key: "original_trial_name",
    },
    {
      dataIndex: "new_trial_name",
      title: t("common.newTrialName"),
      key: "new_trial_name",
      width: 400,
      render: (text: any, record: any, index: any) => {
        return (
          <>
            <Input
              value={text}
              onChange={(e) => {
                setTableData((prevState: any) => {
                  prevState[index].new_trial_name = e.target.value;
                  return [...prevState];
                });
              }}
            />
            {!text && <Text type="danger">{"Trial name required"}</Text>}
          </>
        );
      },
    },
    {
      dataIndex: "note",
      title: t("workOrderDetails.note"),
      key: "note",
      width: 250,
      render: (text: any, record: any, index: any) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              setTableData((prevState: any) => {
                prevState[index].note = e.target.value;
                return [...prevState];
              });
            }}
          />
        );
      },
    },
  ];

  const createWoSubmit = (values: any) => {
    if (
      !!values?.close_date &&
      !!values?.start_date &&
      dayjs(values?.start_date).isSameOrAfter(dayjs(values?.close_date))
    ) {
      return message.error(t("common.message.closeDateGreaterEqualStartDate"));
    }
    dispatch(
      createWorkOrderRequest({
        ...values,
        work_order_dri: values.work_order_dri ? values.work_order_dri : [],
        ...(!!values?.start_date && {
          start_date: dayjs(values?.start_date).toISOString(),
        }),
        ...(!!values?.close_date && {
          close_date: dayjs(values?.close_date).toISOString(),
        }),
        work_order_seq: -1,
        project_id: workOrder.project_id,
        work_order_stage: "work_order_initiated",
        stages: workOrder?.stages,
        duplicate_work_order: {
          // formulations: trials.map((res: any, index: number) => ({
          // 	formulation_id: res?.id_set.formulation_id,
          // 	display_id: tableData[index].new_trial_name,
          // 	...(value === "rename" && { note: tableData[index].note }),
          // })),
          work_order_id: workOrder.work_order_id,
          experiment_id: workOrder?.experiment_id,
        },
      })
    );
    setisDuplicateWoCalled(true);
  };

  return (
    <Modal
      okText={t("common.ok")}
      cancelText={t("common.cancel")}
      open={createWoModalVisible}
      width={stage === 1 ? 500 : 800}
      footer={null}
      maskClosable={false}
      onCancel={closeModal}
      title={
        stage === 1 ? (
          <Space style={{ width: "90%" }}>
            <Text strong>{`${t("common.newWorkOrderInProject")}: ${currentProject?.name
              }`}</Text>
            <Tooltip
              title={t("common.newWorkOrderCreatedWithinTheSameProject")}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        ) : (
          <Text>{t("common.createNewWorkOrder")}</Text>
        )
      }
    >
      <Spin
        spinning={AsyncStates.LOADING === createWOStatus}
        indicator={<LoadingOutlined />}
      >
        {stage === 1 ? (
          <Space direction="vertical" style={{ width: "100", padding: 10 }}>
            <Typography.Title level={4} type="secondary">
              {`${t("common.keepTrialNamesForTheNewWorkOrder")} ?`}
            </Typography.Title>
            <Radio.Group
              onChange={(e) => setValue(e.target.value)}
              value={value}
            >
              <Space direction="vertical">
                <Radio value={"yes"}>{`${t("common.yes")}, ${t(
                  "common.keepTheTrialNamesSame"
                )}`}</Radio>
                <Radio value={"rename"}>{`${t("common.no")}, ${t(
                  "common.renameTheTrialNamesNow"
                )}`}</Radio>
                <Radio value={"no"}>
                  {`${t("common.no")}, ${t("common.but")} ${t(
                    "common.iWillDoItLater"
                  )}`}
                  <Tooltip
                    title={t("common.trialNamesWillBeKeptTheSameForNow")}
                  >
                    <InfoCircleOutlined
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        marginLeft: 5,
                      }}
                    />
                  </Tooltip>
                </Radio>
              </Space>
            </Radio.Group>
            <Row justify="end" style={{ marginTop: 20 }}>
              <Space>
                <StyledButton onClick={closeModal}>
                  {t("common.cancel")}
                </StyledButton>
                <StyledButton onClick={() => setStage(2)} type="primary">
                  {t("common.create")}
                </StyledButton>
              </Space>
            </Row>
          </Space>
        ) : (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form onFinish={createWoSubmit} requiredMark={false} layout={"vertical"}>
              <Form.Item
                required={true}
                label={<Text>{t("common.title")}</Text>}
                name="work_order_name"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z0-9]/),
                    message: t("common.pleaseEnterValidWorkOrderName"),
                  },
                ]}
                tooltip={requiredFieldStar}
              >
                <Input placeholder={t("workOrderDetails.placeholder.title")} />
              </Form.Item>
              <Form.Item
                required={true}
                label={<Text>{t("common.material")}</Text>}
                name="material"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z0-9.]/),
                    message: t("common.pleaseEnterValidMaterial"),
                  },
                ]}
                tooltip={requiredFieldStar}
              >
                <AutoComplete
                  options={materialOptions}
                  onSearch={setmaterialStr}
                  placeholder={t("common.selectMaterial")}
                />
              </Form.Item>
              <Form.Item
                required={true}
                label={<Text>{t("common.application")}</Text>}
                name="application"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z0-9.]/),
                    message: t("common.pleaseEnterValidApplication"),
                  },
                ]}
                tooltip={requiredFieldStar}
              >
                <AutoComplete
                  options={applicationOptions}
                  onSearch={setapplicationStr}
                  placeholder={t("common.selectApplication")}
                />
              </Form.Item>
              <Form.Item
                required={false}
                label={<Text>{t("common.description")}</Text>}
                name="work_order_desc"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9.]/),
                    message: t("common.pleaseEnterValidDescription"),
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={t("common.description")}
                ></Input.TextArea>
              </Form.Item>
              <Form.Item
                required={false}
                label={<Text>{t("common.members")}</Text>}
                name="work_order_dri"
              >
                <Select
                  mode="multiple"
                  showSearch
                  placeholder={t("workOrderDetails.placeholder.selectAPerson")}
                >
                  {currentProject?.members
                    ?.map(({ user_id }: any) => user_id)
                    ?.concat(currentProject?.created_by)
                    ?.filter((res: any) => res !== userId)
                    ?.map((member: any) => (
                      <Option value={member} key={member}>
                        {getName(member)}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                required={false}
                label={<Text>{t("workOrderDetails.startDate")}</Text>}
                name="start_date"
              >
                <DatePicker
                  disabledDate={(dates) => {
                    if (
                      selectedDate?.closeDate &&
                      dayjs(selectedDate.closeDate)
                        .startOf("day")
                        .add(1, "day") < dates
                    ) {
                      return true;
                    }
                    return false;
                  }}
                  onChange={(value) => {
                    setSelectedDate((prev: any) => ({
                      ...prev,
                      startDate: value,
                    }));
                  }}
                />
              </Form.Item>
              {expand && (
                <>
                  <Form.Item
                    required={false}
                    label={<Text>{t("workOrderDetails.closeDate")}</Text>}
                    name="close_date"
                  >
                    <DatePicker
                      onChange={(value) => {
                        setSelectedDate((prev: any) => ({
                          ...prev,
                          closeDate: value,
                        }));
                      }}
                      disabledDate={(dates) => {
                        if (
                          selectedDate?.startDate &&
                          dates < selectedDate.startDate
                        ) {
                          return true;
                        }
                        return false;
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    required={false}
                    label={<Text>{t("workOrderDetails.orderType")}</Text>}
                    name="work_order_type"
                    rules={[
                      {
                        pattern: new RegExp(/^[a-zA-Z0-9]/),
                        message: t("common.pleaseEnterValidOrderType"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("workOrderDetails.orderType")}
                    ></Input>
                  </Form.Item>
                  <Form.Item
                    required={false}
                    label={<Text>{t("common.grade")}</Text>}
                    name="grade"
                    rules={[
                      {
                        pattern: new RegExp(/^[a-zA-Z0-9]/),
                        message: t("common.pleaseEnterValidGrade"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("workOrderDetails.selectGrade")}
                    ></Input>
                  </Form.Item>
                  <Form.Item name="is_multiunit" label={<Text>{t("workOrderDetails.multiUnit")}</Text>}>
                    <Switch />
                  </Form.Item>
                </>
              )}
              {expand ? (
                <StyledButton
                  size="small"
                  type="link"
                  icon={<UpOutlined />}
                  onClick={() => setExpand(false)}
                >
                  {t("workOrderDetails.lessDetails")}
                </StyledButton>
              ) : (
                <StyledButton
                  type="link"
                  icon={<DownOutlined />}
                  onClick={() => setExpand(true)}
                >
                  {t("common.moreDetails")}
                </StyledButton>
              )}
              {value === "rename" ? (
                <Table
                  columns={columns}
                  dataSource={tableData}
                  style={{ marginTop: 20 }}
                />
              ) : (
                ""
              )}
              {duplicateTrialNames.length ? (
                <Text type="danger">{`${t(
                  "common.duplicateTrialNamesFound"
                )} - ${[...new Set([...duplicateTrialNames])].join(
                  ", "
                )}`}</Text>
              ) : (
                ""
              )}
              <Row justify="end" style={{ marginTop: 20 }}>
                <Space>
                  <StyledButton onClick={closeModal}>
                    {t("common.cancel")}
                  </StyledButton>
                  <StyledButton
                    disabled={error || !!duplicateTrialNames.length}
                    htmlType="submit"
                    type="primary"
                  >
                    {t("common.create")}
                  </StyledButton>
                </Space>
              </Row>
            </Form>
          </Space>
        )}
      </Spin>
    </Modal>
  );
};
