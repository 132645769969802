import { CSSProperties } from "react"
import { stylesheetToString } from "src/utils/general/stylesheet-to-string"

type Param = {
	message: string
	styles: CSSProperties
	type?: "error" | "log" | "warn" | "info"
}

export const styledConsoleLog = ({ message, styles, type = "log" }: Param) => {
	console[type](`%c${message}`, stylesheetToString(styles))
}
