import { Table, Tabs, Typography } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTotal } from 'src/components/WorkOrderDetails/MethodsTable/useTotal'
import { StoreState } from 'src/store/configureStore'
import { convertToPrecision } from 'src/utils/decorator'

const { Text } = Typography


export const AiFormulationsCostingTable = ({ formulationList }: any) => {

    const ingredients = useSelector((state: StoreState) => state.displayNames.data.ingredients)

    const columns = useMemo(() => [
        {
            title: "Parameter",
            dataIndex: "parameter",
            fixed: "left",
            className: 'table-name-column',
            width: 250,
            render: (text: any, record: any) => {
                if (record?.parameter === "Total") {
                    return {
                        props: {
                            style: { background: "#f5f5f5" },
                        },
                        children: <Text strong>{text}</Text>,
                    }
                }
                return <Text strong>{ingredients?.[text]?.name || text}</Text>
            }
        },
        ...formulationList.map((res: any, index: number) => ({
            title: <div>
                <Text strong type="secondary">{`Trial ${index + 1}`}</Text><br />
                {res.experiment_id && <small>{res.experiment_id}</small>}
            </div>,
            dataIndex: res?.experiment_id,
            align: "center" as any,
            width: 200,
            render: (text: any, record: any) => {
                if (record?.parameter === "Total") {
                    return {
                        props: {
                            style: { background: "#f5f5f5" },
                        },
                        children: text,
                    }
                }
                return text
            }
        })),
    ], [formulationList, ingredients])

    const dataSource: any = useMemo(() => {
        const parameters: any = [...new Set(formulationList.flatMap((res: any) => Object.keys(res?.ingredients || {})))]
            .filter((key: any) => formulationList.some(({ ingredients }: any) => !["water", "substrate"].includes(key) && !!ingredients?.[key]?.value && ingredients?.[key]?.value !== "-"))
            .map((res: any) => ({ parameter: res }))
        parameters.sort((a: any, b: any) => {
            const aNum = a.parameter.includes("enzyme_") ? parseInt(a.parameter.split("_")[1]) : Infinity
            const bNum = b.parameter.includes("enzyme_") ? parseInt(b.parameter.split("_")[1]) : Infinity
            if (aNum === bNum) {
                return a.localeCompare(b)
            } else {
                return aNum - bNum
            }
        })
        parameters.forEach((res: any) => {
            formulationList.forEach((trial: any, index: number) => {
                const substrate = trial?.ingredients?.["substrate"]?.value
                let cost
                if (!trial?.ingredients?.[res?.parameter]?.value) {
                    res[trial.experiment_id] = "-"
                }
                else if (!ingredients?.[res?.parameter]?.costing?.amount) {
                    res[trial.experiment_id] = "Not Defined"
                }
                if (!!trial?.ingredients?.[res?.parameter]?.value && !!ingredients?.[res?.parameter]?.costing?.amount && !!substrate) {
                    cost = Number(trial?.ingredients?.[res?.parameter]?.value) * Number(ingredients?.[res?.parameter]?.costing?.amount) / 1000 * substrate / 100
                }
                if (!!cost) {
                    res[trial.experiment_id] = convertToPrecision(cost, 5)
                }
            })
        })
        return parameters
    }, [formulationList, ingredients])

    const { totalRow: costingTotal } = useTotal(dataSource, { parameter: "Total" })


    return (
        <Table
            size="small"
            columns={columns as any}
            className="report-table"
            dataSource={dataSource?.length ? [...dataSource, { ...costingTotal, key: dataSource.length }] : []}
            pagination={false}
            bordered
            scroll={{ x: 450 }}
            rowClassName={(record, index) => {
                if (record.parameter === "Total") {
                    return "highlight-row"
                }
                return ""
            }}
        />
    )
}


export const AiProcessingCostingTable = ({ formulationList }: any) => {

    const processingProfiles = useSelector((state: StoreState) => state.workOrderDetails.processingProfilesList)

    const columns = useMemo(() => [
        {
            title: "Parameter",
            dataIndex: "parameter",
            fixed: "left",
            className: 'table-name-column',
            width: 250,
            render: (text: any) => <Text strong>{text}</Text>
        },
        ...formulationList.map((res: any, index: number) => ({
            title: <div>
                <Text strong type="secondary">{`Trial ${index + 1}`}</Text><br />
                {res.experiment_id && <small>{res.experiment_id}</small>}
            </div>,
            dataIndex: res?.experiment_id,
            align: "center" as any,
            width: 200,
            render: (text: any) => text ?? "Not Defined"
        })),
    ], [formulationList])

    const dataSource: any = useMemo(() => {
        const parameters: any = [
            { parameter: "BOM (kg)", value: "" },
            { parameter: "Cost/kg of BOM", value: "" },
            { parameter: "Factory Yield (%)", value: "" },
            { parameter: "Reference Dry Matter Yield (%)", value: "" },
            { parameter: "Reference Yield/Batch", value: "" },
            { parameter: "Total Conversion Cost/Ton", value: "" },
            { parameter: "Total RM Cost/Batch", value: "" },
            { parameter: "Reference Cost of MBE/ton", value: "" },
            { parameter: "Total Solids", value: "" },
        ]
        parameters.forEach((res: any) => {
            formulationList.forEach((trial: any, index: number) => {
                const currentProfile = processingProfiles.find((res: any) => res?.name === trial?.processing?.Profile?.value)?.costing
                if (!!currentProfile?.length) {
                    res[trial?.experiment_id] = currentProfile?.find((key: any) => key?.parameter === res.parameter)?.value
                } else {
                    res[trial?.experiment_id] = ""
                }
            })
        })
        return parameters
    }, [formulationList, processingProfiles])


    return (
        <Table
            size="small"
            columns={columns as any}
            className="report-table"
            dataSource={dataSource}
            pagination={false}
            bordered
            scroll={{ x: 450 }}
        />
    )
}


export const AiOutputCostingTable = ({ formulationList }: any) => {

    const ingredients = useSelector((state: StoreState) => state.displayNames.data.ingredients)
    const processingProfiles = useSelector((state: StoreState) => state.workOrderDetails.processingProfilesList)

    const columns = useMemo(() => [
        {
            title: "Parameter",
            dataIndex: "parameter",
            fixed: "left",
            width: 250,
            render: (text: any) => <Text strong>{text}</Text>
        },
        ...formulationList.map((res: any, index: number) => ({
            title: <div>
                <Text strong type="secondary">{`Trial ${index + 1}`}</Text><br />
                {res.experiment_id && <small>{res.experiment_id}</small>}
            </div>,
            dataIndex: res?.experiment_id,
            align: "center" as any,
            width: 200,
            render: (text: any) => text ?? "Not Defined"
        })),
    ], [formulationList])

    const dataSource: any = useMemo(() => {
        const parameters = [
            { parameter: "Yield/Batch" },
            { parameter: "Total RM Cost/Batch" },
            { parameter: "Cost of MBE/Ton" },
            { parameter: "Additional Yield (kg)/Ton" },
            { parameter: "Cost MBE Accounting for Additional Yield" },
            { parameter: "Cost Impact Total (%)" },
        ]
        formulationList.forEach((trial: any) => {
            let yield_batch: any, total_rm_cost_batch: any, cost_of_mbe_ton: any, additional_yield_kg_ton: any,
                cost_mbe_accounting_for_additional_yield: any, cost_impact_total: any
            const profile = processingProfiles.find((res: any) => res?.name === trial?.processing?.Profile?.value)
            if (["Profile A", "Profile J"].includes(profile?.name)) {
                const currentProfile = profile?.costing
                const bom_kg = currentProfile.find((res: any) => res?.parameter === "BOM (kg)")?.value
                const cost_kg_of_bom = currentProfile.find((res: any) => res?.parameter === "Cost/kg of BOM")?.value
                const factory_yield = currentProfile.find((res: any) => res?.parameter === "Factory Yield (%)")?.value
                const refrernce_dry_matter_yield = currentProfile.find((res: any) => res?.parameter === "Reference Dry Matter Yield (%)")?.value
                const refrernce_yield_batch = currentProfile.find((res: any) => res?.parameter === "Reference Yield/Batch")?.value
                const reference_cost_of_mbe_ton = currentProfile.find((res: any) => res?.parameter === "Reference Cost of MBE/ton")?.value
                const total_conversion_cost_ton = currentProfile.find((res: any) => res?.parameter === "Total Conversion Cost/Ton")?.value
                const total_solids = currentProfile.find((res: any) => res?.parameter === "Total Solids")?.value
                const yield_pct = trial?.predicted_properties?.["yield_pct"]?.value

                if (profile?.name === "Profile A" && !isNaN(bom_kg) && !!bom_kg && !isNaN(refrernce_dry_matter_yield) && !!refrernce_dry_matter_yield && !isNaN(refrernce_yield_batch) && !!refrernce_yield_batch && !isNaN(yield_pct) && !!yield_pct) {
                    yield_batch = bom_kg * refrernce_dry_matter_yield / factory_yield * yield_pct / 100 / refrernce_yield_batch
                }
                if (profile?.name === "Profile J" && !isNaN(bom_kg) && !!bom_kg && !isNaN(total_solids) && !!total_solids && !isNaN(yield_pct) && !!yield_pct && !isNaN(factory_yield) && !!factory_yield) {
                    yield_batch = bom_kg * total_solids * yield_pct / 100 * 17272 * 0.88 * 1 / factory_yield
                }
                const ingredientSum = Object.entries(trial?.ingredients || {}).filter(([key, value]: any) => !["water", "substrate"].includes(key) && !!value?.value && !isNaN(value?.value)).reduce((sum: any, [key, value]: any) => Number(value?.value) * Number(ingredients?.[key]?.costing?.amount) + sum, 0)
                if (!isNaN(bom_kg) && !!bom_kg && !isNaN(ingredientSum) && !!ingredientSum && !isNaN(cost_kg_of_bom) && !!cost_kg_of_bom) {
                    if (profile?.name === "Profile A") {
                        total_rm_cost_batch = ingredientSum * bom_kg / 100 + bom_kg * cost_kg_of_bom
                    }
                    if (profile?.name === "Profile J" && !!ingredients?.["enzyme_4"]?.costing?.amount) {
                        total_rm_cost_batch = ((ingredientSum * bom_kg) / 100) + (bom_kg * cost_kg_of_bom) + (17272 * 0.618) + (17272 * 0.05 / 100 * ingredients?.["enzyme_4"]?.costing?.amount)
                    }
                }

                if (!isNaN(total_rm_cost_batch) && !!total_rm_cost_batch && !isNaN(yield_batch) && !!yield_batch) {
                    cost_of_mbe_ton = total_rm_cost_batch / yield_batch * 10000
                }

                if (!isNaN(yield_batch) && !!yield_batch && !isNaN(refrernce_yield_batch) && !!refrernce_yield_batch && !isNaN(total_conversion_cost_ton) && !!total_conversion_cost_ton) {
                    additional_yield_kg_ton = (yield_batch - refrernce_yield_batch) / (refrernce_yield_batch * 1000 * total_conversion_cost_ton)
                }

                if (!isNaN(cost_of_mbe_ton) && !!cost_of_mbe_ton && !isNaN(additional_yield_kg_ton) && !!additional_yield_kg_ton) {
                    cost_mbe_accounting_for_additional_yield = cost_of_mbe_ton - additional_yield_kg_ton
                }

                if (!isNaN(cost_mbe_accounting_for_additional_yield) && !!cost_mbe_accounting_for_additional_yield && !isNaN(reference_cost_of_mbe_ton) && !!reference_cost_of_mbe_ton) {
                    cost_impact_total = (cost_mbe_accounting_for_additional_yield - reference_cost_of_mbe_ton) / reference_cost_of_mbe_ton * 100
                }

                parameters.forEach((res: any) => {
                    const { parameter } = res
                    if (parameter === "Yield/Batch") {
                        res[trial.experiment_id] = convertToPrecision(yield_batch)
                    }
                    else if (parameter === "Total RM Cost/Batch") {
                        res[trial.experiment_id] = convertToPrecision(total_rm_cost_batch)
                    }
                    else if (parameter === "Cost of MBE/Ton") {
                        res[trial.experiment_id] = convertToPrecision(cost_of_mbe_ton)
                    }
                    else if (parameter === "Additional Yield (kg)/Ton") {
                        res[trial.experiment_id] = convertToPrecision(additional_yield_kg_ton, 5)
                    }
                    else if (parameter === "Cost MBE Accounting for Additional Yield") {
                        res[trial.experiment_id] = convertToPrecision(cost_mbe_accounting_for_additional_yield)
                    }
                    else if (parameter === "Cost Impact Total (%)") {
                        res[trial.experiment_id] = convertToPrecision(cost_impact_total)
                    }
                })
            }
        })
        return parameters
    }, [formulationList, processingProfiles, ingredients])


    return (
        <Table
            size="small"
            columns={columns as any}
            className="report-table"
            dataSource={dataSource}
            pagination={false}
            bordered
            scroll={{ x: 450 }}
        />
    )
}


export const AiCosting = ({ experiments }: any) => {


    return (
        <Tabs>
            <Tabs.TabPane tab={"Formulations Costing"} key={'1'}>
                <AiFormulationsCostingTable formulationList={experiments} />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab={"Processing Costing"} key={'2'}>
                <AiProcessingCostingTable formulationList={experiments} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Output Costing"} key={'3'}>
                <AiOutputCostingTable formulationList={experiments} />
            </Tabs.TabPane> */}
        </Tabs>
    )
}
