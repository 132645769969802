import { Box, PayloadElement, PCM } from "./types"

export const boxInitialValue: Box = {
	length: 500,
	width: 500,
	depth: 500,
}
export const payloadInitialValue = {
	color: "#000000",
	length: "",
	thickness: "",
	width: "",
}
export const boxExampleValue: Box = {
	length: 500,
	width: 500,
	depth: 500,
}

export const pcmEnthalpyMapping = {
	HS01: 350, // ✅
	H2O: 360, // ✅
	HS3N: 346, // ✅
	OM05: 215, // ✅
	HS22: 150, // ✅
	HS26N: 272, // ✅
	OM03: 229, // ✅
	HS23N: 262, // ✅
	FS03: 170, // ✅
	"OM18-P": 220,
	HS18: 130,
	H1N1: 0,
	HSO: 0,
	Payload: 0, // NA - not applicable
}

/**
 * This function verifies that none of the pcm element is overflowing from the given eps box.
 *
 * @param data PCM Data
 * @param box Eps Box Dimensions
 * @returns false if all the pcm elements are within the box, true otherwise
 */
export const isPCMOverflowing = (data: PCM[], box: Box) => {
	const { length, width, depth } = box

	const isLeftNRightOverflowing = data.some((casset) => {
		if (casset.position === "left" || casset.position === "right") {
			return (
				casset.length > depth ||
				casset.width > length ||
				casset.thickness > width
			)
		}
		return false
	})

	const isTopNBottomOverflowing = data.some((casset) => {
		if (casset.position === "bottom" || casset.position === "top") {
			return (
				casset.length > length ||
				casset.width > width ||
				casset.thickness > depth
			)
		}
		return false
	})

	const isFrontNBackOverflowing = data.some((casset) => {
		if (casset.position === "front" || casset.position === "back") {
			return (
				casset.length > depth ||
				casset.width > width ||
				casset.thickness > length
			)
		}
		return false
	})

	return (
		isLeftNRightOverflowing ||
		isTopNBottomOverflowing ||
		isFrontNBackOverflowing
	)
}

/**
 *
 * This function prepare the pcm data to be used in the 3D visualisation. Basically it divides each pcm element's dimensions by 100 and Add Payload element to the pcm data.
 *
 * @param pcmData
 * @param boxData
 * @param payloadElement
 * @returns it returns { data, box } where data is the pcm data and box is the eps box dimensions
 */
export const preparePayload = (
	pcmData: PCM[],
	boxData: Box,
	payloadElement: PayloadElement | null
) => {
	const data = pcmData.map((pcm) => {
		return {
			...pcm,
			width: pcm.width / 100,
			length: pcm.length / 100,
			thickness: pcm.thickness / 100,
		}
	})
	if (payloadElement) {
		data.push({
			width: payloadElement.width / 100,
			length: payloadElement.length / 100,
			thickness: payloadElement.thickness / 100,
			pcm_type: "Payload",
			color: payloadElement.color,
			quantity: 1,
			ambient_temp: 0,
			cells_no: "tab630",
			id: "Payload",
			initial_temperature: 0,
			position: "bottom",
			select: false,
			size_type: "Tab",
			thawing: 0,
			enthalpy: 0,
			no_of_packs: 0
		})
	}

	const box: Box = {
		depth: boxData.depth / 100,
		length: boxData.length / 100,
		width: boxData.width / 100,
	}

	data.sort((a) => {
		return a.position === "bottom" ? -1 : 1
	})

	return { data, box }
}

export const populateColor = (data: Omit<PCM, "color">[]) => {
	return data.map((pcm) => {
		return {
			...pcm,
			color: "#a57633",
		} as PCM
	})
}
