import { Col, Form, Input, Modal, Row, Select, Switch, message } from "antd";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InventoryMethod, InventoryProperty } from "../types";
import {
  createInventoryMethodRequest,
  getInventoryMethodsLookupRequest,
  updateInventoryMethodRequest,
} from "src/store/actions/inventoryV2";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import useTranslate from "src/utils/useTranslate";
import { getDropdownFilteredValue, isValidNumber } from "src/utils/decorator";
import { useValue } from "src/utils/useValue";
import { StyledButton } from "src/styled_components/StyledButton";

type InventoryMethodModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  property: InventoryProperty;
  mode: "create" | "edit";
  method?: InventoryMethod;
  from: string;
  ingredient: any
};

export const InventoryMethodModal = ({
  open,
  setOpen,
  property,
  mode,
  method,
  from,
  ingredient
}: InventoryMethodModalProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const { convertValue } = useValue()
  const createMethodResponse = useSelector(
    (state: StoreState) => state.inventoryV2.createMethod
  );

  const getMethodsResponse = useSelector(
    (state: StoreState) => state.inventoryV2.getMethodsLookup
  );

  const unitsList = useSelector((state: StoreState) => state.conversion.unitList);

  const updateMethodResponse = useSelector(
    (state: StoreState) => state.inventoryV2.updateMethod
  );

  const [hasRangeSelected, setHasRangeSelected] = useState(false)
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    if (mode === "create") {
      dispatch(
        createInventoryMethodRequest({
          ...values,
          method_id: values.is_new ? undefined : values.method_name,
          method_name: values.is_new ? values.method_name : methodsOptions.find((mtdOption: any) => mtdOption.value === values.method_name)?.label,
          type: getMethodsResponse.methods.find((mth) => mth.method_id === values.method_id)?.type,
          ...(from === 'family' ? { family_id: property?.family_id } : { inventory_id: property.inventory_id }),
          ...(from === 'family' ? { property_id: property?.property_id } : { property_id: property?.inventory_property_id }),
          value: values.value ? convertValue(values.value) : undefined,
          value_max: values.value_max ? convertValue(values?.value_max) : null,
          value_type: from !== 'family' ? values.is_non_numerical_value ? "string" : "numerical" : undefined,
          from
        })
      );
    } else {
      dispatch(
        updateInventoryMethodRequest({
          ...values,
          ...(from === 'family' ? { family_id: method?.family_id } : { inventory_id: method?.inventory_id }),
          ...(from === 'family' ? { property_id: method?.property_id } : { property_id: method?.inventory_property_id }),
          method_id: values.is_new ? undefined : method?.method_id,
          value: values.value ? convertValue(values.value) : undefined,
          value_max: values.value_max ? convertValue(values?.value_max) : null,
          value_type: from !== 'family' ? values.is_non_numerical_value ? "string" : "numerical" : undefined,
          from
        })
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (getMethodsResponse.status === AsyncStates.INITIAL) {
      dispatch(getInventoryMethodsLookupRequest());
    }
  }, [getMethodsResponse.status, dispatch]);

  useEffect(() => {
    if (
      createMethodResponse.status === AsyncStates.SUCCESS ||
      updateMethodResponse.status === AsyncStates.SUCCESS
    ) {
      if (mode === "create") {
        form.resetFields();
      }
      setOpen(false);
      setHasRangeSelected(false)
    }
  }, [createMethodResponse, updateMethodResponse, dispatch, mode, setOpen, form, ingredient, method?.inventory_property_id, method?.method_id, property.inventory_property_id, from, method?.property_id, property.property_id]);

  useEffect(() => {
    if (mode === "create") {
      form.resetFields();
    } else {
      form.resetFields();
      form.setFieldsValue({ ...method, is_non_numerical_value: method?.value_type !== 'numerical' });
    }
  }, [form, method, mode]);

  const methodsOptions = useMemo(() => {
    return getMethodsResponse.methods?.filter((res: any) => {
      return !!res.method_name?.trim()
    }).map((res: any) => ({
      label: res.method_name,
      value: res?.method_id,
      key: res?.method_id
    })) ?? []
  }, [getMethodsResponse.methods])

  return (
    <Modal
      title={`${mode === "create" ? "Create" : "Update"} Method`}
      footer={null}
      width={500}
      open={open}
      onCancel={() => {
        if (mode === "create") {
          form.resetFields();
        }
        setOpen(false);
        setHasRangeSelected(false);
      }}
      maskClosable={false}
    >
      <Form
        size="small"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        onFieldsChange={(changedFields) => {
          if (Boolean(changedFields?.[0]?.name?.includes("has_range"))) {
            setHasRangeSelected(changedFields?.[0]?.value);
          }
        }}
      >
        {mode === "create" && (
          <Col span={8}>
            <Form.Item label="New" name="is_new">
              <Switch
                onChange={() => {
                  form.setFieldValue("method_name", "");
                }}
                defaultChecked={false}
                size="small"
              />
            </Form.Item>
          </Col>
        )}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.is_new !== currentValues.is_new
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("is_new") === true || mode === "edit" ? (
                  <Form.Item
                    label="Method Name"
                    name="method_name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Method Name"
                    name="method_name"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder={"Select Method"}
                      filterOption={(inputValue, options: any) =>
                        getDropdownFilteredValue(inputValue, options)
                      }
                      options={methodsOptions}
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </Col>
          {from !== "family" && (
            <Col span={12}>
              <Row>
                <Form.Item
                  label="Non Numerical Value"
                  name="is_non_numerical_value"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                {
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.is_non_numerical_value !==
                      currentValues.is_non_numerical_value
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("is_non_numerical_value") !== true ? (
                        <Form.Item
                          label="Range"
                          name="has_range"
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                }
              </Row>
            </Col>
          )}
        </Row>
        {from !== "family" &&
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.is_non_numerical_value !==
              currentValues.is_non_numerical_value
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("is_non_numerical_value") === true ? (
                <Form.Item label="Value" name="value_str">
                  <Input />
                </Form.Item>
              ) : <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t("inventory.PropertyValue")} name="value">
                    <Input
                      onBlur={(event) => {
                        const value = convertValue(event.target.value)
                        if (!!value && !isValidNumber(value)) {
                          form.setFieldValue("value", null)
                          message.error("Invalid Input")
                        }
                        if (form.getFieldValue("has_range") &&
                          !!(value) && !!form.getFieldValue("value_max")
                          && (value > convertValue(form.getFieldValue("value_max")))) {
                          form.setFieldValue("value", null)
                          message.error(t("inventory.ValueisGreaterthanMaxValue"))
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                {hasRangeSelected && (
                  <Col span={12} style={{ padding: "0rem 0rem" }}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.has_range !== currentValues.has_range
                      }
                    // hidden={(form) => form.getFieldValue('has_range')}
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("has_range") === true ? (
                          <Form.Item label="Max Property Value" name="value_max">
                            <Input
                              onBlur={(event) => {
                                const value = convertValue(event.target.value)
                                if (!!value && !isValidNumber(value)) {
                                  form.setFieldValue("value_max", null)
                                  message.error("Invalid Input")
                                }
                                if (!!form.getFieldValue("value") && !!value &&
                                  convertValue(form.getFieldValue("value")) > value) {
                                  form.setFieldValue("value_max", null)
                                  message.error(t("inventory.Maxvalueislessthanvalue"))
                                }
                              }}
                            />
                          </Form.Item>
                        ) : null
                      }
                    </Form.Item>
                  </Col>
                )}
              </Row>
            }

          </Form.Item>
        }
        <Row>
          <Col span={12}>
            <Form.Item label="Unit" name="unit">
              <Select showSearch placeholder={t("common.selectUnit")}>
                {Array.from(
                  new Set(
                    unitsList.map((res: any) => res.category.toLowerCase())
                  )
                ).map((category: any) => (
                  <Select.OptGroup
                    label={
                      String(category).charAt(0).toLocaleUpperCase() +
                      String(category).slice(1)
                    }
                  >
                    {unitsList
                      .filter(
                        (res: any) => res.category.toLowerCase() === category
                      )
                      .map((res: any, index: any) => (
                        <Select.Option value={res.name} key={res.name + index}>
                          {res.name}
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
          <StyledButton
            type="primary"
            htmlType="submit"
            style={{
              float: "right",
            }}
            loading={
              createMethodResponse.status === AsyncStates.LOADING ||
              updateMethodResponse.status === AsyncStates.LOADING
            }
          >
            {t("common.submit")}
          </StyledButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};
