import { useEffect, useState } from "react"
import { StoreState } from "../../../store/configureStore"
import { useSelector } from "react-redux"
import "./Compare.scss"
import { Space, Table, Select, Input, Row, Col } from "antd"
import Text from "antd/lib/typography/Text"
import { StyledCard } from "src/styled_components/StyledCard"
import useTranslate from "src/utils/useTranslate"

const { Option } = Select

export const ReportTable = ({
	tableData,
	setTableData,
	formulationList,
	displayTables,
}: {
	tableData: any
	setTableData: any
	formulationList: any
	displayTables: any
}) => {
	const linkedFormulationDetailsData = useSelector(
		(state: StoreState) =>
			state.compareFormulations.linkedFormulationDetailsData
	)
	const ingredientsIDList = Array.from(
		new Set(
			formulationList.flatMap(({ ingredients }: any) =>
				Object.keys(ingredients || {}).map(
					(ingredient) => ingredient
				)
			)
		)
	)
	const [selectedIngredientsIds, setselectedIngredientsIds] = useState<any[]>(
		Array.from(
			new Set(
				formulationList.flatMap(({ ingredients }: any) =>
					Object.keys(ingredients || {}).map(
						(ingredient) => ingredient
					)
				)
			)
		)
	)

	const ingredientsLabels = useSelector(
		(state: StoreState) => state.displayNames.data?.ingredients || {}
	)
	const [t] = useTranslate()

	useEffect(() => {
		setTableData((prevState: any) => ({
			...prevState,
			selectedIngredientsIds: selectedIngredientsIds,
		}))
	}, [selectedIngredientsIds, setTableData])

	const [alternate, setAlternate] = useState<any>([])

	useEffect(() => {
		setAlternate((prevState: any) => {
			if (prevState.length === 0) {
				return selectedIngredientsIds.reduce(
					(array: any, element: any) => [
						...array,
						{
							name: element,
							alternative:
								linkedFormulationDetailsData?.find(
									({ id_set }: any) => id_set.formulation_id === element
								)?.meta?.display_id ||
								ingredientsLabels[element]?.name ||
								element,
						},
					],
					[]
				)
			} else if (prevState.length < selectedIngredientsIds.length) {
				const names = prevState.reduce(
					(array: any, element: any) => [...array, element.name],
					[]
				)
				const element = selectedIngredientsIds.filter(
					(res: any) => !names.includes(res)
				)[0]
				const array = [...prevState]
				array.push({
					name: element,
					alternative:
						linkedFormulationDetailsData?.find(
							({ id_set }: any) => id_set.formulation_id === element
						)?.meta?.display_id ||
						ingredientsLabels[element]?.name ||
						element,
				})
				return array
			} else {
				const array = prevState.filter((res: any) =>
					selectedIngredientsIds.includes(res.name)
				)
				return array.map((element: any) => ({
					name: element.name,
					alternative:
						linkedFormulationDetailsData?.find(
							({ id_set }: any) => id_set.formulation_id === element.alternative
						)?.meta?.display_id ||
						ingredientsLabels[element.alternative]?.name ||
						element.alternative,
				}))
			}
		})
	}, [selectedIngredientsIds, ingredientsLabels, linkedFormulationDetailsData])

	useEffect(() => {
		setTableData((prevState: any) => ({ ...prevState, alternate: alternate }))
	}, [alternate, setTableData])

	const columns: any = [
		{
			title: t("common.ingredients"),
			dataIndex: "name",
			editable: true,
			render: (value: any, row: any, index: any) =>
				ingredientsLabels[value]?.name ||
				linkedFormulationDetailsData?.find(
					({ id_set }: any) => id_set.formulation_id === value
				)?.meta?.display_id ||
				value,
		},
		{
			title: t("compare.alternativeIngredientnames"),
			dataIndex: "alternative",
			render: (value: any, row: any, index: any) => {
				return (
					<Text
						editable={{
							tooltip: <>{t("common.edit")}</>,
							onChange: (e) => {
								const array = [...alternate]
								array.forEach((res) => {
									if (res.name === row.name) res.alternative = e
								})
								setAlternate(array)
							},
						}}
					>
						{
							alternate?.filter((res: any) => res?.name === row?.name)[0]
								?.alternative
						}
					</Text>
				)
			},
		},
	]

	return (
		<StyledCard title={t("common.tablesForReport")}>
			<Space direction="vertical" size="large" style={{ width: "100%" }}>
				<Row gutter={16}>
					<Col span={10}>
						<Select
							mode="multiple"
							allowClear
							style={{ width: "100%" }}
							placeholder={t("common.selectTables")}
							onChange={(e) => {
								setTableData((prevState: any) => ({ ...prevState, tables: e }))
							}}
						>
							{displayTables?.formulations && (
								<Option key="f1" value="formulation">
									{t("common.formulation")}
								</Option>
							)}
							{displayTables?.processing && (
								<Option key="f2" value="processing">
									{t("compare.processingSets")}
								</Option>
							)}
							{displayTables?.characterizations && (
								<Option key="f3" value="characterizations">
									{t("compare.Characterization_sets")}
								</Option>
							)}
							{displayTables?.properties && (
								<Option key="f4" value="property_sets">
									{t("compare.propertySets")}
								</Option>
							)}
						</Select>
					</Col>
					{tableData?.tables?.includes("formulation") && (
						<Col span={10}>
							<Select
								mode="multiple"
								value={selectedIngredientsIds}
								allowClear
								style={{ width: "100%" }}
								placeholder={t("common.selectIngredients")}
								onChange={setselectedIngredientsIds}
							>
								{ingredientsIDList.map((ingredient_id: any) => (
									<Option value={ingredient_id} key={ingredient_id}>
										{linkedFormulationDetailsData?.find(({ id_set }: any) => id_set.formulation_id === ingredient_id)?.meta?.display_id || ingredientsLabels[ingredient_id]?.name ||
											ingredient_id}
									</Option>
								))}
							</Select>
						</Col>
					)}
					<Col span={4}>
						<Input
							placeholder={t("common.trialsPerTable")}
							type="number"
							onChange={(e) =>
								setTableData((prevState: any) => ({
									...prevState,
									trialsCount: Number(e.target.value),
								}))
							}
						/>
					</Col>
				</Row>
				{selectedIngredientsIds?.length !== 0 &&
					tableData?.tables?.includes("formulation") && (
						<Table
							columns={columns}
							bordered
							dataSource={selectedIngredientsIds.reduce(
								(array: any, element: any) => [
									...array,
									{
										name: element,
										alternative:
											linkedFormulationDetailsData?.find(
												({ id_set }: any) => id_set.formulation_id === element
											)?.meta?.display_id || element,
									},
								],
								[]
							)}
						/>
					)}
			</Space>
		</StyledCard>
	)
}
