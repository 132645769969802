import { useMemo } from "react";
import { Select } from "antd";

const { Option } = Select

interface UseHighlightOptionTagsProps {
    data: any[],
    searchStr: string | any[]
}

export function useHighlightOptionTags({ data, searchStr }: UseHighlightOptionTagsProps) {
    const options = useMemo(() => {

        return data.map((d: any, index) => {

            if (searchStr) {
                const label = d.text
                const index = (typeof searchStr === 'string') ? label.toLowerCase().indexOf(searchStr.toLowerCase()) : -1

                if (index !== -1) {

                    const length = searchStr.length;

                    const prefix = label.substring(0, index);
                    const suffix = label.substring(index + length);
                    const match = label.substring(index, index + length);

                    return <Option key={d.value} value={d.value} disabled={d.disabled} >{prefix}<b>{match}</b>{suffix}</Option>
                }
            }

            return <Option key={d.value} value={d.value} disabled={d.disabled} >{d.text}</Option>

        })
    }, [data, searchStr])

    return options
}