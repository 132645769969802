import React, { useMemo } from "react"
import { Collapse, List, Tooltip, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
import { InfoCircleOutlined } from "@ant-design/icons"
import { useValue } from "src/utils/useValue"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { AsyncStates } from "src/constants"
const { Panel } = Collapse
const { Text } = Typography

export const Analysis = () => {

  // Other utility hooks
  const [t] = useTranslate()
  const { getValue: getEUValue } = useValue()

  // Selectors
  const { multistageHeatmapResultResponse: { results: data }, multistageHeatmapResultStatus } = useSelector((state: StoreState) => state.multiStageHeatmap)


  // Memo
  const analysisData = useMemo(() => {
    return data?.correlation_analysis || {}
  }, [data])

  return (
    multistageHeatmapResultStatus === AsyncStates.SUCCESS ?
      <Collapse>
        {analysisData["Highly Positive"].length > 0 && (
          <Panel
            header={
              <Text>
                {t("dataSummary.highlyPositive")}
                <Tooltip title={t("dataSummary.highlyPositiveDesc")}>
                  {" "}
                  <InfoCircleOutlined />
                </Tooltip>
              </Text>
            }
            key="Highly Positive"
          >
            <List
              size="small"
              bordered
              dataSource={analysisData["Highly Positive"]}
              renderItem={(item: any) => (
                <List.Item>
                  <Text strong type="secondary">
                    {`${item[0].toString()} ${t(
                      "dataSummary.hasAHighlyPositiveImpact",
                    )}(${getEUValue(item[1])}) ${t(
                      "common.on",
                    )} ${item[2].toString()}`}
                  </Text>
                </List.Item>
              )}
            />
          </Panel>
        )}

        {analysisData.Positive.length > 0 && (
          <Panel
            header={
              <Text>
                {t("dataSummary.positive")}
                <Tooltip title={t("dataSummary.positiveDesc")}>
                  {" "}
                  <InfoCircleOutlined />
                </Tooltip>
              </Text>
            }
            key="Positive"
          >
            <List
              size="small"
              bordered
              dataSource={analysisData.Positive}
              renderItem={(item: any) => (
                <List.Item>
                  <Text strong type="secondary">
                    {`${item[0].toString()} ${t(
                      "dataSummary.hasAPositiveImpact",
                    )}(${getEUValue(item[1])}) ${t(
                      "common.on",
                    )} ${item[2].toString()}`}
                  </Text>
                </List.Item>
              )}
            />
          </Panel>
        )}

        {analysisData["Highly Negative"].length > 0 && (
          <Panel
            header={
              <Text>
                {t("dataSummary.highlyNegative")}
                <Tooltip title={t("dataSummary.highlyNegativeDesc")}>
                  {" "}
                  <InfoCircleOutlined />
                </Tooltip>
              </Text>
            }
            key="Highly Negative"
          >
            <List
              size="small"
              bordered
              dataSource={analysisData["Highly Negative"]}
              renderItem={(item: any) => (
                <List.Item>
                  <Text strong type="secondary">
                    {`${item[0].toString()} ${t(
                      "dataSummary.hasAHighlyNegativeImpact",
                    )}(${getEUValue(item[1])}) ${t(
                      "common.on",
                    )} ${item[2].toString()}`}
                  </Text>
                </List.Item>
              )}
            />
          </Panel>
        )}

        {analysisData.Negative.length > 0 && (
          <Panel
            header={
              <Text>
                {t("dataSummary.negative")}
                <Tooltip title={t("dataSummary.negativeDesc")}>
                  {" "}
                  <InfoCircleOutlined />
                </Tooltip>
              </Text>
            }
            key="Negative"
          >
            <List
              size="small"
              bordered
              dataSource={analysisData.Negative}
              renderItem={(item: any) => (
                <List.Item>
                  <Text strong type="secondary">
                    {`${item[0].toString()} ${t(
                      "dataSummary.hasANegativeImpact",
                    )}(${getEUValue(item[1])}) ${t(
                      "common.on",
                    )} ${item[2].toString()}`}
                  </Text>
                </List.Item>
              )}
            />
          </Panel>
        )}
      </Collapse> : null
  )
}
