import { createActions } from "redux-actions";

export enum FetchPropertyActionTypes {
    REQUEST = "FETCH_PROPERTY_REQUEST",
    SUCCESS = "FETCH_PROPERTY_SUCCESS",
    FAILURE = "FETCH_PROPERTY_FAILURE",
    CLEAR = "FETCH_PROPERTY_CLEAR"
}

export enum FetchPropertyParmetersActionTypes {
    REQUEST = "FETCH_PROPERTY_PARAMETERS_REQUEST",
    SUCCESS = "FETCH_PROPERTY_PARAMETERS_SUCCESS",
    FAILURE = "FETCH_PROPERTY_PARAMETERS_FAILURE"
}

export enum CreatePropertyActionTypes {
    REQUEST = "CREATE_PROPERTY_REQUEST",
    SUCCESS = "CREATE_PROPERTY_SUCCESS",
    FAILURE = "CREATE_PROPERTY_FAILURE"
}

export enum UpdatePropertyActionTypes {
    REQUEST = "UPDATE_PROPERTY_REQUEST",
    SUCCESS = "UPDATE_PROPERTY_SUCCESS",
    FAILURE = "UPDATE_PROPERTY_FAILURE"
}


export enum RemovePropertyActionTypes {
    REQUEST = "REMOVE_PROPERTY_REQUEST",
    SUCCESS = "REMOVE_PROPERTY_SUCCESS",
    FAILURE = "REMOVE_PROPERTY_FAILURE"
}

export enum FetchCategoriesActionTypes {
    REQUEST = "FETCH_CATEGORY_REQUEST",
    SUCCESS = "FETCH_CATEGORY_SUCCESS",
    FAILURE = "FETCH_CATEGORY_FAILURE",
    CLEAR = "FETCH_CATEGORY_CLEAR"
}

export enum CreateCategoriesActionTypes {
    REQUEST = "CREATE_CATEGORY_REQUEST",
    SUCCESS = "CREATE_CATEGORY_SUCCESS",
    FAILURE = "CREATE_CATEGORY_FAILURE"
}

export enum UpdateCategoriesActionTypes {
    REQUEST = "UPDATE_CATEGORY_REQUEST",
    SUCCESS = "UPDATE_CATEGORY_SUCCESS",
    FAILURE = "UPDATE_CATEGORY_FAILURE"
}

export enum RemoveCategoriesActionTypes {
    REQUEST = "REMOVE_CATEGORY_REQUEST",
    SUCCESS = "REMOVE_CATEGORY_SUCCESS",
    FAILURE = "REMOVE_CATEGORY_FAILURE"
}

export enum FetchAdditivesActionTypes {
    REQUEST = "FETCH_ADDITIVE_REQUEST",
    SUCCESS = "FETCH_ADDITIVE_SUCCESS",
    FAILURE = "FETCH_ADDITIVE_FAILURE",
    CLEAR = "FETCH_ADDITIVE_CLEAR"
}

export enum CreateAdditivesActionTypes {
    REQUEST = "CREATE_ADDITIVE_REQUEST",
    SUCCESS = "CREATE_ADDITIVE_SUCCESS",
    FAILURE = "CREATE_ADDITIVE_FAILURE",
    CLEAR = "CREATE_ADDITIVE_CLEAR"
}

export enum UpdateAdditivesActionTypes {
    REQUEST = "UPDATE_ADDITIVE_REQUEST",
    SUCCESS = "UPDATE_ADDITIVE_SUCCESS",
    FAILURE = "UPDATE_ADDITIVE_FAILURE"
}

export enum RemoveAdditivesActionTypes {
    REQUEST = "REMOVE_ADDITIVE_REQUEST",
    SUCCESS = "REMOVE_ADDITIVE_SUCCESS",
    FAILURE = "REMOVE_ADDITIVE_FAILURE"
}

export enum FetchFieldActionTypes {
    REQUEST = "FETCH_FIELD_REQUEST",
    SUCCESS = "FETCH_FIELD_SUCCESS",
    FAILURE = "FETCH_FIELD_FAILURE",
    CLEAR = "FETCH_FIELD_CLEAR"
}

export enum CreateFieldActionTypes {
    REQUEST = "CREATE_FIELD_REQUEST",
    SUCCESS = "CREATE_FIELD_SUCCESS",
    FAILURE = "CREATE_FIELD_FAILURE"
}

export enum UpdateFieldActionTypes {
    REQUEST = "UPDATE_FIELD_REQUEST",
    SUCCESS = "UPDATE_FIELD_SUCCESS",
    FAILURE = "UPDATE_FIELD_FAILURE"
}

export enum RemoveFieldActionTypes {
    REQUEST = "REMOVE_FIELD_REQUEST",
    SUCCESS = "REMOVE_FIELD_SUCCESS",
    FAILURE = "REMOVE_FIELD_FAILURE"
}

export enum FetchParametersActionTypes {
    REQUEST = "FETCH_PARAMETERS_REQUEST",
    SUCCESS = "FETCH_PARAMETERS_SUCCESS",
    FAILURE = "FETCH_PARAMETERS_FAILURE",
    CLEAR = "FETCH_PARAMETERS_CLEAR"
}

export enum CreateParametersActionTypes {
    REQUEST = "CREATE_PARAMETERS_REQUEST",
    SUCCESS = "CREATE_PARAMETERS_SUCCESS",
    FAILURE = "CREATE_PARAMETERS_FAILURE",
    CLEAR = "CREATE_PARAMETERS_CLEAR"
}

export enum UpdateParametersActionTypes {
    REQUEST = "UPDATE_PARAMETERS_REQUEST",
    SUCCESS = "UPDATE_PARAMETERS_SUCCESS",
    FAILURE = "UPDATE_PARAMETERS_FAILURE"
}

export enum RemoveParametersActionTypes {
    REQUEST = "REMOVE_PARAMETERS_REQUEST",
    SUCCESS = "REMOVE_PARAMETERS_SUCCESS",
    FAILURE = "REMOVE_PARAMETERS_FAILURE"
}


export enum FetchAllMethodsActionTypes {
    REQUEST = "FETCH_ALL_METHODS_REQUEST",
    SUCCESS = "FETCH_ALL_METHODS_SUCCESS",
    FAILURE = "FETCH_ALL_METHODS_FAILURE",
    CLEAR = "FETCH_ALL_METHODS_CLEAR"
}

export enum FetchMethodsActionTypes {
    REQUEST = "FETCH_METHODS_REQUEST",
    SUCCESS = "FETCH_METHODS_SUCCESS",
    FAILURE = "FETCH_METHODS_FAILURE",
    CLEAR = "FETCH_METHODS_CLEAR"
}

export enum CreateMethodsActionTypes {
    REQUEST = "CREATE_METHODS_REQUEST",
    SUCCESS = "CREATE_METHODS_SUCCESS",
    FAILURE = "CREATE_METHODS_FAILURE",
    CLEAR = "CREATE_METHODS_CLEAR"
}

export enum UpdateMethodsActionTypes {
    REQUEST = "UPDATE_METHODS_REQUEST",
    SUCCESS = "UPDATE_METHODS_SUCCESS",
    FAILURE = "UPDATE_METHODS_FAILURE"
}

export enum RemoveMethodsActionTypes {
    REQUEST = "REMOVE_METHODS_REQUEST",
    SUCCESS = "REMOVE_METHODS_SUCCESS",
    FAILURE = "REMOVE_METHODS_FAILURE"
}

export enum FetchIngredientTemplatesActionTypes {
    REQUEST = "FETCH_INGREDIENT_TEMPLATES_REQUEST",
    SUCCESS = "FETCH_INGREDIENT_TEMPLATES_SUCCESS",
    FAILURE = "FETCH_INGREDIENT_TEMPLATES_FAILURE",
    CLEAR = "FETCH_INGREDIENT_TEMPLATES_CLEAR"
}

export enum CreateIngredientTemplatesActionTypes {
    REQUEST = "CREATE_INGREDIENT_TEMPLATES_REQUEST",
    SUCCESS = "CREATE_INGREDIENT_TEMPLATES_SUCCESS",
    FAILURE = "CREATE_INGREDIENT_TEMPLATES_FAILURE",
    CLEAR = "CREATE_INGREDIENT_TEMPLATES_CLEAR"
}

export enum UpdateIngredientTemplatesActionTypes {
    REQUEST = "UPDATE_INGREDIENT_TEMPLATES_REQUEST",
    SUCCESS = "UPDATE_INGREDIENT_TEMPLATES_SUCCESS",
    FAILURE = "UPDATE_INGREDIENT_TEMPLATES_FAILURE"
}

export enum RemoveIngredientTemplatesActionTypes {
    REQUEST = "REMOVE_INGREDIENT_TEMPLATES_REQUEST",
    SUCCESS = "REMOVE_INGREDIENT_TEMPLATES_SUCCESS",
    FAILURE = "REMOVE_INGREDIENT_TEMPLATES_FAILURE"
}

export enum FetchAllCategoriesActionTypes {
    REQUEST = "FETCH_ALL_CATEGORIES_REQUEST",
    SUCCESS = "FETCH_ALL_CATEGORIES_SUCCESS",
    FAILURE = "FETCH_ALL_CATEGORIES_FAILURE",
    CLEAR = "FETCH_ALL_CATEGORIES_CLEAR"
}

export enum FetchPropertyUnitsActionTypes {
    REQUEST = "FETCH_PROPERTY_UNITS_REQUEST",
    SUCCESS = "FETCH_PROPERTY_UNITS_SUCCESS",
    FAILURE = "FETCH_PROPERTY_UNITS_FAILURE",
    CLEAR = "FETCH_PROPERTY_UNITS_CLEAR"
}

export enum MarkPropertyUnitDefaultActionTypes {
    REQUEST = "MARK_PROPERTY_UNIT_DEFAULT_REQUEST",
    SUCCESS = "MARK_PROPERTY_UNIT_DEFAULT_SUCCESS",
    FAILURE = "MARK_PROPERTY_UNIT_DEFAULT_FAILURE"
}

export const {
    fetchPropertyRequest,
    fetchPropertySuccess,
    fetchPropertyFailure,
    fetchPropertyClear,

    fetchPropertyParametersRequest,
    fetchPropertyParametersSuccess,
    fetchPropertyParametersFailure,

    createPropertyRequest,
    createPropertySuccess,
    createPropertyFailure,

    updatePropertyRequest,
    updatePropertySuccess,
    updatePropertyFailure,

    removePropertyRequest,
    removePropertySuccess,
    removePropertyFailure,

    fetchCategoryRequest,
    fetchCategorySuccess,
    fetchCategoryFailure,
    fetchCategoryClear,

    createCategoryRequest,
    createCategorySuccess,
    createCategoryFailure,

    updateCategoryRequest,
    updateCategorySuccess,
    updateCategoryFailure,

    removeCategoryRequest,
    removeCategorySuccess,
    removeCategoryFailure,

    fetchAdditiveRequest,
    fetchAdditiveSuccess,
    fetchAdditiveFailure,
    fetchAdditiveClear,

    createAdditiveRequest,
    createAdditiveSuccess,
    createAdditiveFailure,

    updateAdditiveRequest,
    updateAdditiveSuccess,
    updateAdditiveFailure,

    removeAdditiveRequest,
    removeAdditiveSuccess,
    removeAdditiveFailure,

    fetchFieldRequest,
    fetchFieldSuccess,
    fetchFieldFailure,
    fetchFieldClear,

    createFieldRequest,
    createFieldSuccess,
    createFieldFailure,

    updateFieldRequest,
    updateFieldSuccess,
    updateFieldFailure,

    removeFieldRequest,
    removeFieldSuccess,
    removeFieldFailure,

    fetchParametersRequest,
    fetchParametersSuccess,
    fetchParametersFailure,
    fetchParametersClear,

    createParametersRequest,
    createParametersSuccess,
    createParametersFailure,
    createParametersClear,

    updateParametersRequest,
    updateParametersSuccess,
    updateParametersFailure,

    removeParametersRequest,
    removeParametersSuccess,
    removeParametersFailure,

    fetchAllMethodsRequest,
    fetchAllMethodsSuccess,
    fetchAllMethodsFailure,
    fetchAllMethodsClear,

    fetchMethodsRequest,
    fetchMethodsSuccess,
    fetchMethodsFailure,
    fetchMethodsClear,

    createMethodsRequest,
    createMethodsSuccess,
    createMethodsFailure,
    createMethodsClear,

    updateMethodsRequest,
    updateMethodsSuccess,
    updateMethodsFailure,

    removeMethodsRequest,
    removeMethodsSuccess,
    removeMethodsFailure,

    fetchIngredientTemplatesRequest,
    fetchIngredientTemplatesSuccess,
    fetchIngredientTemplatesFailure,
    fetchIngredientTemplatesClear,

    createIngredientTemplatesRequest,
    createIngredientTemplatesSuccess,
    createIngredientTemplatesFailure,
    createIngredientTemplatesClear,

    updateIngredientTemplatesRequest,
    updateIngredientTemplatesSuccess,
    updateIngredientTemplatesFailure,

    removeIngredientTemplatesRequest,
    removeIngredientTemplatesSuccess,
    removeIngredientTemplatesFailure,
    
    fetchAllCategoriesRequest,
    fetchAllCategoriesSuccess,
    fetchAllCategoriesFailure,
    fetchAllCategoriesClear,

    fetchPropertyUnitsRequest,
    fetchPropertyUnitsSuccess,
    fetchPropertyUnitsFailure,
    fetchPropertyUnitsClear,

    markPropertyUnitDefaultRequest,
    markPropertyUnitDefaultSuccess,
    markPropertyUnitDefaultFailure

} = createActions({
    [FetchPropertyActionTypes.REQUEST]: (payload) => payload,
    [FetchPropertyActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [FetchPropertyActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchPropertyActionTypes.CLEAR]: () => { },

    [FetchPropertyParmetersActionTypes.REQUEST]: (payload) => payload,
    [FetchPropertyParmetersActionTypes.SUCCESS]: (property_id: string, data: any) => { return ({ property_id, data }) },
    [FetchPropertyParmetersActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [CreatePropertyActionTypes.REQUEST]: (payload) => payload,
    [CreatePropertyActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [CreatePropertyActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [UpdatePropertyActionTypes.REQUEST]: (payload) => payload,
    [UpdatePropertyActionTypes.SUCCESS]: (property_id: string | number, data: any) => { return ({ property_id, data }) },
    [UpdatePropertyActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [RemovePropertyActionTypes.REQUEST]: (payload) => payload,
    [RemovePropertyActionTypes.SUCCESS]: (property_ids: any) => { return ({ property_ids }) },
    [RemovePropertyActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [FetchCategoriesActionTypes.REQUEST]: payload => payload,
    [FetchCategoriesActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [FetchCategoriesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchCategoriesActionTypes.CLEAR]: () => { },

    [CreateCategoriesActionTypes.REQUEST]: (payload) => payload,
    [CreateCategoriesActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [CreateCategoriesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [UpdateCategoriesActionTypes.REQUEST]: (payload) => payload,
    [UpdateCategoriesActionTypes.SUCCESS]: (category_id: string | number, data: any) => { return ({ category_id, data }) },
    [UpdateCategoriesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [RemoveCategoriesActionTypes.REQUEST]: (payload) => payload,
    [RemoveCategoriesActionTypes.SUCCESS]: (category_ids: any) => { return ({ category_ids }) },
    [RemoveCategoriesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [FetchAdditivesActionTypes.REQUEST]: payload => payload,
    [FetchAdditivesActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [FetchAdditivesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchAdditivesActionTypes.CLEAR]: () => { },

    [CreateAdditivesActionTypes.REQUEST]: (payload) => payload,
    [CreateAdditivesActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [CreateAdditivesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [UpdateAdditivesActionTypes.REQUEST]: (payload) => payload,
    [UpdateAdditivesActionTypes.SUCCESS]: (additive_id: string | number, data: any) => { return ({ additive_id, data }) },
    [UpdateAdditivesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [RemoveAdditivesActionTypes.REQUEST]: (payload) => payload,
    [RemoveAdditivesActionTypes.SUCCESS]: (additive_ids: any) => { return ({ additive_ids }) },
    [RemoveAdditivesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [FetchFieldActionTypes.REQUEST]: payload => payload,
    [FetchFieldActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [FetchFieldActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchFieldActionTypes.CLEAR]: () => { },

    [CreateFieldActionTypes.REQUEST]: (payload) => payload,
    [CreateFieldActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [CreateFieldActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [UpdateFieldActionTypes.REQUEST]: (payload) => payload,
    [UpdateFieldActionTypes.SUCCESS]: (field_id: string | number, data: any) => { return ({ field_id, data }) },
    [UpdateFieldActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [RemoveFieldActionTypes.REQUEST]: (payload) => payload,
    [RemoveFieldActionTypes.SUCCESS]: (field_ids: any) => { return ({ field_ids }) },
    [RemoveFieldActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [FetchParametersActionTypes.REQUEST]: payload => payload,
    [FetchParametersActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [FetchParametersActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchParametersActionTypes.CLEAR]: () => { },

    [CreateParametersActionTypes.REQUEST]: (payload) => payload,
    [CreateParametersActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [CreateParametersActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [CreateParametersActionTypes.CLEAR]: () => { },

    [UpdateParametersActionTypes.REQUEST]: (payload) => payload,
    [UpdateParametersActionTypes.SUCCESS]: (parameter_id: string | number, data: any) => { return ({ parameter_id, data }) },
    [UpdateParametersActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [RemoveParametersActionTypes.REQUEST]: (payload) => payload,
    [RemoveParametersActionTypes.SUCCESS]: (parameter_ids: any) => { return ({ parameter_ids }) },
    [RemoveParametersActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [FetchAllMethodsActionTypes.REQUEST]: payload => payload,
    [FetchAllMethodsActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [FetchAllMethodsActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchAllMethodsActionTypes.CLEAR]: () => { },

    [FetchMethodsActionTypes.REQUEST]: payload => payload,
    [FetchMethodsActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [FetchMethodsActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchMethodsActionTypes.CLEAR]: () => { },

    [CreateMethodsActionTypes.REQUEST]: (payload) => payload,
    [CreateMethodsActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [CreateMethodsActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [CreateMethodsActionTypes.CLEAR]: () => { },

    [UpdateMethodsActionTypes.REQUEST]: (payload) => payload,
    [UpdateMethodsActionTypes.SUCCESS]: (method_id: string | number, data: any) => { return ({ method_id, data }) },
    [UpdateMethodsActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [RemoveMethodsActionTypes.REQUEST]: (payload) => payload,
    [RemoveMethodsActionTypes.SUCCESS]: (method_ids: any) => { return ({ method_ids }) },
    [RemoveMethodsActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [FetchIngredientTemplatesActionTypes.REQUEST]: payload => payload,
    [FetchIngredientTemplatesActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [FetchIngredientTemplatesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [FetchIngredientTemplatesActionTypes.CLEAR]: () => { },

    [CreateIngredientTemplatesActionTypes.REQUEST]: (payload) => payload,
    [CreateIngredientTemplatesActionTypes.SUCCESS]: (data: any) => { return ({ data: data }) },
    [CreateIngredientTemplatesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [CreateIngredientTemplatesActionTypes.CLEAR]: () => { },

    [UpdateIngredientTemplatesActionTypes.REQUEST]: (payload) => payload,
    [UpdateIngredientTemplatesActionTypes.SUCCESS]: (template_id: string | number, data: any) => { return ({ template_id, data }) },
    [UpdateIngredientTemplatesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),

    [RemoveIngredientTemplatesActionTypes.REQUEST]: (payload) => payload,
    [RemoveIngredientTemplatesActionTypes.SUCCESS]: (template_ids: any) => { return ({ template_ids }) },
    [RemoveIngredientTemplatesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    
    [FetchAllCategoriesActionTypes.REQUEST]: payload => payload,
    [FetchAllCategoriesActionTypes.SUCCESS]: (data) => data,
    [FetchAllCategoriesActionTypes.FAILURE]: (message) => message,
    [FetchAllCategoriesActionTypes.CLEAR]: () => { },

    [FetchPropertyUnitsActionTypes.REQUEST]: payload => payload,
    [FetchPropertyUnitsActionTypes.SUCCESS]: (data) => data,
    [FetchPropertyUnitsActionTypes.FAILURE]: (message) => message,
    [FetchPropertyUnitsActionTypes.CLEAR]: () => { },

    [MarkPropertyUnitDefaultActionTypes.REQUEST]: payload => payload,
    [MarkPropertyUnitDefaultActionTypes.SUCCESS]: (data) => data,
    [MarkPropertyUnitDefaultActionTypes.FAILURE]: (message) => message
});
