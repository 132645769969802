import { CloseOutlined } from "@ant-design/icons"
import { Alert, Typography } from "antd"
import { ReactNode } from "react"
import { antdTheme } from "src/constants"

type P = {
  icon: ReactNode
  content: ReactNode
}

const Note = ({ content, icon }: P) => {
  return (
    <Alert
      description={
        <Typography.Text
        >
          {content}
        </Typography.Text>
      }
      type="info"
      showIcon
      closable
      closeIcon={
        <Typography.Text
        >
          <CloseOutlined style={{ padding: '0px 16px 0px 0px' }} />
        </Typography.Text>
      }
      icon={
        <div
          style={{
            color: "rgb(0,0,0,0.5)",
            lineHeight: antdTheme.lineHeight,
            fontSize: antdTheme.fontSize,
          }}
        >
          {icon}
        </div>
      }
      style={{ border: "none", padding: "16px" }}
      className="suggested-exp-note"
    />
  )
}

export default Note
