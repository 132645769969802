import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock"
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';
import { removeUnderScore } from './utils';
import { useValue } from 'src/utils/useValue';
import useTranslate from 'src/utils/useTranslate';


export type ExplainableAIResultProps = {
    property: string,
    model: string[] | [],
    filterBy?: any,
    colorOptions?: any,
    setWaterfallPlotOptions?: any
}

export const ExplainableAIBarChart = ({ property, model, filterBy, colorOptions }: ExplainableAIResultProps) => {
    const [t] = useTranslate();
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)
    const { explainableAIData } = useSelector((state: StoreState) => state.explainableAI)
    const { getValue } = useValue()
    const getDisplayName = useCallback((catergory: string) => {
        const displayName = displayNames.processing?.[catergory]?.name ?? displayNames.ingredients?.[catergory]?.name
        return displayName ?? catergory
    }, [displayNames.ingredients, displayNames.processing])

    const plotData = useMemo(() => {
        return model?.map((modelItem: any, index: number) => ({
            name: removeUnderScore(modelItem) ?? modelItem,
            data: !!filterBy.length ?
                filterBy.map((filteredParameter: string, index: number) => {
                    const elementIndex = explainableAIData?.[property]?.x_cols.indexOf(filteredParameter)
                    const data = explainableAIData?.[property]?.[modelItem]?.plots?.bar[elementIndex]
                    return {
                        y: data,
                        name: getDisplayName(filteredParameter)
                    }
                })
                : [...explainableAIData?.[property]?.[modelItem]?.plots?.bar].map((data, index) => ({
                    y: data,
                    name: getDisplayName(explainableAIData?.[property]?.x_cols?.[index])
                })),
            pointWidth: 10,
            color: colorOptions[modelItem]
        }))
    }, [model, property, explainableAIData, colorOptions, filterBy, getDisplayName])

    const options = useMemo(() => {
        return {
            chart: {
                type: 'bar',
                height: 790,
                zoomType: 'xy',
            },
            title: {
                text: null,
                align: 'center'
            },
            xAxis: {
                type: 'category',
                labels: {
                    formatter: function (item: any) {
                        if (typeof (item.value) === 'string') {
                            return item.value
                        }
                    },
                },
                title: {
                    text: null
                },
                min: 0,
                max: (!!filterBy.length && filterBy.length < 7) ? filterBy.length - 1 : explainableAIData?.[property]?.x_cols.length < 7 ? explainableAIData?.[property]?.x_cols.length - 1 : 7,

                scrollbar: {
                    enabled: true
                },
            },
            yAxis: {
                min: 0,
                max: 100,
                title: {
                    text: `${displayNames.properties?.[property]?.name ?? property} (${t("aiEngine.modelAnalysis.percentContribution")})`,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify',
                    formatter: function (item: any) {
                        return `${getValue(item?.value)}`
                    }
                }
            },
            tooltip: {
                useHTML: true,
                formatter: function (item: any) {
                    const point = (this as any).point
                    return `
                            <strong>${point.name}</strong><br/>
                            <strong>${point.series.name} : ${getValue(point.y, 2)}%</strong>`

                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        formatter: function (this: any) {
                            const pointValue = `${getValue(this.y, 2)}%`
                            return pointValue
                        },
                    },
                }
            },
            legend: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            series: plotData
        }
    }, [displayNames.properties, plotData, property, explainableAIData, filterBy.length, getValue, t])

    return (
        <div style={{ width: "100%", height: "800px" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )
}
