import { sendPost } from "src/utils/sendApi";

export const deleteFileApi = sendPost("v1/data/_file_delete_new");

export const fileUploadAPi = sendPost("v1/data/_fileupload_new");

export const draftFileUploadAPi = sendPost("v1/data/_upload_file");

export const fileHistoryApi = sendPost("v1/data/_file_history_new");

export const importMultipleWorkOrdersApi = sendPost("v1/data/toyoink/_file_upload");
export const fileListApi = sendPost("v1/data/_files_list");

