import _ from "lodash"
import { useState, useEffect, useMemo, useCallback, useRef } from "react"
import {
  Space,
  Row,
  message,
  Col,
  Collapse,
  Form,
  Table,
  Tag,
  Typography,
  Alert,
  MenuProps,
  Segmented,
  TableColumnsType,
  Drawer,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import useTranslate, { TranslationKey } from "src/utils/useTranslate"
import { antdTheme, AsyncStates, zeonFixedCategoryList } from "src/constants"
import {
  transposeData,
  removeNulls,
  newColumnData,
  customScrollIntoView,
} from "../../../../utils/decorator"
//import { getClickedDataRequest } from 'src/store/actions/clickedData'
import {
  LoadingOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons"
import { StyledButton } from "src/styled_components/StyledButton"
import ShowVariationDetail from "../../InverseResults/ShowVariationDetail"
import { SuggestedExpVisualization } from "./SuggestedExpVisualization"
import { exportPredictionRequest } from "src/store/actions/suggestedExp"
import { RecipeDistribution } from "../../ReciepeDistribution"
import { WorkOrderModal } from "../../ForwardModel/WorkOrderModal"
import { linkedCompareFormulationsRequest } from "src/store/actions/compareFormulations"
import { AiCosting } from "../../common/AiCosting"
import "./SuggestedExp.scss"
import BuildOfMaterials from "./BuildOfMaterials"
import FilterAndSorting from "./FilterAndSorting"
import TopAction from "../SuggestedExpShared/TopAction"
import ModelDetails from "../SuggestedExpShared/ModelDetails"
import Note from "../../Note"
import InputConstraintsPanel from "../SuggestedExpShared/InputConstraintsPanel"
import { DroppedPropertyWarning } from "../../common/DroppedPropertyWarning"
import { LinkedTrialsAiEngine } from "../../common/LinkedTrialsAiEngine"
import { linkedFormulationsAiEngineRequest } from "src/store/actions/common"
import { useValue } from "src/utils/useValue"
import StyledBadge from "src/styled_components/StyledBadge"
import { ExportDataSheetModal } from "../../common/ExportDataSheetModal";

export const plotFont = {
  family: "Courier New, monospace",
  size: 18,
  color: "#11111",
}

const { Text } = Typography

export const translatedLabel = (
  input: string,
  t: (key: TranslationKey) => string,
) => {
  if (input === "ingredients") return t("common.ingredients")
  if (input === "processing") return t("common.processing")
  if (input === "predicted_properties") return t("common.properties")
  if (input === "cost") return t("inventory.costing")
  return input
}

type CheckedExperimentsProps = {
  checkAllPages: number[]
  experimentIdList: any[]
}

export const SuggestedExp = ({
  experimentsCurrent,
  pageChange,
  filters,
  setFilters,
  selectedObjective,
  variationDetail,
  zeonCurrentPageInfo,
  key: componentKey,
  tab,
}: any) => {
  const [t] = useTranslate()
  const { getValue, convertValue } = useValue()

  const dispatch = useDispatch()
  const collator = useMemo(() => new Intl.Collator([], { numeric: true }), [])
  const tableRef = useRef<any>(null)

  const configs = useSelector((state: StoreState) => state.configs.features)

  const { configData } = useSelector((state: StoreState) => state.formulate)

  const {
    expIdStatus,
    data: { experiments, total, inverseMissingProperties = [] },
    predictionDataExportStatus,
  } = useSelector((state: StoreState) => state.suggestedExp)

  const dataRelatedToRecipeDistribution = useSelector(
    (state: StoreState) => state.insights?.data?.data?.results,
  )
  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  )
  const { inverseStatus } = useSelector(
    (state: StoreState) => state.newInverseModel,
  )
  const [clickedExperiments, setClickedExperiments] = useState<any>([])
  const [operatorStates, setOperatorStates] = useState<any[]>([])
  const [form] = Form.useForm()
  const [checkedExperiments, setCheckedExperiments] =
    useState<CheckedExperimentsProps>({
      checkAllPages: [],
      experimentIdList: [],
    })

  const [checkedSelectedExp, setCheckedSelectedExp] = useState<string[]>(clickedExperiments.map((res: any) => res.experiment_id))

  // useEffect(()=>{
  //   setCheckedSelectedExp(clickedExperiments.map((res: any) => res.experiment_id))
  // },[clickedExperiments])

  const [filtersVersion, setFiltersVersion] = useState<any>()
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)
  const [suggestedExpFilters, setsuggestedExpFilters] = useState<any>({})
  const [selectedRecipeIds, setselectedRecipeIds] = useState<any[]>([])

  const ingredientsLables = useSelector(
    (state: StoreState) => state.displayNames.data?.ingredients ?? {},
  )
  const processingLables = useSelector(
    (state: StoreState) => state.displayNames.data?.processing ?? {},
  )

  const propertiesLables = useSelector(
    (state: StoreState) => state.displayNames.data?.properties ?? {},
  )

  const isMultistage = useMemo(() => {
    return suggestedExpFilters?.is_multistage || false
  }, [suggestedExpFilters])

  const inputType = useMemo(
    () => ["ingredients", "processing", "predicted_properties", "cost"],
    [],
  )
  const [workOrderVisible, setWorkOrderVisible] = useState(false)
  const [expType, setExpType] = useState<any>()
  const [isFilterSortingVisible, setIsFilterSortingVisible] =
    useState<boolean>(false)

  const [exportDataSheetModalData, setExportDataSheetModalData] = useState({
    isModalVisible: false,
    include_range: true
  })

  useEffect(() => {
    if (inverseStatus === AsyncStates.SUCCESS) form.resetFields()
  }, [form, inverseStatus])

  useEffect(() => {
    setCheckedExperiments({
      checkAllPages: [],
      experimentIdList: [],
    })
  }, [
    selectedObjective,
    zeonCurrentPageInfo?.variation_id,
    zeonCurrentPageInfo?.version,
    componentKey,
  ])

  useEffect(() => {
    if (expIdStatus === AsyncStates.SUCCESS && !!experiments.length) {
      setsuggestedExpFilters(experiments[0])
    }
  }, [experiments, expIdStatus])

  useEffect(() => {
    if (expIdStatus === AsyncStates.SUCCESS) {
      setFiltersVersion(
        configData.find(
          (config: any) => config?.version === experiments?.[0]?.version,
        ),
      )
    }
  }, [configData, experiments, expIdStatus])

  const zeonCategoryList: any[] = useMemo(() => {
    const variableCategoryList = Object.values(
      experiments?.[0]?.ingredients || {},
    ).map((res: any) => res?.category)
    return [...new Set([...zeonFixedCategoryList, ...variableCategoryList])]
  }, [experiments])

  const zeonCategorySocketList = useMemo(() => {
    const variableCategoryList = Object.values(
      dataRelatedToRecipeDistribution?.[0]?.ingredients || {},
    ).map((res: any) => res?.category)
    return [...new Set([...zeonFixedCategoryList, ...variableCategoryList])]
  }, [dataRelatedToRecipeDistribution])

  const recipeStatus = useSelector((state: StoreState) => state.insights.status)
  // useEffect(() => {
  // 	if (expIdStatus === AsyncStates.SUCCESS) {
  // 		console.log("running")
  // 		setCheckedExperiments({
  // 			checkAllPages: [],
  // 			predictionIdList: [],
  // 		})
  // 	}
  // }, [experiments, expIdStatus, displayNames])

  const checkChange = useCallback(
    (e: any, i: any, type: any) => {
      if (type === "suggested") {
        if (e.target.checked) {
          setCheckedExperiments((prevState) => {
            const page = Boolean(configs?.ai_engine_with_methods)
              ? zeonCurrentPageInfo?.currentPage
              : experimentsCurrent

            const data = [
              ...new Set([...prevState.experimentIdList, { experiment_id: e.target.name, idx: i, page: page }]),
            ]
            const result = experiments.filter(
              (exp: any) => !data.map((res: any) => res.experiment_id).includes(exp.experiment_id),
            )

            const checkAllPages =
              result.length % 10 === 0
                ? [...new Set([...prevState.checkAllPages, page])]
                : [...new Set([...prevState.checkAllPages])]

            const alreadyChecked = prevState.experimentIdList.map((res: any) => res.experiment_id).includes(e.target.name)
            return {
              checkAllPages: checkAllPages,
              experimentIdList: [
                ...new Set([...prevState.experimentIdList, ...(!alreadyChecked ? [{ experiment_id: e.target.name, idx: i, page: page }] : [])]),
              ],
            } as any
          })
        } else {
          setCheckedExperiments((prev) => {
            return {
              checkAllPages: [
                ...new Set(
                  prev.checkAllPages.filter(
                    (res: any) =>
                      res !==
                      (Boolean(configs?.ai_engine_with_methods)
                        ? zeonCurrentPageInfo?.currentPage
                        : experimentsCurrent),
                  ),
                ),
              ],
              experimentIdList: prev.experimentIdList.filter(
                (res: any) => res.experiment_id !== e.target.name,
              ),
            }
          })
        }
      }
      if (type === "recipe-suggested") {
        if (e.target.checked) {
          setselectedRecipeIds((prevState) => [
            ...new Set([...prevState, e.target.name]),
          ])
        } else {
          setselectedRecipeIds((prev) =>
            prev.filter((res: any) => res !== e.target.name),
          )
        }
      } else if (type === "suggested_clicked") {
        if (e.target.checked) {
          setCheckedSelectedExp((prevState) => [
            ...new Set([...prevState, e.target.name]),
          ])
        } else {
          setCheckedSelectedExp((prevState: any) => {
            return prevState.filter((res: any) => res !== e.target.name)
          })
        }
      }
    },
    [
      experimentsCurrent,
      zeonCurrentPageInfo?.currentPage,
      configs,
      experiments,
    ],
  )

  const selectAll = useCallback(
    (e: any, type: any) => {
      if (type === "suggested") {
        if (e.target.checked) {
          setCheckedExperiments((prevState) => {
            const page = Boolean(configs?.ai_engine_with_methods)
              ? zeonCurrentPageInfo?.currentPage
              : experimentsCurrent
            const data = [
              ...new Set([
                ...prevState.experimentIdList.filter((res: any) => res.page !== page),
                ...experiments.map((res: any, idx: number) => ({ experiment_id: res.experiment_id, idx, page })),
              ]),
            ]
            return {
              checkAllPages: [
                ...new Set([
                  ...prevState.checkAllPages,
                  Boolean(configs?.ai_engine_with_methods)
                    ? zeonCurrentPageInfo?.currentPage
                    : experimentsCurrent,
                ]),
              ],
              experimentIdList: data,
            } as any
          })
        } else {
          setCheckedExperiments((prev) => {
            const data = prev.experimentIdList.filter(
              (res: any) =>
                !experiments.map((res: any) => res.experiment_id).includes(res.experiment_id),
            )
            return {
              checkAllPages: [
                ...new Set(
                  prev.checkAllPages.filter(
                    (res: any) =>
                      res !==
                      (Boolean(configs?.ai_engine_with_methods)
                        ? zeonCurrentPageInfo?.currentPage
                        : experimentsCurrent),
                  ),
                ),
              ],
              experimentIdList: data,
            }
          })
        }
      } else if (type === "recipe-suggested") {
        setselectedRecipeIds([])
      } else if (type === "suggested_clicked") {
        if (e.target.checked) {
          setCheckedSelectedExp(
            clickedExperiments.map((res: any) => res.experiment_id),
          )
        } else {
          setCheckedSelectedExp([])
        }
      }
    },
    [
      clickedExperiments,
      configs,
      experiments,
      experimentsCurrent,
      zeonCurrentPageInfo?.currentPage,
    ],
  )

  const tableColumns = useMemo(() => {
    return newColumnData(
      experiments,
      checkChange,
      displayNames,
      checkedExperiments,
      "suggested",
      selectAll,
      t,
      Boolean(configs?.ai_engine_with_methods)
        ? zeonCurrentPageInfo?.currentPage
        : experimentsCurrent,
      Boolean(configs?.ai_engine_with_methods),
    )
  }, [
    displayNames,
    experiments,
    selectAll,
    t,
    configs,
    checkChange,
    checkedExperiments,
    experimentsCurrent,
    zeonCurrentPageInfo?.currentPage,
  ])

  const updateHeaders = useCallback((data: any[]) => {
    if (!Array.isArray(data)) return [];

    const clonedData = _.cloneDeep(data)
    const reversedData = clonedData.reverse();

    let counter = 0;
    for (let i = 0; i < reversedData.length; i++) {
      if (reversedData[i]?.key?.includes("header")) {
        const parameter = reversedData[i]?.parameter;
        reversedData[i] = {
          ...reversedData[i],
          parameter: typeof parameter === "string" ? `${parameter.split(" ").slice(0, -1).join(" ")} (${counter})` : ""
        }
        counter = 0
      }
      else counter++
    }

    return reversedData.reverse();
  }, []);

  const filteredData = useMemo(() => {
    const newData: any = transposeData(
      experiments,
      t,
      getValue,
      zeonCategoryList,
      false,
      Boolean(configs?.nestle_configs),
    )
    const removedNulls = removeNulls(newData, "suggested_exp", convertValue, experiments)
    return updateHeaders(removedNulls).map((res: any, index: number) => ({
      ...res,
      ...(res?.key?.includes("header") ? {} : { key: index }),
    }))
  }, [experiments, configs, zeonCategoryList, t, getValue, convertValue, updateHeaders])

  const generateWO = useCallback(
    (type: string, selectAll: boolean = false) => {
      if (type === "suggested") {
        if (selectAll) {
          setIsSelectAll(true)
          setExpType([])
          setWorkOrderVisible(true)
        } else if (checkedExperiments.experimentIdList.length > 0) {
          // dispatch(includeSuggestedExp({ suggested_experiment_id: checkedExperiments.experimentIdList, version: experiments?.[0]?.version }))
          // dispatch(setPredictionId(experiments?.[0]?.prediction_id))
          // push("/work-orders/details")
          setExpType(checkedExperiments.experimentIdList.map((res: any) => res.experiment_id))
          setIsSelectAll(false)
          setWorkOrderVisible(true)
        } else {
          message.warning(t("aiEngine.wo.error"))
        }
      }
      if (type === "recipe-suggested") {
        if (selectedRecipeIds.length > 0) {
          // dispatch(includeSuggestedExp({ suggested_experiment_id: selectedRecipeIds, version: experiments?.[0]?.version }))
          // dispatch(setPredictionId(experiments?.[0]?.prediction_id))
          // push("/work-orders/details")
          setExpType(selectedRecipeIds)
          setWorkOrderVisible(true)
        } else {
          message.warning(t("aiEngine.wo.error"))
        }
      } else if (type === "suggested_clicked") {
        if (checkedSelectedExp.length > 0) {
          // dispatch(includeSuggestedExp({ suggested_experiment_id: checkedSelectedExp, version: experiments?.[0]?.version }))
          // dispatch(setPredictionId(clickedExperiments?.[0]?.prediction_id))
          // push("/work-orders/details")
          setExpType(checkedSelectedExp)
          setWorkOrderVisible(true)
        } else {
          message.warning(t("aiEngine.wo.error"))
        }
      }
    },
    [
      checkedExperiments.experimentIdList,
      checkedSelectedExp,
      selectedRecipeIds,
      t,
    ],
  )

  const generateWoMenuItems: MenuProps = useMemo(() => {
    return {
      items: [
        {
          key: "generate-wo-selected-trials",
          label: t("aiEngine.generateWorkOrder"),
          disabled: expIdStatus === AsyncStates.LOADING,
        },
        {
          key: "generate-wo-all-trials-all-stages",
          label: `${t("aiEngine.generateWorkOrderAll")} (${t(
            "workOrders.stage.allStages",
          )})`,
          disabled: expIdStatus === AsyncStates.LOADING,
        },
      ],
      onClick: ({ key }: any) => {
        generateWO("suggested", key === "generate-wo-all-trials-all-stages")
      },
    }
  }, [expIdStatus, generateWO, t])

  const clearExperiments = () => {
    setCheckedSelectedExp([])
    setClickedExperiments([])
  }

  const [currentSelectedStage, setCurrentSelectedStage] = useState<number>(1)

  const applyFilters = ({
    expFilters,
    sorting,
    isCleared = false,
  }: {
    expFilters: any
    sorting: any
    isCleared?: boolean
  }) => {
    if (!expFilters?.length && !sorting?.length && isCleared) {
      pageChange(1, "experiments", {
        ...(isMultistage ? { stage: experiments?.[0]?.all_stages?.[currentSelectedStage - 1] ?? suggestedExpFilters?.all_stages?.[currentSelectedStage - 1] } : {}),
      })
      setFilters([])
      return
    }

    if (!expFilters?.length && !sorting?.length) {
      message.error(t("aiEngine.filters.error"))
    } else {
      let filtersPayload = []
      let sortingPayload = []
      if (!!expFilters?.length) {
        filtersPayload = expFilters
          .filter((item: any) => !!item)
          .reduce(
            (array: any, res: any) => [
              ...array,
              res.property?.[1] === "total_cost"
                ? {
                  category: res.property?.[1],
                  field: "",
                  operand: res.operand,
                  minVal: String(convertValue(res.minVal)),
                  ...(res?.maxVal && { maxVal: String(convertValue(res.maxVal)) }),
                }
                : {
                  category: res.property?.[0],
                  field: res.property?.[1],
                  operand: res.operand,
                  minVal: String(convertValue(res.minVal)),
                  ...(res?.maxVal && { maxVal: String(convertValue(res.maxVal)) }),
                },
            ],
            [],
          )
      }
      if (!!sorting?.length) {
        sortingPayload = sorting
          .filter((item: any) => !!item)
          .reduce(
            (array: any, res: any) => [
              ...array,
              res.property?.[1] === "total_cost"
                ? {
                  category: res.property?.[1],
                  field: "",
                  sort_order: res.sort_order,
                }
                : {
                  category: res.property?.[0],
                  field: res.property?.[1],
                  sort_order: res.sort_order,
                },
            ],
            [],
          )
      }
      const payload = {
        expFilters: filtersPayload,
        sorts: sortingPayload,
        ...(isMultistage ? { stage: experiments?.[0]?.all_stages?.[currentSelectedStage - 1] ?? suggestedExpFilters?.all_stages?.[currentSelectedStage - 1] } : {}),
      }
      setFilters(payload)
      clearExperiments()
      pageChange(1, "experiments", payload)
    }
  }

  const modelConfigData = useSelector(
    (state: StoreState) => state.formulate.modelConfigData,
  )

  const filterData: any = useMemo(() => {
    return inputType
      .map((parameter) => {
        if (
          Object.keys(suggestedExpFilters || {}).includes(parameter) &&
          Object.keys(suggestedExpFilters?.[parameter] || {}).length > 0
        ) {
          return {
            value: parameter,
            label: translatedLabel(parameter, t),
            children:
              parameter === "cost"
                ? [
                  { value: "total_cost", label: "Total Cost" },
                  ...[
                    ...new Set(
                      experiments.flatMap(({ cost }: any) =>
                        Object.keys(cost.ingredients),
                      ),
                    ),
                  ]
                    .map((value: any) => ({
                      value,
                      label: displayNames.ingredients[value]?.name,
                    }))
                    .sort((a: any, b: any) =>
                      collator.compare(a.label, b.label),
                    ),
                ]
                : Object.keys(
                  suggestedExpFilters?.results_range?.[parameter] || {},
                ).map((res: any) => ({
                  value: res,
                  label:
                    suggestedExpFilters?.results_range?.[parameter]?.[res]
                      ?.name ??
                    modelConfigData?.[currentSelectedStage - 1]
                      ?.display_names?.[parameter]?.[res] ??
                    ingredientsLables?.[res]?.name ??
                    processingLables?.[res]?.name ??
                    propertiesLables?.[res]?.name ??
                    res,
                })),
          }
        } else {
          return null
        }
      })
      .filter((res: any) => res)
  }, [
    inputType,
    suggestedExpFilters,
    t,
    experiments,
    displayNames,
    collator,
    ingredientsLables,
    processingLables,
    propertiesLables,
    modelConfigData,
    currentSelectedStage,
  ])

  useEffect(() => {
    form.resetFields()
  }, [selectedObjective, form])

  const updateSelectedExperiments = (e: any, selectedId: string) => {
    e.preventDefault()
    setCheckedExperiments((prev) => {
      const filteredList = prev.experimentIdList.filter(
        (res) => res.experiment_id !== selectedId,
      )
      const pageExpCountMap: { [key: string]: number } = {}

      filteredList.forEach((res) => {
        if (pageExpCountMap[res.page]) {
          pageExpCountMap[res.page]++
        }
        else {
          pageExpCountMap[res.page] = 1
        }
      })
      const checkAllPages = Object.keys(pageExpCountMap).filter((page: any) => pageExpCountMap[page] === 10).map((page: any) => parseInt(page))

      return {
        checkAllPages: checkAllPages,
        experimentIdList: filteredList,
      }
    })
  }

  const clearAllSelectedExperiment = () => {
    setCheckedExperiments({
      checkAllPages: [],
      experimentIdList: [],
    })
  }

  const exportDataSheet = () => {
    dispatch(
      exportPredictionRequest({
        prediction_id: experiments?.[0]?.prediction_id,
        ...(!!checkedExperiments?.experimentIdList?.length && {
          suggested_experiment_ids: checkedExperiments.experimentIdList?.map((res: any) => res.experiment_id),
        }),
        type: "inverse",
        filters: filters.expFilters,
        sorts: filters.sorts,
        version: filtersVersion?.version,
        ...(isMultistage ? { stage: experiments?.[0]?.all_stages?.[currentSelectedStage - 1] ?? `Stage ${currentSelectedStage}` } : {}),
        include_range: exportDataSheetModalData.include_range
      }),
    )
  }

  const tableColumnsVis = useMemo(() => {
    const exp = dataRelatedToRecipeDistribution?.filter(
      ({ experiment_id }: any) => selectedRecipeIds.includes(experiment_id),
    )
    return newColumnData(
      exp,
      checkChange,
      displayNames,
      selectedRecipeIds,
      "recipe-suggested",
      selectAll,
      t,
      Boolean(configs?.ai_engine_with_methods)
        ? zeonCurrentPageInfo?.currentPage
        : experimentsCurrent,
      Boolean(configs?.ai_engine_with_methods),
    )
  }, [
    displayNames,
    selectAll,
    t,
    configs,
    checkChange,
    experimentsCurrent,
    zeonCurrentPageInfo?.currentPage,
    selectedRecipeIds,
    dataRelatedToRecipeDistribution,
  ])

  const filteredDataVis = useMemo(() => {
    const exp = dataRelatedToRecipeDistribution?.filter(
      ({ experiment_id }: any) => selectedRecipeIds.includes(experiment_id),
    )
    const newData: any = transposeData(
      exp,
      t,
      getValue,
      zeonCategorySocketList,
      false,
      Boolean(configs?.nestle_configs),
    )
    const removedNulls = removeNulls(newData, "suggested_exp", convertValue)
    return removedNulls
  }, [
    dataRelatedToRecipeDistribution,
    configs,
    zeonCategorySocketList,
    selectedRecipeIds,
    t,
    getValue,
    convertValue
  ])

  useEffect(() => {
    if (tab === "formulation_prediction") {
      const linkedFormulations = experiments?.flatMap((exp: any) =>
        Object.entries(exp.ingredients || {})
          .filter(([key, values]: [any, any]) => values?.type === "trials")
          .map(([key, values]: [any, any]) => key),
      )
      const uniqueLinkedFormulationIds = [...new Set(linkedFormulations)]
      if (!!uniqueLinkedFormulationIds.length) {
        dispatch(
          linkedCompareFormulationsRequest({
            formulation_id: uniqueLinkedFormulationIds,
            is_nested: true,
          }),
        )
      }
    }
  }, [experiments, dispatch, tab])

  const getInclusionMode = useCallback(
    (include, exclude) => {
      switch (true) {
        case exclude === false && include === true:
          return t("common.mustInclude")
        case exclude === true && include === false:
          return t("common.exclude")

        default:
          return t("common.canInclude")
      }
    },
    [t],
  )

  const objectiveConstraints = useCallback(() => {
    const columns: TableColumnsType<any> = [
      { title: t('common.objectives'), dataIndex: "objective", key: "objective" },
      // {
      //   title: "Historical Experiment Range",
      //   dataIndex: "range",
      //   key: "range",
      // },
      {
        title: t("aiEngine.optimizationType"),
        key: "optimization_type",
        dataIndex: "optimization_type",
      },
      { title: t("common.min"), dataIndex: "min", key: "min" },
      { title: t("common.max"), dataIndex: "max", key: "max" },
      {
        title: t("common.priority"),
        dataIndex: "priority",
        key: "priority",
      },
    ]

    const data = []
    const objectives = suggestedExpFilters?.objectives ?? {}
    const priority = suggestedExpFilters?.priority_list ?? {}

    for (let obj of Object.keys(objectives)) {
      const actualObjective = objectives?.[obj]
      data.push({
        key: obj,
        objective:
          displayNames?.properties?.[obj]?.name ||
          obj
            .split("_")
            .map((value: string) => value[0].toUpperCase() + value.slice(1))
            .join(" "),
        stage: objectives[obj]?.stage,
        range: objectives[obj]?.range,
        optimization_type:
          typeof actualObjective === "object" &&
            !Array.isArray(actualObjective) &&
            actualObjective !== null
            ? t("common.range")
            : `${actualObjective[0].toUpperCase()}${actualObjective.slice(1)}`,
        min: getValue(objectives[obj]?.min),
        max: getValue(objectives[obj]?.max),
        priority: priority[obj],
      })
    }
    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className="constraints-table"
        scroll={{ y: 300 }}
      />
    )
  }, [displayNames?.properties, suggestedExpFilters?.objectives, suggestedExpFilters?.priority_list, t, getValue])

  const parameterConstraints = useCallback((category) => {

    const paramsData = Object.entries(
      suggestedExpFilters?.parameter_constraints || {},
    )
      .filter(([key, value]: any) => value?.category === category)
      .sort((a, b) => collator.compare(a[0], b[0]))

    const paramsNumericalData = paramsData?.filter(([key, value]: any) => !Array.isArray(value?.range) && !Array.isArray(value?.min))
    const paramsCategoricalData = paramsData?.filter(([key, value]: any) => Array.isArray(value?.range) || Array.isArray(value?.min))

    const parameterConstraintsNumericalColumns: TableColumnsType<any> = [
      { title: t("common.parameters"), dataIndex: "parameter", key: "parameter" },
      {
        title: t("common.range"), dataIndex: "range", key: "range",
      },
      {
        title: t("aiEngine.inclusionMode"),
        dataIndex: "inclusion_mode",
        key: "inclusion_mode",
      },
      {
        title: `${t("common.min")}`, dataIndex: "min", key: "min",
      },
      {
        title: t("common.max"), dataIndex: "max", key: "max",
      },
    ]

    const parameterConstraintsNumericalData = paramsNumericalData
      .map(([key, value]: any) => {
        const min = Array.isArray(value?.min) ? value?.min : getValue(value?.min) ?? "-"
        const max = Array.isArray(value?.max) ? value?.max : getValue(value?.max) ?? "-"
        const range = Array.isArray(value?.range) ? value?.range : `${getValue(value?.range?.min)} - ${getValue(value?.range?.max)}` ??
          "-"
        return {
          key,
          parameter: value?.parameter ?? "-",
          inclusion_mode: getInclusionMode(value?.include, value?.exclude),
          min,
          max,
          item_constraints: value?.item_constraints,
          range,
        }
      })

    const parameterConstraintsCategoricalColumns: TableColumnsType<any> = [
      { title: t("common.parameters"), dataIndex: "parameter", key: "parameter" },
      {
        title: t("common.variations"), dataIndex: "range", key: "range",
        render: (value: any) => <Space style={{ width: "100%" }}>
          {(
            value || []
          ).map((val: string, index: number) => {
            return (
              <Tag key={index} color="blue" style={{ cursor: "pointer" }}>
                {val}
              </Tag>
            )
          })}
        </Space>
      },
      {
        title: `${t("common.value")}`, dataIndex: "min", key: "min",
        render: (value: any) => <Space style={{ width: "100%" }}>
          {(
            value || []
          ).map((val: string, index: number) => {
            return (
              <Tag key={index} color="blue" style={{ cursor: "pointer" }}>
                {val}
              </Tag>
            )
          })}
        </Space>
      },
    ]

    const parameterConstraintsCategoricalData = paramsCategoricalData
      .map(([key, value]: any) => {
        const min = Array.isArray(value?.min) ? value?.min : getValue(value?.min) ?? "-"
        const range = Array.isArray(value?.range) ? value?.range : `${getValue(value?.range?.min)} - ${getValue(value?.range?.max)}` ??
          "-"
        return {
          key,
          parameter: value?.parameter ?? "-",
          range,
          min,
        }
      })



    return (
      <Space direction="vertical" style={{ width: "100%", padding: 12 }}>
        {!!paramsNumericalData?.length && <Table
          columns={parameterConstraintsNumericalColumns}
          dataSource={parameterConstraintsNumericalData}
          pagination={false}
          className="constraints-table"
          title={
            () => <Typography.Text strong>{t('common.numericalParameters')}</Typography.Text>
          }
          scroll={{ y: 300 }}
        />}
        {!!paramsCategoricalData?.length && <Table
          columns={parameterConstraintsCategoricalColumns}
          dataSource={parameterConstraintsCategoricalData}
          pagination={false}
          title={
            () => <Typography.Text strong>{t('common.categoricalParameters')}</Typography.Text>
          }
          className="constraints-table"
          scroll={{ y: 300 }}
        />}
      </Space>
    )
  }, [collator, getInclusionMode, getValue, suggestedExpFilters?.parameter_constraints, t])


  const categoryConstraints = useCallback(() => {
    const categoryConstraintsColumns: TableColumnsType<any> = [
      { title: t("common.type"), dataIndex: "type", key: "type" },
      { title: t("common.category"), dataIndex: "category", key: "category" },
      {
        title: t("aiEngine.inclusionMode"),
        dataIndex: "inclusion_mode",
        key: "inclusion_mode",
      },
      {
        title: t("common.minQuantity"),
        dataIndex: "min_quantity",
        key: "min_quantity",
      },
      {
        title: t("common.maxQuantity"),
        dataIndex: "max_quantity",
        key: "max_quantity",
      },
      { title: t("common.minCount"), dataIndex: "min_count", key: "min_count" },
      { title: t("common.maxCount"), dataIndex: "max_count", key: "max_count" },
    ]

    const getType = (type: string) => {
      if (type === "ingredients") {
        return t("common.ingredients")
      }

      if (type === "processing") {
        return t("common.processing")
      }

      return type || "-"
    }

    const categoryConstraintsData = Object.entries(
      suggestedExpFilters?.category_wise_constraints || {},
    )
      .sort((a, b) => collator.compare(a[0], b[0]))
      .map(([key, value]: any) => {
        return {
          key,
          type: getType(value?.type),
          category: key,
          inclusion_mode: getInclusionMode(value?.include, value?.exclude),
          min_quantity: getValue(value?.sum_of_category_quantity?.min ?? "-"),
          max_quantity: getValue(value?.sum_of_category_quantity?.max ?? "-"),
          min_count: getValue(value?.count_of_category_items?.min) ?? "-",
          max_count: getValue(value?.count_of_category_items?.max) ?? "-",
        }
      })

    return (
      <Table
        columns={categoryConstraintsColumns}
        dataSource={categoryConstraintsData}
        pagination={false}
        className="constraints-table"
        scroll={{ y: 300 }}
        expandable={{
          expandedRowRender: (record) => {
            return parameterConstraints(record.category)
          },
          rowExpandable: (record) => {
            return Object.values(
              suggestedExpFilters?.parameter_constraints || {},
            )?.filter(({ category }: any) => category === record.category)?.length > 0
          },
        }}
      />
    )
  }, [t, suggestedExpFilters?.category_wise_constraints, suggestedExpFilters?.parameter_constraints, collator, getInclusionMode, getValue, parameterConstraints])


  const baseInputConstraints = useCallback(() => {
    const columns = [
      {
        key: "input",
        title: t("common.inputs"),
        dataIndex: "input",
      },
      {
        key: "range",
        title: t("common.range"),
        dataIndex: "range",
      },
    ]

    const data = Object.entries(suggestedExpFilters?.input_constraints ?? {})
      .sort((a, b) => collator.compare(a[0], b[0]))
      .map(([key, data]: [string, any]) => {
        const values = data?.value
        return {
          input:
            suggestedExpFilters?.input_constraints?.[key]?.linked_trial_name || suggestedExpFilters?.ingredients?.[key]?.name ||
            displayNames?.ingredients?.[key]?.name ||
            displayNames?.processing?.[key]?.name ||
            key,
          range: Array.isArray(values)
            ? `(${values.reduce(
              (string: string, element: string) => element + ", " + string,
            )})`
            : `(${getValue(values?.min)} - ${getValue(values?.max)})`,
        }
      })

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: 300 }}
        className="constraints-table"
      />
    )
  }, [
    collator,
    displayNames?.ingredients,
    displayNames?.processing,
    suggestedExpFilters?.ingredients,
    suggestedExpFilters?.input_constraints,
    t,
    getValue,
  ])

  const crossCategoricalConstraints = useCallback(() => {
    return (
      <Space
        direction="vertical"
        style={{ width: "100%", gap: 20, padding: 12 }}
      >
        <Space style={{ width: "100%" }}>
          <Text strong>{t("common.selectedCategory")}</Text>
          <div>:</div>
          <Space style={{ width: "100%" }}>
            {(
              suggestedExpFilters?.cross_category_constraints?.categories || []
            ).map((category: string, index: number) => {
              return (
                <Tag key={index} color="blue" style={{ cursor: "pointer" }}>
                  {category}
                </Tag>
              )
            })}
          </Space>
        </Space>

        <Space>
          <Text strong>{t("common.minQuantity")}</Text>
          <div>:</div>
          <Text strong>
            {getValue(suggestedExpFilters?.cross_category_constraints?.sum?.min) ?? "-"}
          </Text>
        </Space>

        <Space>
          <Text strong>{t("common.maxQuantity")}</Text>
          <div>:</div>
          <Text strong>
            {getValue(suggestedExpFilters?.cross_category_constraints?.sum?.max) ?? "-"}
          </Text>
        </Space>
      </Space>
    )
  }, [
    suggestedExpFilters?.cross_category_constraints?.categories,
    suggestedExpFilters?.cross_category_constraints?.sum?.max,
    suggestedExpFilters?.cross_category_constraints?.sum?.min,
    t,
    getValue
  ])

  const inverseConfigs = useCallback(() => {
    const inverseConfigData = experiments?.[0]?.custom_inverse_configs || {}
    const columns = [
      {
        title: t("common.parameters"),
        dataIndex: "parameter",
        key: "parameter",
      },
      {
        title: t("common.values"),
        dataIndex: "value",
        key: "value",
      },
    ]

    const data: any[] = Object.keys(inverseConfigData || {}).map((property: any, index: any) => {
      return {
        key: property,
        parameter: inverseConfigData[property]?.name,
        value: getValue(inverseConfigData[property]?.value),
      }
    })

    return <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{ y: 300 }}
      className="constraints-table"
    />
  }, [t, experiments, getValue])


  const stagesOptions = useMemo(() => {
    const options = suggestedExpFilters?.all_stages
    const segmentOptions = options?.map((res: string, index: number) => {
      const stage_number = index + 1
      // if (index === 0 && stage_number !== 1) setCurrentSelectedStage(stage_number)
      return ({
        value: stage_number,
        label: res,
        title: res,
        disabled: false,
      })
    })
    return segmentOptions
  }, [suggestedExpFilters?.all_stages])

  const [openBom, setOpenBom] = useState<boolean>(false)

  const isCompleted = useMemo(() => {
    return suggestedExpFilters?.status?.toLowerCase() === "completed"
  }, [suggestedExpFilters])

  const constraintsItems: any[] = useMemo(() => {
    // const options = suggestedExpFilters?.all_stages
    return [
      {
        key: "category-constraints",
        label: `${t("common.constraints")}`,
        children: categoryConstraints(),
      },
      {
        key: "base-input-constraints",
        label: t("common.baseInputConstraints"),
        children: baseInputConstraints(),
      },
      {
        key: "cross-categorical-constraints",
        label: t("aiEngine.crossCategorical"),
        children: crossCategoricalConstraints(),
      },
      ...(!!Object.keys(experiments?.[0]?.custom_inverse_configs ?? {})?.length ? [{
        key: "inverse-config",
        label: t("aiEngine.configurations"),
        children: inverseConfigs(),
      }] : []),
    ]
  }, [categoryConstraints, baseInputConstraints, crossCategoricalConstraints, t, inverseConfigs, experiments])

  const objectivesAndOtherItems = useMemo(() => {
    // const options = suggestedExpFilters?.all_stages
    return [
      {
        key: "objectives",
        label: t("common.objectives"),
        children: objectiveConstraints(),
      }
    ]
  }, [objectiveConstraints, t])

  const { linkedFormulationData } = useSelector(
    (state: StoreState) => state.common,
  )

  return (
    <Space
      size="middle"
      direction="vertical"
      style={{ width: "100%" }}
      id="inversepred-results"
    >
      {/* Top action */}
      {isCompleted && (
        <TopAction
          generateWoMenuItems={generateWoMenuItems}
          predictionDataExportStatus={predictionDataExportStatus}
          setExportDataSheetModalData={setExportDataSheetModalData}
        />
      )}

      {
        exportDataSheetModalData.isModalVisible &&
        <ExportDataSheetModal exportDataSheetModalData={exportDataSheetModalData} setExportDataSheetModalData={setExportDataSheetModalData} loadingState={predictionDataExportStatus} onSubmit={exportDataSheet} from="inverse-prediction" />
      }

      {/* Model detials */}
      <ModelDetails data={suggestedExpFilters} />

      {/* Objectives and other */}
      <InputConstraintsPanel
        items={objectivesAndOtherItems}
        title={`${t("aiEngine.objectives")} ${Object.keys(suggestedExpFilters?.objectives || {}).length ? `(${Object.keys(suggestedExpFilters?.objectives || {}).length})` : ``}`}
        defaultOpen={true}
      />

      {/* Variation details */}
      {variationDetail && (
        <ShowVariationDetail variationDetail={variationDetail} />
      )}

      {/* Note about '-' */}
      {isCompleted && (
        <Note
          content={
            <>
              {t("predictedValue.note")}
              <a
                href="mailto:contact@polymerize.io"
                style={{ color: "#1677ff", display: "inline" }}
              >
                {"contact@polymerize.io"}
              </a>
            </>
          }
          icon={<InfoCircleOutlined />}
        />
      )}

      {/* Dropped properties */}
      {filteredData?.length > 0 && !!inverseMissingProperties?.length && (
        <DroppedPropertyWarning
          missingProperties={inverseMissingProperties}
        />
      )}

      {/* Stage navigator */}
      {isMultistage && (
        <Segmented
          options={stagesOptions}
          value={currentSelectedStage}
          style={{ transition: "all .5s" }}
          size="middle"
          onChange={(stage: any) => {
            form.resetFields()
            pageChange(1, "experiments", {
              ...(isMultistage ? { stage: suggestedExpFilters?.all_stages?.[stage - 1] ?? `Stage ${stage}` } : {}),
            })
            setCurrentSelectedStage(stage)
          }}
          className="inverse-stage-segmented"
        />
      )}

      {/* Input Costraints */}
      <Spin spinning={expIdStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
        <InputConstraintsPanel
          items={constraintsItems}
          title={`${t("aiEngine.inputConstraints")}`}
        />
      </Spin>

      {isCompleted && (
        <>
          {/* Checked exp listing */}
          <Drawer
            open={checkedExperiments.experimentIdList.length > 0}
            placement="bottom"
            style={{
              background: "#262626",
            }}
            height={125}
            mask={false}
            maskClosable={false}
            closeIcon={false}
            title={
              <>
                <Space direction="vertical" size={"large"} style={{
                  width: '100%',
                }} >
                  <Typography.Title
                    level={5}
                    style={{ margin: 0, color: "#fff" }}
                  >{`${checkedExperiments.experimentIdList.length} ${t(
                    "common.trials",
                  )} ${t("formulations.selected")}`}</Typography.Title>
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto",
                    }}
                  >
                    {checkedExperiments.experimentIdList.map(
                      ({ experiment_id: experimentId, idx, page }) => {
                        return (
                          <Tag
                            closable
                            onClose={(e) =>
                              updateSelectedExperiments(e, experimentId)
                            }
                            style={{
                              background: "#262626",
                              borderColor: "#fff",
                              color: "#fff",
                              lineHeight: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: 'max-content',
                              width: 'max-content',
                            }}
                            closeIcon={
                              <CloseOutlined
                                style={{ fontSize: antdTheme.fontSize, color: "#fff" }}
                              />
                            }
                            key={`${experimentId}-${idx}-${page}`}
                          >
                            <Typography.Title
                              level={5}
                              style={{ color: "#fff" }}
                            >
                              {`${t('common.trial')} ${(page - 1) * 10 + idx + 1}`}
                            </Typography.Title>
                          </Tag>
                        )
                      },
                    )}
                  </div>
                </Space>
                <div style={{ position: "absolute", top: "-12%", left: "50%" }}>
                  <StyledButton
                    onClick={() => clearAllSelectedExperiment()}
                    style={{
                      background: "#262626",
                      color: "#fff",
                      borderColor: "#fff",
                    }}
                  >
                    <Space>
                      <CloseOutlined />
                      {t("common.clear")}
                    </Space>
                  </StyledButton>
                </div>
              </>
            }
            styles={{
              body: {
                display: "none",
              },
              header: {
                padding: 24,
                height: '100%',
              },
            }}
          />

          {/* Suggested Experiment Table */}
          <Table
            ref={tableRef}
            dataSource={filteredData}
            bordered={false}
            columns={tableColumns}
            className="suggested-exp-result-table"
            scroll={{ x: 400, y: 800 }}
            title={() => (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row justify="space-between">
                  <Typography.Title level={5}>
                    {`${total} ${t("common.trials")}`}
                  </Typography.Title>
                  <Row gutter={8}>
                    <Col>
                      <StyledBadge
                        dot={
                          filters?.expFilters?.length > 0 ||
                          filters?.sorts?.length > 0
                        }
                        style={{ marginRight: 5 }}
                      >
                        <StyledButton
                          onClick={() =>
                            setIsFilterSortingVisible((prevState) => !prevState)
                          }
                          className={
                            isFilterSortingVisible ? "action-button-active" : ""
                          }
                          style={{ marginRight: 5 }}
                        >
                          {t("aiEngine.filters.title")}
                        </StyledButton>
                      </StyledBadge>
                    </Col>
                    {isMultistage && (
                      <Col>
                        <StyledButton onClick={() => setOpenBom(true)}>
                          {t("common.viewBuildOfMaterials")}
                        </StyledButton>
                      </Col>
                    )}
                  </Row>
                </Row>

                <Alert
                  description={
                    <FilterAndSorting
                      form={form}
                      applyFilters={applyFilters}
                      filterData={filterData}
                      operatorStates={operatorStates}
                      setOperatorStates={setOperatorStates}
                      filters={filters}
                      setFilters={setFilters}
                      pageChange={pageChange}
                      isMultistage={isMultistage}
                      currentSelectedStage={currentSelectedStage}
                      suggestedExpFilters={suggestedExpFilters}
                    />
                  }
                  type="info"
                  message={
                    <Row justify={"space-between"}>
                      <Typography.Title
                        level={5}
                      >
                        {t("aiEngine.filters.title")}
                      </Typography.Title>
                      <CloseOutlined
                        onClick={() => setIsFilterSortingVisible(false)}
                        style={{ outline: "none" }}
                      />
                    </Row>
                  }
                  style={{
                    border: "none",
                    padding: "16px",
                    backgroundColor: "#FAFAFA",
                    display: isFilterSortingVisible ? "flex" : "none",
                  }}
                />
              </Space>
            )}
            pagination={{
              pageSize: filteredData?.length,
              current: Boolean(configs?.ai_engine_with_methods)
                ? zeonCurrentPageInfo?.currentPage
                : experimentsCurrent,
              showSizeChanger: false,
              responsive: true,
              total: Math.ceil((filteredData?.length * total) / 10),
              onChange: (e) => {
                pageChange(e, "experiments", {
                  ...(isMultistage
                    ? {
                      stage: experiments?.[0]?.all_stages?.[currentSelectedStage - 1] ?? suggestedExpFilters?.all_stages?.[currentSelectedStage - 1]
                    }
                    : {}),
                  ...filters,
                })
                customScrollIntoView(tableRef, { behavior: "smooth" })
              },
              position: ["topRight"],
            }}
            loading={{
              spinning: expIdStatus === AsyncStates.LOADING,
              indicator: <LoadingOutlined />,
            }}
            expandable={
              isMultistage
                ? {
                  rowExpandable: (record) => false,
                }
                : {
                  expandedRowRender: (record) => {
                    return (
                      <LinkedTrialsAiEngine
                        record={record}
                        experiments={experiments}
                        linkedFormulationDetailsData={linkedFormulationData}
                        from={"inverse"}
                        pageNumber={experimentsCurrent}
                      />
                    )
                  },
                  rowExpandable: (record) => {
                    return !!record?.linked_trial
                  },
                  onExpand: (expanded, record) => {
                    if (
                      expanded &&
                      !!record?.linked_trial &&
                      !linkedFormulationData?.[record?.linked_trial]
                    ) {
                      const payload = {
                        formulation_id: [record?.linked_trial],
                        is_nested: true,
                      }
                      dispatch(linkedFormulationsAiEngineRequest(payload))
                    }
                  },
                }
            }
          />

          {/* Costing Panel */}
          {!!experiments?.length && Boolean(configs?.work_order_costing) && (
            <Collapse>
              <Collapse.Panel
                header={<Text strong>{"Costing"}</Text>}
                key={"1"}
              >
                <AiCosting experiments={experiments} />
              </Collapse.Panel>
            </Collapse>
          )}

          {!isMultistage && (
            <>
              {/* Suggested Experiment Visualization */}
              <SuggestedExpVisualization
                setClickedExperiments={setClickedExperiments}
                clickedExperiments={clickedExperiments}
                filtersVersion={filtersVersion}
                generateWO={generateWO}
                clearExperiments={clearExperiments}
                checkedSelectedExp={checkedSelectedExp}
                checkChange={checkChange}
                selectAll={selectAll}
                zeonCurrentPageInfo={zeonCurrentPageInfo}
                experimentsCurrent={experimentsCurrent}
                suggestedExpFilters={suggestedExpFilters}
                selectedObjective={selectedObjective}
                zeonCategoryList={zeonCategoryList}
              />

              {/* Recipe Distribution */}
              <RecipeDistribution
                recipeDistribution="inverse-prediction"
                tableColumnsVis={tableColumnsVis}
                filteredDataVis={filteredDataVis}
                generateWO={generateWO}
                selectedRecipeIds={selectedRecipeIds}
                setselectedRecipeIds={setselectedRecipeIds}
                experiments={dataRelatedToRecipeDistribution}
                expStatus={expIdStatus}
                status={recipeStatus === AsyncStates.LOADING}
              />
            </>
          )}

          {/* Workorder Modal */}
          <WorkOrderModal
            workOrderVisible={workOrderVisible}
            setWorkOrderVisible={setWorkOrderVisible}
            checkedExperiments={expType}
            experiments={experiments}
            type={"inverse"}
            isSelectAll={isSelectAll}
          />

          {/* Build of Materials */}
          {isMultistage && (
            <BuildOfMaterials
              openBom={openBom}
              setOpenBom={setOpenBom}
              stagesOptions={stagesOptions}
              source="suggested_exp"
            />
          )}

          {/* Spacing equal to Exp Listing drawer so we can see content properly */}
          <div
            style={{
              paddingBottom:
                checkedExperiments.experimentIdList.length > 0 ? 125 : 10,
            }}
          ></div>
        </>
      )}
    </Space>
  )
}