import "./Common.scss";
import { StyledButton } from "src/styled_components/StyledButton";
import { PlusOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
import { Empty } from "antd";
import TabHeader from "./TabHeader";

type TProps = {
    title: string;
    btns: string[];
    addItem: React.Dispatch<React.SetStateAction<boolean>>;
};

const NoData = ({ title, btns, addItem }: TProps) => {
    const [t] = useTranslate();

    return (
        <div className="no-data">
            <TabHeader title={title} addItem={addItem} />
            <div className="content">
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t("common.noData")}
                />
                <div className="cta-container">
                    <StyledButton
                        type="primary"
                        style={{ borderRadius: "0" }}
                        size="middle"
                        icon={<PlusOutlined />}
                        onClick={() => addItem(true)}
                    >
                        {btns[0]}
                    </StyledButton>
                    {/* <StyledButton
                        type="default"
                        style={{ borderRadius: "0" }}
                        size="middle"
                        icon={<PlusOutlined />}
                    >
                        {btns[1]}
                    </StyledButton> */}
                </div>
            </div>
        </div>
    );
};

export default NoData;
