import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	settingsRequest,
	settingsSuccess,
	settingsFailure,
	settingsUpdateRequest,
	settingsUpdateSuccess,
	settingsUpdateFailure,
	billingDetailsRequest,
	billingDetailsSuccess,
	billingDetailsFailure,
	updatePaymentMethodSuccess,
	updatePaymentMethodFailure,
	updatePaymentMethodRequest,
	updateBillingDetailsRequest,
	updateBillingDetailsSuccess,
	updateBillingDetailsFailure,
	updateSubscriptionRequest,
	updateSubscriptionSuccess,
	updateSubscriptionFailure,
	fetchLabsLocationRequest,
	fetchLabsLocationSuccess,
	fetchLabsLocationFailure,
	editLabsLocationRequest,
	addLabsLocationRequest,
	editLabsLocationFailure,
	editLabsLocationSuccess,
	addLabsLocationSuccess,
	addLabsLocationFailure,
	updateNumericalCodeRequest, updateNumericalCodeSuccess, updateNumericalCodeFailure
} from "../actions/settings"
import { SettingsApi, SettingsUpdateApi, addLabsLocationApi, editLabsLocationApi, fetchLabsLocationApi, getBillingDetailsApi, updateBillingDetailsApi, updatePaymentMethodApi, updateSubscriptionApi, updateNumericalCodeApi } from "../../services/settings"
import { messages } from "src/utils/hooks"
import { message, notification } from "antd"
import { displayNamesRequest } from "../actions/displayNames"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { updateLoginResponse } from "../actions/login"
import { APIError } from "src/typings"
import { updateProjectData } from "../actions/projects"
import { configsUpdate } from "../actions/configs"

type updateSettingsAction = {
	type: string
	payload: {
		stages: [
			{
				identifier: string
				name: string
			}
		]
	}
}

const alertMessage = (message: string) => {
	return {
		content: message,
		style: {
			marginTop: "5vh",
		},
		className: "ant-message",
		duration: 2,
	}
}

function* settingsSaga(): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(SettingsApi, {}, headers)
		if (result.status === "Success") {
			yield put(settingsSuccess(result))
		} else {
			yield put(settingsFailure(result.message))
			message.error(alertMessage(result.message))
		}
	} catch (error) {
		yield put(settingsFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* settingsUpdateSaga({
	payload,
}: updateSettingsAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(SettingsUpdateApi, payload, headers)
		if (result.status === "Success") {
			yield put(settingsUpdateSuccess(result.message))
			message.success(alertMessage(result.status))
			yield put(displayNamesRequest())
		} else {
			yield put(settingsUpdateFailure(result.message))
			message.error(alertMessage(result.message))
		}
	} catch (error) {
		yield put(settingsUpdateFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* getBillingDetailsSaga(): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(getBillingDetailsApi, {}, headers)
		if (status === "Success") {
			yield put(billingDetailsSuccess(data))
		} else {
			yield put(billingDetailsFailure(message))
		}
	} catch (error) {
		yield put(billingDetailsFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* updatePaymentMethodSaga(): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(updatePaymentMethodApi, {}, headers)
		if (status === "Success") {
			yield put(updatePaymentMethodSuccess(data))
			window.location.href = data?.checkout_url
		} else {
			yield put(updatePaymentMethodFailure(message))
		}
	} catch (error) {
		yield put(updatePaymentMethodFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* updateBillingDetailsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(updateBillingDetailsApi, payload, headers)
		if (status === "Success") {
			yield put(updateBillingDetailsSuccess(data))
			yield put(billingDetailsRequest())
		} else {
			yield put(updateBillingDetailsFailure(message))
		}
	} catch (error) {
		yield put(updateBillingDetailsFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}


function* updateSubscriptionSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders, login: { loginResponse } } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(updateSubscriptionApi, payload, headers)
		if (status === "Success") {
			yield put(updateSubscriptionSuccess(data))
			if (Object.keys(data || {}).length) {
				yield put(updateLoginResponse({ ...loginResponse, ...data }))
			}
			yield put(billingDetailsRequest())
		} else {
			yield put(updateSubscriptionFailure(message))
		}
	} catch (error) {
		yield put(updateSubscriptionFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* fetchLabsLocationSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message, status, data },
			},
		} = yield call(fetchLabsLocationApi, payload, headers)
		if (status === "Success") {
			yield put(fetchLabsLocationSuccess(data))
		} else {
			yield put(fetchLabsLocationFailure(message))
		}
	} catch (error) {
		yield put(fetchLabsLocationFailure(messages[ln].internal_server_error))
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}

function* editLabsLocationSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message: apiMessage, status, data },
			},
		} = yield call(editLabsLocationApi(payload?.lab_location_id), payload, headers)
		if (status === "Success") {
			yield put(editLabsLocationSuccess(data))
			notification.success({
				message: apiMessage
			})
		} else {
			yield put(editLabsLocationFailure(message))
			message.error(apiMessage)
		}
	} catch (err) {
		if (err instanceof Error) {
			const error = err as unknown as APIError
			yield put(editLabsLocationFailure(error?.response?.data?.detail ?? messages[ln].internal_server_error))
			message.error(error?.response?.data?.detail ?? messages[ln].internal_server_error)
		} else {
			const error = err as APIError
			if (error.response.status === 500) {
				yield put(editLabsLocationFailure(messages[ln].internal_server_error))
				message.error(messages[ln].internal_server_error)
			} else {
				yield put(editLabsLocationFailure(error.response.data.detail ?? messages[ln].internal_server_error))
				message.error(error?.response?.data?.detail ?? messages[ln].internal_server_error)
			}
		}
	}
}

function* addLabsLocationSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { message: apiMessage, status, data },
			},
		} = yield call(addLabsLocationApi, payload, headers)
		if (status === "Success") {
			if (!!payload?.project_id?.length) {
				const projectList = yield select((state) => state.projects.projectList)
				projectList.forEach((project: any, index: number) => {
					if (project.project_id === payload?.project_id) {
						projectList[index] = {
							...project,
							lab_locations: [...(project?.lab_locations ?? []), data]
						}
					}
				})
				yield put(updateProjectData([...projectList]))
			}
			yield put(addLabsLocationSuccess(data))
			notification.success({
				message: apiMessage
			})
		} else {
			yield put(addLabsLocationFailure(message))
			message.error(apiMessage)
		}
	} catch (err) {
		if (err instanceof Error) {
			const error = err as unknown as APIError
			yield put(addLabsLocationFailure(error?.response?.data?.detail ?? messages[ln].internal_server_error))
			message.error(error?.response?.data?.detail ?? messages[ln].internal_server_error)
		} else {
			const error = err as APIError
			if (error.response.status === 500) {
				yield put(addLabsLocationFailure(messages[ln].internal_server_error))
				message.error(messages[ln].internal_server_error)
			} else {
				yield put(addLabsLocationFailure(error.response.data.detail ?? messages[ln].internal_server_error))
				message.error(error?.response?.data?.detail ?? messages[ln].internal_server_error)
			}
		}
	}
}


function* updateNumericalCodeSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, data },
			},
		} = yield call(updateNumericalCodeApi, payload, headers)
		if (status === "Success") {
			yield put(configsUpdate(data))
			yield put(updateNumericalCodeSuccess())

		} else {
			yield put(updateNumericalCodeFailure())
		}
	} catch (error) {
		yield put(updateNumericalCodeFailure())
		message.error(alertMessage(messages[ln].internal_server_error))
	}
}


export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(settingsRequest, settingsSaga)
	yield takeLatest(settingsUpdateRequest, settingsUpdateSaga)
	yield takeLatest(billingDetailsRequest, getBillingDetailsSaga)
	yield takeLatest(updatePaymentMethodRequest, updatePaymentMethodSaga)
	yield takeLatest(updateBillingDetailsRequest, updateBillingDetailsSaga)
	yield takeLatest(updateSubscriptionRequest, updateSubscriptionSaga)
	yield takeLatest(fetchLabsLocationRequest, fetchLabsLocationSaga)
	yield takeLatest(editLabsLocationRequest, editLabsLocationSaga)
	yield takeLatest(addLabsLocationRequest, addLabsLocationSaga)
	yield takeLatest(updateNumericalCodeRequest, updateNumericalCodeSaga)
}
