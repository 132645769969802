import { LoadingOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { Modal, Row, Select, Space, Spin, TimePicker, Typography } from 'antd'
import { CellProps, DynamicDataSheetGrid, keyColumn, textColumn } from 'react-datasheet-grid'
import { StyledButton } from 'src/styled_components/StyledButton'
import { convertToPrecision } from 'src/utils/decorator'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts"
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
import dayjs from 'dayjs'
import { setEditingState } from 'src/store/actions/workOrderDetails'

const { Option } = Select
const { Text } = Typography

export const PropertiesModal = ({ visible, setVisible, metaData, setMetaData, saveCharacterizationsData, setData, edit, currentTrial, setCurrentTrial, metaTitle }: any) => {
    const dispatch = useDispatch()
    const saveCharacterizationsStatus = useSelector((state: StoreState) => state.characterizationMethods.saveCharacterizationsStatus)
    const editingState = useSelector((state: StoreState) => state.workOrderDetails.editingState)
    const experiments = useSelector((state: StoreState) => state.workOrderDetails.experiment)
    const methodList = useSelector((state: StoreState) => state.characterizationMethods.methodList)

    const columns: any = [
        {
            title: "",
            width: "0 0 5px",
            component: (row: CellProps<any>) => (
                edit ?
                    <MinusCircleOutlined
                        onClick={() => {
                            setMetaData((prevState: any) => prevState.filter((res: any) => res?.index !== row?.rowData?.index))

                        }}
                        style={{ margin: "0 auto" }} /> : ""
            ),
        },
        {
            ...keyColumn("mass", textColumn),
            title: "Mass (g)",
            disabled: !edit,
        },
        {
            title: "Time (hr:mm:ss)",
            component: (row: any) => {
                return (
                    <TimePicker disabled={!edit} value={!!metaData?.[row?.rowData?.index]?.time ? dayjs(metaData?.[row?.rowData?.index]?.time) : null}
                        style={{ width: "100%" }} onChange={(e) => {
                            if (!!e) {
                                row.setRowData({ ...row.rowData, time: e.toISOString() })
                            } else {
                                row.setRowData({ ...row.rowData, time: null, display_time: "", rate: "" })
                            }
                        }}
                    />
                )
            },
        },
        {
            ...keyColumn("display_time", textColumn),
            title: "Time (min)",
            disabled: true,
        },
        {
            ...keyColumn("rate", textColumn),
            title: "Rate (g/min)",
            disabled: true,
        },
    ]


    return (
        <Modal
            maskClosable={false}
            visible={visible}
            onCancel={() => {
                if (editingState) {
                    Modal.confirm({
                        title: "Unsaved changes present.",
                        onCancel: () => { },
                        onOk: () => {
                            setMetaData([])
                            setCurrentTrial("")
                            setVisible(false)
                        }
                    })
                } else {
                    setMetaData([])
                    setCurrentTrial("")
                    setVisible(false)
                }
            }}
            title={metaTitle === "Final Product (Without Leaching)" ? "Overall filtration rate KPI (g/min)" : "Leaching time (g/min)"}
            footer={null}
            width={1000}>
            <Spin spinning={saveCharacterizationsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Space direction='vertical' style={{ width: "100%" }} size="large">
                    <Row justify="space-between" style={{ marginTop: 30 }}>
                        <Space>
                            <Text strong>{"Select Trial: "}</Text>
                            <Select
                                value={currentTrial}
                                defaultValue={experiments?.[0]?.id_set?.formulation_id} style={{ minWidth: 300 }}
                                onChange={(e) => {
                                    const nestleVariationId = methodList.find((method: any) => method?.variations?.[0]?.name === metaTitle)?.variations?.[0]?.variation_id
                                    setCurrentTrial(e)
                                    setMetaData(experiments.find((res: any) => res?.id_set?.formulation_id === e)?.properties?.[0]?.properties?.find((res: any) => res?.variation_id === nestleVariationId)?.meta || [])
                                }}
                            >
                                {experiments.map((res: any) => (
                                    <Option key={res?.id_set?.formulation_id} value={res?.id_set?.formulation_id}>
                                        {res?.meta?.display_id}
                                    </Option>
                                ))}
                            </Select>
                        </Space>
                        {edit &&
                            <StyledButton onClick={() => {
                                setMetaData((prevState: any) => [...prevState, { mass: "", time: "", display_time: "", rate: "", index: prevState.length }])
                            }}>
                                {"Add Row"}
                            </StyledButton>
                        }
                    </Row>
                    <DynamicDataSheetGrid
                        lockRows
                        height={600}
                        value={metaData}
                        onChange={(e: any) => {
                            e.forEach((res: any) => {
                                if (!isNaN(res.mass) && !!res?.mass && !!res.time) {
                                    const time = dayjs(res.time)
                                    const calculatedTime = (time.hour() * 60) + time.minute() + (time.second() / 60)
                                    res.display_time = convertToPrecision(Number(calculatedTime), 2)
                                    res.rate = convertToPrecision(Number(res.mass) / Number(calculatedTime), 2)
                                }
                            })
                            setMetaData(e)
                            dispatch(setEditingState(true))
                        }}
                        columns={columns}
                    />
                    {edit &&
                        <Row justify="end">
                            <StyledButton type="primary"
                                disabled={!metaData.length}
                                onClick={() => {
                                    setData((prevState: any) => {
                                        prevState.forEach((ele: any) => {
                                            ele.forEach((res: any) => {
                                                if (res?.identifier === "overall_filtration_rate_kpi_gdivided_bymin") {
                                                    res[currentTrial] = convertToPrecision(metaData?.[metaData?.length - 1]?.rate, 2)
                                                }
                                                if (res?.identifier === "leaching_time_gdivided_bymin") {
                                                    res[currentTrial] = convertToPrecision(metaData?.[metaData?.length - 1]?.rate, 2)
                                                }
                                            })
                                        })
                                        return [...prevState]
                                    })
                                    saveCharacterizationsData()
                                }}>
                                {"Save"}
                            </StyledButton>
                        </Row>
                    }
                    {!!metaData?.length &&
                        <Space direction='vertical' style={{ width: "100%", marginTop: 30 }} size="large">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    title: {
                                        text: null,
                                    },
                                    legend: {

                                    },
                                    xAxis: [{
                                        title: {
                                            text: "Time (min)"
                                        },
                                        categories: metaData.map((res: any) => res.display_time).filter((res: any) => !!res),
                                        crosshair: true
                                    }],
                                    yAxis: [{
                                        title: {
                                            text: 'Mass (g)',
                                        },
                                    }, {
                                        title: {
                                            text: 'Rate (g/min)',
                                        },
                                        opposite: true
                                    }],
                                    tooltip: {
                                        shared: true
                                    },
                                    series: [{
                                        name: 'Mass (g)',
                                        type: 'column',
                                        data: metaData.map((res: any) => Number(res?.mass) || null),
                                    },
                                    {
                                        name: 'Rate (g/min)',
                                        type: 'spline',
                                        yAxis: 1,
                                        data: metaData.map((res: any) => Number(res?.rate) || null),
                                    }]
                                }}
                            />
                        </Space>
                    }
                </Space>
            </Spin>
        </Modal>
    )
}
