import { Modal, Table, Typography } from 'antd';
import dayjs from 'dayjs'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
import {
  // AvatarComponent,
  dateSorter,
  stringSorter,
  TEXT_PRIMARY,
  valueFilter
} from '../../Dashboard'
import useTranslate from 'src/utils/useTranslate'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { deleteFileRequest, fileHistoryRequest } from 'src/store/actions/files'
import { workOrdersRequest } from 'src/store/actions/workOrders'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton'
import { Link } from 'react-router-dom';
// import { useMemberName } from 'src/utils/useMemberName'

const DatasetsTable = ({ selectedProjectId }: any) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  // const { getName } = useMemberName()

  const { data: historyData, status: historyStatus } = useSelector(
    (state: StoreState) => state.files.fileHistory
  )

  const { data: workOrders, status: workorderStatus } = useSelector(
    (state: StoreState) => state.workOrders
  )

  const stages = useSelector(
    (state: StoreState) => state.displayNames.data?.stages
  )

  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id,
  )

  useEffect(() => {
    dispatch(fileHistoryRequest({ project_id: selectedProjectId }))
    dispatch(workOrdersRequest({ project_id: selectedProjectId, is_table: true }))
  }, [dispatch, selectedProjectId])


  const workorderNameFilterOptions = useMemo(() => {
    const woIdToName = workOrders.reduce((acc: any, wo: any) => {
      acc[wo.work_order_id] = wo.work_order_name
      return acc
    }, {})

    return [...new Set(historyData.map((wo: any) => wo.work_order_id))]
      .filter(
        (res: any) =>
          res !== null &&
          res !== undefined &&
          woIdToName[res] !== null &&
          woIdToName[res] !== undefined
      )
      .map((res: any) => ({ text: woIdToName[res], value: res }))
  }, [workOrders, historyData])

  // const datasetUploaderFilterOptions = useMemo(
  //   () =>
  //     [...new Set(historyData.map((project: any) => project.user_id))].map(
  //       (res: any) => ({ text: getName(res), value: res })
  //     ),
  //   [historyData, getName]
  // )

  const workorderStageFilterOptions = useMemo(() => {
    const woIdToStage = workOrders.reduce((acc: any, wo: any) => {
      acc[wo.work_order_id] = wo.work_order_stage
      return acc
    }, {})

    return [
      ...new Set(historyData.map((wo: any) => woIdToStage[wo.work_order_id]))
    ]
      .filter((res: any) => res !== null && res !== undefined)
      .map((res: any) => ({
        text: stages[res] || res,
        value: res
      }))
  }, [workOrders, stages, historyData])

  const workorderParentStageFilterOptions = useMemo(() => {
    const woIdToParentStage = workOrders.reduce((acc: any, wo: any) => {
      acc[wo.work_order_id] = wo.parent_stage
      return acc
    }, {})

    return [
      ...new Set(
        historyData.map((wo: any) => woIdToParentStage[wo.work_order_id])
      )
    ]
      .filter((res: any) => res !== null && res !== undefined)
      .map((res: any) => ({
        text: res,
        value: res
      }))
  }, [workOrders, historyData])

  const tableColumns = useMemo(() => {
    return [
      {
        title: t('common.Name'),
        key: 'file_name',
        dataIndex: 'file_name',
        width: 150,
        sorter: (a: any, b: any, c: any) => {
          return stringSorter(a.file_name, b.file_name)
        },
        render: (fileName: string) => (
          <Typography.Text
            style={{
              margin: 0,
              width: 150
            }}
            ellipsis={{ tooltip: fileName }}
            strong
          >
            {fileName}
          </Typography.Text>
        )
      },
      {
        title: t('common.workOrder'),
        dataIndex: 'work_order_id',
        key: 'work_order_id',
        width: 150,
        render: (value: any, row: any, index: any) => {
          const woName = workOrders?.find(
            (workOrder: any) => workOrder.work_order_id === value
          )?.work_order_name
          return (
            <Link to={`/work-orders/details/${value}`}>
              <Typography.Text
                style={{
                  margin: 0,
                  width: 150
                }}
                ellipsis={{ tooltip: woName }}
                strong
                className='dashboard_underline_text'
              >
                {woName}
              </Typography.Text>
            </Link>
          )
        },
        sorter: (a: any, b: any, c: any) => {
          const woNameA =
            workOrders?.find(
              (workOrder: any) => workOrder.work_order_id === a.work_order_id
            )?.work_order_name

          const woNameB =
            workOrders?.find(
              (workOrder: any) => workOrder.work_order_id === b.work_order_id
            )?.work_order_name

          return stringSorter(woNameA, woNameB)
        },
        filters: workorderNameFilterOptions,
        onFilter: (value: any, record: any) => {
          return valueFilter(value, record.work_order_id)
        },
        filterSearch: true
      },
      {
        title: t('common.stage'),
        dataIndex: 'parent_stage',
        key: 'parent_stage',
        width: 100,
        render: (value: any, row: any, index: any) => {
          const woParentStage = workOrders?.find(
            (workOrder: any) => workOrder.work_order_id === row?.work_order_id
          )?.parent_stage
          return (
            <Typography.Text
              style={{
                margin: 0,
                width: 100
              }}
              ellipsis={{ tooltip: woParentStage }}
              strong
            >
              {woParentStage}
            </Typography.Text>
          )
        },
        sorter: (a: any, b: any) => {
          const woParentStageA =
            workOrders?.find(
              (workOrder: any) => workOrder.work_order_id === a.work_order_id
            )?.parent_stage

          const woParentStageB =
            workOrders?.find(
              (workOrder: any) => workOrder.work_order_id === b.work_order_id
            )?.parent_stage

          return stringSorter(woParentStageA, woParentStageB)
        },
        onFilter: (value: any, record: any) => {
          const woIdToParentStage = workOrders.reduce((acc: any, wo: any) => {
            acc[wo.work_order_id] = wo.parent_stage
            return acc
          }, {})

          return valueFilter(
            value,
            woIdToParentStage[record.work_order_id]
          )
        },
        filters: workorderParentStageFilterOptions,
        filterSearch: true
      },
      {
        title: t('common.subStage'),
        dataIndex: 'stage',
        key: 'stage',
        width: 150,
        render: (value: any, row: any, index: any) => {
          const stageName =
            stages[
              workOrders?.find(
                (workOrder: any) =>
                  workOrder.work_order_id === row?.work_order_id
              )?.work_order_stage
            ] ||
            workOrders?.find(
              (workOrder: any) => workOrder.work_order_id === row?.work_order_id
            )?.work_order_stage

          return (
            <Typography.Text
              style={{
                margin: 0,
                width: 150
              }}
              ellipsis={{ tooltip: stageName }}
              strong
            >
              {stageName}
            </Typography.Text>
          )
        },
        filters: workorderStageFilterOptions,
        onFilter: (value: any, record: any) => {
          const woIdToStage = workOrders.reduce((acc: any, wo: any) => {
            acc[wo.work_order_id] = wo.work_order_stage
            return acc
          }, {})
          return valueFilter(value, woIdToStage[record.work_order_id])
        },
        sorter: (a: any, b: any, c: any) => {
          const stageNameA =
            stages[
              workOrders?.find(
                (workOrder: any) => workOrder.work_order_id === a.work_order_id
              )?.work_order_stage
            ] ||
            workOrders?.find(
              (workOrder: any) => workOrder.work_order_id === a.work_order_id
            )?.work_order_stage 

          const stageNameB =
            stages[
              workOrders?.find(
                (workOrder: any) => workOrder.work_order_id === b.work_order_id
              )?.work_order_stage
            ] ||
            workOrders?.find(
              (workOrder: any) => workOrder.work_order_id === b.work_order_id
            )?.work_order_stage

          return stringSorter(stageNameA, stageNameB)
        },
        filterSearch: true
      },
      // {
      //   title: t('common.uploadedBy'),
      //   dataIndex: 'user_id',
      //   key: 'uploadedBy',
      //   width: 150,
      //   render: (member: any) => <AvatarComponent id={member} />,
      //   align: 'center' as 'center',
      //   filters: datasetUploaderFilterOptions,
      //   onFilter: (value: any, record: any) => {
      //     return valueFilter(value, record.user_id)
      //   },
      //   filterSearch: true,
      //   sorter: (a: any, b: any, c: any) => {
      //     return stringSorter(getName(a.user_id), getName(b.user_id))
      //   }
      // },
      {
        title: t('common.uploadedOn'),
        dataIndex: 'created',
        key: 'created',
        width: 130,
        render: (date: any) => {
          const dateFormatted = dayjs(date).format('DD MMM YYYY HH:mm')
          return (
            <Typography.Text
              style={{
                margin: 0,
                width: 130
              }}
              ellipsis={{ tooltip: dateFormatted }}
              strong
            >
              {date ? dateFormatted : 'N/A'}
            </Typography.Text>
          )
        },
        sorter: (a: any, b: any, c: any) => {
          return dateSorter(a.created, b.created)
        }
      },
      {
        dataIndex: 'actions',
        key: 'actions',
        width: 70,
        render: (value: any, row: any) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StyledButton
              icon={<DownloadOutlined />}
              type="default"
              style={{ outline: 'none', border: 'none', padding:'0px 5px' }}
              onClick={(e) => {
                e.stopPropagation()
                window.open(row?.download_link, '_blank')
              }}
              size="small"
            />
            <StyledButton
              icon={
                <DeleteOutlined
                  style={{
                    color: 'red'
                  }}
                />
              }
              style={{ outline: 'none', border: 'none', padding:'0px 5px' }}
              type="default"
              onClick={(e) => {
                e.stopPropagation()
                Modal.confirm({
                  title: `${t('common.delete')} ${row?.file_name} ?`,
                  icon: <StyledDeleteIcon/>,
                  okText: t('common.confirm'),
                  cancelText: t('common.cancel'),
                  onOk: () => {
                    dispatch(deleteFileRequest({ file_id: [row?.file_id] }))
                  },
                  okButtonProps: {
                    style: { outline: 'none', border: 'none' }
                  },
                  cancelButtonProps: {
                    style: { outline: 'none', border: 'none' }
                  }
                })
              }}
              size="small"
            />
          </div>
        )
      }
    ]
  }, [t, workorderNameFilterOptions, workorderParentStageFilterOptions, workorderStageFilterOptions, workOrders, stages, dispatch])

  return (
    <Table
      loading={
        historyStatus === AsyncStates.LOADING ||
        workorderStatus === AsyncStates.LOADING
      }
      columns={tableColumns}
      dataSource={historyData.filter((data: any) => data.user_id === userId)}
      className="dashboard-activity-datasets-drawer-table"
      pagination={{
        style: { color: TEXT_PRIMARY, gap: 8 },
        size: 'small'
      }}
      scroll={{ y: 800 }}
      style={{
        width: '100%',
        scrollbarWidth: 'none'
      }}
    />
  )
}

export default DatasetsTable
