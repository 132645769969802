import { useLayoutEffect, useState } from "react";
import "./Parameter.scss";
import useTranslate from "src/utils/useTranslate";
import NoData from "../Common/NoData";
import AddEditParameterModal from "../Common/AddEditParameterModal";
import ParameterDetails from "./ParameterDetails";
import TabHeader from "../Common/TabHeader";
import AddEditPropertyModel from "../Common/AddEditPropertyModel";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { AsyncStates } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { fetchParametersRequest, fetchPropertyRequest } from "src/store/actions/repository";

const Parameters = () => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [showPropertyModal, setShowPropertyModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [selectedParameter, setSelectedParameter] = useState<string>();
    const [mode, setMode] = useState<"edit" | "create" | "delete">("create");
    const parameters = useSelector(
        (state: StoreState) => state.repository.parameters
    );
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(fetchPropertyRequest());
        dispatch(fetchParametersRequest());
    }, [dispatch]);

    const addItem = () => {
        setMode("create");
        setSelectedParameter(undefined);
        setShowModal(true);
    };

    const showCreateParameterModal = () => {
        setMode("create");
        setShowModal(true);
    };

    const showEditParameterModal = (parameter_id: string) => {
        setMode("edit");
        setSelectedParameter(parameter_id);
        setShowModal(true);
    };

    return (
        <Spin
            spinning={parameters.status === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}
        >
            <div className="parameter-container">
                {parameters.data.length === 0 &&
                    parameters.status === AsyncStates.SUCCESS ? (
                    <NoData
                        title={`${t("repository.totalParameters")} (0)`}
                        btns={[t("repository.addNewParameter"), t("repository.uploadBulkParameters")]}
                        addItem={setShowModal}
                    />
                ) : (
                    <div className="content-container">
                        {parameters.data.length > 0 && (
                            <>
                                <TabHeader
                                    title={`${t("repository.totalParameters")} (${parameters.data.length})`}
                                    addItem={addItem}
                                    setSearchTerm={setSearchTerm}
                                />
                                <ParameterDetails
                                    searchTerm={searchTerm}
                                    showCreateParameterModal={showCreateParameterModal}
                                    showEditParameterModal={showEditParameterModal}
                                />
                            </>
                        )}
                    </div>
                )}
                <AddEditParameterModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    showPropertyModal={() => setShowPropertyModal(true)}
                    mode={mode}
                    selectedParameterId={selectedParameter}
                    fetchProperties={false}
                />
                <AddEditPropertyModel
                    showModal={showPropertyModal}
                    setShowModal={setShowPropertyModal}
                    mode="create"
                />
            </div>
        </Spin>
    );
};

export default Parameters;
