import { createActions } from "redux-actions";


export enum UnitListActionTypes {
  REQUEST = "UNIT_LIST_REQUEST",
  SUCCESS = "UNIT_LIST_SUCCESS",
  FAILURE = "UNIT_LIST_FAILURE",
}

export enum ConversionActionTypes {
  REQUEST = "CONVERSION_REQUEST",
  SUCCESS = "CONVERSION_SUCCESS",
  FAILURE = "CONVERSION_FAILURE",
}

export enum CreateUnitActionTypes {
  REQUEST = "CREATE_UNIT_REQUEST",
  SUCCESS = "CREATE_UNIT_SUCCESS",
  FAILURE = "CREATE_UNIT_FAILURE",
}
export enum DeleteUnitActionTypes {
  REQUEST = "DELETE_UNIT_REQUEST",
  SUCCESS = "DELETE_UNIT_SUCCESS",
  FAILURE = "DELETE_UNIT_FAILURE",
}
// export enum UpdateUnitActionTypes {
//   REQUEST = "UPDATE_UNIT_REQUEST",
//   SUCCESS = "UPDATE_UNIT_SUCCESS",
//   FAILURE = "UPDATE_UNIT_FAILURE",
// }

export const {
  unitListRequest,
  unitListSuccess,
  unitListFailure,
  conversionRequest,
  conversionSuccess,
  conversionFailure,
  createUnitRequest,
  createUnitSuccess,
  createUnitFailure,
  deleteUnitRequest,
  deleteUnitSuccess,
  deleteUnitFailure
} = createActions({
  [UnitListActionTypes.REQUEST]: payload => payload,
  [UnitListActionTypes.SUCCESS]: response => response,
  [UnitListActionTypes.FAILURE]: () => { },
  [ConversionActionTypes.REQUEST]: payload => payload,
  [ConversionActionTypes.SUCCESS]: response => response,
  [ConversionActionTypes.FAILURE]: () => { },
  [CreateUnitActionTypes.REQUEST]: payload => payload,
  [CreateUnitActionTypes.SUCCESS]: response => response,
  [CreateUnitActionTypes.FAILURE]: () => { },
  [DeleteUnitActionTypes.REQUEST]: payload => payload,
  [DeleteUnitActionTypes.SUCCESS]: response => response,
  [DeleteUnitActionTypes.FAILURE]: () => { }
})
