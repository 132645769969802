import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	formulationsFilterRequest,
	formulationsFilterSuccess,
	formulationsFilterFailure,
} from "../actions/formulationsFilter"
import { filterApi } from "src/services/formulation/index"
import jwtManager from "src/utils/jwtManager"

type FormulationsFilterAction = {
	type: string
	payload: {
		filters?: any
		project_id?: Array<string>
		search_list?: Array<string>
		similar_formulations?: string
		previous_id?: string
		reset?: boolean
		sort_on?: string
		sort_order?: string
		page_size?: number
		lab_locations?: Array<string>
		work_order_type?: string
	}
}

function* formulationsFilterSaga({
	payload: {
		filters,
		project_id,
		search_list,
		similar_formulations,
		previous_id,
		reset,
		sort_on,
		sort_order,
		page_size,
		lab_locations,
		work_order_type
	} = {},
}: FormulationsFilterAction) {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = {
			search_list,
			filters,
			project_id,
			similar_formulations,
			previous_id,
			sort_on,
			sort_order,
			page_size,
			lab_locations,
			work_order_type
		}
		const {
			data: {
				result: { data, status },
			},
		} = yield call(filterApi, params, headers)

		if (status === "Success") {
			yield put(formulationsFilterSuccess(data, reset))
		} else {
			yield put(formulationsFilterFailure())
		}
	} catch (error) {
		yield put(formulationsFilterFailure())
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(formulationsFilterRequest, formulationsFilterSaga)
}
