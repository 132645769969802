import {
    Card,
    Descriptions,
    Image,
    Modal,
    Popconfirm,
    Space,
    Spin,
    Upload,
    UploadProps,
} from 'antd';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetDataSmilesFileClear, resetDataSmilesFileRequest, uploadSmilesFileClear, uploadSmilesFileRequest } from 'src/store/actions/inventoryV2';
import useTranslate from 'src/utils/useTranslate';
import { smileModalDefaultState } from '../../InventoryDetail';
import { AsyncStates } from 'src/constants';
import { DeleteOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { StoreState } from 'src/store/configureStore';
import { StyledButton } from 'src/styled_components/StyledButton';

export type ingredientSmileModalDataTypes = {
    metaData: {
        inventory_id: string | null;
        chemical_information: any;
    };
    mode: string;
    showModal: boolean;
}

type ViewSmilesDetailModalTypes = {
    ingredientSmileModalData: ingredientSmileModalDataTypes,
    setIngredientSmileModalData: Dispatch<SetStateAction<ingredientSmileModalDataTypes>>,
    editMode: boolean
}

export const ViewSmilesDetailModal = ({ ingredientSmileModalData, setIngredientSmileModalData, editMode }: ViewSmilesDetailModalTypes) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()

    const { smilesFileUploadStatus, deleteSmilesFileStatus } = useSelector((state: StoreState) => state.inventoryV2.smiles);

    useEffect(() => {
        dispatch(uploadSmilesFileClear())
        return () => {
            dispatch(uploadSmilesFileClear())
        }
    }, [dispatch])

    const chemicalInformation = ingredientSmileModalData.metaData?.chemical_information

    const uploadProps: UploadProps = {
        onRemove: (record: any) => {
            // setFile(null)
        },
        multiple: false,
        showUploadList: false,
        accept: ".mol",
        customRequest: ({ file }: any) => {
            const formData = new FormData()
            formData.append('file', file)
            dispatch(uploadSmilesFileRequest({ inventory_id: ingredientSmileModalData.metaData.inventory_id, formData }))
        }
    }

    const allowedKeyDisplayNmaes: { [key: string]: string } = useMemo(() => {
        return {
            "name": t("common.name"),
            "formula": t("smiles.molecular_formula"),
            "csid": t("smiles.csid_number"),
            "Mw": t("smiles.molecular_weight"),
            "smiles": t("smiles.smile_notations")
        }
    }, [t])

    const displayNames: { [key: string]: string } = useMemo(() => {
        return Object.keys(chemicalInformation ?? {}).reduce((acc, curr: string) => {
            if (allowedKeyDisplayNmaes[curr]) {
                return { ...acc, [curr]: allowedKeyDisplayNmaes[curr] }
            }
            return acc
        }, {})
    }, [chemicalInformation, allowedKeyDisplayNmaes])

    const handleSmilesFileDelete = (inventoryId: string) => {
        dispatch(resetDataSmilesFileRequest({ inventory_id: inventoryId }))
    }

    useEffect(() => {
        if (deleteSmilesFileStatus === AsyncStates.SUCCESS) {
            dispatch(resetDataSmilesFileClear())
            setIngredientSmileModalData(smileModalDefaultState)
        }
    }, [deleteSmilesFileStatus, dispatch, setIngredientSmileModalData])

    return <>
        <Spin tip={t("common.loading")} indicator={<LoadingOutlined />} size="default" spinning={smilesFileUploadStatus === AsyncStates.LOADING}>
            <Modal
                okText={t("common.ok")}
                cancelText={t("common.cancel")}
                maskClosable={false}
                open={ingredientSmileModalData.showModal}
                onCancel={() => setIngredientSmileModalData(smileModalDefaultState)}
                bodyStyle={{ padding: 0 }}
                width={400}
                title={t("smiles.chemical_information")}
                footer={<div style={{ display: "flex", gap: "0.5rem" }}>
                    <Upload {...uploadProps} showUploadList={false}>
                        <StyledButton disabled={editMode} loading={smilesFileUploadStatus === AsyncStates.LOADING} icon={<UploadOutlined />}>{t("smiles.reupload_mol_file")}</StyledButton>
                    </Upload>
                    <Popconfirm
                        title={t("smiles.delete_mol_file")}
                        description={t("smiles.confirm_mol_file_deletion")}
                        okText={t("common.confirm")}
                        cancelText={t("common.cancel")}
                        onConfirm={() => !!ingredientSmileModalData.metaData?.inventory_id && handleSmilesFileDelete(ingredientSmileModalData.metaData.inventory_id)}
                    >
                        <StyledButton
                            loading={deleteSmilesFileStatus === AsyncStates.LOADING}
                            danger
                            icon={<DeleteOutlined />}
                            disabled={editMode}
                        >
                        </StyledButton>
                    </Popconfirm>
                </div>
                }
            >
                <Space style={{ width: "100%" }} direction="vertical">
                    <Card
                        bodyStyle={{ padding: "0.5rem" }}
                    >
                        <div style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
                            <Image src={chemicalInformation.img_url} alt={chemicalInformation.formula} style={{ height: 330, width: 330, objectFit: "contain" }} />
                            <Descriptions
                                column={1}
                                bordered
                                size="small"
                                style={{ background: "white" }}
                            >
                                {Object.keys(displayNames).map((key) => {
                                    return <Descriptions.Item key={key} label={displayNames[key]} labelStyle={{ textAlign: "start", display: "flex", width: "9rem", padding: "0.1rem 0.2rem" }} contentStyle={{ padding: "0.1rem 0.2rem" }}>
                                        {chemicalInformation[key]}
                                    </Descriptions.Item>
                                }
                                )}
                            </Descriptions>
                        </div>
                    </Card>
                </Space>
            </Modal >
        </Spin>
    </>
}
