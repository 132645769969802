
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`完全実施要因計画`}</h1>
    <p>{`すべての入力因子の可能な組み合わせを持つ設計は、`}<a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/Factorial_experiment"
      }}>{`完全実施要因計画`}</a>{`と呼ばれます。`}</p>
    <p>{`完全実施要因計画における実験設計点は、各因子の「水準」値によって定義されるn次元の設計空間におけるハイパーキューブの頂点です。これらの実験点はファクトリアルポイントとも呼ばれます。たとえば、3つの因子がそれぞれ4つの水準を持つ場合、完全実施要因計画では合計で4³（64）回の実験が必要となります。`}</p>
    <p><strong parentName="p">{`使用例：`}</strong></p>
    <ul>
      <li parentName="ul">{`要因実験は、各因子に2つ以上の水準がある場合に使用できます。ただし、3水準（またはそれ以上）の要因計画に必要な実験実行回数は、2水準計画に比べてかなり多くなります。`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;