import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
	Popconfirm,
	Row,
	Select,
	Space,
	Tooltip,
	Typography,
	message,
} from "antd"
import { useSelector } from "react-redux"
import { IDataSheet } from "src/components/IDataSheet"
import { Sorter } from "src/components/Sorter"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { isTrialValueExceeded } from "../../utils"
import { Cell } from "src/components/IDataSheet/IDataSheet"
import { useQuery } from "src/utils/useQuery"
import { useValue } from "src/utils/useValue";
import { fixDatasheetColumnsCellRenderer } from "src/utils/general/fix-datasheet-columns"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
const { Option } = Select

type P = {
	addRow: (inputType: string, currentSelectedStage: number) => void
	setProcessingParameterList: Dispatch<
		SetStateAction<{ [key: number]: string[] }>
	>
	deleteTrial: (index: number, stage: number) => void
	paramterListDropDown: (index: number, inputType: string) => JSX.Element
	setUnsavedChanges: Dispatch<SetStateAction<boolean>>
	processingInputs: any
	processingParameterList: string[]
	processingDataList: any[]
	setProcessingDataList: Dispatch<SetStateAction<{ [key: number]: any[] }>>
	currentSelectedStage: number
	trialDisplayNameList: { [key: number]: any[] }
	disableDeletionFor: any
	isMultiStageModel: boolean
}

export const ProcessingTable = ({
	addRow,
	deleteTrial,
	paramterListDropDown,
	setProcessingParameterList,
	setUnsavedChanges,
	processingInputs,
	processingParameterList,
	processingDataList,
	setProcessingDataList,
	currentSelectedStage,
	trialDisplayNameList,
	disableDeletionFor,
	isMultiStageModel,
}: P) => {
	const [t] = useTranslate()
	const { getValue, convertValue } = useValue()
	const displayNames = useSelector(
		(state: StoreState) => state.displayNames.data || {},
	)
	const configs = useSelector((state: StoreState) => state.configs.features)

	const modelConfigData = useSelector(
		(state: StoreState) => state.formulate.modelConfigData,
	)

	let query = useQuery()
	let modelVersion = query?.get("version")

	const optionsDropDown = useCallback(
		(parameterIndex: number, trialIndex: number) => {
			return (
				<div style={{ width: "100%", display: "flex", padding: 8 }}>
					<Select
						style={{ width: "100%" }}
						value={
							processingDataList[trialIndex][
							processingParameterList[parameterIndex]
							]
						}
						onChange={(e: any) => {
							setProcessingDataList((prevState) => {
								return {
									...prevState,
									[currentSelectedStage]: prevState[currentSelectedStage].map(
										(currentData, index) => {
											if (index === trialIndex) {
												return {
													...currentData,
													[processingParameterList[parameterIndex]]: e,
												}
											} else {
												return currentData
											}
										},
									),
								}
							})
						}}
					>
						{processingInputs?.[processingParameterList[parameterIndex]]?.map(
							(res: any) => {
								return (
									<Option key={res} value={res}>
										{typeof res === "number"
											? res
											: res?.[0]?.toUpperCase() + res?.slice(1)}
									</Option>
								)
							},
						)}
					</Select>
				</div>
			)
		},
		[
			processingDataList,
			processingInputs,
			processingParameterList,
			setProcessingDataList,
			currentSelectedStage,
		],
	)

	// const inputComponent = useCallback(
	// 	(parameterIndex, trialIndex) => {
	// 		return (
	// 			<div style={{ padding: 10 }}>
	// 				<Input
	// 					style={{ textAlign: "right" }}
	// 					value={processingDataList[trialIndex][processingParameterList[parameterIndex]]}
	// 					onChange={(e: any) => {
	// 						setProcessingDataList((prevState) => {
	// 							const newState = JSON.parse(JSON.stringify(prevState))
	// 							newState[currentSelectedStage][trialIndex][processingParameterList[parameterIndex]] = e.target.value
	// 							return { ...newState }
	// 						})
	// 					}}
	// 					onBlur={(e) => {
	// 						const value = Number(e.target.value)
	// 						setProcessingDataList((prevState) => {
	// 							const newState = JSON.parse(JSON.stringify(prevState))
	// 							if (isNaN(value)) {
	// 								message.warning(t("aiEngine.pleaseEnterAValidNumber"))
	// 							} else {
	// 								newState[currentSelectedStage][trialIndex][processingParameterList[parameterIndex]] = value
	// 								setUnsavedChanges(true)
	// 							}
	// 							return { ...newState }
	// 						})
	// 					}}
	// 				/>
	// 			</div>
	// 		)
	// 	},
	// 	[currentSelectedStage, processingDataList, processingParameterList, setProcessingDataList, setUnsavedChanges, t],
	// )

	useEffect(() => {
		setProcessingDataList((state: any) => {
			const currenState = state?.[currentSelectedStage]
			if (currenState) {
				const data = currenState?.map((id: any, idx: number) => {
					return currenState[idx]
						? processingParameterList.reduce(
							(acc, parameter) => ({
								...acc,
								[parameter]: currenState[idx][parameter] ?? null,
							}),
							{},
						)
						: processingParameterList.reduce(
							(acc, parameter) => ({ ...acc, [parameter]: null }),
							{},
						)
				})
				return {
					...state,
					[currentSelectedStage]: data,
				}
			} else {
				return {
					...state,
					[currentSelectedStage]: processingParameterList.reduce(
						(acc, parameter) => ({ ...acc, [parameter]: null }),
						{},
					),
				}
			}
		})
	}, [processingParameterList, setProcessingDataList, currentSelectedStage])

	const handleSorter = (value: string[]) => {
		setProcessingParameterList((prev) => ({
			...prev,
			[currentSelectedStage]: value,
		}))
	}

	useEffect(() => {
		if (
			Boolean(configs?.kurita_configs) &&
			modelConfigData[0]?.all_stages?.length > 1 &&
			isMultiStageModel
		) {
			setProcessingParameterList((prev) => {
				return {
					...prev,
					2: [
						...new Set(
							[
								...(prev?.[2] || []),
								...(modelConfigData[1]?.inputs_processing || []),
							].filter((processingName) => !!processingName.length),
						),
					],
				}
			})
		}
	}, [
		configs?.kurita_configs,
		setProcessingParameterList,
		modelConfigData,
		isMultiStageModel,
	])

	const datasheetData = useMemo(() => {
		return [
			[
				{
					value: "Processing",
					component: (
						<Row style={{ padding: 8, marginLeft: 8, minWidth: "400px" }}>
							<Typography.Text strong>
								{Boolean(configs?.valqua_configs)
									? `${t("common.processing")} / ${t(
										"common.characterization",
									)}`
									: t("common.processing")}
							</Typography.Text>
						</Row>
					),
					forceComponent: true,
					readOnly: true,
					width: "400px",
					fixed: true
				},
				{
					value: t("aiEngine.range"),
					component: (
						<Row style={{ padding: 8, marginLeft: 8, minWidth: "200px" }}>
							<Typography.Text strong>
								{t("aiEngine.range")}
							</Typography.Text>
						</Row>
					),
					readOnly: true,
					forceComponent: true,
					width: "200px",
					fixed: true
				},
				...processingDataList?.map((_, index) => ({
					value: trialDisplayNameList?.[currentSelectedStage]?.[index]?.value,
					component: (
						<Space style={{ padding: 8, marginLeft: 8, minWidth: "150px", justifyContent: 'flex-end' }}>
							<Typography.Text strong>
								{isMultiStageModel
									? trialDisplayNameList?.[currentSelectedStage]?.[index]?.label
									: `Trial ${index + 1}`}
							</Typography.Text>
							{processingDataList?.length > 1 && (
								<Tooltip
									title={
										disableDeletionFor?.includes(
											trialDisplayNameList?.[currentSelectedStage]?.[index].value,
										)
											? "Please Remove Ingredient from next Stage to delete."
											: null
									}
								>
									<Popconfirm
										disabled={disableDeletionFor?.includes(
											trialDisplayNameList?.[currentSelectedStage]?.[index].value,
										)}
										okText={t("common.ok")}
										cancelText={t("common.cancel")}
										title={`${t("common.deleteTrial")} ?`}
										onConfirm={() => deleteTrial(index, currentSelectedStage)}
									>
										<StyledDeleteIcon
											style={{ cursor: "pointer" }}
											disabled={disableDeletionFor?.includes(
												trialDisplayNameList?.[currentSelectedStage]?.[index].value,
											)}
										/>
									</Popconfirm>
								</Tooltip>
							)}
						</Space>
					),
					width: "150px",
					forceComponent: true,
					readOnly: true,
					className: "datahseet-header-processing",
				})),
			],
			...processingParameterList.map((key, paramterIndex) => [
				{
					value: paramterIndex,
					component: paramterListDropDown(paramterIndex, "processing"),
					forceComponent: true,
					className: "dropdown-remove-cell",
					width: 400,
				},
				{
					value: Array.isArray(processingInputs?.[key])
						? processingInputs?.[key]
							?.map((res: any) => {
								if (typeof res === "number") return res

								return res?.[0]?.toUpperCase() + res?.slice(1)
							})
							.join(", ")
						: `(${getValue(processingInputs?.[key]?.min) ?? 0} - ${getValue(processingInputs?.[key]?.max) ?? 0
						}) ${getValue(displayNames?.processing?.[key]?.unit?.[0]) ?? ""}`,
					readOnly: true,
					width: 200,
				},
				...processingDataList.map((trial: any, trialIndex) => {
					const isInvalid =
						trial[processingParameterList[paramterIndex]] === ""
							? false
							: isTrialValueExceeded(
								Number(trial[processingParameterList[paramterIndex]]),
								processingInputs?.[key]?.min ?? 0,
								processingInputs?.[key]?.max ?? 0,
							)

					return {
						value: getValue(trial[processingParameterList[paramterIndex]]),
						...(Array.isArray(
							processingInputs?.[processingParameterList[paramterIndex]],
						)
							? {
								component: optionsDropDown(paramterIndex, trialIndex),
								forceComponent: true,
							}
							: {}),
						className: isInvalid ? "datahseet-cell-red" : "",
						width: 150,
					} as Cell
				}),
			]),
		]
	}, [
		configs?.valqua_configs,
		currentSelectedStage,
		deleteTrial,
		disableDeletionFor,
		displayNames?.processing,
		isMultiStageModel,
		optionsDropDown,
		paramterListDropDown,
		processingDataList,
		processingInputs,
		processingParameterList,
		t,
		trialDisplayNameList,
		getValue
	])

	return (
		<Space direction="vertical" size={"small"} style={{ width: "100%" }}>
			{((Boolean(modelVersion) &&
				Object.keys(processingInputs ?? {}).length > 0) ||
				!Boolean(modelVersion)) && (
					<Space
						direction="vertical"
						size={"middle"}
						style={{ width: "100%", overflow: "auto" }}
						className="processing-table-container"
					>
						<Space size={"large"}>
							<Typography.Title level={5} style={{ margin: 0 }}>
								{t("common.processing")}
							</Typography.Title>
							<StyledButton
								onClick={() => addRow("processing", currentSelectedStage)}
								icon={<PlusOutlined />}
								disabled={
									Object.keys(processingInputs ?? {}).length ===
									processingParameterList.length ||
									!Object.keys(processingInputs ?? {}).length
								}
								type="default"
								size="small"
								style={{ borderRadius: 5, outline: "none" }}
							>
								{Boolean(configs?.valqua_configs)
									? `${t("common.addProcessing")} / ${t(
										"common.characterization",
									)}`
									: t("common.addProcessing")}
							</StyledButton>
							<Sorter
								list={processingParameterList.filter((val) => Boolean(val))}
								onChange={handleSorter}
								dataset={"processing"}
							/>
						</Space>

						<IDataSheet
							className="forward-model-datasheet"
							data={datasheetData}
							valueRenderer={(cell) => cell.value}
							onCellsChanged={(changes) => {
								const grid = [...processingDataList]
								changes.forEach(({ row, col, value }) => {
									if (col === 0) return
									const convertedValue = convertValue(value)
									if (isNaN(convertedValue)) {
										const parameter = processingParameterList[row - 1]
										if (Array.isArray(processingInputs[parameter])) {
											grid[col - 2][parameter] = value
											setUnsavedChanges(true)
										} else {
											message.warning(t("aiEngine.pleaseEnterAValidNumber"))
										}
									} else {
										const parameter = processingParameterList[row - 1]
										grid[col - 2][parameter] = value
										setUnsavedChanges(true)
									}
								})
								setProcessingDataList((prev) => ({
									...prev,
									[currentSelectedStage]: grid,
								}))
							}}
							cellRenderer={(props) => fixDatasheetColumnsCellRenderer(datasheetData, props)}
						/>
					</Space>
				)}
		</Space>
	)
}
