import { sendPost } from "../../utils/sendApi"


export const saveCelsureDataApi = sendPost("v1/data/celsure_data")

export const celsureEditTrialApi = sendPost("v1/data/update_celsure_data")

export const celsureDisplayNamesApi = sendPost("v1/data/celsure_display_name")

export const fetchCelsureProjectList = sendPost("v1/data/_celsure_projects")

export const newCelsureProject = sendPost("v1/data/_celsure_create_project")

export const updateCelsureProject = sendPost("v1/data/_celsure_update_project")

export const deleteCelsureProject = sendPost("v1/data/_celsure_delete_project")

export const createCelsureNoteApi = sendPost("v1/data/_celsure_projects/notes/create_notes")

export const updateCelsureNoteApi = sendPost("v1/data/_celsure_projects/notes/update_notes")

export const deleteCelsureNoteApi = sendPost("v1/data/_celsure_projects/notes/delete_notes")

export const getCelsureAllNotesApi = sendPost("v1/data/_celsure_projects/notes")

export const projectCelsureActivityAPi = sendPost("v1/data/_celsure_projects/activity")

export const createCelsureWoApi = sendPost("v1/data/_celsure_wo_create")

export const updateCelsureWorApi = sendPost("v1/data/_celsure_wo_update")

export const deleteCelsureWoApi = sendPost("v1/data/_celsure_wo_delete")

export const celsureWoListApi = sendPost("v1/data/_celsure_wo_list")

export const gelCelsureWoTrialsApi = sendPost("v1/data/_celsure_get_wo_trials")

export const celsuredeleteTrialsApi = sendPost("v1/data/celsure_delete_trials")

export const celsurefilterTrialsApi = sendPost("v1/data/celsure_filter")

export const celsureFileUploadApi = sendPost("v1/data/_celsure_graph_files_upload")

export const celsureGetPlotsApi = sendPost("v1/data/_celsure_get_plots")

export const celsureFileHistoryApi = sendPost("v1/data/_celsure_graph_files_history")

export const celsureApproveTrialsApi = sendPost("v1/data/_celsure_approve_trials")

export const celsureSaveIstaProfilesApi = sendPost("v1/data/save_profiles")

export const celsureDeleteIstaProfilesApi = sendPost("v1/data/delete_profiles")

export const celsureWoUploadReportApi = sendPost("v1/data/celsure_wo_reports/upload")

export const celsureWoRetrieveReportApi = sendPost("v1/data/celsure_wo_reports/retrieve")

export const celsureWoDeleteReportApi = sendPost("v1/data/celsure_wo_reports/delete")

export const getCelsureFormulationDetails = sendPost("v1/data/celsure_trials_filter")

export const celsureRetrieveReports = sendPost("v1/data/celsure_retrieve_reports")

export const celsureReportUpload = sendPost("v1/data/celsure_upload_report")

export const celsureReportDeletion = sendPost("v1/data/celsure_delete_report")

export const celsureReportSharing = sendPost("v1/data/celsure_retrieve_reports") // End point needs to be changed, seems it does not exists.

export const celsureInventoryApi = sendPost("v1/data/celsure_inventory")

export const celsureTrialsReorder = sendPost("v1/data/celsure_reorder_trials")

export const celsureTrialsExport = sendPost("v1/data/celsure/export_data")