import { PageHeader, PageHeaderProps } from "@ant-design/pro-layout";
export const StyledPageHeader: React.FC<PageHeaderProps> = (props) => {
  const { style, ...rest } = props;
  return (
    <PageHeader
      style={{
        borderRadius: 5,
        background: "none",
        // border: '6px solid white',
        // boxShadow: "24px 24px 24px 24px #617c960a",
        ...style,
      }}
      {...rest}
    />
  );
};
