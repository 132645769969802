import { Cell } from "src/components/IDataSheet/IDataSheet"
import { CSSProperties } from "react"

export function fixDatasheetColumnsCellRenderer(
  datasheetData: any,
  props: any,
) {
  const isFixedCol = (
    datasheetData[0]?.[props.col] as Cell & { fixed: boolean }
  )?.fixed
  const lastColWidth = datasheetData[0]?.[props.col - 1]?.width
  const isNextFixedCol = (
    datasheetData[0]?.[props.col + 1] as Cell & { fixed: boolean }
  )?.fixed
  const isLastFixedCol = (
    datasheetData[0]?.[props.col - 1] as Cell & { fixed: boolean }
  )?.fixed

  let offset: any = "0px"
  if (isFixedCol && lastColWidth && (isNextFixedCol || isLastFixedCol)) {
    offset = lastColWidth
  }

  const fixedCellStyles: CSSProperties = {
    position: "sticky",
    left:
      offset !== 0 && offset !== "0px"
        ? `calc(${typeof offset === "number" ? `${offset}px` : offset} + 8px)`
        : offset,
    zIndex: 1,
    backgroundColor: "white"
  }
  return (
    <td
      {...props}
      style={{
        ...(props.style as CSSProperties),
        ...(isFixedCol ? fixedCellStyles : {}),
      }}
    >
      {props.children}
    </td>
  )
}
