import { put, takeLatest, call, select, take } from "redux-saga/effects"
import { END, eventChannel } from "redux-saga";
import {
	formulateRequest,
	formulateFailure,
	modelsConfigRequest,
	modelsConfigSuccess,
	modelsConfigFailure,
	forwardPredDeleteFailure,
	forwardPredDeleteRequest,
	forwardPredDeleteSuccess,
	forwardPredListFailure,
	forwardPredListRequest,
	forwardPredListSuccess,
	forwardPredResultFailure,
	forwardPredResultRequest,
	forwardPredResultSuccess,
	getForwardConfigsRequest,
	getForwardConfigsSuccess,
	getForwardConfigsFailure,
	getForwardConfigsZeonRequest,
	getForwardConfigsZeonSuccess,
	getForwardConfigsZeonFailure,
	setMetric,
	getCharacterizationMethodsZeonRequest,
	getCharacterizationMethodsZeonFailure,
	getCharacterizationMethodsZeonSuccess,
	addFavoritesRequest,
	addFavoritesSuccess,
	addFavoritesFailure,
	getFavoritesRequest,
	getFavoritesSuccess,
	getFavoritesFailure,
	deleteFavoritesRequest,
	deleteFavoritesSuccess,
	deleteFavoritesFailure,
	favouriteModelRequest,
	favouriteModelSuccess,
	favouriteModelFailure,
	modelsConfigListSuccess,
	modelsConfigListFailure,
	modelsConfigListRequest,
	formulateSuccess,
	forwardPredIdRequest,
	changeTitleForwardRequest,
	changeTitleForwardSuccess,
	changeTitleForwardFailure,
	getPredictionTrialRequest,
	getPredictionTrialSuccess,
	getPredictionTrialFailure
} from "../actions/formulate"
import {
	fetchModelsConfig,
	forwardPredDeleteApi,
	forwardPredListApi,
	forwardPredRetrieveApi,
	getForwardConfigsApi,
	getForwardConfigsZeonApi,
	getCharacterizationMethodsZeonApi,
	forwardPredRetrieveZeonApi,
	addFavoriteApi,
	getFavoritesApi,
	deleteFavoritesApi,
	forwardPredFavApi,
	fetchModelsConfigList,
	fetchMultiStageFetchModelsConfig,
	forwardModelStreamCustom,
	forwardModelStreamMultistage,
	forwardModelStreamGeneral,
	forwardModelStreamCompanyBased,
	getLinkedPredictionTrial,
} from "src/services/forwardModel"
import {
	PLUSS_HS05_MODEL_CONFIG,
	maxellForwardVersion,
} from "src/constants"
import { message, notification } from "antd"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { reconnect } from "../actions/connection";
import { changeTitleApi } from "src/services/inverseModel";
import { configsUpdateAiengine } from "../actions/configs";


type formulateAction = {
	type: string
	payload: {
		company?: string
		model_names: string[]
		input_data: any[]
		objective: string
		version: any
		project_type?: string
		prediction_type?: string,
		isMultiStage?: boolean
	}
}

function* modelsConfigListSaga(): Generator<any, any, any> {
	try {
		const configs = yield select((state: StoreState) => state.configs.features)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				data,
				results_project_ids = {
					forward: [],
					inverse: []
				}
			},
			status,
		} = yield call(fetchModelsConfigList, null, headers)

		if (status === 200) {
			if (Boolean(configs?.hs05_model_config)) {
				data.push(PLUSS_HS05_MODEL_CONFIG)
			}
			yield put(modelsConfigListSuccess({ data, projectsUsedInPredictions: results_project_ids }))
		}
	} catch (error) {
		yield put(modelsConfigListFailure(error))
	}
}

function* modelsConfigSaga({ payload: { isMultiStage = false, stage_name = undefined, ...restPayload } }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const configs = yield select((state: StoreState) => state.configs.features)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data },
			status,
		} = yield call(isMultiStage ? fetchMultiStageFetchModelsConfig : fetchModelsConfig, { ...restPayload, ...(isMultiStage && { stage_name }) }, headers)

		if (status === 200) {
			if (Boolean(configs?.hs05_model_config)) {
				data.push(PLUSS_HS05_MODEL_CONFIG)
			}
			yield put(modelsConfigSuccess(data))
		}
	} catch (error: any) {
		yield put(modelsConfigFailure(messages[ln].internal_server_error))
	}
}

let socket: WebSocket
let socketChannel: any

function createForwardModelChannel(socket: WebSocket) {
	return eventChannel((emit) => {

		socket.onmessage = (event: any) => {
			emit(event.data);
		};

		socket.onclose = () => {
			emit(END);
		};

		const unsubscribe = () => {
			socket.onmessage = null;
		};

		return unsubscribe;
	});
}

function* formulateSaga({
	payload: { isMultiStage = false, ...restPayload },
}: formulateAction): Generator<any, any, any> {

	const { user_id: key } = yield select(
		(state) => state.login.loginResponse
	)

	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	const configs = yield select((state: StoreState) => state.configs.features)
	const companyId = yield select((state: StoreState) => state.login.loginResponse.company_id)

	socket = yield call(
		isMultiStage ? forwardModelStreamMultistage :
			(Boolean(configs?.zeon_configs) ||
				(maxellForwardVersion.includes((Number(restPayload?.version))) && Boolean(configs?.maxell_configs)) ||
				restPayload?.prediction_type === "celsure" ||
				restPayload?.version === -1 ||
				companyId === "COMP140MGVSEQE2023"
			) ? forwardModelStreamCompanyBased :
				Number(restPayload?.version) >= 0 ?
					forwardModelStreamCustom : forwardModelStreamGeneral
		, key)
	socketChannel = yield call(createForwardModelChannel, socket)

	socket.send(JSON.stringify({ ...restPayload, token: jwtManager.getToken() }))
	while (true) {
		try {
			const wsResponse = yield take(socketChannel)
			const result = JSON.parse(wsResponse)
			if (result?.type === 'results') {
				try {
					// yield put(
					// 	forwardPredResultRequest({
					// 		prediction_id: result.prediction_id,
					// 		type: "predictions"
					// 	})
					// );
					yield put(forwardPredListRequest({ pageNum: 1, pageSize: 10 }));
					yield put(formulateSuccess(result));
					yield put(forwardPredIdRequest(result?.prediction_id))
					yield put(setMetric(result.statements || {}))
					const entries = Object.entries(result.statements || {})
					if (
						entries.length > 0 &&
						entries.every(([_, value]: any) =>
							Object.keys(value || {}).includes("matching_subset")
						)
					) {
						notification.error({
							message: messages[ln].ai_engine_no_matching_models,
							duration: 5,
						})
						yield put(formulateFailure())
					}
					socket.close();
					notification.success({
						duration: 5,
						message: result?.message,
					})
				} catch (err) {
					yield put(forwardPredListFailure());
					notification.error({
						message: messages[ln].internal_server_error,
					});
				}
			} else if (result?.type === 'ack') {
				notification.info({
					duration: 5,
					message: result?.message,
				})
			}
			else if (result?.type === 'error' && !result?.success) {
				message.error(result?.message)
				yield put(formulateFailure())
			}
		} catch (error) {
			notification.error({
				message: messages[ln].internal_server_error,
			});
			yield put(reconnect(error));
		}
	}
}

function* forwardPredListSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const allConfigs = yield select((state: StoreState) => state.configs)
		const {
			data: {
				result: { data, total, total_unread_count },
			},
			status,
		} = yield call(forwardPredListApi, payload, headers)
		if (status === 200) {
			yield put(
				forwardPredListSuccess({ forwardData: data, forwardTotal: total })
			)

			// Update the unread count on forward
			const updatedConfigs = { ...allConfigs?.ai_engine }
			updatedConfigs.forward.total_unread_count = total_unread_count
			yield put(configsUpdateAiengine(updatedConfigs))

		} else {
			yield put(forwardPredListFailure())
		}
	} catch (error) {
		yield put(forwardPredListFailure(error))
	}
}

function* forwardPredResultSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)


	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const configs = yield select((state: StoreState) => state.configs.features)
		const allConfigs = yield select((state: StoreState) => state.configs)

		const {
			forwardPredList: { forwardData, forwardTotal },
		} = yield select((state: StoreState) => state.formulate)

		const userId = yield select((state) => state.login.loginResponse.user_id)

		const {
			data: {
				result: { data },
			},
			status,
		} = yield call(
			Boolean(configs?.zeon_configs)
				? forwardPredRetrieveZeonApi
				: forwardPredRetrieveApi,
			payload,
			headers
		)
		if (status === 200) {
			if (data?.predictions?.some((res: any) => !!Object.keys(res?.predicted_properties || {})?.length)) {
				yield put(forwardPredResultSuccess({ data, total: data?.total_count }))
				if (data?.prediction_id) yield put(forwardPredIdRequest(data.prediction_id))

				// Decrement read receipt total - Optimistic UI
				const updatedConfigs = { ...allConfigs?.ai_engine }
				updatedConfigs.forward.total_unread_count = data?.total_unread_count
				yield put(configsUpdateAiengine(updatedConfigs))

				// Change read recipt in main table - Optimistic UI

				const updatedPredList = [...forwardData?.predictions_list || []]
				updatedPredList.forEach(pred => {
					if (pred?.prediction_id === payload?.prediction_id) {
						pred.read = pred?.read?.length > 0 ? [...pred.read, userId] : [userId]
					}
				})

				yield put(forwardPredListSuccess({
					forwardTotal: forwardTotal,
					forwardData: { ...forwardData, predictions_list: updatedPredList }
				}))

				// if (payload?.type === "predictions") {
				// 	notification.success({
				// 		message: responseMessage,
				// 		description: "If it is not displayed, refresh the page and access the prediction card from the 'Predicted Experiments History' button.",
				// 		duration: 0
				// 	})
				// }
			} else {
				yield put(
					forwardPredResultFailure(messages[ln].ai_engine_no_matching_models)
				)
			}
		} else {
			yield put(forwardPredResultFailure())
		}
	} catch (error) {
		yield put(forwardPredResultFailure(error))
	}
}

function* forwardPredDeleteSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { status, data } = yield call(forwardPredDeleteApi, payload, headers)

		if (status === 200) {
			yield put(forwardPredDeleteSuccess())
			notification.success({
				message: data.result.message,
			})
		} else {
			yield put(forwardPredDeleteFailure())
		}
	} catch (error) {
		yield put(forwardPredDeleteFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

function* getMlProjectSpecificDataSaga({
	payload,
}: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data },
			status,
		} = yield call(getForwardConfigsApi, payload, headers)

		if (status === 200) {
			yield put(getForwardConfigsSuccess(data))
		} else {
			yield put(getForwardConfigsFailure())
		}
	} catch (error) {
		yield put(getForwardConfigsFailure(error))
	}
}

function* getMlProjectSpecificDataZeonSaga({
	payload,
}: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data },
			status,
		} = yield call(getForwardConfigsZeonApi, payload, headers)

		if (status === 200) {
			yield put(getForwardConfigsZeonSuccess(data))
		} else {
			yield put(getForwardConfigsZeonFailure())
		}
	} catch (error) {
		yield put(getForwardConfigsZeonFailure(error))
	}
}

function* getCharacterizationMethodsZeonSaga({ payload: apiPayload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)

		const { payload, from } = apiPayload
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data, message },
			status,
		} = yield call(getCharacterizationMethodsZeonApi, payload, headers)

		if (status === 200) {
			if (!Object.keys(data ?? {})[0]) {
				notification.error({ message: "No variations found for this characterization", duration: 3 })
			}
			yield put(getCharacterizationMethodsZeonSuccess(data, from))
		} else {
			yield put(getCharacterizationMethodsZeonFailure(message))
		}
	} catch (error) {
		yield put(getCharacterizationMethodsZeonFailure(error))
	}
}

function* addFavoriteSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { message, status },
		} = yield call(addFavoriteApi, payload, headers)
		if (status === "Success") {
			yield put(addFavoritesSuccess())
			notification.success({
				message: message,
			})

		} else {
			yield put(addFavoritesFailure(message))
			notification.error({
				message: message,
			})
		}

	} catch (error) {
		message.error(messages[ln].internal_server_error)
	}

}

function* getFavoritesListSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, message, status, total_count, display_names },
			},
		} = yield call(getFavoritesApi, payload, headers)

		if (status === "Success") {
			yield put(getFavoritesSuccess({
				favoritesList: data,
				favoritesDisplayNames: display_names,
				total_count
			}))
		} else {
			yield put(getFavoritesFailure(message))
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
	}
}

function* deleteFavoritesSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { message, status },
		} = yield call(deleteFavoritesApi, payload, headers)
		if (status === "Success") {
			yield put(deleteFavoritesSuccess())
			notification.success({
				message: message,
			})
		} else {
			yield put(deleteFavoritesFailure(message))
			notification.error({
				message: message,
			})
		}
	} catch (error) {
		message.error(messages[ln].internal_server_error)
	}
}


function* favoriteModelSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result: { status, data, message } },
		} = yield call(forwardPredFavApi, payload, headers)
		if (status === "Success") {
			yield put(favouriteModelSuccess(data))
			notification.success({
				message: message,
			})
		} else {
			yield put(favouriteModelFailure(message))
			notification.error({
				message: message,
			})
		}
	} catch (error) {
		yield put(favouriteModelFailure())
		message.error(messages[ln].internal_server_error)
	}
}

function* changeTitleForwardSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			forwardPredList: { forwardData, forwardTotal },
		} = yield select((state: StoreState) => state.formulate)
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(
			changeTitleApi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(changeTitleForwardSuccess(data))

			// Change the title of main table - Optimistic UI
			const updatedPredList = [...forwardData?.predictions_list || []]
			updatedPredList.forEach(pred => {
				if (pred?.prediction_id === payload?.prediction_id) {
					pred.objective = payload?.title
				}
			})

			yield put(forwardPredListSuccess({
				forwardTotal: forwardTotal,
				forwardData: { ...forwardData, predictions_list: updatedPredList }
			}))
		} else {
			notification.error({
				message
			})
			yield put(changeTitleForwardFailure({ error: message }))
		}
	} catch (error: any) {
		yield put(changeTitleForwardFailure({ error }))
		message.error(messages[ln].internal_server_error)
	}
}

function* getLinkedPredictionTrialDataSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result: { status, data, message } },
		} = yield call(getLinkedPredictionTrial, payload, headers)

		if (status === "Success") {
			yield put(getPredictionTrialSuccess({
				formulation_id: payload.formulation_id,
				data
			}))
		} else {
			yield put(getPredictionTrialFailure({
				formulation_id: payload.formulation_id,
				message
			}))
			notification.error({
				message: message,
			})
		}
	} catch (error) {
		yield put(getPredictionTrialFailure({
			formulation_id: payload.formulation_id,
			message: error
		}))
		message.error(messages[ln].internal_server_error)
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(modelsConfigListRequest, modelsConfigListSaga)
	yield takeLatest(modelsConfigRequest, modelsConfigSaga)
	yield takeLatest(formulateRequest, formulateSaga)
	yield takeLatest(forwardPredListRequest, forwardPredListSaga)
	yield takeLatest(forwardPredResultRequest, forwardPredResultSaga)
	yield takeLatest(forwardPredDeleteRequest, forwardPredDeleteSaga)
	yield takeLatest(getForwardConfigsRequest, getMlProjectSpecificDataSaga)
	yield takeLatest(
		getForwardConfigsZeonRequest,
		getMlProjectSpecificDataZeonSaga
	)
	yield takeLatest(
		getCharacterizationMethodsZeonRequest,
		getCharacterizationMethodsZeonSaga
	)
	yield takeLatest(addFavoritesRequest, addFavoriteSaga)
	yield takeLatest(getFavoritesRequest, getFavoritesListSaga)
	yield takeLatest(deleteFavoritesRequest, deleteFavoritesSaga)
	yield takeLatest(favouriteModelRequest, favoriteModelSaga)
	yield takeLatest(changeTitleForwardRequest, changeTitleForwardSaga)
	yield takeLatest(getPredictionTrialRequest, getLinkedPredictionTrialDataSaga)
}
