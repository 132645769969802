//App.js File
import { CloudUploadOutlined } from "@ant-design/icons";
import { Space, Upload, UploadProps } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileListView } from "src/components/FileListView";
import { FileListColumns } from "src/components/FileListView/FileListView";
import { permissions, projectStatus } from "src/constants";
import {
  deleteFileAttachmentRequest,
  fetchFileListRequest,
  uploadFileAttachmentRequest,
} from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { usePermission } from "src/utils/usePermissions";
import { WorkOrderContainer } from "../WorkOrderContainer";
import { Stages } from "src/utils/mapDataset";
import useTranslate from "src/utils/useTranslate";

const Attachments = () => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const workOrder = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder || {}
  );
  const projectId = useSelector((state: StoreState) => state.projects.current);
  const attachments = useSelector(
    (state: StoreState) => state.workOrderDetails.fileList
  );
  const uploadFileAttachmentStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.uploadFileAttachmentStatus
  );
  const userAccess = usePermission();
  const disabled = useMemo(
    () =>
      userAccess?.permission === permissions.viewer ||
      userAccess?.status !== projectStatus.in_progress,
    [userAccess]
  );

  useEffect(() => {
    if (workOrder.work_order_stage !== Stages.report_preview) {
      dispatch(
        fetchFileListRequest({
          work_order_id: workOrder.work_order_id,
        })
      );
    }
  }, [workOrder, dispatch]);

  const deleteAttachment = useCallback(
    (record: any) => {
      const { file_id, work_order_id, work_order_stage } = record;
      dispatch(
        deleteFileAttachmentRequest({
          file_id,
          work_order_id,
          work_order_stage,
        })
      );
    },
    [dispatch]
  );

  const uploadProps: UploadProps = useMemo(
    () => ({
      disabled,
      multiple: true,
      showUploadList: false,
      name: "fileAttachment",
      customRequest: ({ file }) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
          "payload",
          JSON.stringify({
            work_order_id: workOrder.work_order_id,
            project_id: projectId,
            work_order_stage: workOrder.work_order_stage,
            file_type: (file as any).type,
            file_name: (file as any).name,
          })
        );
        dispatch(
          uploadFileAttachmentRequest({
            formData,
            details: {
              work_order_id: workOrder.work_order_id,
            },
          })
        );
      },
    }),
    [projectId, workOrder, dispatch, disabled]
  );

  return (
    <WorkOrderContainer>
      {workOrder.work_order_id && (
        <>
          <Space
            size="large"
            direction="vertical"
            style={{ width: "100%", padding: "16px 28px" }}
          >
            {!disabled && workOrder?.status !== "closed" && (
              <div style={{ float: "right" }}>
                <Upload {...uploadProps}>
                  <StyledButton
                    icon={<CloudUploadOutlined />}
                    type="primary"
                  >
                    {t("common.Uploadafile")}
                  </StyledButton>
                </Upload>
              </div>
            )}
            <FileListView
              columns={[
                FileListColumns.NAME,
                FileListColumns.DELETE,
                FileListColumns.DOWNLOAD,
                FileListColumns.STAGE,
                FileListColumns.UPLOADED_BY,
                FileListColumns.UPLOADED_ON,
              ]}
              fileList={attachments}
              onDelete={deleteAttachment}
              uploadStatus={uploadFileAttachmentStatus}
            />
          </Space>
        </>
      )}
    </WorkOrderContainer>
  );
};
export default Attachments;
