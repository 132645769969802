import { Row, Col, Cascader } from "antd"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { forwardRef, useCallback, memo, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { getDisplayNameElement } from "src/utils/general/getDisplayNameElement"
import useTranslate from "src/utils/useTranslate"
import { convertToPrecision } from "src/utils/decorator"
import { recipeDistributionColorCodes as colorCodes } from "src/constants"
import { useValue } from "src/utils/useValue"
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/heatmap")(Highcharts)
require("highcharts-coloraxis-bands")(Highcharts)
require("highcharts-contour")(Highcharts)
require("highcharts-draggable-3d")(Highcharts)
// import workerUrl from "src/workers/recipeService"

/**
 * props
 */
interface SuggestedExpPlotProps {
    /**
     * Filters visibility status
     */
    visibilityArray: boolean[],
    filterValues: any[],
    /**
     * Selected recipes
     */
    setselectedRecipes: any,
    selectedRecipes: string[],
    experiments: any[],
    recipeDistributionFrom: string
}

/**
     * Recipes distribution bubble plots
     * @category AI Engine
     * @subcategory Inverse Prediction
     * @component SuggestedExpPlot
     * @function SuggestedExpPlot
     * @param {SuggestedExpPlotProps} props For plots
     */
export const SuggestedExpPlot = memo(forwardRef(({ visibilityArray, filterValues, setselectedRecipes, selectedRecipes, experiments, recipeDistributionFrom }: SuggestedExpPlotProps, chartRef: any) => {
    /**
     * @member {string} Xaxis Label for X
     * @memberof SuggestedExpPlot
     * @instance
     */
    const [Xaxis, setXaxis] = useState()
    /**
     * @member {string} Yaxis Label for Y
     * @memberof SuggestedExpPlot
     * @instance
     */
    const [Yaxis, setYaxis] = useState()
    /**
    * @member {string} Zaxis Label for Z
    * @memberof SuggestedExpPlot
    * @instance
    */
    const [Zaxis, setZaxis] = useState<any>()
    const { getValue, convertValue } = useValue()

    const displayNames = useSelector(
        (state: StoreState) => state.displayNames.data
    )
    /**
     * Drag Selection
     * @function selectPointsByDrag
     * @memberof SuggestedExpPlot
     * @param {any} e Point info
     * @instance
     */

    // const recipeWorkerService = useMemo(() => new Worker(workerUrl), [])
    // const [chartData, SetChartData] = useState()
    const { recipeDistributionDisplayNames, recipeDistributionData } = useSelector((state: StoreState) => state.dataSummary)
    const configs = useSelector((state: StoreState) => state.configs.features)
    const [t] = useTranslate()
    const collator = useMemo(() => new Intl.Collator([], { numeric: true }), [])

    const getstruct = (arg: any[]) => {
        let test = {}
        for (const i in arg) {
            if ((Number(i) || Number(i) === 0)) { test = { ...test, ...arg[i] } } else { test = { ...test, ...{ [i]: arg[i] } } }
        }
        return test
    }

    const getData = useCallback((parameter: string) => {
        const recipeData: any[] = recipeDistributionFrom === "data-summary" ? recipeDistributionDisplayNames?.[parameter] ?? [] : Object.keys(experiments[0]?.[parameter] || {})
        if (parameter === 'cost') {
            return [
                { value: 'total_cost', label: 'Formulation Cost' },
                ...[...new Set(experiments.flatMap(({ cost }) => cost?.ingredients && Object.keys(cost.ingredients)))].map(value => ({ value, label: displayNames?.ingredients[value]?.name + " Cost" })).sort((a: any, b: any) => collator.compare(a.label, b.label))
            ]
        }

        if (parameter === 'properties' && Boolean(configs?.nestle_configs)) {
            const data = experiments?.length > 0 ? experiments?.flatMap(({ properties }: any) => properties.flatMap((value: any) => value)) : []

            const variation_ids = [...new Set(experiments.flatMap((distribution: any) => distribution.properties).map(({ variation_id }: any) => variation_id))]

            return data.length > 0 ? variation_ids.map((var_id: any) => ({
                value: var_id,
                label: displayNames?.characterization_methods[var_id]?.name,
                children: Object.keys(getstruct(data.filter(({ variation_id }: any) => variation_id === var_id).map(({ data }: any) => data))).map(pro_id => ({ value: pro_id, label: displayNames?.properties[pro_id].name })
                ).sort((a, b) =>
                    a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : 0
                )
            })).sort((a, b) =>
                a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : 0
            ) : []
        } else {
            return experiments?.length > 0 ? [...recipeData?.map(value => ({ value: value, label: displayNames.hasOwnProperty(parameter) ? displayNames[parameter][value]?.name || experiments[0]?.[parameter]?.[value]?.name || recipeDistributionDisplayNames?.linked_trials?.[value] || value : displayNames['properties'][value]?.name })
            )
            ].filter(({ value }) => experiments.map(exp => exp?.[parameter]?.[value]?.value).some(value => typeof value !== 'string')).sort((a, b) =>
                a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : 0
            ) : []
        }
    }, [collator, configs, displayNames, experiments, recipeDistributionDisplayNames, recipeDistributionFrom])

    const options: any = useMemo(() => (experiments?.length > 0 ? [{
        value: 'ingredients',
        label: t('common.ingredients'),
        children: [...getData('ingredients')]
    },
    {
        ...((recipeDistributionFrom !== "data-summary" && experiments[0]?.predicted_properties) && {
            value: 'properties',
            label: t('common.properties'),
            children: [...getData('predicted_properties')],
        })
    },
    {
        ...((recipeDistributionFrom === "data-summary" && !!recipeDistributionDisplayNames?.properties?.length && experiments.flatMap(({ properties }) => properties).length) && {
            value: 'properties',
            label: t('common.properties'),
            children: [...getData('properties')],
        })
    },
    {
        ...((recipeDistributionFrom === "data-summary" ? !!recipeDistributionDisplayNames?.processing?.length : (!Boolean(configs?.nestle_configs) && experiments[0]?.processing && JSON.stringify(experiments[0]?.processing) !== '{}')) && {
            value: 'processing',
            label: t('common.processing'),
            children: [...getData('processing')]
        })
    },
    {
        ...((Boolean(configs?.work_order_costing) && experiments[0].hasOwnProperty('cost')) && {
            value: 'cost',
            label: t('inventory.costing'),
            children: [...getData('cost')]
        })
    }
    ].filter(values => JSON.stringify(values) !== '{}') : []),
        [experiments, getData, recipeDistributionDisplayNames?.processing?.length, recipeDistributionDisplayNames?.properties?.length, recipeDistributionFrom, t, configs])

    const selectByClick = useCallback((e: any) => {
        const selectedExp = e.point.exp
        setselectedRecipes((selectedRecipes: any) => {
            if (selectedRecipes.includes(selectedExp)) {
                return selectedRecipes.filter((rec: any) => rec !== e.point.exp)
            } else {
                return [...selectedRecipes, e.point.exp]
            }
        })
    }, [setselectedRecipes])

    const getAxisValue = useCallback((axisState: any) => {
        if (Boolean(configs?.nestle_configs) && axisState?.[0] === 'properties' && recipeDistributionFrom === "data-summary") {
            return `${axisState?.[2]}---${axisState?.[1]}`
        } else if (axisState?.[0] === 'cost') {
            return `${axisState?.[1]}---${axisState?.[0]}`
        } else {
            return axisState?.[1]
        }
    }, [configs, recipeDistributionFrom])

    const getColor = useCallback((exp: any) => {
        let color: string[] = []
        let indices: number[] = []
        const profile_ids = (Boolean(configs?.nestle_configs) && recipeDistributionFrom === "data-summary") ? recipeDistributionData.find(experiment => experiment.experiment_id === exp.experiment_id)?.processing?.map((process: any) => process.meta)?.flatMap((process_id: any) => Object.values(process_id)) : []

        const paramsObj = Boolean(configs?.nestle_configs) ? getstruct({ ...exp?.processing, ...exp?.properties?.flatMap((value1: any) => Object.keys(value1.data || {})?.flatMap(exppro => ({ [exppro + '---' + value1.variation_id]: value1.data[exppro] }))), ...exp?.predicted_properties, ...exp.ingredients, ...(exp?.cost) && [...Object.entries({ ...exp?.cost?.ingredients, total_cost: exp.cost.total_cost }).map(value => ({ [value[0] + '---cost']: { 'value': value[1], unit: null, category: null, name: value[0] } }))] }) : { ...exp?.processing, ...exp?.properties, ...exp?.predicted_properties, ...exp?.ingredients }

        filterValues.forEach(({ param, op, val, val2, work_order, material, grade }: { param: any[], op: string, val: string, val2?: string, work_order?: string, material: string, grade: string }, index: number) => {
            if (visibilityArray[index]) {

                if (param?.length > 0 && param[0] === 'work_order') {
                    if (exp?.work_order_id === work_order) {
                        color.push(colorCodes[index])
                        indices.push(index)
                    }
                } else if (param?.length > 0 && param[0] === 'material') {
                    if (exp?.material === material) {
                        color.push(colorCodes[index])
                        indices.push(index)
                    }
                } else if (param?.length > 0 && param[0] === 'grade') {
                    if (exp?.grade === grade) {
                        color.push(colorCodes[index])
                        indices.push(index)
                    }
                } else {
                    const parameter = getAxisValue(param)
                    switch (op) {
                        case '=':
                            if (val !== '' && [...new Set(experiments.map(value => Object.keys(value.ingredients)).flat())].includes(parameter) && Number(paramsObj?.[parameter]?.value) === Number(val)) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            } else if (val !== '' && paramsObj?.[parameter]?.value === val) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            } else if (val !== '' && paramsObj?.[parameter]?.value === convertValue(val)) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            } else if (val && !Number(convertValue(val)) && !Number(paramsObj?.[parameter]?.value) && paramsObj?.[parameter]?.value?.toLowerCase() === val?.toLowerCase()) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            }
                            break;

                        case '<=':
                            if (val !== '' && paramsObj?.[parameter]?.value !== '' && paramsObj?.[parameter]?.value <= Number(convertValue(val))) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            }
                            break;
                        case '<':
                            if (val !== '' && paramsObj?.[parameter]?.value !== '' && paramsObj?.[parameter]?.value < Number(convertValue(val))) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            }
                            break;

                        case '>=':
                            if (val !== '' && paramsObj?.[parameter]?.value >= Number(convertValue(val))) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            }
                            break;

                        case '>':
                            if (val !== '' && paramsObj?.[parameter]?.value > Number(convertValue(val))) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            }
                            break;

                        case 'exists':
                            if (val === 'yes') {
                                if (Boolean(configs?.nestle_configs) && param[0] === 'processing' && profile_ids.includes(param[1])) {
                                    color.push(colorCodes[index])
                                    indices.push(index)
                                } else if (Boolean(configs?.nestle_configs) && param[0] === 'processing' && !profile_ids?.includes(param?.[1]) && recipeDistributionFrom !== "inverse-prediction") {
                                    break
                                }
                                else if (paramsObj?.[parameter]) {
                                    if (Number(paramsObj?.[parameter]?.value) !== 0 && param[1] !== "Profile") {
                                        color.push(colorCodes[index])
                                        indices.push(index)
                                    } else if (paramsObj?.[parameter]?.value === param[2] && Boolean(configs?.nestle_configs) && recipeDistributionFrom === "inverse-prediction") {
                                        color.push(colorCodes[index])
                                        indices.push(index)
                                    }
                                }
                            }
                            if (val === 'no') {
                                if (Boolean(configs?.nestle_configs) && param[0] === 'processing' && !profile_ids?.includes(param?.[1]) && recipeDistributionFrom !== "inverse-prediction") {
                                    color.push(colorCodes[index])
                                    indices.push(index)
                                } else if (Boolean(configs?.nestle_configs) && param[0] === 'processing' && profile_ids?.includes(param?.[1]) && recipeDistributionFrom !== "inverse-prediction") {
                                    break
                                }
                                else if (!paramsObj?.[parameter]?.value || Number(paramsObj?.[parameter]?.value) === 0) {
                                    color.push(colorCodes[index])
                                    indices.push(index)
                                } else if (paramsObj?.[parameter]?.value !== param[2] && Boolean(configs?.nestle_configs) && recipeDistributionFrom === "inverse-prediction") {
                                    color.push(colorCodes[index])
                                    indices.push(index)
                                }
                            }
                            break;

                        case 'range':
                            if (
                                (val !== '' && val2 !== '' && val2 !== undefined && paramsObj?.[parameter]?.value >= Number(convertValue(val))) &&
                                (paramsObj?.[parameter]?.value <= Number(convertValue(val2)))
                            ) {
                                color.push(colorCodes[index])
                                indices.push(index)
                            }
                            break;

                        default:
                            break;
                    }
                }
            }
        });
        return { color, indices }
    }, [recipeDistributionData, configs, filterValues, visibilityArray, getAxisValue, experiments, recipeDistributionFrom, convertValue])

    const radialGradGen = (colors: string[]) => {
        const width = 1 / colors.length
        const transColors = colors.map(col => col)
        const stops = colors.flatMap((val, idx) => [[idx * width, transColors[idx]], [(idx + 1) * width, transColors[idx]]])
        return stops
    }

    const getAxisData = useCallback((exp) => {
        if (Boolean(configs?.nestle_configs)) {
            return getstruct({
                ...exp?.processing, ...exp?.properties?.flatMap((value1: any) => Object.keys(value1.data || {}).flatMap(exppro => ({ [exppro + '---' + value1.variation_id]: value1.data[exppro] })))
                , ...exp?.predicted_properties, ...exp?.ingredients,
                ...(exp?.cost) && [...Object.entries({ ...exp.cost.ingredients, total_cost: exp.cost.total_cost }).map(value => ({ [`${value[0]}---cost`]: { 'value': value[1], unit: null, category: null, name: value[0] } }))]
            })
        } else {
            return { ...exp?.processing, ...exp?.properties, ...exp?.predicted_properties, ...exp?.ingredients }
        }

    }, [configs])



    const getPreciseValue = (value: any, parameterName: string) => {
        if (parameterName === "properties") {
            return (value === "" || value === null) ? null : convertToPrecision(Number(value))
        } else {
            return (value === '' || value === 0 || value === null) ? null : convertToPrecision(Number(value))
        }
    }

    const HighchartsOptions = useMemo(() => (
        {
            credits: {
                enabled: false
            },

            chart: {
                type: 'bubble',
                plotBorderWidth: 0.5,
                zoomType: 'xy',
                events: {
                    // selection: selectPointsByDrag,
                },
            },

            legend: {
                enabled: false
            },

            title: {
                text: null
            },

            accessibility: {
                point: {
                    valueDescriptionFormat: '{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, obesity: {point.z}%.'
                }
            },

            xAxis: {
                gridLineWidth: 1,
                labels: {
                    formatter: function (this: any) {
                        const point = getValue(this.value)
                        return point;
                    }
                },
            },

            yAxis: {
                title: {
                    text: null

                },
                maxPadding: 0.2,
                labels: {
                    formatter: function (this: any) {
                        const point = getValue(this.value)
                        return point;
                    }
                },
            },

            tooltip: {
                useHTML: true,
                headerFormat: "",
                pointFormatter: function (this: any) {
                    const getName = (nameArg: string) => Boolean(configs?.nestle_configs) ? nameArg.split('---')[0] : nameArg
                    const getCostingName = (nameArg: string) => {
                        if (nameArg.includes('cost')) {
                            const [costingName] = nameArg.split('---')
                            return costingName === 'total_cost' ? 'Total Cost' : `${displayNames.ingredients[costingName]?.name}(Cost)`
                        } else return nameArg
                    }

                    const XdisplayName = experiments?.[0]?.ingredients?.[this?.xName]?.name ?? recipeDistributionDisplayNames?.linked_trials?.[this?.xName] ?? getDisplayNameElement(displayNames, "ingredients", this?.xName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.xName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.xName)?.name ?? this?.xName
                    const ydisplayName = experiments?.[0]?.ingredients?.[this?.yName]?.name ?? recipeDistributionDisplayNames?.linked_trials?.[this?.yName] ?? getDisplayNameElement(displayNames, "ingredients", this?.yName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.yName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.yName)?.name ?? this?.yName
                    return `<strong>${this?.exp}</strong><br/><br/>
                    <strong>${getCostingName(ydisplayName)}: </strong><span>${getValue(this.y)}</span><br/>
                    <strong>${getCostingName(XdisplayName)}: </strong><span>${getValue(this.x)}</span>`
                },
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    },
                    // allowPointSelect: true,
                    point: {
                        events: {
                            click: selectByClick,
                            // unselect: unselectByClick,
                        }
                    }
                },
                bubble: {
                    minSize: 10,
                    maxSize: 18
                }
            },
            series: [{
                data: (Xaxis && Yaxis ? experiments?.map((exp: any, index: number) => {
                    return ({
                        trial_id: exp.experiment_id,
                        exp: exp?.meta?.display_id ?? exp?.meta?.formulation_id ?? exp.experiment_id,
                        xName: getAxisValue(Xaxis),
                        yName: getAxisValue(Yaxis),
                        z: getColor(exp).indices?.length * 10,
                        x: getPreciseValue((getAxisData(exp))?.[getAxisValue(Xaxis) ?? '']?.value, Xaxis?.[0]),
                        y: getPreciseValue((getAxisData(exp))?.[getAxisValue(Yaxis) ?? '']?.value, Yaxis?.[0]),
                        marker: {
                            fillColor: {
                                radialGradient: { cx: 0.5, cy: 0.5, r: .5 },
                                stops: radialGradGen(getColor(exp).color)
                            }
                        },
                        color: getColor(exp).color?.at(-1),
                        selected: selectedRecipes.includes(exp?.meta?.display_id ?? exp?.meta?.formulation_id ?? exp.experiment_id)
                        // name: getColor(exp)?.indices
                    })
                }) : []),
            }],
            colors: ['grey']

        }
    ), [selectByClick, Xaxis, Yaxis, experiments, recipeDistributionDisplayNames?.linked_trials, displayNames, configs, getAxisValue, getColor, getAxisData, selectedRecipes, getValue])

    const Highcharts3dOptions = useMemo(() => (
        {
            credits: {
                enabled: false
            },

            chart: {
                height: 500,
                width: 700,
                type: 'scatter3d',
                options3d: {
                    enabled: true,
                    alpha: 20,
                    beta: 30,
                    depth: 200,
                    viewDistance: 10,
                    frame: {
                        bottom: {
                            size: 1,
                            color: 'rgba(0,0,0,0.05)'
                        }
                    },
                    drag: {
                        enabled: true,
                        minBeta: Number.NEGATIVE_INFINITY,
                        maxBeta: Number.POSITIVE_INFINITY,
                        snap: 15,
                        animateSnap: true,
                        flipAxes: true,
                    },
                    fitToPlot: true,
                },
            },

            legend: {
                enabled: false
            },

            title: {
                text: null
            },

            accessibility: {
                point: {
                    valueDescriptionFormat: '{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, obesity: {point.z}%.'
                }
            },

            xAxis: {
                gridLineWidth: 1,
                labels: {
                    formatter: function (this: any) {
                        const point = getValue(this.value)
                        return point;
                    }
                },
            },

            yAxis: {
                title: {
                    text: null

                },
                maxPadding: 0.2,
                labels: {
                    formatter: function (this: any) {
                        const point = getValue(this.value)
                        return point;
                    }
                },
            },
            zAxis: {
                title: {
                    text: null
                },
                maxPadding: 0.2,
                labels: {
                    formatter: function (this: any) {
                        const point = getValue(this.value)
                        return point;
                    }
                },
            },

            tooltip: {
                useHTML: true,
                headerFormat: "",
                pointFormatter: function (this: any) {
                    const getName = (nameArg: string) => Boolean(configs?.nestle_configs) ? nameArg.split('---')[0] : nameArg
                    const getCostingName = (nameArg: string) => {
                        if (nameArg.includes('cost')) {
                            const [costingName] = nameArg.split('---')
                            return costingName === 'total_cost' ? 'Total Cost' : `${displayNames.ingredients[costingName]?.name}(Cost)`
                        } else return nameArg
                    }

                    const XdisplayName = experiments?.[0]?.ingredients?.[this?.xName]?.name ?? recipeDistributionDisplayNames?.linked_trials?.[this?.xName] ?? getDisplayNameElement(displayNames, "ingredients", this?.xName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.xName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.xName)?.name ?? this?.xName
                    const ydisplayName = experiments?.[0]?.ingredients?.[this?.yName]?.name ?? recipeDistributionDisplayNames?.linked_trials?.[this?.yName] ?? getDisplayNameElement(displayNames, "ingredients", this?.yName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.yName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.yName)?.name ?? this?.yName
                    const zdisplayName = experiments?.[0]?.ingredients?.[this?.zName]?.name ?? recipeDistributionDisplayNames?.linked_trials?.[this?.zName] ?? getDisplayNameElement(displayNames, "ingredients", this?.zName)?.name ?? getDisplayNameElement(displayNames, "properties", getName(this?.zName))?.name ?? getDisplayNameElement(displayNames, "processing", this?.zName)?.name ?? this?.zName
                    return `<strong>${this?.exp}</strong><br/><br/>
                    <strong>${getCostingName(ydisplayName)}: </strong><span>${getValue(this.y)}</span><br/>
                    <strong>${getCostingName(XdisplayName)}: </strong><span>${getValue(this.x)}</span><br/>
                    <strong>${getCostingName(zdisplayName)}: </strong><span>${getValue(this.z)}</span>`
                },
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    },
                    // allowPointSelect: true,
                    point: {
                        events: {
                            click: selectByClick,
                            // unselect: unselectByClick,
                        }
                    }
                },
            },
            series: [{
                data: (Xaxis && Yaxis ? experiments?.map((exp: any, index: number) => {
                    return ({
                        trial_id: exp.experiment_id,
                        exp: exp?.meta?.display_id ?? exp?.meta?.formulation_id ?? exp.experiment_id,
                        xName: getAxisValue(Xaxis),
                        yName: getAxisValue(Yaxis),
                        zName: getAxisValue(Zaxis),
                        x: getPreciseValue((getAxisData(exp))?.[getAxisValue(Xaxis) ?? '']?.value, Xaxis?.[0]),
                        y: getPreciseValue((getAxisData(exp))?.[getAxisValue(Yaxis) ?? '']?.value, Yaxis?.[0]),
                        z: getPreciseValue((getAxisData(exp))?.[getAxisValue(Zaxis) ?? '']?.value, Zaxis?.[0]),
                        marker: {
                            radius: 8,
                            fillColor: {
                                radialGradient: { cx: 0.5, cy: 0.5, r: .5 },
                                stops: !!radialGradGen(getColor(exp)?.color)?.length ? radialGradGen(getColor(exp)?.color) : [[0, "grey"]]
                            }
                        },
                        color: getColor(exp).color?.at(-1),
                        selected: selectedRecipes.includes(exp?.meta?.display_id ?? exp?.meta?.formulation_id ?? exp.experiment_id)
                        // name: getColor(exp)?.indices
                    })
                }) : []),
            }],
            colors: ['grey']

        }
    ), [selectByClick, Xaxis, Yaxis, Zaxis, experiments, recipeDistributionDisplayNames?.linked_trials, displayNames, configs, getAxisValue, getColor, getAxisData, selectedRecipes, getValue])



    const displayRender = (labels: string[]) => labels.join('/')
    return (
        <Row align="middle">
            <Col style={{ width: 40 }}>
                <Cascader style={{ width: 200, transform: 'translate(-100px, 0px) rotate(-90deg)' }}
                    options={options}
                    displayRender={displayRender}
                    showSearch
                    onChange={(value: any) => {
                        setYaxis(value)
                        if (selectedRecipes.length) {
                            setselectedRecipes([])
                        }
                    }}
                    placeholder={t("common.pleaseSelect")} />
            </Col>
            <Col>
                <Row>
                    <div style={{ display: !!Zaxis?.length ? "block" : "none" }}>
                        <HighchartsReact
                            ref={chartRef as any}
                            highcharts={Highcharts}
                            options={Highcharts3dOptions}
                        />
                    </div>
                    <div style={{ display: !Zaxis?.length ? "block" : "none" }}>
                        <HighchartsReact
                            ref={chartRef as any}
                            highcharts={Highcharts}
                            options={HighchartsOptions}
                        />
                    </div>
                </Row>
                <Row justify="center">
                    <Cascader style={{ width: 200 }}
                        options={options}
                        displayRender={displayRender}
                        showSearch
                        onChange={(value: any) => {
                            setXaxis(value)
                            if (selectedRecipes.length) {
                                setselectedRecipes([])
                            }
                        }}
                        placeholder={t("common.pleaseSelect")} />
                </Row>
            </Col>
            <Col style={{ width: 40, marginLeft: 30 }}>
                <Cascader style={{ width: 200, transform: 'translate(-100px, 0px) rotate(-90deg)' }}
                    options={options}
                    displayRender={displayRender}
                    showSearch
                    onChange={(value: any) => {
                        setZaxis(value)
                        if (selectedRecipes.length) {
                            setselectedRecipes([])
                        }
                    }}
                    placeholder={t("common.pleaseSelect")} />
            </Col>
        </Row>
    )
}))
