import dayjs from "dayjs"
import useTranslate from 'src/utils/useTranslate'
import { StyledButton } from 'src/styled_components/StyledButton'


export const BilingDetail = ({ billing }: any) => {
    const [t] = useTranslate()

    return (
        <div className='billing__history__info'>
            <div>{dayjs(billing.created).format('YYYY-MM-DD')}</div>
            <div>{billing.status === "paid" ? t("billing.paid") : t("billing.notPaid")}</div>
            <div>{billing.currency?.toUpperCase()} {billing.amount_paid}</div>
            <div><StyledButton type='link' onClick={() => window.open(billing?.hosted_invoice_url, "_blank")}>{t("billing.viewInvoice")}</StyledButton></div>
        </div>
    )
}
