import axios from "axios";
import { sendPost } from "src/utils/sendApi";
import { Headers } from "../interface";


export const GetExperimentsIdApi = (params: any, headers: Headers) => {
    return axios.post(
        process.env.REACT_APP_API_URL + "v1/data/_suggested_exp",
        params,
        {
            headers
        }
    )
}

export const GetBuildOfMaterialsApi = (params: any, headers: Headers) => {
    return axios.post(
        process.env.REACT_APP_API_URL + "v1/data/_suggested_experiment/bom",
        params,
        {
            headers
        }
    )
}

export const GetZeonSuggestedExperimentsIdApi = (params: any, headers: Headers) => {
    return axios.post(
        process.env.REACT_APP_API_URL + "v1/data/zeon/_suggested_exp",
        params,
        {
            headers
        }
    )
}

export const GetExperimentDataApi = (params: any, headers: Headers) => {
    return axios.post(
        process.env.REACT_APP_API_URL + "v1/data/_get_suggested_exp",
        params,
        {
            headers
        }
    )
}

export const predictionDataExportApi = sendPost("v1/data/export/_prediction");
export const buildOfMaterialsExportApi = sendPost("v1/data/export/_build_of_materials");