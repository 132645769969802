import { InfoCircleOutlined } from "@ant-design/icons"
import { InputNumber, Slider, Space, Table, TableProps, Tooltip, Typography } from "antd"
import { useMemo } from "react"
import useTranslate from "src/utils/useTranslate"
import { useValue } from "src/utils/useValue"
import './InverseConfigs.scss'

type InverseConfigsProps = {
  currentSelectedStage: number,
  setInverseConfigurationData: any,
  inverseConfigurationData: any,
  fromPredictButton?: boolean
}

export const InverseConfigs = ({
  currentSelectedStage,
  setInverseConfigurationData,
  inverseConfigurationData,
  fromPredictButton
}: InverseConfigsProps) => {
  const [t] = useTranslate()
  const { formatter, parser, getValue } = useValue()


  const ParameterComponent = (text: string, desc: string) => {
    return (
      <Space>
        <Tooltip title={desc}>
          <InfoCircleOutlined
            style={{
              cursor: "pointer"
            }}
          />
        </Tooltip>
        <Typography.Text
          ellipsis={{
            tooltip: text,
          }}
          style={{
            width: 250,
            padding: "4px 0px",
            height: 32,
          }}
        >
          {text}
        </Typography.Text>
      </Space>
    )
  }

  const AddValueComponent = ([property, data]: any) => {
    const handleChange = (property: string, value: number) => {
      setInverseConfigurationData((prevState: any) => {
        const newInverseConfig = JSON.parse(JSON.stringify(prevState ?? {})) ?? {}
        return {
          ...newInverseConfig,
          [currentSelectedStage]: {
            ...(newInverseConfig?.[currentSelectedStage] ?? {}),
            [property]: {
              ...(newInverseConfig?.[currentSelectedStage]?.[property] ?? {}),
              value: Number(value),
            },
          }
        }
      })
    }

    const marks = {
      ...(inverseConfigurationData[property]?.min_limit !== undefined && {
        [inverseConfigurationData[property]?.min_limit]: {
          label: <Typography.Text
            style={{ maxWidth: 50 }}
            ellipsis={{
              tooltip: getValue(inverseConfigurationData[property]?.min_limit),
            }}
          >
            {getValue(inverseConfigurationData[property]?.min_limit)}
          </Typography.Text>
        }
      }),
      ...(inverseConfigurationData[property]?.max_limit !== undefined && {
        [inverseConfigurationData[property]?.max_limit]: {
          label: <Typography.Text
            style={{ maxWidth: 70 }}
            ellipsis={{
              tooltip: getValue(inverseConfigurationData[property]?.max_limit),
            }}
          >
            {getValue(inverseConfigurationData[property]?.max_limit)}
          </Typography.Text>
        }
      }),
      // [inverseConfigurationData[property]?.value]: {
      //   style: { color: '#000', marginTop: (inverseConfigurationData[property]?.min_limit === inverseConfigurationData[property]?.value) || (inverseConfigurationData[property]?.max_limit === inverseConfigurationData[property]?.value) ? "0rem" : "1rem" },
      //   label: <strong>{getValue(inverseConfigurationData[property]?.value)}</strong>,
      // }
    }

    return <div style={{
      display: "flex",
      gap: "2.5rem",
      alignItems: "center",
      padding: "4px 1.25rem"
    }}>
      <Slider
        style={{
          width: "50%"
        }}
        value={inverseConfigurationData[property]?.value}
        min={inverseConfigurationData[property]?.min_limit}
        max={inverseConfigurationData[property]?.max_limit}
        marks={marks}
        tooltip={{
          open: false
        }}
        onChange={(value) => {
          handleChange(property, value)
        }}
      />
      <InputNumber
        parser={parser}
        formatter={formatter}
        min={inverseConfigurationData[property]?.min_limit}
        max={inverseConfigurationData[property]?.max_limit}
        value={inverseConfigurationData[property]?.value}
        onChange={(value) => {
          if (value !== null) {
            handleChange(property, value)
          }
        }}
      />
    </div>
  }

  const columns: TableProps<any>["columns"] = [
    {
      title: t("common.parameters"),
      dataIndex: "parameter",
      width: "50%",
      key: "parameter",
      render: ([text, desc]) => ParameterComponent(text, desc),
      align: "left",
    },
    {
      title: t("common.values"),
      dataIndex: "value",
      key: "value",
      width: "50%",
      render: (data) => {
        return AddValueComponent(data)
      },
      align: "left",
    },
  ]

  const data: any[] = useMemo(() => {
    return Object.keys(inverseConfigurationData ?? {}).map((property: any, index: any) => {
      return {
        key: property,
        parameter: [
          inverseConfigurationData[property].name,
          inverseConfigurationData[property].description,
        ],
        value: [property, inverseConfigurationData[property]],
      }
    })
  }, [inverseConfigurationData])

  return (
      <Table
        style={{
          width: "100%"
        }}
        size="large"
        columns={columns}
        dataSource={data}
        pagination={false}
        className={"configuration__table"}
      />
  )
}
