import { DeleteOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse, Divider, Empty, Popconfirm, Space, Spin, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import useTranslate from "src/utils/useTranslate";
import AddEditMethodDrawer from "../Common/AddEditMethodDrawer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { useValue } from "src/utils/useValue";
// import { StyledButton } from "src/styled_components/StyledButton";
import { removeMethodsRequest } from "src/store/actions/repository";
import InventoryUtils from "../../utils";

type TProps = {
    selectedProperty: string;
    parameters: { [key: string]: any }[];
};

const PropertyMethods = ({
    selectedProperty,
    parameters,
}: TProps) => {
    const [t] = useTranslate();
    const [mode, setMode] = useState<"edit" | "create">("create");
    const [selectedMethodId, setSelectedMethodId] = useState<string>()
    const [showMethodDrawer, setShowMethodDrawer] = useState(false);
    const methods = useSelector((state: StoreState) => state.repository.methods);
    const { getValue: getLocalValue } = useValue();
    const dispatch = useDispatch();

    useEffect(() => {
        if (methods.status === AsyncStates.SUCCESS) {
            setShowMethodDrawer(false);
        }
    }, [methods.status]);

    const deleteMethod = (method: any) => {
        dispatch(removeMethodsRequest({ property_id: selectedProperty, methods: [method]}));
    }

    const getColumns = (method: any) => {
        return method.parameters?.map((methodParameter: any) => {
            const parameterDetails = parameters.find(
                (p) => p.parameter_id === methodParameter.parameter_id
            );

            if (parameterDetails) {
                return {
                    title: parameterDetails?.is_default ? (
                        <>
                            {parameterDetails?.parameter}&nbsp;
                            <Tag>{t("common.default")}</Tag>
                        </>
                    ) : (
                        <>{parameterDetails?.parameter}</>
                    ),
                    dataIndex: parameterDetails?.parameter,
                    key: parameterDetails?.parameter,
                    width: 150,
                    render: (text: string) => getCellValue(text),
                };
            } else return null;
        }).filter((p: any) => p) || [];
    };

    const getCellValue = (text: string) => {
        const methodParameter = JSON.parse(text);
        let value =
            getLocalValue(methodParameter.value) || methodParameter.value_str || methodParameter.value;
        if (methodParameter.value_subtype === "range")
            value += ` - ${getLocalValue(methodParameter.value_max)}`;
        if (methodParameter.value_subtype === "operator")
            value = `${methodParameter.operator} ${getLocalValue(
                methodParameter.value
            )}`;

        return (
            <>
                {value}{" "}
                {methodParameter.unit && (
                    <>
                        <Divider type="vertical" />
                        {methodParameter.unit}
                    </>
                )}
            </>
        );
    };

    const getData = (method: any) => {
        const data: any = {};

        method.parameters?.forEach((methodParameter: any) => {
            const parameterDetails = parameters.find(
                (p) => p.parameter_id === methodParameter.parameter_id
            );
            if (parameterDetails) data[parameterDetails?.parameter] = JSON.stringify(methodParameter);
        });
        data["key"] = method.method_id

        return Object.keys(data).length > 1 ? [data] : [];
    };

    const addNewMethod = () => {
        setMode("create");
        setSelectedMethodId(undefined);
        setShowMethodDrawer((prev) => !prev);
    }

    // const editMethod = (method_id: string) => {
    //     setMode("edit");
    //     setSelectedMethodId(method_id);
    //     setShowMethodDrawer((prev) => !prev);
    // }

    return (
        <div className="methods-container">
            <Spin
                spinning={methods.status === AsyncStates.LOADING}
                indicator={<LoadingOutlined />}
            >
                <div className="header">
                    <div className="methods-title">
                        {t("repository.totalMethods")} ({methods.data.length})
                    </div>
                    <div
                        className="methods-add"
                        onClick={addNewMethod}
                    >
                        <PlusOutlined /> {t("common.Add New")}
                    </div>
                </div>
                <div className="content">
                    {methods.data.length === 0 &&
                        methods.status === AsyncStates.SUCCESS && (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {methods.data
                        .sort((a, b) => a.method_name.localeCompare(b.method_name))
                        .map((method) => (
                            <Collapse
                                defaultActiveKey={method.method_id}
                                items={[
                                    {
                                        key: method.method_id,
                                        label: InventoryUtils.getNameWithParameters(method.method_name, method.parameters, getLocalValue),
                                        extra: (
                                            <Popconfirm
                                                title={t("common.delete")}
                                                description={t("common.deleteWarningMessage")}
                                                okText={t("common.Yes")}
                                                cancelText={t("common.no")}
                                                onPopupClick={(e) => e.stopPropagation()}
                                                onConfirm={() => deleteMethod({method_id: method.method_id, method_name: method.method_name})}
                                            >
                                                <DeleteOutlined style={{ color: "#F5222D" }} onClick={(e) => e.stopPropagation()} />
                                            </Popconfirm>
                                        ),
                                        children: (
                                            <div className="method-content">
                                                <div className="methods-content-header">
                                                    <span>{t("common.parameters")} ({method.parameters.length})</span>
                                                    {/* <StyledButton
                                                        type="link"
                                                        size="small"
                                                        icon={<PlusOutlined />}
                                                        onClick={() => editMethod(method.method_id)}
                                                    >
                                                        {t("repository.addNewParameter")}
                                                    </StyledButton> */}
                                                </div>
                                                <Table
                                                    className="repository-table"
                                                    columns={getColumns(method)}
                                                    dataSource={getData(method)}
                                                    size="middle"
                                                    pagination={false}
                                                />
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        ))}
                    </Space>
                </div>
            </Spin>
            {showMethodDrawer && (<AddEditMethodDrawer
                showMethodDrawer={showMethodDrawer}
                setShowMethodDrawer={setShowMethodDrawer}
                parameters={parameters}
                selectedPropertyId={selectedProperty}
                mode={mode}
                editMethodId={selectedMethodId}
            />)}
        </div>
    );
};

export default PropertyMethods;
