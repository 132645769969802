import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Col, Form, Row, Select, Spin, Tooltip } from "antd";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import SmileAnalysis from "./SmileAnalysis";
import { DisplayNames } from "src/typings";
import useTranslate from "src/utils/useTranslate";
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official";
import HighchartsHeatmap from "highcharts/modules/heatmap"
import SmileCharts from "./SmileCharts";
import { useValue } from "src/utils/useValue";
const { Option } = Select;
HighchartsHeatmap(Highcharts)

interface NumericSummaryType {
    correlation_summary: CorrelationSummaries;
    correlation_insights: CorrelationInsightsFiltered;
    smiles_extended_report: string;
    charts: NumericSummaryCharts
}

export interface NumericSummaryCharts {
    [key: string]: {
        [key: string]: { [key: string]: any };
    }
}

interface CorrelationInsightsFiltered {
    [key: string]: {
        [key: string]: any[];
    }
}

interface CorrelationSummaries {
    [key: string]: CorrelationSummary
}

interface CorrelationSummary {
    [key: string]: {
        [key: string]: any
    }
}

export interface FilteredSummary {
    correlation_summary: CorrelationSummary,
    correlation_insights: {
        [key: string]: any[];
    }
}

type TProps = {
    setSmileRepostDownloadLink: Dispatch<SetStateAction<string | undefined>>;
    setHasHeatmapData: Dispatch<SetStateAction<boolean>>;
}

const SmileNumericalHeatmap = ({ setSmileRepostDownloadLink, setHasHeatmapData }: TProps) => {
    const [t] = useTranslate();
    const smileSummaryResult = useSelector((state: StoreState) => state.smileSummary.smileSummaryResult)
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const names = useSelector((state: StoreState) => state.displayNames.data) as DisplayNames;
    const [selectedProperty, setSelectedProperty] = useState<string>();
    const [numericSummary, setNumericSummary] = useState<NumericSummaryType>();
    const [filteredData, setFilteredData] = useState<FilteredSummary>();
    const { getValue: getLocalValue } = useValue();

    useEffect(() => {
        const numericSummaryData = smileSummaryResult?.results;
        const correlation_summary = numericSummaryData?.correlation_results?.correlation_summary?.correlation_top_n;
        const correlation_insights = numericSummaryData?.correlation_results?.correlation_insights_filtered;
        const chartsData: NumericSummaryCharts = {
            "scatter_plot": numericSummaryData?.["scatter_plot_results"]?.data
        };

        setNumericSummary({
            correlation_summary: correlation_summary,
            correlation_insights: correlation_insights,
            smiles_extended_report: numericSummaryData?.correlation_results?.smiles_extended_report,
            charts: chartsData
        });

        // Set initial property value
        const firstProperty = Object.keys(correlation_summary || {})[0];
        setSelectedProperty(firstProperty);
        setSmileRepostDownloadLink(numericSummaryData?.correlation_results?.smiles_extended_report);
        setHasHeatmapData(true);

    }, [setHasHeatmapData, setSmileRepostDownloadLink, smileSummaryResult?.results]);

    useEffect(() => {
        if (!selectedProperty) return;

        setFilteredData({
            correlation_summary: numericSummary?.correlation_summary[selectedProperty]!,
            correlation_insights: numericSummary?.correlation_insights[selectedProperty]!
        })
    }, [selectedProperty, numericSummary]);

    const textFormater = (text: string, rotate: boolean = false) => {
        return text?.length < 10 ? text + " -" : (
            `<div class="custom-tooltip">
                ${text?.substring(0, 9) + '... -'}
                <span class="tooltiptext${rotate ? ' rotate-tooltip-90' : ""}">${text}</span>
            </div>`);
    }

    const highChartsPlotsData = useMemo(() => {

        const correlation = { ...filteredData?.correlation_summary }

        const axis = Object.keys(correlation).filter(key => {
            let hasValues = false;
            Object.keys(correlation[key]).forEach((innerKey) => {
                const pointValue = correlation[key][innerKey];
                if (pointValue !== null && pointValue !== undefined) hasValues = true;
            })
            return hasValues;
        })

        const x = [...(axis || [])];
        const y = x
        const array: [number, number, null | number][] = []

        x.forEach((x_key, x_index) => {
            y.forEach((y_key, y_index) => {
                const elementFound = array.find((item) => {
                    return item[0] === y_index && item[1] === x_index
                })
                const value = correlation[y_key]?.[x_key]
                if (!elementFound) {
                    array.push([x_index, y_index, value === "-" ? null : value])
                }
            })
        })

        const options: Highcharts.Options = {
            chart: {
                type: "heatmap",
                height: (y?.length * 28) + 240,
                marginTop: 40,
                marginBottom: 200,
                scrollablePlotArea: {
                    minWidth: (x?.length * 28) + 240,
                    scrollPositionX: 0,
                }
            },
            xAxis: {
                categories: x,
                labels: {
                    useHTML: true,
                    formatter: function (item) {
                        if (names.properties?.[item.value])
                            return `<span style="color:#1677ff; font-weight: 600">${textFormater(names.properties?.[item.value]?.name ?? item.value, true)}</span>`
                        else
                            return `<span style="color:#000; font-weight: 600">${textFormater(names.properties?.[item.value]?.name ?? item.value, true)}</span>`
                    },
                    rotation: -90,
                    style: {
                        textOverflow: "visible"
                    }
                },
                lineColor: "transparent",
                title: undefined
            },
            yAxis: {
                categories: y,
                labels: {
                    useHTML: true,
                    formatter: function (item) {
                        if (names.properties?.[item.value])
                            return `<span style="color:#1677ff; font-weight: 600">${textFormater(names.properties?.[item.value]?.name ?? item.value)}</span>`
                        else
                            return `<span style="color:#000; font-weight: 600">${textFormater(names.properties?.[item.value]?.name ?? item.value)}</span>`
                    },
                    style: {
                        textOverflow: "visible"
                    }
                },
                reversed: true,
                gridLineColor: "transparent",
                title: undefined
            },
            colorAxis: {
                min: -1,
                max: 1,
                minColor: "#2e40bb",
                maxColor: "#b40326"
            },
            tooltip: {
                formatter: function (item) {
                    const point = this.point
                    const series = point.series
                    const xVal = series.xAxis.categories[point["x"]]
                    let yVal = ""
                    if (point.y !== null && point.y !== undefined) yVal = series.yAxis.categories[point.y];

                    return `${getLocalValue(point.value) || ""}, ${names.properties?.[xVal]?.name ?? xVal}, ${names.properties?.[yVal]?.name ?? yVal}`
                },
            },
            title: {
                text: t("dataSummary.correlationHeatmapTop15"),
                style: {
                    fontSize: "12px",
                    fontWeight: "400"
                }
            },
            credits: {
                enabled: false,
            },
            legend: {
                align: "right",
                layout: "vertical",
                margin: 30,
                verticalAlign: "top",
                y: 25,
                symbolHeight: 280,
            },
            series: [
                {
                    type: "heatmap",
                    className: "data-summary-block",
                    borderWidth: 0,
                    data: array,
                    nullColor: "#FFFFFF",
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: '8px',
                        },
                        formatter: function (this) {
                            const pointValue = this.point ? getLocalValue(this.point.value) : null;
                            return pointValue
                        },
                    },
                },
            ],
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 1000,
                        },
                        chartOptions: {
                            yAxis: {
                                labels: {
                                    format: '{substr value 0 10}'
                                }
                            }
                        }
                    },
                ],
            },
        }
        return options
    }, [filteredData, names.properties, t, getLocalValue])

    return (
        <>
            <div className="heatmap-container" id="num-correlation-heatmap">
                <Row justify="start" gutter={[8, 0]}>
                    <Col className="heading">{t("dataSummary.numericalCorrelationHeatmaps")}</Col>
                    <Col>
                        <Tooltip title={t("aiEngine.tab.dataSummary.numericalcorrelationsheatmaps")}><InfoCircleOutlined /></Tooltip>
                    </Col>
                </Row>
                <div className="notes">
                    <b>{t("workOrderDetails.note")}: </b><span className="text-grey">{t("dataSummary.correlationHeatmap")}-
                        <ul>
                            <li>{t("dataSummary.Correlation.2ComponentsCombinationNotAvailable")} - {t("dataSummary.Correlation.2ComponentsCombinationNotAvailableExample")}</li>
                            <li>{t("dataSummary.Correlation.2ComponentsCombinationVariation")} - {t("dataSummary.Correlation.2ComponentsCombinationVariationExample")}</li>
                            <li>{t("dataSummary.3differentCombinations")}</li>
                        </ul>
                    </span>
                </div>
                {!filteredData ?
                    (
                        <div className="chart-container loading-heatmap">
                            <Spin
                                spinning={true}
                                indicator={<LoadingOutlined />}
                            />
                            <div>{t("graph.generatingHeatMap")}</div>
                        </div>
                    ) : (
                        <div className="chart-container">
                            <div className="filter-container">
                                <div className="heading">{t("inventory.filterBy")}</div>
                                <Form
                                    onFinish={() => null}
                                    layout="vertical"
                                >
                                    <Row justify="start" gutter={[16, 16]} style={{ marginTop: "10px" }}>

                                        <Col span={8}>
                                            <Form.Item
                                                name="ingredients"
                                                label={t("common.ingredient")}
                                            >
                                                <Select
                                                    placeholder={t("common.select")}
                                                    allowClear
                                                    mode="multiple"
                                                    optionFilterProp="children"
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                name="property"
                                                label={t("common.property")}
                                            >
                                                <Select
                                                    placeholder={t("common.select")}
                                                    optionFilterProp="children"
                                                    allowClear
                                                    onChange={setSelectedProperty}
                                                    defaultValue={selectedProperty}
                                                    showSearch={true}
                                                >
                                                    {Object.keys(numericSummary?.correlation_summary || {}).map((property: string) => (
                                                        <Option
                                                            value={property}
                                                            key={property}
                                                        >
                                                            {names.properties?.[property]?.name ?? property}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="heatmap-chart">
                                <HighchartsReact
                                    ref={chartComponentRef}
                                    highcharts={Highcharts}
                                    options={highChartsPlotsData}
                                />
                            </div>
                        </div>
                    )}
                {filteredData && Object.keys(filteredData.correlation_insights || {}).length > 0 && <SmileAnalysis filteredSummmary={filteredData} />}
            </div>
            {filteredData && numericSummary?.charts && <SmileCharts chartsData={numericSummary?.charts} properties={Object.keys(numericSummary?.correlation_summary || {})} />}
        </>
    );
}

export default SmileNumericalHeatmap;