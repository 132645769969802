import { CloseOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Drawer, Segmented, Space, Table, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStates, zeonFixedCategoryList } from 'src/constants';
import { buildOfMaterialsRequest } from 'src/store/actions/suggestedExp';
import { StoreState } from 'src/store/configureStore';
import { StyledButton } from 'src/styled_components/StyledButton';
import {
  buildOfMaterialsColumnData, buildOfMaterialsTransposeData,
  // removeNulls 
} from 'src/utils/decorator';
import useTranslate from 'src/utils/useTranslate';
import './BuildOfMaterials.scss'
// import { woBuildOfMaterialsRequest } from 'src/store/actions/workOrderDetails';
import { useQuery } from 'src/utils/useQuery';
import { useValue } from "src/utils/useValue"

const BuildOfMaterials = ({ openBom, setOpenBom, stagesOptions, source }: any) => {
  let query = useQuery()
  const predId = query?.get("predId")

  const dispatch = useDispatch()
  const [t] = useTranslate()
  const { getValue } = useValue()

  const onClose = () => {
    setOpenBom(false);
  };
  const {
    buildOfMaterialsStatus,
    buildOfMaterials: { experiments: suggestedExpExperiment, total: suggedtedExpTotal },
  } = useSelector((state: StoreState) => state.suggestedExp)
  // const {
  //   woBuildOfMaterialsStatus,
  //   woBuildOfMaterials: { experiments: woExperiment, total: woTotal },
  // } = useSelector((state: StoreState) => state.workOrderDetails)

  const [experiments, setExperiments] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [status, setStatus] = useState<AsyncStates>(AsyncStates.INITIAL)

  useEffect(() => {
    if (source === 'suggested_exp') {
      setExperiments(suggestedExpExperiment)
      setTotal(suggedtedExpTotal)
      setStatus(buildOfMaterialsStatus)
    }
    // if (source === 'workorder') {
    //   setExperiments(woExperiment)
    //   setTotal(woTotal)
    //   setStatus(woBuildOfMaterialsStatus)
    // }
  }, [buildOfMaterialsStatus, source, suggedtedExpTotal, suggestedExpExperiment,
    // woBuildOfMaterialsStatus, woExperiment, woTotal
  ])

  const configs = useSelector((state: StoreState) => state.configs.features)

  const [currentSelectedStage, setCurrentSelectedStage] = useState<number>(1)
  const [currentSelectedPage, setCurrentSelectedPage] = useState<number>(1)


  const zeonCategoryList = useMemo(() => {
    const variableCategoryList = Object.values(
      experiments?.[0]?.ingredients || {},
    ).map((res: any) => res?.category)
    return [...new Set([...zeonFixedCategoryList, ...variableCategoryList])]
  }, [experiments])

  useEffect(() => {
    if (source === 'suggested_exp') {
      const currentStage = stagesOptions.find((stageOption: any, index: number) => currentSelectedStage === stageOption.value)
      dispatch(buildOfMaterialsRequest({ stage: currentStage?.label ?? `Stage ${currentSelectedStage}`, source, pageNum: currentSelectedPage, prediction_id: predId }))
    }
    // if (source === 'workorder') { dispatch(woBuildOfMaterialsRequest({ stage: `Stage ${currentSelectedStage}`, source, pageNum: currentSelectedPage })) }
  }, [dispatch, currentSelectedStage, currentSelectedPage, source, predId, stagesOptions])

  const tableColumns = useMemo(() => {
    return buildOfMaterialsColumnData(
      experiments,
      t,
      currentSelectedPage
    )
  }, [currentSelectedPage, experiments, t])

  const tableData = useMemo(() => {
    const newData: any = buildOfMaterialsTransposeData(
      experiments,
      t,
      getValue,
      zeonCategoryList,
      Boolean(configs?.characterization_methods),
      Boolean(configs?.nestle_configs),
    )
    // const removedNulls = removeNulls(newData, "suggested_exp", experiments)
    return newData
  }, [configs?.characterization_methods, configs?.nestle_configs, experiments, t, zeonCategoryList, getValue])
  return (
    <Drawer
      width={'100%'}
      height={"calc(100% - 45px)"}
      open={openBom}
      closable={false}
      placement='bottom'
      styles={{ body: { padding: '24px' } }}
      style={{ userSelect: 'none' }}
      title={
        <Space style={{ justifyContent: 'space-between', width: "100%" }} >
          <Segmented
            options={stagesOptions}
            value={currentSelectedStage}
            style={{ transition: "all .5s" }}
            size="middle"
            onChange={(stage: any) => {
              setCurrentSelectedPage(1)
              setCurrentSelectedStage(stage)
            }}
            className="inverse-stage-segmented"
          />
          <StyledButton onClick={onClose} type='link' ><CloseOutlined /></StyledButton>

        </Space>
      }
    >
      <Table
        dataSource={tableData}
        bordered={true}
        columns={tableColumns}
        scroll={{ x: 400, y: 'calc(100vh - 340px)' }}
        title={() => (
          <Space style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {<InfoCircleOutlined />} {t('common.stage')} {currentSelectedStage} {t('common.formulations')}
            </Typography.Title>
            {/* TODO: When BE is done for BOM export */}
            {/* <Tooltip
              placement="top"
              title={t("workOrderDetails.exportDatasheet")}
            >
            <StyledButton
              type="default"
              icon={<DownloadOutlined />}
              onClick={() => {
                dispatch(
                  exportBuildOfMaterialsRequest({
                    prediction_id: experiments?.[0]?.prediction_id
                    // wo id?
                  }),
                )
              }}
            />
            </Tooltip> */}
          </Space>
        )}
        className="build-of-materials-table"
        pagination={{
          pageSize: tableData?.length,
          current: currentSelectedPage,
          showSizeChanger: false,
          responsive: true,
          total: Math.ceil((tableData?.length * total) / 10),
          onChange: (page) => {
            setCurrentSelectedPage(page)
          },
        }}
        loading={{
          spinning: status === AsyncStates.LOADING,
          indicator: <LoadingOutlined />,
        }}
      />
    </Drawer>
  )
}

export default BuildOfMaterials