export function sortArray(a: string, b: string) {
    const digitRegex: any = /^\d/;
    const alphabetRegex: any = /^[a-zA-Z]/;
    const symbolRegex: any = /^[^\w\s]/;

    const scoreA = symbolRegex.test(a) * 1 || digitRegex.test(a) * 10 || alphabetRegex.test(a) * 100;
    const scoreB = symbolRegex.test(b) * 1 || digitRegex.test(b) * 10 || alphabetRegex.test(b) * 100;

    if (scoreA !== scoreB) {
        return scoreB - scoreA;
    }

    if (a < b) {
        return -1;
    } else if (a > b) {
        return 1;
    }

    return 0;
}

export function sortByOrder(a: string, b: string, c: string) {
    const digitRegex: any = /^\d/;
    const alphabetRegex: any = /^[a-zA-Z]/;
    const symbolRegex: any = /^[^\w\s]/;

    const scoreA = symbolRegex.test(a) * 1 || digitRegex.test(a) * 10 || alphabetRegex.test(a) * 100;
    const scoreB = symbolRegex.test(b) * 1 || digitRegex.test(b) * 10 || alphabetRegex.test(b) * 100;

    if (!a && !b) {
        return -1
    }
    if (c === "ascend") {
        if (!a && b) {
            return 1
        }
        if (a && !b) {
            return -1
        }
    } else {
        if (!a && b) {
            return -1
        }
        if (a && !b) {
            return 1
        }
    }


    if (scoreA !== scoreB) {
        return scoreB - scoreA;
    }

    if (c === "ascend") {
        return a < b ? -1 : 1
    } else {
        return a > b ? 1 : -1
    }

    // return 0;
}