import { useEffect, useState } from "react"
import { io, Socket } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
// const URL = 'http://localhost:8000/comments';


export const useNotifySocket = (namespace: string, token: string) => {

    const [socket, setSocket] = useState<Socket | undefined>()

    useEffect(() => {
        if (token) {
            const URL = process.env.REACT_APP_API_URL + namespace
            setSocket(io(
                URL,
                {
                    path: "/ws/socket.io/",
                    withCredentials: true,
                    forceNew: true,
                    query: {
                        token
                    },
                    transports: ["websocket"]
                }))
        }

    }, [namespace, token]);

    useEffect(() => {
        socket && socket.connect();

        return () => {
            socket && socket.disconnect()
        }
    }, [socket])

    return socket
}