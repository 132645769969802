import axios from "axios";
import { sendPost } from "src/utils/sendApi";

export const loginApi = sendPost("v1/data/user_login")

export const signupApi = sendPost("v1/data/_reg")

export const checkUserExists = sendPost("v1/data/_auth")

export const refreshTokenApi = sendPost("v1/data/_refresh")

export const requestOtpApi = sendPost("v1/data/_request_totp")

export const veriftOtpTrueApi = sendPost("v1/data/_verify_totp_true")

export const verifyOtpApi = sendPost("v1/data/_verify_totp")

export const disableOtpApi = sendPost("v1/data/_disable_totp")

export const getIpDetails = () => {
  return axios.get("https://ipapi.co/json/?key=" + process.env.REACT_APP_IPAPI_KEY);
};

export const setProjectTypeApi = sendPost("v1/data/_set_project_type")

export const logoutApi = sendPost("v1/data/_logout")

export const loginViaMagicLinkApi = sendPost("v1/data/send-magic-link")

export const updateProfileApi = sendPost("v1/data/_update_profile")

export const updateCurrencyApi = sendPost("v1/data/_update_company_details")

export const authMagicLinkApi = sendPost("v1/data/auth-magic-link")