import React, { Dispatch, SetStateAction } from "react"
import { Row, Select, Space } from "antd"
import { DisplayNames } from "src/typings"
import useTranslate from "src/utils/useTranslate"
const { Option } = Select

type P = {
	value1: string | null
	value2: string | null
	setValue1: Dispatch<SetStateAction<string | null>>
	setValue2: Dispatch<SetStateAction<string | null>>
	data: {
		[key: string]: {
			[key: string]: number
		}
	}
	names: DisplayNames
	getDisplayName: (value: string) => string
}

export const PropertySelectionForm = ({
	value1,
	value2,
	setValue1,
	setValue2,
	data,
	names,
	getDisplayName
}: P) => {
	const [t] = useTranslate()

	return (
		<>
			<Row style={{ justifyContent: "center" }}>
				<b>{t('workOrderDetails.note')} :- </b> {t(
					"dataSummary.selectIngredientOrProperty"
				)}
			</Row>
			<Space
				style={{
					display: "flex",
					justifyContent: "space-evenly",
					margin: "1rem",
				}}
			>

				<Select
					showSearch
					placeholder={t("dataSummary.selectIngredientOrProperty")}
					optionFilterProp="children"
					value={value1}
					style={{ width: 160 }}
					onChange={(value) => setValue1(value)}
				>
					{Object.keys(data)
						.filter((element) => element !== value2)
						.map((item) => (
							<Option key={item} value={item}>
								{names.properties?.[item]?.name ??
									names.ingredients?.[item]?.name ??
									getDisplayName(item) ??
									item}
							</Option>
						))}
				</Select>
				<Select
					showSearch
					placeholder={t("dataSummary.selectIngredientOrProperty")}
					optionFilterProp="children"
					value={value2}
					style={{ width: 160 }}
					onChange={(value) => setValue2(value)}
				>
					{Object.keys(data)
						.filter((element) => element !== value1)
						.map((item) => (
							<Option key={item} value={item}>
								{names.properties?.[item]?.name ??
									names.ingredients?.[item]?.name ??
									getDisplayName(item) ??
									item}
							</Option>
						))}
				</Select>
			</Space>
		</>
	)
}
