import { Checkbox, Divider, Form, Modal, Row, Select, Space, Typography } from 'antd'
import { useMemo, useState } from 'react'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'

const { Option } = Select
const { Text } = Typography

interface P {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    initialDisplayIdList: any[]
    trialsList: any[]
    setData: React.Dispatch<React.SetStateAction<string[]>>
    recordKey: number
}


export const CopyValuesModal = ({ visible, setVisible, initialDisplayIdList, trialsList, setData, recordKey }: P) => {
    const [t] = useTranslate()
    const [form] = Form.useForm()
    const requiredFieldStar = useRequiredFieldStar()

    const [selectedTrial, setSelectedTrial] = useState("")
    const [trials, setTrials] = useState<any>([])

    const closeModal = () => {
        form.resetFields()
        setSelectedTrial("")
        setTrials([])
        setVisible(false)
    }

    const copyOptions = useMemo(() => {
        return trialsList?.map((res: any, index: number) => ({
            label: initialDisplayIdList?.[index],
            key: res?.id_set?.formulation_id,
            value: res?.id_set?.formulation_id
        })).filter((res: any) => res?.value !== selectedTrial)
    }, [initialDisplayIdList, selectedTrial, trialsList])

    const copyValues = (values: any) => {
        const { copy_from, copy_to } = values
        setData((prevState: any) => {
            const currentRecord = prevState[recordKey]
            currentRecord.forEach((record: any) => {
                copy_to.forEach((trial: string) => {
                    record[trial] = record[copy_from]
                })
            })
            prevState[recordKey] = currentRecord
            return [...prevState]
        })
        closeModal()
    }


    return (
        <Modal visible={visible} onCancel={closeModal} title={"Copy Values"} footer={null} width={600} maskClosable={false}>
            <Space style={{ width: "100%" }} direction="vertical" size={"large"}>
                <Form layout="vertical" onFinish={copyValues} form={form} requiredMark={false} >
                    <Form.Item
                        name={"copy_from"}
                        required tooltip={requiredFieldStar}
                        label={<Text type="secondary" strong>{"Copy Values from"}</Text>}
                        rules={[{ required: true, message: t("common.required") }]}>
                        <Select allowClear onChange={e => {
                            setSelectedTrial(e)
                            form.setFieldsValue({
                                copy_to: []
                            })
                        }}>
                            {trialsList?.map((res: any, index: number) => (
                                <Option key={res?.id_set?.formulation_id} value={res?.id_set?.formulation_id}>
                                    {initialDisplayIdList?.[index]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name={"copy_to"}
                        required tooltip={requiredFieldStar}
                        label={<Text type="secondary" strong>{"Copy Values to"}</Text>}
                        rules={[{ required: true, message: t("common.required") }]}>
                        <Select mode="multiple" allowClear options={copyOptions} onChange={setTrials}
                            dropdownRender={(menu) => (
                                <>
                                    <Checkbox checked={copyOptions.length === trials.length}
                                        style={{ padding: 10 }} onChange={e => {
                                            if (e.target.checked) {
                                                setTrials(copyOptions.map((res: any) => res?.value))
                                                form.setFieldsValue({ copy_to: copyOptions.map((res: any) => res?.value) })
                                            } else {
                                                setTrials([])
                                                form.setFieldsValue({ copy_to: [] })
                                            }
                                        }} >{"Select All"}</Checkbox>
                                    <Divider style={{ margin: "4px 0" }} />
                                    {menu}
                                </>
                            )}
                        />
                    </Form.Item>
                    <Row justify="end" style={{ marginTop: 40 }}>
                        <Space>
                            <StyledButton onClick={closeModal}>
                                {t("common.cancel")}
                            </StyledButton>
                            <StyledButton type="primary" htmlType="submit">
                                {"Add Values"}
                            </StyledButton>
                        </Space>
                    </Row>
                </Form>
            </Space>
        </Modal>
    )
}
