import {
  proceduresUploadFileApi,
  proceduresRetrieveApi,
} from "../../services/procedures";
import { put, takeLatest, call, select } from "redux-saga/effects";
import {
  createProcedureFailure,
  createProcedureRequest,
  createProcedureSuccess,
  retrieveProcedureRequest,
  retrieveProcedureFailure,
  retrieveProcedureSuccess,
} from "../actions/procedures";
import { messages } from "src/utils/hooks";
import { notification } from "antd";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import jwtManager from "src/utils/jwtManager";
import { setIsEditing } from "../actions/isEditing";

function* proceduresCreateSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { result },
    } = yield call(proceduresUploadFileApi, payload, headers);

    if (result.status === "Success") {
      yield put(setIsEditing(false));
      yield put(createProcedureSuccess(result));
      notification.success({ message: result.message });
    } else {
      yield put(createProcedureFailure(result));
    }
  } catch (error) {
    yield put(createProcedureFailure(messages[ln].internal_server_error));
  }
}

function* proceduresRetrieveSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: { result },
    } = yield call(proceduresRetrieveApi, payload, headers);
    if (result.status === "Success") {
      yield put(retrieveProcedureSuccess(result.data.freepage));
    } else {
      yield put(retrieveProcedureFailure(result));
    }
  } catch (error) {
    yield put(retrieveProcedureFailure(messages[ln].internal_server_error));
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(createProcedureRequest, proceduresCreateSaga);
  yield takeLatest(retrieveProcedureRequest, proceduresRetrieveSaga);
}