import { MoreOutlined, SearchOutlined } from "@ant-design/icons"
import { Dropdown, Input, Select, Space, Table } from "antd";
import Text from "antd/lib/typography/Text"
import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import { StyledCard } from "src/styled_components/StyledCard"
// import { useMemberName } from "src/utils/useMemberName"
import useTranslate from "src/utils/useTranslate"
import { RevokeAccessModal } from "./RevokeAccessModal"
// import { SingleUserProjectInfo } from "./SingleUserProjectInfo"
import { resendEmailRequest } from "src/store/actions/invite"
import { antdTheme, AsyncStates } from "src/constants"
import { WithdrawInvitationModal } from "./WithdrawInvitationModal/WithdrawInvitationModal"
import './UserList.scss'
import Highlighter from "react-highlight-words";

// const { TabPane } = Tabs;
const { Option } = Select;

export const UserList = () => {
  const [t] = useTranslate()
  // const { getName } = useMemberName()
  const dispatch = useDispatch()
  const userInfo = useSelector((store: StoreState) => store.login.loginResponse)

  const [filterTerm, setFilterTerm] = useState("")
  // const [selectedRecords, setSelectedRecords] = useState<any[]>([])
  const users = useSelector((state: StoreState) => state.teams?.data)
  const { resendEmailStatus, withdrawInvitationStatus } = useSelector((state: StoreState) => state.invite)

  const { projectList: projects } = useSelector((state: StoreState) => state.projects)
  // const [isModalVisible, setIsModalVisible] = useState<any>(false)
  const [withdrawInvitationInfo, setWithdrawInvitationInfo] = useState({
    isModalVisible: false,
    withdrawInvitationData: {}
  })

  // const [deleteModalVsisble, setDeleteModalVisible] = useState<boolean>(false)
  // const [selectedUserRole, setSelectedUserRole] = useState<any>("")
  // const [userRoleModalVisible, setUserRoleModalVisible] = useState<any>(false)
  // const [record, setRecord] = useState<any>({})
  // const [tab, setTab] = useState("projects")
  const [filterByRole, setfilterByRole] = useState("")
  const [selectedEmailAction, setSelectedEmailAction] = useState(null)

  const [revokeModalInfo, setRevokeModalInfo] = useState({
    isModalVisible: false,
    revokeModalData: {}
  })

  const usersList = useMemo(() => {
    const usersData = users.filter((user: any) => (user?.user_name.toLowerCase()?.includes(filterTerm?.toLocaleLowerCase()) || user?.user_email?.toLowerCase()?.includes(filterTerm?.toLocaleLowerCase())) && (user.role?.includes(filterByRole || "")))
    return usersData.filter((user) => !user.revoked_access)
  }, [users, filterByRole, filterTerm])

  const handleRevokeAccess = (userData: any) => {
    setRevokeModalInfo({
      isModalVisible: true,
      revokeModalData: userData
    })
  }

  const getMenu = useCallback((record: any) => {
    if (record.role.includes("Pending")) {
      return [
        {
          label: <StyledButton
            type="link"
            loading={(record.user_email === selectedEmailAction) && (AsyncStates.LOADING === resendEmailStatus)}
            onClick={() => {
              setSelectedEmailAction(record.user_email)
              dispatch(resendEmailRequest({ email: record?.user_email }))
            }
            }
          >
            {t("common.resendEmail")}
          </StyledButton>,
          key: 'pending_invitation',
        },
        {
          label: <StyledButton
            type="link"
            danger
            loading={(record.user_email === selectedEmailAction) && (AsyncStates.LOADING === withdrawInvitationStatus)}
            onClick={() => {
              setSelectedEmailAction(record?.invited_email)
              setWithdrawInvitationInfo({
                isModalVisible: true,
                withdrawInvitationData: record
              })
              // deleteInvitation([record?.invited_email])
            }
            }
          >
            {t("teams.withdrawInvitation")}
          </StyledButton>,
          key: 'withdraw_invitation',
        }
      ]
    } else {
      return [{
        label: <StyledButton
          disabled={record.user_email === userInfo.user_email}
          danger
          type="text"
          onClick={() => {
            setSelectedEmailAction(record.user_email)
            handleRevokeAccess(record)
          }}>{t("common.deactivateAccount")}</StyledButton >,
        key: 'deactivate_account',
      }]
    }
  }, [dispatch, resendEmailStatus, selectedEmailAction, t, userInfo.user_email, withdrawInvitationStatus])

  const userColumns = useMemo(() => {
    const columns = [
      {
        title: t("common.name"),
        dataIndex: 'user_name',
        key: "user_name",
        render: (text: string) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[filterTerm]}
            autoEscape
            textToHighlight={text ? `${text[0].toUpperCase()}${text.slice(1)}` : ''}
          />
      ),
        sorter: (a: any, b: any) => { return a.user_name.toLowerCase().localeCompare(b.user_name.toLowerCase()) },
      },
      {
        title: t("common.email"),
        dataIndex: 'user_email',
        render: (text: string) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[filterTerm]}
            autoEscape
            textToHighlight={text ? text : ''}
          />
      ),
      },
      {
        title: t("profile.roles"),
        dataIndex: 'role',
        render: (text: string) => text.charAt(0).toUpperCase() + text.slice(1)
      },
      {
        title: t("project.users.noOfProjects"),
        render: (text: any, record: any) => (
            <Text>
              {projects
                ?.filter((project: any) => 
                  project?.members?.find((proj: any) => proj?.user_id === record?.user_id) 
                  || project?.created_by === record?.user_id)
                ?.length
              }
            </Text>
        )
      },
      // {
      //   title: '',
      //   key: 'action',
      //   render: (text: any, record: any) => {
      //     return (
      //       <Dropdown key="more" overlay={() => dropDownOverlay(record)}>
      //         <StyledButton
      //           type="link"
                    // style={{
                    //   border: "none",
                    //   padding: 0,
                    //   color:'black', 
                    //   outline:'none'
                    // }}
      //           onClick={e => e.stopPropagation()}
      //         >
      //           <MoreOutlined
      //             style={{
      //               fontSize: antdTheme.fontSizeHeading3,
      //               verticalAlign: "top",
      //             }}
      //           />
      //         </StyledButton>
      //       </Dropdown>
      //     )
      //   }
      // },
    ]
    if (userInfo.user_role === "admin") {
      columns.push({
        title: t("common.actions"),
        render: (text: any, record: any) => {
          return <Dropdown menu={{ items: getMenu(record) }} trigger={['click']} >
            <StyledButton 
              type="link"
              style={{
                border: "none",
                padding: 0,
                color:'black', 
                outline:'none'
              }}
              onClick={(e) => e.stopPropagation()}>
              <MoreOutlined
                style={{
                  fontSize: antdTheme.fontSizeHeading3,
                  verticalAlign: "top",
                }}
              />
            </StyledButton>
          </Dropdown>

          // if (record.role.includes("Pending")) {
          //   return <StyledButton
          //     loading={(record.user_email === selectedEmailAction) && (AsyncStates.LOADING === resendEmailStatus)}
          //     onClick={() => {
          //       setSelectedEmailAction(record.user_email)
          //       dispatch(resendEmailRequest({ email: record?.user_email }))
          //     }
          //     }
          //   >
          //     {"Resend Email"}
          //   </StyledButton>
          // } else {
          //   return <StyledButton
          //     disabled={record.user_email === userInfo.user_email}
          //     danger
          //     type="text"
          //     onClick={() => {
          //       setSelectedEmailAction(record.user_email)
          //       handleRevokeAccess(record)
          //     }}>Deactivate account</StyledButton >
          // }
        }
      })
    }
    return columns
  }, [t, userInfo.user_role, filterTerm, projects, getMenu])

  // const menu = (
  //   <Menu onClick={(e) => { setSelectedUserRole(e.key); setUserRoleModalVisible(true) }}>
  //     <Menu.Item key={"admin"}>
  //       <Space direction="vertical">
  //         <Text>Project Admin</Text>
  //       </Space>
  //     </Menu.Item>
  //     <Menu.Item key={"member"}>
  //       <Space direction='vertical'>
  //         <Text>Member</Text>
  //       </Space>
  //     </Menu.Item>
  //   </Menu>
  // );


  // const dropDownOverlay = (record: any) => {
  //   return (
  //     <Menu>
  //       <Menu.Item key={1} style={{ color: 'bule' }} onClick={() => { setIsModalVisible(true); setRecord(record) }}>
  //         <StyledButton style={{ color: "black" }} type="link" >{"View user details"}</StyledButton>
  //       </Menu.Item>
  //       <Menu.Item key={2} onClick={() => { navigator.clipboard.writeText(record.user_email); message.success(`${record?.user_email} copied to your clipboard`) }}>
  //         <StyledButton style={{ color: "black" }} type="link">{"Copy email"}</StyledButton>
  //       </Menu.Item>
  //       {/* <Menu.Item key={3} onClick={() => { setRecord(record); setDeleteModalVisible(true); }} >
  //         <StyledButton danger type="link">{"Remove user"}</StyledButton>
  //       </Menu.Item> */}
  //     </Menu>
  //   )
  // }
  // const getComponentsFromTabs = () => {
  //   switch (tab) {
  //     case "projects":
  //       return <SingleUserProjectInfo record={record} setRecord={setRecord} />
  //   }
  // }


  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <StyledCard>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
            <Text strong>{`${t('common.users')} (${users?.length})`}</Text>
            <Space>
              <Input
                prefix={<SearchOutlined />}
                placeholder={t("teams.searchByNameOrEmail")}
                onChange={e => setFilterTerm(e.target.value)}
                allowClear
              />
              <Select defaultValue={filterByRole} onChange={value => setfilterByRole(value)}>
                <Option value="">{t("project.users.allRoles")}</Option>
                <Option value="admin">{t("common.admin")}</Option>
                <Option value="member">{t("common.member")}</Option>
              </Select>
            </Space>
        </Space>
        {/* <Space direction='vertical' style={{ width: "100%" }}> */}
          {/* {!!selectedRecords.length &&
            <Space direction="horizontal">
              <Text strong type="secondary">{selectedRecords?.length} selected</Text>
              <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                <StyledButton icon={<EditOutlined />} style={{ marginLeft: 24 }}>Change role</StyledButton>
              </Dropdown>
              <StyledButton icon={<UserDeleteOutlined />} style={{ marginLeft: 12 }} onClick={() => setDeleteModalVisible(true)}>Remove users</StyledButton>
            </Space>
          } */}
          <Table
            dataSource={usersList.map((ele: any) => ({ ...ele, key: ele.user_id }))}
            columns={userColumns}
            pagination={false}
            // rowSelection={{
            //   type: "checkbox", selectedRowKeys: selectedRecords.map((res: any) => res.user_id),
            //   onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            //     setSelectedRecords(selectedRows)
            //   },
            // }}
            locale={{
              triggerDesc: t('file.clickToSortZA'),
              triggerAsc: t('file.clickToSortAZ'),
              cancelSort: t('file.clickToCancelSort')
            }}
            scroll={{ x: 450 }}
            bordered={false}
            className="teams-users-table"
          />
        {/* </Space> */}
        {/* <Modal visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null} width={800}>
          <Space>
            <Space direction="vertical">
              <Text strong>{record?.user_name}</Text>
              <Text type="secondary">{record?.user_email}</Text>
            </Space>
          </Space>
          <Tabs defaultActiveKey="1" onChange={setTab} style={{ border: "none" }}>
            <TabPane key={"projects"} tab={t('projects.header.title')}></TabPane>
          </Tabs>
          {getComponentsFromTabs()}
        </Modal> */}
        </Space>
      </StyledCard>

      {/* <Modal title={<Text strong>{`Remove ${!!Object.keys(record)?.length ? 'user' : "users"} from the organisation?`}</Text>} visible={deleteModalVsisble} onCancel={() => { setRecord({}); setDeleteModalVisible(false) }} width={450} okText={"Remove"} okButtonProps={{ style: { background: "#FF4D4F", borderRadius: "4px" } }} cancelButtonProps={{ type: "text" }}>
        {!!Object.keys(record)?.length ?
          <Space direction="vertical">
            <Text strong>Are you sure you want to remove {getName(record?.user_id)} from the organisation?</Text>
            <Text type="secondary">Doing so will also remove {getName(record?.user_id)} from all projects that the user is a member of. Once removed, {getName(record?.user_id)} will lose access to everything in Polymerize Connect.</Text>
            <Text type="secondary">You will need to invite {getName(record?.user_id)} to Polymerize Connect again in order for {getName(record?.user_id)} to access the data in future.</Text>
          </Space>
          :
          <Space direction="vertical">
            <Text strong>Are you sure you want to remove the selected user(s) from the organisation?</Text>
            <Space direction="vertical">
              {
                selectedRecords?.map((member): any => (
                  <>
                    <Space>
                      <Avatar src={member?.image_url} alt={member?.user_name} />
                      <Space>
                        <Text strong style={{ width: "100%", display: "flex" }}>
                          {member?.user_name[0].toUpperCase() + member?.user_name.slice(1)}
                        </Text>
                        <Text type='secondary'>
                          {member?.user_email}
                        </Text>
                      </Space>
                    </Space>
                  </>
                ))
              }
            </Space>
            <Text type="secondary">Doing so will remove them from all projects they are a member of. Once removed, they will lose access to everything in Polymerize Connect.</Text>
            <Text type="secondary">You will need to invite each user to Polymerize Connect again in order for them to access the data in future.</Text>
          </Space>
        }
      </Modal> */}

      {/* <Modal title={<Text strong>{"Change user role?"}</Text>} visible={userRoleModalVisible} onCancel={() => { setRecord({}); setUserRoleModalVisible(false) }} width={450} okText={"Remove"} okButtonProps={{ style: { background: "#FF4D4F", borderRadius: "4px" } }} cancelButtonProps={{ type: "text" }}>
        <Space direction="vertical">
          <Text strong>Are you sure you want to change the user role of the selected user(s) to {selectedUserRole === "admin" ? "Admin" : "Member"}?</Text>
          <Space direction="vertical">
            {
              selectedRecords?.map((member): any => (
                <>
                  <Space>
                    <Avatar src={member?.image_url} alt={member?.user_name} />
                    <Space>
                      <Text strong style={{ width: "100%", display: "flex" }}>
                        {member?.user_name[0].toUpperCase() + member?.user_name.slice(1)}
                      </Text>
                      <Text type='secondary'>
                        {member?.user_email}
                      </Text>
                    </Space>
                  </Space>
                </>
              ))
            }
          </Space>
          <Text type="secondary">Doing so will automatically make them {selectedUserRole === "admin" ? "Admin" : "Member"} for all projects.</Text>
        </Space>
      </Modal> */}

      <WithdrawInvitationModal withdrawInvitationInfo={withdrawInvitationInfo} setWithdrawInvitationInfo={setWithdrawInvitationInfo} />
      <RevokeAccessModal revokeModalInfo={revokeModalInfo} setRevokeModalInfo={setRevokeModalInfo} />
    </Space>
  )
}
