import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";

export const useData = () => {
  const [ingredientsParameterList, setIngredientsParameterList] = useState<{ [key: number]: string[] }>({ 1: [] });
  const [processingParameterList, setProcessingParameterList] = useState<{ [key: number]: string[] }>({ 1: [] });
  const [ingredientsDataList, setIngredientsDataList] = useState<{ [key: number]: any[] }>({ 1: [{ "": "" }] });
  const [processingDataList, setProcessingDataList] = useState<{ [key: number]: any[] }>({ 1: [{ "": "" }] });
  const [ingredientsInputs, setIngredientsInputs] = useState<any>({ 1: {} });
  const [ingredientsCategories, setIngredientsCategories] = useState<any>({ 1: {} });
  const [processingInputs, setProcessingInputs] = useState<any>({ 1: {} });
  const [selectedCharacterization, setSelectedCharacterization] = useState<{ [key: string]: any }>({ 1: { inverse: {}, forward: {} } })
  const configs = useSelector((state: StoreState) => state.configs.features);

  const [trialDisplayNameList, setTrialDisplayNameList] = useState<{ [key: number]: any[] }>({
    1: [{
      label: `Stage 1: Trial 1`,
      value: "FR_Trial_1"
    }]
  })

  const addRow = useCallback((inputType: string, stageNumber: number) => {
    if (inputType === "ingredients") {
      setIngredientsParameterList((state) => {
        return { ...state, [stageNumber]: [...(state?.[stageNumber] || []), ""] }
      });
    } else if (inputType === "processing") {
      setProcessingParameterList((state) => {
        return { ...state, [stageNumber]: [...(state?.[stageNumber] || []), ""] }
      });
    }
  }, []);
  const removeRow = useCallback((parameterIndex: number, inputType: string, stage: number) => {
    if (inputType === "ingredients") {
      setIngredientsParameterList((state) => {
        const filteredIngredient = state?.[stage]?.filter((item, index) => index !== parameterIndex) ?? []
        return {
          ...state,
          [stage]: filteredIngredient
        }
      }
      );
    } else if (inputType === "processing") {
      setProcessingParameterList((state) => {
        const filteredProcessing = state?.[stage]?.filter((item, index) => index !== parameterIndex) ?? []
        return {
          ...state,
          [stage]: filteredProcessing
        }
      }
      );
    }
  }, []);

  const addTrials = useCallback((stage: number, stageName: string) => {
    setIngredientsDataList((prevState: any) => {
      return {
        ...prevState,
        [stage]: [
          ...prevState[stage],
          ingredientsParameterList?.[stage]?.reduce((acc: any, parameter) => {
            let { min, max } = ingredientsInputs[stage][parameter] || { min: "", max: "" };
            return { ...acc, [parameter]: (min === max && !configs?.no_autofill) ? max : "" };
          }, {}),
        ]
      }
    });

    setProcessingDataList((prevState: any) => {
      return {
        ...prevState,
        [stage]: [
          ...prevState[stage],
          processingParameterList?.[stage]?.reduce((acc: any, parameter) => {
            let { min, max } = processingInputs?.[parameter] || {
              min: "",
              max: "",
            };
            return { ...acc, [parameter]: (min === max && !configs?.no_autofill) ? max : "" };
          }, {}),
        ]
      }
    });
    setTrialDisplayNameList((prev) => {
      return {
        ...prev, [stage]: [...prev[stage], {
          label: `${stageName ?? `Stage ${stage}`}: Trial ${prev[stage]?.length + 1}`,
          value: `FR_Trial_${prev[stage]?.length + 1}`
        }]
      }
    })
    setTimeout(() => {
      const ingredients = document.getElementsByClassName(
        "datahseet-header-ingredients"
      );
      const processings = document.getElementsByClassName(
        "datahseet-header-processing"
      );

      const lastProcessing = processings[processings.length - 1];
      const lastIngredient = ingredients[ingredients.length - 1];

      lastProcessing?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
      lastIngredient?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, 5);
  }, [configs?.no_autofill, ingredientsInputs, ingredientsParameterList, processingInputs, processingParameterList]);

  const deleteTrial = useCallback((index: number, stage: number) => {
    setIngredientsDataList((prevState: any) => {
      const prevTrials = { ...prevState }
      prevTrials[stage]?.splice(index, 1);
      return prevTrials;
    });
    setProcessingDataList((prevState: any) => {
      const prevTrials = { ...prevState }
      prevTrials[stage]?.splice(index, 1);
      return prevTrials;
    });
    setTrialDisplayNameList((prev) => {
      return { ...prev, [stage]: prev[stage]?.filter((item, idx) => index !== idx) }
    })
  }, []);

  return {
    ingredientsParameterList,
    processingParameterList,
    ingredientsDataList,
    processingDataList,
    ingredientsInputs,
    ingredientsCategories,
    processingInputs,
    addRow,
    removeRow,
    addTrials,
    deleteTrial,
    setIngredientsParameterList,
    setProcessingParameterList,
    setIngredientsDataList,
    setProcessingDataList,
    setIngredientsInputs,
    setIngredientsCategories,
    setProcessingInputs,
    selectedCharacterization,
    setSelectedCharacterization,
    trialDisplayNameList,
    setTrialDisplayNameList
  };
};
