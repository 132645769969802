import { Radio, Tooltip, Typography } from 'antd'
import React, { useMemo } from 'react'
import { Table } from "antd"
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { DisplayNames } from 'src/typings'
import { getFilters, getSorter } from '../../ForwardModel/form/payload-form/utils'
import useTranslate from 'src/utils/useTranslate'
import { useValue } from 'src/utils/useValue'

const { Text } = Typography

export const ZeonCharacterizationVariationsTable = ({
    loading,
    data,
    setSelectedVariation,
    selectedVariation
}: any) => {
    const displayNames = useSelector(
        (state: StoreState) => state.displayNames.data
    ) as DisplayNames

    const [t] = useTranslate()
    const { getValue } = useValue()
    const columns = useMemo(() => {
        const cols = [
            ...new Set(
                data.reduce((acc: any, curr: any) => {
                    return [...acc, ...Object.keys(curr)]
                }, [])
            ),
        ]

        const tableCols = cols.filter((key) => (key !== "characterization_set_id")).map((col: any, i) => {
            const filters = getFilters(data, col)
            const sorter = getSorter(data, col)

            return {
                title: displayNames.characterizations?.[col].name ?? col,
                dataIndex: col,
                key: i,
                filters,
                width: 200,
                onFilter: (value: string, record: any) => {
                    return (record[col]?.value ?? "-") === value
                },
                sorter,
                render: (param: any) => {
                    return <Tooltip title={
                        <>
                            {<div><b>{t('common.category')}:</b> {param?.category ?? "-"}</div>}
                            {<div><b>{t('common.unit')}:</b> {param?.unit ?? "-"}</div>}
                        </>
                    }>
                        <Text style={{ wordBreak: "break-word" }}>{getValue(param?.value) ?? "-"}</Text>
                    </Tooltip>
                }
            }
        }) as any

        return [
            {
                dataIndex: "data",
                key: "data",
                width: 50,
                render: (col: any) => {
                    return (
                        <Radio
                            value={col}
                            checked={selectedVariation.id === col.id}
                            onChange={(e) => {
                                setSelectedVariation(e.target.value);
                            }}
                        />
                    )
                },
            },
            ...tableCols,
        ] as any
    }, [data, displayNames.characterizations, setSelectedVariation, selectedVariation, t, getValue])

    const dataSource = useMemo(() => {
        return data.map((item: any, i: any) => {
            const response = Object.keys(item || {}).filter((key) => (key !== "characterization_set_id")).reduce((acc: any, curr: any) => {
                return { ...acc, [curr]: item[curr] }
            }, {})
            return {
                data: {
                    id: i,
                    characterization_set_id: item.characterization_set_id,
                },
                ...response,
            }
        }) as any[]
    }, [data])


    if (data.length < 1) return null

    return <Table loading={loading} dataSource={dataSource} columns={columns} style={{ width: "100%" }} />
}
