import { Divider, Flex, Statistic } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { antdTheme } from "src/constants";
import { StoreState } from "src/store/configureStore";
import { useMemberName } from "src/utils/useMemberName";
import useTranslate from "src/utils/useTranslate";

const SmileResultHeader = () => {
    const [t] = useTranslate();
    const { getName } = useMemberName();
    const smileSummaryResult = useSelector((state: StoreState) => state.smileSummary.smileSummaryResult);

    const resultDetails = useMemo(() => {
        return smileSummaryResult?.experiment_details || {};
    }, [smileSummaryResult]);

    return (
        <Flex vertical gap={12}>
            {resultDetails?.description && (<Statistic title={t("common.description")} value={resultDetails?.description && resultDetails.description.join(" ")} valueStyle={{ fontSize: antdTheme.fontSizeHeading4 }} />)}
            <Flex gap={20}>
                <Statistic title={t("common.createdBy")} value={resultDetails?.user_id && getName(resultDetails?.user_id)} valueStyle={{ fontSize: antdTheme.fontSizeHeading4 }} />
                <Statistic title={t("common.type")} value={resultDetails?.upload_file ? "File Upload" : "Work Order"} valueStyle={{ fontSize: antdTheme.fontSizeHeading4 }} />
                <Statistic title={t("common.createdOn")} value={new Date(resultDetails?.created).toLocaleString()} valueStyle={{ fontSize: antdTheme.fontSizeHeading4 }} />
            </Flex>
            <Divider />
        </Flex>

    )
}

export default SmileResultHeader;