import { sendGet, sendPost } from "src/utils/sendApi";

export const SettingsApi = sendPost("v1/data/_settings");

export const SettingsUpdateApi = sendPost(
  "v1/data/_update_settings"
);

export const getBillingDetailsApi = sendPost("v1/data/payment_details");

export const updatePaymentMethodApi = sendPost("v1/data/add_payment_method");

export const updateBillingDetailsApi = sendPost("v1/data/update_billing_details");

export const updateSubscriptionApi = sendPost("v1/data/update_subscription");

export const addLabsLocationApi = sendPost("v1/data/lab-location");

export const fetchLabsLocationApi = sendGet("v1/data/lab-location");

export const editLabsLocationApi = (labLocationId: string) => sendPost(`v1/data/lab-location/${labLocationId}`);

export const updateNumericalCodeApi = sendPost("v1/data/_update_numeric_code")