import { useDispatch } from "react-redux";
import { Space } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import useTranslate from "src/utils/useTranslate";
import { WorkOrdersPage } from "./WorkOrdersPage";
import { StyledButton } from "src/styled_components/StyledButton";

import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
export const WorkOrders = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [t] = useTranslate();

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <StyledPageHeader
        ghost={false}
        title={t("common.workOrders")}
        extra={
          <StyledButton
            type="primary"
            icon={<FileAddOutlined />}
            size="large"
            onClick={() => {
              dispatch(resetWorkOrder());
              push("/work-orders/create-workorder");
            }}
          >
            {t("common.createWorkOrder")}
          </StyledButton>
        }
      />
      <WorkOrdersPage />
    </Space>
  );
};
