import { createActions } from "redux-actions";

export enum LoginViaMagicLinkActionTypes {
    REQUEST = "LOGIN_VIA_MAGIC_LINK_REQUEST",
    SUCCESS = "LOGIN_VIA_MAGIC_LINK_SUCCESS",
    FAILURE = "LOGIN_VIA_MAGIC_LINK_FAILURE",
    CLEANUP = "LOGIN_VIA_MAGIC_LINK_CLEANUP"
}

export enum AuthMagicLinkActionTypes {
    REQUEST = "AUTH_MAGIC_LINK_REQUEST",
    SUCCESS = "AUTH_MAGIC_LINK_SUCCESS",
    FAILURE = "AUTH_MAGIC_LINK_FAILURE",
    CLEANUP = "AUTH_MAGIC_LINK_CLEANUP"
}

export const {
    loginViaMagicLinkRequest,
    loginViaMagicLinkSuccess,
    loginViaMagicLinkFailure,
    loginViaMagicLinkCleanup,
    authMagicLinkRequest,
    authMagicLinkSuccess,
    authMagicLinkFailure,
    authMagicLinkCleanup
} = createActions({
    [LoginViaMagicLinkActionTypes.REQUEST]: (payload) => payload,
    [LoginViaMagicLinkActionTypes.SUCCESS]: (data: any[]) => data,
    [LoginViaMagicLinkActionTypes.FAILURE]: (error: string) => error,
    [LoginViaMagicLinkActionTypes.CLEANUP]: () => { },
    [AuthMagicLinkActionTypes.REQUEST]: (payload) => payload,
    [AuthMagicLinkActionTypes.SUCCESS]: (data) => data,
    [AuthMagicLinkActionTypes.FAILURE]: (error) => error,
    [AuthMagicLinkActionTypes.CLEANUP]: () => { },
})