
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Input, Modal, Row, Space, Spin, Typography } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { createTemplateRequest } from 'src/store/actions/templates'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar'

const { Text } = Typography


export const SaveTemplateModal = ({ saveTemplateModalVisible, setSaveTemplateModalVisible, customFields, type }: any) => {
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()

    const dispatch = useDispatch()
    const { listTemplate: { status } } = useSelector((state: StoreState) => state.templates)

    const templateSave = (values: any) => {
        dispatch(createTemplateRequest({ ...values, fields: customFields, template_type: type }))
    }

    useEffect(() => {
        if (status === AsyncStates.SUCCESS) {
            setSaveTemplateModalVisible(false)
        }
    }, [status, setSaveTemplateModalVisible])

    return (
        <Modal
            visible={saveTemplateModalVisible}
            onCancel={() => setSaveTemplateModalVisible(false)}
            title={t("project.saveNewTemplate")} footer={null}
        >
            <Spin spinning={status === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Space direction="vertical" style={{ width: "100%" }} size="large">
                    <Text type="secondary">
                        {t("project.templateDisclamer")}
                    </Text>
                    <Form layout="vertical" onFinish={templateSave} requiredMark={false}>
                        <Form.Item label={t("project.templateName")} name="name" rules={[{ required: true }]} required tooltip={requiredFieldStar}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={t("project.templateDescription")} name="description" rules={[{ required: true }]} required tooltip={requiredFieldStar}>
                            <Input.TextArea rows={5} />
                        </Form.Item>
                        <Row justify="end">
                            <Space>
                                <StyledButton onClick={() => setSaveTemplateModalVisible(false)}>{t('common.cancel')}</StyledButton>
                                <StyledButton htmlType="submit" type="primary">{t('project.templateSaveAsNew')}</StyledButton>
                            </Space>
                        </Row>
                    </Form>
                </Space>
            </Spin>
        </Modal>
    )
}
