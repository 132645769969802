import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';
import { StyledButton } from 'src/styled_components/StyledButton';
import { EditOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Card, Collapse, Empty, Input, Popconfirm, Select, Spin, Table, Tabs, Drawer, Row, Space, Typography } from "antd"
import Meta from "antd/lib/card/Meta"
import { AsyncStates } from "src/constants"
import { checkVariationUsedRequest, deleteMethodRequest } from "src/store/actions/characterizationMethods"
import useTranslate from "src/utils/useTranslate"
import { useLibrarySearchNFilter } from '../utils';
import { UpdateCharacterizationModal } from '../Characterizations/modals/UpdateCharacterization';
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon';


const { TabPane } = Tabs
const { Text, Title } = Typography


export default function LibraryDrawer({ viewLibraryDrawer, setViewLibraryDrawer, setModalVisible, disabled, methodType, methodList }: any) {
    const dispatch = useDispatch()
    const [t] = useTranslate()


    const { data, query, setQuery, categories, filteredCategories, setFilteredCategories } = useLibrarySearchNFilter(methodList)
    const displayNames = useSelector((state: StoreState) => state.displayNames?.data)
    const { deleteCharacterizationMethodsStatus, methodListStatus } = useSelector((state: StoreState) => state.characterizationMethods)
    const [showEditCharacterizationModal, setShowEditCharacterizationModal] = useState(false)
    const { isVariationUsed, updateMethodStatus } = useSelector((state: any) => state.characterizationMethods)
    const [updateLibraryElement, setUpdatedLibraryElement] = useState({})

    const filterSection = (
        <Space>
            <Input
                placeholder='Search'
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                prefix={<SearchOutlined />}
            />
            <Select
                mode="multiple"
                allowClear
                placeholder="Filter by category"
                style={{ width: "250px" }}
                value={filteredCategories}
                onChange={(cats) => setFilteredCategories(cats)}
            >
                {categories.map((category: any) => {
                    return <Select.Option value={category} key={category}>
                        {category}
                    </Select.Option>
                })}
            </Select>
        </Space>
    )


    return (
        <>
            <Drawer
                title={
                    <Row justify="space-between">
                            <Space direction="horizontal">
                                <Title level={4} style={{marginBottom:0}} >{"Library"}</Title>
                                <Text type='secondary' style={{ width: "100%", color: "#000000", opacity: 0.2 }}>
                                    {"The library contains your saved methods"}
                                </Text>
                            </Space>
                        {(!disabled) && <StyledButton style={{ marginRight: 35 }} icon={<PlusOutlined />} onClick={() => { setViewLibraryDrawer(false); setModalVisible(true) }}>{t("inventory.CreateMethod")}</StyledButton>}

                    </Row>}
                width={"75%"}
                open={viewLibraryDrawer}
                onClose={() => setViewLibraryDrawer(false)}
            >
                <Space direction="vertical" size="large" style={{ width: "100%", overflow: "auto" }}>
                    <Tabs defaultActiveKey="1" tabBarExtraContent={filterSection} activeKey={"1"}>
                        <TabPane tab={"Methods"} key="1">
                            <Spin spinning={deleteCharacterizationMethodsStatus === AsyncStates.LOADING || methodListStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                                <Space direction='vertical' style={{ width: '100%' }}>
                                    {data.length > 0 ?
                                        data.map((method: any) => (
                                            <Card key={method.characterization_method_id}>
                                                <Row justify="space-between">
                                                    <Meta title={method.name} description={method.description} />
                                                    <Space style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <StyledButton icon={<EditOutlined />} onClick={() => {
                                                            setShowEditCharacterizationModal(true);
                                                            dispatch(checkVariationUsedRequest({
                                                                method_type: methodType,
                                                                variation_ids: method.variations.map((variation: any) => variation.variation_id)
                                                            }))
                                                            setUpdatedLibraryElement(method)
                                                        }}>{t("common.edit")}</StyledButton>
                                                        <Popconfirm placement="left" title={isVariationUsed ? t("common.deletionRestricted") : "Delete selected method ?"}
                                                            onConfirm={() => {
                                                                dispatch(deleteMethodRequest({
                                                                    method_type: methodType,
                                                                    characterization_method_id: [method.characterization_method_id]
                                                                }))
                                                            }}
                                                            okButtonProps={{ disabled: isVariationUsed }}
                                                        >
                                                            <StyledButton icon={<StyledDeleteIcon/>} danger
                                                                loading={updateMethodStatus === AsyncStates.LOADING}
                                                                onClick={() => {
                                                                    dispatch(checkVariationUsedRequest({
                                                                        method_type: methodType,
                                                                        variation_ids: method.variations.map((variation: any) => variation.variation_id)
                                                                    }))
                                                                }}
                                                            >{t("common.delete")}</StyledButton>
                                                        </Popconfirm>
                                                    </Space>
                                                </Row>
                                                <br />
                                                <Collapse>
                                                    <Collapse.Panel header="Parameters" key="1">
                                                        <Space direction='vertical' style={{ width: '100%' }}>
                                                            {method.variations.map((variation: any, index: number) => (
                                                                <Space direction='vertical' style={{ width: '100%' }} key={variation.variation_id}>
                                                                    <Space style={{ display: "flex", justifyContent: "space-between" }}>
                                                                        <Meta title={variation.name} description={variation.description} />
                                                                    </Space>
                                                                    <br />
                                                                    {/* <Meta title={variation.name} description={variation.description} /><br /> */}
                                                                    <Card key={"data" + index} title={"Characterizations Parameters"} >
                                                                        <Table
                                                                            size='small'
                                                                            pagination={false}
                                                                            bordered
                                                                            columns={[
                                                                                {
                                                                                    title: 'Parameters', dataIndex: 'parameter',
                                                                                    render: (text: any) => {
                                                                                        return displayNames?.characterizations?.[text]?.name ?? text
                                                                                    }
                                                                                },
                                                                                {
                                                                                    title: 'Units', dataIndex: 'unit'
                                                                                },
                                                                                {
                                                                                    title: 'Value', dataIndex: 'value'
                                                                                }
                                                                            ]}
                                                                            dataSource={variation.data}
                                                                        />
                                                                    </Card>
                                                                    {!!variation?.properties?.length &&
                                                                        <Card key={"properties" + index} title={"Properties Parameters"}>
                                                                            <Table
                                                                                size='small'
                                                                                pagination={false}
                                                                                bordered
                                                                                columns={[
                                                                                    {
                                                                                        title: 'Parameters', dataIndex: 'parameter',
                                                                                        render: (text: any) => {
                                                                                            return displayNames?.properties?.[text]?.name ?? text
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        title: 'Units', dataIndex: 'unit'
                                                                                    },
                                                                                ]}
                                                                                dataSource={variation?.properties}
                                                                            />
                                                                        </Card>
                                                                    }
                                                                </Space>
                                                            ))}
                                                        </Space>
                                                    </Collapse.Panel>
                                                </Collapse>
                                            </Card>
                                        ))
                                        : <Card style={{ width: "100%" }}><Empty description="No results found">{"It seems we can't find any results based on your search."}</Empty></Card>
                                    }
                                </Space>
                            </Spin>
                        </TabPane>
                    </Tabs>
                </Space>
            </Drawer>
            {showEditCharacterizationModal && <UpdateCharacterizationModal updateLibraryElement={updateLibraryElement} modalVisible={showEditCharacterizationModal} setModalVisible={setShowEditCharacterizationModal} setUpdatedLibraryElement={setUpdatedLibraryElement} />}
        </>
    )
}
