import { Dropdown, MenuProps, Space, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { InventoryProperty } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { InventoryPropertyModal } from "./InventoryPropertyModal";
import { InventoryMethodsTable } from "./InventoryMethodsTable";
import {
  createInventoryMethodClear,
  createMethodParameterClear,
  deleteInventoryPropertyClear,
  deleteInventoryPropertyRequest,
  updateInventoryMethodClear,
  updateMethodParameterClear,
} from "src/store/actions/inventoryV2";
import { AsyncStates } from "src/constants";
import DeleteConfirmationModal from "src/components/DeleteConfirmationModal";
import { useValue } from "src/utils/useValue";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";

const { Text } = Typography;

type InventoryPropertiesTableProps = {
  inventory_id?: string,
  ingredient: any,
  from: string,
  inventoryData: any
}

export const InventoryPropertiesTable = ({ inventory_id, ingredient, from, inventoryData }: InventoryPropertiesTableProps) => {
  const [t] = useTranslate();

  const dispatch = useDispatch();
  const {
    deleteProperty: deletePropertyResponse,
    createMethod: createMethodResponse,
    updateMethod: updateMethodResponse,
    createMethodParameter: createMethodParameterResponse,
    updateMethodParameter: updateMethodParameterResponse,
  } = useSelector((state: StoreState) => state.inventoryV2);

  const { getValue } = useValue()
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [propertyModalOpen, setPropertyModalOpen] = useState<boolean>(false);

  const [selectedProperty, setSelectedProperty] = useState<
    InventoryProperty | undefined
  >(undefined);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const columns: ColumnsType<InventoryProperty> = [
    {
      title: t("common.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("common.category"),
      dataIndex: "category",
      key: "category",
    },
    {
      title: t("common.unit"),
      dataIndex: "unit",
      key: "unit",
    },
    // {
    //   title: "Is Range",
    //   dataIndex: "has_range",
    //   key: "has_range",
    //   render: (text, record) => <>{record.has_range ? "Yes" : "No"}</>,
    // },
    ...(from !== 'family' ? [{
      title: t("common.value"),
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any) => <>{record?.operator ? record?.operator : ""} {getValue(record.value) ?? getValue(record.value_str)}</>,
    },
    {
      title: t("common.maxValue"),
      dataIndex: "value_max",
      key: "value_max",
      render: (text: any, record: any) => <>{getValue(text)}</>,
    }] : []),
    {
      title: t("common.methodName"),
      dataIndex: "method_name",
      key: "method_name",
    }
  ];

  if (!inventory_id) columns.push({
    title: "Actions",
    key: "actions",
    fixed: "right" as any,
    align: "center" as any,
    width: 200,
    render: (text, record) => (
      <Dropdown menu={menuProps(record)} placement="bottomRight">
        <StyledButton size="small" icon={<MoreOutlined />} />
      </Dropdown>
    ),
  })

  const menuItems: MenuProps["items"] = [
    {
      label: "Edit",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete",
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const handleMenuClick = (e: any, record: InventoryProperty) => {
    switch (e.key) {
      case "edit":
        setMode("edit");
        setSelectedProperty(record);
        setPropertyModalOpen(true);
        break;
      case "delete":
        setSelectedProperty(record);
        setDeleteModalVisible(true);
        break;
    }
  };

  const menuProps = (record: any) => {
    return {
      items: menuItems,
      onClick: (e: any) => handleMenuClick(e, record),
    };
  };

  useEffect(() => {
    if (deletePropertyResponse.status === AsyncStates.SUCCESS) {
      // message.success(`${from === 'family' ? "Family": "Ingredient"} property deleted!`);
      dispatch(deleteInventoryPropertyClear());
      setDeleteModalVisible(false);
      setSelectedProperty(undefined);
    }
  }, [
    deletePropertyResponse,
    dispatch,
    ingredient,
    selectedProperty?.inventory_property_id,
    from
  ]);

  useEffect(() => {
    if (createMethodResponse.status === AsyncStates.SUCCESS) {
      // message.success(`Method created`);
      dispatch(createInventoryMethodClear());
    }
  }, [createMethodResponse, dispatch]);

  useEffect(() => {
    if (updateMethodResponse.status === AsyncStates.SUCCESS) {
      dispatch(updateInventoryMethodClear());
    }
  }, [updateMethodResponse, dispatch]);

  useEffect(() => {
    if (createMethodParameterResponse.status === AsyncStates.SUCCESS) {
      dispatch(createMethodParameterClear());
    }
  }, [createMethodParameterResponse, dispatch]);

  useEffect(() => {
    if (updateMethodParameterResponse.status === AsyncStates.SUCCESS) {
      dispatch(updateMethodParameterClear());
    }
  }, [updateMethodParameterResponse, dispatch, ingredient]);

  return (
    <>
      <Table
        caption={
          <Space
            style={{
              width: "100%",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <Text style={{ fontWeight: "bold" }}>{t("common.properties")}</Text>
            {!inventory_id && <StyledButton
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setMode("create");
                setPropertyModalOpen(true);
              }}
            >
              {t("common.properties")}
            </StyledButton>}
          </Space>
        }
        size="small"
        columns={columns}
        dataSource={[...(ingredient?.properties ?? [])]}
        rowKey={(record) => from !== "family" ? record.inventory_property_id : record.property_id}
        expandable={{
          expandedRowRender: (record) => (
            <InventoryMethodsTable property={record as InventoryProperty} inventory_id={inventory_id} from={from} ingredient={ingredient} />
          ),
          rowExpandable: (record) => !record.value,
        }}
        pagination={false}
      />
      <InventoryPropertyModal
        open={propertyModalOpen}
        onClose={() => {
          setPropertyModalOpen(false);
          setSelectedProperty(undefined);
        }}
        mode={mode}
        property={selectedProperty}
        from={from}
        ingredient={ingredient}
        inventoryDataList={inventoryData}
      />
      <DeleteConfirmationModal
        title={t("inventory.Areyousure?")}
        description={
          t("inventory.DeletingaPropertyispermanent.Allcorrespondingformulationswillbeaffected.Confirmdelete?")
        }
        onCancel={() => setDeleteModalVisible(false)}
        visible={deleteModalVisible}
        onConfirm={() => {
          dispatch(
            deleteInventoryPropertyRequest({
              ...(from === 'family' ? { family_id: selectedProperty?.family_id } : { inventory_id: selectedProperty?.inventory_id }),
              ...(from === 'family' ? { property_id: selectedProperty?.property_id } : { property_id: selectedProperty?.inventory_property_id }),
              from
            })
          );
        }}
      />
    </>
  );
};
