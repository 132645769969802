import { useEffect, useState, useRef } from 'react'
import { Drawer, List, Typography, Form, Mentions, Row, Popconfirm, Col } from "antd"
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { antdTheme, AsyncStates } from 'src/constants'
import { modelCommentListClear, modelCommentListRequest, deleteModelCommentRequest, editModelCommentRequest } from 'src/store/actions/modelAnalysis'
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import useTranslate from 'src/utils/useTranslate'
import { geekblue, red } from '@ant-design/colors'
import dayjs from 'dayjs'
import { Comment } from '@ant-design/compatible'
import relativeTime from 'dayjs/plugin/relativeTime'
import { nanoid } from 'nanoid'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton'
dayjs.extend(relativeTime)

const { Text } = Typography


export function ModelComments({ commentsDrawerVisible, setCommentsDrawerVisible, modelComments, setModelComments, socket }: any) {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const { modelCommentListStatus, modelData, createModelCommentStatus, deleteModelCommentStatus, editModelCommentStatus } = useSelector((state: StoreState) => state.modelAnalysis)
  const teamsData = useSelector((state: StoreState) => state.teams.data)
  const { user_id } = useSelector((state: StoreState) => state.login.loginResponse)
  const [editCommentKey, setEditCommentKey] = useState<string>("")
  const [editCommentData, setEditCommentData] = useState<any>({ comment_id: "", comment: "" })
  const [editForm] = Form.useForm()
  const [createForm] = Form.useForm()
  const ref: any = useRef()

  useEffect(() => {
    return () => {
      dispatch(modelCommentListClear())
    }
  }, [dispatch])

  useEffect(() => {
    if (commentsDrawerVisible) {
      ref.current?.scrollTo(0, ref.current.scrollHeight)
    }
  }, [commentsDrawerVisible])

  useEffect(() => {
    if (editModelCommentStatus === AsyncStates.SUCCESS) {
      setModelComments((prevState: any) => {
        const newObj = { ...prevState }
        newObj?.comments_data?.forEach((res: any) => {
          if (res.comment_id === editCommentData.comment_id) {
            res.comment = editCommentData.comment
            res.updated = dayjs()
          }
        })
        return newObj
      })
      editForm.resetFields()
      setEditCommentKey("")
    }
  }, [editModelCommentStatus, editCommentData, editForm, setModelComments])

  const loadMoreComments = () => {
    dispatch(modelCommentListRequest(({ model_version: modelData.version, previous_id: modelComments.previous_id })))
  }

  const createNewComment = (value: any) => {
    const { comment } = value
    const commentId = `PlCom_${nanoid(10)}`;
    const tags: string[] = []
    teamsData.forEach((res: any) => {
      if (comment.includes(res.user_name)) tags.push(res.user_id)
    })
    // dispatch(createModelCommentRequest({
    //   model_version: modelData.version,
    //   comment,
    //   tags,
    //   comment_id: commentId
    // }))
    socket?.emit("create_model_comment", { comment, tags, model_version: modelData.version, comment_id: commentId })
    createForm.resetFields()
    ref.current?.scrollTo(0, ref.current.scrollHeight)
  }

  const deleteComment = (record: any) => {
    dispatch(deleteModelCommentRequest({ comment_id: record.comment_id, model_version: modelData.version }))
    ref.current?.scrollTo(0, ref.current.scrollHeight)
  }

  const editComment = (value: any, comment_id: string) => {
    const { comment } = value
    const tags: string[] = []
    teamsData.forEach((res: any) => {
      if (comment.includes(res.user_name)) tags.push(res.user_id)
    })
    setEditCommentData({ comment_id, comment })
    dispatch(editModelCommentRequest({
      comment_id,
      comment,
      tags
    }))
  }

  const Editor = ({ edit = false, value }: any) => {
    const requiredFieldStar = useRequiredFieldStar()

    if (edit) {
      editForm.setFieldsValue({ comment: value.comment })
    }

    return (
      <Form onFinish={edit ? (e) => editComment(e, value.comment_id) : createNewComment} form={edit ? editForm : createForm} requiredMark={false} >
        <Form.Item name="comment" rules={[{ required: true }]}
          required
          tooltip={requiredFieldStar}
        >
          <Mentions autoSize={{ minRows: 4 }} placeholder={t("commentsSection.leaveAComment")} >
            {teamsData.map((member: any) => <Mentions.Option value={member.user_name} key={member.user_name}>{member.user_name}</Mentions.Option>)}
          </Mentions>
        </Form.Item>
        {edit ?
          <Form.Item>
            <Row justify="end" gutter={12}>
              <Col>
                <StyledButton onClick={() => setEditCommentKey("")} >
                  {t("common.cancel")}
                </StyledButton>
              </Col>
              <Col>
                <StyledButton htmlType="submit" loading={editModelCommentStatus === AsyncStates.LOADING} type="primary">
                  {t("common.saveChanges")}
                </StyledButton>
              </Col>
            </Row>
          </Form.Item>
          :
          <Form.Item>
            <Row justify="end">
              <StyledButton htmlType="submit"
                disabled={!!editCommentKey}
                loading={createModelCommentStatus === AsyncStates.LOADING} type="primary">
                {t("common.comment")}
              </StyledButton>
            </Row>
          </Form.Item>}
      </Form>
    )
  }


  return (
    <Drawer
      title={`${t("common.comments")} (${modelComments?.total_records || "0"})`}
      open={commentsDrawerVisible}
      onClose={() => setCommentsDrawerVisible(false)} width={400}>
      {modelComments?.comments_data?.length < modelComments?.total_records && (
        <StyledButton style={{ width: "100%", textAlign: "center", background: "inherit", fontSize: antdTheme.fontSizeHeading5, marginBottom: 5 }}
          onClick={loadMoreComments}
          type="text" >
          <Text type="secondary">{t("modelComments.loadEarlierComments")}</Text>
        </StyledButton>)}
      <div ref={ref} style={{ height: "70%", overflowY: "auto", overflowX: "clip" }}>
        <List loading={{
          spinning: modelCommentListStatus === AsyncStates.LOADING || deleteModelCommentStatus === AsyncStates.LOADING,
          indicator: <LoadingOutlined />
        }}
          className="comment-list"
          itemLayout="horizontal"
          dataSource={modelComments.comments_data}
          renderItem={(item: any) => (
            <li>
              {item.comment_id === editCommentKey ?
                <Comment
                  content={
                    <Editor edit value={item} />
                  }
                /> :
                <Comment
                  author={<Text strong>{item.user_name}</Text>}
                  avatar={item.image_url}
                  content={<Text>{item.comment}</Text>}
                  datetime={dayjs(item?.updated).fromNow()}
                  actions={item.user_id === user_id ? [
                    <Popconfirm
                      okText={t("common.ok")}
                      cancelText={t("common.cancel")}
                      title={t("modelComments.deleteCommentConfirmation")} onConfirm={() => deleteComment(item)}>
                      <StyledButton type="text" disabled={!!editCommentKey}>
                        <StyledDeleteIcon
                          style={{ fontSize: antdTheme.fontSizeHeading5, color: red[3] }} />
                      </StyledButton>
                    </Popconfirm>,
                    <StyledButton type="text" disabled={!!editCommentKey}>
                      <EditOutlined
                        onClick={() => setEditCommentKey(item.comment_id)}
                        style={{ fontSize: antdTheme.fontSizeHeading5, color: geekblue[4] }} />
                    </StyledButton>
                  ] : []}
                />}
            </li>
          )}
        />
      </div>
      <Comment
        content={
          <Editor />
        }
      />
    </Drawer>
  )
}
