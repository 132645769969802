import { DownloadOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm, Table, Typography } from 'antd';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
import { useMemberName } from 'src/utils/useMemberName'
import dayjs from "dayjs"
import relativeTime from 'dayjs/plugin/relativeTime'
import useTranslate from 'src/utils/useTranslate'
import { inventoryAttachmentsDeleteRequest } from 'src/store/actions/inventory'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton';
dayjs.extend(relativeTime)


export const AttachmentsList = () => {
    const [t] = useTranslate()
    const { attachmentsList, attachementsAddStatus, attachementsDeleteStatus, attachementsListStatus } = useSelector((state: StoreState) => state.inventory)
    const { getName } = useMemberName()
    const dispatch = useDispatch()

    const columns = useMemo(() => [
        {
            dataIndex: "file_name",
            title: t('common.name'),
            key: "file_name",
            width: 300,
            render: (name: string) => <Typography.Text style={{ width: 350 }} ellipsis={{ tooltip: name }}>{name}</Typography.Text>,
        },
        {
            dataIndex: "user_id",
            title: t('common.uploadedBy'),
            key: "user_id",
            render: (text: string) => getName(text),
            width: 150,
        },
        {
            dataIndex: "created",
            title: t('common.uploadedOn'),
            key: "created",
            render: (text: string) => dayjs(text).fromNow(),
            width: 150,
        },
        {
            dataIndex: "actions",
            title: t('common.actions'),
            key: "actions",
            fixed: "right" as any,
            align: "center" as any,
            width: 100,
            render: (text: any, record: any) => {
                return (
                    <Dropdown
                        overlay={() => (
                            <Menu>
                                <Menu.Item key="download">
                                    <StyledButton type="text" onClick={(e) => {
                                        e.stopPropagation()
                                        window.open(record?.download_link, "_blank")
                                    }} icon={<DownloadOutlined />}>
                                        {t("notifications.download")}
                                    </StyledButton>
                                </Menu.Item>
                                <Menu.Item key="delete">
                                    <Popconfirm title={`${t("common.confirmDelete")} ?`} onConfirm={() => {
                                        dispatch(inventoryAttachmentsDeleteRequest({
                                            file_id: record?.file_id,
                                            type: record?.type,
                                            identifier: record?.identifier
                                        }))
                                    }}>
                                        <StyledButton danger type="text" icon={<StyledDeleteIcon />}>
                                            {t("common.delete")}
                                        </StyledButton>
                                    </Popconfirm>

                                </Menu.Item>
                            </Menu>
                        )}
                    >
                        {<MoreOutlined style={{ cursor: "pointer" }} />}
                    </Dropdown>
                );
            }
        }
    ], [getName, t, dispatch])


    return (
        <Table
            bordered
            columns={columns}
            dataSource={attachmentsList}
            loading={{
                spinning: attachementsAddStatus === AsyncStates.LOADING ||
                    attachementsListStatus === AsyncStates.LOADING || attachementsDeleteStatus === AsyncStates.LOADING,
                indicator: <LoadingOutlined />

            }}
            scroll={{ x: 450 }} />
    )
}
