import { ItemType } from "antd/es/breadcrumb/Breadcrumb"
import { useMemo } from "react"
import BreadCrumb from "./BreadCrumb"
import { Badge, Space } from "antd"

type P = {
  breadCrumbPath: { name: string; asset_id: string }[]
  breadCrumbOnClick: (item: ItemType) => void
  breadCrumbSelectedId: string
  breadCrumbSelectedTag?: number
}

const BreadCrumbSection = ({
  breadCrumbPath,
  breadCrumbOnClick,
  breadCrumbSelectedId,
  breadCrumbSelectedTag,
}: P) => {
  const breadCrumbItems: ItemType[] = useMemo(() => {
    return breadCrumbPath.map((item: { name: string; asset_id: string }) => {
      return {
        title: (
          <>
            <Space
              styles={{
                item: {
                  lineHeight: 1
                }
              }}
            >
              {item.name}
              {breadCrumbSelectedTag && breadCrumbSelectedTag > 0 &&
                breadCrumbSelectedId === item?.asset_id ? (
                <Badge
                  color="#1890ff"
                  count={breadCrumbSelectedTag}
                />
              ) : null}
            </Space>
          </>
        ),
        key: item?.asset_id,
        label: item?.name
      }
    })
  }, [breadCrumbPath, breadCrumbSelectedId, breadCrumbSelectedTag])

  return (
    <BreadCrumb
      items={breadCrumbItems}
      selectedId={breadCrumbSelectedId}
      breadCrumbOnClick={breadCrumbOnClick}
    />
  )
}

export default BreadCrumbSection
