import SearchFormulation from "./FiltersView/SearchFormulation"
import "./Formulation.scss"
import { Modal, Space } from "antd"
import { useSelector } from "react-redux"
import useTranslate from "src/utils/useTranslate"
import { motion } from "framer-motion"
import { StoreState } from "src/store/configureStore"
import { Formulations as CelsureFormulations } from "src/components/Celsure/Formulations/Formulations"
import { projectType } from "src/constants"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import { useState, useEffect } from "react"
import { CustomPrompt } from "src/utils/CustomPrompts"

const Formulation = () => {
	const [t] = useTranslate()
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	useEffect(() => {
		setIsModalOpen(localStorage.getItem("isLanguageAnnouncement") === "true")
	}, [])

	const { project_type } = useSelector(
		(state: StoreState) => state.login.loginResponse
	)

	const isEditing = useSelector((state: StoreState) => state.isEditing)

	return (
		<motion.div
			initial={{ opacity: 0, x: 20 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 20 }}
			transition={{ type: "just" }}
			className="page__container"
		>
			<CustomPrompt isEditing={(isEditing) as boolean} message={`${t("common.unsavedChangesLost")}!.`} />
			{project_type !== projectType.celsure ? (
				<Space direction="vertical" size="large" style={{ width: "100%" }}>
					<StyledPageHeader
						ghost={false}
						title={t("navBar.menuItem.formulations")}
						extra={
							""
						}
					/>
					<SearchFormulation />
				</Space>
			) : (
				<CelsureFormulations />
			)}
			<Modal open={isModalOpen} footer={null} onCancel={() => {
				setIsModalOpen(false)
				localStorage.removeItem("isLanguageAnnouncement")
			}}>
				<p style={{ marginTop: '1rem' }}>{t("common.languageAnnouncement")}</p>
			</Modal>
		</motion.div>
	)
}
export default Formulation
