import { Row, Col, Descriptions, Avatar, Tooltip, Space, Tag } from "antd";
import Title from "antd/lib/typography/Title";
import { useHistory } from "react-router-dom";
import Text from "antd/lib/typography/Text";
import { useMemberName } from "src/utils/useMemberName";
import { useDispatch, useSelector, } from "react-redux";
import { DropdownMenu } from '../Project/DropdDownMenu/DropDownMenu'
import { blue, orange } from "@ant-design/colors";
import { PushpinOutlined } from "@ant-design/icons"
import { StyledCard } from "src/styled_components/StyledCard"
import { setProject, updateProjectRequest } from "src/store/actions/projects";
import { trackEvent } from "src/analytics";
import { StoreState } from "src/store/configureStore";
import { antdTheme } from "src/constants";


export const ProjectCard = ({ relatedProjects, getPinAccess, getProjectStatus }: any) => {

    const dispatch = useDispatch()
    const { push } = useHistory();
    const { getName } = useMemberName()
    const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)


    return (
        <>
            <Row gutter={20}>
                {relatedProjects?.map((project: any) => {
                    const access = getPinAccess(project)
                    return (
                        <Col key={project.id}>
                            <StyledCard
                                style={{
                                    width: 340,
                                    height: 250,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    cursor: 'pointer',
                                    backgroundColor: 'transparent',
                                    marginBottom: 10
                                }}
                                onClick={() => { trackEvent(userId, 'Project details were viewed', { "Project details": project }); dispatch(setProject(project.project_id)); push(`/projects/details/${project.project_id}`) }}
                                hoverable
                                title={
                                    <Title level={5} ellipsis={{ tooltip: project.name }} style={{ width: 200 }}>{project.name}</Title>
                                }
                                extra={<Space>
                                    <Tooltip title={access ? `Click to  ${!project?.favourite ? "pin" : "unpin"} this project` : ""}>
                                        <PushpinOutlined onClick={(e) => {
                                            e.stopPropagation()
                                            if (access) {
                                                dispatch(updateProjectRequest({ ...project, project_id: project.project_id, favourite: !project?.favourite }))
                                            }
                                        }
                                        } style={{ fontSize: antdTheme.fontSizeHeading3, color: project?.favourite ? blue[4] : "", cursor: access ? "pointer" : "auto" }} />
                                    </Tooltip>
                                    <DropdownMenu type="projects" id={project.project_id} />
                                </Space>}
                            >
                                <Descriptions column={1}>
                                    <Descriptions.Item><Text style={{ width: 140 }} ellipsis={{ tooltip: project.description }}>{project.description}</Text></Descriptions.Item>
                                </Descriptions>
                                {!!project.tags?.length &&
                                    <Descriptions column={1}>
                                        <Descriptions.Item>
                                            <Space size={[0, 8]} wrap>
                                                <Text style={{ width: 200 }} ellipsis={{ tooltip: project.tags.join(", ") }}>
                                                    {
                                                        project.tags?.map((tag: string, idx: number) => <Tag color="geekblue" key={idx}>{tag}</Tag>)
                                                    }
                                                </Text>
                                            </Space>
                                        </Descriptions.Item>
                                    </Descriptions>
                                }
                                <Row style={{ marginBottom: 15 }}>
                                    {(Boolean(project?.start_date) || Boolean(project?.close_date)) &&
                                        <Space>
                                            <Text type="secondary">{!!project?.start_date ? new Date(project?.start_date).toLocaleDateString() : "-"}</Text>
                                            <Text>{" - "}</Text>
                                            <Text type="secondary">{!!project?.close_date ? new Date(project?.close_date).toLocaleDateString() : "-"}</Text>
                                        </Space>}
                                </Row>
                                <Row justify="space-between">
                                    {getProjectStatus(project.status)}
                                    <Tooltip title={getName(project.created_by)} placement="top">
                                        <Avatar style={{ background: orange[5] }} size="small">{getName(project.created_by)[0]}</Avatar>
                                    </Tooltip>
                                </Row>
                            </StyledCard>
                        </Col>
                    );
                })}
            </Row>
            {/* {
                length <= 3 ? null : (
                    <Row justify="center" align="middle" style={{ marginTop: "1rem" }}>
                        <Col>
                            {expand ? (
                                <StyledButton
                                    type="primary"
                                    ghost
                                    htmlType="submit"
                                    onClick={() => setExpand(false)}
                                >
                                    {t("projects.seeLess")}
                                </StyledButton>
                            ) : (
                                <StyledButton
                                    type="primary"
                                    ghost
                                    htmlType="submit"
                                    onClick={() => setExpand(true)}
                                >
                                    {`${t("projects.seeAll")} ${length} ${t("projects.header.title")}`}
                                </StyledButton>
                            )}
                        </Col>
                    </Row>
                )
            } */}
        </>
    )
}
