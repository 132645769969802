import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import {
	Col,
	message,
	Modal,
	Row,
	Select,
} from "antd"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { workOrdersRequest } from "src/store/actions/workOrders"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
const { Option } = Select

type P = {
	workOrder: {
		[key: string]: any
	}
	setWorkOrder: (workOrder: any) => void
	visible: boolean
	setVisible: Dispatch<SetStateAction<boolean>>
}

export const ChooseWorkOrderModal = ({
	setWorkOrder,
	visible,
	setVisible,
	workOrder,
}: P) => {
	const dispatch = useDispatch()
	const [t] = useTranslate()
	const { current } = useSelector((state: StoreState) => state.projects)
	const { data: workOrders } = useSelector(
		(state: StoreState) => state.workOrders
	)

	useEffect(() => {
		if (current) {
			dispatch(workOrdersRequest({ project_id: current }))
		}
	}, [current, dispatch])

	const workOrdersOptions = useMemo(() => {
    const openWorOrders = [...new Set(workOrders)].filter(
      (wo) => wo.status === "open",
    );
    const closedWorOrders = [...new Set(workOrders)].filter(
      (wo) => wo.status === "closed",
    );
    return (
      <>
        {closedWorOrders.length > 0 && (
          <Select.OptGroup label={t("common.closed")}>
            {closedWorOrders.map((wo) => (
              <Option label={wo.work_order_id} key={wo.work_order_id}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {wo.work_order_name}
                </Row>
              </Option>
            ))}
          </Select.OptGroup>
        )}
        {openWorOrders.length > 0 && (
          <Select.OptGroup label={t("projects.open")}>
            {openWorOrders.map((wo) => (
              <Option label={wo.work_order_id} key={wo.work_order_id}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {wo.work_order_name}
                </Row>
              </Option>
            ))}
          </Select.OptGroup>
        )}
      </>
    );
  }, [t, workOrders]);

	const changeWorkOrder = (value: string) => {
		const workOrder = workOrders.find((wo) => wo.work_order_id === value)
		setWorkOrder((prevState: any) => ({ ...prevState, workOrder }))
	}

	// const checkBoxChange = (e: any, type: string) => {
	// 	setWorkOrder((prevState: any) => ({
	// 		...prevState,
	// 		[type]: e.target.checked,
	// 	}))
	// }

	return (
		<Modal
			okText={t("common.ok")}
			cancelText={t("common.cancel")}
			title={t("common.selectWorkOrder")}
			visible={visible}
			onCancel={() => setVisible(false)}
			cancelButtonProps={{ ghost: true, disabled: true }}
			footer={null}
		>
			<Col style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
				<Col
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
					}}
				>
					<Select
						allowClear
						style={{ flexGrow: 1 }}
						value={workOrder?.workOrder?.work_order_id}
						onChange={changeWorkOrder}
						placeholder={t("common.selectWorkOrder")}
					>
						{workOrdersOptions}
					</Select>
				</Col>
			</Col>
			<Row justify="end" style={{ marginTop: 20 }}>
				<StyledButton
					onClick={() => {
						if (!workOrder.workOrder) {
							message.error(t("workOrder.message.SelectWorkOrder"))
							return
						}

						if (
							workOrder.use_ingredients === false &&
							workOrder.use_processing === false &&
							workOrder.use_characterization === false &&
							workOrder.use_properties === false
						) {
							message.error(
								t("workOrder.error.selectAtleastOneOptionToInclude")
							)
							return
						}

						setVisible(false)
					}}
					type="primary"
				>
					{t("common.ok")}
				</StyledButton>
			</Row>
		</Modal>
	)
}
