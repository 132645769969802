import { createActions } from "redux-actions";


export enum ConfigsActionTypes {
    REQUEST = "CONFIGS_REQUEST",
    SUCCESS = "CONFIGS_SUCCESS",
    FAILURE = "CONFIGS_FAILURE",
    CLEAR = "CONFIGS_CLEAR",
    UPDATE_AIENGINE = 'CONFIGS_UPDATE_AIENGINE',
    CONFIGS_UPDATE = "CONFIGS_UPDATE"
}

export const {
    configsRequest,
    configsSuccess,
    configsUpdateAiengine,
    configsFailure,
    configsClear,
    configsUpdate
} = createActions({
    [ConfigsActionTypes.REQUEST]: payload => payload,
    [ConfigsActionTypes.SUCCESS]: response => response,
    [ConfigsActionTypes.UPDATE_AIENGINE]: response => response,
    [ConfigsActionTypes.FAILURE]: () => { },
    [ConfigsActionTypes.CLEAR]: () => { },
    [ConfigsActionTypes.CONFIGS_UPDATE]: response => response,
})
