/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { CustomMLViewData } from "./CustomMLViewData";
import {
  Empty,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { AsyncStates } from "src/constants";
import { DownOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
  customTrainClear,
  customTrainRequest,
} from "src/store/actions/customML";
import { setIsEditing } from "src/store/actions/isEditing";
import { StyledCard } from "src/styled_components/StyledCard";
import CreateModelModal from "./CreateModelModal";
const { Text } = Typography;

export const CustomMLResult = ({
  filterData,
  createModalPayload,
  filters,
  submitDataFilter,
  pagination,
  dataCount,
  isMultiStage,
  ignoredData,
  selectedWorkOrders,
  workOrders,
  filterDataStatus
}: any) => {
  const [form] = Form.useForm();
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { current } = useSelector(
    (state: StoreState) => state.projects
  );

  const { customDataTrainStatus } = useSelector(
    (state: StoreState) => state.CustomML
  );
  const configs = useSelector((state: StoreState) => state.configs.features);
  const [type, setType] = useState("ingredients");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [userIgnoredData, setUserIgnoredData] = ignoredData
  const [activeWorkOrderId, setActiveWorkOrderId] = useState(selectedWorkOrders[0]);
  const [stage, setStage] = useState();
  const initial = useRef(true);
  const [availableStages, setAvailableStages] = useState([]);
  const [totalTrials, setTotalTrials] = useState();

  useEffect(() => {
    if (filterData?.total_formulation_ids_count) setTotalTrials(filterData?.total_formulation_ids_count)
  }, [filterData]);

  useEffect(() => {
    if (customDataTrainStatus === AsyncStates.SUCCESS) {
      setModalVisible(false);
      form.resetFields();
      dispatch(customTrainClear());
    }
  }, [customDataTrainStatus, dispatch, form]);

  useEffect(() => {
    if (current) setUserIgnoredData({ ingredients: [], properties: [], processing: [], formulations: [] })
  }, [current, setUserIgnoredData])

  useEffect(() => {
    if (Array.isArray(filterData?.available_stages)) {
      setAvailableStages(filterData.available_stages);

      if (initial.current) {
        setStage(filterData.available_stages[0]);
      }
    }
  }, [filterData.available_stages]);

  useEffect(() => {
    if (!isMultiStage) submitDataFilter(1, 10, activeWorkOrderId);
    else submitDataFilter(1, 10, activeWorkOrderId, stage);
  }, [activeWorkOrderId]);
  // Seperated to check which dep changed
  useEffect(() => {
    // Prevent duplicate api call on 1st stage set
    if (!stage) return;
    if (initial.current) {
      initial.current = false;
      return;
    }

    if (!isMultiStage) submitDataFilter(1, 10, activeWorkOrderId);
    else submitDataFilter(1, 10, activeWorkOrderId, stage);
  }, [stage]);

  const updateStage = (add: number) => {
    const stages = availableStages as Array<string>;
    const currentStageIndex = stages.findIndex(s => s === stage);
    const newIndex = currentStageIndex + add;

    if (newIndex > (stages.length - 1)) setStage(availableStages[0]);
    else if (newIndex < 0) setStage(availableStages[(stages.length - 1)]);
    else setStage(availableStages[newIndex]);
  }

  const customDataTrain = (values: any) => {
    const payload = {
      ...createModalPayload,
      comment: values.comment,
      objective: values.objective,
      input_types: values.input_types,
      use_metadata: values.use_metadata,
      ignore_ingredients: userIgnoredData.ingredients.length > 0 ? userIgnoredData.ingredients : [],
      ignore_properties: userIgnoredData.properties.length > 0 ? userIgnoredData.properties : [],
      ignore_processings: userIgnoredData.processing.length > 0 ? userIgnoredData.processing : [],
      ignore_formulation_ids: userIgnoredData.formulations.length > 0 ? userIgnoredData.formulations : [],
      ...(Boolean(configs?.processing_profiles) && {
        use_processing_profiles: true,
      }),
    };
    if (isMultiStage) {
      delete payload['input_types']
      delete payload['output_types']
      delete payload['page_num']
      delete payload['page_size']
    }
    dispatch(customTrainRequest({ ...payload, isMultiStage }));
    dispatch(setIsEditing(false));
  };

  const formatTabName = (name: string, woId: string) => {
    const formattedName = name ? name : woId;
    return formattedName.length < 11 ? formattedName : <Tooltip title={formattedName}>{formattedName.substring(0, 10)}...</Tooltip>
  }

  return (
    <>
      <StyledCard title={filters?.name || ""} headStyle={{ border: "none" }}>
        <Row justify="space-between" style={{ paddingBottom: "24px" }}>
          <div>
            {isMultiStage && availableStages.length > 0 && stage && (
              <>
                <span onClick={() => updateStage(-1)} className="left-button"><LeftOutlined /></span>
                <Select
                  className="custom-select"
                  value={stage}
                  style={{ width: 120 }}
                  onChange={(value) => setStage(value)}
                  options={availableStages.map((stage: string) => ({ label: stage, value: stage }))}
                />
                <span onClick={() => updateStage(1)} className="right-button"><RightOutlined /></span>
              </>
            )}
            <Space style={{ marginLeft: "16px" }}>
              <Row justify="center" align="middle" style={{ gap: "8px" }}>
                <Text className="info-text">{t("common.totalWorkOrders")}: {selectedWorkOrders.length}</Text>
                <svg height="4" width="4">
                  <circle cx="2" cy="2" r="2" stroke="#595959" stroke-width="0" fill="#595959" />
                </svg>
                <Text className="info-text">{t("common.totalTrials")}: {totalTrials}</Text>
              </Row></Space>
          </div>
          <Tooltip title={t("customML.useDataForModelTraining")}>
            <span>
              <StyledButton
                onClick={() => setModalVisible(true)}
                type="primary"
              >
                {t("aiEngine.customML.button.createModel")}
              </StyledButton>
            </span>
          </Tooltip>
        </Row>
        <Tabs
          defaultActiveKey={activeWorkOrderId}
          tabPosition={"top"}
          style={{ height: 220 }}
          moreIcon={<DownOutlined />}
          activeKey={activeWorkOrderId}
          onTabClick={(key) => { setActiveWorkOrderId(key); setType('ingredients'); }}
          items={selectedWorkOrders.map((woId: string) => {
            return {
              label: formatTabName(workOrders.find((wo: any) => woId === wo.work_order_id)?.work_order_name, woId),
              key: woId,
              children: (
                <>
                  <Row justify="center">
                    <Spin
                      style={{ marginTop: "30px" }}
                      spinning={filterDataStatus === AsyncStates.LOADING}
                      indicator={<LoadingOutlined />}
                    ></Spin>
                  </Row>
                  {!filterData?.dataframe?.length && filterDataStatus !== AsyncStates.LOADING && (<Empty style={{ marginTop: "30px" }} />)}
                  {!!filterData?.dataframe?.length && (
                    <CustomMLViewData
                      filterData={filterData?.dataframe}
                      propertyTrainWarnings={filterData.property_train_warnings}
                      from={"custom_ml"}
                      submitDataFilter={submitDataFilter}
                      pagination={pagination}
                      dataCount={dataCount}
                      UserIgnoredDataInfo={[userIgnoredData, setUserIgnoredData]}
                      isMultiStage={isMultiStage}
                      activeWorkOrderId={activeWorkOrderId}
                      typeInfo={[type, setType]}
                      stage={stage}
                    />
                  )}
                </>
              ),
            };
          })}
        />
      </StyledCard>

      <CreateModelModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        customDataTrain={customDataTrain}
        isMultiStage={isMultiStage}
        form={form}
      />
    </>
  );
};
