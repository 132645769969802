import React, { useMemo } from "react"
import { Descriptions, Space, Typography } from "antd"
import { ColumnDataTypeDistribution as Data } from "src/components/AIEngine/DataSummary/types"
import { SummaryWrapper } from "../summary-wrapper"
import useTranslate from "src/utils/useTranslate"
const { Title } = Typography

type P = {
  data: Data
  getDisplayName: (value: string) => string
}

export const ColumnDataTypeDistribution = ({ data, getDisplayName }: P) => {
  const [t] = useTranslate();

  const numericalKeys = useMemo(() => {
    const newData = Object.keys(data.Numerical || {});
    return newData;
  }, [data.Numerical]);

  const categoricalKeys = useMemo(() => {
    const newData = Object.keys(data.Categorical || {});
    return newData;
  }, [data.Categorical]);

  const mergedNumericalLength = useMemo(() => {
    const newData = Object.values(data.Numerical || {}).flatMap((val) => val);
    return newData.length;
  }, [data.Numerical]);

  const mergedCategoricalLength = useMemo(() => {
    const newData = Object.values(data.Categorical || {}).flatMap((val) => val);
    return newData.length;
  }, [data.Categorical]);

  const titleMapping: { [key: string]: string } = {
    ingredients: t("common.ingredients"),
    processing: t("common.processing"),
    properties: t("common.properties"),
    costing: t("inventory.costing"),
  };

  return (
    <SummaryWrapper
      heading={t("dataSummary.columnDataTypeDistribution")}
      key={t("dataSummary.columnDataTypeDistribution")}
      tooltip={t("aiEngine.tab.dataSummary.columndatatypedistribution")}
    >
      <Space style={{ width: "100%", overflowX: "auto" }} direction="vertical">
        <Title style={{ marginLeft: 5 }} level={5}>
          {`${t("common.total")} ${t("common.columns")}: ${categoricalKeys.filter((key) => data.Categorical[key].length > 0)
            .length +
            numericalKeys.filter((key) => data.Numerical[key].length > 0).length
            }`}
        </Title>

        <Descriptions
          size="small"
          column={1}
          bordered
          labelStyle={{ fontWeight: "bold", width: 150 }}
        >
          {mergedNumericalLength > 0 && (
            <Descriptions.Item
              label={`${t("common.numerical")} (${mergedNumericalLength})`}
              key={`${t("common.numerical")} (${mergedNumericalLength})`}
            >
              <Descriptions
                size="small"
                column={1}
                bordered
                labelStyle={{ fontWeight: "bold", width: 150 }}
              >
                {numericalKeys
                  .filter((key) => data.Numerical[key].length > 0)
                  .map((key: any) => {
                    return (
                      <Descriptions.Item
                        key={key}
                        label={`${titleMapping[key]} (${data.Numerical[key].length})`}
                      >
                        {data.Numerical[key]
                          .map((num: string) => getDisplayName(num)).join(", ")}
                      </Descriptions.Item>
                    );
                  })}
              </Descriptions>
            </Descriptions.Item>
          )}

          {mergedCategoricalLength > 0 && (
            <Descriptions.Item
              label={`${t("common.categorical")} (${mergedCategoricalLength})`}
            >
              <Descriptions
                size="small"
                column={1}
                bordered
                labelStyle={{ fontWeight: "bold", width: 150 }}
              >
                {categoricalKeys
                  .filter((key) => data.Categorical[key].length > 0)
                  .map((key: any) => {
                    return (
                      <Descriptions.Item
                        key={key}
                        label={`${titleMapping[key]} (${data.Categorical[key].length})`}
                      >
                        {data.Categorical[key]
                          .map((categoricalKey: string) => {
                            const identifer = categoricalKey?.split(" ")?.[0]
                            const displayCategoricalName = categoricalKey?.replace(identifer, getDisplayName(identifer))
                            return displayCategoricalName
                          }).join(", ")}
                      </Descriptions.Item>
                    );
                  })}
              </Descriptions>
            </Descriptions.Item>
          )}
        </Descriptions>
      </Space>
    </SummaryWrapper>
  );
}
