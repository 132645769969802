
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Plackett-Burman`}</h1>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Plackett%E2%80%93Burman_design"
      }}>{`Plackett-Burman Design`}</a>{` (a type of screening design) helps you to find out which factors in an experiment are important. This design screens out unimportant factors (noise), which means that you avoid collecting large amounts of data on relatively unimportant factors.`}</p>
    <p>{`In 1946, R.L. Plackett and J.P. Burman published their now famous paper "The Design of Optimal Multifactorial Experiments" in Biometrika (vol. 33). This paper described the construction of very economical designs with the run number a multiple of four (rather than a power of 2). Plackett-Burman designs are very efficient screening designs when only main effects are of interest.`}</p>
    <p>{`Plackett-Burman (PB) designs are used for screening experiments because, in a PB design, main effects are, in general, heavily confounded with two-factor interactions. The PB design in 12 runs, may be used for an experiment containing up to 11 factors ( For example, 4, 5, 6, or 7 factors would require 8 experimental runs, 8, 9, 10, or 11 would require 12 runs, and so on ) `}</p>
    <p>{`Use Cases:-`}</p>
    <ul>
      <li parentName="ul">{`In screening`}</li>
      <li parentName="ul">{`When neglecting higher order interactions is possible`}</li>
      <li parentName="ul">{`In two-level multi-factor experiments`}</li>
      <li parentName="ul">{`When there are more than four factors (if there are between two to four variables, a full factorial can be performed)`}</li>
      <li parentName="ul">{`To economically detect large main effects`}</li>
      <li parentName="ul">{`For `}<em parentName="li">{`N`}</em>{` = 12, 20, 24, 28 and 36 (where `}<em parentName="li">{`N`}</em>{` = the number of experiments)`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;