import { FolderFilled, LoadingOutlined } from "@ant-design/icons";
import { Checkbox, Spin, Tooltip, Typography } from "antd";

type TProps = {
    batch: any;
    onBatchClick?: Function;
    selectable?: boolean;
    setSelectedIds?: React.Dispatch<React.SetStateAction<string[]>>;
    selectedIds?: string[];
};

const BatchCard = ({
    batch,
    onBatchClick,
    selectable = false,
    setSelectedIds,
    selectedIds = [],
}: TProps) => {
    return (
        <div
            key={batch.batch_id}
            className="batch"
            onClick={() => {
                onBatchClick && onBatchClick(batch.batch_id);
            }}
        >
            {selectable && (
                <div className="selection">
                    <Checkbox
                        checked={selectedIds.includes(batch.batch_id)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            if (setSelectedIds) {
                                if (e.target.checked)
                                    setSelectedIds((prev: string[]) => [...prev, batch.batch_id]);
                                else
                                    setSelectedIds((prev: any) =>
                                        prev.filter((p: string) => p !== batch.batch_id)
                                    );
                            }
                        }}
                    />
                </div>
            )}
            {batch.percent_completed && (<Typography.Text style={{ color: "#1677ff" }}><Spin indicator={<LoadingOutlined />} size="small" />&nbsp;&nbsp;{batch.percent_completed}</Typography.Text>)}
            <FolderFilled />
            {String(batch.batch_name).length > 20 ? (
                <Tooltip title={batch.batch_name}>
                    {String(batch.batch_name).substring(0, 20)}...
                </Tooltip>
            ) : (
                <>{batch.batch_name}</>
            )}
        </div>
    );
};

export default BatchCard;
