import { useLayoutEffect, useState } from "react";
import "./Additives.scss";
import useTranslate from "src/utils/useTranslate";
import NoData from "../Common/NoData";
import TabHeader from "../Common/TabHeader";
import AddEditAdditiveModal from "../Common/AddEditAdditiveModal";
import AdditiveDetails from "./AdditiveDetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdditiveRequest } from "src/store/actions/repository";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";

const Additives = () => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [selectedAdditive, setSelectedAdditive] = useState<string>();
    const [mode, setMode] = useState<"edit" | "create" | "delete">("create");
    const additives = useSelector(
        (state: StoreState) => state.repository.additives
    );
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(fetchAdditiveRequest());
    }, [dispatch]);

    const addAdditive = () => {
        setMode("create");
        setShowModal(true);
    };

    return (
        <Spin
            spinning={additives.status === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}
        >
            <div className="additive-container">
                {additives.data.length === 0 &&
                    additives.status === AsyncStates.SUCCESS ? (
                    <NoData
                        title={`${t("repository.totalAdditives")} (0)`}
                        btns={[t("repository.addNewAdditives"), t("repository.uploadBulkAdditive")]}
                        addItem={setShowModal}
                    />
                ) : (
                    <div className="content-container">
                        {additives.data.length > 0 && (
                            <>
                                <TabHeader
                                    title={`${t("repository.totalAdditives")} (${additives.data.length})`}
                                    addItem={addAdditive}
                                    setSearchTerm={setSearchTerm}
                                />
                                <AdditiveDetails
                                    searchTerm={searchTerm}
                                    setSelectedAdditive={setSelectedAdditive}
                                    setMode={setMode}
                                    setShowModal={setShowModal}
                                />
                            </>
                        )}
                    </div>
                )}
                <AddEditAdditiveModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    mode={mode}
                    selectedAdditiveId={selectedAdditive}
                />
            </div>
        </Spin>
    );
};

export default Additives;
