import { FilterOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Form, Row, Col, Space, Cascader, Select, Input } from 'antd'
import { useSelector } from 'react-redux'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import useTranslate from 'src/utils/useTranslate'


const FilterAndSorting = ({ form, applyFilters, filterData, operatorStates, setOperatorStates, filters, setFilters, pageChange }: any) => {
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()

  const {
    suggestedExpDataForFilterData,
  } = useSelector((state: StoreState) => state.suggestedExp)

  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data,
  )

  const getCategoricalParameterListFilter = (
    parameterType: string,
    parameterName: string,
  ) => {
    return (
      suggestedExpDataForFilterData?.[0]?.results_range?.[parameterType]?.[
      parameterName
      ] ??
      suggestedExpDataForFilterData?.[0]?.results_range?.[parameterType]?.[
      parameterName
      ] ??
      suggestedExpDataForFilterData?.[0]?.results_range?.[parameterType]?.[
      parameterName
      ] ??
      []
    )
  }

  return (
    <Form
      name="dynamic_form_nest_item"
      onFinish={applyFilters}
      form={form}
      requiredMark={false}
    >
      <Row gutter={24} style={{ flexWrap: "nowrap" }}>
        <Col span={12}>
          <Form.List name="expFilters">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <StyledButton
                    type="primary"
                    onClick={() => add()}
                    ghost
                    icon={<FilterOutlined />}
                  >
                    {t("formulations.button.addFilter")}
                  </StyledButton>
                </Form.Item>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }) => (
                    <Row
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col span={8}>
                        <Form.Item
                          style={{ width: "100%" }}
                          {...restField}
                          name={[name, "property"]}
                          fieldKey={[key, "property"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "aiEngine.form.properties.error",
                              ),
                            },
                          ]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Cascader
                            placeholder={t(
                              "aiEngine.form.properties.placeholder",
                            )}
                            options={filterData}
                            onChange={() => {
                              const { expFilters } =
                                form.getFieldsValue()
                              if (
                                Array.isArray(
                                  getCategoricalParameterListFilter(
                                    form?.getFieldsValue()
                                      ?.expFilters?.[name]
                                      ?.property?.[0],
                                    form?.getFieldsValue()
                                      ?.expFilters?.[name]
                                      ?.property?.[1],
                                  ),
                                )
                              ) {
                                setOperatorStates(
                                  (state: any) => {
                                    const array = [...state]
                                    array[key] = "="
                                    return array
                                  },
                                )
                                Object.assign(
                                  expFilters?.[name],
                                  {
                                    operand: "=",
                                    minVal: [],
                                    maxVal: null,
                                  },
                                )
                              } else {
                                setOperatorStates(
                                  (state: any) => {
                                    const array = [...state]
                                    array[name] = null
                                    return array
                                  },
                                )
                                if (expFilters?.[name]) {
                                  Object.assign(
                                    expFilters?.[name],
                                    {
                                      operand: null,
                                      minVal: [],
                                      maxVal: null,
                                    },
                                  )
                                }
                              }

                              form.setFieldsValue({
                                expFilters,
                              })
                            }}
                            allowClear={false}
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        span={6}
                        style={{ paddingLeft: "5px" }}
                      >
                        <Form.Item
                          style={{ width: "100%" }}
                          {...restField}
                          name={[name, "operand"]}
                          fieldKey={[key, "operand"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "aiEngine.form.operator.error",
                              ),
                            },
                          ]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select
                            placeholder={t(
                              "formulations.placeholder.operator",
                            )}
                            onChange={(operator) => {
                              setOperatorStates(
                                (state: any) => {
                                  const array = [...state]
                                  array[name] = operator
                                  return array
                                },
                              )
                              const { expFilters } =
                                form.getFieldsValue()
                              if (expFilters?.[name]) {
                                Object.assign(
                                  expFilters?.[name],
                                  { minVal: "", maxVal: "" },
                                )
                              }
                              form.setFieldsValue({
                                expFilters,
                              })
                            }}
                            style={{ width: "100%" }}
                          >
                            <Select.Option value="=">
                              =
                            </Select.Option>
                            {suggestedExpDataForFilterData
                              ?.map(
                                (exp: any) =>
                                  exp?.results_range?.[
                                    form?.getFieldsValue()
                                    ?.expFilters?.[name]
                                      ?.property?.[0]
                                  ]?.[
                                    form?.getFieldsValue()
                                    ?.expFilters?.[name]
                                      ?.property?.[1]
                                  ]?.value,
                              )
                              ?.every(
                                (value: any) =>
                                  typeof value !== "string",
                              ) && (
                                <>
                                  <Select.Option value="<=">
                                    {"<="}
                                  </Select.Option>
                                  <Select.Option value=">=">
                                    {">="}
                                  </Select.Option>
                                  <Select.Option value="range">
                                    {t("formulations.operator")}
                                  </Select.Option>
                                  <Select.Option value="exists">
                                    {t("common.exists")}
                                  </Select.Option>
                                </>
                              )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col
                        span={8}
                        style={{ paddingLeft: "5px" }}
                      >
                        {operatorStates[name] === "range" ? (
                          <Row
                            style={{
                              width: "100%",
                              gap: "2%",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              flexWrap: "nowrap",
                            }}
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "minVal"]}
                              fieldKey={[key, "minVal"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "aiEngine.form.minVal.error",
                                  ),
                                },
                              ]}
                              style={{ width: "47%" }}
                              required
                              tooltip={requiredFieldStar}
                            >
                              <Input
                                style={{
                                  textAlign: "center",
                                  width: "100%",
                                }}
                                placeholder={t(
                                  "formulations.placeholder.minimum",
                                )}
                              />
                            </Form.Item>
                            <div style={{ marginBottom: 12 }}>
                              ~
                            </div>
                            <Form.Item
                              {...restField}
                              name={[name, "maxVal"]}
                              fieldKey={[key, "maxVal"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "aiEngine.form.maxVal.error",
                                  ),
                                },
                              ]}
                              style={{ width: "47%" }}
                              required
                              tooltip={requiredFieldStar}
                            >
                              <Input
                                className="site-input-right"
                                style={{
                                  textAlign: "center",
                                  width: "100%",
                                }}
                                placeholder={t(
                                  "formulations.placeholder.maximum",
                                )}
                              />
                            </Form.Item>
                          </Row>
                        ) : operatorStates[name] ===
                          "exists" ? (
                          <Form.Item
                            {...restField}
                            name={[name, "minVal"]}
                            fieldKey={[key, "minVal"]}
                            style={{ flex: 1, width: "100%" }}
                          >
                            {" "}
                            <Select
                              placeholder={t(
                                "formulations.placeholder.value",
                              )}
                              onChange={(value) => {
                                const { expFilters } =
                                  form?.getFieldsValue()
                                Object.assign(
                                  expFilters?.[name],
                                  { minVal: value },
                                )
                                form.setFieldsValue({
                                  expFilters,
                                })
                              }}
                              style={{ width: "100%" }}
                            >
                              <Select.Option value={1}>
                                {t("common.yes")}
                              </Select.Option>
                              <Select.Option value={0}>
                                {t("common.no")}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        ) : (
                          <Form.Item
                            {...restField}
                            name={[name, "minVal"]}
                            fieldKey={[key, "minVal"]}
                            style={{ flex: 1 }}
                            rules={[
                              {
                                required: true,
                                message: t("common.required"),
                              },
                            ]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            {Array.isArray(
                              getCategoricalParameterListFilter(
                                form?.getFieldsValue()
                                  ?.expFilters?.[name]
                                  ?.property?.[0],
                                form?.getFieldsValue()
                                  ?.expFilters?.[name]
                                  ?.property?.[1],
                              ),
                            ) ? (
                              <Select
                                style={{ width: "100%" }}
                                showSearch
                                mode="multiple"
                                placeholder={t(
                                  "formulations.placeholder.value",
                                )}
                                onChange={(value) => {
                                  const { expFilters } =
                                    form?.getFieldsValue()
                                  Object.assign(
                                    expFilters?.[name],
                                    { minVal: value },
                                  )
                                  form.setFieldsValue({
                                    expFilters,
                                  })
                                }}
                                options={getCategoricalParameterListFilter(
                                  form?.getFieldsValue()
                                    ?.expFilters?.[name]
                                    ?.property?.[0],
                                  form?.getFieldsValue()
                                    ?.expFilters?.[name]
                                    ?.property?.[1],
                                )?.map((value: string) => ({
                                  label:
                                    displayNames?.[
                                      form?.getFieldsValue()
                                      ?.expFilters?.[name]
                                        ?.property?.[0]
                                    ]?.[value]?.name ?? value,
                                  value,
                                }))}
                              />
                            ) : (
                                    <Input
                                style={{
                                  textAlign: "center",
                                  width: "100%",
                                }}
                                placeholder={t(
                                  "formulations.placeholder.value",
                                )}
                              />
                            )}
                          </Form.Item>
                        )}
                      </Col>
                      <Col
                        span={2}
                        style={{ paddingLeft: "5px" }}
                      >
                        <StyledDeleteIcon
                          onClick={() => {
                            setOperatorStates((state: any[]) =>
                              state.filter(
                                (o, idx) => idx !== name,
                              ),
                            )
                            remove(name)
                          }}
                          style={{
                            width: "100%",
                            marginBottom: 12,
                          }}
                        />
                      </Col>
                    </Row>
                  ),
                )}
              </>
            )}
          </Form.List>
        </Col>

        <Col span={12}>
          <Form.List name="sorting">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <StyledButton
                    type="primary"
                    onClick={() => add()}
                    ghost
                    icon={<SortDescendingOutlined />}
                  >
                    {t("aiEngine.button.sorting")}
                  </StyledButton>
                </Form.Item>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }) => (
                    <Row
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Col
                        span={8}
                        style={{ paddingLeft: "5px" }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "property"]}
                          fieldKey={[key, "property"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "aiEngine.form.properties.error",
                              ),
                            },
                          ]}
                          style={{ width: "100%" }}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Cascader
                            placeholder={t(
                              "aiEngine.form.properties.placeholder",
                            )}
                            options={filterData}
                            onChange={() => {
                              const { sorting } =
                                form.getFieldsValue()
                              if (sorting?.[name]) {
                                Object.assign(sorting?.[name], {
                                  sort_order: null,
                                })
                              }
                              form.setFieldsValue({ sorting })
                            }}
                            showSearch
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        span={8}
                        style={{ paddingLeft: "5px" }}
                      >
                        <Form.Item
                          style={{ width: "100%" }}
                          {...restField}
                          name={[name, "sort_order"]}
                          fieldKey={[key, "sort_order"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "aiEngine.form.sort.error",
                              ),
                            },
                          ]}
                          required
                          tooltip={requiredFieldStar}
                        >
                          <Select
                            placeholder={t(
                              "aiEngine.form.sort.placeholder",
                            )}
                            style={{ width: "100%" }}
                          >
                            <Select.Option value="ascending">
                              {t(
                                "aiEngine.form.select.ascending",
                              )}
                            </Select.Option>
                            <Select.Option value="descending">
                              {t(
                                "aiEngine.form.select.descending",
                              )}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        span={2}
                        style={{ paddingLeft: "5px" }}
                      >
                        <StyledDeleteIcon
                          onClick={() => {
                            remove(name)
                          }}
                          style={{
                            width: "100%",
                            marginBottom: 12,
                            outline: "none",
                          }}
                        />
                      </Col>
                    </Row>
                  ),
                )}
              </>
            )}
          </Form.List>
        </Col>
      </Row>

      <Space>
        <Form.Item>
          <StyledButton type="primary" htmlType="submit">
            {t("aiEngine.button.apply")}
          </StyledButton>
        </Form.Item>
        <Form.Item>
          <StyledButton
            type="primary"
            ghost
            disabled={!Object.keys(filters).length}
            onClick={() => {
              form.resetFields()
              setOperatorStates([])
              pageChange(1, "experiments")
              setFilters([])
            }}
          >
            {t("common.clear")}
          </StyledButton>
        </Form.Item>
      </Space>
    </Form>
  )
}

export default FilterAndSorting