import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts"
import React, { createRef, useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { Col, Row } from 'antd'
import { useValue } from 'src/utils/useValue'


export const BoxPlotDistributionChart = ({ getDisplayName }: any) => {
    const { boxPlotSummaryData = [] } = useSelector((state: StoreState) => state.dataSummary)
    const isSidebarCollapsed = useSelector((state: StoreState) => state.sidebar.collapsed)
    const highchartsRef = useMemo(() => Array.from({ length: boxPlotSummaryData.length }).map(() => createRef<HighchartsReact.RefObject>()), [boxPlotSummaryData.length])
    const { getValue: getEUValue } = useValue()

    const redraw = useCallback(() => {
        if (highchartsRef.length) {
            highchartsRef?.[0]?.current?.chart?.reflow()
            highchartsRef?.[1]?.current?.chart?.reflow()
        }
    }, [highchartsRef])

    useEffect(() => {
        redraw()
    }, [isSidebarCollapsed, redraw])

    const optionsList = useMemo(() => {
        const plotOptions = boxPlotSummaryData.map((plotData: any) => ({
            title: {
                text: `<h3>${getDisplayName(plotData.ylabel)} Distribution by ${getDisplayName(plotData.xlabel)}</h3>`,
            },

            legend: {
                enabled: false
            },

            credits: {
                enabled: false
            },
            xAxis: {
                categories: plotData.categories.map((category: string) => getDisplayName(category)),
                title: {
                    text: getDisplayName(plotData.xlabel)
                },
                labels: {
                    formatter: function (this: any) {
                        const point = getEUValue(this.value)
                        return point;
                    }
                },
            },

            yAxis: {
                title: {
                    text: getDisplayName(plotData.ylabel)
                },
                labels: {
                    formatter: function (this: any) {
                        const point = getEUValue(this.value)
                        return point;
                    }
                },
            },

            series: [{
                type: 'boxplot',
                name: 'Distribution',
                data: plotData.boxplotData,
                tooltip: {
                    headerFormat: '<strong>{point.key}</strong><br/>',
                    pointFormatter: function (this: any) {
                        const point = this
                        return `
                        <b>Distribution</b><br>
                        Maximum: ${getEUValue(point.high)}<br>
                        Upper quartile: ${getEUValue(point.q3)}<br>
                        Median: ${getEUValue(point.median)}<br>
                        Lower quartile: ${getEUValue(point.q1)}<br>
                        Minimum: ${getEUValue(point.low)}`
                    },

                }
            }, {
                name: 'Observation',
                type: 'scatter',
                data: plotData.scatterData,
                jitter: {
                    x: 0.1 // Exact fit for box plot's groupPadding and pointPadding
                },
                marker: {
                    radius: 3
                },
                color: '#000',
                tooltip: {
                    pointFormatter: function (this: any) {
                        const point = this
                        return `Value: ${getEUValue(point.y)}`
                    },
                }
            }]
        }))
        return plotOptions
    }, [boxPlotSummaryData, getDisplayName, getEUValue])


    return (
        <div style={{ display: "flex", justifyContent: "center", gap: 10, width: "100%" }}>
            <Row gutter={[12, 12]} style={{ width: "100%" }}>
                {
                    optionsList.map((options, index) => (
                        <Col span={(optionsList?.length > 1) ? 12 : 24} style={{ border: "1px solid #ccc" }} key={index}>
                            <HighchartsReact highcharts={Highcharts} options={options} ref={highchartsRef?.[index]} />
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}
