import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	workOrdersRequest,
	workOrdersSuccess,
	workOrdersFailure,
	checkWorkOrderExistsRequest,
	checkWorkOrderExistsSuccess,
	checkWorkOrderExistsFailure,
	closeAllWorkOrdersSuccess,
	closeAllWorkOrdersFailure,
	closeAllWorkOrdersRequest,
	workOrdersFilterOptionsRequest,
	workOrdersFilterOptionsSuccess,
	workOrdersFilterOptionsFailure,
} from "../actions/workOrders"
import { checkWorkOrderExistsApi, closeAllWorkOrders, workOrders, workOrdersFilterOptions } from "src/services/workOrders"
import jwtManager from "src/utils/jwtManager"
import { messages } from "src/utils/hooks"
import { notification } from "antd"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"

type workOrdersAction = {
	type: string
	payload: any
}

function* workOrdersSaga(action: workOrdersAction): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message, count },
			},
		} = yield call(workOrders, action.payload, headers)
		if (status === "Success") {
			yield put(workOrdersSuccess(data, count))
		} else {
			yield put(workOrdersFailure(message))
		}
	} catch (error) {
		yield put(workOrdersFailure(error))
	}
}


function* checkWorkOrderExistsSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(checkWorkOrderExistsApi, payload, headers)
		if (status === "Success") {
			yield put(checkWorkOrderExistsSuccess(data))
		} else {
			yield put(checkWorkOrderExistsFailure())
		}
	} catch (error) {
		yield put(checkWorkOrderExistsFailure())
	}
}

function* closeAllWorkOrdersSaga(action: workOrdersAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	);
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(closeAllWorkOrders, action.payload, headers)
		if (status === "Success") {
			yield put(closeAllWorkOrdersSuccess(message))
			notification.success({ message: message });
		} else {
			yield put(closeAllWorkOrdersFailure(message))
			notification.error({ message: message });
		}
	} catch (error) {
		yield put(closeAllWorkOrdersFailure(error))
		notification.error({ message: messages[ln].internal_server_error });
	}
}

function* workOrdersFilterOptionsSaga(action: workOrdersAction): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(workOrdersFilterOptions, action.payload, headers)
		if (status === "Success") {
			yield put(workOrdersFilterOptionsSuccess(data))
		} else {
			yield put(workOrdersFilterOptionsFailure(message))
		}
	} catch (error) {
		yield put(workOrdersFilterOptionsFailure(error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(workOrdersRequest, workOrdersSaga)
	yield takeLatest(checkWorkOrderExistsRequest, checkWorkOrderExistsSaga)
	yield takeLatest(closeAllWorkOrdersRequest, closeAllWorkOrdersSaga)
	yield takeLatest(workOrdersFilterOptionsRequest, workOrdersFilterOptionsSaga)
}
