import { useMemo } from "react"
import { useSelector } from "react-redux"
import { permissions, userRoles } from "src/constants"
import { StoreState } from "src/store/configureStore"

export interface userAccessInterface {
    permission: string,
    status: string
    stages: any[]
}


export const usePermission = (id: string | null = null) => {
    const { projectList, current } = useSelector((state: StoreState) => state.projects)
    const stages = useSelector((state: StoreState) => state.displayNames.data?.stages)
    const currentProject = useMemo(() => projectList.find((res: any) => res.project_id === (!!id ? id : current)), [current, projectList, id])
    const { user_id, user_role } = useSelector((state: StoreState) => state.login.loginResponse)
    const userAccess: any = useMemo(() => {
        if ((user_role === "admin" && !!id) || (currentProject?.created_by === user_id || user_role === userRoles.admin)) {
            return { permission: permissions.projectAdmin, stages: Object.keys(stages || {}), status: currentProject?.status }
        }
        if (currentProject?.created_by === user_id || user_role === userRoles.admin) {
            return { permission: permissions.projectAdmin, stages: Object.keys(stages || {}), status: currentProject?.status }
        } else {
            let memberAcess = { permission: permissions.viewer, stages: Object.keys(stages || {}), status: currentProject?.status }
            currentProject?.members?.forEach((res: any) => {
                if (res?.user_id === user_id) {
                    memberAcess = { ...res, status: currentProject?.status }
                }
            })
            return memberAcess
        }
    }, [currentProject, user_id, user_role, id, stages])
    return userAccess
}