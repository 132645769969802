import { Checkbox, Col, Divider, Row, Space, Table, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useData } from "./useData";
import Text from "antd/lib/typography/Text";
import { blue, grey } from "@ant-design/colors";
import useTranslate from "src/utils/useTranslate";
import "./ZeonWorkOrderTables.css";
import { usePermission } from "src/utils/usePermissions";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { antdTheme } from "src/constants";

export default function ZeonWorkOrderTables({
  formulationList,
  index,
  checkbox,
  checkBoxChange,
  formulationsCheck,
  from,
  tableId = "",
}: any) {
  const propertyLabels = useSelector(
    (state: StoreState) => state.displayNames.data?.properties || {}
  );
  const characterizationLabels = useSelector(
    (state: StoreState) => state.displayNames.data?.characterizations || {}
  );

  const { tableData: characterizationsTableData } = useData({
    formulationList,
    stageType: "characterizations",
  });
  const { tableData: propertiesTableData } = useData({
    formulationList,
    stageType: "properties",
  });

  const total = useSelector(
    (state: StoreState) => state.workOrderDetails.total
  );

  const [t] = useTranslate();
  const userAccess = usePermission();

  return (
    <Row>
      {userAccess?.stages?.includes("characterization_stage") &&
        !!characterizationsTableData?.length && (
          <Col style={{ marginBottom: "1rem", width: "100%" }}>
            <Table
              bordered
              size="small"
              pagination={false}
              id={tableId + "_characterization_stage"}
              getPopupContainer={() =>
                (document as any).getElementById(
                  tableId + "_characterization_stage"
                )
              }
              className="report-table"
              columns={[
                {
                  title: t("common.category"),
                  dataIndex: "category",
                  filters: Array.from(
                    new Set(
                      Object.values(characterizationLabels || {})?.map(
                        (res: any) => res?.category
                      )
                    )
                  )
                    .map((property) => ({
                      text: String(property),
                      value: String(property),
                    }))
                    .sort((value1, value2) =>
                      value1.text.toLowerCase() < value2.text.toLowerCase()
                        ? -1
                        : 1
                    ),
                  onFilter: (value, record) =>
                    characterizationLabels?.[
                      record?.category
                    ]?.category?.indexOf(value) === 0,
                  sorter: (a, b) => a?.category?.localeCompare(b?.category),
                  sortDirections: ["ascend", "descend"],
                  fixed: "left",
                  width: 150,
                  align: "center" as any,
                  render: (text: string) => (
                    <Text style={{ wordBreak: "break-word" }}>{text}</Text>
                  ),
                },
                {
                  title: t("common.characterizations"),
                  dataIndex: "parameter",
                  fixed: "left",
                  width: 200,
                  align: "center" as any,
                  sorter: (a, b) => a.parameter.localeCompare(b.parameter),
                  filters: Array.from(
                    new Set(
                      Object.values(characterizationLabels || {})?.map(
                        (res: any) => res?.name
                      )
                    )
                  )
                    .map((property) => ({
                      text: String(property),
                      value: String(property),
                    }))
                    .sort((value1, value2) =>
                      value1.text.toLowerCase() < value2.text.toLowerCase()
                        ? -1
                        : 1
                    ),
                  onFilter: (value, record) =>
                    characterizationLabels?.[record?.parameter]?.name?.indexOf(
                      value
                    ) === 0,
                  sortDirections: ["ascend", "descend"],
                  render: (text: string) => (
                    <Text
                      style={
                        from === "drawer"
                          ? { color: blue[6], wordBreak: "break-word" }
                          : { wordBreak: "break-word" }
                      }
                      strong
                    >
                      {characterizationLabels?.[text]?.name ?? text}
                    </Text>
                  ),
                },
                {
                  title: t("common.unit"),
                  dataIndex: "unit",
                  fixed: "left",
                  width: 100,
                  align: "center" as any,
                },

                {
                  title: () => (
                    <Typography.Title level={4} style={{ color: grey[1] }}>
                      {"Input"}{" "}
                    </Typography.Title>
                  ),
                  dataIndex: formulationList[0]?.id_set?.formulation_id,
                  width: 150,
                  align: "center" as any,
                },
                // ...formulationList.flatMap(({ id_set: { formulation_id }, characterizations, meta: { display_id } }: any, index: any) => characterizations.map(({ id_set: { processing_id, characterization_id } }: any) => ({
                //     title: () => (
                //         <Space
                //             style={{ width: "100%" }}
                //             align="center"
                //             size="small"
                //             direction="vertical"
                //         >
                //             {checkbox && (
                //                 <Checkbox
                //                     checked={formulationsCheck?.includes(formulation_id)}
                //                     onChange={(e) => checkBoxChange(e, formulation_id)}
                //                 />
                //             )}

                //         </Space>
                //     ),
                //     dataIndex: formulation_id,
                //     width: 150,
                //     align: "center" as any,
                // })
                // )
                // ),
              ]}
              dataSource={characterizationsTableData?.[index] as any}
              scroll={{ x: 450 }}
            />
          </Col>
        )}

      {characterizationsTableData.length && propertiesTableData.length ? (
        <Divider />
      ) : null}

      {userAccess?.stages?.includes("properties_stage") &&
        !!propertiesTableData.length && (
          <Col style={{ marginBottom: "1rem", width: "100%" }}>
            <div className="mb-1">
              <span className="highlighted">{t("common.totalTrials")}</span> {total.length}
            </div>
            <Table
              bordered
              size="small"
              pagination={false}
              id={tableId + "_properties_stage"}
              getPopupContainer={() =>
                (document as any).getElementById(tableId + "_properties_stage")
              }
              className="report-table"
              columns={[
                {
                  title: t("common.category"),
                  dataIndex: "category",
                  filters: Array.from(
                    new Set(
                      Object.values(propertyLabels || {})?.map(
                        (res: any) => res?.category
                      )
                    )
                  )
                    .map((property) => ({
                      text: String(property),
                      value: String(property),
                    }))
                    .sort((value1, value2) =>
                      value1.text.toLowerCase() < value2.text.toLowerCase()
                        ? -1
                        : 1
                    ),
                  onFilter: (value, record) =>
                    propertyLabels?.[record?.category]?.category?.indexOf(
                      value
                    ) === 0,
                  sorter: (a, b) => a?.category?.localeCompare(b?.category),
                  sortDirections: ["ascend", "descend"],
                  fixed: "left",
                  width: 150,
                  align: "center" as any,
                  render: (text: string) => (
                    <Text style={{ wordBreak: "break-word" }}>{text}</Text>
                  ),
                },
                {
                  title: t("common.property"),
                  dataIndex: "parameter",
                  fixed: "left",
                  width: 200,
                  align: "center" as any,
                  sorter: (a, b) => a.parameter.localeCompare(b.parameter),
                  filters: Array.from(
                    new Set(
                      Object.values(propertyLabels || {})?.map(
                        (res: any) => res?.category
                      )
                    )
                  )
                    .map((property) => ({
                      text: String(property),
                      value: String(property),
                    }))
                    .sort((value1, value2) =>
                      value1.text.toLowerCase() < value2.text.toLowerCase()
                        ? -1
                        : 1
                    ),
                  onFilter: (value, record) =>
                    propertyLabels?.[record?.parameter]?.name?.indexOf(
                      value
                    ) === 0,
                  sortDirections: ["ascend", "descend"],
                  render: (text: string) => (
                    <Text
                      style={from === "drawer" ? { color: blue[6] } : {}}
                      strong
                    >
                      {propertyLabels?.[text]?.name ?? text}
                    </Text>
                  ),
                },
                {
                  title: t("common.unit"),
                  dataIndex: "unit",
                  fixed: "left",
                  width: 100,
                  align: "center" as any,
                },
                ...formulationList.flatMap(
                  (
                    {
                      id_set: { formulation_id },
                      properties,
                      meta: { display_id },
                      use_for_ml,
                    }: any,
                    index: any
                  ) =>
                    properties.map(
                      ({ id_set: { processing_id, properties_id } }: any) => ({
                        title: () => (
                          <Space
                            style={{ width: "100%" }}
                            align="center"
                            size="small"
                            direction="vertical"
                          >
                            {checkbox && (
                              <Checkbox
                                checked={formulationsCheck?.includes(
                                  formulation_id
                                )}
                                onChange={(e) =>
                                  checkBoxChange(e, formulation_id)
                                }
                              />
                            )}
                            <Typography.Title level={5} style={{ color: grey[1] }}>
                              {display_id}
                            </Typography.Title>
                            {checkbox &&
                              (use_for_ml ? (
                                <Space>
                                  <Text>{t("common.successful")}</Text>
                                  <Text>
                                    <CheckCircleTwoTone
                                      style={{ fontSize: antdTheme.fontSizeHeading3 }}
                                      twoToneColor="#52c41a"
                                    />
                                  </Text>
                                </Space>
                              ) : (
                                <Space>
                                  <Text>{t("common.failed")}</Text>
                                  <Text>
                                    <CloseCircleTwoTone
                                      style={{ fontSize: antdTheme.fontSizeHeading3 }}
                                      twoToneColor="#ff0000"
                                    />
                                  </Text>
                                </Space>
                              ))}
                          </Space>
                        ),
                        dataIndex: formulation_id,
                        width: 150,
                        align: "center" as any,
                      })
                    )
                ),
              ]}
              dataSource={propertiesTableData?.[index] as any}
              scroll={{ x: 450 }}
            />
          </Col>
        )}
    </Row>
  );
}
