
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`2水準完全要因計画`}</h1>
    <p>{`すべての要因のレベルの組み合わせが実行される`}<a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/%E5%9B%A0%E5%AD%90%E5%AE%9F%E9%A8%93"
      }}>{`因子実験`}</a>{`は、通常、完全要因実験と呼ばれます。完全要因2水準実験は、2𝑘 設計とも呼ばれ、𝑘 は実験で調査されている因子の数を示します。`}</p>
    <p>{`2𝑘 設計は、すべての因子が2つのレベルにある因子実験の特別なカテゴリです。これらの設計は、因子が2つのレベルのみであり、直交しているため、因子の数が多い場合でも解析が大幅に簡素化されます。`}</p>
    <p><strong parentName="p">{`使用例：`}</strong></p>
    <ul>
      <li parentName="ul">{`2𝑘 設計は、多くの要因を調査する必要がある実験作業の初期段階で特に有用です。`}</li>
      <li parentName="ul">{`k個の要因を研究するために必要な最小限の実行回数を提供します。`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;