import { handleActions } from "redux-actions";
import {
  QualityCheckApplyFiles,
  QualityCheckGetFiles,
  QualityCheckViewerVisibility,
  QualityCheckDeleteFile,
  QualityCheckLocalUpdatesOnFiles,
  QualityCheckFileExtractions,
  QualityCheckSaveChangesAndApply,
  QualityCheckRegisterLinkLocalUpdate,
  QualityCheckValidateLink,
  QualityCheckLinkRecurringCycle,
  QualityCheckSyncOnDemand,
  QualityCheckGetRegisterLinkData,
  QualityCheckGenerateReport,
  QualityCheckGetFilesFilters,
} from "../actions/qualityCheck";
import { AsyncStates } from "src/constants";

type registerLinkDataType = {
  link_type: 'external' | 'internal';
  drive_type: 'azure' | 'google_drive' | 'onedrive' | 'dropbox' | 'box';
  link: string;
  validation_status: 'pending' | 'success' | 'link_error' | 'access_error';
  is_recurring: boolean;
  recurring_type: 'daily' | 'weekly' | 'monthly';
  recurring_time: string;
  recurring_days_of_week: string[];
  recurring_days_of_month: string[];
}

export const registerDataInitialState: registerLinkDataType = {
  link_type: "external",
  drive_type: "azure",
  link: "",
  validation_status: "pending",
  is_recurring: false,
  recurring_type: "daily",
  recurring_time: "00:00",
  recurring_days_of_week: ["monday"],
  recurring_days_of_month: ["1"],
}

export type QualityCheckState = {
  fileNavigationOpen?: boolean;
  selectedFile?: any;
  pageNumber?: number;
  isReportSectionUnsaved?: boolean;
  registerLinkModalVisible?: boolean;
  fileDrawerVisible?: boolean;
  fileUploadModalVisible?: boolean;

  getFilesData?: any[];
  getFilesTotal?: number;
  getFilesDataStatus?: AsyncStates;
  getFilesDataError?: any;
  getFilesPageNumber: number;
  getFilesPageSize: number;
  getFilesFilters?: any;
  getFilesSerachTerm?: string;

  getFilesFiltersResponse?: any;
  getFilesFiltersStatus?: AsyncStates;
  getFilesFiltersError?: any;

  applyFilesStatus?: AsyncStates;
  applyFilesError?: any;
  applyFilesResponse?: any;

  fileExtractionsStatus?: AsyncStates;
  fileExtractionsError?: any;
  fileExtractionsResponse?: any;

  saveChangedAndApplyStatus?: AsyncStates;
  saveChangedAndApplyError?: any;
  saveChangedAndApplyResponse?: any;

  deleteFileStatus?: AsyncStates;
  deleteFileError?: any;
  deleteFileResponse?: any;

  registerLinkData: registerLinkDataType;

  getRegisterLinkStatus?: AsyncStates;
  getRegisterLinkError?: any;

  validateLinkStatus?: AsyncStates;
  validateLinkError?: any;
  validateLinkResponse?: any;

  linkRecurringCycleStatus?: AsyncStates;
  linkRecurringCycleError?: any;
  linkRecurringCycleResponse?: any;

  syncOnDemandStatus?: AsyncStates;
  syncOnDemandError?: any;
  syncOnDemandResponse?: any;

  filesToReport?: any;
  reportData?: any;
  reportStatus?: AsyncStates;
  reportError?: any;
  isSelectAllFiles?: boolean;
  filesToIgnore?: any[];
};

const defaultState: QualityCheckState = {
  fileNavigationOpen: true,
  selectedFile: {},
  pageNumber: 1,
  isReportSectionUnsaved: false,
  registerLinkModalVisible: false,
  fileDrawerVisible: false,
  fileUploadModalVisible: false,

  getFilesData: [],
  getFilesTotal: 0,
  getFilesDataStatus: AsyncStates.INITIAL,
  getFilesDataError: null,
  getFilesPageNumber: 1,
  getFilesPageSize: 20,
  getFilesFilters:{
    suppliers:[],
    categories:[],
    product_name: [],
    batch_number: '',
    status: [],
    created_at: null,
    production_date: null,
    operator: 'and'
  },
  getFilesSerachTerm:'',

  getFilesFiltersResponse: {
    suppliers:[],
    categories:[],
    product_name:[],
    status:[]
  },
  getFilesFiltersStatus: AsyncStates.INITIAL,
  getFilesFiltersError: null,

  applyFilesStatus: AsyncStates.INITIAL,
  applyFilesError: null,
  applyFilesResponse: null,

  fileExtractionsStatus: AsyncStates.INITIAL,
  fileExtractionsError: null,
  fileExtractionsResponse: null,

  saveChangedAndApplyStatus: AsyncStates.INITIAL,
  saveChangedAndApplyError: null,
  saveChangedAndApplyResponse: null,

  deleteFileStatus: AsyncStates.INITIAL,
  deleteFileError: null,
  deleteFileResponse: null,

  registerLinkData: registerDataInitialState,

  getRegisterLinkStatus: AsyncStates.INITIAL,
  getRegisterLinkError: null,

  validateLinkStatus: AsyncStates.INITIAL,
  validateLinkError: null,
  validateLinkResponse: null,

  linkRecurringCycleStatus: AsyncStates.INITIAL,
  linkRecurringCycleError: null,
  linkRecurringCycleResponse: null,

  syncOnDemandStatus: AsyncStates.INITIAL,
  syncOnDemandError: null,
  syncOnDemandResponse: null,

  filesToReport: {1:[]},
  reportData: null,
  reportStatus: AsyncStates.INITIAL,
  reportError: null,
  isSelectAllFiles: false,
  filesToIgnore: [],
}

const qualityCheckReducer = handleActions({
  [QualityCheckViewerVisibility.QC_TOGGLE_FILE_NAVIGATION]: (state: QualityCheckState) => {
    return {
      ...state,
      fileNavigationOpen: !state.fileNavigationOpen,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_SELECTED_FILE]: (state: QualityCheckState, action: any) => {
    const file_id = action.payload
    const selectedFile = state.getFilesData?.find((f: any) => String(f.file_id) === file_id)
    return {
      ...state,
      selectedFile: selectedFile,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_PAGE_NUMBER]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      pageNumber: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_IS_REPORT_SECTION_UNSAVED]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      isReportSectionUnsaved: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_REGISTER_LINK_MODAL_VISIBLE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      registerLinkModalVisible: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_FILE_DRAWER_VISIBLE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      fileDrawerVisible: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_FILE_UPLOAD_MODAL_VISIBLE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      fileUploadModalVisible: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_SELECTED_FILES_FOR_REPORT]: (state: QualityCheckState, action: any) => {
    const selectedFilesCurrentPage = { [state.getFilesPageNumber]: action.payload }
    return {
      ...state,
      filesToReport: {
        ...state.filesToReport,
        ...selectedFilesCurrentPage
      },
      // isSelectAllFiles: false,
    }
  },
  [QualityCheckViewerVisibility.QC_CLEAR_SELECTED_FILES_FOR_REPORT]: (state: QualityCheckState) => {
    return {
      ...state,
      filesToReport: {1:[]},
    }
  },
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_PAGE_NUMBER]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesPageNumber: action.payload,
      ...(state.isSelectAllFiles ? {filesToReport: {[action.payload]: state.getFilesData?.map((file:any)=>file?.file_id) }} : {}),
    }
  },
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_FILTERS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesFilters: {...state.getFilesFilters,...action.payload},
    }
  },
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_SEARCH_TERM]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesSerachTerm: action.payload,
    }
  },
  [QualityCheckViewerVisibility.QC_SET_SELECT_ALL_FILES]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      isSelectAllFiles: action.payload,
      ...(action.payload ? { filesToIgnore:[] } : {filesToReport: {1:[]}}),
    }
  },
  [QualityCheckViewerVisibility.QC_ADD_IGNORE_FILES_FOR_REPORT]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      filesToIgnore: [...new Set([...(state?.filesToIgnore || []), action.payload])],
    }
  },
  [QualityCheckViewerVisibility.QC_REMOVE_IGNORE_FILES_FOR_REPORT]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      filesToIgnore: state?.filesToIgnore?.filter((file_id) => file_id !== action.payload),
    }
  },


  [QualityCheckGetFiles.QC_GET_FILES_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      getFilesDataStatus: AsyncStates.LOADING,
    }
  },

  [QualityCheckGetFiles.QC_GET_FILES_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesData: action.payload.data,
      getFilesTotal: action.payload.total,
      getFilesDataStatus: AsyncStates.SUCCESS,
    }
  },

  [QualityCheckGetFiles.QC_GET_FILES_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesDataStatus: AsyncStates.ERROR,
      getFilesDataError: action.payload,
      getFilesPageNumber:1
    }
  },

  [QualityCheckGetFiles.QC_GET_FILES_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      getFilesData: [],
      getFilesTotal: 0,
      getFilesDataStatus: AsyncStates.INITIAL,
      getFilesDataError: null,
    }
  },


  [QualityCheckGetFilesFilters.QC_GET_FILES_FILTERS_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      getFilesFiltersStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckGetFilesFilters.QC_GET_FILES_FILTERS_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesFiltersResponse: action.payload,
      getFilesFiltersStatus: AsyncStates.SUCCESS,
    }
  },
  [QualityCheckGetFilesFilters.QC_GET_FILES_FILTERS_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getFilesFiltersStatus: AsyncStates.ERROR,
      getFilesFiltersError: action.payload,
    }
  },
  [QualityCheckGetFilesFilters.QC_GET_FILES_FILTERS_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      getFilesFiltersResponse: {
        suppliers:[],
        categories:[],
      },
      getFilesFiltersStatus: AsyncStates.INITIAL,
      getFilesFiltersError: null,
    }
  },




  [QualityCheckApplyFiles.QC_APPLY_FILES_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      applyFilesStatus: AsyncStates.LOADING,
    }
  },

  [QualityCheckApplyFiles.QC_APPLY_FILES_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      applyFilesStatus: AsyncStates.SUCCESS,
      applyFilesResponse: action.payload,
    }
  },

  [QualityCheckApplyFiles.QC_APPLY_FILES_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      applyFilesStatus: AsyncStates.ERROR,
      applyFilesError: action.payload,
    }
  },

  [QualityCheckApplyFiles.QC_APPLY_FILES_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      applyFilesStatus: AsyncStates.INITIAL,
      applyFilesError: null,
      applyFilesResponse: null,
    }
  },


  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      fileExtractionsStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      fileExtractionsStatus: AsyncStates.SUCCESS,
      fileExtractionsResponse: action.payload,
    }
  },
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      fileExtractionsStatus: AsyncStates.ERROR,
      fileExtractionsError: action.payload,
    }
  },
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      fileExtractionsStatus: AsyncStates.INITIAL,
      fileExtractionsError: null,
      fileExtractionsResponse: null,
    }
  },

  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      saveChangedAndApplyStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      saveChangedAndApplyStatus: AsyncStates.SUCCESS,
      saveChangedAndApplyResponse: action.payload,
      selectedFile: { ...state.selectedFile, qc_details: { ...action.payload } },
      getFilesData: state.getFilesData?.map((file: any) => {
        if (file.file_id === state.selectedFile?.file_id) {
          return {
            ...file,
            qc_details: { ...action.payload }
          }
        }
        return file
      })
    }
  },
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      saveChangedAndApplyStatus: AsyncStates.ERROR,
      saveChangedAndApplyError: action.payload,
    }
  },
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      saveChangedAndApplyStatus: AsyncStates.INITIAL,
      saveChangedAndApplyError: null,
      saveChangedAndApplyResponse: null,
    }
  },

  [QualityCheckDeleteFile.QC_DELETE_FILE_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      deleteFileStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckDeleteFile.QC_DELETE_FILE_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      deleteFileStatus: AsyncStates.SUCCESS,
      deleteFileResponse: action.payload,
      isSelectAllFiles: false,
    }
  },
  [QualityCheckDeleteFile.QC_DELETE_FILE_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      deleteFileStatus: AsyncStates.ERROR,
      deleteFileError: action.payload,
    }
  },
  [QualityCheckDeleteFile.QC_DELETE_FILE_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      deleteFileStatus: AsyncStates.INITIAL,
      deleteFileError: null,
      deleteFileResponse: null,
    }
  },

  [QualityCheckRegisterLinkLocalUpdate.QC_REGISTER_LINK_LOCAL_UPDATE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      registerLinkData: {
        ...state.registerLinkData,
        ...action.payload,
      }
    }
  },

  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      getRegisterLinkStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getRegisterLinkStatus: AsyncStates.SUCCESS,
      registerLinkData: action.payload,
    }
  },
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      getRegisterLinkStatus: AsyncStates.ERROR,
      getRegisterLinkError: action.payload,
    }
  },
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      getRegisterLinkStatus: AsyncStates.INITIAL,
      getRegisterLinkError: null,
    }
  },

  [QualityCheckValidateLink.QC_VALIDATE_LINK_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      validateLinkStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckValidateLink.QC_VALIDATE_LINK_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      validateLinkStatus: AsyncStates.SUCCESS,
      validateLinkResponse: action.payload,
    }
  },
  [QualityCheckValidateLink.QC_VALIDATE_LINK_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      validateLinkStatus: AsyncStates.ERROR,
      validateLinkError: action.payload,
    }
  },
  [QualityCheckValidateLink.QC_VALIDATE_LINK_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      validateLinkStatus: AsyncStates.INITIAL,
      validateLinkError: null,
      validateLinkResponse: null,
    }
  },


  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      linkRecurringCycleStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      linkRecurringCycleStatus: AsyncStates.SUCCESS,
      linkRecurringCycleResponse: action.payload,
    }
  },
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      linkRecurringCycleStatus: AsyncStates.ERROR,
      linkRecurringCycleError: action.payload,
    }
  },
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      linkRecurringCycleStatus: AsyncStates.INITIAL,
      linkRecurringCycleError: null,
      linkRecurringCycleResponse: null,
    }
  },

  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      syncOnDemandStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      syncOnDemandStatus: AsyncStates.SUCCESS,
      syncOnDemandResponse: action.payload,
    }
  },
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      syncOnDemandStatus: AsyncStates.ERROR,
      syncOnDemandError: action.payload,
    }
  },
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      syncOnDemandStatus: AsyncStates.INITIAL,
      syncOnDemandError: null,
      syncOnDemandResponse: null,
    }
  },


  [QualityCheckLocalUpdatesOnFiles.QC_LOCAL_UPDATE_FILE]: (state: QualityCheckState, action: any) => {
    const { file_id, to_update } = action.payload
    const updatedFiles = state?.getFilesData?.map((file: any) => {
      if (file.file_id === file_id) {
        return {
          ...file,
          ...to_update,
        }
      }
      return file
    })

    let updatedSelectedFile = state.selectedFile
    if (state.selectedFile?.file_id === file_id) {
      updatedSelectedFile = {
        ...updatedSelectedFile,
        ...to_update,
      }
    }

    return {
      ...state,
      getFilesData: updatedFiles,
      selectedFile: updatedSelectedFile,
    }
  },

  [QualityCheckGenerateReport.QC_GENERATE_REPORT_REQUEST]: (state: QualityCheckState) => {
    return {
      ...state,
      reportStatus: AsyncStates.LOADING,
    }
  },
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_SUCCESS]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      reportData: action.payload,
      reportStatus: AsyncStates.SUCCESS,
      isSelectAllFiles: false,
    }
  },
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_FAILURE]: (state: QualityCheckState, action: any) => {
    return {
      ...state,
      reportError: action.payload,
      reportStatus: AsyncStates.ERROR,
    }
  },
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_CLEAR]: (state: QualityCheckState) => {
    return {
      ...state,
      reportData: null,
      reportStatus: AsyncStates.INITIAL,
      reportError: null,
      filesToReport: {1:[]},
      isSelectAllFiles: false,
      filesToIgnore: [],
    }
  },
}, defaultState)

export default qualityCheckReducer;