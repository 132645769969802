import { toTitleCase } from "src/utils/general"
import { CategoricalDistributionCharts } from "../../../types"
import { antdTheme } from "src/constants"

export const preparePieChartData = (
	data: CategoricalDistributionCharts[string],
	name: string
): Highcharts.SeriesPieOptions & {
	title: string
} => {
	return {
		type: "pie",
		name: toTitleCase(name),
		data: Object.keys(data.data).map((key) => {
			return {
				name: toTitleCase(key),
				y: data.data[key],
			}
		}),
		title: toTitleCase(name),
	}
}
export const getPieChartOptions = (
	payload: Highcharts.SeriesPieOptions,
	title: string,
	getValue: any
): Highcharts.Options => {
	return {
		chart: {
			plotShadow: false,
			type: "pie",
			width: "100px",
		},
		title: { text: "" },
		tooltip: {
			useHTML: true,
			headerFormat: "<small>{series.name}</small><br/>",
			pointFormatter: function (this) {
				return `<b>${this.options.name}</b>: ${getValue(
					this.percentage
				)}%`
			},
		},
		accessibility: {
			point: {
				valueSuffix: "%",
			},
		},
		credits: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				size: "100%",
				allowPointSelect: true,
				cursor: "pointer",
				startAngle: 90,
				dataLabels: {
					distance: -40,
					crookDistance: "0%",
					crop: false,
					connectorPadding: 0,
					connectorShape: "straight",
					enabled: true,
					style: {
						fontSize: `${antdTheme.fontSize}px`,
					},
					formatter: function (this: any) {
						const pointValue = `<b>${this.key}</b>: ${getValue(this.percentage)} %`
						return pointValue
					},
				},
			},
		},
		series: [payload],
	}
}

export const prepareBarChartData = (
	data: CategoricalDistributionCharts[string],
	name: string
): Highcharts.SeriesColumnOptions & {
	title: string
	xAxisData: string[]
} => {
	return {
		type: "column",
		name: toTitleCase(name),
		data: Object.values(data.data),
		xAxisData: Object.keys(data.data),
		title: toTitleCase(name),
	}
}
export const getBarChartOptions = (
	payload: Highcharts.SeriesColumnOptions,
	title: string,
	xAxisData: string[],
	getValue: any
): Highcharts.Options => {
	return {
		chart: {
			plotShadow: false,
			type: "column",
		},
		title: { text: "" },
		xAxis: {
			categories: xAxisData,
			title: {
				text: null,
			},
		},
		tooltip: {
			useHTML: true,
			headerFormat: "<small>{series.name}</small><br/>",
			pointFormatter: function (this) {
				return `<b>${this.category}</b>: ${getValue(this.options.y)}`
			},
		},
		legend: {
			enabled: false,
		},
		yAxis: {
			min: 0,
			title: {
				text: null,
			},
			labels: {
				overflow: "justify",
			},
		},
		credits: {
			enabled: false,
		},
		plotOptions: {
			bar: {
				dataLabels: {
					enabled: false,
				},
			},
		},
		series: [payload],
	}
}
