import { createActions } from "redux-actions"
import { SuggestedExpResponse } from "../../services/suggestedExp/interface"

export enum SuggestedExpActionTypes {
	SUGGESTED_EXPERIMENTS_REQUEST = "SUGGESTED_EXPERIMENTS_REQUEST",
	SUGGESTED_EXPERIMENTS_SUCCESS = "SUGGESTED_EXPERIMENTS_SUCCESS",
	SUGGESTED_EXPERIMENTS_FAILURE = "SUGGESTED_EXPERIMENTS_FAILURE",
	SUGGESTED_EXPERIMENTS_CLEAR = "SUGGESTED_EXPERIMENTS_CLEAR",
}

export enum BuildOfMaterialsActionTypes {
	BUILD_OF_MATERIALS_REQUEST = "BUILD_OF_MATERIALS_REQUEST",
	BUILD_OF_MATERIALS_SUCCESS = "BUILD_OF_MATERIALS_SUCCESS",
	BUILD_OF_MATERIALS_FAILURE = "BUILD_OF_MATERIALS_FAILURE",
	BUILD_OF_MATERIALS_CLEAR = "BUILD_OF_MATERIALS_CLEAR",
}

export enum SuggestedExperimentsFilterActionTypes {
	SUCCESS = "SUGGESTED_EXPERIMENTS_Filter_SUCCESS",
	FAILURE = "SUGGESTED_EXPERIMENTS_Filter_FAILURE",
}

export enum ExportPredictionActionTypes {
	REQUEST = "EXPORT_PREDICTION_REQUEST",
	SUCCESS = "EXPORT_PREDICTION_SUCCESS",
	FAILURE = "EXPORT_PREDICTION_FAILURE",
	CLEAR = "EXPORT_PREDICTION_CLEAR",
}

export enum ExportBuildOfMaterialsActionTypes {
	REQUEST = "EXPORT_BUILD_OF_MATERIALS_REQUEST",
	SUCCESS = "EXPORT_BUILD_OF_MATERIALS_SUCCESS",
	FAILURE = "EXPORT_BUILD_OF_MATERIALS_FAILURE",
	CLEAR = "EXPORT_BUILD_OF_MATERIALS_CLEAR",
}

export enum ChangeTitleActionTypes {
	REQUEST = "CHANGE_TITLE_REQUEST",
	SUCCESS = "CHANGE_TITLE_SUCCESS",
	FAILURE = "CHANGE_TITLE_FAILURE",
	CLEAR = "CHANGE_TITLE_CLEAR",
}

export const {
	suggestedExperimentsRequest,
	suggestedExperimentsSuccess,
	suggestedExperimentsFailure,
	suggestedExperimentsClear,
	buildOfMaterialsRequest,
	buildOfMaterialsSuccess,
	buildOfMaterialsFailure,
	buildOfMaterialsClear,
	exportPredictionRequest,
	exportPredictionSuccess,
	exportPredictionFailure,
	exportPredictionClear,
	exportBuildOfMaterialsRequest,
	exportBuildOfMaterialsSuccess,
	exportBuildOfMaterialsFailure,
	exportBuildOfMaterialsClear,
	changeTitleRequest,
	changeTitleSuccess,
	changeTitleFailure,
	changeTitleClear,
	suggestedExperimentsFilterSuccess,
	suggestedExperimentsFilterFailure
} = createActions({
	[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_REQUEST]: (payload) => payload,
	[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_SUCCESS]: (
		response: SuggestedExpResponse
	) => response,
	[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_FAILURE]: (error: string) => ({
		error,
	}),
	[SuggestedExpActionTypes.SUGGESTED_EXPERIMENTS_CLEAR]: () => { },
	[BuildOfMaterialsActionTypes.BUILD_OF_MATERIALS_REQUEST]: (payload) => payload,
	[BuildOfMaterialsActionTypes.BUILD_OF_MATERIALS_SUCCESS]: (
		response: SuggestedExpResponse
	) => response,
	[BuildOfMaterialsActionTypes.BUILD_OF_MATERIALS_FAILURE]: (error: string) => ({
		error,
	}),
	[BuildOfMaterialsActionTypes.BUILD_OF_MATERIALS_CLEAR]: () => { },
	[ExportPredictionActionTypes.REQUEST]: (payload) => payload,
	[ExportPredictionActionTypes.SUCCESS]: (response) => response,
	[ExportPredictionActionTypes.FAILURE]: error => ({ error }),
	[ExportPredictionActionTypes.CLEAR]: () => ({}),
	[ExportBuildOfMaterialsActionTypes.REQUEST]: (payload) => payload,
	[ExportBuildOfMaterialsActionTypes.SUCCESS]: (response) => response,
	[ExportBuildOfMaterialsActionTypes.FAILURE]: error => ({ error }),
	[ExportBuildOfMaterialsActionTypes.CLEAR]: () => ({}),
	[ChangeTitleActionTypes.REQUEST]: (payload) => payload,
	[ChangeTitleActionTypes.SUCCESS]: (response) => response,
	[ChangeTitleActionTypes.FAILURE]: error => ({ error }),
	[ChangeTitleActionTypes.CLEAR]: () => ({}),
	[SuggestedExperimentsFilterActionTypes.SUCCESS]: (response: SuggestedExpResponse) => response,
	[SuggestedExperimentsFilterActionTypes.FAILURE]: (error: string) => ({ error }),
})
