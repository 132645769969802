import { useMemo } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Card, Form, Row, Col, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { celsureWoCreateRequest } from "src/store/actions/celsureWo";
import { useMemberName } from "src/utils/useMemberName";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";

const CelsureWo = () => {
  const [form] = Form.useForm();
  const [t] = useTranslate();
  const requiredFieldStar = useRequiredFieldStar()

  const dispatch = useDispatch();
  const { current, projectList: projects } = useSelector(
    (state: StoreState) => state.projects
  );
  const currentProject = useMemo(
    () => projects.find((project: any) => project.project_id === current),
    [projects, current]
  );
  const { getName } = useMemberName();
  const { celsureCreateWoStatus } = useSelector(
    (state: StoreState) => state.celsureWo
  );

  const submitWorkOrder = (values: any) => {
    dispatch(celsureWoCreateRequest({ ...values, project_id: current }));
  };

  return (
    <>
      <StyledPageHeader
        ghost={false}
        title={t("common.createWorkOrder")}
        onBack={() => window.history.back()}
      />
      <Spin
        spinning={celsureCreateWoStatus === AsyncStates.LOADING}
        indicator={<LoadingOutlined />}
      >
        <Card title={t("workOrderDetails.details")} headStyle={{paddingLeft:16, paddingRight:16}} bodyStyle={{padding:'24px 16px'}} >
          <Form layout="vertical" form={form} onFinish={submitWorkOrder} requiredMark={false}>
            <Row justify="start">
              <Col span={12}>
                <Form.Item
                  name="work_order_name"
                  label={t("common.title")}
                  rules={[{ required: true, message: t("common.required") }]}
                  required 
                  tooltip={requiredFieldStar}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
                <Form.Item
                  name="work_order_desc"
                  label={t("common.description")}
                >
                  <Input.TextArea placeholder={t("editProject.enterDescription")} rows={3} />
                </Form.Item>
                <Form.Item
                  required={false}
                  label={"Members"}
                  name="work_order_dri"
                >
                  <Select
                    placeholder="Select members"
                    mode="multiple"
                    options={currentProject?.members
                      ?.map(({ user_id }: any) => user_id)
                      ?.concat(currentProject.project_owner)
                      ?.map((member: any) => ({
                        key: member,
                        value: member,
                        label: getName(member),
                      }))}
                  />
                </Form.Item>
                <Row justify="end">
                  <StyledButton type="primary" htmlType="submit">
                    {t("common.save")}
                  </StyledButton>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};
export default CelsureWo;
