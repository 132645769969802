import { Col, Collapse, Form, Input, message, Row, Select, Space, Spin } from "antd"
import { Page, Text, View, Document, StyleSheet, PDFViewer, Font, Image, pdf } from '@react-pdf/renderer';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { fetchExperimentCleanup, fetchLinkedExperimentCleanup, fetchLinkedExperimentRequest } from "src/store/actions/experiment";
import { antdTheme, AsyncStates } from "src/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { plotsDataClear, plotsDataRequest } from "src/store/actions/workOrderDetails";
import { StyledButton } from "src/styled_components/StyledButton";
import { DatasheetTable } from "../Celsure/UploadForm/DatasheetTable";
import { getDropdownFilteredValue } from "src/utils/decorator";
import { reportUploadRequest } from "src/store/actions/compareFormulations";
import dayjs from "dayjs";
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"


Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf' }
    ]
})

const FOOTER_CONSTANT = {
    footer_2: `Pluss Advanced Technologies Ltd.
B-205, Tower B – Pioneer Urban Square, Sec 62, Gurugram-122101, Haryana, India
Telephone: +91 - 124 - 4309490/91/92
E-mail: info@pluss.co.in | Web: www.pluss.co`,
    footer_4: `The information given here is meant as a guide to determining suitability of our products for a desired application. It is based on tests carried out by our aboratories and data selected from literature and shall in no event be held to constitute or imply any warranty. The products are intended for use in industrial applications. The users should test the materials before use and satisfy themselves with regard to contents and suitability in the desired application. Our formal specifications define the limits of our commitment. Recommlendation herein may not be construed as freedom to infringe/operate under any third party patents. In the event of a proven claim, our liability is limited only to replacement of our material and in no case shall we be liable for special, incidental or consequential damages arising out of usage of our material. This datasheet is subject to change without notice. For detailed safety and handling information regarding these products, please refer to Safety Data Sheet and Bulking handling instruction which is available on request.`
}

const HEADER_LINK = "https://s3.ap-southeast-1.amazonaws.com/polymerize-uploads/COMPMK46SE20L72021/uploads/header.png"
const FOOTER_LINK = "https://s3.ap-southeast-1.amazonaws.com/polymerize-uploads/COMPMK46SE20L72021/uploads/footer_1.png"

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        justifyContent: "space-between",
        backgroundColor: '#ffffff',
    },
    mainContainer: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        marginRight: 10,
        marginLeft: 10,
        marginTop: 5,
        padding: 10,
    },
    sectionDescriptionHeader: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSizeSM
    },
    sectionDescription: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    sectionDescriptionText: {
        fontFamily: 'Open Sans',
        fontWeight: "thin",
        fontSize: antdTheme.fontSizeSM,
    },
    footer: {
        marginTop: 5
    },
    footerContainer: {
        marginTop: 5,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    title: {
        fontFamily: 'Open Sans',
        fontWeight: "bold",
        fontSize: antdTheme.fontSizeHeading4,
    },
    footerText: {
        fontFamily: 'Open Sans',
        fontWeight: "thin",
        fontSize: antdTheme.fontSizeSM
    },
    imageContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5,
        marginBottom: 5
    },
    headerImageContainer: {
        marginTop: 5,
        marginBottom: 5
    },
    imageView: {
        maxWidth: 270,
        maxHeight: "550px",
        borderStyle: "solid",
        borderWidth: 1,
    },
    table: {
        display: "table",
        width: "auto",
        borderColor: "#ffffff",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: "auto",
        marginBottom: "0.5rem",
        flexDirection: "row",
        backgroundColor: "#F5F5F5"
    },
    tableCategoryRow: {
        borderStyle: "solid",
        borderBottomWidth: 1,
        borderColor: "#ffffff",
    },
    tableHeaderRow: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: "#C0C0C0",
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        display: "flex",
        flexDirection: "row",
    },
    tableColTestConditions: {
        width: "33%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#ffffff",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        display: "flex",
        flexDirection: "row"
    },
    tableColValue: {
        width: "15%",
        borderStyle: "solid",
        borderColor: "#ffffff",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        display: "flex",
        flexDirection: "row",
    },
    tableCell: {
        margin: "auto",
        // marginTop: 4,
        fontSize: antdTheme.fontSizeSM,
        flex: 1,
        textAlign: "left",
        marginLeft: 2,
        marginRight: 2
    },
    tableHeader: {
        margin: "auto",
        marginTop: 4,
        fontSize: antdTheme.fontSizeSM,
        fontFamily: 'Open Sans',
        fontWeight: "extrabold",
        color: "white",
        flex: 1,
        textAlign: "left",
        marginLeft: 2,
        marginRight: 2
    }
})


export const Tds = () => {
    const dispatch = useDispatch()

    const { data: workOrdersList, status: workOrdersStatus } = useSelector((state: StoreState) => state.workOrders)
    const { linkedExperiment: experimentsList, linkedExperimentStatus, plotsData, plotsDataStatus } = useSelector((state: StoreState) => state.workOrderDetails)
    const reportUploadStatus = useSelector((state: StoreState) => state.compareFormulations.uploadStatus)
    const [plots, setPlots] = useState<any>({ temperaturePlot: "", enthalpyPlot: "" })
    const [graphImages, setGraphImages] = useState<any>({ temperaturePlot: "", enthalpyPlot: "" })
    const [table, setTable] = useState<any>([
        { category: "Phase transition temperature", property: "Melting", value: null, unit: "°C", testing_method: "PLUSS® T-History", testing_conditions: null },
        { category: "Phase transition temperature", property: "Freezing", value: null, unit: "°C", testing_method: "PLUSS® T-History", testing_conditions: null },
        { category: "Phase transition temperature", property: "Nucleation temperature", value: null, unit: "°C", testing_method: "PLUSS® T-History", testing_conditions: null },
        { category: "Latent heat/enthalpy", property: "Melting", value: null, unit: "kJ/kg", testing_method: "PLUSS® T-History", testing_conditions: null },
        { category: "Latent heat/enthalpy", property: "Freezing", value: null, unit: "kJ/kg", testing_method: "PLUSS® T-History", testing_conditions: null },
        { category: "Density", property: "Liquid", value: null, unit: "kg/m", testing_method: "ASTM D891-95", testing_conditions: null },
        { category: "Density", property: "Solid", value: null, unit: "kg/m\u00B3", testing_method: "ASTM D891-95", testing_conditions: null },
        { category: "Specific heat", property: "Liquid", value: null, unit: "kj/kgK", testing_method: "PLUSS® T-History", testing_conditions: null },
        { category: "Specific heat", property: "Solid", value: null, unit: "kj/kgK", testing_method: "PLUSS® T-History", testing_conditions: null },
        { category: "Thermal conductivity", property: "Liquid", value: null, unit: "W/mK", testing_method: "KD2Pro", testing_conditions: null },
        { category: "Thermal conductivity", property: "Solid", value: null, unit: "W/mK", testing_method: "KD2Pro", testing_conditions: null },
        { category: "-", property: "Number of cycles tested", value: null, testing_method: "PLUSS® Internal", testing_conditions: null },
        { category: "-", property: "Maximum operating temperature", value: null, unit: "°C", testing_method: null, testing_conditions: null },
        { category: "-", property: "Flammability", value: null, testing_method: null, testing_conditions: null },
        // { category: "-", property: "Flash point", value: null, testing_method: "PLUSS® T-History", testing_conditions: null },
        // { category: "-", property: "Base material", value: null, testing_method: null, testing_conditions: null },
        // { category: "-", property: "Congurent melting", value: null, testing_method: null, testing_conditions: null },
        // { category: "-", property: "Cycling stability", value: null, testing_method: "PLUSS® T-History", testing_conditions: null },
    ])
    const [formState, setFormState] = useState<any>(FOOTER_CONSTANT)
    const [generate, setGenerate] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>(dayjs().format('DD-MM-YYYY; HH:mm'))
    const [form] = Form.useForm()
    const ref1: any = useRef()
    const ref2: any = useRef()

    useEffect(() => {
        return () => {
            dispatch(fetchExperimentCleanup())
            dispatch(fetchLinkedExperimentCleanup())
            dispatch(plotsDataClear())
        }
    }, [dispatch])

    useEffect(() => {
        if ((!!plots?.temperaturePlot && !!ref1?.current?.chart) || (!!plots?.enthalpyPlot && !!ref2?.current?.chart)) {
            setTimeout(() => {
                (async () => {
                    const graphs = await Promise.all(
                        Object.entries(plots || {}).map(async ([key, value]: any, index: any) => {
                            if (value) {
                                let svgDoms: any = document?.getElementsByClassName(
                                    "highcharts-root" || ""
                                )
                                svgDoms[index]?.setAttribute("id", "plot" + index)
                                const canvas = document.createElement("canvas")
                                const ctx = canvas.getContext("2d")
                                if (!!svgDoms?.length) {
                                    const svgXml = new XMLSerializer()?.serializeToString(svgDoms[index])
                                    canvas.width = svgDoms[index]?.width?.baseVal?.value
                                    canvas.height = svgDoms[index]?.height?.baseVal?.value
                                    const img = document.createElement("img")
                                    img.setAttribute(
                                        "src",
                                        "data:image/svg+xml;base64," +
                                        window.btoa(unescape(encodeURIComponent(svgXml)))
                                    )
                                    try {
                                        await img.decode()
                                        ctx?.drawImage(
                                            img,
                                            0,
                                            0,
                                            svgDoms[index]?.width?.baseVal?.value,
                                            svgDoms[index]?.height?.baseVal?.value
                                        )
                                        return Promise.resolve({
                                            ...{ [key]: canvas.toDataURL("image/png", 1.0) },
                                        })
                                    } catch (error) {
                                        return Promise.reject()
                                    }
                                }
                            }
                        })
                    )
                    setGraphImages(graphs.reduce((obj: any, element: any) => ({
                        ...obj, [Object.keys(element || {})?.[0]]: Object.values(element || {})?.[0]
                    }), {}))
                })()
            }, 2000)
        } else {
            setGraphImages({ temperaturePlot: "", enthalpyPlot: "" })
        }
    }, [plots])

    useEffect(() => {
        if (plotsDataStatus === AsyncStates.SUCCESS) {
            (async () => {
                const selectedTrial = Object.keys(plotsData?.line || {})?.[0] || Object.keys(plotsData?.bar || {})?.[0]
                let temperaturePlot: any, enthalpyPlot: any
                if (selectedTrial) {
                    temperaturePlot = (Object.keys(plotsData?.line))?.length > 0 && {
                        chart: {
                            type: 'line',
                            height: "550px"
                        },
                        title: {
                            text: 'Temperature vs time curve', style: { fontSize: "1.5em", fontWeight: 'bold' }
                        },
                        legend: { itemStyle: { fontSize: "1.5em" } },
                        xAxis: {
                            title: {
                                text: 'Time in Minutes',
                                style: { fontSize: "1.5em", fontWeight: 'bold' }
                            },
                            labels: { style: { fontSize: "1.5em", fontWeight: 'bold' } },
                            categories: plotsData?.line?.[selectedTrial]?.time,
                            tickInterval: 100
                        },
                        yAxis: {
                            title: {
                                text: 'Temperature in °C', style: { fontSize: "1.5em", fontWeight: 'bold' }
                            },
                            labels: { style: { fontSize: "1.5em", fontWeight: 'bold' } },
                        },
                        series: [{
                            type: "line",
                            data: plotsData?.line?.[selectedTrial]?.bath,
                            name: 'Bath',
                            color: "#b3050e"
                        }, {
                            type: "line",
                            data: plotsData?.line?.[selectedTrial]?.trial_data,
                            name: 'Temperature',
                            color: "#0559b3"

                        }]
                    }
                    enthalpyPlot = (Object.keys(plotsData?.bar))?.length > 0 && {
                        chart: {
                            type: 'column',
                            height: "550px"
                        },
                        title: {
                            text: 'Enthalpy vs temperature curve', style: { fontSize: "1.5em", fontWeight: 'bold' }
                        },
                        legend: { itemStyle: { fontSize: "1.5em" } },
                        xAxis: {
                            title: {
                                text: 'Temperature in °C', style: { fontSize: "1.5em", fontWeight: 'bold' }
                            },
                            labels: { style: { fontSize: "1.5em", fontWeight: 'bold' } },
                            categories: plotsData?.bar?.[selectedTrial]?.trial_data
                        },
                        yAxis: {
                            title: {
                                text: 'Enthalpy in J/g', style: { fontSize: "1.5em", fontWeight: 'bold' }
                            },
                            labels: { style: { fontSize: "1.5em", fontWeight: 'bold' } },
                        },
                        series: [{
                            type: "column",
                            data: plotsData?.bar?.[selectedTrial]?.enthalpy_freezing,
                            name: 'Enthalpy-Melting',
                            color: "#0559b3"
                        }, {
                            type: "column",
                            data: plotsData?.bar?.[selectedTrial]?.enthalpy_melting,
                            name: 'Enthalpy-Freezing',
                            color: "#b3050e"
                        }]
                    }
                }
                setPlots({ temperaturePlot, enthalpyPlot })
            }
            )()
        } else {
            setPlots({ temperaturePlot: "", enthalpyPlot: "" })
        }
    }, [plotsData, plotsDataStatus])

    const onWorkOrderChange = (e: string) => {
        const currentWo = workOrdersList.find((res: any) => res?.work_order_id === e)
        form.setFieldsValue({ experiment: null })
        setPlots({ temperaturePlot: "", enthalpyPlot: "" })
        dispatch(fetchLinkedExperimentRequest({ experiment_id: currentWo?.experiment_id, linked_stages: true }))
    }

    const onExperimentChange = async (e: string) => {
        dispatch(plotsDataRequest({ formulation_id: [e] }))
    }
    const pdfDocument = (
        <Document title="TDS doc">
            <Page size="A4" style={styles.page} orientation="portrait" key={'page_'} wrap>
                <View style={styles.mainContainer}>
                    <View style={styles.header}>
                        <Text style={styles.title}>{`TECHNICAL DATASHEET OF ${formState?.product || ""}`}</Text>
                    </View>
                    <View style={styles.headerImageContainer}>
                        <Image
                            src={`${HEADER_LINK}?not-from-cache-please`}
                            style={{
                                maxWidth: "100%",
                            }}
                        />
                    </View>
                    <Text style={styles.sectionDescriptionHeader}>{"Technical Specifications:"}</Text>
                    <View style={styles.sectionDescription}>
                        <View style={styles.sectionDescriptionText}>
                            <Text>{'Product :'}</Text>
                            <Text>{"Description :"}</Text>
                            <Text>{"Appearance :"}</Text>
                        </View>
                        <View style={{ ...styles.sectionDescriptionText, marginLeft: 20 }}>
                            <Text>{formState?.product}</Text>
                            <Text>{formState?.description}</Text>
                            <Text>{formState?.appearence}</Text>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.sectionDescriptionHeader}>{formState?.note}</Text>
                    </View>
                    <View style={{ ...styles.imageContainer, justifyContent: graphImages?.temperaturePlot && graphImages?.enthalpyPlot ? "space-between" : "center", }}>
                        {!!graphImages?.temperaturePlot &&
                            <View style={styles.imageView}>
                                <Image src={graphImages.temperaturePlot}
                                    style={{
                                        maxWidth: 270,
                                    }}
                                />
                            </View>
                        }
                        {!!graphImages?.enthalpyPlot &&
                            <View style={styles.imageView}>
                                <Image src={graphImages.enthalpyPlot}
                                    style={{
                                        maxWidth: 270,
                                    }}
                                />
                            </View>
                        }
                    </View>
                    {!!table?.length &&
                        <View style={styles.table}>
                            <View style={styles.tableHeaderRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>{"Property"}</Text>
                                </View>
                                <View style={styles.tableColValue}>
                                    <Text style={styles.tableHeader}>{"Value**"}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>{"Test method"}</Text>
                                </View>
                                <View style={styles.tableColTestConditions}>
                                    <Text style={styles.tableHeader}>{"Test conditions (if any)"}</Text>
                                </View>
                            </View>
                            {["Phase transition temperature", "Latent heat/enthalpy", "Density", "Specific heat", "Thermal conductivity"].map((category: any) => {
                                const currentcategoryRows = table.filter((res: any) => res?.category === category)
                                return (
                                    <View style={styles.tableCategoryRow}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{category}</Text>
                                            </View>
                                            <View style={styles.tableColValue}></View>
                                            <View style={styles.tableCol}></View>
                                            <View style={styles.tableColTestConditions}></View>
                                        </View>
                                        {currentcategoryRows.map((row: any) => (
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{row.property}</Text>
                                                </View>
                                                <View style={styles.tableColValue}>
                                                    <Text style={styles.tableCell}>{`${row.value || ""}${row?.value ? row?.unit : ""}`}</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>{row.testing_method}</Text>
                                                </View>
                                                <View style={styles.tableColTestConditions}>
                                                    <Text style={styles.tableCell}>{row.testing_conditions}</Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>
                                )
                            })}
                            {table.filter((row: any) => row?.category === "-").map((row: any) => {
                                return (
                                    <View style={styles.tableCategoryRow}>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={{ ...styles.tableCell, fontWeight: "bold" }}>{row.property}</Text>
                                            </View>
                                            <View style={styles.tableColValue}>
                                                <Text style={styles.tableCell}>{row.value}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{row.testing_method}</Text>
                                            </View>
                                            <View style={styles.tableColTestConditions}>
                                                <Text style={styles.tableCell}>{row.testing_conditions}</Text>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    }
                    <View style={styles.footer}>
                        <Text style={styles.footerText}>
                            {formState?.footer_1}
                        </Text>
                    </View>
                    <View style={styles.footerContainer}>
                        <Text style={styles.footerText}>
                            {formState?.footer_2}
                        </Text>
                        <Text style={styles.footerText} >
                            {formState?.footer_3}
                        </Text>
                    </View>
                    <View style={styles.footer}>
                        <Text style={styles.footerText}>
                            {formState?.footer_4}
                        </Text>
                    </View>
                </View>
                <Image src={FOOTER_LINK + "?not-from-cache-please"} />
            </Page>
        </Document>
    )


    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Row justify={"space-between"}>
                <Col span={16}>
                    <Spin
                        spinning={[workOrdersStatus, linkedExperimentStatus, plotsDataStatus].includes(AsyncStates.LOADING)}
                        indicator={<LoadingOutlined />}
                    >
                        <Form name="tds" layout="vertical" form={form}
                            initialValues={FOOTER_CONSTANT}
                            onFieldsChange={(_, allFields) => {
                                setFormState(allFields
                                    .filter((field: any) => !["experiment", "work_order"].includes(field?.name[0]))
                                    .reduce((obj: any, field: any) => ({ ...obj, [field.name[0]]: field?.value }), {})
                                )
                            }}>
                            <Form.Item label={"Work order"} name={"work_order"}>
                                <Select onChange={onWorkOrderChange} showSearch
                                    filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}
                                >
                                    {workOrdersList.map((res: any) => (
                                        <Select.Option key={res?.work_order_id} value={res?.work_order_id}>
                                            {res?.work_order_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={"Experiment"} name={"experiment"}>
                                <Select onChange={onExperimentChange} showSearch
                                    filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}
                                >
                                    {experimentsList.map((res: any) => (
                                        <Select.Option key={res?.formulation_id} value={res?.formulation_id}>
                                            {res?.formulation_display_id}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={"Product"} name={"product"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Description"} name={"description"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Appearence"} name={"appearence"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Note"} name={"note"}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={"Footer 1"} name={"footer_1"}>
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item label={"Footer 2"} name={"footer_2"}>
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item label={"Footer 3"} name={"footer_3"}>
                                <Input.TextArea rows={4} />
                            </Form.Item>
                            <Form.Item label={"Footer 4"} name={"footer_4"}>
                                <Input.TextArea rows={8} />
                            </Form.Item>
                        </Form>
                    </Spin>
                </Col>
            </Row>
            <DatasheetTable data={table} setData={setTable} title={"Properties"} type={"tds_properties"} hideActions={true} />
            <Collapse onChange={(e) => setGenerate(Boolean(e?.length))}>
                <Collapse.Panel header={"Preview"} key={"preview"} extra={
                    <Space onClick={(e) => e.stopPropagation()}>
                        <Input style={{ width: 250 }} value={fileName} onChange={e => setFileName(e.target.value)} />
                        <StyledButton loading={reportUploadStatus === AsyncStates.LOADING} onClick={() => {
                            if (!fileName) {
                                message.error("Please add a file name")
                                return
                            }
                            pdf(pdfDocument).toBlob().then((report) => {
                                dispatch(reportUploadRequest({
                                    report,
                                    type: "tds",
                                    file_name: fileName,
                                    data: formState,
                                    from: "modularReports"
                                }))
                            })
                        }} type="primary">{"Save"}</StyledButton>
                    </Space>
                }>
                    {generate &&
                        <PDFViewer style={{ width: "100%", height: 1280 }}>
                            {pdfDocument}
                        </PDFViewer>
                    }
                </Collapse.Panel>
            </Collapse>
            <div id="graph" style={{ display: "none" }}>
                {!!plots?.temperaturePlot &&
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={plots?.temperaturePlot}
                        ref={ref1}
                    />}
                {!!plots?.enthalpyPlot &&
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={plots?.enthalpyPlot}
                        ref={ref2}
                    />}
            </div>
        </Space>
    )
}