import { put, takeLatest, call, select } from "redux-saga/effects"
import {
    progressRequest,
    progressFailure,
    progressSuccess
} from "../actions/widget"
import jwtManager from "src/utils/jwtManager"
import { progressApi } from "src/services/widget"

type progressAction = {
    type: string
    payload: any
}

function* widgetSaga(action: progressAction): Generator<any, any, any> {
    try {
        const { defaultHeaders } = yield select((state) => state)
        const headers = { ...defaultHeaders, token: jwtManager.getToken() }
        const {
            data
        } = yield call(progressApi, action.payload, headers)

        yield put(progressSuccess(data))

    } catch (error) {
        yield put(progressFailure(error))
    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(progressRequest, widgetSaga)
}
