import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Space, Statistic, Tabs, Typography, Table, Col, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';
import { StyledCard } from 'src/styled_components/StyledCard';
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader';
import { useMemberName } from 'src/utils/useMemberName';
import useTranslate from 'src/utils/useTranslate';

const { Text } = Typography
const { TabPane } = Tabs


export const UploadPreview = () => {
    const { getName } = useMemberName()
    const { data } = useSelector((state: StoreState) => state.files.uploadFile)
    const [t] = useTranslate()

    const [entireData, setEnitreData] = useState<any>({
        columns: { formulations: [], processing: [], properties: [], characterisation: [] },
        data: { formulations: [], processing: [], properties: [], characterisation: [] }
    })

    useEffect(() => {
        const { uploaded_data: { formulations, processing, characterisation, properties } } = data
        setEnitreData({
            columns: {
                formulations: Object.keys(formulations?.[0] || {}).map((res: any) => ({
                    dataIndex: res,
                    title: ["ingredients", "ingredients_category", "ingredients_unit"].includes(res) ? res?.charAt(0)?.toUpperCase() + res?.slice(1) : res,
                    key: res,
                })),
                processing: Object.keys(processing?.[0] || {}).map((res: any) => ({
                    dataIndex: res,
                    title: ["processing_parameters", "processing_category", "processing_unit"].includes(res) ? res?.charAt(0)?.toUpperCase() + res?.slice(1) : res,
                    key: res,
                })),
                properties: Object.keys(properties?.[0] || {}).map((res: any) => ({
                    dataIndex: res,
                    title: ["property_parameters", "property_category", "property_method", "property_standard", "property_specimen", "property_unit"].includes(res) ? res?.charAt(0)?.toUpperCase() + res?.slice(1) : res,
                    key: res
                })),
                characterisation: Object.keys(characterisation?.[0] || {}).map((res: any) => ({
                    dataIndex: res,
                    title: ["characterisation_type", "characterisation_category", "characterisation_method", "characterisation_parameters", "characterisation_unit"].includes(res) ? res?.charAt(0)?.toUpperCase() + res?.slice(1) : res,
                    key: res
                }))
            },
            data: { formulations, processing, properties, characterisation }
        })
    }, [data])


    return (
        <Space direction="vertical" style={{ width: "100%", marginBottom: 10 }} size="large">
            <StyledPageHeader>
                <Space direction="vertical" style={{ width: "100%" }} size="large">
                    <Row>
                        <Text>{data?.file_name}</Text>
                    </Row>
                    <Row gutter={30}>
                        <Col>
                            <Statistic title={t("common.fileSize")} value={`${data?.file_size || ""} kb`} valueStyle={{ fontSize: "1rem" }} />
                        </Col>
                        <Col>
                            <Statistic title={t("common.dateUpated")} value={new Date(data?.date_uploaded).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })} valueStyle={{ fontSize: "1rem" }} />
                        </Col>
                        <Col>
                            <Statistic title={t("common.uploadedBy")} value={getName(data?.uploaded_by)} valueStyle={{ fontSize: "1rem" }} />
                        </Col>
                        <Col>
                            <Statistic title={<Space>
                                <Text>{t("uploadPreview.noOfExperiments")}</Text>
                                <Tooltip title={t("uploadPreview.successfullyUploaded")}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Space>} value={data?.no_of_experiments} valueStyle={{ fontSize: "1rem" }} />
                        </Col>
                    </Row>
                </Space>
            </StyledPageHeader>
            <StyledCard>
                <Tabs size="large" >
                    {!!entireData?.data?.formulations?.length &&
                        <TabPane tab={t("common.formulations")} key="formulations" style={{ width: "100%", overflow: "auto" }}>
                            <Table columns={entireData?.columns?.formulations} dataSource={entireData?.data?.formulations} bordered pagination={false} />
                        </TabPane>}
                    {!!entireData?.data?.processing?.length &&
                        <TabPane tab={t("common.process")} key="process" style={{ width: "100%", overflow: "auto" }}>
                            <Table columns={entireData?.columns?.processing} dataSource={entireData?.data?.processing} bordered pagination={false} />
                        </TabPane>}
                    {!!entireData?.data?.characterisation?.length &&
                        <TabPane tab={t("common.characterisation")} key="characterisation" style={{ width: "100%", overflow: "auto" }}>
                            <Table columns={entireData?.columns?.characterisation} dataSource={entireData?.data?.characterisation} bordered pagination={false} />
                        </TabPane>}
                    {!!entireData?.data?.properties?.length &&
                        <TabPane tab={t("formulations.type.properties")} key="properties" style={{ width: "100%", overflow: "auto" }}>
                            <Table columns={entireData?.columns?.properties} dataSource={entireData?.data?.properties} bordered pagination={false} />
                        </TabPane>}
                </Tabs>
            </StyledCard>
        </Space>)
}
