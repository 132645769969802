import { handleActions } from "redux-actions";
import {
  ListAlgorithmsActionTypes, GenrateNewExperimentsActionTypes, UploadDoeExperimentsActionTypes,
  DoeHistoryListActionTypes, GetDoeExperimentsActionTypes, DeleteDoeActionTypes, EditDoeActionTypes, OtherLocalStuff
} from "../actions/doe";
import { AsyncStates } from "../../constants";


export type DoeReducerState = {
  listAlgorithmsStatus: AsyncStates
  listAlgorithmsData: any
  generateExperimentStatus: AsyncStates
  generateExperimentData: any
  uploadDoeStatus: AsyncStates
  doeHistoryListStatus: AsyncStates
  doeHistoryData: any
  doeExperimentsStatus: AsyncStates
  doeExperimentsData: any
  deleteDoeStatus: AsyncStates
  editDoeStatus: AsyncStates
  selectedAlgorithm: any,
  formData: any,
  additionalParams: any,
  ingredientsFactors: any,
  ingredientsConstantData: any,
  processingFactors: any,
  processingConstanstData: any,
  taguchiSelectedLevel: any,
  paramsCheck: any,
  factorsCheck: any,
  factorModalCheck: any,
}

const defaultState: DoeReducerState = {
  listAlgorithmsStatus: AsyncStates.INITIAL,
  listAlgorithmsData: {},
  generateExperimentStatus: AsyncStates.INITIAL,
  generateExperimentData: { experiments: [] },
  uploadDoeStatus: AsyncStates.INITIAL,
  doeHistoryListStatus: AsyncStates.INITIAL,
  doeHistoryData: { total: 0 },
  doeExperimentsStatus: AsyncStates.INITIAL,
  doeExperimentsData: {},
  deleteDoeStatus: AsyncStates.INITIAL,
  editDoeStatus: AsyncStates.INITIAL,
  selectedAlgorithm: {},
  formData: {},
  additionalParams: {
    alpha: "orthogonal",
    face: "circumscribed",
    reduction: 2,
    samples: "",
    centers: 1,
  },
  ingredientsFactors: [],
  ingredientsConstantData: [],
  processingFactors: [],
  processingConstanstData: [],
  taguchiSelectedLevel: {
    selectedLevel: 2,
    isMixedLevelModalOpen: false,
    seletedLevelData: null
  },
  paramsCheck: false,
  factorsCheck: true,
  factorModalCheck: "",
}

const doeReducer = handleActions(
  {
    [GenrateNewExperimentsActionTypes.REQUEST]: (state) => ({
      ...state,
      generateExperimentData: { experiments: [] },
      generateExperimentStatus: AsyncStates.LOADING,
    }),
    [GenrateNewExperimentsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      generateExperimentData: action.payload,
      generateExperimentStatus: AsyncStates.SUCCESS,
    }),
    [GenrateNewExperimentsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      generateExperimentData: { experiments: [] },
      generateExperimentStatus: AsyncStates.ERROR,
    }),
    [ListAlgorithmsActionTypes.REQUEST]: (state) => ({
      ...state,
      listAlgorithmsStatus: AsyncStates.LOADING,
      listAlgorithmsData: {},
    }),
    [ListAlgorithmsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      listAlgorithmsStatus: AsyncStates.SUCCESS,
      listAlgorithmsData: action.payload,
    }),
    [ListAlgorithmsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      listAlgorithmsStatus: AsyncStates.ERROR,
      listAlgorithmsData: {},
    }),
    [UploadDoeExperimentsActionTypes.REQUEST]: (state) => ({
      ...state,
      uploadDoeStatus: AsyncStates.LOADING
    }),
    [UploadDoeExperimentsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      uploadDoeStatus: AsyncStates.SUCCESS
    }),
    [UploadDoeExperimentsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      uploadDoeStatus: AsyncStates.ERROR
    }),
    [UploadDoeExperimentsActionTypes.CLEANUP]: (state, action) => ({
      ...state,
      uploadDoeStatus: AsyncStates.INITIAL
    }),
    [DoeHistoryListActionTypes.REQUEST]: (state) => ({
      ...state,
      doeHistoryListStatus: AsyncStates.LOADING,
      doeHistoryData: { total: 0 },
    }),
    [DoeHistoryListActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      doeHistoryListStatus: AsyncStates.SUCCESS,
      doeHistoryData: action.payload,
    }),
    [DoeHistoryListActionTypes.FAILURE]: (state, action) => ({
      ...state,
      doeHistoryListStatus: AsyncStates.ERROR,
      doeHistoryData: { total: 0 },
    }),
    [GetDoeExperimentsActionTypes.REQUEST]: (state) => ({
      ...state,
      doeExperimentsStatus: AsyncStates.LOADING,
      doeExperimentsData: {},
    }),
    [GetDoeExperimentsActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      doeExperimentsStatus: AsyncStates.SUCCESS,
      doeExperimentsData: action.payload,
    }),
    [GetDoeExperimentsActionTypes.FAILURE]: (state, action) => ({
      ...state,
      doeExperimentsStatus: AsyncStates.ERROR,
      doeExperimentsData: {}
    }),
    [DeleteDoeActionTypes.CLEANUP]: (state) => ({
      ...state,
      deleteDoeStatus: AsyncStates.INITIAL
    }),
    [DeleteDoeActionTypes.REQUEST]: (state) => ({
      ...state,
      deleteDoeStatus: AsyncStates.LOADING
    }),
    [DeleteDoeActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      deleteDoeStatus: AsyncStates.SUCCESS
    }),
    [DeleteDoeActionTypes.FAILURE]: (state, action) => ({
      ...state,
      deleteDoeStatus: AsyncStates.ERROR
    }),
    [EditDoeActionTypes.REQUEST]: (state) => ({
      ...state,
      editDoeStatus: AsyncStates.LOADING
    }),
    [EditDoeActionTypes.SUCCESS]: (state, action) => ({
      ...state,
      editDoeStatus: AsyncStates.SUCCESS
    }),
    [EditDoeActionTypes.FAILURE]: (state, action) => ({
      ...state,
      editDoeStatus: AsyncStates.ERROR
    }),
    [OtherLocalStuff.SELECT_ALGO]: (state, action) => ({
      ...state,
      selectedAlgorithm: action.payload,
    }),
    [OtherLocalStuff.SET_FORM_DATA]: (state, action) => ({
      ...state,
      formData: action.payload,
    }),
    [OtherLocalStuff.SET_ADDITIONAL_PARAMS]: (state, action) => ({
      ...state,
      additionalParams: action.payload,
    }),
    [OtherLocalStuff.SET_INGREDIENTS_FACTORS]: (state, action) => ({
      ...state,
      ingredientsFactors: action.payload,
    }),
    [OtherLocalStuff.SET_INGREDIENTS_CONSTANTS]: (state, action) => ({
      ...state,
      ingredientsConstantData: action.payload,
    }),
    [OtherLocalStuff.SET_PROCESSING_FACTORS]: (state, action) => ({
      ...state,
      processingFactors: action.payload,
    }),
    [OtherLocalStuff.SET_PROCESSING_CONSTANTS]: (state, action) => ({
      ...state,
      processingConstanstData: action.payload,
    }),
    [OtherLocalStuff.SET_TAGUCHI_SELECTED_LEVEL]: (state, action) => ({
      ...state,
      taguchiSelectedLevel: action.payload,
    }),
    [OtherLocalStuff.SET_PARAMS_CHECK]: (state, action) => ({
      ...state,
      paramsCheck: action.payload,
    }),
    [OtherLocalStuff.SET_FACTORS_CHECK]: (state, action) => ({
      ...state,
      factorsCheck: action.payload,
    }),
    [OtherLocalStuff.SET_FACTORS_MODAL_CHECK]: (state, action) => ({
      ...state,
      factorModalCheck: action.payload,
    }),
    [OtherLocalStuff.CLEAR_OTHER_STUFF]: (state) => ({
      ...state,
      selectedAlgorithm: {},
      formData: {},
      additionalParams: {
        alpha: "orthogonal",
        face: "circumscribed",
        reduction: 2,
        samples: "",
        centers: 1,
      },
      ingredientsFactors: [],
      ingredientsConstantData: [],
      processingFactors: [],
      processingConstanstData: [],
      taguchiSelectedLevel: {
        selectedLevel: 2,
        isMixedLevelModalOpen: false,
        seletedLevelData: null
      },
      paramsCheck: false,
      factorsCheck: true,
      factorModalCheck: "",
    }),
  },
  defaultState
)

export default doeReducer
