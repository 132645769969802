export const similarDetection = (inputValues) => {
  const existingCombinations = inputValues.combinations
  const currentCombination = inputValues.combination
  if (!currentCombination.propertyId && !currentCombination.methodId && !currentCombination.standard && !currentCombination.specimen) return false
  const found = !!Object.values(existingCombinations)
    // eslint-disable-next-line eqeqeq
    .filter(para => para.propertyId == currentCombination.propertyId)
    // eslint-disable-next-line eqeqeq
    .filter(para => para.methodId == currentCombination.methodId)
    // eslint-disable-next-line eqeqeq
    .filter(para => para.standard == currentCombination.standard)
    // eslint-disable-next-line eqeqeq
    .filter(para => para.specimen == currentCombination.specimen)
    .length
  return found
}