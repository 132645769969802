import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Space,
    Card,
    Spin,
    Row,
    Col,
    Tooltip,
    Avatar,
    Descriptions,
    Divider,
    Typography,
    Tag,
    Modal,
    Dropdown,
    Menu,
} from "antd";
import { celsureWoDeleteRequest, celsureWoListRequest } from "src/store/actions/celsureWo"
import { StoreState } from "src/store/configureStore"
import { antdTheme, AsyncStates } from "src/constants"
import { CalendarOutlined, LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { red } from "@ant-design/colors"
import { resetWorkOrder } from "src/store/actions/workOrderDetails"
import useTranslate from "src/utils/useTranslate"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
import { StyledButton } from "src/styled_components/StyledButton"

const { Text, Title, Paragraph } = Typography

export const WorkOrdersPage = () => {

    const dispatch = useDispatch()
    const { push } = useHistory()

    const { current } = useSelector((state: StoreState) => state.projects)
    const teamsData = useSelector((state: StoreState) => state.teams.data)
    const { celsureDisplayNamesData, celsureDisplayNamesStatus } = useSelector((state: StoreState) => state.celsure)
    const { celsureWoListStatus, celsureWoList, celsureDeleteWoStatus } = useSelector((state: StoreState) => state.celsureWo)
    const [woListData, setWoListData] = useState(celsureWoList)
    const [woDetails, setWoDetails] = useState<any>({})
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [tab, setTab] = useState("all")
    const [t] = useTranslate()

    useEffect(() => {
        if (tab === "all") {
            setWoListData(celsureWoList)
        } else {
            setWoListData(celsureWoList.filter((wo: any) => wo.work_order_stage === tab))
        }
    }, [tab, celsureWoList])

    useEffect(() => {
        dispatch(celsureWoListRequest({ project_id: current }))
        setTab("all")
    }, [dispatch, current])

    useEffect(() => {
        if (celsureDeleteWoStatus === AsyncStates.SUCCESS) {
            setDeleteModalVisible(false)
        }
    }, [dispatch, celsureDeleteWoStatus])

    useEffect(() => {
        setWoListData(celsureWoList)
    }, [celsureWoList])


    const menu = (wo: any) => (
        <Menu>
            <Menu.Item key={2}>
                <StyledButton
                    type="link"
                    icon={<StyledDeleteIcon style={{ color: red[3] }} />}
                    onClick={e => { e?.stopPropagation(); setWoDetails(wo); setDeleteModalVisible(true) }}
                >{t("common.deleteWorkOrder")}</StyledButton>
            </Menu.Item>
        </Menu >
    )

    return (
        <>
            <Spin spinning={celsureWoListStatus === AsyncStates.LOADING || celsureDisplayNamesStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Card style={{ minHeight: 'calc(100vh - 300px)' }}
                    tabList={[{ key: "all", tab: "All Stages" }, ...Object.entries(celsureDisplayNamesData?.stages || {}).map(([key, tab]) => ({ key, tab: String(tab), }))]}
                    onTabChange={(e) => setTab(e)}
                    activeTabKey={tab}
                >
                    <Row>
                        {woListData?.map((wo: any) => {
                            return (
                                <Col>
                                    <Card
                                        style={{ width: 300, background: "#F8F8F8", margin: 5 }}
                                        onClick={() => {
                                            dispatch(resetWorkOrder());
                                            push(`/work-orders/details/${wo.work_order_id}`)
                                        }}
                                        hoverable
                                        actions={[]}
                                    >
                                        <Row justify="space-between">
                                            <Title
                                                level={4}
                                                style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                                                title={wo.work_order_name}
                                            >
                                                {wo.work_order_name}
                                            </Title>
                                            <Avatar.Group
                                                maxCount={2}
                                                maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                                            >
                                                {wo.work_order_dri?.map(
                                                    (memberId: string) => (
                                                        <Tooltip title={teamsData.find((member: any) => member.user_id === memberId)?.user_name}>
                                                            <Avatar
                                                                key={memberId}
                                                                style={{ backgroundColor: "#f56a00", }}
                                                                src={teamsData.find((member: any) => member.user_id === memberId)?.child_user_image_url}
                                                            >
                                                                {teamsData.find((member: any) => member.user_id === memberId)?.user_name?.slice(0, 3)?.toUpperCase()}
                                                            </Avatar>
                                                        </Tooltip>
                                                    )
                                                )}
                                            </Avatar.Group>
                                        </Row>
                                        <Divider style={{ margin: 0, marginBottom: 16 }} />
                                        <Descriptions
                                            column={1}
                                            size="small"
                                            style={{
                                                height: 80,
                                                textOverflow: "ellipsis",
                                            }}
                                        >
                                            <Descriptions.Item label={<Text strong> {t("common.description")}</Text>}>
                                                <Paragraph ellipsis={{ rows: 2, symbol: 'more', tooltip: wo.work_order_desc }}>
                                                    {wo.work_order_desc}
                                                </Paragraph>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                                            <Tag color="blue" icon={<CalendarOutlined />}>
                                                {t("common.createdOn")} :{dayjs(wo.created).format("YYYY-MM-DD")}
                                            </Tag>
                                            <Dropdown key="more" overlay={menu(wo)}>
                                                <StyledButton
                                                    type="link"
                                                    style={{
                                                    border: "none",
                                                    padding: 0,
                                                    color:'black', 
                                                    outline:'none'
                                                    }}
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <MoreOutlined
                                                        style={{
                                                            fontSize: antdTheme.fontSizeHeading3,
                                                            verticalAlign: "top",
                                                        }}
                                                    />
                                                </StyledButton>
                                            </Dropdown>
                                        </Space>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Card>
            </Spin>
            <Modal width={600} open={deleteModalVisible} onCancel={() => setDeleteModalVisible(false)}
                footer={
                    <Spin indicator={<LoadingOutlined />} size="large" spinning={celsureDeleteWoStatus === AsyncStates.LOADING}>
                        <Space direction="vertical">
                            <StyledButton type="primary" ghost danger
                                onClick={() => dispatch(celsureWoDeleteRequest({ work_order_id: woDetails?.work_order_id, delete_formulations: false }))}>
                                {t('celsure.workOrders.onlyDeleteWOKeepAssociatedTrials')}
                            </StyledButton>
                            <StyledButton type="primary" ghost danger
                                onClick={() => dispatch(celsureWoDeleteRequest({ work_order_id: woDetails?.work_order_id, delete_formulations: true }))}
                            >{t("workOrder.deleteWithAssociatedTrialTrial")}</StyledButton>
                            <StyledButton type="primary" ghost
                                onClick={() => setDeleteModalVisible(false)}>
                                {t("common.cancel")}
                            </StyledButton>
                        </Space>
                    </Spin>}
            >
                <Title level={5}>{`${t("workOrder.confirmDelete")} ${woDetails?.work_order_name || ""} ?`}</Title>
            </Modal>
        </>
    )
}
