import React, { Dispatch, useEffect } from "react"
import { Form, Input, message, Modal, Row, Space, Spin } from "antd"
import useTranslate from "src/utils/useTranslate"
import { StoreState } from "src/store/configureStore"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates } from "src/constants"
import {
	createNewFilterCleanup,
	createNewFilterRequest,
	getSavedFiltersAdd,
} from "src/store/actions/saveFormulationsFilters"
import { useMesssage } from "src/utils/hooks"
import { LoadingOutlined } from "@ant-design/icons"
import { StyledButton } from "src/styled_components/StyledButton"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"

type P = {
	visible: boolean
	setVisible: Dispatch<React.SetStateAction<boolean>>
	filters: object
	filter_type?: 'formulation' | 'inventory'
}

export const NewFilterForm = ({ setVisible, visible, filters, filter_type="formulation" }: P) => {
	const [t] = useTranslate()
	const requiredFieldStar = useRequiredFieldStar()
	const [form] = Form.useForm<{
		name: string
		description: string
	}>()
	const { m } = useMesssage()
	const saveFormulationsFilterRes = useSelector(
		(state: StoreState) => state.saveFormulationsFilters
	)
	const dispatch = useDispatch()

	useEffect(() => {

		if (saveFormulationsFilterRes.error) {
			message.info(saveFormulationsFilterRes.error)
			dispatch(createNewFilterCleanup())
		}
		if (saveFormulationsFilterRes.status === AsyncStates.SUCCESS) {
			dispatch(getSavedFiltersAdd(saveFormulationsFilterRes.data))
			form.resetFields()
			setVisible(false)
			dispatch(createNewFilterCleanup())
		}
	}, [saveFormulationsFilterRes, form, setVisible, dispatch, m])

	const handleSaveFilters = () => {
		dispatch(
			createNewFilterRequest({
				name: form.getFieldValue("name"),
				description: form.getFieldValue("description"),
				filter_type,
				query: filters,
			})
		)
	}

	function handleCancel() {
		setVisible(false)
		form.resetFields()
	}

	return (
		<Modal
			title={t('common.createNewFilter')}
			visible={visible}
			onCancel={handleCancel}
			footer={null}
		>
			<Spin
				spinning={saveFormulationsFilterRes.status === AsyncStates.LOADING}
				indicator={<LoadingOutlined />}
			>
				<Form layout="vertical" form={form} onFinish={handleSaveFilters} requiredMark={false} >
					<Form.Item
						name="name"
						label={t("common.name")}
						rules={[
							{
								required: true,
								message: "Please input the filter name!",
								transform: (value) => value?.trim(),
							},
						]}
						required tooltip={requiredFieldStar}
					>
						<Input placeholder={t("common.title")} required />
					</Form.Item>

					<Form.Item
						name="description"
						label={t("common.description")}
						// rules={[
						// 	{
						// 		required: true,
						// 		message: "Please input the filter description!",
						// 		transform: (value) => value?.trim(),
						// 	},
						// ]}
						// required
						//  tooltip={requiredFieldStar}
					>
						<Input.TextArea placeholder={t("common.description")} />
					</Form.Item>
					<Row justify="end" style={{ marginTop: 40 }}>
						<Space>
							<StyledButton type="text" onClick={handleCancel}>
								{t('common.cancel')}
							</StyledButton>
							<StyledButton htmlType="submit" type="primary">
								{`${t('common.create')} ${t('common.filter')}`}
							</StyledButton>
						</Space>
					</Row>
				</Form>
			</Spin>
		</Modal>
	)
}
