import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import { AuthMagicLinkActionTypes, LoginViaMagicLinkActionTypes } from "../actions/loginViaMagicLink";

export type LoginViaMagicLinkState = {
    loginViaMagicLinkStatus: AsyncStates;
    loginViaMagicLinkError: string;
    loginViaMagicLinkData: any;
    authMagicLinkStatus: AsyncStates;
    authMagicLinkError: string;
    authMagicLinkData: any;
};

const defaultState: LoginViaMagicLinkState = {
    loginViaMagicLinkStatus: AsyncStates.INITIAL,
    loginViaMagicLinkError: "",
    loginViaMagicLinkData: null,
    authMagicLinkStatus: AsyncStates.INITIAL,
    authMagicLinkError: "",
    authMagicLinkData: null,
}

const loginViaMagicLinkReducer = handleActions<any>({
    [LoginViaMagicLinkActionTypes.REQUEST]: (state) => {
        return ({
            ...state,
            loginViaMagicLinkStatus: AsyncStates.LOADING,
            loginViaMagicLinkError: "",
        })
    },
    [LoginViaMagicLinkActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            loginViaMagicLinkStatus: AsyncStates.SUCCESS,
            loginViaMagicLinkError: "",
            loginViaMagicLinkData: action.payload,
        };
    },
    [LoginViaMagicLinkActionTypes.FAILURE]: (state, action) => {
        return {
            ...state,
            loginViaMagicLinkStatus: AsyncStates.ERROR,
            loginViaMagicLinkError: action.payload,
        }
    },
    [LoginViaMagicLinkActionTypes.CLEANUP]: (state, action: any) => {
        return {
            ...state,
            loginViaMagicLinkStatus: AsyncStates.INITIAL,
            loginViaMagicLinkError: "",
            loginViaMagicLinkData: null
        }
    },
    [AuthMagicLinkActionTypes.REQUEST]: (state) => {
        return ({
            ...state,
            authMagicLinkStatus: AsyncStates.LOADING,
            authMagicLinkError: "",
        })
    },
    [AuthMagicLinkActionTypes.SUCCESS]: (state, action: any) => {
        return {
            ...state,
            authMagicLinkStatus: AsyncStates.SUCCESS,
            authMagicLinkError: "",
        };
    },
    [AuthMagicLinkActionTypes.FAILURE]: (state, action: any) => ({
        ...state,
        authMagicLinkStatus: AsyncStates.ERROR,
        authMagicLinkError: action.payload,
    }),
    [AuthMagicLinkActionTypes.CLEANUP]: (state, action: any) => ({
        ...state,
        authMagicLinkStatus: AsyncStates.INITIAL,
        authMagicLinkError: "",
        authMagicLinkData: null

    }),
}, defaultState);

export default loginViaMagicLinkReducer;