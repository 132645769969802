import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { Space, Spin, Table } from 'antd';
import { motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { celsureDisplayNamesRequest } from 'src/store/actions/celsure'
import { StoreState } from 'src/store/configureStore'
import { StyledCard } from 'src/styled_components/StyledCard'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import useTranslate from 'src/utils/useTranslate'
import { InventoryModal } from './InventoryModal'
import { StyledButton } from 'src/styled_components/StyledButton'


export const Inventory = () => {
    const dispatch = useDispatch()
    const { celsureDisplayNamesData, celsureDisplayNamesStatus, celsureInventoryStatus } = useSelector((state: StoreState) => state.celsure)
    const [modalVisible, setModalVisible] = useState<any>({ visible: false, identifer: "" })
    const [t] = useTranslate()

    useEffect(() => {
        dispatch(celsureDisplayNamesRequest())
    }, [dispatch])

    const columns = [
        {
            dataIndex: "type",
            title: t("common.type"),
            key: "type"
        },
        {
            dataIndex: "values",
            title: t('common.values'),
            key: "values"
        },
        {
            dataIndex: "edit",
            title: "",
            key: "edit",
            width: 100,
            align: "center" as any,
            render: (text: any, record: any) => {
                return (
                    <StyledButton size='small' ghost type="link"
                        onClick={() => setModalVisible({ visible: true, identifier: record?.identifier })} icon={<EditOutlined />}>
                        {""}
                    </StyledButton>
                )
            }
        }
    ]

    const tableData = useMemo(() => {
        return [
            { type: "Insulation Material Type", identifier: "insulation_type", values: Object.values(celsureDisplayNamesData?.insulation_type || {}).map(({ name }: any) => name).join(", ") },
            { type: "PCM Type", identifier: "pcm_type", values: Object.values(celsureDisplayNamesData?.pcm_type || {}).map(({ name }: any) => name).join(", ") },
            { type: "PCM Size Type", identifier: "size_type", values: Object.values(celsureDisplayNamesData?.size_type || {}).join(", ") },
            { type: "Tab Type", identifier: "tab_type", values: Object.values(celsureDisplayNamesData?.tab_type || {}).join(", ") },
            // { type: "Material", identifier: "material", values: Object.values(celsureDisplayNamesData?.material || {}).join(", ") },
        ]
    }, [celsureDisplayNamesData])


    return (
        <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ type: 'just' }}
        >
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                <StyledPageHeader ghost={false} title={"Inventory"} />
                <Spin indicator={<LoadingOutlined />}
                    spinning={celsureDisplayNamesStatus === AsyncStates.LOADING || celsureInventoryStatus === AsyncStates.LOADING}>
                    <StyledCard bodyStyle={{ padding: "8px 16px" }}>
                        <Table bordered columns={columns} dataSource={tableData} pagination={false} />
                    </StyledCard>
                </Spin>
            </Space>
            <InventoryModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
        </motion.div >
    )
}
