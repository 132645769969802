import { Select } from 'antd';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';

export const UnitSelection = ({ baseUnits, paramIndex, setbaseUnits }: any) => {
  const unit = useMemo(() => baseUnits?.[paramIndex], [baseUnits, paramIndex])
  const unitList = useSelector((state: StoreState) => state.conversion.unitList)
  const getUnitOptions = useCallback((currentUnit) => {
    const currentUnitCategory = unitList.find(({ name: unit }) => unit === currentUnit)?.category
    const categoryUnits = unitList.filter(({ category }) => category === currentUnitCategory)
    return categoryUnits?.map(({ name: value }) => {
      const formula = unitList.find((res: any) => res.name === unit)?.conversion_metric?.[`${unit}_to_${value}`]
      return ({ label: value, value, disabled: !formula })
    })
  }, [unit, unitList])
  const options = getUnitOptions(unit)

  const onSelect = (value: string) => {
    setbaseUnits((state: any) => {
      state[paramIndex] = value
      return [...state]
    })
  }

  return (
    <Select
      onMouseDown={e => e.stopPropagation()}
      onSelect={onSelect}
      popupMatchSelectWidth={false}
      autoFocus
      style={{ width: "100%" }}
      showSearch
      showArrow={false}
      bordered={false}
      value={unit}
      options={options}
    />
  );
}
