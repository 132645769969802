import { useState } from "react";
import "./Units.scss";
import useTranslate from "src/utils/useTranslate";
import NoData from "../Common/NoData";
import TabHeader from "../Common/TabHeader";
import UnitDetails from "./UnitDetails";
import { useDispatch, useSelector } from "react-redux";
import { AddUnitModal } from "src/components/UnitsConversion/modals";
import { unitListRequest } from "src/store/actions/conversion";
import { StoreState } from "src/store/configureStore";

const Units = () => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>();
    const unitsList = useSelector(
        (state: StoreState) => state.conversion.unitList
    );
    const dispatch = useDispatch();

    return (
        <div className="unit-container">
            {unitsList.length === 0 ? (
                <NoData
                    title={`${t("repository.totalUnits")} (0)`}
                    btns={[t("units.addNewUnit"), t("repository.uploadBulkUnits")]}
                    addItem={setShowModal}
                />
            ) : (
                <div className="content-container">
                    <TabHeader
                        title={`${t("repository.totalUnits")} (${unitsList.length})`}
                        addItem={() => setShowModal(true)}
                        setSearchTerm={setSearchTerm}
                    />
                    <UnitDetails searchTerm={searchTerm} />
                </div>
            )}
            <AddUnitModal
                refetch={() => dispatch(unitListRequest({ category: "" }))}
                isAddNewUnitModal={showModal}
                closeModal={() => setShowModal(false)}
            />
        </div>
    );
};

export default Units;
