import { Modal, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { fetchProjectListRequest } from 'src/store/actions/projects'
import { revokeUserAccessRequest } from 'src/store/actions/teams'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'


const { Title } = Typography
type RevokeAccessModalTypes = {
    revokeModalInfo: any,
    setRevokeModalInfo: any
}
export const RevokeAccessModal = ({ revokeModalInfo, setRevokeModalInfo }: RevokeAccessModalTypes) => {
    const dispatch = useDispatch()
    const [t] = useTranslate();
    const { revokeUserAccessStatus } = useSelector((state: StoreState) => state.teams)

    useEffect(() => {
        if (revokeUserAccessStatus === AsyncStates.SUCCESS) {
            dispatch(fetchProjectListRequest({ set: true }))
            setRevokeModalInfo({
                isModalVisible: false,
                revokeModalData: {}
            })
        }
    }, [revokeUserAccessStatus, setRevokeModalInfo, dispatch])

    const handleCancelAction = () => {
        setRevokeModalInfo({
            isModalVisible: false,
            revokeModalData: {}
        })
    }

    const handleRevokeAccess = () => {
        dispatch(revokeUserAccessRequest({ email: revokeModalInfo.revokeModalData.user_email }))
    }

    return (
        <Modal title={null} open={revokeModalInfo.isModalVisible} footer={null} onCancel={() => handleCancelAction()}>
            <div>
                <div>
                    <Title level={4}>{`${t("form.suspend.confirm")} ${revokeModalInfo.revokeModalData.user_name} (${revokeModalInfo.revokeModalData.user_email})?`}</Title>
                </div>

                <div>
                    {t("form.suspend.info")}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: "flex-end", gap: "0.7rem", paddingTop: "1rem" }}>
                <StyledButton onClick={() => handleCancelAction()}>{t("common.cancel")}</StyledButton>
                <StyledButton type="primary" danger onClick={() => handleRevokeAccess()} loading={revokeUserAccessStatus === AsyncStates.LOADING}>{t("common.confirm")}</StyledButton>
            </div>
        </Modal>
    )
}
