import { sendDelete, sendGet, sendPost, sendPut } from "src/utils/sendApi"

export const assetLibraryGetContentApi = (folder_id:string='root') => sendGet(`v1/data/asset_library/get_content?folder_id=${folder_id}`)

export const assetLibraryAddFolderApi = sendPost(`v1/data/asset_library/create_folder`)

export const assetLibraryAddFileApi = (id:string='root') => sendPost(`v1/data/asset_library/create_file?parent_id=${id}`)

export const assetLibraryRenameApi = sendPut(`v1/data/asset_library/rename`)

export const assetLibraryDeleteApi = sendDelete(`v1/data/asset_library/delete`)

export const assetLibraryDownloadApi = sendPost(`v1/data/asset_library/download`)

export const assetLibraryPasteApi = sendPost(`v1/data/asset_library/paste`)

export const assetLibraryMoveApi = sendPut(`v1/data/asset_library/move`)
