import { Drawer, message, Upload, UploadProps } from 'antd'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { StyledCard } from 'src/styled_components/StyledCard'
// import './AssetLibrary.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  assetLibraryAddFileRequest,
  assetLibraryAddFolderRequest,
  assetLibraryCopyDirectories,
  assetLibraryDeleteRequest,
  assetLibraryDownloadRequest,
  assetLibraryGetContentRequest,
  assetLibraryMoveRequest,
  assetLibraryPasteRequest,
  assetLibraryRenameRequest,
  assetLibrarySetBreadcrumb,
  assetLibrarySetCurrentDirectory,
  assetLibrarySetSelectedDirectories,
  assetLibraryUpdateBreadcrumb
} from 'src/store/actions/assetLibrary'
import { StoreState } from 'src/store/configureStore'
import RenameFolderModal from './RenameFolderModal'
import SelectAssetModal from './SelectAssetModal'
import DocumentViewer from './DocumentViewer'
import { ChonkyActions, ChonkyIconName, defineFileAction, FileArray, FullFileBrowser } from 'chonky';
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import TopHeader from './TopHeader'
import { useHistory, useLocation } from 'react-router-dom'
import { UploadRef } from 'antd/es/upload/Upload'
// Somewhere in your `index.ts`:
setChonkyDefaults({ iconComponent: ChonkyIconFA });

const AssetLibrary = () => {
  // Utilities
  const dispatch = useDispatch()
  const { push } = useHistory()
  const location = useLocation()

  // States
  const [openFileViewer, setOpenFileViewer] = useState(false)
  const [fileViewerUrl, setFileViewerUrl] = useState('')

  const isImage = useMemo(() => ["png", "jpg"].some(ext => fileViewerUrl.includes(ext)), [fileViewerUrl])
  const [addFolderModalVisible, setAddFolderModalVisible] = useState(false)
  const [renameFolderModalVisible, setRenameFolderModalVisible] = useState(false)
  const [openSelectAssetModal, setOpenSelectAssetModal] = useState(false)

  // Selectors
  const {
    assetLibraryCurrentDirectory,
    assetLibrarySelectedDirectories,
    assetLibraryGetContentResponse,
    assetLibraryBreadcrumb,
    assetLibraryCopiedDirectories,
    assetLibraryFolderNavigateSelectedDirectory,
    assetLibraryFolderNavigateCurrentDirectory
  } = useSelector((state: StoreState) => state.assetLibrary)

  // Effects

  useEffect(() => {
    dispatch(assetLibraryGetContentRequest({ folder_id: assetLibraryCurrentDirectory ?? "root" }))
  }, [assetLibraryCurrentDirectory, dispatch])

  // Helper functions
  const openDirectory = useCallback(
    (
      asset_id: string,
      name: string,
      isFile: boolean = false,
      fileUrl = '',
      isAdding: boolean = true
    ) => {
      if (isFile) {
        push(`${location.pathname}?folder=${assetLibraryCurrentDirectory}&file=${asset_id}`)
        setOpenFileViewer(true)
        setFileViewerUrl(fileUrl)
        return
      }

      dispatch(
        assetLibraryUpdateBreadcrumb({
          asset_id,
          ...(isAdding ? { name } : {})
        })
      )

      dispatch(assetLibrarySetCurrentDirectory(asset_id))

      dispatch(assetLibraryGetContentRequest({ folder_id: asset_id }))

      dispatch(assetLibrarySetSelectedDirectories([]))

    },
    [assetLibraryCurrentDirectory, dispatch, location.pathname, push]
  )

  const selectDirectories = useCallback(
    (selection: any[]) => {
      dispatch(assetLibrarySetSelectedDirectories(selection))
    },
    [dispatch]
  )

  const copyDirectories = useCallback(() => {
    dispatch(assetLibraryCopyDirectories())
  }, [dispatch])

  const deleteDirectories = useCallback(() => {
    for (const asset_id of assetLibrarySelectedDirectories) {
      dispatch(
        assetLibraryDeleteRequest({
          asset_id
        })
      )
    }
  }, [assetLibrarySelectedDirectories, dispatch])

  const downloadDirectories = useCallback(() => {
    let asset_ids = []
    for (const asset_id of assetLibrarySelectedDirectories) {
      asset_ids.push(asset_id)
    }
    dispatch(assetLibraryDownloadRequest({ asset_ids }))
  }, [assetLibrarySelectedDirectories, dispatch])

  const pasteDirectories = useCallback(() => {
    dispatch(
      assetLibraryPasteRequest({
        ids: [
          ...(assetLibraryCopiedDirectories?.files || [])?.map(
            (f: any) => f.asset_id
          ),
          ...(assetLibraryCopiedDirectories?.folders || [])?.map(
            (f: any) => f.asset_id
          )
        ],
        to: assetLibraryCurrentDirectory
      })
    )
  }, [assetLibraryCurrentDirectory, assetLibraryCopiedDirectories, dispatch])

  const renameDirectories = useCallback(() => {
    if (assetLibrarySelectedDirectories.length === 1) {
      setRenameFolderModalVisible(true)
    }
  }, [assetLibrarySelectedDirectories])

  const [filesToMove, setFilesToMove] = useState([])

  const onFolderSelect = useCallback(() => {

    if (
      filesToMove?.length > 0 &&
      (assetLibraryFolderNavigateSelectedDirectory || assetLibraryFolderNavigateCurrentDirectory)
    ) {
      dispatch(
        assetLibraryMoveRequest({
          ids: filesToMove,
          to: assetLibraryFolderNavigateSelectedDirectory || assetLibraryFolderNavigateCurrentDirectory
        })
      )
    }
    setOpenSelectAssetModal(false)
  }, [assetLibraryFolderNavigateCurrentDirectory, assetLibraryFolderNavigateSelectedDirectory, dispatch, filesToMove])

  const moveDirectories = useCallback(() => {
    if (assetLibrarySelectedDirectories.length > 0) {
      setOpenSelectAssetModal(true)
      setFilesToMove(assetLibrarySelectedDirectories)
    }
  }, [assetLibrarySelectedDirectories])

  const onAddFolder = useCallback(
    (folderName: string) => {
      dispatch(
        assetLibraryAddFolderRequest({
          name: folderName,
          parent_id: assetLibraryCurrentDirectory,
          type: 'default'
        })
      )
    },
    [assetLibraryCurrentDirectory, dispatch]
  )

  const onRenameFolder = useCallback(
    (updatedName: string, assetId: string) => {
      dispatch(
        assetLibraryRenameRequest({
          name: updatedName,
          asset_id: assetId
        })
      )
    },
    [dispatch]
  )

  const files: FileArray = useMemo(() => [
  ]
    .concat(assetLibraryGetContentResponse.files.map((file: any) => ({
      id: file.asset_id,
      name: file.name,
      modDate: file.updated,
      size: file.size,
      ...((file.thumbnailUrl && ["png", "jpg"].includes(file.type)) ? { thumbnailUrl: file.thumbnailUrl } : {})
    })))
    .concat(assetLibraryGetContentResponse.folders.map((file: any) => ({
      id: file.asset_id,
      name: file.name,
      modDate: file.updated,
      thumbnailUrl: file.thumbnailUrl,
      isDir: true
    }))), [assetLibraryGetContentResponse.files, assetLibraryGetContentResponse.folders]);

  const folderChain = useMemo(() => assetLibraryBreadcrumb.map((path: any) => ({ id: path.asset_id, name: path.name, isDir: true })), [assetLibraryBreadcrumb]);

  const renameFiles = defineFileAction({
    id: 'rename_files',
    requiresSelection: true,
    hotkeys: ['ctrl+i'],
    button: {
      name: 'Rename',
      toolbar: false,
      contextMenu: true,
      icon: ChonkyIconName.text,
    },
  })

  const pasteFiles = defineFileAction({
    id: 'paste_files',
    requiresSelection: false,
    hotkeys: ['ctrl+v'],
    button: {
      name: 'Paste',
      toolbar: false,
      contextMenu: true,
      icon: ChonkyIconName.paste,
    },
  })

  const moveFiles = defineFileAction({
    id: 'move_files',
    requiresSelection: true,
    hotkeys: ['ctrl+x'],
    button: {
      name: 'Move',
      toolbar: false,
      contextMenu: true,
      icon: ChonkyIconName.folder,
    },
  })

  const copyLink = defineFileAction({
    id: 'copy_link',
    requiresSelection: true,
    hotkeys: ['ctrl+x'],
    button: {
      name: 'Copy link',
      toolbar: false,
      contextMenu: true,
      icon: ChonkyIconName.share,
    },
  })

  const fileActions = [
    //custom
    renameFiles,
    ChonkyActions.CreateFolder,
    ChonkyActions.UploadFiles,
    ChonkyActions.CopyFiles,
    moveFiles,
    pasteFiles,
    ChonkyActions.DeleteFiles,
    ChonkyActions.DownloadFiles,
    copyLink,
    //default
    // ChonkyActions.OpenSelection,
    ChonkyActions.SelectAllFiles,
    ChonkyActions.ClearSelection,
    ChonkyActions.EnableListView,
    // TODO: Don't enable until compact view is fully supported
    // ChonkyActions.EnableCompactView,
    ChonkyActions.EnableGridView,
    ChonkyActions.SortFilesByName,
    ChonkyActions.SortFilesBySize,
    ChonkyActions.SortFilesByDate,
    // ChonkyActions.ToggleHiddenFiles,
    ChonkyActions.ToggleShowFoldersFirst,
    ChonkyActions.FocusSearchInput,
  ]

  useEffect(() => {
    const data = new URLSearchParams(location.search)
    if (data.has("reset")) {
      const breadcrumb = [{ asset_id: "root", name: "Library" }]
      dispatch(assetLibrarySetBreadcrumb(breadcrumb))
      dispatch(assetLibrarySetCurrentDirectory("root"))
      dispatch(assetLibraryGetContentRequest({ folder_id: "root" }))
      push(`${location.pathname}`)
    }
  }, [dispatch, location.pathname, location.search, push])

  useEffect(() => {
    const data = new URLSearchParams(location.search)
    if (data.has("folder")) {
      dispatch(assetLibrarySetCurrentDirectory(data.get("folder")))
      dispatch(assetLibraryGetContentRequest({ folder_id: data.get("folder") }))
    }
  }, [dispatch, location.search])

  useEffect(() => {
    const data = new URLSearchParams(location.search)
    if (data.has("folder")) {
      if (data.has("file")) {
        const asset_id = data.get("file")
        const asset = [...assetLibraryGetContentResponse.files, ...assetLibraryGetContentResponse.folders].find(asset => asset?.asset_id === asset_id)
        if(asset) {
          setOpenFileViewer(true)
          setFileViewerUrl(asset.thumbnailUrl)
          const breadcrumb = [{ asset_id: "root", name: "Library" }, ...asset.path.slice(0, -1)]
          dispatch(assetLibrarySetBreadcrumb(breadcrumb))
        }
      }
    }

  }, [assetLibraryGetContentResponse.files, assetLibraryGetContentResponse.folders, dispatch, location.search])

  const uploadRef = useRef<UploadRef>()

  const onFileAction = useCallback(e => {

    switch (e.id) {
      case ChonkyActions.OpenFiles.id:
        if (!e.payload.targetFile.isDir) {
          const thumbnailUrl = assetLibraryGetContentResponse.files.find((file: any) => file.asset_id === e.payload.targetFile.id)?.thumbnailUrl
          if (thumbnailUrl) {
            openDirectory(e.payload.targetFile.id, e.payload.targetFile.name, !e.payload.targetFile.isDir, thumbnailUrl, true)
          }
        } else {
          openDirectory(e.payload.targetFile.id, e.payload.targetFile.name, !e.payload.targetFile.isDir, e.payload.targetFile.thumbnailUrl, true)
        }
        break;
      case ChonkyActions.ChangeSelection.id:
        const selection = Array.from(e.payload.selection)
        selectDirectories(selection)
        break;
      case ChonkyActions.DeleteFiles.id:
        deleteDirectories()
        break;
      case ChonkyActions.DownloadFiles.id:
        downloadDirectories()
        break;
      case ChonkyActions.CopyFiles.id:
        copyDirectories()
        break;
      case ChonkyActions.CreateFolder.id:
        setAddFolderModalVisible(true)
        break;
      case "rename_files":
        renameDirectories()
        break;
      case "paste_files":
        pasteDirectories()
        break;
      case "move_files":
        moveDirectories()
        break;
      case "copy_link":
        const asset_id = e.state.selectedFilesForAction[0].id
        const asset = [...assetLibraryGetContentResponse.files, ...assetLibraryGetContentResponse.folders].find(asset => asset?.asset_id === asset_id)
        message.success("Link copied to clipboard", 1)
        navigator.clipboard.writeText(
          `${window.location.origin}/asset-library/?folder=${asset.parent_id}&file=${asset.asset_id}`,
        )
        break;
      case ChonkyActions.UploadFiles.id:
        uploadRef?.current?.upload?.uploader?.onClick()
        break;

      default:
        break;
    }
  }, [assetLibraryGetContentResponse.files, assetLibraryGetContentResponse.folders, copyDirectories, deleteDirectories, downloadDirectories, moveDirectories, openDirectory, pasteDirectories, renameDirectories, selectDirectories])

  const draggerProps: UploadProps = useMemo(
    () => ({
      multiple: true,
      // style: {
      //   height: '95%',
      //   display: 'flex',
      //   flexDirection: 'column',
      //   gap: 16,
      //   backgroundColor: 'transparent'
      // },
      showUploadList: false,
      className: 'asset-library-dragger',
      customRequest: (options) => {
        if (typeof options.file !== 'string') {
          const file = options.file as any
          const formData = new FormData()
          formData.append('file', file)
          dispatch(
            assetLibraryAddFileRequest({
              file: formData,
              parent_id: assetLibraryCurrentDirectory
            })
          )
        }
      }
    }),
    [assetLibraryCurrentDirectory, dispatch]
  )

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
      style={{
        height: '100vh',
        overflowY: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <TopHeader
        onAddFolder={onAddFolder}
        uploadProps={draggerProps}
        addFolderModalVisible={addFolderModalVisible}
        setAddFolderModalVisible={setAddFolderModalVisible}
        copyDirectories={copyDirectories}
        deleteDirectories={deleteDirectories}
        downloadDirectories={downloadDirectories}
        renameDirectories={renameDirectories}
        moveDirectories={moveDirectories}
      />
      <Upload ref={uploadRef} {...draggerProps} />
      <StyledCard
        bodyStyle={{
          padding: 0,
          height: '98%',
          display: 'flex',
          flexDirection: 'column'
        }}
        style={{ height: '94%', paddingTop: '1%' }}
      >
        <div style={{ height: "90%" }}>
          <FullFileBrowser
            onFileAction={onFileAction}
            files={files}
            folderChain={folderChain}
            fileActions={fileActions}
            disableDefaultFileActions
          />
        </div>
      </StyledCard>
      {openFileViewer && <Drawer
        title="File Viewer"
        placement="bottom"
        closable={true}
        onClose={() => {
          push(`${location.pathname}`)
          setOpenFileViewer(false)
          setFileViewerUrl("")
        }}
        open={openFileViewer}
        height={'99%'}
        styles={{
          body: {
            overflow: 'hidden',
            ...(isImage && { margin: "auto" })
          }
        }}
        destroyOnClose={true}
      >
        {!!fileViewerUrl && openFileViewer && <DocumentViewer url={fileViewerUrl} isImage={isImage} />}
      </Drawer>}

      <RenameFolderModal
        open={renameFolderModalVisible}
        onRenameFolder={onRenameFolder}
        closeModal={() => {
          setRenameFolderModalVisible(false)
        }}
      />

      <SelectAssetModal
        heading="Select Destination Folder"
        type="folders"
        open={openSelectAssetModal}
        setOpen={setOpenSelectAssetModal}
        onAssetSelect={onFolderSelect}
      />
    </motion.div>
  )
}

export default AssetLibrary
