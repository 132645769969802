
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`一般化部分集計画法`}</h1>
    <p><a parentName="p" {...{
        "href": "https://pubs.acs.org/doi/10.1021/acs.analchem.7b00506"
      }}>{`一般化部分集計画法（GSD）`}</a>{`は、複数のマルチレベル因子を扱う際に、縮小された設計を提供します。この設計では、論理的にリンクされた一連の縮小設計セット（サブセット）が生成されます。これらのサブセットを組み合わせると、すべてのサブセットが完全なマルチレベル多因子設計となります。`}</p>
    <p>{`一般化部分集計画法（GSD）は、個別にまたは組み合わせて使用するための一連の縮小設計セットを作成します。各個別の設計セットの目的は、すべての可能な因子の組み合わせのバランスの取れた、ユニークで代表的なサンプルであることです。`}</p>
    <p><strong parentName="p">{`使用例：`}</strong></p>
    <ul>
      <li parentName="ul">{`効率的な安定性試験の生成（最大70%の効率化）`}</li>
      <li parentName="ul">{`マルチレベル因子のスクリーニング（定量的および定性的）`}</li>
      <li parentName="ul">{`大規模スクリーニングDOE設計のための逐次的アプローチ`}</li>
      <li parentName="ul">{`データベースやビッグデータアプリケーションでのバイアスのないサンプル選択`}</li>
      <li parentName="ul">{`多変量キャリブレーション内のDOE`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;