import React, { Dispatch, forwardRef, SetStateAction } from "react"
import { Space, Card, Popconfirm } from "antd";
import { PlusOutlined, CopyOutlined } from "@ant-design/icons";
import { DataSheetGridRef, DynamicDataSheetGrid } from "react-datasheet-grid"
import useTranslate from "src/utils/useTranslate"
import { useTable } from "./useTable"
import { PCM } from "./types"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { StyledButton } from "src/styled_components/StyledButton";

type P = {
	data: PCM[]
	setData: Dispatch<SetStateAction<PCM[]>>
	setModalVisible?: React.SetStateAction<any>
}

export const Table = forwardRef<DataSheetGridRef, P>(
	({ data, setData, setModalVisible }, ref) => {
		const [t] = useTranslate()

		const {
			selectedRows,
			copyAndAddRow,
			removeRows,
			onRowsChange,
			columns,
			addRow
		} = useTable({ data, setData, setModalVisible })

		return (
			<Card
				type="inner"
				title="PCM Details"
				style={{ marginBottom: 20 }}
				extra={
					<Space size="small">
						<StyledButton onClick={addRow} icon={<PlusOutlined />}>
							{t("common.addRow")}
						</StyledButton>

						<StyledButton
							type="dashed"
							icon={<CopyOutlined />}
							onClick={copyAndAddRow}
							disabled={selectedRows.length < 1}
						>
							{t("common.copyAndAddRow")}
						</StyledButton>

						<Popconfirm
							title={"Sure to remove selected row(s) ?"}
							onConfirm={removeRows}
							disabled={selectedRows.length < 1}
						>
							<StyledButton danger icon={<StyledDeleteIcon />}>
								{t("datasheet.deletRows")}
							</StyledButton>
						</Popconfirm>
					</Space>
				}
			>
				<div style={{ width: "100%", overflow: "auto" }}>
					<DynamicDataSheetGrid
						ref={ref}
						lockRows
						value={data}
						onChange={onRowsChange}
						columns={columns}
					/>
				</div>
			</Card>
		)
	}
)
