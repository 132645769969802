import React, { useMemo } from "react"
import { Space, Table, Typography } from "antd"
import { NumericalCategoricalCorr as Data } from "src/components/AIEngine/DataSummary/types"
import { ColumnType } from "antd/lib/table"
import { SummaryWrapper } from "../summary-wrapper"
import { toTitleCase } from "src/utils/general"
import { Notes } from "../categorical-numerical-corr/notes"
import useTranslate from "src/utils/useTranslate"

type P = {
	data: Data
	getDisplayName: (value: string) => string
}

export const NumericalCategoricalCorr = ({ data, getDisplayName }: P) => {
	const [t] = useTranslate()


	const tableData = useMemo(() => {
		const d = data.data ?? {}
		const inputCategory = Object.keys(data.input_category ?? {})
		if (inputCategory.length === 0) return []

		const keys = Object.keys(d)
		if (keys.length === 0) return []

		const firstElement = d[keys[0]]

		const fieldsArr: {
			field: string
			[key: string]: string
		}[] = Object.keys(firstElement).map((key, i) => ({
			key: i.toString(),
			field: key,
		}))

		fieldsArr.forEach((obj) => {
			keys.forEach((key) => {
				obj[key] = d[key][obj.field]
			})
		})

		return fieldsArr
	}, [data])

	const columns: ColumnType<object>[] = Object.keys(data.data ?? {}).map(
		(key) => {
			return {
				key,
				dataIndex: key,
				width: 125,
				align: "center",
				title: () => (
					<Space
						style={{ width: "100%" }}
						align="center"
						size="small"
						direction="vertical"
					>
						{getDisplayName(key)}
					</Space>
				),
				render(value) {
					if (key === "F-statistic") return value?.toFixed(2)
					if (key === "Numerical Output")
						return toTitleCase(getDisplayName(value))
					if (key === "Categorical Input")
						return {
							props: {
								style: {
									color:
										(data.input_category ?? {})[value] === "Ingredients"
											? "blue"
											: "green",
								},
							},
							children: <span>{toTitleCase(getDisplayName(value))}</span>,
						}
					return getDisplayName(value)
				},
			}
		}
	)

	if (!tableData.length) return null

	return (
		<SummaryWrapper heading={t("dataSummary.numericalCategoricalCorrelation")} tooltip={t("aiEngine.tab.dataSummary.numericalcategoricalcorrelation")} key={"numerical-categorical-correlation"}>
			<Space
				style={{
					width: "100%",
					overflowX: "auto",
					display: "flex",
					flexDirection: "column",
					gap: "20px",
				}}
				direction="vertical"
			>
				{tableData.length > 0 && <Notes />}
				{tableData.length > 0 ? (
					<Table
						columns={[
							// {
							// 	dataIndex: "field",
							// 	title: "Stats",
							// 	key: "stats",
							// 	fixed: "left" as any,
							// 	width: 120,
							// 	align: "center" as any,
							// 	render: (text: string) => <Text strong>{text}</Text>,
							// },
							...columns,
						]}
						dataSource={tableData}
						pagination={false}
						scroll={{ x: 5 }}
						bordered
					/>
				) : (
					<Typography.Title level={5} style={{ color: "#ff3d54"}}>
						{t("dataSummary.noCorrelationFound")}
					</Typography.Title>
				)}
			</Space>
		</SummaryWrapper>
	)
}
