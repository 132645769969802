import { Card, Tabs } from "antd"
import React, { Dispatch, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearSuggestedExpInsights } from "src/store/actions/insights"
import { suggestedExperimentsRequest } from "src/store/actions/suggestedExp"
import { StoreState } from "src/store/configureStore"
import { ZeonCurrentPageInfo } from "../InverseModelNew/catwise/inverse-model"
import { useQuery } from "src/utils/useQuery"
import { SuggestedExp as SuggestedExpCatwise } from "../InverseModelNew/catwise/SuggestedExp"
import { SuggestedExp as SuggestedExpGeneral } from "../InverseModelNew/general/SuggestedExp"

type Props = {
  zeonCurrentPageInfo: ZeonCurrentPageInfo
  setZeonCurrentPageInfo: Dispatch<React.SetStateAction<ZeonCurrentPageInfo>>
  currentVersionTab: any
  experimentsCurrent: any
  filters: any
  pageChange: any
  setFilters: any
  selectedObjective: any
  tab: string
}

export default function ZeonSuggestExpWrapper({
  zeonCurrentPageInfo,
  setZeonCurrentPageInfo,
  currentVersionTab,
  experimentsCurrent,
  filters,
  pageChange,
  setFilters,
  selectedObjective,
  tab,
}: Props) {
  const { inverseVersionList, newInverseModel } = useSelector(
    (state: StoreState) => state.newInverseModel,
  )
  const dispatch = useDispatch()

  const configs = useSelector((state: StoreState) => state.configs.features)
  let query = useQuery()
  let modelVersion = query?.get("version")
  const { configData } = useSelector((state: StoreState) => state.formulate)

  const [isMultiStageModel, setIsMultiStageModel] = useState(
    configData.find(
      (modelData: any) => modelData.version === Number(modelVersion),
    )?.is_multistage ?? false,
  )

  useEffect(() => {
    if (Boolean(modelVersion)) {
      setIsMultiStageModel(
        configData.find(
          (modelData: any) => modelData.version === Number(modelVersion),
        )?.is_multistage ?? false,
      )
    }
  }, [configData, modelVersion])

  return (
    <Card>
      <Tabs
        onChange={(e: any) => {
          setZeonCurrentPageInfo((prev: any) => ({
            ...prev,
            version: inverseVersionList?.[e]?.versions?.[0],
            variation_id: Number(e),
            currentPage: 1,
          }))
          dispatch(
            suggestedExperimentsRequest({
              prediction_id:
                selectedObjective || newInverseModel?.prediction_id,
              pageNum: 1,
              version: inverseVersionList?.[e]?.versions?.[0],
              variation: inverseVersionList.find(
                (item, _id) => _id === Number(e),
              )?.variation,
            }),
          )
          dispatch(clearSuggestedExpInsights())
        }}
        activeKey={zeonCurrentPageInfo?.variation_id.toString()}
      >
        {inverseVersionList?.map((variations: any, index) => (
          <Tabs.TabPane tab={`Variation ${index + 1}`} key={index}>
            <Tabs
              onChange={(e) => {
                dispatch(
                  suggestedExperimentsRequest({
                    prediction_id:
                      selectedObjective || newInverseModel?.prediction_id,
                    pageNum: 1,
                    version: Number(e),
                    variation: inverseVersionList.find(
                      (item, _id) => _id === zeonCurrentPageInfo?.variation_id,
                    )?.variation,
                  }),
                )
                setZeonCurrentPageInfo((prev: any) => ({
                  ...prev,
                  currentPage: 1,
                  version: Number(e),
                }))
                dispatch(clearSuggestedExpInsights())
              }}
              activeKey={zeonCurrentPageInfo?.version?.toString()}
            >
              {variations?.versions?.map((version: any, versionId: number) => (
                <Tabs.TabPane
                  tab={`Ingredient Set ${versionId + 1}`}
                  key={version}
                >
                  {isMultiStageModel ||
                  Boolean(configs?.kurita_configs) ||
                  Boolean(configs?.catwise_inverse_constraints) ||
                  Boolean(configs?.kuraray_configs) ||
                  Boolean(configs?.nestle_configs) ? (
                    <SuggestedExpCatwise
                      key={version}
                      configData={configData}
                      experimentsCurrent={experimentsCurrent}
                      filters={filters}
                      pageChange={pageChange}
                      setFilters={setFilters}
                      selectedObjective={selectedObjective}
                      variationDetail={variations.variation}
                      zeonCurrentPageInfo={zeonCurrentPageInfo}
                      tab={tab}
                    />
                  ) : (
                    <SuggestedExpGeneral
                      key={version}
                      configData={configData}
                      experimentsCurrent={experimentsCurrent}
                      filters={filters}
                      pageChange={pageChange}
                      setFilters={setFilters}
                      selectedObjective={selectedObjective}
                      variationDetail={variations.variation}
                      zeonCurrentPageInfo={zeonCurrentPageInfo}
                      tab={tab}
                    />
                  )}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Card>
  )
}
