import React, { useEffect, useMemo, useState } from "react"
import { Header } from "./header"
import { ViewData } from "./view-data"
import { Report } from "./report"
import { Row } from "antd"
import { fetchMethodListRequest } from "src/store/actions/characterizationMethods"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { fetchLinkedExperimentDetailsRequest } from "src/store/actions/experiment"

export type Tab = "summary" | "report"

export const ZeonCompareFormulation = () => {
	const dispatch = useDispatch()

	const [tab, setTab] = useState<Tab>("summary")
	const configs = useSelector((state: StoreState) => state.configs.features)
	const experiments = useSelector((state: StoreState) => state.compareFormulations.formulationDetailsData)

	useEffect(() => {
		dispatch(fetchMethodListRequest({ method_type: "characterizations" }))
	}, [dispatch])


	useEffect(() => {
		if (Boolean(configs?.nestle_configs)) {
			dispatch(fetchLinkedExperimentDetailsRequest({ formulation_merge_id: [...new Set(experiments.map((res: any) => res?.formulation_merge_id))] }))
		}
	}, [configs, dispatch, experiments])

	const Tabs = useMemo(() => {
		switch (tab) {
			case "summary":
				return <ViewData />
		}
	}, [tab])

	return (
		<Row style={{ flexDirection: "column", gap: "30px" }}>
			<Header tab={tab} setTab={setTab} />
			{tab !== "report" && Tabs}
			{<div style={{ display: tab === "report" ? "unset" : "none" }}><Report visible={tab === "report"} /></div>}
		</Row>
	)
}
