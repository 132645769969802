import { EditOutlined, LoadingOutlined, MoreOutlined, SaveOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message, Modal, Popconfirm, Space, Table, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { deleteMetadataTemplateRequest, listMetadataTemplateRequest } from 'src/store/actions/inventory'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { AddTemplateModal } from './AddTemplateModal'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton';



export const ViewTemplatesModal = ({ visible, setVisible, addTemplateModalVisible, setAddTemplateModalVisible, form, setMetadataModalVisible }: any) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const inventoryData = useSelector((state: StoreState) => state.displayNames.inventoryData)
    const templateData = useSelector((state: StoreState) => state.inventory.templateData)
    const templateListStatus = useSelector((state: StoreState) => state.inventory.templateListStatus)
    const templateDeletetatus = useSelector((state: StoreState) => state.inventory.templateDeletetatus)
    const [templateFields, setTemplateFields] = useState<any>({})
    const filterCategories = useMemo(() => {
        return [...new Set(inventoryData?.ingredients?.map((res: any) => res?.category).filter((res: any) => !!res?.trim()))]
    }, [inventoryData])
    const filterSubCategories = useMemo(() => {
        return [...new Set(inventoryData?.ingredients?.map((res: any) => res?.sub_category).filter((res: any) => !!res?.trim()))]
    }, [inventoryData])
    const [tab, setTab] = useState("company")

    useEffect(() => {
        if (visible) {
            setTab("company")
            dispatch(listMetadataTemplateRequest({ "type": "company" }))
        }
    }, [dispatch, visible])

    const columns = [
        {
            key: 'name',
            title: t("common.Name"),
            dataIndex: 'name',
        },
        {
            key: 'category',
            title: t("common.category"),
            dataIndex: 'category',
        },
        {
            key: 'sub_category',
            title: t("inventory.subCategory"),
            dataIndex: 'sub_category',
        },
        {
            key: 'actions',
            title: t("common.actions"),
            dataIndex: 'actions',
            width: 100,
            align: "center",
            fixed: "right",
            render: (text: any, record: any) => {
                return (
                    <Dropdown
                        overlay={() => (
                            <Menu>
                                <Menu.Item onClick={() => {
                                    Modal.confirm({
                                        title: `Apply template ${record?.name} for ingredient properties ?`,
                                        onOk: () => {
                                            const selectedTemplate = templateData.find((res: any) => res?.template_id === record?.template_id)
                                            form.setFieldsValue({
                                                properties: selectedTemplate?.data.map((res: any) => ({
                                                    category: inventoryData?.metadata_properties?.find((key: any) => key?.identifier === res?.identifier)?.category,
                                                    identifier: res?.identifier,
                                                    value: res?.value,
                                                    unit: res?.unit
                                                })) || []
                                            })
                                            setVisible(false)
                                            message.success(`Template ${selectedTemplate?.name} applied`)
                                        },
                                        onCancel: () => { }
                                    })
                                }}>
                                    <StyledButton type='text' icon={<SaveOutlined />}>{"Apply Template"}</StyledButton>
                                </Menu.Item>
                                <Menu.Item onClick={() => {
                                    setTemplateFields({
                                        template_id: record?.template_id,
                                        name: record?.name,
                                        type: record?.type,
                                        category: record?.category,
                                        sub_category: record?.sub_category,
                                        properties: record?.data.map((res: any) => ({
                                            category: inventoryData?.metadata_properties?.find((key: any) => key?.identifier === res?.identifier)?.category,
                                            identifier: res?.identifier,
                                            value: res?.value,
                                            unit: res?.unit
                                        }))
                                    })
                                    setAddTemplateModalVisible(true)
                                }} key="edit">
                                    <StyledButton type="text" icon={<EditOutlined />}>
                                        {t("common.view")}
                                    </StyledButton>
                                </Menu.Item>
                                <Menu.Item key="view">
                                    <Popconfirm title={"Delete this template ?"} onConfirm={() => {
                                        dispatch(deleteMetadataTemplateRequest({ template_id: record?.template_id, type: tab }))
                                    }}>
                                        <StyledButton danger type="text" icon={<StyledDeleteIcon />}>
                                            {t("common.delete")}
                                        </StyledButton>
                                    </Popconfirm>
                                </Menu.Item>
                            </Menu>
                        )}
                    >
                        {<MoreOutlined style={{ cursor: "pointer" }} />}
                    </Dropdown>
                )
            }
        }
    ]


    return (
        <Modal title={t("common.templates")} visible={visible}
            zIndex={998}
            onCancel={() => setVisible(false)}
            width={800}
            footer={null}
            maskClosable={false}>
            <Space direction="vertical" size="large" style={{ width: "100%", marginTop: 20 }}>
                <Tabs activeKey={tab} defaultActiveKey={"company"}
                    onChange={e => {
                        setTab(e)
                        dispatch(listMetadataTemplateRequest({ type: e }))
                    }}>
                    <Tabs.TabPane tab={t("inventory.companyDirectory")} tabKey={"company"} key={"company"}>
                        <Table
                            columns={columns as any} dataSource={templateData} bordered
                            loading={{
                                spinning: templateListStatus === AsyncStates.LOADING || templateDeletetatus === AsyncStates.LOADING,
                                indicator: <LoadingOutlined />
                            }}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("inventory.personalDirectory")} tabKey={"personal"} key={"personal"}>
                        <Table
                            columns={columns as any} dataSource={templateData} bordered
                            loading={{
                                spinning: templateListStatus === AsyncStates.LOADING || templateDeletetatus === AsyncStates.LOADING,
                                indicator: <LoadingOutlined />
                            }}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Space>
            <AddTemplateModal
                visible={addTemplateModalVisible}
                setVisible={setAddTemplateModalVisible}
                templateFields={templateFields}
                setTemplateFields={setTemplateFields}
                filterCategories={filterCategories}
                filterSubCategories={filterSubCategories}
                setMetadataModalVisible={setMetadataModalVisible}
                currentType={tab}
            />
        </Modal>
    )
}
