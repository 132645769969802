import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, Input, Layout, Space, Alert, Typography } from "antd";
import LeftSideOnboarding from "../SignUp/LeftSideOnboarding";
import "./ForgotPassword.scss"

import Title from "antd/lib/typography/Title"
import Text from "antd/lib/typography/Text"
import { Link } from "react-router-dom"
import { MailOutlined } from "@ant-design/icons";
import AllRightsReserved from "../SignUp/AllRightsReserved";
import useTranslate from "src/utils/useTranslate";
import { StoreState } from "src/store/configureStore";
import { forgotPasswordClear, forgotPasswordRequest } from "src/store/actions/forgotPassword";
import { loginViaMagicLinkCleanup, loginViaMagicLinkRequest } from "src/store/actions/loginViaMagicLink";
import { AsyncStates } from "src/constants";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";
import { StyledButton } from "src/styled_components/StyledButton";

const { Content: LayoutContent } = Layout

type FormData = {
  user_email: string
}

const ForgotPassword = () => {
  const [t] = useTranslate();
  const dispatch = useDispatch()
  const [form] = Form.useForm<FormData>()
  const requiredFieldStar = useRequiredFieldStar()

  const errorMsg = useSelector<StoreState, string>(
    (state) => state.forgotPassword.error
  )

  const submitForm = (values: FormData) => {
    dispatch(forgotPasswordRequest(values))
  }

  const loginViaMagicLink = () => {
    const data = form.getFieldsValue()
    dispatch(loginViaMagicLinkRequest({ email: data.user_email }))
  }
  const { loginViaMagicLinkError, loginViaMagicLinkStatus } = useSelector((state: StoreState) => state.loginViaMagicLink)

  useEffect(() => {
    return () => {
      dispatch(forgotPasswordClear())
      dispatch(loginViaMagicLinkCleanup())
    }

  }, [dispatch])

  return (
    <Layout
      style={{ textAlign: "center", minHeight: "100%", background: "white" }}
      className="fontWorkSans"
    >
      <LayoutContent style={{ overflow: "auto", position: "relative" }}>
        <Space style={{ width: "100%" }} direction="horizontal" size="large">
          <LeftSideOnboarding minWidth="55vw" />
          <Space direction="vertical">
            <Space
              direction="vertical"
              style={{ width: "100%", padding: "48px", maxWidth: "40vw" }}
            >
              <Title
                style={{
                  marginBottom: "40px",
                  color: "#1753E2",
                  fontSize: "2.5rem",
                  fontWeight: 500,
                }}
                level={1}
              >
                Forgot your password?
              </Title>
              <Space direction="vertical" style={{ rowGap: "0px" }}>
                <Text
                  style={{
                    textAlign: "center",
                    display: "block",
                    marginBottom: "1rem",
                  }}
                >
                  Don't worry! Enter the email that you used to create an
                  account, and we'll send you instructions on how to reset your
                  password.
                </Text>

                {!!errorMsg && (
                  <Alert
                    message={errorMsg}
                    type="error"
                    style={{
                      backgroundColor: "#CF1322",
                      color: "white",
                      textAlign: "left",
                      display: "block",
                      marginBottom: "1rem",
                    }}
                    className="error-alert-msg"
                  />
                )}

                {!!loginViaMagicLinkError && (
                  <Alert
                    message={loginViaMagicLinkError}
                    type="error"
                    style={{
                      backgroundColor: "#CF1322",
                      color: "white",
                      textAlign: "left",
                      display: "block",
                      marginBottom: "1rem",
                    }}
                    className="error-alert-msg"
                  />
                )}

                <Form
                  name="normal_login"
                  layout="vertical"
                  onFinish={submitForm}
                  form={form}
                  requiredMark={false}
                >
                  <Form.Item
                    name="user_email"
                    validateTrigger="onChange"
                    required tooltip={requiredFieldStar}
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(
                          /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                        ),
                        message: (
                          <Text
                            style={{
                              textAlign: "left",
                              display: "block",
                              color: "red",
                            }}
                          >
                            {t("common.notAvalidMail")}
                          </Text>
                        ),
                      },
                    ]}
                  >
                    <Input
                      prefix={<MailOutlined />}
                      size="large"
                      placeholder={"name@company.com"}
                      minLength={4}
                      style={{ borderRadius: "7.5px", minHeight: "50px" }}
                    />
                  </Form.Item>
                  <Form.Item shouldUpdate>
                    {() => (
                      <StyledButton
                        className="primary-btn"
                        style={{
                          backgroundColor: "#1B55CE",
                          width: "100%",
                          borderRadius: "7.5px",
                          minHeight: "50px",
                        }}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        disabled={
                          !form.isFieldsTouched(true) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }: any) => errors.length).length
                        }
                      >
                        {"Send instructions"}
                      </StyledButton>
                    )}
                  </Form.Item>
                  <Form.Item shouldUpdate>
                    {() => (
                      <StyledButton
                        className="primary-btn"
                        style={{
                          backgroundColor: "#1B55CE",
                          width: "100%",
                          borderRadius: "7.5px",
                          minHeight: "50px",
                        }}
                        size="large"
                        type="primary"
                        onClick={() => loginViaMagicLink()}
                        disabled={
                          !form.isFieldsTouched(true) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }: any) => errors.length).length
                        }
                        loading={loginViaMagicLinkStatus === AsyncStates.LOADING}
                      >
                        {"Login With Magic Link"}
                      </StyledButton>
                    )}
                  </Form.Item>
                </Form>
                <Typography.Title
                  level={4}
                  style={{
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  Go back to{" "}
                  <Link
                    to="/login"
                    style={{ textDecoration: "underline", color: "#007AFF" }}
                  >
                    {"Login page"}
                  </Link>
                </Typography.Title>
              </Space>
            </Space>
            <AllRightsReserved />
          </Space>
        </Space>
      </LayoutContent>
    </Layout>
  );
}

export default ForgotPassword
