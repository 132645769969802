import { CloseOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons";
import "./Widget.scss"
import { useEffect, useState } from "react";
import { Drawer, Empty, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { progressRequest } from "src/store/actions/widget";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import ProgressInfo from "./ProgressInfo";
import { Badge } from "antd/lib";
import useTranslate from "src/utils/useTranslate";

const Widget = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { progress, status, showProgress } = useSelector((state: StoreState) => state.widget);
    const { loginResponse: { user_id } } = useSelector((state: StoreState) => state.login);
    const [hasUpdate, setHasUpdate] = useState(false);
    const [showFlash, setShowFlash] = useState(false);

    useEffect(() => {
        dispatch(progressRequest())
    }, [dispatch]);

    useEffect(() => {
        setTimeout(() => setShowFlash(false), 1000);
    }, [showFlash]);

    useEffect(() => {
        if (open) dispatch(progressRequest())
        setHasUpdate(false);
    }, [open, dispatch])

    const getAuthenticatedProgressData = () => {
        return progress.filter(p => {
            if (p.scope.toLowerCase() === "company") return true;
            if (p.scope.toLowerCase() === "user" && p.user_id !== user_id) return false;
            return true;
        })
    }

    const [t] = useTranslate()

    return (
        <>
            <div className="widget-container">
                {!open && showProgress && <div className={`widget-open-button ${showFlash && "flash"}`} onClick={() => setOpen(true)}>
                    {t("progress.requestInProgress")} &nbsp;&nbsp;<Badge dot={hasUpdate}><UpOutlined /></Badge>
                </div>}
                <div id="drawer-container">
                    <Drawer placement="right"
                        open={open}
                        style={{
                            width: "550px",
                            marginLeft: "calc(100% - 550px)",
                            borderTopLeftRadius: "16px",
                            height: "500px"
                        }}
                        contentWrapperStyle={{
                            boxShadow: "none"
                        }}
                        forceRender={true}
                        closable={false}
                        rootClassName="widget-drawer"
                    >

                        <div className="drawer-header">
                            <div className="drawer-header-title">{t("progress.requestInProgress")}</div>
                            <div className="drawer-close" onClick={() => setOpen(false)}><CloseOutlined /></div>
                        </div>
                        <div className="drawer-body">
                            {status === AsyncStates.LOADING ?
                                <Spin
                                    spinning={true}
                                    indicator={<LoadingOutlined />}
                                ></Spin>
                                : <> {getAuthenticatedProgressData().length === 0 && <Empty description={t("progress.noTraining")} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                    {getAuthenticatedProgressData().map((p, idx) => (
                                        <ProgressInfo key={idx} widgetProgress={p} setHasUpdate={setHasUpdate} setShowFlash={setShowFlash} />
                                    ))}
                                </>
                            }
                        </div>

                    </Drawer>
                </div>
            </div>
        </>
    )
}

export default Widget;