import { Modal } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { deleteNoteRequest } from "src/store/actions/projects"
import { useDispatch } from "react-redux"
import { useState } from "react"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"

export const DeleteNote = ({
  note_id,
  project_id,
}: {
  note_id: string
  project_id: string
}) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const [showModal, setShowModal] = useState(false)

  const confirm = () => {
    setShowModal(true)
    Modal.confirm({
      okText: t("common.ok"),
      cancelText: t("common.cancel"),
      visible: showModal,
      title: `${t("project.deleteNote")}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        dispatch(deleteNoteRequest({ note_id, project_id }))
        setShowModal(false)
      },
    })
  }

  return (
    <StyledButton
      onClick={confirm}
      type="link"
      icon={<StyledDeleteIcon />}
      style={{ color: "red" }}
    >
      {t("common.delete")}
    </StyledButton>
  )
}
