import { Space, Typography } from 'antd'
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { ExplainableAIResultProps } from './ExplainableAIBarChart'
import { removeUnderScore } from './utils'

import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import useTranslate from 'src/utils/useTranslate'

import { useValue } from 'src/utils/useValue';

const { Title } = Typography

export const ExplainableAIBeeswarm = ({
  property,
  model,
  filterBy
}: ExplainableAIResultProps) => {
  const { explainableAIData } = useSelector(
    (state: StoreState) => state.explainableAI
  )

  const chartData = useMemo(() => {
    const data = model?.map((modelItem: any) => ({
      modelName: modelItem,
      beeswarm: explainableAIData?.[property]?.[modelItem]?.plots?.beeswarm
    }))
    return data
  }, [model, property, explainableAIData])

  const paramsToShow = useMemo(() => {
    if (filterBy.length === 0) return explainableAIData?.[property]?.x_cols
    else return filterBy
  }, [explainableAIData, filterBy, property])

  const filteredBeeswarmData = useMemo(() => {
    const output:any[] = []

    chartData.forEach((data: any, index:number) => {
      output[index] = {
        modelName: data.modelName,
        beeswarm: data.beeswarm
      }
    })

    return output

  },[chartData])

  return (
    <Space
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 10
      }}
      styles={{
        item: {
          height: '100%',
          width: '100%'
        }
      }}
    >
      {filteredBeeswarmData?.map((data: any) => (
        <div
          style={{
            border: '1px solid #ccc',
            padding: 12,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%'
          }}
          key={data.modelName}
        >
          {
            <Title level={4} style={{ textAlign: 'center' }}>
              {removeUnderScore(data.modelName) ?? data.modelName}
            </Title>
          }
          <SwarmPlot
            data={data.beeswarm}
            paramsToShow={paramsToShow}
            property={property}
          />
        </div>
      ))}
    </Space>
  )
}

const SwarmPlot = ({ data, property, paramsToShow }: any) => {
  const [t] = useTranslate()
  const { getValue } = useValue()

  useEffect(() => {
    return () => {
      Highcharts?.charts[0]?.destroy()
    }
  }, [])

  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data
  )

  const propertyName = useMemo(() => {
    return displayNames.properties?.[property]?.name ?? property
  }, [displayNames, property])

  const beeswarmData = useMemo(
    () => data.custom_data_beeswarm,
    [data.custom_data_beeswarm]
  )

  const sortedParams = useMemo(() => {
    return data.feature_order
  },[data.feature_order])

  const yCategories = useMemo(() => {
    return sortedParams
      ?.filter((param: any) => paramsToShow.includes(param))
      ?.map((param: any, idx: number) => sortedParams.indexOf(param))
  }, [paramsToShow, sortedParams])

  const yCategoriesNames = useMemo(() => {
    return sortedParams
      ?.filter((param: any) => paramsToShow.includes(param))
  }, [paramsToShow, sortedParams])

  const getData = useCallback(
    (param: number) => {
      const finalPos = yCategoriesNames.indexOf(param)
      const currentPos = yCategories[finalPos]

      const offset = currentPos - finalPos

      const x = beeswarmData[param].x_coords
      const y = beeswarmData[param].y_coords.map((y: any) => y - offset)

      const color = beeswarmData[param].colors
      return x.map((_: any, idx: number) => {
        return [x[idx], y[idx], color[idx]]
      })
    },
    [beeswarmData, yCategories, yCategoriesNames]
  )

  const seriesData = useMemo(() => {
    return Object.keys(beeswarmData)
      ?.filter((param: any) => paramsToShow.includes(param))
      ?.map((param: any) => ({
        name: param,
        data: getData(param),
        keys: ['x', 'y', 'color'],
        colorByPoint: true
      }))
  }, [beeswarmData, getData, paramsToShow])

  const options = useMemo(() => {

    return {
      chart: {
        type: 'scatter',
        marginRight: 8,
        zoomType: 'xy',
        height: Math.max(600, yCategoriesNames.length * 50)
      },

      title: {
        text: null
      },

      credits: {
        enabled: false
      },

      xAxis: {
        title: {
          text: `${t('common.impactOn')} ${propertyName}`
        },
        lineWidth: 0,
        labels: {
          formatter: function (item: any) {
            return `${getValue(item?.value)}`
          }
        },

        tickLength: 6,
        plotLines: [
          {
            value: 0,
            width: 1,
            zIndex: 4
          }
        ],
        
      },

      yAxis: {
        categories: yCategoriesNames.map((_: any,idx:number) => idx),
        gridLineWidth: 0,
        title: {
          text: null
        },
        labels: {
          formatter: function (): string | null {
            return yCategoriesNames?.[(this as any).value] || null
          },
          overflow: 'justify'
        },
        scrollbar: {
          enabled: true
        },
      },

      tooltip: {
        formatter: function (item: any) {
            const point = (this as any).point;
            return `
              <div>
                <strong>${point?.series?.userOptions?.name}</strong>
                <br/>
                <ul>
                  <li><strong>X:</strong> ${getValue(point?.x)}</li>
                  <br/>
                  <li><strong>Y:</strong> ${getValue(point?.y)}</li>
                </ul>
              </div>
            `
        }
      },

      plotOptions: {
        scatter: {
          showInLegend: false,
          jitter: {
            x: 0,
            y: 0
          },
          marker: {
            radius: 2,
            symbol: 'circle'
          },
        }
      },

      series: seriesData,
    }
  }, [t, propertyName, yCategoriesNames, seriesData, getValue])

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        gap:'1rem'
      }}
    >
      <div style={{
        maxHeight: '600px',
        overflowY: 'auto',
        width: 'calc(100% - 1rem)',
      }} >
        <HighchartsReact highcharts={Highcharts} options={options}/>
      </div>
      <ColorScale params={paramsToShow.length} />
    </div>
  )
}

const ColorScale = ({ params }: any) => {
  const [t] = useTranslate()

  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 50,
        marginRight: 36,
        height: 'calc(600px - 50px)'
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 24,
          fontSize: '0.75rem'
        }}
      >
        {t('common.high')}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 24,
          fontSize: '0.75rem'
        }}
      >
        {t('common.low')}
      </div>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          rotate: '270deg',
          left: -70,
          fontSize: '0.625rem',
          width: 200,
          textAlign: 'center',
          transform: 'translateX(10px)'
        }}
      >
        {`${t('common.ingredientQuantity')} / ${t('common.processParameter')}`}
      </div>
      <div
        style={{
          height: '100%',
          width: '12px',
          background: 'linear-gradient(to bottom, #fe0150, #008eff)'
        }}
      ></div>
    </div>
  )
}
