import { handleActions } from "redux-actions";
import { AddLabsLocationActionTypes, BillingDetailsActionTypes, EditLabsLocationActionTypes, FetchLabsLocationActionTypes, SettingsActionTypes, UpdateBillingDetailsActionTypes, UpdateNumericalCodeActionTypes, UpdatePaymentMethodActionTypes, UpdateSubscriptionActionTypes } from "../actions/settings";
import { AsyncStates } from "../../constants";
import { SettingsResponse } from "../../services/settings/interface"

export type SettingsReducerState = {
    status: AsyncStates;
    updatedStatus: AsyncStates;
    error: string;
    data: SettingsResponse,
    message: {},
    billingDetails: any,
    billingDetailsStatus: AsyncStates,
    billingDetailsErrorMessage: string,
    updatePaymentMethodStatus: AsyncStates,
    updatePaymentMethodErrorMessage: string,
    updateBillingDetailsStatus: AsyncStates,
    updateBillingDetailsErrorMessage: string,
    updateSubscriptionStatus: AsyncStates,
    updateSubscriptionErrorMessage: string,
    labsLocationList: any[],
    labsLocationListStatus: AsyncStates,
    labsLocationListErrorMessage: string | null,
    addLabsLocationStatus: AsyncStates,
    addLabsLocationErrorMessage: string | null,
    editLabsLocationStatus: AsyncStates,
    editLabsLocationErrorMessage: string | null
    updateNumericCodeStatus: AsyncStates,
}

const defaultState: SettingsReducerState = {
    status: AsyncStates.INITIAL,
    updatedStatus: AsyncStates.INITIAL,
    error: "",
    data: { company: { company_name: '', location: '', stages: [{ identifier: '', name: '' }] }, stages: [{ identifier: '', name: '' }] },
    message: "",
    billingDetails: {},
    billingDetailsStatus: AsyncStates.INITIAL,
    billingDetailsErrorMessage: "",
    updatePaymentMethodStatus: AsyncStates.INITIAL,
    updatePaymentMethodErrorMessage: "",
    updateBillingDetailsStatus: AsyncStates.INITIAL,
    updateBillingDetailsErrorMessage: "",
    updateSubscriptionStatus: AsyncStates.INITIAL,
    updateSubscriptionErrorMessage: "",
    labsLocationList: [],
    labsLocationListStatus: AsyncStates.INITIAL,
    labsLocationListErrorMessage: null,
    addLabsLocationStatus: AsyncStates.INITIAL,
    addLabsLocationErrorMessage: null,
    editLabsLocationStatus: AsyncStates.INITIAL,
    editLabsLocationErrorMessage: null,
    updateNumericCodeStatus: AsyncStates.INITIAL
}

const settingsReducer = handleActions({
    [SettingsActionTypes.REQUEST]: (state) => ({
        ...state,
        status: AsyncStates.LOADING,
        updatedStatus: AsyncStates.INITIAL,
        error: "",
        message: ''
    }),
    [SettingsActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            status: AsyncStates.SUCCESS,
            error: "",
            data: action.payload.data,
        };
    },
    [SettingsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        status: AsyncStates.ERROR,
        error: action.payload.error,
    }),
    [SettingsActionTypes.UPDATE_REQUEST]: (state) => ({
        ...state,
        updatedStatus: AsyncStates.LOADING,
        error: '',
    }),
    [SettingsActionTypes.UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        updatedStatus: AsyncStates.SUCCESS,
        error: '',
        message: action.payload
    }),
    [SettingsActionTypes.UPDATE_FAILURE]: (state, action) => ({
        ...state,
        updatedStatus: AsyncStates.ERROR,
        error: action.payload.error,
    }),
    [SettingsActionTypes.UPDATE_CLEAR]: (state) => ({
        ...state,
        status: AsyncStates.INITIAL,
        data: defaultState.data,
        updatedStatus: AsyncStates.INITIAL,
        error: '',
        message: ''
    }),
    [BillingDetailsActionTypes.REQUEST]: (state) => ({
        ...state,
        billingDetailsStatus: AsyncStates.LOADING,
        billingDetailsErrorMessage: '',
    }),
    [BillingDetailsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        billingDetails: action.payload,
        billingDetailsStatus: AsyncStates.SUCCESS,
    }),
    [BillingDetailsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        billingDetailsStatus: AsyncStates.ERROR,
        billingDetailsErrorMessage: action.payload.error,
    }),
    [BillingDetailsActionTypes.CLEAR]: (state) => ({
        ...state,
        billingDetailsStatus: AsyncStates.INITIAL,
        billingDetailsErrorMessage: '',
    }),
    [UpdatePaymentMethodActionTypes.REQUEST]: (state) => ({
        ...state,
        updatePaymentMethodStatus: AsyncStates.LOADING,
        updatePaymentMethodErrorMessage: '',
    }),
    [UpdatePaymentMethodActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        updatePaymentMethodStatus: AsyncStates.SUCCESS,
    }),
    [UpdatePaymentMethodActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updatePaymentMethodStatus: AsyncStates.ERROR,
        updatePaymentMethodErrorMessage: action.payload.error,
    }),
    [UpdatePaymentMethodActionTypes.CLEAR]: (state) => ({
        ...state,
        updatePaymentMethodStatus: AsyncStates.INITIAL,
        updatePaymentMethodErrorMessage: '',
    }),
    [UpdateBillingDetailsActionTypes.REQUEST]: (state) => ({
        ...state,
        updateBillingDetailsStatus: AsyncStates.LOADING,
        updateBillingDetailsErrorMessage: '',
    }),
    [UpdateBillingDetailsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        updateBillingDetailsStatus: AsyncStates.SUCCESS,
    }),
    [UpdateBillingDetailsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updateBillingDetailsStatus: AsyncStates.ERROR,
        updateBillingDetailsErrorMessage: action.payload.error,
    }),
    [UpdateBillingDetailsActionTypes.CLEAR]: (state) => ({
        ...state,
        updateBillingDetailsStatus: AsyncStates.INITIAL,
        updateBillingDetailsErrorMessage: '',
    }),
    [UpdateSubscriptionActionTypes.REQUEST]: (state) => ({
        ...state,
        updateSubscriptionStatus: AsyncStates.LOADING,
        updateSubscriptionErrorMessage: '',
    }),
    [UpdateSubscriptionActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        updateSubscriptionStatus: AsyncStates.SUCCESS,
    }),
    [UpdateSubscriptionActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updateSubscriptionStatus: AsyncStates.ERROR,
        updateSubscriptionErrorMessage: action.payload.error,
    }),
    [UpdateSubscriptionActionTypes.CLEAR]: (state) => ({
        ...state,
        updateSubscriptionStatus: AsyncStates.INITIAL,
        updateSubscriptionErrorMessage: '',
    }),
    [FetchLabsLocationActionTypes.REQUEST]: (state) => ({
        ...state,
        labsLocationListStatus: AsyncStates.LOADING,
        labsLocationListErrorMessage: null,
    }),
    [FetchLabsLocationActionTypes.SUCCESS]: (state, action: any) => ({
        ...state,
        labsLocationList: action.payload,
        labsLocationListStatus: AsyncStates.SUCCESS,
    }),
    [FetchLabsLocationActionTypes.FAILURE]: (state, action) => ({
        ...state,
        labsLocationListStatus: AsyncStates.ERROR,
        labsLocationListErrorMessage: action.payload.error,
    }),
    [FetchLabsLocationActionTypes.CLEAR]: (state) => ({
        ...state,
        labsLocationListStatus: AsyncStates.INITIAL,
        labsLocationListErrorMessage: null,
        labsLocationList: []
    }),

    [AddLabsLocationActionTypes.REQUEST]: (state) => ({
        ...state,
        addLabsLocationStatus: AsyncStates.LOADING,
        addLabsLocationErrorMessage: null,
    }),
    [AddLabsLocationActionTypes.SUCCESS]: (state, action: any) => {
        const apiResponse = action.payload
        const updatedLabsLocationList = [...(state?.labsLocationList ?? []), apiResponse]
        return {
            ...state,
            labsLocationList: updatedLabsLocationList,
            addLabsLocationStatus: AsyncStates.SUCCESS,
        }
    },
    [AddLabsLocationActionTypes.FAILURE]: (state, action) => ({
        ...state,
        addLabsLocationStatus: AsyncStates.ERROR,
        addLabsLocationErrorMessage: action.payload.error,
    }),
    [AddLabsLocationActionTypes.CLEAR]: (state) => ({
        ...state,
        addLabsLocationStatus: AsyncStates.INITIAL,
        addLabsLocationErrorMessage: null,
    }),

    [EditLabsLocationActionTypes.REQUEST]: (state) => ({
        ...state,
        editLabsLocationStatus: AsyncStates.LOADING,
        editLabsLocationErrorMessage: null,
    }),
    [EditLabsLocationActionTypes.SUCCESS]: (state, action: any) => {
        const apiResponse = action.payload
        const labsLocationList = [...state.labsLocationList]
        labsLocationList.forEach((labLocation, index) => {
            if (labLocation.lab_location_id === apiResponse.lab_location_id) {
                labsLocationList[index] = apiResponse
            }
        })
        return {
            ...state,
            editLabsLocationStatus: AsyncStates.SUCCESS,
            editLabsLocationErrorMessage: null,
            labsLocationList: labsLocationList
        }
    },
    [EditLabsLocationActionTypes.FAILURE]: (state, action) => ({
        ...state,
        editLabsLocationStatus: AsyncStates.ERROR,
        editLabsLocationErrorMessage: action.payload.error,
    }),
    [EditLabsLocationActionTypes.CLEAR]: (state) => ({
        ...state,
        editLabsLocationStatus: AsyncStates.INITIAL,
        editLabsLocationErrorMessage: null,
    }),
    [UpdateNumericalCodeActionTypes.REQUEST]: (state, action) => ({
        ...state,
        updateNumericCodeStatus: AsyncStates.LOADING
    }),
    [UpdateNumericalCodeActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        updateNumericCodeStatus: AsyncStates.SUCCESS
    }),
    [UpdateNumericalCodeActionTypes.FAILURE]: (state, action) => ({
        ...state,
        updateNumericCodeStatus: AsyncStates.ERROR
    }),
}, defaultState)


export default settingsReducer;