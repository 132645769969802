import { Cascader, Col, Form, Modal, Row, Typography } from "antd";
import { DefaultOptionType } from "antd/es/cascader";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import { updateIngredientTemplatesRequest } from "src/store/actions/repository";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTemplateId: string;
};

const AddEditIngredientTemplateAdditivesModal = ({
    showModal,
    setShowModal,
    selectedTemplateId,
}: TProps) => {
    const [t] = useTranslate();
    const [templateForm] = Form.useForm();
    const dispatch = useDispatch();
    const templates = useSelector(
        (state: StoreState) => state.repository.templates.data
    );
    const additives = useSelector(
        (state: StoreState) => state.repository.additives.data
    );
    const templateStatus = useSelector(
        (state: StoreState) => state.repository.templates.status
    );

    const currentTemplate = useMemo(() => {
        return templates.find((t) => t.template_id === selectedTemplateId);
    }, [templates, selectedTemplateId]);

    useEffect(() => {
        if (showModal) {
            const additive_ids = currentTemplate?.additives;

            const additiveCascaderValue = additive_ids.map((id: any) => ([additives.find(a => a.additive_id === id)?.category_name, id]))
            templateForm.setFieldValue("additives", additiveCascaderValue);
        }
    }, [currentTemplate, templateForm, showModal, additives]);

    useEffect(() => {
        if (templateStatus === AsyncStates.SUCCESS) {
            setShowModal(false);
        }
    }, [templateStatus, setShowModal]);

    const submit = () => {
        const payload = templateForm.getFieldsValue();

        // Flatten cascader value
        payload.additives = payload.additives.map((a: any) => a[(a.length - 1)]);
        dispatch(
            updateIngredientTemplatesRequest({
                ...payload,
                template_id: selectedTemplateId,
            })
        );
    };

    const additiveByCategories = useMemo(() => {
        const additiveCategories = [
            ...new Set(additives.map((a) => a.category_name)),
        ].filter(p => p).sort((a, b) => a?.localeCompare(b));

        return additiveCategories.map((category_name: any) => ({
            label: category_name ? category_name : "-",
            value: category_name,
            children: additives
                .filter((a) => a.category_name === category_name)
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map((a) => ({
                    label: a.name,
                    value: a.additive_id,
                })),
        }));
    }, [additives]);


    const filter = (inputValue: string, path: DefaultOptionType[]) =>
        path.some(
            (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );

    return (
        <Modal
            title={
                <Typography.Title level={4}>
                    {t("repository.updateAdditives")}
                </Typography.Title>
            }
            open={showModal}
            onOk={submit}
            onCancel={() => setShowModal(false)}
            footer={null}
            destroyOnClose
        >
            <Form
                onFinish={submit}
                form={templateForm}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item
                    name={"additives"}
                    label={t("repository.updateAdditives")}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Cascader
                        style={{ width: "100%" }}
                        options={additiveByCategories}
                        rootClassName="repository-cascader"
                        placeholder={t("common.select")}
                        showSearch={{ filter }}
                        multiple
                        maxTagCount="responsive"
                        showCheckedStrategy={Cascader.SHOW_CHILD}
                    />
                </Form.Item>

                <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
                    <Col>
                        <StyledButton
                            type="default"
                            onClick={() => templateForm.resetFields()}
                        >
                            {t("common.reset")}
                        </StyledButton>
                    </Col>
                    <Col>
                        <StyledButton
                            type="primary"
                            htmlType="submit"
                            disabled={templateStatus === AsyncStates.LOADING}
                            loading={templateStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddEditIngredientTemplateAdditivesModal;
