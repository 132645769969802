import { InboxOutlined } from "@ant-design/icons";
import { Divider, Drawer, Space, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { Dispatch, SetStateAction, useState } from "react";
import useTranslate from "src/utils/useTranslate";

type InventoryAttachmentsDrawerProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
export const InventoryAttachmentsDrawer = ({
  open,
  setOpen,
}: InventoryAttachmentsDrawerProps) => {
  const [fileList, setFileList] = useState<any>([]);
  const [t] = useTranslate();

  const uploadProps = {
    onRemove: (file: any) => {
      setFileList((prevState: any) => {
        const index = prevState.indexOf(file);
        const newFileList = prevState.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file: any) => {
      setFileList((prevState: any) => {
        if (
          fileList.map((fileRecord: any) => fileRecord.name).includes(file.name)
        ) {
          message.error("File already uploaded");
          return prevState;
        } else {
          return [...prevState, file];
        }
      });
    },
    fileList,
    multiple: true,
  };

  return (
    <Drawer
      title="Attachments"
      placement={"right"}
      width={640}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      maskClosable={false}
    >
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t("fileupload.upload")}</p>
        </Dragger>
      </Space>
      <Divider />
      {/* <AttachmentsList /> */}
    </Drawer>
  );
};
