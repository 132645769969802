import { orange } from '@ant-design/colors'
import { Form, Input, Modal, Row, Select, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { AsyncStates } from 'src/constants'
import { countryNames } from 'src/constants/countries'
import { updateBillingDetailsRequest } from 'src/store/actions/settings'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { CustomPrompt } from 'src/utils/CustomPrompts'
import useTranslate from 'src/utils/useTranslate'

type EditBillingDetailModalProps = {
    showEditBillingDetailModal: boolean
    setShowEditBillingDetailModal: React.Dispatch<React.SetStateAction<boolean>>
    customerDetails: any
}

const { Text } = Typography;
const { Option } = Select;


// const data = {
//     "address": {
//         "city": "New York",
//         "country": "US",
//         "line1": "123 Main Street",
//         "line2": "Apt 4B",
//         "postal_code": "10001",
//         "state": "NY"
//     },
//     "email": null,
//     "name": "Polymerize Connect PTE LTD",
//     "phone": null
// }


export const EditBillingDetailModal = ({ showEditBillingDetailModal, setShowEditBillingDetailModal, customerDetails }: EditBillingDetailModalProps) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const [billingDetailForm] = useForm()
    const requiredFieldStar = useRequiredFieldStar()


    const [isEditing, setIsEditing] = useState(false)
    const { updateBillingDetailsStatus } = useSelector((state: StoreState) => state.settings)

    useEffect(() => {
        if (customerDetails) {
            billingDetailForm.setFieldsValue({
                ...customerDetails, country: customerDetails.address?.country ?? null
            })
        }
    }, [billingDetailForm, customerDetails])


    useEffect(() => {
        if (updateBillingDetailsStatus === AsyncStates.SUCCESS) {
            setShowEditBillingDetailModal(false)
            setIsEditing(false)
            billingDetailForm.resetFields()
        }
    }, [billingDetailForm, setShowEditBillingDetailModal, updateBillingDetailsStatus])

    const handleBillingDetails = useCallback((payload: any) => {
        dispatch(updateBillingDetailsRequest(payload))
    }, [dispatch])

    return (
        <>
            <CustomPrompt message={t("common.modal.unsavedwarning")} isEditing={isEditing} />
            <Modal open={showEditBillingDetailModal} onCancel={() => setShowEditBillingDetailModal(false)} footer={null} title={<>{t("billing.modal.editBilling")} {(isEditing && <Text style={{ color: orange.primary }}>{t("common.unsavedChanges")}</Text>)}</>} width={600}>
                <Form
                    name="update_billing_Detail"
                    layout='vertical'
                    form={billingDetailForm}
                    onFieldsChange={() => setIsEditing(true)}
                    onFinish={(values) => handleBillingDetails(values)}
                    requiredMark={false}
                >
                    <Form.Item
                        label={t("billing.modal.name")}
                        name="name"
                        validateTrigger="onChange"
                        required tooltip={requiredFieldStar}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <Text
                                        style={{
                                            color: "red",
                                            display: "block",
                                            textAlign: "left",
                                            margin: "0.2rem",
                                        }}
                                    >
                                        {t("signup.warn.companyName")}
                                    </Text>
                                ),
                            },
                            {
                                pattern: new RegExp(
                                    /^(?!.*[-_]{2})(?=.*[a-zA-Z0-9.]$)[a-zA-Z0-9.][a-zA-Z0-9. _-]*$/
                                ),
                                message: (
                                    <Text style={{ float: "left", color: "red" }}>
                                        {t("billing.modal.nameError")}
                                    </Text>
                                ),
                            },
                        ]}>
                        <Input
                            size="large"
                            placeholder={t("billing.modal.namePlaceholder")}
                            minLength={2}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t("billing.modal.email")}
                        name="email"
                        validateTrigger="onChange"
                        required tooltip={requiredFieldStar}
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp(
                                    /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                                ),
                                message: (
                                    <Text
                                        style={{
                                            color: "red",
                                            display: "block",
                                            textAlign: "left",
                                            margin: "0.2rem",
                                        }}
                                    >
                                        {t("billing.modal.emailError")}
                                    </Text>
                                ),
                            },
                        ]}>
                        <Input
                            size="large"
                            placeholder={t("billing.modal.emailPlaceholder")}
                            minLength={2}
                            type='email'
                        />
                    </Form.Item>

                    <Form.Item
                        label={t("billing.modal.country")}
                        name="country"
                        required tooltip={requiredFieldStar}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <Text
                                        style={{
                                            color: "red",
                                            display: "block",
                                            textAlign: "left",
                                            margin: "0.2rem",
                                        }}
                                    >
                                        {t("signup.warn.location")}
                                    </Text>
                                ),
                            },
                        ]}
                        validateTrigger="onChange"
                    >
                        <Select
                            showSearch
                            placeholder={t("billing.modal.selectCountry")}
                        >
                            {countryNames.map((countryName) => (
                                <Option key={countryName} value={countryName}>
                                    {countryName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Row justify="end">
                        <StyledButton
                            htmlType="submit"
                            type="primary"
                            disabled={!isEditing}
                            loading={updateBillingDetailsStatus === AsyncStates.LOADING}
                        >
                            {t("billing.modal.updateBilling")}
                        </StyledButton>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
