import { put, takeLatest, call, select } from "redux-saga/effects"
import { ForgetPasswordApi } from "src/services/passwordReset"
import { messages } from "src/utils/hooks"
import {
	forgotPasswordRequest,
	forgotPasswordSuccess,
	forgotPasswordFailure,
} from "../actions/forgotPassword"
import { history } from "src"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { APIError } from "src/typings"

type forgotPasswordAction = {
	type: string
	payload: {
		user_email: string
		dontRedirect?: boolean
	}
}

function* forgotPasswordSaga({
	payload,
}: forgotPasswordAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(ForgetPasswordApi, payload, headers)

		if (result.status === "Success") {
			yield put(forgotPasswordSuccess(result))
			if (!payload.dontRedirect) history.push("/after-forgot", payload)
		} else {
			yield put(forgotPasswordFailure(result.message))
		}
	} catch (err) {
		const error = err as APIError
		if (error?.response?.status === 429) {
			yield put(forgotPasswordFailure("You have made too many requests in a short period. Please wait a few minutes and try again.")) // Backend Asked this message to be hardcoded
		} else {
			yield put(forgotPasswordFailure(messages[ln].internal_server_error))
		}
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(forgotPasswordRequest, forgotPasswordSaga)
}
