import { createActions } from "redux-actions";

export enum ProgressActionTypes {
    REQUEST = "PROGRESS_REQUEST",
    SUCCESS = "PROGRESS_SUCCESS",
    FAILURE = "PROGRESS_FAILURE",
    CLEAR = "PROGRESS_CLEAR",
    UPDATE = "PROGRESS_UPDATE",
    REMOVE = "PROGRESS_REMOVE",
    SHOW = "PROGRESS_SHOW"
}

export const {
    progressRequest,
    progressSuccess,
    progressFailure,
    progressClear,
    progressUpdate,
    progressRemove,
    progressShow
} = createActions({
    [ProgressActionTypes.REQUEST]: payload => payload,
    [ProgressActionTypes.SUCCESS]: (data: any) => { return ({ progress: data }) },
    [ProgressActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [ProgressActionTypes.CLEAR]: () => { },
    [ProgressActionTypes.UPDATE]: (data: any, id: string) => { return ({ data: data, id: id }) },
    [ProgressActionTypes.REMOVE]: (id: string) => { return ({ id: id }) },
    [ProgressActionTypes.SHOW]: (showProgress: boolean) => { return ({ showProgress }) }
});
