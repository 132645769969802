import { Drawer } from "antd";
import { FilterForm } from "./FilterForm";
import useTranslate from "src/utils/useTranslate";

const AdvanceFilters = ({
  visible,
  setVisible,
  selectedProjects,
  formState,
  applyFilters,
  clearFilters,
  form,
  setFormState,
  setInnerSelector,
  outerSelector,
  setOuterSelector,
  filtersLoading,
  innerSelector,
}: any) => {
  const [t] = useTranslate();
  function onClose() {
    setVisible(false);
  }
  return (
    <Drawer
      open={visible}
      width={550}
      onClose={onClose}
      bodyStyle={{ padding: "10px 30px" }}
      mask={false}
      title={t("common.advancedFilters")}
    >
      <FilterForm
        selectedProjects={selectedProjects}
        form={form}
        applyFilters={(values: any) => {
          applyFilters(values);
          onClose();
        }}
        clearFilters={() => {
          clearFilters();
        }}
        formState={formState}
        setFormState={setFormState}
        filtersLoading={filtersLoading}
        outerSelector={outerSelector}
        setOuterSelector={setOuterSelector}
        innerSelector={innerSelector}
        setInnerSelector={setInnerSelector}
      />
    </Drawer>
  );
};

export default AdvanceFilters;
