import { sendGet, sendPost } from "src/utils/sendApi";

const polyGPTAPIPrefix = "v1/data/polygpt";

export const createConversationApi = sendPost(
  `${polyGPTAPIPrefix}/conversations`
);

export const getConversationsApi = sendGet(`${polyGPTAPIPrefix}/conversations`);

export const askQuestionApi = sendPost(`${polyGPTAPIPrefix}/conversations/ask`);

export const getConversationHistoryApi = sendPost(
  `${polyGPTAPIPrefix}/conversations/history`
);

export const getConversationSummaryApi = sendPost(
  `${polyGPTAPIPrefix}/conversations/summarize`
);

export const favoriteConversationApi = sendPost(
  `${polyGPTAPIPrefix}/conversations/fav`
);

export const deleteConversationApi = sendPost(
  `${polyGPTAPIPrefix}/conversations/conv/delete`
);

export const updateConversationApi = sendPost(
  `${polyGPTAPIPrefix}/conversations/title`
);
export const deleteChatConvertionApi = sendPost(
  `${polyGPTAPIPrefix}/conversations/chat/delete`
);

export const getExtractionsApi = (params: { conversation_id?: string }) =>
  sendPost(
    `${polyGPTAPIPrefix}/extractions/get?conversation_id=${params.conversation_id}`
  );

export const updateExtractionTableApi = (params: any) => sendPost(
  `${polyGPTAPIPrefix}/extractions/save`
);
