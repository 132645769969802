import { Layout, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { StoreState } from 'src/store/configureStore';
import LeftSideOnboarding from "../SignUp/LeftSideOnboarding";
import "../SignUp/SignUp.scss";
import AllRightsReserved from '../SignUp/AllRightsReserved';

interface AccessDeniedProps {
}
const { Content: LayoutContent } = Layout

export const AccessDenied = (props: AccessDeniedProps) => {
  const { state: userInfo }: any = useLocation()
  const history = useHistory()
  const userData = useSelector((store: StoreState) => store.login.loginResponse)



  useEffect(() => {
    if (userData.user_verified && userData.user_access) {
      history.push("/formulations")
    }
  }, [history, userData.user_access, userData.user_verified, userData?.company_id])

  return (
    <>
      <Layout
        style={{ textAlign: "center", minHeight: "100%", background: "white" }}
        className="fontWorkSans"
      >
        <LayoutContent style={{ overflow: "auto", position: "relative" }}>
          <Space style={{ width: "100%" }} direction="horizontal" size="large">
            <LeftSideOnboarding minWidth="55vw" />
            <Space direction="vertical" style={{ maxWidth: "40vw" }}>
              <Space direction="vertical" style={{ margin: "48px", textAlign: "left" }} size={'large'}>
                <Title
                  level={1}
                  style={{
                    fontSize: "2.5rem",
                    fontWeight: 500,
                    color: "#1753E2",
                  }}
                >
                  Thank you for signing up,{" "}
                  {userInfo?.user_name || userData?.user_name}
                </Title>
                  {userInfo?.user_verified && !userInfo?.user_access ? (
                    <Title level={5}>
                      Our accounts management team will reach out to you within
                      1-2 business days.
                    </Title>
                  ) : (
                    <>
                      <Title level={5}>
                        We've received your sign-up request. A verification link
                        has been sent to your email at{" "}
                        <strong>
                          {userInfo?.user_email || userData?.user_email}
                        </strong>
                      </Title>
                      <Title level={5}>
                        Once you have verified your email, Our accounts
                        management team will reach out to you within 1-2
                        business days.
                      </Title>
                    </>
                  )}
                  <Title level={5}>
                    If you have any questions in the meantime, please reach out
                    to our Customer Support team.
                  </Title>
                

                <Space
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Title
                    level={5}
                    style={{
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    Go back to <Link to="/login">{"Login page"}</Link>
                  </Title>
                  <Title
                    level={5}
                    style={{
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    Need help?{" "}
                    <a href="mailto:contact@polymerize.io">
                      {"Contact support"}
                    </a>
                  </Title>
                </Space>
              </Space>
              <AllRightsReserved />
            </Space>
          </Space>
        </LayoutContent>
      </Layout>
    </>
  );
};
