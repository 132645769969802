import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import {
	loginSaga,
	signupSaga,
	formulationsFilterSaga,
	formulateSaga,
	displayNamesSaga,
	workOrdersSaga,
	workOrderDetailsSaga,
	CustomMlSaga,
	inventorySaga,
	inviteSaga,
	teamsSaga,
	filesSaga,
	suggestedExpSaga,
	loginFirebaseSaga,
	DoeSaga,
	modelAnalysisSaga,
	celsureSaga,
	celsureWoSaga,
	dataSummarySaga,
	chemDrawSaga,
	configsSaga,
	polyGPTSaga,
	customFieldsSaga,
	inventoryV2Saga
} from "./sagas/"
import forgotPasswordSaga from "./sagas/forgotPassword"
import updatePasswordSaga from "./sagas/updatePassword"
import settingsSaga from "./sagas/settings"
import insightsSaga from "./sagas/insights"
import resetPasswordSaga from "./sagas/resetPassword"
import formulationDetailsSaga from "./sagas/formulationDetails"
import compareFormulationsSaga from "./sagas/compareFormulations"
import fileuploadDetailsSaga from "./sagas/development"
import notificationsSaga from "./sagas/notifications"
import woReportPreviewSaga from "./sagas/reportPreview"
import feedbackSaga from "./sagas/feedback"
import templatesSaga from "./sagas/templates"
import loginReducer, { LoginReducerState } from "./reducers/login"
import formulationsFilterReducer, {
	FormulationsFilterState,
} from "./reducers/formulationsFilter"
import formulationDetailsReducer, {
	FormulationDetailsState,
} from "./reducers/formulationDetails"
import compareFormulationReducer, {
	CompareFormulationState,
} from "./reducers/compareFormulations"
import previousActionReducer, {
	PreviousActionReducerState,
} from "./reducers/previousAction"
import loginFirebaseReducer, {
	LoginFirebaseReducerState,
} from "./reducers/firebase"
import settingsReducer, { SettingsReducerState } from "./reducers/settings"
import insightsReducer, { insightsReducerState } from "./reducers/insights"
import suggestedExpReducer, {
	suggestedExpReducerState,
} from "./reducers/suggestedExp"
import fileUploadDetailsReducer, {
	FileUploadDetailsState,
} from "./reducers/development"
import { persistStore, persistReducer, createTransform } from "redux-persist"
import storage from "redux-persist/lib/storage"
import signupReducer, { SignupReducerState } from "./reducers/signup"
import forgotPasswordReducer, {
	ForgotPasswordReducerState,
} from "./reducers/forgotPassword"
import resetPasswordReducer, {
	ResetPasswordReducerState,
} from "./reducers/resetPassword"
import updatePasswordReducer, {
	UpdatePasswordReducerState,
} from "./reducers/updatePassword"
import formulateReducer, { FormulateState } from "./reducers/formulate"
import displayNamesReducer, {
	DisplayNamesState,
	FilteredDisplayNamesReducer,
	FilteredDisplayNamesState,
} from "./reducers/displayNames"
import sidebarReducer, { SidebarState } from "./reducers/sidebar"
import workOrdersReducer, { WorkOrdersState } from "./reducers/workOrders"
import inviteReducer, { InviteState } from "./reducers/invite"
import teamsReducer, { TeamsState } from "./reducers/teams"
import conversionReducer, { ConversionState } from "./reducers/conversion"
import workOrderDetailsReducer, {
	WorkOrderDetailsState,
} from "./reducers/workOrderDetails"
import filesReducer, { FilesState } from "./reducers/files"
import notificationsReducer, {
	NotificationsState,
} from "./reducers/notifications"
import inverseModelReducer, {
	InverseModelReducerState,
} from "./reducers/inverseModel"
import projectsReducer, { ProjectsState } from "./reducers/projects"
import inverseModelSaga from "./sagas/inverseModel"
import { AsyncStates } from "src/constants"
import feedbackReducer, { FeedbackState } from "./reducers/feedback"
import proceduresSaga from "./sagas/procedures"
import procedureReducer, { ProcedureReducerState } from "./reducers/procedures"
import languageReducer, { LanguageState } from "./reducers/language"
import reportPreviewReducer, {
	ReportPreviewReducerState,
} from "./reducers/reportPreview"
import inventoryReducer, { inventoryReducerState } from "./reducers/inventory"
import Axios from "axios"
import projectsSaga from "./sagas/projects"
import { Modal } from "antd"
import CustomMlReducer, { CustomMlState } from "src/store/reducers/customML"
import DoeReducer, { DoeReducerState } from "src/store/reducers/doe"
import { rootReducer } from "./reducers"
import ModelAnalysisReducer, {
	ModelAnalysisState,
} from "src/store/reducers/modelAnalysis"
import CelsureReducer, { CelsureReducerState } from "src/store/reducers/celsure"
import CelsureWoReducer, {
	CelsureWoReducerState,
} from "src/store/reducers/celsureWo"
import celsureReportPreviewReducer, {
	CelsureReportPreviewReducerState,
} from "src/store/reducers/celsureReportPreview"
import celsureWoReportPreviewSaga from "./sagas/celsureReportPreview"
import celsureCompareFormulationsSaga from "./sagas/celsureCompareFormulation"
import celsureCompareFormulationsReducer, {
	CelsureCompareFormulationState,
} from "./reducers/celsureCompareFormulation"
import isEditingReducer, { isEditingState } from "src/store/reducers/isEditing"
import dataSummaryReducer, {
	DataSummaryReducerState,
} from "./reducers/dataSummary"
import catDataSummaryReducer, {
	CatDataSummaryReducerState,
} from "./reducers/catDataSummary"
import pivotTableReducer, {
	PivotTableReducerState,
} from "./reducers/pivotTable"
import saveFormulationsFiltersReducer, {
	SaveFormulationsFilterState,
} from "./reducers/saveFormulationsFilters"
import savedFormulationsFilters, {
	GetSavedFiltersState,
} from "./reducers/savedFormulationsFilters"
import deleteFormulationsFilterReducer, {
	DeleteFormulationsFilterState,
} from "./reducers/deleteFormulationsFilters"
import pivotTableSaga from "./sagas/pivotTable"
import catDataSaga from "./sagas/catDataSummary"
import dashBoardReducer, { DashboardState } from "./reducers/dashboard"
import dashboardSaga from "./sagas/dasboard"
import widgetSaga from "./sagas/widget"
import auditLogReducer, { AuditLogState } from "./reducers/auditLog"
import auditLogSaga from "./sagas/auditLog"
import conversionSaga from "./sagas/conversion"
import chemDrawReducer, { chemDrawState } from "./reducers/chemDraw"
import characterizationMethodsReducer, {
	CharacterizationMethodsReducerState,
} from "./reducers/characterizationMethods"
import characterizationMethodsSaga from "./sagas/characterizationMethods"
import saveFormulationFilters from "./sagas/saveFormulationsFilters"
import newInverseModelReducer, {
	NewInverseModelReducerState,
} from "./reducers/newInverseModel"
import newInverseModelSaga from "./sagas/newInverseModel"
import templatesReducer, { TemplatesState } from "./reducers/templates"
import defaultHeadersReducer, {
	DefaultHeadersState,
} from "./reducers/defaultHeaders"
import productGuideSettingReducer, {
	ProductGuideSettingsState,
} from "./reducers/product-guide-settings"
import connectionReducer, { connectionReducerState } from "./reducers/connection"
import explainableAIReducer, { ExplainableAIReducerState } from "./reducers/explainableAI"
import explainableAISaga from "./sagas/explainableAI"
import performanceReducer, {
	PerformanceReducerState,
} from "./reducers/performance";
import performanceSaga from "./sagas/performance"
import configsReducer, { ConfigsReducerState } from "src/store/reducers/configs"
import preferencesReducer, { PreferencesReducerState } from "./reducers/preferences"
import preferencesSaga from "./sagas/preferences"
import woExperimentUnitConversionSaga from "./sagas/woUnitConversion"
import woExperimentUnitConversionReducer, { ExperimentUnitConversionState } from "./reducers/woUnitConversion"
import loginViaMagicLinkSaga from "./sagas/loginViaMagicLink"
import loginViaMagicLinkReducer, { LoginViaMagicLinkState } from "./reducers/loginViaMagicLink"
import polyGPTReducer, { PolyGPTState } from "./reducers/polyGPT"
import customFieldsReducer, { CustomFieldsState } from "./reducers/customFields"
import commonSaga from "./sagas/common"
import commonReducer, { CommonState } from "./reducers/common"
import widgetReducer, { WidgetState } from "./reducers/widget"
import inventoryV2Reducer, { InventoryV2State } from "./reducers/inventoryV2"
import semAnalysisReducer, { SemAnalysisState } from "./reducers/semAnalysis"
import semAnalysisSaga from "./sagas/semAnalysis"
import SmileSummaryReducer, { SmileSummaryState } from "./reducers/smileSummary"
import smileSummarySaga from "./sagas/smileSummary"
import repositoryReducer, { RepositoryState } from "./reducers/repository"
import repositorySaga from "./sagas/repository"
import qualityCheckReducer, { QualityCheckState } from "./reducers/qualityCheck"
import qualityCheckSaga from "./sagas/qualityCheck"
import userPreferencesSaga from "./sagas/userPreferences"
import multistageHeatmapSaga from "./sagas/multistageHeatmap"
import userPreferencesReducer, { UserPreferencesState } from "./reducers/userPreferences"
import multistageHeatmapReducer, { MultistageHeatmapState } from "./reducers/multistageHeatmap"
import assetLibrarySaga from './sagas/assetLibrary'
import assetLibraryReducer, { AssetLibraryState } from "./reducers/assetLibrary"

export interface StoreState {
	login: LoginReducerState;
	loginFirebase: LoginFirebaseReducerState;
	forgotPassword: ForgotPasswordReducerState;
	updatePassword: UpdatePasswordReducerState;
	resetPassword: ResetPasswordReducerState;
	signup: SignupReducerState;
	displayNames: DisplayNamesState;
	filteredDisplayNames: FilteredDisplayNamesState;
	formulationsFilter: FormulationsFilterState;
	formulationDetails: FormulationDetailsState;
	formulate: FormulateState;
	compareFormulations: CompareFormulationState;
	fileUploadDetails: FileUploadDetailsState;
	sidebar: SidebarState;
	workOrders: WorkOrdersState;
	workOrderDetails: WorkOrderDetailsState;
	invite: InviteState;
	teams: TeamsState;
	files: FilesState;
	notifications: NotificationsState;
	feedback: FeedbackState;
	settings: SettingsReducerState;
	insights: insightsReducerState;
	suggestedExp: suggestedExpReducerState;
	inverseModel: InverseModelReducerState;
	procedure: ProcedureReducerState;
	language: LanguageState;
	reportPreview: ReportPreviewReducerState;
	projects: ProjectsState;
	inventory: inventoryReducerState;
	CustomML: CustomMlState;
	doe: DoeReducerState;
	previousAction: PreviousActionReducerState;
	modelAnalysis: ModelAnalysisState;
	celsure: CelsureReducerState;
	celsureWo: CelsureWoReducerState;
	celsureReportPreview: CelsureReportPreviewReducerState;
	celsureCompareFormulation: CelsureCompareFormulationState;
	isEditing: isEditingState;
	dataSummary: DataSummaryReducerState;
	catDataSummary: CatDataSummaryReducerState;
	pivotTable: PivotTableReducerState;
	dashboard: DashboardState;
	semAnalysis: SemAnalysisState;
	auditLog: AuditLogState;
	conversion: ConversionState;
	chemDraw: chemDrawState;
	characterizationMethods: CharacterizationMethodsReducerState;
	saveFormulationsFilters: SaveFormulationsFilterState;
	savedFormulationsFilters: GetSavedFiltersState;
	deleteFormulationsFilters: DeleteFormulationsFilterState;
	newInverseModel: NewInverseModelReducerState;
	templates: TemplatesState;
	defaultHeaders: DefaultHeadersState;
	productGuideSettings: ProductGuideSettingsState;
	connection: connectionReducerState;
	explainableAI: ExplainableAIReducerState;
	performance: PerformanceReducerState;
	configs: ConfigsReducerState,
	preferences: PreferencesReducerState,
	woExperimentUnitConversion: ExperimentUnitConversionState,
	loginViaMagicLink: LoginViaMagicLinkState,
	polyGpt: PolyGPTState,
	customFields: CustomFieldsState
	common: CommonState;
	widget: WidgetState
	inventoryV2: InventoryV2State;
	smileSummary: SmileSummaryState;
	repository: RepositoryState;
	qualityCheck: QualityCheckState;
	userPreferences: UserPreferencesState;
	multiStageHeatmap: MultistageHeatmapState;
	assetLibrary: AssetLibraryState
}

const sagaMiddleware = createSagaMiddleware()
const composeEnhancer =
	((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
		(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			trace: true,
			traceLimit: 25,
		})) ||
	compose

const setTransform = createTransform(
	// transform state on its way to being serialized and persisted.
	(inboundState: LoginReducerState, key) => inboundState,
	// transform state being rehydrated
	(outboundState: LoginReducerState, key) => ({
		...outboundState,
		status: AsyncStates.INITIAL,
	}),
	// define which reducers this transform gets called for.
	{ whitelist: ["login"] }
)

const persistConfig = {
	key: "root",
	storage,
	whitelist: [
		"login",
		"configs",
		"sidebar",
		"language",
		"workOrderDetails",
		"celsureWo",
		"compareFormulations",
		"projects",
		"celsureCompareFormulation",
		"assetLibrary"
	],
	transforms: [setTransform],
}

export const appReducer = combineReducers<StoreState>({
	login: loginReducer,
	loginFirebase: loginFirebaseReducer,
	forgotPassword: forgotPasswordReducer,
	updatePassword: updatePasswordReducer,
	resetPassword: resetPasswordReducer,
	signup: signupReducer,
	displayNames: displayNamesReducer,
	filteredDisplayNames: FilteredDisplayNamesReducer,
	formulationsFilter: formulationsFilterReducer,
	formulationDetails: formulationDetailsReducer,
	formulate: formulateReducer,
	compareFormulations: compareFormulationReducer,
	fileUploadDetails: fileUploadDetailsReducer,
	sidebar: sidebarReducer,
	workOrders: workOrdersReducer,
	workOrderDetails: workOrderDetailsReducer,
	invite: inviteReducer,
	teams: teamsReducer,
	files: filesReducer,
	notifications: notificationsReducer,
	feedback: feedbackReducer,
	settings: settingsReducer,
	insights: insightsReducer,
	suggestedExp: suggestedExpReducer,
	inverseModel: inverseModelReducer,
	procedure: procedureReducer,
	language: languageReducer,
	reportPreview: reportPreviewReducer,
	projects: projectsReducer,
	inventory: inventoryReducer,
	CustomML: CustomMlReducer,
	doe: DoeReducer,
	previousAction: previousActionReducer,
	modelAnalysis: ModelAnalysisReducer,
	celsure: CelsureReducer,
	celsureWo: CelsureWoReducer,
	celsureReportPreview: celsureReportPreviewReducer,
	characterizationMethods: characterizationMethodsReducer,
	celsureCompareFormulation: celsureCompareFormulationsReducer,
	isEditing: isEditingReducer,
	dataSummary: dataSummaryReducer,
	catDataSummary: catDataSummaryReducer,
	pivotTable: pivotTableReducer,
	dashboard: dashBoardReducer,
	semAnalysis: semAnalysisReducer,
	conversion: conversionReducer,
	chemDraw: chemDrawReducer,
	auditLog: auditLogReducer,
	saveFormulationsFilters: saveFormulationsFiltersReducer,
	savedFormulationsFilters: savedFormulationsFilters,
	deleteFormulationsFilters: deleteFormulationsFilterReducer,
	newInverseModel: newInverseModelReducer,
	templates: templatesReducer,
	defaultHeaders: defaultHeadersReducer,
	productGuideSettings: productGuideSettingReducer,
	connection: connectionReducer,
	explainableAI: explainableAIReducer,
	performance: performanceReducer,
	configs: configsReducer,
	preferences: preferencesReducer,
	woExperimentUnitConversion: woExperimentUnitConversionReducer,
	loginViaMagicLink: loginViaMagicLinkReducer,
	polyGpt: polyGPTReducer,
	common: commonReducer,
	widget: widgetReducer,
	customFields: customFieldsReducer,
	inventoryV2: inventoryV2Reducer,
	smileSummary: SmileSummaryReducer,
	repository: repositoryReducer,
	qualityCheck: qualityCheckReducer,
	userPreferences: userPreferencesReducer,
	multiStageHeatmap: multistageHeatmapReducer,
	assetLibrary: assetLibraryReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
	persistedReducer,
	composeEnhancer(
		applyMiddleware(sagaMiddleware)
	)
)

const rootSagas = [
	loginSaga,
	loginFirebaseSaga,
	signupSaga,
	forgotPasswordSaga,
	updatePasswordSaga,
	resetPasswordSaga,
	displayNamesSaga,
	formulationsFilterSaga,
	formulationDetailsSaga,
	formulateSaga,
	compareFormulationsSaga,
	fileuploadDetailsSaga,
	workOrdersSaga,
	workOrderDetailsSaga,
	inviteSaga,
	teamsSaga,
	filesSaga,
	notificationsSaga,
	feedbackSaga,
	settingsSaga,
	insightsSaga,
	suggestedExpSaga,
	inverseModelSaga,
	proceduresSaga,
	woReportPreviewSaga,
	projectsSaga,
	inventorySaga,
	CustomMlSaga,
	DoeSaga,
	modelAnalysisSaga,
	celsureSaga,
	celsureWoSaga,
	celsureWoReportPreviewSaga,
	characterizationMethodsSaga,
	celsureCompareFormulationsSaga,
	dataSummarySaga,
	catDataSaga,
	dashboardSaga,
	semAnalysisSaga,
	conversionSaga,
	chemDrawSaga,
	auditLogSaga,
	pivotTableSaga,
	saveFormulationFilters,
	newInverseModelSaga,
	templatesSaga,
	explainableAISaga,
	performanceSaga,
	configsSaga,
	preferencesSaga,
	woExperimentUnitConversionSaga,
	loginViaMagicLinkSaga,
	polyGPTSaga,
	commonSaga,
	widgetSaga,
	customFieldsSaga,
	commonSaga,
	inventoryV2Saga,
	smileSummarySaga,
	repositorySaga,
	qualityCheckSaga,
	userPreferencesSaga,
	multistageHeatmapSaga,
	assetLibrarySaga
]
rootSagas.forEach(sagaMiddleware.run)

const persistor = persistStore(store)
export { store, persistor }

Axios.interceptors.response.use(
	(res) => {
		const storeState = store.getState()
		storeState.previousAction.length &&
			store.dispatch({ type: "CLEAR_PREVIOUS_ACTION" })
		return res
	},
	(err) => {
		if (err?.response?.status === 401) {
			const storeState = store.getState()
			if (err.response.data.detail === "session_expired") {
				!!storeState.login.loginResponse?.user_id &&
					Modal.info({
						title: "Session expired",
						content: "Please Login again",
					})
				store.dispatch({ type: "LOGOUT_SUCCESS" })
			}
			else if (err?.response?.data?.detail === "new_token_required") {
				const storeState = store.getState()
				const requestPayload = {
					type: "REFRESH_TOKEN_REQUEST",
					payload: {
						lang: storeState.language.current,
					},
				}
				store.dispatch(requestPayload)
				store.dispatch({ type: "CLEAR_PREVIOUS_ACTION" })
			}
		}
		return Promise.reject(err)
	}
)
