import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import {
    deleteSemAnalysisFilesClear,
    deleteSemAnalysisFilesRequest,
    fetchSemAnalysisListRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import ThumbnailList from "../Shared/ThumbnailList";
import { Modal, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { IMAGE_ANALYSIS_TYPES } from "../SEMAnalysisWrapper";
import SelectionsActions from "../Shared/SelectionsActions";

const WhiteObjectAnalysis = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let {
        semAnalysisListStatus,
        deleteSemAnalysisFilesStatus,
        analysisTypes,
        semAnalysisList,
        processWhiteObjectsStatus
    } = useSelector((state: StoreState) => state.semAnalysis);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);

    const fetchThumbnails = useCallback(() => {
        dispatch(
            fetchSemAnalysisListRequest({
                analysisType:
                    analysisTypes[
                    IMAGE_ANALYSIS_TYPES.WHITE_OBJECT_ANALYSIS as keyof typeof analysisTypes
                    ],
            })
        );
    }, [analysisTypes, dispatch]);

    useEffect(() => {
        fetchThumbnails();

        return () => { dispatch(deleteSemAnalysisFilesClear()); }
    }, [dispatch, fetchThumbnails]);

    useEffect(() => {
        if ([deleteSemAnalysisFilesStatus].includes(AsyncStates.SUCCESS)) {
            fetchThumbnails();
            setSelectedFileIds([]);
        }
    }, [deleteSemAnalysisFilesStatus, dispatch, fetchThumbnails]);

    useEffect(() => {
        if ([processWhiteObjectsStatus].includes(AsyncStates.SUCCESS)) {
            fetchThumbnails();
        }
    }, [processWhiteObjectsStatus, dispatch, fetchThumbnails]);

    const handleCardClick = ({ fileId }: any) => {
        history.push(
            `/sem-analysis/${IMAGE_ANALYSIS_TYPES.WHITE_OBJECT_ANALYSIS}/files/${fileId}`
        );
    };

    const filteredSemAnalysisList = useMemo(
        () =>
            Array.isArray(semAnalysisList)
                ? semAnalysisList
                    ?.filter((item: any) => String(item.status).toLowerCase() === "completed")
                    ?.sort(
                        (x: any, y: any) =>
                            new Date(y.created_at).getTime() -
                            new Date(x.created_at).getTime()
                    )
                : [],
        [semAnalysisList]
    );

    const selectAll = useCallback(() => {
        if (selectedFileIds.length === filteredSemAnalysisList.length) setSelectedFileIds([]);
        else {
            setSelectedFileIds(filteredSemAnalysisList.map(s => s.file_id));
        }
    }, [filteredSemAnalysisList, selectedFileIds.length]);

    const deleteFiles = useCallback(() => {
        Modal.confirm({
            title: "Are you sure?",
            icon: <ExclamationCircleOutlined />,
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                dispatch(
                    deleteSemAnalysisFilesRequest({
                        file_ids: selectedFileIds || [],
                        analysis_type:
                            analysisTypes[
                            IMAGE_ANALYSIS_TYPES.WHITE_OBJECT_ANALYSIS as keyof typeof analysisTypes
                            ],
                    })
                );
            },
        });
    }, [analysisTypes, dispatch, selectedFileIds]);

    return (
        <Spin
            spinning={[deleteSemAnalysisFilesStatus, semAnalysisListStatus].includes(
                AsyncStates.LOADING
            )}
        >
            <SelectionsActions
                count={filteredSemAnalysisList?.length || 0}
                selectedIds={selectedFileIds}
                onSelectAll={selectAll}
                showDownloadReport={false}
                onDelete={deleteFiles}
            />
            <ThumbnailList
                handleCardClick={handleCardClick}
                thumbnailList={filteredSemAnalysisList}
                loading={semAnalysisListStatus === AsyncStates.LOADING}
                selectable={true}
                setSelectedIds={setSelectedFileIds}
                selectedIds={selectedFileIds}
            />
        </Spin>
    );
};

export default WhiteObjectAnalysis;
