import { createActions } from "redux-actions";

export enum UpdateExperimentUnitActionTypes {
    REQUEST = "UPDATE_EXPERIMENT_UNIT_REQUEST",
    SUCCESS = "UPDATE_EXPERIMENT_UNIT_SUCCESS",
    FAILURE = "UPDATE_EXPERIMENT_UNIT_FAILURE",
    CLEANUP = "UPDATE_EXPERIMENT_UNIT_CLEANUP",
}

export const {
    updateExperimentUnitRequest,
    updateExperimentUnitSuccess,
    updateExperimentUnitFailure,
    updateExperimentUnitCleanup,
} = createActions({
    [UpdateExperimentUnitActionTypes.REQUEST]: payload => payload,
    [UpdateExperimentUnitActionTypes.SUCCESS]: (response, type) => ({ response, type }),
    [UpdateExperimentUnitActionTypes.FAILURE]: payload => payload,
    [UpdateExperimentUnitActionTypes.CLEANUP]: () => { },
})