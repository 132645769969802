import { useCallback, useEffect, useState } from "react";
import "./Properties.scss";
import useTranslate from "src/utils/useTranslate";
import NoData from "../Common/NoData";
import AddEditPropertyModel from "../Common/AddEditPropertyModel";
import PropertySidebar from "./PropertySidebar";
import PropertyDetails from "./PropertyDetails";
import RepositoryActionDrawer from "../Common/RepositoryActionDrawer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
    fetchPropertyRequest,
    removePropertyRequest,
} from "src/store/actions/repository";

const Properties = () => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [selectedProperty, setSelectedProperty] = useState<string>();
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [mode, setMode] = useState<"edit" | "create">();
    const properties = useSelector(
        (state: StoreState) => state.repository.properties
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPropertyRequest());
    }, [dispatch]);

    useEffect(() => {
        // Update selected properties when changed
        const availableProperties = properties.data.map((p) => p.property_id);
        setSelectedProperties((prev) =>
            prev.filter((p) => availableProperties.includes(p))
        );
    }, [properties, selectedProperty]);

    useEffect(() => {
        // Update current selected property view
        const availableProperties = properties.data.map((p) => p.property_id);
        if (selectedProperty && !availableProperties.includes(selectedProperty))
            setSelectedProperty(undefined);
    }, [properties, selectedProperty]);

    useEffect(() => {
        if (selectedProperties.length > 0) setDrawerOpen(true);
        else setDrawerOpen(false);
    }, [selectedProperties]);

    const deleteProperties = useCallback(() => {
        dispatch(
            removePropertyRequest({
                property_ids: selectedProperties,
            })
        );
    }, [dispatch, selectedProperties]);

    const onDismiss = () => {
        setSelectedProperties([]);
        setDrawerOpen(false);
    };

    return (
        <Spin
            spinning={properties.status === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}
        >
            <div className="property-container">
                {properties.data.length === 0 &&
                    properties.status === AsyncStates.SUCCESS ? (
                    <NoData
                        title={`${t("repository.totalProperties")} (0)`}
                        btns={[t("common.addNewProperty"), t("repository.uploadBulkProperties")]}
                        addItem={setShowModal}
                    />
                ) : (
                    <div className="content-container">
                        {properties.data.length > 0 && (
                            <>
                                <PropertySidebar
                                    addItem={() => {
                                        setMode("create");
                                        setShowModal(true);
                                    }}
                                    setSelectedProperty={setSelectedProperty}
                                    selectedProperty={selectedProperty}
                                    selectedProperties={selectedProperties}
                                    setSelectedProperties={setSelectedProperties}
                                    setSearchTerm={setSearchTerm}
                                    searchTerm={searchTerm}
                                />
                                {selectedProperty && (
                                    <PropertyDetails
                                        selectedProperty={selectedProperty}
                                        showPropertyModal={() => {
                                            setMode("create");
                                            setShowModal(true);
                                        }}
                                        showEditPropertyModal={() => {
                                            setMode("edit");
                                            setShowModal(true);
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
                <AddEditPropertyModel
                    showModal={showModal}
                    setShowModal={setShowModal}
                    mode={mode}
                    selectedPropertyId={selectedProperty}
                />
                <RepositoryActionDrawer
                    open={drawerOpen}
                    close={onDismiss}
                    selected={selectedProperties.length}
                    onDelete={deleteProperties}
                />
            </div>
        </Spin>
    );
};

export default Properties;
