import React, { useEffect, useLayoutEffect, useRef } from 'react'
import cx from 'classnames'
import { useFirstRender } from './utils/useFirstRender'
import { antdTheme } from 'src/constants'



export const TextComponent = React.memo<any>(
    ({
        active,
        focus,
        rowData,
        setRowData,
        columnData: {
            original: {
                columnData: {
                    placeholder,
                    alignRight,
                    formatInputOnFocus,
                    formatBlurredInput,
                    parseUserInput,
                    continuousUpdates,
                }
            }
        },
        id,
        disabled
    }: any) => {
        const ref = useRef<HTMLInputElement>(null)
        const firstRender = useFirstRender()

        // We create refs for async access so we don't have to add it to the useEffect dependencies
        const asyncRef = useRef({
            rowData,
            formatInputOnFocus,
            formatBlurredInput,
            setRowData,
            parseUserInput,
            continuousUpdates,
            firstRender,
            // Timestamp of last focus (when focus becomes true) and last change (input change)
            // used to prevent un-necessary updates when value was not changed
            focusedAt: 0,
            changedAt: 0,
            // This allows us to keep track of whether or not the user blurred the input using the Esc key
            // If the Esc key is used we do not update the row's value (only relevant when continuousUpdates is false)
            escPressed: false,
        })
        asyncRef.current = {
            rowData,
            formatInputOnFocus,
            formatBlurredInput,
            setRowData,
            parseUserInput,
            continuousUpdates,
            firstRender,
            // Keep the same values across renders
            focusedAt: asyncRef.current.focusedAt,
            changedAt: asyncRef.current.changedAt,
            escPressed: asyncRef.current.escPressed,
        }

        useLayoutEffect(() => {
            // When the cell gains focus we make sure to immediately select the text in the input:
            // - If the user gains focus by typing, it will replace the existing text, as expected
            // - If the user gains focus by clicking or pressing Enter, the text will be preserved and selected
            if (focus) {
                if (ref.current) {
                    // Make sure to first format the input
                    ref.current.value = asyncRef.current.formatInputOnFocus(
                        asyncRef.current.rowData?.[id]
                    )
                    ref.current.focus()
                    // ref.current.select()
                }

                // We immediately reset the escPressed
                asyncRef.current.escPressed = false
                // Save current timestamp
                asyncRef.current.focusedAt = Date.now()
            }
            // When the cell looses focus (by pressing Esc, Enter, clicking away...) we make sure to blur the input
            // Otherwise the user would still see the cursor blinking
            else {
                if (ref.current) {
                    // Update the row's value on blur only if the user did not press escape (only relevant when continuousUpdates is false)
                    if (
                        !asyncRef.current.escPressed &&
                        !asyncRef.current.continuousUpdates &&
                        !asyncRef.current.firstRender &&
                        // Make sure that focus was gained more than 10 ms ago, used to prevent flickering
                        asyncRef.current.changedAt >= asyncRef.current.focusedAt
                    ) {
                        asyncRef.current.setRowData(
                            asyncRef.current.parseUserInput(ref.current.value)
                        )
                    }
                    ref.current.blur()
                }
            }
        }, [focus, id])

        useEffect(() => {
            if (!focus && ref.current) {
                // On blur or when the data changes, format it for display
                ref.current.value = asyncRef.current.formatBlurredInput(rowData?.[id])
            }
        }, [focus, rowData, id])

        return (
            <input
                disabled={disabled}
                // We use an uncontrolled component for better performance
                defaultValue={formatBlurredInput(rowData?.[id ?? ""])}
                className={cx('dsg-input', alignRight && 'dsg-input-align-right')}
                placeholder={active ? placeholder : undefined}
                // Important to prevent any undesired "tabbing"
                tabIndex={-1}
                ref={ref}
                value={rowData?.[id] ?? ""}
                // Make sure that while the cell is not focus, the user cannot interact with the input
                // The cursor will not change to "I", the style of the input will not change,
                // and the user cannot click and edit the input (this part is handled by DataSheetGrid itself)
                style={{ pointerEvents: focus ? 'auto' : 'none', fontSize: antdTheme.fontSizeHeading5, width: "100%" }}
                onChange={(e) => {
                    asyncRef.current.changedAt = Date.now()

                    // Only update the row's value as the user types if continuousUpdates is true
                    if (continuousUpdates) {
                        setRowData({ ...rowData, [id]: parseUserInput(e.target.value) })
                    }
                }}
                onKeyDown={(e) => {
                    // Track when user presses the Esc key
                    if (e.key === 'Escape') {
                        asyncRef.current.escPressed = true
                    }
                }}
            />
        )
    }
)


