import { WrapperTooltipProps } from "antd/lib/form/FormItemLabel";
import { antdTheme } from "src/constants";
import useTranslate from "src/utils/useTranslate";

export const useRequiredFieldStar  = ():WrapperTooltipProps => {
  const [t] = useTranslate()
  return ({
    title: t("common.requiredField"),
    icon: 
      <div
        style={{
          color: antdTheme.colorError, fontSize:antdTheme.fontSizeLG,
        }}
      >
        *
      </div>
  })
}