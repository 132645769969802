import { createActions } from "redux-actions";
import { FormulationDatum } from "../../services/formulation/interface";

export enum CompareFormulationsActionTypes {
  REQUEST = "COMPARE_FORMULATIONS_REQUEST",
  SUCCESS = "COMPARE_FORMULATIONS_SUCCESS",
  FAILURE = "COMPARE_FORMULATIONS_FAILURE",
  CLEANUP = "COMPARE_FORMULATIONS_CLEANUP",
}
export enum LinkedCompareFormulationsActionTypes {
  REQUEST = "LINKED_COMPARE_FORMULATIONS_REQUEST",
  SUCCESS = "LINKED_COMPARE_FORMULATIONS_SUCCESS",
  FAILURE = "LINKED_COMPARE_FORMULATIONS_FAILURE",
  CLEANUP = "LINKED_COMPARE_FORMULATIONS_CLEANUP",
}

export enum ReportUploadActionTypes {
  REQUEST = "REPORT_UPLOAD_REQUEST",
  SUCCESS = "REPORT_UPLOAD_SUCCESS",
  FAILURE = "REPORT_UPLOAD_FAILURE",
}

export enum ReportShareActionTypes {
  REQUEST = "REPORT_SHARE_REQUEST",
  SUCCESS = "REPORT_SHARE_SUCCESS",
  FAILURE = "REPORT_SHARE_FAILURE",
}

export enum ReportDeleteActionTypes {
  REQUEST = "REPORT_DELETE_REQUEST",
  SUCCESS = "REPORT_DELETE_SUCCESS",
  FAILURE = "REPORT_DELETE_FAILURE",
}


export enum ReportsRetrieveActionTypes {
  REQUEST = "REPORTS_RETRIEVE_REQUEST",
  SUCCESS = "REPORTS_RETRIEVE_SUCCESS",
  FAILURE = "REPORTS_RETRIEVE_FAILURE",
}

export enum TemplateCreateActionTypes {
  REQUEST = "TEMPLATE_CREATE_REQUEST",
  SUCCESS = "TEMPLATE_CREATE_SUCCESS",
  FAILURE = "TEMPLATE_CREATE_FAILURE",
  CLEANUP = "TEMPLATE_CREATE_CLEANUP",
}

export enum TemplateListActionTypes {
  REQUEST = "TEMPLATE_LIST_REQUEST",
  SUCCESS = "TEMPLATE_LIST_SUCCESS",
  FAILURE = "TEMPLATE_LIST_FAILURE",
}

export enum TemplateUpdateActionTypes {
  REQUEST = "TEMPLATE_UPDATE_REQUEST",
  SUCCESS = "TEMPLATE_UPDATE_SUCCESS",
  FAILURE = "TEMPLATE_UPDATE_FAILURE",
}

export enum TemplateDeleteActionTypes {
  REQUEST = "TEMPLATE_DELETE_REQUEST",
  SUCCESS = "TEMPLATE_DELETE_SUCCESS",
  FAILURE = "TEMPLATE_DELETE_FAILURE",
}



export const {
  compareFormulationsRequest,
  compareFormulationsSuccess,
  compareFormulationsFailure,
  linkedCompareFormulationsRequest,
  linkedCompareFormulationsSuccess,
  linkedCompareFormulationsFailure,
  reportUploadRequest,
  reportUploadSuccess,
  reportUploadFailure,
  reportShareRequest,
  reportShareSuccess,
  reportShareFailure,
  reportDeleteRequest,
  reportDeleteSuccess,
  reportDeleteFailure,
  reportsRetrieveRequest,
  reportsRetrieveSuccess,
  reportsRetrieveFailure,
  templateListRequest,
  templateListSuccess,
  templateListFailure,
  templateCreateRequest,
  templateCreateSuccess,
  templateCreateFailure,
  templateCreateCleanup,
  templateUpdateRequest,
  templateUpdateSuccess,
  templateUpdateFailure,
  templateDeleteRequest,
  templateDeleteSuccess,
  templateDeleteFailure,
  compareFormulationsCleanup,
} = createActions({
  [CompareFormulationsActionTypes.REQUEST]: (formulationId: Array<string>) => ({ formulationId }),
  [CompareFormulationsActionTypes.SUCCESS]: (formulationDetailsData: FormulationDatum[]) => ({ formulationDetailsData }),
  [CompareFormulationsActionTypes.FAILURE]: (error: string) => ({ error }),
  [LinkedCompareFormulationsActionTypes.REQUEST]: (payload: any) => (payload),
  [LinkedCompareFormulationsActionTypes.SUCCESS]: (formulationDetailsData: FormulationDatum[]) => ({ formulationDetailsData }),
  [LinkedCompareFormulationsActionTypes.FAILURE]: (error: string) => ({ error }),
  [ReportUploadActionTypes.REQUEST]: payload => payload,
  [ReportUploadActionTypes.SUCCESS]: payload => payload,
  [ReportUploadActionTypes.FAILURE]: (error: string) => ({ error }),
  [ReportShareActionTypes.REQUEST]: payload => payload,
  [ReportShareActionTypes.SUCCESS]: payload => payload,
  [ReportShareActionTypes.FAILURE]: (error: string) => ({ error }),
  [ReportDeleteActionTypes.REQUEST]: payload => payload,
  [ReportDeleteActionTypes.SUCCESS]: payload => payload,
  [ReportDeleteActionTypes.FAILURE]: (error: string) => ({ error }),
  [ReportsRetrieveActionTypes.REQUEST]: payload => payload,
  [ReportsRetrieveActionTypes.SUCCESS]: payload => payload,
  [ReportsRetrieveActionTypes.FAILURE]: (error: string) => ({ error }),
  [TemplateListActionTypes.REQUEST]: payload => payload,
  [TemplateListActionTypes.SUCCESS]: payload => payload,
  [TemplateListActionTypes.FAILURE]: (error: string) => ({ error }),
  [TemplateCreateActionTypes.REQUEST]: payload => payload,
  [TemplateCreateActionTypes.SUCCESS]: payload => payload,
  [TemplateCreateActionTypes.FAILURE]: (error: string) => ({ error }),
  [TemplateCreateActionTypes.CLEANUP]: () => { },
  [TemplateUpdateActionTypes.REQUEST]: payload => payload,
  [TemplateUpdateActionTypes.SUCCESS]: payload => payload,
  [TemplateUpdateActionTypes.FAILURE]: (error: string) => ({ error }),
  [TemplateDeleteActionTypes.REQUEST]: payload => payload,
  [TemplateDeleteActionTypes.SUCCESS]: payload => payload,
  [TemplateDeleteActionTypes.FAILURE]: (error: string) => ({ error }),
  [CompareFormulationsActionTypes.CLEANUP]: () => { },
});
