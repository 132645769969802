import { InfoCircleFilled } from '@ant-design/icons'
import { Modal, Form, Input, Select } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { AsyncStates } from 'src/constants'
import { countryNames } from 'src/constants/countries'
import { addLabsLocationClear, addLabsLocationRequest, editLabsLocationClear, editLabsLocationRequest } from 'src/store/actions/settings'
import { StoreState } from 'src/store/configureStore'

import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'




type LabLocationModalProps = {
    labLocationModalState: {
        selectedLabLocation: any,
        isModalVisible: boolean,
        mode: "create" | "edit",
        project_id?: string | null
    },
    handleClose: () => void
}

export const LabLocationModal = ({ labLocationModalState, handleClose }: LabLocationModalProps) => {
    const [labLocationForm] = Form.useForm()
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()

    const dispatch = useDispatch()
    const addLabsLocationStatus = useSelector((state: StoreState) => state.settings.addLabsLocationStatus);
    const editLabsLocationStatus = useSelector((state: StoreState) => state.settings.editLabsLocationStatus);

    useEffect(() => {
        if (labLocationModalState.mode === "edit" && !!Object.keys(labLocationModalState.selectedLabLocation || {}).length) {
            labLocationForm.setFieldsValue(labLocationModalState.selectedLabLocation)
        }
    }, [labLocationForm, labLocationModalState.mode, labLocationModalState.selectedLabLocation])

    useEffect(() => {
        return () => {
            labLocationForm.resetFields()
            dispatch(addLabsLocationClear())
            dispatch(editLabsLocationClear())
        }
    }, [labLocationForm, dispatch])

    useEffect(() => {
        if (addLabsLocationStatus === AsyncStates.SUCCESS || editLabsLocationStatus === AsyncStates.SUCCESS) {
            handleClose()
            labLocationForm.resetFields()
        }
    }, [addLabsLocationStatus, editLabsLocationStatus, handleClose, labLocationForm])

    const submitForm = (values: any) => {
        if (labLocationModalState.mode === "edit") {
            dispatch(editLabsLocationRequest({ lab_location_id: labLocationModalState.selectedLabLocation?.lab_location_id, ...values, name: values?.name?.trim(), city: values?.city?.trim() }))
        } else {
            dispatch(addLabsLocationRequest({ project_id: labLocationModalState?.project_id, ...values, name: values?.name?.trim(), city: values?.city?.trim() }))
        }
    }

    return (
        <Modal open={labLocationModalState?.isModalVisible} footer={null} title={labLocationModalState.mode === "create" ? t("common.addLabLocation") : t("common.updateLabLocation")} onCancel={handleClose}>
            <Form
                form={labLocationForm}
                name="labLocation"
                className="labLocationForm__form"
                layout="vertical"
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
                onFinish={submitForm}
                requiredMark={false}
            >
                <Form.Item
                    label={t("common.name")}
                    name="name"
                    required
                    tooltip={requiredFieldStar}
                    rules={[
                        {
                            required: true,
                            message: (
                                <>
                                    <InfoCircleFilled
                                        color="red.primary"
                                        style={{ marginRight: "2.5px" }}
                                    />
                                    {t("common.pleaseInputName")}
                                </>
                            ),
                            transform: (value) => value?.trim()
                        },
                    ]}
                >
                    <Input
                        type="text"
                        size="small"
                        placeholder={t("common.enterName")}
                    />
                </Form.Item>

                <Form.Item
                    label={t("profile.country")}
                    name="country"
                    required tooltip={requiredFieldStar}
                    rules={[
                        {
                            required: true,
                            message: (
                                <>
                                    <InfoCircleFilled
                                        color="red.primary"
                                        style={{ marginRight: "2.5px" }}
                                    />
                                    {t("signup.warn.location")}
                                </>
                            ),
                        },
                    ]}
                    validateTrigger="onChange"
                >
                    <Select
                        showSearch
                        placeholder={t("billing.modal.selectCountry")}
                    >
                        {countryNames.map((countryName) => (
                            <Select.Option key={countryName} value={countryName}>
                                {countryName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t("common.city")}
                    name="city"
                    required tooltip={requiredFieldStar}
                    rules={[
                        {
                            required: true,
                            message: (
                                <>
                                    <InfoCircleFilled
                                        color="red.primary"
                                        style={{ marginRight: "2.5px" }}
                                    />
                                    {t("common.pleaseInputCity")}
                                </>
                            ),
                            transform: (value) => value?.trim()
                        }
                    ]}
                >
                    <Input
                        type="text"
                        size="small"
                        placeholder={t("common.enterCity")}
                        minLength={2}
                    />
                </Form.Item>

                <Form.Item>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <StyledButton
                            size="small"
                            type="primary"
                            className="round-text"
                            htmlType="submit"
                            loading={addLabsLocationStatus === AsyncStates.LOADING || editLabsLocationStatus === AsyncStates.LOADING}
                        >
                            {labLocationModalState.mode === "create" ? t("common.addLabLocation") : t("common.updateLabLocation")}
                        </StyledButton>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    )
}
