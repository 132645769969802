import { FlumeConfig, Colors, Controls } from 'flume'

const config = new FlumeConfig()
config
  .addPortType({
    type: "combination",
    name: "combination",
    label: "Current Combination",
    color: Colors.blue,
  })
  .addPortType({
    type: "combinations",
    name: "combinations",
    label: "Combinations",
    color: Colors.green,
  })
  .addPortType({
    type: "boolean",
    name: "boolean",
    label: "Boolean",
    color: Colors.red,
    controls:[
        Controls.checkbox({
            name: "boolean",
            label: "Boolean"
        })
    ]
  })
  .addNodeType({
    type: "currentCombination",
    label: "Current Combination",
    description: "Current Combination",
    outputs: ports => [
      ports.combination({
        name: "combination",
        label: "Current Combination"
      })
    ]
  })
  .addNodeType({
    type: "combinations",
    label: "Combinations",
    description: "Outputs properties",
    outputs: ports => [
      ports.combinations({
        name: "combinations",
        label: "Combinations"
      })
    ]
  })
  .addNodeType({
    type: "combination_detector",
    label: "Combination Detector",
    initialWidth: 170,
    inputs: ports => [
      ports.combinations({
        name: "combinations",
        label: "Combinations"
      }),
      ports.combination({
        name: "combination",
        label: "Current Combination"
      }),
    ],
    outputs: ports => [
      ports.boolean({
        name: "found",
        label: "Found"
      }),
    ]
  })
  .addRootNodeType({
    type: "error",
    label: "Error",
    initialWidth: 170,
    inputs: ports => [
      ports.boolean({
        name: "error",
        label: "Error"
      }),
    ]
  })

export default config;