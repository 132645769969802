import React from "react"
import { Collapse, List, Tooltip, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
import { InfoCircleOutlined } from "@ant-design/icons"
import { useValue } from "src/utils/useValue"
const { Panel } = Collapse
const { Text } = Typography

type P = {
	data: any
	getDisplayName: (value: string) => string
}

export const AnalysisResult = ({ data, getDisplayName }: P) => {
	const [t] = useTranslate()
	const { getValue: getEUValue } = useValue()

	return (
		<Collapse>
			{data["Highly Positive"].length > 0 && (
				<Panel header={
					<Text>{t("dataSummary.highlyPositive")}
						<Tooltip title={t("dataSummary.highlyPositiveDesc")}> <InfoCircleOutlined /></Tooltip>
					</Text>
				} key="Highly Positive">
					<List
						size="small"
						bordered
						dataSource={data["Highly Positive"]}
						renderItem={(item: any) => (
							<List.Item>
								<Text strong type="secondary">
									{`${getDisplayName(item[0].toString())} ${t(
										"dataSummary.hasAHighlyPositiveImpact"
									)}(${getEUValue(item[1])}) ${t("common.on")} ${getDisplayName(
										item[2].toString()
									)}`}
								</Text>
							</List.Item>
						)}
					/>
				</Panel>
			)}

			{data.Positive.length > 0 && (
				<Panel header={
					<Text>{t("dataSummary.positive")}
						<Tooltip title={t("dataSummary.positiveDesc")}> <InfoCircleOutlined /></Tooltip>
					</Text>
				} key="Positive">
					<List
						size="small"
						bordered
						dataSource={data.Positive}
						renderItem={(item: any) => (
							<List.Item>
								<Text strong type="secondary">
									{`${getDisplayName(item[0].toString())} ${t(
										"dataSummary.hasAPositiveImpact"
									)}(${getEUValue(item[1])}) ${t("common.on")} ${getDisplayName(
										item[2].toString()
									)}`}
								</Text>
							</List.Item>
						)}
					/>
				</Panel>
			)}

			{data["Highly Negative"].length > 0 && (
				<Panel header={
					<Text>
						{t("dataSummary.highlyNegative")}
						<Tooltip title={t("dataSummary.highlyNegativeDesc")}> <InfoCircleOutlined /></Tooltip>
					</Text>}
					key="Highly Negative">
					<List
						size="small"
						bordered
						dataSource={data["Highly Negative"]}
						renderItem={(item: any) => (
							<List.Item>
								<Text strong type="secondary">
									{`${getDisplayName(item[0].toString())} ${t(
										"dataSummary.hasAHighlyNegativeImpact"
									)}(${getEUValue(item[1])}) ${t("common.on")} ${getDisplayName(
										item[2].toString()
									)}`}
								</Text>
							</List.Item>
						)}
					/>
				</Panel>
			)}

			{data.Negative.length > 0 && (
				<Panel header={<Text>{t("dataSummary.negative")}
					<Tooltip title={t("dataSummary.negativeDesc")}> <InfoCircleOutlined /></Tooltip>
				</Text>
				} key="Negative">
					<List
						size="small"
						bordered
						dataSource={data.Negative}
						renderItem={(item: any) => (
							<List.Item>
								<Text strong type="secondary">
									{`${getDisplayName(item[0].toString())} ${t(
										"dataSummary.hasANegativeImpact"
									)}(${getEUValue(item[1])}) ${t("common.on")} ${getDisplayName(
										item[2].toString()
									)}`}
								</Text>
							</List.Item>
						)}
					/>
				</Panel>
			)}
		</Collapse>
	)
}
