import { orange } from '@ant-design/colors'
import { InfoCircleFilled, LinkOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, DatePicker, Divider, Form, Input, InputNumber, message, Modal, Row, Select, Switch, Space, Spin, Tabs, Tooltip, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { setIsEditing } from 'src/store/actions/isEditing'
import { editTemplateRequest, listTemplateRequest } from 'src/store/actions/templates'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledCard } from 'src/styled_components/StyledCard'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import { CustomPrompt } from 'src/utils/CustomPrompts'
import { usePermission } from 'src/utils/usePermissions'
import { TemplateDropDown } from './TemplateDropDown'
import useTranslate from 'src/utils/useTranslate'
import { useValue } from 'src/utils/useValue'
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar'
import isURL from 'validator/lib/isURL'
import { getExternalLink } from 'src/utils/decorator'

const { Paragraph, Title, Text, Link } = Typography
const { Option } = Select
const { confirm }: any = Modal;

export const Templates = () => {
    const dispatch = useDispatch()
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()

    const { parser, formatter } = useValue()
    const { listTemplate: { status, data }, editTemplate: { status: editTemplateSuccess } } = useSelector((state: StoreState) => state.templates)
    const isEditing = useSelector((state: StoreState) => state.isEditing)

    const [templates, setTemplates] = useState([])
    const [searchValue, setSearchValue] = useState<string>("")
    const [tab, setTab] = useState("project")
    const [ismodalVisible, setIsModalVisible] = useState(false)
    const [templateData, setTemplateData] = useState<any>({})
    const [fieldData, setFieldData] = useState([])
    const [templateFields, setTemplateFields] = useState([])
    const [optionName, setOptionName] = useState('')

    const userAccess = usePermission()
    const isEditingAllowed = userAccess?.permission === "admin" || userAccess?.permission === "project_admin";
    const filteredTemplates = useMemo(() => templates.filter((template: any) => template?.name?.toLocaleLowerCase()?.includes(searchValue.toLowerCase())), [searchValue, templates])
    const [items, setItems] = useState<any>([])

    useEffect(() => {
        if (fieldData.length > 0) setItems(fieldData?.map((field: any) => (field?.field_type === "select" ? field.options || [] : null)))
    }, [fieldData])

    const [form] = useForm()

    useEffect(() => {
        dispatch(listTemplateRequest())
    }, [dispatch])

    useEffect(() => {
        if (editTemplateSuccess === AsyncStates.SUCCESS) {
            setIsModalVisible(false)
            setFieldData([])
            dispatch(setIsEditing(false))
        }
    }, [editTemplateSuccess, dispatch])

    useEffect(() => {
        if (status === AsyncStates.SUCCESS) {
            setTemplates(data)
        }
    }, [status, data])

    const getDefaultvalue = useCallback((fieldData: any, key: number) => {
        switch (fieldData?.field_type) {
            case "text":
            case "link":
                return <Input style={{ width: 150 }} />
            case "description":
                return <Input.TextArea style={{ width: 150 }} />
            case "number":
                return <InputNumber style={{ width: 150 }} parser={parser} formatter={formatter} />
            case "date":
                return <DatePicker defaultValue={fieldData?.fieldType ?? ""} />
            case "select":
                return <Select placeholder={"Select option(s)"} style={{ width: 150 }} mode="multiple" allowClear>
                    {items?.[key]?.map((res: any) => (
                        <Option value={res} key={res}>{res}</Option>
                    ))}
                </Select>
            default: return null
        }
    }, [items, formatter, parser])

    const addItem = (e: any, key: number) => {
        const trimmedName = optionName?.trim()
        if (!trimmedName) {
            message.error(t("inventory.Addcontentintheitem"))
            return
        }
        if (items.includes(trimmedName)) {
            message.error(`${trimmedName} is already added in options`)
            return
        }
        e.preventDefault()

        setItems((prevItems: any) => {
            return prevItems.map((item: any, index: number) => {
                if (index === key) {
                    return [...item, trimmedName]
                }
                return item
            })
        })
        setOptionName('')
        form.setFieldsValue({ options: [...items] })
    }

    const templateCard = (template: any) => (
        <StyledCard
            style={{
                width: 300,
                margin: 5,
            }}
            onClick={() => { }}
            hoverable
            actions={[]}
            title={template?.name}
            extra={<TemplateDropDown template={template} />}
        >
            <Paragraph ellipsis={{ rows: 2, symbol: 'more', tooltip: template.description }}>
                {template.description}
            </Paragraph>
            <StyledButton type="link" style={{ paddingLeft: 0 }} onClick={() => { setTemplateData(template); form.setFieldsValue({ ...template, fields: template.fields?.map((field: any) => ({ ...field, default_value: field.field_type === "date" ? (!!field.default_value ? dayjs(field.default_value) : "") : field.default_value })) }); setFieldData(template.fields); setTemplateFields(template.fields); setIsModalVisible(true); }}>{"View Template"}</StyledButton>
        </StyledCard>
    )

    const handleSubmit = (values: any) => {
        dispatch(editTemplateRequest({
            template_id: templateData.template_id,
            name: templateData.name,
            description: templateData.description,
            template_type: templateData.template_type,
            quickstart: templateData.quickstart, ...values
        }));
    }

    const validInputFields = (value: any) => {
        if (!value.trim().length) {
            return Promise.reject("Enter Valid Input")
        }
        return Promise.resolve()
    }

    const handleFieldTypeChange = (type: any, key: number) => {
        const { fields } = form.getFieldsValue()
        form.setFieldsValue({ options: [], field_type: type })

        if (type === "text" || type === "number" || type === "description") {
            const fieldData = { field_type: fields[key].field_type, default_value: undefined, field_name: fields[key].field_name, required: fields[key].required }
            fields[key] = fieldData
            form.setFieldsValue({ fields })
        } else if (type === "select") {
            const fieldData = { field_type: fields[key].field_type, default_value: [], field_name: fields[key].field_name, required: fields[key].required, options: [] }
            fields[key] = fieldData
            form.setFieldsValue({ fields })
        } else if (type === "date") {
            const fieldData = { field_type: fields[key].field_type, default_value: undefined, field_name: fields[key].field_name, required: fields[key].required, date_format: undefined }
            fields[key] = fieldData
            form.setFieldsValue({ fields })
        }
        setFieldData(fields)
    }

    const capitalizeLabel = (label: string) => {
        if (label === "number") {
            return "Value"
        }
        return label?.[0].toUpperCase() + label?.slice(1);
    }

    const openConfirmationPrompt = () => {
        confirm({
            width: 500,
            title: `${t("common.unsavedChangesLost")} ${t("inventory.Areyousure?")}`,
            onOk() {
                form.resetFields();
                form.resetFields();
                setTemplateData({});
                setIsModalVisible(false)
                dispatch(setIsEditing(false))
            },
            okText: t("common.yes"),
            okType: 'danger',
            cancelText: t("common.no"),
            onCancel() { },
        });
    }

    const handleClose = () => {
        if (isEditing) {
            openConfirmationPrompt()
        } else {
            form.resetFields();
            setTemplateData({});
            setIsModalVisible(false)
            dispatch(setIsEditing(false))
        }
    }

    return (
        <Spin spinning={status === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
            <CustomPrompt isEditing={isEditing} message="Unsaved changes will be lost!." />
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <StyledPageHeader
                    title={t("common.templates")}
                    ghost={false}
                />
                <StyledCard headStyle={{ paddingLeft: "16px", paddingRight: "16px" }} bodyStyle={{ paddingLeft: "16px", paddingRight: "16px" }}>
                    <Tabs tabBarExtraContent={<Input.Search onChange={(e: any) => setSearchValue(e.target.value)} allowClear value={searchValue} />} onChange={(value) => { setTab(value); setSearchValue("") }}>
                        <Tabs.TabPane key={t("common.projects")} tab={"Projects"}>
                            <Row gutter={16}>
                                {filteredTemplates.filter((temp: any) => temp.template_type === tab).map((res: any) => (
                                    <Col>
                                        {templateCard(res)}
                                    </Col>
                                ))}
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane key={t("workOrderDetails.workOrder")} tab={"Work Orders"}>
                            <Row>
                                {filteredTemplates.filter((temp: any) => temp.template_type === tab).map((res: any) => (
                                    <Col>
                                        {templateCard(res)}
                                    </Col>
                                ))}
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>
                    <Modal visible={ismodalVisible} width={800} onCancel={handleClose}
                        title={<Title level={4}>{templateData?.name} {(isEditing && <Text style={{ color: orange.primary }}>{t("common.unsavedChanges")}</Text>)}</Title>} footer={null}>
                        <Form layout="vertical" form={form} onFinish={handleSubmit} style={{ width: "100%" }}
                            disabled={!isEditingAllowed}
                            onValuesChange={() => dispatch(setIsEditing(true))}
                            requiredMark={false}
                        >
                            <Form.Item label={t("common.name")} rules={[{ required: true, min: 1, whitespace: true, type: "string", transform: (value) => value?.trim() }]} name="name" required tooltip={requiredFieldStar}>
                                <Input placeholder='Enter a Template Name' />
                            </Form.Item>
                            <Form.Item label={t("common.description")} rules={[{ required: true, min: 1, whitespace: true, type: "string", transform: (value) => value?.trim() }]} name="description" required tooltip={requiredFieldStar}>
                                <Input.TextArea placeholder='Enter a Template Description' />
                            </Form.Item>
                            <Title level={4}>{t("inventory.CustomFields")}</Title>
                            <Divider plain />
                            <Form.List name="fields">
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map(({ key, name }) => (
                                            <Space direction="horizontal" style={{ justifyContent: "space-between" }}>
                                                <Form.Item
                                                    label={templateFields?.[name]?.["field_name"] ?? t("common.fieldName")}
                                                    name={[name, 'field_name']}
                                                    fieldKey={[key, 'field_name']}
                                                    validateFirst
                                                    rules={[{ required: true, message: t("common.required") }, {
                                                        validator: (_, value: any) => validInputFields(value),
                                                    }]}
                                                    required
                                                    tooltip={requiredFieldStar}
                                                >
                                                    <Input placeholder={t("common.Enterthenameofthefield")} />
                                                </Form.Item>
                                                <Form.Item
                                                    label={templateFields?.[name]?.["field_type"] ?? t("common.fieldType")}
                                                    name={[name, 'field_type']}
                                                    fieldKey={[key, 'field_type']}
                                                    rules={[{ required: true, message: t("common.required") }]}
                                                    required
                                                    tooltip={requiredFieldStar}
                                                >
                                                    <Select placeholder={t("common.Selectthefieldtype")}
                                                        style={{ width: 100 }}
                                                        onChange={(value) => handleFieldTypeChange(value, name)}
                                                    >
                                                        <Option value={"text"}>{"Text"}</Option>
                                                        <Option value={"number"}>{"Number"}</Option>
                                                        <Option value={"select"}>{"Select"}</Option>
                                                        <Option value={"date"}>{"Date"}</Option>
                                                        <Option value={"link"}>{"Link"}</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name={[name, 'required']}
                                                    fieldKey={[key, 'required']}
                                                    label={t("common.requiredField") + "?"}
                                                >
                                                    <Switch checked={fieldData?.[name]?.["required"] ?? false}
                                                        onChange={(e: any) => {
                                                            setFieldData((prevState) => {
                                                                prevState.forEach((res: any, index: any) => {
                                                                    if (index === name) {
                                                                        res.required = e
                                                                    }
                                                                })
                                                                return [...prevState]
                                                            })
                                                        }}
                                                    />
                                                </Form.Item>
                                                {fieldData?.[name]?.["field_type"] === "select" &&
                                                    <Form.Item
                                                        name={[name, 'options']}
                                                        fieldKey={[key, 'options']}
                                                        label={"Options"}
                                                        rules={[{ required: true, message: t("common.required") }]}
                                                        required
                                                        tooltip={requiredFieldStar}
                                                    >
                                                        <Select mode="multiple" style={{ width: 150 }}
                                                            placeholder={t("inventory.Selectalltheavailableoptionsforthisselecttypefield")}
                                                            dropdownRender={menu => (
                                                                <>
                                                                    {menu}
                                                                    <Divider style={{ margin: '8px 0' }} />
                                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                                        <Input placeholder={t("common.addAnOption")} value={optionName} onChange={(e) => setOptionName(e.target.value)} minLength={1} />
                                                                        <Link onClick={(e: any) => addItem(e, name)} style={{ whiteSpace: 'nowrap' }}>
                                                                            <PlusOutlined />{t("common.addOption")}
                                                                        </Link>
                                                                    </Space>
                                                                </>
                                                            )}>
                                                            {items?.[name]?.map((res: any) => (
                                                                <Option value={res} key={res}>{res}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                }
                                                {fieldData?.[name]?.["field_type"] === "date" &&
                                                    <Form.Item
                                                        name={[name, 'date_format']}
                                                        fieldKey={[key, 'date_format']}
                                                        label={"Date format"}
                                                        rules={[{ required: true, message: t("common.required") }]}
                                                        required
                                                        tooltip={requiredFieldStar}
                                                    >
                                                        <Select placeholder={t("project.selectDateFormat")} style={{ width: 150 }}>
                                                            <Option value={"YYYY-MM-DD"}>{"YYYY-MM-DD"}</Option>
                                                        </Select>
                                                    </Form.Item>
                                                }
                                                {!!fieldData?.[name]?.["field_type"] &&
                                                    <Form.Item
                                                        name={[name, 'default_value']}
                                                        rules={[...fieldData?.[name]?.["field_type"] === "link" ?
                                                            [
                                                                {
                                                                    message: "Please enter a valid URL",
                                                                    validator: (_: any, value: string) => {
                                                                        if (isURL(value)) {
                                                                            return Promise.resolve();
                                                                        } else {
                                                                            return Promise.reject();
                                                                        }
                                                                    },
                                                                },
                                                            ]
                                                            : []]
                                                        }
                                                        extra={!!fieldData?.[name]?.["default_value"] && fieldData?.[name]?.["field_type"] === 'link' &&
                                                            <Link href={getExternalLink(fieldData?.[name]?.["default_value"])} target="_blank" rel="noreferrer">
                                                                <LinkOutlined />
                                                            </Link>
                                                        }
                                                        label={<Space>
                                                            <Text>{`${t("common.default")} ${capitalizeLabel(fieldData?.[name]?.["field_type"])}`}</Text>
                                                            <Tooltip title={t("common.Defaultvalueswillbeautomaticallyappliedtotheproject.Youcanstilleditthevaluesaftertheprojectiscreated.")}> <InfoCircleFilled /> </Tooltip>
                                                        </Space>}>
                                                        {getDefaultvalue(fieldData?.[name], name)}
                                                    </Form.Item>
                                                }
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        setFieldData(fieldData.filter((res, index) => index !== name))
                                                        remove(name)
                                                    }}
                                                />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <StyledButton
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{ width: '100%' }}
                                                icon={<PlusOutlined />}
                                            >
                                                {t("projects.customField")}
                                            </StyledButton>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Row justify='end' style={{ marginTop: 20 }}>
                                <Space>
                                    <StyledButton type="primary" htmlType="submit">
                                        {t("common.saveChanges")}
                                    </StyledButton>
                                </Space>
                            </Row>
                        </Form>
                    </Modal>
                </StyledCard>
            </Space>
        </Spin>
    )
}
