import { Typography } from 'antd';
import { TEXT_SECONDARY, TEXT_PRIMARY, BORDER_STYLE } from '../../Dashboard'

const CardStatusSection = ({ list }: any) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      {list.map((item: any, index: number) => (
        <div
          key={index}
          style={{
            display: 'flex',
            gap: '4px'
          }}
        >
          <Typography.Text
            style={{
              color: `${TEXT_SECONDARY}`,
              margin: 0
            }}
          >
            {item.title}
          </Typography.Text>
          <Typography.Text
            style={{
              color: `${TEXT_PRIMARY}`,
              margin: 0
            }}
            strong
          >
            {item.count}
          </Typography.Text>
        </div>
      ))}
    </div>
  )
}

const CardViewAllSection = ({ text }: any) => {
  return (
    <Typography.Text
      style={{
        color: `${TEXT_PRIMARY}`,
        margin: 0
      }}
      className='dashboard-activity-card-view-all'
    >
      {text}
    </Typography.Text>
  )
}

const CountCard = ({ total, title, type, statusList, viewAllText, onClick }: any) => {
  return (
    <div
      style={{
        width: '50%',
        padding: '12px 12px',
        outline: BORDER_STYLE,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        flexGrow: 1,
        userSelect: 'none'
      }}
      className='dashboard-activity-card'
      onClick={onClick}
    >
      <Typography.Title
        level={3}
        style={{
          color: `${TEXT_PRIMARY}`,
          margin: 0
        }}
      >
        {total}
      </Typography.Title>

      <Typography.Title
        level={5}
        style={{
          color: `${TEXT_SECONDARY}`,
          margin: 0
        }}
      >
        {title}
      </Typography.Title>
      {type === 'status' && <CardStatusSection list={statusList} />}

      {type === 'viewAll' && <CardViewAllSection text={viewAllText} />}
    </div>
  )
}

export default CountCard;