import { Dropdown, Input, List, Tooltip, Typography } from "antd";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { GPTConversation } from "../interfaces";
import { useState } from "react";
import type { MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationDeleteRequest,
  conversationFavoriteRequest,
  conversationHistoryClear,
  conversationUpdateRequest,
  currentConversationSet,
  getExtractionsClear,
} from "src/store/actions/polyGPT";
import { conversationHistoryRequest } from "src/store/actions/polyGPT";
import DeleteConfirmationModal from "src/components/DeleteConfirmationModal";
import useTranslate from "src/utils/useTranslate";
import { StoreState } from "src/store/configureStore";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { StyledButton } from "src/styled_components/StyledButton";

dayjs.extend(relativeTime);
const { Text } = Typography;

type ConversationHistoryItemProps = {
  conversation: GPTConversation;
};
export const GPTConversationListItem = ({
  conversation,
}: ConversationHistoryItemProps) => {
  const dispatch = useDispatch();
  const [t] = useTranslate();

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [editedTitle, setEditedTitle] = useState(conversation.title);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const handleTitleChange = (e: any) => {
    setEditedTitle(e.target.value);
  };
  const { currentConversation } = useSelector(
    (state: StoreState) => state.polyGpt
  );

  const handleSaveTitle = () => {
    const payload = {
      conversation_id: conversation.conversation_id,
      title: editedTitle,
    };
    dispatch(conversationUpdateRequest(payload));
    setIsEditingTitle(false);
  };

  const handleDelete = () => {
    const payload = {
      conversation_id: conversation.conversation_id,
    };
    dispatch(conversationDeleteRequest(payload));
    setDeleteModalVisible(false);
  };

  const handleCancelClick = () => {
    setIsEditingTitle(false);
    setEditedTitle(conversation.title);
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    switch (e.key) {
      case "edit_title":
        setIsEditingTitle(true);
        break;
      case "favorite":
        const payload = {
          conversation_id: conversation.conversation_id,
        };
        dispatch(conversationFavoriteRequest(payload));
        break;
      case "delete":
        setDeleteModalVisible(true);
        break;
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      label: t("polygpt.Rename"),
      key: "edit_title",
      icon: <EditOutlined />,
    },
    // {
    //   label: "Mark as favorite",
    //   key: "favorite",
    //   icon: conversation.is_favourite ? (
    //     <StarFilled style={{ color: "#FAAD14" }} />
    //   ) : (
    //     <StarOutlined style={{ color: "$FAAD14" }} />
    //   ),
    // },
    {
      label: t("polygpt.Delete"),
      key: "delete",
      icon: <StyledDeleteIcon />,
      danger: true,
    },
  ];

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <>
      <List.Item
        key={conversation.title}
        style={{
          background:
            currentConversation?.conversation_id ===
              conversation?.conversation_id
              ? "aliceblue"
              : "",
          padding: "5px 10px",
        }}
        actions={
          isEditingTitle
            ? [
              <StyledButton type="default" size="small" onClick={handleCancelClick}>
                Cancel
              </StyledButton>,
              <StyledButton size="small" type="primary" onClick={handleSaveTitle}>
                Save
              </StyledButton>,
            ]
            : [
              <Text
              >
                {`${conversation.questions_count} Question${conversation.questions_count! > 1 ? 's': ''}`}
              </Text>,
              <Text
              >
                {dayjs(conversation.created_at).fromNow()}
              </Text>,
            ]
        }
        extra={
          <Dropdown
            menu={menuProps}
            trigger={["click"]}
            placement="bottomRight"
          >
            <StyledButton
              style={{
                border: "none",
                background: "transparent",
              }}
              onClick={(e) => {
                e.preventDefault();
              }}
              icon={<MoreOutlined />}
            />
          </Dropdown>
        }
      >
        <List.Item.Meta
          style={{ marginBlockEnd: "0" }}
          title={
            isEditingTitle ? (
              <Input value={editedTitle} onChange={handleTitleChange} />
            ) : (
              <div
                onClick={() => {
                  dispatch(currentConversationSet(conversation));
                  dispatch(conversationHistoryClear());
                  dispatch(getExtractionsClear());
                  dispatch(
                    conversationHistoryRequest({
                      conversation_id: conversation.conversation_id,
                      page_size: 10,
                      page_num: 1,
                    })
                  );
                }}
                style={{
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Tooltip style={{ width: "100%" }} title={conversation.title}>
                  <Text>
                    {conversation.title}
                  </Text>
                </Tooltip>
              </div>
            )
          }
        />
      </List.Item>
      <DeleteConfirmationModal
        title={`${t("common.confirmDelete")}?`}
        description={
          <Text>
            {t("common.deleteConfirmation")}{" "}
            <Text strong>{`${conversation.title}`}</Text>?{" "}
            {t("common.cannotUndoOperation")}
          </Text>
        }
        onCancel={() => setDeleteModalVisible(false)}
        visible={deleteModalVisible}
        onConfirm={handleDelete}
      />
    </>
  );
};
