import React from "react";
import { NonIdealState } from "@blueprintjs/core";

const GenericError = () => (
  <NonIdealState
    icon="error"
    title="Something went wrong"
    description={"We are working to fix the problem. Please try again later."}
  />
);

export default GenericError;
