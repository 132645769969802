
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`플래켓-버만`}</h1>
    <p>{`플래켓-버만 설계(스크리닝을 위한 설계 방식 중 하나)는 실험 중 어떤 요인이 중요한지 알아내는 데 도움을 줍니다. 이 설계는 중요하지 않은 요인(잡음)을 제외시켜 비교적 중요하지 않은 요인에 대한 대량의 데이터 수집을 피할 수 있도록 합니다.`}</p>
    <p>{`1946년 R.L. 플래켓트와 J.P. 버만은 저명한 논문 '다변량 실험의 최적 설계'를 Biometrika에 발표하였습니다. 이 논문에서는 2의 거듭제곱이 아닌 실행 횟수가 4의 배수인 매우 경제적인 설계의 구축에 대해 설명했습니다. 플래켓-버만 설계는 주 효과만 관심 있는 경우 매우 효율적인 스크리닝 설계입니다.`}</p>
    <p>{`플래켓-버만(PB) 설계는 주로 스크리닝 실험에 사용됩니다. PB 설계에서 주 효과는 일반적으로 두 요인 간의 상호작용과 크게 혼합됩니다. 예를 들어, 12번 실행되는 PB 설계는 최대 11개 요인을 포함한 실험에 사용될 수 있습니다 (예: 4, 5, 6, 또는 7개 요인은 8번 실험을 요구하고, 8, 9, 10, 또는 11개 요인은 12번 실행을 요구하는 등등).`}</p>
    <p><strong parentName="p">{`사용 사례:`}</strong></p>
    <ul>
      <li parentName="ul">{`스크리닝`}</li>
      <li parentName="ul">{`고차 상호작용을 무시할 수 있는 경우`}</li>
      <li parentName="ul">{`두 수준 다변량 실험에서`}</li>
      <li parentName="ul">{`요인이 네 개 이상인 경우 (만약 두 개에서 네 개의 변수가 있다면 완전 요인설계법을 수행할 수 있습니다)`}</li>
      <li parentName="ul">{`큰 주 효과를 경제적으로 감지하기 위해`}</li>
      <li parentName="ul">{`N = 12, 20, 24, 28 및 36 (여기서 N = 실험 횟수)`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;