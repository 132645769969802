import { put, takeLatest, call, select } from "redux-saga/effects";
import { dashboardActivityCardTotalApi, dashboardProjectStateCountsApi, dashboardRecentEditsApi, dashboardUsedIngredientsApi, dashboardWorkorderStateCountsApi, fetchdashboardDataApi, fetchNewsApi } from "src/services/dashboard";
import { dashboardActivityCardTotalFailure, dashboardActivityCardTotalRequest, dashboardActivityCardTotalSuccess, dashboardProjectStateCountsFailure, dashboardProjectStateCountsRequest, dashboardProjectStateCountsSuccess, dashboardRecentEditsFailure, dashboardRecentEditsRequest, dashboardRecentEditsSuccess, dashboardUsedIngredientsFailure, dashboardUsedIngredientsRequest, dashboardUsedIngredientsSuccess, dashboardWorkorderStateCountsFailure, dashboardWorkorderStateCountsRequest, dashboardWorkorderStateCountsSuccess, fetchDashboardDataFailure, fetchDashboardDataRequest, fetchDashboardDataSuccess, fetchNewsFailure, fetchNewsRequest, fetchNewsSuccess } from "../actions/dashboard";
import { message } from 'antd';
import jwtManager from "src/utils/jwtManager";
import performApiCallSaga from "./utils";


function* fetchDashboardDataSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const params = action.payload;

    const response = yield call(fetchdashboardDataApi, params, headers);
    const {
      data: {
        result: { message: responseMessage, status, data },
      },
    } = response

    if (status === "Success") {
      yield put(fetchDashboardDataSuccess(data));
    } else {
      message.error(responseMessage)
      yield put(fetchDashboardDataFailure(responseMessage));

    }
  } catch (error: any) {
    yield put(fetchDashboardDataFailure(error?.response?.data?.detail));
  }
}

function* newsDataSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const params = action.payload;
    const response = yield call(fetchNewsApi, params, headers);

    const {
      data: {
        result: { message: responseMessage, status, data },
      },
    } = response;

    if (status === "Success") {
      yield put(fetchNewsSuccess(data));
    } else {
      yield put(fetchNewsFailure(responseMessage));
    }
  } catch (error: any) {
    yield put(fetchNewsFailure(error?.response?.data?.detail));
  }
}

function* dashboardRecentEditsSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    dashboardRecentEditsApi,
    dashboardRecentEditsSuccess,
    dashboardRecentEditsFailure,
    action.payload,
  );
}

function* dashboardUsedIngredientsSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    dashboardUsedIngredientsApi,
    dashboardUsedIngredientsSuccess,
    dashboardUsedIngredientsFailure,
    action.payload,
  );
}

function* dashboardWorkorderStateCountsSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    dashboardWorkorderStateCountsApi,
    dashboardWorkorderStateCountsSuccess,
    dashboardWorkorderStateCountsFailure,
    action.payload,
  );
}

function* dashboardProjectStateCountsSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    dashboardProjectStateCountsApi,
    dashboardProjectStateCountsSuccess,
    dashboardProjectStateCountsFailure,
    action.payload,
  );
}

function* dashboardActivityCardTotalSaga(action: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    dashboardActivityCardTotalApi,
    dashboardActivityCardTotalSuccess,
    dashboardActivityCardTotalFailure,
    action.payload,
  );
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(fetchDashboardDataRequest, fetchDashboardDataSaga);
  yield takeLatest(fetchNewsRequest, newsDataSaga);
  yield takeLatest(dashboardRecentEditsRequest, dashboardRecentEditsSaga);
  yield takeLatest(dashboardUsedIngredientsRequest, dashboardUsedIngredientsSaga);
  yield takeLatest(dashboardWorkorderStateCountsRequest, dashboardWorkorderStateCountsSaga);
  yield takeLatest(dashboardProjectStateCountsRequest, dashboardProjectStateCountsSaga);
  yield takeLatest(dashboardActivityCardTotalRequest, dashboardActivityCardTotalSaga);
}