import React, { useEffect, useState } from 'react'
import { Space, Spin } from "antd"
import { useSelector } from 'react-redux'
import { StoreState } from '../../../store/configureStore'
import { LoadingOutlined } from '@ant-design/icons'
import { AsyncStates } from 'src/constants'
import { IstaProfiles } from "./IstaProfiles"
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts";
import { useValue } from 'src/utils/useValue'



export const CharacterizationGraph = ({ ambientTableData }: any) => {
  const [graphData, setGraphData] = useState<any>()
  const { getValue } = useValue()
  useEffect(() => {
    if (!!ambientTableData?.length) {
      const result = [0]
      ambientTableData?.forEach((res: any, index: any) => {
        const temp = Number(result?.[result?.length - 1])
        if (!!res.ambient_time_value) {
          result.push((res?.ambient_time_unit === "hours" ? res?.ambient_time_value : Number((res?.ambient_time_value / 60))) + temp)
          result.push((res?.ambient_time_unit === "hours" ? res?.ambient_time_value : Number((res?.ambient_time_value / 60))) + temp)
        }
      })

      setGraphData({
        temp_data:
          [ambientTableData[0]?.ambient_temperature_value || null,
          ...ambientTableData.reduce((array: any, res: any, index: any) => [
            ...array,
            res.ambient_temperature_value || null,
            ambientTableData?.[index + 1]?.ambient_temperature_value || ambientTableData?.[index]?.ambient_temperature_value || null
          ], [])
          ],
        time_data: result,
        time_unit: [ambientTableData[0]?.ambient_time_unit || null,
        ...ambientTableData.reduce((array: any, res: any, index: any) => [
          ...array,
          res.ambient_time_unit || null,
          ambientTableData?.[index]?.ambient_time_unit || null
        ], [])
        ],
        temperature_unit: [ambientTableData[0]?.ambient_temperature_unit || null,
        ...ambientTableData.reduce((array: any, res: any, index: any) => [
          ...array,
          res.ambient_temperature_unit || null,
          ambientTableData?.[index]?.ambient_temperature_unit || null
        ], [])
        ]
      })
    }
  }, [ambientTableData])

  return <HighchartsReact
    highcharts={Highcharts}
    options={{
      chart: {
        type: "line"
      },
      title: {
        text: null,
      },
      xAxis: {
        title: {
          text: "Time (hours)"
        },
        lineColor: 'black',
        lineWidth: 1
      },
      yAxis: {
        title: {
          text: "Temperature (degC)",
        },
        lineColor: 'black',
        lineWidth: 1,
        minorGridLineWidth: 0,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          },
          tooltip: {
            headerFormat: null,
            pointFormat: `Time : {point.x} {point.time_unit} <br> Temperature : {point.y} {point.temperature_unit}`
          },
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Temperature',
        showInLegend: false,
        data: graphData?.time_data?.map((ele: any, index: any) => ({ x: isNaN(getValue(ele)) ? null : getValue(ele), y: isNaN(getValue(graphData?.temp_data?.[index])) ? null : getValue(graphData?.temp_data?.[index]), time_unit: graphData?.time_unit[index] === "minutes" ? "hours" : graphData?.time_unit[index], temperature_unit: graphData?.temperature_unit[index] })) || [],
        step: true,
      }]
    }}
  />
}


export const CharacterizationUpload = () => {

  const { celsureEditTrialStatus } = useSelector((state: StoreState) => state.celsure)
  const [profileModalVisible, setProfileModalVisible] = useState<boolean>(false)



  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }} id="upload-form">
      <Spin spinning={celsureEditTrialStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
      </Spin>
      <IstaProfiles profileModalVisible={profileModalVisible} setProfileModalVisible={setProfileModalVisible} />
    </Space >
  )
}
