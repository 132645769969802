import {
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { Empty, Modal, Spin, Tag } from "antd";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
    deleteSemAnalysisFilesClear,
    deletePaintFilmBatchClear,
    deletePaintFilmBatchRequest,
    fetchPaintFilmBatchInfoRequest,
    processPaintFilmAnalysisClear,
    paintFilmRecalibrateScaleClear,
    paintFilmRecalibrateScaleRequest,
    modifyPaintFilmBatchClear,
} from "src/store/actions/semAnalysis";
import { AsyncStates } from "src/constants";
import { useHistory, useParams } from "react-router-dom";
import DownloadReportModal from "./DownloadReportModal";
import { IMAGE_ANALYSIS_TYPES } from "../SEMAnalysisWrapper";
import ThumbnailList from "../Shared/ThumbnailList";
import BatchCard from "../Shared/BatchCard";
import SelectionsActions from "../Shared/SelectionsActions";
import { Unsubscribe } from "firebase/auth";
import { FB_COLLECTION_SEM_ANALYSIS, firestoreDb } from "src/utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import ReCalibrateModal from "../Shared/ReCalibrateModal";

const PaintFilmAnalysis = () => {
    const dispatch = useDispatch();
    const params = useParams<any>();
    const {
        paintFilmBatchInfo,
        paintFilmBatchInfoStatus,
        deleteSemAnalysisFilesStatus,
        deletePaintFilmBatchStatus,
        processPaintFilmAnalysisStatus,
        paintFilmRecalibrateScaleStatus,
        analysisTypes,
        modifyPaintFilmBatchData
    } = useSelector((state: StoreState) => state.semAnalysis);
    const history = useHistory();
    const [view, setView] = useState<"batch" | "expanded">("batch");
    const [selectedBatch, setSelectedBatch] = useState<string>();
    const [reCalibrateModalOpen, setReCalibrateModalOpen] = useState(false);
    const [downloadReportModalOpen, setDownloadReportModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [loadingDataMap, setLoadingDataMap] = useState<any>({});

    useLayoutEffect(() => {
        if (Array.isArray(paintFilmBatchInfo) && params.batchId) {
            setView("expanded");
            setSelectedBatch(params.batchId);
        } else {
            setSelectedBatch(undefined);
            setView("batch");
        }
    }, [paintFilmBatchInfo, params]);

    useEffect(() => {
        if (paintFilmBatchInfoStatus === AsyncStates.SUCCESS && (!paintFilmBatchInfo || paintFilmBatchInfo.length === 0))
            history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS}`)
    }, [history, paintFilmBatchInfo, paintFilmBatchInfoStatus]);

    const fetchPaintFilmBatch = useCallback(() => {
        dispatch(fetchPaintFilmBatchInfoRequest({
            batch_id: params?.batchId,
            analysis_type:
                analysisTypes[
                IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
                ]
        }));
    }, [analysisTypes, dispatch, params?.batchId])

    useEffect(() => {
        fetchPaintFilmBatch();
    }, [fetchPaintFilmBatch]);

    useEffect(() => {
        if (
            [
                deleteSemAnalysisFilesStatus,
                deletePaintFilmBatchStatus
            ].includes(AsyncStates.SUCCESS)
        ) {
            fetchPaintFilmBatch();
            dispatch(deleteSemAnalysisFilesClear());
            dispatch(deletePaintFilmBatchClear());
            setSelectedIds([]);
        }
    }, [deletePaintFilmBatchStatus, deleteSemAnalysisFilesStatus, dispatch, fetchPaintFilmBatch]);

    useEffect(() => {
        if (
            [
                processPaintFilmAnalysisStatus,
                paintFilmRecalibrateScaleStatus,
            ].includes(AsyncStates.SUCCESS)
        ) {
            fetchPaintFilmBatch();
            dispatch(processPaintFilmAnalysisClear());
            dispatch(paintFilmRecalibrateScaleClear());
        }
    }, [dispatch, fetchPaintFilmBatch, paintFilmRecalibrateScaleStatus, processPaintFilmAnalysisStatus]);

    const listenToBatchStatus = useCallback((doc_id) => {
        let unsub: Unsubscribe;
        const taskDocRef = doc(
            firestoreDb,
            `${FB_COLLECTION_SEM_ANALYSIS}/${doc_id}`
        );

        unsub = onSnapshot(taskDocRef, (doc) => {
            const taskDoc = doc.data();
            if (taskDoc && selectedBatch) {
                const taskStatus = taskDoc["status"];
                setLoadingDataMap((prev: any) => ({
                    ...prev,
                    [selectedBatch]: {
                        ...taskDoc
                    },
                }));

                if (String(taskStatus).toLowerCase() === "completed") {
                    unsub && unsub();
                    dispatch(modifyPaintFilmBatchClear());
                }
            }
        });
    }, [dispatch, selectedBatch])

    useEffect(() => {
        if (
            selectedBatch &&
            Object.keys(modifyPaintFilmBatchData || {}).length > 0 &&
            Object.keys(modifyPaintFilmBatchData || {}).includes(selectedBatch)
        ) {
            const fbKey = modifyPaintFilmBatchData[Object.keys(modifyPaintFilmBatchData)[0]];
            listenToBatchStatus(fbKey);
        }
    }, [selectedBatch, modifyPaintFilmBatchData, listenToBatchStatus]);

    const selectedBatchInfo = useMemo(() => {
        if (
            !selectedBatch ||
            !Array.isArray(paintFilmBatchInfo) ||
            paintFilmBatchInfo.length === 0
        )
            return null;

        return paintFilmBatchInfo.find((batch) => batch.batch_id === selectedBatch);
    }, [selectedBatch, paintFilmBatchInfo]);

    const handleReCalibrate = useCallback(
        (scale_length: any, unit: any) => {
            const payload: Record<string, any> = {
                scale: `${scale_length || ""} ${unit || ""}`,
                batch_id: selectedBatch,
                analysis_type: analysisTypes[
                    IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
                ]
            };
            dispatch(paintFilmRecalibrateScaleRequest(payload));
        },
        [analysisTypes, dispatch, selectedBatch]
    );

    const gotoBatchView = () => history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS}`);

    const goToBatchDetails = (batchId: string) => {
        history.push(
            `/sem-analysis/${IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS}/batch/${batchId}`
        );
    };

    const goToFileDetails = useCallback(
        ({ fileId }: any) => {
            history.push(
                `/sem-analysis/${IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS}/batch/${selectedBatchInfo.batch_id}/files/${fileId}`
            );
        },
        [history, selectedBatchInfo?.batch_id]
    );

    const selectAll = useCallback(() => {
        if (
            view === "expanded" &&
            selectedBatchInfo?.thumbnails &&
            Array.isArray(selectedBatchInfo.thumbnails)
        ) {
            if (selectedIds.length !== selectedBatchInfo?.thumbnails.length)
                setSelectedIds([
                    ...selectedBatchInfo.thumbnails.map((t: any) => t.file_id),
                ]);
            else setSelectedIds([]);
        }

        if (view === "batch" && Array.isArray(paintFilmBatchInfo)) {
            if (selectedIds.length !== paintFilmBatchInfo.length)
                setSelectedIds([...paintFilmBatchInfo.map((t: any) => t.batch_id)]);
            else setSelectedIds([]);
        }
    }, [
        paintFilmBatchInfo,
        selectedBatchInfo?.thumbnails,
        selectedIds.length,
        view,
    ]);

    const deleteFiles = useCallback(() => {
        Modal.confirm({
            title: "Are you sure?",
            icon: <ExclamationCircleOutlined />,
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                const payload: any = {
                    batch_ids: [selectedBatch],
                    file_ids: selectedIds || [],
                    analysis_type:
                        analysisTypes[
                        IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
                        ],
                };
                dispatch(deletePaintFilmBatchRequest(payload));
            },
        });
    }, [analysisTypes, dispatch, selectedBatch, selectedIds]);

    const deleteBatches = useCallback(() => {
        Modal.confirm({
            title: "Are you sure?",
            icon: <ExclamationCircleOutlined />,
            okText: "Delete",
            okType: "danger",
            cancelText: "Cancel",
            onOk() {
                const payload: any = {
                    batch_ids: selectedIds || [],
                    analysis_type:
                        analysisTypes[
                        IMAGE_ANALYSIS_TYPES.PAINT_FILM_ANALYSIS as keyof typeof analysisTypes
                        ],
                };
                dispatch(deletePaintFilmBatchRequest(payload));
            },
        });
    }, [analysisTypes, dispatch, selectedIds]);

    return (
        <Spin
            spinning={[
                paintFilmBatchInfoStatus,
                deleteSemAnalysisFilesStatus,
                deletePaintFilmBatchStatus,
            ].includes(AsyncStates.LOADING)}
            indicator={<LoadingOutlined />}
        >
            <div className="paint-film-analysis-container">
                {Array.isArray(paintFilmBatchInfo) && (
                    <>
                        {view === "batch" && (
                            <>
                                <SelectionsActions
                                    count={paintFilmBatchInfo ? paintFilmBatchInfo.length : 0}
                                    selectedIds={selectedIds}
                                    onSelectAll={selectAll}
                                    showDownloadReport={false}
                                    onDelete={deleteBatches}
                                />
                                <div className="batch-container">
                                    {paintFilmBatchInfo.map((batch) => (
                                        <BatchCard
                                            batch={batch}
                                            onBatchClick={goToBatchDetails}
                                            selectable={true}
                                            selectedIds={selectedIds}
                                            setSelectedIds={setSelectedIds}
                                        />
                                    ))}
                                    {paintFilmBatchInfoStatus !== AsyncStates.LOADING &&
                                        (!paintFilmBatchInfo ||
                                            paintFilmBatchInfo.length === 0) && <Empty />}
                                </div>
                            </>
                        )}
                        {view === "expanded" && (
                            <>
                                <div className="content-container">
                                    <div className="content-header">
                                        <div
                                            className="back"
                                            onClick={() => {
                                                gotoBatchView();
                                            }}
                                        >
                                            <ArrowLeftOutlined />
                                        </div>
                                        {selectedBatchInfo ? (
                                            <>
                                                <div className="batch-name">
                                                    {selectedBatchInfo?.batch_name}
                                                </div>
                                                <div>
                                                    Scale: {selectedBatchInfo?.scale?.scale}{" "}
                                                    {selectedBatchInfo?.scale?.unit}
                                                </div>
                                                <div
                                                    className="link"
                                                    onClick={() => setReCalibrateModalOpen(true)}
                                                >
                                                    Recalibrate Scale
                                                </div>
                                                <div>
                                                    {Object.keys(loadingDataMap || {}).length > 0 && loadingDataMap[selectedBatch!] && String(loadingDataMap[selectedBatch!]?.status || "").toLowerCase() !== "completed" ? (
                                                        <Tag icon={<SyncOutlined spin />} color="processing">
                                                            Applying Changes to Batch - {loadingDataMap[selectedBatch!].percent_completed} Completed
                                                        </Tag>
                                                    ) : null}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {paintFilmBatchInfoStatus !== AsyncStates.LOADING && (
                                                    <Empty />
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <SelectionsActions
                                        count={
                                            selectedBatchInfo?.thumbnails &&
                                                Array.isArray(selectedBatchInfo.thumbnails)
                                                ? selectedBatchInfo.thumbnails.length
                                                : 0
                                        }
                                        selectedIds={selectedIds}
                                        onSelectAll={selectAll}
                                        onDownloadReport={() => setDownloadReportModalOpen(true)}
                                        onDelete={deleteFiles}
                                    />
                                    <div className="thumb-container">
                                        <ThumbnailList
                                            loadingDataMap={{}}
                                            handleCardClick={goToFileDetails}
                                            thumbnailList={
                                                selectedBatchInfo?.thumbnails &&
                                                    Array.isArray(selectedBatchInfo?.thumbnails)
                                                    ? selectedBatchInfo?.thumbnails
                                                    : []
                                            }
                                            loading={paintFilmBatchInfoStatus === AsyncStates.LOADING}
                                            selectable={true}
                                            setSelectedIds={setSelectedIds}
                                            selectedIds={selectedIds}
                                        />
                                    </div>
                                </div>
                                <ReCalibrateModal
                                    reCalibrateModalOpen={reCalibrateModalOpen}
                                    setReCalibrateModalOpen={setReCalibrateModalOpen}
                                    handleReCalibrate={handleReCalibrate}
                                    selectedBatchInfo={selectedBatchInfo}
                                />
                                <DownloadReportModal
                                    downloadReportModalOpen={downloadReportModalOpen}
                                    setDownloadReportModalOpen={setDownloadReportModalOpen}
                                    selectedBatch={selectedBatch}
                                    selectedBatchInfo={selectedBatchInfo}
                                    selectedIds={selectedIds}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </Spin>
    );
};

export default PaintFilmAnalysis;
