import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	listAlgorithmsFailure,
	listAlgorithmsRequest,
	listAlgorithmsSuccess,
	generateExperimentFailure,
	generateExperimentRequest,
	generateExperimentSuccess,
	uploadDoeFailure,
	uploadDoeRequest,
	uploadDoeSuccess,
	doeHistoryListFailure,
	doeHistoryListRequest,
	doeHistoryListSuccess,
	getDoeExperimentsFailure,
	getDoeExperimentsRequest,
	getDoeExperimentsSuccess,
	deleteDoeFailure,
	deleteDoeRequest,
	deleteDoeSuccess,
	editDoeFailure,
	editDoeRequest,
	editDoeSuccess,
} from "../actions/doe"
import {
	generateNewExperimentAPi,
	listAlgorithmsApi,
	uploadDoeExperimentApi,
	getDoeHistoryListApi,
	retrieveDoeExperimentsApi,
	deleteDoeApi,
	editDoeApi,
} from "src/services/doe"
import { notification } from "antd"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import { messages } from "src/utils/hooks"
import { APIResponse } from "src/typings"
import jwtManager from "src/utils/jwtManager"
import { setIsEditing } from "../actions/isEditing"

function* generateExperimentSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data, status } = yield call(
			generateNewExperimentAPi,
			payload,
			headers
		)

		if (status === 200) {
			if (data?.status === "Success") {
				yield put(generateExperimentSuccess(data));
				yield put(setIsEditing(false))
			} else {
				yield put(generateExperimentFailure())
				notification.error({
					message: data.message,
				})
			}
		} else {
			yield put(generateExperimentFailure())
		}
	} catch (error) {
		yield put(generateExperimentFailure())
	}
}

function* listAlgorithmsSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data, status } = yield call(listAlgorithmsApi, payload, headers)

		if (status === 200) {
			yield put(listAlgorithmsSuccess(data))
		} else {
			yield put(listAlgorithmsFailure())
		}
	} catch (error) {
		yield put(listAlgorithmsFailure())
	}
}

function* uploadDoeSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data, status } = yield call(
			uploadDoeExperimentApi,
			payload,
			headers
		)
		const { message } = data;
		if (status === 200) {
			yield put(uploadDoeSuccess(data))
			notification.success({ message })
		} else {
			yield put(uploadDoeFailure())
		}
	} catch (error) {
		yield put(uploadDoeFailure())
	}
}

function* doeHistoryListSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data, status } = yield call(getDoeHistoryListApi, payload, headers)
		if (status === 200) {
			yield put(doeHistoryListSuccess(data.result))
		} else {
			yield put(doeHistoryListFailure())
		}
	} catch (error) {
		yield put(doeHistoryListFailure())
	}
}

function* getDoeExperimentsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	);
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data, status } = yield call(
			retrieveDoeExperimentsApi,
			payload,
			headers
		)
		if (status === 200) {
			yield put(getDoeExperimentsSuccess(data))
		} else {
			yield put(getDoeExperimentsFailure())
		}
	} catch (error: any) {
		yield put(getDoeExperimentsFailure())
		const errorMessage = error?.message || error?.response?.data?.message;
		notification.error({
			message: errorMessage || messages[ln].internal_server_error,
			duration: 5,
		});
	}
}

function* deleteDoeSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { status } = yield call(deleteDoeApi, payload, headers)
		if (status === 200) {
			yield put(deleteDoeSuccess())
			yield put(
				doeHistoryListRequest({
					pageNum: 0,
					doe_ids: [],
					algorithms: [],
					user_ids: [],
					titles: [],
					sort_by: "created",
					ascending: false,
				})
			)
		} else {
			yield put(deleteDoeFailure())
		}
	} catch (error) {
		yield put(deleteDoeFailure())
	}
}

function* editDoeSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { status, data }: APIResponse<{ message: string }> = yield call(
			editDoeApi,
			payload,
			headers
		)

		if (status === 200) {
			yield put(editDoeSuccess())
			notification.success({ message: data.message })
		} else {
			yield put(editDoeFailure())
			notification.error({ message: data.message })
		}
	} catch (error) {
		yield put(editDoeFailure())
		notification.error({ message: messages[ln].internal_server_error })
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(generateExperimentRequest, generateExperimentSaga)
	yield takeLatest(listAlgorithmsRequest, listAlgorithmsSaga)
	yield takeLatest(uploadDoeRequest, uploadDoeSaga)
	yield takeLatest(doeHistoryListRequest, doeHistoryListSaga)
	yield takeLatest(getDoeExperimentsRequest, getDoeExperimentsSaga)
	yield takeLatest(deleteDoeRequest, deleteDoeSaga)
	yield takeLatest(editDoeRequest, editDoeSaga)
}
