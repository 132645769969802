import { useMemo } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { convertToPrecision } from "src/utils/decorator"

export const useData = ({ formulationList, stageType }: any) => {
    const displayNames = useSelector((state: StoreState) => state.displayNames.data)

    const transformData = useMemo(() => {
        const data = Array.from(new Set(formulationList.flatMap((res: any) => res[stageType])))
        const out = data
            .reduce((acc: any, trial: any) => ({
                ...acc,
                ...(Array.isArray(trial?.[stageType]) ? trial?.[stageType]
                    ?.reduce((_acc: any, { characterization_set_id, variation_id, data = [] }: any) => ({
                        ..._acc,
                        [characterization_set_id]: {
                            variation: {
                                id: variation_id,
                                data: {
                                    ...acc[characterization_set_id]?.variation?.data,
                                    ...Object.entries(data).reduce((__acc: any, [param, dataObj]: any) => ({
                                        ...__acc,
                                        [param]: {
                                            ...acc[characterization_set_id]?.variation?.data?.[param],
                                            [trial.id_set.formulation_id]: dataObj
                                        }
                                    }),
                                        {}
                                    )
                                }
                            }
                        }
                    }),
                        {}
                    ) : [])
            }),
                {}
            )
        return out
    }, [formulationList, stageType])

    const tableData = useMemo(() =>
        Object.entries(transformData as any)?.map(([method, { variation: { data } }]: any, index) => Object.entries(data)?.map(([parameter, obj]: any) => ({
            parameter,
            method,
            category: displayNames?.[stageType]?.[parameter]?.category,
            unit: Object.values(obj as object)?.[0]?.unit || "-",
            ...Object.entries(obj as object).reduce((acc: any, [formulation, { value, unit }]: any) => ({
                ...acc, [formulation]: convertToPrecision(value)
            }), {})
        })
        )), [transformData, displayNames, stageType])
    return { tableData }
}