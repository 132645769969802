import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Col, InputNumber, message, Row, Select, Space, Tooltip, Typography } from "antd";
import useTranslate from "src/utils/useTranslate";
import { IDataSheet } from "src/components/IDataSheet";
import { StyledButton } from "src/styled_components/StyledButton";
import { InfoCircleFilled, PlusOutlined } from "@ant-design/icons";
import { NumericalProperty, NumericalPropertyErrors } from "../types";
import "./numerical-output-constraints.scss"
import { useValue } from "src/utils/useValue";
import { ErrorText, PADDING_TOP_FOR_DATASHEET_CELLS } from "./InputConstraintsNew";
const { Option } = Select;

type P = {
  disableCell: (record: any, type: string) => boolean;
  parameterListDropDown: (index: any, constraintstype: string) => JSX.Element;
  addRow: (inputType: string) => void;
  numericalObjectiveList: any[];
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  numericalProperties: (NumericalProperty & NumericalPropertyErrors)[];
  setNumericalProperties: Dispatch<
    SetStateAction<{ [key: string]: (NumericalProperty & NumericalPropertyErrors)[] }>
  >;
  currentSelectedStage?: any;
  isMultiStage: boolean;
};

export const NumericalOutputConstraints = ({
  numericalProperties,
  disableCell,
  parameterListDropDown,
  addRow,
  numericalObjectiveList,
  setUnsavedChanges,
  setNumericalProperties,
  currentSelectedStage,
  isMultiStage,
}: P) => {
  const [t] = useTranslate();
  const { convertValue, getValue: getEUValue, formatter, parser } = useValue();
  const priorityDropDown = useCallback(
    (_, index) => {
      const priorityList = numericalProperties
        .filter((res) => res.modelType !== "range")
        .map((res, key) => key + 1);

      const errorMessage = numericalProperties[index]?.error_priority
      return (
        <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS/2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display:'flex', flexDirection:'column', gap:'0rem', alignItems: 'flex-start' }}>
          <Select
            style={{ flexGrow: 1, width: "100%" }}
            {...errorMessage && { status : "error" }}
            value={numericalProperties[index].priority}
            allowClear
            disabled={
              !numericalProperties[index].parameter.length ||
              !numericalProperties[index]?.modelType?.length ||
              numericalProperties[index]?.modelType === "range"
            }
            onChange={(e: any) => {
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState));
                newState[currentSelectedStage][index].priority = e;
                delete newState[currentSelectedStage][index].error_priority 
                return { ...newState };
              });
            }}
          // placeholder={t('aiEngine.inverseModel.select')}
          >
            {priorityList.map((res) => (
              <Option key={res} value={res}>
                {res}
              </Option>
            ))}
          </Select>
          <ErrorText text={errorMessage ? errorMessage : ''} />
        </div>
      );
    },
    [currentSelectedStage, numericalProperties, setNumericalProperties],
  );

  const optimizationTypeDropDown = useCallback(
    (_, index: number) => {
      const errorMessage = numericalProperties[index]?.error_modelType
      return (
        <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS/2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display:'flex', flexDirection:'column', gap:'0rem', alignItems: 'flex-start' }}>

          <Select
            style={{
              flexGrow: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              verticalAlign: "bottom",
              width: "100%" 
            }}
            {...errorMessage && { status : "error" }}
            value={numericalProperties[index]?.modelType}
            disabled={!numericalProperties?.[index]?.parameter}
            onChange={(e) => {
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage][index].modelType = e
                delete newState[currentSelectedStage][index].error_modelType

                const prevData =
                  newState[currentSelectedStage].filter(
                    (prev: NumericalProperty) =>
                      !!prev.modelType.length && prev.modelType !== "range",
                  ).length === 1
                if (e === "minimize" || e === "maximize") {
                  delete newState[currentSelectedStage][index].error_min
                  delete newState[currentSelectedStage][index].error_max
                  newState[currentSelectedStage][index].min = ""
                  newState[currentSelectedStage][index].max = ""
                  newState[currentSelectedStage][index].value = e
                  if (prevData) {
                    newState[currentSelectedStage][index].priority = 1
                    delete newState[currentSelectedStage][index].error_priority
                  }
                } else if (e === "range") {
                  delete newState[currentSelectedStage][index].error_priority
                  newState[currentSelectedStage][index].value = ""
                  newState[currentSelectedStage][index].priority = null
                }
                return { ...newState }
              })
            }}
            placeholder={t("aiEngine.inverseModel.selectType")}
            className="dropdown-content-cell"
          >
            <Option value="range">{t("common.range")}</Option>
            <Option value={"minimize"}>{t("aiEngine.minimize")}</Option>
            <Option value={"maximize"}>{t("aiEngine.maximize")}</Option>
          </Select>
          <ErrorText text={errorMessage ? errorMessage : ''} />
        </div>
      )
    },
    [currentSelectedStage, numericalProperties, setNumericalProperties, t],
  )

  const minInputComponent = useCallback(
    (property, index) => {
      const errorMessage = numericalProperties[index]?.error_min
      return (
        <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS/2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display:'flex', flexDirection:'column', gap:'0rem', alignItems: 'flex-start' }}>
          <InputNumber
            disabled={disableCell(property, "range")}
            formatter={formatter}
            parser={parser}
            {...errorMessage && { status : "error" }}
            style={{ textAlign: "right", width: "100%" }}
            value={numericalProperties[index]?.min}
            onChange={(e: any) => {
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage][index].min = e
                delete newState[currentSelectedStage][index].error_min
                return { ...newState }
              })
            }}
            onBlur={(e) => {
              const strValue = e.target.value
              const convertedValue = convertValue(e.target.value)
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                if (isNaN(convertedValue)) {
                  message.warning(t("aiEngine.pleaseEnterAValidNumber"))
                  newState[currentSelectedStage][index].min = ""
                  newState[currentSelectedStage][index].error_min = t("aiEngine.pleaseEnterAValidNumber")
                } else if (newState[currentSelectedStage][index].max === "") {
                  newState[currentSelectedStage][index].min = strValue !== '' ? getEUValue(convertedValue) : ""
                  delete newState[currentSelectedStage][index].error_min
                  setUnsavedChanges(true)
                } else if (convertedValue <= convertValue(newState[currentSelectedStage][index].max)) {
                  newState[currentSelectedStage][index].min = strValue !== '' ? getEUValue(convertedValue) : ""
                  delete newState[currentSelectedStage][index].error_min
                  setUnsavedChanges(true)
                } else {
                  message.warning(t("aiEngine.minShouldBeLessThanMax"))
                  newState[currentSelectedStage][index].min = ""
                  newState[currentSelectedStage][index].error_min = t("aiEngine.minShouldBeLessThanMax")
                }
                return { ...newState }
              })
            }}
          />
          <ErrorText text={errorMessage ? errorMessage : ''} />
        </div>
      )
    },
    [
      currentSelectedStage,
      disableCell,
      numericalProperties,
      setNumericalProperties,
      setUnsavedChanges,
      t,
      convertValue,
      getEUValue,
      formatter,
      parser
    ],
  )

  const maxInputComponent = useCallback(
    (property, index) => {
      const errorMessage = numericalProperties[index]?.error_max
      return (
        <div style={{ padding: PADDING_TOP_FOR_DATASHEET_CELLS/2, paddingTop: PADDING_TOP_FOR_DATASHEET_CELLS, paddingBottom: 0, display:'flex', flexDirection:'column', gap:'0rem', alignItems: 'flex-start' }}>
          <InputNumber
            disabled={disableCell(property, "range")}
            style={{ textAlign: "right", width: "100%" }}
            formatter={formatter}
            parser={parser}
            {...errorMessage && { status : "error" }}
            value={numericalProperties[index]?.max}
            onChange={(e: any) => {
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))
                newState[currentSelectedStage][index].max = e
                delete newState[currentSelectedStage][index].error_max
                return { ...newState }
              })
            }}
            onBlur={(e) => {
              const strValue = e.target.value
              const convertedValue = convertValue(e.target.value)
              setNumericalProperties((prevState) => {
                const newState = JSON.parse(JSON.stringify(prevState))

                if (isNaN(convertedValue)) {
                  message.warning(t("aiEngine.pleaseEnterAValidNumber"))
                  newState[currentSelectedStage][index].max = ""
                  newState[currentSelectedStage][index].error_max = t("aiEngine.pleaseEnterAValidNumber")
                } else if (convertedValue >= convertValue(newState[currentSelectedStage][index].min)) {
                  newState[currentSelectedStage][index].max = strValue !== '' ? getEUValue(convertedValue) : ""
                  delete newState[currentSelectedStage][index].error_max
                  setUnsavedChanges(true)
                } else {
                  message.warning(t("aiEngine.maxShouldBeGreaterThanMin"))
                  newState[currentSelectedStage][index].max = ""
                  newState[currentSelectedStage][index].error_max = t("aiEngine.maxShouldBeGreaterThanMin")
                }

                return { ...newState }
              })
            }}
          />
          <ErrorText text={errorMessage ? errorMessage : ''} />
        </div>
      )
    },
    [
      currentSelectedStage,
      disableCell,
      numericalProperties,
      setNumericalProperties,
      setUnsavedChanges,
      t,
      convertValue,
      getEUValue,
      formatter,
      parser
    ],
  )

  const datasheetData = useMemo(() => {
    const priorityColumn = {
      value: "Priority",
      readOnly: true,
      component: (
        <Row style={{ padding: 8, marginLeft: 8 }}>
          <Typography.Text strong>
            {t("common.priority")}
          </Typography.Text>
        </Row>
      ),
      width: 150,
      forceComponent: true,
      align: 'left',
    };

    const datasheetColumnData = (numericalProperties || [])?.map(
      (property: any, index: any) => {
        const data = [
          {
            value: property.parameter,
            component: parameterListDropDown(
              index,
              "numerical_output_constraints",
            ),
            align: 'left',
            forceComponent: true,
            className: "dropdown-remove-cell",
            width: 300,
          },
          {
            value: property.output_range_optimal,
            readOnly: true,
            forceComponent: true,
            width: 200,
          },
          {
            value: property?.modalType,
            component: optimizationTypeDropDown(property, index),
            forceComponent: true,
            width: 150,
            readOnly: !property.parameter,
            align: 'left',
          },
          {
            value: property?.min,
            forceComponent: true,
            component: minInputComponent(property, index),
            width: 150,
            readOnly: disableCell(property, "range"),
            align: 'left',
            disableEvents: true
          },
          {
            value: property?.max,
            forceComponent: true,
            component: maxInputComponent(property, index),
            width: 150,
            readOnly: disableCell(property, "range"),
            align: 'left',
            disableEvents: true
          },
        ];
        if (true) {
          data.push({
            value: property.priority,
            component: priorityDropDown(property, index),
            forceComponent: true,
            width: 150,
            readOnly:
              !!numericalProperties[index]?.modelType &&
              numericalProperties[index]?.modelType === "range",
            align: 'left',
          });
        }
        return data;
      },
    );

    const data = [
      [
        {
          value: t("aiEngine.objectives"),
          component: (
            <Row style={{ padding: 8, marginLeft: 8, width: "100%" }}>
              <Typography.Text strong>
                {t("aiEngine.objectives")}
              </Typography.Text>
            </Row>
          ),
          forceComponent: true,
          readOnly: true,
          width: 450,
        },
        {
          value: t("aiEngine.range"),
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8 }}>
              <Typography.Text strong style={{ width: '200px' }}>
                {t("aiEngine.range")}
              </Typography.Text>
            </Row>
          ),
          forceComponent: true,
          width: 200,
        },
        {
          value: "Optimization Type",
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8, alignItems: "center" }}>
              <Typography.Text strong>
                {t("aiEngine.optimizationType")}
              </Typography.Text>
              <Col span={3}>
                <Tooltip
                  overlayInnerStyle={{ width: "350px" }}
                  title={
                    <Space direction="vertical">
                      <Space direction="horizontal">
                        <strong>{t("common.range")}</strong> -{" "}
                        {t("inverseModel.getValuesBetweenMinAndMaxValues")}
                      </Space>
                      <Space>
                        <strong>{t("aiEngine.minimize")}</strong> -{" "}
                        {t("inverseModel.getTheLowestPossibleValues")}
                      </Space>
                      <Space>
                        <strong>{t("aiEngine.maximize")}</strong>-{" "}
                        {t("inverseModel.getTheHighestPossibleValues")}
                      </Space>
                    </Space>
                  }
                >
                  <InfoCircleFilled />
                </Tooltip>
              </Col>
            </Row>
          ),
          width: 250,
          forceComponent: true,
        },
        {
          value: t("common.min"),
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8 }}>
              <Typography.Text strong>
                {t("common.min")}
              </Typography.Text>
            </Row>
          ),
          width: 150,
          forceComponent: true,
        },
        {
          value: t("common.max"),
          readOnly: true,
          component: (
            <Row style={{ padding: 8, marginLeft: 8 }}>
              <Typography.Text strong>
                {t("common.max")}
              </Typography.Text>
            </Row>
          ),
          width: 150,
          forceComponent: true,
        },
      ],
      ...datasheetColumnData,
    ];
    if (true) data[0].push(priorityColumn);
    return data;
  }, [t, numericalProperties, parameterListDropDown, optimizationTypeDropDown, minInputComponent, disableCell, maxInputComponent, priorityDropDown]);

  return (
    <Space direction="vertical" size={"middle"} style={{ width: '100%' }}>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Space size={'large'}>
          <Typography.Text strong>
            {t('aiEngine.inverse.objectivesTitle')}
          </Typography.Text>
          <AddPropertyButton
            onClick={() => addRow("properties")}
            disabled={
              numericalObjectiveList?.length === 0 ||
              numericalProperties?.length === numericalObjectiveList?.length
            }
          />
        </Space>
      </div>

      <IDataSheet
        data={datasheetData}
        valueRenderer={(cell) => cell.value}
        className="catwise-objective-tab-table"
      />
    </Space>
  )
};

const AddPropertyButton = ({ onClick, disabled }: any) => {
  const [t] = useTranslate();
  return <StyledButton
    onClick={onClick}
    icon={<PlusOutlined />}
    disabled={
      disabled
    }
    type="default"
    size="small"
    style={{ borderRadius: 5, outline: "none" }}
  >
    {t("common.add")} {t("formulations.type.properties")}
  </StyledButton>
}
