import { EditOutlined } from "@ant-design/icons";
import { Collapse, Empty } from "antd";
import { useState } from "react";
import AddEditIngredientTemplateCustomFieldsModal from "../Common/IngredientTemplate/AddEditIngredientTemplateCustomFieldsModal";

type TProps = {
    title: string;
    fields: any[];
    selectedTemplateId: string;
};

const IngredientTemplatsCustomFields = ({
    title,
    fields,
    selectedTemplateId,
}: TProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Collapse
                defaultActiveKey={["1"]}
                items={[
                    {
                        key: "1",
                        showArrow: false,
                        collapsible: "icon",
                        label: (
                            <div className="collapse-header-container">
                                <span className="collapse-header">{title}</span>
                                <span
                                    className="add-new-cta"
                                    onClick={() => setShowModal(true)}
                                >
                                    <EditOutlined />
                                </span>
                            </div>
                        ),
                        children: (
                            <>
                                {fields.length === 0 && (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )}
                                {fields.map((p: any) => (
                                    <div key={p.id} className="data-list">
                                        {p.name && <span>{p.name}</span>}
                                    </div>
                                ))}
                            </>
                        ),
                    },
                ]}
            />
            <AddEditIngredientTemplateCustomFieldsModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedTemplateId={selectedTemplateId}
            />
        </>
    );
};

export default IngredientTemplatsCustomFields;
