import { HeatMapOutlined } from '@ant-design/icons'
import { Space, Table, Typography } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { useValue } from 'src/utils/useValue'

const { Text } = Typography


export const PredictedProperties = ({ experiments, title }: any) => {
    const [t] = useTranslate()
    const { getValue } = useValue()
    const displayNames = useSelector((state: StoreState) => state.displayNames.data?.properties)

    const columns: any = useMemo(() => [
        {
            key: "category",
            dataIndex: "category",
            title: t("common.category"),
            fixed: "left",
            width: 150,
            align: "center" as any,
        },
        {
            key: "predicted_properties",
            dataIndex: "predicted_properties",
            title: t("common.property"),
            fixed: "left",
            width: 200,
            align: "center" as any,
            render: (text: any) => displayNames?.[text]?.name || text
        },
        {
            key: "unit",
            dataIndex: "unit",
            title: t("common.unit"),
            fixed: "left",
            width: 100,
            align: "center" as any,
        },
        ...experiments.map((res: any) => ({
            key: res?.id_set?.formulation_id,
            dataIndex: res?.id_set?.formulation_id,
            title: res?.meta?.display_id,
            width: 150,
            align: "center" as any,
        }))
    ], [experiments, displayNames, t])

    const data = useMemo(() => {
        const predictedProperties = [...new Set(experiments.flatMap((res: any) => Object.keys(res?.predicted_properties || {})))].map((res: any) => ({ predicted_properties: res }))
        experiments.forEach((exp: any) => {
            predictedProperties.forEach((record: any) => {
                record.unit = exp?.predicted_properties?.[record?.predicted_properties]?.unit
                record.category = exp?.predicted_properties?.[record?.predicted_properties]?.category
                const value = exp?.predicted_properties?.[record?.predicted_properties]?.value
                record[exp?.id_set?.formulation_id] = getValue(value) || "-"
            })
        })
        return predictedProperties
    }, [experiments, getValue])


    return (
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
            <Table
                title={() => !!title ? <Space><HeatMapOutlined /><Text strong type="secondary">{title}</Text> </Space> : null}
                className={"report-table"}
                size='small'
                scroll={{ x: 450 }}
                bordered
                columns={columns}
                dataSource={data}
                pagination={false} />
        </Space>
    )
}
