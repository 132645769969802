import { createActions } from "redux-actions";

export enum InverseModelActionTypes {
    INVERSE_MODEL_RANGE_CONNECT = "INVERSE_MODEL_RANGE_CONNECT",
    INVERSE_MODEL_MINMAX_CONNECT = "INVERSE_MODEL_MINMAX_CONNECT",
    INVERSE_MODEL_RANGE_SUCCESS = "INVERSE_MODEL_RANGE_SUCCESS",
    INVERSE_MODEL_MINMAX_SUCCESS = "INVERSE_MODEL_MINMAX_SUCCESS",
    INVERSE_MODEL_RANGE_FAILURE = "INVERSE_MODEL_RANGE_FAILURE",
    INVERSE_MODEL_MINMAX_FAILURE = "INVERSE_MODEL_MINMAX_FAILURE",
    GET_PREDICTION_REQUEST = "GET_PREDICTION_REQUEST",
    GET_PREDICTION_SUCCESS = "GET_PREDICTION_SUCCESS",
    GET_PREDICTION_FAILURE = "GET_PREDICTION_FAILURE",
    INVERSE_MINMAX_ACK = 'INVERSE_MINMAX_ACK',
    INVERSE_RANGE_ACK = 'INVERSE_RANGE_ACK',
    DELETE_PREDICTION_IDS_REQUEST = 'DELETE_PREDICTION_IDS_REQUEST',
    DELETE_PREDICTION_IDS_SUCCESS = 'DELETE_PREDICTION_IDS_SUCCESS',
    DELETE_PREDICTION_IDS_FAILURE = 'DELETE_PREDICTION_IDS_FAILURE',
    INVERSE_NOTIFICATION_CLICK = 'INVERSE_NOTIFICATION_CLICK',
    DELETE_PREDICTION_IDS_CLEANUP = 'DELETE_PREDICTION_IDS_CLEANUP',
}

export enum InverseConfigActionTypes {
    INVERSE_CONFIG_REQUEST = "INVERSE_CONFIG_REQUEST",
    INVERSE_CONFIG_SUCCESS = "INVERSE_CONFIG_SUCCESS",
    INVERSE_CONFIG_FAILURE = "INVERSE_CONFIG_FAILURE",
    INVERSE_CONFIG_CLEANUP = "INVERSE_CONFIG_CLEANUP",
    TOGGLE_INVERSE_CONFIG = "TOGGLE_INVERSE_CONFIG",
    UPDATE_INVERSE_CONFIG_REQUEST = "UPDATE_INVERSE_CONFIG_REQUEST",
    UPDATE_INVERSE_CONFIG_SUCCESS = "UPDATE_INVERSE_CONFIG_SUCCESS",
    UPDATE_INVERSE_CONFIG_FAILURE = "UPDATE_INVERSE_CONFIG_FAILURE",
    UPDATE_INVERSE_CONFIG_CLEANUP = "UPDATE_INVERSE_CONFIG_CLEANUP",
    LOCAL_UPDATE_INVERSE_CONFIG = "LOCAL_UPDATE_INVERSE_CONFIG",
    UNSAVE_INVERSE_CONFIG = "UNSAVE_INVERSE_CONFIG",
}

export enum AddFavouritesInInverseActionTypes {
    REQUEST = "ADD_FAVOURITES_IN_INVERSE_REQUEST",
    SUCCESS = "ADD_FAVOURITES_IN_INVERSE_SUCCESS",
    FAILURE = "ADD_FAVOURITES_IN_INVERSE_FAILURE",
    CLEANUP = "ADD_FAVOURITES_IN_INVERSE_CLEANUP",
}

export enum DeleteFavouritesInInverseActionTypes {
    REQUEST = "DELETE_FAVOURITES_IN_INVERSE_REQUEST",
    SUCCESS = "DELETE_FAVOURITES_IN_INVERSE_SUCCESS",
    FAILURE = "DELETE_FAVOURITES_IN_INVERSE_FAILURE",
    CLEANUP = "DELETE_FAVOURITES_IN_INVERSE_CLEANUP",
}

export enum GetFavouritesListInInverseActionTypes {
    REQUEST = "GET_FAVOURITES_LIST_IN_INVERSE_REQUEST",
    SUCCESS = "GET_FAVOURITES_LIST_IN_INVERSE_SUCCESS",
    FAILURE = "GET_FAVOURITES_LIST_IN_INVERSE_FAILURE",
    CLEANUP = "GET_FAVOURITES_LIST_IN_INVERSE_CLEANUP",
}

export const {
    inverseModelRangeConnect,
    inverseModelMinmaxConnect,
    inverseModelRangeSuccess,
    inverseModelMinmaxSuccess,
    inverseModelRangeFailure,
    inverseModelMinmaxFailure,
    getPredictionRequest,
    getPredictionSuccess,
    getPredictionFailure,
    inverseMinmaxAck,
    inverseRangeAck,
    deletePredictionIdsRequest,
    deletePredictionIdsSuccess,
    deletePredictionIdsFailure,
    deletePredictionIdsCleanup,
    inverseNotificationClick,
    inverseConfigRequest,
    inverseConfigSuccess,
    inverseConfigFailure,
    inverseConfigCleanup,
    addFavouritesInInverseRequest,
    addFavouritesInInverseSuccess,
    addFavouritesInInverseFailure,
    addFavouritesInInverseClear,
    deleteFavouritesInInverseRequest,
    deleteFavouritesInInverseSuccess,
    deleteFavouritesInInverseFailure,
    deleteFavouritesInInverseClear,
    getFavouritesListInInverseRequest,
    getFavouritesListInInverseSuccess,
    getFavouritesListInInverseFailure,
    getFavouritesListInInverseClear
} = createActions({
    [InverseModelActionTypes.INVERSE_MODEL_RANGE_CONNECT]: (payload) => payload,
    [InverseModelActionTypes.INVERSE_MODEL_MINMAX_CONNECT]: (payload) => payload,
    [InverseModelActionTypes.INVERSE_MODEL_RANGE_SUCCESS]: (response) => response,
    [InverseModelActionTypes.INVERSE_MODEL_MINMAX_SUCCESS]: (response) => response,
    [InverseModelActionTypes.INVERSE_MODEL_RANGE_FAILURE]: (error) => ({ error }),
    [InverseModelActionTypes.INVERSE_MODEL_MINMAX_FAILURE]: (error) => ({ error }),
    [InverseModelActionTypes.GET_PREDICTION_REQUEST]: (payload) => payload,
    [InverseModelActionTypes.GET_PREDICTION_SUCCESS]: (response) => response,
    [InverseModelActionTypes.GET_PREDICTION_FAILURE]: (error) => ({ error }),
    [InverseModelActionTypes.INVERSE_RANGE_ACK]: (response) => response,
    [InverseModelActionTypes.INVERSE_MINMAX_ACK]: (response) => response,
    [InverseModelActionTypes.DELETE_PREDICTION_IDS_REQUEST]: (payload) => payload,
    [InverseModelActionTypes.DELETE_PREDICTION_IDS_SUCCESS]: () => { },
    [InverseModelActionTypes.DELETE_PREDICTION_IDS_FAILURE]: (error) => ({ error }),
    [InverseModelActionTypes.DELETE_PREDICTION_IDS_CLEANUP]: () => { },
    [InverseModelActionTypes.INVERSE_NOTIFICATION_CLICK]: (payload) => (payload),
    [InverseConfigActionTypes.INVERSE_CONFIG_REQUEST]: (payload) => payload,
    [InverseConfigActionTypes.INVERSE_CONFIG_SUCCESS]: (response) => response,
    [InverseConfigActionTypes.INVERSE_CONFIG_FAILURE]: (error) => ({ error }),
    [InverseConfigActionTypes.INVERSE_CONFIG_CLEANUP]: () => { },
    [AddFavouritesInInverseActionTypes.REQUEST]: (payload) => payload,
    [AddFavouritesInInverseActionTypes.SUCCESS]: (response) => response,
    [AddFavouritesInInverseActionTypes.FAILURE]: (error) => (error),
    [AddFavouritesInInverseActionTypes.CLEANUP]: () => { },
    [DeleteFavouritesInInverseActionTypes.REQUEST]: (payload) => payload,
    [DeleteFavouritesInInverseActionTypes.SUCCESS]: (response) => response,
    [DeleteFavouritesInInverseActionTypes.FAILURE]: (error) => (error),
    [DeleteFavouritesInInverseActionTypes.CLEANUP]: () => { },
    [GetFavouritesListInInverseActionTypes.REQUEST]: (payload) => payload,
    [GetFavouritesListInInverseActionTypes.SUCCESS]: (response) => response,
    [GetFavouritesListInInverseActionTypes.FAILURE]: (error) => (error),
    [GetFavouritesListInInverseActionTypes.CLEANUP]: () => { },
});
