import useTranslate from "src/utils/useTranslate";
import { TRIAL_DATA_TYPES } from "../Common/Data";

type TProps = {
    type: TRIAL_DATA_TYPES;
    setType: React.Dispatch<React.SetStateAction<TRIAL_DATA_TYPES>>;
};

const TrialDataTypesSidebar = ({ type, setType }: TProps) => {
    const [t] = useTranslate();

    const getLabel = (category: TRIAL_DATA_TYPES) => {
        if (category === TRIAL_DATA_TYPES.CHARACTERIZATIONS) return t("common.characterizations");
        else if (category === TRIAL_DATA_TYPES.PROCESSING) return t("common.processing");
        else if (category === TRIAL_DATA_TYPES.PROPERTIES) return t("common.properties");
        else if (category === TRIAL_DATA_TYPES.METADATA_PROPERTIES) return t("inventory.metadata.title");
        else return category;
    }

    return (
        <div className="category-sidebar">
            <div className="body">
                {Object.values(TRIAL_DATA_TYPES).map((c) => (
                    <div
                        key={c}
                        className={`category${type === c ? " selected" : ""}`}
                        onClick={() => setType(c)}
                    >
                        {getLabel(c).toLowerCase()}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TrialDataTypesSidebar;
