import axios from "axios"
import {
	AggregationMethod,
	PivotTableData,
} from "src/components/AIEngine/DataSummary/tabs/summaries/pivot-table-analysis/types"
import { sendPost, sendPostML } from "src/utils/sendApi"
import { Headers } from "../interface"

export const getOverallSummaryApi = (data: any, headers: Headers) => {
	return axios.post<any>(
		`${process.env.REACT_APP_ML_URL}overall_summary`,
		data,
		{ headers }
	)
}

export type PivotTableAPIParams = {
	vars_x: string
	vars_y: string[]
	prop_to_compare: string
	agg: AggregationMethod
	work_order_id: string
}
export interface PivotTableAPIResponse {
	data: PivotTableData
	status: number
	statusText: string // "OK"
}
export const postPivotTableData =
	sendPostML<PivotTableAPIParams, PivotTableAPIResponse>("pivot_summary")

export const boxPlotSummaryApi = sendPostML("box_plot_summary")

export const dataAnalyticsPlotDataApi = sendPost("v1/data/analytics/plots_data")

export const getIndividualSummaryApi = (data: any, headers: Headers) => {
	return axios.post<any>(
		`${process.env.REACT_APP_ML_URL}individual_summary`,
		data,
		{ headers }
	)
}

export const postOverallCategoricalSummary = (data: any, headers: Headers) => {
	return axios.post<any>(
		`${process.env.REACT_APP_ML_URL}overall_categorical_summary`,
		data,
		{ headers }
	)
}
export const postIndividualCategoricalSummary = (
	data: any,
	headers: Headers
) => {
	return axios.post<any>(
		`${process.env.REACT_APP_ML_URL}individual_categorical_summary`,
		data,
		{ headers }
	)
}


export const savePlotsDataApi = sendPost("v1/data/analytics/save_plots")

export const plotsHistoryDataApi = sendPost("v1/data/analytics/plots_history_list")

export const deleteSavedPlotsDataApi = sendPost("v1/data/analytics/plots_history_delete")


export function dataSummaryStream(payload: any) {
	return new Promise((resolve, reject) => {
		const socket = new WebSocket(
			process.env.REACT_APP_ML_WS_URL + "ws/heatmap/" + payload
		)
		socket.onopen = function () {
			resolve(socket)
		}

		socket.onerror = function (evt) {
			reject(payload)
		}
	})
}