import "./Common.scss";
import { PlusOutlined } from "@ant-design/icons";
import { Input, Typography } from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    title: string;
    addItem: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchTerm?: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const TabHeader = ({ title, addItem, setSearchTerm }: TProps) => {
    const [t] = useTranslate();

    return (
        <div className="tab-header">
            <div className="total-container">
                <Typography.Title level={4} className="properties">
                    {title}
                </Typography.Title>
                <StyledButton
                    type="default"
                    style={{ borderRadius: "6px" }}
                    size="middle"
                    icon={<PlusOutlined />}
                    onClick={() => addItem(true)}
                >
                    {t("common.Add New")}
                </StyledButton>
            </div>
            {setSearchTerm && <Input.Search
                style={{ width: 300 }}
                placeholder={t("common.search")}
                onSearch={(value) => {
                    setSearchTerm && setSearchTerm(value.trim())
                }}
                allowClear
            />}
        </div>
    );
};

export default TabHeader;
