import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import { DashboardActivityCardTotalActionTypes, DashboardProjectStateCountsActionTypes, DashboardRecentEditsActionTypes, DashboardUsedIngredientsActionTypes, DashboardWorkorderStateCountsActionTypes, FetchDashboardDataActionTypes, FetchNewsActionTypes } from "../actions/dashboard";


export type DashboardState = {
    dashboardData:any,
    dashboardDataStatus:AsyncStates,
    dashboardDataError:String,
    newsData:any[],
    newsDataStatus:AsyncStates,
    newsDataError:String,

    dashboardRecentEditsResponse:any[],
    dashboardRecentEditsStatus:AsyncStates,
    dashboardRecentEditsError:String

    dashboardUsedIngredientsResponse:any,
    dashboardUsedIngredientsStatus:AsyncStates,
    dashboardUsedIngredientsError:String,

    dashboardWorkorderStateCountsResponse:any,
    dashboardWorkorderStateCountsStatus:AsyncStates,
    dashboardWorkorderStateCountsError:String,

    dashboardProjectStateCountsResponse:any,
    dashboardProjectStateCountsStatus:AsyncStates,
    dashboardProjectStateCountsError:String

    dashboardActivityCardTotalResponse: any,
    dashboardActivityCardTotalStatus: AsyncStates,
    dashboardActivityCardTotalError: String
}

const defaultState: DashboardState={
    dashboardData:{},
    dashboardDataStatus:AsyncStates.INITIAL,
    dashboardDataError:"",
    newsData:[],
    newsDataStatus:AsyncStates.INITIAL,
    newsDataError:"",

    dashboardRecentEditsResponse:[],
    dashboardRecentEditsStatus:AsyncStates.INITIAL,
    dashboardRecentEditsError:"",

    dashboardUsedIngredientsResponse:{},
    dashboardUsedIngredientsStatus:AsyncStates.INITIAL,
    dashboardUsedIngredientsError:"",

    dashboardWorkorderStateCountsResponse:{},
    dashboardWorkorderStateCountsStatus:AsyncStates.INITIAL,
    dashboardWorkorderStateCountsError:"",


    dashboardProjectStateCountsResponse:{},
    dashboardProjectStateCountsStatus:AsyncStates.INITIAL,
    dashboardProjectStateCountsError:"",

    dashboardActivityCardTotalResponse: {},
    dashboardActivityCardTotalStatus: AsyncStates.INITIAL,
    dashboardActivityCardTotalError: ""

}

const dashBoardReducer = handleActions<DashboardState,any>({
    [FetchDashboardDataActionTypes.REQUEST]: (state) => ({
        ...state,
        dashboardDataStatus: AsyncStates.LOADING,
    }),
    [FetchDashboardDataActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        dashboardDataStatus: AsyncStates.SUCCESS,
        dashboardData: action.payload ?? {}
    }),
    [FetchDashboardDataActionTypes.FAILURE]: (state, action) => ({
        ...state,
        dashboardDataStatus: AsyncStates.ERROR,
        dashboardDataError: action.payload
    }),
    [FetchNewsActionTypes.REQUEST]: (state) => ({
        ...state,
        newsDataStatus: AsyncStates.LOADING,
    }),
    [FetchNewsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        newsDataStatus: AsyncStates.SUCCESS,
        newsData: action.payload ?? []
    }),
    [FetchNewsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        newsDataStatus: AsyncStates.ERROR,
        newsDataError: action.payload
    }),
    
    [DashboardRecentEditsActionTypes.REQUEST]: (state) => ({
        ...state,
        dashboardRecentEditsStatus: AsyncStates.LOADING,
    }),
    [DashboardRecentEditsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        dashboardRecentEditsStatus: AsyncStates.SUCCESS,
        dashboardRecentEditsResponse: action.payload ?? []
    }),
    [DashboardRecentEditsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        dashboardRecentEditsStatus: AsyncStates.ERROR,
        dashboardRecentEditsError: action.payload
    }),

    [DashboardUsedIngredientsActionTypes.REQUEST]: (state) => ({
        ...state,
        dashboardUsedIngredientsStatus: AsyncStates.LOADING,
    }),
    [DashboardUsedIngredientsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        dashboardUsedIngredientsStatus: AsyncStates.SUCCESS,
        dashboardUsedIngredientsResponse: action.payload ?? []
    }),
    [DashboardUsedIngredientsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        dashboardUsedIngredientsStatus: AsyncStates.ERROR,
        dashboardUsedIngredientsError: action.payload
    }),

    [DashboardWorkorderStateCountsActionTypes.REQUEST]: (state) => ({
        ...state,
        dashboardWorkorderStateCountsStatus: AsyncStates.LOADING,
    }),
    [DashboardWorkorderStateCountsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        dashboardWorkorderStateCountsStatus: AsyncStates.SUCCESS,
        dashboardWorkorderStateCountsResponse: action.payload ?? {}
    }),
    [DashboardWorkorderStateCountsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        dashboardWorkorderStateCountsStatus: AsyncStates.ERROR,
        dashboardWorkorderStateCountsError: action.payload
    }),

    [DashboardProjectStateCountsActionTypes.REQUEST]: (state) => ({
        ...state,
        dashboardProjectStateCountsStatus: AsyncStates.LOADING,
    }),
    [DashboardProjectStateCountsActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        dashboardProjectStateCountsStatus: AsyncStates.SUCCESS,
        dashboardProjectStateCountsResponse: action.payload ?? {}
    }),
    [DashboardProjectStateCountsActionTypes.FAILURE]: (state, action) => ({
        ...state,
        dashboardProjectStateCountsStatus: AsyncStates.ERROR,
        dashboardProjectStateCountsError: action.payload
    }),

    [DashboardActivityCardTotalActionTypes.REQUEST]: (state) => ({
        ...state,
        dashboardActivityCardTotalStatus: AsyncStates.LOADING,
    }),
    [DashboardActivityCardTotalActionTypes.SUCCESS]: (state, action) => ({
        ...state,
        dashboardActivityCardTotalStatus: AsyncStates.SUCCESS,
        dashboardActivityCardTotalResponse: action.payload ?? {}
    }),
    [DashboardActivityCardTotalActionTypes.FAILURE]: (state, action) => ({
        ...state,
        dashboardActivityCardTotalStatus: AsyncStates.ERROR,
        dashboardActivityCardTotalError: action.payload
    }),
},defaultState)

export default dashBoardReducer