import { Collapse } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Text from "antd/lib/typography/Text";
import ZeonWorkOrderTables from './ZeonWorkOrderTables'
import { useMethodDetails } from 'src/utils/useMethodDetails';

const { Panel } = Collapse;

export const ZeonMethodsTable = ({ formulationList, index, checkbox, checkBoxChange, formulationsCheck, from, variation, set_id, filters, tableId = "" }: any) => {
    const [isHighlighted, setIsHighlighted] = useState<string[]>([])
    const { getVariationDetails } = useMethodDetails()
    const methodDetails = useMemo(() => getVariationDetails(variation?.id, variation?.data), [getVariationDetails, variation])

    function isInRange(x: number, min: number, max: number) {
        return x >= min && x <= max;
    }

    const updateHighlighter = useCallback((operator: string, selector: any, parameter: string, filterValue: any) => {
        if (operator === "eq") {
            if (selector.data[parameter].value === filterValue) {
                setIsHighlighted((prev: any) => [...prev, selector.characterization_set_id])
            }
        }
        if (operator === "gt") {
            if (selector.data[parameter].value > filterValue) {
                setIsHighlighted((prev: any) => [...prev, selector.characterization_set_id])
            }
        }
        if (operator === "lt") {
            if (selector.data[parameter].value < filterValue) {
                setIsHighlighted((prev: any) => [...prev, selector.characterization_set_id])
            }
        }

        if (operator === "range") {
            const response = isInRange(selector.data[parameter].value, filterValue[0], filterValue[1])
            if (response) {
                setIsHighlighted((prev: any) => [...prev, selector.characterization_set_id])
            }
        }
        if (operator === "exists") {
            if (selector.data[parameter]) {
                setIsHighlighted((prev: any) => [...prev, selector.characterization_set_id])
            }
        }
    }, [])

    useEffect(() => {
        if (from === "drawer") {
            filters?.data?.flatMap(({ data: filterData }: any) => filterData.map((ele: any) => {
                if (ele.parameter_type === "properties") {
                    formulationList[0]?.properties[0]?.properties?.map((property: any) => {
                        if (property.data[ele.parameter]) {
                            const filterValue = typeof property.data[ele.parameter]?.value === "string" ? ele.val : Number(ele.val)
                            const userInputValue = ele.operator === "range" ? [filterValue, ele.max] : filterValue
                            updateHighlighter(ele.operator, property, ele.parameter, userInputValue)
                        }
                        return null
                    })
                }
                if (ele.parameter_type === "characterization_method") {
                    formulationList[0]?.characterizations[0]?.characterizations.map((characterization: any) => {
                        if (ele.parameter === characterization?.variation_id) {
                            setIsHighlighted((prev: any) => [...prev, characterization.characterization_set_id])
                        }
                        return null
                    })
                }
                if (ele.parameter_type === "characterization_method_parameters") {
                    formulationList[0]?.characterizations[0]?.characterizations.map((characterization: any) => {
                        if (characterization.data[ele.parameter]?.value && (ele.variation === characterization.variation_id)) {
                            const filterValue = typeof characterization.data[ele.parameter]?.value === "string" ? ele.val : Number(ele.val)
                            const userInputValue = ele.operator === "range" ? [filterValue, ele.max] : filterValue
                            updateHighlighter(ele.operator, characterization, ele.parameter, userInputValue)
                        }
                        return null
                    })
                }
                if (ele.parameter_type === "characterizations") {
                    formulationList[0]?.characterizations[0]?.characterizations.map((characterization: any) => {
                        if (characterization.data[ele.parameter]?.value) {
                            const filterValue = typeof characterization.data[ele.parameter]?.value === "string" ? ele.val : Number(ele.val)
                            const userInputValue = ele.operator === "range" ? [filterValue, ele.max] : filterValue
                            updateHighlighter(ele.operator, characterization, ele.parameter, userInputValue)
                        }
                        return null
                    })
                }
                return null
            }))
        }
    }, [filters, formulationList, updateHighlighter, from])


    return (
        <Collapse expandIconPosition="right">
            <Panel header={<Text className={isHighlighted.includes(set_id) ? "highlighted" : ""}>{`${index + 1}) ${methodDetails.variationName} - ${methodDetails.variationDesc} `}</Text>} key={set_id}>
                <ZeonWorkOrderTables formulationList={formulationList} index={index} checkbox={checkbox} checkBoxChange={checkBoxChange} formulationsCheck={formulationsCheck} from={from} tableId={tableId} />
            </Panel>
        </Collapse>
    )
}