import KDEChart from "../../Shared/KDEChart";
import MaskTable from "../../Shared/MaskTable";

type TProps = {
    data: any;
};

const PaintFilmAnalysisAttributes = ({
    data
}: TProps) => {
    return (
        <>
            {data && data?.mask_results?.length && (
                <>
                    <KDEChart
                        data={data?.mask_results}
                        unit={data?.unit}
                        selections={[]}
                        handleChartSelection={() => null}
                        titles={{
                            graphTitle: "Diameter Distribution",
                            yAxis: "Count",
                            xAxis: "Diameter"
                        }}
                        showSelections={false}
                    />
                    <h3>Total masks ({data?.mask_results?.length})</h3>
                    <MaskTable data={data} />
                </>
            )}
        </>
    );
};

export default PaintFilmAnalysisAttributes;
