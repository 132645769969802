import { LoadingOutlined } from "@ant-design/icons"
import { Card, Form, Input, Select, Space, Typography, Row, Col, Spin, Steps, Modal } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Prompt } from "react-router-dom"
import { AsyncStates } from "src/constants"
import { celsureWoCreateRequest, celsureWoTrialsRequest, celsureWoUpdateRequest, resetCelsureWo } from "src/store/actions/celsureWo"
import { StoreState } from '../../../store/configureStore'
import { useMemberName } from "../../../utils/useMemberName"
import { TrialsUpload } from "../UploadForm/TrialsUpload"
import { PropertiesUpload } from "../UploadForm/PropertiesUpload"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { CelsureExperiments } from "../CelsureExperiments/CelsureExperiments"
import { orange } from "@ant-design/colors"
import { setCelsureEditingTrialsState, setCelsureEditingWoState } from "src/store/actions/celsure"
import { FileUpload } from "./FileUpload"
import { Plots } from "./Plots"
import { WorkOrderReportPreview } from "./WorkOrderReportPreview"
import { CelsureCompareGraph } from "./CompareGraphs"
import useTranslate from "src/utils/useTranslate"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
import { StyledButton } from "src/styled_components/StyledButton"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";

const { Text } = Typography
const { Option } = Select
const { Step } = Steps


export const WorkOrderDetails = () => {
  const dispatch = useDispatch();
  const requiredFieldStar = useRequiredFieldStar()

  const { getName } = useMemberName()
  const { current, projectList: projects } = useSelector((state: StoreState) => state.projects)
  const currentProject = useMemo(() => projects.find((project: any) => project.project_id === current), [projects, current])
  const { celsureCreateWoStatus, celsureUpdateWoStatus, celsureWo, celsureWoTrialsStatus, celsureWoTrials } = useSelector((state: StoreState) => state.celsureWo)
  const { celsureDisplayNamesStatus, celsureDisplayNamesData, celsureEditingWoState, celsureEditingTrialsState, celsureEditTrialStatus, saveDataStatus } = useSelector((state: StoreState) => state.celsure)
  const [trialRecord, setTrialRecord] = useState<any>({})
  const [form] = Form.useForm()
  const [trialsForm] = Form.useForm()
  const [t] = useTranslate()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!!celsureWo?.work_order_id) {
      dispatch(
        celsureWoTrialsRequest({ work_order_id: celsureWo?.work_order_id })
      );
    }
  }, [dispatch, celsureWo?.work_order_id]);

  useEffect(() => {
    return () => {
      dispatch(resetCelsureWo());
      dispatch(setCelsureEditingTrialsState(false));
      dispatch(setCelsureEditingTrialsState(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      celsureCreateWoStatus === AsyncStates.SUCCESS ||
      celsureUpdateWoStatus === AsyncStates.SUCCESS
    ) {
      dispatch(setCelsureEditingWoState(false));
    }
  }, [dispatch, celsureCreateWoStatus, celsureUpdateWoStatus]);

  useEffect(() => {
    if (
      celsureEditTrialStatus === AsyncStates.SUCCESS ||
      saveDataStatus === AsyncStates.SUCCESS
    ) {
      dispatch(setCelsureEditingTrialsState(false));
    }
  }, [dispatch, celsureEditTrialStatus, saveDataStatus]);

  const submitWorkOrder = (values: any) => {
    if (!!celsureWo?.work_order_id) {
      dispatch(
        celsureWoUpdateRequest({
          ...values,
          work_order_id: celsureWo?.work_order_id,
        })
      );
    } else {
      dispatch(celsureWoCreateRequest({ ...values, project_id: current }));
    }
  };

  const setStageStatus = (stage: string) => {
    const stageSeq = Object.keys(celsureDisplayNamesData?.stages || {});
    const stageIdx = stageSeq.indexOf(stage);
    const curStageIdx = stageSeq.indexOf(celsureWo?.work_order_stage);

    if (stage === celsureWo.work_order_stage) {
      return { status: "process", desc: t("stages.status.inProgress") };
    } else if (stageIdx < curStageIdx) {
      return { status: "finish", desc: t("stages.status.completed") };
    } else if (stageIdx > curStageIdx) {
      return { status: "wait", desc: t("stages.status.awaiting") };
    } else {
      return { status: "error", decs: t("stages.status.disabled") };
    }
  };

  // check for unsaved prompt when tab is closed or refresh

  const callback = useCallback(
    (e: any) => {
      e.preventDefault();
      e.returnValue = t("workOrderDetails.warn.unsavedChanges");
    },
    [t]
  );

  useEffect(() => {
    if (!!celsureWo?.work_order_id) {
      form.setFieldsValue({
        ...celsureWo,
        work_order_dri: celsureWo?.work_order_dri
          ? celsureWo?.work_order_dri
          : []
      })
    }
  }, [celsureWo, form])

  useEffect(() => {
    (celsureEditingWoState || celsureEditingTrialsState) &&
      window.addEventListener("beforeunload", callback);
    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [celsureEditingWoState, callback, celsureEditingTrialsState]);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Prompt
        when={(celsureEditingWoState || celsureEditingTrialsState) as boolean}
        message={`${t("common.unsavedChangesLost")}!.`}
      />
      <StyledPageHeader
        ghost={false}
        title={t("common.workOrder")}
        onBack={() => window.history.back()}
      />
      <Spin
        spinning={
          celsureCreateWoStatus === AsyncStates.LOADING ||
          celsureUpdateWoStatus === AsyncStates.LOADING
        }
        indicator={<LoadingOutlined />}
      >
        <Card
          title={t("workOrderDetails.details")}
          extra={
            <Space size="large">
              {celsureEditingWoState && (
                <Text style={{ color: orange.primary }}>
                  {t("common.unsavedChanges")}
                </Text>
              )}
              <Text type="secondary">{"* All field are editable."}</Text>
            </Space>
          }
          bodyStyle={{ padding: "24px 16px" }}
          headStyle={{ padding: "0px 16px" }}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={submitWorkOrder}
            onFieldsChange={() => {
              if (!celsureEditingWoState)
                dispatch(setCelsureEditingWoState(true));
            }}
            initialValues={{
              ...celsureWo,
              work_order_dri: celsureWo?.work_order_dri
                ? celsureWo?.work_order_dri
                : [],
            }}
            requiredMark={false}
          >
            <Row justify="start">
              <Col span={12}>
                <Form.Item
                  name="work_order_name"
                  label={t("common.title")}
                  rules={[{ required: true, message: t("common.required") }]}
                  required tooltip={requiredFieldStar}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
                <Form.Item
                  name="work_order_desc"
                  label={t("common.description")}
                >
                  <Input.TextArea placeholder={t("editProject.enterDescription")} rows={3} />
                </Form.Item>
                <Form.Item
                  required={false}
                  label={"Members"}
                  name="work_order_dri"
                >
                  <Select placeholder="Select members" mode="multiple">
                    {currentProject?.members
                      ?.map(({ user_id }: any) => user_id)
                      ?.concat(currentProject.project_owner)
                      ?.map((member: any) => (
                        <Option value={member} key={member}>
                          {getName(member)}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Row justify="end">
                  <StyledButton type="primary" htmlType="submit">
                    {celsureWo?.work_order_id
                      ? t("common.update")
                      : t("common.save")}
                  </StyledButton>
                </Row>
              </Col>
            </Row>
          </Form>
        </Card>
        {!!celsureWo?.work_order_id && (
          <Spin
            spinning={
              celsureDisplayNamesStatus === AsyncStates.LOADING ||
              celsureWoTrialsStatus === AsyncStates.LOADING
            }
            indicator={<LoadingOutlined />}
          >
            <Card
              bodyStyle={{ paddingTop: 0, paddingRight: 32 }}
              style={{ marginBottom: 5 }}
            >
              <Steps
                type="navigation"
                size="small"
                current={Object.keys(
                  celsureDisplayNamesData?.stages || {}
                ).indexOf(celsureWo.work_order_stage)}
                onChange={function (current: number) {
                  Modal.confirm({
                    title: `${celsureEditingTrialsState || celsureEditingWoState
                      ? `${"Unsaved changes present"}.`
                      : ""
                      } ${"Confirm stage change?"}`,
                    icon: <ExclamationCircleOutlined />,
                    content: `${celsureDisplayNamesData?.stages?.[
                      celsureWo.work_order_stage
                    ] ||
                      Object.values(celsureDisplayNamesData?.stages || {})[0]
                      } > ${Object.values(celsureDisplayNamesData?.stages || {})?.[
                      current
                      ]
                      }`,
                    onOk() {
                      dispatch(
                        celsureWoUpdateRequest({
                          work_order_id: celsureWo?.work_order_id,
                          work_order_stage: Object.keys(
                            celsureDisplayNamesData?.stages || {}
                          )?.[current],
                        })
                      );
                      dispatch(setCelsureEditingTrialsState(false));
                      dispatch(setCelsureEditingWoState(false));
                      setTrialRecord({});
                    },
                  });
                }}
                style={{ flexWrap: "wrap" }}
              >
                {Object.entries(celsureDisplayNamesData?.stages || {}).map(
                  ([key, label]: any) => (
                    <Step
                      key={key}
                      style={{
                        flexBasis: 220,
                        flexGrow: 0,
                        paddingTop: 16,
                        textAlign: "left",
                        paddingLeft: 48,
                      }}
                      title={
                        <Text
                          strong
                          ellipsis
                          style={{ width: 140 }}
                          title={label}
                        >
                          {label}
                        </Text>
                      }
                      status={setStageStatus(key).status as any}
                      description={setStageStatus(key).desc}
                      disabled={!celsureWo.work_order_stage}
                    />
                  )
                )}
              </Steps>
            </Card>
            <Space direction="vertical" style={{ width: "100%" }} ref={ref}>
              {!!celsureWoTrials?.length && <CelsureExperiments from={"work-order"} setTrialRecord={setTrialRecord} />}
              {celsureWo.work_order_stage === "formulation_stage" &&
                <TrialsUpload trialRecord={trialRecord} setTrialRecord={setTrialRecord} form={trialsForm}
                />}
              {(celsureWo.work_order_stage === "properties_stage" && !!trialRecord?.data?.trial_id) &&
                <PropertiesUpload trialRecord={trialRecord} setTrialRecord={setTrialRecord} form={trialsForm} />
              }
              {(celsureWo.work_order_stage === "properties_stage" && !!celsureWo?.plots_data_uploaded) &&
                <Plots form={trialsForm} trialRecord={trialRecord} celsureWoTrials={celsureWoTrials} from={"work-order"} />
              }
              {(celsureWo.work_order_stage === "properties_stage") &&
                <CelsureCompareGraph />
              }
              {celsureWo.work_order_stage === "properties_stage" && (
                <FileUpload />
              )}
              {celsureWo.work_order_stage === "report_preview" && (
                <WorkOrderReportPreview
                  celsureWo={celsureWo}
                  from={"work-order"}
                />
              )}
            </Space>
          </Spin>
        )}
      </Spin>
    </Space>
  );
};
