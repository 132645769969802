import React from "react"
import { CollapseProps, Divider, Tooltip, Typography } from "antd"
import { ErrorBoundary } from "src/components/core"
import useTranslate from "src/utils/useTranslate"
import { QuestionCircleOutlined } from "@ant-design/icons"
import "./summary-wrapper.scss"
const { Title, Text } = Typography

type HeaderProps = {
	heading: string
}
export const Header = ({ heading }: HeaderProps) => {
	return (
		<Title style={{ margin: "0", userSelect: "none" }} level={4}>
			{heading}
		</Title>
	)
}

type SummaryWrapperProps = CollapseProps & {
	heading: string
	tooltip?: string,
	id?: string
}
export const SummaryWrapper = ({
	heading,
	tooltip,
	id,
	children,
	...props
}: SummaryWrapperProps) => {
	const [t] = useTranslate()

	const genExtra = () => (
		<Tooltip title={tooltip} >
			<QuestionCircleOutlined
				onClick={event => {
					// If you don't want click extra trigger collapse, you can prevent this:
					event.stopPropagation();
				}}
				style={{ paddingRight: 12 }}
			/>
		</Tooltip>
	);

	return (
		<ErrorBoundary
			fallback={
				<Typography.Title level={5} style={{ color: "red" }}>
					{t("common.anErroOccurredIn")} {heading}. {t("common.tryAgainLater")}
				</Typography.Title>
			}
		>
			{/* <Collapse defaultActiveKey={heading} expandIconPosition="end" {...props}>
				<Collapse.Panel header={<Header heading={heading} />} key={heading} extra={genExtra()}> */}
			<div className="custom__border">
				<div className="summary__wrapper__header" id={id}>
					<Header heading={heading} />
					{tooltip && <Text strong>{genExtra()}</Text>}
				</div>
				<Divider style={{ margin: "0.25rem 0rem 0.75rem" }} />
				{children}
			</div>
			{/* </Collapse.Panel>
			</Collapse> */}
		</ErrorBoundary>
	)
}
