import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setProject } from "src/store/actions/projects";
import { CustomML } from "./CustomML";

const CustomMLWrapper = ({
    customMlDrawerVisible,
    setCustomMlDrawerVisible,
}: any) => {
    const [initital, setInitial] = useState(true);
    const { state } = useLocation() as any;
    const [isMultiStage] = useState(state?.isMultiStage);
    const [project] = useState(state?.project);
    const [isRedirect] = useState(state?.redirect);
    const [selectedFormulations] = useState(state?.selectedFormulations);
    const [selectedWorkOrders] = useState(state?.selectedWorkOrders);
    const dispatch = useDispatch();

    useEffect(() => {
        if (project) {
            dispatch(setProject(project));
        }
        if (initital) {
            setInitial(false);
            
            // Cleanup router state
            window.history.replaceState({}, '');
        }
    }, [dispatch, project, initital])

    if (initital) return <></>

    if (isRedirect) return (
        <CustomML
            customMlDrawerVisible={customMlDrawerVisible}
            setCustomMlDrawerVisible={setCustomMlDrawerVisible}
            isRedirect={isRedirect}
            isMultiStage={isMultiStage}
            selectedFormulations={selectedFormulations}
            selectedWorkOrders={selectedWorkOrders}
        />
    )
    else return (
        <CustomML
            customMlDrawerVisible={customMlDrawerVisible}
            setCustomMlDrawerVisible={setCustomMlDrawerVisible}
            isRedirect={false}
            isMultiStage={false}
        />
    )
}

export default CustomMLWrapper;