import { Modal, Space, Typography, Input, Form, Spin, Row, Select } from 'antd';
import {
  CheckOutlined,
  ContainerOutlined,
  LoadingOutlined,
  TagsOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux'
import { deleteProjectRequest, updateProjectRequest } from '../../../store/actions/projects'
import { useEffect, useMemo, useState } from 'react'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { useForm } from 'antd/es/form/Form'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'

const { Text } = Typography

interface DeleteProjectProps {
  currentProject: any
  type: string
  disabled?: boolean
}


export const ProjectModal = ({ currentProject, type, disabled = false }: DeleteProjectProps) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const requiredFieldStar = useRequiredFieldStar()

  const deleteProjectStatus = useSelector((state: StoreState) => state.projects.deleteProjectStatus)
  const updateProjectStatus = useSelector((state: StoreState) => state.projects.updateProjectStatus)
  const projects = useSelector((state: StoreState) => state.projects.projectList)
  const lang = useSelector((state: StoreState) => state.language)
  const workOrders = useSelector((state: StoreState) => state.workOrders.data)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [value, setValue] = useState("")
  const [editTagForm] = useForm()

  useEffect(() => {
    if (deleteProjectStatus === AsyncStates.SUCCESS || updateProjectStatus === AsyncStates.SUCCESS) {
      setIsModalVisible(false)
    }
  }, [deleteProjectStatus, updateProjectStatus])

  const showModal = (e: any) => {
    e.stopPropagation()
    setIsModalVisible(true)
  }

  const handleOk = () => {
    if (type === "delete") {
      dispatch(deleteProjectRequest({ project_id: currentProject?.project_id, reason: value }))
    }
    else if (["archived", "completed", "in_progress"].includes(type)) {
      dispatch(updateProjectRequest({ project_id: currentProject?.project_id, reason: value, status: type }))
    }
    else if (type === "update_tags") {
      dispatch(updateProjectRequest({ project_id: currentProject?.project_id, tags: editTagForm.getFieldValue("tags"), from: type }))
    }
  }

  const handleCancel = (e: any) => {
    e.stopPropagation()
    setValue("")
    setIsModalVisible(false)
    editTagForm.resetFields()
  }

  const getButton = () => {
    switch (type) {
      case "delete": return (
        <StyledButton onClick={showModal} type="text" danger icon={<StyledDeleteIcon />} disabled={disabled} className={"project__dropdown__btn"}>
          {t("projects.deleteProject")}
        </StyledButton>
      )
      case "archived": return (
        <StyledButton onClick={showModal} type="text" icon={<ContainerOutlined />} disabled={currentProject?.status === "archived" || disabled} className={"project__dropdown__btn"}>
          {t("projects.archiveProject")}
        </StyledButton>
      )
      case "in_progress": return (
        <StyledButton onClick={showModal} icon={<ThunderboltOutlined />} type="text" disabled={currentProject?.status === "in_progress" || disabled}>
          {t("project.set.Inprogress")}
        </StyledButton>
      )
      case "completed": return (
        <StyledButton onClick={showModal} icon={<CheckOutlined />} type="text" disabled={currentProject?.status === "completed" || disabled}>
          {t("project.set.SetComplete")}
        </StyledButton>
      )
      case "update_tags": return (
        <StyledButton onClick={showModal} icon={<TagsOutlined />} type="text" disabled={currentProject?.status === "completed" || disabled} className={"project__dropdown__btn"}>
          {t("project.editTags")}
        </StyledButton>
      )
    }
  }

  const tagOptions = useMemo(() => {
    return ([...new Set(projects.flatMap((project) => project.tags))]?.filter((res) => res) ?? [])?.map((tag, idx) => ({
      value: tag,
      label: tag,
      key: `${tag}_${idx}`
    }))
  }, [projects])

  const getModal = () => {
    switch (type) {
      case "delete": return (
        <Modal zIndex={1050} width={450} maskClosable={false} confirmLoading={deleteProjectStatus === AsyncStates.LOADING}
          title={<Space style={{ width: "90%" }}>
            <Text type="secondary">{t("projects.confirmDelete")}</Text><Text strong>{`${currentProject?.name} ?`}</Text>
          </Space>}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin indicator={<LoadingOutlined />} spinning={deleteProjectStatus === AsyncStates.LOADING}>
            <Space direction="vertical" style={{ width: '100%' }} size="large" onClick={(e) => e.stopPropagation()}>
              <Text>{lang?.current!=="ja"&&"You are deleting"}<Text strong>{currentProject?.name}.</Text>{t("deleteProject.note")}</Text>
              <Form layout="vertical" onFinish={handleOk} requiredMark={false}>
                <Form.Item label={<Text>{"Message"}</Text>} required 
                  tooltip={requiredFieldStar}>
                  <Input onChange={(e) => setValue(e.target.value)} value={value} />
                </Form.Item>
                <Row justify="end" style={{ marginTop: 20 }}>
                  <Space>
                    <StyledButton onClick={handleCancel}>{t("common.cancel")}</StyledButton>
                    <StyledButton danger disabled={!value?.trim()} type="primary" htmlType="submit">{t("common.confirm")}</StyledButton>
                  </Space>
                </Row>
              </Form>
            </Space>
          </Spin>
        </Modal>
      )
      case "archived": return (
        <Modal zIndex={1050} width={450} maskClosable={false} confirmLoading={updateProjectStatus === AsyncStates.LOADING}
          title={<Space style={{ width: "90%" }}>
            <Text type="secondary">{t("project.confirmArchive")}
            </Text><Text strong>{`${currentProject?.name} ?`}</Text>
          </Space>}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          okButtonProps={{ type: "primary", disabled: !value?.trim() }}
        >
          <Spin indicator={<LoadingOutlined />} spinning={updateProjectStatus === AsyncStates.LOADING}>
            <Space direction="vertical" style={{ width: '100%' }} size="large" onClick={(e) => e.stopPropagation()}>
              <Text>{t("projects.archiving")}<Text strong>{" " + currentProject?.name}. </Text>{t("projects.confirmWorkOrdersArchive")}</Text>
              <Form layout="vertical" onFinish={handleOk} requiredMark={false}  >
                <Form.Item label={<Text>{t("formulation.message")}</Text>} rules={[{ required: true }]} required 
                  tooltip={requiredFieldStar}
                >
                  <Input onChange={(e) => setValue(e.target.value)} value={value} />
                </Form.Item>
                <Row justify="end" style={{ marginTop: 20 }}>
                  <Space>
                    <StyledButton onClick={handleCancel}>{t("common.cancel")}</StyledButton>
                    <StyledButton disabled={!value?.trim()} type="primary" htmlType="submit">{t("common.confirm")}</StyledButton>
                  </Space>
                </Row>
              </Form>
            </Space>
          </Spin>
        </Modal>
      )
      case "in_progress": return (
        <Modal
          zIndex={1050}
          width={450}
          maskClosable={false}
          confirmLoading={updateProjectStatus === AsyncStates.LOADING}
          title={
            <Space style={{ width: "90%" }}>
              <Text type="secondary">{t("common.set")} </Text>
              <Text strong>{currentProject?.name}</Text>
              <Text type="secondary">{t("common.asInProgress")}</Text>
            </Space>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          okButtonProps={{ type: "primary", disabled: !value?.trim() }}
        >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={updateProjectStatus === AsyncStates.LOADING}
          >
            <Space
              direction="vertical"
              style={{ width: "100%" }}
              size="large"
              onClick={(e) => e.stopPropagation()}
            >
              <Text>
                {`${t("projectModal.youAreSetting")} `}
                <Text strong>{currentProject?.name}</Text>
                {` ${t("projectModal.setProjectToProgress")}`}
              </Text>
              <Form layout="vertical" onFinish={handleOk}>
                <Form.Item
                  label={<Text>{t("formulation.message")}</Text>}
                  required
                >
                  <Input
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                  />
                </Form.Item>
                <Row justify="end" style={{ marginTop: 20 }}>
                  <Space>
                    <StyledButton onClick={handleCancel}>
                      {t("common.cancel")}
                    </StyledButton>
                    <StyledButton
                      disabled={!value?.trim()}
                      type="primary"
                      htmlType="submit"
                    >
                      {t("common.confirm")}
                    </StyledButton>
                  </Space>
                </Row>
              </Form>
            </Space>
          </Spin>
        </Modal>
      );
      case "completed": return (
        <Modal
          zIndex={1050}
          width={450}
          maskClosable={false}
          confirmLoading={updateProjectStatus === AsyncStates.LOADING}
          title={
            <Space style={{ width: "90%" }} onClick={(e) => e.stopPropagation()}>
              <Text type="secondary">{t("common.set")} </Text>
              <Text strong>{currentProject?.name}</Text>
              <Text type="secondary">{t("project.asComplete")}</Text>
            </Space>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={updateProjectStatus === AsyncStates.LOADING}
          >
            <Space
              direction="vertical"
              style={{ width: "100%" }}
              size="large"
              onClick={(e) => e.stopPropagation()}
            >
              <Text>
                {`${t("projectModal.youAreSetting")} `}
                <Text strong>{currentProject?.name}</Text>
                {` ${t('projectModal.setProjectToComplete')}`}
              </Text>
              <Form layout="vertical" onFinish={handleOk}>
                <Form.Item
                  label={<Text>{t("formulation.message")}</Text>}
                  required
                >
                  <Input
                    disabled={workOrders.some(
                      (res: any) => res.status !== "closed"
                    )}
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                  />
                </Form.Item>
                {workOrders.some((res: any) => res.status !== "closed") && (
                  <Text type="danger">
                    {
                      t('projectModal.closeWorkOrdersBeforeComplete')
                    }
                  </Text>
                )}
                <Row justify="end" style={{ marginTop: 20 }}>
                  <Space>
                    <StyledButton onClick={handleCancel}>
                      {t("common.cancel")}
                    </StyledButton>
                    <StyledButton
                      disabled={
                        !value?.trim() ||
                        workOrders.some((res: any) => res.status !== "closed")
                      }
                      type="primary"
                      htmlType="submit"
                    >
                      {t("common.confirm")}
                    </StyledButton>
                  </Space>
                </Row>
              </Form>
            </Space>
          </Spin>
        </Modal>
      );
      case "update_tags": return (
        <Modal zIndex={1200} width={450} maskClosable={false} confirmLoading={updateProjectStatus === AsyncStates.LOADING}
          title={null}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          okButtonProps={{ type: "primary" }}
        >
          <Spin indicator={<LoadingOutlined />} spinning={updateProjectStatus === AsyncStates.LOADING}>
            <Space direction="vertical" style={{ width: '100%' }} size="large" onClick={(e) => e.stopPropagation()}>
              <Space style={{ width: "90%" }}>
                <Text type="secondary">Edit Tags for</Text><Text strong>{`${currentProject?.name} ?`}</Text>
              </Space>
              <Form layout="vertical" onFinish={handleOk} form={editTagForm} initialValues={{ tags: currentProject?.tags ?? [] }}>
                <Form.Item
                  name="tags"
                  label={t("project.tags")}
                  required={false}
                >
                  <Select
                    mode="tags"
                    onSelect={(values) => {
                      const tags: string[] = editTagForm.getFieldValue("tags") ?? [];
                      editTagForm.setFieldsValue({
                        tags: tags.filter((tag, index) => {
                          const trimTag = tag.trim().toLocaleLowerCase()
                          return !!trimTag.length
                        }).map((tag) => tag.trim())
                      });
                    }
                    }
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      (triggerNode?.parentNode as HTMLElement) || document.body
                    }
                    placeholder={"Enter Tag(s) here"}
                    style={{ width: '100%' }}
                    options={tagOptions}
                    allowClear
                  />
                </Form.Item>
                <Row justify="end" style={{ marginTop: 20 }}>
                  <Space>
                    <StyledButton onClick={handleCancel}>{t("common.cancel")}</StyledButton>
                    <StyledButton type="primary" htmlType="submit">{t("common.confirm")}</StyledButton>
                  </Space>
                </Row>
              </Form>
            </Space>
          </Spin>
        </Modal>
      )
    }
  }


  return (
    <>
      {getButton()}
      {getModal()}
    </>
  )
}

