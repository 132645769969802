import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Space,
  Card,
  Form,
  Typography,
  Spin,
  notification,
  Popconfirm,
  Input,
  InputNumber,
  AutoComplete,
  Row,
  message,
  DatePicker,
  Collapse,
  Select,
  Tooltip,
  Col,
  Divider,
} from "antd";
import { CloseOutlined, EditOutlined, InfoCircleFilled, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { saveCelsureDataRequest, celsureEditTrialRequest, setCelsureEditingTrialsState, saveCelsureDataReset, celsureEditTrialReset } from "../../../store/actions/celsure"
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../store/configureStore'
import { AsyncStates } from "../../../constants"
import { geekblue, orange } from '@ant-design/colors'
import { DatasheetTable } from './DatasheetTable'
import { celsureGenerateOptionalFields as generateOptionalFields, getDropdownFilteredValue, mapFields } from "src/utils/decorator"
import dayjs from 'dayjs'
import useTranslate from 'src/utils/useTranslate'
import { View3D as PCM3DView, Table as PCMVisualizationTable } from '../Pcm3DVisualisation'
import { PCM } from '../Pcm3DVisualisation/types'
import { CharacterizationGraph } from './CharcaterizationUpload'
import { IstaProfiles } from './IstaProfiles'
import { StyledButton } from 'src/styled_components/StyledButton'
import { CustomFieldsModal } from 'src/components/Templates/CustomFieldsModal'
import { InventoryModal } from '../Inventory/InventoryModal'
import { useValue } from 'src/utils/useValue'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
const { Text } = Typography
const { Option } = Select
const { Panel } = Collapse

interface TrialsUploadProps {
  trialRecord: any
  setTrialRecord: React.Dispatch<React.SetStateAction<any>>
  form: any
}

export const TrialsUpload = ({ trialRecord, setTrialRecord, form }: TrialsUploadProps) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()

  const { formatter, parser } = useValue()
  const { current } = useSelector((state: StoreState) => state.projects)
  const { celsureWoTrials, celsureWo } = useSelector((state: StoreState) => state.celsureWo)
  const { saveDataStatus, celsureDisplayNamesStatus, celsureDisplayNamesData, celsureEditingTrialsState, celsureEditTrialStatus } = useSelector((state: StoreState) => state.celsure)
  const [payloadType, setPayloadType] = useState<string>("")
  const [waterTableData, setWaterTableData] = useState<any[]>([])
  const [insulationTable, setInsulationTable] = useState<any[]>([])
  const [pcmTableData, setPcmTableData] = useState<PCM[]>([])
  const [insulationThicknessData, setInsulationThicknessData] = useState<any[]>([])
  const [outerPackaging, setOuterPackaging] = useState<any[]>([])
  const [ambientTableData, setAmbientTableData] = useState<any[]>([])
  const [profileModalVisible, setProfileModalVisible] = useState<boolean>(false)
  const [profileId, setProfileId] = useState("")
  const [formState, setFormState] = useState<any>({})
  const [customModalVisible, setCustomModalVisible] = useState(false)
  const [customFields, setCustomFields] = useState<any[]>([])
  const [modalVisible, setModalVisible] = useState<any>({ visible: false, identifer: "" })

  const ambientGraph = useMemo(() => <CharacterizationGraph ambientTableData={ambientTableData} />, [ambientTableData])

  const getInitialValues = useCallback((field: any) => {
    if (trialRecord?.type !== "edit") {
      if (field.field_type === "date") {
        if (!!field?.value || !!field.default_value) {
          return dayjs(field.default_value)
        } else {
          return null
        }
      } else {
        return field?.value ?? field?.default_value
      }
    }
    else {
      if (field.field_type === "date") {
        if (!!field?.value || !!field.default_value) {
          return dayjs(field?.value ?? field.default_value)
        } else return null
      } else {
        return field?.value ?? field?.default_value
      }
    }
  }, [trialRecord])

  useEffect(() => {
    if (celsureEditTrialStatus === AsyncStates.SUCCESS || saveDataStatus === AsyncStates.SUCCESS) {
      dispatch(saveCelsureDataReset())
      dispatch(celsureEditTrialReset())
      setTrialRecord({})
      setPcmTableData([])
      setWaterTableData([])
      setInsulationTable([])
      setInsulationThicknessData([])
      setOuterPackaging([])
      setTrialRecord({})
      setAmbientTableData([])
      setCustomFields([])
      setFormState({})
      form.resetFields()
      setTimeout(() => document?.getElementById?.('wo-trials')?.scrollIntoView(), 200)
    }
  }, [dispatch, celsureEditTrialStatus, saveDataStatus, form, setTrialRecord])

  const setThickness = () => {
    const { payload_box_outer_x, payload_box_outer_y, payload_box_outer_z,
      payload_box_inner_x, payload_box_inner_y, payload_box_inner_z } = form.getFieldsValue()
    if (!!payload_box_outer_x && !!payload_box_outer_y && !!payload_box_outer_z &&
      !!payload_box_inner_x && !!payload_box_inner_y && !!payload_box_inner_z) {
      form.setFieldsValue({
        payload_thickness_value: (payload_box_outer_x - payload_box_inner_x) / 2,
      })
    }
  }

  const onPayloadInnerChange = () => {
    const { payload_box_inner_x, payload_box_inner_y, payload_box_inner_z, payload_thickness_value } = form.getFieldsValue()
    if (!!payload_box_inner_x && !!payload_box_inner_y && !!payload_box_inner_z && !!payload_thickness_value) {
      form.setFieldsValue({
        payload_box_outer_x: payload_box_inner_x + (2 * payload_thickness_value),
        payload_box_outer_y: payload_box_inner_y + (2 * payload_thickness_value),
        payload_box_outer_z: payload_box_inner_z + (2 * payload_thickness_value)
      })
    }
    setThickness()
  }

  const onPayloadOuterChange = () => {
    const { payload_thickness_value, payload_box_outer_x, payload_box_outer_y, payload_box_outer_z } = form.getFieldsValue()
    if (!!payload_box_outer_x && !!payload_box_outer_y && !!payload_box_outer_z && !!payload_thickness_value) {
      form.setFieldsValue({
        payload_box_inner_x: payload_box_outer_x - (2 * payload_thickness_value),
        payload_box_inner_y: payload_box_outer_y - (2 * payload_thickness_value),
        payload_box_inner_z: payload_box_outer_z - (2 * payload_thickness_value)
      })
    }
    setThickness()
  }

  const selectProfile = (e: any) => {
    const tempProfiles = celsureDisplayNamesData?.ista_profiles?.find((res: any) => res.profile_id === e)
    setProfileId(tempProfiles?.profile_id)
    setAmbientTableData(tempProfiles?.data)
  }

  const dropDownMenu = (menu: any, identifier: string) => (
    <div>
      {menu}
      <Divider style={{ margin: "4px 0" }} />
      <StyledButton
        onClick={() => setModalVisible({ visible: true, identifier })}
        type="link"
      >
        {t("common.newCustomParameter")}
      </StyledButton>
    </div>
  )

  useEffect(() => {
    if (!!trialRecord?.data?.trial_id && celsureWo?.work_order_stage === "formulation_stage") {
      const { data: { trial_display_id, trial_type, insulation_type, insulation_thickness, insulation_box_dimension, payload_type,
        payload_material_type, payload_material, payload_material_weight, pcm_type, insulation_sheets, water, comment, description,
        start_date, close_date, outer_packaging_materials, outer_packaging_dimension, outer_packaging,
        threshold_minimum_temperature, threshold_maximum_temperature, payload_initial_temperature, ambient, temperature_profile
      } } = trialRecord
      if (!!trialRecord?.data?.additional_fields?.length) {
        setCustomFields(trialRecord?.data?.additional_fields.map((res: any) => (res.field_type === "date" && (!!res?.value ?? !!res?.default_value)) ? ({ ...res, value: dayjs(res.value) }) : res))
        form.setFieldsValue({
          ...trialRecord?.data?.additional_fields?.reduce((acc: any, field: any) => ({
            ...acc, [field.field_name]: getInitialValues(field)
          }), {})
        })
      }
      setPcmTableData(pcm_type)
      setWaterTableData(water)
      setInsulationTable(insulation_sheets)
      setInsulationThicknessData(insulation_thickness)
      setOuterPackaging(outer_packaging)
      setPayloadType(payload_type?.type)
      form.setFieldsValue({
        trial_type: celsureDisplayNamesData?.trial_type?.[trial_type] || trial_type,
        trial_display_id: trialRecord?.type === "edit" ? trial_display_id : "",
        comment: comment,
        description: description,
        insulation_type: celsureDisplayNamesData?.insulation_type?.[insulation_type]?.name || insulation_type,
        insulation_box_dimensions_outer_x: insulation_box_dimension?.value?.[0],
        insulation_box_dimensions_outer_y: insulation_box_dimension?.value?.[1],
        insulation_box_dimensions_outer_z: insulation_box_dimension?.value?.[2],
        insulation_box_dimensions_outer_unit: insulation_box_dimension?.unit,
        outer_packaging_materials: celsureDisplayNamesData?.insulation_type?.[outer_packaging_materials]?.name || outer_packaging_materials,
        outer_packaging_dimension_x: outer_packaging_dimension?.value?.[0],
        outer_packaging_dimension_y: outer_packaging_dimension?.value?.[1],
        outer_packaging_dimension_z: outer_packaging_dimension?.value?.[2],
        outer_packaging_dimension_unit: outer_packaging_dimension?.unit,
        payload_material_type: celsureDisplayNamesData?.payload_material_type?.[payload_material_type] || payload_material_type,
        payload_type: celsureDisplayNamesData?.payload_type?.[payload_type?.type] || payload_type?.type,
        payload_box_inner_x: payload_type?.payload_box?.inner?.value?.[0],
        payload_box_inner_y: payload_type?.payload_box?.inner?.value?.[1],
        payload_box_inner_z: payload_type?.payload_box?.inner?.value?.[2],
        payload_box_inner_unit: payload_type?.payload_box?.inner?.unit,
        payload_thickness_value: payload_type?.payload_box?.thickness?.value,
        payload_thickness_unit: payload_type?.payload_box?.thickness?.unit,
        payload_box_outer_x: payload_type?.payload_box?.outer?.value?.[0],
        payload_box_outer_y: payload_type?.payload_box?.outer?.value?.[1],
        payload_box_outer_z: payload_type?.payload_box?.outer?.value?.[2],
        payload_box_outer_unit: payload_type?.payload_box?.outer?.unit,
        payload_volume_value: payload_type?.volume?.value,
        payload_volume_unit: payload_type?.volume?.unit,
        payload_material: celsureDisplayNamesData?.payload_material?.[payload_material?.[0]] || payload_material?.[0],
        payload_material_weight_value: payload_material_weight?.value,
        payload_material_weight_unit: payload_material_weight?.unit,
        close_date: !!close_date ? dayjs(close_date) : undefined,
        start_date: !!start_date ? dayjs(start_date) : undefined,
        threshold_minimum_temperature_value: threshold_minimum_temperature?.value || "",
        threshold_minimum_temperature_unit: threshold_minimum_temperature?.unit || "degC",
        threshold_maximum_temperature_value: threshold_maximum_temperature?.value || "",
        threshold_maximum_temperature_unit: threshold_maximum_temperature?.unit || "degC",
        payload_initial_temperature_value: payload_initial_temperature?.value,
        payload_initial_temperature_unit: payload_initial_temperature?.unit || "degC",
      })
      setAmbientTableData(ambient)
      setProfileId(temperature_profile || "")
      dispatch(setCelsureEditingTrialsState(true))
    }
  }, [dispatch, trialRecord, form, celsureWo, setPcmTableData, celsureDisplayNamesData, celsureWoTrials, getInitialValues])

  const submitCelsureForm = (values: any) => {
    const containsDuplicateTrials = celsureWoTrials?.find(({ trial_display_id }: any) => trial_display_id === values?.trial_display_id)
    if ((containsDuplicateTrials?.trial_id !== trialRecord?.data?.trial_id) && (!!containsDuplicateTrials?.length)) {
      return message.error("Trial IDs must be unique!.")
    }
    const additional_fields: any = []
    Object.entries(values || {}).forEach(([key, value]: any) => {
      const record = customFields.find((res: any) => res.field_name === key)
      if (!!record) {
        additional_fields.push({ ...record, value: (record?.field_type === "date" && (!!record?.value ?? !!record?.default_value)) ? dayjs(value).toISOString() : value })
      }
    })
    const {
      trial_type, trial_display_id, comment, description, insulation_type, outer_packaging_materials, insulation_box_dimensions_outer_unit,
      insulation_box_dimensions_outer_x, insulation_box_dimensions_outer_y, insulation_box_dimensions_outer_z,
      outer_packaging_dimension_x, outer_packaging_dimension_y, outer_packaging_dimension_z, outer_packaging_dimension_unit,
      payload_material_type,
      payload_type, payload_volume_value, payload_volume_unit, payload_box_inner_x, payload_box_inner_y, payload_box_inner_z,
      payload_box_inner_unit, payload_box_outer_x, payload_box_outer_y, payload_box_outer_z, payload_box_outer_unit,
      payload_thickness_value, payload_thickness_unit, payload_material, payload_material_weight_unit, payload_material_weight_value, close_date, start_date,
      threshold_minimum_temperature_value, threshold_minimum_temperature_unit, threshold_maximum_temperature_value,
      threshold_maximum_temperature_unit, payload_initial_temperature_value, payload_initial_temperature_unit
    } = values
    const payload = {
      project_id: current,
      work_order_id: celsureWo.work_order_id,
      trial_type,
      trial_display_id,
      comment,
      description,
      insulation_type: insulation_type.name || insulation_type,
      outer_packaging_materials,
      insulation_thickness: insulationThicknessData,
      outer_packaging: outerPackaging,
      ...(insulation_box_dimensions_outer_x && { insulation_box_dimension: { value: [insulation_box_dimensions_outer_x, insulation_box_dimensions_outer_y, insulation_box_dimensions_outer_z], unit: insulation_box_dimensions_outer_unit } }),
      ...(outer_packaging_dimension_x && { outer_packaging_dimension: { value: [outer_packaging_dimension_x, outer_packaging_dimension_y, outer_packaging_dimension_z], unit: outer_packaging_dimension_unit } }),
      payload_type: {
        volume: { value: payload_volume_value, unit: payload_volume_unit },
        type: payload_type,
        ...(payload_type && {
          payload_box: {
            inner: { value: [payload_box_inner_x, payload_box_inner_y, payload_box_inner_z], unit: payload_box_inner_unit },
            thickness: { value: payload_thickness_value, unit: payload_thickness_unit },
            outer: { value: [payload_box_outer_x, payload_box_outer_y, payload_box_outer_z], unit: payload_box_outer_unit },
          }
        })
      },
      ...(!!payload_material_type && { payload_material_type }),
      pcm_type: pcmTableData,
      water: waterTableData,
      insulation_sheets: insulationTable,
      ...(!!payload_material && { payload_material: [payload_material] }),
      payload_material_weight: { value: payload_material_weight_value, unit: payload_material_weight_unit },
      start_date: !!start_date ? start_date : null, close_date: !!close_date ? close_date : null,
      ambient: ambientTableData,
      temperature_profile: profileId,
      payload_initial_temperature: { value: payload_initial_temperature_value, unit: payload_initial_temperature_unit },
      threshold_minimum_temperature: { value: threshold_minimum_temperature_value, unit: threshold_minimum_temperature_unit },
      threshold_maximum_temperature: { value: threshold_maximum_temperature_value, unit: threshold_maximum_temperature_unit },
      additional_fields
    }
    if (trialRecord?.type === "edit") {
      dispatch(celsureEditTrialRequest({ ...trialRecord.data, ...payload, trial_id: trialRecord?.data?.trial_id }))
    } else {
      dispatch(saveCelsureDataRequest(payload))
    }
    setPayloadType("")
  }

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }} id="upload-form">
      <Spin spinning={saveDataStatus === AsyncStates.LOADING || celsureDisplayNamesStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
        <Card title={trialRecord?.type === "edit" ? <Space style={{ padding: 10, background: geekblue[1] }}
        ><Text type="secondary"><EditOutlined /></Text> {`Edit ${trialRecord.data?.trial_display_id}`}</Space> : "Create New Trial"}
          extra={<Space size="large">
            {celsureEditingTrialsState && <Text style={{ color: orange.primary }}>{t("common.unsavedChanges")}</Text>}
            {trialRecord?.type === "copy" && <Space style={{ padding: 10, background: geekblue[1] }}> <Text type="secondary">{`${t("trialsUpload.detailsCopied")} ${trialRecord?.data?.trial_display_id}`}</Text></Space>}
          </Space>}

          bodyStyle={{ padding: "24px 16px" }}
          headStyle={{ padding: "0px 16px" }}
        >
          <Form name="celsure-data-form" form={form}
            onFinish={submitCelsureForm}
            layout="vertical"
            requiredMark={false}
            wrapperCol={{ xs: { span: 24 }, sm: { span: 16 }, md: { span: 14 }, lg: { span: 14 } }}
            onFieldsChange={(event) => {
              if (event[0].name[0] === "insulation_type" && Object.values(celsureDisplayNamesData.insulation_type).find(({ name }: any) => name === event[0].value)) {
                const { meta }: any = Object.values(celsureDisplayNamesData.insulation_type)?.find(({ name }: any) => name === event[0].value)
                form.setFieldsValue({
                  ...(meta ? { insulation_r_value: meta?.r_value, insulation_mass_density: meta?.mass_density, insulation_specific_heat_capacity: meta?.specific_heat_capacity, insulation_thermal_conductivity: meta?.thermal_conductivity, insulation_phase: meta?.phase, insulation_color: meta?.color } : { insulation_r_value: '', insulation_mass_density: '', insulation_specific_heat_capacity: '', insulation_thermal_conductivity: '', insulation_phase: '', insulation_color: '' })
                })
              }
              if (event[0].name[0] === "outer_packaging_materials" && Object.values(celsureDisplayNamesData.insulation_type).find(({ name }: any) => name === event[0].value)) {
                const { meta }: any = Object.values(celsureDisplayNamesData.insulation_type)?.find(({ name }: any) => name === event[0].value)
                form.setFieldsValue({
                  ...(meta ? { outer_packaging_r_value: meta?.r_value, outer_packaging_mass_density: meta?.mass_density, outer_packaging_specific_heat_capacity: meta?.specific_heat_capacity, outer_packaging_thermal_conductivity: meta?.thermal_conductivity, outer_packaging_phase: meta?.phase, outer_packaging_color: meta?.color } : { outer_packaging_r_value: '', outer_packaging_mass_density: '', outer_packaging_specific_heat_capacity: '', outer_packaging_thermal_conductivity: '', outer_packaging_phase: '', outer_packaging_color: '' })
                })
              }
              if (!celsureEditingTrialsState) dispatch(setCelsureEditingTrialsState(true))
              setFormState(form.getFieldsValue())
            }}
            onFinishFailed={({ values, errorFields, outOfDate }) => {
              notification.error({ message: "Required fields are not entered" })
            }}>
            <Space direction="vertical" style={{ width: '100%', overflowX: "auto" }}>
              <Form.Item label="Trial Name" name="trial_display_id" rules={[{ required: true, message: t("common.required") }]}
                required
                tooltip={requiredFieldStar}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Trial type" name={"trial_type"} rules={[{ required: true, message: t("common.required") }]} initialValue={"Box"}
                required
                tooltip={requiredFieldStar}
              >
                <AutoComplete
                  showSearch
                  options={generateOptionalFields(celsureDisplayNamesData.trial_type)}
                />
              </Form.Item>
              <Form.Item required={false} label={t('workOrderDetails.startDate')} name="start_date">
                <DatePicker disabledDate={(curr) => curr > form.getFieldValue('close_date')} />
              </Form.Item>
              <Form.Item required={false} label={t("workOrderDetails.closeDate")} name="close_date">
                <DatePicker disabledDate={(curr) => curr < form.getFieldValue('start_date')} />
              </Form.Item>
              <Form.Item label={t("common.description")} name="description" >
                <Input />
              </Form.Item>
              <Form.Item label={t("common.comment")} name="comment">
                <Input.TextArea rows={5} />
              </Form.Item>
              <Card type="inner" title="Insulation" style={{ width: "100%" }}>
                <Space direction="vertical" size="small" style={{ width: "100%" }}>
                  <Form.Item label="Insulation type" name={"insulation_type"} rules={[{ required: true, message: t("common.required") }]}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <Select
                      showSearch
                      options={Object.values(celsureDisplayNamesData?.insulation_type || {})?.map(({ name }: any, index: number) => ({ value: name, label: name, key: name + String(index) }))}
                      filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}
                      dropdownRender={(menu) => dropDownMenu(menu, "insulation_type")}
                    />
                  </Form.Item>
                  {!!formState?.["insulation_type"] &&
                    <Collapse style={{ marginBottom: 10 }}>
                      <Panel key="insulation_type_collapse" header={"Insulation type metadata"}>
                        <Form.Item name="insulation_r_value" label="R-Value"  >
                          <Input readOnly />
                        </Form.Item>
                        <Form.Item name="insulation_mass_density" label="Mass Density (kg/m3)"  >
                          <InputNumber readOnly />
                        </Form.Item>
                        <Form.Item name="insulation_specific_heat_capacity" label="Specific Heat Capacity (J/(Kg K))"  >
                          <InputNumber readOnly />
                        </Form.Item>
                        <Form.Item name="insulation_thermal_conductivity" label="Thermal Conductivity (W/(m-K))"  >
                          <InputNumber readOnly />
                        </Form.Item>
                        <Form.Item name={"insulation_phase"} label={"Phase"}  >
                          <Input readOnly />
                        </Form.Item>
                        <Form.Item name="insulation_color" label={"Color"}  >
                          <Input readOnly />
                        </Form.Item>
                      </Panel>
                    </Collapse>
                  }
                  <DatasheetTable data={insulationThicknessData} setData={setInsulationThicknessData}
                    title={"Insulation Thickness"} type={"insulation_thickness"} />
                  <Form.Item label="Insulation Box Dimensions (Outer)" style={{ marginBottom: 0 }} required
                    tooltip={requiredFieldStar}>
                    <Space size="small">
                      <Form.Item name="insulation_box_dimensions_outer_x" initialValue={550} rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Text><CloseOutlined style={{ marginBottom: 25 }} /></Text>
                      <Form.Item name="insulation_box_dimensions_outer_y" initialValue={530} rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Text><CloseOutlined style={{ marginBottom: 25 }} /></Text >
                      <Form.Item name="insulation_box_dimensions_outer_z" initialValue={500} rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item name={"insulation_box_dimensions_outer_unit"} rules={[{ required: true, message: t("common.required") }]} initialValue={"mm"}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <AutoComplete
                          showSearch style={{ width: 80 }}
                          options={generateOptionalFields(celsureDisplayNamesData?.unit?.dimension)}
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                </Space>
              </Card>
              <Card type="inner" title="Outer Packaging" style={{ marginBottom: 20, width: "100%" }}>
                <Space direction="vertical" size="small" style={{ width: "100%" }}>
                  <Form.Item label="Outer Packaging Materials" name={"outer_packaging_materials"} rules={[{ required: true, message: t("common.required") }]}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <Select
                      showSearch
                      options={Object.values(celsureDisplayNamesData?.insulation_type || {})?.map(({ name }: any, index: number) => ({ value: name, label: name + String(index) }))}
                      filterOption={(inputValue, options: any) => getDropdownFilteredValue(inputValue, options)}
                      dropdownRender={(menu) => dropDownMenu(menu, "insulation_type")}
                    />
                  </Form.Item>
                  {!!formState?.["outer_packaging_materials"] &&
                    <Collapse style={{ marginBottom: 10 }}>
                      <Panel key="outer_packaging_collapse" header={"Outer Packaging Metadata"}>
                        <Form.Item name="outer_packaging_r_value" label="R-Value"  >
                          <Input readOnly />
                        </Form.Item>
                        <Form.Item name="outer_packaging_mass_density" label="Mass Density (kg/m3)"  >
                          <InputNumber readOnly />
                        </Form.Item>
                        <Form.Item name="outer_packaging_specific_heat_capacity" label="Specific Heat Capacity (J/(Kg K))"  >
                          <InputNumber readOnly />
                        </Form.Item>
                        <Form.Item name="outer_packaging_thermal_conductivity" label="Thermal Conductivity (W/(m-K))"  >
                          <InputNumber readOnly />
                        </Form.Item>
                        <Form.Item name={"outer_packaging_phase"} label={"Phase"}  >
                          <Input readOnly />
                        </Form.Item>
                        <Form.Item name="outer_packaging_color" label={"Color"}  >
                          <Input readOnly />
                        </Form.Item>
                      </Panel>
                    </Collapse>
                  }
                  <DatasheetTable data={outerPackaging} setData={setOuterPackaging} title={"Outer Packaging Details"} type={"outer_packaging"} />
                  <Form.Item label="Outer Packaging Dimensions" style={{ marginBottom: 0 }} required
                    tooltip={requiredFieldStar}>
                    <Space size="small">
                      <Form.Item name="outer_packaging_dimension_x" rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Text><CloseOutlined style={{ marginBottom: 25 }} /></Text>
                      <Form.Item name="outer_packaging_dimension_y" rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Text><CloseOutlined style={{ marginBottom: 25 }} /></Text >
                      <Form.Item name="outer_packaging_dimension_z" rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item name={"outer_packaging_dimension_unit"} rules={[{ required: true, message: t("common.required") }]} initialValue={"mm"}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <AutoComplete
                          showSearch style={{ width: 80 }}
                          options={generateOptionalFields(celsureDisplayNamesData?.unit?.dimension)}
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>

                </Space>
              </Card>
              <Card type="inner" title="Payload" style={{ marginBottom: 20, width: "100%" }}>
                <Space direction="vertical" size="small" style={{ width: "100%" }}>
                  <Form.Item label="Payload Volume" style={{ marginBottom: 0 }}
                    required
                    tooltip={requiredFieldStar}
                  >
                    <Space size="small">
                      <Form.Item name="payload_volume_value" rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item name={"payload_volume_unit"} rules={[{ required: true, message: t("common.required") }]} initialValue={"L"}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <AutoComplete
                          showSearch style={{ width: 80 }}
                          options={generateOptionalFields(celsureDisplayNamesData?.unit?.dimension)}
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>

                  <Form.Item label={t("common.type")} name="payload_type">
                    <Input onChange={(e) => setPayloadType(e.target.value)} />
                  </Form.Item>

                  {!!payloadType?.trim() &&
                    <>
                      <Form.Item label="Payload Box (Outer)" style={{ marginBottom: 0 }} required
                        tooltip={requiredFieldStar}>
                        <Space size="small">
                          <Form.Item name="payload_box_outer_x" rules={[{ required: true, message: t("common.required") }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <InputNumber min={0} onChange={onPayloadOuterChange} />
                          </Form.Item>
                          <Text><CloseOutlined style={{ marginBottom: 25 }} /></Text>
                          <Form.Item name="payload_box_outer_y" rules={[{ required: true, message: t("common.required") }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <InputNumber min={0} onChange={onPayloadOuterChange} />
                          </Form.Item>
                          <Text><CloseOutlined style={{ marginBottom: 25 }} /></Text>
                          <Form.Item name="payload_box_outer_z" rules={[{ required: true, message: t("common.required") }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <InputNumber min={0} onChange={onPayloadOuterChange} />
                          </Form.Item>
                          <Form.Item name={"payload_box_outer_unit"}
                            rules={[{ required: true, message: t("common.required") }]}
                            required
                            tooltip={requiredFieldStar}
                            initialValue={"mm"}>
                            <AutoComplete
                              showSearch style={{ width: 80 }}
                              options={generateOptionalFields(celsureDisplayNamesData?.unit?.dimension)}
                            />
                          </Form.Item>
                        </Space>
                      </Form.Item>

                      <Form.Item label="Payload Box (Inner)" style={{ marginBottom: 0 }} required
                        tooltip={requiredFieldStar}>
                        <Space size="small">
                          <Form.Item name="payload_box_inner_x" rules={[{ required: true, message: t("common.required") }]} required
                            tooltip={requiredFieldStar}>
                            <InputNumber min={0} onChange={onPayloadInnerChange} />
                          </Form.Item>
                          <Text><CloseOutlined style={{ marginBottom: 25 }} /></Text>
                          <Form.Item name="payload_box_inner_y" rules={[{ required: true, message: t("common.required") }]} required
                            tooltip={requiredFieldStar}>
                            <InputNumber min={0} onChange={onPayloadInnerChange} />
                          </Form.Item>
                          <Text><CloseOutlined style={{ marginBottom: 25 }} /></Text>
                          <Form.Item name="payload_box_inner_z" rules={[{ required: true, message: t("common.required") }]} required
                            tooltip={requiredFieldStar}>
                            <InputNumber min={0} onChange={onPayloadInnerChange} />
                          </Form.Item>
                          <Form.Item name={"payload_box_inner_unit"}
                            rules={[{ required: true, message: t("common.required") }]}
                            required
                            tooltip={requiredFieldStar}
                            initialValue={"mm"}>
                            <AutoComplete
                              showSearch style={{ width: 80 }}
                              options={generateOptionalFields(celsureDisplayNamesData?.unit?.dimension)}
                            />
                          </Form.Item>
                        </Space>
                      </Form.Item>

                      <Form.Item label="Payload Box (Thickness)" style={{ marginBottom: 0 }} required
                        tooltip={requiredFieldStar}>
                        <Space size="small">
                          <Form.Item name="payload_thickness_value" rules={[{ required: true, message: t("common.required") }]}
                            required
                            tooltip={requiredFieldStar}
                          >
                            <InputNumber min={0} onChange={() => {
                              onPayloadOuterChange()
                              onPayloadInnerChange()
                            }} />
                          </Form.Item>
                          <Form.Item name={"payload_thickness_unit"}
                            rules={[{ required: true, message: t("common.required") }]}
                            required
                            tooltip={requiredFieldStar}
                            initialValue={"mm"}>
                            <AutoComplete style={{ width: 80 }}
                              showSearch
                              options={generateOptionalFields(celsureDisplayNamesData?.unit?.dimension)}
                            />
                          </Form.Item>  </Space>
                      </Form.Item>
                    </>}
                  <Form.Item label="Dummy Payload" name={"payload_material"} >
                    <AutoComplete
                      showSearch
                      options={generateOptionalFields(celsureDisplayNamesData?.payload_material)}
                      filterOption={(inputValue, options: any) =>
                        getDropdownFilteredValue(inputValue, options)
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Dummy Payload Weight" style={{ marginBottom: 0 }} required
                    tooltip={requiredFieldStar}>
                    <Space size="small">
                      <Form.Item name="payload_material_weight_value" rules={[{ required: true, message: t("common.required") }]}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                      <Form.Item name={"payload_material_weight_unit"} rules={[{ required: true, message: t("common.required") }]} initialValue={"grams"}
                        required
                        tooltip={requiredFieldStar}
                      >
                        <AutoComplete
                          showSearch style={{ width: 80 }}
                          options={generateOptionalFields(celsureDisplayNamesData?.unit?.weight)}
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                </Space>
              </Card>

              {/*  CELSURE 3D BOX HERE */}
              <PCMVisualizationTable data={pcmTableData} setData={setPcmTableData} setModalVisible={setModalVisible} />
              <PCM3DView data={pcmTableData} />

              <DatasheetTable data={waterTableData} setData={setWaterTableData} title={"Water and material"} type={"water"} setModalVisible={setModalVisible} />

              <DatasheetTable data={insulationTable} setData={setInsulationTable} title={"Insulation Sheets"} type={"insulation_sheets"} setModalVisible={setModalVisible} />

              <Card>
                <Row justify='end' style={{ marginBottom: 10 }}>
                  <Space>
                    <Select placeholder="Select Profile" onSelect={selectProfile}>
                      {celsureDisplayNamesData?.ista_profiles?.map((res: any) => (
                        <Option key={res.profile_id} value={res.profile_id}>{res.name}</Option>
                      ))}
                    </Select>
                    <StyledButton onClick={() => setProfileModalVisible(true)}>{t("celsure.create/EditProfiles")}</StyledButton>
                  </Space>
                </Row>
                <DatasheetTable data={ambientTableData} setData={setAmbientTableData} type={"ambient"} title={"Ambient Time and Temperature"} />
                {!!ambientTableData?.length &&
                  <Collapse defaultActiveKey={["temperature-plot"]}>
                    <Panel key="temperature-plot" header="Ambient Time vs Temperature Graph">
                      <Row justify="center">
                        {ambientGraph}
                      </Row>
                    </Panel>
                  </Collapse>}

                <Form.Item label="Payload initial temperature" style={{ marginBottom: 0, marginTop: 50 }} required
                  tooltip={requiredFieldStar}>
                  <Space size="small">
                    <Form.Item name="payload_initial_temperature_value" rules={[{ required: true, message: "required" }]} required
                      tooltip={requiredFieldStar}>
                      <InputNumber />
                    </Form.Item>
                    <Form.Item name={"payload_initial_temperature_unit"} rules={[{ required: true, message: "required" }]} initialValue={"degC"} required
                      tooltip={requiredFieldStar}>
                      <AutoComplete
                        showSearch style={{ width: 80 }}
                        options={generateOptionalFields(celsureDisplayNamesData?.unit?.temperature)}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>

                <Form.Item label="Threshold minimum temperature" style={{ marginBottom: 0 }} required
                  tooltip={requiredFieldStar}>
                  <Space size="small">
                    <Form.Item name="threshold_minimum_temperature_value" rules={[{ required: true, message: "required" }, ({ getFieldValue }) => ({
                      validator(_, value) {
                        const threshold_maximum_temperature = getFieldValue('threshold_maximum_temperature_value')
                        if (value && threshold_maximum_temperature && value > threshold_maximum_temperature) {
                          return Promise.reject(new Error('Minimum should be less than maximum'))
                        }
                        return Promise.resolve()
                      },
                    })]}

                      required
                      tooltip={requiredFieldStar}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item name={"threshold_minimum_temperature_unit"} rules={[{ required: true, message: "required" }]} initialValue={"degC"}>
                      <AutoComplete
                        showSearch style={{ width: 80 }}
                        options={generateOptionalFields(celsureDisplayNamesData?.unit?.temperature)}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>

                <Form.Item label="Threshold maximum temperature" style={{ marginBottom: 0 }} required
                  tooltip={requiredFieldStar}>
                  <Space size="small">
                    <Form.Item name="threshold_maximum_temperature_value" rules={[{ required: true, message: "required" }, ({ getFieldValue }) => ({
                      validator(_, value) {
                        const threshold_minimum_temperature = getFieldValue('threshold_minimum_temperature_value')
                        if (value && threshold_minimum_temperature && value < threshold_minimum_temperature) {
                          return Promise.reject(new Error('Maximum should be more than minimum'))
                        }
                        return Promise.resolve()
                      },
                    })]}
                      required
                      tooltip={requiredFieldStar}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item name={"threshold_maximum_temperature_unit"} rules={[{ required: true, message: "required" }]} initialValue={"degC"}
                      required
                      tooltip={requiredFieldStar}
                    >
                      <AutoComplete
                        showSearch style={{ width: 80 }}
                        options={generateOptionalFields(celsureDisplayNamesData?.unit?.temperature)}
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Card>

              <Collapse defaultActiveKey={"custom"}>
                <Panel
                  header={
                    <Space>
                      <Text strong>{t("projects.customFields")}</Text>
                      <Tooltip title={t("project.customFields.message")}>
                        {" "}
                        <InfoCircleFilled />
                      </Tooltip>{" "}
                    </Space>
                  }
                  key="custom"
                >
                  <Row>
                    <Col span={14}>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        {customFields.map((field: any) => (
                          <Space>
                            <Popconfirm
                              title={t("common.removeField")}
                              onConfirm={() => {
                                setCustomFields((prevState: any) =>
                                  prevState.filter(
                                    (res: any) =>
                                      res?.field_name !== field?.field_name
                                  )
                                );
                              }}
                            >
                              <StyledButton
                                style={{ marginTop: 4 }}
                                icon={<MinusCircleOutlined />}
                                type="text"
                              ></StyledButton>
                            </Popconfirm>
                            <Form.Item
                              name={field.field_name}
                              label={field.field_name}
                              initialValue={getInitialValues(field)}
                              rules={[
                                {
                                  required: field.required,
                                  message: "required",
                                  transform: (value: any) => value?.trim(),
                                },
                              ]}
                            >
                              {mapFields(field, false, formatter, parser)}
                            </Form.Item>
                          </Space>
                        ))}
                      </Space>
                    </Col>
                  </Row>
                  <StyledButton
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => setCustomModalVisible(true)}
                  >
                    <Text type="secondary">
                      {t("common.AddCustomField")}
                    </Text>
                  </StyledButton>
                </Panel>
              </Collapse>

              <Row justify='center' style={{ marginTop: 100 }}>
                <Space>
                  <StyledButton style={{ width: 300, paddingTop: 10, paddingBottom: 30 }} type="primary" htmlType="submit">{t("common.submit")}</StyledButton>
                  <Popconfirm title={"Sure to reset data? unsaved changes will be lost"}
                    onConfirm={() => {
                      dispatch(setCelsureEditingTrialsState(false))
                      setPcmTableData([])
                      setTrialRecord({})
                      setWaterTableData([])
                      setInsulationTable([])
                      setInsulationThicknessData([])
                      setOuterPackaging([])
                      setAmbientTableData([])
                      setCustomFields([])
                      setFormState({})
                      form.resetFields()
                    }}>
                    <StyledButton style={{ width: 100, paddingTop: 10, paddingBottom: 30 }} type="dashed" >{"Reset"}</StyledButton>
                  </Popconfirm>
                </Space>
              </Row>
            </Space>
          </Form>
        </Card>
      </Spin>
      <IstaProfiles profileModalVisible={profileModalVisible} setProfileModalVisible={setProfileModalVisible} />
      <CustomFieldsModal
        customModalVisible={customModalVisible}
        setCustomModalVisible={setCustomModalVisible}
        customFields={customFields}
        setCustomFields={setCustomFields}
        type="celsure_formulations"
      />
      <InventoryModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
    </Space>
  );
}