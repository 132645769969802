
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`プラケット・バーマン計画`}</h1>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Plackett%E2%80%93Burman_design"
      }}>{`プラケット・バーマン計画（Plackett-Burman）`}</a>{`は、実験における重要な因子を見つけるのに役立ちます（スクリーニング計画の一種です）。この計画では、重要でない因子（ノイズ）を除外するため、相対的に重要でない因子について大量のデータを収集する必要がありません。`}</p>
    <p>{`1946年にR.L.プラケットとJ.P.バーマンが「The Design of Optimal Multifactorial Experiments」という有名な論文をBiometrika（vol. 33）に発表しました。この論文では、2の累乗ではなく4の倍数となる非常に経済的な設計の構築方法が説明されています。プラケット・バーマン計画は、主効果のみが関心の対象である場合に非常に効率的なスクリーニング計画となります。`}</p>
    <p>{`プラケット・バーマン（PB）計画は、スクリーニング実験に使用されることが多いです。PB計画では、一般的に主効果は2因子相互作用と強く相関しています。例えば、12回の実験で11つの因子を含む実験に使用することができます（4つ、5つ、6つ、または7つの因子がある場合は8回の実験が必要で、8つ、9つ、10つ、または11つの因子がある場合は12回の実験が必要です）。`}</p>
    <p><strong parentName="p">{`使用例：`}</strong></p>
    <ul>
      <li parentName="ul">{`スクリーニングの場合`}</li>
      <li parentName="ul">{`高次相互作用を無視できる場合`}</li>
      <li parentName="ul">{`2レベルの多因子実験の場合`}</li>
      <li parentName="ul">{`因子が4つ以上ある場合（2〜4つの変数がある場合は完全要因実験が行えます）`}</li>
      <li parentName="ul">{`大きな主効果を経済的に検出したい場合`}</li>
      <li parentName="ul">{`N = 12, 20, 24, 28, 36 の場合（N = 実験数）`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;