
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`완전 요인설계법`}</h1>
    <p>{`모든 입력 요인의 모든 가능한 조합으로 이루어진 설계를 완전요인설계법 실험이라고 합니다.`}</p>
    <p>{`완전요인설계법에서의 실험 설계 지점들은 각 요인의 '수준' 값에 의해 정의된 n차원 설계 공간에서 하이퍼큐브의 꼭짓점입니다. 이러한 실험 지점들은 팩토리얼 지점이라고도 합니다. 예를 들어, 세 가지 요인 각각이 네 수준을 가지는 경우, 완전 요인설계법을 고려한다면 총 4³ (64)개의 실험을 수행해야 합니다.`}</p>
    <p><strong parentName="p">{`사용 사례:`}</strong></p>
    <ul>
      <li parentName="ul">{`요인이 두 수준보다 많을 때 요인설계법을 사용할 수 있습니다. 그러나 세 수준 (또는 그 이상)의 요인설계법을 위해 필요한 실험 실행 횟수는 두 수준의 대응물보다 상당히 많을 것입니다`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;