import { Image, Menu, notification, Row, Select, Space, Tabs, Tooltip, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtractionsRequest,
  updateExtractionTableRequest,
} from "src/store/actions/polyGPT";
import { StoreState } from "src/store/configureStore";
import EditableTable from "./EditableTable";
import { GPTConversationStatus } from "../enums";
import { AsyncStates } from "src/constants";
import { DownloadOutlined, EyeOutlined, FileAddOutlined, FolderAddOutlined } from "@ant-design/icons";
import { StyledButton } from "src/styled_components/StyledButton";
import SelectAssetModal from "src/components/AssetLibrary/SelectAssetModal";
import { defaultHeaders } from 'src/constants'
import jwtManager from 'src/utils/jwtManager'


const { Title, Paragraph } = Typography;

export const GPTExtractionTab = () => {
  const dispatch = useDispatch();

  const { currentConversation, getExtractions, updateExtractionTable } =
    useSelector((state: StoreState) => state.polyGpt);

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [selectedTable, setSelectedTable] = useState<any>(null);

  const [addToAssetLibraryModalVisible, setAddToAssetLibraryModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (
      currentConversation &&
      currentConversation?.extraction_status === GPTConversationStatus.COMPLETED
    ) {
      dispatch(
        getExtractionsRequest({
          conversation_id: currentConversation?.conversation_id,
        })
      );
    }
  }, [dispatch, currentConversation]);

  const selectedFilesExtractions = useMemo(() => {
    let combinedExtractions = {
      tables_info: [],
      images_info: [],
    };
    if (selectedFiles.length > 0) {
      getExtractions.data?.data
        ?.filter((ext: any, index: number) =>
          selectedFiles.includes(ext.file_name)
        )
        .forEach((fileExtraction: any) => {
          const fileTableInfo = fileExtraction.extractions.reduce(
            (accumulator: any[], currentValue: any) => {
              return accumulator.concat([
                ...currentValue.tables_info.map((table: any) => {
                  return {
                    ...table,
                    page_no: currentValue.page_no,
                    file_name: fileExtraction.file_name,
                  };
                }),
              ]);
            },
            []
          );
          const fileImagesInfo = fileExtraction.extractions.reduce(
            (accumulator: any[], currentValue: any) => {
              return accumulator.concat(currentValue.images_info);
            },
            []
          );
          combinedExtractions.tables_info =
            combinedExtractions.tables_info.concat(fileTableInfo);

          combinedExtractions.images_info =
            combinedExtractions.images_info.concat(fileImagesInfo);
        });
    }

    return combinedExtractions;
  }, [selectedFiles, getExtractions.data?.data]);

  const columns = useMemo(() => {
    return selectedTable
      ? Object.keys(selectedTable?.table[0]).map((key) => ({
        title: key,
        dataIndex: key,
        key: key,
        editable: true,
      }))
      : [];
  }, [selectedTable]);

  useEffect(() => {
    setSelectedFiles([]);
    setSelectedTable(null);
  }, [currentConversation]);

  const handleSave = () => {
    const updatedTable = [...selectedTable.table];
    updatedTable.forEach((obj: any) => {
      delete obj["key"];
    });
    const payload = {
      conversation_id: currentConversation?.conversation_id,
      file_name: selectedTable?.file_name,
      page_no: selectedTable?.page_no,
      table_id: selectedTable?.table_id,
      updated_table: updatedTable,
    };
    dispatch(updateExtractionTableRequest(payload));
  };

  const fileOptions = useMemo(() => {
    return getExtractions.data?.data?.map((ext: any) => ({
      label: ext.file_name,
      value: ext.file_name,
    }));
  }, [getExtractions.data?.data]);

  useEffect(() => {
    fileOptions?.[0]?.value && setSelectedFiles([fileOptions?.[0]?.value]);
  }, [fileOptions]);

  useEffect(() => {
    if (selectedFilesExtractions.tables_info?.[0]) {
      setSelectedTable(
        selectedFilesExtractions.tables_info?.[0]
      )
    }
  }, [selectedFilesExtractions.tables_info])

  const {
    assetLibraryFolderNavigateSelectedDirectory,
    assetLibraryFolderNavigateCurrentDirectory
  } = useSelector((state: StoreState) => state.assetLibrary)

  const [assetToBeAdded, setAssetToBeAdded] = useState()

  const linkAsset = useCallback((name: string | undefined) => {
    if (assetToBeAdded) {
      const ext = new URL(assetToBeAdded).pathname.split(".").at(-1)
      fetch(`${process.env.REACT_APP_API_URL}v1/data/asset_library/create_file?parent_id=${assetLibraryFolderNavigateSelectedDirectory || assetLibraryFolderNavigateCurrentDirectory}&file_type=${"link"}&file_url=${assetToBeAdded}&name=${name}.${ext}`,
        {
          method: "POST",
          headers: {
            ...defaultHeaders,
            token: jwtManager.getToken() ?? "",
          },
        }
      )
        .then(res => res.json())
        .then(res => {
          if (res.result.status === 'Success') {
            setAddToAssetLibraryModalVisible(false)
          } else {
            notification.error({ message: res.result.message });
          }
        })
        .catch(err => {
          notification.error({ message: "An error ocurred." });
        })
    }
  }, [assetLibraryFolderNavigateCurrentDirectory, assetLibraryFolderNavigateSelectedDirectory, assetToBeAdded])

  return (
    <>
      {currentConversation?.extraction_status === "in_progress" ? (
        <div
          className="file-upload-status"
          style={{
            padding: "10px",
          }}
        >
          File extraction in progress. Please try again in 10-15 mins
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            <Typography.Title level={5} style={{ marginRight: "10px" }}>File(s):</Typography.Title>
            <Select
              key={currentConversation?.conversation_id}
              mode="multiple"
              maxTagTextLength={10}
              style={{ width: "50%" }}
              value={selectedFiles}
              options={fileOptions}
              onChange={(value: string[]) => {
                setSelectedFiles(value);
              }}
            />
          </div>
          <Tabs type="card" size="small" defaultActiveKey="images">
            <Tabs.TabPane tab="Images" key="images">
              <div
                style={{ height: "65vh", overflow: "auto", paddingTop: "10px" }}
              >
                <Image.PreviewGroup>
                  {selectedFilesExtractions.images_info?.map((imgUrl: any) => (
                    <Image width={200} src={imgUrl} preview={{
                      mask: (
                        <Space direction="vertical" align="center">
                          <StyledButton ghost size="small" icon={<EyeOutlined />}>Preview</StyledButton>
                          <StyledButton ghost size="small" icon={<FolderAddOutlined />} onClick={(e) => { e.stopPropagation(); setAddToAssetLibraryModalVisible(true); setAssetToBeAdded(imgUrl) }}>Add to library</StyledButton>
                        </Space>
                      )
                    }} />
                  ))}
                </Image.PreviewGroup>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Tables" key="tables">
              {selectedFilesExtractions.tables_info?.length > 0 && (
                <>
                  <Menu
                    onClick={(e: any) => {
                      setSelectedTable(
                        selectedFilesExtractions.tables_info[e.key]
                      );
                    }}
                    mode="horizontal"
                    selectedKeys={[String(Number(selectedTable?.page_no) - 1)]}
                  >
                    {selectedFilesExtractions.tables_info?.map(
                      (item: any, index: number) => (
                        <Menu.Item key={index}>{index + 1}</Menu.Item>
                      )
                    )}
                  </Menu>
                  <div
                    style={{
                      height: "65vh",
                      overflow: "auto",
                      paddingTop: "10px",
                    }}
                  >
                    {selectedTable !== null && (
                      <div style={{ marginBottom: "50px", display: 'flex', flexDirection: 'column', gap: 8 }}>
                        <Title level={4}>{selectedTable.name}</Title>
                        <Paragraph>{selectedTable.explanation}</Paragraph>
                        <Image
                          src={selectedTable.image_url}
                          style={{ maxWidth: "100%" }}
                          preview={{
                            mask: (
                              <Space direction="vertical" align="center">
                                <StyledButton ghost size="small" icon={<EyeOutlined />}>Preview</StyledButton>
                                <StyledButton ghost size="small" icon={<FolderAddOutlined />} onClick={(e) => { e.stopPropagation(); setAddToAssetLibraryModalVisible(true); setAssetToBeAdded(selectedTable.image_url) }}>Add to library</StyledButton>
                              </Space>
                            )
                          }}
                        />
                        {selectedTable.table && (
                          <>
                            <Row justify={"end"} align={"middle"} style={{
                              gap: 8
                            }} >
                              {
                                !!selectedTable.excel_url &&
                                <StyledButton size="small" onClick={() => window.open(selectedTable.excel_url)} icon={<DownloadOutlined />} />
                              }
                              <Tooltip title="Add to asset library">
                                <StyledButton size="small" onClick={() => { setAddToAssetLibraryModalVisible(true); setAssetToBeAdded(selectedTable?.excel_url) }} icon={<FileAddOutlined />} />
                              </Tooltip>
                              <StyledButton
                                loading={
                                  updateExtractionTable.status ===
                                  AsyncStates.LOADING
                                }
                                type="primary"
                                onClick={handleSave}
                                size="small"
                              >
                                Save
                              </StyledButton>
                            </Row>
                            <EditableTable
                              columns={columns}
                              datasource={selectedTable.table.map(
                                (row: any, index: number) => {
                                  return {
                                    ...row,
                                    key: index,
                                  };
                                }
                              )}
                              updateDatasource={(updatedData: any) => {
                                setSelectedTable({
                                  ...selectedTable,
                                  table: updatedData,
                                });
                              }}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </Tabs.TabPane>
          </Tabs>
          {addToAssetLibraryModalVisible && <SelectAssetModal
            heading="Add to Asset Library"
            type="folders"
            open={addToAssetLibraryModalVisible}
            setOpen={setAddToAssetLibraryModalVisible}
            onAssetSelect={linkAsset}
            withName={true}
          />}
        </>
      )}
    </>
  );
};
