import { Space, Table } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useTranslate from 'src/utils/useTranslate'
import Text from "antd/lib/typography/Text";
import { useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';
import { getLinkedTableCellData } from 'src/utils/decorator';
import { AsyncStates } from 'src/constants';
import { FormulationDatum } from 'src/services/formulation/interface';
import { useValue } from 'src/utils/useValue';
import { LoadingOutlined } from '@ant-design/icons';

export const LinkedTrialsAiEngine = ({ record, experiments, linkedFormulationDetailsData, from, selectedStages, pageNumber }: any) => {
    const [t] = useTranslate()
    const { getValue } = useValue()
    const ingredientsLabels = useSelector((state: StoreState) => state.displayNames.data?.ingredients || {})
    const { linkedFormulationsStatus } = useSelector((state: StoreState) => state.common)

    const linkedTrialData: FormulationDatum[] = useMemo(() => Object.values(linkedFormulationDetailsData ?? {}), [linkedFormulationDetailsData])
    const columnsTrialData = useMemo(() => from === "data-summary" ? experiments?.filter((experiment: any) => Object.keys(record || {}).includes(experiment.formulation_id)) : experiments, [experiments, record, from])


    const [status, setStatus] = useState<AsyncStates>(AsyncStates.LOADING)

    useEffect(() => {
        if (linkedFormulationsStatus === AsyncStates.SUCCESS) {
            setStatus(linkedFormulationsStatus)
        }
    }, [linkedFormulationsStatus])


    const calculateConvertedProportion = useCallback((name: any, formulationId: any) => {
        const currentValue = Number(record?.[formulationId])
        const linkedTrial = linkedTrialData.find((res: any) => res?.id_set?.formulation_id === record?.linked_trial)

        const linkedTrialValue = Number(linkedTrial?.ingredients?.[name]?.value)

        // let linkedTrialTotal = 0
        // let categoricalTrialValue = ""
        // Object.values(linkedTrial?.ingredients || {}).forEach((res: any) => {
        //     if (!isNaN(Number(res?.value))) {
        //         linkedTrialTotal += Number(res?.value)
        //     } else {
        //         categoricalTrialValue = res?.value
        //     }
        // })
        //  return !isNaN(linkedTrialValue) ? getValue((linkedTrialValue / linkedTrialTotal) * currentValue) : categoricalTrialValue
        return Boolean(!!currentValue && !isNaN(currentValue)) ? getValue(linkedTrialValue) : 0
    }, [linkedTrialData, record, getValue])

    const columns = useMemo(() => [
        {
            title: (
                <Text strong style={{ paddingLeft: "26px", }}>
                    {t("common.ingredient")}
                </Text>
            ),
            dataIndex: "name",
            key: "name",
            className: 'table-name-column',
            align: "left" as any,
            fixed: "left",
            width: "300px",
            render: (text: string) => {
                return getLinkedTableCellData(text, true)
            },
        },
        {
            dataIndex: "category",
            key: "Category",
            width: "170px",
            title: (
                <Text strong>
                    {t("common.category")}
                </Text>
            ),
            align: "left",
            fixed: "left" as "left",
            render: (text: string) => {
                return getLinkedTableCellData(text, false)
            },
        },
        {
            title: (
                <Text strong>
                    {t("common.unit")}
                </Text>
            ),
            dataIndex: "unit",
            key: "unit",
            className: 'table-unit-column',
            fixed: "left",
            width: "80px",
            align: "left",
            render: (text: string) => {
                return getLinkedTableCellData(text, false)
            },
        },
        ...columnsTrialData.map((res: any, index: number) => (
            {
                title: () => (
                    <Space
                        style={{
                            alignItems: "center",
                            gap: "10px",
                            wordBreak: "break-word",
                            lineHeight: 1,
                            paddingLeft: "26px",
                        }}
                    >
                        <Text strong>{`Trial ${(pageNumber ? 1 : 0) * (pageNumber - 1) * 10 + index + 1
                            }`}</Text>
                        {/* {(from !== "data-summary" && !!res?.experiment_id) && <Text strong type="secondary">{res.experiment_id}</Text>}
                        {!!res?.id_set?.formulation_id && <Text strong type="secondary">{res?.id_set?.formulation_id}</Text>} */}
                    </Space>
                ),
                dataIndex: from !== "data-summary" ? res.experiment_id : res?.formulation_id,
                width: "170px",
                align: "left",
                render: (text: string) => {
                    return getLinkedTableCellData(text)
                },
            }
        ))

    ], [t, columnsTrialData, from, pageNumber])

    const ingredientData = useMemo(() => {
        const data = linkedTrialData?.reduce((acc: any, curr: any) => {
            return {
                ...acc,
                ...(curr?.ingredients ?? {})
            }
        }, {})
        return data
    }, [linkedTrialData])

    const dataSource = useMemo(() => {
        const data = Array.from(
            new Set(linkedTrialData.filter((res: any) => res?.id_set?.formulation_id === record?.linked_trial)
                .flatMap(({ ingredients }: any) => Object.keys(ingredients || {})))
        ).filter((key: any) => linkedTrialData.some(({ ingredients }: any) => !!ingredients?.[key]?.value && ingredients?.[key]?.value !== "-"))
            .map((name: any, index: number) => ({
                key: index,
                category: ingredientData?.[name]?.category || ingredientsLabels[name]?.category || "-",
                name: linkedTrialData?.find(({ id_set }: any) => id_set.formulation_id === name)?.meta?.display_id || name,
                unit: linkedTrialData?.[0]?.ingredients?.[name]?.unit || ingredientsLabels[name]?.unit?.[0] || "-",
                ...(from !== "data-summary" ?
                    experiments.filter((res: any) => Object.keys(record || {}).includes((res.experiment_id))).reduce(
                        (acc: any, experiment: any) => ({
                            ...acc,
                            [experiment.experiment_id]: calculateConvertedProportion(name, experiment.experiment_id) || "-",
                        }),
                        {}
                    ) :
                    columnsTrialData.filter((res: any) => Object.keys(record || {}).includes((res?.formulation_id))).reduce(
                        (acc: any, experiment: any) => ({
                            ...acc,
                            [experiment?.formulation_id]: calculateConvertedProportion(name, experiment?.formulation_id) || "-",
                        }),
                        {}
                    )
                )
            }))
        return data
    }, [ingredientsLabels, linkedTrialData, record, calculateConvertedProportion, experiments, from, columnsTrialData, ingredientData])

    return (
        <Table scroll={{ x: 400 }} style={{ marginTop: 20, marginBottom: 20 }} className={"suggested-exp-result-table"} pagination={false} columns={columns} dataSource={dataSource} bordered loading={{
            indicator: <LoadingOutlined />,
            spinning: status === AsyncStates.LOADING
        }} />
    )
}
