import { notification } from "antd"
import { put, takeLatest, call, select } from "redux-saga/effects"
import { history } from "src"
import {
	deleteProject,
	fetchProjectList,
	newProject,
	updateProject,
	getAllNotesApi,
	createNoteApi,
	deleteNoteApi,
	updateNoteApi,
	projectActivityAPi,
	projectOverviewAPI,
} from "src/services/projects"
import {
	deleteCelsureProject,
	fetchCelsureProjectList,
	newCelsureProject,
	updateCelsureProject,
	getCelsureAllNotesApi,
	createCelsureNoteApi,
	deleteCelsureNoteApi,
	updateCelsureNoteApi,
	projectCelsureActivityAPi,
} from "src/services/celsure"
import {
	deleteProjectFailure,
	deleteProjectRequest,
	deleteProjectSuccess,
	fetchProjectListFailure,
	fetchProjectListRequest,
	fetchProjectListSuccess,
	newProjectFailure,
	newProjectRequest,
	newProjectSuccess,
	updateProjectFailure,
	updateProjectRequest,
	updateProjectSuccess,
	createNoteFailure,
	createNoteRequest,
	createNoteSuccess,
	updateNoteFailure,
	updateNoteRequest,
	updateNoteSuccess,
	deleteNoteFailure,
	deleteNoteRequest,
	deleteNoteSuccess,
	getNotesFailure,
	getNotesRequest,
	getNotesSuccess,
	projectActivityFailure,
	projectActivityRequest,
	projectActivitySuccess,
	setProject,
	projectOverviewRequest,
	projectOverviewSuccess,
	projectOverviewFailure,
} from "../actions/projects"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import { messages } from "src/utils/hooks"
import { APIError } from 'src/typings'
import jwtManager from "src/utils/jwtManager"

type newProjectAction = {
	type: string
	payload: any
}

function* fetchProjectListSaga(
	action: newProjectAction
): Generator<any, any, any> {
	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const currentProject = yield select((state) => state.projects.current)
		const projects = yield select((state) => state.projects.projectList)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = action.payload
		const response = yield call(
			project_type === "celsure" ? fetchCelsureProjectList : fetchProjectList,
			params,
			headers
		)
		const {
			data: {
				result: { message, status, data },
			},
		} = response
		if (status === "Success") {
			if (!currentProject && projects.length < 2) {
				yield put(setProject(data?.[0]?.project_id))
			}
			if (
				(action?.payload?.set && !currentProject) ||
				!data.map((res: any) => res?.project_id).includes(currentProject)
			) {
				yield put(setProject(data?.[0]?.project_id))
			}
			yield put(fetchProjectListSuccess({ data }))
		} else {
			yield put(fetchProjectListFailure(message))
		}
	} catch (error) {
		yield put(fetchProjectListFailure(error))
	}
}

function* newProjectSaga(action: newProjectAction): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const projectList = yield select((state) => state.projects.projectList)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = action.payload
		const response = yield call(
			project_type === "celsure" ? newCelsureProject : newProject,
			params,
			headers
		)
		const {
			data: {
				result: { data: project, message, status },
			},
		} = response

		if (status === "Success") {
			yield put(newProjectSuccess({ message }))
			yield put(fetchProjectListSuccess({ data: [...projectList, project] }))
			notification.success({
				message,
				placement: "topRight",
			})
			if (!window.location.pathname.includes("/work-orders/details")) {
				yield put(setProject(project?.project_id))
				yield call(history.push, `/projects/details/${project?.project_id}`)
			}
		} else {
			yield put(newProjectFailure(message))
			notification.error({
				message: message,
				placement: "topRight",
			})
		}
	} catch (error) {
		yield put(newProjectFailure(error))
		notification.error({
			message: messages[ln].internal_server_error,
			placement: "topRight",
		})
	}
}

function* deleteProjectSaga(
	action: newProjectAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = action.payload
		const response = yield call(
			project_type === "celsure" ? deleteCelsureProject : deleteProject,
			params,
			headers
		)
		const {
			data: {
				result: { message, status },
			},
		} = response

		if (status === "Success") {
			yield call(history.push, "/projects")
			yield put(deleteProjectSuccess({ message }))
			yield put(fetchProjectListRequest({ set: true }))
			notification.success({
				message,
				placement: "topRight",
			})
		} else {
			yield put(deleteProjectFailure(message))
			notification.error({
				message,
				placement: "topRight",
			})
		}
	} catch (error) {
		yield put(deleteProjectFailure(error))
		notification.error({
			message: messages[ln].internal_server_error,
			placement: "topRight",
		})
	}
}

function* updateProjectSaga(
	action: newProjectAction
): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const params = action.payload
		const {
			data: {
				result: { message, status, data: projectData },
			},
		} = yield call(
			project_type === "celsure" ? updateCelsureProject : updateProject,
			params,
			headers
		)
		if (status === "Success") {
			yield put(updateProjectSuccess(projectData))
			yield put(fetchProjectListRequest({}))
			yield put(projectActivityRequest({ project_id: action.payload.project_id }))
			if (!!action.payload.status) {
				notification.success({
					message,
					placement: "bottomRight",
				})
			}
			else {
				if (!["teams", "update_tags"].includes(action?.payload?.from)) {
					yield call(history.goBack)
					// yield call(history.replace, `/projects/details/${projectData?.project_id}`)
				}
				notification.success({
					message,
					placement: "bottomRight",
				})
			}
		} else {
			yield put(updateProjectFailure(message));
			notification.error({
				message,
				placement: "bottomRight",
			});
		}
	} catch (error) {
		yield put(updateProjectFailure(error));
		notification["error"]({
			message: messages[ln].internal_server_error,
			placement: "bottomRight",
		});
	}
}

function* getNotesSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, data },
			},
		} = yield call(
			project_type === "celsure" ? getCelsureAllNotesApi : getAllNotesApi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(getNotesSuccess(data))
		} else {
			yield put(getNotesFailure())
		}
	} catch (error) {
		yield put(getNotesFailure(error))
	}
}

function* createNoteSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(
			project_type === "celsure" ? createCelsureNoteApi : createNoteApi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(createNoteSuccess())
			yield put(getNotesRequest({ project_id: payload.project_id }))
			notification.success({
				message,
				placement: "topRight",
			})
		} else {
			yield put(createNoteFailure())
			notification.error({
				message,
				placement: "topRight",
			})
		}
	} catch (error) {
		yield put(createNoteFailure(error))
		notification.error({
			message: messages[ln].internal_server_error,
			placement: "topRight",
		})
	}
}

function* updateNoteSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(
			project_type === "celsure" ? updateCelsureNoteApi : updateNoteApi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(updateNoteSuccess())
			yield put(getNotesRequest({ project_id: payload.project_id }))
			notification.success({
				message,
				placement: "topRight",
			})
		} else {
			yield put(updateNoteFailure())
			notification.error({
				message,
				placement: "topRight",
			})
		}
	} catch (error) {
		yield put(updateNoteFailure(error))
		notification.error({
			message: messages[ln].internal_server_error,
			placement: "topRight",
		})
	}
}

function* deleteNoteSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, message },
			},
		} = yield call(
			project_type === "celsure" ? deleteCelsureNoteApi : deleteNoteApi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(deleteNoteSuccess())
			yield put(getNotesRequest({ project_id: payload.project_id }))
			notification.success({
				message: message,
				placement: "topRight",
			})
		} else {
			yield put(deleteNoteFailure())
			notification.error({
				message: message,
				placement: "topRight",
			})
		}
	} catch (err) {
		yield put(deleteNoteFailure(err))
		const error = (err as unknown) as APIError

		notification.error({
			message: error?.response?.data?.detail ?? messages[ln].internal_server_error,
			placement: "topRight",
		})
	}
}

function* projectActivitySaga({ payload }: any): Generator<any, any, any> {
	try {
		const { project_type } = yield select((state) => state.login.loginResponse)
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { status, data },
			},
		} = yield call(
			project_type === "celsure"
				? projectCelsureActivityAPi
				: projectActivityAPi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(projectActivitySuccess(data))
		} else {
			yield put(projectActivityFailure())
		}
	} catch (error) {
		yield put(projectActivityFailure(error))
	}
}

function* projectsOverviewSaga(action: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(projectOverviewAPI, action.payload, headers)
		if (status === "Success") {
			yield put(projectOverviewSuccess(data))
		} else {
			yield put(projectOverviewFailure(message))
		}
	} catch (error) {
		yield put(projectOverviewFailure(error))
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(fetchProjectListRequest, fetchProjectListSaga)
	yield takeLatest(newProjectRequest, newProjectSaga)
	yield takeLatest(updateProjectRequest, updateProjectSaga)
	yield takeLatest(deleteProjectRequest, deleteProjectSaga)
	yield takeLatest(getNotesRequest, getNotesSaga)
	yield takeLatest(createNoteRequest, createNoteSaga)
	yield takeLatest(updateNoteRequest, updateNoteSaga)
	yield takeLatest(deleteNoteRequest, deleteNoteSaga)
	yield takeLatest(projectActivityRequest, projectActivitySaga)
	yield takeLatest(projectOverviewRequest, projectsOverviewSaga)
}
