import React from "react"
import { geekblue, purple } from "@ant-design/colors"
import { ValueType } from "./types"
import { Tooltip } from "antd"

type P = {
	data: {
		type: "characterization" | "property"
		value: ValueType
	}[][]
}

export const CharacterizationDataTable = ({ data }: P) => {
	return (
		<table className="zeon-prediction-result-characterization-table">
			<tbody>
				<tr>
					{data.map((row, rowIndex) => {
						return (
							<td key={rowIndex}>
								{row.map((cell, cellIndex) => {
									const isProperty = cell.type === "property"
									const tooltipVisibility =
										!!cell.value &&
										typeof cell.value === "string" &&
										cell.value.length > 24

									return (
										<table key={cellIndex}>
											<tbody>
												<tr
													style={{
														background: isProperty ? geekblue[0] : purple[0],
														color: isProperty ? geekblue[5] : purple[5],
													}}
													className="trial-cell-row"
												>
													<td
														className="trial-cell zeon-table-cell"
														align="center"
													>
														<Tooltip
															overlayInnerStyle={{ textAlign: "center" }}
															title={tooltipVisibility ? cell.value : undefined}
														>
															{cell.value}
														</Tooltip>
													</td>
												</tr>
											</tbody>
										</table>
									)
								})}
							</td>
						)
					})}
				</tr>
			</tbody>
		</table>
	)
}
