import { createActions } from "redux-actions";

export enum DeleteFileActionTypes {
    REQUEST = "DELETE_FILE_REQUEST",
    SUCCESS = "DELETE_FILE_SUCCESS",
    FAILURE = "DELETE_FILE_FAILURE",
}

export enum DraftFileUploadActionTypes {
    REQUEST = "DRAFT_FILE_UPLOAD_REQUEST",
    SUCCESS = "DRAFT_FILE_UPLOAD_SUCCESS",
    FAILURE = "DRAFT_FILE_UPLOAD_FAILURE",
    CLEAR = "DRAFT_FILE_UPLOAD_CLEAR"
}

export enum UploadFileActionTypes {
    REQUEST = "UPLOAD_FILE_REQUEST",
    SUCCESS = "UPLOAD_FILE_SUCCESS",
    FAILURE = "UPLOAD_FILE_FAILURE",
    CLEAR = "UPLOAD_FILE_CLEAR"
}

export enum FileHistoryActionTypes {
    REQUEST = "FILE_HISTORY_REQUEST",
    SUCCESS = "FILE_HISTORY_SUCCESS",
    FAILURE = "FILE_HISTORY_FAILURE",
}

export enum ImportMultipleWorkOrdersActionTypes {
    REQUEST = "IMPORT_MULTIPLE_WORK_ORDERS_REQUEST",
    SUCCESS = "IMPORT_MULTIPLE_WORK_ORDERS_SUCCESS",
    FAILURE = "IMPORT_MULTIPLE_WORK_ORDERS_FAILURE",
    CLEAR = "IMPORT_MULTIPLE_WORK_ORDERS_CLEAR",
}

export enum MappedFilesActionTypes {
    REQUEST = "MAPPED_FILES_REQUEST",
    SUCCESS = "MAPPED_FILES_SUCCESS",
    FAILURE = "MAPPED_FILES_FAILURE",
}


export const {
    mappedFilesRequest,
    mappedFilesSuccess,
    mappedFilesFailure,
    deleteFileRequest,
    deleteFileSuccess,
    deleteFileFailure,
    draftFileUploadRequest,
    draftFileUploadSuccess,
    draftFileUploadFailure,
    draftFileUploadClear,
    uploadFileRequest,
    uploadFileSuccess,
    uploadFileFailure,
    uploadFileClear,
    fileHistoryRequest,
    fileHistorySuccess,
    fileHistoryFailure,
    importMultipleWorkOrdersRequest,
    importMultipleWorkOrdersSuccess,
    importMultipleWorkOrdersFailure,
    importMultipleWorkOrdersClear,
} = createActions({
    [MappedFilesActionTypes.REQUEST]: (payload) => payload,
    [MappedFilesActionTypes.SUCCESS]: (data: any[]) => data,
    [MappedFilesActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [DeleteFileActionTypes.REQUEST]: (payload) => payload,
    [DeleteFileActionTypes.SUCCESS]: (data: any[]) => data,
    [DeleteFileActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [DraftFileUploadActionTypes.REQUEST]: (payload) => payload,
    [DraftFileUploadActionTypes.SUCCESS]: (data) => data,
    [DraftFileUploadActionTypes.FAILURE]: (message) => ({ error: message }),
    [DraftFileUploadActionTypes.CLEAR]: () => { },
    [UploadFileActionTypes.REQUEST]: (payload) => payload,
    [UploadFileActionTypes.SUCCESS]: (data) => data,
    [UploadFileActionTypes.FAILURE]: (message) => ({ error: message }),
    [UploadFileActionTypes.CLEAR]: () => { },
    [FileHistoryActionTypes.REQUEST]: (payload) => payload,
    [FileHistoryActionTypes.SUCCESS]: (payload) => payload,
    [FileHistoryActionTypes.FAILURE]: (payload) => payload,
    [ImportMultipleWorkOrdersActionTypes.REQUEST]: (payload) => payload,
    [ImportMultipleWorkOrdersActionTypes.SUCCESS]: (payload) => payload,
    [ImportMultipleWorkOrdersActionTypes.FAILURE]: (payload) => payload,
    [ImportMultipleWorkOrdersActionTypes.CLEAR]: (payload) => payload,
});
