import { sendDelete, sendGet, sendPost } from "src/utils/sendApi";

const customFieldAPIPrefix = "v1/data/custom-field";

export const createCustomFieldApi = sendPost(`${customFieldAPIPrefix}`);

export const getCustomFieldsApi = (params: { entity_system_name?: string }) =>
  sendGet(
    `${customFieldAPIPrefix}${
      params.entity_system_name
        ? "?entity_system_name=" + params.entity_system_name
        : ""
    }`
  );

export const deleteCustomFieldApi = ({ field_id }: { field_id: string }) =>
  sendDelete(`${customFieldAPIPrefix}/${field_id}`);

export const updateCustomFieldApi = sendPost(`${customFieldAPIPrefix}`);
