import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { useMemo } from 'react'


export const ProfileGraph = ({ tableData }: any) => {

    const graphData = useMemo(() => {
        const result = [0]
        tableData?.forEach((res: any, index: any) => {
            const temp = Number(result?.[result?.length - 1])
            result.push(Number(res?.time) + temp)
            result.push(Number(res?.time) + temp)
        })
        const tempData = [tableData[0]?.temperature || null,
        ...tableData.reduce((array: any, res: any, index: any) => [
            ...array,
            res.temperature || null,
            tableData?.[index + 1]?.temperature || tableData?.[index]?.temperature || null
        ], [])
        ]
        return result.map((res: any, index: number) => ({
            x: res,
            y: tempData[index]
        }))
    }, [tableData])


    return <HighchartsReact
        highcharts={Highcharts}
        options={{
            chart: {
                type: "line"
            },
            title: {
                text: null,
            },
            xAxis: {
                title: {
                    text: "Time (mins)"
                },
                lineColor: 'black',
                lineWidth: 1
            },
            yAxis: {
                title: {
                    text: "Temperature (degC)",
                },
                lineColor: 'black',
                lineWidth: 1,
                minorGridLineWidth: 0,
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: true
                    },
                    tooltip: {
                        headerFormat: null,
                        pointFormat: `Time : {point.x} <br> Temperature : {point.y}`
                    },
                }
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Temperature',
                showInLegend: false,
                data: graphData || [],
                step: true,
            }]
        }}
    />
}