export const fileToBase64 = async (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve({ id: file.uid, rawData: event?.target?.result });
        };
        reader.readAsDataURL(file);
        reader.onerror = () => {
            reject();
        }
    })
}