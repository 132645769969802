import { useEffect, useMemo, useState } from 'react'
import { Space, Anchor, Row, Col, Card, Typography, Table, Spin, Descriptions, Tooltip, Modal, notification, Tag } from "antd"
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { uploadDoeClear, uploadDoeRequest, editDoeRequest, getDoeExperimentsRequest, setFormData, generateExperimentSuccess, generateExperimentFailure } from 'src/store/actions/doe'
import { AsyncStates } from 'src/constants'
import { geekblue } from "@ant-design/colors"
import { includeDoeExp } from 'src/store/actions/workOrderDetails'
import { useHistory, useParams } from 'react-router-dom'
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons'
import useTranslate from 'src/utils/useTranslate'
import { StyledButton } from 'src/styled_components/StyledButton'
import { useValue } from 'src/utils/useValue'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import { Unsubscribe } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'
import { DOE_COLLECTION, firestoreDb } from 'src/utils/firebase'

const { Title, Text, Paragraph } = Typography

const style = {
  width: 150
}

interface ExpResultsProps {
  from: any,
  setEditingState?: any
}


export function ExpResults({ from }: ExpResultsProps) {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const [t] = useTranslate()
  const { getValue } = useValue()
  const { generateExperimentData, generateExperimentStatus, listAlgorithmsData, listAlgorithmsStatus, uploadDoeStatus,
    doeExperimentsData, doeExperimentsStatus, editDoeStatus, doeHistoryData, formData: expFormFields, additionalParams, selectedAlgorithm } = useSelector((state: StoreState) => state.doe)
  const displayNames = useSelector((state: StoreState) => state.displayNames.data)
  const [ingredientsLevelsCheck, setIngredientsLevelsCheck] = useState<boolean>(true)
  const [processingLevelsCheck, setProcessingLevelsCheck] = useState<boolean>(true)
  const [currentAnchor, setCurrentAnchor] = useState<string>("#basic-information")
  const [basicInfo, setBasicInfo] = useState<any>({ doe_id: "", title: "", description: "" })
  const [specifications, setSpecifications] = useState<any>({ exp_design: "", alpha: "", face: "", centers: "", reduction: "", factors: "", samples: "" })
  const [expData, setExpData] = useState<any>([])
  const [loadingStatus, setLoadingStatus] = useState<AsyncStates>(AsyncStates.INITIAL)
  const [tempInfo, setTempInfo] = useState<any>({ title: "", description: "" })
  const [expGenCompleted, setExpGenCompleted] = useState<any>();

  const { doeId: doe_id }: any = useParams();

  useEffect(() => {
    if (editDoeStatus === AsyncStates.SUCCESS) {
      setBasicInfo((prevState: any) => ({ ...prevState, title: tempInfo.title, description: tempInfo.description }))
    }
  }, [dispatch, editDoeStatus, tempInfo])

  const record = useMemo(() => {
    return doeHistoryData?.["DOE History"]?.find((res: any) => res.doe_id === doe_id)
  }, [doeHistoryData, doe_id])

  useEffect(() => {
    if (!record && from === 'history') {
      push('/experiment-history')
    }
  }, [from, push, record]);

  useEffect(() => {
    if (doe_id === 'draft' && !generateExperimentData?.doe_id) {
      push('/experiment-history')
    }
  }, [doe_id, generateExperimentData?.doe_id, push]);

  useEffect(() => {
    let unsub: Unsubscribe;
    if (
      generateExperimentData?.doe_collection_key
    ) {
      const doeId = generateExperimentData?.doe_collection_key;

      if(doeId !== doe_id) {
        setExpGenCompleted(true);
        return;
      };

      const taskDocRef = doc(
          firestoreDb,
          `${DOE_COLLECTION}/${generateExperimentData?.doe_collection_key}`
      );

      unsub = onSnapshot(taskDocRef, (doc) => {
          const taskDoc = doc.data();
          if (taskDoc) {
              const taskStatus = taskDoc["status"];

              if (String(taskStatus).toLowerCase() === "completed") {
                  unsub && unsub();
                  notification.success({
                    message: t("doe.expGenSuccess"),
                    duration: 5
                  });
                  dispatch(generateExperimentSuccess({}));
                  setExpGenCompleted(true);
              }
              if (String(taskStatus).toLowerCase() === "failed") {
                unsub && unsub();
                notification.error({
                  message: t("doe.expGenInFailed"),
                  duration: 5
                });
                dispatch(generateExperimentFailure({}));
                setExpGenCompleted(true);
            }
          }
      });
    } else {
      setExpGenCompleted(true);
    }

    return () => { unsub && unsub() } 
  }, [dispatch, doe_id, generateExperimentData, t]);


  useEffect(() => {
    dispatch(getDoeExperimentsRequest({ doe_id: from === 'history' ? doe_id : generateExperimentData?.doe_id }));
  }, [dispatch, doe_id, from, generateExperimentData?.doe_id, expGenCompleted])

  useEffect(() => {
    if (from === "history") {
      const status = doeExperimentsData?.experiments?.[0]?.status;
      setLoadingStatus(() => {
        if(status === "completed") return doeExperimentsStatus;
        else return AsyncStates.LOADING
      });
      
      if (doeExperimentsStatus === AsyncStates.SUCCESS) {
        setExpData(doeExperimentsData)
        setBasicInfo({
          title: record?.title, description: record?.description, doe_id: doeExperimentsData?.experiments?.[0]?.doe_id
        });
        const metadata = doeExperimentsData?.experiments?.[0]?.metadata;
        setSpecifications({
          exp_design: record?.algorithm?.split("_")?.map((res: any) => res.charAt(0).toUpperCase() + res.slice(1))?.join(" ") ?? record?.algorithm,
          ...(metadata?.samples && { samples: metadata.samples }),
          ...(metadata?.alpha && { alpha: metadata.alpha }),
          ...(metadata?.face && { face: metadata.face }),
          ...(metadata?.centers && { centers: metadata.centers }),
          ...(metadata?.reduction && { reduction: metadata.reduction }),
          factors: Object.keys(doeExperimentsData?.experiments?.[0]?.ingredients || {})?.length + Object.keys(doeExperimentsData?.experiments?.[0]?.processing || {})?.length
        })
      }
    } else {
      setLoadingStatus(generateExperimentStatus)
      if (generateExperimentStatus === AsyncStates.SUCCESS) {
        const { parameters: { alpha, centers, reduction, face, samples } } = selectedAlgorithm
        setExpData(generateExperimentData)
        setBasicInfo({ title: expFormFields.title, description: expFormFields.description, doe_id: generateExperimentData?.doe_id })
        setSpecifications({
          exp_design: Object.entries(listAlgorithmsData || {}).find(([key, value]: any) => selectedAlgorithm.name === value.name)?.[0]?.split("_").map((res: any) => res.charAt(0).toUpperCase() + res.slice(1)).join(" "),
          ...(samples === null && { samples: additionalParams?.samples ? additionalParams.samples : Object.keys(generateExperimentData?.experiments?.[0]?.ingredients || {}).length }),
          ...(alpha === null && { alpha: additionalParams?.alpha }),
          ...(face === null && { face: additionalParams?.face }),
          ...(centers === null && { centers: generateExperimentData?.experiments?.[0]?.algorithm === "central_composite" ? `(${additionalParams?.centers?.join(",")})` : additionalParams?.centers }),
          ...(reduction === null && { reduction: additionalParams?.reduction }),
          factors: Object.keys(generateExperimentData?.experiments?.[0]?.ingredients || {})?.length + Object.keys(generateExperimentData?.experiments?.[0]?.processing || {})?.length
        })
      }
    }
  }, [from, doeExperimentsData, generateExperimentData, doeExperimentsStatus, generateExperimentStatus, expFormFields, listAlgorithmsData, selectedAlgorithm, additionalParams, record, getValue])

  useEffect(() => {
    return () => { dispatch(uploadDoeClear()) }
  }, [dispatch])

  useEffect(() => {
    let ingredientsLevelsFlag = false
    let processingLevelsFlag = false
    if (doeExperimentsStatus === AsyncStates.SUCCESS) {
      expData?.experiments?.[0]?.levels?.forEach((res: any) => {
        if (res?.length === 3) {
          ingredientsLevelsFlag = true
        }
      })
      expData?.experiments?.[0]?.processing_levels?.forEach((res: any) => {
        if (res?.length === 3) {
          processingLevelsFlag = true
        }
      })
      setIngredientsLevelsCheck(ingredientsLevelsFlag)
      setProcessingLevelsCheck(processingLevelsFlag)
    }
  }, [expData, doeExperimentsStatus])

  const experimentsColumns = useMemo(() => {
    if (loadingStatus === AsyncStates.SUCCESS) {
      return [
        {
          dataIndex: "factor",
          title: t("common.factors"),
          key: "factor"
        },
        {
          dataIndex: "unit",
          title: t("common.unit"),
          key: "unit"
        },
        ...expData?.experiments?.map((res: any, index: any) => ({
          dataIndex: res.exp_id,
          title: res.exp_id,
          key: res.exp_id
        }))
      ]
    }
    else return []
  }, [expData, loadingStatus, t])


  const experimentsTableData = useMemo(() => {
    if (loadingStatus === AsyncStates.SUCCESS) {
      const inputData: any = [...Object.keys(expData?.experiments?.[0]?.ingredients || {}).map((key: any) => ({ factor: key }))]
      expData?.experiments.forEach((res: any) => {
        inputData.forEach((key: any, i: any) => {
          key[res.exp_id] = getValue((Object.values(res?.ingredients || {})[i] as any)?.value, 3)
        })
      })
      inputData.forEach((res: any) => {
        res.factor = displayNames?.ingredients?.[res.factor]?.name || res.factor
        res.unit = "g"
      })
      return inputData
    }
    else return []
  }, [loadingStatus, expData, displayNames, getValue])

  const processingExperimentsTableData = useMemo(() => {
    if (loadingStatus === AsyncStates.SUCCESS) {
      const inputData: any = [...Object.keys(expData?.experiments?.[0]?.processing || {}).map((key: any) => ({ factor: key }))]
      expData?.experiments.forEach((res: any) => {
        inputData.forEach((key: any, i: any) => {
          key[res.exp_id] = getValue((Object.values(res?.processing || {})[i] as any)?.value, 3)
        })
      })
      inputData.forEach((res: any) => {
        res.unit = displayNames?.processing?.[res.factor]?.unit?.[0]
        res.factor = displayNames?.processing?.[res.factor]?.name || res.factor
      })
      return inputData
    }
    else return []
  }, [loadingStatus, expData, displayNames, getValue])

  const uploadDoeExperiments = () => {
    dispatch(uploadDoeRequest({
      title: expFormFields?.title,
      ingredients: Object.keys(expData?.experiments?.[0]?.ingredients || {}),
      doe_id: expData.doe_id,
      description: expFormFields?.description
    }))
  }

  const factorsTableColumns: any = [
    {
      dataIndex: "factor",
      title: t("common.factor"),
      key: "factor",
    },
    {
      dataIndex: "unit",
      title: t("aiEngine.unitOfMeasurement"),
      key: "unit",
      align: "center",
    },
    {
      dataIndex: "low",
      title: t("common.low"),
      key: "low",
      align: "center",
    },
    {
      dataIndex: "center",
      title: t("common.center"),
      key: "center",
      align: "center",
      render(text: any, record: any) {
        return {
          props: {
            style: !record?.center ? { background: "#f0f0f0" } : {}
          },
          children: <div>{text}</div>
        }
      }
    },
    {
      dataIndex: "high",
      title: t("common.high"),
      key: "high",
      align: "center",
    },
  ]

  const ingredientsFactorsTableData = useMemo(() => {
    const data: any = []
    if (doeExperimentsStatus === AsyncStates.SUCCESS) {
      Object.keys(expData?.experiments?.[0]?.ingredients || {}).filter((res: any) => !Object.keys(expData?.experiments?.[0]?.constants || {})?.includes(res))?.forEach((res: any) => {
        data.push({ factor: displayNames?.ingredients?.[res]?.name || res, unit: "g" })
      })
      expData?.experiments?.[0]?.levels?.forEach((res: any, index: any) => {
        if(!data[index]) return;
        if (res.length === 2) {
          data[index].low = getValue(res[0])
          data[index].high = getValue(res[1])
        } else {
          data[index].low = getValue(res[0])
          data[index].center = getValue(res[1])
          data[index].high = getValue(res[2])
        }
      })
    }
    return data
  }, [doeExperimentsStatus, expData, displayNames, getValue])

  const processingFactorsTableData = useMemo(() => {
    const data: any = []
    if (doeExperimentsStatus === AsyncStates.SUCCESS) {
      Object.keys(expData?.experiments?.[0]?.processing || {}).filter((res: any) => !Object.keys(expData?.experiments?.[0]?.processing_constants || {})?.includes(res))?.forEach((res: any) => {
        data.push({ factor: displayNames?.processing?.[res]?.name || res, unit: from === "history" ? expData?.experiments?.[0]?.processing?.[res]?.unit : displayNames?.processing?.[res]?.unit?.[0] || "" })
      })
      expData?.experiments?.[0]?.processing_levels?.forEach((res: any, index: any) => {
        if(!data[index]) return;
        if (res.length === 2) {
          data[index].low = getValue(res[0])
          data[index].high = getValue(res[1])
        } else {
          data[index].low = getValue(res[0])
          data[index].center = getValue(res[1])
          data[index].high = getValue(res[2])
        }
      })
    }
    return data
  }, [doeExperimentsStatus, expData, displayNames, from, getValue])

  const updateDoeInfo = (e: any, type: string) => {
    if (e !== basicInfo?.[type]) {
      if (from === "history") {
        dispatch(editDoeRequest({ ...basicInfo, [type]: e, }))
        setTempInfo({ ...basicInfo, [type]: e, })
      }
      else {
        if (uploadDoeStatus === AsyncStates.SUCCESS) {
          dispatch(editDoeRequest({ ...basicInfo, [type]: e, }))
          setTempInfo({ ...basicInfo, [type]: e, })
        }
        dispatch(setFormData({ ...expFormFields, [type]: e }))
      }
    }
  }

  const renderStatus = useMemo(() => {
    const status = doeExperimentsData?.experiments?.[0]?.status;
    const formattedText = String(status || "")
          .replace("_", " ")
          .toLowerCase();
    if (formattedText === "completed")
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {formattedText}
        </Tag>
      );
    if (formattedText === "failed")
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {formattedText}
        </Tag>
      );
    if (formattedText === "in progress")
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          {formattedText}
        </Tag>
      );

    return (
      <Tag icon={<ExclamationCircleOutlined />} color="cyan">
        {formattedText}
      </Tag>
    );
  }, [doeExperimentsData?.experiments]);

  return (
    <Space size="large" direction="vertical" style={{ width: "100%", marginBottom: 50 }}>
      <Spin spinning={AsyncStates.LOADING === generateExperimentStatus || listAlgorithmsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
        <Row justify="space-around" style={{ marginTop: 20 }}>
          <Col span={20}>
            <Space size="large" direction="vertical" style={{ width: "100%" }}>
              <StyledPageHeader
                ghost={false}
                title={<Title level={5} style={{ margin: 0 }} >{t("expResults.basicInformation")}</Title>} onBack={() => {
                  if (from === 'generated-exp' && uploadDoeStatus !== AsyncStates.SUCCESS) {
                    Modal.confirm({
                      title: t("common.saveExperiments"),
                      content: t('common.unsavedChangesWillLost'),
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                      onOk: () => {
                        window.history.back()
                      },
                    });
                  } else { window.history.back() }

                }}
                extra={<Space>
                  {from === "generated-exp" &&
                    <Tooltip title={uploadDoeStatus === AsyncStates.SUCCESS ? t("expResults.experimentsSaved") : t("expResults.saveGeneratedExperiments")}>
                      <StyledButton onClick={uploadDoeExperiments}
                        disabled={uploadDoeStatus === AsyncStates.SUCCESS}
                        loading={uploadDoeStatus === AsyncStates.LOADING}>
                        {t('common.saveExperiments')}
                      </StyledButton>
                    </Tooltip>}
                  <Tooltip title={from === "generated-exp" && uploadDoeStatus !== AsyncStates.SUCCESS ? t("expResults.saveTheExperimentsCreateWorkOrder") : t("expResults.createWorkOrderFromGeneratedExperiments")}>
                    <StyledButton type="primary" disabled={from === "generated-exp" && uploadDoeStatus !== AsyncStates.SUCCESS}
                      onClick={() => {
                        dispatch(includeDoeExp([from === "generated-exp" ? expData?.doe_id : expData?.experiments?.[0]?.doe_id]))
                        push('/work-orders/create-workorder')
                      }}>
                      {t("common.generateWorkOrder")}</StyledButton>
                  </Tooltip>
                </Space>}
              />

              <Card id="basic-information">
                <Spin spinning={[editDoeStatus, doeExperimentsStatus].includes(AsyncStates.LOADING)} indicator={<LoadingOutlined />}>
                  <Descriptions column={1}>
                    <Descriptions.Item label={<Text style={style} type="secondary">{t("expResults.expId")}</Text>}>{basicInfo?.doe_id}</Descriptions.Item>
                    <Descriptions.Item label={<Text style={style} type="secondary">{t("doe.exptitle")}</Text>}>
                      <Text style={{ width: "100%" }} editable={{
                        onChange: (e: any) => updateDoeInfo(e, "title")
                      }}>{basicInfo?.title}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text style={style} type="secondary">{t("aiengine.Expdescription")}</Text>}>
                      <Paragraph style={{ width: "100%" }}
                        editable={{
                          autoSize: { maxRows: 3, minRows: 1 },
                          onChange: (e: any) => updateDoeInfo(e, "description")
                        }}>{basicInfo?.description}</Paragraph>
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text style={style} type="secondary">{t("common.status")}</Text>}>{renderStatus}</Descriptions.Item>
                  </Descriptions>
                </Spin>
              </Card>

              <Card id="specifications" title={<div>
                <Title level={5}>{t("common.specification")}</Title>
                <Text type="secondary">{t("expResults.experimentSelectedProperties")}</Text>
              </div>}>
                <Spin spinning={[doeExperimentsStatus].includes(AsyncStates.LOADING)} indicator={<LoadingOutlined />}>
                  <Descriptions column={1}>
                    <Descriptions.Item label={<Text style={style} type="secondary">{t("expResult.ExpDesign")}</Text>}>
                      {specifications.exp_design}
                    </Descriptions.Item>
                    {specifications?.samples &&
                      <Descriptions.Item label={<Text style={style} type="secondary">{t("doe.samples")}</Text>}>{getValue(specifications.samples)}</Descriptions.Item>}
                    {specifications?.alpha &&
                      <Descriptions.Item label={<Text style={style} type="secondary">{t("doe.alpha")}</Text>}>{getValue(specifications.alpha)}</Descriptions.Item>}
                    {specifications?.face &&
                      <Descriptions.Item label={<Text style={style} type="secondary">{t("doe.expResults.faceType")}</Text>}>{getValue(specifications.face)}</Descriptions.Item>}
                    {specifications?.centers &&
                      <Descriptions.Item label={<Text style={style} type="secondary">{t("common.centers")}</Text>}>{getValue(specifications.centers)}</Descriptions.Item>}
                    {specifications.reduction &&
                      <Descriptions.Item label={<Text style={style} type="secondary">{t("expResults.reductions")}</Text>}>{getValue(specifications.reduction)}</Descriptions.Item>}
                    <Descriptions.Item label={<Text style={style} type="secondary">{t("doe.factors")}</Text>}>{getValue(specifications.factors)}</Descriptions.Item>
                  </Descriptions>
                </Spin>
              </Card>

              <Card id="factors" title={<div>
                <Title level={5}>{t("doe.factors")}</Title>
                <Text type="secondary">{t("expResults.factorsUsedInExperiment")}</Text>
              </div>}>
                <Spin spinning={[doeExperimentsStatus].includes(AsyncStates.LOADING)} indicator={<LoadingOutlined />}>
                  <Space size="large" direction="vertical" style={{ width: "100%", overflowX: "auto" }}>
                    {!!Object.keys(expData?.experiments?.[0]?.ingredients || {})?.length &&
                      <Table title={() => <Text strong>{t("common.ingredientsFactors")}</Text>} bordered
                        columns={ingredientsLevelsCheck ? factorsTableColumns : factorsTableColumns.filter((res: any) => res.dataIndex !== "center")}
                        dataSource={ingredientsFactorsTableData}
                        pagination={false} />}
                    {!!Object.keys(expData?.experiments?.[0]?.processing || {})?.length &&
                      <Table title={() => <Text strong>{t("common.processingFactors")}</Text>} bordered
                        columns={processingLevelsCheck ? factorsTableColumns : factorsTableColumns.filter((res: any) => res.dataIndex !== "center")}
                        dataSource={processingFactorsTableData}
                        pagination={false} />}
                  </Space>
                </Spin>
              </Card >

              <Card id="generated-experiments" title={<div>
                <Title level={5}>{t("common.generatedExperiments")}</Title>
                <Text type="secondary">{t("expResults.experimentsGeneratedTrialData")}</Text>
              </div>} extra={[loadingStatus].includes(AsyncStates.SUCCESS)  ? <Text strong style={{ color: geekblue[5] }}>{`${expData?.experiments?.length || ""} ${t("doe.experimentsGenerated")}`}</Text> : null}>
                <Spin spinning={[loadingStatus].includes(AsyncStates.LOADING)} indicator={<LoadingOutlined />}>
                  <Space size="large" direction="vertical" style={{ width: "100%", overflowX: "auto" }}>
                    {!!Object.keys(expData?.experiments?.[0]?.ingredients || {})?.length &&
                      <Table title={() => <Text strong>{t("common.formulations")}</Text>}
                        columns={experimentsColumns} dataSource={experimentsTableData}
                        pagination={false} bordered />}
                    {!!Object.keys(expData?.experiments?.[0]?.processing || {})?.length &&
                      <Table title={() => <Text strong>{t("common.processing")}</Text>}
                        columns={experimentsColumns} dataSource={processingExperimentsTableData}
                        pagination={false} bordered />}
                  </Space>
                </Spin>
              </Card>
            </Space>

          </Col >
          <Col span={3}>
            <Anchor style={{ marginTop: 50 }}
              affix={true}
              getCurrentAnchor={() => currentAnchor}
              onChange={(anchor) => {
                setCurrentAnchor(anchor)
              }}
              replace={true}
              items={[
                { key: 'basic-information', title: t("expResults.basicInformation"), href: "#basic-information" },
                { key: 'specifications', title: t("common.specification"), href: "#specifications" },
                { key: 'factors', title: t("common.factors"), href: "#factors" },
                { key: 'generated-experiments', title: t("common.generatedExperiments"), href: "#generated-experiments" }
              ]}
            />
          </Col>
        </Row >
      </Spin >
    </Space >
  )
}
