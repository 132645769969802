import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Spin,
  Upload,
  Row,
  Tooltip,
  message,
  Table,
  Space,
  Typography,
  Popconfirm,
  Card,
  Radio,
  Tabs,
} from "antd";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { StoreState } from 'src/store/configureStore'
import { uploadGraphsDataRequest } from 'src/store/actions/workOrderDetails'
import { useMemberName } from "src/utils/useMemberName"
import useTranslate from 'src/utils/useTranslate';
import { useMesssage } from "src/utils/hooks"
import workerUrl from "src/workers/service"
import "./FileUpload.scss"
import { StyledButton } from "src/styled_components/StyledButton"

const { Dragger } = Upload
const { Text } = Typography


export const FileUploads = ({ displayIdList }: any) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const { m } = useMesssage()

  const { getName } = useMemberName()
  const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder)
  const uploadGraphsDataStatus = useSelector((state: StoreState) => state.workOrderDetails.uploadGraphsDataStatus)
  const graphsDataHistory = useSelector((state: StoreState) => state.workOrderDetails.graphsDataHistory)
  const graphsDataHistoryStatus = useSelector((state: StoreState) => state.workOrderDetails.graphsDataHistoryStatus)
  const [fileList, setFileList] = useState<any[]>([])
  const [dataList, setDataList] = useState([] as any[])
  const [errorRowCount, setErrorRowCount] = useState([] as any[])
  const [missingTrials, setMissingTrials] = useState([] as any[])
  const [isProcessing, setIsProcessing] = useState(false)
  const [tab, setTab] = useState("line")
  const [type, setType] = useState<any>("line")
  const [fileHistoryTable, setFileHistoryTable] = useState<any>([])

  useEffect(() => {
    if (graphsDataHistoryStatus === AsyncStates.SUCCESS) {
      setFileHistoryTable(graphsDataHistory.filter((res: any) => res?.type === tab))
    }
  }, [graphsDataHistoryStatus, graphsDataHistory, tab])

  const worker = useMemo(() => new Worker(workerUrl), [])

  worker.addEventListener("message", (e) => {
    const [newDataList, newErrorCount, newMissingTrials] = e.data
    setMissingTrials(newMissingTrials)
    setErrorRowCount(newErrorCount)
    setDataList(newDataList)
    setIsProcessing(false)
  })

  worker.addEventListener("error", () => {
    setIsProcessing(false)
    message.error(m('internal_server_error'))
  })

  useEffect(() => {
    !!fileList.length && (async () => {
      setIsProcessing(true)
      const newFileList = await Promise.all(fileList.map((file: any) => file.arrayBuffer()))
      worker.postMessage([newFileList, displayIdList, type])
    })()
  }, [fileList, displayIdList, worker, type])

  const uploadProps = {
    onRemove: (file: any) => {
      setFileList((prevState: any) => {
        const index = prevState.indexOf(file)
        const newFileList = prevState.slice()
        newFileList.splice(index, 1)
        return newFileList
      })
      setDataList([])
      setErrorRowCount([])
      setMissingTrials([])
    },
    beforeUpload: (file: any) => {
      if (file.name.split(".").pop() === "xlsx") {
        setFileList((prevState: any) => {
          if (fileList.map((fileRecord: any) => fileRecord.name).includes(file.name)) {
            message.error("File already uploaded")
            return prevState
          } else {
            return [...prevState, file]
          }
        })
      } else message.error(t("dataUpload.selectedFileDesc"))
      return false
    },
    fileList,
    multiple: true
  }

  const uploadGraphFiles = () => {
    const formData = new FormData()
    fileList.forEach((file: any) => {
      formData.append('files', file)
    })
    formData.append('work_order_id', workOrder.work_order_id)
    formData.append('type', type)
    formData.append('data', JSON.stringify(dataList))
    dispatch(uploadGraphsDataRequest({ formData, rawData: false }))
  }

  useEffect(() => {
    if (uploadGraphsDataStatus === AsyncStates.SUCCESS) {
      setFileList([])
      setDataList([])
      setErrorRowCount([])
      setMissingTrials([])
    }
  }, [uploadGraphsDataStatus])

  const previewTableColumns = useCallback((data) => {
    return [...new Set(data.reduce((array: any, record: any) => [...array, ...Object.keys(record)], []))]
      .map((col: any) => ({ title: col, dataIndex: col, key: col }))
  }, [])

  const graphsHistoryColumns: any = [
    {
      title: "Trial",
      dataIndex: "display_id",
      key: "display_id",
      align: "center"
    },
    {
      title: t("woReportPreview.fileName"),
      dataIndex: "file_name",
      key: "file_name",
      align: "center"
    },
    {
      title: "Uploaded on",
      dataIndex: "updated",
      key: "updated",
      render: (text: any, record: any) => new Date(text).toLocaleString(),
      align: "center"
    },
    {
      title: t("history.uploadedBy"),
      dataIndex: "user_id",
      key: "user_id",
      render: (text: any, record: any) => getName(text),
      align: "center"
    },
    {
      title: "Download Link",
      dataIndex: "download_link",
      key: "download_link",
      render: (text: any, record: any) => <a href={text} target="_blank" rel="noreferrer">{"Link"}</a>,
      align: "center"
    },
  ]


  return (
    <Spin spinning={uploadGraphsDataStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
      <Space direction="vertical" size="large" style={{ "width": "100%", overflowX: "auto" }}>
        <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
          <Radio value={"line"}>{"Time/Temperature (Line graph)"}</Radio>
          <Radio value={"bar"}>{"Enthalpy/Temperature (Bar Graph)"}</Radio>
          <Radio value={"battery"}>{"Heat Battery Application"}</Radio>
          <Radio value={"dsc"}>{"DSC Data"}</Radio>
        </Radio.Group>
        <Dragger {...uploadProps} disabled={isProcessing}>
          {isProcessing ? (<>
            <p className="ant-upload-drag-icon">
              <LoadingOutlined />
            </p>
            <p className="ant-upload-text">{t("fileUpload.generatingPreview")}</p>
          </>) : (<>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("fileupload.upload")}</p>
            <p className="ant-upload-hint">
              {!workOrder.plots_data_uploaded ? "Upload the files for the Trials , only Files with .xlsx supported" : "Update Data, only Files with .xlsx supported"}
            </p>
          </>)}
        </Dragger>
        {Boolean(dataList.length && fileList.length) &&
          <Card title="File Preview">
            {dataList.map((data, index) => (
              <Space size="middle" direction="vertical" style={{ width: "100%" }}>
                <Table
                  bordered
                  key={fileList[index]?.uid}
                  title={() => fileList[index]?.name}
                  dataSource={data}
                  columns={previewTableColumns(data)}
                  rowClassName={(record) => {
                    const hasMissingValue = Object.values(record).some((val: any) => isNaN(val))
                    return (hasMissingValue && Boolean(errorRowCount[index])) ? 'error' : 'row'
                  }}
                />
                {Boolean(missingTrials[index]?.length) && <Text type="danger">{t("fileUpload.matchingTrialsNotFound")} : <b>{missingTrials[index]?.join(', ')}</b></Text>}
                {Boolean(errorRowCount[index]) && <Row justify="space-between">
                  <Text type="danger">{t("fileUpload.rowInvalidValuesCount")} <b>{errorRowCount[index]}</b></Text>
                  <Popconfirm
                    okText={t("common.ok")}
                    cancelText={t("common.cancel")}
                    onConfirm={() => {
                      setErrorRowCount((prevState: any) => {
                        const newState = [...prevState]
                        newState[index] = 0
                        return newState
                      })
                    }} title={"Remove invalid value from the trials while uploading the file ?"}>
                    <StyledButton type="primary">{"Remove Invalid data"}</StyledButton>
                  </Popconfirm>
                </Row>}
              </Space>))}
          </Card>}
        <Row>
          <Tooltip title={fileList?.length > displayIdList?.length ? "Number of uploaded files are greater than the number of trials in the datasheet" : "Upload data"}>
            <StyledButton
              type="primary"
              onClick={uploadGraphFiles}
              disabled={Boolean(errorRowCount?.some(val => val > 0)) || Boolean(missingTrials?.every(val => val?.length))}
              style={{ marginTop: 16 }}
            >
              {'Start Upload'}
            </StyledButton>
          </Tooltip>
        </Row>
        {!!workOrder?.plots_data_uploaded && (
          <Card title='File Upload history'>
            <Tabs onChange={(e) => setTab(e)} >
              <Tabs.TabPane key="line" tab="Time/Temperature (Line graph)">
                <Table
                  columns={graphsHistoryColumns}
                  loading={{ spinning: graphsDataHistoryStatus === AsyncStates.LOADING, indicator: <LoadingOutlined /> }}
                  dataSource={fileHistoryTable} bordered
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="bar" tab="Enthalpy/Temperature (Bar Graph)">
                <Table
                  columns={graphsHistoryColumns}
                  loading={{ spinning: graphsDataHistoryStatus === AsyncStates.LOADING, indicator: <LoadingOutlined /> }}
                  dataSource={fileHistoryTable} bordered
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="battery" tab="Heat Battery Application">
                <Table
                  columns={graphsHistoryColumns}
                  loading={{ spinning: graphsDataHistoryStatus === AsyncStates.LOADING, indicator: <LoadingOutlined /> }}
                  dataSource={fileHistoryTable} bordered
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="dsc" tab="DSC Data">
                <Table
                  columns={graphsHistoryColumns}
                  loading={{ spinning: graphsDataHistoryStatus === AsyncStates.LOADING, indicator: <LoadingOutlined /> }}
                  dataSource={fileHistoryTable} bordered
                />
              </Tabs.TabPane>
            </Tabs>
          </Card>
        )}
      </Space>
    </Spin>
  )
}