import React, { useCallback, useMemo } from "react"
import { CelsureDisplayNames } from "src/typings"
import useTranslate from "src/utils/useTranslate"
import { Position } from "src/components/Celsure/Pcm3DVisualisation/types"
import { ModalData, ModalType } from "../types"
import { AmbientModal } from "./AmbientModal"
import { PcmDetailsModal } from "./PCMDetailsModal"
import { WaterModal } from "./WaterModal"
import { toTitleCase } from "src/utils/general"
import { ColumnsType } from "antd/lib/table"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"

type P = {
	modalData: ModalData
	modalType: ModalType | null
	modalVisible: boolean
	names: CelsureDisplayNames
	resetModal: () => void
}

export const Modals = ({
	modalData,
	modalType,
	modalVisible,
	names,
	resetModal,
}: P) => {
	const [t] = useTranslate()

	const celsureDisplayNamesData = useSelector((state: StoreState) => state.celsure.celsureDisplayNamesData)

	const getData = useCallback((value: any, identifier: string) => {
		if (identifier === "additional_fields") {
			return value?.map((field: any) => ({
				fields: field?.field_name,
				value: Array.isArray(field?.value) ? field?.value?.join(", ") : (field.field_type === "date" && (field?.value ?? field?.default_value)) ? new Date(field.value).toLocaleDateString() : field?.value
			}))
		}
		return Object.entries(celsureDisplayNamesData?.insulation_type?.[value]?.meta || {}).map(([key, value]: any) => ({
			fields: key?.split("_").map((res: string) => res?.[0]?.toUpperCase() + res.slice(1)).join(" "), value
		}))
	}, [celsureDisplayNamesData])

	const columns: ColumnsType<object> = useMemo(() => {
		const { water } = modalData

		if (modalType === "water") {
			return [
				{
					title: t("common.type"),
					dataIndex: "size_type",
					key: "size_type",
					width: 20,
					render: (_: string, record: unknown, index: number) => {
						return (
							names.size_type[water[index].size_type] ?? water[index].size_type
						)
					},
				},
				{
					title: t("common.material"),
					dataIndex: "material",
					key: "material",
					width: 20,
				},
				{
					title: t("common.position"),
					dataIndex: "position",
					key: "position",
					width: 20,
					render: (text: Position) => toTitleCase(text),
				},
				{
					title: "Length(mm)",
					key: "length",
					dataIndex: "length",
					width: 20,
				},
				{
					title: "Width(mm)",
					key: "width",
					dataIndex: "width",
					width: 20,
				},
				{
					title: "Thickness(mm)",
					key: "thickness",
					dataIndex: "thickness",
					width: 20,
				},
				{
					title: t("common.amount"),
					dataIndex: "amount",
					key: "amount",
					width: 20,
				},
				{
					title: "PCM Energy (kJ)",
					dataIndex: "enthalpy",
					key: "enthalpy",
					width: 20,
				},
				{
					title: "Initial Temperature(°C)",
					dataIndex: "initial_temperature",
					key: "initial_temperature",
					width: 20,
				},
			]
		}

		if (modalType === "insulation_thickness") {
			return [
				{
					title: "Insulation Thickness (mm)",
					dataIndex: "value",
					key: "value",
					align: "center"
				},
				{
					title: "Position",
					dataIndex: "position",
					key: "position",
					render: (text: string) => toTitleCase(text),
					align: "center"
				},
			]
		}

		if (modalType === "outer_packaging") {
			return [
				{
					title: "Outer Packaging Thickness (mm)",
					dataIndex: "value",
					key: "value",
					align: "center"
				},
				{
					title: "Position",
					dataIndex: "position",
					key: "position",
					render: (text: string) => toTitleCase(text),
					align: "center"
				},
			]
		}

		if ((["insulation_type", "outer_packaging_materials", "additional_fields"] as any).includes(modalType)) {
			return [
				{
					title: "Fields",
					dataIndex: "fields",
					key: "fields",
					align: "center",
					width: 20,
				},
				{
					title: "Value",
					dataIndex: "value",
					key: "value",
					align: "center",
					width: 100,
				},
			]
		}

		// DEFAULT FOR ISOLATION SHEETS
		return [
			{
				title: "Material Type",
				dataIndex: "material_type",
				key: "material_type",
				width: 20,
				render: (text: string) =>
					names.insulation_type?.[text] ?? toTitleCase(text),
			},
			{
				title: t("common.position"),
				dataIndex: "position",
				key: "position",
				width: 20,
				render: (text: Position) => toTitleCase(text),
			},
			{
				title: "Length(mm)",
				key: "length",
				dataIndex: "length",
				width: 20,
			},
			{
				title: "Width(mm)",
				key: "width",
				dataIndex: "width",
				width: 20,
			},
			{
				title: "Thickness(mm)",
				key: "thickness",
				dataIndex: "thickness",
				width: 20,
			},
		]
	}, [names, modalData, modalType, t])

	return (
		<>
			{modalType === "insulation_sheets" &&
				modalData.insulation_sheets &&
				modalData.trial_display_id && (
					<WaterModal
						data={modalData.insulation_sheets}
						columns={columns}
						title={modalData.trial_display_id}
						visible={modalVisible}
						onCancel={resetModal}
					/>
				)}

			{modalType === "insulation_thickness" &&
				modalData?.insulation_thickness &&
				modalData.trial_display_id && (
					<WaterModal
						data={modalData.insulation_thickness}
						columns={columns}
						title={modalData.trial_display_id}
						visible={modalVisible}
						onCancel={resetModal}
					/>
				)}

			{modalType === "outer_packaging" &&
				modalData?.outer_packaging &&
				modalData.trial_display_id && (
					<WaterModal
						data={modalData.outer_packaging}
						columns={columns}
						title={modalData.trial_display_id}
						visible={modalVisible}
						onCancel={resetModal}
					/>
				)}

			{modalType === "water" &&
				modalData.water &&
				modalData.trial_display_id && (
					<WaterModal
						data={modalData.water}
						columns={columns}
						title={modalData.trial_display_id}
						visible={modalVisible}
						onCancel={resetModal}
					/>
				)}

			{((["insulation_type", "outer_packaging_materials", "additional_fields"] as any).includes(modalType)) &&
				modalData.trial_display_id && (
					<WaterModal
						data={getData(!!modalType ? modalData?.[modalType] : {}, modalType ?? "")}
						columns={columns}
						title={`${modalData.trial_display_id} - ${modalType?.split("_").map((res: string) => res?.[0].toUpperCase() + res.slice(1)).join(" ")}`}
						visible={modalVisible}
						onCancel={resetModal}
					/>
				)}

			{modalType === "pcm_type" &&
				modalData.pcm_type &&
				modalData.trial_display_id && (
					<PcmDetailsModal
						data={modalData.pcm_type}
						title={modalData.trial_display_id}
						visible={modalVisible}
						onCancel={resetModal}
					/>
				)}

			{modalType === "ambient" &&
				modalData.ambient &&
				modalData.trial_display_id && (
					<AmbientModal
						title={modalData.trial_display_id}
						data={modalData.ambient}
						visible={modalVisible}
						onCancel={resetModal}
					/>
				)}
		</>
	)
}