import { Col, Row, Space, Typography } from "antd"

const { Text } = Typography


export const ModalTitle = ({ title, subTitle, extra }: any) => {
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Row justify='space-between'>
                    <Col>{title}</Col>
                    {!!extra && <Col style={{ marginRight: 40 }}>{extra}</Col>}
                </Row>
            </Space>
            {!!subTitle && (<Space>
                <Text type='secondary' style={{ width: "100%", color: "#000000", opacity: 0.2 }}>
                    <small>{subTitle}</small>
                </Text>
            </Space>)}
        </Space>
    )
}
