import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { convertToPrecision, isValidNumber } from "./decorator/decorator";
import Big from "big.js";
import { useCallback } from "react";

enum LocaleValues {
    eu = "de_DE",
    en = "en_US"
}

export const getPrecisionValue = (inputValue: number, defaultPrecision: number = 3) => {
    if (inputValue > -1 && inputValue < 1) {
        return 6
    } else {
        return defaultPrecision
    }
}


export function useValue() {
    const locale = useSelector((state: StoreState) => state.configs.features?.locale)

    // converts numeric values into EU notation string (e.g - 1234567.89 => "1.234.567,89")
    const getValue = useCallback((value: number | string | Big | null | undefined, precision: number = 3) => {
        if (!isValidNumber(value)) {
            return value
        }
        if (locale === LocaleValues.eu) {
            const numericValue = Number(value)
            return numericValue.toLocaleString('de-DE', {
                maximumFractionDigits: getPrecisionValue(numericValue, precision)
            })
        }
        return convertToPrecision(value, precision)
    }, [locale])

    // converts EU notation string values into numeric (e.g - "1.234.567,89" => 1234567.89)
    const convertValue = useCallback((value: number | string | Big | null, precision: number = 3, numeric = false) => {
        if (!value) {
            return isValidNumber(value) ? 0 : null
        }
        if (locale === LocaleValues.eu) {
            if (String(value)?.includes(",") || String(value)?.includes(".")) {
                return convertToPrecision(parseFloat(String(value).replace(/\./g, '').replace(',', '.')), precision)
            }
        }
        return convertToPrecision(value, precision)
    }, [locale])

    // return decimal for antd InputNumber
    const getDecimalSeparator = useCallback(() => {
        if (locale === LocaleValues.eu) {
            return ","
        }
        return "."
    }, [locale])

    // return formatter for antd InputNumber
    const formatter = (value: any) => {
        if (locale === LocaleValues.eu) {
            if (!value) return ''
            let parts = `${value}`.split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            return parts.join(',')
        } else {
            if (!value) return ''
            return value
        }
    };

    // return parser for antd InputNumber
    const parser = (value: any) => {
        if (locale === LocaleValues.eu) {
            return value ? parseFloat(value.replace(/\./g, '').replace(',', '.')) : ''
        } else {
            return value ? parseFloat(value.replace(/,/g, '')) : ''
        }
    };

    const countDecimalPlaces = (number: number) => {
        if (!isFinite(number)) return 1;

        let text = number.toString();
        let parts = text.split(".");

        if (parts.length === 1) return 1;

        let fraction = parts[1];
        return fraction.length;
    }

    const addZeroes = useCallback((value: any, zeroes: number = 2) => {
        if (value == null || value === "") return value;
        let numberValue;

        if (typeof value === 'string') {
            // Remove thousands separators and replace decimal separators
            value = value.replace(/\./g, '').replace(',', '.');
        }

        numberValue = Number(value);

        if (locale === LocaleValues.eu) {
            return numberValue.toLocaleString('de-DE', { minimumFractionDigits: countDecimalPlaces(numberValue) });
        }
        return numberValue.toLocaleString('en-US', { minimumFractionDigits: countDecimalPlaces(numberValue) });

    }, [locale])

    return { getValue, convertValue, getDecimalSeparator, addZeroes, formatter, parser }
}