import React, { useState } from "react"
import { Space, Table, Modal, Typography, Tooltip } from "antd"
import { InfoCircleFilled } from "@ant-design/icons";
import { EditableCell, EditableRow } from "./EditableCell"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import "./editableTable.css"
import { setIsEditing } from "src/store/actions/isEditing"
import { useDispatch } from "react-redux"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"

const { Text } = Typography

interface ConstantFactorsProps {
	setAddFactorsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
	setFactorModalCheck: React.Dispatch<React.SetStateAction<string>>
	ingredientsConstantData: any[]
	setIngredientsConstantData: React.Dispatch<React.SetStateAction<any[]>>
	processingConstanstData: any[]
	setProcessingConstanstData: React.Dispatch<React.SetStateAction<any[]>>
	setNewFactorsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const ConstantFactors = ({
	setAddFactorsModalVisible,
	ingredientsConstantData,
	setIngredientsConstantData,
	setFactorModalCheck,
	processingConstanstData,
	setProcessingConstanstData,
	setNewFactorsModalVisible,
}: ConstantFactorsProps) => {
	const [selectedRecords, setSelectedRecords] = useState<any>([])
	const [selectedRowsType, setSelectedRowsType] = useState<any>("")
	const [t] = useTranslate()
	const dispatch = useDispatch()

	const constantFactorColumns = [
		{
			dataIndex: "factor",
			title: t("common.factorName"),
			key: "factor",
			width: "40%",
		},
		{
			dataIndex: "unit",
			title: () => (
				<Space>
					<Text>{t("aiEngine.unitOfMeasurement")}</Text>
					<Tooltip title={t("constantFactors.editUnitsfromWO")}>
						<InfoCircleFilled />
					</Tooltip>
				</Space>
			),
			key: "unit",
			align: "center",
			width: "20%",
			render(text: any, record: any) {
				return {
					props: {
						style: { background: "#f0f0f0" },
					},
					children: <div>{text}</div>,
				}
			},
		},
		{
			dataIndex: "value",
			title: t("formulations.placeholder.value"),
			key: "value",
			width: "40%",
			editable: true,
		},
	]

	const mergedColumns: any = constantFactorColumns.map((col) => {
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: any) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				inputType: "number",
				title: col.title,
				handleSave: (row: any) => {
					if (row.factorType === "ingredients") {
						setIngredientsConstantData((prevState: any) => {
							const newData = [...prevState]
							newData.forEach((data: any) => {
								if (data.key === record.key) {
									data.value = row.value
								}
							})
							return newData
						})
					} else if (row.factorType === "processing") {
						setProcessingConstanstData((prevState: any) => {
							const newData = [...prevState]
							newData.forEach((data: any) => {
								if (data.key === record.key) {
									data.value = row.value
								}
							})
							return newData
						})
					}
					dispatch(setIsEditing(true))
				},
			}),
		}
	})

	const deleteModal = () => {
		Modal.confirm({
			title: t("common.confirmDelete"),
			width: 500,
			icon: "",
			content: (
				<Text type="secondary">
					{t("aiEngine.youAreDeleting")}{" "}
					<Text strong>
						{selectedRecords?.length} {t("common.records")}{" "}
					</Text>
					{t("common.cannotUndoOperation")}
				</Text>
			),
			okText: t("common.confirm"),
			cancelText: t("common.cancel"),
			onOk: () => {
				if (selectedRowsType === "ingredients") {
					setIngredientsConstantData(
						ingredientsConstantData.filter(
							(data: any) =>
								!selectedRecords.map((res: any) => res.key).includes(data.key)
						)
					)
				} else if (selectedRowsType === "processing") {
					setProcessingConstanstData(
						processingConstanstData.filter(
							(data: any) =>
								!selectedRecords.map((res: any) => res.key).includes(data.key)
						)
					)
				}
				setSelectedRecords([])
			},
		})
	}

	const moveRow = (dragIndex: any, hoverIndex: any, type: string) => {
		if (type === "ingredientConstantFactors") {
			setIngredientsConstantData((prevState: any) => {
				const newArray = [...prevState]
				const [removed] = newArray.splice(dragIndex, 1)
				newArray.splice(hoverIndex, 0, removed)
				return newArray
			})
		} else if (type === "processingConstantFactors") {
			setProcessingConstanstData((prevState: any) => {
				const newArray = [...prevState]
				const [removed] = newArray.splice(dragIndex, 1)
				newArray.splice(hoverIndex, 0, removed)
				return newArray
			})
		}
	}

	return (
		<Space size="large" direction="vertical" style={{ width: "100%" }}>
			<Space>
				<StyledButton type="primary" onClick={() => setNewFactorsModalVisible(true)}>
					{t("constantFactors.addNewFactors")}{" "}
				</StyledButton>
				<StyledButton
					onClick={() => {
						setFactorModalCheck("constantFactorsTable")
						setAddFactorsModalVisible(true)
					}}
				>
					{t("doe.factorFromInventory")}
				</StyledButton>
				{!!selectedRecords?.length && (
					<StyledButton icon={<StyledDeleteIcon />} onClick={deleteModal} danger>
						{t("common.delete")}
					</StyledButton>
				)}
			</Space>
			{!!ingredientsConstantData?.length && (
				<DndProvider backend={HTML5Backend}>
					<Table
						rowClassName={() => "editable-row"}
						title={() => (
							<Text strong>{t("aiEngine.ingredientConstantFactor")}</Text>
						)}
						columns={mergedColumns}
						dataSource={ingredientsConstantData}
						bordered
						pagination={false}
						components={{
							body: {
								row: EditableRow,
								cell: EditableCell,
							},
						}}
						onRow={(record, index) =>
						({
							index,
							moveRow: (dragIndex: any, hoverIndex: any) =>
								moveRow(dragIndex, hoverIndex, "ingredientConstantFactors"),
						} as any)
						}
						rowSelection={{
							type: "checkbox",
							selectedRowKeys: selectedRecords.map((res: any) => res.key),
							onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
								setSelectedRowsType("ingredients")
								setSelectedRecords(selectedRows)
							},
						}}
					/>
				</DndProvider>
			)}
			{!!processingConstanstData?.length && (
				<DndProvider backend={HTML5Backend}>
					<Table
						rowClassName={() => "editable-row"}
						columns={mergedColumns}
						dataSource={processingConstanstData}
						bordered
						title={() => (
							<Text strong>
								{t("constantFactors.processingConstantFactors")}
							</Text>
						)}
						pagination={false}
						components={{
							body: {
								row: EditableRow,
								cell: EditableCell,
							},
						}}
						onRow={(record, index) =>
						({
							index,
							moveRow: (dragIndex: any, hoverIndex: any) =>
								moveRow(dragIndex, hoverIndex, "processingConstantFactors"),
						} as any)
						}
						rowSelection={{
							type: "checkbox",
							selectedRowKeys: selectedRecords.map((res: any) => res.key),
							onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
								setSelectedRowsType("processing")
								setSelectedRecords(selectedRows)
							},
						}}
					/>
				</DndProvider>
			)}
		</Space>
	)
}
