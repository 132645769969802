import './Dashboard.scss'
import { motion } from 'framer-motion'
import Header from './Header'
import MainContent from './MainContent'
import RightSidebar from './RightSidebar'
import { Avatar, Tooltip, Typography } from 'antd'
import { useMemberName } from 'src/utils/useMemberName'
import { useEffect, useMemo } from 'react'
import useTranslate from 'src/utils/useTranslate'
import { useDispatch } from 'react-redux'
import { fetchProjectListRequest } from 'src/store/actions/projects'
import { antdTheme } from 'src/constants'

export const BORDER_COLOR = '#E4E4E4'
export const TEXT_PRIMARY = '#171725'
export const TEXT_SECONDARY = '#93989A'
export const BORDER_STYLE = `0.5px solid ${BORDER_COLOR}`

const Dashboard = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProjectListRequest({ set: true }));

  }, [dispatch])
  

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
      style={{
        marginLeft: '-24px',
        marginRight: '-24px',
        marginBottom: '-24px',
        marginTop: '-12px'
      }}
    >
      <Header />
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: 'calc(100vh - 60px)'
        }}
      >
        <MainContent />
        <RightSidebar />
      </div>
    </motion.div>
  )
}

export default Dashboard

const textStyles: React.CSSProperties = {
  color: `${TEXT_PRIMARY}`,
  margin: 0,
  maxWidth: '200px',
  verticalAlign: 'middle'
}

export const StyledText = (text: string) => {
  return (
    <Typography.Text
      style={textStyles}
      ellipsis={{
        tooltip: text
      }}
      strong
    >
      {text}
    </Typography.Text>
  )
}

export const stringSorter = (a: string, b: string):number => {
  return (a || '').toLowerCase().localeCompare((b||'').toLowerCase())
}

export const dateSorter = (a: any, b: any) => {
  return new Date(a || Date.now()).getTime() - new Date(b || Date.now()).getTime()
}

export const numberSorter = (a: number, b: number) => {
  return (a || 0) - (b || 0)
}

export const listFilter = (value:any, list:any[]) => {
  return list?.includes(value)
}

export const valueFilter = (value:any, compareAgainst:any) => {
  return (compareAgainst || '').indexOf((value || '')) === 0
}

export const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((n: string) => n[0])
    ?.slice(0, 2)
    .join('')
}

export const AvatarComponent = ({
  id,
  key = Date.now(),
  marginAuto = true
}: {
  id: string
  key?: number | string | undefined
  marginAuto?: boolean
}) => {
  const { getName } = useMemberName()

  const userName = useMemo(() => getName(id), [getName, id])

  return (
    <Tooltip title={userName} placement="top" key={key}>
      <Avatar
        style={{
          color: 'white',
          backgroundColor: TEXT_PRIMARY,
          fontSize: antdTheme.fontSize,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          ...(marginAuto?{margin: 'auto'}:{})
        }}
        size={27}
      >
        {getInitials(userName)}
      </Avatar>
    </Tooltip>
  )
}

export const AvatarComponentWithName = ({
  name,
  key = Date.now(),
  marginAuto = true
}: {
  name: string
  key?: number | string | undefined
  marginAuto?: boolean
}) => {

  return (
    <Tooltip title={name} placement="top" key={key}>
      <Avatar
        style={{
          color: 'white',
          backgroundColor: TEXT_PRIMARY,
          fontSize: antdTheme.fontSize,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          ...(marginAuto?{margin: 'auto'}:{})
        }}
        size={27}
      >
        {getInitials(name)}
      </Avatar>
    </Tooltip>
  )
}


export const useStatusNames = () => {
  const [t] = useTranslate()

  const projectStatusNames: { [key: string]: string } = useMemo(
    () => ({
      ongoing: t('projects.onGoing'),
      in_progress: t('projects.onGoing'),
      completed: t('projects.completed'),
      archived: t('projects.archived')
    }),
    [t]
  )

  const workorderStatusNames: { [key: string]: string } = useMemo(
    () => ({
      work_order_initiated: t('common.WorkOrderInitiated'),
      formulation_stage: t('common.Formulation'),
      processing_stage: t('common.Processing'),
      characterization_stage: t('common.Characterization'),
      properties_stage: t('common.Properties'),
      report_preview: t('common.WorkOrderPreview'),
      work_order_status: t('common.WorkOrderCompleted'),
      closed: t('common.closedWorkOrder')
    }),
    [t]
  )

  return { projectStatusNames, workorderStatusNames }
}