import { useCallback, useEffect, useMemo, useState } from "react"
import { keyColumn, floatColumn, CellProps } from "react-datasheet-grid"
import { Space } from "antd"
import Checkbox from "antd/lib/checkbox/Checkbox"
import "src/styles/Global.scss"

interface useDataSheetGridProps {
    data: Array<any>
    setData: React.Dispatch<React.SetStateAction<Array<any>>>
    type: string
}

interface useDataSheetGridState {
    copyAndAddRow: () => void
    onRowsChange: (data: Array<any>) => void
    removeRows: () => void
    selectedRows: Array<any>
    columns: Array<any>
    addRow: () => void
}

export const useData = ({
    data,
    setData,
    type,
}: useDataSheetGridProps): useDataSheetGridState => {

    const [selectedRows, setSelectedRows] = useState<any>([])

    useEffect(() => {
        setSelectedRows(
            data.filter((res: any) => res.select).map((row: any) => row.id)
        )
    }, [data])

    const copyAndAddRow = () => {
        setData((prevState: any) => {
            const duplicateRows = prevState
                .filter((res: any) => selectedRows.includes(res.id))
                .reduce(
                    (array: any, element: any, index: any) => [
                        ...array,
                        { ...element, id: prevState.length + index + 1 },
                    ],
                    []
                )
            return [...prevState, ...duplicateRows]
        })
    }

    const removeRows = () => {
        setData((prevState: any) => {
            const newState = prevState.filter(
                (res: any) => !selectedRows.includes(res.id)
            )
            newState.forEach((res: any, key: any) => {
                res.id = key + 1
            })
            return newState
        })
        setSelectedRows([])
    }

    const onRowsChange = (data: any) => {
        setData(data)
    }

    const addRow = useCallback(() => {
        switch (type) {
            case "profile":
                setData((prevState) => [
                    ...prevState,
                    {
                        select: false,
                        id: prevState.length + 1,
                        time: "",
                        temperature: "",
                    },
                ])
                break
        }
    }, [type, setData])

    const handleSelectAll = useCallback(
        (e: any) => {
            if (e.target.checked) {
                setSelectedRows(data.map((res: any) => res.id))
                setData(data?.map((ele: any) => Object.assign(ele, { select: true })))
            } else {
                setSelectedRows([])
                setData(data?.map((ele: any) => Object.assign(ele, { select: false })))
            }
        },
        [data, setData]
    )

    const columns = useMemo(() => {
        switch (type) {
            case "profile":
                return [
                    {
                        title: (
                            <Space>
                                <Checkbox
                                    checked={!!selectedRows?.length}
                                    onClick={handleSelectAll}
                                ></Checkbox>
                            </Space>
                        ),
                        width: "0 0 5px",
                        component: (row: CellProps<any>) => (
                            <Checkbox
                                style={{ margin: "0 auto" }}
                                checked={row.rowData.select}
                                onChange={(e) => {
                                    row.setRowData({ ...row.rowData, select: e.target.checked })
                                }}
                            />
                        ),
                    },
                    {
                        ...keyColumn("time", floatColumn),
                        title: "Time (mins)",
                    },
                    {
                        ...keyColumn("temperature", floatColumn),
                        title: "Temperature (degC)",
                    },
                ]
            default:
                return []
        }
    }, [
        type,
        handleSelectAll,
        selectedRows?.length,
    ])

    return {
        copyAndAddRow,
        onRowsChange,
        removeRows,
        selectedRows,
        columns,
        addRow,
    }
}
