import { Space, Card, Popconfirm } from "antd";
import { PlusOutlined, CopyOutlined } from "@ant-design/icons";
import { DynamicDataSheetGrid } from 'react-datasheet-grid'
import { useDataSheetGrid } from "src/utils/useDataSheetGrid"
import useTranslate from "src/utils/useTranslate";
import React from "react"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { StyledButton } from "src/styled_components/StyledButton";

interface DatasheetTableProps {
  data: Array<any>,
  setData: React.Dispatch<React.SetStateAction<Array<any>>>,
  type: string
  title: string
  setModalEditingState?: React.Dispatch<React.SetStateAction<boolean>>
  moveObj?: (value: string) => void
  hideActions?: boolean,
  setModalVisible?: React.SetStateAction<any>
}


export const DatasheetTable = React.forwardRef(({ data, setData, title, type, setModalEditingState, moveObj, hideActions = false, setModalVisible, }: DatasheetTableProps, ref) => {
  const [t] = useTranslate()

  const { selectedRows, copyAndAddRow, removeRows, onRowsChange, columns, addRow } = useDataSheetGrid({ data, setData, type, setModalEditingState, moveObj, setModalVisible })

  return (
    <Card type="inner" style={{ marginBottom: 20 }} title={title} extra={<Space size="small">
      {!hideActions && <>
        <StyledButton onClick={addRow} icon={<PlusOutlined />}>{t("common.addRow")}</StyledButton>
        <StyledButton disabled={!selectedRows.length} type="dashed" onClick={copyAndAddRow} icon={<CopyOutlined />}>{t("common.copyAndAddRow")}</StyledButton>
        <Popconfirm disabled={!selectedRows.length} title={"Sure to remove selected row(s) ?"} onConfirm={() => removeRows()}>
          <StyledButton disabled={!selectedRows.length} danger icon={<StyledDeleteIcon />}>{t("datasheet.deletRows")} </StyledButton>
        </Popconfirm>
      </>}
    </Space>}>
      <div style={{ width: "100%", overflow: "auto" }}>
        <DynamicDataSheetGrid
          ref={ref as any}
          lockRows
          value={data}
          onChange={onRowsChange}
          columns={columns as any}
        />
      </div>
    </Card>
  )
})
