import { Row } from "antd";
import "./Stepper.scss"
import { DoubleRightOutlined } from "@ant-design/icons";
import { ReactNode } from "react";

type TProps = {
    items: ReactNode[];
    current: number;
    fontSize: number;
    className?: string;
}

const Stepper = ({ items, current, fontSize, className }: TProps) => {
    return (
        <div className={className}>
            <Row justify="center" align="middle" className="stepper">
                {items.map((item, idx) => (
                    <Row key={idx} justify="start" align="middle">
                        <div style={{ fontSize: `${fontSize}px` }} className={`stepper-block`}>
                            <div
                                style={{ borderRadius: `${fontSize * 2}px`, width: `${fontSize * 2}px`, height: `${fontSize * 2}px` }}
                                className={`middle ${(idx < current) ? " in-progress" : ""}${(idx === current) ? " current" : ""}${(idx > current) ? " pending" : ""}`}>
                                {idx < current && <>&#10003;</>}
                                {idx >= current && <>{idx + 1}</>}
                            </div>
                            <div>{item}</div>
                        </div>
                        {idx !== (items.length - 1) && <div><DoubleRightOutlined className={`next-icon${idx < current ? " done-icon" : ""}`} /></div>}
                    </Row>
                ))}
            </Row>
        </div>
    )
}

export default Stepper;