import { PlusOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Input } from "antd";
import { useCallback, useMemo } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    addItem: () => void;
    setSelectedProperty: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedProperty: string | undefined;
    selectedProperties: string[];
    setSelectedProperties: React.Dispatch<React.SetStateAction<any[]>>;
    setSearchTerm: React.Dispatch<React.SetStateAction<string | undefined>>;
    searchTerm: string | undefined;
    filteredProperties: any[];
};

const PropertyDrawerSidebar = ({
    addItem,
    setSelectedProperty,
    selectedProperty,
    selectedProperties,
    setSelectedProperties,
    setSearchTerm,
    searchTerm,
    filteredProperties
}: TProps) => {
    const [t] = useTranslate();

    // const selectAllProperties = () => {
    //     if (selectedProperties.length === properties.length)
    //         setSelectedProperties([]);
    //     else setSelectedProperties(properties.map((p) => p.property_id));
    // };

    const addRemoveSelectedProperty = useCallback(
        (property_id: string) => {
            if (selectedProperties.findIndex((p) => p === property_id) !== -1)
                setSelectedProperties((prev) => prev.filter((p) => p !== property_id));
            else
                setSelectedProperties((prev) => [
                    ...prev,
                    filteredProperties.find((p) => p.property_id === property_id)?.property_id,
                ]);
        },
        [selectedProperties, setSelectedProperties, filteredProperties]
    );

    const propertiesByCategory = useMemo(() => {
        const filteredData = searchTerm
            ? filteredProperties.filter((p) =>
                JSON.stringify(Object.values(p))
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
            : filteredProperties;

        const uniqueCategories = [...new Set(filteredData.map((t) => t.category_name))].sort((a, b) => a?.localeCompare(b));

        const items = uniqueCategories.map(category_name => ({
            key: String(category_name),
            label: String(category_name),
            children: (
                <>
                    {filteredData
                        .filter((p) => p.category_name === category_name)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((p, idx) => (
                            <div
                                key={idx}
                                className={`property${selectedProperty &&
                                    p.property_id === selectedProperty
                                    ? " selected"
                                    : ""
                                    }`}
                                onClick={() => setSelectedProperty(p.property_id)}
                            >
                                <Checkbox
                                    checked={
                                        selectedProperties.findIndex((s) => s === p.property_id) !==
                                        -1
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addRemoveSelectedProperty(p.property_id);
                                    }}
                                />
                                {p.name}
                            </div>
                        ))}
                </>
            ),
        }));

        return items;
    }, [
        selectedProperties,
        setSelectedProperty,
        selectedProperty,
        addRemoveSelectedProperty,
        filteredProperties,
        searchTerm
    ]);

    return (
        <div className="property-sidebar">
            <div className="header">
                {/* <Checkbox
                    checked={selectedProperties.length === properties.length}
                    onClick={selectAllProperties}
                /> */}
                <div className="properties">
                    {`${t("repository.totalProperties")} (${filteredProperties.length})`}
                </div>
                <StyledButton
                    type="default"
                    style={{ borderRadius: "6px" }}
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={addItem}
                >
                    {t("common.add")}
                </StyledButton>
                <Input.Search
                    style={{ width: "100%" }}
                    placeholder={t("common.search")}
                    onSearch={(value) => setSearchTerm && setSearchTerm(value)}
                    allowClear
                />
            </div>
            <div className="body">
                <Collapse defaultActiveKey={[...new Set(filteredProperties.map((t) => t.category_name))]} items={propertiesByCategory} bordered={false} />
            </div>
        </div>
    );
};

export default PropertyDrawerSidebar;
