import { message, message as toast, notification } from "antd";
import { put, takeLatest, call, select, delay } from "redux-saga/effects";
import { editDisplayId, fetchExperiment, fetchSimilarFormulations } from "src/services/experiment";
import {
  ArchiveFormulationBody,
  EditFormulationDetailsBody,
} from "src/services/formulation/interface";
import {
  addParamApi,
  updateParamApi,
  removeParamApi,
  copyTrialsApi,
  createTrialApi,
  createWorkOrder,
  dataSanityApi,
  dataSanityDataApi,
  deleteFileListApi,
  deleteWorkOrder,
  editWorkOrder,
  fetchFileListApi,
  getWorkOrder,
  savePartialDataApi,
  stageExistsApi,
  uploadFileAttachmentApi,
  ZeonDataExportApi,
  woCommentsListApi,
  exportGraphApi
} from "src/services/workOrders";
import {
  archiveFormulation,
  autoSaveExperimentDetails,
  editFormulationDetails,
  getFormulationDetails,
} from "../../services/formulation";
import {
  fetchExperimentFailure,
  fetchExperimentRequest,
  fetchExperimentSuccess,
  fetchLinkedExperimentDetailsFailure,
  fetchLinkedExperimentDetailsRequest,
  fetchLinkedExperimentDetailsSuccess,
  fetchLinkedExperimentFailure,
  fetchLinkedExperimentRequest,
  fetchLinkedExperimentSuccess,
  fetchSimilarFormulationsFailure,
  fetchSimilarFormulationsRequest,
  fetchSimilarFormulationsSuccess,
} from "../actions/experiment";
import {
  editDisplayIdRequest,
  editDisplayIdSuccess,
  editDisplayIdFailure,
  createWorkOrderFailure,
  createWorkOrderRequest,
  deleteWorkOrderFailure,
  deleteWorkOrderRequest,
  deleteWorkOrderSuccess,
  editExperimentFailure,
  editExperimentRequest,
  editWorkOrderFailure,
  editWorkOrderRequest,
  editWorkOrderSuccess,
  formulationFailure,
  formulationRequest,
  formulationSuccess,
  referenceFormulationsFailure,
  referenceFormulationsRequest,
  referenceFormulationsSuccess,
  workOrderFailure,
  workOrderRequest,
  workOrderSuccess,
  archiveFormulationFailure,
  archiveFormulationSuccess,
  archiveFormulationRequest,
  dataExportSuccess,
  dataExportRequest,
  dataExportFailure,
  uploadGraphsDataFailure,
  uploadGraphsDataRequest,
  uploadGraphsDataSuccess,
  plotsDataFailure,
  plotsDataRequest,
  plotsDataSuccess,
  graphsDataHistoryFailure,
  graphsDataHistoryRequest,
  graphsDataHistorySuccess,
  woStatusChangeFailure,
  woStatusChangeRequest,
  woStatusChangeSuccess,
  setDuplicateWo,
  fetchFileListSuccess,
  fetchFileListFailure,
  fetchFileListRequest,
  uploadFileAttachmentSuccess,
  uploadFileAttachmentFailure,
  uploadFileAttachmentRequest,
  deleteFileAttachmentFailure,
  deleteFileAttachmentSuccess,
  deleteFileAttachmentRequest,
  stageExistsRequest,
  stageExistsSuccess,
  stageExistsFailure,
  saveProcessingProfilesRequest,
  saveProcessingProfilesFailure,
  saveProcessingProfilesSuccess,
  deleteProcessingProfilesFailure,
  deleteProcessingProfilesRequest,
  deleteProcessingProfilesSuccess,
  listProcessingProfilesFailure,
  listProcessingProfilesRequest,
  listProcessingProfilesSuccess,
  autoSaveExperimentRequest,
  autoSaveExperimentSuccess,
  dataSanityRequest,
  dataSanitySuccess,
  dataSanityFailure,
  dataSanityDataRequest,
  dataSanityDataFailure,
  dataSanityDataSuccess,
  editExperimentSuccess,
  createWorkOrderSetSuccess,
  createTrialRequest,
  createTrialSuccess,
  createTrialFailure,
  savePartialSuccess,
  savePartialFailure,
  savePartialRequest,
  copyTrialsSuccess,
  copyTrialsFailure,
  copyTrialsRequest,
  addParamSuccess,
  addParamFailure,
  updateParamSuccess,
  updateParamFailure,
  removeParamSuccess,
  removeParamFailure,
  addParamRequest,
  updateParamRequest,
  removeParamRequest,
  woBuildOfMaterialsSuccess,
  woBuildOfMaterialsFailure,
  woBuildOfMaterialsRequest,
  findAndUpdateSpecificExperimentDataRequest,
  findAndUpdateSpecificExperimentDataSuccess,
  findAndUpdateSpecificExperimentDataFailure,
  graphsDataDeleteRequest, graphsDataDeleteSuccess, graphsDataDeleteFailure,
  woCommentListRequest,
  woCommentListSuccess,
  woCommentListFailure,
  exportGraphFailure, exportGraphRequest, exportGraphSuccess
} from "../actions/workOrderDetails";
import { workOrdersRequest } from "../actions/workOrders";
import { StoreState, store } from "../configureStore";
import {
  dataExportApi,
  uploadGraphsDataApi,
  uploadRawGraphsDataApi,
  plotsDataApi,
  graphsDataHistoryApi,
  changeWoStatusApi,
  graphsDataDeleteApi
} from "../../services/workOrders";
import { history } from "src";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { displayNamesRequest } from "../actions/displayNames";
import jwtManager from "src/utils/jwtManager";
import {
  createEditProfileApi,
  DeleteProfileApi,
  listProfileApi,
} from "src/services/fileUpload";
import { firestoreDb } from "src/utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { uploadFileClear } from "../actions/files";
import { updateExperimentUnitCleanup } from "../actions/woUnitConversion";
import { AsyncStates, EXP_PAGE_SIZE } from "src/constants";
import performApiCallSaga, { performApiCallSagaWithIdExchange } from "./utils";
// import { GetBuildOfMaterialsApi } from "src/services/suggestedExp/suggestedExp";

type workOrderDetailsAction = {
  type: string;
  payload: any;
};

type EditFormulationAction = {
  type: string;
  payload: EditFormulationDetailsBody;
};

type ArchiveFormulationAction = {
  type: string;
  payload: ArchiveFormulationBody;
};

function* createWorkOrderSaga(
  action: workOrderDetailsAction
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const {
      suggested_experiment_id = [],
      prediction_experiment_ids = [],
      doe_id = [],
    } = yield select((state: StoreState) => state.workOrderDetails.workOrder);
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };

    const params = {
      ...action.payload,
      suggested_experiment_id,
      prediction_experiment_ids,
      doe_ids: doe_id,
    };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(createWorkOrder, params, headers);

    if (status === "Success") {
      yield put(displayNamesRequest({ backgroundFetch: true }));
      yield put(createWorkOrderSetSuccess(data))
      if (!!action.payload?.duplicate_work_order) {
        yield put(setDuplicateWo(data));
      } else {
        notification.success({ message: message });
      }
      if (
        !!data?.suggested_experiment_id?.length ||
        !!data?.prediction_experiment_ids?.length
      ) {
        delay(3000);
        yield put(workOrderRequest(data?.work_order_id));
      }
      if (
        action.type === "CREATE_WORK_ORDER_REQUEST" &&
        !Object.keys(action.payload).includes("duplicate_work_order")
      )
        history.push(`/work-orders/details/${data.work_order_id}/`);
    } else {
      yield put(createWorkOrderFailure(message));
      notification.error({
        message: message,
      });
    }
  } catch (error) {
    yield put(createWorkOrderFailure(messages[ln].internal_server_error));
    notification.error({
      message: messages[ln].internal_server_error,
    });
  }
}

function* editWorkOrderSaga(
  action: workOrderDetailsAction
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const params = {
      ...Object.fromEntries(Object.entries(action.payload).filter(([key]) => key !== 'updateDisplayNames')),
    };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(editWorkOrder, params, headers);

    if (status === "Success") {
      if (params.updateDisplayNames) yield put(displayNamesRequest({ backgroundFetch: true }));
      notification.success({ message });
      if (!!data?.length) {
        yield put(editWorkOrderSuccess(data[0]));
      } else {
        yield call(history.push, "/signup");
      }
    } else {
      yield put(editWorkOrderFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    yield put(editWorkOrderFailure(error));
    notification.error({
      message:
        (error as any)?.response?.data?.detail ??
        messages[ln].internal_server_error,
    });
  }
}

function* deleteWorkOrderSaga(
  action: workOrderDetailsAction
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const project_id = yield select(
      (state: StoreState) => state.projects.current
    );
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(deleteWorkOrder, action.payload, headers);
    if (status === "Success") {
      yield put(deleteWorkOrderSuccess(data));
      toast.success(message);
      if (action.payload.fetchWoProjectIdsPayload) {
        const payload = { ...action.payload.fetchWoProjectIdsPayload }
        yield put(workOrdersRequest(payload));
      }
      else yield put(workOrdersRequest({ project_id }));
    } else {
      yield put(deleteWorkOrderFailure(message));
      toast.error(message);
    }
  } catch (error) {
    yield put(deleteWorkOrderFailure(error));
    notification.error({
      message:
        (error as any)?.response?.data?.detail ??
        messages[ln].internal_server_error,
    });
  }
}

function* workOrderSaga(
  action: workOrderDetailsAction
): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const params = {
      work_order_id: action.payload,
    };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(getWorkOrder, params, headers);
    if (status === "Success") {
      yield put(workOrderSuccess(data));
    } else {
      yield put(workOrderFailure(message));
    }
  } catch (error) {
    yield put(workOrderFailure(error));
  }
}

function* referenceFormulationsSaga(
  action: workOrderDetailsAction
): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const params = {
      formulation_id: action.payload,
    };
    const {
      data: {
        result: { data, status, message = "Error" },
      },
    } = yield call(getFormulationDetails, params, headers);
    if (status === "Success") {
      yield put(referenceFormulationsSuccess(data));
    } else {
      yield put(referenceFormulationsFailure(message));
    }
  } catch (error) {
    yield put(referenceFormulationsFailure(error));
  }
}

function* formulationSaga(
  action: workOrderDetailsAction
): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const params = {
      formulation_id: action.payload,
    };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(getFormulationDetails, params, headers);
    if (status === "Success") {
      yield put(formulationSuccess(data));
    } else {
      yield put(formulationFailure(message));
    }
  } catch (error) {
    yield put(formulationFailure(error));
  }
}

function* editFormulationSaga(
  action: EditFormulationAction
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders, woExperimentUnitConversion: { updateExperimentWithUnitStatus } } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const params = action.payload;
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(editFormulationDetails, params, headers);
    if (status === "Success") {
      if (updateExperimentWithUnitStatus === AsyncStates.SUCCESS) {
        yield put(updateExperimentUnitCleanup())
      }
      yield put(editExperimentSuccess(data));
      toast.success(message);
    } else {
      yield put(editExperimentFailure(message));
      toast.error(message);
    }
  } catch (error) {
    yield put(editExperimentFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* autoSaveExperimentSaga(
  action: EditFormulationAction
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const params = action.payload;
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(autoSaveExperimentDetails, params, headers);
    if (status === "Success") {
      yield put(autoSaveExperimentSuccess({ data }));
    } else {
      yield put(editExperimentFailure(message));
      toast.error(message);
    }
  } catch (error) {
    yield put(editExperimentFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* archiveFormulationSaga(
  action: ArchiveFormulationAction
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  )
  try {
    const { defaultHeaders } = yield select((state) => state)
    const experiments = yield select((state) => state.workOrderDetails.experiment)
    const expTotal = yield select((state) => state.workOrderDetails.total)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const { formulation_id, current, experiment_id } = action.payload
    const {
      data: {
        result: { status, message },
      },
    } = yield call(archiveFormulation, { formulation_id }, headers)
    if (status === "Success") {
      yield put(archiveFormulationSuccess())
      if (formulation_id?.length === experiments?.length && current > 1) {
        const newPageNum = Math.ceil((expTotal - formulation_id.length) / EXP_PAGE_SIZE)
        const pageNum = current < newPageNum ? current : newPageNum
        yield put(fetchExperimentRequest({
          experiment_id: [experiment_id],
          page_num: pageNum,
          page_size: EXP_PAGE_SIZE,
        }))
      } else {
        yield put(fetchExperimentRequest({
          experiment_id: [experiment_id],
          page_num: current,
          page_size: EXP_PAGE_SIZE,
        }))
      }
      notification.success({ message })
    } else {
      yield put(archiveFormulationFailure(message))
    }
  } catch (error) {
    yield put(archiveFormulationFailure(error))
    message.error(messages[ln].internal_server_error)
  }
}

type ExperimentRequestAction = {
  type: string;
  payload: {
    experiment_id: string;
  };
};

function* fetchExperimentSaga({ payload }: ExperimentRequestAction) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data: responseData, status, message },
      },
    } = yield call(fetchExperiment, { ...payload }, headers);
    if (status === "Success") {
      yield put(fetchExperimentSuccess(responseData));
      yield put(uploadFileClear());
      yield put(updateExperimentUnitCleanup());
    } else {
      yield put(fetchExperimentFailure(message));
    }
  } catch (error) {
    yield put(fetchExperimentFailure(error));
  }
}

function* fetchLinkedExperimentSaga({ payload }: ExperimentRequestAction) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data: responseData, status, message },
      },
    } = yield call(fetchExperiment, payload, headers);
    if (status === "Success") {
      const { data } = responseData;
      yield put(fetchLinkedExperimentSuccess(data));
    } else {
      yield put(fetchLinkedExperimentFailure(message));
    }
  } catch (error) {
    yield put(fetchLinkedExperimentFailure(error));
  }
}

function* fetchLinkedExperimentDetailsSaga({
  payload,
}: ExperimentRequestAction) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data: responseData, status, message },
      },
    } = yield call(fetchExperiment, { ...payload }, headers);
    if (status === "Success") {
      const { data } = responseData;
      yield put(fetchLinkedExperimentDetailsSuccess(data));
    } else {
      yield put(fetchLinkedExperimentDetailsFailure(message));
    }
  } catch (error) {
    yield put(fetchLinkedExperimentDetailsFailure(error));
  }
}

function* fetchSpecificTrialDataSaga({ payload }: any) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data: responseData, status },
      },
    } = yield call(fetchExperiment, { ...payload }, headers);
    if (status === "Success") {
      const { data } = responseData;
      yield put(findAndUpdateSpecificExperimentDataSuccess(data))
    } else {
      yield put(findAndUpdateSpecificExperimentDataFailure())
    }
  } catch (error) {
    yield put(findAndUpdateSpecificExperimentDataFailure())
  }
}

function* fetchSimilarFormulationsSaga({
  payload,
}: ExperimentRequestAction) {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(fetchSimilarFormulations, { ...payload }, headers);
    if (status === "Success") {
      yield put(fetchSimilarFormulationsSuccess(data));
      notification.success({ message });
    } else {
      yield put(fetchSimilarFormulationsFailure(message));
      notification.error({ message });
    }
  } catch (error) {
    yield put(fetchSimilarFormulationsFailure(error));
    notification.error({ message });
  }
}

function* dataExportSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const configs = yield select((state: StoreState) => state.configs.features);
    const newPayload = Boolean(configs?.characterization_methods)
      ? payload?.from === "compare"
        ? { graphs: payload.graphs }
        : payload
      : payload?.from === "compare"
        ? { graphs: payload.graphs }
        : payload;

    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };

    const {
      data: {
        result: { data, status, message },
      },
    } = yield call(
      Boolean(configs?.characterization_methods)
        ? ZeonDataExportApi
        : dataExportApi,
      newPayload,
      headers
    );
    if (status === "Success") {
      yield put(dataExportSuccess(data));
      window.open(data?.download_link, "_blank");
    } else {
      yield put(dataExportFailure());
      message.error(message);
    }
  } catch (error: any) {
    yield put(dataExportFailure());
    message.error(messages[ln].internal_server_error);
  }
}

function* editDisplayIdSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message: apiResponseMessage },
      },
    } = yield call(editDisplayId, payload, headers);
    if (status === "Success") {
      message.success({ content: apiResponseMessage, duration: 1 });
      yield put(editDisplayIdSuccess(payload));
    } else {
      yield put(editDisplayIdFailure(apiResponseMessage));
    }
  } catch (error) {
    yield put(editDisplayIdFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* uploadGraphsDataSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(payload?.rawData ? uploadRawGraphsDataApi : uploadGraphsDataApi, payload?.formData, headers);

    if (status === "Success") {
      yield put(uploadGraphsDataSuccess(data));
      notification.success({ message });
    } else {
      yield put(uploadGraphsDataFailure());
      notification.error({ message });
    }
  } catch (error: any) {
    yield put(uploadGraphsDataFailure());
    notification.error({
      message:
        (error as any)?.response?.data?.detail ??
        messages[ln].internal_server_error,
    });
  }
}

function* plotsDataSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, data },
      },
    } = yield call(plotsDataApi, payload, headers);
    if (status === "Success") {
      yield put(plotsDataSuccess(data));
    } else {
      yield put(plotsDataFailure());
    }
  } catch (error) {
    yield put(plotsDataFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* graphsDataHistorySaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, data },
      },
    } = yield call(graphsDataHistoryApi, payload, headers);
    if (status === "Success") {
      yield put(graphsDataHistorySuccess(data));
    } else {
      yield put(graphsDataHistoryFailure());
    }
  } catch (error) {
    yield put(graphsDataHistoryFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* graphsDataDeleteSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message },
      },
    } = yield call(graphsDataDeleteApi, payload, headers);
    if (status === "Success") {
      yield put(plotsDataRequest({ work_order_id: payload?.work_order_id, experiment_id: payload?.experiment_id }))
      yield put(graphsDataHistoryRequest({ work_order_id: payload?.work_order_id, experiment_id: payload?.experiment_id }))
      yield put(graphsDataDeleteSuccess())
      notification.success({ message })
    } else {
      yield put(graphsDataDeleteFailure());
    }
  } catch (error) {
    yield put(graphsDataDeleteFailure());
    message.error(messages[ln].internal_server_error);
  }
}

function* woStatusChangeSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message },
      },
    } = yield call(changeWoStatusApi, payload, headers);
    if (status === "Success") {
      yield put(woStatusChangeSuccess());
      notification.success({ message });
      // if (payload.status === "closed") {
      //   yield call(history.push, "/work-orders?closed=true");
      // } else {
      yield call(history.push, "/work-orders");
      // }
    } else {
      yield put(woStatusChangeFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(woStatusChangeFailure(error));
    message.error(messages[ln].internal_server_error);
  }
}

function* uploadFileAttachmentSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, message, status },
      },
    } = yield call(uploadFileAttachmentApi, action.payload.formData, headers);

    if (status === "Success") {
      notification.success({ message });
      yield put(uploadFileAttachmentSuccess(data));
      yield put(fetchFileListRequest(action.payload.details));
    } else {
      notification.error({ message });
      yield put(uploadFileAttachmentFailure(message));
    }
  } catch (error) {
    console.log(error);
    yield put(uploadFileAttachmentFailure(error));
  }
}

function* fetchFileListSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { data, message, status },
      },
    } = yield call(fetchFileListApi, action.payload, headers);

    if (status === "Success") {
      yield put(fetchFileListSuccess(data));
    } else {
      yield put(fetchFileListFailure(message));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchFileListFailure(error));
  }
}

function* deleteFileAttachmentSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { file_id, work_order_stage, ...rest } = action.payload;
    const {
      data: {
        result: { data, message, status },
      },
    } = yield call(deleteFileListApi, { file_id }, headers);

    if (status === "Success") {
      notification.success({ message });
      yield put(deleteFileAttachmentSuccess(data));
      yield put(fetchFileListRequest(rest));
    } else {
      notification.error({ message });
      yield put(deleteFileAttachmentFailure(message));
    }
  } catch (error) {
    console.log(error);
    yield put(deleteFileAttachmentFailure(error));
  }
}

function* stageExistsSaga(action: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result,
      },
    } = yield call(stageExistsApi, action.payload, headers);
    const { stages, message, status } = result
    if (status === "Success") {
      console.log(action.payload);
      yield put(stageExistsSuccess(stages));
    } else {
      yield put(stageExistsFailure(message));
    }
  } catch (error) {
    yield put(stageExistsFailure());
  }
}

function* saveProcessingProfilesSaga({
  payload,
}: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message },
      },
    } = yield call(createEditProfileApi, payload, headers);
    if (status === "Success") {
      yield put(saveProcessingProfilesSuccess());
      notification.success({ message });
      yield put(listProcessingProfilesRequest());
    } else {
      yield put(saveProcessingProfilesFailure());
      notification.error({ message });
    }
  } catch (error) {
    yield put(saveProcessingProfilesFailure());
  }
}

function* deleteProcessingProfilesSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message },
      },
    } = yield call(DeleteProfileApi, payload, headers);
    if (status === "Success") {
      yield put(deleteProcessingProfilesSuccess());
      notification.success({ message });
      yield put(listProcessingProfilesRequest());
    } else {
      yield put(deleteProcessingProfilesFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(deleteProcessingProfilesFailure());
  }
}

function* listProcessingProfilesSaga({
  payload,
}: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(listProfileApi, payload, headers);
    if (status === "Success") {
      yield put(listProcessingProfilesSuccess(data));
    } else {
      yield put(listProcessingProfilesFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(listProcessingProfilesFailure());
  }
}

function* dataSanitySaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(dataSanityApi, payload, headers);
    if (status === "Success") {
      yield put(dataSanitySuccess(data));
      const unsubscribe = onSnapshot(
        doc(
          firestoreDb,
          process.env.REACT_APP_DS_COLLECTION ?? "",
          data?.redis_key
        ),
        (doc) => {
          const checkStatus = doc.data();
          if (checkStatus?.column_group_check?.status === "failed") {
            store.dispatch(dataSanitySuccess({ column_group_check: "failed" }));
            unsubscribe();
            store.dispatch(dataSanityDataRequest({ key: data?.redis_key }));
          }
          if (checkStatus?.column_group_check?.status === "in_progress") {
            store.dispatch(dataSanitySuccess({ checkStatus }));
          }
          if (checkStatus?.column_group_check?.status === "completed") {
            unsubscribe();
            store.dispatch(dataSanityDataRequest({ key: data?.redis_key }));
          }
        }
      );
    } else {
      yield put(dataSanityFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(dataSanityFailure());
  }
}

function* dataSanityDataSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(dataSanityDataApi, payload, headers);
    if (status === "Success") {
      yield put(dataSanityDataSuccess(data));
    } else {
      yield put(dataSanityDataFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(dataSanityDataFailure());
  }
}

function* createFormulationIdsSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const expTotal = yield select((state) => state.workOrderDetails.total);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(createTrialApi, payload, headers);
    if (status === "Success") {
      if (payload?.add_parameters) {
        yield put(fetchExperimentRequest({
          experiment_id: [payload?.experiment_id],
          page_num: Math.ceil((expTotal + 1) / EXP_PAGE_SIZE),
          page_size: EXP_PAGE_SIZE,
        }))
      } else {
        yield put(createTrialSuccess(data))
      }
    } else {
      yield put(createTrialFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(createTrialFailure());
  }
}

function* savePartialDataSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(savePartialDataApi, payload, headers);
    if (status === "Success") {
      yield put(savePartialSuccess(data));
      const formulation_id = payload?.data?.map((formulation: any) => formulation?.parameter_id)
      yield put(findAndUpdateSpecificExperimentDataRequest({ formulation_id }));
    } else {
      yield put(savePartialFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(savePartialFailure());
  }
}

function* copyTrialsSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const expTotal = yield select((state) => state.workOrderDetails.total);
    const experiments = yield select((state) => state.workOrderDetails.experiment);
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(copyTrialsApi, payload, headers);
    if (status === "Success") {
      if (payload?.formulation_ids?.length > (EXP_PAGE_SIZE - experiments?.length)) {
        yield put(fetchExperimentRequest({
          experiment_id: [payload?.experiment_id],
          page_num: Math.ceil((expTotal + payload?.formulation_ids?.length) / EXP_PAGE_SIZE),
          page_size: EXP_PAGE_SIZE,
        }))
      } else {
        yield put(copyTrialsSuccess(data));
      }
    } else {
      yield put(copyTrialsFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(copyTrialsFailure());
  }
}


function* exportGraphSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );
  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(exportGraphApi, payload, headers);
    if (status === "Success") {
      yield put(exportGraphSuccess())
      window.open(data?.download_link, "_blank");
    } else {
      yield put(exportGraphFailure());
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(exportGraphFailure());
  }
}


function* addParamSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSagaWithIdExchange(
    addParamApi,
    addParamSuccess,
    addParamFailure,
    payload
  );
}

function* updateParamSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSagaWithIdExchange(
    updateParamApi,
    updateParamSuccess,
    updateParamFailure,
    payload
  );
}

function* removeParamSaga({ payload }: any): Generator<any, any, any> {
  yield* performApiCallSaga(
    removeParamApi,
    removeParamSuccess,
    removeParamFailure,
    payload
  );
}

function* woBuildOfMaterialsSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  )

  try {
    // const { defaultHeaders } = yield select((state) => state)
    // const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      result
      // data: { result },
    } = {
      "result": {
        "message": "Options Data loaded successfully",
        "status": "Success",
        "data": {
          "experiments": [
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            },
            {
              "experiment_id": "EX_SUGG_70F3D0RJZX",
              "formulation_id": "FR_CFM57X0I41",
              "ingredients": {
                "Stage 1": {
                  "daach3cl_aq": {
                    "value": 32.107,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAA(CH3Cl) (aq)"
                  },
                  "water_aq_1": {
                    "value": 161.248,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water aq 1"
                  },
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 2": {
                  "water_1": {
                    "value": 34.418,
                    "unit": "mol%",
                    "category": "Aq Water",
                    "name": "water"
                  },
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 3": {
                  "span80_4_3": {
                    "value": 0.697,
                    "unit": "mol%",
                    "category": "Surfactant",
                    "name": "Span80 (4.3)"
                  },
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 4": {
                  "isopar_m": {
                    "value": 58.1,
                    "unit": "mol%",
                    "category": "Oil",
                    "name": "Isopar M"
                  },
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "Stage 5": {
                  "dam1": {
                    "value": 27.812,
                    "unit": "mol%",
                    "category": "Monomer",
                    "name": "DAM1"
                  },
                  "a-9300": {
                    "value": 0.004,
                    "unit": "mol%",
                    "category": "Crosslinker",
                    "name": "A-9300"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "processing": {
                "Stage 1": {
                  "ph": {
                    "value": 7.8,
                    "unit": "-",
                    "category": "Sludge",
                    "name": "pH"
                  }
                },
                "Stage 2": {
                  "ss": {
                    "value": 1.83,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "SS"
                  }
                },
                "Stage 3": {
                  "ts": {
                    "value": 0.94,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "TS"
                  }
                },
                "Stage 4": {
                  "vss": {
                    "value": 58.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VSS"
                  }
                },
                "Stage 5": {
                  "vts": {
                    "value": 43.9,
                    "unit": "%",
                    "category": "Sludge",
                    "name": "VTS"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              },
              "predicted_properties": {
                "Stage 1": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 2": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 3": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 4": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "Stage 5": {
                  "ﾌﾛｯｸ径_flock_diameter": {
                    "value": 24.1,
                    "std": 2.29,
                    "unit": "-",
                    "category": "Floculation",
                    "name": "ﾌﾛｯｸ径 (Flock Diameter)"
                  }
                },
                "All Stages": {
                  "Total": {
                    "value": 10,
                    "std": 2.29,
                    "unit": "-",
                    "category": "",
                    "name": "Total"
                  }
                }
              }
            }
          ]
        },
        "total": 20
      }
    }

    // yield call(GetBuildOfMaterialsApi, payload, headers)
    if (result.status === "Success") {
      yield put(
        woBuildOfMaterialsSuccess({
          experiments: result.data.experiments,
          total: result.total,
        })
      )
    } else {
      yield put(woBuildOfMaterialsFailure(result.message))
    }
  } catch (error) {
    yield put(woBuildOfMaterialsFailure(messages[ln].internal_server_error))
  }
}

function* woCommentListSaga({ payload }: any): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  )

  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data },
      },
    } = yield call(woCommentsListApi, payload, headers)
    if (status === "Success") {
      yield put(woCommentListSuccess(data))
    } else {
      yield put(woCommentListFailure())
    }
  } catch (error) {
    yield put(woCommentListFailure())
    message.error(messages[ln].internal_server_error)
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(fetchExperimentRequest, fetchExperimentSaga);
  yield takeLatest(fetchLinkedExperimentRequest, fetchLinkedExperimentSaga);
  yield takeLatest(
    fetchLinkedExperimentDetailsRequest,
    fetchLinkedExperimentDetailsSaga
  );
  yield takeLatest(fetchSimilarFormulationsRequest, fetchSimilarFormulationsSaga);
  yield takeLatest(archiveFormulationRequest, archiveFormulationSaga);
  yield takeLatest(editExperimentRequest, editFormulationSaga);
  yield takeLatest(autoSaveExperimentRequest, autoSaveExperimentSaga);
  yield takeLatest(formulationRequest, formulationSaga);
  yield takeLatest(referenceFormulationsRequest, referenceFormulationsSaga);
  yield takeLatest(createWorkOrderRequest, createWorkOrderSaga);
  yield takeLatest(editWorkOrderRequest, editWorkOrderSaga);
  yield takeLatest(deleteWorkOrderRequest, deleteWorkOrderSaga);
  yield takeLatest(workOrderRequest, workOrderSaga);
  yield takeLatest(dataExportRequest, dataExportSaga);
  yield takeLatest(editDisplayIdRequest, editDisplayIdSaga);
  yield takeLatest(uploadGraphsDataRequest, uploadGraphsDataSaga);
  yield takeLatest(plotsDataRequest, plotsDataSaga);
  yield takeLatest(graphsDataHistoryRequest, graphsDataHistorySaga);
  yield takeLatest(graphsDataDeleteRequest, graphsDataDeleteSaga);
  yield takeLatest(woStatusChangeRequest, woStatusChangeSaga);
  yield takeLatest(fetchFileListRequest, fetchFileListSaga);
  yield takeLatest(uploadFileAttachmentRequest, uploadFileAttachmentSaga);
  yield takeLatest(deleteFileAttachmentRequest, deleteFileAttachmentSaga);
  yield takeLatest(stageExistsRequest, stageExistsSaga);
  yield takeLatest(saveProcessingProfilesRequest, saveProcessingProfilesSaga);
  yield takeLatest(
    deleteProcessingProfilesRequest,
    deleteProcessingProfilesSaga
  );
  yield takeLatest(listProcessingProfilesRequest, listProcessingProfilesSaga);
  yield takeLatest(dataSanityRequest, dataSanitySaga);
  yield takeLatest(dataSanityDataRequest, dataSanityDataSaga);
  yield takeLatest(createTrialRequest, createFormulationIdsSaga);
  yield takeLatest(savePartialRequest, savePartialDataSaga);
  yield takeLatest(copyTrialsRequest, copyTrialsSaga);
  yield takeLatest(addParamRequest, addParamSaga);
  yield takeLatest(updateParamRequest, updateParamSaga);
  yield takeLatest(removeParamRequest, removeParamSaga);
  yield takeLatest(woBuildOfMaterialsRequest, woBuildOfMaterialsSaga);
  yield takeLatest(findAndUpdateSpecificExperimentDataRequest, fetchSpecificTrialDataSaga);
  yield takeLatest(woCommentListRequest, woCommentListSaga);
  yield takeLatest(exportGraphRequest, exportGraphSaga);
}
