import { put, takeLatest, call, all, select } from "redux-saga/effects";
import { loginSuccess } from "../actions";
import { messages } from "src/utils/hooks";
import {
  checkUserExists,
  getIpDetails,
} from "src/services/login";
import {
  loginFirebaseRequest,
  loginFirebaseFailure,
  loginFirebaseSuccess,
  loginFirebaseClear,
} from "../actions/firebase";
import { defaultHeaders } from "src/constants";
import { signupFailure } from "../actions/signup";
import { history } from "src";
import { notification } from "antd";
import "firebase/auth";
import { loginNotVerified } from "../actions/login";
import { LanguageUnion } from 'src/utils/useTranslate';
import { StoreState } from '../configureStore';
import { GoogleAuthProvider, getAdditionalUserInfo, getRedirectResult } from "firebase/auth";
import { auth, handleFirebaseLogin } from "src/utils/firebase";

function* loginFirebaseSaga(): any {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  )

  try {
    const response = yield all([
      call(getIpDetails),
      getRedirectResult(auth)
    ])
    const [
      {
        data: { ip, country }
      },
      result
    ] = response
    if (result) {
      const user = result.user
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const { providerId } = getAdditionalUserInfo(result) || {}
      const firebase_token = credential?.idToken
      const firebase_accessToken = credential?.accessToken;
      const locationDetails = {
        user_location: country,
        user_loggedin_IP: ip
      }
      const payloadForUserExists = {
        providerId,
        firebase_token,
        firebase_accessToken,
        user_name: user.displayName,
        user_email: user.email,
        uid: user.uid,
        image_url: user.photoURL
      };
      const displayName = user.displayName;

      const { data } = yield call(checkUserExists, { ...payloadForUserExists, ...locationDetails }, defaultHeaders);
      const userExistsResponse = data?.result?.data

      if (data?.result?.status === "Success") {
        yield put(
          loginFirebaseSuccess({
            ...payloadForUserExists,
            displayName: displayName,
          })
        );

        if (userExistsResponse.user_exists) { // restrict on company domains
          if (userExistsResponse.user_access) {
            if (!!userExistsResponse?.firebase_auth_token?.length) {
              yield call(handleFirebaseLogin, userExistsResponse?.firebase_auth_token);
            }
            yield put(loginSuccess(userExistsResponse));
            yield call(history.push, "/formulations");
          } else {
            yield put(loginNotVerified());
            yield call(history.push as any, "/access-denied", {
              user_email: user.email,
              user_name: user.displayName,
              // user_verified: data.user_verified,
              user_access: userExistsResponse.user_access,
            });
          }
        } else {
          yield call(history.push, "/signup");
          yield put(signupFailure("Kindly, fill in the form to proceed with signup!"))
        }
      } else {
        window.sessionStorage.removeItem("isLoginWithFirebaseInitiated")
        if (data?.result?.redirect) {
          yield put(loginFirebaseFailure(`${data?.result?.message}. <br> <a style="color:white;font-weight: bold" href=${data?.result?.data?.url} rel="noreferrer" target="_blank">Click Here to Login to ${data?.result?.data.platform}</a>`))
        } else {
          yield put(loginFirebaseFailure(messages[ln].internal_server_error));
        }
      }
    } else {
      yield put(loginFirebaseClear());
      window.sessionStorage.removeItem("isLoginWithFirebaseInitiated")
    }
  } catch (error: any) {
    if (error?.code === "auth/account-exists-with-different-credential") {
      notification.error({
        message: 'Access Denied',
        description: error.message
      });
    }
    yield put(loginFirebaseFailure(messages[ln].internal_server_error));
    window.sessionStorage.removeItem("isLoginWithFirebaseInitiated")
  }
}

export default function* rootSaga() {
  yield takeLatest(loginFirebaseRequest, loginFirebaseSaga);
}
