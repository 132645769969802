import { DownSquareFilled } from "@ant-design/icons"
import { Checkbox, Form, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux"
import { antdTheme, AsyncStates } from "src/constants";
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"

const StageAndParamFilter = ({ identifier, form, setSubmitAllowed }: any) => {
  // Other utility hooks
  const [t] = useTranslate()

  // Selectors
  const { multistageHeatmapOptionsResponse, multistageHeatmapOptionsStatus } =
    useSelector((state: StoreState) => state.multiStageHeatmap)

  // States
  const [ingredients, setIngredients] = useState<string[]>([])
  const [processing, setProcessing] = useState<string[]>([])
  const [properties, setProperties] = useState<string[]>([])

  // Effects
  useEffect(() => {
    if (multistageHeatmapOptionsStatus === AsyncStates.SUCCESS) {
      setIngredients(multistageHeatmapOptionsResponse?.[identifier]?.ingredients)
      setProcessing(multistageHeatmapOptionsResponse?.[identifier]?.processing)
      setProperties(multistageHeatmapOptionsResponse?.[identifier]?.properties)
    }
  }, [identifier, multistageHeatmapOptionsResponse, multistageHeatmapOptionsStatus])

  // Memo

  const stageData = useMemo(() => {
    return [multistageHeatmapOptionsResponse?.[identifier]]
  }, [identifier, multistageHeatmapOptionsResponse])

  const displayNames = useMemo(() => {
    return multistageHeatmapOptionsResponse?.display_names
  }, [multistageHeatmapOptionsResponse?.display_names])

  const stageOptions = useMemo(() => {
    return stageData?.map((stage: any) => {
      return (
        <Select.Option key={stage?.experiment_id} value={stage?.experiment_id}>
          {stage.stage_name}
        </Select.Option>
      )
    })
  }, [stageData])


  const ingredientOptions = useMemo(() => {
    return stageData?.map((stage: any) => {
      return stage.ingredients.map((ingredient: any) => {
        return (
          <Select.Option key={ingredient} value={ingredient}>
            {displayNames?.ingredients?.[ingredient] || ingredient}
          </Select.Option>
        )
      })
    }).flat()
  }, [displayNames?.ingredients, stageData])

  const processingOptions = useMemo(() => {
    return stageData?.map((stage: any) => {
      return stage.processing.map((processing: any) => {
        return (
          <Select.Option key={processing} value={processing}>
            {displayNames?.processing?.[processing] || processing}
          </Select.Option>
        )
      })
    }).flat()
  }, [displayNames, stageData])

  const propertiesOptions = useMemo(() => {
    return stageData?.map((stage: any) => {
      return stage.properties.map((property: any) => {
        return (
          <Select.Option key={property} value={property}>
            {displayNames?.properties?.[property] || property}
          </Select.Option>
        )
      })
    }).flat()
  }, [displayNames, stageData])

  return (
    <div
      style={{
        width: "50%",
      }}
    >
      <Form.Item label={identifier === 'initial_stage' ? t("common.startStage") : t("common.endStage")}>
      </Form.Item>
      <Form.Item label={identifier === 'initial_stage' ? t("common.startStage") : t("common.endStage")} name={`${identifier}`} hidden >
        <Select
          placeholder={identifier === 'initial_stage' ? t("common.startStage") : t("common.endStage")}
          optionFilterProp="children"
          maxTagCount={2}
          // mode="multiple"
          showSearch
          allowClear
          defaultValue={stageData?.[0]?.experiment_id}
          style={{ maxWidth: '400px' }}
          onChange={(value) => {
            if (value === 'all') {
              form.setFieldValue(`${identifier}-ingredients`, stageData?.[0]?.ingredients)
            }
          }}
        >
          {stageOptions}
        </Select>
      </Form.Item>

      <Form.Item label={t("common.ingredient")} name={`${identifier}-ingredients`} >
        <Select
          placeholder={t("common.ingredient")}
          optionFilterProp="children"
          maxTagCount={2}
          mode="multiple"
          showSearch
          allowClear
          style={{ maxWidth: '400px' }}
          suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#2980B9', paddingRight: 16 }} />}
          value={ingredients}
          onChange={(value) => {
            setIngredients(value)
            form.setFieldValue(`${identifier}-ingredients`, value)
          }}
          dropdownRender={(menu) => {
            const checked = ingredientOptions.length === ingredients.length
            return (
              <>
                <Checkbox
                  style={{ padding: 10 }}
                  checked={checked}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setIngredients(ingredientOptions.map((option: any) => option.props.value))
                      form.setFieldValue(`${identifier}-ingredients`, ingredientOptions.map((option: any) => option.props.value))
                    } else {
                      setIngredients([])
                      form.setFieldValue(`${identifier}-ingredients`, [])
                    }
                  }}
                >
                  {t("common.selectAllIngredients")}
                </Checkbox>
                {menu}
              </>
            )
          }}
        >
          {ingredientOptions}
        </Select>
      </Form.Item>

      <Form.Item label={t("common.processing")} name={`${identifier}-processing`} >
        <Select
          placeholder={t("common.processing")}
          optionFilterProp="children"
          maxTagCount={2}
          mode="multiple"
          showSearch
          allowClear
          style={{ maxWidth: '400px' }}
          suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#633974', paddingRight: 16 }} />}
          value={processing}
          onChange={(value) => {
            setProcessing(value)
            form.setFieldValue(`${identifier}-processing`, value)
          }}
          dropdownRender={(menu) => {
            const checked = processingOptions.length === processing.length
            return (
              <>
                <Checkbox
                  style={{ padding: 10 }}
                  checked={checked}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setProcessing(processingOptions.map((option: any) => option.props.value))
                      form.setFieldValue(`${identifier}-processing`, processingOptions.map((option: any) => option.props.value))
                    } else {
                      setProcessing([])
                      form.setFieldValue(`${identifier}-processing`, [])
                    }
                  }}
                >
                  {t("common.selectAllProcessings")}
                </Checkbox>
                {menu}
              </>
            )
          }}
        >
          {processingOptions}
        </Select>
      </Form.Item>

      <Form.Item label={t("common.properties")} name={`${identifier}-properties`}
      >
        <Select
          placeholder={t("common.properties")}
          optionFilterProp="children"
          maxTagCount={2}
          mode="multiple"
          showSearch
          allowClear
          style={{ maxWidth: '400px' }}
          suffixIcon={<DownSquareFilled style={{ fontSize: antdTheme.fontSizeHeading3, color: '#F39C12', paddingRight: 16 }} />}
          value={properties}
          onChange={(value) => {
            setProperties(value)
            form.setFieldValue(`${identifier}-properties`, value)
            if (identifier === 'final_stage' && (value.length <= 0 || value === undefined)) {
              setSubmitAllowed(false)
            } else {
              setSubmitAllowed(true)
            }
          }}
          dropdownRender={(menu) => {
            const checked = propertiesOptions.length === properties.length
            return (
              <>
                <Checkbox
                  style={{ padding: 10 }}
                  checked={checked}
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setProperties(propertiesOptions.map((option: any) => option.props.value))
                      form.setFieldValue(`${identifier}-properties`, propertiesOptions.map((option: any) => option.props.value))
                      if (identifier === 'final_stage') setSubmitAllowed(true)
                    } else {
                      setProperties([])
                      form.setFieldValue(`${identifier}-properties`, [])
                      if (identifier === 'final_stage') setSubmitAllowed(false)

                    }
                  }}
                >
                  {t("common.selectAllProperties")}
                </Checkbox>
                {menu}
              </>
            )
          }}
        >
          {propertiesOptions}
        </Select>

      </Form.Item>
    </div>
  )
}

export default StageAndParamFilter
