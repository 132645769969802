import { PlusOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Input } from "antd";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

type TProps = {
    addItem: () => void;
    setSelectedTemplateId: React.Dispatch<
        React.SetStateAction<string | undefined>
    >;
    selectedTemplateId: string | undefined;
    selectedTemplates: string[];
    setSelectedTemplates: React.Dispatch<React.SetStateAction<any[]>>;
    setSearchTerm: React.Dispatch<React.SetStateAction<string | undefined>>;
    searchTerm: string | undefined;
};

const IngredientTemplateSidebar = ({
    addItem,
    setSelectedTemplateId,
    selectedTemplateId,
    selectedTemplates,
    setSelectedTemplates,
    setSearchTerm,
    searchTerm,
}: TProps) => {
    const [t] = useTranslate();
    const templates = useSelector(
        (state: StoreState) => state.repository.templates.data
    );
    const categories = useSelector(
        (state: StoreState) => state.repository.categories.data
    );

    const addRemoveSelectedTemplate = useCallback(
        (template_id: string) => {
            if (selectedTemplates.findIndex((t) => t === template_id) !== -1)
                setSelectedTemplates((prev) => prev.filter((p) => p !== template_id));
            else
                setSelectedTemplates((prev) => [
                    ...prev,
                    templates.find((t) => t.template_id === template_id)?.template_id,
                ]);
        },
        [selectedTemplates, setSelectedTemplates, templates]
    );

    const templatesByCategory = useMemo(() => {
        const filteredData = searchTerm
            ? templates.filter((t) =>
                JSON.stringify(Object.values(t))
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
            : templates;

        const uniqueCategories = [
            ...new Set(filteredData.map((t) => t.category_id)),
        ];

        const items = uniqueCategories.map((category_id) => ({
            key: String(category_id),
            label: String(
                categories.find((c) => c.category_id === category_id)?.name || "-"
            ),
            children: (
                <>
                    {filteredData
                        .filter((t) => t.category_id === category_id)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((t, idx) => (
                            <div
                                key={idx}
                                className={`template${selectedTemplateId && t.template_id === selectedTemplateId
                                        ? " selected"
                                        : ""
                                    }`}
                                onClick={() => setSelectedTemplateId(t.template_id)}
                            >
                                <Checkbox
                                    checked={
                                        selectedTemplates.findIndex((s) => s === t.template_id) !==
                                        -1
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addRemoveSelectedTemplate(t.template_id);
                                    }}
                                />
                                {t.name}
                            </div>
                        ))}
                </>
            ),
        })).sort((a, b) => a.label.localeCompare(b.label));;

        return items;
    }, [
        selectedTemplateId,
        setSelectedTemplateId,
        selectedTemplates,
        addRemoveSelectedTemplate,
        templates,
        searchTerm,
        categories,
    ]);

    return (
        <div className="ingredient-template-sidebar">
            <div className="header">
                <div className="templates">
                    {`${t("repository.totalTemplates")} (${templates.length})`}
                </div>
                <StyledButton
                    type="default"
                    style={{ borderRadius: "6px" }}
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={addItem}
                >
                    {t("common.add")}
                </StyledButton>
                <Input.Search
                    style={{ width: "100%" }}
                    placeholder={t("common.search")}
                    onSearch={(value) => setSearchTerm && setSearchTerm(value)}
                    allowClear
                />
            </div>
            <div className="body">
                <Collapse items={templatesByCategory} bordered={false} />
            </div>
        </div>
    );
};

export default IngredientTemplateSidebar;
