import "../../utils/firebase";

import "firebase/auth";
import { GoogleAuthProvider, OAuthProvider, signInWithRedirect } from "firebase/auth";
import { auth } from "../../utils/firebase";

export enum Services {
  'gsuite' = 'google.com',
  'microsoft' = 'microsoft.com',
}

const servicePicker = {
  [Services.gsuite]: new GoogleAuthProvider().setCustomParameters({ hd: "*", prompt: "select_account" }),
  [Services.microsoft]: new OAuthProvider(Services.microsoft).setCustomParameters({ hd: "*", prompt: "select_account" })
}

export const signInViaFirebase = (service: Services) => {
  signInWithRedirect(auth, servicePicker[service])
    .catch(function (error) {
      // var errorCode = error.code;
      // var errorMessage = error.message;
    });
};
