import { Card, Space, Spin, Typography } from "antd";
import { memo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { PerformancePercentageResult } from "./PerformancePercentageResult";
import useTranslate from "src/utils/useTranslate";

type Props = {
  currentSelectedStage: number;
};

const { Title } = Typography;

export const PerformancePercentage = memo(({ currentSelectedStage }: Props) => {
  const [t] = useTranslate();

  const { performanceData, getPerformanceStatus } = useSelector(
    (state: StoreState) => state.performance,
  );
  return (
    <Card
      title={
        <Space direction="vertical">
          <Title level={4}>{t("aiEngine.modelAnalysis.dataAndModelPerformanceValidation")}</Title>
        </Space>
      }
    >
      <Spin spinning={getPerformanceStatus === AsyncStates.LOADING}>
        {getPerformanceStatus === AsyncStates.SUCCESS &&
          Object.keys(performanceData || {}).length > 0 && (
            <div
              id="performanceResult"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Card>
                {Object.keys(
                  performanceData.data_performance_percentage || {},
                ).map((property, index) => (
                  <PerformancePercentageResult
                    index={index}
                    property={property}
                    currentSelectedStage={currentSelectedStage}
                  />
                ))}
              </Card>
            </div>
          )}
      </Spin>
    </Card>
  );
});
