import React, { useMemo } from "react"
import { Space, Table, Typography } from "antd"
import { CategoricalDataSummary as Data } from "src/components/AIEngine/DataSummary/types"
import { ColumnType } from "antd/lib/table"
import { SummaryWrapper } from "../summary-wrapper"
import useTranslate from "src/utils/useTranslate"
const { Text } = Typography

type P = {
	data: Data
	getDisplayName: (value: string) => string
}

export const CategoricalDataSummary = ({ data, getDisplayName }: P) => {
	const [t] = useTranslate()

	const tableData = useMemo(() => {
		const keys = Object.keys(data)
		if (keys.length === 0) return []

		const firstElement = data[keys[0]]

		const fieldsArr: {
			field: keyof typeof firstElement
			[key: string]: string
		}[] = Object.keys(firstElement).map((key, i) => ({
			key: i.toString(),
			field: key as keyof typeof firstElement,
		}))

		fieldsArr.forEach((obj) => {
			keys.forEach((key) => {
				obj[key] = data[key][obj.field]
			})
		})

		return fieldsArr
	}, [data])

	const columns: ColumnType<object>[] = Object.keys(data).map((key) => {
		return {
			key,
			dataIndex: key,
			width: 125,
			align: "center",
			title: () => (
				<Space
					style={{ width: "100%" }}
					align="center"
					size="small"
					direction="vertical"
				>
					{getDisplayName(key)}
				</Space>
			),
		}
	})

	if (!tableData.length) return null

	return (
		<SummaryWrapper heading={t("dataSummary.categoricalDataSummary")} tooltip={t("aiEngine.tab.dataSummary.categoricalsdatasummary")} id={"categorical-data-summary"} key={"categorical-data-summary"}>
			<Space style={{ width: "100%", overflowX: "auto" }} direction="vertical">
				<Table
					columns={[
						{
							dataIndex: "field",
							title: t("common.stats"),
							key: "stats",
							fixed: "left" as any,
							width: 120,
							align: "center" as any,
							render: (text: string) => <Text strong>{text}</Text>,
						},
						...columns,
					]}
					dataSource={tableData}
					pagination={false}
					scroll={{ x: 5 }}
					bordered
				/>
			</Space>
		</SummaryWrapper>
	)
}
