import { put, takeLatest, call, select, take } from "redux-saga/effects"
import {
	boxPlotSummaryFailure,
	boxPlotSummaryRequest,
	boxPlotSummarySuccess,
	dataSummaryFailure,
	dataSummaryRequest,
	dataSummarySuccess,
	recipeDistributionFailure,
	recipeDistributionRequest,
	getZeonCharacterizationDsmFailure,
	getZeonCharacterizationDsmRequest,
	getZeonCharacterizationDsmSuccess,
	dataSummaryPlotOptionsSuccess,
	surfacePlotRequest,
	surfacePlotSuccess,
	surfacePlotFailure,
	analyticsRecipeDistributionSuccess,
	analyticsRecipeDistributionFailure,
	analyticsRecipeDistributionRequest,
	analyticsRecipeDistributionTableRequest,
	analyticsRecipeDistributionTableSuccess,
	analyticsRecipeDistributionTableFailure,
	savePlotsRequest,
	savePlotsSuccess,
	savePlotsFailure,
	getPlotsHistoryRequest,
	getPlotsHistorySuccess,
	getPlotsHistoryFailure,
	deleteSavedPlotsRequest,
	deleteSavedPlotsSuccess,
	deleteSavedPlotsFailure,
	formulationsDataFromCompareSuccess,
	dataSummaryStreamRequest,
	dataSummaryFiltersSuccess,
	dataSummaryFiltersRequest,
} from "src/store/actions/dataSummary"
import {
	boxPlotSummaryApi,
	dataAnalyticsPlotDataApi,
	dataSummaryStream,
	deleteSavedPlotsDataApi,
	getIndividualSummaryApi,
	getOverallSummaryApi,
	plotsHistoryDataApi,
	savePlotsDataApi,

} from "src/services/dataSummary"
import { messages } from "src/utils/hooks"
import { APIError } from "src/typings"
import { LanguageUnion } from 'src/utils/useTranslate'
import { StoreState } from '../configureStore'
import { message, notification } from "antd"
import { getCharacterizationMethodsZeonDsmApi } from "src/services/forwardModel"
import jwtManager from "src/utils/jwtManager"
import { fetchExperiment } from "src/services/experiment"
import { history } from "src"
import { END, eventChannel } from "redux-saga"
import { reconnect } from "../actions/connection"
import { AsyncStates } from "src/constants"

let dataSummarySocket: WebSocket;
let dataSummarySocketChannel: any;

function createDataSummaryChannel(socket: WebSocket) {
	return eventChannel((emit) => {

		socket.onmessage = (event: any) => {
			emit(event.data);
		};

		socket.onclose = () => {
			emit(END);
		};

		const unsubscribe = () => {
			socket.onmessage = null;
		};

		return unsubscribe;
	});
}


type NumericalSummaryPayload = {
	payload: {
		work_order_ids?: string[]
		type?: "ingredients" | "processing" | "properties"
		characterization_set_id?: "string"
		formulation_id?: "string"
		normalise?: boolean,
		stage_identifier?: string | undefined,
		experiment_ids?: string[],
		work_order_id?: string[],
		with_processing_profile?: boolean,
		formulation_ids?: string[],
		from?: string,
		meta?: any
	}
}

function* recipeDistributionSaga({
	payload,
}: NumericalSummaryPayload): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const apiResponse = yield call(fetchExperiment,
			{ ...payload, background: true },
			headers
		)
		const { data: { result: { status } } } = apiResponse

		if (status !== "Success") {
			yield put(recipeDistributionFailure(messages[ln].internal_server_error))
		}
	} catch (err) {
		if (err instanceof Error) {
			const error = err as unknown as APIError
			yield put(recipeDistributionFailure(error?.response?.data?.detail ?? messages[ln].internal_server_error))
		} else {
			const error = err as APIError
			if (error.response.status === 500) {
				yield put(recipeDistributionFailure(messages[ln].internal_server_error))
			} else {
				yield put(
					recipeDistributionFailure(
						error.response.data.detail ?? messages[ln].internal_server_error
					)
				)
			}
		}
	}
}


function* dataSummarySaga({
	payload,
}: NumericalSummaryPayload): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { status, data } = yield call(
			payload.type ? getIndividualSummaryApi : getOverallSummaryApi,
			payload.type
				? {
					work_order_ids: payload.work_order_ids, type: payload.type, characterization_set_id: payload?.characterization_set_id, formulation_ids: payload?.formulation_ids,
					normalise: payload?.normalise, stage_identifier: payload.stage_identifier, experiment_ids: payload?.experiment_ids, with_processing_profile: payload?.with_processing_profile
				}
				: {
					work_order_ids: payload.work_order_ids, characterization_set_id: payload?.characterization_set_id,
					formulation_ids: payload?.formulation_ids, normalise: payload?.normalise, stage_identifier: payload.stage_identifier, experiment_ids: payload?.experiment_ids, with_processing_profile: payload?.with_processing_profile
				},
			headers
		)

		if (status === 200) {
			if (!payload.type) {
				yield put(dataSummaryPlotOptionsSuccess({
					plotOptions: {
						...data.ingredients_processing_properties,
						...(data.variation_data && { variation_data: data.variation_data })
					},
					plotOptionsFormulationDisplayNames: data.formulation_display_names
				}))
			}
			yield put(dataSummarySuccess({ data }))
			if (payload?.from === "compare-formulation") {
				yield put(formulationsDataFromCompareSuccess(payload?.meta))
				history.push("/data-analytics?source=compare-formulation")
			}
		} else {
			yield put(dataSummaryFailure(messages[ln].internal_server_error))
		}
	} catch (err) {
		if (err instanceof Error) {
			const error = err as unknown as APIError
			yield put(dataSummaryFailure(Array.isArray(error?.response?.data?.detail) ? messages[ln].internal_server_error : (error?.response?.data?.detail ?? messages[ln].internal_server_error)))
		} else {
			const error = err as APIError
			if (error.response.status === 500) {
				yield put(dataSummaryFailure(messages[ln].internal_server_error))
			} else {
				yield put(
					dataSummaryFailure(Array.isArray(error?.response?.data?.detail) ? messages[ln].internal_server_error : (error?.response?.data?.detail ?? messages[ln].internal_server_error))
				)
			}
		}
	}
}

function* getCharacterizationMethodsZeonDsmSaga({
	payload,
}: {
	payload: {
		characterization_method_id: string
		ings_procs: string[]
		properties: string[]
	}
}): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { data, message },
			status,
		} = yield call(getCharacterizationMethodsZeonDsmApi, payload, headers)

		if (status === 200) {
			if (!Object.keys(data ?? {})[0]) {
				notification.error({ message: "No variations found for this characterization", duration: 3 })
			}
			yield put(getZeonCharacterizationDsmSuccess(data))
		} else {
			yield put(getZeonCharacterizationDsmFailure(message))
		}
	} catch (error) {
		yield put(getZeonCharacterizationDsmFailure(error))
	}
}

function* boxPlotSummarySaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { data, status, message: errorMessage } } } = yield call(boxPlotSummaryApi, payload, headers)

		if (status === "Success") {
			yield put(boxPlotSummarySuccess(data))
		} else {
			yield put(boxPlotSummaryFailure(errorMessage))
			message.error(errorMessage)
		}

	} catch (err) {
		yield put(boxPlotSummaryFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* surfacePlotSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { data, status, message: errorMessage } } } = yield call(dataAnalyticsPlotDataApi, payload, headers)

		if (status === "Success") {
			yield put(surfacePlotSuccess(data))
		} else {
			yield put(surfacePlotFailure(errorMessage))
			message.error(errorMessage)
		}

	} catch (err) {
		yield put(surfacePlotFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* analyticsRecipeDistributionSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { data, status, message: errorMessage } } } = yield call(dataAnalyticsPlotDataApi, payload, headers)

		if (status === "Success") {
			yield put(analyticsRecipeDistributionSuccess(data))
		} else {
			yield put(analyticsRecipeDistributionFailure(errorMessage))
			message.error(errorMessage)
		}

	} catch (err) {
		yield put(analyticsRecipeDistributionFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* analyticsRecipeDistributionTableSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { data, status, message: errorMessage } } } = yield call(fetchExperiment, payload, headers)

		if (status === "Success") {
			yield put(analyticsRecipeDistributionTableSuccess(data))
		} else {
			yield put(analyticsRecipeDistributionTableFailure(errorMessage))
			message.error(errorMessage)
		}

	} catch (err) {
		yield put(analyticsRecipeDistributionTableFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* savePlotsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { data, status, message: apiMessage } } } = yield call(savePlotsDataApi, payload, headers)

		if (status === "Success") {
			yield put(savePlotsSuccess(data))
			notification.success({ message: apiMessage })
		} else {
			yield put(savePlotsFailure(apiMessage))
			message.error(apiMessage)
		}

	} catch (err) {
		yield put(savePlotsFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* getPlotsHistorySaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { data, status, message: apiMessage } } } = yield call(plotsHistoryDataApi, payload, headers)

		if (status === "Success") {
			yield put(getPlotsHistorySuccess(data))
		} else {
			yield put(getPlotsHistoryFailure(apiMessage))
			message.error(apiMessage)
		}

	} catch (err) {
		yield put(getPlotsHistoryFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* deleteSavedPlotsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const { data: { result: { status, message: apiMessage } } } = yield call(deleteSavedPlotsDataApi, payload, headers)

		if (status === "Success") {
			yield put(deleteSavedPlotsSuccess(payload.plots_data_id))
			notification.success({ message: apiMessage })
		} else {
			yield put(deleteSavedPlotsFailure(apiMessage))
			message.error(apiMessage)
		}

	} catch (err) {
		yield put(deleteSavedPlotsFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* dataSummaryStreamSaga({ payload }: any): Generator<any, any, any> {
	const token = jwtManager.getToken()
	const { user_id: key } = yield select((state) => state.login.loginResponse)
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current,
	)

	if (
		dataSummarySocket === undefined ||
		dataSummarySocketChannel === undefined ||
		payload?.newSocket
	) {
		if (dataSummarySocket) {
			dataSummarySocket.close()
		}
		dataSummarySocket = yield call(dataSummaryStream, key)
		dataSummarySocketChannel = yield call(createDataSummaryChannel, dataSummarySocket)
	}

	if (payload.type === "query") {
		yield put(dataSummarySuccess({
			data: {}, correlationStatus: {
				apiStatus: AsyncStates.LOADING,
				errorMessage: null
			}
		}));
	}

	if(payload.type === "options") {
		yield put(dataSummaryFiltersRequest())
	}

	dataSummarySocket.send(JSON.stringify({ ...payload, token }));
	while (true) {
		try {
			const response = yield take(dataSummarySocketChannel)
			const res = JSON.parse(response);

			try {
				if (res?.type === "options") {
					yield put(dataSummaryFiltersSuccess(res?.data))
				}

				if (res?.type === "result") {
					yield put(dataSummarySuccess({
						data: res.results, correlationStatus: {
							apiStatus: AsyncStates.SUCCESS,
							errorMessage: res.message
						}
					}))
				}

				if (res?.type === "error") {
					const message = res.message;
					if (message) {
						notification.error({
							message: message,
						});
						yield put(dataSummarySuccess({
							data: {}, correlationStatus: {
								apiStatus: AsyncStates.ERROR,
								errorMessage: message
							}
						}));
					}
				}
			} catch (error) {
				throw error
			}
		} catch (error) {
			notification.error({
				message: messages[ln].internal_server_error,
			})
			yield put(reconnect(error))
		}
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(recipeDistributionRequest, recipeDistributionSaga)
	yield takeLatest(dataSummaryRequest, dataSummarySaga)
	yield takeLatest(boxPlotSummaryRequest, boxPlotSummarySaga)
	yield takeLatest(getZeonCharacterizationDsmRequest, getCharacterizationMethodsZeonDsmSaga)
	yield takeLatest(surfacePlotRequest, surfacePlotSaga)
	yield takeLatest(analyticsRecipeDistributionRequest, analyticsRecipeDistributionSaga)
	yield takeLatest(analyticsRecipeDistributionTableRequest, analyticsRecipeDistributionTableSaga)
	yield takeLatest(savePlotsRequest, savePlotsSaga)
	yield takeLatest(getPlotsHistoryRequest, getPlotsHistorySaga)
	yield takeLatest(deleteSavedPlotsRequest, deleteSavedPlotsSaga)
	yield takeLatest(dataSummaryStreamRequest, dataSummaryStreamSaga)
}
