import { useMemo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Space,
  Table,
  Typography,
  Modal,
  Card,
  Popconfirm,
  Checkbox,
  Row,
  Descriptions,
  Form,
  Input,
  Select,
  Spin,
  Popover,
  message,
} from "antd"
import { StoreState } from "../../../store/configureStore"
import { AsyncStates } from "../../../constants"
import { useMemberName } from "../../../utils/useMemberName"
import { LoadingOutlined, EditOutlined, NumberOutlined, MenuOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom"
import {
  celsureApproveTrialsRequest,
  celsureWoCreateRequest,
  celsureWoListRequest,
  celsureWoTrialsRequest,
  setCelsureWo,
} from "src/store/actions/celsureWo"
import { celsureDeleteTrialsRequest } from "src/store/actions/celsure"
import { CelsureReportHistory } from "../WorkOrderDetails/ReportHistory"
import useTranslate from "src/utils/useTranslate"
import { Modals } from "./modals"
import { ModalData, ModalType } from "./types"
import { calculateAverageAmbientTemperature } from "../celsureUtils/celsureUtils"
import { StyledButton } from "src/styled_components/StyledButton"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { blue } from "@ant-design/colors"
import { celsureTrialsExport, celsureTrialsReorder } from "src/services/celsure"
import jwtManager from "src/utils/jwtManager"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";

const { Text, Paragraph } = Typography;
const { Option } = Select;
interface CelsureExperimentsProps {
  from: string;
  setTrialRecord?: any;
}

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  border: `1px solid ${isDragging ? blue.primary : '#f0f0f0'}`,
  background: 'white',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'baseline',

  // styles we need to apply on draggables
  ...draggableStyle
});

const grid = 6

const getListStyle = (isDraggingOver: boolean) => ({
  padding: grid,
  width: 160,
  maxHeight: 300,
  overflow: 'auto'
});


export const CelsureExperiments = ({
  from,
  setTrialRecord,
}: CelsureExperimentsProps) => {
  const { getName } = useMemberName();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const requiredFieldStar = useRequiredFieldStar()

  const { celsureDisplayNamesData = {}, celsureDeleteTrialsStatus } =
    useSelector((state: StoreState) => state.celsure)
  const {
    celsureWoTrials,
    celsureWoTrialsStatus,
    celsureWoList,
    celsureWo,
    ceslureApproveTrialsStatus,
    celsureCreateWoStatus,
    celsureWoListStatus,
  } = useSelector((state: StoreState) => state.celsureWo)
  const {
    formulationDetailsData: celsureTrialList,
    status: celsureCompareFormulationStatus,
  } = useSelector((state: StoreState) => state.celsureCompareFormulation)
  const { current, projectList: projects } = useSelector(
    (state: StoreState) => state.projects
  )
  const currentProject = useMemo(
    () => projects.find((project: any) => project.project_id === current),
    [projects, current]
  )
  const [loading, setLoading] = useState<any>()
  const [trialsList, setTrialsList] = useState<any>([])
  const [modalVisible, setModalVisible] = useState(false)
  const [modalData, setModalData] = useState<ModalData | null>(null)
  const [selectedRecords, setSelectedRecords] = useState<any[]>([])
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [woModal, setWoModal] = useState<boolean>(false)
  const [reorderList, setReorderList] = useState<any>([])
  const [reorderLoading, setReorderLoading] = useState(false)
  const token: any = jwtManager.getToken()

  useEffect(() => {
    if (celsureCreateWoStatus === AsyncStates.SUCCESS) {
      setWoModal(false);
    }
  }, [celsureCreateWoStatus]);

  useEffect(() => {
    if (celsureWoListStatus === AsyncStates.INITIAL) {
      dispatch(celsureWoListRequest({ project_id: current }));
    }
  }, [dispatch, celsureWoListStatus, current]);

  useEffect(() => {
    if (from === "work-order") {
      setLoading(celsureWoTrialsStatus)
      setTrialsList(celsureWoTrials)
      setReorderList(celsureWoTrials)
    } else if (from === "formulations" || from === "compare_formulation") {
      setLoading(celsureCompareFormulationStatus)
      setTrialsList(celsureTrialList)
    }
  }, [
    from,
    celsureWoTrials,
    celsureWoTrialsStatus,
    celsureTrialList,
    celsureCompareFormulationStatus,
    dispatch,
  ])

  const checkboxChange = useCallback((e: any) => {
    if (e.target.checked) {
      setSelectedRecords((prevState: any) => [
        ...new Set([...prevState, e.target.name]),
      ]);
    } else {
      setSelectedRecords((prevState: any) =>
        prevState.filter((res: any) => res !== e.target.name)
      );
    }
  }, []);

  const setSelectAll = useCallback(
    (e: any) => {
      if (e.target.checked) {
        setSelectedRecords(celsureWoTrials.map((res: any) => res.trial_id));
      } else {
        setSelectedRecords([]);
      }
    },
    [celsureWoTrials]
  );

  const getValueFromDisplayNames = useCallback(
    (identifier: any, trialDetails: any) => {
      return (
        celsureDisplayNamesData?.[identifier]?.[trialDetails] || trialDetails
      );
    },
    [celsureDisplayNamesData]
  );

  useEffect(() => {
    if (celsureDeleteTrialsStatus === AsyncStates.SUCCESS) {
      setSelectedRecords([]);
    }
  }, [celsureDeleteTrialsStatus]);

  const onDragEnd = (result: any) => {
    setReorderLoading(true)
    if (!result.destination) {
      return;
    }
    const items = reorder(
      reorderList.map((res: any) => res?.trial_id),
      result.source.index,
      result.destination.index
    )
    setReorderList(items.map((res: any) => trialsList.find((trial: any) => trial?.trial_id === res)))
    const payload = items.map((res: any, index: number) => ({ trial_id: res, index }))
    celsureTrialsReorder({ values: payload }, {
      token,
      "Content-Type": 'application/json' as any | 'multipart/form-data' as any,
      "Access-Control-Allow-Origin": "*",
    }).then((res: any) => {
      setReorderLoading(false)
      if (res?.data?.result?.status === "Success") {
        dispatch(celsureWoTrialsRequest({ work_order_id: celsureWo?.work_order_id }))
      }
    }).catch((err: any) => {
      message.error("Error in Re ordering Trials")
      setReorderLoading(false)
    })
  }

  const content = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {reorderList.map((item: any, index: number) => (
              <Draggable key={item.trial_id} draggableId={item.trial_id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <MenuOutlined style={{ color: '#d0d0d0' }} /><Text ellipsis={{ tooltip: item.trial_display_id }} style={{ width: 80 }}>{item.trial_display_id}</Text>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  const exportTrials = () => {
    setReorderLoading(true)
    celsureTrialsExport({
      work_order_id: celsureWo?.work_order_id,
      trial_id: selectedRecords
    }, {
      token,
      "Content-Type": 'application/json' as any | 'multipart/form-data' as any,
      "Access-Control-Allow-Origin": "*",
    }).then((res: any) => {
      setReorderLoading(false)
      if (res?.data?.result?.status === "Success") {
        window.open(res?.data?.result?.data?.download_link, "_blank")
      }
    }).catch((err: any) => {
      message.error("Error in Exporting Trials")
      setReorderLoading(false)
    })
  }

  const trialsColumns = useMemo(() => {
    if (loading === AsyncStates.SUCCESS) {
      return [
        {
          dataIndex: "fields",
          title: () => (
            <Space
              direction="vertical"
              align="center"
              style={{ width: "100%" }}
            >
              {from === "work-order" && <Checkbox onChange={setSelectAll} />}
              <Text strong>{"Fields"}</Text>
            </Space>
          ),
          key: "fields",
          fixed: "left",
          width: 250,
          render: (text: any) => {
            let reponseText = text
            if (text === "Ambient") {
              reponseText = "Average Ambient Temperature"
            }
            else if (text === "Payload Material Weight") {
              reponseText = "Dummy Payload Weight"
            }
            else if (text === "Payload Material") {
              reponseText = "Dummy Payload"
            }
            else if (text === "R Value") {
              reponseText = "R-Value"
            }
            else if (text === "Additional Fields") {
              reponseText = "Custom Fields"
            }
            return <Text strong>{reponseText}</Text>
          },
        },
        ...trialsList.map((trial: any, index: number) => ({
          dataIndex: trial.trial_id,
          width: 200,
          title: () => (
            <Space
              direction="vertical"
              style={{ width: "100%" }}
              align="center"
            >
              {from === "work-order" && (
                <Checkbox
                  key={trial.trial_id + "checkbox"}
                  name={trial.trial_id}
                  checked={selectedRecords.includes(trial.trial_id)}
                  onChange={checkboxChange}
                />
              )}
              <Text
                style={
                  celsureWo?.work_order_stage === "formulation_stage"
                    ? { marginTop: 12 }
                    : {}
                }
                strong
              >
                {trial.trial_display_id}
              </Text>
              {from === "work-order" &&
                celsureWo.work_order_stage !== "work_order_status" && (
                  <Space
                    size="large"
                    key={trial.trial_id + "space"}
                    style={{ width: "100%" }}
                  >
                    <Popconfirm
                      title={`${t("common.edit")} ${t("common.trial")} ${trial.trial_display_id
                        } ?`}
                      onConfirm={() => {
                        setTrialRecord({ type: "edit", data: trial })
                        setTimeout(
                          () =>
                            document
                              ?.getElementById?.("upload-form")
                              ?.scrollIntoView(),
                          300
                        )
                      }}
                    >
                      <EditOutlined style={{ cursor: "pointer" }} />
                    </Popconfirm>
                    {celsureWo?.work_order_stage === "formulation_stage" && (
                      <Paragraph
                        style={{ marginTop: 12 }}
                        copyable={{
                          tooltips: "Copy trial details",
                          onCopy: () => {
                            setTrialRecord({ type: "copy", data: trial })
                            document
                              ?.getElementById?.("upload-form")
                              ?.scrollIntoView()
                          },
                        }}
                      >
                        {""}
                      </Paragraph>
                    )}
                  </Space>
                )}
              {from === "work-order" &&
                celsureWo.work_order_stage === "work_order_status" &&
                !!trial?.approved && (
                  <Text type="success" strong>
                    {"Approved"}
                  </Text>
                )}
            </Space>
          ),
          key: trial.trial_id + String(index),
          render: (text: any, record: any) => {
            const { identifier } = record
            const trialDetails = record?.[trial?.trial_id]
            if (identifier === "payload_type") {
              const { payload_box, type, volume } = trialDetails
              return (
                <Space direction="vertical">
                  <Space>
                    <Text strong>{t("celsureExp.Volume")}</Text>
                    <Text>{`${volume?.value} ${volume?.unit}`}</Text>
                  </Space>
                  {!!type && (
                    <>
                      <Space>
                        <Text strong>{t("common.type")}</Text>
                        <Text>{type}</Text>{" "}
                      </Space>
                      <Space>
                        <Text strong>{`${t(
                          "celsureExperiments.inner"
                        )}:`}</Text>
                        <Text>{`${payload_box?.inner?.value?.join(" * ")} ${payload_box?.inner?.unit
                          }`}</Text>
                      </Space>
                      <Space>
                        <Text strong>{`${t(
                          "celsureExperiments.outer"
                        )}:`}</Text>
                        <Text>{`${payload_box?.outer?.value?.join(" * ")} ${payload_box?.outer?.unit
                          }`}</Text>
                      </Space>
                      <Space>
                        <Text strong>{"Thickness:"}</Text>
                        <Text>{`${payload_box?.thickness?.value} ${payload_box?.thickness?.unit}`}</Text>
                      </Space>
                    </>
                  )}
                </Space>
              )
            } else if (["insulation_box_dimension", "outer_packaging_dimension"].includes(identifier)) {
              return !!trialDetails?.value?.length ? `${trialDetails?.value?.join(" * ")} ${trialDetails?.unit}` : null
            }
            else if (["insulation_type", "outer_packaging_materials"].includes(identifier)) {
              return (
                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center" }}>
                  <StyledButton
                    type="link"
                    onClick={() => {
                      setModalType(identifier)
                      setModalData(trial)
                      setModalVisible(true)
                    }}
                  >
                    {celsureDisplayNamesData?.insulation_type?.[text]?.name || text}
                  </StyledButton>
                </div>
              )
            } else if (
              [
                "payload_material_weight",
                "threshold_minimum_temperature",
                "threshold_maximum_temperature",
                "payload_initial_temperature",
                "backup_time",
              ].includes(identifier)
            ) {
              return `${trialDetails?.value || ""} ${trialDetails?.unit || ""}`
            }
            else if (
              [
                "minimum_temperature",
                "maximum_temperature",
              ].includes(identifier)
            ) {
              return !!text ? `${text} degC` : null
            }
            else if (
              [
                "trial_type",
                "payload_material_type",
              ].includes(identifier)
            ) {
              return getValueFromDisplayNames(identifier, trialDetails)
            } else if (["pcm_type", "insulation_sheets", "water", "ambient", "insulation_thickness", "outer_packaging", "additional_fields"].includes(identifier)) {
              const avgAmbientTemp = identifier === "ambient" ? calculateAverageAmbientTemperature(trial.ambient) : null
              return !!trial?.[identifier]?.length ? (
                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center" }}>
                  {identifier === "ambient" && (avgAmbientTemp ? `${avgAmbientTemp} degC` : null)}
                  <StyledButton
                    type="link"
                    onClick={() => {
                      setModalType(identifier)
                      setModalData(trial)
                      setModalVisible(true)
                    }}
                  >
                    {t("common.view")}
                  </StyledButton>
                  {!!trial.temperature_profile && identifier === "ambient" && (
                    <Text strong>
                      ({
                        celsureDisplayNamesData?.ista_profiles?.find(
                          (res: any) => res?.profile_id === trial.temperature_profile)?.name
                      })
                    </Text>
                  )}
                </div>
              ) : (
                ""
              )
            } else if (identifier === "payload_material") {
              return `${trialDetails
                .map((res: any) => getValueFromDisplayNames(identifier, res))
                .join(", ")}`
            } else if (["user_id", "updated_by"].includes(identifier)) {
              return getName(text)
            } else if (
              ["created", "updated", "close_date", "start_date"].includes(
                identifier
              )
            ) {
              if (!!text) {
                return new Date(text).toLocaleString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              } else return ""
            } else if (["backup_till"].includes(identifier)) {
              return trialDetails
                ?.split("_")
                .map((res: any) => res?.[0]?.toUpperCase() + res.slice(1))
                ?.join(" ")
            } else if (identifier === "work_order_id") {
              return celsureWoList.find((wo: any) => wo?.work_order_id === text)
                ?.work_order_name
            }
            else return text
          },
        })),
      ]
    } else return []
  }, [loading, trialsList, from, setSelectAll, selectedRecords, checkboxChange, celsureWo.work_order_stage, t, setTrialRecord, celsureDisplayNamesData.insulation_type, celsureDisplayNamesData?.ista_profiles, getValueFromDisplayNames, getName, celsureWoList])

  const getLabels = (trial: any) => {
    if (trial === "user_id") {
      return "User";
    } else if (trial === "pcm_type") {
      return "PCM Details";
    } else if (trial === "water") {
      return "Water and material";
    } else {
      return trial
        ?.split("_")
        ?.map((res: any) => res?.[0]?.toUpperCase() + res?.substring(1))
        ?.join(" ");
    }
  };

  const trialsTableData = useMemo(() => {
    if (loading === AsyncStates.SUCCESS) {
      const woTrialsData: any = [...new Set(trialsList.flatMap((res: any) => Object.keys(res || {})))]
        .filter(
          (data: any) =>
            ![
              "access",
              "work_order_id",
              "trial_id",
              "project_id",
              // "threshold_minimum_temperature",
              // "threshold_maximum_temperature",
              "backup_time",
              // "ambient",
              "approved",
              // "payload_initial_temperature",
              "backup_till",
              "minimum_temperature",
              "maximum_temperature",
              "temperature_profile"
            ].includes(data)
        )
        .map((trial: any) => ({ identifier: trial, fields: getLabels(trial) }));
      if (from === "compare_formulation") {
        woTrialsData.push({
          identifier: "work_order_id",
          fields: "Work Order",
        });
      }
      trialsList.forEach((res: any) => {
        woTrialsData.forEach((data: any, i: number) => {
          data[res.trial_id] = res[data.identifier];
          data.key = "trials" + String(i);
        });
      });
      // const characterizationData = Object.keys(trialsList?.[0] || {})
      // 	.filter((data: any) =>
      // 		[
      // 			"ambient",
      // 			"payload_initial_temperature",
      // 			"threshold_minimum_temperature",
      // 			"threshold_maximum_temperature",
      // 		].includes(data)
      // 	)
      // 	.map((trial: any) => ({
      // 		identifier: trial,
      // 		fields: trial
      // 			?.split("_")
      // 			?.map((res: any) => res?.[0]?.toUpperCase() + res?.substring(1))
      // 			?.join(" "),
      // 	}))
      // trialsList.forEach((res: any) => {
      // 	characterizationData.forEach((data: any, i: any) => {
      // 		data[res.trial_id] = res[data.identifier]
      // 		data.key = i
      // 	})
      // })
      const woPropertiesData = [...new Set(trialsList.flatMap((res: any) => Object.keys(res || {})))]
        .filter((data: any) =>
          [
            "backup_time",
            "backup_till",
            "minimum_temperature",
            "maximum_temperature"
          ].includes(data)
        )
        .map((trial: any) => ({
          identifier: trial,
          fields: trial
            ?.split("_")
            ?.map((res: any) => res?.[0]?.toUpperCase() + res?.substring(1))
            ?.join(" "),
        }));
      trialsList.forEach((res: any) => {
        woPropertiesData.forEach((data: any, i: number) => {
          data[res.trial_id] = res?.[data.identifier];
          data.key = "properties" + String(i);
        });
      });
      if (from === "work-order") {
        if (celsureWo?.work_order_stage === "formulation_stage")
          return woTrialsData;
        else if (celsureWo?.work_order_stage === "properties_stage")
          return woPropertiesData;
        else if (
          celsureWo?.work_order_stage === "work_order_status" ||
          celsureWo?.work_order_stage === "report_preview"
        )
          return [...woTrialsData, ...woPropertiesData];
      } else {
        return [...woTrialsData, ...woPropertiesData];
      }
    } else return [];
  }, [trialsList, loading, celsureWo, from]);

  const reviewTrials = (value: boolean) => {
    dispatch(
      celsureApproveTrialsRequest({
        work_order_id: celsureWo?.work_order_id,
        trial_ids: selectedRecords,
        approve: value,
      })
    );
  };

  const deleteTrials = () => {
    dispatch(celsureDeleteTrialsRequest({ trial_ids: selectedRecords }));
  };

  const generateNewWO = (values: any) => {
    dispatch(
      celsureWoCreateRequest({
        ...values,
        project_id: current,
        start_date: new Date().toLocaleDateString(),
        duplicate_work_order: celsureWo?.work_order_id,
        duplicate_trial_ids: selectedRecords,
      })
    );
  };

  return (
    <>
      <Card
        id="wo-trials"
        title={from === "work-order" ? "Trials" : ""}
        extra={
          from === "work-order" ? (
            <Space>
              {celsureWo?.work_order_stage === "formulation_stage" && (
                <StyledButton
                  type="primary"
                  onClick={() =>
                    document?.getElementById?.("upload-form")?.scrollIntoView()
                  }
                >
                  {t("common.addNewTrial")}
                </StyledButton>
              )}
              <Popconfirm
                title={t("common.deleteSelectedTrials")}
                onConfirm={deleteTrials}
              >
                <StyledButton
                  danger
                  disabled={!selectedRecords?.length}
                  icon={<StyledDeleteIcon />}
                >
                  {t("celsureExp.deleteTrials")}
                </StyledButton>
              </Popconfirm>
              <StyledButton
                type="primary"
                onClick={() => setWoModal(true)}
                disabled={!selectedRecords?.length}
              >
                {"Generate New Work Order"}
              </StyledButton>
              <StyledButton type="link" onClick={exportTrials} loading={reorderLoading}>
                {"Export Trials"}
              </StyledButton>
              {celsureWo.work_order_stage === "work_order_status" && (
                <>
                  <Popconfirm
                    title="Approve selected trials ?"
                    onConfirm={() => reviewTrials(true)}
                  >
                    <StyledButton disabled={!selectedRecords?.length}>
                      {t("common.approveTrials")}
                    </StyledButton>
                  </Popconfirm>
                  <Popconfirm
                    title="Reject selected trials ?"
                    onConfirm={() => reviewTrials(false)}
                  >
                    <StyledButton disabled={!selectedRecords?.length} danger>
                      {t("celsureExperiments.rejectTrials")}
                    </StyledButton>
                  </Popconfirm>
                </>
              )}
            </Space>
          ) : (
            ""
          )
        }
        bodyStyle={{ padding: "24px 16px" }}
        headStyle={{ padding: "0px 16px" }}
      >
        <Space
          direction="vertical"
          style={{ width: "100%", overflowX: "auto" }}
        >
          {from === "formulations" &&
            celsureWoList.find(
              (wo: any) => wo.work_order_id === trialsList[0]?.work_order_id
            )?.work_order_name ? (
            <Space size="small">
              <Text strong>{"Work order:"}</Text>
              <Popconfirm
                title={`${t("common.goToWorkOrder")}?`}
                onConfirm={() => {
                  dispatch(
                    setCelsureWo(
                      celsureWoList.find(
                        (wo: any) =>
                          wo.work_order_id === trialsList[0]?.work_order_id
                      )
                    )
                  )
                  push("/work-orders/details/create-workorder")
                }}
              >
                <StyledButton type="link">
                  {
                    celsureWoList.find(
                      (wo: any) =>
                        wo.work_order_id === trialsList[0]?.work_order_id
                    )?.work_order_name
                  }
                </StyledButton>
              </Popconfirm>
            </Space>
          ) : (
            ""
          )}

          {celsureWoList.find(
            (wo: any) => wo.work_order_id === trialsList[0]?.work_order_id
          )?.["work_order_desc"] &&
            from === "formulations" && (
              <Descriptions column={2} size="small">
                <Descriptions.Item
                  label={<Text strong>{t("common.description")}</Text>}
                >
                  <Paragraph
                    type="secondary"
                    ellipsis={{
                      rows: 2,
                      tooltip: celsureWoList.find(
                        (wo: any) =>
                          wo.work_order_id === trialsList[0]?.work_order_id
                      )?.["work_order_desc"],
                    }}
                  >
                    {
                      celsureWoList.find(
                        (wo: any) =>
                          wo.work_order_id === trialsList[0]?.work_order_id
                      )?.["work_order_desc"]
                    }
                  </Paragraph>
                </Descriptions.Item>
              </Descriptions>
            )}

          <Table
            title={() => !!trialsList?.length ?
              <Space size={"large"}>
                <Text strong>{`Trials - ${trialsList.length}`}</Text>
                {from === "work-order" &&
                  <Popover content={content} trigger="click">
                    <StyledButton loading={reorderLoading}
                      type="primary" icon={<NumberOutlined />}>{t("common.reorder")}</StyledButton>
                  </Popover>
                }
              </Space>
              : null}
            scroll={{ x: 950, y: "calc(100vh - 220px)" }}
            pagination={false}
            bordered
            dataSource={trialsTableData}
            columns={trialsColumns}
            // sticky={{ offsetHeader: 64 }}
            size="small"
            loading={{
              spinning:
                from === "formulations"
                  ? loading === AsyncStates.LOADING
                  : false ||
                  celsureDeleteTrialsStatus === AsyncStates.LOADING ||
                  ceslureApproveTrialsStatus === AsyncStates.LOADING,
              indicator: <LoadingOutlined />,
            }}
          />
          {celsureWo?.work_order_stage === "work_order_status" && (
            <CelsureReportHistory />
          )}
        </Space>

        {/* MODALS */}
        {modalData && (
          <Modals
            modalData={modalData}
            modalType={modalType}
            modalVisible={modalVisible}
            names={celsureDisplayNamesData}
            resetModal={() => {
              setModalData(null);
              setModalType(null);
              setModalVisible(false);
            }}
          />
        )}
      </Card>

      <Modal
        title={"Genrate New Work Order"}
        open={woModal}
        onCancel={() => setWoModal(false)}
        footer={null}
      >
        <Spin
          spinning={celsureCreateWoStatus === AsyncStates.LOADING}
          indicator={<LoadingOutlined />}
        >
          <Text strong>{`Selected trials - ${trialsList
            .filter((res: any) => selectedRecords.includes(res?.trial_id))
            .map((res: any) => res?.trial_display_id)
            .join(", ")}`}</Text>
          <Form
            layout="vertical"
            onFinish={generateNewWO}
            style={{ marginTop: 30 }}
            requiredMark={false}
          >
            <Form.Item
              label={t("common.title")}
              name="work_order_name"
              rules={[{ required: true, message: "required" }]}
              required
              tooltip={requiredFieldStar}
            >
              <Input placeholder="Enter title" />
            </Form.Item>
            <Form.Item label={t("common.description")} name="work_order_desc">
              <Input.TextArea placeholder={t("editProject.enterDescription")} rows={3} />
            </Form.Item>
            <Form.Item label={"Members"} name="work_order_dri">
              <Select placeholder="Select members" mode="multiple">
                {currentProject?.members
                  ?.map(({ user_id }: any) => user_id)
                  ?.concat(currentProject.project_owner)
                  ?.map((member: any) => (
                    <Option value={member} key={member}>
                      {getName(member)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Row justify="end">
              <StyledButton type="primary" htmlType="submit">
                {t("common.submit")}
              </StyledButton>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};