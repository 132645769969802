import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { Input, message } from "antd";
import { Box, PayloadElement } from "../types"
import { payloadInitialValue } from "../utils"
import { StyledButton } from "src/styled_components/StyledButton"

type P = {
	element: PayloadElement | null
	setElement: Dispatch<SetStateAction<PayloadElement | null>>
	box: Box
}
const inputStyle = {
	maxWidth: "100px",
}

export const PayloadDimensionForm = ({ element, setElement, box }: P) => {
	const [payload, setPayload] = useState<PayloadElement>(
		element ? element : (payloadInitialValue as unknown as PayloadElement)
	)

	function onSubmit() {
		if (typeof payload.width !== "number")
			return message.error("Please provide the width of the Payload!")
		if (typeof payload.length !== "number")
			return message.error("Please provide the length of the Payload!")
		if (typeof payload.thickness !== "number")
			return message.error("Please provide the thickness of the Payload!")

		if (payload.width <= 0)
			return message.error("Payload width must be a number greater than 0")
		if (payload.length <= 0)
			return message.error("Payload length must be a number greater than 0")
		if (payload.thickness <= 0)
			return message.error("Payload thickness must be a number greater than 0")

		if (payload.width > box.width)
			return message.error(
				"Payload Width must be less than or equal to Box Width"
			)
		if (payload.length > box.length)
			return message.error(
				"Payload Length must be less than or equal to Box Length"
			)
		if (payload.thickness > box.depth)
			return message.error(
				"Payload Thickness must be less than or equal to Box Depth"
			)

		setElement(payload)
	}

	function handleDimensionChange(e: FormEvent<HTMLInputElement>) {
		const { value, name } = e.currentTarget

		setPayload({
			...payload,
			[name]: name === "color" ? value : +value,
		})
	}

	return (
		<div
			style={{
				display: "flex",
				gap: "8px",
			}}
		>
			<Input
				type="number"
				name="length"
				placeholder="Length"
				value={payload.length}
				onChange={handleDimensionChange}
				style={inputStyle}
			/>
			<Input
				type="number"
				name="width"
				placeholder="Width"
				value={payload.width}
				onChange={handleDimensionChange}
				style={inputStyle}
			/>
			<Input
				type="number"
				name="thickness"
				placeholder="Thickness"
				value={payload.thickness}
				onChange={handleDimensionChange}
				style={inputStyle}
			/>
			<Input
				type="color"
				name="color"
				placeholder="Color"
				value={payload.color}
				onChange={handleDimensionChange}
				style={inputStyle}
			/>
			<StyledButton onClick={onSubmit} type="primary" htmlType="button">
				Set Payload
			</StyledButton>
		</div>
	)
}
