import { MinusCircleOutlined, FilterOutlined, PlusOutlined, SearchOutlined, LoadingOutlined } from "@ant-design/icons"
import {
  Space,
  Card,
  Form,
  Row,
  Col,
  Tabs,
  Select,
  Collapse,
  Dropdown,
  Divider,
  Tag,
  Steps,
  Input,
  Cascader,
  Spin,
} from "antd";
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useTranslate from "src/utils/useTranslate"
import { getCelsureTrialsRequest } from 'src/store/actions/celsure'
import { StoreState } from "src/store/configureStore"
import { ProjectSelection } from "src/components/ProjectSelection"
import { blue } from "@ant-design/colors"
import { antdTheme, AsyncStates, currentPlatform } from "src/constants"
import { useDebounce } from "src/utils/useDebounce"
import jwtManager from "src/utils/jwtManager"
import { CreateProjectModal } from "src/components/Project/CreateProjectModal"
import { StyledButton } from "src/styled_components/StyledButton"


const { CheckableTag } = Tag
const { Step } = Steps
const { Option } = Select

enum FilterStatus {
  WAIT = "wait",
  PROCESS = "process",
  FINISH = "finish",
  ERROR = "error"
}
const searchFilters = {
  trial_type: "Trial Type",
  insulation_type: "Insulation Type",
  // payload_material_type: "Payload Material Type",
  payload_material: "Dummy Payload"
}

interface FiltersFormProps {
  filterList: string[]
  setFilterList: React.Dispatch<React.SetStateAction<string[]>>
  selectedTab: any
  setSelectedTab: React.Dispatch<React.SetStateAction<any>>
  selectedTags: any[]
  setSelectedTags: React.Dispatch<React.SetStateAction<any[]>>,
  searchList: any[]
  setSearchList: React.Dispatch<React.SetStateAction<any>>,
}

interface IState {
  data: any[],
  value: any[] | string,
  fetching: boolean,
}

interface AutoCompleteRequest {
  token: string,
  user_id: string,
  search_term: string
}

const fetchSuggestions: any = (request_body: AutoCompleteRequest, headers: any) => {
  return fetch(process.env.REACT_APP_API_URL + 'v1/data/celsure_autocomplete', {
    method: 'POST',
    headers,
    body: JSON.stringify(request_body)
  })
    .then(response => response.json())
    .then(({ result }) => result.data.map((keyword: string) => {
      const [text, value] = keyword.split(',')
      return { text, value, }
    }))
};

export const FiltersForm = ({ filterList, setFilterList, selectedTab, setSelectedTab, selectedTags, setSelectedTags,
  searchList, setSearchList }: FiltersFormProps) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { current: currentProject, selectAllProjects } = useSelector((state: StoreState) => state.projects)
  const { getCelsureTrialsStatus, celsureDisplayNamesData
  } = useSelector((state: StoreState) => state.celsure)

  const [filterStatus, setFilterStatus] = useState<{ [key: number]: FilterStatus }>({})
  const [operatorStates, setOperatorStates] = useState<any[]>([])
  const [filterCollapse, setFilterCollapse] = useState(true)
  const [state, setState] = useState<IState>({ data: [], value: [], fetching: false })
  const { fetching, data, value } = state
  const [form] = Form.useForm()

  const handleSearch = (value: string) => {
    if (value.length >= 3) {
      setState(state => ({
        ...state,
        value,
      }));
    } else {
      setState(state => ({ ...state, data: [], value }));
    }
  }
  const handleChange = (value: any) => {
    setState(state => ({
      ...state,
      value: '',
      data: [],
      fetching: false,
    }));
  }

  const onBlur = () => {
    setState(state => ({ ...state, value: '' }));
  }

  const debouncedSearchTerm = useDebounce(value, 300);

  useEffect(
    () => {
      if (typeof debouncedSearchTerm === 'string' && debouncedSearchTerm.length >= 3) {
        const headers = { token: jwtManager.getToken(), platform: currentPlatform }
        const requestBody = {
          search_term: debouncedSearchTerm,
          ...(!selectAllProjects && { project_id: [currentProject] })
        }
        setState(state => ({ ...state, fetching: true, data: [] }));
        fetchSuggestions(requestBody, headers).then((results: []) => {
          results.length ? setState(state => ({ ...state, fetching: false, data: results })) : setState(state => ({ ...state, fetching: false, data: [{ text: 'No similar results found.', disabled: true }] }));
        })
          .catch(() => setState(state => ({ ...state, fetching: false, data: [{ text: t("formulationFilter.UnableToConnect"), disabled: true }] })));
      } else {
        setState((state) => ({
          ...state,
          data: [
            {
              text: `${t("common.typeIn")} ${3 - state.value.length} ${t(
                "common.more"
              )} ${state.value.length === 2
                ? t("common.character")
                : t("common.characters")
                } ${t("common.forSimilarResult")}`,
              disabled: true,
            },
          ],
        }))
      }
    },
    [debouncedSearchTerm, t, currentProject, selectAllProjects]
  );

  const tagsSelector = useCallback((state: StoreState) => state.celsure.celsureDisplayNamesData?.[selectedTab] || {}, [selectedTab])
  const tags = useSelector((state: StoreState) => tagsSelector(state))

  const filterData = [
    {
      value: "threshold_maximum_temperature.value",
      label: "Threshold Maximum Temperature",
    },
    {
      value: "threshold_minimum_temperature.value",
      label: "Threshold Minimum Temperature",
    },
    {
      value: "backup_time.value",
      label: "Backup Till Threshold Maximum",

    }, {
      value: "payload_type.volume",
      label: "Payload Volume",
    },
    {
      value: "pcm_type.pcm_type",
      label: "PCM Type",

    }, {
      value: "pcm_type.enthalpy",
      label: "Overall Enthalpy Value",
    },
  ]

  const selectTags = useCallback((tag: string, checked: boolean) => {
    const newSelectedTags = checked ? [...selectedTags, tag] : [...selectedTags.filter((t: string) => t !== tag)]
    setSelectedTags(newSelectedTags)
    dispatch(getCelsureTrialsRequest({ reset: true, page_size: 20, filter_list: selectAllProjects ? [...filterList, ...newSelectedTags.map(tag => selectedTab + ',eq,' + tag),] : [...filterList, ...newSelectedTags.map(tag => selectedTab + ',eq,' + tag), `project_id,eq,${currentProject}`], ...(searchList?.length ? { search_list: searchList } : {}) }))
  }, [currentProject, filterList, selectedTab, dispatch, selectAllProjects, setSelectedTags, searchList, selectedTags])

  const apply = useCallback(async (changedData: any, formData: any) => {
    setTimeout(() => {

      const { filters, searchTerm } = formData

      let searchTermValues = []
      let filter_list = []
      let newFilterStatus = filterStatus
      const changedFilters = changedData.filters || []
      if (!!searchTerm?.length) {
        const searchStrArray = searchTerm.map((term: any) => term.value)
        searchTermValues = searchStrArray
        searchStrArray.length && setSearchList(searchStrArray)
      } else {
        setSearchList([])
      }

      if (filters) {
        if (changedFilters.length)
          changedFilters.forEach((changedField: any, index: number) => {
            const filter = filters[index]
            const filled = !!(filter && filter.category && filter.operator && (filter.operator === 'range' ? (filter.min && filter.max) : (filter.val)))
            newFilterStatus = { ...newFilterStatus, [index]: filled ? FilterStatus.FINISH : FilterStatus.WAIT }
          })
        else
          newFilterStatus = {}
        setFilterStatus(newFilterStatus)
        filter_list = (filters.filter((filter: any, index: number) => newFilterStatus[index] !== FilterStatus.WAIT) || []).map((value: any) => JSON.parse(JSON.stringify(value))).filter(({ category, operator, min, max, val }: any) => category && operator && (operator === 'range' ? (min && max) : (val))).map(({ category, operator, min, max, val }: any) => `${category[0]},${operator},${operator === 'range' ? `${min},${max}` : val}`)
        setFilterList(filter_list)

        setTimeout(function (changedFilters, filters, newFilterStatus) {
          if (changedFilters.length)
            changedFilters.forEach((changedField: any, index: number) => {
              const filter = filters[index]
              const filled = !!(filter && filter.category && filter.operator && (
                filter.operator === 'range' ? (filter.min && filter.max) : (filter.val)
              ))
              newFilterStatus = { ...newFilterStatus, [index]: filled ? FilterStatus.PROCESS : FilterStatus.WAIT }
            })
          else
            newFilterStatus = {}
          setFilterStatus(newFilterStatus)
        }, 1000, changedFilters, filters, newFilterStatus);
      }
      if (searchTerm || filters) {
        const changedIndex = Number(Object.keys(changedFilters)[0])
        !(isNaN(changedIndex) &&
          newFilterStatus[changedIndex]) &&
          newFilterStatus[changedIndex] !== FilterStatus.WAIT &&
          dispatch(getCelsureTrialsRequest({
            ...(searchTerm?.length ? { search_list: searchTermValues } : {}),
            ...((filter_list?.length + selectedTags.length) ? {
              filter_list: selectAllProjects ? [
                ...filter_list,
                ...selectedTags.map(tag => selectedTab + ',eq,' + tag),] : [
                ...filter_list,
                ...selectedTags.map(tag => selectedTab + ',eq,' + tag),
                `project_id,eq,${currentProject}`
              ]
            } : { filter_list: selectAllProjects ? [] : [`project_id,eq,${currentProject}`] }),
            reset: true,
            page_size: 20
          }))
      }
    }, 1000)
  }, [currentProject, dispatch, filterStatus, selectedTab, selectedTags, selectAllProjects, setFilterList, setSearchList])

  useEffect(() => {
    setSelectedTags([])
    dispatch(getCelsureTrialsRequest({ reset: true, page_size: 20, filter_list: [`project_id,eq,${currentProject}`] }))
  }, [currentProject, setSelectedTags, selectedTab, dispatch])

  const [createProjectModal, setCreateProjectModal] = useState<boolean>(false)

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
        <Form name="dynamic_form_nest_item" onValuesChange={async (changedField: any, allFields: any) => { await apply(changedField, allFields) }} autoComplete="on" form={form}>
          <Row>
            <Col span={24}>
              <Tabs defaultActiveKey='Material' tabPosition="top" size="small" style={{ height: 'auto' }} onChange={setSelectedTab} tabBarExtraContent={<ProjectSelection selectAll={true} createProjectModal={createProjectModal} setCreateProjectModal={setCreateProjectModal} />}>
                {Object.entries(searchFilters).map(([tab, label]) => (
                  <Tabs.TabPane tab={String(label)} key={tab}>
                    <Input.Group compact
                      style={{ width: '70%', margin: '20px auto' }}
                    >
                      <Form.Item name="searchTerm"
                        style={{ width: 'calc(100% - 40px)', marginBottom: 0 }}
                      >
                        <Select
                          loading={fetching}
                          mode="multiple"
                          labelInValue
                          placeholder={t("formulations.placeholder.searchFromAnyCategory")}
                          notFoundContent={fetching ? <Spin indicator={<LoadingOutlined />} size="small" /> : null}
                          filterOption={false}
                          onSearch={handleSearch}
                          onChange={handleChange}
                          onBlur={onBlur}
                          size="large"
                          allowClear
                          bordered={false}
                          style={{ borderBottom: '2px solid ' + blue.primary }}
                        >
                          {data.map((d: any, index) => (
                            <Option key={index} value={d.value} disabled={d.disabled} >{d.text}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <StyledButton icon={<SearchOutlined style={{ fontSize: antdTheme.fontSizeHeading2 }} />} size="large" type="link" style={{ width: '40px' }} onClick={() => form.submit()} loading={getCelsureTrialsStatus === AsyncStates.LOADING} />
                    </Input.Group>
                    <Row justify="center">
                      {Object.entries(tags).map(([key, val]: any) => (
                        <CheckableTag
                          style={{
                            margin: 8,
                            padding: "2px 16px",
                            fontSize: antdTheme.fontSizeHeading5,
                            ...((selectedTags.indexOf(key) > -1) ?
                              { backgroundColor: blue.primary } :
                              { border: '1px solid ' + blue.primary })
                          }}
                          key={key}
                          checked={selectedTags.indexOf(key) > -1}
                          onChange={checked => selectTags(key, checked)}
                        >
                          {String(val?.name || val)}
                        </CheckableTag>
                      ))}
                    </Row>
                  </Tabs.TabPane>
                ))}
              </Tabs>
              <Form.List name="filters">
                {(fields, { add, remove }) => (
                  <Collapse ghost expandIconPosition="right" activeKey={(filterCollapse && fields.length) ? ['filters'] : ['']} onChange={() => setFilterCollapse(state => !state)}>
                    <Collapse.Panel
                      header={
                        <Space>
                          <Dropdown.Button size="small" icon={<FilterOutlined />} overlay={<></>} type="primary" onClick={async (e) => { e.stopPropagation(); await add(); setFilterCollapse(true) }}>
                            <PlusOutlined /> {t("formulations.button.addFilter")}
                          </Dropdown.Button>
                          {!!fields.length && <StyledButton size="small" type="primary" ghost onClick={() => {
                            form.resetFields()
                            setFilterList([])
                            setOperatorStates([])
                            setFilterStatus({})
                            dispatch(getCelsureTrialsRequest({
                              reset: true,
                              page_size: 20,
                              filter_list: selectAllProjects ? [...selectedTags.map(tag => selectedTab + ',eq,' + tag)] : [...selectedTags.map(tag => selectedTab + ',eq,' + tag), `project_id,eq,${currentProject}`],
                              ...(searchList?.length ? { search_list: searchList } : {})
                            }))
                            setFilterCollapse(prevState => !prevState)
                          }
                          }>{t("common.clear")}</StyledButton>}
                        </Space>
                      }
                      key="filters"
                    >
                      <Steps size="small" direction="vertical" className="formulation-filters">
                        {fields.map((field) => (
                          <Step
                            status={filterStatus[field.name]}
                            title={<>
                              <Input.Group compact key={field.key} style={{ display: 'flex' }}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'category']}

                                  style={{ flex: 1, marginBottom: 0 }}
                                >
                                  <Cascader size="small" bordered={false} options={filterData} onChange={(value: any) => {
                                    console.log('value', value);

                                    const { filters } = form.getFieldsValue()
                                    if (filters?.[field.name]) {
                                      if (filters?.[field.name].operator === 'range') { Object.assign(filters?.[field.name], { category: value, operator: null, min: null, max: null, val: null }) }
                                      else {
                                        Object.assign(filters?.[field.name], { category: value, operator: null, val: null })

                                      }
                                      setOperatorStates((state: any) => { state[field.name] = null; return state })
                                    }
                                    console.log('filters onchange', filters);

                                    form.setFieldsValue({ filters })
                                  }} placeholder={t("formulations.placeholder.selectOrType")} style={{ minWidth: 400 }} />
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'operator']}

                                  style={{ flex: 1, marginBottom: 0 }}
                                >
                                  <Select size="small" disabled={!form.getFieldValue('filters')?.[field.name]?.category?.[0]} bordered={false} placeholder={t("formulations.placeholder.operator")} onChange={(operator) => {
                                    const { filters } = form.getFieldsValue()
                                    if (filters?.[field.name].operator === 'range') { Object.assign(filters?.[field.name], { min: null, max: null, val: null }) }
                                    else {
                                      Object.assign(filters?.[field.name], { val: null })
                                    }
                                    form.setFieldsValue({ filters })
                                    setOperatorStates((state: any) => { state[field.name] = operator; return state })
                                  }
                                  }>
                                    <Select.Option value="eq">{'='}</Select.Option>
                                    {form.getFieldValue('filters')?.[field.name]?.category?.[0] !== 'pcm_type.pcm_type' && (<><Select.Option value="lt">{'<'}</Select.Option>
                                      <Select.Option value="gt">{'>'}</Select.Option>
                                      <Select.Option value="range">{t("common.range")}</Select.Option></>)}
                                  </Select>
                                </Form.Item>
                                {operatorStates[field.name] === 'range' ? (<>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'min']}

                                    style={{ flex: 1, marginBottom: 0 }}
                                  >
                                    <Input bordered={false} style={{ textAlign: 'center', borderRightWidth: 0 }} placeholder={t("common.minimum")} />
                                  </Form.Item>
                                  <Form.Item style={{ flexBasis: 30, marginBottom: 0 }}>
                                    <Input
                                      bordered={false}
                                      className="site-input-split"
                                      style={{
                                        borderLeft: 0,
                                        borderRight: 0,
                                        pointerEvents: 'none',
                                        backgroundColor: 'transparent',
                                      }}
                                      placeholder="~"
                                      disabled
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'max']}

                                    style={{ flex: 1, marginBottom: 0 }}
                                  >
                                    <Input
                                      bordered={false}
                                      className="site-input-right"
                                      style={{
                                        textAlign: 'center',
                                        borderLeftWidth: 0
                                      }}
                                      placeholder={t("formulations.placeholder.maximum")}
                                    />
                                  </Form.Item>
                                </>) : form.getFieldValue('filters')?.[field.name]?.category?.[0] === 'pcm_type.pcm_type' ? (<Form.Item
                                  {...field}
                                  name={[field.name, 'val']}

                                  style={{ flex: 1, marginBottom: 0 }}
                                >
                                  <Select size="small" bordered={false} placeholder={'Value'} options={Object?.entries(celsureDisplayNamesData?.pcm_type || {}).map(([key, value]: any) => ({
                                    value: key,
                                    label: value?.name,
                                  }))} />
                                </Form.Item>) : (
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'val']}

                                    style={{ flex: 1, marginBottom: 0 }}
                                  >
                                    <Input
                                      size="small"
                                      bordered={false}
                                      style={{
                                        textAlign: 'center',
                                      }}
                                      placeholder={t("formulations.placeholder.value")}
                                    />
                                  </Form.Item>
                                )}
                                <StyledButton icon={<MinusCircleOutlined />} type="link" onClick={() => { setOperatorStates(state => state.filter((o, idx) => idx !== field.name)); remove(field.name); }} />
                              </Input.Group>
                              <Divider style={{ margin: 0 }} />
                            </>}
                          />
                        ))}
                      </Steps>
                    </Collapse.Panel>
                  </Collapse>
                )}
              </Form.List>
              <Divider style={{ margin: 0 }} />
            </Col>
          </Row>
        </Form>
      </Card>
      <CreateProjectModal
        setCreateProjectModal={setCreateProjectModal}
        createProjectModal={createProjectModal}
      />
    </Space>
  )

}
