
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Full Factorial`}</h1>
    <p>{`A design with all possible combinations of all the input factors is called a  `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Factorial_experiment"
      }}>{`Full Factorial Experiment`}</a></p>
    <p>{`The experimental design points in a Full Factorial Design are the vertices of a hyper cube in the n-dimensional design space defined by the "level" values of each of the factors. These experimental points are also called factorial points. For three factors having four levels of each factor, considering full factorial design, total 4³ (64) numbers of experiments have to be carried out.`}</p>
    <p><strong parentName="p">{`Use Cases:-`}</strong></p>
    <ul>
      <li parentName="ul">{`Factorial experiments can be used when there are more than two levels of each factor. However, the number of experimental runs required for three-level (or more) factorial designs will be considerably greater than for their two-level counterparts.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;