import { createActions } from "redux-actions"
import { FormulateData } from "../../services/formulation/interface"

export enum ModelsConfigListActionTypes {
	REQUEST = "MODELS_CONFIG_LIST_REQUEST",
	SUCCESS = "MODELS_CONFIG_LIST_SUCCESS",
	FAILURE = "MODELS_CONFIG_LIST_FAILURE",
	CLEANUP = "MODELS_CONFIG_LIST_CLEANUP",
}

export enum ModelsConfigActionTypes {
	REQUEST = "MODELS_CONFIG_REQUEST",
	SUCCESS = "MODELS_CONFIG_SUCCESS",
	FAILURE = "MODELS_CONFIG_FAILURE",
	CLEANUP = "MODELS_CONFIG_CLEANUP",
}

export enum FormulateActionTypes {
	REQUEST = "FORMULATE_REQUEST",
	SUCCESS = "FORMULATE_SUCCESS",
	FAILURE = "FORMULATE_FAILURE",
	CLEANUP = "FORMULATE_CLEANUP",
	SET_METRIC = "SET_METRIC",
}

export enum ForwardPredictionHistoryActionTypes {
	FORWARD_PRED_LIST_REQUEST = "FORWARD_PRED_LIST_REQUEST",
	FORWARD_PRED_LIST_SUCCESS = "FORWARD_PRED_LIST_SUCCESS",
	FORWARD_PRED_LIST_FAILURE = "FORWARD_PRED_LIST_FAILURE",
	FORWARD_PRED_RESULT_REQUEST = "FORWARD_PRED_RESULT_REQUEST",
	FORWARD_PRED_RESULT_SUCCESS = "FORWARD_PRED_RESULT_SUCCESS",
	FORWARD_PRED_RESULT_FAILURE = "FORWARD_PRED_RESULT_FAILURE",
	FORWARD_PRED_RESULT_CLEAR = "FORWARD_PRED_RESULT_CLEAR",
	FORWARD_PRED_DELETE_REQUEST = "FORWARD_PRED_DELETE_REQUEST",
	FORWARD_PRED_DELETE_SUCCESS = "FORWARD_PRED_DELETE_SUCCESS",
	FORWARD_PRED_DELETE_FAILURE = "FORWARD_PRED_DELETE_FAILURE",
	FORWARD_PRED_RESULT_FILTERS_REQUEST = "FORWARD_PRED_RESULT_FILTERS_REQUEST",
	FORWARD_PRED_RESULT_CURRENTPAGE_REQUEST = "FORWARD_PRED_RESULT_CURRENTPAGE_REQUEST",
	FORWARD_PRED_RESULT_OBJECTIVE_REQUEST = "FORWARD_PRED_RESULT_OBJECTIVE_REQUEST",
	FORWARD_PRED_ID_REQUEST = "FORWARD_PRED_ID_REQUEST"
}

export enum GetForwardConfigsActionTypes {
	REQUEST = "GET_FORWARD_CONFIGS_REQUEST",
	SUCCESS = "GET_FORWARD_CONFIGS_SUCCESS",
	FAILURE = "GET_FORWARD_CONFIGS_FAILURE",
}

export enum GetForwardConfigsZeonActionTypes {
	REQUEST = "GET_FORWARD_CONFIGS_ZEON_REQUEST",
	SUCCESS = "GET_FORWARD_CONFIGS_ZEON_SUCCESS",
	FAILURE = "GET_FORWARD_CONFIGS_ZEON_FAILURE",
}

export enum GetCharacterizationMethodsZeonActionTypes {
	REQUEST = "GET_CHARACTERIZATION_METHODS_ZEON_REQUEST",
	SUCCESS = "GET_CHARACTERIZATION_METHODS_ZEON_SUCCESS",
	FAILURE = "GET_CHARACTERIZATION_METHODS_ZEON_FAILURE",
	CLEAN = "GET_CHARACTERIZATION_METHODS_ZEON_CLEAN",
}

export enum AddFavoritesActionTypes {
	REQUEST = "ADD_FAVORITES_REQUEST",
	SUCCESS = "ADD_FAVORITES_SUCCESS",
	FAILURE = "ADD_FAVORITES_FAILURE",
}

export enum GetFavoritesActionTypes {
	REQUEST = "GET_FAVORITES_REQUEST",
	SUCCESS = "GET_FAVORITES_SUCCESS",
	FAILURE = "GET_FAVORITES_FAILURE",
}

export enum DeleteFavoritesActionTypes {
	REQUEST = "DELETE_FAVORITES_REQUEST",
	SUCCESS = "DELETE_FAVORITES_SUCCESS",
	FAILURE = "DELETE_FAVORITES_FAILURE",
}

export enum FavouriteModelActionTypes {
	REQUEST = "FAVOURITE_MODEL_REQUEST",
	SUCCESS = "FAVOURITE_MODEL_SUCCESS",
	FAILURE = "FAVOURITE_MODEL_FAILURE",
	CLEANUP = "FAVOURITE_MODEL_CLEANUP"
}

export enum ChangeTitleActionTypes {
	REQUEST = "CHANGE_TITLE_FORWARD_REQUEST",
	SUCCESS = "CHANGE_TITLE_FORWARD_SUCCESS",
	FAILURE = "CHANGE_TITLE_FORWARD_FAILURE",
	CLEAR = "CHANGE_TITLE_FORWARD_CLEAR",
}

export enum GetPredictionTrialActionTypes {
	REQUEST = "GET_PREDICTION_TRIAL_REQUEST",
	SUCCESS = "GET_PREDICTION_TRIAL_SUCCESS",
	FAILURE = "GET_PREDICTION_TRIAL_FAILURE",
	CLEAR = "GET_PREDICTION_TRIAL_CLEAR",
}


export const {
	modelsConfigListRequest,
	modelsConfigListSuccess,
	modelsConfigListFailure,
	modelsConfigRequest,
	modelsConfigSuccess,
	modelsConfigFailure,
	modelsConfigCleanup,
	formulateRequest,
	formulateSuccess,
	formulateFailure,
	formulateCleanup,
	setMetric,
	forwardPredListRequest,
	forwardPredListSuccess,
	forwardPredListFailure,
	forwardPredResultRequest,
	forwardPredResultSuccess,
	forwardPredResultFailure,
	forwardPredResultClear,
	forwardPredResultFiltersRequest,
	forwardPredResultCurrentpageRequest,
	forwardPredResultObjectiveRequest,
	forwardPredIdRequest,
	forwardPredDeleteRequest,
	forwardPredDeleteSuccess,
	forwardPredDeleteFailure,
	getForwardConfigsRequest,
	getForwardConfigsSuccess,
	getForwardConfigsFailure,
	getForwardConfigsZeonRequest,
	getForwardConfigsZeonSuccess,
	getForwardConfigsZeonFailure,
	getCharacterizationMethodsZeonRequest,
	getCharacterizationMethodsZeonSuccess,
	getCharacterizationMethodsZeonFailure,
	getCharacterizationMethodsZeonClean,
	addFavoritesRequest,
	addFavoritesSuccess,
	addFavoritesFailure,
	getFavoritesRequest,
	getFavoritesSuccess,
	getFavoritesFailure,
	deleteFavoritesRequest,
	deleteFavoritesSuccess,
	deleteFavoritesFailure,
	favouriteModelRequest,
	favouriteModelSuccess,
	favouriteModelFailure,
	favouriteModelCleanup,
	changeTitleForwardRequest,
	changeTitleForwardSuccess,
	changeTitleForwardFailure,
	changeTitleForwardClear,
	getPredictionTrialRequest,
	getPredictionTrialSuccess,
	getPredictionTrialFailure,
	getPredictionTrialClear,
} = createActions({
	[ModelsConfigListActionTypes.REQUEST]: (_configData: FormulateData) =>
		_configData,
	[ModelsConfigListActionTypes.SUCCESS]: (configData: any) => ({
		configData,
	}),
	[ModelsConfigListActionTypes.FAILURE]: (error: string) => ({ error }),
	[ModelsConfigActionTypes.REQUEST]: (_configData: FormulateData) =>
		_configData,
	[ModelsConfigActionTypes.SUCCESS]: (configData: any) => ({
		configData,
	}),
	[ModelsConfigActionTypes.FAILURE]: (error: string) => ({ error }),
	[ModelsConfigActionTypes.CLEANUP]: () => { },
	[FormulateActionTypes.REQUEST]: (payload) => payload,
	[FormulateActionTypes.SUCCESS]: (payload) => payload,
	[FormulateActionTypes.FAILURE]: (error: string) => ({ error }),
	[FormulateActionTypes.CLEANUP]: () => { },
	[FormulateActionTypes.SET_METRIC]: (payload) => payload,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_REQUEST]: (payload) =>
		payload,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_SUCCESS]: (response) =>
		response,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_LIST_FAILURE]: (error) => ({
		error,
	}),
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_REQUEST]: (
		payload
	) => payload,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_SUCCESS]: (
		response
	) => response,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_FAILURE]: (
		error
	) => ({ error }),
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_CLEAR]: () => { },
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_FILTERS_REQUEST]: (
		payload
	) => payload,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_CURRENTPAGE_REQUEST]: (
		payload
	) => payload,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_RESULT_OBJECTIVE_REQUEST]: (
		payload
	) => payload,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_ID_REQUEST]: (
		payload
	) => payload,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_REQUEST]: (
		payload
	) => payload,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_SUCCESS]: (
		response
	) => response,
	[ForwardPredictionHistoryActionTypes.FORWARD_PRED_DELETE_FAILURE]: (
		error
	) => ({ error }),
	[GetForwardConfigsActionTypes.REQUEST]: (payload) => payload,
	[GetForwardConfigsActionTypes.SUCCESS]: (response) => response,
	[GetForwardConfigsActionTypes.FAILURE]: (error) => ({ error }),
	[GetForwardConfigsZeonActionTypes.REQUEST]: (payload) => payload,
	[GetForwardConfigsZeonActionTypes.SUCCESS]: (response) => response,
	[GetForwardConfigsZeonActionTypes.FAILURE]: (error) => ({ error }),
	[GetCharacterizationMethodsZeonActionTypes.REQUEST]: (payload) => payload,
	[GetCharacterizationMethodsZeonActionTypes.SUCCESS]: (response, from) => ({ response, from }),
	[GetCharacterizationMethodsZeonActionTypes.FAILURE]: (error) => ({ error }),
	[GetCharacterizationMethodsZeonActionTypes.CLEAN]: () => { },
	[AddFavoritesActionTypes.REQUEST]: (payload) => payload,
	[AddFavoritesActionTypes.SUCCESS]: () => { },
	[AddFavoritesActionTypes.FAILURE]: (error) => ({ error }),
	[GetFavoritesActionTypes.REQUEST]: (payload) => payload,
	[GetFavoritesActionTypes.SUCCESS]: (response) => response,
	[GetFavoritesActionTypes.FAILURE]: (error) => ({ error }),
	[DeleteFavoritesActionTypes.REQUEST]: (payload) => payload,
	[DeleteFavoritesActionTypes.SUCCESS]: (payload) => payload,
	[DeleteFavoritesActionTypes.FAILURE]: (error) => ({ error }),
	[FavouriteModelActionTypes.REQUEST]: (payload) => payload,
	[FavouriteModelActionTypes.SUCCESS]: (payload) => payload,
	[FavouriteModelActionTypes.FAILURE]: (error) => ({ error }),
	[FavouriteModelActionTypes.CLEANUP]: (payload) => (payload),
	[ChangeTitleActionTypes.REQUEST]: (payload) => payload,
	[ChangeTitleActionTypes.SUCCESS]: (response) => response,
	[ChangeTitleActionTypes.FAILURE]: error => ({ error }),
	[ChangeTitleActionTypes.CLEAR]: () => ({}),
	[GetPredictionTrialActionTypes.REQUEST]: (payload) => payload,
	[GetPredictionTrialActionTypes.SUCCESS]: (response) => response,
	[GetPredictionTrialActionTypes.FAILURE]: error => error,
	[GetPredictionTrialActionTypes.CLEAR]: () => { },
})
