import { call, put, select } from "redux-saga/effects";

import jwtManager from "src/utils/jwtManager";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import { message, notification } from "antd";
import { messages } from "src/utils/hooks";

// type ApiCallFunction = (payload: any, headers: Record<string, any>) => Promise<AxiosResponse<any, any>>;

export function* performApiCallSaga(
  apiCall: any,
  successAction: Function,
  failureAction: Function,
  payload: any,
  cb?: Function
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(apiCall, payload, headers);
    if (status === "Success") {
      yield put(successAction(data));
      if (cb) {
        yield cb();
      }
    } else {
      yield put(failureAction(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(failureAction());
  }
}

export function* performApiCallSagaWithIdExchange(
  apiCall: any,
  successAction: Function,
  failureAction: Function,
  payload: any,
  cb?: Function
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const { temp_identifier, ...rest } = payload
    const {
      data: {
        result: { status, message, data },
      },
    } = yield call(apiCall, rest, headers);
    if (status === "Success") {
      yield put(successAction({ ...data, temp_identifier }));
      if (cb) {
        yield cb();
      }
    } else {
      yield put(failureAction(message));
      notification.error({ message });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(failureAction());
  }
}

export function* performApiCallSagaV2(
  apiCall: any,
  successAction: Function,
  failureAction: Function,
  payload: any
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message: apiMessage, data },
      },
    } = yield call(apiCall, payload, headers);
    if (status === "Success") {
      yield put(successAction({ data, reqPayload: payload }));
      message.success(apiMessage);
    } else {
      yield put(failureAction(apiMessage));
      notification.error({ message: apiMessage });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(failureAction());
  }
}

export function* performApiCallSagaV3(
  apiCall: any,
  successAction: Function,
  failureAction: Function,
  payload: any
): Generator<any, any, any> {
  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current
  );

  try {
    const { defaultHeaders } = yield select((state) => state);
    const headers = { ...defaultHeaders, token: jwtManager.getToken() };
    const {
      data: {
        result: { status, message: apiMessage, data },
      },
    } = yield call(apiCall, payload, headers);
    if (status === "Success") {
      yield put(successAction(data));
      // message.success(apiMessage);
    } else {
      yield put(failureAction({ message: apiMessage, data }));
      notification.error({ message: apiMessage });
    }
  } catch (error) {
    message.error(messages[ln].internal_server_error);
    yield put(failureAction());
  }
}

export default performApiCallSaga;
