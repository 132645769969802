import { useMemo } from "react";
import KDEChart from "../../Shared/KDEChart";
import MaskTable, { extractNumericValue } from "../../Shared/MaskTable";

type TProps = {
  data: any;
};

const FiberAnalysisAttributes = ({ data }: TProps) => {
  const isCalibrated = data?.mask_results?.[0]?.calibrated_area?.value;

  const maskTableColumns = useMemo(
    () => [
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (item: any) => <>{item?.replace("mask_id_", "Mask ")}</>,
        width: "10%",
      },
      {
        title: "Width",
        dataIndex: "width",
        key: "width",
        render: (item: any) => <>{`${item} px`}</>,
        width: "20%",
        sorter: {
          compare: (a: any, b: any) => {
            return a.width - b.width;
          },
        },
      },
      {
        title: "Length",
        dataIndex: isCalibrated ? "calibrated_length" : "max_length",
        key: isCalibrated ? "calibrated_length" : "length",
        render: (item: any) => <>{item?.value || `${item?.pixel_length} px`}</>,
        width: "20%",
        sorter: {
          compare: (a: any, b: any) =>
            isCalibrated
              ? extractNumericValue(a.calibrated_length.value) -
                extractNumericValue(b.calibrated_length.value)
              : a.max_length.pixel_length - b.max_length.pixel_length,
        },
      },
    ],
    [isCalibrated]
  );

  return (
    <>
      {data && data?.mask_results?.length && (
        <>
          <KDEChart
            data={data?.mask_results}
            unit={data?.unit}
            selections={[]}
            handleChartSelection={() => null}
            titles={{
              graphTitle: "Length Distribution",
              yAxis: "Count",
              xAxis: "Length",
            }}
            showSelections={false}
          />
          <KDEChart
            data={data?.mask_results}
            unit={data?.unit}
            selections={[]}
            handleChartSelection={() => null}
            titles={{
              graphTitle: "Width Distribution",
              yAxis: "Count",
              xAxis: "Width",
            }}
            showSelections={false}
            type="width"
          />
          <h3>Total masks ({data?.mask_results?.length})</h3>
          <MaskTable data={data} columns={maskTableColumns} />
        </>
      )}
    </>
  );
};

export default FiberAnalysisAttributes;
