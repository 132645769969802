import { handleActions } from "redux-actions"
import { AsyncStates } from "src/constants"
import { LinkedFormulationsAiEngineActionTypes } from "../actions/common";

export type CommonState = {
    linkedFormulationData: any,
    linkedFormulationsError: null | string,
    linkedFormulationsStatus: any,
}

const defaultState: CommonState = {
    linkedFormulationData: {},
    linkedFormulationsError: null,
    linkedFormulationsStatus: {},
}


const commonReducer = handleActions<CommonState, any>({
    [LinkedFormulationsAiEngineActionTypes.REQUEST]: (state, action) => {
        return {
            ...state,
            linkedFormulationsStatus: AsyncStates.LOADING,
            linkedFormulationsError: null,
        }
    },
    [LinkedFormulationsAiEngineActionTypes.SUCCESS]: (state, action) => {
        return {
            ...state,
            linkedFormulationsStatus: AsyncStates.SUCCESS,
            linkedFormulationsError: null,
            linkedFormulationData: {
                ...state.linkedFormulationData,
                ...action.payload.data
            },
        };
    },
    [LinkedFormulationsAiEngineActionTypes.FAILURE]: (state, action) => ({
        ...state,
        linkedFormulationsStatus: AsyncStates.ERROR,
        linkedFormulationsError: action.payload.error,
    }),
    [LinkedFormulationsAiEngineActionTypes.CLEAR]: (state, action) => ({
        ...state,
        linkedFormulationsStatus: AsyncStates.INITIAL,
        linkedFormulationsError: null,
        linkedFormulationData: {}
    })
}, defaultState);

export default commonReducer;
