import { LoadingOutlined } from "@ant-design/icons"
import { Form, Select, Spin, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";
import { AsyncStates } from "src/constants"
import { multistageHeatmapCleanupOptions, multistageHeatmapCleanupResult, multistageHeatmapRequest } from "src/store/actions/multistageHeatmap";
import { workOrdersRequest } from "src/store/actions/workOrders"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate"

const WorkorderForm = () => {

  // Other utility hooks
  const [t] = useTranslate()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const requiredFieldStar = useRequiredFieldStar()

  // Selectors
  const { data: workOrdersList, status: workOrderStatus } = useSelector(
    (state: StoreState) => state.workOrders,
  )
  const { current: currentProject } = useSelector((state: StoreState) => state.projects);
  const { multistageHeatmapOptionsStatus } = useSelector((state: StoreState) => state.multiStageHeatmap)
  const project_id = useSelector((state: StoreState) => state.projects.current)


  // States
  const [workOrderIds, setWorkOrderIds] = useState<string[]>([])
  const [initialStages, setInitialStages] = useState<any[]>([])
  const [finalStages, setFinalStages] = useState<any[]>([])


  // Effects
  useEffect(() => {
    dispatch(
      workOrdersRequest({
        project_id: currentProject,
        closed_stages: true,
      })
    );
  }, [dispatch, currentProject])

  useEffect(() => {
    dispatch(multistageHeatmapCleanupOptions())
    dispatch(multistageHeatmapCleanupResult())
  }, [dispatch, project_id])

  useEffect(() => {
    setWorkOrderIds([]);
    setInitialStages([]);
    setFinalStages([]);
    form.setFieldsValue({
      workOrderIds: undefined,
      initialStages: undefined,
      finalStages: undefined,
    });
  }, [form, project_id])

  // Memo

  const workordersOptions = useMemo(() => {
    return (workOrderStatus !== AsyncStates.LOADING) && workOrdersList.filter((wo: any) => wo?.stages?.length > 1).map((wo: any) => {
      return (
        <Select.Option value={wo.work_order_id} key={wo.work_order_id}>
          {wo.work_order_name}
        </Select.Option>
      );
    })
  }, [
    workOrdersList,
    workOrderStatus,
  ])

  const initialStagesOptions = useMemo(() => {
    const stages = workOrdersList.filter((wo: any) => wo.work_order_id === workOrderIds[0])[0]?.stages || []
    const expIds = workOrdersList.filter((wo: any) => wo.work_order_id === workOrderIds[0])[0]?.experiment_id || []
    return stages.map((stage: any, idx: number) => {
      return (
        <Select.Option value={expIds[idx]} key={expIds[idx]}>
          {stage.actual_stage_name}
        </Select.Option>
      );
    })
  }, [
    workOrdersList,
    workOrderIds,
  ])


  const finalStagesOptions = useMemo(() => {
    const stages = workOrdersList.filter((wo: any) => wo.work_order_id === workOrderIds[0])[0]?.stages || []
    const expIds = workOrdersList.filter((wo: any) => wo.work_order_id === workOrderIds[0])[0]?.experiment_id || []
    return stages.map((stage: any, idx: number) => {
      return (
        <Select.Option value={expIds[idx]} key={expIds[idx]}>
          {stage.actual_stage_name}
        </Select.Option>
      );
    })
  }, [workOrderIds, workOrdersList])


  // Helper functions

  const onSubmit = (values: any) => {
    dispatch(multistageHeatmapRequest({
      work_order_ids: workOrderIds,
      initial_stage_exp_ids: initialStages,
      final_stage_exp_ids: finalStages,
      newSocket: true,
    }))
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="multistage-heatmap"
      onFinish={onSubmit}
      style={{
        display: "grid",
        gap: "0.5rem",
        gridTemplateColumns: `repeat(auto-fit, minmax(100px,0.75fr))`,
        alignItems: 'start'
      }}
      requiredMark={false}
    >
      <Form.Item label={t("common.selectWorkOrders")}
        required
        tooltip={requiredFieldStar}
        name={"workOrderIds"}
      >
        <Select
          dropdownRender={(menu) => {
            return (
              <Spin
                spinning={workOrderStatus === AsyncStates.LOADING}
                indicator={<LoadingOutlined />}
              >
                <div
                  style={
                    workOrderStatus === AsyncStates.LOADING
                      ? { height: 100, opacity: 0 }
                      : {}
                  }
                >
                  {/* {workOrdersList.length > 0 && (
                          <>
                            <Checkbox
                              style={{ padding: 10 }}
                              checked={
                                workOrderIds?.length === workOrdersList?.length
                              }
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setWorkOrderIds(
                                    workOrdersList.map(
                                      (workOrder) => workOrder.work_order_id,
                                    ),
                                  )
                                } else {
                                  setWorkOrderIds([])
                                }
                              }}
                            >
                              {`${t("common.selectAll")} ${t(
                                "common.workOrders",
                              )}`}
                            </Checkbox>
                            <Divider style={{ margin: "0.25rem" }} />
                          </>
                        )} */}
                  {menu}
                </div>
              </Spin>
            )
          }}
          style={{ width: "100%" }}
          value={workOrderIds[0]}
          placeholder={t("common.selectWorkOrders")}
          optionFilterProp="children"
          maxTagCount={2}
          // mode="multiple"
          showSearch
          notFoundContent={<Typography.Text>{t("datasummary.noClosedWorkOrders")}</Typography.Text>}
          onChange={(woId: string) => {
            if (woId !== undefined) {
              setWorkOrderIds([woId]);
              setInitialStages([]);
              setFinalStages([]);
              form.setFieldsValue({
                initialStages: undefined,
                finalStages: undefined,
              });
            }
          }}
          allowClear
          onDeselect={(e) => {
          }}
          onClear={() => {
            setWorkOrderIds([]);
            setInitialStages([]);
            setFinalStages([]);
            form.setFieldsValue({
              workOrderIds: undefined,
              initialStages: undefined,
              finalStages: undefined,
            });
          }}
        >
          {workordersOptions}
        </Select>
      </Form.Item>

      <Form.Item label={t("common.startStage")}
        required
        tooltip={requiredFieldStar}
        name={"initialStages"}
      >
        <Select
          dropdownRender={(menu) => {
            return (
              <Spin
                spinning={workOrderStatus === AsyncStates.LOADING}
                indicator={<LoadingOutlined />}
              >
                <div
                  style={
                    workOrderStatus === AsyncStates.LOADING
                      ? { height: 100, opacity: 0 }
                      : {}
                  }
                >
                  {menu}
                </div>
              </Spin>
            )
          }}
          style={{ width: "100%" }}
          value={initialStages[0]}
          placeholder={t("common.startStage")}
          optionFilterProp="children"
          maxTagCount={2}
          // mode="multiple"
          showSearch
          onChange={(stages: any) => {
            if (stages !== undefined) {
              setInitialStages([stages]);
              setFinalStages([]);
              form.setFieldsValue({
                finalStages: undefined,
              });
            }
          }}
          allowClear
          onDeselect={(e) => {
          }}
          onClear={() => {
            setInitialStages([]);
            form.setFieldsValue({
              finalStages: undefined,
            });
          }}>
          {initialStagesOptions}
        </Select>
      </Form.Item>

      <Form.Item label={t("common.endStage")}
        required
        tooltip={requiredFieldStar}
        name={"finalStages"}
      >
        <Select
          dropdownRender={(menu) => {
            return (
              <Spin
                spinning={workOrderStatus === AsyncStates.LOADING}
                indicator={<LoadingOutlined />}
              >
                <div
                  style={
                    workOrderStatus === AsyncStates.LOADING
                      ? { height: 100, opacity: 0 }
                      : {}
                  }
                >
                  {menu}
                </div>
              </Spin>
            )
          }}
          style={{ width: "100%" }}
          value={finalStages[0]}
          placeholder={t("common.endStage")}
          optionFilterProp="children"
          maxTagCount={2}
          // mode="multiple"
          showSearch
          onChange={(stages: any) => {
            if (stages !== undefined)
              setFinalStages([stages]);
          }}
          allowClear
          onDeselect={(e) => {
          }}
          onClear={() => {
            setFinalStages([]);
          }}>
          {finalStagesOptions}
        </Select>
      </Form.Item>
      <Form.Item style={{ width: "100%" }} label={" "}>
        <StyledButton
          htmlType="submit"
          type="primary"
          loading={(multistageHeatmapOptionsStatus === AsyncStates.LOADING)}
          disabled={
            workOrderStatus === AsyncStates.LOADING ||
            workOrderIds.length === 0 ||
            initialStages.length === 0 ||
            finalStages.length === 0
          }
        >
          {`${t("common.generate")} ${t("common.options")}`}
        </StyledButton>
      </Form.Item>
    </Form>
  )
}

export default WorkorderForm
