import { Drawer } from "antd";
import { useHistory, useParams } from "react-router-dom";
import "../../Shared/SEMAnalysis.scss";
import ProtrusionAnalysisDetailsSidebar from "./ProtrusionAnalysisDetailsSidebar";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadSemAnalysisReportRequest,
  fetchClassificationAnalysisClear,
  fetchClassificationAnalysisRequest,
  fetchSemAnalysisMaskDataRequest,
  fetchSemAnalysisMaskHeightWidthRequest,
  fetchSemAnalysisScaleRequest,
  setSemAnalysisMaskHeightWidthRequest,
} from "src/store/actions/semAnalysis";
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { CreateGroupModal } from "../../Shared/CreateGroupModal";
import CalibrateModal from "../../Shared/CalibrateModal";
import ProtrusionAnalysisDetailsContent from "./ProtrusionAnalysisDetailsContent";
import ProtrusionAnalysisAttributes from "./ProtrusionAnalysisAttributes";
import { IMAGE_ANALYSIS_TYPES } from "../../SEMAnalysisWrapper";

const ProtrusionAnalysisDetails = () => {
  const params = useParams<any>();
  const mounted = useRef(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const [showBBox, setShowBBox] = useState(false);
  const [showGeneralAnalysis, setShowGeneralAnalysis] = useState(false);
  const [selectedPoints, setSelectedPoints] = useState<any[]>([]);
  const [showCreateGrooupModal, setShowCreateGroupModal] = useState(false);
  const [isGroupsVisible, setIsGroupsVisible] = useState(false);
  const [showCalibrateModal, setShowCalibrateModal] = useState(false);
  const [tooltip, setTooltip] = useState({});
  const [selections, setSelections] = useState<any[]>([]);
  const [showLines] = useState(true);
  const {
    semAnalysisMaskData,
    downloadSemAnalysisReportStatus,
    analysisTypes,
    deleteSemAnalysisMaskHeightWidthStatus,
    setSemAnalysisMaskHeightWidthStatus,
    classificationAnalysisStatus,
    semAnalysisScaleStatus
  } = useSelector((state: StoreState) => state.semAnalysis);

  useLayoutEffect(() => {
    if (!mounted.current || semAnalysisScaleStatus === AsyncStates.SUCCESS) {
      mounted.current = true;
      dispatch(fetchClassificationAnalysisRequest({ file_id: params.fileId }));
    }

    return () => {
      dispatch(fetchClassificationAnalysisClear());
    }
  }, [analysisTypes, dispatch, params.fileId, semAnalysisScaleStatus]);

  useEffect(() => {
    if (classificationAnalysisStatus === AsyncStates.SUCCESS) {
      dispatch(fetchSemAnalysisMaskDataRequest({
        file_id: params.fileId,
        analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS as keyof typeof analysisTypes]
      }));
      dispatch(
        fetchSemAnalysisMaskHeightWidthRequest({
          file_id: params.fileId,
          analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS as keyof typeof analysisTypes]
        })
      );
    }
  }, [analysisTypes, classificationAnalysisStatus, dispatch, params.fileId]);

  useEffect(() => {
    if (
      deleteSemAnalysisMaskHeightWidthStatus === AsyncStates.SUCCESS ||
      setSemAnalysisMaskHeightWidthStatus === AsyncStates.SUCCESS
    )
      setSelectedPoints([]);
  }, [
    deleteSemAnalysisMaskHeightWidthStatus,
    setSemAnalysisMaskHeightWidthStatus,
    setSelectedPoints,
  ]);

  const downloadReport = useCallback(() => {
    const payload: any = {
      file_id: params.fileId,
      analysis_type: !showGeneralAnalysis
        ? analysisTypes[
        IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS as keyof typeof analysisTypes
        ]
        : analysisTypes[
        IMAGE_ANALYSIS_TYPES.GENERAL_ANALYSIS as keyof typeof analysisTypes
        ],
    };

    dispatch(downloadSemAnalysisReportRequest(payload));
  }, [analysisTypes, dispatch, params.fileId, showGeneralAnalysis]);

  const handleCreateGroup = useCallback(
    (val: any) => {
      const points: any = {};
      selectedPoints.forEach((item: any, i: any) => {
        points[`p${i}`] = item;
      });
      const obj = { group_name: val, points };
      dispatch(
        setSemAnalysisMaskHeightWidthRequest({
          ...obj,
          file_id: params.fileId,
          analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS as keyof typeof analysisTypes]
        })
      );
    },
    [params.fileId, dispatch, selectedPoints, analysisTypes]
  );

  const handleCalibrate = useCallback(
    (scale_length: any, unit: any) => {
      dispatch(
        fetchSemAnalysisScaleRequest({
          scale_length,
          unit,
          file_id: params.fileId,
          analysis_type: analysisTypes[IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS as keyof typeof analysisTypes]
        })
      );
    },
    [dispatch, params.fileId, analysisTypes]
  );

  const handleChartSelection = useCallback((eventData) => {
    if (eventData.selections && Array.isArray(eventData.selections))
      setSelections(eventData.selections);
    else {
      setSelections([
        {
          xref: "x",
          yref: "y",
          line: { width: 1, dash: "dot" },
          type: "rect",
          x0: eventData["selections[0].x0"],
          y0: eventData["selections[0].y0"],
          x1: eventData["selections[0].x1"],
          y1: eventData["selections[0].y1"],
        },
      ]);
    }
  }, []);

  const onDrawerClose = () =>
    history.push(`/sem-analysis/${IMAGE_ANALYSIS_TYPES.PROTRUSION_ANALYSIS}`);

  return (
    <Drawer
      title={"Protrusion Analysis"}
      placement="bottom"
      open={true}
      height="100%"
      styles={{
        body: { padding: 0 },
      }}
      onClose={onDrawerClose}
      className="paint-film-analysis-drawer"
      extra={
        <>
          <StyledButton
            type="primary"
            onClick={downloadReport}
            style={{ marginRight: 10 }}
            loading={downloadSemAnalysisReportStatus === AsyncStates.LOADING}
          >
            {t("report.downloadReport")}
          </StyledButton>
        </>
      }
    >
      <div className="container">
        <ProtrusionAnalysisDetailsSidebar
          showBBox={showBBox}
          setShowBBox={setShowBBox}
          showGeneralAnalysis={showGeneralAnalysis}
          setShowGeneralAnalysis={setShowGeneralAnalysis}
          selectedPoints={selectedPoints}
          setSelectedPoints={setSelectedPoints}
          setShowCreateGroupModal={setShowCreateGroupModal}
          isGroupsVisible={isGroupsVisible}
          setIsGroupsVisible={setIsGroupsVisible}
          setShowCalibrateModal={setShowCalibrateModal}
          tooltip={tooltip}
        />
        <ProtrusionAnalysisDetailsContent
          showGeneralAnalysis={showGeneralAnalysis}
          data={semAnalysisMaskData}
          image={semAnalysisMaskData?.original_image_url}
          showBBox={showBBox}
          selectedPoints={selectedPoints}
          setSelectedPoints={setSelectedPoints}
          isGroupsVisible={isGroupsVisible}
          setTooltip={setTooltip}
          selections={selections}
          showLines={showLines}
        />
      </div>
      <div className="info-container">
        <ProtrusionAnalysisAttributes
          showGeneralAnalysis={showGeneralAnalysis}
          handleChartSelection={handleChartSelection}
          selections={selections}
        />
      </div>
      {showCreateGrooupModal && (
        <CreateGroupModal
          createGroupModal={showCreateGrooupModal}
          setCreateGroupModal={setShowCreateGroupModal}
          handleCreateGroup={handleCreateGroup}
        />
      )}
      {showCalibrateModal && (
        <CalibrateModal
          calibrateModal={showCalibrateModal}
          setCalibrateModal={setShowCalibrateModal}
          handleCalibrate={handleCalibrate}
        />
      )}
    </Drawer>
  );
};

export default ProtrusionAnalysisDetails;
