import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeTitleForwardClear, favouriteModelCleanup, forwardPredIdRequest, forwardPredResultClear, forwardPredResultCurrentpageRequest, forwardPredResultFiltersRequest, forwardPredResultRequest, setMetric } from 'src/store/actions/formulate'
import { useQuery } from 'src/utils/useQuery'
import { PredictionResult } from './prediction-result'

const ForwardResult = ({ tab }: any) => {
  const dispatch = useDispatch()
  const query = useQuery()

  const predId = query?.get('predId')

  useEffect(() => {
    return () => {
      dispatch(changeTitleForwardClear())
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(forwardPredResultClear())
    }
  }, [dispatch])

  useEffect(() => {
    if (predId) {
      dispatch(setMetric({}))
      dispatch(favouriteModelCleanup())
      dispatch(
        forwardPredResultRequest({
          prediction_id: predId,
          type: "history"
        })
      )
      dispatch(forwardPredIdRequest(predId))
      dispatch(forwardPredResultCurrentpageRequest(1))
      dispatch(forwardPredResultFiltersRequest({}))
    }
  }, [dispatch, predId])
  return (
    <PredictionResult currentTab={tab} />
  )
}

export default ForwardResult