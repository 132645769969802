import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
	Modal,
	Space,
	Input,
	Typography,
	Table,
	Checkbox,
	Select,
	Form,
	Spin,
} from "antd"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "../../store/configureStore"
import { AsyncStates, allowedUnitCategories } from "src/constants"
import { createParameterRequest } from "src/store/actions/inventory"
import { LoadingOutlined } from "@ant-design/icons"
import { setIsEditing } from "src/store/actions/isEditing"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import { fetchAllCategoriesRequest } from "src/store/actions/repository"
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar"

const { Text } = Typography
const { Option } = Select

interface AddFactorsModalProps {
	addFactorsModalVisible: boolean
	setAddFactorsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
	ingredientsConstantData: any[]
	setIngredientsConstantData: React.Dispatch<React.SetStateAction<any[]>>
	ingredientsFactors: any[]
	setIngredientsFactors: React.Dispatch<React.SetStateAction<any>>
	selectedAlgorithm: any
	from: string
	processingFactors: any[]
	setProcessingFactors: React.Dispatch<React.SetStateAction<any[]>>
	processingConstanstData: any[]
	setProcessingConstanstData: React.Dispatch<React.SetStateAction<any[]>>
	newFactorsModalVisible: boolean
	setNewFactorsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddFactorsModal = ({
	addFactorsModalVisible,
	setAddFactorsModalVisible,
	ingredientsConstantData,
	setIngredientsConstantData,
	ingredientsFactors,
	setIngredientsFactors,
	selectedAlgorithm,
	from,
	processingFactors,
	setProcessingFactors,
	processingConstanstData,
	setProcessingConstanstData,
	newFactorsModalVisible,
	setNewFactorsModalVisible,
}: AddFactorsModalProps) => {
	const dispatch = useDispatch()
	const [t] = useTranslate()
	const requiredFieldStar = useRequiredFieldStar()

	const createParameterStatus = useSelector(
		(state: StoreState) => state.inventory.createParameterStatus
	)
	const { data: displayNames, statusWithoutSpinner } = useSelector(
		(state: StoreState) => state.displayNames
	)
	const ingredientAndPropertyCategories = useSelector((state: StoreState) => state.repository.allCategories.data);
	const ingredientAndPropertyCategoriesStatus = useSelector((state: StoreState) => state.repository.allCategories.status);
	const unitsList = useSelector((state: StoreState) => state.conversion.unitList);

	const [factorModalData, setFactorsModalData] = useState<any>([])
	const [selectedFactors, setSelectedFactors] = useState<any>([])
	const [type, setType] = useState<any>("ingredients")
	const [searchText, setSearchText] = useState<string>("")
	const [form] = Form.useForm()

	const addFactorsColumn = [
		{
			dataIndex: "factor",
			title: t("common.factorName"),
			key: "factor",
		},
		{
			dataIndex: "select",
			title: t("common.select"),
			key: "select",
			render: (text: any, record: any, index: any) => {
				return (
					<Checkbox
						checked={selectedFactors.includes(record.key)}
						onChange={(e: any) => {
							if (e.target.checked) {
								setSelectedFactors([
									...new Set([...selectedFactors, record.key]),
								])
							} else {
								setSelectedFactors(
									selectedFactors.filter((res: any) => res !== record.key)
								)
							}
						}}
					></Checkbox>
				)
			},
		},
	]

	useEffect(() => {
		if (createParameterStatus === AsyncStates.SUCCESS) {
			form.resetFields()
			setNewFactorsModalVisible(false)
			setSearchText("")
		}
	}, [dispatch, createParameterStatus, setNewFactorsModalVisible, form])

	const setModalState = useCallback(() => {
		if (type === "ingredients") {
			if (searchText?.trim()) {
				setFactorsModalData(
					Object.entries(displayNames?.ingredients || {})
						.map(([key, value]: any) => ({
							factor: value?.name,
							select: value?.name,
							key,
						}))
						.filter(
							(element: any) =>
								!ingredientsFactors
									.map((res: any) => res?.key)
									.includes(element.key)
						)
						.filter(
							(element: any) =>
								!ingredientsConstantData
									.map((res: any) => res?.key)
									.includes(element.key)
						)
						.filter((res: any) =>
							res?.factor?.toLocaleLowerCase()?.includes(searchText)
						)
				)
			} else {
				setFactorsModalData(
					Object.entries(displayNames?.ingredients || {})
						.map(([key, value]: any) => ({
							factor: value?.name,
							select: value?.name,
							key,
						}))
						.filter(
							(element: any) =>
								!ingredientsFactors
									.map((res: any) => res?.key)
									.includes(element.key)
						)
						.filter(
							(element: any) =>
								!ingredientsConstantData
									?.map((res: any) => res?.key)
									?.includes(element.key)
						)
				)
			}
		} else if (type === "processing") {
			if (searchText?.trim()) {
				setFactorsModalData(
					Object.entries(displayNames?.[type] || {})
						.map(([key, value]: any) => ({
							factor: value?.name,
							select: value?.name,
							key,
						}))
						.filter(
							(element: any) =>
								!processingFactors
									.map((res: any) => res?.key)
									.includes(element.key)
						)
						.filter(
							(element: any) =>
								!processingConstanstData
									.map((res: any) => res?.key)
									.includes(element.key)
						)
						.filter((res: any) =>
							res?.factor?.toLocaleLowerCase()?.includes(searchText)
						)
				)
			} else {
				setFactorsModalData(
					Object.entries(displayNames?.[type] || {})
						.map(([key, value]: any) => ({
							factor: value?.name,
							select: value?.name,
							key,
						}))
						.filter(
							(element: any) =>
								!processingFactors
									.map((res: any) => res?.key)
									.includes(element.key)
						)
						.filter(
							(element: any) =>
								!processingConstanstData
									?.map((res: any) => res?.key)
									?.includes(element.key)
						)
				)
			}
		}
	}, [
		displayNames,
		ingredientsConstantData,
		ingredientsFactors,
		processingConstanstData,
		processingFactors,
		searchText,
		type,
	])

	useEffect(() => {
		setModalState()
	}, [setModalState])

	useEffect(() => {
		dispatch(fetchAllCategoriesRequest())
	}, [dispatch])


	const categoriesOptions = useMemo(() => {
		if (type === "ingredients") {
			return ingredientAndPropertyCategories?.["ingredient_category"]?.map((category: any) => ({
				label: category.name,
				value: category.category_id
			}))
		} else if (type === "processing") {
			return [
				...new Set(
					Object.values(displayNames?.[type] || {}).map((res: any) => res?.category)
				),
			].map((res: any) => ({ label: res, value: res }))
		}
	}, [displayNames, ingredientAndPropertyCategories, type])

	const unitOptions = useMemo(() => {

		const allowedCategories = type === "ingredients" ? allowedUnitCategories : unitsList.reduce((acc: any, unit: any) => {
			if (unit.category && !acc.includes(unit.category)) {
				acc.push(unit.category)
			}
			return acc
		}, [])

		const filteredCategoryBasedOnUnit = unitsList.filter((unit: any) => !!unit.category?.length ? allowedCategories?.map((cat: string) => cat?.toLowerCase())?.includes(unit.category?.toLowerCase()) : false) ?? []
		return allowedCategories.reduce((acc: any, category: string) => {
			const categories = unitsList.filter((unit: any) => unit.category?.toLowerCase() === category?.toLowerCase())
			if (categories.length > 0) {
				acc.push({
					label: filteredCategoryBasedOnUnit?.find((unit: any) => unit.category?.toLowerCase() === category)?.category ?? category,
					options: unitsList.filter((unit: any) => unit.category === category).map((unit: any) => ({
						label: unit.name,
						value: unit.name
					}))
				})
			}
			return acc
		}, [])
	}, [type, unitsList])

	return (
		<>
			<Modal
				cancelButtonProps={{ style: { borderRadius: 12, } }}
				cancelText={t("common.cancel")}
				title={t("doe.searchInInventory")}
				visible={addFactorsModalVisible}
				maskClosable={false}
				onCancel={() => {
					setSearchText("")
					setAddFactorsModalVisible(false)
					setSelectedFactors([])
				}}
				okText={t("common.confirm")}
				onOk={() => {
					if (from === "constantFactorsTable") {
						if (type === "ingredients") {
							setIngredientsConstantData((prevState: any) => {
								const array = prevState.map((res: any) => res)
								selectedFactors.forEach((factor: any, index: any) => {
									array.push({
										factor: displayNames?.ingredients?.[factor]?.name,
										value: "",
										unit: "g",
										key: factor,
										factorType: "ingredients",
									})
								})
								return array
							})
						} else if (type === "processing") {
							setProcessingConstanstData((prevState: any) => {
								const array = prevState.map((res: any) => res)
								selectedFactors.forEach((factor: any, index: any) => {
									array.push({
										factor: displayNames?.[type]?.[factor]?.name,
										value: "",
										unit: displayNames?.[type]?.[factor]?.unit?.[0],
										key: factor,
										factorType: "processing",
									})
								})
								return array
							})
						}
					} else {
						if (type === "ingredients") {
							setIngredientsFactors((prevState: any) => {
								const array = prevState.map((res: any) => res)
								selectedFactors.forEach((factor: any, index: any) => {
									array.push({
										factor: displayNames?.[type]?.[factor]?.name,
										unit: "g",
										low: null,
										center: null,
										high: null,
										key: factor,
										level: selectedAlgorithm.parameters.levels === 2 ? 2 : 3,
										factorType: "ingredients",
									})
								})
								return array
							})
						} else if (type === "processing") {
							setProcessingFactors((prevState: any) => {
								const array = prevState.map((res: any) => res)
								selectedFactors.forEach((factor: any, index: any) => {
									array.push({
										factor: displayNames?.[type]?.[factor]?.name,
										unit: displayNames?.processing?.[factor]?.unit?.[0] || "-",
										low: null,
										center: null,
										high: null,
										key: factor,
										level: selectedAlgorithm.parameters.levels === 2 ? 2 : 3,
										factorType: "processing",
									})
								})
								return array
							})
						}
					}
					setSearchText("")
					setAddFactorsModalVisible(false)
					setSelectedFactors([])
				}}
				okButtonProps={{ disabled: !selectedFactors?.length, style: { borderRadius: 12 } }}
			>
				<Spin
					spinning={statusWithoutSpinner === AsyncStates.LOADING}
					indicator={<LoadingOutlined />}
				>
					<Space size="middle" direction="vertical" style={{ width: "100%" }}>
						<Text type="secondary">
							{t("addFactorsModal.searchFactorsAndSelectNote")}
						</Text>
						<Space>
							<Text strong>{t("addFactorsModal.selectFactorType")}</Text>
							<Select
								onChange={(e: any) => {
									setSelectedFactors([])
									setType(e)
								}}
								value={type}
							>
								<Option value={"ingredients"}>
									{t("common.ingredientsFactors")}
								</Option>
								<Option value={"processing"}>
									{t("common.processingFactors")}
								</Option>
							</Select>
						</Space>
						<Input.Search
							placeholder={t("common.searchFactor")}
							value={searchText}
							onChange={(e: any) =>
								setSearchText(e.target.value?.toLocaleLowerCase())
							}
						/>
						<Table
							columns={addFactorsColumn}
							dataSource={factorModalData}
							pagination={{ pageSize: 6, showSizeChanger: false }}
						/>
					</Space>
				</Spin>
			</Modal>

			<Modal
				okButtonProps={{ style: { borderRadius: 12 } }}
				cancelButtonProps={{ style: { borderRadius: 12, } }}
				okText={t("common.ok")}
				cancelText={t("common.cancel")}
				title={t("addFactorsModal.AddNewFactorsInventory")}
				visible={newFactorsModalVisible}
				footer={null}
				maskClosable={false}
				onCancel={() => {
					form.resetFields()
					setNewFactorsModalVisible(false)
				}}
			>
				<Spin
					indicator={<LoadingOutlined />}
					spinning={
						createParameterStatus === AsyncStates.LOADING ||
						statusWithoutSpinner === AsyncStates.LOADING
					}
				>
					<Form
						layout="vertical"
						requiredMark={false}
						initialValues={{ type: "ingredients" }}
						onFinish={(values) => {
							const params = new FormData()
							params.append("name", values?.name)
							params.append("type", type)
							params.append("category", values?.category)
							params.append("unit", JSON.stringify([values.unit]))
							dispatch(createParameterRequest(params))
							dispatch(setIsEditing(false))
						}}
						form={form}
						onFieldsChange={() => dispatch(setIsEditing(true))}
					>
						<Form.Item
							label={t("common.type")}
							rules={[{ required: true }]}
							name="type"
							required tooltip={requiredFieldStar}
						>
							<Select value={type} onChange={(value) => {
								setType(value)
								form.setFieldValue("category", null)
							}}>
								<Option value="ingredients"> {t("common.ingredients")}</Option>
								<Option value="processing"> {t("common.processing")}</Option>
							</Select>
						</Form.Item>

						<Form.Item
							label={t("common.Name")}
							rules={[{ required: true }]}
							name="name"
							required tooltip={requiredFieldStar}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label={t("common.category")}
							rules={[{ required: true }]}
							name="category"
							required tooltip={requiredFieldStar}
						>
							<Select
								showSearch
								optionFilterProp="label"
								options={categoriesOptions}
								dropdownRender={(menu) => {
									return (
										<Spin spinning={ingredientAndPropertyCategoriesStatus === AsyncStates.LOADING}>
											{menu}
										</Spin>
									)
								}}
							/>
						</Form.Item>

						<Form.Item
							label={t("common.unit")}
							rules={[{ required: true }]}
							name="unit"
							required tooltip={requiredFieldStar}
						>
							<Select
								showSearch
								options={unitOptions}
							/>
						</Form.Item>

						<StyledButton
							type="primary"
							htmlType="submit"
							style={{ width: "100%" }}
						>
							{t("common.submit")}
						</StyledButton>
					</Form>
				</Spin>
			</Modal>
		</>
	)
}
