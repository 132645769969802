import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, Input, Space, Typography, message, Layout } from "antd";
import LeftSideOnboarding from "../SignUp/LeftSideOnboarding";
import "./ResetPassword.scss"
import Sha1 from "js-sha1"
import { InfoCircleFilled } from "@ant-design/icons"
import useTranslate from "src/utils/useTranslate"
import { StoreState } from "src/store/configureStore";
import { AsyncStates } from "src/constants";
import { resetPasswordClear, resetPasswordRequest } from "src/store/actions/resetPassword";
import Spinner from "src/components/Spinner/Spinner";
import AllRightsReserved from "../SignUp/AllRightsReserved";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";
import { StyledButton } from "src/styled_components/StyledButton";
const { Title } = Typography

const { Content: LayoutContent } = Layout

const ResetPassword = () => {
  const dispatch = useDispatch()
  const requiredFieldStar = useRequiredFieldStar()

  const [type, setType] = useState("none")
  const [t] = useTranslate()

  const apiStatus = useSelector<StoreState, AsyncStates>(
    (state) => state.resetPassword.status
  )

  const errorMsg = useSelector<StoreState, string>(
    (state) => state.resetPassword.error
  )
  const submitForm = (values: any) => {
    const { password, confirm_password } = values
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("token")
    if (password === confirm_password) {
      const encryptedPassword = Sha1(password.trim())
      dispatch(resetPasswordRequest({ token, password: encryptedPassword }))
      setType("none")
    } else {
      setType("block")
    }
  }

  const errorCall = () => {
    message.error({
      content: errorMsg,
      className: "ant-message",
      style: {
        marginTop: "5vh",
      },
      duration: 4,
      onClose: () => dispatch(resetPasswordClear()),
    })
  }

  useEffect(() => {
    dispatch(resetPasswordClear())
  }, [dispatch])

  return (
    <Layout
      style={{ textAlign: "center", minHeight: "100%", background: "white" }}
      className="fontWorkSans"
    >
      <LayoutContent style={{ overflow: "auto", position: "relative" }}>
        <Space style={{ width: "100%" }} direction="horizontal" size="large">
          <LeftSideOnboarding minWidth="55vw" />
          <Space
            direction="vertical"
            style={{
              width: "100%",
              margin: "48px",
              maxWidth: "35vw",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title
              style={{
                marginBottom: "40px",
                color: "#1753E2",
                textAlign: "center",
                fontSize: "2.5rem",
                fontWeight: 500,
              }}
              level={1}
            >
              {t("resetPassword.resetPassword")}
            </Title>
            <Form
              name="normal_login"
              className="login-form"
              layout="vertical"
              onFinish={submitForm}
              requiredMark={false}
            >
              <Form.Item
                label="Password"
                name="password"
                style={{ width: "80%", margin: "auto" }}
                required tooltip={requiredFieldStar}
                rules={[
                  {
                    required: true,
                    message: (
                      <>
                        <InfoCircleFilled
                          color="red.primary"
                          style={{ marginRight: "2.5px" }}
                        />
                        {"Please input your Password!"}
                      </>
                    ),
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])([a-zA-Z0-9@!*#$%]{8,})*$/
                    ),
                    message: (
                      <>
                        <InfoCircleFilled
                          color="red.primary"
                          style={{ marginRight: "2.5px" }}
                        />
                        Password must contain at least 8 characters that include
                        at least 1 lowercase character, 1 uppercase characters,
                        1 number, and 1 special character or Password cannot
                        start and end with spaces.
                      </>
                    ),
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Password"
                  className="round-text"
                  type="password"
                  minLength={8}
                  style={{
                    borderRadius: "7.5px",
                    minHeight: "50px",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                style={{ width: "80%", margin: "auto" }}
                required tooltip={requiredFieldStar}
                rules={[
                  {
                    required: true,
                    message: (
                      <>
                        <InfoCircleFilled
                          color="red.primary"
                          style={{ marginRight: "2.5px" }}
                        />
                        {"Please input your Password!"}
                      </>
                    ),
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])([a-zA-Z0-9@!*#$%]{8,})*$/
                    ),
                    message: (
                      <>
                        <InfoCircleFilled
                          color="red.primary"
                          style={{ marginRight: "2.5px" }}
                        />
                        Password must contain at least 8 characters that include
                        at least 1 lowercase character, 1 uppercase characters,
                        1 number, and 1 special character or Password cannot
                        start and end with spaces.
                      </>
                    ),
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Password"
                  className="round-text"
                  type="password"
                  minLength={8}
                  style={{
                    borderRadius: "7.5px",
                    minHeight: "50px",
                  }}
                />
              </Form.Item>
              <Form.Item name="msg" className={type}>
                <span>{t("resetPassword.passwordDoNotMatch")}</span>
              </Form.Item>
              <Form.Item>
                <StyledButton
                  style={{
                    background: "#1B55CE",
                    width: "80%",
                    borderRadius: "7.5px",
                    minHeight: "50px",
                    marginTop: "30px",
                  }}
                  size="large"
                  type="primary"
                  className="round-text"
                  htmlType="submit"
                >
                  {t("common.submit")}
                </StyledButton>
              </Form.Item>
            </Form>
            <AllRightsReserved />
          </Space>
        </Space>
      </LayoutContent>
      {apiStatus === AsyncStates.LOADING && <Spinner />}
      {apiStatus === AsyncStates.ERROR && errorCall()}
    </Layout>
  );
}

export default ResetPassword
