import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Space, Spin, Table } from "antd";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { SummaryWrapper } from "src/components/AIEngine/DataSummary/tabs/summaries";
import { AnalyticsReciepeDistributionFilterForm, operatorConstantsMapper } from "./AnalyticsReciepeDistributionFilterForm";
import { AnalyticsRecipeDistributionPlot } from "./AnalyticsRecipeDistributionPlot";
import { LinkedTrialsAiEngine } from "src/components/AIEngine/common/LinkedTrialsAiEngine";
import { PlotType } from "../enums";
import { analyticsRecipeDistributionRequest } from "src/store/actions/dataSummary";
import { linkedFormulationsAiEngineClear, linkedFormulationsAiEngineRequest } from "src/store/actions/common";

export const OPS = ['=', '<=', '>=', 'exists', 'range']

export const AnalyticsRecipeDistribution = memo(({ status, tableColumnsVis, filteredDataVis, generateWO, selectedRecipeIds, setselectedRecipeIds, toolbarEnabled = true, experiments, expStatus, recipeDistribution: recipeDistributionFrom, currentTab, selectedStages, selectedExperiments, workOrderIds, setSaveGraphsPayload, selectedPlotsData, selectedFormulations }: any) => {
  const dispatch = useDispatch()
  const [Xaxis, setXaxis] = useState([])
  const [Yaxis, setYaxis] = useState([])
  const [Zaxis, setZaxis] = useState([])
  const { analyticsRecipeDistributionTableStatus, analyticsRecipeDistributionStatus } = useSelector((state: StoreState) => state.dataSummary);
  const { plotOptions, } = useSelector((state: StoreState) => state.dataSummary.dataSummaryPlotOptions)
  const configs = useSelector((state: StoreState) => state.configs.features);
  const { linkedFormulationData } = useSelector((state: StoreState) => state.common)

  const [t] = useTranslate()
  const [filterValues, setFilterValues] = useState([{ param: [], op: '', val: null }])
  const [visibilityArray, setVisibilityArray] = useState(filterValues.map(() => true))
  const chartRef = useRef(null)

  // const [plotIdList, setplotIdList] = useState<string[]>(['recipe_dist_1'])

  // const addPlot = useCallback(() => {
  //   setplotIdList(ids => {
  //     const lastId = ids.at(-1)
  //     const lastIdCount = lastId?.split('_').at(-1)
  //     const newId = 'recipe_dist_' + (Number(lastIdCount ?? 0) + 1)
  //     return [...ids, newId]
  //   })
  // }, [])

  // const removePlot = useCallback((id) => {
  //   setplotIdList(ids => ids.filter(_id => _id !== id))
  // }, [])

  // useEffect(() => {
  //   if (recipeDistributionFrom === "data-summary") {
  //     const linkedFormulations = filteredDataVis.reduce((acc: any, curr: any) => {
  //       if (!!curr.linked_trial) {
  //         acc.push(curr.linked_trial)
  //       }
  //       return acc
  //     }, [])
  //     if (!!linkedFormulations.length) {
  //       dispatch(linkedCompareFormulationsRequest({ formulation_id: linkedFormulations, is_nested: true }))
  //     }
  //   }
  // }, [filteredDataVis, dispatch, currentTab, recipeDistributionFrom])

  useEffect(() => {
    return () => {
      setselectedRecipeIds([])
      dispatch(linkedFormulationsAiEngineClear())
    }
  }, [dispatch, setselectedRecipeIds])

  useEffect(() => {
    if (!!Xaxis?.length && !!Yaxis.length) {
      const filters = filterValues.filter((filter: any, index: number) =>
      ((filter?.param?.length > 0) && ((!!filter?.op?.length) ? (filter.op === "range" ? ((filter.val2 !== null && filter.val2 !== "") && (filter.val !== null && filter.val !== ""))
        : (["work_order_id", "material", "grade"].includes(filter?.param[0]) ? !!filter?.[filter?.param?.[0]]?.length : (filter?.val !== null && filter?.val !== ""))) : false) && visibilityArray[index])
      ) ?? []
      setSaveGraphsPayload((prev: any) => ({
        ...prev,
        [PlotType.RECIPE_DISTRIBUTION]: {
          ...(!!filters.length && { filters }),
          data: {
            x: Xaxis,
            y: Yaxis,
            ...(!!Zaxis?.length && { z: Zaxis })
          }
        }
      }))
    } else {
      setSaveGraphsPayload((prev: any) => {
        const prevState = JSON.parse(JSON.stringify(prev))
        if (prevState?.[PlotType.RECIPE_DISTRIBUTION]) {
          delete prevState[PlotType.RECIPE_DISTRIBUTION]
          return prevState
        }
        return prevState
      })
    }
  }, [Xaxis, Yaxis, Zaxis, filterValues, setSaveGraphsPayload, visibilityArray])

  const workOrders = useSelector((state: StoreState) => state.workOrders.data)

  const checkPropertyExist = useCallback((variation_id: string, propertyIdentifier: string) => {
    return plotOptions?.variation_data?.properties?.[variation_id]?.properties?.includes(propertyIdentifier)
  }, [plotOptions?.variation_data?.properties])

  useEffect(() => {
    if (Object.keys(selectedPlotsData?.data?.plot_data ?? {}).includes(PlotType.RECIPE_DISTRIBUTION)) {
      const { filters = [], data = {} } = selectedPlotsData?.data?.plot_data?.[PlotType.RECIPE_DISTRIBUTION] ?? {}
      if (!!filters.length) {
        const filtersData = filters.reduce((acc: any, filter: any) => {
          if (["work_order_id", "material", "grade"].includes(filter?.param?.[0])) {
            const filteredWorkOrdersList = workOrders.filter((workOrder) => workOrderIds.includes(workOrder.work_order_id))

            if (filter?.param?.[0] === "work_order_id") {
              if (workOrderIds.includes(filter.work_order_id)) {
                acc.push(filter)
              }
            } else if (filter?.param?.[0] === "material") {
              const materials = [...new Set(filteredWorkOrdersList.map((materials) => materials?.material))]
              if (materials.includes(filter["material"])) {
                acc.push(filter)
              }

            } else if (filter?.param?.[0] === "grade") {
              const grades = [...new Set(filteredWorkOrdersList.map((grades) => grades?.material))]
              if (grades.includes(filter["grade"])) {
                acc.push(filter)
              }
            }
          } else if (Boolean(configs?.nestle_configs)) {
            if (filter?.param?.[0] === "ingredients" && plotOptions?.[filter?.param?.[0]].includes(filter?.param?.[1])) {
              acc.push(filter)
            } else if (filter?.param?.[0] === "properties" && checkPropertyExist(filter?.param?.[1], filter?.param?.[filter?.param?.length - 1])) {
              acc.push(filter)
            }
          } else if (plotOptions?.[filter?.param?.[0]].includes(filter?.param?.[1])) {
            acc.push(filter)
          }
          return acc
        }, [])
        if (!!filtersData.length) {
          setFilterValues(filtersData)
        } else {
          setFilterValues([{ param: [], op: '', val: null }])
        }
      }
      if (Boolean(configs?.nestle_configs)) {
        if (plotOptions?.[data?.x?.[0]] === "ingredients") {
          if (plotOptions?.[data?.x?.[0]].includes(data?.x?.[1])) {
            setXaxis(data?.x ?? [])
          } else {
            setXaxis([])
          }
          if (plotOptions?.[data?.y?.[0]].includes(data?.y?.[1])) {
            setYaxis(data?.y ?? [])
          } else {
            setYaxis([])
          }
          if (plotOptions?.[data?.z?.[0]].includes(data?.z?.[1])) {
            setZaxis(data?.z ?? [])
          } else {
            setZaxis([])
          }
        } else {
          if (checkPropertyExist(data?.x?.[1], data?.x?.[data?.x?.length - 1])) {
            setXaxis(data?.x ?? [])
          } else {
            setXaxis([])
          }
          if (checkPropertyExist(data?.y?.[1], data?.y?.[data?.y?.length - 1])) {
            setYaxis(data?.y ?? [])
          } else {
            setYaxis([])
          }
          if (checkPropertyExist(data?.z?.[1], data?.z?.[data?.z?.length - 1])) {
            setZaxis(data?.z ?? [])
          } else {
            setZaxis([])
          }
        }
      } else {
        if (plotOptions?.[data?.x?.[0]]?.includes(data?.x?.[1])) {
          setXaxis(data?.x ?? [])
        } else {
          setXaxis([])
        }
        if (plotOptions?.[data?.y?.[0]]?.includes(data?.y?.[1])) {
          setYaxis(data?.y ?? [])
        } else {
          setYaxis([])
        }
        if (plotOptions?.[data?.z?.[0]]?.includes(data?.z?.[1])) {
          setZaxis(data?.z ?? [])
        } else {
          setZaxis([])
        }
      }
    }
  }, [selectedPlotsData, plotOptions, workOrders, workOrderIds, configs?.nestle_configs, checkPropertyExist])

  useEffect(() => {
    if (analyticsRecipeDistributionStatus === AsyncStates.INITIAL && Object.keys(selectedPlotsData?.data?.plot_data ?? {}).includes(PlotType.RECIPE_DISTRIBUTION) && !!Xaxis.length && !!Yaxis.length) {
      const paramTypes = ["properties", "processing"]
      dispatch(analyticsRecipeDistributionRequest({
        x: { "type": Xaxis[0], "value": Xaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Xaxis[0]))) && { category: Xaxis[1] }) },
        y: { "type": Yaxis[0], "value": Yaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Yaxis[0]))) && { category: Yaxis[1] }) },
        ...(Zaxis?.length && { z: { "type": Zaxis[0], "value": Zaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Zaxis[0]))) && { category: Zaxis[1] }) } }),
        ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_id: selectedExperiments }),
        work_order_id: workOrderIds,
        ...(selectedPlotsData?.data?.plot_data?.[PlotType.RECIPE_DISTRIBUTION]?.filters?.length && {
          filters: (selectedPlotsData?.data?.plot_data?.[PlotType.RECIPE_DISTRIBUTION]?.filters || []).reduce((acc: any[], filter: any, index: number) => {
            return [
              ...acc,
              {
                "val": ["work_order_id", "material", "grade"].includes(filter?.param?.[0]) ? filter?.[filter?.param?.[0]] : filter?.val,
                "max": filter?.val2,
                "parameter": filter?.param?.[1],
                "parameter_type": filter?.param?.[0],
                "operator": operatorConstantsMapper[filter?.op] ?? filter?.op
              }
            ];
          }, [])
        })
      }))
    }
  }, [Xaxis, Yaxis, Zaxis, analyticsRecipeDistributionStatus, configs?.nestle_configs, dispatch, selectedExperiments, selectedPlotsData, selectedPlotsData?.data?.plot_data, workOrderIds, selectedFormulations])

  useEffect(() => {
    setVisibilityArray((prev) => {
      const filterData = selectedPlotsData?.data?.plot_data?.[PlotType.RECIPE_DISTRIBUTION]?.filters
      if (filterData?.length) {
        return filterData.map((res: boolean, index: number) => !!prev?.[index] ? prev[index] : false)
      }
      return prev
    })
  }, [selectedPlotsData?.data?.plot_data])

  return (
    <SummaryWrapper
      key={'recipes-distribution'}
      heading={t("aiEngine.RecipesDistribution")}
      tooltip={t("aiEngine.RecipesDistribution")}
      expandIconPosition="end"
      id="recipes-distribution"
    >
      <Spin
        spinning={status}
        indicator={<LoadingOutlined />}
        tip={t('projects.gettingRecipesDistributionReady')}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <AnalyticsReciepeDistributionFilterForm
            reciepeExperiments={experiments}
            visibilityArray={visibilityArray}
            setVisibilityArray={setVisibilityArray}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            recipeDistributionFrom={recipeDistributionFrom}
            Xaxis={Xaxis}
            Yaxis={Yaxis}
            Zaxis={Zaxis}
            selectedExperiments={selectedExperiments}
            workOrderIds={workOrderIds}
            selectedFormulations={selectedFormulations}
          />
          {/* {plotIdList.map((id) => (
            <StyledCard
              // extra={<CloseOutlined onClick={() => removePlot(id)} />}
              key={id}
            > */}
          <AnalyticsRecipeDistributionPlot
            ref={chartRef}
            visibilityArray={visibilityArray}
            filterValues={filterValues}
            setselectedRecipes={setselectedRecipeIds}
            selectedRecipes={selectedRecipeIds}
            experiments={experiments}
            recipeDistributionFrom={recipeDistributionFrom}
            Xaxis={Xaxis}
            setXaxis={setXaxis}
            Yaxis={Yaxis}
            setYaxis={setYaxis}
            Zaxis={Zaxis}
            setZaxis={setZaxis}
            selectedExperiments={selectedExperiments}
            workOrderIds={workOrderIds}
            selectedFormulations={selectedFormulations}
          />
          {/* </StyledCard> */}
          {/* ))} */}
          {/* <StyledButton onClick={addPlot}>{t('common.addPlot')}</StyledButton> */}
          {!!selectedRecipeIds.length && (
            <Table
              id="suggested-exp"
              dataSource={filteredDataVis}
              columns={tableColumnsVis}
              bordered
              scroll={{ x: 400 }}
              className="suggested-exp-result-table"
              title={() =>
                toolbarEnabled ? (
                  <Row justify="end" gutter={8}>
                    <Col>
                      <StyledButton
                        type="primary"
                        disabled={!selectedRecipeIds?.length}
                        onClick={() => setselectedRecipeIds([])}
                      >
                        {t('common.removeSelection')}
                      </StyledButton>
                    </Col>
                    <Col>
                      <StyledButton
                        type="primary"
                        disabled={expStatus === AsyncStates.LOADING}
                        onClick={() => generateWO("recipe-suggested")}
                      >
                        {t("aiEngine.generateWorkOrder")}
                      </StyledButton>
                    </Col>
                  </Row>
                ) : null
              }
              pagination={false}
              loading={{
                spinning: analyticsRecipeDistributionTableStatus === AsyncStates.LOADING,
                indicator: <LoadingOutlined />,
              }}
              expandable={{
                expandedRowRender: (record) => {
                  return <LinkedTrialsAiEngine record={record} experiments={experiments}
                    linkedFormulationDetailsData={linkedFormulationData} from={recipeDistributionFrom} selectedStages={selectedStages} />
                },
                rowExpandable: (record) => {
                  return !!record?.linked_trial
                },
                onExpand: (expanded, record) => {
                  if (expanded && !!record?.linked_trial && !linkedFormulationData?.[record?.linked_trial]) {
                    const payload = {
                      formulation_id: [record?.linked_trial], is_nested: true
                    }
                    dispatch(linkedFormulationsAiEngineRequest(payload))
                  }
                },
              }}
            />
          )}
        </Space>
      </Spin>
    </SummaryWrapper >
  );
})