
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`ボックス-ベンケン`}</h1>
    <p><a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/Box%E2%80%93Behnken_design#:~:text=In%20statistics%2C%20Box%E2%80%93Behnken%20designs,1%2C%200%2C%20%2B1."
      }}>{`ボックス-ベンケン`}</a>{` デザインは、通常の要因分析技術に比べて実行回数を減らすことで、より高次の応答曲面を生成するために使用されます。この手法と中心複合計画技術は、高次の曲面定義を維持するために特定の実行を抑制するものです。`}</p>
    <p>{`ボックス-ベンケンデザインは、埋め込まれた要因分析や部分的要因分析デザインを含まない独立した二次設計です。この設計では、処理の組み合わせはプロセス空間のエッジの中点と中心に配置されます。これらの設計は回転可能（またはほぼ回転可能）であり、各要素に3つのレベルが必要です。中心複合計画と比較して、これらの設計は直交ブロッキングの能力が制限されています。`}</p>
    <p><strong parentName="p">{`使用例:`}</strong>{`
ボックス-ベンケンデザイン（BBD）は、以下のような領域で広く使用されています。`}</p>
    <ul>
      <li parentName="ul">{`製薬`}</li>
      <li parentName="ul">{`バイオプロセス`}</li>
      <li parentName="ul">{`食品工学`}</li>
      <li parentName="ul">{`農薬`}</li>
      <li parentName="ul">{`その他の産業`}</li>
    </ul>
    <p>{`これらの設計は、様々なソースから人間の使用を目的とした多糖類、フェノール化合物、タンパク質などの生物活性化合物を抽出するために使用されます。`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;