import { Col, Form, Input, Modal, Row, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { savePlotsRequest } from 'src/store/actions/dataSummary'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { ViewSelectedGraphsData } from './ViewSelectedGraphsData/ViewSelectedGraphsData'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'

export const SaveGraphsModal = ({ saveGraphsPayload, setSaveGraphsPayload, showSaveGraphsModal, setShowSaveGraphsModal, getDisplayName }: any) => {
    const [t] = useTranslate()
    const [saveGraphForm] = useForm()
    const requiredFieldStar = useRequiredFieldStar()
    const dispatch = useDispatch()
    const savePlotsStatus = useSelector((state: StoreState) => state.dataSummary.savePlotsStatus)
    const configs = useSelector((state: StoreState) => state.configs.features);
    const { plotOptions } = useSelector((state: StoreState) => state.dataSummary.dataSummaryPlotOptions)
    const { current: currentProject, projectList } = useSelector((state: StoreState) => state.projects);

    const handleCancel = useCallback(() => {
        saveGraphForm.resetFields()
        setShowSaveGraphsModal(false)
    }, [saveGraphForm, setShowSaveGraphsModal])

    useEffect(() => {
        if (savePlotsStatus === AsyncStates.SUCCESS) {
            handleCancel()
        }
    }, [savePlotsStatus, handleCancel, setSaveGraphsPayload])

    const handleFinish = (value: any) => {
        const variation_data = Boolean(configs?.nestle_configs) ? Object.keys(saveGraphsPayload || {}).reduce((acc: any, plotType) => {
            if (saveGraphsPayload[plotType]) {
                const plotData = saveGraphsPayload[plotType]
                if (plotType === "surface-plot") {
                    if (plotData?.x?.[0] === "properties") {
                        acc = {
                            ...acc,
                            properties: {
                                ...acc["properties"],
                                [plotData?.x?.[1]]: plotOptions?.variation_data?.properties?.[plotData?.x?.[1]]?.variation_name
                            }
                        }

                    } else if (plotData?.x?.[0] === "processing") {
                        acc = {
                            ...acc,
                            processing: {
                                ...acc["processing"],
                                [plotData?.x?.[2]]: plotOptions?.variation_data?.processing?.[plotData?.x?.[2]]
                            }
                        }
                    }
                    if (plotData?.y?.[0] === "properties") {
                        acc = {
                            ...acc,
                            properties: {
                                ...acc["properties"],
                                [plotData?.y?.[1]]: plotOptions?.variation_data?.properties?.[plotData?.y?.[1]]?.variation_name
                            }
                        }

                    } else if (plotData?.y?.[0] === "processing") {
                        acc = {
                            ...acc, processing: {
                                ...acc["processing"],
                                [plotData?.y?.[2]]: plotOptions?.variation_data?.processing?.[plotData?.xy?.[2]]
                            }
                        }
                    }
                    if (plotData?.z?.[0] === "properties") {
                        acc = {
                            ...acc, properties: {
                                ...acc["properties"],
                                [plotData?.z?.[1]]: plotOptions?.variation_data?.properties?.[plotData?.z?.[1]]?.variation_name
                            }
                        }

                    } else if (plotData?.z?.[0] === "processing") {
                        acc = {
                            ...acc, processing: {
                                ...acc["processing"],
                                [plotData?.z?.[2]]: plotOptions?.variation_data?.processing?.[plotData?.z?.[2]]
                            }
                        }
                    }
                }
                if (plotType === "recipe-distribution") {
                    const recipeplotData = saveGraphsPayload[plotType]?.data
                    const recipeplotDataFilter = saveGraphsPayload[plotType]?.filters ?? []
                    // For Data
                    if (recipeplotData?.x?.[0] === "properties") {
                        acc = {
                            ...acc,
                            properties: {
                                ...acc["properties"],
                                [recipeplotData?.x?.[1]]: plotOptions?.variation_data?.properties?.[recipeplotData?.x?.[1]]?.variation_name
                            }
                        }
                    } else if (recipeplotData?.x?.[0] === "processing") {
                        acc = {
                            ...acc,
                            processing: {
                                ...acc["processing"],
                                [recipeplotData?.x?.[2]]: plotOptions?.variation_data?.processing?.[recipeplotData?.x?.[2]]
                            }
                        }
                    }
                    if (recipeplotData?.y?.[0] === "properties") {
                        acc = {
                            ...acc, properties: {
                                ...acc["properties"],
                                [recipeplotData?.y?.[1]]: plotOptions?.variation_data?.properties?.[recipeplotData?.y?.[1]]?.variation_name
                            }
                        }

                    } else if (recipeplotData?.y?.[0] === "processing") {
                        acc = {
                            ...acc, processing: {
                                ...acc["processing"],
                                [recipeplotData?.y?.[2]]: plotOptions?.variation_data?.processing?.[recipeplotData?.y?.[2]]
                            }
                        }
                    }

                    // for filters 
                    recipeplotDataFilter.forEach((recipe: any) => {
                        if (recipe.param?.[0] === "properties") {
                            acc = {
                                ...acc, properties: {
                                    ...acc["properties"],
                                    [recipe?.param?.[1]]: plotOptions?.variation_data?.properties?.[recipe?.param?.[1]]?.variation_name
                                }
                            }
                        } else if (recipe.param?.[0] === "processing") {
                            acc = {
                                ...acc, processing: {
                                    ...acc["processing"],
                                    [recipe?.param?.[2]]: plotOptions?.variation_data?.processing?.[recipe?.param?.[2]]
                                }
                            }
                        }
                    });
                }
            }
            return acc

        }, {}) : undefined

        if (!!Object.keys(saveGraphsPayload ?? {})?.length) {
            dispatch(savePlotsRequest({
                ...value,
                data: {
                    plot_data: saveGraphsPayload,
                    variation_data,
                    additional_info: {
                        project: {
                            project_id: currentProject,
                            project_name: projectList.find((project) => project.project_id === currentProject)?.name
                        }
                    }
                },
            }))
        } else {
            message.error(t("graphs.addGraphsToSave"))
        }
    }

    return (
        <Modal title={t("graphs.saveGraph")} open={showSaveGraphsModal} onCancel={handleCancel} footer={null} width={700}>
            <Form onFinish={handleFinish} form={saveGraphForm} requiredMark={false} layout='vertical'>
                <Form.Item
                    name="name"
                    style={{ margin: 0 }}
                    label={t("common.name")}
                    rules={[{ required: true }]}
                    tooltip={requiredFieldStar}
                >
                    <Input placeholder={t("graphs.enterName")} min={1} />
                </Form.Item>
                <Form.Item>
                    <ViewSelectedGraphsData plotData={saveGraphsPayload} getDisplayName={getDisplayName} createdOn={null} from={"save_graphs_modal"} setSaveGraphsPayload={setSaveGraphsPayload} plot={null} />
                </Form.Item>
                <Row justify="end" gutter={8}>
                    <Col>
                        <Form.Item style={{ margin: 0 }}>
                            <StyledButton
                                type="default"
                                onClick={handleCancel}
                            >
                                {t("common.cancel")}
                            </StyledButton>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item style={{ margin: 0 }}>
                            <StyledButton
                                loading={savePlotsStatus === AsyncStates.LOADING}
                                htmlType="submit"
                                type="primary"
                            >
                                {t("graphs.saveGraph")}
                            </StyledButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
