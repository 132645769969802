import { 
  Modal
} from "antd";
import useTranslate from "src/utils/useTranslate";
import { CreateProjectTabs } from "../Projects";
import { useHistory } from "react-router-dom";


export function CreateProjectModal({ createProjectModal, setCreateProjectModal }: any) {
    const [t] = useTranslate()
	  const {push} = useHistory()


    return (
        <Modal
          title={t('template.selectFromTemplate')}
          visible={createProjectModal}
          onCancel={() => setCreateProjectModal(false)} footer={null}
        >
          <CreateProjectTabs t={t} push={push}/>
        </Modal>
    )
}
