import { isEqual } from "lodash"

export function getCharacterizationName(id: string, formulation: any) {
	return id
}

export function getVarianceId(id: string, formulation: any, plotKey: string) {
	let variation_id = ""

	formulation?.[plotKey].find((trial: any) => {
		return trial?.[plotKey].find((c: any) => {
			if (c.characterization_set_id === id) {
				variation_id = c.variation_id
				return true
			}
			return false
		})
	})

	return variation_id
}

export const getYAxis = (
	ele: any,
	propertyName: string,
	yAxis: number,
	plotType?: string
) => {
	if (!propertyName) return []

	const characterizationsData = Array.isArray(ele.characterizations) ? ele.characterizations : []
	const plotKey = !!characterizationsData.length ? "characterizations" : "properties"

	const characterizationIds = [
		...new Set(
			(Array.isArray(ele?.[plotKey]) ? (ele?.[plotKey]) : [])?.flatMap((c: any) => {
				return c?.[plotKey]?.map((set: any) => {
					return set.characterization_set_id
				})
			})
		),
	]

	return characterizationIds.map((id) => {
		const propertiesValues = ele.properties?.[0]?.properties?.map((p: any) => {
			if (Object.keys(p.data).includes(propertyName) && p.characterization_set_id === id) {
				return {
					y: p.data[propertyName].value,
					workOrderName: ele.work_order_name,
					trial_id: ele?.id_set?.formulation_id,
					trial_name: ele.meta.display_id,
					stageName: ele.stage_name
				}
			}
			return null
		})
		const characterizationData = (Array.isArray(ele?.[plotKey]) ? ele?.[plotKey] : [])?.[0]?.[plotKey]?.find((p: any) => p.characterization_set_id === id)?.data


		return {
			name: getCharacterizationName(id as string, ele),
			displayName: propertyName,
			variation_id: getVarianceId(id as string, ele, plotKey),
			characterization_set_id: id,
			trial_id: ele.id_set.formulation_id,
			trial_name: ele.meta.display_id,
			data: propertiesValues.filter((v: any) => v !== null),
			characterizationData,
			// colorKey: 'colorValue',
			type: plotType ?? "column",
			yAxis: yAxis ?? 0,
			zIndex: plotType === "line" && 1000,
			chartType: "properties",
			stageName: ele.stage_name
		}
	})
}

type P = {
	stateData: StateDataEntry[]
	linkedFormulationDetailsData: any[]
	displayNames: {
		[key: string]: any
	}
	formulationList: any[]
	selectedTrials: string[]
}

type StateDataEntry = {
	type: string
	selectedData: string[]
	plotype?: string
}

export const colorCodes = [
	"#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1",
	"#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",
	"#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00",
	"#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e",
	"#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8",
	"#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179",
	"#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
	"#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
	"#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
	"#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
	"#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
	"#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
	"#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
	"#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
	"#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
	"#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
	"#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
	"#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
	"#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
	"#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
	"#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
	"#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
	"#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
	"#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
	"#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
	"#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
	"#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
	"#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
	"#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
	"#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
	"#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
	"#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
	"#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
	"#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
	"#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
	"#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
	"#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
	"#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
	"#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
	"#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"
]

export function prepareChartSeries({
	stateData = [],
	linkedFormulationDetailsData = [],
	displayNames,
	formulationList = [],
	selectedTrials = [],
}: P) {
	return stateData
		.filter((state) => !!state.type)
		.flatMap((property: any, yAxis) => {
			if (property.type === "ingredients") {
				const values = property.selectedData.map((ingredientName: any) => {
					const name = linkedFormulationDetailsData.find(({ id_set }: any) => id_set.formulation_id === ingredientName)?.meta?.display_id ?? displayNames["ingredients"]?.[ingredientName]?.name ?? ingredientName
					return {
						name,
						data: formulationList
							.filter((formulation: any) =>
								selectedTrials.includes(formulation?.id_set?.formulation_id)
							).sort((a: any, b: any) => {
								const aIndex = selectedTrials.indexOf(a?.id_set?.formulation_id);
								const bIndex = selectedTrials.indexOf(b?.id_set?.formulation_id);
								return aIndex - bIndex;
							})
							.flatMap((obj: any) =>
								obj[property.type][ingredientName]?.value !== undefined
									? isNaN(obj[property.type][ingredientName]?.value) ? null : typeof obj[property.type][ingredientName]?.value !== "string" ? {
										y: obj[property.type][ingredientName]?.value,
										workOrderName: obj.work_order_name,
										stageName: obj.stage_name,
									} : null : null // for handling the case when the value is a string / '"
							),
						yAxis,
						type: property.plotype ?? "column",
						chartType: "ingredients",
						zIndex: property.plotype === "line" && 1000,
						stack: name,
					}
				})
				return values
			}

			const data = property?.selectedData?.flatMap((propertyName: any) => {
				return formulationList
					.filter((formulation: any) => {
						return selectedTrials.includes(formulation?.id_set?.formulation_id)
					}).sort((a: any, b: any) => {
						const aIndex = selectedTrials.indexOf(a?.id_set?.formulation_id);
						const bIndex = selectedTrials.indexOf(b?.id_set?.formulation_id);
						return aIndex - bIndex;
					})
					.flatMap((formulation) => {
						return getYAxis(formulation, propertyName, yAxis, property.plotype)
					})
			})

			const colorIds: any = {}
			const variationsDict: any = {}
			data.forEach((res: any, i: any) => {
				const matchingVariations = data.filter((ele: any) => (ele?.variation_id === res?.variation_id &&
					isEqual(res?.characterizationData, ele?.characterizationData)))
				const newIdentifier: any = res.characterization_set_id + "_" + res?.trial_id
				variationsDict[newIdentifier] = matchingVariations.map((val: any) => val.characterization_set_id + "_" + val?.trial_id)
			})
			Object.entries(variationsDict).forEach(([key, value]: any, index) => {
				value.forEach((res: any) => {
					if (!colorIds?.[res]) {
						colorIds[res] = colorCodes[index]
					}
				})
			})

			const hashMapWithUniqueCharacterizationId = data.reduce(
				(acc: any, curr: any) => {
					const trialIndex = selectedTrials.findIndex((tr: any) => {
						return tr === curr.trial_id
					})
					if (acc[curr.characterization_set_id]) {
						acc[curr.characterization_set_id].data[trialIndex] = curr.data[0]
					} else {
						const data = new Array(selectedTrials.length).fill(null)
						data[trialIndex] = curr.data[0]
						acc[curr.characterization_set_id] = {
							...curr,
							data,
						}
					}
					return acc
				},
				{}
			)

			const populateWithNull = Object.values(hashMapWithUniqueCharacterizationId).map((d: any) => {
				const coloredData = d.data.reduce((acc: any, res: any) => [...acc, ({ ...res, color: colorIds?.[d?.characterization_set_id + "_" + res?.trial_id] })], [])
				return {
					...d,
					data: selectedTrials.map((_, i) => {
						if (!coloredData[i]?.y) {
							return null
						}
						return coloredData[i]
					})
				}
			})
			return populateWithNull
		})
}
