import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { AsyncStates } from "src/constants";
import NoData from "../Common/NoData";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import RepositoryActionDrawer from "../Common/RepositoryActionDrawer";
import IngredientTemplateSidebar from "./IngredientTemplateSidebar";
import "./IngredientTemplate.scss";
import {
    fetchAdditiveRequest,
    fetchCategoryRequest,
    fetchFieldRequest,
    fetchIngredientTemplatesRequest,
    fetchPropertyRequest,
    removeIngredientTemplatesRequest,
} from "src/store/actions/repository";
import { CATEGORY_TYPES } from "../Common/Data";
import { StoreState } from "src/store/configureStore";
import AddEditIngredientTemplateModal from "../Common/IngredientTemplate/AddEditIngredientTemplateModal";
import IngredientTemplateDetails from "./IngredientTemplateDetails";

const IngredientTemplate = () => {
    const [t] = useTranslate();
    const templates = useSelector(
        (state: StoreState) => state.repository.templates
    );
    const [showModal, setShowModal] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
    const [mode, setMode] = useState<"edit" | "create">();
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(fetchIngredientTemplatesRequest());
        dispatch(fetchCategoryRequest({ type: CATEGORY_TYPES.INGREDIENT }));
        dispatch(fetchPropertyRequest());
        dispatch(fetchFieldRequest());
        dispatch(fetchAdditiveRequest());
    }, [dispatch]);

    useEffect(() => {
        // Update selected templates when changed
        const availableTemplates = templates.data.map((t) => t.template_id);
        setSelectedTemplates((prev) =>
            prev.filter((p) => availableTemplates.includes(p))
        );
    }, [templates, selectedTemplateId]);

    useEffect(() => {
        // Update current selected template view
        const availableTemplates = templates.data.map((t) => t.template_id);
        if (selectedTemplateId && !availableTemplates.includes(selectedTemplateId))
            setSelectedTemplateId(undefined);
    }, [templates, selectedTemplateId]);

    useEffect(() => {
        if (selectedTemplates.length > 0) setDrawerOpen(true);
        else setDrawerOpen(false);
    }, [selectedTemplates]);

    const deleteTemplates = useCallback(() => {
        dispatch(
            removeIngredientTemplatesRequest({
                template_ids: selectedTemplates,
            })
        );
    }, [dispatch, selectedTemplates]);

    const onDismiss = () => {
        setSelectedTemplates([]);
        setDrawerOpen(false);
    };

    return (
        <Spin
            spinning={templates.status === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}
        >
            <div className="ingredient-template-container">
                {templates.data.length === 0 &&
                    templates.status === AsyncStates.SUCCESS ? (
                    <NoData
                        title={`${t("repository.totalTemplates")} (0)`}
                        btns={[
                            t("repository.addNewIngredientTemplate"),
                            t("repository.uploadBulkTemplates"),
                        ]}
                        addItem={setShowModal}
                    />
                ) : (
                    <div className="content-container">
                        {templates.data.length > 0 && (
                            <>
                                <IngredientTemplateSidebar
                                    addItem={() => {
                                        setMode("create");
                                        setShowModal(true);
                                    }}
                                    setSelectedTemplateId={setSelectedTemplateId}
                                    selectedTemplateId={selectedTemplateId}
                                    selectedTemplates={selectedTemplates}
                                    setSelectedTemplates={setSelectedTemplates}
                                    setSearchTerm={setSearchTerm}
                                    searchTerm={searchTerm}
                                />
                                {selectedTemplateId && (
                                    <IngredientTemplateDetails
                                        selectedTemplateId={selectedTemplateId}
                                        showEditTemplateModal={() => {
                                            setMode("edit");
                                            setShowModal(true);
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
                <AddEditIngredientTemplateModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    mode={mode}
                    selectedTemplateId={selectedTemplateId}
                />
                <RepositoryActionDrawer
                    open={drawerOpen}
                    close={onDismiss}
                    selected={selectedTemplates.length}
                    onDelete={deleteTemplates}
                />
            </div>
        </Spin>
    );
};

export default IngredientTemplate;
