import { handleActions } from "redux-actions";
import { AsyncStates } from "src/constants";
import { IStoreActionState } from "../interfaces";
import { STORE_ACTION_INITIAL_DATA } from "../constants";
import {
  ConversationAskActionTypes,
  ConversationDeleteActionTypes,
  ConversationFavoriteActionTypes,
  ConversationHistoryActionTypes,
  ConversationSummaryActionTypes,
  ConversationUpdateActionTypes,
  CreateConversationActionTypes,
  CurrentConversationActionTypes,
  GetConversationsActionTypes,
  GetExtractionsActionTypes,
  UpdateExtractionTableActionTypes,
} from "../actions/polyGPT";
import { GPTConversation } from "src/modules/PolyGPT/interfaces";

export type PolyGPTState = {
  createConversation: IStoreActionState;
  getConversations: {
    status: AsyncStates;
    data: {
      conversations: GPTConversation[];
      conversations_count: number;
      favourites?: GPTConversation[];
    };
    error: any;
  };
  conversationAsk: IStoreActionState;
  // conversationHistory: IStoreActionState;
  conversationHistory: {
    status: AsyncStates;
    data: {
      chat: any[];
      conversation_id: string;
      file_name: string;
      page_num: number;
      page_size: number;
      questions_count: number;
      qa_status: any;
      summary_status: any;
      file_url: string;
    };
    error: any;
  };
  conversationSummary: IStoreActionState;
  conversationFavorite: IStoreActionState;
  conversationDelete: IStoreActionState;
  conversationUpdate: IStoreActionState;
  currentConversation?: GPTConversation;
  getExtractions: IStoreActionState;
  updateExtractionTable: IStoreActionState;
};

const conversationsInitialData = {
  status: AsyncStates.INITIAL,
  data: {
    conversations: [],
    conversations_count: 0,
    favourites: [],
    // Favourites: [],
  },
  error: "",
};

const conversationHistoryInitialData = {
  status: AsyncStates.INITIAL,
  data: {
    chat: [],
    conversation_id: "",
    file_name: "",
    page_num: 1,
    page_size: 20,
    questions_count: 1,
    qa_status: "in_progress",
    summary_status: "in_progress",
    file_url: ""
  },
  error: "",
};

const defaultState: PolyGPTState = {
  createConversation: STORE_ACTION_INITIAL_DATA,
  getConversations: conversationsInitialData,
  conversationAsk: STORE_ACTION_INITIAL_DATA,
  conversationHistory: conversationHistoryInitialData,
  conversationSummary: STORE_ACTION_INITIAL_DATA,
  conversationFavorite: STORE_ACTION_INITIAL_DATA,
  conversationDelete: STORE_ACTION_INITIAL_DATA,
  conversationUpdate: STORE_ACTION_INITIAL_DATA,
  currentConversation: undefined,
  getExtractions: STORE_ACTION_INITIAL_DATA,
  updateExtractionTable: STORE_ACTION_INITIAL_DATA,
};

const getUpdatedConversations = (
  conversations: GPTConversation[],
  updatedConversationData: any
) => {
  const updatedConversations = conversations.map((conv) => {
    if (conv.conversation_id === updatedConversationData?.conversation_id) {
      return {
        ...conv,
        title: updatedConversationData.title,
      };
    } else {
      return conv;
    }
  });

  // const updatedFavourites = conversationsData.Favourites.map((conv) => {
  //   if (conv.conversation_id === updatedConversationData?.conversation_id) {
  //     return {
  //       ...conv,
  //       title: updatedConversationData.title,
  //     };
  //   } else {
  //     return conv;
  //   }
  // });

  return updatedConversations;
};

const deleteConversation = (
  conversations: GPTConversation[],
  deleted_conversation_id: string
) => {
  const updatedConversations = conversations.filter(
    (conv) => conv.conversation_id !== deleted_conversation_id
  );
  // const updatedFavourites = conversationsData.Favourites.filter(
  //   (conv) => conv.conversation_id !== deleted_conversation_id
  // );

  return updatedConversations;
};

// const favouriteConversation = (
//   conversationsData: {
//     Conversations: GPTConversation[];
//     // Favourites: GPTConversation[];
//   },
//   favourite_conversation_id: string
// ) => {
//   // const conversation = conversationsData.Conversations.find((conv) => conv.conversation_id === favourite_conversation_id);;

//   const updatedConversations = conversationsData.Conversations.map((conv) => {
//     if (conv.conversation_id === favourite_conversation_id) {
//       return {
//         ...conv,
//         is_favourite: !conv.is_favourite,
//       };
//     } else {
//       return conv;
//     }
//   });

//   // const updatedFavourites = conversationsData.Favourites.filter(
//   //   (conv) => conv.conversation_id !== favourite_conversation_id
//   // );
//   return {
//     Conversations: updatedConversations,
//     // Favourites: updatedFavourites,
//   };
// };

const getUpdatedExtractions = (extractions: any[], updatedExtraction: any) => {
  const newExtractions = extractions.map((outerExt: any) => {
    if (outerExt.file_name === updatedExtraction.file_name) {
      return {
        ...outerExt,
        extractions: outerExt.extractions.map((innerExt: any) => {
          if (innerExt.page_no === updatedExtraction.page_no) {
            return {
              ...innerExt,
              tables_info: innerExt.tables_info.map((tab: any) => {
                if (tab.table_id === updatedExtraction.table_id) {
                  return {
                    ...tab,
                    table: updatedExtraction.updated_table
                  };
                }
                return tab;
              })
            }
          }
          return innerExt;
        })
      }
    }
    return outerExt;
  })

  console.log({ newExtractions })

  return newExtractions;

}
const polyGPTReducer = handleActions(
  {
    // Create Conversation
    [CreateConversationActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        createConversation: {
          data: {},
          status: AsyncStates.LOADING,
          error: "",
        },
      };
    },
    [CreateConversationActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        createConversation: {
          ...state.createConversation,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [CreateConversationActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      createConversation: {
        ...state.createConversation,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [CreateConversationActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      createConversation: STORE_ACTION_INITIAL_DATA,
    }),

    // Get Conversations
    [GetConversationsActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getConversations: {
          ...conversationsInitialData,
          status: AsyncStates.LOADING,
        },
      };
    },
    [GetConversationsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getConversations: {
          ...state.getConversations,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetConversationsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getConversations: {
        ...state.getConversations,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetConversationsActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getConversations: conversationsInitialData,
    }),

    // Conversation Ask
    [ConversationAskActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        conversationAsk: {
          ...STORE_ACTION_INITIAL_DATA,
          status: AsyncStates.LOADING,
        },
      };
    },
    [ConversationAskActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        conversationAsk: {
          ...state.conversationAsk,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        conversationHistory: {
          ...state.conversationHistory,
          data: {
            ...state.conversationHistory.data,
            chat: [
              ...state.conversationHistory.data.chat,
              { ...action.payload, created_at: new Date() },
            ],
          },
        },
        currentConversation: {
          ...state.currentConversation!,
          questions_count: state.currentConversation?.questions_count! + 1
        },
        getConversations: {
          ...state.getConversations,
          data: {
            ...state.getConversations.data,
            conversations: state.getConversations.data.conversations.map((conversation) => {
              if (conversation.conversation_id === state.currentConversation?.conversation_id) {
                return {
                  ...conversation,
                  questions_count: conversation.questions_count! + 1
                }
              }
              return conversation;
            })
          }
        }
      };
    },
    [ConversationAskActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      conversationAsk: {
        ...state.conversationAsk,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
      ...(!action?.payload?.status && {
        conversationHistory: {
          ...state?.conversationHistory,
          data: {
            ...state?.conversationHistory?.data,
            chat: [
              ...state?.conversationHistory?.data?.chat,
              { ...action?.payload, ...action.payload.error?.data,  isError: true, created_at: new Date() },
            ],
          },
        },
      }),
    }),
    [ConversationAskActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      conversationAsk: STORE_ACTION_INITIAL_DATA,
    }),

    // Conversation Ask
    [ConversationHistoryActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        conversationHistory: !state.conversationHistory.data.chat.length
          ? {
            ...conversationHistoryInitialData,
            status: AsyncStates.LOADING,
          }
          : {
            ...state.conversationHistory,
            // status: AsyncStates.LOADING,
          },
      };
    },
    [ConversationHistoryActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        conversationHistory:
          action.payload.page_num === 1
            ? {
              ...state.conversationHistory,
              status: AsyncStates.SUCCESS,
              error: "",
              data: {
                ...action.payload,
                chat: action.payload.chat?.reverse(),
              },
            }
            : {
              ...state.conversationHistory,
              status: AsyncStates.SUCCESS,
              error: "",
              data: {
                ...action.payload,
                chat: [
                  ...action.payload.chat.reverse(),
                  ...state.conversationHistory.data.chat,
                ],
              },
            },
      };
    },
    [ConversationHistoryActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      conversationHistory: {
        ...state.conversationHistory,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [ConversationHistoryActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      conversationHistory: conversationHistoryInitialData,
    }),

    // Conversation Summary
    [ConversationSummaryActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        conversationSummary: {
          ...STORE_ACTION_INITIAL_DATA,
          status: AsyncStates.LOADING,
        },
      };
    },
    [ConversationSummaryActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        conversationSummary: {
          ...state.conversationSummary,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [ConversationSummaryActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      conversationSummary: {
        ...state.conversationSummary,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [ConversationSummaryActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      conversationSummary: STORE_ACTION_INITIAL_DATA,
    }),

    // Conversation Favorite
    [ConversationFavoriteActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        conversationFavorite: {
          ...STORE_ACTION_INITIAL_DATA,
          status: AsyncStates.LOADING,
        },
      };
    },
    [ConversationFavoriteActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        conversationFavorite: {
          ...state.conversationFavorite,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [ConversationFavoriteActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      conversationFavorite: {
        ...state.conversationFavorite,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [ConversationFavoriteActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      conversationFavorite: STORE_ACTION_INITIAL_DATA,
    }),

    // Conversation Update
    [ConversationUpdateActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        conversationUpdate: {
          ...STORE_ACTION_INITIAL_DATA,
          status: AsyncStates.LOADING,
        },
      };
    },
    [ConversationUpdateActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        conversationUpdate: {
          ...state.conversationUpdate,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        getConversations: {
          ...state.getConversations,
          data: {
            ...state.getConversations.data,
            conversations: getUpdatedConversations(
              state.getConversations.data.conversations,
              action.payload
            ),
          },
        },
      };
    },
    [ConversationUpdateActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      conversationUpdate: {
        ...state.conversationUpdate,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [ConversationUpdateActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      conversationUpdate: STORE_ACTION_INITIAL_DATA,
    }),

    // Conversation Delete
    [ConversationDeleteActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        conversationDelete: {
          ...STORE_ACTION_INITIAL_DATA,
          status: AsyncStates.LOADING,
        },
      };
    },
    [ConversationDeleteActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        conversationDelete: {
          ...state.conversationDelete,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        getConversations: {
          ...state.getConversations,
          data: {
            ...state.getConversations.data,
            conversations: deleteConversation(
              state.getConversations.data.conversations,
              action.payload
            ),
            conversations_count:
              state.getConversations.data.conversations_count - 1,
          },
        },
      };
    },
    [ConversationDeleteActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      conversationDelete: {
        ...state.conversationDelete,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [ConversationDeleteActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      conversationDelete: STORE_ACTION_INITIAL_DATA,
    }),

    // Current Conversation
    [CurrentConversationActionTypes.SET]: (state, action: any) => ({
      ...state,
      currentConversation: action.payload,
    }),
    [CurrentConversationActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      currentConversation: undefined,
    }),

    // Get Extractions
    [GetExtractionsActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        getExtractions: STORE_ACTION_INITIAL_DATA,
      };
    },
    [GetExtractionsActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        getExtractions: {
          ...state.getExtractions,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
      };
    },
    [GetExtractionsActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      getExtractions: {
        ...state.getExtractions,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [GetExtractionsActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getExtractions: STORE_ACTION_INITIAL_DATA,
    }),

    // Save Extraction Table
    [UpdateExtractionTableActionTypes.REQUEST]: (state) => {
      return {
        ...state,
        updateExtractionTable: { ...STORE_ACTION_INITIAL_DATA, status: AsyncStates.LOADING, },
      };
    },
    [UpdateExtractionTableActionTypes.SUCCESS]: (state, action: any) => {
      return {
        ...state,
        updateExtractionTable: {
          ...state.updateExtractionTable,
          status: AsyncStates.SUCCESS,
          error: "",
          data: action.payload,
        },
        getExtractions: {
          ...state.getExtractions,
          data: {
            ...state.getExtractions.data,
            data: getUpdatedExtractions(state.getExtractions.data?.data, action.payload.reqPayload)
          }
        }
      };
    },
    [UpdateExtractionTableActionTypes.FAILURE]: (state, action: any) => ({
      ...state,
      updateExtractionTable: {
        ...state.updateExtractionTable,
        status: AsyncStates.ERROR,
        error: action.payload,
      },
    }),
    [UpdateExtractionTableActionTypes.CLEAR]: (state, action: any) => ({
      ...state,
      getExtractions: STORE_ACTION_INITIAL_DATA,
    }),
  },
  defaultState
);

export default polyGPTReducer;
