import axios, { AxiosInstance } from "axios"
import { sendCredsHostnames } from "src/constants"
import { Headers } from "../services/interface"

const defaultOptions = {
	baseURL: process.env.REACT_APP_API_URL,
}

export const axiosInstance: AxiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.response.use((response) => response, (error) => {
	if (error?.response?.status === 500 || error?.response?.status === 502) {
		// if(window.confirm('Something went wrong, please reload page')){
		// 	window.location.reload();
		// }
	}
	throw error;
});

export const sendPost = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.post<R>(process.env.REACT_APP_API_URL + url, params, {
			headers,
			...(sendCredsHostnames.includes(window.location.hostname) && { withCredentials: true })
		})
}

export const sendPostWholeUrl = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.post<R>(url, params, {
			headers,
			...(sendCredsHostnames.includes(window.location.hostname) && { withCredentials: true })
		})
}

export const sendPostML = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.post<R>(process.env.REACT_APP_ML_URL + url, params, {
			headers
		})
}
export const sendPostImageAnalysis = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.post<R>(process.env.REACT_APP_IMAGE_ANALYSIS_URL + url, params, {
			headers
		})
}

export const sendGetML = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.get<R>(process.env.REACT_APP_ML_URL + url, {
			headers
		})
}
export const sendGet = <T = any, Result = any>(url: string) => {
	return (params: T, headers: Headers) => {
		return axiosInstance.get<Result>(process.env.REACT_APP_API_URL + url, {
			headers
		})
	}
}

export const sendGetWholeUrl = <T = any, Result = any>(url: string) => {
	return (params: T, headers: Headers) => {
		return axiosInstance.get<Result>(url, {
			headers
		})
	}
}

export const sendGetImageAnalysis = <T = any, Result = any>(url: string) => {
	return (params: T, headers: Headers) => {
		return axiosInstance.get<Result>(process.env.REACT_APP_IMAGE_ANALYSIS_URL + url, {
			headers
		})
	}
}

export const sendPostCelsure = (url: string) => {
	return (params: any, headers: Headers) =>
		axiosInstance.post(process.env.REACT_APP_CELSURE_URL + url, params, {
			headers
		})
}


export const sendPatch = <T = any, Result = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.patch<Result>(process.env.REACT_APP_API_URL + url, params, {
			headers
		})
}

export const sendPut = <T = any, R = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.put<R>(process.env.REACT_APP_API_URL + url, params, {
			headers,
			...(sendCredsHostnames.includes(window.location.hostname) && { withCredentials: true })
		})
}

export const sendDelete = <T = any, Result = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.delete<Result>(process.env.REACT_APP_API_URL + url, { headers, data: params })
}
export const sendDeleteImageAnalysis = <T = any, Result = any>(url: string) => {
	return (params: T, headers: Headers) =>
		axiosInstance.delete<Result>(process.env.REACT_APP_IMAGE_ANALYSIS_URL + url, { headers, data: params })
}