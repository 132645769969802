
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`라틴 하이퍼큐브`}</h1>
    <p>{`라틴 하이퍼큐브 샘플링(LHS)은 다차원 분포에서 매개변수 값의 거의 임의적인 샘플을 생성하기 위한 통계적 방법입니다. 이 샘플링 방법은 컴퓨터 실험 설계에 활용됩니다.`}</p>
    <p>{`라틴 하이퍼큐브 샘플링(LHS)은 매개변수 값의 임의 샘플을 생성하는 방법 중 하나입니다. 몬테카를로 시뮬레이션에서 널리 사용되며, 이는 합리적으로 정확한 결과를 얻기 위해 필요한 실행 횟수를 급격히 줄일 수 있습니다.`}</p>
    <p>{`LHS는 라틴 스퀘어 디자인에 기반을 두고 있으며, 각 행과 열에 단일 샘플이 있는 디자인입니다. '하이퍼큐브'는 세 개 이상의 차원을 가진 큐브를 의미하며, 라틴 스퀘어는 다중 차원 및 다중 초평면에서 샘플링하기 위해 확장됩니다`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;