import { blue } from "@ant-design/colors"
import { LoadingOutlined } from "@ant-design/icons"
import {
    Form,
    Space,
    Upload,
    Typography,
    message,
    Row,
    Tooltip,
    Spin,
} from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates, nestleFileUploadTemplateUrl, permissions, projectStatus } from "src/constants"
import { uploadFileClear, uploadFileRequest } from "src/store/actions/files"
import { StoreState } from "src/store/configureStore"
import { StyledButton } from "src/styled_components/StyledButton"
import { StyledCard } from "src/styled_components/StyledCard"
import { usePermission } from "src/utils/usePermissions"
import useTranslate from "src/utils/useTranslate"
import XLSX from "xlsx"

const { Text } = Typography
const { Dragger } = Upload

// const Sheet1 = "配合データ(形式2) (Formulation _1)"
// const Sheet2 = "配合データ(形式2_追加データ) (Formulation 2"

export const KurarayFileUpload = ({ currentExpIdIndex }: any) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()

    const workOrder = useSelector((state: StoreState) => state.workOrderDetails.workOrder)
    const fileUploadStatus = useSelector((state: StoreState) => state.files.uploadFile.status)
    const [file, setFile] = useState<any>()
    const [sheetData, setSheetData] = useState<any>({})
    const userAccess = usePermission()

    const handleFileUpload = async () => {
        const params = new FormData()
        params.append("file", file)
        params.append("work_order_id", workOrder.work_order_id)
        params.append("experiment_id", workOrder.experiment_id[currentExpIdIndex])
        params.append("stage", workOrder.stages[currentExpIdIndex]?.identifier)
        params.append("data", JSON.stringify(sheetData))
        dispatch(uploadFileRequest(params))
    }

    const uploadProps = {
        onRemove: (record: any) => {
            setFile(null)
        },
        beforeUpload: (record: any) => {
            if (record.name.split(".").pop() === "xlsx") {
                setFile(record)
            } else message.error(t("dataUpload.selectedFileDesc"))
            return false
        },
        multiple: false,
        fileList: !!file ? [file] : [],
    }

    useEffect(() => {
        if (fileUploadStatus === AsyncStates.SUCCESS) {
            setFile(null)
        }
    }, [fileUploadStatus])

    useEffect(() => {
        return () => {
            dispatch(uploadFileClear())
        }
    }, [dispatch])

    useEffect(() => {
        !!file &&
            (async () => {
                const fileSheets = await file.arrayBuffer()
                const workbook = XLSX.read(fileSheets)
                const sheets = workbook.SheetNames
                const sheet1 = XLSX.utils.sheet_to_json(workbook.Sheets[sheets?.[0]], { defval: "-", raw: false })
                const sheet2 = XLSX.utils.sheet_to_json(workbook.Sheets[sheets?.[1]], { defval: "-", raw: false })
                setSheetData({ sheet1, sheet2 })
            })()
    }, [file])


    return (
        <Spin spinning={fileUploadStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
            <Space direction="vertical" style={{ width: "100%" }} size="large">
                <Form
                    onFinish={handleFileUpload}
                    layout="vertical"
                    disabled={userAccess.permission === permissions.viewer || userAccess?.status !== projectStatus.in_progress}
                >
                    <StyledCard
                        title={
                            <Space
                                direction="vertical"
                                style={{ width: "100%" }}
                                size="small"
                            >
                                <Text type="secondary">
                                    {t("dataUpload.importExperimentData")}
                                </Text>
                            </Space>
                        }
                    >
                        <Space direction="vertical" style={{ width: "100%" }} size="large">
                            <Form.Item>
                                <Dragger {...uploadProps}>
                                    <p
                                        style={{ paddingTop: 30, paddingBottom: 30 }}
                                        className="ant-upload-text"
                                    >
                                        {t("dataUpload.dragnDrop")}
                                        <span style={{ color: blue[5] }}>
                                            {" "}
                                            {t("dataUpload.selectFile")}
                                        </span>
                                    </p>
                                </Dragger>
                            </Form.Item>
                            <Form.Item>
                                <Text>
                                    {t("datamapper.needHelp?")}{" "}
                                    <a
                                        target={"_blank"}
                                        rel="noreferrer"
                                        href={nestleFileUploadTemplateUrl}
                                    >
                                        {t("datamapper.downloadTemplate?")}{" "}
                                    </a>
                                    {t("datamapper.spreadsheet?")}
                                </Text>
                            </Form.Item>
                            <Row justify="end">
                                <Tooltip
                                    title={!file ? t("common.uploadAFile") : t("dataMapper.mapsheets")}
                                >
                                    <StyledButton
                                        type="primary"
                                        htmlType="submit"
                                        disabled={!file}
                                        size="large"
                                    >{"Upload"}</StyledButton>
                                </Tooltip>
                            </Row>
                        </Space>
                    </StyledCard>
                </Form>
            </Space>
        </Spin>
    )
}
