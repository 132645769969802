import { useMemo } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { isValidNumber } from "src/utils/decorator"
import { useValue } from "src/utils/useValue"

export type UseTotalHook = (data: any[], ignoreKeys: { [key: string]: string }) => {
    totalRow: { [key: string]: number | string }
}

export const useTotal: UseTotalHook = (data, ignoreKeysObject = {}) => {
    const configs = useSelector((state: StoreState) => state.configs.features)
    const { convertValue, getValue } = useValue()
    const totalRow = useMemo(() => {
        const firstRow = data?.[0]
        const ignoreKeys = Object.keys(ignoreKeysObject || {})

        // PREPARING EMPTY TOTAL ROW
        const totalRow = Object.keys(firstRow || {})?.reduce<{ [key: string]: number | string }>((acc, curr) => {
            if (ignoreKeys.includes(curr)) return acc
            acc[curr] = 0
            return acc
        }, ignoreKeysObject)

        // POPULATING TOTAL ROW
        data.forEach((row) => {
            Object.keys(totalRow || {}).forEach((key) => {
                if (ignoreKeys.includes(key)) return
                if (Boolean(configs?.work_order_costing) && data.some((res: any) => res?.[key] === "Not Defined")) {
                    totalRow[key] = "-"
                } else {
                    const rowValue = isValidNumber(convertValue(row?.[key])) ? convertValue(row?.[key]) : 0
                    const total = getValue(convertValue(totalRow?.[key]) + rowValue)
                    totalRow[key] = total === '0' ? 0 : total;
                }
            })
        })
        return totalRow
    }, [data, ignoreKeysObject, configs, convertValue, getValue])

    return { totalRow }
}