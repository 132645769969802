import React, { useEffect, useRef } from "react"
import Highcharts from "highcharts"
import Chart from "highcharts-react-official"
import { Col } from "antd"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"

type P = {
	options: Highcharts.Options
	title: string
}

export const BarChart = ({ options, title }: P) => {
	const chartRef = useRef<Chart.RefObject>(null)
	const columnRef = useRef<HTMLDivElement>(null)
	const isSidebarCollapsed = useSelector(
		(state: StoreState) => state.sidebar.collapsed
	)

	useEffect(() => {
		let cont = columnRef.current?.getBoundingClientRect()
		if (cont)
			chartRef.current?.chart.setSize(
				cont.width,
				isSidebarCollapsed ? 400 : 300
			)
	}, [isSidebarCollapsed])

	return (
		<Col ref={columnRef} className="categorical-data-summary-bar-chart-row">
			<p>{title}</p>
			<Chart ref={chartRef} highcharts={Highcharts} options={options} />
		</Col>
	)
}
