import { createActions } from "redux-actions"

export enum QualityCheckViewerVisibility {
  QC_TOGGLE_FILE_NAVIGATION = "QC_TOGGLE_FILE_NAVIGATION",
  QC_SET_SELECTED_FILE = "QC_SET_SELECTED_FILE",
  QC_SET_PAGE_NUMBER = "QC_SET_PAGE_NUMBER",
  QC_SET_IS_REPORT_SECTION_UNSAVED = "QC_SET_IS_REPORT_SECTION_UNSAVED",
  QC_SET_REGISTER_LINK_MODAL_VISIBLE = "QC_SET_REGISTER_LINK_MODAL_VISIBLE",
  QC_SET_FILE_DRAWER_VISIBLE = "QC_SET_FILE_DRAWER_VISIBLE",
  QC_SET_FILE_UPLOAD_MODAL_VISIBLE = "QC_SET_FILE_UPLOAD_MODAL_VISIBLE",

  QC_SET_SELECTED_FILES_FOR_REPORT = "QC_SET_SELECTED_FILES_FOR_REPORT",
  QC_CLEAR_SELECTED_FILES_FOR_REPORT = "QC_CLEAR_SELECTED_FILES_FOR_REPORT",

  QC_SET_GET_FILES_PAGE_NUMBER = "QC_SET_GET_FILES_PAGE_NUMBER",
  QC_SET_GET_FILES_FILTERS = "QC_SET_GET_FILES_FILTERS",
  QC_SET_GET_FILES_SEARCH_TERM = "QC_SET_GET_FILES_SEARCH_TERM",

  QC_SET_SELECT_ALL_FILES = "QC_SET_SELECT_ALL_FILES",

  QC_ADD_IGNORE_FILES_FOR_REPORT = "QC_ADD_IGNORE_FILES_FOR_REPORT",
  QC_REMOVE_IGNORE_FILES_FOR_REPORT = "QC_REMOVE_IGNORE_FILES_FOR_REPORT",
}

export enum QualityCheckGetFiles {
  QC_GET_FILES_REQUEST = "QC_GET_FILES_REQUEST",
  QC_GET_FILES_SUCCESS = "QC_GET_FILES_SUCCESS",
  QC_GET_FILES_FAILURE = "QC_GET_FILES_FAILURE",
  QC_GET_FILES_CLEAR = "QC_GET_FILES_CLEAR",
}

export enum QualityCheckGetFilesFilters {
  QC_GET_FILES_FILTERS_REQUEST = "QC_GET_FILES_FILTERS_REQUEST",
  QC_GET_FILES_FILTERS_SUCCESS = "QC_GET_FILES_FILTERS_SUCCESS",
  QC_GET_FILES_FILTERS_FAILURE = "QC_GET_FILES_FILTERS_FAILURE",
  QC_GET_FILES_FILTERS_CLEAR = "QC_GET_FILES_FILTERS_CLEAR",
}

export enum QualityCheckApplyFiles {
  QC_APPLY_FILES_REQUEST = "QC_APPLY_FILES_REQUEST",
  QC_APPLY_FILES_SUCCESS = "QC_APPLY_FILES_SUCCESS",
  QC_APPLY_FILES_FAILURE = "QC_APPLY_FILES_FAILURE",
  QC_APPLY_FILES_CLEAR = "QC_APPLY_FILES_CLEAR",
}

export enum QualityCheckFileExtractions {
  QC_FILE_EXTRACTIONS_SUCCESS = "QC_FILE_EXTRACTIONS_SUCCESS",
  QC_FILE_EXTRACTIONS_REQUEST = "QC_FILE_EXTRACTIONS_REQUEST",
  QC_FILE_EXTRACTIONS_FAILURE = "QC_FILE_EXTRACTIONS_FAILURE",
  QC_FILE_EXTRACTIONS_CLEAR = "QC_FILE_EXTRACTIONS_CLEAR",
}

export enum QualityCheckSaveChangesAndApply {
  QC_SAVE_CHANGES_AND_APPLY_REQUEST = "QC_SAVE_CHANGES_AND_APPLY_REQUEST",
  QC_SAVE_CHANGES_AND_APPLY_SUCCESS = "QC_SAVE_CHANGES_AND_APPLY_SUCCESS",
  QC_SAVE_CHANGES_AND_APPLY_FAILURE = "QC_SAVE_CHANGES_AND_APPLY_FAILURE",
  QC_SAVE_CHANGES_AND_APPLY_CLEAR = "QC_SAVE_CHANGES_AND_APPLY_CLEAR",
}

export enum QualityCheckDeleteFile {
  QC_DELETE_FILE_REQUEST = "QC_DELETE_FILE_REQUEST",
  QC_DELETE_FILE_SUCCESS = "QC_DELETE_FILE_SUCCESS",
  QC_DELETE_FILE_FAILURE = "QC_DELETE_FILE_FAILURE",
  QC_DELETE_FILE_CLEAR = "QC_DELETE_FILE_CLEAR",
}

export enum QualityCheckRegisterLinkLocalUpdate {
  QC_REGISTER_LINK_LOCAL_UPDATE = "QC_REGISTER_LINK_LOCAL_UPDATE",
}

export enum QualityCheckGetRegisterLinkData {
  QC_GET_REGISTER_LINK_DATA_REQUEST = "QC_GET_REGISTER_LINK_DATA_REQUEST",
  QC_GET_REGISTER_LINK_DATA_SUCCESS = "QC_GET_REGISTER_LINK_DATA_SUCCESS",
  QC_GET_REGISTER_LINK_DATA_FAILURE = "QC_GET_REGISTER_LINK_DATA_FAILURE",
  QC_GET_REGISTER_LINK_DATA_CLEAR = "QC_GET_REGISTER_LINK_DATA_CLEAR",
}

export enum QualityCheckValidateLink {
  QC_VALIDATE_LINK_REQUEST = "QC_VALIDATE_LINK_REQUEST",
  QC_VALIDATE_LINK_SUCCESS = "QC_VALIDATE_LINK_SUCCESS",
  QC_VALIDATE_LINK_FAILURE = "QC_VALIDATE_LINK_FAILURE",
  QC_VALIDATE_LINK_CLEAR = "QC_VALIDATE_LINK_CLEAR",
}

export enum QualityCheckLinkRecurringCycle {
  QC_LINK_RECURRING_CYCLE_REQUEST = "QC_LINK_RECURRING_CYCLE_REQUEST",
  QC_LINK_RECURRING_CYCLE_SUCCESS = "QC_LINK_RECURRING_CYCLE_SUCCESS",
  QC_LINK_RECURRING_CYCLE_FAILURE = "QC_LINK_RECURRING_CYCLE_FAILURE",
  QC_LINK_RECURRING_CYCLE_CLEAR = "QC_LINK_RECURRING_CYCLE_CLEAR",
}

export enum QualityCheckSyncOnDemand {
  QC_SYNC_ON_DEMAND_REQUEST = "QC_SYNC_ON_DEMAND_REQUEST",
  QC_SYNC_ON_DEMAND_SUCCESS = "QC_SYNC_ON_DEMAND_SUCCESS",
  QC_SYNC_ON_DEMAND_FAILURE = "QC_SYNC_ON_DEMAND_FAILURE",
  QC_SYNC_ON_DEMAND_CLEAR = "QC_SYNC_ON_DEMAND_CLEAR",
}

export enum QualityCheckLocalUpdatesOnFiles {
  QC_LOCAL_UPDATE_FILE = "QC_LOCAL_UPDATE_FILE"
}

export enum QualityCheckGenerateReport {
  QC_GENERATE_REPORT_REQUEST = "QC_GENERATE_REPORT_REQUEST",
  QC_GENERATE_REPORT_SUCCESS = "QC_GENERATE_REPORT_SUCCESS",
  QC_GENERATE_REPORT_FAILURE = "QC_GENERATE_REPORT_FAILURE",
  QC_GENERATE_REPORT_CLEAR = "QC_GENERATE_REPORT_CLEAR",
}

export const {
  qcToggleFileNavigation,
  qcSetSelectedFile,
  qcSetPageNumber,
  qcSetIsReportSectionUnsaved,
  qcSetRegisterLinkModalVisible,
  qcSetFileDrawerVisible,
  qcSetFileUploadModalVisible,
  qcSetSelectedFilesForReport,
  qcClearSelectedFilesForReport,
  qcSetGetFilesPageNumber,
  qcSetGetFilesFilters,
  qcSetGetFilesSearchTerm,
  qcSetSelectAllFiles,
  qcAddIgnoreFilesForReport,
  qcRemoveIgnoreFilesForReport,

  qcGetFilesRequest,
  qcGetFilesSuccess,
  qcGetFilesFailure,
  qcGetFilesClear,

  qcGetFilesFiltersRequest,
  qcGetFilesFiltersSuccess,
  qcGetFilesFiltersFailure,
  qcGetFilesFiltersClear,

  qcApplyFilesRequest,
  qcApplyFilesSuccess,
  qcApplyFilesFailure,
  qcApplyFilesClear,

  qcFileExtractionsRequest,
  qcFileExtractionsSuccess,
  qcFileExtractionsFailure,
  qcFileExtractionsClear,

  qcSaveChangesAndApplyRequest,
  qcSaveChangesAndApplySuccess,
  qcSaveChangesAndApplyFailure,
  qcSaveChangesAndApplyClear,

  qcDeleteFileRequest,
  qcDeleteFileSuccess,
  qcDeleteFileFailure,
  qcDeleteFileClear,

  qcRegisterLinkLocalUpdate,

  qcGetRegisterLinkDataRequest,
  qcGetRegisterLinkDataSuccess,
  qcGetRegisterLinkDataFailure,
  qcGetRegisterLinkDataClear,

  qcValidateLinkRequest,
  qcValidateLinkSuccess,
  qcValidateLinkFailure,
  qcValidateLinkClear,

  qcLinkRecurringCycleRequest,
  qcLinkRecurringCycleSuccess,
  qcLinkRecurringCycleFailure,
  qcLinkRecurringCycleClear,

  qcSyncOnDemandRequest,
  qcSyncOnDemandSuccess,
  qcSyncOnDemandFailure,
  qcSyncOnDemandClear,

  qcLocalUpdateFile,

  qcGenerateReportRequest,
  qcGenerateReportSuccess,
  qcGenerateReportFailure,
  qcGenerateReportClear,

} = createActions({
  [QualityCheckViewerVisibility.QC_TOGGLE_FILE_NAVIGATION]: () => ({}),
  [QualityCheckViewerVisibility.QC_SET_SELECTED_FILE]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_PAGE_NUMBER]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_IS_REPORT_SECTION_UNSAVED]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_REGISTER_LINK_MODAL_VISIBLE]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_FILE_DRAWER_VISIBLE]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_FILE_UPLOAD_MODAL_VISIBLE]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_SELECTED_FILES_FOR_REPORT]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_CLEAR_SELECTED_FILES_FOR_REPORT]: () => ({}),
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_PAGE_NUMBER]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_FILTERS]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_SEARCH_TERM]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_SET_SELECT_ALL_FILES]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_ADD_IGNORE_FILES_FOR_REPORT]: (payload: any) => (payload),
  [QualityCheckViewerVisibility.QC_REMOVE_IGNORE_FILES_FOR_REPORT]: (payload: any) => (payload),

  [QualityCheckGetFiles.QC_GET_FILES_REQUEST]: (payload: any) => (payload),
  [QualityCheckGetFiles.QC_GET_FILES_SUCCESS]: (payload: any) => (payload),
  [QualityCheckGetFiles.QC_GET_FILES_FAILURE]: (payload: any) => (payload),
  [QualityCheckGetFiles.QC_GET_FILES_CLEAR]: () => ({}),

  [QualityCheckGetFilesFilters.QC_GET_FILES_FILTERS_REQUEST]: (payload: any) => (payload),
  [QualityCheckGetFilesFilters.QC_GET_FILES_FILTERS_SUCCESS]: (payload: any) => (payload),
  [QualityCheckGetFilesFilters.QC_GET_FILES_FILTERS_FAILURE]: (payload: any) => (payload),
  [QualityCheckGetFilesFilters.QC_GET_FILES_FILTERS_CLEAR]: () => ({}),

  [QualityCheckApplyFiles.QC_APPLY_FILES_REQUEST]: (payload: any) => (payload),
  [QualityCheckApplyFiles.QC_APPLY_FILES_SUCCESS]: (payload: any) => (payload),
  [QualityCheckApplyFiles.QC_APPLY_FILES_FAILURE]: (payload: any) => (payload),
  [QualityCheckApplyFiles.QC_APPLY_FILES_CLEAR]: () => ({}),

  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_REQUEST]: (payload: any) => (payload),
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_SUCCESS]: (payload: any) => (payload),
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_FAILURE]: (payload: any) => (payload),
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_CLEAR]: () => ({}),

  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_REQUEST]: (payload: any) => (payload),
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_SUCCESS]: (payload: any) => (payload),
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_FAILURE]: (payload: any) => (payload),
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_CLEAR]: () => ({}),

  [QualityCheckDeleteFile.QC_DELETE_FILE_REQUEST]: (payload: any) => (payload),
  [QualityCheckDeleteFile.QC_DELETE_FILE_SUCCESS]: (payload: any) => (payload),
  [QualityCheckDeleteFile.QC_DELETE_FILE_FAILURE]: (payload: any) => (payload),
  [QualityCheckDeleteFile.QC_DELETE_FILE_CLEAR]: () => ({}),

  [QualityCheckRegisterLinkLocalUpdate.QC_REGISTER_LINK_LOCAL_UPDATE]: (payload: any) => (payload),

  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_REQUEST]: (payload: any) => (payload),
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_SUCCESS]: (payload: any) => (payload),
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_FAILURE]: (payload: any) => (payload),
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_CLEAR]: () => ({}),

  [QualityCheckValidateLink.QC_VALIDATE_LINK_REQUEST]: (payload: any) => (payload),
  [QualityCheckValidateLink.QC_VALIDATE_LINK_SUCCESS]: (payload: any) => (payload),
  [QualityCheckValidateLink.QC_VALIDATE_LINK_FAILURE]: (payload: any) => (payload),
  [QualityCheckValidateLink.QC_VALIDATE_LINK_CLEAR]: () => ({}),

  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_REQUEST]: (payload: any) => (payload),
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_SUCCESS]: (payload: any) => (payload),
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_FAILURE]: (payload: any) => (payload),
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_CLEAR]: () => ({}),

  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_REQUEST]: (payload: any) => (payload),
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_SUCCESS]: (payload: any) => (payload),
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_FAILURE]: (payload: any) => (payload),
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_CLEAR]: () => ({}),

  [QualityCheckLocalUpdatesOnFiles.QC_LOCAL_UPDATE_FILE]: (payload: any) => (payload),

  [QualityCheckGenerateReport.QC_GENERATE_REPORT_REQUEST]: (payload: any) => (payload),
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_SUCCESS]: (payload: any) => (payload),
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_FAILURE]: (payload: any) => (payload),
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_CLEAR]: () => ({}),
})
