import { DownloadOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons'
import { Drawer, Typography, Checkbox, Modal } from 'antd';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { qcClearSelectedFilesForReport, qcDeleteFileRequest, qcGenerateReportRequest, qcSetSelectAllFiles } from 'src/store/actions/qualityCheck'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { modalProps } from '.'
import { antdTheme } from 'src/constants'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton';

const SelectionDrawer = () => {

  const dispatch = useDispatch()
  const [t] = useTranslate()

  const {
    filesToReport,
    getFilesFilters,
    isSelectAllFiles,
    getFilesTotal,
    filesToIgnore,
    getFilesSerachTerm
  } = useSelector((state: StoreState) => state.qualityCheck)

  const selectedFiles = useMemo(
    () => Object.values(filesToReport).flat(),
    [filesToReport]
  )

  return (
    <Drawer
      placement="bottom"
      closable={true}
      onClose={() => dispatch(qcClearSelectedFilesForReport())}
      open={selectedFiles.length > 0}
      height={'10%'}
      styles={{
        header: {
          display: 'none'
        },
        body: {
          backgroundColor: 'black',
          opacity: 0.9,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }
      }}
      mask={false}
    >
      <Typography.Text
        style={{
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 8
        }}
        strong
      >
        <Checkbox checked style={{
          marginRight: 8
        }} />
        {`${isSelectAllFiles && getFilesTotal ? getFilesTotal - (filesToIgnore || [])?.length : selectedFiles.length} ${t('common.items')} ${t(
          'common.Selected'
        )} `}
      </Typography.Text>

      <div
        style={{
          display: 'flex',
          gap: 12,
          alignItems: 'center'
        }}
      >
        <StyledButton
          icon={
            <DownloadOutlined
              style={{
                fontSize: antdTheme.fontSizeHeading4,
                color: '#D9D9D9',
                outline: 'none'
              }}
            />
          }
          style={{
            backgroundColor: '#262626',
            outline: 'none',
            border: 'none',
          }}
          onClick={() => {
            dispatch(qcGenerateReportRequest({ 
              filters: getFilesFilters, 
              search: getFilesSerachTerm,
              ...(isSelectAllFiles ? { select_all: isSelectAllFiles } : { file_ids: selectedFiles }),
              ...((filesToIgnore || [])?.length > 0 && { ignore_files: filesToIgnore })
            }))
          }}
        />

        <StyledButton
          icon={
            <DeleteOutlined
              style={{
                fontSize: antdTheme.fontSizeHeading4,
                outline: 'none',
                color: '#FF4D4F'
              }}
            />
          }
          style={{
            backgroundColor: '#262626',
            outline: 'none',
            border: 'none'
          }}
          onClick={() => {
            Modal.confirm({
              title: `${t('common.deleteFiles')} ?`,
              icon: <StyledDeleteIcon style={{
                fontSize: antdTheme.fontSizeHeading4,
                color: '#FF4D4F',
                marginTop: 4
              }} />,
              okText: t('common.confirm'),
              cancelText: t('common.cancel'),
              onOk: () => {
                dispatch(qcDeleteFileRequest({
                  filters: getFilesFilters, 
                  search: getFilesSerachTerm,
                  ...(isSelectAllFiles ? { select_all: isSelectAllFiles } : { file_ids: selectedFiles }),
                  ...((filesToIgnore || [])?.length > 0 && { ignore_files: filesToIgnore })
                }))
              },
              onCancel: () => {
              },
              ...modalProps,
              styles: {

              },
              centered: true,
              type: 'confirm'
            })
          }}
        />
      </div>

      <StyledButton
        icon={
          <CloseOutlined
            style={{
              fontSize: antdTheme.fontSize,
              color: 'white',
              outline: 'none'
            }}
          />
        }
        style={{
          outline: 'none',
          fontWeight: 600,
          color: 'white',
          borderColor: 'white',
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center'
        }}
        size="small"
        onClick={() => {
          dispatch(qcSetSelectAllFiles(false))
          dispatch(qcClearSelectedFilesForReport())
        }}
      >
        {t('common.clearAll')}
      </StyledButton>
    </Drawer>
  )
}

export default SelectionDrawer