import { Tabs } from "antd";
import { GPTConversationTab } from "./components/GPTConversationTab";
import { GPTExtractionTab } from "./components/GPTExtractionTab";
import { useEffect, useState } from "react";
import useTranslate from "src/utils/useTranslate";

type PropTypes = {
  chatStatus: any[];
};

export const GptRightPanel = ({ chatStatus }: PropTypes) => {
  const [newChat] = chatStatus;
  const [t] = useTranslate();

  const [activeTab, setActiveTab] = useState<string>("conversation");

  useEffect(() => {
    if(newChat){
      setActiveTab("conversation");
    }
  }, [newChat])

  return (
    <Tabs activeKey={activeTab} onChange={(e) => setActiveTab(e)}>
      <Tabs.TabPane key={"conversation"} tab={t("polyGPT.conversation")}>
        <GPTConversationTab chatStatus={chatStatus} />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={"extractions"}
        tab={t("polyGPT.extractions")}
      >
        <GPTExtractionTab />
      </Tabs.TabPane>
    </Tabs>
  );
};
