import { useEffect, useMemo, useState } from 'react';
import { DownOutlined, LoadingOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Popover,
  Select,
  Space,
  message,
  Tabs,
  Typography,
  Table,
  Modal,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStates } from 'src/constants';
import { getPendingInvitationsListRequest, inviteRequest, resendEmailRequest, withdrawInvitationRequest } from 'src/store/actions/invite';
import { StoreState } from 'src/store/configureStore';
import useTranslate from 'src/utils/useTranslate';
import { motion } from 'framer-motion';
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader';
import { StyledButton } from 'src/styled_components/StyledButton';
import ProjectsCard from './ProjectsCard';
import { UserList } from './UserList';
import { StyledButtonDropdown } from 'src/styled_components/StyledButtonDropdown';
import { orange } from '@ant-design/colors';
import { CustomPrompt } from 'src/utils/CustomPrompts';
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar';
import './Teams.scss'
import Highlighter from 'react-highlight-words';

const { TabPane } = Tabs
const { Text } = Typography


export const Teams = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const [form] = useForm()
  const requiredFieldStar = useRequiredFieldStar()


  const { projectList: projects, } = useSelector((state: StoreState) => state.projects)
  const { user_role: userRole, user_email } = useSelector((state: StoreState) => state.login.loginResponse)
  const { status } = useSelector((state: StoreState) => state.invite)
  const [tab, setTab] = useState("users")

  const isEditing = useSelector((state: StoreState) => state.isEditing)
  const [isInvitePopoverVisible, setisInvitePopoverVisible] = useState(false)
  const [pendingInvitationModalVisible, setPendingInvitationModalVisible] = useState(false)

  const {
    invitationList,
    invitationListStatus,
    withdrawInvitationStatus,
  } = useSelector((state: StoreState) => state.invite)
  const [selectedRecords, setSelectedRecords] = useState<any[]>([])
  const [filterTerm, setfilterTerm] = useState("")
  const filteredInvitationList = useMemo(
    () =>
      invitationList?.filter(
        (invitation) =>
          invitation?.invited_user_name
            ?.toLocaleLowerCase()
            ?.includes(filterTerm?.toLocaleLowerCase()) ||
          invitation?.invited_email?.includes(filterTerm)
      ),
    [filterTerm, invitationList]
  )

  useEffect(() => {
    if (status === AsyncStates.SUCCESS) {
      setisInvitePopoverVisible(false)
      form.resetFields()
    }
  }, [status, form])

  useEffect(() => {
    if (withdrawInvitationStatus === AsyncStates.SUCCESS) {
      setSelectedRecords([])
    }
  }, [withdrawInvitationStatus])

  const onInvite = (values: { name: string, email: string, role: string, project_id?: string[] }) => {
    if (values.email.split("@")[1] === user_email.split("@")[1]) {
      dispatch(inviteRequest(values))
    } else {
      message.error(t("teams.message.cannotSendInvitation"))
    }
  }

  const inviteForm = (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onInvite}
      // onFinishFailed={onFinishFailed}
      form={form}
      requiredMark={false}
    >
      <Form.Item
        label={t("common.name")}
        name="name"
        rules={[{ required: true, message: t("teams.form.name"), transform: (value) => value?.trim() }]}
        required 
				tooltip={requiredFieldStar}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("common.email")}
        name="email"
        required 
				tooltip={requiredFieldStar}
        rules={[{ required: true, message: t('common.invalidEmail'), pattern: new RegExp(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,}|[0-9]{1,3})(\]?)$/), transform: (value) => value?.trim() }]}>
        <Input />
      </Form.Item>

      <Form.Item
        label={t("projects.header.title")}
        name="project_id"
      >
        <Select mode="multiple">
          {projects.map(({ project_id, name }: any) => (
            <Select.Option value={project_id} key={project_id}>
              {String(name)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={t("profile.role")}
        name="role"
        rules={[{ required: true, message: t("teams.form.role") }]}
        required 
				tooltip={requiredFieldStar}
      >
        <Select>
          <Select.Option value={"admin"} key={"admin"}>
            {t("common.admin")}
          </Select.Option>
          <Select.Option value={"member"} key={"member"}>
            {t("common.member")}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <StyledButton
          type="primary"
          htmlType="submit"
          loading={status === AsyncStates.LOADING}
        >
          {t("common.send")}
        </StyledButton>
      </Form.Item>
    </Form>
  )

  const getComponentsFromTabs = () => {
    switch (tab) {
      case "projects":
        return <ProjectsCard />
      case "users":
        return <UserList />
    }
  }

  const showModal = () => {
    dispatch(getPendingInvitationsListRequest())
    setPendingInvitationModalVisible(true)
  }

  const deleteInvitation = (data: string[]) => {
    dispatch(withdrawInvitationRequest({ emails: data }))
  }

  const invitationColumns = [
    {
      key: "invited_email",
      title: t("teams.inviteMember"),
      render: (rowValue: any, row: any, index: any): any => (
          <Space direction="vertical" style={{ rowGap: 0 }}>
            <Text strong>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[filterTerm]}
                autoEscape
                textToHighlight={row?.invited_user_name ? row?.invited_user_name.toString() : ''}
              />
            </Text>
            <Text type="secondary" style={{ wordBreak: "break-word" }}>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[filterTerm]}
                autoEscape
                textToHighlight={row?.invited_email ? row?.invited_email.toString() : ''}
              />
            </Text>
          </Space>
      ),
    },
    {
      key: "actions",
      title: "",
      render: (rowValue: any, row: any, index: any): any => (
        <Space style={{ float: "right" }}>
          <Tooltip title={t("teams.resendInvitation")}>
            <StyledButton
              onClick={() =>
                dispatch(resendEmailRequest({ email: rowValue?.invited_email }))
              }
            >
              {t("teams.resend")}
            </StyledButton>
          </Tooltip>

          <Tooltip title={t("teams.withdrawInvitation")}>
            <StyledButton
              danger
              style={{ wordBreak: "break-word" }}
              onClick={() => deleteInvitation([rowValue?.invited_email])}
              disabled={selectedRecords?.length > 1}
            >
              {t("teams.withdraw")}
            </StyledButton>
          </Tooltip>
        </Space>
      ),
    },
  ]


  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
    >
      <CustomPrompt
        isEditing={isEditing}
        message={`${t("common.unsavedChangesLost")}!.`}
      />
      <Space direction="vertical" style={{ width: '100%', gap:0 }}>
        <StyledPageHeader
          ghost={false}
          title={t("teams.teamManagement")}
          footer={(
            <Tabs activeKey={tab} onChange={setTab}>
              <TabPane key={"users"} tab={t("common.users")}></TabPane>
              <TabPane key={"projects"} tab={t("projects.header.title")}></TabPane>
            </Tabs>
          )}
          extra={
            <Space>
              {userRole === 'admin' && (
                <Popover
                  content={inviteForm}
                  title={
                    <>
                      {t("teams.invite")}{" "}
                      {isEditing && (
                        <Text
                          type="secondary"
                          style={{ color: orange.primary }}
                        >
                          {t("common.unsavedChanges")}
                        </Text>
                      )}
                    </>
                  }
                  trigger="click"
                  placement="bottomRight"
                  showArrow={false}
                  overlayStyle={{ width: 400 }}
                  open={isInvitePopoverVisible}
                  onOpenChange={setisInvitePopoverVisible}
                >
                  <StyledButtonDropdown
                    icon={<DownOutlined />}
                    type="primary"
                    style={{ marginBottom: 8, outline: 'none', }}
                    menu={
                      {
                        style: {
                          borderRadius: 8,
                          overflow: 'hidden',
                          outline: 'none',
                        },
                        items:[
                          {
                            key: "invite",
                            label: t("teams.pendingInvitations"),
                            icon: <UserAddOutlined />,
                            onClick: showModal,
                            style: { outline: 'none' }
                          }
                        ]
                      }
                    }
                  >
                    <UserAddOutlined /> {t("teams.invite")}
                  </StyledButtonDropdown>
                </Popover>
              )}
            </Space>
          }
        />
        {getComponentsFromTabs()}
      </Space >
      <Modal
        okText={t("common.ok")}
        cancelText={t("common.cancel")}
        title={t("teams.pendingInvitations")}
        open={pendingInvitationModalVisible}
        onCancel={() => {
          setSelectedRecords([])
          setfilterTerm("")
          setPendingInvitationModalVisible(false)
        }}
        width={600}
        footer={null}
        styles={{
          body:{
            padding: '1rem 0rem'
          }
        }}
      >
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <Space>
            {!!selectedRecords?.length && (
              <Text strong>
                {`${t("formulations.selected")} (${selectedRecords?.length})`}
              </Text>
            )}
          </Space>
          <Space>
            {selectedRecords?.length > 1 && (
              <StyledButton
                danger
                onClick={() => deleteInvitation(selectedRecords)}
              >
                {t("teams.withdraw")}
              </StyledButton>
            )}
            <Input
              prefix={<SearchOutlined />}
              style={{ width: "310px" }}
              value={filterTerm}
              placeholder={t("teams.searchforMembersByNameOrEmail")}
              onChange={(e) => {
                setfilterTerm(e.target.value)
                setSelectedRecords([])
              }}
              allowClear
            />
          </Space>
        </Space>

        <Space
          direction="vertical"
          style={{ width: "100%"}}
        >
          <Table
            dataSource={filteredInvitationList.map((ele: any, id: any) => ({
              ...ele,
              key: ele.invited_email,
            }))}
            bordered={false}
            columns={invitationColumns}
            loading={{ spinning: AsyncStates.LOADING === invitationListStatus, indicator: <LoadingOutlined /> }}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedRecords.map((res: any) => res),
              onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedRecords(selectedRowKeys)
              },
            }}
            pagination={{ pageSize: 5 }}
            className='teams-pending-invitation-table'
          />
        </Space>
      </Modal>
    </motion.div>
  )
}
