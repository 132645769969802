import { put, call, select, take, takeLatest } from "redux-saga/effects"
import { END, eventChannel } from "redux-saga"
import { notification } from "antd"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { reconnect } from "../actions/connection"
import { multistageHeatmapSocket } from "src/services/multistageHeatmap"
import {
  multistageHeatmapCleanupOptions,
  multistageHeatmapCleanupResult,
  multistageHeatmapFailure,
  multistageHeatmapFailureOptions,
  multistageHeatmapFailureResult,
  multistageHeatmapRequest,
  multistageHeatmapSuccessOptions,
  multistageHeatmapSuccessResult,
} from "../actions/multistageHeatmap";

type MultistageHeatmapSocketPayload = {
  payload: {
    type?: string
    work_order_ids?: string[]
    initial_stage_exp_ids?: string[]
    final_stage_exp_ids?: string[]
    initial_stage?: any
    final_stage?: any,
    newSocket?: boolean
  },
}

let heatmapSocket: WebSocket
let heatmapSocketChannel: any

function createMultistageHeatmapChannel(heatmapSocket: WebSocket) {
  return eventChannel((emit) => {
    heatmapSocket.onmessage = (event: any) => {
      emit(event.data)
    }

    heatmapSocket.onclose = () => {
      emit(END)
    }

    const unsubscribe = () => {
      heatmapSocket.onmessage = null
    }

    return unsubscribe
  })
}

function* multiStageHeatmapSaga({
  payload,
}: MultistageHeatmapSocketPayload): Generator<any, any, any> {
  const { user_id: key } = yield select((state) => state.login.loginResponse)

  const ln: LanguageUnion = yield select(
    (state: StoreState) => state.language.current,
  )

  if (
    heatmapSocket === undefined ||
    heatmapSocketChannel === undefined ||
    payload?.newSocket
  ) {
    if (heatmapSocket) {
      heatmapSocket.close()
    }
    heatmapSocket = yield call(multistageHeatmapSocket, key)
    heatmapSocketChannel = yield call(
      createMultistageHeatmapChannel,
      heatmapSocket,
    )
  }
  heatmapSocket.send(
    JSON.stringify({ ...payload, token: jwtManager.getToken() }),
  )
  while (true) {
    try {
      const wsResponse = yield take(heatmapSocketChannel)
      const result = JSON.parse(wsResponse)

      if (result?.type === "options") {
        try {
          yield put(multistageHeatmapSuccessOptions(result))
        } catch (err) {
          yield put(multistageHeatmapFailureOptions(result))
        }
      }

      if (result?.type === "result") {
        try {
          yield put(multistageHeatmapSuccessResult(result))
        } catch (err) {
          yield put(multistageHeatmapFailureResult(result))
        }
      }

      if (result?.type === "error") {
        notification.error({
          message: result.message,
        })
        yield put(multistageHeatmapCleanupOptions())
        yield put(multistageHeatmapCleanupResult())
      }
    } catch (error) {
      notification.error({
        message: messages[ln].internal_server_error,
      })
      yield put(multistageHeatmapFailure(error))
      yield put(reconnect(error))
    }
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(multistageHeatmapRequest, multiStageHeatmapSaga)
}
