import "./style.scss"
import "../../WorkOrderDetails/react-datasheet.css"
import { useEffect, useState } from "react"
import { Spin, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { BulbOutlined, HistoryOutlined, LoadingOutlined } from "@ant-design/icons"
import { AsyncStates } from "src/constants";
import { Form } from "./form"
// import { PredictionResult } from "./prediction-result"
import { PredictionResultZeon } from "./prediction-result-zeon"
import { Drawer } from "./drawer"
import {
	getCharacterizationMethodsZeonClean,
	getCharacterizationMethodsZeonRequest,
	getForwardConfigsRequest,
	getForwardConfigsZeonRequest,
} from "src/store/actions/formulate"
import { StyledCard } from "src/styled_components/StyledCard"
import useTranslate from "src/utils/useTranslate"
import { useHistory } from "react-router-dom"
import StyledBadge from "src/styled_components/StyledBadge"
import { StyledButton } from "src/styled_components/StyledButton";

type P = {
	tab: any
}

export const ForwardModel = (props: P) => {
	const configs = useSelector((state: StoreState) => state.configs.features)
	const ai_engine = useSelector((state: StoreState) => state.configs.ai_engine)
	const [drawerVisible, setDrawerVisible] = useState(false)
	const [t] = useTranslate()
	const dispatch = useDispatch()
	const { push } = useHistory()

	const { current: currentProject, selectAllProjects } = useSelector(
		(state: StoreState) => state.projects
	)

	useEffect(() => {
		if (!Boolean(configs?.ai_engine_with_methods)) {
			dispatch(
				getForwardConfigsRequest(
					selectAllProjects
						? { project_id: "" }
						: { project_id: currentProject }
				)
			)
		} else {
			dispatch(getForwardConfigsZeonRequest({ project_id: '' }))
		}
	}, [configs, currentProject, dispatch, selectAllProjects])

	useEffect(() => {
		if (props.tab !== "properties_prediction") {
			setDrawerVisible(false)
		}
	}, [props.tab])


	return (
		<StyledCard
			title={
        <Typography.Text
          ellipsis={{
            tooltip: t("aiEngine.forwardModel.makePropertiesPrediction"),
          }}
						strong
						style={{
							marginBottom: 0,
							backgroundColor: "#e6f4ff",
							borderRadius: 6,
							padding: 12,
						}}
					>
          <BulbOutlined /> -{" "}
          {t("aiEngine.forwardModel.makePropertiesPrediction")}
        </Typography.Text>
      }
			extra={
				<Tooltip placement="top" title={t("aiEngine.predictedExperimentsHistory")}>
					<StyledBadge count={ai_engine?.forward?.total_unread_count} overflowCount={10} showZero={false}>
          <StyledButton
            type="default"
            icon={<HistoryOutlined />}
            style={{ marginLeft: "1rem" }}
							size="large"
            onClick={() => {
							push(`/ai-engine/history/properties_prediction`)
          }}
          />
					</StyledBadge>
        </Tooltip>
			}
			headStyle={{ padding: "1rem", border: 'none', paddingBottom: 0 }}
			bodyStyle={{ padding: "1rem" }}
		>
			{drawerVisible && <Drawer setVisible={setDrawerVisible} visible={drawerVisible} />}

			{Boolean(configs?.ai_engine_with_methods) ? (
				<ZeonComponent {...props} />
			) : (
				<GeneralComponent {...props} />
			)}
		</StyledCard>
	)
}

/**
 * This component is a wrapper for the company name Zeon
 *
 */
const ZeonComponent = ({ tab }: P) => {
	const {
		mlProjectSpecificDataZeonStatus,
		mlProjectSpecificDataZeon,
		forwardPredResultStatus,
		formulateStatus,
	} = useSelector((state: StoreState) => state.formulate)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(
			getCharacterizationMethodsZeonRequest({
				payload: {
					characterization_method_id: "",
				},
				from: "forward"
			})
		)

		return () => {
			dispatch(getCharacterizationMethodsZeonClean())
		}
	}, [dispatch])

	return (
		<Spin
			spinning={
				formulateStatus === AsyncStates.LOADING ??
				forwardPredResultStatus === AsyncStates.LOADING ??
				mlProjectSpecificDataZeonStatus === AsyncStates.LOADING
			}
			indicator={<LoadingOutlined />}
		>
			<div style={{ display: "flex", flexDirection: "column", gap: "60px" }}>
				<Form
					tab={tab}
					data={mlProjectSpecificDataZeon}
					status={mlProjectSpecificDataZeonStatus}
				/>

				<PredictionResultZeon />
			</div>
		</Spin>
	)
}

/**
 * This component is a wrapper for the company other than Zeon
 *
 */
const GeneralComponent = ({ tab }: P) => {
	const {
    mlProjectSpecificDataStatus,
    mlProjectSpecificData,
    forwardPredResultStatus,
    formulateStatus,
  } = useSelector((state: StoreState) => state.formulate);
	return (
		<Spin
			spinning={
				formulateStatus === AsyncStates.LOADING ??
				forwardPredResultStatus === AsyncStates.LOADING ??
				mlProjectSpecificDataStatus === AsyncStates.LOADING
			}
			indicator={<LoadingOutlined />}
		>
			<div style={{ display: "flex", flexDirection: "column", gap: "60px" }}>
				<Form
					tab={tab}
					data={mlProjectSpecificData}
					status={mlProjectSpecificDataStatus}
				/>

				{/* <PredictionResult currentTab={tab} /> */}
			</div>
		</Spin>
	)
}
