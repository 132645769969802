import { Dispatch, SetStateAction } from "react";
import { FileTextOutlined, ToolOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useHistory } from "react-router-dom"
import useTranslate from "src/utils/useTranslate"
import { Record } from "./types"
import { useDispatch } from "react-redux"
import { modelsConfigRequest } from "src/store/actions/formulate"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
import { StyledButton } from "src/styled_components/StyledButton";

type P = {
	record: Record
	setSelectedRecord: Dispatch<SetStateAction<Record>>
	setModelUseModalVisible: Dispatch<SetStateAction<boolean>>
	setDeleteModalVisible: Dispatch<SetStateAction<boolean>>
}

export const DropDownOverlay = ({
	record,
	setModelUseModalVisible,
	setSelectedRecord,
	setDeleteModalVisible,
}: P) => {
	const dispatch = useDispatch()
	const { push } = useHistory()
	const [t] = useTranslate()

	return (
		<Menu>
			<Menu.Item
				onClick={() => {
					setSelectedRecord(record)
					setModelUseModalVisible(true)
					dispatch(modelsConfigRequest({ version: record.version }))
				}}
			>
				<StyledButton icon={<ToolOutlined />} style={{ color: "black" }} type="link">
					{t("aiEngine.useModel")}
				</StyledButton>
			</Menu.Item>

			<Menu.Item
				onClick={() => {
					push(`/model-details?version=${record.version}`)
				}}
			>
				<StyledButton
					icon={<FileTextOutlined />}
					style={{ color: "black" }}
					type="link"
				>
					{t("common.viewDetails")}
				</StyledButton>
			</Menu.Item>

			{record?.model_display_type?.includes('custom_ai') && (
				<Menu.Item
					onClick={() => {
						setSelectedRecord(record)
						setDeleteModalVisible(true)
					}}
				>
					<StyledButton icon={<StyledDeleteIcon/>} danger type="link">
						{t("aiEngine.DeleteModel")}
					</StyledButton>
				</Menu.Item>
			)}
		</Menu>
	)
}
