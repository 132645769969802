import { put, takeLatest, call, select } from "redux-saga/effects";
import {
    createAdditiveCategoryAPI,
    createAdditivesAPI,
    createFieldAPI,
    createIngredientCategoryAPI,
    createIngredientTemplateAPI,
    createMethodsAPI,
    createParameterAPI,
    createPropertyAPI,
    createPropertyCategoryAPI,
    deleteAdditiveCategoryAPI,
    deleteAdditivesAPI,
    deleteFieldAPI,
    deleteIngredientCategoryAPI,
    deleteIngredientTemplateAPI,
    deleteMethodsAPI,
    deleteParameterAPI,
    deletePropertyAPI,
    deletePropertyCategoryAPI,
    getAdditiveCategoriesAPI,
    getAdditivesAPI,
    getAllCategoriesApi,
    getAllMethodsAPI,
    getFieldAPI,
    getIngredientCategoriesAPI,
    getIngredientTemplateAPI,
    getMethodsAPI,
    getParameterAPI,
    getPropertyAPI,
    getPropertyCategoriesAPI,
    getPropertyParameterAPI,
    getPropertyUnitsApi,
    setDefaultPropertyUnitApi,
    updateAdditiveCategoryAPI,
    updateAdditivesAPI,
    updateFieldAPI,
    updateIngredientCategoryAPI,
    updateIngredientTemplateAPI,
    updateMethodsAPI,
    updateParameterAPI,
    updatePropertyAPI,
    updatePropertyCategoryAPI,
} from "src/services/repository";
import { notification } from "antd";
import jwtManager from "src/utils/jwtManager";
import {
    createAdditiveFailure,
    createAdditiveRequest,
    createAdditiveSuccess,
    createCategoryFailure,
    createCategoryRequest,
    createCategorySuccess,
    createFieldFailure,
    createFieldRequest,
    createFieldSuccess,
    createIngredientTemplatesFailure,
    createIngredientTemplatesRequest,
    createIngredientTemplatesSuccess,
    createMethodsFailure,
    createMethodsRequest,
    createMethodsSuccess,
    createParametersFailure,
    createParametersRequest,
    createParametersSuccess,
    createPropertyFailure,
    createPropertyRequest,
    createPropertySuccess,
    fetchAdditiveFailure,
    fetchAdditiveRequest,
    fetchAdditiveSuccess,
    fetchCategoryFailure,
    fetchCategoryRequest,
    fetchCategorySuccess,
    fetchFieldFailure,
    fetchFieldRequest,
    fetchFieldSuccess,
    fetchIngredientTemplatesFailure,
    fetchIngredientTemplatesRequest,
    fetchIngredientTemplatesSuccess,
    fetchMethodsFailure,
    fetchMethodsRequest,
    fetchMethodsSuccess,
    fetchParametersFailure,
    fetchParametersRequest,
    fetchParametersSuccess,
    fetchPropertyFailure,
    fetchPropertyParametersFailure,
    fetchPropertyParametersRequest,
    fetchPropertyParametersSuccess,
    fetchPropertyRequest,
    fetchPropertySuccess,
    fetchAllCategoriesFailure,
    fetchAllCategoriesRequest,
    fetchAllCategoriesSuccess,
    removeAdditiveFailure,
    removeAdditiveRequest,
    removeAdditiveSuccess,
    removeCategoryFailure,
    removeCategoryRequest,
    removeCategorySuccess,
    removeFieldFailure,
    removeFieldRequest,
    removeFieldSuccess,
    removeIngredientTemplatesFailure,
    removeIngredientTemplatesRequest,
    removeIngredientTemplatesSuccess,
    removeMethodsFailure,
    removeMethodsRequest,
    removeMethodsSuccess,
    removeParametersFailure,
    removeParametersRequest,
    removeParametersSuccess,
    removePropertyFailure,
    removePropertyRequest,
    removePropertySuccess,
    updateAdditiveFailure,
    updateAdditiveRequest,
    updateAdditiveSuccess,
    updateCategoryFailure,
    updateCategoryRequest,
    updateCategorySuccess,
    updateFieldFailure,
    updateFieldRequest,
    updateFieldSuccess,
    updateIngredientTemplatesFailure,
    updateIngredientTemplatesRequest,
    updateIngredientTemplatesSuccess,
    updateMethodsFailure,
    updateMethodsRequest,
    updateMethodsSuccess,
    updateParametersFailure,
    updateParametersRequest,
    updateParametersSuccess,
    updatePropertyFailure,
    updatePropertyRequest,
    updatePropertySuccess,
    fetchPropertyUnitsSuccess,
    fetchPropertyUnitsFailure,
    fetchPropertyUnitsRequest,
    markPropertyUnitDefaultRequest,
    markPropertyUnitDefaultSuccess,
    markPropertyUnitDefaultFailure,
    fetchAllMethodsRequest,
    fetchAllMethodsSuccess,
    fetchAllMethodsFailure,
} from "../actions/repository";
import { messages } from "src/utils/hooks";
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from "../configureStore";
import { CATEGORY_TYPES } from "src/modules/InventoryV2/Repository/Common/Data";

type repositoryAction = {
    type: string;
    payload: any;
};

function* getPropertySaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(getPropertyAPI, action.payload, headers);

        yield put(fetchPropertySuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchPropertyFailure(err));
        notification.error({ message: err });
    }
}

function* getPropertyParametersSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(
            getPropertyParameterAPI(action.payload.property_id),
            action.payload,
            headers
        );

        yield put(
            fetchPropertyParametersSuccess(action.payload.property_id, result.data)
        );
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchPropertyParametersFailure(err));
        notification.error({ message: err });
    }
}

function* createPropertySaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(createPropertyAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(createPropertySuccess(result.data));
            notification.success({ message: result.message });
        } else {
            yield put(createPropertyFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(createPropertyFailure(err));
        notification.error({ message: err });
    }
}

function* updatePropertySaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(
            updatePropertyAPI(action.payload.property_id),
            action.payload,
            headers
        );

        if (result.status.toLowerCase() === "success") {
            yield put(updatePropertySuccess(action.payload.property_id, result.data));
            notification.success({ message: result.message });
        } else {
            yield put(updatePropertyFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(updatePropertyFailure(err));
        notification.error({ message: err });
    }
}

function* removePropertySaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(deletePropertyAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(removePropertySuccess(action.payload.property_ids));
            notification.success({ message: result.message });
        } else {
            yield put(removePropertyFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(removePropertyFailure(err));
        notification.error({ message: err });
    }
}

function* getCategoriesSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };

        let getCategoriesAPI = getIngredientCategoriesAPI;
        if (action.payload.type === CATEGORY_TYPES.PROPERTY) getCategoriesAPI = getPropertyCategoriesAPI;
        if (action.payload.type === CATEGORY_TYPES.ADDITIVE) getCategoriesAPI = getAdditiveCategoriesAPI;

        const {
            data: { result },
        } = yield call(getCategoriesAPI, action.payload, headers);
        if (result.status === "Success") {
            yield put(fetchCategorySuccess(result.data));
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchCategoryFailure(err));
        notification.error({ message: err });
    }
}

function* createCategorySaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };

        let createCategoryAPI = createIngredientCategoryAPI;
        if (action.payload.type === CATEGORY_TYPES.PROPERTY) createCategoryAPI = createPropertyCategoryAPI;
        if (action.payload.type === CATEGORY_TYPES.ADDITIVE) createCategoryAPI = createAdditiveCategoryAPI;

        const {
            data: { result },
        } = yield call(createCategoryAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(createCategorySuccess(result.data));
            notification.success({ message: result.message });
        } else {
            yield put(createCategoryFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(createCategoryFailure(err));
        notification.error({ message: err });
    }
}

function* updateCategorySaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };

        let updateCategoryAPI = updateIngredientCategoryAPI;
        if (action.payload.type === CATEGORY_TYPES.PROPERTY) updateCategoryAPI = updatePropertyCategoryAPI;
        if (action.payload.type === CATEGORY_TYPES.ADDITIVE) updateCategoryAPI = updateAdditiveCategoryAPI;

        const {
            data: { result },
        } = yield call(
            updateCategoryAPI(action.payload.category_id),
            action.payload,
            headers
        );

        if (result.status.toLowerCase() === "success") {
            yield put(updateCategorySuccess(action.payload.category_id, result.data));
            notification.success({ message: result.message });
        } else {
            yield put(updateCategoryFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(updateCategoryFailure(err));
        notification.error({ message: err });
    }
}

function* removeCategorySaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };

        let deleteCategoryAPI = deleteIngredientCategoryAPI;
        if (action.payload.type === CATEGORY_TYPES.PROPERTY) deleteCategoryAPI = deletePropertyCategoryAPI;
        if (action.payload.type === CATEGORY_TYPES.ADDITIVE) deleteCategoryAPI = deleteAdditiveCategoryAPI;

        const {
            data: { result },
        } = yield call(deleteCategoryAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(removeCategorySuccess(action.payload.category_ids));
            notification.success({ message: result.message });
        } else {
            yield put(removeCategoryFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(removeCategoryFailure(err));
        notification.error({ message: err });
    }
}

function* getAdditiveSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(getAdditivesAPI, action.payload, headers);

        yield put(fetchAdditiveSuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchAdditiveFailure(err));
        notification.error({ message: err });
    }
}

function* createAdditiveSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(createAdditivesAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(createAdditiveSuccess(result.data));
            notification.success({ message: result.message });
        } else {
            yield put(createAdditiveFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(createAdditiveFailure(err));
        notification.error({ message: err });
    }
}

function* updateAdditiveSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(
            updateAdditivesAPI(action.payload.additive_id),
            action.payload,
            headers
        );

        if (result.status.toLowerCase() === "success") {
            yield put(updateAdditiveSuccess(action.payload.additive_id, result.data));
            notification.success({ message: result.message });
        } else {
            yield put(updateAdditiveFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(updateAdditiveFailure(err));
        notification.error({ message: err });
    }
}

function* removeAdditiveSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(deleteAdditivesAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(removeAdditiveSuccess(action.payload.additive_ids));
            notification.success({ message: result.message });
        } else {
            yield put(removeAdditiveFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(removeAdditiveFailure(err));
        notification.error({ message: err });
    }
}

function* getFieldSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(getFieldAPI, action.payload, headers);

        yield put(fetchFieldSuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchFieldFailure(err));
        notification.error({ message: err });
    }
}

function* createFieldSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(createFieldAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(createFieldSuccess(result.data));
            notification.success({ message: result.message });
        } else {
            yield put(createFieldFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(createFieldFailure(err));
        notification.error({ message: err });
    }
}

function* updateFieldSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(
            updateFieldAPI(action.payload.field_id),
            action.payload,
            headers
        );

        if (result.status.toLowerCase() === "success") {
            yield put(updateFieldSuccess(action.payload.field_id, result.data));
            notification.success({ message: result.message });
        } else {
            yield put(updateFieldFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(updateFieldFailure(err));
        notification.error({ message: err });
    }
}

function* removeFieldSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(deleteFieldAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(removeFieldSuccess(action.payload.field_ids));
            notification.success({ message: result.message });
        } else {
            yield put(removeFieldFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(removeFieldFailure(err));
        notification.error({ message: err });
    }
}

function* getParameterSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(getParameterAPI, action.payload, headers);

        yield put(fetchParametersSuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchParametersFailure(err));
        notification.error({ message: err });
    }
}

function* createParameterSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(createParameterAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(createParametersSuccess(result.data));
            notification.success({ message: result.message });
        } else {
            yield put(createParametersFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(createParametersFailure(err));
        notification.error({ message: err });
    }
}

function* updateParameterSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(
            updateParameterAPI(action.payload.parameter_id),
            action.payload,
            headers
        );

        if (result.status.toLowerCase() === "success") {
            yield put(
                updateParametersSuccess(action.payload.parameter_id, result.data)
            );
            notification.success({ message: result.message });
        } else {
            yield put(updateParametersFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(updateParametersFailure(err));
        notification.error({ message: err });
    }
}

function* removeParameterSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(deleteParameterAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(removeParametersSuccess(action.payload.parameter_ids));
            notification.success({ message: result.message });
        } else {
            yield put(removeParametersFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(removeParametersFailure(err));
        notification.error({ message: err });
    }
}

function* getMethodsSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(getMethodsAPI(action.payload.property_id), action.payload, headers);

        yield put(fetchMethodsSuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchMethodsFailure(err));
        notification.error({ message: err });
    }
}

function* getAllMethodsSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(getAllMethodsAPI(), action.payload, headers);

        yield put(fetchAllMethodsSuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchAllMethodsFailure(err));
        notification.error({ message: err });
    }
}

function* createMethodsSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(createMethodsAPI(action.payload.property_id), action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(createMethodsSuccess(result.data));
            yield put(fetchAllMethodsRequest());
            notification.success({ message: result.message });
        } else {
            yield put(createMethodsFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(createMethodsFailure(err));
        notification.error({ message: err });
    }
}

function* updateMethodsSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(
            updateMethodsAPI(action.payload.property_id, action.payload.method_id),
            action.payload,
            headers
        );

        if (result.status.toLowerCase() === "success") {
            yield put(
                updateMethodsSuccess(action.payload.method_id, result.data)
            );
            notification.success({ message: result.message });
        } else {
            yield put(updateMethodsFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(updateMethodsFailure(err));
        notification.error({ message: err });
    }
}

function* removeMethodsSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(deleteMethodsAPI(action.payload.property_id), action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(removeMethodsSuccess(action.payload.methods.map((method: any) => method.method_id)));
            notification.success({ message: result.message });
        } else {
            yield put(removeMethodsFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(removeMethodsFailure(err));
        notification.error({ message: err });
    }
}

function* getIngredientTemplateSaga(action: repositoryAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(getIngredientTemplateAPI, action.payload, headers);

        yield put(fetchIngredientTemplatesSuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(fetchIngredientTemplatesFailure(err));
        notification.error({ message: err });
    }
}

function* createIngredientTemplateSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(createIngredientTemplateAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(createIngredientTemplatesSuccess(result.data));
            notification.success({ message: result.message });
        } else {
            yield put(createIngredientTemplatesFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(createIngredientTemplatesFailure(err));
        notification.error({ message: err });
    }
}

function* updateIngredientTemplateSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(
            updateIngredientTemplateAPI(action.payload.template_id),
            action.payload,
            headers
        );

        if (result.status.toLowerCase() === "success") {
            yield put(
                updateIngredientTemplatesSuccess(action.payload.template_id, result.data)
            );
            notification.success({ message: result.message });
        } else {
            yield put(updateIngredientTemplatesFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(updateIngredientTemplatesFailure(err));
        notification.error({ message: err });
    }
}

function* removeIngredientTemplateSaga(
    action: repositoryAction
): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(deleteIngredientTemplateAPI, action.payload, headers);

        if (result.status.toLowerCase() === "success") {
            yield put(removeIngredientTemplatesSuccess(action.payload.template_ids));
            notification.success({ message: result.message });
        } else {
            yield put(removeIngredientTemplatesFailure(result.message));
            notification.warning({ message: result.message });
        }
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(removeIngredientTemplatesFailure(err));
        notification.error({ message: err });
    }
}

function* fetchAllCategoriesSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: {
                result: { message, status, data },
            },
        } = yield call(getAllCategoriesApi, undefined, headers);
        if (status === "Success") {
            yield put(fetchAllCategoriesSuccess(data));
        } else {
            yield put(fetchAllCategoriesFailure(message));
            notification.error({ message });
        }
    } catch (error) {
        yield put(fetchAllCategoriesFailure(messages[ln].internal_server_error));
        notification.error({ message: messages[ln].internal_server_error });
    }
}

function* fetchPropertyUnitsSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: {
                result: { message, status, data },
            },
        } = yield call(getPropertyUnitsApi(payload.property_id), undefined, headers);
        if (status === "Success") {
            yield put(fetchPropertyUnitsSuccess(data));
        } else {
            yield put(fetchPropertyUnitsFailure(message));
            notification.error({ message });
        }
    } catch (error) {
        yield put(fetchPropertyUnitsFailure(messages[ln].internal_server_error));
        notification.error({ message: messages[ln].internal_server_error });
    }
}

function* markPropertyUnitDefaultSaga({ payload }: any): Generator<any, any, any> {
    const ln: LanguageUnion = yield select((state: StoreState) => state.language.current);
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: {
                result: { message, status, data },
            },
        } = yield call(setDefaultPropertyUnitApi, payload, headers);
        if (status === "Success") {
            yield put(markPropertyUnitDefaultSuccess(data));
        } else {
            yield put(markPropertyUnitDefaultFailure(message));
            notification.error({ message });
        }
    } catch (error) {
        yield put(markPropertyUnitDefaultFailure(messages[ln].internal_server_error));
        notification.error({ message: messages[ln].internal_server_error });
    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(fetchPropertyRequest, getPropertySaga);
    yield takeLatest(fetchPropertyParametersRequest, getPropertyParametersSaga);
    yield takeLatest(createPropertyRequest, createPropertySaga);
    yield takeLatest(updatePropertyRequest, updatePropertySaga);
    yield takeLatest(removePropertyRequest, removePropertySaga);
    yield takeLatest(fetchCategoryRequest, getCategoriesSaga);
    yield takeLatest(createCategoryRequest, createCategorySaga);
    yield takeLatest(updateCategoryRequest, updateCategorySaga);
    yield takeLatest(removeCategoryRequest, removeCategorySaga);
    yield takeLatest(fetchAdditiveRequest, getAdditiveSaga);
    yield takeLatest(createAdditiveRequest, createAdditiveSaga);
    yield takeLatest(updateAdditiveRequest, updateAdditiveSaga);
    yield takeLatest(removeAdditiveRequest, removeAdditiveSaga);
    yield takeLatest(fetchFieldRequest, getFieldSaga);
    yield takeLatest(createFieldRequest, createFieldSaga);
    yield takeLatest(updateFieldRequest, updateFieldSaga);
    yield takeLatest(removeFieldRequest, removeFieldSaga);
    yield takeLatest(fetchParametersRequest, getParameterSaga);
    yield takeLatest(createParametersRequest, createParameterSaga);
    yield takeLatest(updateParametersRequest, updateParameterSaga);
    yield takeLatest(removeParametersRequest, removeParameterSaga);
    yield takeLatest(fetchAllMethodsRequest, getAllMethodsSaga);
    yield takeLatest(fetchMethodsRequest, getMethodsSaga);
    yield takeLatest(createMethodsRequest, createMethodsSaga);
    yield takeLatest(updateMethodsRequest, updateMethodsSaga);
    yield takeLatest(removeMethodsRequest, removeMethodsSaga);
    yield takeLatest(fetchIngredientTemplatesRequest, getIngredientTemplateSaga);
    yield takeLatest(createIngredientTemplatesRequest, createIngredientTemplateSaga);
    yield takeLatest(updateIngredientTemplatesRequest, updateIngredientTemplateSaga);
    yield takeLatest(removeIngredientTemplatesRequest, removeIngredientTemplateSaga);
    yield takeLatest(fetchAllCategoriesRequest, fetchAllCategoriesSaga);
    yield takeLatest(fetchPropertyUnitsRequest, fetchPropertyUnitsSaga);
    yield takeLatest(markPropertyUnitDefaultRequest, markPropertyUnitDefaultSaga);
}
