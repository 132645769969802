import { LoadingOutlined } from "@ant-design/icons"
import { Modal, Spin, Space, Typography } from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AsyncStates, projectType } from "src/constants"
import { StoreState } from "src/store/configureStore"
import Plot from 'react-plotly.js'
import useTranslate from "src/utils/useTranslate"


const plotLayout: any = {
  xaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Time',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
  yaxis: {
    showspikes: true,
    spikemode: 'toaxis',
    title: {
      text: 'Temperature',
      font: {
        family: "Courier New, monospace",
        size: 18,
        color: "#11111",
      },
    },
  },
}


export const CompareGraphs = ({ compareGraphsModalvisible, setCompareGraphsModalvisible, selected }: any) => {

  const project_type = useSelector((state: StoreState) => state.login.loginResponse?.project_type)
  const { plotsData, plotsDataStatus } = useSelector((state: StoreState) => state.workOrderDetails)
  const { ceslurePlotsData, ceslurePlotsStatus } = useSelector((state: StoreState) => state.celsureWo)
  const [t] = useTranslate()

  const [plots, setPlots] = useState<any>({})

  useEffect(() => {
    if (ceslurePlotsStatus === AsyncStates.SUCCESS || plotsDataStatus === AsyncStates.SUCCESS) {
      if (project_type === projectType.celsure) {
        setPlots(ceslurePlotsData)
      } else {
        setPlots(plotsData?.line)
      }
    }
  }, [ceslurePlotsStatus, plotsDataStatus, ceslurePlotsData, plotsData, project_type])


  return (
    <Modal title={"Compare Graphs"}
      okText={t("common.ok")}
      cancelText={t("common.cancel")}
      visible={compareGraphsModalvisible}
      onCancel={() => setCompareGraphsModalvisible(false)}
      width={1000} footer={null} >
      <Space direction="vertical" size="small" style={{ width: "100%", textAlign: "center" }}>
        {!!selected.size ?
          <Spin spinning={plotsDataStatus === AsyncStates.LOADING || ceslurePlotsStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
            {Object.entries(plots || {}).map(([key, value]: any) => (
              <Plot
                layout={{
                  title: key, showlegend: true, hovermode: 'closest', ...plotLayout
                }}
                data={[{
                  x: value?.['time'] || [] as any,
                  y: value?.['trial_data'] || [] as any,
                  type: 'scatter',
                  name: 'Temperature',
                },
                {
                  x: value?.['time'] || [] as any,
                  y: value?.['bath'] || [] as any,
                  type: 'scatter',
                  name: 'Bath',
                },
                ]}
              />
            ))}
          </Spin> : <Typography.Text strong>{t("report.trialsNotSelected")}</Typography.Text>}
      </Space>
    </Modal>
  )
}