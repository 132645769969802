import { geekblue } from '@ant-design/colors'
import { LoadingOutlined } from '@ant-design/icons'
import { Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Tag, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { celsureInventoryRequest } from 'src/store/actions/celsure'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

const { Text } = Typography

const pcmFields = {
    "phase_transition_temperature_melting": "Phase transition temperature Melting (°C)",
    "phase_transition_temperature_freezing": "Phase transition temperature Freezing (°C)",
    "phase_transition_temperature_nucleation": "Phase transition temperature Nucleation (°C)",
    "enthalpy_melting": "Latent heat/enthalpy Melting (kJ/kg)",
    "enthalpy_freezing": "Latent heat/enthalpy Freezing (kJ/kg)",
    "density_liquid": "Density Liquid (kg/m3)",
    "density_solid": "Density Solid (kg/m3)",
    "specific_heat_liquid": "Specific heat Liquid (kJ/kgK)",
    "specific_heat_solid": "Specific heat Solid (kJ/kgK)",
    "thermal_conductivity_liquid": "Thermal conductivity Liquid (W/mK)",
    "thermal_conductivity_solid": "Thermal conductivity Solid (W/mK)"
}

export const InventoryModal = ({ modalVisible, setModalVisible }: any) => {
    const dispatch = useDispatch()
    const { celsureInventoryStatus, celsureDisplayNamesData } = useSelector((state: StoreState) => state.celsure)
    const { user_role: userRole } = useSelector((state: StoreState) => state.login.loginResponse)

    const [insulationMaterialType, setInsulationMaterialType] = useState<{ oldValue: string, newValue: string }>({ oldValue: "", newValue: "" })
    const [insulationMaterialData, setInsulationMaterialData] = useState<any[]>([])
    const [pcmType, setPcmType] = useState<{ oldValue: string, newValue: string }>({ oldValue: "", newValue: "" })
    const [property, setProperty] = useState<string>("")
    const [form] = Form.useForm()

    const closeModal = useCallback(() => {
        form.resetFields()
        setProperty("")
        setPcmType({ oldValue: "", newValue: "" })
        setInsulationMaterialType({ oldValue: "", newValue: "" })
        setModalVisible({ visible: false, record: {} })
    }, [form, setModalVisible, setProperty])

    useEffect(() => {
        if (celsureInventoryStatus === AsyncStates.SUCCESS) {
            closeModal()
        }
    }, [closeModal, celsureInventoryStatus])

    useEffect(() => {
        if (modalVisible.visible) {
            const { identifier } = modalVisible
            form.setFieldsValue({ values: ["insulation_type", "pcm_type"].includes(identifier) ? Object.values(celsureDisplayNamesData?.[identifier] || {}).map((data: any) => data.name) : Object.values(celsureDisplayNamesData?.[identifier] || {}) })
            setProperty(identifier)
        }
    }, [modalVisible, celsureDisplayNamesData, form])

    useEffect(() => {
        if (property === "insulation_type") {
            setInsulationMaterialData(Object.values(celsureDisplayNamesData?.[property] || {}))
        }
    }, [celsureDisplayNamesData, property])

    const typeValueHandler = useCallback((changedFields: any, allFields: any) => {
        if (property === "insulation_type" && insulationMaterialData?.find(({ name }) => name === insulationMaterialType)) {
            const { name: insulationTypeName, meta: insulationTypeMeta } = insulationMaterialData?.find(({ name }) => name === insulationMaterialType)
            setInsulationMaterialData([...insulationMaterialData?.filter(({ name }) => name !== insulationMaterialType), { name: insulationTypeName, meta: { ...insulationTypeMeta, [changedFields[0].name[0]]: changedFields[0].value } }])
        }
    }, [insulationMaterialData, insulationMaterialType, property])

    const typeSelectionHandler = useCallback((event: any) => {
        if (!insulationMaterialData?.find(({ name }) => name === insulationMaterialType)) setInsulationMaterialData([...insulationMaterialData, { name: event, meta: null }])
        if (property === "insulation_type") setInsulationMaterialType({ oldValue: event, newValue: event })
        if (property === "pcm_type") setPcmType({ oldValue: event, newValue: event })
    }, [insulationMaterialData, insulationMaterialType, property])

    const getValuesData = useMemo(() => {
        return ["insulation_type", "pcm_type"].includes(property) ? Object.values(celsureDisplayNamesData?.[property] || {})?.map(({ name }: any) => ({ label: name, value: name })) : Object.values(celsureDisplayNamesData?.[property] || {})?.map((res: any) => (
            { value: res, label: res }
        ))
    }, [celsureDisplayNamesData, property])

    const tagRender = (props: CustomTagProps) => {
        const { label, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault()
            event.stopPropagation()
        }
        return (
            <Tag
                color='blue'
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={(event) => {
                    onClose(event)
                    if (property === "insulation_type") {
                        setInsulationMaterialType({ oldValue: "", newValue: "" })
                        setInsulationMaterialData([...insulationMaterialData.filter(({ name }) => name !== label)])
                    }
                    if (property === "pcm_type") {
                        setPcmType({ oldValue: "", newValue: "" })
                    }
                }}
                style={{ marginRight: 3, cursor: 'pointer' }}
                onClick={() => {
                    if (property === "insulation_type") {
                        setInsulationMaterialType({ oldValue: label, newValue: label })
                        form.setFieldsValue({
                            ...insulationMaterialData?.find(({ name }) => name === label)?.meta || {
                                "r_value": null,
                                "mass_density": null,
                                "specific_heat_capacity": null,
                                "thermal_conductivity": null,
                                "phase": null,
                                "color": null
                            }
                        })
                    }
                    if (property === "pcm_type") {
                        setPcmType({ oldValue: label, newValue: label })
                        form.setFieldsValue({
                            ...Object.values(celsureDisplayNamesData?.[property])?.find(({ name }: any) => name === label)?.meta || {
                                "phase_transition_temperature_melting": null,
                                "phase_transition_temperature_freezing": null,
                                "phase_transition_temperature_nucleation": null,
                                "enthalpy_melting": null,
                                "enthalpy_freezing": null,
                                "density_liquid": null,
                                "density_solid": null,
                                "specific_heat_liquid": null,
                                "specific_heat_solid": null,
                                "thermal_conductivity_liquid": null,
                                "thermal_conductivity_solid": null
                            }
                        })
                    }
                }}
            >
                {label}
            </Tag>
        )
    }

    const onSubmit = (values: any) => {
        if (property === "insulation_type") {
            insulationMaterialData.find(({ name }) => name === insulationMaterialType?.oldValue).meta = {
                "r_value": values?.r_value,
                "mass_density": values?.mass_density,
                "specific_heat_capacity": values?.specific_heat_capacity,
                "thermal_conductivity": values?.thermal_conductivity,
                "phase": values?.phase,
                "color": values?.color
            }
            insulationMaterialData.find(({ name }) => name === insulationMaterialType?.oldValue).name = insulationMaterialType.newValue
            dispatch(celsureInventoryRequest({ type: property, values: insulationMaterialData }))

        } else if (property === "pcm_type") {
            let data: any = form.getFieldsValue().values.map((pcmValue: string) => Object.values(celsureDisplayNamesData?.[property]).find(({ name }: any) => name === pcmValue) || { name: pcmValue, meta: null })
            let formData: any = Object.entries(form.getFieldsValue())
            formData.shift()
            formData = Object.fromEntries(formData)
            let apiPayload = {
                "phase_transition_temperature_melting": formData?.phase_transition_temperature_melting || null,
                "phase_transition_temperature_freezing": formData?.phase_transition_temperature_freezing || null,
                "phase_transition_temperature_nucleation": formData?.phase_transition_temperature_nucleation || null,
                "enthalpy_melting": formData?.enthalpy_melting || null,
                "enthalpy_freezing": formData?.enthalpy_freezing || null,
                "density_liquid": formData?.density_liquid || null,
                "density_solid": formData?.density_solid || null,
                "specific_heat_liquid": formData?.specific_heat_liquid || null,
                "specific_heat_solid": formData?.specific_heat_solid || null,
                "thermal_conductivity_liquid": formData?.thermal_conductivity_liquid || null,
                "thermal_conductivity_solid": formData?.thermal_conductivity_solid || null
            }
            if (!!pcmType?.newValue) {
                data.find(({ name }: any) => name === pcmType?.oldValue).meta = apiPayload
                data.find(({ name }: any) => name === pcmType?.oldValue).name = pcmType?.newValue
            }
            dispatch(celsureInventoryRequest({ type: property, values: data }))
        } else {
            dispatch(celsureInventoryRequest({ type: property, values: values?.values }))
        }
    }


    return (
        <Modal title={`Edit - ${property?.split("_").map((res: string) => res?.[0]?.toUpperCase() + res?.slice(1)).join(" ")}`} width={800} open={modalVisible.visible} footer={null}
            onCancel={() => closeModal()} maskClosable={false}>
            <Spin indicator={<LoadingOutlined />} spinning={celsureInventoryStatus === AsyncStates.LOADING}>
                <Form onFinish={onSubmit} layout="vertical" style={{ padding: 10 }} form={form} onFieldsChange={typeValueHandler} >
                    <Form.Item label={"Values"} name="values">
                        <Select mode="tags" tagRender={tagRender} onSelect={typeSelectionHandler} options={getValuesData} />
                    </Form.Item>
                    {property === "insulation_type" && !!insulationMaterialType?.oldValue && (<>
                        <Space style={{ padding: 10, background: geekblue[1], borderRadius: "5px" }}>
                            <Text editable={{ onChange: (e: any) => setInsulationMaterialType((prevState: any) => ({ ...prevState, newValue: e })) }} type="secondary">{insulationMaterialType?.newValue}</Text>
                        </Space>
                        <Form.Item name="r_value" label="R-Value"  >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name="mass_density" label="Mass Density (kg/m3)"  >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name="specific_heat_capacity" label="Specific Heat Capacity (J/(Kg K))"  >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name="thermal_conductivity" label="Thermal Conductivity (W/(m-K))"  >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name={"phase"} label={"Phase"}
                        >
                            <Select
                                showSearch
                                options={[{ value: 'solid', label: 'Solid' }, { value: 'liquid', label: 'Liquid' }]}
                            />
                        </Form.Item>
                        <Form.Item label="Color" name="color" >
                            <Input />
                        </Form.Item>
                    </>)}
                    {property === "pcm_type" && !!pcmType?.oldValue && <>
                        <Space style={{ padding: 10, background: geekblue[1], borderRadius: "5px" }}>
                            <Text editable={{ onChange: (e: any) => setPcmType((prevState: any) => ({ ...prevState, newValue: e })) }} type="secondary">{pcmType?.newValue}</Text>
                        </Space>
                        {Object.entries(pcmFields || {}).map(([key, label]) =>
                            <Form.Item name={key} label={label}  >
                                <InputNumber readOnly={userRole !== "admin"} />
                            </Form.Item>
                        )}
                    </>}
                    <Row justify='end' style={{ marginTop: 20 }}>
                        <StyledButton type="primary" htmlType="submit">
                            {"Save"}
                        </StyledButton>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

