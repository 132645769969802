import IngredientsBarChart from './IngredientsBarChart';
import MembersList from './MembersList';
import PieCharts from './PieCharts';
import ProjectTrials from './ProjectTrials';

const MainContent = () => {
  return (
    <div style={{
      height: '100%',
      width: '65%',
      overflowY: "auto",
      scrollbarWidth: 'none',
    }}>
        <PieCharts/>
        <ProjectTrials/>
        <IngredientsBarChart/>
        <MembersList/>
    </div>
  )
}

export default MainContent