import { Table, Space, Typography } from "antd";
import { ColumnType } from "antd/es/table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Headers } from "src/services/interface";
import InventoryService from "src/services/inventory/v3";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import jwtManager from "src/utils/jwtManager";
import { useQuery } from "src/utils/useQuery";
import useTranslate from "src/utils/useTranslate";
import { IngredientCompareChart } from "./sections/IngredientCompareChart";
import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { useValue } from "src/utils/useValue";
import { UnitSelection } from "src/components/UnitSelection";
import { apply } from 'json-logic-js'
import { StyledButton } from "src/styled_components/StyledButton";


const { Text } = Typography;

const CompareIngredients = () => {
  const [t] = useTranslate();

  const { getValue: getLocalValue } = useValue();

  const [ingredients, setIngredients] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);

  const [plots, setPlots] = useState<any[]>([]);

  let query = useQuery();
  const inventory_ids = useMemo(
    () => query.get("ids")?.split(",") ?? [],
    [query]
  );

  const defaultHeaders = useSelector(
    (state: StoreState) => state.defaultHeaders
  );

  // const [disableReportButton, setDisableReportButton] =
  //   useState<boolean>(false);

  // useEffect(() => {
  //   if (
  //     !!plots.length &&
  //     plots?.filter(({ selectedProperties }) => !!selectedProperties?.length)
  //       .length !== plots.length
  //   ) {
  //     setDisableReportButton(true);
  //   } else {
  //     setDisableReportButton(false);
  //   }
  // }, [plots]);

  const getPropertyOrParameterValue = useCallback(
    (param: any) => {
      const unit = !param.unit || param.unit === "-" ? "" : param.unit;
      const operator = param.operator || "";

      if (param.value_type === "categorical") {
        return `${param.value_str || ""} ${unit}`;
      } else if (
        param.value_type === "numerical" &&
        param.value_subtype === "single"
      ) {
        return `${getLocalValue(param.value) || ""} ${unit}`;
      } else if (
        param.value_subtype === "range" &&
        param.value &&
        param.value_max
      ) {
        return `${getLocalValue(param.value) || ""} - ${getLocalValue(param.value_max) || ""
          } ${unit}`;
      } else if (param.value_subtype === "operator") {
        return `${operator} ${param.value || ""} ${unit}`;
      } else {
        return "";
      }
    },
    [getLocalValue]
  );

  const generatePropertyKey = useCallback(
    (property: any, property_value: any) => {
      let propertyName = property?.property_name;
      if (property_value?.parameters && property_value?.parameters.length > 0) {
        propertyName += ` (${property_value.parameters
          .map((parameter: any) => getPropertyOrParameterValue(parameter))
          .filter((p: any) => p)
          .join(", ")})`;
      }
      if (!Array.isArray(property_value.unit)) {
        propertyName += `[${property_value.unit}]`;
      }

      return propertyName;
    },
    [getPropertyOrParameterValue]
  );
  const generatePropertyName = useCallback(
    (property: any, property_value: any) => {
      let propertyName = property?.property_name;
      if (property_value?.parameters && property_value?.parameters.length > 0) {
        propertyName += ` (${property_value.parameters
          .map((parameter: any) => getPropertyOrParameterValue(parameter))
          .filter((p: any) => p)
          .join(", ")})`;
      }

      return propertyName;
    },
    [getPropertyOrParameterValue]
  );

  const properties = useMemo(() => {
    return ingredients.reduce((prevIngredients, currIngredient) => {
      currIngredient.properties.forEach((category: any) => {
        category.properties.forEach((inventoryProperty: any) => {
          const ingredientData = {
            inventory_id: inventoryProperty.inventory_id,
            ingredient_category: currIngredient.category,
            ingredient_name: currIngredient.name,
          };
          const property_id = inventoryProperty.property_id;
          const propertyData = {
            property_id,
            property_category: category.category_name,
            property_name: inventoryProperty.property_name,
          };
          inventoryProperty.property_values.forEach((pv: any) => {
            const pvData = pv;
            const property_key = generatePropertyKey(propertyData, pv);
            const property_name_with_params = generatePropertyName(propertyData, pv);
            let propIndex = prevIngredients.findIndex(
              (prevIngredient: any) =>
                prevIngredient.property_id === inventoryProperty.property_id &&
                prevIngredient.method_name === pv.method_name &&
                prevIngredient.standard === pv.standard &&
                prevIngredient.property_key === property_key
            );
            if (propIndex === -1) {
              prevIngredients.push({ ...propertyData, property_name_with_params, property_key, ingredients: {} });
              propIndex = prevIngredients.length - 1;
            }
            if (prevIngredients[propIndex]["ingredients"][ingredientData.inventory_id]) {
              prevIngredients[propIndex]["ingredients"][ingredientData.inventory_id] = pvData;
            } else {
              prevIngredients[propIndex]["method_name"] = pv.method_name;
              prevIngredients[propIndex]["unit"] = pv.unit;
              prevIngredients[propIndex]["property_key"] = generatePropertyKey(propertyData, pv);
              prevIngredients[propIndex]["property_name_with_params"] = generatePropertyName(propertyData, pv);
              prevIngredients[propIndex]["standard"] = pv.standard;
              prevIngredients[propIndex]["specimen"] = pv.specimen;
              prevIngredients[propIndex]["ingredients"][ingredientData.inventory_id] = pvData;
            }
          });
        });
      });
      return prevIngredients.sort((a: any, b: any) => {
        const categoryComparison = a.property_category?.localeCompare(b.property_category);
        if (categoryComparison !== 0) return categoryComparison;
        return a.property_name?.localeCompare(b.property_name);
      });
    }, []);
  }, [ingredients, generatePropertyKey, generatePropertyName]);

  const unitList = useSelector((state: StoreState) => state.conversion.unitList)

  const ingredientOptions = useMemo(() => {
    return ingredients.map((p) => ({
      label: p.name,
      value: p.inventory_id,
    }));
  }, [ingredients]);

  const fetchIngredients = useCallback(async () => {
    setLoading(true);
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;
    const ingredientsResponse: any = await InventoryService.getIngredients(
      { inventory_ids },
      headers
    );
    setLoading(false);
    setIngredients(ingredientsResponse.data?.result?.data ?? []);
  }, [defaultHeaders, inventory_ids]);

  useEffect(() => {
    fetchIngredients();
  }, [fetchIngredients]);

  const exportIngredients = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setExporting(true);
    e.stopPropagation();
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;
    const exportResponse: any = await InventoryService.exportIngredients(
      { inventory_ids },
      headers
    );
    setExporting(false);
    window.open(exportResponse.data?.result?.data?.download_link, "_blank");
  };

  const [baseUnits, setBaseUnits] = useState([])

  useEffect(() => {
    const baseUnits = properties.map((p: any) => p.unit)
    setBaseUnits(baseUnits)
  }, [properties])

  const getValueBasedOnValueType = useCallback((param: any, propIndex: number) => {
    const unit = param?.unit;
    const formula = unitList.find((res: any) => res.name === unit)?.conversion_metric?.[`${unit}_to_${baseUnits[propIndex]}`]

    if (param?.value_type === "categorical") {
      return param.value_str

    } else if (param?.value_type === "numerical" && param?.value_subtype === "single") {
      const value = param?.value
      const convertedValue = formula && !!value && !isNaN(value) ? apply(formula, [value]) : value
      return getLocalValue(convertedValue)

    } else if (param?.value_subtype === "range" && param?.value && param?.value_max) {
      const value_max = param?.value_max
      const min_value = param?.value
      const convertedValueMax = formula && !!value_max && !isNaN(value_max) ? apply(formula, [value_max]) : value_max
      const convertedValueMin = formula && !!min_value && !isNaN(min_value) ? apply(formula, [min_value]) : min_value
      return `${getLocalValue(convertedValueMin)} - ${getLocalValue(convertedValueMax)}`

    } else if (param?.value_subtype === "operator") {
      const value = param?.value
      const convertedValue = formula && !!value && !isNaN(value) ? apply(formula, [value]) : value
      return `${param?.operator} ${getLocalValue(convertedValue)}`

    } else {
      return null;
    }
  }, [baseUnits, unitList, getLocalValue])

  const getPropertyIngredientMethodValue = useCallback(
    (inventory_id: string, property_id: string, method_name: string, standard: string, property_name_with_params: string) => {
      const propIndex = properties.findIndex(
        (p: any) =>
          p.property_id === property_id && p.method_name === method_name && p.standard === standard
          && p.property_name_with_params === property_name_with_params
      );
      if (propIndex !== -1) {
        const ingredientData = properties[propIndex]?.["ingredients"]?.[inventory_id]
        const ingredientValue = getValueBasedOnValueType(ingredientData, propIndex)

        return ingredientValue ?? "-";
      }
      return "-";
    }, [properties, getValueBasedOnValueType]);

  const ingredientColumns = useMemo(() => {
    return ingredients.map((ing: any) => {
      return {
        title: ing.name,
        key: `ingredient-${ing.inventory_id}`,
        render: (text: any, record: any) =>
          getPropertyIngredientMethodValue(
            ing.inventory_id,
            record.property_id,
            record.method_name,
            record.standard,
            record.property_name_with_params
          ),
        // fixed: "left",
        width: 100,
        align: "center" as any,
      } as ColumnType<any>;
    });
  }, [ingredients, getPropertyIngredientMethodValue]);

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <StyledPageHeader
        ghost={false}
        title={`${t("common.compare")} ${t("common.ingredients")}`}
        onBack={() => window.history.back()}
        extra={
          <Space size="large">
            <StyledButton
              loading={exporting}
              disabled={loading || exporting}
              icon={<FileExcelOutlined />}
              onClick={exportIngredients}
              size="small"
            >
              {t("formulations.button.exportResults")}
            </StyledButton>
          </Space>
        }
      />
      <Table
        loading={loading}
        bordered
        size="small"
        pagination={false}
        className="report-table"
        columns={[
          {
            title: t("common.category"),
            dataIndex: "property_category",
            filterSearch: true,
            sortDirections: ["ascend", "descend"],
            fixed: "left",
            width: 150,
            align: "center" as any,
          },
          {
            title: t("common.property"),
            dataIndex: "property_name_with_params",
            fixed: "left",
            width: 140,
            // align: "center" as any,
            filterSearch: true,
            sortDirections: ["ascend", "descend"],
          },
          {
            title: t("common.standard"),
            dataIndex: "standard",
            render: (text: any, record: any) => record.standard ?? "-",
            fixed: "left",
            width: 100,
            align: "center" as any,
            filterSearch: true,
            sortDirections: ["ascend", "descend"],
          },
          {
            title: t("inventory.specimen"),
            dataIndex: "specimen",
            render: (text: any, record: any) => record.specimen ?? "-",
            fixed: "left",
            width: 100,
            align: "center" as any,
            filterSearch: true,
            sortDirections: ["ascend", "descend"],
          },
          {
            title: t("common.unit"),
            dataIndex: "unit",
            fixed: "left",
            width: 120,
            align: "center" as any,
            render: (text: any, record: any, index: any) => {
              return <UnitSelection baseUnits={baseUnits} setbaseUnits={setBaseUnits} paramIndex={index} />
            }
          },
          ...ingredientColumns,
        ]}
        dataSource={properties}
        scroll={{ x: 150 }}
      />
      <StyledCard
        loading={loading}
        title={
          <Space>
            <Text>{t("common.buildAReport")}</Text>
          </Space>
        }
      >
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          {plots?.map((plot, index) => (
            <IngredientCompareChart
              allPlots={plots}
              plot={plot}
              setPlots={setPlots}
              key={plot?.id}
              chartIndex={index}
              properties={properties}
              ingredientOptions={ingredientOptions}
              isChartLoading={loading}
            />
          ))}
          <Space style={{ display: "flex", justifyContent: "center", gap: 10 }}>
            <StyledButton
              style={{ margin: "auto", display: "block" }}
              size="small"
              onClick={() => {
                setPlots((state) =>
                  state.concat([
                    {
                      id:
                        "report_section_" +
                        (Number(
                          state.slice(-1)[0]?.id.split("_").slice(-1)[0]
                        ) + 1 || 0),
                    },
                  ])
                );
              }}
              type="dashed"
              icon={<PlusOutlined />}
            >
              {t("common.addChart")}
            </StyledButton>
            {/* {!!plots?.length && (
              <Tooltip
                title={
                  disableReportButton
                    ? "Please add remaining chart details"
                    : t("common.generateReport")
                }
              >
                <StyledButton
                  size="small"
                  type="primary"
                  onClick={generateReport}
                  disabled={disableReportButton}
                >
                  {t("report.downloadReport")}
                </StyledButton>
              </Tooltip>
            )} */}
          </Space>
        </Space>
      </StyledCard>
    </Space>
  );
};

export default CompareIngredients;
