import { put, takeLatest, call, select } from "redux-saga/effects"

import jwtManager from "src/utils/jwtManager"
import { updateUserPreferencesRequest, userPreferencesFailure, userPreferencesRequest, userPreferencesSuccess } from "../actions/userPreferences"
import { getUserPreferencesApi, updateUserPreferencesApi } from "src/services/userPreferences"
import { notification } from "antd"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"

type progressAction = {
    type: string
    payload: any
}

function* fetchUserPreferences(action: progressAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(getUserPreferencesApi, action.payload, headers);

        if (result.data && Object.keys(result.data).length > 0) yield put(userPreferencesSuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(userPreferencesFailure(err));
        notification.error({ message: err });
    }
}

function* updateUserPreferences(action: progressAction): Generator<any, any, any> {
    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current
    );
    try {
        const { defaultHeaders } = yield select((state) => state);
        const headers = { ...defaultHeaders, token: jwtManager.getToken() };
        const {
            data: { result },
        } = yield call(updateUserPreferencesApi, action.payload, headers);

        if (result.data && Object.keys(result.data).length > 0) yield put(userPreferencesSuccess(result.data));
    } catch (error) {
        const err = messages[ln].internal_server_error;
        yield put(userPreferencesFailure(err));
        notification.error({ message: err });
    }
}

export default function* rootSaga(): Generator<any, any, any> {
    yield takeLatest(userPreferencesRequest, fetchUserPreferences)
    yield takeLatest(updateUserPreferencesRequest, updateUserPreferences)
}
