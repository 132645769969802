import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Modal,
    Tabs,
    Form,
    Input,
    Row,
    message,
    Spin,
    Select,
    Popconfirm,
    Typography,
    Space,
    Card,
    Collapse,
} from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'
import { CopyOutlined, ExclamationCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { orange } from '@ant-design/colors'
import useTranslate from 'src/utils/useTranslate'
import { useData } from '../../utils/useData'
import { CellProps, DynamicDataSheetGrid, floatColumn, keyColumn, textColumn } from 'react-datasheet-grid'
import { deleteProcessingProfilesRequest, saveProcessingProfilesRequest } from 'src/store/actions/workOrderDetails'
import { ProfileGraph } from '../ProfileGraph'
import { TextComponent } from '../../TextComponent'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'
import { StyledButton } from 'src/styled_components/StyledButton'

const { Text } = Typography
const { TabPane } = Tabs
const { Option } = Select


export const AddProfiles = ({ profileModalVisible, setProfileModalVisible }: any) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()

    const { saveProcessingProfilesStatus, deleteProcessingProfilesStatus, processingProfilesList, processingProfilesLoading } = useSelector((state: StoreState) => state.workOrderDetails)
    const [createData, setcreateData] = useState<any[]>([])
    const [editData, setEditData] = useState<any[]>([])
    const [editingState, setEditingState] = useState<boolean>(false)
    const [form] = Form.useForm()
    const [type, setType] = useState("create")
    const initialCostingData = useMemo(() => [
        { parameter: "BOM (kg)", value: "" },
        { parameter: "Cost/kg of BOM", value: "" },
        { parameter: "Factory Yield (%)", value: "" },
        { parameter: "Reference Dry Matter Yield (%)", value: "" },
        { parameter: "Reference Yield/Batch", value: "" },
        { parameter: "Total Conversion Cost/Ton", value: "" },
        { parameter: "Total RM Cost/Batch", value: "" },
        { parameter: "Reference Cost of MBE/ton", value: "" },
        { parameter: "Total Solids", value: "" },
    ], [])
    const [createCosting, setCreateCosting] = useState<any>(initialCostingData)
    const [editCosting, setEditCosting] = useState<any>(initialCostingData)

    const costingColumns = [
        {
            ...keyColumn("parameter", textColumn),
            title: "Parameter",
            component: (props: CellProps) => {
                return <TextComponent {...props} id={"parameter"} disabled={true} />
            }
        },
        {
            ...keyColumn("value", floatColumn),
            title: "Value"
        },
    ]

    const resetData = useCallback(() => {
        setcreateData([])
        setEditData([])
        form.resetFields()
        setEditingState(false)
        setCreateCosting(initialCostingData)
        setEditCosting(initialCostingData)
    }, [form, initialCostingData])

    useEffect(() => {
        if (saveProcessingProfilesStatus === AsyncStates.SUCCESS || deleteProcessingProfilesStatus === AsyncStates.SUCCESS) {
            resetData()
        }
    }, [saveProcessingProfilesStatus, deleteProcessingProfilesStatus, resetData])

    const selectProfile = (e: any) => {
        const currentProfile = processingProfilesList?.find((res: any) => res.name === e)
        setEditData(currentProfile?.data || [])
        setEditCosting(currentProfile?.costing || initialCostingData)
        form.setFieldsValue({ name_select_edit: e })
    }

    const submitForm = (values: any) => {
        if ((!createData.length && type === "create") || (!editData.length && type === "edit")) {
            message.error("Add time & temperature values for the profile")
            return
        }
        dispatch(saveProcessingProfilesRequest(type === "create" ? { name: values.name_input, data: createData, costing: createCosting } :
            {
                profile_id: processingProfilesList?.find((res: any) => res.name === values?.name_select)?.profile_id,
                name: values?.name_select_edit,
                data: editData,
                costing: editCosting
            }))
    }

    const { selectedRows, copyAndAddRow, removeRows, onRowsChange, columns, addRow } = useData({
        data: type === "create" ? createData : editData, setData: type === "create" ? setcreateData : setEditData, type: "profile",
    })

    const profileForm = () => (
        <Spin
            spinning={saveProcessingProfilesStatus === AsyncStates.LOADING || deleteProcessingProfilesStatus === AsyncStates.LOADING || processingProfilesLoading === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}>
            <Space direction="vertical" size="large" style={{ width: '100%' }} >
                <Form onFinish={submitForm} form={form} style={{ marginTop: 20 }} requiredMark={false} onFieldsChange={() => {
                    if (!editingState) setEditingState(true)
                }}>
                    {type === "create" ?
                        <Form.Item name="name_input" label={"Profile Name"} rules={[{ required: true, message: "required" }]}
                        required 
				        tooltip={requiredFieldStar}
                        >
                            <Input />
                        </Form.Item> :
                        <>
                            <Form.Item name="name_select" label={t("common.select")} rules={[{ required: true, message: "required" }]}
                            required 
				            tooltip={requiredFieldStar}
                            >
                                <Select placeholder="Select Profile" onSelect={selectProfile}>
                                    {processingProfilesList?.map((res: any) => (
                                        <Option key={res.profile_id} value={res.name}>{res.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="name_select_edit" label={"Profile"} rules={[{ required: true, message: "required" }]}
                            required 
				            tooltip={requiredFieldStar}
                            >
                                <Input />
                            </Form.Item>
                        </>
                    }
                    <Card type="inner" style={{ marginBottom: 20 }} extra={<Space size="small">
                        <StyledButton onClick={addRow} icon={<PlusOutlined />}>{t("common.addRow")}</StyledButton>
                        <StyledButton disabled={!selectedRows.length} type="dashed" onClick={copyAndAddRow} icon={<CopyOutlined />}>{t("common.copyAndAddRow")}</StyledButton>
                        <Popconfirm disabled={!selectedRows.length} title={"Sure to remove selected row(s) ?"} onConfirm={() => removeRows()}>
                            <StyledButton disabled={!selectedRows.length} danger icon={<StyledDeleteIcon />}>{t("datasheet.deletRows")} </StyledButton>
                        </Popconfirm>
                    </Space>}>
                        <div style={{ width: "100%", overflow: "auto" }}>
                            <DynamicDataSheetGrid
                                lockRows
                                value={type === "create" ? createData : editData}
                                onChange={onRowsChange}
                                columns={columns as any}
                            />
                        </div>
                    </Card>
                    {(!!createData?.length || !!editData?.length) &&
                        <Collapse defaultActiveKey={["temperature-plot"]} style={{ marginBottom: 20 }}>
                            <Collapse.Panel key="temperature-plot" header="Time vs Temperature Graph">
                                <Row justify="center">
                                    <ProfileGraph tableData={type === "create" ? createData : editData} />
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    }
                    <Card type="inner" title={"Costing details"} style={{ marginBottom: 20 }}>
                        <div style={{ width: "100%", overflow: "auto" }}>
                            <DynamicDataSheetGrid
                                lockRows
                                value={type === "create" ? createCosting : editCosting}
                                onChange={(e) => type === "create" ? setCreateCosting(e) : setEditCosting(e)}
                                columns={costingColumns as any}
                            />
                        </div>
                    </Card>
                    <Row justify='end'>
                        <Space>
                            <StyledButton type='primary' htmlType='submit'>{t("common.submit")}</StyledButton>
                            <Popconfirm title={"Clear data?"} onConfirm={resetData}>
                                <StyledButton>{t("compare.clear")}</StyledButton>
                            </Popconfirm>
                        </Space>
                    </Row>
                </Form>
            </Space>
        </Spin>

    )


    return (
        <Modal width={800}
            footer={""}
            title={"Processing profiles"}
            visible={profileModalVisible}
            maskClosable={false}
            onCancel={() => {
                const reset = () => {
                    resetData()
                    setProfileModalVisible(false)
                }
                if (editingState) {
                    Modal.confirm({
                        title: t("common.confirm"),
                        icon: <ExclamationCircleOutlined />,
                        content: 'Unsaved Changes. Discard?',
                        onOk: () => reset()
                    })
                } else reset()
            }}>
            <Tabs onChange={setType} tabBarExtraContent={editingState ?
                <Text style={{ color: orange.primary }}>{t("common.unsavedChanges")}</Text> : ""}>
                <TabPane tab={t('common.create')} key="create">
                    {profileForm()}
                </TabPane>
                <TabPane tab={`${t('common.edit')}/${t('common.delete')}`} key="edit">
                    <Row justify='end'>
                        <Popconfirm title={`${t('istaProfiles.deleteProfile')}?`}
                            disabled={!form.getFieldsValue()?.name_select}
                            onConfirm={() => dispatch(deleteProcessingProfilesRequest({ profile_id: [processingProfilesList?.find((res: any) => res.name === form.getFieldsValue()?.name_select)?.profile_id] }))}>
                            <StyledButton danger disabled={!form.getFieldsValue()?.name_select} icon={<StyledDeleteIcon />}>{t("istaProfiles.deleteProfile")}</StyledButton>
                        </Popconfirm>
                    </Row>
                    {profileForm()}
                </TabPane>
            </Tabs>
        </Modal>
    )
}
