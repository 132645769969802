import { Space } from "antd";
import { motion } from "framer-motion"
import { StyledCard } from "src/styled_components/StyledCard"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import useTranslate from "src/utils/useTranslate"
import WorkorderForm from "./WorkorderForm";
import FilterForm from "./FilterForm";
import { Heatmap } from "./Heatmap";
import { Analysis } from "./Analysis";

const MultistageHeatmap = () => {

  // Other utility hooks
  const [t] = useTranslate()

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: "just" }}
    >
      <Space direction="vertical" size="large" style={{ width: "100%", gap:0 }}>
        <StyledPageHeader
          ghost={false}
          title={`${t("aiEngine.multiStage")} ${t("common.heatmap")}`}
        ></StyledPageHeader>

        <StyledCard bodyStyle={{ padding: "0px 16px", display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <WorkorderForm />
          <FilterForm />
          <Heatmap />
          <Analysis />
        </StyledCard>
      </Space>
    </motion.div>
  )
}

export default MultistageHeatmap
