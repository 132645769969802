import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates } from "src/constants"
import { setCelsureEditingTrialsState } from "../store/actions/celsure"
import { StoreState } from "../store/configureStore"
import { keyColumn, floatColumn, CellProps, textColumn } from "react-datasheet-grid"
import { Divider, InputNumber, Select, Space } from "antd"
import Checkbox from "antd/lib/checkbox/Checkbox"
import "src/styles/Global.scss"
import useTranslate from "./useTranslate"
import { TextComponent } from "src/components/Datasheet/TextComponent"
import { StyledButton } from "src/styled_components/StyledButton"

const { Option } = Select

interface useDataSheetGridProps {
	data: Array<any>
	setData: React.Dispatch<React.SetStateAction<Array<any>>>
	type: string
	setModalEditingState?: React.Dispatch<React.SetStateAction<boolean>>
	moveObj?: (value: string) => void
	setModalVisible?: React.SetStateAction<any>
}

interface useDataSheetGridState {
	copyAndAddRow: () => void
	onRowsChange: (data: Array<any>) => void
	removeRows: () => void
	selectedRows: Array<any>
	columns: Array<any>
	addRow: () => void
}

export const useDataSheetGrid = ({
	data,
	setData,
	type,
	setModalEditingState,
	moveObj,
	setModalVisible
}: useDataSheetGridProps): useDataSheetGridState => {
	const dispatch = useDispatch()
	const {
		celsureEditingTrialsState,
		celsureDisplayNamesData,
		celsureDisplayNamesStatus,
	} = useSelector((state: StoreState) => state.celsure)
	const [selectedRows, setSelectedRows] = useState<any>([])
	const [t] = useTranslate()

	const generateOptionalFields = useCallback(
		(identifier: any) =>
			Object.values(celsureDisplayNamesData?.[identifier] || {}).map(
				(value: any, index: number) => ((identifier === "insulation_type" || identifier === "pcm_type") ? { value: value?.name, key: value?.name + String(index) } : { value, key: value + String(index) })
			),
		[celsureDisplayNamesData]
	)

	const dropDownMenu = useCallback((menu: any, identifier: string) => (
		<div>
			{menu}
			<Divider style={{ margin: "4px 0" }} />
			<StyledButton
				onClick={() => setModalVisible({ visible: true, identifier })}
				type="link"
			>
				{t("common.newCustomParameter")}
			</StyledButton>
		</div>
	), [t, setModalVisible])

	useEffect(() => {
		setSelectedRows(
			data?.filter((res: any) => res?.select)?.map((row: any) => row.id) || []
		)
	}, [data])

	const copyAndAddRow = () => {
		setData((prevState: any) => {
			const duplicateRows = prevState
				.filter((res: any) => selectedRows.includes(res.id))
				.reduce(
					(array: any, element: any, index: any) => [
						...array,
						{ ...element, id: prevState.length + index + 1 },
					],
					[]
				)
			return [...prevState, ...duplicateRows]
		})
	}

	const removeRows = () => {
		setData((prevState: any) => {
			const newState = prevState.filter(
				(res: any) => !selectedRows.includes(res.id)
			)
			newState.forEach((res: any, key: any) => {
				res.id = key + 1
			})
			return newState
		})
		setSelectedRows([])
		if (!celsureEditingTrialsState) {
			dispatch(setCelsureEditingTrialsState(true))
		}
		if (setModalEditingState) {
			setModalEditingState(true)
		}
	}

	const onRowsChange = (data: any) => {
		setData(
			data.map((record: any) => {
				// const pcmType = pcm.pcm_type?.toUpperCase() as PCM["pcm_type"]
				if (Object.keys(record).includes("size_type")) {
					const enthalpy_melting = Object.entries(celsureDisplayNamesData.pcm_type || {})
						.find(([key, value]: any) => key === record.size_type || value?.name === record.size_type)?.[1]?.meta?.enthalpy_melting
					const enthalpy = !record.size_type || !record.amount || !enthalpy_melting
						? 0 : (enthalpy_melting ?? 0) * record.amount
					return { ...record, enthalpy }
				} else {
					return record
				}
			})
		)
		if (!celsureEditingTrialsState) {
			dispatch(setCelsureEditingTrialsState(true))
		}
		if (setModalEditingState) {
			setModalEditingState(true)
		}
	}

	const addRow = useCallback(() => {
		if (!celsureEditingTrialsState) {
			dispatch(setCelsureEditingTrialsState(true))
		}
		switch (type) {
			case "pcm":
				setData((prevState) => [
					...prevState,
					{
						select: false,
						id: prevState.length + 1,
						pcm_type: "",
						size_type: "",
						cells_no: "",
						length: "",
						width: "",
						thickness: "",
						position: "",
						quantity: "",
						initial_temperature: "",
						thawing: "",
						ambient_temp: "",
					},
				])
				break

			case "water":
				setData((prevState) => [
					...prevState,
					{
						select: false,
						id: prevState.length + 1,
						size_type: "",
						position: "",
						length: "",
						width: "",
						thickness: "",
						amount: "",
						enthalpy: "",
						initial_temperature: "",
					},
				])
				break

			case "insulation_sheets":
				setData((prevState) => [
					...prevState,
					{
						select: false,
						id: prevState.length + 1,
						material_type: "",
						position: "",
						length: "",
						width: "",
						thickness: "",
						material_unit: "mm",
					},
				])
				break

			case "ambient":
				if (setModalEditingState) {
					setModalEditingState(true)
				}
				setData((prevState) => [
					...prevState,
					{
						select: false,
						id: prevState.length + 1,
						ambient_time_value: "",
						ambient_time_unit: "hours",
						ambient_temperature_value: "",
						ambient_temperature_unit: "degC",
					},
				])
				break

			case "predict_backup_time":
				setData((prevState) => [
					...prevState,
					{
						"select": false,
						"id": prevState.length + 1,
						"Box-length": "",
						"Box-Width": "",
						"Box-Height": "",
						"Amb Temp": "",
						"PLB Temp": "",
						"Latent heat": "",
						"k-Insulation": "",
						"Insulation thickness": "",
						"PCM Quantity": "",
					},
				])
				break
			case "predict_pcm_quantity":
				setData((prevState) => [
					...prevState,
					{
						"select": false,
						"id": prevState.length + 1,
						"Box-length": "",
						"Box-Width": "",
						"Box-Height": "",
						"Amb Temp": "",
						"PLB Temp": "",
						"Latent heat": "",
						"k-Insulation": "",
						"Insulation thickness": "",
						"Backup Time": "",
					},
				])
				break
			case "insulation_thickness":
				setData((prevState) => [
					...prevState,
					{
						"select": false,
						"id": prevState.length + 1,
						"value": "",
						"position": "",
					},
				])
				break
			case "outer_packaging":
				setData((prevState) => [
					...prevState,
					{
						"select": false,
						"id": prevState.length + 1,
						"value": "",
						"position": "",
					},
				])
				break
			case "tds_properties":
				setData((prevState) => [
					...prevState,
					{
						select: false,
						id: prevState.length + 1,
						property: "",
						value: "",
						test_method: "",
						test_conditions: ""
					},
				])
				break
			default:
				break
		}
	}, [dispatch, type, setData, celsureEditingTrialsState, setModalEditingState])

	const handleSelectAll = useCallback(
		(e: any) => {
			if (e.target.checked) {
				setSelectedRows(data?.map((res: any) => res?.id))
				setData(data?.map((ele: any) => Object.assign(ele, { select: true })))
			} else {
				setSelectedRows([])
				setData(data?.map((ele: any) => Object.assign(ele, { select: false })))
			}
		},
		[data, setData]
	)

	const columns = useMemo(() => {
		if (celsureDisplayNamesStatus === AsyncStates.SUCCESS || type === "tds_properties") {
			switch (type) {
				case "pcm":
					return [
						{
							title: (
								<Space>
									<Checkbox
										checked={!!selectedRows?.length}
										onClick={handleSelectAll}
									></Checkbox>
								</Space>
							),
							width: "0 0 5px",
							component: (row: CellProps<any>) => (
								<Checkbox
									style={{ margin: "0 auto" }}
									checked={row.rowData.select}
									onChange={(e) => {
										row.setRowData({ ...row.rowData, select: e.target.checked })
									}}
								/>
							),
						},
						{
							key: "pcm_type",
							title: "Pcm Type",
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={
										celsureDisplayNamesData?.pcm_type?.[
											data?.[row.rowIndex]?.pcm_type
										]?.name || data?.[row.rowIndex]?.pcm_type
									}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, pcm_type: value })
									}
									options={generateOptionalFields("pcm_type") as any}
								/>
							),
						},
						{
							key: "size_type",
							title: t("common.type"),
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={
										celsureDisplayNamesData?.size_type?.[
										data?.[row.rowIndex]?.size_type
										] || data?.[row.rowIndex]?.size_type
									}
									onChange={(value: any) =>
										row.setRowData({
											...row.rowData,
											size_type: value,
											cells_no: "",
										})
									}
									options={generateOptionalFields("size_type") as any}
								/>
							),
						},
						{
							title: "No. of Cells / Tab type",
							width: "100%",
							component: (row: any) => {
								if (row.rowData?.size_type?.toLowerCase() === "tab") {
									return (
										<Select
											showSearch
											bordered={false}
											style={{ width: "100%" }}
											value={
												celsureDisplayNamesData?.tab_type?.[
												data?.[row.rowIndex]?.cells_no
												] || data?.[row.rowIndex]?.cells_no
											}
											onChange={(value: any) =>
												row.setRowData({ ...row.rowData, cells_no: value })
											}
											options={generateOptionalFields("tab_type") as any}
										/>
									)
								} else {
									return (
										<InputNumber
											onChange={(value: any) =>
												row.setRowData({ ...row.rowData, cells_no: value })
											}
											value={data?.[row.rowIndex]?.cells_no}
										/>
									)
								}
							},
						},
						{
							...keyColumn("length", floatColumn),
							title: "Length(mm)",
							width: "100%",
						},
						{
							...keyColumn("width", floatColumn),
							title: "Width(mm)",
							width: "100%",
						},
						{
							...keyColumn("thickness", floatColumn),
							title: "Thickness(mm)",
							width: "100%",
						},
						{
							key: "position",
							title: t("common.position"),
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={data?.[row.rowIndex]?.position}
									onChange={(value: any) => {
										row.setRowData({ ...row.rowData, position: value })
										moveObj?.(value)
									}}
								>
									<Option value={"top"}>{"Top"}</Option>
									<Option value={"bottom"}>{"Bottom"}</Option>
									<Option value={"left"}>{"Left"}</Option>
									<Option value={"right"}>{"Right"}</Option>
									<Option value={"front"}>{"Front"}</Option>
									<Option value={"back"}>{t("common.back")}</Option>
								</Select>
							),
						},
						{
							...keyColumn("quantity", floatColumn),
							title: "Quantity(kg)",
						},
						{
							...keyColumn("initial_temperature", floatColumn),
							title: "Initial Temperature(°C)",
						},
						{
							...keyColumn("thawing", floatColumn),
							title: "Thawing Time(hours)",
						},
						{
							...keyColumn("ambient_temp", floatColumn),
							title: "Ambient Temperature(°C)",
						},
					]
				case "water":
					return [
						{
							title: (
								<Space>
									<Checkbox
										checked={selectedRows.length > 0}
										onClick={handleSelectAll}
									></Checkbox>
								</Space>
							),
							width: "0 0 5px",
							component: (row: CellProps<any>) => (
								<Checkbox
									style={{ margin: "0 auto" }}
									checked={row.rowData.select}
									onChange={(e) => {
										row.setRowData({ ...row.rowData, select: e.target.checked })
									}}
								/>
							),
						},
						{
							key: "size_type",
							title: t("common.type"),
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={
										celsureDisplayNamesData?.size_type?.[
										data?.[row.rowIndex]?.size_type
										] || data?.[row.rowIndex]?.size_type
									}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, size_type: value })
									}
									options={generateOptionalFields("pcm_type") as any}
									dropdownRender={(menu) => dropDownMenu(menu, "pcm_type")}
									dropdownStyle={{ minWidth: 300 }}
								/>
							),
							width: "100%",
						},
						{
							key: "material",
							title: t("common.material"),
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={
										celsureDisplayNamesData?.material?.[
										data?.[row.rowIndex]?.material
										] || data?.[row.rowIndex]?.material
									}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, material: value })
									}
									options={generateOptionalFields("material") as any}
								/>
							),
							width: "100%",
						},
						{
							key: "position",
							title: t("common.position"),
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={data?.[row.rowIndex]?.position}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, position: value })
									}
								>
									<Option value={"top"}>{"Top"}</Option>
									<Option value={"bottom"}>{"Bottom"}</Option>
									<Option value={"left"}>{"Left"}</Option>
									<Option value={"right"}>{"Right"}</Option>
									<Option value={"front"}>{"Front"}</Option>
									<Option value={"back"}>{t("common.back")}</Option>
								</Select>
							),
							width: "100%",
						},
						{
							...keyColumn("length", floatColumn),
							title: "Length(mm)",
							width: "100%",
						},
						{
							...keyColumn("width", floatColumn),
							title: "Width(mm)",
							width: "100%",
						},
						{
							...keyColumn("thickness", floatColumn),
							title: "Thickness(mm)",
							width: "100%",
						},
						{
							...keyColumn("amount", floatColumn),
							title: "Amount",
						},
						{
							...keyColumn("enthalpy", floatColumn),
							title: "PCM Quantity (kJ)",
							disabled: true,
						},
						{
							...keyColumn("initial_temperature", floatColumn),
							title: "Initial Temperature(°C)",
						},
					]
				case "insulation_sheets":
					return [
						{
							title: (
								<Space>
									<Checkbox
										checked={!!selectedRows?.length}
										onClick={handleSelectAll}
									></Checkbox>
								</Space>
							),
							width: "0 0 5px",
							component: (row: CellProps<any>) => (
								<Checkbox
									style={{ margin: "0 auto" }}
									checked={row.rowData.select}
									onChange={(e) => {
										row.setRowData({ ...row.rowData, select: e.target.checked })
									}}
								/>
							),
						},
						{
							key: "material_type",
							title: t("common.materialType"),
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={data?.[row.rowIndex]?.material_type}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, material_type: value })
									}
									options={generateOptionalFields("insulation_type")}
									dropdownRender={(menu) => dropDownMenu(menu, "insulation_type")}
									dropdownStyle={{ minWidth: 300 }}
								/>
							),
							width: "100%",
						},
						{
							key: "position",
							title: "Position",
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={data?.[row.rowIndex]?.position}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, position: value })
									}
								>
									<Option value={"top"}>{"Top"}</Option>
									<Option value={"bottom"}>{"Bottom"}</Option>
									<Option value={"left"}>{"Left"}</Option>
									<Option value={"right"}>{"Right"}</Option>
									<Option value={"front"}>{"Front"}</Option>
									<Option value={"back"}>{t("common.back")}</Option>
								</Select>
							),
							width: "100%",
						},
						{
							...keyColumn("length", floatColumn),
							title: "Length(mm)",
							width: "100%",
						},
						{
							...keyColumn("width", floatColumn),
							title: "Width(mm)",
							width: "100%",
						},
						{
							...keyColumn("thickness", floatColumn),
							title: "Thickness(mm)",
							width: "100%",
						},
					]
				case "ambient":
					return [
						{
							title: (
								<Space>
									<Checkbox
										checked={!!selectedRows?.length}
										onClick={handleSelectAll}
									></Checkbox>
								</Space>
							),
							width: "0 0 5px",
							component: (row: CellProps<any>) => (
								<Checkbox
									style={{ margin: "0 auto" }}
									checked={row.rowData.select}
									onChange={(e) => {
										row.setRowData({ ...row.rowData, select: e.target.checked })
									}}
								/>
							),
						},
						{
							...keyColumn("ambient_time_value", floatColumn),
							title: "Duration",
						},
						{
							...keyColumn("ambient_time_unit", floatColumn),
							title: t("common.unit"),
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={data?.[row.rowIndex]?.ambient_time_unit}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, ambient_time_unit: value })
									}
								>
									{celsureDisplayNamesData?.unit?.time.map((res: any) => (
										<Option value={res}>{res}</Option>
									))}
								</Select>
							),
						},
						{
							...keyColumn("ambient_temperature_value", floatColumn),
							title: t("common.temperature"),
						},
						{
							...keyColumn("ambient_temperature_unit", floatColumn),
							title: t("common.unit"),
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%" }}
									value={data?.[row.rowIndex]?.ambient_temperature_unit}
									onChange={(value: any) =>
										row.setRowData({
											...row.rowData,
											ambient_temperature_unit: value,
										})
									}
								>
									{celsureDisplayNamesData?.unit?.temperature.map(
										(res: any) => (
											<Option value={res}>{res}</Option>
										)
									)}
								</Select>
							),
						},
					]
				case "predict_backup_time":
					return [
						{
							title: (
								<Space>
									<Checkbox
										checked={!!selectedRows?.length}
										onClick={handleSelectAll}
									></Checkbox>
								</Space>
							),
							width: "0 0 5px",
							component: (row: CellProps<any>) => (
								<Checkbox
									style={{ margin: "0 auto" }}
									checked={row.rowData.select}
									onChange={(e) => {
										row.setRowData({ ...row.rowData, select: e.target.checked })
									}}
								/>
							),
						},
						{
							...keyColumn("Box-length", floatColumn),
							title: "Box Length(mm)",
							width: "100%",
						},
						{
							...keyColumn("Box-Width", floatColumn),
							title: "Box Width(mm)",
							width: "100%",
						},
						{
							...keyColumn("Box-Height", floatColumn),
							title: "Box Height(mm)",
							width: "100%",
						},
						{
							...keyColumn("PLB Temp", floatColumn),
							title: "PLB Temperature(°C)",
						},
						{
							...keyColumn("Amb Temp", floatColumn),
							title: "Ambient Temperature(°C)",
						},
						{
							...keyColumn("Latent heat", floatColumn),
							title: "Latent heat",
						},
						{
							...keyColumn("k-Insulation", floatColumn),
							title: "k-Insulation",
						},
						{
							...keyColumn("Insulation thickness", floatColumn),
							title: "Insulation thickness(mm)",
						},
						{
							...keyColumn("PCM Quantity", floatColumn),
							title: "PCM quantity(kg)",
						},
					]
				case "predict_pcm_quantity":
					return [
						{
							title: (
								<Space>
									<Checkbox
										checked={!!selectedRows?.length}
										onClick={handleSelectAll}
									></Checkbox>
								</Space>
							),
							width: "0 0 5px",
							component: (row: CellProps<any>) => (
								<Checkbox
									style={{ margin: "0 auto" }}
									checked={row.rowData.select}
									onChange={(e) => {
										row.setRowData({ ...row.rowData, select: e.target.checked })
									}}
								/>
							),
						},
						{
							...keyColumn("Box-length", floatColumn),
							title: "Box Length(mm)",
							width: "100%",
						},
						{
							...keyColumn("Box-Width", floatColumn),
							title: "Box Width(mm)",
							width: "100%",
						},
						{
							...keyColumn("Box-Height", floatColumn),
							title: "Box Height(mm)",
							width: "100%",
						},
						{
							...keyColumn("PLB Temp", floatColumn),
							title: "PLB Temperature(°C)",
						},
						{
							...keyColumn("Amb Temp", floatColumn),
							title: "Ambient Temperature(°C)",
						},
						{
							...keyColumn("Latent heat", floatColumn),
							title: "Latent heat",
						},
						{
							...keyColumn("k-Insulation", floatColumn),
							title: "k-Insulation",
						},
						{
							...keyColumn("Insulation thickness", floatColumn),
							title: "Insulation thickness(mm)",
						},
						{
							...keyColumn("Backup Time", floatColumn),
							title: "Backup time(hrs)",
						},
					]
				case "insulation_thickness":
					return [
						{
							title: (
								<Space>
									<Checkbox
										checked={!!selectedRows?.length}
										onClick={handleSelectAll}
									></Checkbox>
								</Space>
							),
							width: "0 0 5px",
							component: (row: CellProps<any>) => (
								<Checkbox
									style={{ margin: "0 auto" }}
									checked={row.rowData.select}
									onChange={(e) => {
										row.setRowData({ ...row.rowData, select: e.target.checked })
									}}
								/>
							),
						},
						{
							...keyColumn("value", floatColumn),
							title: "Insulation Thickness (mm)",
							width: "50px",
							textAlign: "center"
						},
						{
							key: "position",
							title: "Position",
							width: "50px",
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%", textAlign: "center" }}
									value={data?.[row.rowIndex]?.position}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, position: value })
									}
								>
									<Option value={"top"}>{"Top"}</Option>
									<Option value={"bottom"}>{"Bottom"}</Option>
									<Option value={"left"}>{"Left"}</Option>
									<Option value={"right"}>{"Right"}</Option>
									<Option value={"front"}>{"Front"}</Option>
									<Option value={"back"}>{t("common.back")}</Option>
								</Select>
							),
						},
					]
				case "outer_packaging":
					return [
						{
							title: (
								<Space>
									<Checkbox
										checked={!!selectedRows?.length}
										onClick={handleSelectAll}
									></Checkbox>
								</Space>
							),
							width: "0 0 5px",
							component: (row: CellProps<any>) => (
								<Checkbox
									style={{ margin: "0 auto" }}
									checked={row.rowData.select}
									onChange={(e) => {
										row.setRowData({ ...row.rowData, select: e.target.checked })
									}}
								/>
							),
						},
						{
							...keyColumn("value", floatColumn),
							title: "Packaging Thickness (mm)",
							width: "50px",
							textAlign: "center"
						},
						{
							key: "position",
							title: "Position",
							width: "50px",
							component: (row: any) => (
								<Select
									showSearch
									bordered={false}
									style={{ width: "100%", textAlign: "center" }}
									value={data?.[row.rowIndex]?.position}
									onChange={(value: any) =>
										row.setRowData({ ...row.rowData, position: value })
									}
								>
									<Option value={"top"}>{"Top"}</Option>
									<Option value={"bottom"}>{"Bottom"}</Option>
									<Option value={"left"}>{"Left"}</Option>
									<Option value={"right"}>{"Right"}</Option>
									<Option value={"front"}>{"Front"}</Option>
									<Option value={"back"}>{t("common.back")}</Option>
								</Select>
							),
						},
					]
				case "tds_properties":
					return [
						{
							...keyColumn("category", textColumn),
							title: t("common.category"),
							width: "100px",
							disabled: true,
							component: (props: any) => <TextComponent {...props} id={"category"} disabled={true} />
						},
						{
							...keyColumn("property", textColumn),
							title: t("common.property"),
							width: "100px",
							disabled: true,
							component: (props: any) => <TextComponent {...props} id={"property"} disabled={true} />
						},
						{
							...keyColumn("value", textColumn),
							title: t("common.value"),
							width: "50px",
							component: (props: any) => <><TextComponent {...props} id={"value"} />
								{props?.rowData?.unit ? <span style={{ marginRight: "2px" }}>{props.rowData.unit}</span> : null}</>
						},
						{
							...keyColumn("testing_method", textColumn),
							title: "Test Method",
							width: "100px",
							disabled: true,
							component: (props: any) => <TextComponent {...props} id={"testing_method"} disabled={true} />
						},
						{
							...keyColumn("testing_conditions", textColumn),
							title: "Test Conditions",
							width: "100px",
							// component: (props: any) => <TextComponent {...props} id={"testing_conditions"} />
						},
					]
				default:
					return []
			}
		} else return []
	}, [
		type,
		celsureDisplayNamesData,
		celsureDisplayNamesStatus,
		data,
		generateOptionalFields,
		handleSelectAll,
		selectedRows?.length,
		t,
		moveObj,
		dropDownMenu
	])

	return {
		copyAndAddRow,
		onRowsChange,
		removeRows,
		selectedRows,
		columns,
		addRow,
	}
}