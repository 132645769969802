
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`2-Stufen Vollfaktoriell`}</h1>
    <p>{`Die `}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Faktorieller_Versuch"
      }}>{`Faktoriellen Experimente`}</a>{`, bei denen alle Kombinationen der Stufen der Faktoren durchgeführt werden, werden in der Regel als Vollfaktorielle Experimente bezeichnet. Vollfaktorielle Experimente mit zwei Stufen werden auch als 2𝑘-Designs bezeichnet, wobei 𝑘 die Anzahl der in dem Experiment untersuchten Faktoren darstellt.`}</p>
    <p>{`Die 2𝑘-Designs gehören zu einer speziellen Kategorie der faktoriellen Experimente, bei denen alle Faktoren nur zwei Stufen haben. Die Tatsache, dass diese Designs nur Faktoren mit zwei Stufen enthalten und orthogonal sind, vereinfacht ihre Analyse erheblich, auch wenn die Anzahl der Faktoren groß ist.`}</p>
    <p><strong parentName="p">{`Anwendungsfälle:`}</strong></p>
    <ul>
      <li parentName="ul">{`2𝑘-Designs sind besonders nützlich in den frühen Phasen
experimenteller Arbeiten, wenn viele Faktoren untersucht werden müssen.`}</li>
      <li parentName="ul">{`Sie bieten die geringste Anzahl von Durchläufen, mit denen k
Faktoren untersucht werden können.`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;