import { createActions } from "redux-actions";

export enum FetchProjectListActionTypes {
  REQUEST = "FETCH_PROJECT_LIST_REQUEST",
  SUCCESS = "FETCH_PROJECT_LIST_SUCCESS",
  FAILURE = "FETCH_PROJECT_LIST_FAILURE",
}

export enum NewProjectActionTypes {
  REQUEST = "NEW_PROJECT_REQUEST",
  SUCCESS = "NEW_PROJECT_SUCCESS",
  FAILURE = "NEW_PROJECT_FAILURE",
}

export enum UpdateProjectActionTypes {
  REQUEST = "UPDATE_PROJECT_REQUEST",
  SUCCESS = "UPDATE_PROJECT_SUCCESS",
  FAILURE = "UPDATE_PROJECT_FAILURE",
  SET_EDIT_PROJECT_ID = "SET_EDIT_PROJECT_ID",
  CLEAR_EDIT_PROJECT_ID = "CLEAR_EDIT_PROJECT_ID",
  UPDATE_PROJECT_DATA = "UPDATE_PROJECT_DATA"
}

export enum DeleteProjectActionTypes {
  REQUEST = "DELETE_PROJECT_REQUEST",
  SUCCESS = "DELETE_PROJECT_SUCCESS",
  FAILURE = "DELETE_PROJECT_FAILURE",
}

export enum ProjectActionTypes {
  SET = "SET_PROJECT",
  SELECT_ALL_PROJECTS = "SELECT_ALL_PROJECTS"
}

export enum ProjectNotesActionTypes {
  CREATE_REQUEST = "CREATE_NOTE_REQUEST",
  CREATE_SUCCESS = "CREATE_NOTE_SUCCESS",
  CREATE_FAILURE = "CREATE_NOTE_FAILURE",
  UPDATE_REQUEST = "UPDATE_NOTE_REQUEST",
  UPDATE_SUCCESS = "UPDATE_NOTE_SUCCESS",
  UPDATE_FAILURE = "UPDATE_NOTE_FAILURE",
  DELETE_REQUEST = "DELETE_NOTE_REQUEST",
  DELETE_SUCCESS = "DELETE_NOTE_SUCCESS",
  DELETE_FAILURE = "DELETE_NOTE_FAILURE",
  GET_REQUEST = "GET_NOTES_REQUEST",
  GET_SUCCESS = "GET_NOTES_SUCCESS",
  GET_FAILURE = "GET_NOTES_FAILURE",
}

export enum ProjectActivityTypes {
  REQUEST = 'PROJECT_ACTIVITY_REQUEST',
  SUCCESS = 'PROJECT_ACTIVITY_SUCCESS',
  FAILURE = 'PROJECT_ACTIVITY_FAILURE',
}

export enum ProjectOverviewActionTypes {
  REQUEST = 'PROJECT_OVERVIEW_REQUEST',
  SUCCESS = 'PROJECT_OVERVIEW_SUCCESS',
  FAILURE = 'PROJECT_OVERVIEW_FAILURE',
}

export const {
  fetchProjectListRequest,
  fetchProjectListSuccess,
  fetchProjectListFailure,
  newProjectRequest,
  newProjectSuccess,
  newProjectFailure,
  updateProjectRequest,
  updateProjectSuccess,
  updateProjectFailure,
  setEditProjectId,
  clearEditProjectId,
  updateProjectData,
  deleteProjectRequest,
  deleteProjectSuccess,
  deleteProjectFailure,
  setProject,
  selectAllProjects,
  createNoteRequest,
  createNoteSuccess,
  createNoteFailure,
  updateNoteRequest,
  updateNoteSuccess,
  updateNoteFailure,
  deleteNoteRequest,
  deleteNoteSuccess,
  deleteNoteFailure,
  getNotesRequest,
  getNotesSuccess,
  getNotesFailure,
  projectActivityRequest,
  projectActivitySuccess,
  projectActivityFailure,
  projectOverviewRequest,
  projectOverviewSuccess,
  projectOverviewFailure
} =
  createActions({
    [FetchProjectListActionTypes.REQUEST]: (payload) => payload,
    [FetchProjectListActionTypes.SUCCESS]: (payload) => payload,
    [FetchProjectListActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [NewProjectActionTypes.REQUEST]: (payload) => payload,
    [NewProjectActionTypes.SUCCESS]: ({ message }: any) => ({ message }),
    [NewProjectActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [UpdateProjectActionTypes.REQUEST]: (payload) => payload,
    [UpdateProjectActionTypes.SUCCESS]: (payload) => payload,
    [UpdateProjectActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [UpdateProjectActionTypes.SET_EDIT_PROJECT_ID]: (response) => (response),
    [UpdateProjectActionTypes.CLEAR_EDIT_PROJECT_ID]: () => { },
    [UpdateProjectActionTypes.UPDATE_PROJECT_DATA]: (response) => (response),
    [DeleteProjectActionTypes.REQUEST]: (payload) => payload,
    [DeleteProjectActionTypes.SUCCESS]: ({ message }) => ({ message }),
    [DeleteProjectActionTypes.FAILURE]: ({ message }: any) => ({ error: message }),
    [ProjectActionTypes.SET]: payload => payload,
    [ProjectActionTypes.SELECT_ALL_PROJECTS]: payload => payload,
    [ProjectNotesActionTypes.CREATE_REQUEST]: (payload) => payload,
    [ProjectNotesActionTypes.CREATE_SUCCESS]: (response) => response,
    [ProjectNotesActionTypes.CREATE_FAILURE]: ({ error }) => ({ error }),
    [ProjectNotesActionTypes.UPDATE_REQUEST]: (payload) => payload,
    [ProjectNotesActionTypes.UPDATE_SUCCESS]: (response) => response,
    [ProjectNotesActionTypes.UPDATE_FAILURE]: ({ error }) => ({ error }),
    [ProjectNotesActionTypes.DELETE_REQUEST]: (payload) => payload,
    [ProjectNotesActionTypes.DELETE_SUCCESS]: (response) => response,
    [ProjectNotesActionTypes.DELETE_FAILURE]: ({ error }) => ({ error }),
    [ProjectNotesActionTypes.GET_REQUEST]: (payload) => payload,
    [ProjectNotesActionTypes.GET_SUCCESS]: (response) => response,
    [ProjectNotesActionTypes.GET_FAILURE]: ({ error }) => ({ error }),
    [ProjectActivityTypes.REQUEST]: (payload) => payload,
    [ProjectActivityTypes.SUCCESS]: (response) => response,
    [ProjectActivityTypes.FAILURE]: ({ error }) => ({ error }),
    [ProjectOverviewActionTypes.REQUEST]: (payload) => payload,
    [ProjectOverviewActionTypes.SUCCESS]: (response) => response,
    [ProjectOverviewActionTypes.FAILURE]: ({ error }) => ({ error }),
  });
