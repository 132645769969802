import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export const useLibrarySearchNFilter = (methodList: any[]) => {
	const [query, setQuery] = useState("")
	const [data, setData] = useState(methodList)
	const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
	const [filteredCategories, setFilteredCategories] = useState<string[]>([])

	const categories = useMemo(() => {
		return [...new Set(methodList.map((method) => method.category))]
	}, [methodList])

	const filterList = useCallback(
		(term: string) => {
			return methodList.filter((method: any) => {
				const name = method?.name?.toLowerCase()
				const description = method?.description?.toLowerCase()
				const searchTerm = term?.toLowerCase()

				let result = true

				if (!name.includes(searchTerm) && !description.includes(searchTerm)) {
					result = false
				}
				if (
					filteredCategories.length > 0 &&
					!filteredCategories.includes(method.category)
				) {
					result = false
				}

				return result
			})
		},
		[methodList, filteredCategories]
	)

	useEffect(() => {
		timer.current = setTimeout(() => {
			setData(filterList(query))
		}, 400)

		return () => {
			timer.current && clearTimeout(timer.current)
		}
	}, [query, filteredCategories, filterList, methodList])

	return {
		query,
		setQuery,
		data,
		categories,
		filteredCategories,
		setFilteredCategories,
	}
}
