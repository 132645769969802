import { Button, Card, Empty, Input, Modal, Space, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import BreadCrumbSection from './BreadCrumbSection'
import Meta from 'antd/es/card/Meta'
import { directoryIcon } from './DirectoryCard'
import {
  assetLibraryAddFolderRequest,
  assetLibraryFolderNavigateClear,
  assetLibraryFolderNavigateRequest,
  assetLibraryFolderNavigateSelect,
  assetLibraryFolderNavigateUpdateBreadcrumb,
  assetLibraryGetContentRequest,
  assetLibrarySetSelectedDirectories
} from 'src/store/actions/assetLibrary'
import { nanoid } from 'nanoid'

type P = {
  heading: string,
  type: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onAssetSelect: (name?: string | undefined) => void
  withName?: boolean
}

const SelectAssetModal = ({ heading, type, open, setOpen, onAssetSelect, withName }: P) => {
  // State
  const [folderName, setFolderName] = React.useState<string>('')
  const [isAddNewFolderModalVisible, setIsAddNewFolderModalVisible] =
    React.useState<boolean>(false)

  // Utility Hooks

  const dispatch = useDispatch()

  // Selector

  const {
    assetLibraryFolderNavigateBreadcrumb,
    assetLibraryFolderNavigateSelectedDirectory,
    assetLibraryFolderNavigateCurrentDirectory
  } = useSelector((state: StoreState) => state.assetLibrary)

  // Memo

  const isFolder = useMemo(() => type === 'folders', [type])

  const isFile = useMemo(() => type === 'files', [type])

  // Helper functions

  const navigateFolder = useCallback(
    (folderId: string, folderName: string) => {
      dispatch(
        assetLibraryFolderNavigateRequest({
          folder_id: folderId
        })
      )
      dispatch(
        assetLibraryFolderNavigateUpdateBreadcrumb({
          name: folderName,
          asset_id: folderId
        })
      )
      dispatch(assetLibraryFolderNavigateSelect(null))
    },
    [dispatch]
  )

  // Effects

  useEffect(() => {
    dispatch(assetLibraryGetContentRequest({ folder_id: 'root' }))
  }, [dispatch])

  useEffect(() => {
    dispatch(assetLibraryFolderNavigateSelect(null))
    dispatch(
      assetLibraryFolderNavigateUpdateBreadcrumb({
        name: 'Library',
        asset_id: 'root'
      })
    )
    dispatch(
      assetLibraryFolderNavigateRequest({
        folder_id: 'root'
      })
    )
  }, [dispatch])

  const onCancel = () => {
    dispatch(assetLibraryFolderNavigateClear())
    dispatch(assetLibraryFolderNavigateSelect(null))
    dispatch(assetLibrarySetSelectedDirectories([]))
    setOpen(false)
    navigateFolder('root', 'root')
  }

  const onNewFolder = useCallback(() => {
    setFolderName('')
    setIsAddNewFolderModalVisible(true)
  }, [])

  const [name, setName] = useState(`PolyGPT file ${nanoid(3)}`)

  return (
    <Modal
      open={open}
      onOk={() => {
        onAssetSelect(name)
      }}
      centered
      width={800}
      onCancel={onCancel}
      title={heading}
      destroyOnClose={true}
      footer={
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {isFolder ? (
            <Button onClick={onNewFolder}>New Folder</Button>
          ) : (
            <div></div>
          )}

          <Space>
            {/* {isFolder && (
              <Button
                type="primary"
                onClick={() => {
                  dispatch(assetLibraryFolderNavigateSelect('root'))
                  onAssetSelect()
                }}
              >
                Move to Root
              </Button>
            )} */}
            {withName && !name?.trim?.() && <Typography.Text type='warning'>Name is required.</Typography.Text>}
            {withName && <Input addonBefore="Name:" defaultValue={name} value={name} onChange={e => setName(e.target.value)} />}
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                onAssetSelect(name)
              }}
              disabled={(!assetLibraryFolderNavigateSelectedDirectory && !assetLibraryFolderNavigateCurrentDirectory) || !name?.trim?.()}
            >
              Add
            </Button>
          </Space>
        </Space>
      }
      styles={{
        header: { userSelect: 'none' },
        body: {
          height: 400,
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          userSelect: 'none'
        }
      }}
    >
      <BreadCrumbSection
        breadCrumbOnClick={(item: any) => {
          navigateFolder(item.key as string, item.label as string)
        }}
        breadCrumbPath={assetLibraryFolderNavigateBreadcrumb}
        breadCrumbSelectedId={assetLibraryFolderNavigateCurrentDirectory}
      />

      {isFile && (
        <FilesOrFolders
          type={'folders'}
          navigateFolder={navigateFolder}
          isMove={false}
        />
      )}
      <FilesOrFolders type={type} navigateFolder={navigateFolder} />

      <Modal
        title="Add New Folder"
        open={isAddNewFolderModalVisible}
        centered
        onOk={() => {
          dispatch(
            assetLibraryAddFolderRequest({
              name: folderName,
              parent_id: assetLibraryFolderNavigateCurrentDirectory.asset_id,

              type: 'popup'
            })
          )
          setFolderName('')
          setIsAddNewFolderModalVisible(false)
        }}
        onCancel={() => {
          setFolderName('')
          setIsAddNewFolderModalVisible(false)
        }}
      >
        <Input
          placeholder="Please enter the name of the folder"
          onChange={(e) => {
            setFolderName(e.target.value)
          }}
          value={folderName}
        />
      </Modal>
    </Modal>
  )
}

export default SelectAssetModal

const DirectoryCard = ({
  name,
  type,
  isSelected,
  onClick,
  onDoubleClick
}: any) => {
  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 200,
        border: '1px solid #c1c1c1',
        maxHeight: 200,
        ...(isSelected && {
          backgroundColor: '#e6f7ff'
        })
      }}
      className="directory-card"
      cover={
        type !== 'folder' ? (
          <div
            style={{
              position: 'relative',
              height: 140,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 80
            }}
          >
            {directoryIcon(type)}
          </div>
        ) : null
      }
      bodyStyle={{
        padding: 14
      }}
      onClick={(e) => {
        onClick && onClick(e)
      }}
      onDoubleClick={(e) => {
        onDoubleClick && onDoubleClick(e)
      }}
    >
      <Meta
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 16
            }}
          >
            {directoryIcon(type)}
            <Typography.Text
              style={{
                flexGrow: 1,
                textAlign: 'left'
              }}
              ellipsis={{ tooltip: true }}
            >
              {name}
            </Typography.Text>
          </div>
        }
      />
    </Card>
  )
}

type Q = {
  type: string
  navigateFolder: (folderId: string, folderName: string) => void
  isMove?: boolean
}

const FilesOrFolders = ({ type, navigateFolder, isMove = true }: Q) => {
  const dispatch = useDispatch()

  const {
    assetLibraryFolderNavigateSelectedDirectory,
    assetLibraryFolderNavigateResponse,
    assetLibrarySelectedDirectories
  } = useSelector((state: StoreState) => state.assetLibrary)

  const title = useMemo(() => {
    return type === 'folders' ? 'Folders' : 'Files'
  }, [type])

  const isFolder = useMemo(() => type === 'folders', [type])

  const itemsTobeShown = useMemo(() => {
    let data = assetLibraryFolderNavigateResponse?.[type]

    if (isMove) {
      data = data?.filter(
        (directory: any) =>
          !assetLibrarySelectedDirectories.includes(directory.asset_id)
      )
    }
    return data
  }, [
    assetLibraryFolderNavigateResponse,
    assetLibrarySelectedDirectories,
    type,
    isMove
  ])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: 16,
        width: '100%',
        marginTop: 16,
        flexGrow: 1
      }}
      onClick={(e) => {
        e.stopPropagation()
        dispatch(assetLibraryFolderNavigateSelect(null))
      }}
    >
      <Typography.Title level={5}>{title}</Typography.Title>
      {itemsTobeShown?.length ? (
        <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
          {itemsTobeShown.map((file: any) => (
            <DirectoryCard
              key={file.asset_id}
              {...file}
              onClick={(e: any) => {
                e.stopPropagation()
                dispatch(assetLibraryFolderNavigateSelect(file.asset_id))
              }}
              isSelected={
                assetLibraryFolderNavigateSelectedDirectory === file.asset_id
              }
              onDoubleClick={
                isFolder
                  ? (e: any) => {
                    e.stopPropagation()
                    navigateFolder(file.asset_id, file.name)
                  }
                  : null
              }
            />
          ))}
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={`No ${type}`}
          style={{
            width: '100%'
          }}
        />
      )}
    </div>
  )
}
