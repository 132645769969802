import { DeleteOutlined } from '@ant-design/icons'
import { Modal, Popover, Space, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { antdTheme, AsyncStates } from 'src/constants'
import { CustomFieldType } from 'src/modules/CustomFields/types'
import { deleteCustomFieldRequest, getCustomFieldsRequest, getCustomFieldsSuccess } from 'src/store/actions/customFields'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'

type ManageFieldsModalProps = {
  modalState: any[]
}

const { Text } = Typography

export default function ManageFieldsModal({ modalState }: ManageFieldsModalProps) {
  const [isModalOpen, setIsModalOpen] = modalState
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const { data: customFields, status } = useSelector((store: StoreState) => store.customFields.getCustomFields)
  const { status: deleteStatus, data: fieldId } = useSelector((store: StoreState) => store.customFields.deleteCustomField)
  const [popStatus, setPopStatus] = useState<any>(null)

  useEffect(() => {
    dispatch(
      getCustomFieldsRequest({ entity_system_name: "inventory.ingredients" })
    );

  }, [dispatch])

  useEffect(() => {
    if ([AsyncStates.SUCCESS, AsyncStates.ERROR].includes(deleteStatus)) setPopStatus(null)
    if (deleteStatus === AsyncStates.SUCCESS) dispatch(getCustomFieldsSuccess([...customFields.filter(({ field_id }: any) => field_id !== fieldId)]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus, dispatch, fieldId])



  return (
    <Modal title={t("inventory.ManageCustomFields")} open={isModalOpen} footer={null} onCancel={() => setIsModalOpen(false)}>
      <Spin spinning={status === AsyncStates.LOADING}>
        <Space direction='vertical' style={{ width: "100%" }}>
          {customFields.length > 0 ? customFields?.map(({ field_name, field_id }: CustomFieldType, index: number) => <div style={{ display: "flex", border: "1px solid", borderRadius: "10px", padding: "0.5rem" }}>
            <Text>{field_name}</Text>
            <Popover open={popStatus === index} content={<><div>Deleting a Field is permanent. Confirm delete?
            </div>
              <div style={{ display: "flex", marginTop: "0.5rem" }}>
                <StyledButton size='small' style={{ marginLeft: "auto" }} loading={deleteStatus === AsyncStates.LOADING} onClick={() => {
                  dispatch(deleteCustomFieldRequest({ field_id }))
                }} danger icon={<DeleteOutlined />} >{t("common.delete")}</StyledButton>
                <StyledButton size='small' style={{ marginLeft: "0.5rem" }} onClick={() => setPopStatus(null)}>{t("common.cancel")}</StyledButton>
              </div></>} title={`${t("common.confirmDelete")}?`} trigger="click">
              <DeleteOutlined onClick={() => {
                setPopStatus(index)
              }} style={{ color: antdTheme.colorError, fontSize:antdTheme.fontSizeLG, marginLeft: "auto" }} />
            </Popover>
          </div>) : <div>Fields are not created</div>}
        </Space>
      </Spin>
    </Modal>
  )
}
