import React, { Dispatch, SetStateAction } from "react"
import { Select } from "antd"
import useTranslate from "src/utils/useTranslate"
import { toTitleCase } from "src/utils/general"
const { Option } = Select

type P = {
	input1: string | null
	input2: string | null
	input3: string | null
	setInput1: Dispatch<SetStateAction<string | null>>
	setInput2: Dispatch<SetStateAction<string | null>>
	setInput3: Dispatch<SetStateAction<string | null>>
	numericalVariables: string[]
	getDisplayName: (value: string) => string
}

export const Form = ({
	input1,
	input2,
	input3,
	setInput1,
	setInput2,
	setInput3,
	numericalVariables,
	getDisplayName,
}: P) => {
	const [t] = useTranslate()

	return (
		<div className="scatter-plot-3d-form">
			<Select
				showSearch
				style={{ width: "100%" }}
				placeholder={t("common.selectXAxis")}
				value={input1}
				onChange={(value) => setInput1(value)}
			>
				{numericalVariables
					.filter((element) => element !== input2 && element !== input3)
					.map((item) => (
						<Option key={item} value={item}>
							{toTitleCase(getDisplayName(item))}
						</Option>
					))}
			</Select>

			<Select
				style={{ width: "100%" }}
				showSearch
				placeholder={t("common.selectYAxis")}
				value={input2}
				onChange={(value) => setInput2(value)}
			>
				{numericalVariables
					.filter((element) => element !== input1 && element !== input3)
					.map((item) => (
						<Option key={item} value={item}>
							{toTitleCase(getDisplayName(item))}
						</Option>
					))}
			</Select>

			<Select
				showSearch
				style={{ width: "100%" }}
				placeholder={t("common.selectScatterSize")}
				value={input3}
				onChange={(value) => setInput3(value)}
			>
				{numericalVariables
					.filter((element) => element !== input1 && element !== input2)
					.map((item) => (
						<Option key={item} value={item}>
							{toTitleCase(getDisplayName(item))}
						</Option>
					))}
			</Select>
		</div>
	)
}
