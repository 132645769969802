import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal } from 'antd';
import { useDispatch } from 'react-redux'
import { antdTheme } from 'src/constants'
import { deleteTemplateRequest } from 'src/store/actions/templates'
import { StyledButton } from 'src/styled_components/StyledButton';
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon'

export const TemplateDropDown = ({ template }: any) => {
    const dispatch = useDispatch()

    const getMenu = (template: any) => {
        return (
            <Menu>
                <Menu.Item key={2}>
                    <StyledButton type='text' icon={<StyledDeleteIcon />} danger onClick={(e) => {
                        e.stopPropagation()
                        Modal.confirm({
                            title: `Delete ${template?.name} ?`,
                            content: 'You are deleting a template. This action cannot be undone. Confirm delete?',
                            icon: <StyledDeleteIcon />,
                            okText: "Delete",
                            cancelText: 'Cancel',
                            onOk: () => { dispatch(deleteTemplateRequest({ template_id: template?.template_id })); }
                        })
                    }}>Delete Template</StyledButton>
                </Menu.Item>
            </Menu >
        )
    }

    return (
        <Dropdown key="more" overlay={getMenu(template) as any}>
            <StyledButton
                type="link"
                style={{
                  border: "none",
                  padding: 0,
                  color:'black', 
                  outline:'none'
                }}
                onClick={e => e.stopPropagation()}
            >
                <MoreOutlined
                    style={{
                        fontSize: antdTheme.fontSizeHeading3,
                        verticalAlign: "top",
                    }}
                />
            </StyledButton>
        </Dropdown>
    )
}
