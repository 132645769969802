import HighchartsReact from 'highcharts-react-official'
import { useEffect, useMemo, useRef, useState } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsMore from 'highcharts/highcharts-more'
import { BORDER_STYLE } from '../Dashboard'
import useTranslate from 'src/utils/useTranslate'
import { Segmented, Spin, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardUsedIngredientsRequest } from 'src/store/actions/dashboard'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates } from 'src/constants'

HighchartsMore(Highcharts)

const CHART_COLOR_1 = '#2563EB'
const CHART_COLOR_2 = '#38BDF8'
// const CHART_COLOR_3 = '#F87171'

const IngredientsBarChart = () => {
  const [type, setType] = useState<string>('most_used_ingredients')

  const { dashboardUsedIngredientsStatus } = useSelector(
    (state: StoreState) => state.dashboard
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        outline: BORDER_STYLE,
        padding: '12px 24px',
        gap: 12
      }}
    >
      <Spin spinning={dashboardUsedIngredientsStatus === AsyncStates.LOADING}>
        <HeaderSection setType={setType} />
        <BarChartSection type={type} />
      </Spin>
    </div>
  )
}

export default IngredientsBarChart

const BarChartSection = ({ type }: any) => {
  const [t] = useTranslate()
  const chartRef = useRef<any>(null)

  const dispatch = useDispatch()
  const { dashboardUsedIngredientsResponse } = useSelector(
    (state: StoreState) => state.dashboard
  )

  useEffect(() => {
    dispatch(dashboardUsedIngredientsRequest())
  }, [dispatch])

  const ingredientNames = useMemo(() => {
    return (
      dashboardUsedIngredientsResponse?.[type]?.map((item: any) => item.name) ||
      []
    )
  }, [dashboardUsedIngredientsResponse, type])

  const ingredientsUsedInWorkorder = useMemo(() => {
    return (
      dashboardUsedIngredientsResponse?.[type]?.map((item: any) => item.workorder_count) ||
      []
    )
  }, [dashboardUsedIngredientsResponse, type])

  const ingredientsUsedInProjects = useMemo(() => {
    return (
      dashboardUsedIngredientsResponse?.[type]?.map((item: any) => item.project_count) ||
      []
    )
  }, [dashboardUsedIngredientsResponse, type])

  // const ingredientsUsedOverall = useMemo(() => {
  //   return (
  //     dashboardUsedIngredientsResponse?.[type]?.map((item: any) => item.count) ||
  //     []
  //   )
  // }, [dashboardUsedIngredientsResponse, type])

  const chartOptions = useMemo(() => {
    return {
      chart: {
        type: 'column',
        marginRight: 250,
        marginTop: 50
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      legend: {
        align: 'left',
        verticalAlign: 'bottom',
        symbolWidth: 8,
        symbolHeight: 8,
        itemStyle: {
          fontSize: '10px',
          margin: 0
        }
      },
      xAxis: {
        categories: ingredientNames,
        tickWidth: 0,
        tickLength: 0,
        lineWidth: 0
      },
      yAxis: {
        min: 0,
        title: {
          enabled: false
        },
        gridLineDashStyle: 'Dash',
        allowDecimals: false
      },
      tooltip: {
        valueSuffix: ''
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          groupPadding: 0.2
        }
      },
      series: [
        {
          name: t('common.project'),
          data: ingredientsUsedInProjects,
          color: CHART_COLOR_1
        },
        {
          name: t('common.workOrder'),
          data: ingredientsUsedInWorkorder,
          color: CHART_COLOR_2
        },
        // {
        //   name: t('common.Total'),
        //   data: ingredientsUsedOverall,
        //   color: CHART_COLOR_3
        // }
      ]
    }
  }, [
    ingredientNames,
    ingredientsUsedInProjects,
    ingredientsUsedInWorkorder,
    // ingredientsUsedOverall,
    t
  ])

  return (
    <div
      style={{
        padding: '0px 0px'
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
    </div>
  )
}

const HeaderSection = ({ setType }: any) => {
  const [t] = useTranslate()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 0px',
        height: '60px'
      }}
    >
      <Typography.Title
        style={{
          margin: 0
        }}
        level={5}
      >
        {t('common.ingredients')}
      </Typography.Title>
      <QuickFilter setType={setType} />
    </div>
  )
}

const QuickFilter = ({ setType }: any) => {

  const [t] = useTranslate()

  return (
    <Segmented
      options={[
        {
          label: `5 ${t("common.highlyUsed")}`,
          value: 'most_used_ingredients'
        },
        {
          label: `5 ${t("common.leastUsed")}`,
          value: 'least_used_ingredients'
        }
      ]}
      onChange={(value) => {
        setType(value)
      }}
      className="dashboard-ingredients-filter"
    />
  )
}
