import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { DatePicker, Dropdown, Input, Select, Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import RepositoryActionDrawer from "../Common/RepositoryActionDrawer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { removeFieldRequest } from "src/store/actions/repository";
import useTranslate from "src/utils/useTranslate";
import dayjs from "dayjs";
import { useValue } from "src/utils/useValue";

type TProps = {
    searchTerm: string | undefined;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedField: React.Dispatch<React.SetStateAction<string | undefined>>;
    setMode: React.Dispatch<React.SetStateAction<"edit" | "create" | "delete">>;
};

const CustomFieldDetails = ({
    searchTerm,
    setSelectedField,
    setMode,
    setShowModal,
}: TProps) => {
    const [t] = useTranslate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedFields, setSelectedFields] = useState<any[]>([]);
    const fields = useSelector(
        (state: StoreState) => state.repository.fields.data
    );
    const dispatch = useDispatch();
    const { getValue: getLocalValue } = useValue();

    useEffect(() => {
        if (selectedFields.length > 0) setDrawerOpen(true);
        else setDrawerOpen(false);
    }, [selectedFields]);

    useEffect(() => {
        // Update selected fields when changed
        const availableFields = fields.map((f) => f.field_id);
        setSelectedFields((prev) =>
            prev.filter((f) => availableFields.includes(f))
        );
    }, [fields]);

    const onRowSelection = (
        selectedRowKeys: React.Key[],
        selectedRows: any[]
    ) => {
        setSelectedFields(selectedRowKeys);
    };

    const deleteFields = useCallback(
        (ids?: string[]) => {
            setMode("delete");
            dispatch(removeFieldRequest({ field_ids: ids ? ids : selectedFields }));
        },
        [dispatch, selectedFields, setMode]
    );

    const takeAction = useCallback(
        (action: string, record: any) => {
            setSelectedField(record.key);

            if (action === "edit") {
                setShowModal(true);
                setMode("edit");
            }
            if (action === "delete") {
                deleteFields([record.key]);
            }
        },
        [deleteFields, setMode, setSelectedField, setShowModal]
    );

    const onDismiss = () => {
        setSelectedFields([]);
        setDrawerOpen(false);
    };

    const renderName = (text: string, record: any) => {
        return (
            <>
                {record.required && (
                    <>
                        <sup style={{ color: "#F5222D" }}>*</sup>&nbsp;
                    </>
                )}
                {text}
            </>
        );
    };

    const renderFields = useCallback((type: string, record: any) => {
        switch (type) {
            case "alpha_numeric":
            case "numerical":
                return (
                    <Input
                        disabled
                        placeholder={"-"}
                        className="input-showcase"
                        value={getLocalValue(record.value)}
                    />
                );
            case "date":
                return (
                    <DatePicker
                        disabled
                        placeholder={"-"}
                        className="input-showcase"
                        value={record.value ? dayjs(record.value, "YYYY-MM-DD") : undefined}
                    />
                );
            case "categorical":
                return (
                    <Select
                        disabled
                        placeholder={"-"}
                        className="input-showcase"
                        options={[]}
                        value={record.value}
                    />
                );
            case "range":
                return (
                    <div className="touple-container">
                        <Input
                            disabled
                            placeholder={"-"}
                            className="input-showcase"
                            value={getLocalValue(record.value)}
                        />
                        <Input
                            disabled
                            placeholder={"-"}
                            className="input-showcase"
                            value={getLocalValue(record.value_max)}
                        />
                    </div>
                );
            case "operator_value":
                return (
                    <div className="touple-container">
                        <Select
                            disabled
                            placeholder={"-"}
                            className="input-showcase"
                            options={[]}
                            value={record.operator}
                        />
                        <Input
                            disabled
                            placeholder={"-"}
                            className="input-showcase"
                            value={getLocalValue(record.value)}
                        />
                    </div>
                );
            default:
                return <Input className="input-showcase" disabled />;
        }
    }, [getLocalValue])

    const columns: any = useMemo(
        () => [
            {
                title: t("common.Name"),
                dataIndex: "name",
                key: "name",
                width: 200,
                render: (text: string, record: any) => renderName(text, record),
            },
            {
                title: t("repository.Field"),
                dataIndex: "field",
                key: "field",
                render: (text: string, record: any) => renderFields(text, record),
            },
            {
                title: "",
                dataIndex: "",
                key: "action",
                fixed: "right",
                width: 40,
                render: (record: any) => (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: (
                                        <div>
                                            <EditOutlined /> {t("common.edit")}
                                        </div>
                                    ),
                                    key: "edit",
                                },
                                {
                                    label: (
                                        <div style={{ color: "#F5222D" }}>
                                            <DeleteOutlined /> {t("common.delete")}
                                        </div>
                                    ),
                                    key: "delete",
                                },
                            ],
                            onClick: (event) => takeAction(event.key, record),
                        }}
                        trigger={["click"]}
                    >
                        <MoreOutlined />
                    </Dropdown>
                ),
            },
        ],
        [t, renderFields, takeAction]
    );

    const data = useMemo(() => {
        const filteredData = searchTerm
            ? fields.filter((c) =>
                JSON.stringify(Object.values(c))
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
            : fields;
        return filteredData.map((f) => ({
            key: f.field_id,
            name: f.field_name,
            field: f.field_type,
            value: f.value || f.default_value,
            value_max: f.value_max,
            operator: f.operator,
            required: f.required,
        })).sort((a, b) => a.name?.localeCompare(b.name));
    }, [fields, searchTerm]);

    return (
        <>
            <div className="custom-fields-details-container">
                <Table
                    bordered={false}
                    rowSelection={{
                        type: "checkbox",
                        onChange: onRowSelection,
                        selectedRowKeys: selectedFields,
                        hideSelectAll: true
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    className="repository-table"
                />
            </div>
            <RepositoryActionDrawer
                open={drawerOpen}
                close={onDismiss}
                selected={selectedFields.length}
                onDelete={deleteFields}
            />
        </>
    );
};

export default CustomFieldDetails;
