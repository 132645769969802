import { put, takeLatest, call, select } from "redux-saga/effects"
import {
	suggestedExperimentsRequest,
	suggestedExperimentsSuccess,
	suggestedExperimentsFailure,
	exportPredictionRequest,
	exportPredictionFailure,
	exportPredictionSuccess,
	suggestedExperimentsFilterSuccess,
	buildOfMaterialsRequest,
	buildOfMaterialsSuccess,
	buildOfMaterialsFailure,
	exportBuildOfMaterialsSuccess,
	exportBuildOfMaterialsRequest,
	exportBuildOfMaterialsFailure,
	changeTitleRequest,
	changeTitleSuccess,
	changeTitleFailure,
} from "src/store/actions/suggestedExp"
import {
	GetBuildOfMaterialsApi,
	// GetBuildOfMaterialsApi,
	GetExperimentsIdApi, GetZeonSuggestedExperimentsIdApi, buildOfMaterialsExportApi, predictionDataExportApi
} from "src/services/suggestedExp/suggestedExp"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "src/store/configureStore"
import { message, notification } from "antd"
import jwtManager from "src/utils/jwtManager"
import { changeTitleApi } from "src/services/inverseModel"
import { getPredictionSuccess } from "../actions/inverseModel"
import { configsUpdateAiengine } from "../actions/configs"


function* suggestedExperimentsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)
	const configs = yield select((state: StoreState) => state.configs.features)
	const allConfigs = yield select((state: StoreState) => state.configs)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(Boolean(configs?.ai_engine_with_methods) ? GetZeonSuggestedExperimentsIdApi : GetExperimentsIdApi, { ...payload, "allow_zero_values": false }, headers)
		if (result.status === "Success") {
			if (!payload?.filters?.length) {
				// For Maintining Filter State in Inverse Pred
				yield put(suggestedExperimentsFilterSuccess(result.data.experiments))
			}
			yield put(
				suggestedExperimentsSuccess({
					experiments: result.data.experiments,
					total: result.total,
					versions: result?.versions,
					inverseMissingProperties: result?.missing_properties ?? []
				})
			)

			// Decrement read receipt total - Optimistic UI

			const updatedConfigs = { ...allConfigs?.ai_engine }
			updatedConfigs.inverse.total_unread_count = result?.total_unread_count
			yield put(configsUpdateAiengine(updatedConfigs))

		} else {
			yield put(suggestedExperimentsFailure(result.message))
		}
	} catch (error) {
		yield put(suggestedExperimentsFailure(messages[ln].internal_server_error))
	}
}

function* buildOfMaterialsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const
			{ data: { result }, }
				= yield call(GetBuildOfMaterialsApi, payload, headers)
		if (result.status === "Success") {
			yield put(
				buildOfMaterialsSuccess({
					experiments: result.data.experiments,
					total: result.total,
				})
			)
		} else {
			yield put(buildOfMaterialsFailure(result.message))
		}
	} catch (error) {
		yield put(buildOfMaterialsFailure(messages[ln].internal_server_error))
	}
}

function* buildOfMaterialsExportSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }

		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(
			buildOfMaterialsExportApi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(exportBuildOfMaterialsSuccess(data))
			window.open(data?.download_link, "_blank")
		} else {
			notification.error({
				message
			})
			yield put(exportBuildOfMaterialsFailure())
		}
	} catch (error: any) {
		yield put(exportBuildOfMaterialsFailure())
		message.error(messages[ln].internal_server_error)
	}
}
function* predictionDataExportSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }

		const {
			data: {
				result: { status, message },
			},
		} = yield call(
			predictionDataExportApi,
			payload,
			headers
		)
		if (status === "Success") {
			yield put(exportPredictionSuccess())
			notification.success({
				message
			})
		} else {
			notification.error({
				message
			})
			yield put(exportPredictionFailure())
		}
	} catch (error: any) {
		yield put(exportPredictionFailure())
		message.error(messages[ln].internal_server_error)
	}
}


function* changeTitleSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const inverseModel = yield select((state: StoreState) => state.inverseModel)
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(
			changeTitleApi,
			payload,
			headers
		)
		if (status === "Success") {

			yield put(changeTitleSuccess(data))

			// Change the title of main table - Optimistic UI
			const updatedPredList = [...inverseModel?.predictionIdsData?.data]
			updatedPredList.forEach(pred => {
				if (pred?.prediction_id === payload?.prediction_id) {
					pred.title = payload?.title
				}
			})

			yield put(getPredictionSuccess({
				total: inverseModel.predictionIdsData.total,
				data: updatedPredList
			}))
		} else {
			notification.error({
				message
			})
			yield put(changeTitleFailure({ error: message }))
		}
	} catch (error: any) {
		yield put(changeTitleFailure({ error }))
		message.error(messages[ln].internal_server_error)
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(suggestedExperimentsRequest, suggestedExperimentsSaga)
	yield takeLatest(buildOfMaterialsRequest, buildOfMaterialsSaga)
	yield takeLatest(exportPredictionRequest, predictionDataExportSaga)
	yield takeLatest(exportBuildOfMaterialsRequest, buildOfMaterialsExportSaga)
	yield takeLatest(changeTitleRequest, changeTitleSaga)
}
