import { Action, ActionFunction1, createActions } from "redux-actions"
import { Language } from "../reducers/language"

export enum LanguageActionTypes {
	TOGGLE = "LANGUAGE_TOGGLE",
	DEV = "LANGUAGE_DEV",
}

export type LanguageToggleAction = ActionFunction1<
	Language,
	Action<{
		current: string
	}>
>

export const {
	languageToggle,
	languageDev
} = createActions({
	[LanguageActionTypes.TOGGLE]: (current: Language) => ({ current }),
	[LanguageActionTypes.DEV]: () => ({ })
})
