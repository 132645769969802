import React, { useEffect, useMemo } from "react"
import { Checkbox, Table, Tooltip, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { DisplayNames } from "src/typings"
import {
	getFilters,
	getSorter,
} from "src/components/AIEngine/ForwardModel/form/payload-form/utils"
import { Column } from "../types"
import { convertToPrecision } from "src/utils/decorator"
import useTranslate from "src/utils/useTranslate"

const { Text } = Typography

type P = {
	data: any
	setSelectedCharacterization: any
	loading: boolean
	currentTab: string
	selectedVariations: any,
	setSelectedVariations: any
}

export const ZeonCharacterizationVariations = ({
	data,
	setSelectedCharacterization,
	loading,
	currentTab,
	selectedVariations,
	setSelectedVariations
}: P) => {
	const displayNames = useSelector(
		(state: StoreState) => state.displayNames.data
	) as DisplayNames

	const [t] = useTranslate()

	useEffect(() => {
		if (loading) setSelectedVariations((prev: any) => {
			return {
				...prev,
				[currentTab]: []
			}
		})
	}, [loading, currentTab, setSelectedVariations])

	useEffect(() => {
		setSelectedCharacterization((prev: any) => {
			return {
				...prev,
				[currentTab]: {
					...prev[currentTab],
					variations: selectedVariations?.[currentTab].map((item: any) => {
						return item.item
					}),
				}
			}
		})
	}, [selectedVariations, setSelectedCharacterization, currentTab])

	const columns = useMemo(() => {
		const cols = [
			...new Set(
				data?.reduce((acc: any, curr: any) => {
					return [...acc, ...Object.keys(curr || {})]
				}, [])
			),
		]

		const tableCols = cols.map((col: any, i) => {
			const filters = getFilters(data, col)
			const sorter = getSorter(data, col)

			return {
				title: <Text style={{ wordBreak: "break-word" }}>{displayNames.characterizations?.[col].name ?? col}</Text>,
				dataIndex: col,
				key: i,
				filters,
				onFilter: (value: string, record) => {
					return (record[col]?.value ?? "-") === value
				},
				sorter,
				render: (param) => {
					return <Tooltip title={
						<>
							{<div><b>{t('common.category')}:</b> {param?.category ?? "-"}</div>}
							{<div><b>{t('common.unit')}:</b> {param?.unit ?? "-"}</div>}
						</>
					}>
						<Text style={{ wordBreak: "break-word" }}>{convertToPrecision(param?.value) ?? "-"}</Text>
					</Tooltip>
				}
			}
		}) as ColumnsType<Column>

		return [
			{
				title: (
					<Checkbox
						checked={data.length === selectedVariations?.[currentTab].length}
						onChange={(e) => {
							if (e.target.checked) {
								setSelectedVariations((prev: any) => {
									return {
										...prev,
										[currentTab]: data.map((item: any, i: any) => {
											return {
												id: i,
												item: item,
											}
										})
									}
								}

								)
							} else {
								setSelectedVariations((prev: any) => {
									return {
										...prev,
										[currentTab]: []
									}
								})
							}
						}}
					/>
				),
				dataIndex: "data",
				key: "data",
				width: 50,
				render: (col) => {
					return (
						<Checkbox
							checked={selectedVariations?.[currentTab].some((vari: any) => vari.id === col.id)}
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedVariations((prev: any) => {
										return {
											...prev,
											[currentTab]: [
												...prev[currentTab],
												col
											]
										}
									})
								} else {
									setSelectedVariations((prev: any) => {
										return {
											...prev,
											[currentTab]: prev?.[currentTab]?.filter((item: any) => item.id !== col.id)
										}
									}
									)
								}
							}}
						/>
					)
				},
			},
			...tableCols,
		] as ColumnsType<Column>
	}, [data, displayNames.characterizations, selectedVariations, currentTab, setSelectedVariations, t])

	const dataSource = useMemo(() => {
		return data.map((item: any, i: any) => {
			return {
				data: {
					id: i,
					item: item,
				},
				...item,
			}
		}) as Column[]
	}, [data])

	if (data.length < 1) return null

	return <Table loading={loading} dataSource={dataSource} columns={columns} />
}
