import React, { useEffect, useState } from "react"
import { Form, Input, message } from "antd"
import Sha1 from "js-sha1"
import useTranslate from "src/utils/useTranslate"
import { InfoCircleFilled } from "@ant-design/icons"
import { red } from "@ant-design/colors"
import { useDispatch, useSelector } from "react-redux"
import { updatePasswordRequest } from "src/store/actions/updatePassword"
import { StoreState } from "src/store/configureStore"
import { UpdatePasswordReducerState } from "src/store/reducers/updatePassword"
import { AsyncStates } from "src/constants"
import { useForm } from "antd/lib/form/Form"
import { ForgotPassword } from "./forgot-password"
import { StyledButton } from "src/styled_components/StyledButton"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"

type Data = {
	old_password: string
	new_password: string
	confirm_new_password: string
}

export const UpdatePassword = () => {
	const [t] = useTranslate()
	const [form] = useForm()
	const dispatch = useDispatch()
	const requiredFieldStar = useRequiredFieldStar()

	const [isPasswordNotMatch, setIsPasswordNotMatch] = useState(false)
	const response = useSelector<StoreState, UpdatePasswordReducerState>(
		(state) => state.updatePassword
	)

	function submitForm(values: Data) {
		if (values.new_password !== values.confirm_new_password) {
			setIsPasswordNotMatch(true)
			return
		}
		if (values.old_password === values.new_password) {
			message.error(t("updatePassword.message.passwordMatchedError"))
			return
		}

		dispatch(
			updatePasswordRequest({
				old_password: Sha1(values.old_password.trim()),
				new_password: Sha1(values.new_password.trim()),
			})
		)
	}

	useEffect(() => {
		if (response.status === AsyncStates.SUCCESS) {
			form.resetFields()
		}
	}, [response, form])

	return (
		<div>
			<Form
				form={form}
				name="normal_login"
				className="login-form"
				layout="vertical"
				style={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
				}}
				onFinish={submitForm}
				requiredMark={false}
			>
				<Form.Item
					label={t("common.oldPassword")}
					name="old_password"
					required 
					tooltip={requiredFieldStar}
					rules={[
						{
							required: true,
							message: (
								<>
									<InfoCircleFilled
										color="red.primary"
										style={{ marginRight: "2.5px" }}
									/>
									{"Please input your Old Password!"}
								</>
							),
						},
						// {
						// 	pattern: new RegExp(
						// 		/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])([a-zA-Z0-9@!*#$%]{8,})*$/
						// 	),
						// 	message: (
						// 		<>
						// 			<InfoCircleFilled
						// 				color="red.primary"
						// 				style={{ marginRight: "2.5px" }}
						// 			/>
						// 			Password must contain at least 8 characters that include at
						// 			least 1 lowercase character, 1 uppercase characters, 1 number,
						// 			and 1 special character or Password cannot start and end with
						// 			spaces.
						// 		</>
						// 	),
						// },
					]}
				>
					<Input.Password
						type="password"
						size="large"
						placeholder={t("common.oldPassword")}
					/>
				</Form.Item>

				<Form.Item
					label={t("common.newPassword")}
					name="new_password"
					required tooltip={requiredFieldStar}
					rules={[
						{
							required: true,
							message: (
								<>
									<InfoCircleFilled
										color="red.primary"
										style={{ marginRight: "2.5px" }}
									/>
									{"Please input your New Password!"}
								</>
							),
						},
						{
							pattern: new RegExp(
								/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])([a-zA-Z0-9@!*#$%]{8,})*$/
							),
							message: (
								<>
									<InfoCircleFilled
										color="red.primary"
										style={{ marginRight: "2.5px" }}
									/>
									Password must contain at least 8 characters that include at
									least 1 lowercase character, 1 uppercase characters, 1 number,
									and 1 special character or Password cannot start and end with
									spaces.
								</>
							),
						},
					]}
				>
					<Input.Password
						type="password"
						size="large"
						placeholder={t("common.newPassword")}
						minLength={8}
					/>
				</Form.Item>

				<Form.Item
					label={t("common.confirmNewPassword")}
					name="confirm_new_password"
					required tooltip={requiredFieldStar}
					rules={[
						{
							required: true,
							message: (
								<>
									<InfoCircleFilled
										color="red.primary"
										style={{ marginRight: "2.5px" }}
									/>
									{"Please input your Confirm New Password!"}
								</>
							),
						},
						{
							pattern: new RegExp(
								/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])([a-zA-Z0-9@!*#$%]{8,})*$/
							),
							message: (
								<>
									<InfoCircleFilled
										color="red.primary"
										style={{ marginRight: "2.5px" }}
									/>
									Password must contain at least 8 characters that include at
									least 1 lowercase character, 1 uppercase characters, 1 number,
									and 1 special character or Password cannot start and end with
									spaces.
								</>
							),
						},
					]}
				>
					<Input.Password
						type="password"
						size="large"
						placeholder={t("common.confirmNewPassword")}
						minLength={8}
					/>
				</Form.Item>

				{isPasswordNotMatch && (
					<Form.Item>
						<span style={{ color: red.primary }}>
							{t("updatePassword.message.passwordMismatch")}
						</span>
					</Form.Item>
				)}

				<Form.Item>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							alignItems: "center",
						}}
					>
						<ForgotPassword />

						<StyledButton
							style={{ background: "#FF9300" }}
							size="large"
							type="primary"
							className="round-text"
							htmlType="submit"
						>
							{t("common.submit")}
						</StyledButton>
					</div>
				</Form.Item>
			</Form>
		</div>
	)
}
