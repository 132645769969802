import { Modal, Row, Space, Table, Tabs, Typography } from "antd"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"


const { TabPane } = Tabs
const { Text, Title } = Typography



export const NewParametersModal = ({ newParameters, showNewParametersModal, setShowNewParametersModal, setSkipPrompt }: any) => {

    const [t] = useTranslate()

    const columns = [{
        dataIndex: "name",
        title: t("common.name"),
        key: "name",
    },
    {
        dataIndex: "category",
        title: t("common.category"),
        key: "category",
    }
    ]


    return (
        <Modal
            open={showNewParametersModal}
            onCancel={() => setShowNewParametersModal(false)}
            width={1000}
            maskClosable={false}
            footer={
                <Row justify={"end"} style={{ marginTop: 20 }}>
                    <StyledButton onClick={() => {
                        setSkipPrompt(true)
                        setShowNewParametersModal(false)
                    }} type="primary">{t("dataUpload.NewParametersIgnore")}</StyledButton>
                </Row>}
        >
            <Space direction="vertical" style={{ width: "100%", marginTop: 25 }} size="large">
                <Space direction="vertical" style={{ width: "100%" }} size="small">
                    <Title level={3}>
                        {t("dataUpload.NewParametersTitle")}
                    </Title>
                    <Text>
                        {t("dataUpload.NewParametersDescription")}
                    </Text>
                </Space>
                <Tabs size="large">
                    {!!newParameters?.ingredients?.length && (
                        <TabPane
                            tab={`${t("common.formulations")} (${newParameters?.ingredients.length})`}
                            key="formulations"
                            style={{ width: "100%", overflow: "auto" }}
                        >
                            <Table
                                style={{ marginTop: 20 }}
                                columns={columns}
                                dataSource={newParameters.ingredients}
                                bordered
                            // pagination={false}
                            />
                        </TabPane>
                    )}
                    {!!newParameters?.processing?.length && (
                        <TabPane
                            tab={`${t("common.processing")} (${newParameters?.processing.length})`}
                            key="processing"
                            style={{ width: "100%", overflow: "auto" }}
                        >
                            <Table
                                style={{ marginTop: 20 }}
                                columns={columns}
                                dataSource={newParameters.processing}
                                bordered
                            // pagination={false}
                            />
                        </TabPane>
                    )}
                    {!!newParameters?.characterisation?.length && (
                        <TabPane
                            tab={`${t("common.characterisation")} (${newParameters?.characterisation.length})`}
                            key="characterisation"
                            style={{ width: "100%", overflow: "auto" }}
                        >
                            <Table
                                style={{ marginTop: 20 }}
                                columns={columns}
                                dataSource={newParameters.characterisation}
                                bordered
                            // pagination={false}
                            />
                        </TabPane>
                    )}
                    {!!newParameters?.properties?.length && (
                        <TabPane
                            tab={`${t("formulations.type.properties")} (${newParameters?.properties.length})`}
                            key="properties"
                            style={{ width: "100%", overflow: "auto" }}
                        >
                            <Table
                                style={{ marginTop: 20 }}
                                columns={columns}
                                dataSource={newParameters.properties}
                                bordered
                            // pagination={false}
                            />
                        </TabPane>
                    )}
                </Tabs>
            </Space>
        </Modal>
    )
}
