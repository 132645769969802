export function formatTimestamp(timestamp: any) {
    let now = new Date();
    let time = new Date(timestamp);
    let seconds = Math.floor((now.getTime() - time.getTime()) / 1000);

    let interval = seconds / 86400;
    if (interval > 1) {
        return (
            time?.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "2-digit",
            }) +
            ", " +
            time?.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
            })
        );
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours ago";
    }

    interval = seconds / 60;
    if (interval > 0) {
        return Math.floor(interval) + " minutes ago";
    }

    return "few seconds ago";
}