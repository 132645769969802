import { blue } from "@ant-design/colors";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Radio, Space, Typography, Select, Spin, Col, message, Upload } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";
import { AsyncStates } from "src/constants";
import { importMultipleWorkOrdersClear, importMultipleWorkOrdersRequest } from "src/store/actions/files";
import { setProject } from "src/store/actions/projects";
import {
  editWorkOrderRequest,
  resetWorkOrder,
} from "src/store/actions/workOrderDetails";
import { workOrdersRequest } from "src/store/actions/workOrders";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import useTranslate from "src/utils/useTranslate";

const { Text } = Typography;
const { Dragger } = Upload;

export const DataImport = () => {
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const { push } = useHistory();
  const requiredFieldStar = useRequiredFieldStar()

  const configs = useSelector((state: StoreState) => state.configs.features);
  const importWoStatus = useSelector((state: StoreState) => state.files.uploadWorkOrderFile.status)
  const currentProject = useSelector(
    (state: StoreState) => state.projects.current
  );
  const workOrders = useSelector((state: StoreState) => state.workOrders.data);
  const workOrdersStatus = useSelector(
    (state: StoreState) => state.workOrders.status
  );
  const editWOStatus = useSelector(
    (state: StoreState) => state.workOrderDetails.editWOStatus
  );
  const uploadMultipleStatus = useSelector(
    (state: StoreState) => state.files.uploadWorkOrderFile.status
  );
  const [workOrderType, setWorkOrderType] = useState("new");
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<any>({});
  const [selectedStage, setSelectedStage] = useState<any>({});
  const [formSubmit, setFormSubmit] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(workOrdersRequest({ project_id: currentProject, status: "open" }));
  }, [currentProject, dispatch]);

  useEffect(() => {
    if (editWOStatus === AsyncStates.SUCCESS && formSubmit) {
      dispatch(resetWorkOrder());
      dispatch(setProject(selectedWorkOrder?.project_id));
      push(`/work-orders/details/${selectedWorkOrder?.work_order_id}`);
    }
  }, [editWOStatus, dispatch, push, selectedWorkOrder, formSubmit]);

  const importData = (values: any) => {
    if (workOrderType === "new") {
      dispatch(resetWorkOrder());
      push("/work-orders/create-workorder");
    }
    else if (workOrderType === 'multiple') {
      const payload = new FormData();
      payload.append("file", file);
      payload.append("project_id", currentProject)
      dispatch(importMultipleWorkOrdersRequest(payload));
    } else {
      setFormSubmit(true);
      const { work_order_id, stage } = values;
      dispatch(
        editWorkOrderRequest({
          work_order_id,
          stage: stage,
          data: {
            work_order_parent_stage: stage,
            work_order_stage: "formulation_stage",
          },
          updateDisplayNames: true
        })
      );
    }
  };

  useEffect(() => {
    if (importWoStatus === AsyncStates.SUCCESS) {
      dispatch(importMultipleWorkOrdersClear());
      message.success(t("dataManagement.importWoProcessing"));
      // history.push(`/projects/details/${currentProject}`);
    } else if (importWoStatus === AsyncStates.ERROR) {
      dispatch(importMultipleWorkOrdersClear());
      message.error(`${t("common.somethingWentWrong")}, ${t("common.tryAgain")}`);
    }
  }, [importWoStatus, currentProject, dispatch, t]);


  const [file, setFile] = useState<any>();

  const uploadProps = {
    onRemove: (record: any) => {
      setFile(null);
    },
    beforeUpload: (record: any) => {
      if (record.name.split(".").pop() === "xlsx") {
        setFile(record)
      } else message.error(t("dataUpload.selectedFileDesc"))
      return false
    },
    multiple: false,
    fileList: !!file ? [file] : [],
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <StyledCard title={<Typography.Title level={3}>
        {t("common.import")}
      </Typography.Title>}>
        <Spin
          spinning={
            workOrdersStatus === AsyncStates.LOADING ||
            editWOStatus === AsyncStates.LOADING
          }
          indicator={<LoadingOutlined />}
        >
          <Col span={15}>
            <Form
              name="generate-work-order"
              form={form}
              initialValues={{ work_order_type: "new" }}
              requiredMark={false}
              layout="vertical"
              onFinish={importData}
            >
              <Form.Item
                label={<Text strong>{t("common.workOrder")}</Text>}
                name="work_order_type"
                required
                tooltip={requiredFieldStar}
                rules={[{ required: true, message: t("common.required") }]}
              >
                <Radio.Group
                  onChange={(e) => setWorkOrderType(e.target.value)}
                  value={workOrderType}
                >
                  <Radio value={"new"}>{t("projects.importInNewWO")}</Radio>
                  <Radio value={"existing"}>
                    {t("projects.importExistingWO")}
                  </Radio>
                  {Boolean(configs?.work_order_upload) && (
                    <Radio value={"multiple"}>
                      {t("projects.importMultipleWOs")}
                    </Radio>
                  )}
                </Radio.Group>
              </Form.Item>
              {workOrderType === "existing" && (
                <>
                  <Form.Item
                    label={
                      <Text strong>
                        {t("aiEngine.customInsights.selectWorkOrder")}
                      </Text>
                    }
                    name="work_order_id"
                    required
                    tooltip={requiredFieldStar}
                    rules={[{ required: true, message: t("common.required") }]}
                  >
                    <Select
                      showSearch
                      onChange={(e: any) => {
                        setSelectedStage({});
                        form.setFieldsValue({ stage: "" });
                        setSelectedWorkOrder(
                          workOrders.find(
                            (res: any) => res?.work_order_id === e
                          )
                        );
                      }}
                      optionFilterProp="label"
                      options={workOrders.map((res: any) => ({
                        value: res?.work_order_id,
                        label: res?.work_order_name,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Text strong>{t("common.selectStage")}</Text>}
                    name="stage"
                    required
                    tooltip={requiredFieldStar}
                    rules={[{ required: true, message: t("common.required") }]}
                  >
                    <Select
                      showSearch
                      onChange={(e: any) =>
                        setSelectedStage(
                          selectedWorkOrder?.stages?.find(
                            (res: any) => res?.identifier === e
                          )
                        )
                      }
                      optionFilterProp="label"
                      options={selectedWorkOrder?.stages?.map(
                        (res: any, index: number) => ({
                          value: res?.identifier,
                          label: res?.name
                            ? `Stage ${index + 1}: ${res.name}`
                            : `Stage ${index + 1}`,
                        })
                      )}
                    />
                  </Form.Item>
                  {selectedStage?.status === "closed" && (
                    <Form.Item>
                      <Text type="warning">
                        {
                          "This stage is closed. It will be reopened after adding predicted trials in this stage."
                        }
                      </Text>
                    </Form.Item>
                  )}
                </>
              )}
              {workOrderType === "multiple" && (
                <Form.Item
                  name="file"
                  required
                  rules={[{ required: true, message: t("common.required") }]}
                >
                  <Dragger {...uploadProps}>
                    <p
                      style={{ paddingTop: 30, paddingBottom: 30 }}
                      className="ant-upload-text"
                    >
                      {t("dataUpload.dragnDrop")}
                      <span style={{ color: blue[5] }}>
                        {" "}
                        {t("dataUpload.selectFile")}
                      </span>
                    </p>
                  </Dragger>
                </Form.Item>
              )}
              <StyledButton
                type="primary"
                htmlType="submit"
                style={{ marginTop: 40 }}
                disabled={
                  workOrderType === "multiple"
                    ? uploadMultipleStatus === AsyncStates.LOADING || !file
                      ? true
                      : false
                    : false
                }
                loading={
                  workOrderType === "multiple" &&
                  uploadMultipleStatus === AsyncStates.LOADING
                    ? true
                    : false
                }
              >
                {t("common.import")}
              </StyledButton>
            </Form>
          </Col>
        </Spin>
      </StyledCard>
    </Space>
  );
};
