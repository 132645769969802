import { useLayoutEffect, useState } from "react";
import "./CustomField.scss";
import useTranslate from "src/utils/useTranslate";
import NoData from "../Common/NoData";
import TabHeader from "../Common/TabHeader";
import CustomFieldDetails from "./CustomFieldDetails";
import AddEditCustomFieldModal from "../Common/AddEditCustomFieldModal";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AsyncStates } from "src/constants";
import { fetchFieldRequest } from "src/store/actions/repository";

const CustomFields = () => {
    const [t] = useTranslate();
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>();
    const fields = useSelector((state: StoreState) => state.repository.fields);
    const [selectedField, setSelectedField] = useState<string>();
    const [mode, setMode] = useState<"edit" | "create" | "delete">("create");
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(fetchFieldRequest());
    }, [dispatch]);

    const addField = () => {
        setMode("create");
        setShowModal(true);
    };

    return (
        <Spin
            spinning={fields.status === AsyncStates.LOADING}
            indicator={<LoadingOutlined />}
        >
            <div className="field-container">
                {fields.data.length === 0 && fields.status === AsyncStates.SUCCESS ? (
                    <NoData
                        title={`${t("repository.totalFields")} (0)`}
                        btns={[t("repository.addNewField"), t("repository.uploadBulkFields")]}
                        addItem={setShowModal}
                    />
                ) : (
                    <div className="content-container">
                        {fields.data.length > 0 && (
                            <>
                                <TabHeader
                                    title={`${t("repository.totalFields")}(${fields.data.length})`}
                                    addItem={addField}
                                    setSearchTerm={setSearchTerm}
                                />
                                <CustomFieldDetails
                                    searchTerm={searchTerm}
                                    setSelectedField={setSelectedField}
                                    setMode={setMode}
                                    setShowModal={setShowModal}
                                />
                            </>
                        )}
                    </div>
                )}
                <AddEditCustomFieldModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    mode={mode}
                    selectedFieldId={selectedField}
                />
            </div>
        </Spin>
    );
};

export default CustomFields;
