import { blue } from "@ant-design/colors"
import { NumberOutlined, MenuOutlined } from "@ant-design/icons"
import { Popover, Typography } from "antd";
import { useMemo } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import StyledBadge from "src/styled_components/StyledBadge"
import { StyledButton } from "src/styled_components/StyledButton"
import { DisplayNames } from "src/typings"
import useTranslate from "src/utils/useTranslate"
// import styles from './Sorter.module.scss'

const { Text } = Typography

interface SorterProps {
  list: string[]
  onChange: any
  getDisplayName: (value: string) => string
  textFormater: (value: string) => string
  buttonSize?: "small" | "middle" | "large"
}

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  border: `1px solid ${isDragging ? blue.primary : "#f0f0f0"}`,
  background: "white",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "baseline",

  // styles we need to apply on draggables
  ...draggableStyle,
})

const grid = 6

const getListStyle = (isDraggingOver: boolean) => ({
  padding: grid,
  width: 160,
  maxHeight: 300,
  overflow: "auto",
})

export const Sorter = ({
  list,
  onChange,
  getDisplayName,
  textFormater,
  buttonSize = "small",
}: SorterProps) => {
  const [t] = useTranslate()
  const names = useSelector((state: StoreState) => state.displayNames.data) as DisplayNames
  const { data } = useSelector((state: StoreState) => state.dataSummary)


  const getNamesColor = (id: string) => {
    if (names.ingredients?.[id]?.name || data?.correlation_summary?.ingredients?.includes(String(id))) {
      return '#2980B9'
    } else if (names.processing?.[id]?.name || data?.correlation_summary?.processing?.includes(String(id))) {
      return '#633974'
    } else if (names.properties?.[id]?.name || data?.correlation_summary?.properties?.includes(String(id))) {
      return '#F39C12'
    } else if (id === "Formulation Cost") {
      return '#1a9850'
    } else if (getDisplayName(id.toString())) {
      return '#2980B9'
    } else {
      return '#d0d0d0'
    }
  }

  const items = useMemo(
    () =>
      list.map((id) => ({
        value: id,
        label: getDisplayName(id.toString())
          ? textFormater(getDisplayName(id.toString()))
          : textFormater(id.toString()) ?? id,
      })),
    [list, getDisplayName, textFormater],
  )

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(list, result.source.index, result.destination.index)
    // dispatch(setEditingState(true));
    onChange(items)
  }

  const content = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item: any, index: number) => (
              <Draggable
                key={item.value}
                draggableId={item.value}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <MenuOutlined style={{ color: getNamesColor(item.value) }} />
                    <Text
                      ellipsis={{ tooltip: item.label }}
                      style={{ width: 80, color: getNamesColor(item.value) }}
                    >
                      {item.label}
                    </Text>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  const isEditing = useSelector((state: StoreState) => state.isEditing)

  return (
    <>
      {!!list?.length && (
        <Popover content={content} trigger="click">
          <StyledBadge dot={isEditing}>
            <StyledButton
              size={buttonSize}
              icon={<NumberOutlined />}
              style={{ outline: "none" }}
            >
              {t("common.reorder")}
            </StyledButton>
          </StyledBadge>
        </Popover>
      )}
    </>
  )
}