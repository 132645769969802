import { EditOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import useTranslate from "src/utils/useTranslate";
import PropertyParameters from "./PropertyParameters";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { useEffect, useMemo } from "react";
import { fetchMethodsRequest, fetchPropertyParametersRequest, fetchPropertyUnitsRequest } from "src/store/actions/repository";
import PropertyUnits from "./PropertyUnits";
import PropertyStandards from "./PropertyStandards";
import PropertySpecimens from "./PropertySpecimens";
import PropertyMethods from "./PropertyMethods";

type TProps = {
    selectedProperty: string;
    showPropertyModal: () => void;
    showEditPropertyModal: () => void;
};

const PropertyDetails = ({
    selectedProperty,
    showPropertyModal,
    showEditPropertyModal,
}: TProps) => {
    const [t] = useTranslate();
    const parameters = useSelector(
        (state: StoreState) => state.repository.parameters
    );
    const properties = useSelector(
        (state: StoreState) => state.repository.properties.data
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPropertyParametersRequest({ property_id: selectedProperty }));
        dispatch(fetchMethodsRequest({ property_id: selectedProperty }));
        dispatch(fetchPropertyUnitsRequest({ property_id: selectedProperty }));
    }, [selectedProperty, dispatch]);

    const selectedPropertyParameters = useMemo(
        () => parameters.data.filter((p) => p.property_id === selectedProperty),
        [parameters, selectedProperty]
    );

    const selectedPropertyName = useMemo(
        () => properties.find((p) => p.property_id === selectedProperty)?.name,
        [properties, selectedProperty]
    );

    return (
        <div className="property-details">
            <div className="header">
                <div className="property-title">{selectedPropertyName}</div>
                <div className="property-edit">
                    <EditOutlined onClick={showEditPropertyModal} />
                </div>
            </div>
            <div className="content">
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            key: "1",
                            label: t("common.parameters"),
                            children: (
                                <PropertyParameters
                                    showPropertyModal={showPropertyModal}
                                    parameters={selectedPropertyParameters}
                                    selectedProperty={selectedProperty}
                                />
                            ),
                        },
                        {
                            key: "2",
                            label: t("common.methods"),
                            children: (
                                <PropertyMethods
                                    selectedProperty={selectedProperty}
                                    parameters={selectedPropertyParameters}
                                />
                            ),
                        },
                        {
                            key: "3",
                            label: t("common.units"),
                            children: (
                                <PropertyUnits
                                    selectedProperty={selectedProperty}
                                />
                            ),
                        },
                        {
                            key: "4",
                            label: t("common.standards"),
                            children: (
                                <PropertyStandards
                                    selectedProperty={selectedProperty}
                                />
                            ),
                        },
                        {
                            key: "5",
                            label: t("inventory.specimen"),
                            children: (
                                <PropertySpecimens
                                    selectedProperty={selectedProperty}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default PropertyDetails;
