import { Modal, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { withdrawInvitationRequest } from 'src/store/actions/invite'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'

const { Text } = Typography

export const WithdrawInvitationModal = ({ withdrawInvitationInfo, setWithdrawInvitationInfo }: any) => {
    const dispatch = useDispatch()
    const [t] = useTranslate()
    const { withdrawInvitationStatus } = useSelector((state: StoreState) => state.invite)

    useEffect(() => {
        if (withdrawInvitationStatus === AsyncStates.SUCCESS) {
            setWithdrawInvitationInfo({
                isModalVisible: false,
                withdrawInvitationData: {}
            })
        }
    }, [setWithdrawInvitationInfo, withdrawInvitationStatus])

    return (
        <Modal
            title={`Withdraw Invitation`}
            open={withdrawInvitationInfo.isModalVisible}
            okText={t("common.delete")}
            cancelText={t("common.cancel")}
            onOk={() => {
                dispatch(withdrawInvitationRequest({ emails: [withdrawInvitationInfo.withdrawInvitationData.user_email] }))
            }}
            onCancel={() => {
                setWithdrawInvitationInfo({
                    isModalVisible: false,
                    withdrawInvitationData: {}
                })
            }}
            okButtonProps={{ style: { background: "#FF4D4F" }, loading: withdrawInvitationStatus === AsyncStates.LOADING }}
        >
            <div>
                <Text strong>{`Are you sure to withdraw invitation for ${withdrawInvitationInfo.withdrawInvitationData.user_email} ?`}</Text>
            </div>
        </Modal>
    )
}
