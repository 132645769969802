import { Form, Modal, Radio, Select, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStates, KuritaCompanyId } from 'src/constants'
import { updateExperimentUnitCleanup, updateExperimentUnitRequest } from 'src/store/actions/woUnitConversion';
import { setEditingState } from 'src/store/actions/workOrderDetails';
import { StoreState } from 'src/store/configureStore';
import { isValidNumber } from 'src/utils/decorator';
import { Dataset } from 'src/utils/getHeaders';
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar';
import { StyledButton } from 'src/styled_components/StyledButton';


const { Text } = Typography;

export const BaseCategorySelectionModal = ({ showBaseCategoryModal, setShowBaseCategoryModal, trialSetDataList, initialTrialSetList, parameterList, batchSizeList, dataset, setBatchMode, displayIdList }: any) => {
    const dispatch = useDispatch();
    const requiredFieldStar = useRequiredFieldStar()

    const companyId = useSelector((state: StoreState) => state.login.loginResponse.company_id)
    const { updateExperimentWithUnitStatus, unitConvertedData } = useSelector((state: StoreState) => state.woExperimentUnitConversion);
    const editFormulationsStatus = useSelector((state: StoreState) => state.workOrderDetails.editFormulationsStatus);
    const [phrConversion, setPhrConversion] = useState("categories")
    const [baseCategoriesForm] = useForm();

    const datasetLabels = useSelector((state: StoreState) => state.displayNames.data?.ingredients);
    const linkedTrials = useSelector((state: StoreState) => state.workOrderDetails.linkedExperiment);

    const availableCategories = useMemo(() => {
        return [
            ...new Set(
                Object.entries(datasetLabels || {})
                    .filter(([key, obj]: any) => {
                        return parameterList.includes(key);
                    })
                    .map(([key, obj]: any) => obj.category)
            ),
        ]
    }, [datasetLabels, parameterList])

    const categoryOptions = useMemo(() => {
        if (!availableCategories) {
            return [];
        }

        return availableCategories
            .filter((category) => typeof category === 'string' && category.trim() !== '') // Check if category is a non-empty string
            .map((category) => ({
                label: category,
                value: category,
                key: category,
            }));
    }, [availableCategories]);

    const ingredientsOptions = useMemo(() => {
        return Object.entries(datasetLabels || {})
            .filter(([key, value]: any) => parameterList.includes(key))
            .map(([key, value]: any) => ({
                label: value?.name,
                value: key,
                key,
            }))
    }, [datasetLabels, parameterList])

    useEffect(() => {
        if (companyId === KuritaCompanyId && showBaseCategoryModal.toUnit === "mol%" && availableCategories.includes("Monomer")) {
            baseCategoriesForm.setFieldValue("base_categories", ["Monomer"])
        }
    }, [baseCategoriesForm, companyId, showBaseCategoryModal.toUnit, availableCategories])

    const handleMolColversionModal = useCallback(() => {
        setPhrConversion("categories")
        setShowBaseCategoryModal({
            isModalVisible: false,
            toUnit: null,
            fromUnit: null,
            isDirectConversionPossible: false,
            base_categories: [],
            base_ingredients: []
        })
        baseCategoriesForm.resetFields()
    }, [baseCategoriesForm, setShowBaseCategoryModal])

    useEffect(() => {
        if (updateExperimentWithUnitStatus === AsyncStates.SUCCESS) {
            setShowBaseCategoryModal((prev: any) => ({
                ...prev,
                isModalVisible: false,
                toUnit: null,
                fromUnit: null,
                isDirectConversionPossible: false,
            }))
            baseCategoriesForm.resetFields()
            dispatch(setEditingState(true))
        }
    }, [dispatch, handleMolColversionModal, updateExperimentWithUnitStatus, setShowBaseCategoryModal, baseCategoriesForm])

    useEffect(() => {
        if (editFormulationsStatus === AsyncStates.SUCCESS) {
            handleMolColversionModal()
            dispatch(updateExperimentUnitCleanup())
        }
    }, [dispatch, handleMolColversionModal, editFormulationsStatus])

    const applyMolConversion = (value: any) => {
        const payload = {
            exp_data: trialSetDataList.map(
                (trialSetData: any, trialSetDataIndex: any) => ({
                    to_unit: showBaseCategoryModal.toUnit,
                    input_data: {
                        ...parameterList.reduce(
                            (acc: any, parameter: any) => ({
                                ...acc,
                                [parameter]: {
                                    mol_value: unitConvertedData?.[trialSetDataIndex]?.input_data?.[parameter]?.mol_value ?? initialTrialSetList?.[trialSetDataIndex]?.[dataset]?.[parameter]?.mol_value ?? null,
                                    value: trialSetData[parameter],
                                    unit: showBaseCategoryModal.fromUnit,
                                    category: datasetLabels[parameter]?.category,
                                    ...(linkedTrials.some(
                                        (trial: any) => trial?.formulation_id === parameter
                                    )
                                        ? { type: "trials" }
                                        : {}),
                                },
                            }),
                            {}
                        ),
                    },
                    ...(initialTrialSetList[trialSetDataIndex]?.id_set || {}),
                    input_meta: {
                        display_id: displayIdList[trialSetDataIndex],
                        ...(dataset === Dataset.ingredients
                            ? {
                                batch_size: {
                                    value_grams: !["wt%", "phr", "mol%", "mol"].includes(showBaseCategoryModal.fromUnit) ? Object.values(trialSetDataList[trialSetDataIndex] || {}).reduce((acc: any, curr: any) => {
                                        return isValidNumber(curr) ? Number(curr) + acc : acc
                                    }, 0) : batchSizeList?.[trialSetDataIndex]?.value_grams || 100,
                                    pref_unit: !["wt%", "phr", "mol%", "mol"].includes(showBaseCategoryModal.fromUnit) ? showBaseCategoryModal.fromUnit : batchSizeList?.[trialSetDataIndex]?.pref_unit ?? "g",
                                },
                            }
                            : {}),
                    },
                })
            ),
            base_categories: value.base_categories,
            base_ingredients: value?.base_ingredients,
            type: dataset
        }
        setShowBaseCategoryModal((prev: any) => ({
            ...prev,
            base_categories: value.base_categories,
            base_ingredients: value.base_ingredients,
        }))
        dispatch(updateExperimentUnitRequest(payload))
    };

    return (
        <Modal
            maskClosable={false}
            open={showBaseCategoryModal.isModalVisible}
            onCancel={() => handleMolColversionModal()}
            title={`Conversion from ${showBaseCategoryModal.fromUnit} to ${showBaseCategoryModal.toUnit}`}
            footer={null}
        >
            <div>
                <Form
                    name="conversion_unit_selection"
                    layout="vertical"
                    onFinish={applyMolConversion}
                    autoComplete="off"
                    requiredMark={false}
                    form={baseCategoriesForm}
                    initialValues={{ base_categories: companyId === KuritaCompanyId && showBaseCategoryModal.toUnit === "mol%" && availableCategories.includes("Monomer") ? ["Monomer"] : [] }}
                >
                    {
                        (!showBaseCategoryModal.isDirectConversionPossible) ? <>
                            <Text> Direct Conversion is not possible. Converting it to Unit(s): {[...new Set(batchSizeList.map((batchSize: any) => batchSize?.pref_unit))].join(", ") ?? "g"}.</Text>
                            <div>
                                <Text>If you want to Update The Batch Size <StyledButton style={{ padding: 0 }} type='link' onClick={() => {
                                    setBatchMode(true)
                                    handleMolColversionModal()
                                }}>Turn On Absolute Mode</StyledButton>
                                </Text>
                            </div>
                        </> :
                            null
                    }
                    {(showBaseCategoryModal.toUnit === "phr") ? (
                        <>
                            <Form.Item >
                                <Radio.Group defaultValue={phrConversion} value={phrConversion} onChange={(e) => {
                                    baseCategoriesForm.resetFields()
                                    setPhrConversion(e?.target?.value)
                                }}>
                                    <Radio value={"categories"}>{"Use Categories"}</Radio>
                                    <Radio value={"ingredients"}>{"Use Ingredients"}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {phrConversion === "ingredients" ?
                                <Form.Item
                                    label="Select Ingredients"
                                    name="base_ingredients"
                                    rules={[
                                        { required: true, message: "Please Select Ingredients" },
                                    ]}
                                    required 
                                    tooltip={requiredFieldStar}
                                    >
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select Ingredients"
                                        mode="multiple"
                                        options={ingredientsOptions}
                                    >
                                    </Select>
                                </Form.Item> :
                                <Form.Item
                                    label="Select Base Categories"
                                    name="base_categories"
                                    rules={[
                                        { required: true, message: "Please Select Base Category(s)!" },
                                    ]}
                                    required tooltip={requiredFieldStar}>
                                    <Select
                                        style={{ width: "100%" }}
                                        placeholder="Select A Base Category"
                                        mode="multiple"
                                        options={categoryOptions}
                                    >
                                    </Select>
                                </Form.Item>
                            }
                        </>
                    )
                        :
                        (!(["wt%", "phr"].includes(showBaseCategoryModal.fromUnit) && showBaseCategoryModal.toUnit === "mol")) &&
                        <Form.Item
                            label="Select Base Categories"
                            name="base_categories"
                            rules={[
                                { required: true, message: "Please Select Base Category(s)!" },
                            ]}
                            required tooltip={requiredFieldStar}>
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Select A Base Category"
                                mode="multiple"
                                options={categoryOptions}
                            >
                            </Select>
                        </Form.Item>
                    }
                    <div style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "0.5rem",
                        alignItems: "center",
                    }}>
                        <Form.Item>
                            <StyledButton
                                danger
                                type="default"
                                onClick={() => handleMolColversionModal()}
                            >
                                Cancel
                            </StyledButton>
                        </Form.Item>

                        <Form.Item>
                            <StyledButton
                                loading={updateExperimentWithUnitStatus === AsyncStates.LOADING}
                                type="primary"
                                htmlType="submit"
                            >
                                Apply Conversion
                            </StyledButton>
                        </Form.Item>
                    </div>
                </Form>
            </div >
        </Modal>
    )
}