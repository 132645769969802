import { call, take, put, select, takeLatest } from "redux-saga/effects"
import { eventChannel, END } from "redux-saga"
import { addFavouritesInInverseApi, deleteFavouritesInInverseApi, fetchInverseFavouritesListApi, getInverseConfig, inverseModelStream } from "../../services/inverseModel"
import {
	inverseModelRangeConnect,
	inverseModelMinmaxConnect,
	inverseModelMinmaxSuccess,
	inverseModelRangeSuccess,
	inverseModelMinmaxFailure,
	inverseModelRangeFailure,
	getPredictionFailure,
	getPredictionRequest,
	getPredictionSuccess,
	inverseMinmaxAck,
	inverseRangeAck,
	deletePredictionIdsFailure,
	deletePredictionIdsRequest,
	deletePredictionIdsSuccess,
	inverseConfigRequest,
	inverseConfigSuccess,
	inverseConfigFailure,
	addFavouritesInInverseSuccess,
	addFavouritesInInverseFailure,
	getFavouritesListInInverseSuccess,
	getFavouritesListInInverseFailure,
	addFavouritesInInverseRequest,
	getFavouritesListInInverseRequest,
	deleteFavouritesInInverseRequest,
	deleteFavouritesInInverseSuccess,
	deleteFavouritesInInverseFailure,
} from "../actions/inverseModel"
import { reconnect } from "../actions/connection"
import { messages } from "src/utils/hooks"
import {
	getPredictionIdApi,
	deletePredictionIdsApi,
} from "../../services/inverseModel"
// import { suggestedExperimentsRequest } from "../actions/suggestedExp"
import { message, notification } from "antd"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { configsUpdateAiengine } from "../actions/configs"


function createInverseModelChannel(socket: WebSocket) {
	return eventChannel((emit) => {
		socket.onmessage = (event: any) => {
			emit(event.data)
		}

		socket.onclose = () => {
			emit(END)
		}

		const unsubscribe = () => {
			socket.onmessage = null
		}

		return unsubscribe
	})
}

function isValid(data: any) {
	try {
		JSON.parse(JSON.parse(data))
	} catch {
		return false
	}
	return true
}

let socket: WebSocket
let socketChannel: any

function* inverseModelSaga({
	payload: inputPayload,
}: any): Generator<any, any, any> {
	const { user_id: key } = yield select(
		(state) => state.login.loginResponse
	)
	const token = jwtManager.getToken()
	if (socket?.readyState !== WebSocket.OPEN) {
		socket = yield call(inverseModelStream, key)
		socketChannel = yield call(createInverseModelChannel, socket)
	}
	socket.send(JSON.stringify(inputPayload))
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	while (true) {
		try {
			const payload = yield take(socketChannel)

			if (isValid(payload)) {
				const result = JSON.parse(JSON.parse(payload))[0]
				if (result) {
					// yield put(
					// 	suggestedExperimentsRequest({
					// 		prediction_id: result.prediction_id,
					// 		pageNum: 1,
					// 	})
					// )

					result.objective?.range
						? yield put(inverseModelRangeSuccess(result))
						: yield put(inverseModelMinmaxSuccess(result))
					if (!!token) {
						// notification.success({
						// 	message: messages[ln].ai_engine_inverse_results_ready,
						// 	description: messages[ln].ai_engine_inverse_results_desc,
						// 	style: { cursor: "pointer" },
						// })
						yield put(getPredictionRequest({ pageNum: 1 }))
					}
				} else {
					notification.error({
						message: messages[ln].could_not_fetch_results,
					})
					inputPayload?.objectives?.range
						? yield put(inverseModelRangeFailure(inputPayload?.version))
						: yield put(inverseModelMinmaxFailure(inputPayload?.version))
				}
			} else {
				const ack = JSON.parse(payload)
				inputPayload?.objectives?.range
					? yield put(inverseRangeAck(ack))
					: yield put(inverseMinmaxAck(ack))
			}
		} catch (error) {
			notification.error({
				message: messages[ln],
			})
			inputPayload?.objectives?.range
				? yield put(inverseModelRangeFailure(error))
				: yield put(inverseModelMinmaxFailure(error))
			yield put(reconnect(error))
			message.error(messages[ln].internal_server_error)
		} finally {
			// socket.close()
			// socketChannel.close()
		}
	}
}

function* getPredictionIdsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const allConfigs = yield select((state: StoreState) => state.configs)
		const {
			data: {
				result: { data, total, message, status, total_unread_count },
			},
		} = yield call(getPredictionIdApi, payload, headers)
		if (status === "Success") {
			yield put(getPredictionSuccess({ data, total }))

			// Update the unread count on inverse
			const updatedConfigs = { ...allConfigs?.ai_engine }
			updatedConfigs.inverse.total_unread_count = total_unread_count
			yield put(configsUpdateAiengine(updatedConfigs))


		} else {
			yield put(getPredictionFailure(message))
		}
	} catch (error) {
		yield put(getPredictionFailure(messages[ln].internal_server_error))
	}
}

function* deletePredictionIdsSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: { result },
		} = yield call(deletePredictionIdsApi, payload, headers)

		if (result.status === "Success") {
			yield put(deletePredictionIdsSuccess())
			notification.success({
				message: result.message,
			})
		} else {
			yield put(deletePredictionIdsFailure(result.message))
		}
	} catch (error) {
		yield put(deletePredictionIdsFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* getInverseConfigSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data,
			status
		} = yield call(getInverseConfig, payload, headers)

		if (status === 200) {
			yield put(inverseConfigSuccess(data))
		} else {
			yield put(inverseConfigFailure(messages[ln].internal_server_error))
		}
	} catch (error) {
		yield put(inverseConfigFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* addFavouritesInInverseSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message: apiMessage },
			},
		} = yield call(addFavouritesInInverseApi, payload, headers)

		if (status === "Success") {
			yield put(addFavouritesInInverseSuccess(data))
			notification.success({
				message: apiMessage
			})
		} else {
			yield put(addFavouritesInInverseFailure(messages[ln].internal_server_error))
			notification.error({
				message: apiMessage
			})
		}
	} catch (error) {
		yield put(addFavouritesInInverseFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* deleteFavouritesInInverseSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message: apiMessage },
			},
		} = yield call(deleteFavouritesInInverseApi, { favorite_id: payload?.favorite_id }, headers)

		if (status === "Success") {
			yield put(deleteFavouritesInInverseSuccess(data))
			yield put(getFavouritesListInInverseRequest({ model_version: payload?.model_version }))
			notification.success({
				message: apiMessage
			})
		} else {
			yield put(deleteFavouritesInInverseFailure(messages[ln].internal_server_error))
			notification.error({
				message: apiMessage
			})
		}
	} catch (error) {
		yield put(deleteFavouritesInInverseFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}

function* getInverseFavouritesSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	)

	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message: apiMessage },
			},
		} = yield call(fetchInverseFavouritesListApi, payload, headers)
		if (status === "Success") {
			yield put(getFavouritesListInInverseSuccess(data))
		} else {
			notification.error({
				message: apiMessage
			})
			yield put(getFavouritesListInInverseFailure(messages[ln].internal_server_error))
		}
	} catch (error) {
		yield put(getFavouritesListInInverseFailure(messages[ln].internal_server_error))
		message.error(messages[ln].internal_server_error)
	}
}


export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(
		[inverseModelMinmaxConnect, inverseModelRangeConnect],
		inverseModelSaga
	)
	yield takeLatest(getPredictionRequest, getPredictionIdsSaga)
	yield takeLatest(deletePredictionIdsRequest, deletePredictionIdsSaga)
	yield takeLatest(inverseConfigRequest, getInverseConfigSaga)
	yield takeLatest(addFavouritesInInverseRequest, addFavouritesInInverseSaga)
	yield takeLatest(deleteFavouritesInInverseRequest, deleteFavouritesInInverseSaga)
	yield takeLatest(getFavouritesListInInverseRequest, getInverseFavouritesSaga)

}
