import { put, takeLatest, call, select } from "redux-saga/effects"
import { notification } from "antd"
import {
	conversionFailure,
	conversionRequest,
	conversionSuccess,
	createUnitFailure,
	createUnitRequest,
	createUnitSuccess,
	deleteUnitFailure,
	deleteUnitRequest,
	deleteUnitSuccess,
	unitListFailure,
	unitListRequest,
	unitListSuccess,
} from "../actions/conversion"
import { conversion, createUnit, deleteUnit, fetchUnitList } from "src/services/conversion"
import jwtManager from "src/utils/jwtManager"
import { messages } from "src/utils/hooks"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"

function* unitListSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(fetchUnitList, payload, headers)

		if (status === "Success") {
			yield put(unitListSuccess(data))
		} else {
			yield put(unitListFailure())
		}
	} catch (error) {
		yield put(unitListFailure())
	}
}

function* conversionSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status, message },
			},
		} = yield call(conversion, payload, headers)
		if (status === "Success") {
			yield put(conversionSuccess(data))
		} else {
			notification.error({ message })
			yield put(conversionFailure())
		}
	} catch (error) {
		yield put(conversionFailure())
	}
}

function* createUnitSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select(
		(state: StoreState) => state.language.current
	  );
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(createUnit, payload, headers)

		if (status === "Success") {
			yield put(createUnitSuccess(data))
			yield put(unitListRequest({ "category": "" }))
			notification.success({
				key: 'updatable',
				message: messages[ln].newUnitAdded,
				description: messages[ln].newUnitAddedConversionAlert
				})
		} else {
			yield put(createUnitFailure())
			notification.error({
				key: 'updatable',
				message: messages[ln].alreadyExists,
				})
		}
	} catch (error) {
		yield put(createUnitFailure())
	}
}

function* deleteUnitSaga({ payload }: any): Generator<any, any, any> {
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				result: { data, status },
			},
		} = yield call(deleteUnit, payload, headers)

		if (status === "Success") {
			yield put(deleteUnitSuccess(data))
		} else {
			yield put(deleteUnitFailure())
		}
	} catch (error) {
		yield put(deleteUnitFailure())
	}
}
export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(unitListRequest, unitListSaga)
	yield takeLatest(conversionRequest, conversionSaga)
	yield takeLatest(createUnitRequest, createUnitSaga)
	yield takeLatest(deleteUnitRequest, deleteUnitSaga)
}
