import { InfoCircleOutlined } from '@ant-design/icons'
import { Row, Select, Space, Table, Tooltip, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledCard } from 'src/styled_components/StyledCard'
import useTranslate from 'src/utils/useTranslate'

const { Text } = Typography
const { Option, OptGroup } = Select

export const MapUnits = ({ callbacks, data }: any) => {
    const [t] = useTranslate()

    const { unitsData } = data
    const { setUnitsData, setCurrent, uploadData } = callbacks

    const unitList = useSelector((state: StoreState) => state.conversion.unitList)

    const columns = [{
        dataIndex: "unit_name",
        title: <Space><Text>{t("mapUnits.yourUnit")}</Text><Tooltip title={t("mapUnits.detectedUnits")}><InfoCircleOutlined /></Tooltip></Space>,
        key: "unit_name"
    },
    {
        dataIndex: "unit_mapped_to",
        title: <Space><Text>{t("mapUnits.sheetMappedTo")}</Text><Tooltip title={t("mapUnits.availableUnit")}><InfoCircleOutlined /></Tooltip></Space>,
        key: "unit_mapped_to",
        render: (text: any, record: any, index: any) => {
            return <Select
                showSearch
                placeholder={t("common.selectAUnit")}
                value={record.sheet_mapped_to} style={{ width: "100%" }}
                onChange={(e: any) => {
                    setUnitsData((prevState: any) => {
                        const array = [...prevState]
                        array.forEach((res: any) => {
                            if (res.unit_name === record.unit_name) {
                                res.unit_mapped_to = e
                            }
                        })
                        return array
                    })
                }}>
                {Array.from(new Set(unitList.map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                    <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                        {unitList.filter((res: any) => res.category.toLowerCase() === category).map((res: any) => (
                            <Option value={res.name} key={res.name}>{res.name}</Option>
                        ))}
                    </OptGroup>
                )}
            </Select>
        }
    }
    ]


    return (
        <Space direction="vertical" style={{ width: "100%" }} size="large">
            <StyledCard title={<Space direction="vertical" style={{ width: "100%" }} size="small">
                <Text style={{ whiteSpace: "pre-wrap" }} type='secondary'>{t("mapUnits.unitsNotDetected")}</Text>
                <Text style={{ whiteSpace: "pre-wrap" }} type='secondary'>{t("mapUnits.mappedAvailableUnits")}</Text>
                <Text style={{ whiteSpace: "pre-wrap" }} type='secondary'>{t("mapUnits.customUnits")}</Text>
                <Text style={{ whiteSpace: "pre-wrap" }} type='secondary'>{t("mapUnits.customUnitNote")}</Text>
            </Space>}>
                <Table columns={columns} dataSource={unitsData} pagination={false} />
            </StyledCard>
            <Row justify='end'>
                <Space>
                    <StyledButton onClick={() => { setCurrent(3) }} size='large'>{`${t("common.back")}: ${t("dataMapper.selectExperiments")}`}</StyledButton>
                    <StyledButton disabled={!unitsData.every((res: any) => res?.unit_mapped_to)} onClick={uploadData} size='large' type='primary' >{t("mapUnits.nextConfirmUpload")}</StyledButton>
                </Space>
            </Row>
        </Space>
    )
}
