import { LoadingOutlined } from '@ant-design/icons'
import { AutoComplete, Form, Input, Modal, Row, Select, Space, Spin } from 'antd'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { AsyncStates } from 'src/constants'
import { createParameterRequest } from 'src/store/actions/inventory'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'

const { OptGroup, Option } = Select


export const AddMetaDataModal = ({ metadataModalVisible, setMetadataModalVisible, filterCategories }: any) => {
    const [t] = useTranslate()
    const requiredFieldStar = useRequiredFieldStar()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const metadataPropertiesStatus = useSelector((state: StoreState) => state.inventory.metadataPropertiesStatus)
    const unitsList = useSelector((state: StoreState) => state.conversion.unitList)

    const closeModal = useCallback(() => {
        form.resetFields()
        setMetadataModalVisible(false)
    }, [form, setMetadataModalVisible])

    useEffect(() => {
        if (metadataPropertiesStatus === AsyncStates.SUCCESS) {
            closeModal()
        }
    }, [metadataPropertiesStatus, closeModal])

    const formSubmit = (values: any) => {
        const params = new FormData()
        params.append("name", values?.name)
        params.append("type", "metadata_properties")
        params.append("category", values?.category)
        params.append("unit", JSON.stringify(values.unit))
        dispatch(createParameterRequest(params))
    }


    return (
        <Modal title={t("inventory.Addpropertiesdirectory")} width={600} open={metadataModalVisible}
            maskClosable={false}
            onCancel={closeModal} footer={null} zIndex={1000}>
            <Spin spinning={metadataPropertiesStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Form form={form} onFinish={formSubmit} layout="vertical" requiredMark={false}>
                    <Form.Item label={t("common.Name")} required tooltip={requiredFieldStar} rules={[{ required: true, min: 2, whitespace: true, type: "string", transform: (value) => value?.trim() }]} name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label={t("common.category")} required tooltip={requiredFieldStar} rules={[{ required: true, transform: (value) => value?.trim() }]} name="category">
                        <AutoComplete
                            style={{ zIndex: 1000001 }}
                            showSearch
                            placeholder={t("common.selectCategory")}
                            filterOption={true}
                            options={filterCategories.map((res: any) => ({ label: res, value: res }))}
                        />
                    </Form.Item>
                    <Form.Item label={t("common.unit")} required tooltip={requiredFieldStar} rules={[{ required: true }]} name="unit">
                        <Select showSearch mode="multiple" style={{ zIndex: 1000001 }}
                            placeholder={t("common.selectUnit")}>
                            {Array.from(new Set(unitsList.map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                                <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                                    {unitsList.filter((res: any) => res.category.toLowerCase() === category).map((res: any, index: any) => (
                                        <Option value={res.name} key={res.name + index}>{res.name}</Option>
                                    ))}
                                </OptGroup>
                            )}
                        </Select>
                    </Form.Item>
                    <Row justify={"end"} style={{ marginTop: 40 }}>
                        <Space>
                            <StyledButton onClick={closeModal}>{t("common.cancel")}</StyledButton>
                            <StyledButton htmlType="submit" type={"primary"}>{t("common.add")}</StyledButton>
                        </Space>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}
