/* eslint-disable no-template-curly-in-string */
import { useMemo, useState } from "react"
import { SeriesBubbleOptions } from "highcharts"
import { ScatterPlot } from "src/components/AIEngine/DataSummary/types"
import { toTitleCase } from "src/utils/general"
import { message } from "antd"
import useTranslate from "src/utils/useTranslate"

export const colors = [
	"#450356",
	"#414286",
	"#2d6e8e",
	"#1f978b",
	"#4bc16b",
	"#fde624",
]

type getDisplayName = (name: string) => string

function prepareHighchartOptions(
	data: SeriesBubbleOptions["data"],
	input1: string,
	input2: string,
	input3: string,
	getDisplayName: getDisplayName
) {
	return {
		chart: {
			type: "bubble",
		},
		title: { text: "" },
		xAxis: {
			title: {
				text: toTitleCase(getDisplayName(input1)),
			},
			startOnTick: true,
			endOnTick: true,
			showLastLabel: true,
		},
		yAxis: {
			title: {
				text: toTitleCase(getDisplayName(input2)),
			},
		},
		credits: {
			enabled: false,
		},
		legend: {
			enabled: false,
		},
		tooltip: {
			useHTML: true,
			headerFormat: "<table>",
			pointFormat:
				'<tr><th colspan="2"><h3>{point.name}</h3></th></tr>' +
				`<tr><th>${toTitleCase(getDisplayName(input1))}:</th><td>{point.x}</td></tr>` +
				`<tr><th>${toTitleCase(getDisplayName(input2))}:</th><td>{point.y}</td></tr>` +
				`<tr><th>${toTitleCase(getDisplayName(input3))}:</th><td>{point.z}</td></tr>`,
			footerFormat: "</table>",
			shared: true,
		},
		plotOptions: {
			scatter: {
				marker: {
					states: {
						hover: {
							enabled: true,
							lineColor: "rgb(100,100,100)",
						},
					},
				},
			},
		},
		series: [{ type: "bubble", color: "#FF3232", data }],
	} as Highcharts.Options
}

export const useData = (data: ScatterPlot, getDisplayName: getDisplayName) => {
	const [input1, setInput1] = useState<string | null>(null)
	const [input2, setInput2] = useState<string | null>(null)
	const [input3, setInput3] = useState<string | null>(null)
	const [t] = useTranslate()

	const options: Highcharts.Options = useMemo(() => {
		if (input1 === null || input2 === null || input3 === null) {
			return prepareHighchartOptions([], "", "", "", getDisplayName)
		}

		const experiments = data[input1] ?? {}
		const seriesData: SeriesBubbleOptions["data"] = Object.keys(
			experiments
		).map((name) => {
			return {
				type: "bubble",
				x: data[input1][name] ?? 0,
				y: data[input2]?.[name] ?? 0,
				z: data[input3]?.[name] ?? 0,
				name,
			}
		})

		if (seriesData.length === 0) {
			message.error(t("dataSummary.3dScatterChart.notEnoughData"))
		}

		return prepareHighchartOptions(seriesData, input1, input2, input3, getDisplayName)
	}, [input1, input2, input3, data, t, getDisplayName])

	return {
		input1,
		input2,
		input3,
		setInput1,
		setInput2,
		setInput3,
		options,
	}
}
