import { sendDelete, sendGet, sendPatch, sendPost, sendPut } from "src/utils/sendApi";

export const getPropertyAPI = sendGet("v1/data/repo/property");
export const getPropertyParameterAPI = (property_id: number | string) => sendGet(`v1/data/repo/property/${property_id}/parameters`);
export const createPropertyAPI = sendPost("v1/data/repo/property");
export const updatePropertyAPI = (property_id: number | string) => sendPatch(`v1/data/repo/property/${property_id}`);
export const deletePropertyAPI = sendDelete("v1/data/repo/property");

export const getAdditivesAPI = sendGet("v1/data/repo/additives");
export const createAdditivesAPI = sendPost("v1/data/repo/additives");
export const updateAdditivesAPI = (additive_id: number | string) => sendPut(`v1/data/repo/additives/${additive_id}`);
export const deleteAdditivesAPI = sendDelete("v1/data/repo/additives");

export const getFieldAPI = sendGet("v1/data/repo/custom-field");
export const createFieldAPI = sendPost("v1/data/repo/custom-field");
export const updateFieldAPI = (field_id: number | string) => sendPut(`v1/data/repo/custom-field/${field_id}`);
export const deleteFieldAPI = sendDelete("v1/data/repo/custom-field");

export const getParameterAPI = sendGet("v1/data/repo/parameters");
export const createParameterAPI = sendPost("v1/data/repo/parameters");
export const updateParameterAPI = (parameter_id: number | string) => sendPut(`v1/data/repo/parameters/${parameter_id}`);
export const deleteParameterAPI = sendDelete("v1/data/repo/parameters");

export const getIngredientCategoriesAPI = sendGet("v1/data/repo/category");
export const createIngredientCategoryAPI = sendPost("v1/data/repo/category");
export const updateIngredientCategoryAPI = (category_id: number | string) => sendPatch(`v1/data/repo/category/${category_id}`);
export const deleteIngredientCategoryAPI = sendDelete("v1/data/repo/category");

export const getPropertyCategoriesAPI = sendGet("v1/data/repo/property/category");
export const createPropertyCategoryAPI = sendPost("v1/data/repo/property/category");
export const updatePropertyCategoryAPI = (category_id: number | string) => sendPatch(`v1/data/repo/property/category/${category_id}`);
export const deletePropertyCategoryAPI = sendDelete("v1/data/repo/property/category");

export const getAdditiveCategoriesAPI = sendGet("v1/data/repo/additive/category");
export const createAdditiveCategoryAPI = sendPost("v1/data/repo/additive/category");
export const updateAdditiveCategoryAPI = (category_id: number | string) => sendPatch(`v1/data/repo/additive/category/${category_id}`);
export const deleteAdditiveCategoryAPI = sendDelete("v1/data/repo/additive/category");

export const getIngredientTemplateAPI = sendGet("v1/data/repo/ingredient-template");
export const createIngredientTemplateAPI = sendPost("v1/data/repo/ingredient-template");
export const updateIngredientTemplateAPI = (template_id: number | string) => sendPut(`v1/data/repo/ingredient-template/${template_id}`);
export const deleteIngredientTemplateAPI = sendDelete("v1/data/repo/ingredient-template");

export const getAllMethodsAPI = () => sendGet(`v1/data/repo/methods`);
export const getMethodsAPI = (property_id: number | string) => sendGet(`v1/data/repo/${property_id}/methods`);
export const createMethodsAPI = (property_id: number | string) => sendPost(`v1/data/repo/${property_id}/methods`);
export const updateMethodsAPI = (property_id: number | string, method_id: number | string) => sendPut(`v1/data/repo/${property_id}/methods/${method_id}`);
export const deleteMethodsAPI = (property_id: number | string) => sendDelete(`v1/data/repo/${property_id}/methods`);
export const getAllCategoriesApi = sendGet("v1/data/repo/master-data");

export const getPropertyUnitsApi = (property_id: number | string) => sendGet(`v1/data/repo/property/${property_id}/units`);
export const setDefaultPropertyUnitApi = sendPost(`v1/data/repo/property/update-default-unit`);
