import { Modal, Space, Typography } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { closeAllWorkOrdersRequest } from 'src/store/actions/workOrders'
import { StyledButton } from 'src/styled_components/StyledButton';
import useTranslate from 'src/utils/useTranslate'

const CloseAllWorkOrdersModal = ({ projectIds, buttonType, icon }: any) => {
  const [t] = useTranslate()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dispatch = useDispatch()

  return (
    <>
      <StyledButton type={buttonType} onClick={() => { setIsOpen(true) }}
        className={"project__dropdown__btn"}
        icon={icon ? icon : null}>
        {t("common.closeAllWoTitle")}
      </StyledButton>
      <Modal
        title={t("common.closeAllWoTitle")}
        open={isOpen}
        onOk={() => {
          setIsOpen(false);
          dispatch(closeAllWorkOrdersRequest({ project_ids: projectIds }))
        }}
        onCancel={() => setIsOpen(false)}
        okText={t("common.proceed")}
        cancelText={t("common.cancel")}
      >
        <Space direction='vertical'>
          <Typography.Text>
            {t('common.pleaseNote')}
          </Typography.Text>
          <Space direction='vertical'>
            <Typography.Text>
              {t('common.closeAllWoDescription1')}
            </Typography.Text>
            <Typography.Text>
              {t('common.closeAllWoDescription2')}
            </Typography.Text>
          </Space>
        </Space>
      </Modal>

    </>

  )
}

export default CloseAllWorkOrdersModal